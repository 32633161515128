import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencyTypeService} from '../web-services/agency-type.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-agency-type', templateUrl: './agency-type.component.html', styleUrls: ['./agency-type.component.css']})
export class AgencyTypeComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAgencyTypeId = null;
    agencyTypeList: any = [];
    agencyTypeSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencyTypeForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private agencyTypeServices: AgencyTypeService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateAgencyTypeId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.agencyTypeSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgencyType();
        this.findAllAgencyType();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countAgencyType() {
        this.agencyTypeServices.countAgencyType(this.agencyTypeSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAgencyType();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateAgencyType(this.agencyTypeForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAgencyType();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencyTypeSearch.reset();
        this.countAgencyType();
        // this.findAllAgencyType();
    }

    addUpdateAgencyType(agencyType) {
        this.startLoader();
        if (this.updateAgencyTypeId == null) {
            this.agencyTypeServices.addAgencyType(this.agencyTypeForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    agencyType = res['data'][0];
                    this.agencyTypeList.push(agencyType);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.btnAdd),
                            detail: this.translate(this.labels.entityNameAgencyType)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countAgencyType();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            agencyType.agencyTypeId = this.updateAgencyTypeId;
            this.agencyTypeServices.updateAgencyType(agencyType).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencyTypeList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameAgencyType)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countAgencyType();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencyTypeId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.agencyTypeForm = new FormGroup({
                                                agencyTypeTitle: new FormControl('', [Validators.required, validateSpaces]),
                                                userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                            });
        this.agencyTypeSearch = new FormGroup({agencyTypeTitle: new FormControl(), userId: new FormControl(),});
        this.countAgencyType();
        this.findAllAgencyType();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencyTypeSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllAgencyType() {
        this.startLoader();
        this.agencyTypeServices.findAllAgencyType(this.agencyTypeSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencyTypeList = res['data'];
                } else {
                    this.agencyTypeList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgencyType(agencyTypeId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencyTypeServices.deleteByIdAgencyType(agencyTypeId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.agencyTypeList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.btnDelete),
                                detail: this.translate(this.labels.entityNameAgencyType)
                            });
                        this.countAgencyType();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadAgencyTypeToEdit(agencyTypeId: number, index) {
        let agencyType = this.agencyTypeList.find(x => x['agencyTypeId'] == agencyTypeId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencyTypeId = agencyType['agencyTypeId'];
        this.selectedIndex = index;
        this.agencyTypeForm.controls['agencyTypeTitle'].setValue(agencyType['agencyTypeTitle']);
        this.agencyTypeForm.controls['userId'].setValue(agencyType['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.agencyTypeForm.reset();
        this.agencyTypeForm.markAsUntouched();
        this.updateAgencyTypeId = null;
        closeAddOverlaydiv();
    }

    fieldReset(control) {
        return this.agencyTypeForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencyTypeForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencyTypeForm.get(control)?.valid && this.agencyTypeForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.agencyTypeForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.agencyTypeSearch.reset();
    }

    refresh() {
        this.countAgencyType();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.agencyTypeForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencyTypeForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAgencyTypeId = null;
    }
}
