import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {PaymentsService} from '../web-services/payments.service';
import {InvoiceService} from '../web-services/invoice.service';
import {AgencyService} from '../web-services/agency.service';
import {ClinicsService} from '../web-services/clinics.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { PaymentsDetailComponent } from '../payments-detail/payments-detail.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {SessionService} from '../services/session.service';
import {Utils} from '../util/utils';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
    selector: 'app-payments', 
    templateUrl: './payments.component.html', 
    styleUrls: ['./payments.component.css'],
    providers: [DialogService]
})
export class PaymentsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePaymentId = null;
    invoiceList: any = [];
    agencyList: any = [];
    clinicsList: any = [];
    listOfValuesDetailList: any = [];
    paymentsList: any = [];
    paymentsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    paymentsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    sourcesList : any = [];
    balanceSummaryList : any = [];
    paymentTypeList : any = [];

    loggedInUserType: any;
    loggedInRefId: any;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;

    addEditTitle = '';
    resetTag = true;

    ref: DynamicDialogRef;
    

    constructor(
        private paymentsServices: PaymentsService, 
        private invoiceServices: InvoiceService, 
        private agencyServices: AgencyService,
        private clinicsServices: ClinicsService, 
        private listOfValuesServices: ListOfValuesService,
        private primengConfig: PrimeNGConfig, 
        private confirmation: ConfirmationService, 
        private messageService: MessageService,
        private route: Router, 
        private loader: NgxUiLoaderService, 
        private constantService: ConstantService,
        private sharedService: SharedService,
        private dialogService: DialogService,
        private sessionService: SessionService,
    ) {
    };

    get addUpdateTitle() {
        return this.updatePaymentId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.paymentsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countPayments();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }
    utils = new Utils();
    countPayments() {
        this.paymentsSearch.controls['userId'].setValue(this.loggedInRefId);
        let obj =this.utils.getUpdatedMillissecondObject(this.paymentsSearch.value,"paidOn");
        if(obj['paidOn'] === '0'){
            delete obj['paidOn'];
        }
        this.paymentsServices.countPayments(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllPayments();
            } else {
                this.totalRecords = 0;
                this.paymentsList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {

        this.paymentsForm.markAllAsTouched();
        console.log("submit called form value :: ",this.paymentsForm.value);
        this.paymentsForm.controls['userId'].setValue(this.loggedInRefId);
        if(this.paymentsForm.valid){
            this.addUpdatePayments(this.paymentsForm.value);
            this.submitted = false;
        }
        
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllPayments();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.paymentsSearch.reset();
        this.countPayments();
        // this.findAllPayments();
    }

    addUpdatePayments(payments) {
        this.startLoader();
        let obj =this.utils.getUpdatedMillissecondObject(this.paymentsForm.value,"paidOn");
        if (this.updatePaymentId == null) {
            this.paymentsServices.addPayments(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    payments = res['data'][0];
                    this.paymentsList.push(payments);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNamePayments) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNamePayments)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countPayments();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.paymentId = this.updatePaymentId;
            this.paymentsServices.updatePayments(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.paymentsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNamePayments) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNamePayments)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countPayments();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePaymentId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.paymentsForm = new FormGroup({
                                              invoiceId: new FormControl(),
                                              agencyId: new FormControl(),
                                              paidOn: new FormControl('',[Validators.required]),
                                              paidAt: new FormControl(),
                                              source: new FormControl(0, [Validators.required]),
                                              balanceSummary: new FormControl(0, [Validators.required]),
                                              userId: new FormControl(),
                                              paymentDescription: new FormControl('', [Validators.required, validateSpaces]),
                                              paymentType: new FormControl(0,[Validators.required]),
                                              paymentAmount: new FormControl(0,[Validators.required])
                                          });
        this.paymentsSearch = new FormGroup({
                                                invoiceId: new FormControl(),
                                                agencyId: new FormControl(),
                                                paidOn: new FormControl(),
                                                paidAt: new FormControl(),
                                                source: new FormControl(),
                                                balanceSummary: new FormControl(),
                                                userId: new FormControl(),
                                                paymentDescription: new FormControl(),
                                                paymentType: new FormControl(),
                                                paymentAmount: new FormControl()
                                            });

        this.findAllInvoice();
        this.findAllAgency();
        // this.findAllClinics();
        this.findAllListOfValues();

        this.countPayments();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.paymentsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllPayments() {
        this.startLoader();
        this.paymentsSearch.controls['userId'].setValue(this.loggedInRefId);
        let obj =this.utils.getUpdatedMillissecondObject(this.paymentsSearch.value,"paidOn");
        if(obj['paidOn'] === '0'){
            delete obj['paidOn'];
        }
        this.paymentsServices.findAllPayments(obj, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.paymentsList = res['data'];
                } else {
                    this.paymentsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdPayments(paymentId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.paymentsServices.deleteByIdPayments(paymentId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.paymentsList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNamePayments) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNamePayments)
                            });
                        this.countPayments();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadPaymentsToEdit(paymentId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let payments = this.paymentsList.find(x => x['paymentId'] == paymentId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updatePaymentId = payments['paymentId'];
        this.selectedIndex = index;
        if(payments['invoiceId']){
            this.paymentsForm.controls['invoiceId'].setValue(payments['invoiceId']['invoiceId']);
        }
        if(payments['agencyId']){
            this.paymentsForm.controls['agencyId'].setValue(payments['agencyId']['agencyId']);
        }
        
        if(payments['paidOn']){
            this.paymentsForm.controls['paidOn'].setValue(new Date(payments['paidOn']));
        }

        if(payments['paidAt']){
            this.paymentsForm.controls['paidAt'].setValue(payments['paidOn']);
        }
        
        if(payments['source']){
            this.paymentsForm.controls['source'].setValue(payments['source']['listOfValuesId']);
        }

        if(payments['balanceSummary']){
            this.paymentsForm.controls['balanceSummary'].setValue(payments['balanceSummary']['listOfValuesId']);
        }

        if(payments['userId']){
            this.paymentsForm.controls['userId'].setValue(payments['userId']['userId']);
        }
        
        if(payments['paymentDescription']){
            this.paymentsForm.controls['paymentDescription'].setValue(payments['paymentDescription']);
        }
        
        if(payments['paymentType']){
            this.paymentsForm.controls['paymentType'].setValue(payments['paymentType']['listOfValuesId']);
        }

        if(payments['paymentAmount']){
            this.paymentsForm.controls['paymentAmount'].setValue(payments['paymentAmount']);
        }
        
        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.paymentsForm.reset();
        this.paymentsForm.markAsUntouched();
        this.updatePaymentId = null;
        // closeAddOverlaydiv();
    }

    findAllInvoice() {
        let temp = [];
        this.startLoader();
        this.invoiceServices.findInvoiceByUserId(parseInt(sessionStorage.getItem("loggedInUser")),
        parseInt(sessionStorage.getItem("userActivatedRole"))).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['invoiceId'];
                        dropDown.label = object['invoiceAmount'];
                        this.invoiceList.push(dropDown);
                    }
                } else {
                    this.invoiceList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllListOfValues() {
        let temp = [];
        this.startLoader();
        this.listOfValuesServices.getAllListOfValuesByName(Constant.PAYMENTS_LOV_ITMES).subscribe(result =>{

            console.log("result of Lov values :: ",result);
            
            if(result['statusCode'] == 200){
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];
                    console.log("element of data arrrray :: ",index," index obj :: ",element);
                    
                    if(element[Constant.PAYMENTS_LOV_SOURCE]){
                        console.log("source array :: ",element[Constant.PAYMENTS_LOV_SOURCE]);
                        
                        for (let object of element[Constant.PAYMENTS_LOV_SOURCE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.sourcesList.push(dropDown);
                            console.log("drop down values array :: ",dropDown);
                        }

                    }else if (element[Constant.PAYMENTS_LOV_BALANCE_SUMMARY]){
                        console.log("sex array :: ",element[Constant.PAYMENTS_LOV_BALANCE_SUMMARY]);
                       
                        for (let object of element[Constant.PAYMENTS_LOV_BALANCE_SUMMARY]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.balanceSummaryList.push(dropDown);
                            console.log("drop down values array :: ",dropDown);
                        }
                    }else if (element[Constant.PAYMENTS_LOV_PAYMENT_TYPE]){
                        console.log("payment type array :: ",element[Constant.PAYMENTS_LOV_PAYMENT_TYPE]);
                       
                        for (let object of element[Constant.PAYMENTS_LOV_PAYMENT_TYPE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.paymentTypeList.push(dropDown);
                            console.log("drop down values array :: ",dropDown);
                        }
                    }
                }
            }

        });
            
    }

    fieldReset(control) {
        return this.paymentsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.paymentsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.paymentsForm.get(control)?.valid && this.paymentsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.paymentsForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.paymentsSearch.reset();
    }

    refresh() {
        this.countPayments();
    }

    onAddOverlayDiv() {
        this.paymentsForm.reset();
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue){
        console.log("global search clicked ",eventValue);
        this.searchTerm = eventValue;
        this.paymentGlCount();
    }

    paymentGlCount(){
        this.paymentsServices.paymentsGlcount(this.searchTerm,this.loggedInRefId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchPayments();
            } else {
                this.totalRecords = 0;
                this.paymentsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchPayments(){
        this.paymentsServices.findGlobalPayments(this.searchTerm,this.loggedInRefId, this.pageNo, this.itemPerPage).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.paymentsList = result['data'];
            }else{
                this.paymentsList = [];
            }
            console.log(result,"result");
        });
    }

    addPaymentDetail($event,element){
        this.ref = this.dialogService.open(PaymentsDetailComponent, {
            header: 'Payment Detail',
            width: '90%',
            contentStyle: {'max-height': '500px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {
                paymentObj:element
            }
        });

        console.log(this.ref, 'onclose reference');
        this.ref.onClose.subscribe(res => {
            console.log(' before confirmation closing');
            this.confirmation.confirm(null).close();
            this.countPayments();
        });
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.paymentsSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog){
            this.paymentsForm.reset();
        }
        this.addEditTitle = 'Add';
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePaymentId = null;
    }
    
    calculateRemainigAmount(element){
        
        if(element.paidAmount == 0){
            return 0;
        }else{
            return element.paymentAmount - element.paidAmount;
        }
    }
}
