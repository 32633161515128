import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencyCompanyService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgencyCompany(agencyCompanyId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyCompany/deleteAgencyCompany', {'agencyCompanyId': agencyCompanyId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAgencyCompany(agencyCompany): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'AgencyCompany/count', {'agencyCompany': agencyCompany}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateAgencyCompany(agencyCompany): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyCompany/updateAgencyCompany', [agencyCompany], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addAgencyCompany(agencyCompany): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyCompany/addAgencyCompany', [agencyCompany], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAgencyCompany(agencyCompany, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'AgencyCompany/findAllAgencyCompany',
            {'agencyCompany': agencyCompany, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
