import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Constant } from '../util/constant';

@Injectable({
  providedIn: 'root'
})
export class ImageFileUploadService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient) {
  };


  deleteByIdImage(imageFileUploadId): Observable<string> {
    return this.httpClient.post<string>(Constant.BASE_URL + 'ImageFileUpload/deleteImageFileUpload', {'imageFileUploadId':imageFileUploadId }, this.httpOptions).pipe(
        retry(3), catchError(Constant.handleError));
}

countImageFile(data): Observable<number> {
    return this.httpClient.post<number>(Constant.BASE_URL + 'ImageFileUpload/count', {data:data}, this.httpOptions).pipe(
        retry(3), catchError(Constant.handleError));
}

updateImageFile(data): Observable<string> {
    return this.httpClient.post<string>(Constant.BASE_URL + 'ImageFileUpload/updateImageFile', [data], this.httpOptions).pipe(
        retry(3), catchError(Constant.handleError));
}

addImageFile(data): Observable<string> {

    return this.httpClient.post<string>(Constant.BASE_URL + 'ImageFileUpload/addImageFileUpload', [data], this.httpOptions).pipe(
        retry(3), catchError(Constant.handleError));
}

findAllImages(data, pageNo: number, itemsPerPage: number): Observable<string[]> {
    return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'ImageFileUpload/findAllImageFileUpload', {'imageFileUpload': data, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
        this.httpOptions
    ).pipe(
        retry(3), catchError(Constant.handleError));
}

}
