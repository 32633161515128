<!-- Header Component-->
<!-- <p-toast position="top-center"></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameExpensesDetail)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button pButton type="button" icon="pi pi-search-plus" pTooltip="Advanced Search"
                                        tooltipPosition="bottom"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        class="p-button-rounded p-button-text"></button>
                                <button pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-text"
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameExpensesDetail)}}"  tooltipPosition="bottom"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="dialogOverlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameExpensesDetail)}}
                                </p-header>
                                <form class="pngForm" id="invitationpage-searchmemeber-pngForm"
                                    [formGroup]="expensesDetailSearch" (ngSubmit)="onSearch()">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnItemId)}}</label>
                                            <p-dropdown [filter]="true" [options]="expense_items_array"
                                                [showClear]="true" class="pngDropdown" formControlName="itemId"
                                                placeholder="Select">
                                            </p-dropdown>
                                        </div>
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnQuantity)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="quantity"
                                                name="quantity" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"> </p-inputNumber>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnUnitPrice)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="unitPrice"
                                                name="unitPrice" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"> </p-inputNumber>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnDiscount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="discount"
                                                name="discount" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"> </p-inputNumber>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnTaxAmount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="taxAmount"
                                                name="taxAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"> </p-inputNumber>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnTotalAmount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="totalAmount"
                                                name="totalAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"> </p-inputNumber>
                                        </div> -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnDescription)}}</label> <span
                                                class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                    (click)="fieldReset('description')"
                                                    *ngIf="diplayeIconInField('description')"></i> <input pInputText
                                                    type="text" name="description" formControlName="description"
                                                    class="pngInputtext"> </span>
                                        </div>
                                        
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" type = "submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" styleClass="p-button" class="pngBtnTransparent"
                                                  (click)="showHideSearchDialog()">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
 <!-- Add dialogBox -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="dialogOverlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header> {{addEditTitle}} {{translate(labels.entityNameExpensesDetail)}}
                                </p-header>
                                <form class="pngForm" id="invitationpage-addmemeber-pngForm"
                                    [formGroup]="expensesDetailForm" (ngSubmit)="onSubmit()">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                       
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnItemId)}}</label>
                                            
                                            <p-dropdown [filter]="true" [options]="expense_items_array"
                                                [showClear]="true" class="pngDropdown" formControlName="itemId"
                                                placeholder="Select">
                                            </p-dropdown>

                                            <app-custom-error [form]="expensesDetailForm" [control]="'itemId'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnQuantity)}}</label>
                                            <p-inputNumber (focusout)="calculateAmount()" type="number" pNumberInput formControlName="quantity"
                                                name="quantity" class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('quantity')" [useGrouping]="false">
                                            </p-inputNumber>
                                            <app-custom-error [form]="expensesDetailForm" [control]="'quantity'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnUnitPrice)}}</label>
                                            <p-inputNumber (focusout)="calculateAmount()" type="number" pNumberInput formControlName="unitPrice"
                                                name="unitPrice" class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('unitPrice')" [useGrouping]="false">
                                            </p-inputNumber>
                                            <app-custom-error [form]="expensesDetailForm" [control]="'unitPrice'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnDiscount)}}</label>
                                            <p-inputNumber (focusout)="calculateAmount()" type="number" pNumberInput formControlName="discount"
                                                name="discount" class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('discount')" [useGrouping]="false">
                                            </p-inputNumber>
                                            <app-custom-error [form]="expensesDetailForm" [control]="'discount'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnTaxAmount)}}</label>
                                            <p-inputNumber (focusout)="calculateAmount()" type="number" pNumberInput
                                                formControlName="taxAmount" name="taxAmount"
                                                class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('taxAmount')" [useGrouping]="false">
                                            </p-inputNumber>
                                            <app-custom-error [form]="expensesDetailForm" [control]="'taxAmount'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnTotalAmount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="totalAmount"
                                                name="totalAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('totalAmount')"
                                                [useGrouping]="false" [disabled]="true"> </p-inputNumber>
                                            <app-custom-error [form]="expensesDetailForm" [control]="'totalAmount'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesDetailColumnDescription)}}</label> <span
                                                class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                    (click)="fieldReset('description')"
                                                    *ngIf="diplayeIconInField('description')"></i> <input pInputText
                                                    type="text" name="description"
                                                    [class.p-invalid]="inputFieldDanger('description')" pInputText
                                                    formControlName="description" class="pngInputtext"></span>
                                            <app-custom-error [form]="expensesDetailForm" [control]="'description'">
                                            </app-custom-error>
                                        </div>
                                        
                                    </div>
                                   
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" class="pngBtnTransparent" styleClass="p-button" (click)="showHideAddDialog()">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
  <!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)= "onGlobalSearchClick(global.value)"></i>
                                        
                                        <input #global
                                            pInputText type="text" class="pngInputtext"
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}' /></span>

                                </p-header>
                                <p-table  *ngIf="totalRecords > 0" [value]="expensesDetailList" #dv [lazy]="true"
                                    (onLazyLoad)="onPageChnage($event)" [resizableColumns]="true"
                                    [rowsPerPageOptions]="[10,20,30]" [paginator]="true" [rows]="itemPerPage"
                                    [autoLayout]="true" [totalRecords]="totalRecords"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [showCurrentPageReport]=true id="invitationpage-memberslist-table" class="pngTable payments-detail-table"
                                    styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <!-- <th> ExpensesId </th> -->
                                            <th> Item </th>
                                            <th> Quantity </th>
                                            <th> Unit Price </th>
                                            <th> Discount </th>
                                            <th> Tax Amount </th>
                                            <th> Total Amount </th>
                                            <th> Description </th>
                                            <!-- <th> UserId </th> -->
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-element let-i="rowIndex">
                                        <tr>
                                            <!-- <td> {{element?.expensesId.expensesId}} </td> -->
                                            <td> {{element?.itemId?.label}} </td>
                                            <td> {{element?.quantity}} </td>
                                            <td> {{element?.unitPrice}} </td>
                                            <td> {{element?.discount}} </td>
                                            <td> {{element?.taxAmount}} </td>
                                            <td> {{element?.totalAmount}} </td>
                                            <td> {{element?.description}} </td>
                                            <!-- <td> {{element?.userId}} </td> -->
                                            <td>

                                                <div class="pngTable-icons">
                                                    <p-button pTooltip="Edit" icon="pi pi-pencil"
                                                        (click)="loadExpensesDetailToEdit(element.expensesDetailId, i)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button   pTooltip="Delete"
                                                                icon="pi pi-trash"
                                                                (click)="deleteByIdExpensesDetail(element.expensesDetailId,i)"
                                                                styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <p-button pTooltip="View" icon="pi pi-eye"
                                                        (click) = "viewExpenseDetail(element.expensesDetailId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>