<ngx-ui-loader></ngx-ui-loader>
<style type="text/css">
  .customTitleBar > .ui-panel-titlebar {
    background: #BBBBBB;
  }
</style>
<section class="sectionmain">
  <div class="card-cpa-rounded-20 background-white-m-30">
    <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
             [readonly]="false">
    </p-steps>
  </div>

  <div class="p-row initial-assessment-container">

    <div class="p-col-12">
      <div class="row">
        <div class="p-col-3 m-5">

          <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
        </div>
      </div>
      <div class="p-col-8 m-5">
        <p-card styleClass="card-cpa-rounded card-shadow">
      <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">

            <p-panel class="pngPanelMain" id="patients-pngPanelMain">


                <div class="row">
                  <div class="col-md-6">
                    <h5>Differential Diagnosis</h5>
                  </div>

                  <div class="col-md-6">

                  </div>

                </div>
                <div class="row" *ngFor="let cdp of caseDiagnosisPredictionsList" (click)="diagnosisSelect(cdp.diagnosisId)" style="height: 300px!important;">
                  <div class="col-md-10">
                    <div class=" diagnosis-card" [ngClass]="{'selected-diagnosis' : cdp.diagnosisId == selectedPredictedDiagnosis}"  >
                        <span style="width: 60%;">
                          <h4 class="card-title">{{cdp.diagnosisName}}</h4>
                        </span>

                      <span>
                         <circle-progress
                           [percent]="85"
                           [radius]="50"
                           [outerStrokeWidth]="16"
                           [innerStrokeWidth]="8"
                           [outerStrokeColor]="'#78C000'"
                           [innerStrokeColor]="'#C7E596'"
                           [animation]="true"
                           [animationDuration]="400"
                         ></circle-progress>
                      </span>

                    </div>

                  </div>


                </div>

                <div class="row" *ngFor="let cdp of caseDiagnosisPredictionsList" (click)="diagnosisSelect(999)" style="height: 300px!important;">
                  <div class="col-md-10">
                    <div class=" diagnosis-card" [ngClass]="{'selected-diagnosis' : 999 == selectedPredictedDiagnosis}"  >
                        <span style="width: 60%;">
                          <h4 class="card-title">Subacromial Impingement</h4>
                        </span>

                      <span>
                         <circle-progress
                           [percent]="55"
                           [radius]="50"
                           [outerStrokeWidth]="16"
                           [innerStrokeWidth]="8"
                           [outerStrokeColor]="'#78C000'"
                           [innerStrokeColor]="'#C7E596'"
                           [animation]="true"
                           [animationDuration]="400"
                         ></circle-progress>
                      </span>

                    </div>

                  </div>


                </div>

                <div class="row" *ngFor="let cdp of caseDiagnosisPredictionsList" (click)="diagnosisSelect(9999)" style="height: 300px!important;">
                <div class="col-md-10">
                  <div class=" diagnosis-card" [ngClass]="{'selected-diagnosis' : 9999 == selectedPredictedDiagnosis}"  >
                        <span style="width: 60%;">
                          <h4 class="card-title">Adhesive Capsulitis</h4>
                        </span>

                    <span>
                         <circle-progress
                           [percent]="25"
                           [radius]="50"
                           [outerStrokeWidth]="16"
                           [innerStrokeWidth]="8"
                           [outerStrokeColor]="'#78C000'"
                           [innerStrokeColor]="'#C7E596'"
                           [animation]="true"
                           [animationDuration]="400"
                         ></circle-progress>
                      </span>

                  </div>

                </div>


              </div>


                <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">



                  <form (ngSubmit)="onSubmit()" [formGroup]="diagnosisForm" class="pngForm"
                        id="invitationpage-addmemeber-pngForm">
                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">


                      <div class="p-field p-col-12 formfieldheight">
                        <label>{{translate(labels.entityNamePatientDiagnosis)}}</label>

                        <p-dropdown [filter]="true"
                                    [options]="diagnosisList"
                                    [showClear]="true"
                                    optionLabel="diagnosisName"
                                    optionValue="diagnosisId"
                                    class="pngDropdown"
                                    formControlName="diagnosis"
                                    placeholder="Select">
                        </p-dropdown>
                        <app-custom-error [control]="'diagnosis'" [form]="diagnosisForm"></app-custom-error>
                      </div>
                      <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                  type="submit"
                                  styleClass="p-button"
                                  class="pngBlueBtn">
                        </p-button>
                        <p-button [label]="labels.btnClose"
                                  (click)="showHideAddDialog()"
                                  styleClass="p-button"
                                  class="pngBtnTransparent">
                        </p-button>
                      </div>
                    </div>
                  </form>
                </p-panel>

              <!-- Table Data -->

            </p-panel>

      </section>
        </p-card>
      </div>
    </div>
    </div>

</section>
