
<!--<app-app-header></app-app-header>-->

<section class="sectionmain client-history-appointment" id="sectionmain">
    <div class="container">
        <p-card header="" styleClass="p-card-shadow">
            <p-tabView class="appointments-tabview">

                <p-tabPanel header="Appointments" headerStyleClass="h3-style">
                    <app-appointments></app-appointments>
                </p-tabPanel>

<!--                <p-tabPanel header="Assesments" headerStyleClass="h3-style ">-->
<!--&lt;!&ndash;                    <app-appointments></app-appointments>&ndash;&gt;-->
<!--                </p-tabPanel>-->

<!--                <p-tabPanel header="Notes" headerStyleClass="h3-style">-->
<!--&lt;!&ndash;                    <app-appointments></app-appointments>&ndash;&gt;-->
<!--                </p-tabPanel>-->

<!--                <p-tabPanel header="Exercise Prescription" headerStyleClass="h3-style">-->
<!--&lt;!&ndash;                    <app-appointments></app-appointments>&ndash;&gt;-->
<!--                </p-tabPanel>-->

            </p-tabView>
        </p-card>

    </div>
</section>
