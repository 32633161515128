
<div class="card-cpa-rounded-20 background-white-m-30">
  <p-steps [model]="stepsItems"  [(activeIndex)]="activeStep"
           [readonly]="false" (activeIndexChange)="urlChanged($event)">
  </p-steps>
</div>
<div class="p-row initial-assessment-container" style="height:auto">

  <div class="p-col-12">
    <div class="row">
      <div class="p-col-3 m-5">

        <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>


      </div>
    </div>

    <div class="row">

      <div class="p-col-11 m-5">
        <p-card styleClass="card-cpa-rounded card-shadow">
          <p-tabView>
            <p-tabPanel header="Review">

              <div class="row" style="margin:20px">
                <div class="col-xl-12">

                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <h2 class="card-title">{{baseComponent.translate(baseComponent.labels.entityNameFollowUp)}}</h2>
                      <button *ngIf="!reviewData.isCompleted" class="btn-success" (click)="markInitialAssessmentAsComplete()">Mark Follow Up As Completed</button>
                    </div>
                    <div class="card-body pb-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Date:</strong>
                          <span class="mb-0"> {{reviewData.followUp.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Consent:</strong>
                          <span> {{reviewData.followUp.consent.value}} </span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Percentage Improvement:</strong>
                          <span> {{reviewData.followUp.percentageImprovement}} </span>
                        </li>
                      </ul>


                    </div>
                  </div>
                </div>
              </div>


              <div class="row" style="margin:20px">
                <div class="col-xl-12">

                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <h2 class="card-title">{{baseComponent.translate(baseComponent.labels.entityNameBodyChartMaster)}}</h2>
                    </div>
                    <div class="card-body pb-0">

                      <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Date:</strong>
                          <span class="mb-0"> {{reviewData.bodyChartMaster.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <img class="bodychart-img-front" [src]="reviewData.bodyChartMaster.frontAnatomyImageUrl" width="200px" height="300px">
                          <img class="bodychart-img-front" [src]="reviewData.bodyChartMaster.backAnatomyImageUrl" width="200px" height="300px">
                          <img class="bodychart-img-front" [src]="reviewData.bodyChartMaster.skeletonAnatomyImageUrl" width="200px" height="300px">

                        </li>
                      </ul>
                      <ul class="list-group list-group-flush" *ngFor="let bcd of reviewData.bodyChartDetails">
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <div class="card-header border-0 pb-0">
                            <h5 class="card-title">{{bcd.bodyChartAnatomiesDiagram.bodyChartAnatomyDiagramName}}</h5>
                          </div>

                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Pain Score:</strong>
                          <span> {{bcd.bodyChartAnatomyPainScore}} </span>
                        </li>
                      </ul>


                    </div>

                  </div>
                </div>
              </div>




              <div class="row" style="margin:20px">
                <div class="col-xl-12">

                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <h2 class="card-title">{{baseComponent.translate(baseComponent.labels.entityNameTestingArom)}}</h2>
                    </div>
                    <div class="card-body pb-0">
                      <ul class="list-group list-group-flush" *ngFor="let arom of reviewData.testingAroms">
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Date:</strong>
                          <span class="mb-0"> {{arom.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Joint:</strong>
                          <span> {{arom.joint}} </span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Side:</strong>
                          <span> {{arom.aromSide}} </span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Movement:</strong>
                          <span *ngFor="let movement of arom.movement" style="display: block;"> {{movement.lovValues}} </span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Measures:</strong>
                          <span *ngFor="let measures of arom.measures" style="display: block;"> {{measures.lovValues}} </span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Limiting Factors:</strong>
                          <span *ngFor="let limitingFactors of arom.limitingFactors" style="display: block;"> {{limitingFactors.lovValues}} </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="margin:20px">
                <div class="col-xl-12">

                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <h2 class="card-title">{{baseComponent.translate(baseComponent.labels.entityNameTestingNeuro)}}</h2>
                    </div>
                    <div class="card-body pb-0">
                      <ul class="list-group list-group-flush" *ngFor="let testingNeuros of reviewData.testingNeuro">
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Date:</strong>
                          <span class="mb-0"> {{testingNeuros.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Dermatome:</strong>
                          <span class="mb-0"> {{testingNeuros.dermatome }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Dermatome Side:</strong>
                          <span class="mb-0"> {{testingNeuros.dermatomeSide }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Dermatome Comments:</strong>
                          <span class="mb-0"> {{testingNeuros.dermatomeComments }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Reflexes Side:</strong>
                          <span class="mb-0"> {{testingNeuros.reflexesSide }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Dermatome Description:</strong>
                          <span *ngFor="let dermatomeDescription of testingNeuros.dermatomeDescription" style="display: block;"> {{dermatomeDescription.lovValues}} </span>
                        </li>


                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Reflexes:</strong>
                          <span *ngFor="let reflexes of testingNeuros.reflexes" style="display: block;"> {{reflexes.lovValues}} </span>
                        </li>


                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Reflexes Description:</strong>
                          <span *ngFor="let reflexesDescription of testingNeuros.reflexesDescription" style="display: block;"> {{reflexesDescription.lovValues}} </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="margin:20px">
                <div class="col-xl-12">

                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <h2 class="card-title">{{baseComponent.translate(baseComponent.labels.entityNameTestingSpecial)}}</h2>
                    </div>
                    <div class="card-body pb-0 m-2" *ngFor="let testingSpecials of reviewData.testingSpecial">
                      <ul class="list-group list-group-flush" >
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Date:</strong>
                          <span class="mb-0"> {{testingSpecials.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Special Tests Performed:</strong>
                          <span *ngFor="let testType of testingSpecials.testType" style="display: block;"> {{testType.lovValues}} </span>
                        </li>


                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Side:</strong>
                          <span class="mb-0"> {{testingSpecials.side }}</span>
                        </li>
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Comments:</strong>
                          <span class="mb-0"> {{testingSpecials.testComments }}</span>
                        </li>


                      </ul>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row" style="margin:20px">
                <div class="col-xl-12">

                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <h2 class="card-title">{{baseComponent.translate(baseComponent.labels.entityNameTestingMuscle)}}</h2>
                    </div>
                    <div class="card-body pb-0 m-2" *ngFor="let testingMuscles of reviewData.testingMuscles">
                      <ul class="list-group list-group-flush" >
                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Date:</strong>
                          <span class="mb-0"> {{testingMuscles.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Muscle Group:</strong>
                          <span class="mb-0"> {{testingMuscles.muscleGroup }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Muscle Side:</strong>
                          <span class="mb-0"> {{testingMuscles.muscleSide }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Measures:</strong>
                          <span class="mb-0"> {{testingMuscles.measures }}</span>
                        </li>

                        <li class="list-group-item d-flex px-0 justify-content-between">
                          <strong>Comments:</strong>
                          <span class="mb-0"> {{testingMuscles.comment }}</span>
                        </li>


                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Exercises">
              <div class="row" style="margin:20px">
                <div class="col-xl-6">
                  <h4 class="p-panel-header">Cable Machine</h4>
                  <video id="player" playsinline controls width="400px" height="300px">
                    <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/6-Cable%20machine%20rear%20deltoid%20row.mp4" type="video/mp4" />

                    <!-- Captions are optional -->
                  </video>
                </div>
                <div class="col-xl-6">
                  <h4 class="p-panel-header">Shoulder Internal</h4>

                  <video id="player" playsinline controls  width="400px" height="300px">
                    <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/49-Shoulder%20internal%20and%20external%20rotation%20supine%20with%20weight.mp4" type="video/mp4" />

                    <!-- Captions are optional -->
                  </video>
                </div>
              </div>

              <div class="row" style="margin:20px">
                <div class="col-xl-6 ">
                  <h4 class="p-panel-header">Scapular Retraction</h4>
                  <video id="player" playsinline controls  width="400px" height="300px">
                    <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/42-Scapular%20retraction_protraction%20supine%20arm%20with%20weight.mp4" type="video/mp4" />

                    <!-- Captions are optional -->
                  </video>
                </div>
                <div class="col-xl-6 ">
                  <h4 class="p-panel-header">Posture and Scapula</h4>

                  <video id="player" playsinline controls  width="400px" height="300px">
                    <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/27-Posture%20and%20scapula%20stability%20against%20wall.mp4" type="video/mp4" />

                    <!-- Captions are optional -->
                  </video>
                </div>
              </div>


              <div class="row" style="margin:20px">
                <div class="col-xl-6">
                  <h4 class="p-panel-header">Prone GHJt</h4>

                  <video id="player" playsinline controls  width="400px" height="300px">
                    <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/28-Prone%20GHjt%20ER%2890%29%20with%20weight.mp4" type="video/mp4" />

                    <!-- Captions are optional -->
                  </video>
                </div>
                <div class="col-xl-6 ">
                  <h4 class="p-panel-header">Shoulder Abduction</h4>

                  <video id="player" playsinline controls  width="400px" height="300px">
                    <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/44-Shoulder%20abduction%20with%20weight%20%2890%20degrees%29.mp4" type="video/mp4" />

                    <!-- Captions are optional -->
                  </video>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Treatment">
              Strengthening Exercises for Rotator Cuff and Scapula<br>
              Advice & Education
            </p-tabPanel>
            <p-tabPanel header="Advice Leaflets">
              <div>Your advice leaflets are below please use as part of your treatment plan</div>
              <div class="row m-5">
                <div class="col-md-12">
                <span style="margin-right:20px!important;">
              <a target="_blank" href="https://bodychart.fra1.digitaloceanspaces.com/videos/advice/120149_Rotator_cuff_tear%20NHS.pdf">
                Click here to view
                <i class="fa fa-2x fa-file-pdf-o" style="color:red"></i></a>
          </span>

                  <span>

              <a target="_blank" href="https://bodychart.fra1.digitaloceanspaces.com/videos/advice/Non-surg.-RC-tear.pdf">
                 Click here to view
                <i class="fa fa-2x fa-file-pdf-o" style="color:red"></i></a>
          </span>
                </div>

              </div>


            </p-tabPanel>
          </p-tabView>

        </p-card>
      </div>
    </div>
  </div>
</div>
