import {AfterViewInit, Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { PatientsService } from '../web-services/patients.service';
import { UsersService } from '../web-services/users.service';
import { ListOfValuesDetailService } from '../web-services/list-of-values-detail.service';
import { CompanyService } from '../web-services/company.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';
import { AgencyService } from '../web-services/agency.service';
import {SharedService} from '../web-services/shared.service';
import {SessionService} from '../services/session.service';
import { ValidationService } from '../services/validation.service';
import {DatePipe} from '@angular/common';
import {Utils} from '../util/utils';
import {SubscriptionService} from '../web-services/subscription.service';
import {ChatService} from '../collaboration/services/chat/chat.service';
import {HttpStatusCode} from '@angular/common/http';
import {HttpPatchItem} from '../util/HttpPatchItem';
import {HttpPatch} from '../util/HttpPatch';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
    selector: 'app-patients',
    templateUrl: './patients.component.html',
    styleUrls: ['./patients.component.css'],
    providers: [DialogService]
})
export class PatientsComponent implements OnInit, AfterViewInit {
    items: MenuItem[];
    public labels = Labels;
    subscriptionViewModal: boolean;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePatientId = null;
    duplicateEmail = false;
    usersList: any = [];
    listOfValuesDetailList: any = [];
    companyList: any = [];
    patientsList: any = [];
    patientsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    patientsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    currentLoggedInAgencyId : number = 0;
    clientId: number;
    sex_array : any = [];
    gender_array : any = [];
    conscent_array : any = [];
    comm_prefs_array : any = [];
    marketing_array : any = [];
    billing_array : any = [];
    cons_type_array : any = [];
    invoice_array : any = [];
    ref_source_array : any = [];
    loggedInUserType: any;
    loggedInRefId: any;
    title: any;
    patientTypeList: any = [];
    showActiveDeactive: any;
    checklistIndex: any;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;
    hideActiveDeactive = true;

    userSubscriptionList: any = [];
    subscriptionSearch: FormGroup;
    patientByProfessionTag = false;
    manageOptions = [
        {label: 'Required', value: 'Active'},
        {label: 'Not Required', value: 'Inactive'}
    ];

  currentMessage = new BehaviorSubject(null);
  fcmToken: string;



    constructor(
        private patientsServices: PatientsService, private usersServices: UsersService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private companyServices: CompanyService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private router: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovService : ListOfValuesService,
        private dialogService: DialogService,
        private agencyService : AgencyService,
        private sessionService: SessionService,
        private lovServices: ListOfValuesService,
        private _validation : ValidationService,
        private subscriptionService: SubscriptionService,
        private chatService: ChatService,
        private afMessaging: AngularFireMessaging
    ) {

      this.afMessaging.requestPermission
        .pipe(take(1))
        .subscribe(() => {
          console.log('Permission granted!');
          this.afMessaging.getToken
            .pipe(take(1))
            .subscribe((token) => {
              this.fcmToken = token;
              console.log('FCM token:', this.fcmToken);
            });
        });





    };
    ref: DynamicDialogRef;

    getUserId(userId){
        this.userId = userId;
    }



    ngOnInit() {
        this.items = [
            {
                label: 'Manage Subscription',
                icon: 'manage-subs-icon',
                // url: 'assets/iamges/manage-subscribtion.png',
                command: () => {
                    this.getUserSubscriptionList(this.userId)
                }
            },
        ]

      this.afMessaging.messages.subscribe(
        (message) => {

          console.log('Received message:', message);
          this.currentMessage.next(message);

        },
        (error) => {
          console.error(" messafge " + error);
        }
      );




      this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.showActiveDeactive = false;
        this.hideActiveDeactive = true;
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.clientId = parseInt(this.sessionService.getSession('clientId'));

        if (this.router.url.includes('/physiotherapy_patients')) {
            this.title = Constant.PATIENTS_STATUS_PHYSIOTHERAPY;
        } else if (this.router.url.includes('/psychology_patients')) {
            this.title = Constant.PATIENTS_STATUS_PSYCHOLOGY;
        }else if (this.router.url.includes('/active_patient')) {
            this.title = Constant.PATIENTS_STATUS_ACTIVE;
            // this.showActiveDeactive = true;
            this.hideActiveDeactive = false;
        }else if (this.router.url.includes('/deactivated_patient')) {
            this.title = Constant.PATIENTS_STATUS_DEACTIVATED;
            // this.showActiveDeactive = true;
            this.hideActiveDeactive = false;
        }else if (this.router.url.includes('/patient_by_profession')) {
            this.title = Constant.PATIENTS_BY_PROFESSION;
            this.patientByProfessionTag = true;
        }
        this.patientsForm = new FormGroup({
                                              userId: new FormControl(),
                                              clientId: new FormControl(),
                                              firstName: new FormControl('', [Validators.required, validateSpaces]),
                                              lastName: new FormControl('', [Validators.required, validateSpaces]),
                                              dob: new FormControl('', [Validators.required]),
                                              sex: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              genderIdentity: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              occupation: new FormControl('', [Validators.required, validateSpaces]),
                                              emergencyContact: new FormControl('', [Validators.required, validateSpaces]),
                                              consent: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              email: new FormControl('', [Validators.required, validateSpaces,Validators.pattern(this._validation.regex.email)]),
                                              address: new FormControl('', [Validators.required, validateSpaces]),
                                              commPrefs: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              marketingInfo: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              billing: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              patientType: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              conscessionType: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              invoice: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              invoiceInfo: new FormControl('', [Validators.required, validateSpaces]),
                                              insuranceNumber: new FormControl('', [Validators.required, validateSpaces]),
                                              uniqueRefNumber: new FormControl('', [Validators.required, validateSpaces]),
                                              referralSource: new FormControl(0, [Validators.required, Validators.min(0)]),
                                              userEmail : new FormControl('', [Validators.required, validateSpaces,Validators.pattern(this._validation.regex.email)]),
                                              password : new FormControl('', [Validators.required, validateSpaces])
                                          });

        this.patientsSearch = new FormGroup({
                                                userId: new FormControl(),
                                                firstName: new FormControl(),
                                                lastName: new FormControl(),
                                                dob: new FormControl(),
                                                sex: new FormControl(),
                                                genderIdentity: new FormControl(),
                                                occupation: new FormControl(),
                                                emergencyContact: new FormControl(),
                                                consent: new FormControl(),
                                                email: new FormControl(),
                                                address: new FormControl(),
                                                commPrefs: new FormControl(),
                                                marketingInfo: new FormControl(),
                                                billing: new FormControl(),
                                                patientType: new FormControl(),
                                                conscessionType: new FormControl(),
                                                invoice: new FormControl(),
                                                invoiceInfo: new FormControl(),
                                                insuranceNumber: new FormControl(),
                                                uniqueRefNumber: new FormControl(),
                                                referralSource: new FormControl(),
                                                isSystemUser: new FormControl(),
                                                patientUserId: new FormControl(),
                                                companyId: new FormControl(),
                                                agencyId: new FormControl(),
                                                status: new FormControl(),
                                                userEmail : new FormControl(),
                                                password : new FormControl()
                                            });
        this.findCurrentAgency();
        this.findAllListOfValuesDetail();
        this.getAllLov();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.patientsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
        this.patientsSearch.reset();
    }

    ngAfterViewInit() {
        this.patientsSearch.reset();
        this.selectedIndex = -1;
        console.log('After view init called');
        console.log(this.patientsSearch, 'array after view initialized');
    }

    showHidePassword(){
        if (this.passwordType === 'text') {
            this.passwordType = 'password';
        } else {
            this.passwordType = 'text';
        }
    }

    async findCurrentAgency(){
        let data = {"userId":parseInt(sessionStorage.getItem('loggedInUser'))};
       await this.agencyService.findAllAgency(data,0,10).toPromise().then(result => {
            if(result['statusCode'] == 200 ){
                this.currentLoggedInAgencyId = parseInt(result['data'][0]['agencyId']);
                this.patientsSearch.get('agencyId').setValue(this.currentLoggedInAgencyId);
                console.log("agency id : ",this.currentLoggedInAgencyId);
            }
        });
    }
    get addUpdateTitle() {
        return this.updatePatientId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.patientsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.findAllPatientsByClientId();
        // this.findAllPatients();
        // closeSearchOverlaydiv();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }
    utils = new Utils();
    countPatients() {
        if(this.title && this.title === Constant.PATIENTS_STATUS_PHYSIOTHERAPY){
            let result = this.patientTypeList.find(element => element.label === Constant.PATIENTS_STATUS_PHYSIOTHERAPY);
            this.patientsSearch.controls['patientType'].setValue(result.value);
        } else if(this.title && this.title === Constant.PATIENTS_STATUS_PSYCHOLOGY){
            let result = this.patientTypeList.find(element => element.label === Constant.PATIENTS_STATUS_PSYCHOLOGY);
            this.patientsSearch.controls['patientType'].setValue(result.value);
        } else if(this.title && this.title === Constant.PATIENTS_STATUS_ACTIVE){
            this.patientsSearch.controls['status'].setValue(this.title);
        } else if(this.title && this.title === Constant.PATIENTS_STATUS_DEACTIVATED){
            this.patientsSearch.controls['status'].setValue(this.title);
        }
        let obj =this.utils.getUpdatedMillissecondObject(this.patientsSearch.value,"dob");
        if(obj['dob'] === '0'){
            delete obj['dob'];
        }
        this.patientsServices.countPatients(obj,this.loggedInUserType,this.loggedInRefId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllPatientsByClientId();
            } else {
                this.totalRecords = 0;
                this.patientsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        // temporary commenting for testing
       /// console.log("patients form value :: ", this.patientsForm.value);
        this.patientsForm.controls['clientId'].setValue(this.clientId);
        this.patientsForm.markAllAsTouched();

       // if (this.patientsForm.valid) {
            this.addPatient();
            this.submitted = false;
            this.onClearAdd();
        //}

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllPatientsByClientId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.patientsSearch.reset();
       /// this.countPatients();
    }

  addPatient(): void {

    if (this.updatePatientId == null) {

      this.patientsServices.addPatient(this.patientsForm.value).subscribe(res => {

          if (res.status === HttpStatusCode.Created) {

            this.messageService.add(
              {
                severity: 'success',
                summary: this.translate(this.labels.entityNamePatients) +
                  this.translate(this.labels.addedSuccessfully),
                // detail: this.translate(this.labels.entityNamePatients)
              });

            this.showHideAddDialog();
            this.submitted = true;
            this.refresh();


          }


        },
        err => {

          this.messageService.add({
            severity: 'error',
            summary: err,
            detail: ''
          });

        });

    } else {

      const updateFieldsToExclude = ['userId', 'clientId', 'userEmail', 'password'];

      let httpPatch = new HttpPatch();

      Object.keys(this.patientsForm.controls).forEach(key => {

        if (!updateFieldsToExclude.includes(key)) {

          httpPatch.pushToJsonPatch(new HttpPatchItem('replace', '/' + key, this.patientsForm.get(key).value));

        }
      });

      this.patientsServices.updatePatientPatched(httpPatch.jsonPatch, this.updatePatientId).subscribe(res => {

          if (res.status === HttpStatusCode.NoContent) {


            this.messageService.add(
              {
                severity: 'success',
                summary: this.translate(this.labels.entityNamePatients) +
                  this.translate(this.labels.updatedSuccessfully),
              });

            this.showHideAddDialog();
            this.submitted = true;

          }

        },
        err => {

          this.messageService.add({
            severity: 'error',
            summary: err,
            detail: ''
          });

        });
    }
  }

    addUpdatePatients(patients) {
        this.startLoader();
        this.duplicateEmail =  false;

        let obj =this.utils.getUpdatedMillissecondObject(this.patientsForm.value,"dob");
        if (this.updatePatientId == null) {
            // this.patientsForm.controls['status'].setValue(Constant.PATIENTS_STATUS_ACTIVE);
            obj.status = Constant.PATIENTS_STATUS_ACTIVE;
            this.patientsServices.addPatients(obj,this.loggedInUserType,this.loggedInRefId).subscribe(res => {
                if(res['documentation'] == Constant.NOT_UNIQUE_EMAIL){
                    this.duplicateEmail =  true;
                    this.stopLoader();
                    return;
                }else if (res['message'] == 'Success') {
                    patients = res['data'][0];
                    this.patientsList.push(patients);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNamePatients) +
                                     this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNamePatients)
                        });

                    // Register Event on user creation
                    let subscribeObj = {
                        "subscription": {
                            "loggedInUser": res['data'][0].userId.toString(),
                            "project": "1",
                            "event": [
                                { "id": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                    "title": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                    "Code": "New Message" }
                            ],
                            "notificationType": 3,
                            "template": 4,
                            "applicationId": 5
                        }
                    }
                    this.chatService.subscribeEvent(subscribeObj).subscribe(res => {
                        if (res['message'] == 'Success') {
                            console.log('user subscribed Successfully');
                        } else {
                            console.log('Error');
                        }
                    });

                    this. showHideAddDialog();
                    this.submitted = true;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        }
        else {
            obj.patientId = this.updatePatientId;
            // patients.patientId = this.updatePatientId;
            // this.patientsForm.controls['status'].setValue(Constant.PATIENTS_STATUS_ACTIVE);
            // patients.status = Constant.PATIENTS_STATUS_ACTIVE;
            this.patientsServices.updatePatients(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.patientsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNamePatients) +
                                     this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNamePatients)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePatientId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }
    // findPatientType() {
    //     let temp = [];
    //     this.startLoader();
    //     this.lovServices.findAllListOfValuesByName('PatientType').subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = {label: '', value: ''};
    //                     dropDown.value = object['listOfValuesId'];
    //                     dropDown.label = object['label'];
    //                     this.patientTypeList.push(dropDown);
    //                 }
    //             } else {
    //                 this.patientTypeList = [];
    //             }
    //             this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }


    getAllLov(){
        // Constant.PATIENTS_LOV_ITMES;
        this.startLoader();
        this.lovService.getAllListOfValuesByName(Constant.PATIENTS_LOV_ITMES).subscribe(result => {

            console.log("result of Lov values :: ",result);

            if(result['statusCode'] == 200){
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];

                    if(element[Constant.PATIENT_SEX]){
                        for (let object of element[Constant.PATIENT_SEX]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.sex_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_GENDERIDENTITY]){
                        for (let object of element[Constant.PATIENT_GENDERIDENTITY]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.gender_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_CONSENT]){
                        for (let object of element[Constant.PATIENT_CONSENT]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.conscent_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_COMM_PREFS]){
                        for (let object of element[Constant.PATIENT_COMM_PREFS]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.comm_prefs_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_MARKETING_INFO]){
                        for (let object of element[Constant.PATIENT_MARKETING_INFO]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.marketing_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_TYPE]){
                        for (let object of element[Constant.PATIENT_TYPE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.patientTypeList.push(dropDown);
                        }

                    }
                    else if(element[Constant.PATIENT_BILLING]){
                        for (let object of element[Constant.PATIENT_BILLING]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.billing_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_CONSCESSION_TYPE]){
                        for (let object of element[Constant.PATIENT_CONSCESSION_TYPE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.cons_type_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_INVOICE]){
                        for (let object of element[Constant.PATIENT_INVOICE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.invoice_array.push(dropDown);
                        }

                    }else if(element[Constant.PATIENT_REF_SOURCE]){
                        for (let object of element[Constant.PATIENT_REF_SOURCE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.ref_source_array.push(dropDown);
                        }
                    }

                }
                this.findAllPatientsByClientId();
            }else{

            }

        });
    }

    findAllPatientsByClientId(): void {

      this.startLoader();

      this.patientsServices.findPatientsByClientId(this.clientId).subscribe(res => {


        if (res.status === HttpStatusCode.Ok) {

          this.patientsList = res.body;
          this.totalRecords = this.patientsList.length;
          this.stopLoader();

        }


      }, err => {
        this.messageService.add(
          {
            severity: 'error',
            summary: err,
            // detail: this.translate(this.labels.entityNamePatients)
          });
        this.stopLoader();
      });

    }

    deleteByIdPatients(patientId: number, index) {
        this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                const httpPatchItem = new HttpPatchItem('replace', '/dmlStatus', 3);

                let httpPatch = new HttpPatch();
                httpPatch.pushToJsonPatch(httpPatchItem);

                this.patientsServices.updatePatientPatched(httpPatch.jsonPatch, patientId).subscribe(res => {

                    if (res.status == HttpStatusCode.NoContent) {

                        this.patientsList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNamePatients) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNamePatients)
                            });
                        this.refresh();
                    } else {

                      this.messageService.add(
                        {
                          severity: 'error',
                          summary: 'cannot delete',
                          // detail: this.translate(this.labels.entityNamePatients)
                        });
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }

    loadPatientsToEdit(patientId: number, index) {
        this.disableColumn = true;
        this.addEditTitle = this.labels.btnEdit;
        let patients = this.patientsList.find(x => x['patientId'] == patientId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updatePatientId = patients['patientId'];
        this.selectedIndex = index;

        if(patients['firstName']){
            this.patientsForm.controls['firstName'].setValue(patients.firstName);
        }
        if(patients['lastName']){
            this.patientsForm.controls['lastName'].setValue(patients.lastName);
        }
        if(patients['dob']){
            this.patientsForm.controls['dob'].setValue(new Date(patients.dob));
        }
        if(patients['sex']){
            this.patientsForm.controls['sex'].setValue(patients.sex);
        }
        if(patients['genderIdentity']){
            this.patientsForm.controls['genderIdentity'].setValue(patients.genderIdentity);
        }
        if(patients['occupation']){
            this.patientsForm.controls['occupation'].setValue(patients.occupation);
        }
        if(patients['emergencyContact']){
            this.patientsForm.controls['emergencyContact'].setValue(patients.emergencyContact);
        }
        if(patients['consent']){
            this.patientsForm.controls['consent'].setValue(patients.consent);
        }
        if(patients['email']){
            this.patientsForm.controls['email'].setValue(patients.email);
        }
        if(patients['address']){
            this.patientsForm.controls['address'].setValue(patients.address);
        }
        if(patients['commPrefs']){
            this.patientsForm.controls['commPrefs'].setValue(patients.commPrefs);
        }
        if(patients['marketingInfo']){
            this.patientsForm.controls['marketingInfo'].setValue(patients.marketingInfo);
        }
        if(patients['patientType']){
            this.patientsForm.controls['patientType'].setValue(patients.patientType);
        }
        if(patients['billing']){
            this.patientsForm.controls['billing'].setValue(patients.billing);
        }
        if(patients['conscessionType']){
            this.patientsForm.controls['conscessionType'].setValue(patients.conscessionType);
        }
        if(patients['invoice']){
            this.patientsForm.controls['invoice'].setValue(patients.invoice);
        }
        if(patients['invoiceInfo']){
            this.patientsForm.controls['invoiceInfo'].setValue(patients.invoiceInfo);
        }
        if(patients['insuranceNumber']){
            this.patientsForm.controls['insuranceNumber'].setValue(patients.insuranceNumber);
        }
        if(patients['uniqueRefNumber']){
            this.patientsForm.controls['uniqueRefNumber'].setValue(patients.uniqueRefNumber);
        }
        if(patients['referralSource']){
            this.patientsForm.controls['referralSource'].setValue(patients.referralSource);
        }

        if(patients['companyId']){
            this.patientsForm.controls['companyId'].setValue(patients.companyId);
        }

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        // this.patientsForm.reset();
        // this.patientsForm.markAsUntouched();
        this.updatePatientId = null;
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.patientsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.patientsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.patientsForm.get(control)?.valid && this.patientsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.patientsForm.reset();
    }

    onCloseSearch() {
        this.patientsSearch.reset();
    }

    refresh() {
        this.findAllPatientsByClientId();
    }

    onAddOverlayDiv() {
        this.patientsForm.reset();
    }

    // Temporary -> will see to minimize this later
    viewPatientDetail(patientId: number) {

        let patientObj = this.patientsList.find(x => x['patientId'] == patientId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
                    header: 'Patient Detail',
                    width: '90%',
                    contentStyle: {'max-height': '750px', 'overflow': 'auto'},
                    baseZIndex: 10000,
                    data: {
                        mainData:[
                                { label:this.labels.patientsColumnFirstName, value: patientObj['firstName']  },
                                { label:this.labels.patientsColumnLastName, value: patientObj['lastName'] },
                                { label:this.labels.patientsColumnDob, value: new DatePipe('en-US').transform(patientObj['dob'], 'yyyy-MM-dd')},
                                { label:this.labels.patientsColumnOccupation, value: patientObj['occupation']},
                                { label:this.labels.patientsColumnSex, value: patientObj['sex']['label']  },
                                { label:this.labels.patientsColumnGenderIdentity, value: patientObj['genderIdentity']['label']  },
                                { label:this.labels.patientsColumnEmergencyContact, value: patientObj['emergencyContact']  },
                                { label:this.labels.patientsColumnConsent, value: patientObj['consent']['label']  },
                                { label:this.labels.patientsColumnEmail, value: patientObj['email']},
                                { label:this.labels.patientsColumnAddress, value: patientObj['address']},
                                { label:this.labels.patientsColumnCommPrefs, value: patientObj['commPrefs']['label']},
                                { label:this.labels.patientsColumnMarketingInfo, value: patientObj['marketingInfo']['label']},
                                { label:this.labels.patientsColumnBilling, value: patientObj['billing']['label']  },
                                { label:this.labels.patientsColumnPatientType, value: patientObj['patientType']['label']  },
                                { label:this.labels.patientsColumnConscessionType, value: patientObj['conscessionType']['label']},
                                { label:this.labels.patientsColumnInvoice, value: patientObj['invoice']['label']  },
                                { label:this.labels.patientsColumnInsuranceNumber, value: patientObj['insuranceNumber']  },
                                { label:this.labels.patientsColumnUniqueRefNumber, value: patientObj['uniqueRefNumber']},
                                { label:this.labels.patientsColumnReferralSource, value: patientObj['referralSource']['label']},
                                { label:this.labels.patientsColumnUserEmail, value: patientObj['userEmail']},
                ]
            }
                });
    }

    searchTerm : any = null;
    onGlobalSearchClick(searchTerm){

        this.searchTerm = searchTerm;
        this.patientGlCount();

    }

    patientGlCount(){
        this.patientsServices.patientGlcount(this.searchTerm,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchPatient();
            } else {
                this.totalRecords = 0;
                this.patientsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchPatient(){
        this.patientsServices.findGlobalPatient(this.searchTerm, this.pageNo, this.itemPerPage,this.loggedInRefId,this.loggedInUserType).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.patientsList = result['data'];
            }else{
                this.patientsList = [];
            }
            console.log(result,"result");
        });
    }
    changePatientStatus(element, index) {
        this.checklistIndex = index;
        let patientObj = element;
        this.updatePatientId = element['patientId'];
        if(this.title === Constant.PATIENTS_STATUS_ACTIVE ){
            this.patientsForm.controls['status'].setValue(Constant.PATIENTS_STATUS_DEACTIVATED);
        }
        else if(this.title === Constant.PATIENTS_STATUS_DEACTIVATED){
            this.patientsForm.controls['status'].setValue(Constant.PATIENTS_STATUS_ACTIVE);
        }

        if(patientObj['userId']){
            this.patientsForm.controls['userId'].setValue(patientObj['userId']);
        }
        if(patientObj['firstName']){
            this.patientsForm.controls['firstName'].setValue(patientObj['firstName']);
        }
        if(patientObj['lastName']){
            this.patientsForm.controls['lastName'].setValue(patientObj['lastName']);
        }
        if(patientObj['dob']){
            this.patientsForm.controls['dob'].setValue(new Date(patientObj['dob']));
        }
        if(patientObj['sex']){
            this.patientsForm.controls['sex'].setValue(patientObj['sex']['listOfValuesId']);
        }
        if(patientObj['genderIdentity']){
            this.patientsForm.controls['genderIdentity'].setValue(patientObj['genderIdentity']['listOfValuesId']);
        }
        if(patientObj['occupation']){
            this.patientsForm.controls['occupation'].setValue(patientObj['occupation']);
        }
        if(patientObj['emergencyContact']){
            this.patientsForm.controls['emergencyContact'].setValue(patientObj['emergencyContact']);
        }
        if(patientObj['consent']){
            this.patientsForm.controls['consent'].setValue(patientObj['consent']['listOfValuesId']);
        }
        if(patientObj['email']){
            this.patientsForm.controls['email'].setValue(patientObj['email']);
        }
        if(patientObj['address']){
            this.patientsForm.controls['address'].setValue(patientObj['address']);
        }
        if(patientObj['commPrefs']){
            this.patientsForm.controls['commPrefs'].setValue(patientObj['commPrefs']['listOfValuesId']);
        }
        if(patientObj['marketingInfo']){
            this.patientsForm.controls['marketingInfo'].setValue(patientObj['marketingInfo']['listOfValuesId']);
        }
        if(patientObj['patientType']){
            this.patientsForm.controls['patientType'].setValue(patientObj['patientType']['listOfValuesId']);
        }
        if(patientObj['billing']){
            this.patientsForm.controls['billing'].setValue(patientObj['billing']['listOfValuesId']);
        }
        if(patientObj['conscessionType']){
            this.patientsForm.controls['conscessionType'].setValue(patientObj['conscessionType']['listOfValuesId']);
        }
        if(patientObj['invoice']){
            this.patientsForm.controls['invoice'].setValue(patientObj['invoice']['listOfValuesId']);
        }
        if(patientObj['invoiceInfo']){
            this.patientsForm.controls['invoiceInfo'].setValue(patientObj['invoiceInfo']);
        }
        if(patientObj['insuranceNumber']){
            this.patientsForm.controls['insuranceNumber'].setValue(patientObj['insuranceNumber']);
        }
        if(patientObj['uniqueRefNumber']){
            this.patientsForm.controls['uniqueRefNumber'].setValue(patientObj['uniqueRefNumber']);
        }
        if(patientObj['referralSource']){
            this.patientsForm.controls['referralSource'].setValue(patientObj['referralSource']['listOfValuesId']);
        }
        if(patientObj['isSystemUser']){
            this.patientsForm.controls['isSystemUser'].setValue(patientObj['isSystemUser']);
        }
        if(patientObj['patientUserId']){
            this.patientsForm.controls['patientUserId'].setValue(patientObj['patientUserId']['userId']);
        }
        if(patientObj['companyId']){
            this.patientsForm.controls['companyId'].setValue(patientObj['companyId']['companyId']);
        }

        this.addUpdatePatients(this.patientsForm.value);
    }

    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.patientsSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.patientsForm.reset();
            this.patientsForm.markAsUntouched();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePatientId = null;
        this.disableColumn = false;

    }

    userId : any;
    getUserSubscriptionList(userId) {
        this.startLoader();
        this.userSubscriptionList = [];
        this.userId = userId;
        this.subscriptionService.findUserSubscription(userId).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.userSubscriptionList = res['data'];
                    console.log('user subs data : ', this.userSubscriptionList);
                    this.userSubscriptionList.forEach(element=>{
                        element['value'] = element['subscriptionStatus']
                    });
                    this.subscriptionViewModal = true;
                } else {
                    this.userSubscriptionList = [];
                    this.subscriptionViewModal = true;
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    onSelect(item: any, i: number, element: any, event) {
        if(event.value === 'Active'){
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Active';
        }else {
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Inactive';
        }
        // console.log(this.userSubscriptionList, 'changed user subscription list');
    }

    cancelUserSubscription() {
        this.subscriptionViewModal = false;
    }

    saveUserSubscription(event) {
        this.startLoader();
        // let jsonObj = {'userSubscriptionList': this.userSubscriptionList};
        this.subscriptionService.activateUserSubscriptionlist(this.userId,Constant.USER_TYPE_INDIVIDUAL,this.userSubscriptionList).subscribe(res => {
            if (res['message'] == 'Success') {
                console.log('check list item updated => ', res['data']);
                this.subscriptionViewModal = false;
                this.stopLoader();
            } else {
                this.subscriptionViewModal = false;
                this.stopLoader();

            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    refreshSubs(patientUserId) {
        this.subscriptionService.refreshSubscriptionlist(this.loggedInRefId,Constant.USER_TYPE_INDIVIDUAL,patientUserId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.userSubscriptionList = [];
                this.userSubscriptionList = res['data'];
                this.userSubscriptionList.forEach(element=>{
                    element['value'] = element['subscriptionStatus']
                });
                this.stopLoader();
            } else {
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });

    }

    showManageSubscription(userId) {

        this.getUserSubscriptionList(userId);
        this.subscriptionViewModal = true;
    }
}
