<section class="sectionmain">
  <div class="card-cpa-rounded-20 background-white-m-30">
    <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
             [readonly]="false">
    </p-steps>
  </div>

  <div class="p-row initial-assessment-container">

    <div class="p-col-12">
      <div class="row">
        <div class="p-col-3 m-5">

          <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
        </div>
      </div>
      <div class="row">

        <div class="p-col-8 m-5">
          <p-card styleClass="card-cpa-rounded card-shadow">

            <h5 class="superAdminTitles">Testing</h5>
            <div class="p-col-12">
              <div class="middle">
                <label *ngFor="let bodyPart of existingBodyPartData">
                  <input type="radio" name="radio" (click)="bodyPartChanged(bodyPart.bodyChartAnatomiesDiagram.bodyChartAnatomyDiagramId)"/>
                  <div class="box">
                    <span>  {{bodyPart.bodyChartAnatomiesDiagram.bodyChartAnatomyDiagramName}}</span>
                  </div>
                </label>


              </div>
            </div>
            <p-tabView class="expertAnalyticsTabs" *ngIf="chosenBodyChartAnatomyDiagramId">

              <p-tabPanel header="AROM" headerStyleClass="h3-style">
                <app-testing-arom [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-arom>
              </p-tabPanel>


              <p-tabPanel header="Neuro Testing" headerStyleClass="h3-style ">
                <app-testing-neuro [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-neuro>
              </p-tabPanel>

              <p-tabPanel header="Muscle Testing" headerStyleClass="h3-style">
                <app-testing-muscle [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-muscle>
              </p-tabPanel>

              <p-tabPanel header="Special Testing" headerStyleClass="h3-style">
                <app-testing-special [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-special>
              </p-tabPanel>

            </p-tabView>

        </p-card>

    </div>
</div>
      </div>
    </div>
</section>
