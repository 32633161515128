import { Component, OnInit } from '@angular/core';
import {SessionService} from "../services/session.service";
import {InitialAssessmentSteps} from "../initial-assessment-steps/InitialAssessmentSteps";
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {Router} from "@angular/router";
import {SharedService} from "../web-services/shared.service";
import {Labels} from "../util/labels";
import {ConstantService} from "../web-services/constant.service";
import {CaseService} from "../web-services/case.service";
import {HttpPatchItem} from "../util/HttpPatchItem";
import {HttpPatch} from "../util/HttpPatch";
import {AppointmentsService} from '../web-services/appointments.service';
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../web-services/clinical-data.service';

@Component({
  selector: 'app-initial-assessment-review',
  templateUrl: './initial-assessment-review.component.html',
  styleUrls: ['./initial-assessment-review.component.css']
})
export class InitialAssessmentReviewComponent implements OnInit {
  public labels = Labels;
  constructor(private primengConfig: PrimeNGConfig,
              private sessionService: SessionService,
              private router: Router,
              private sharedService: SharedService,
              private constantService: ConstantService,
              private caseService: CaseService,
              private messageService: MessageService,
              private clinicalDataService: ClinicalDataService

              ) { }

  expertId: number;
  patientId: number;
  appointmentId: number;
  caseId: number;
  patientObj; any;
  stepsItems: any;
  activeStep: number;
  constantList: any;
  caseReviewData: any;


  ngOnInit(): void {

    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;




    this.primengConfig.ripple = true;
    this.stepsItems = InitialAssessmentSteps.stepItems;
    this.activeStep = this.stepsItems.find(step => step.route === 'initial-assessment-review').number;
    this.getConstantList(1234);
    this.getAllReviewDataByAppointmentId();

  }

  urlChanged(stepNumber: number): void {

    const url = this.stepsItems.find(step => step.number === stepNumber).route;
    this.router.navigate([url]);
  }

  translate(label): any {
    return this.sharedService.verifyLabel(label, this.constantList);
  }


  getAllReviewDataByAppointmentId(): void {



    this.clinicalDataService.getReviewByAppointmentId(this.appointmentId).subscribe(res => {

      if (res.status === HttpStatusCode.Ok) {

        this.caseReviewData = res.body;
      }

    });
  }

  getConstantList(formId): void {
    this.constantService.getFormConstantsList(formId).subscribe(data => {
      if (data['statusCode'] === 200) {

        this.constantList = data['data'];
      } else {
        this.constantList = [];
      }
    });
  }

  markInitialAssessmentAsComplete(): void {


      const httpPatchItem = new HttpPatchItem('replace', '/initialAssessmentComplete', true);

      let httpPatch = new HttpPatch();

      httpPatch.pushToJsonPatch(httpPatchItem);



      this.caseService.updateCase(httpPatch.jsonPatch, this.caseId).subscribe(res => {

        if (res.status === HttpStatusCode.Ok) {

          this.caseReviewData.initialAssessmentComplete = true;

          this.messageService.add({
            severity: 'success',
            summary: 'Initial Assessment Completed',
          });

        }
      });
  }

}
