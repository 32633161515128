export class FormDataDTO {

  appointmentId: any;
  patientId: any;
  expertId: any;
  caseId: any;
  multiSelectData: any;
  hasMultiSelectErrors = false;
  multiSelectErrorFields = [];
  historyAndDiagnosisData: any;
  diagnosisData: any;
  redFlagData = [];


  constructor(appointmentId: number, patientId: number, expertId: number, caseId: number, multiSelectData: any, formData: any) {


    this.appointmentId = { appointmentId: appointmentId };
    this.patientId = { patientId: patientId };
    this.expertId = { expertId: expertId };
    this.caseId = { caseId: caseId };
    this.multiSelectData = this.createMultiSelectDataFromObjectMultiSelects(multiSelectData, this);
    this.addFormDataToDTO(formData, this);

  }

  createMultiSelectDataFromObjectMultiSelects(objectMultiSelectsData: any, currentClass: FormDataDTO): any {

    let multiSelectData = {};
    let diagnosisData = [];
    let redFlagData = [];

    console.log(objectMultiSelectsData);
    for (let [key, value] of Object.entries(objectMultiSelectsData)) {

      const selected = objectMultiSelectsData[key].list.filter(item => item.selected === 1);

      if (objectMultiSelectsData[key].isRequired && !selected.length) {

        objectMultiSelectsData[key].error = true;
        currentClass.hasMultiSelectErrors = true;
        this.multiSelectErrorFields.push(objectMultiSelectsData[key]);
      }

      multiSelectData[objectMultiSelectsData[key].dbField] = objectMultiSelectsData[key].list.filter(item => item.selected === 1);


      const currentKeyRedFlagData =  objectMultiSelectsData[key].list.filter(item => {
        if (item.selected === 1 && currentClass.hasRedFlag(item)) {

          item.caseId = this.caseId;
          item.appointmentId = this.appointmentId;
          return item;
        }
      });

      if (currentKeyRedFlagData.length > 0 ) {
        console.log(currentKeyRedFlagData);

        redFlagData = [...redFlagData, ...currentKeyRedFlagData];
      }

      const multiSelectDiagnosisData = objectMultiSelectsData[key].list.filter(item =>  {

        if (item.selected === 1) {
          if (item.diagnosis !== undefined && item.diagnosis.length > 0) {

            const itemDiagnosis = item.diagnosis.map(itemD =>  {

              itemD.caseId = currentClass.caseId;
              itemD.nonObjectDiagnosisId = itemD.diagnosisId;
              itemD.diagnosisId = {

                diagnosisId: itemD.diagnosisId
              };

              return itemD;

            });

            return itemDiagnosis;
          }
        }
      });

      if (multiSelectDiagnosisData.length > 0 ) {
        diagnosisData = [...diagnosisData, ...multiSelectDiagnosisData];
      }

    }

    currentClass.redFlagData = redFlagData;
    currentClass.diagnosisData = diagnosisData;

    return multiSelectData;
  }

  hasRedFlag(item: any): any {

    return item.isRedFlag || item.isOrangeFlag || item.isYellowFlag || item.isBlueFlag || item.isGreenFlag;

  }

  addFormDataToDTO(formData: any, currentClass: FormDataDTO): void {

    for (let key in formData.controls) {
      currentClass[key] =  formData.controls[key].value;
    }

  }
}
