import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";

@Injectable({providedIn: 'root'})
export class TestingSpecialService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdTestingSpecial(testingSpecialId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingSpecial/deleteTestingSpecial', {'testingSpecialId': testingSpecialId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countTestingSpecial(testingSpecial): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'TestingSpecial/count', {'testingSpecial': testingSpecial},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateTestingSpecial(testingSpecial, refData): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingSpecial/updateTestingSpecial', [testingSpecial, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addTestingSpecial(testingSpecial, refData): Observable<string> {
      console.log(testingSpecial);
      throw ":sdsadas"
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingSpecial/addTestingSpecial', [testingSpecial, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllTestingSpecial(testingSpecial, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingSpecial/findAllTestingSpecial',
            {'testingSpecial': testingSpecial, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'TestingSpecial/countGlobalTestSpecial',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingSpecial/findGlobalTestSpecial',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addTestingSpecialCpa(testingSpecialDTO: FormDataDTO): Observable<any> {
    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'testing-specials', testingSpecialDTO, {headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }

}
