import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {PatientExercisesService} from '../web-services/patient-exercises.service';
import {ExercisesService} from '../web-services/exercises.service';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component(
    {
        selector: 'app-patient-exercises',
        templateUrl: './patient-exercises.component.html',
        styleUrls: ['./patient-exercises.component.css']
    })
export class PatientExercisesComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePatientExercisesId = null;
    exercisesList: any = [];
    usersList: any = [];
    patientExercisesList: any = [];
    patientExercisesSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    patientExercisesForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private patientExercisesServices: PatientExercisesService, private exercisesServices: ExercisesService,
        private usersServices: UsersService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updatePatientExercisesId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.patientExercisesSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countPatientExercises();
        this.findAllPatientExercises();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countPatientExercises() {
        this.patientExercisesServices.countPatientExercises(this.patientExercisesSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllPatientExercises();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdatePatientExercises(this.patientExercisesForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllPatientExercises();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.patientExercisesSearch.reset();
        this.countPatientExercises();
        // this.findAllPatientExercises();
    }

    addUpdatePatientExercises(patientExercises) {
        this.startLoader();
        if (this.updatePatientExercisesId == null) {
            this.patientExercisesServices.addPatientExercises(this.patientExercisesForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    patientExercises = res['data'][0];
                    this.patientExercisesList.push(patientExercises);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePatientExercises) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePatientExercises)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countPatientExercises();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            patientExercises.patientExercisesId = this.updatePatientExercisesId;
            this.patientExercisesServices.updatePatientExercises(patientExercises).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.patientExercisesList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePatientExercises) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePatientExercises)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePatientExercisesId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllExercises();
        this.findAllUsers();
        this.findAllUsers();
        // this.findAllAppointments();
        this.patientExercisesForm = new FormGroup({
                                                      exerciseId: new FormControl(),
                                                      patientId: new FormControl(),
                                                      expertId: new FormControl(),
                                                      appointmentId: new FormControl(),
                                                      userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                  });
        this.patientExercisesSearch = new FormGroup({
                                                        exerciseId: new FormControl(),
                                                        patientId: new FormControl(),
                                                        expertId: new FormControl(),
                                                        appointmentId: new FormControl(),
                                                        userId: new FormControl(),
                                                    });
        this.countPatientExercises();
        this.findAllPatientExercises();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.patientExercisesSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllPatientExercises() {
        this.startLoader();
        this.patientExercisesServices.findAllPatientExercises(this.patientExercisesSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.patientExercisesList = res['data'];
                } else {
                    this.patientExercisesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdPatientExercises(patientExercisesId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.patientExercisesServices.deleteByIdPatientExercises(patientExercisesId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.patientExercisesList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNamePatientExercises) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNamePatientExercises)
                                                });
                        this.countPatientExercises();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadPatientExercisesToEdit(patientExercisesId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let patientExercises = this.patientExercisesList.find(x => x['patientExercisesId'] == patientExercisesId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updatePatientExercisesId = patientExercises['patientExercisesId'];
        this.selectedIndex = index;
        this.patientExercisesForm.controls['exerciseId'].setValue(patientExercises['exerciseId']['exerciseId']);
        this.patientExercisesForm.controls['patientId'].setValue(patientExercises['patientId']['patientId']);
        this.patientExercisesForm.controls['expertId'].setValue(patientExercises['expertId']['expertId']);
        this.patientExercisesForm.controls['appointmentId'].setValue(patientExercises['appointmentId']['appointmentId']);
        this.patientExercisesForm.controls['userId'].setValue(patientExercises['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.patientExercisesForm.reset();
        this.patientExercisesForm.markAsUntouched();
        this.updatePatientExercisesId = null;
        closeAddOverlaydiv();
    }

    findAllExercises() {
        let temp = [];
        this.startLoader();
        this.exercisesServices.findAllExercises(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['exerciseId'];
                        dropDown.label = object['exerciseImageUrl'];
                        this.exercisesList.push(dropDown);
                    }
                } else {
                    this.exercisesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.patientExercisesForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.patientExercisesForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.patientExercisesForm.get(control)?.valid && this.patientExercisesForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.patientExercisesForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.patientExercisesSearch.reset();
    }

    refresh() {
        this.countPatientExercises();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.patientExercisesForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.patientExercisesForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePatientExercisesId = null;
    }
}
