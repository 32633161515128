import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-users', templateUrl: './users.component.html', styleUrls: ['./users.component.css']})
export class UsersComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateUserId = null;
    usersList: any = [];
    usersSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    usersForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private usersServices: UsersService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateUserId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.usersSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countUsers();
        this.findAllUsers();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countUsers() {
        this.usersServices.countUsers(this.usersSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllUsers();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateUsers(this.usersForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllUsers();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.usersSearch.reset();
        this.countUsers();
        // this.findAllUsers();
    }

    addUpdateUsers(users) {
        this.startLoader();
        if (this.updateUserId == null) {
            this.usersServices.addUsers(this.usersForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    users = res['data'][0];
                    this.usersList.push(users);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.btnAdd),
                            detail: this.translate(this.labels.entityNameUsers)
                        });
                    this.submitted = true;
                } else {
                    console.error(res['documentation']);
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            users.userId = this.updateUserId;
            this.usersServices.updateUsers(users).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.usersList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.btnUpdate),
                            detail: this.translate(this.labels.entityNameUsers)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateUserId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.usersForm = new FormGroup({userName: new FormControl('', [Validators.required, validateSpaces]),});
        this.usersSearch = new FormGroup({userName: new FormControl(),});
        this.countUsers();
        this.findAllUsers();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.usersSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllUsers() {
        this.startLoader();
        this.usersServices.findAllUsers(this.usersSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.usersList = res['data'];
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdUsers(userId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.usersServices.deleteByIdUsers(userId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.usersList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.btnDelete),
                                detail: this.translate(this.labels.entityNameUsers)
                            });
                        this.countUsers();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadUsersToEdit(userId: number, index) {
        let users = this.usersList.find(x => x['userId'] == userId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateUserId = users['userId'];
        this.selectedIndex = index;
        this.usersForm.controls['userName'].setValue(users['userName']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.usersForm.reset();
        this.usersForm.markAsUntouched();
        this.updateUserId = null;
        closeAddOverlaydiv();
    }

    fieldReset(control) {
        return this.usersForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.usersForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.usersForm.get(control)?.valid && this.usersForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.usersForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.usersSearch.reset();
    }

    refresh() {
        this.countUsers();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.usersForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.usersForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateUserId = null;
    }
}
