import {Injectable} from '@angular/core';
import {MessageService, PrimeNGConfig} from 'primeng/api';

@Injectable({
                providedIn: 'root'

            })
export class ToastService {

    constructor(private messageService: MessageService, private primengConfig: PrimeNGConfig) {

    }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }

    showSuccess(operationType, victim) {
        this.messageService.add({
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: 'Your ' + operationType + ' request for ' + victim + ' is completed Successfully.'
                                });
    }

    showSuccessWithCustomMessage(customMessage) {
        this.messageService.add({severity: 'success', summary: 'Success', detail: customMessage});
    }

    showInfo(customMessage) {
        this.messageService.add({severity: 'info', summary: 'Info', detail: customMessage});
    }

    showWarn(customMessage) {
        this.messageService.add({severity: 'warn', summary: 'Warn', detail: customMessage});
    }

    showError(operationType, victim) {
        this.messageService.add(
            {severity: 'error', summary: 'Error', detail: 'Your ' + operationType + ' request for ' + victim + ' is failed .'});
    }

    showErrorWithCustomMessage(customMessage) {
        console.log(customMessage);
        this.messageService.add({severity: 'error', summary: 'Error', detail: customMessage});
    }

    showTopLeft() {
        this.messageService.add({key: 'tl', severity: 'info', summary: 'Info', detail: 'Message Content'});
    }

    showTopCenter() {
        this.messageService.add({key: 'tc', severity: 'info', summary: 'Info', detail: 'Message Content'});
    }

    showBottomCenter() {
        this.messageService.add({key: 'bc', severity: 'info', summary: 'Info', detail: 'Message Content'});
    }

    showConfirm() {
        this.messageService.clear();
        this.messageService.add({key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Confirm to proceed'});
    }

    showMultiple() {
        this.messageService.addAll([
                                       {severity: 'info', summary: 'Message 1', detail: 'Message Content'},
                                       {severity: 'info', summary: 'Message 2', detail: 'Message Content'},
                                       {severity: 'info', summary: 'Message 3', detail: 'Message Content'}
                                   ]);
    }

    showSticky() {
        this.messageService.add({severity: 'info', summary: 'Sticky', detail: 'Message Content', sticky: true});
    }

    onConfirm() {
        this.messageService.clear('c');
    }

    onReject() {
        this.messageService.clear('c');
    }

    clear() {
        this.messageService.clear();
    }

}
