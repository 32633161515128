import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../alertservice/alert.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastr: AlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(this.errorHandler));
    }

    // Http Error handling
    errorHandler(error: HttpErrorResponse) {
        if (!navigator.onLine) {
            return throwError('Error : Connection Error');
        } else {
            return throwError(error);
        }
    }
}