import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PalpationMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['palpation']: {
      name: Constant.OBSERVATIONS_DROPDOWN_CATEGORY_PALPATION,
      dbField: 'palpationTypeId',
      label: 'Palpation',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false


    }
  };

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
