import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PostureService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdPosture(postureId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Posture/deletePosture', {'postureId': postureId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countPosture(posture): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Posture/count', {'posture': posture}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updatePosture(posture, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Posture/updatePosture', [posture, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPosture(posture, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Posture/addPosture', [posture, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPostureCpa(postureDTO): Observable<any> {
      return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'postures', postureDTO, {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'}).pipe(
        retry(3), catchError(Constant.handleError));
    }

    findAllPosture(posture, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Posture/findAllPosture',
            {'posture': posture, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }


  findAllPostureByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(

      Constant.CPA_SPRING_API_URL + 'patients/' + patientId  + '/postures', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findAllPosturesByAppointmentId(appointmentId: number): Observable<any> {
    return this.httpClient.get<any>(

      Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId  + '/postures', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }


  // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Posture/countGlobalPosture',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Posture/findGlobalPosture',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
