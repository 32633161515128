<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deleteDrugHistory" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!--<ngx-ui-loader></ngx-ui-loader>-->
<!-- Main Invitation Page-->
<section class="sectionmain">
  <div class="container card-cpa-rounded card-shadow">
        <section >
            <div class="overlaypaneldivContainer">
                <div class="overlaypaneldiv" >
                    <p-panel class="pngPanelMain" >
                        <p-header> {{translate(labels.entityNameDrugHistory)}}
                            <div class="pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameDrugHistory)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>

<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <form (ngSubmit)="onSubmit()" [formGroup]="drugHistoryForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <!-- Drug History -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <app-lov-details [categoryString]="drugHistory"
                                                             label="Prescription History"
                                                             [dataObj]="lovDataObject"
                                                             (getResponse)="getResponse($event)"></app-lov-details>
                                            <span id="#drugHistoryError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>





                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="vaccinations"
                                                           label="Vaccinations"
                                                           [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span id="#vaccinationsError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>

                                        </div>


                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <!--
                                          <label>{{translate(labels.drugHistoryColumnLifestyle)}}</label>
                                          --->
                                          <app-lov-details [categoryString]="recreation"
                                                           label="Recreational Drugs"
                                                           [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span id="#recreationError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>


                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <!--
                                            <label>{{translate(labels.drugHistoryColumnDrinks)}}</label>
-->
                                          <app-lov-details [categoryString]="drinks"
                                                           label="Alcohol Consumption"
                                                           [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span id="#drinksError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>


                                        </div>


                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                           <!--
                                            <label>{{translate(labels.drugHistoryColumnSmoke)}}</label>
                                          -->
                                          <app-lov-details [categoryString]="smoke"
                                                           label="Smoking Frequency"
                                                           [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#smokeError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>

                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">

                                          <app-lov-details [categoryString]="caffeinatedDrink"
                                                           label="Amount of Caffeinated Drink"
                                                           [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#caffeinatedDrinksError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-12 formfieldheight">
                                            <label>{{translate(labels.drugHistoryColumnAdditionalComments)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('additionalComments')"
                                                    *ngIf="diplayeIconInField('additionalComments')"
                                                    class="pi pi-times color-danger"></i>
                                                    <textarea rows="5" [class.p-invalid]="inputFieldDanger('additionalComments')"
                                                               class="form-control" formControlName="additionalComments" name="additionalComments"></textarea>


                                            </span>
                                            <app-custom-error [control]="'additionalComments'"
                                                              [form]="drugHistoryForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                                placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="drugHistoryList.length > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="drugHistoryList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': drugHistoryList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped" dataKey="insertionTimestamp">
                                    <ng-template pTemplate="header">
                                        <tr>

                                            <th></th>
                                            <th> Date</th>
                                            <th> Drinks</th>
                                            <th> Smoke</th>
                                            <th> Recreation</th>
                                            <th> Drugs</th>
                                            <th> Additional Comments</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body" let-expanded="expanded">
                                        <tr>
                                          <td>
                                            <button type="button" pButton pRipple [pRowToggler]="element" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                          </td>
                                            <td> {{element?.insertionTimestamp | date: "dd-MM-yyyy"}} </td>
                                            <td>{{element?.drinks[0].lovValues}}</td>
                                            <td>{{element?.smoke[0].lovValues}}  </td>
                                            <td>{{element?.recreation[0].lovValues}} </td>
                                            <td>{{element?.drugHistory[0].lovValues}}  </td>
                                            <td> {{element?.additionalComments}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadDrugHistoryToEdit(element.drugHistoryId, i)"
                                                              icon="pi pi-pencil"
                                                              pTooltip="Edit"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdDrugHistory(element.drugHistoryId,i)"
                                                              icon="pi pi-trash"
                                                              pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                  <ng-template pTemplate="rowexpansion" let-element>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-wine-glass fa-2x"></i> Drinks </span>
                                        <span *ngFor="let drinks of element.drinks; let b = index">
                                          {{drinks.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-smoking fa-2x"></i> Smoking  </span>
                                        <span *ngFor="let smoke of element.smoke; let b = index">
                                          {{smoke.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-cannabis fa-2x"></i> Recreational Drugs </span>
                                        [
                                        <span *ngFor="let recreation of element.recreation; let c = index">
                                          {{recreation.lovValues}}
                                        </span> ]
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-pills fa-2x"></i> Prescription Drugs </span>
                                        <span *ngFor="let drugHistory of element.drugHistory; let d = index">
                                          {{drugHistory.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-comments fa-2x"></i> Additional Comments </span>
                                        {{element.additionalComments}}
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
