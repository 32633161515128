<!-- Header Component-->
<ngx-ui-loader></ngx-ui-loader>
<div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
  <div class="app-auth-background">

  </div>
  <div class="app-auth-container">
    <div class="logo">
      Physio Pro Assist
    </div>
    <div *ngIf="isTokenValid(); else elseBlock">
      <p class="auth-description">You have chosen to reset your password please set your password below</p>
      <form  [formGroup]="ResetPasswordForm" class="formContainer">

        <div class="auth-credentials m-b-xxl">
          <label for="signInPassword" class="form-label">Password</label>
          <input
            formControlName="password"
            id="login-email-id" pInputText
            placeholder="Password"
            type="password"
            class="form-control"
            aria-describedby="CompleteSignUpPassword"
          />
        </div>
      </form>
      <div class="auth-submit">
        <p-button [disabled]="!ResetPasswordForm.valid" (click)="onSubmit()"
                  class="p-d-inline-flex p-ai-center pngBlueBtn" id="login-btn"
                  label="Reset"
                  styleClass="p-button">
        </p-button>
      </div>

    </div>
    <ng-template  #elseBlock>
      <div>

        This token is invalid or expired. You can reset your password again  <a href="#" class="auth-forgot-password float-end" [routerLink]="[ '/','forgotPassword' ]" style="float: right !important;">here</a>


      </div>
    </ng-template>
    <div class="divider"></div>
  </div>
</div>
