import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExpertService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdExpert(expertId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Expert/deleteExpert', {'expertId': expertId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findExpertsById(expertId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Expert/findExpert', {'expertId': expertId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countExpert(expert,refId,userType): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'Expert/count', {'expert': expert,'referenceData':{'userType':userType,'refId':refId}}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateExpert(expert): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Expert/updateExpert', [expert], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addExpert(expert,refId,userType): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        let refObj = {'userType':userType,'refId':refId};

        console.log(expert);
        console.log(refObj);

        return this.httpClient.post<string>(Constant.BASE_URL + 'Expert/addExpert', [expert,refObj], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllExpert(expert, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Expert/findAllExpert', {'expert': expert,'referenceData':{'userType':userType,'refId':refId}, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom services to handle global searching
    expertGlcount(searchTerm,refId,userType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Expert/countGlobalExpert',
                                            {'searchTerm': searchTerm,'refId':refId,'userType':userType},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalExpert(searchTerm, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Expert/findGlobalExpert',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage},
            'refId':refId,'userType':userType},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    //Manage Subsriptions
    // findUserSubscription(UserId): Observable<string[]> {
    //     let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
    //     return this.httpClient.post<string[]>(
    //         baseURL + 'UserSubscription/getActiveSubscriptionlist',
    //         {'userId': UserId},
    //         this.httpOptions
    //     ).pipe(
    //         retry(3), catchError(Constant.handleError));
    // }
    addUserSubscriptionlist(userId, UserSubscriptionlist): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Expert/addUserSubscriptions', {'expertId': userId, 'subscription': UserSubscriptionlist},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

  addExpertCpa(expert): Observable<any> {


    return this.httpClient.post<any>(Constant.CPA_EXPERT_PATIENT_SERVICE + 'experts', expert,{headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(0), catchError(Constant.handleError));
  }

  findAllExpertsByClientId(clientId: number):Observable<any> {

    return this.httpClient.get<any>(Constant.CPA_EXPERT_PATIENT_SERVICE + 'experts/filter?type=byClient&id=' + clientId ,{ headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(2), catchError(Constant.handleError));
  }

  updateExpertPatched(expertPatch: any, expertId): Observable<any> {

    return this.httpClient.patch<string>(Constant.CPA_EXPERT_PATIENT_SERVICE + 'experts/' + expertId, expertPatch, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/merge-patch+json'),
      observe: 'response'
    }).pipe(
      retry(0), catchError(Constant.handleError));


  }

  findExpertByUserId(userId: number): Observable<any> {

      alert(Constant.CPA_EXPERT_PATIENT_SERVICE + 'experts/filter?type=byUser&id=' + userId);
    return this.httpClient.get<any>(Constant.CPA_EXPERT_PATIENT_SERVICE + 'experts/filter?type=byUser&id=' + userId ,{ headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(2), catchError(Constant.handleError));
  }

}
