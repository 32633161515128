import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {

  specialQuestionChecked: any[];
  severity: any[];
  selectBtnData: any[];
  dropdownData;
  postureDesc;
  gaitDesc;

  constructor() { }

  ngOnInit(): void {
    this.specialQuestionChecked = [
      { name: "Yes", value: 1 },
      { name: "No", value: 2 }
    ];
    this.severity = [
      { name: "Mild", value: 1 },
      { name: "Moderate", value: 2 },
      { name: "Severe", value: 3 }
    ];
    this.selectBtnData = [
      { name: "Affected", value: 1 },
      { name: "Not Affected", value: 2 }
    ];
    this.dropdownData = [
      {
        name: 'Select One'
      },
      {
        name: 'Select Two'
      }
    ];
    this.postureDesc = [
      {
        name: 'Normal'
      },
      {
        name: 'Head Tilt'
      }
    ];
    this.gaitDesc = [
      {
        name: 'Normal Pattern'
      },
      {
        name: 'Shuffling Gait'
      }
    ];
  }

}
