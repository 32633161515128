import { Component, OnInit } from '@angular/core';
import { PatientsService } from '../../web-services/patients.service';
import { SessionService } from '../../services/session.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormControl, FormGroup } from '@angular/forms';
import { ExpensesService } from '../../web-services/expenses.service';
import {InvoiceService} from '../../web-services/invoice.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  displayBasic2: boolean;
  showCases: boolean = false;
  showInvoicing: boolean = false;
  showExp: boolean = false;
  showContacts: boolean = false;
  showClinics: boolean = false;
  showAppointments: boolean = false;
  showMainDashboard: boolean = false;

  patientsSearchForm: FormGroup;
  expensesSearchForm: FormGroup;
  invoiceSearchForm: FormGroup;
  patientList: any = [];
  expensesList: any = [];
  invoiceList: any = [];
  physiotherapyPatients: any = [];
  psychologyPatients: any = [];
  activePatients;
  deActivatedPatients;
  totalPatients;
  totalExpenses;
  totalInvoicesCount = 0;
  paidInvoicesCount = 0;
  pendingInvoicesCount = 0;
  loggedInUserType: any;
  loggedInRefId: any;

  constructor(
    private patientsServices: PatientsService,
    private sessionService: SessionService,
    private loader: NgxUiLoaderService,
    private expensesServices: ExpensesService,
    private invoiceServices: InvoiceService
  ) { }

  ngOnInit(): void {
    this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
    this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));

    this.patientsSearchForm = new FormGroup({
      userId: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      dob: new FormControl(),
      sex: new FormControl(),
      genderIdentity: new FormControl(),
      occupation: new FormControl(),
      emergencyContact: new FormControl(),
      consent: new FormControl(),
      email: new FormControl(),
      address: new FormControl(),
      commPrefs: new FormControl(),
      marketingInfo: new FormControl(),
      billing: new FormControl(),
      patientType: new FormControl(),
      conscessionType: new FormControl(),
      invoice: new FormControl(),
      invoiceInfo: new FormControl(),
      insuranceNumber: new FormControl(),
      uniqueRefNumber: new FormControl(),
      referralSource: new FormControl(),
      isSystemUser: new FormControl(),
      patientUserId: new FormControl(),
      companyId: new FormControl(),
      agencyId: new FormControl(),
      status: new FormControl()
    });

    this.invoiceSearchForm = new FormGroup({
                                         patientId: new FormControl(),
                                         agencyId: new FormControl(),
                                         invoiceTo: new FormControl(),
                                         issueDate: new FormControl(),
                                         issueTo: new FormControl(),
                                         issueBy: new FormControl(),
                                         userId: new FormControl(),
                                         companyId: new FormControl(),
                                         issueByType: new FormControl(),
                                         issueToType: new FormControl(),
                                         invoiceAmount: new FormControl(0),
                                         invoiceStatus: new FormControl(),
                                       });

    this.findAllPatients();

    this.expensesSearchForm = new FormGroup({
      appointmentId: new FormControl(),
      clinicId: new FormControl(),
      paidOn: new FormControl(),
      company: new FormControl(),
      source: new FormControl(),
      userId: new FormControl(),
      expenseType: new FormControl(),
      expenseAmount: new FormControl(),
      referenceId: new FormControl()
    });
    this.expensesSearchForm.controls['referenceId'].setValue(parseInt(sessionStorage.getItem('loggedInRefId')));
    // this.expensesSearchForm.controls['userId'].setValue(parseInt(sessionStorage.getItem('loggedInUser')));
    this.countExpenses();
    this.findAllInvoice();

  }
  findAllPatients() {
    // if(this.title && this.title === Constant.PATIENTS_STATUS_PHYSIOTHERAPY){
    //   let result = this.patientTypeList.find(element => element.label === Constant.PATIENTS_STATUS_PHYSIOTHERAPY);
    //   this.patientsSearch.controls['patientType'].setValue(result.value);
    // } else if(this.title && this.title === Constant.PATIENTS_STATUS_PSYCHOLOGY){
    //   let result = this.patientTypeList.find(element => element.label === Constant.PATIENTS_STATUS_PSYCHOLOGY);
    //   this.patientsSearch.controls['patientType'].setValue(result.value);
    // } else if(this.title && this.title === Constant.PATIENTS_STATUS_ACTIVE){
    //   this.patientsSearch.controls['status'].setValue(this.title);
    // } else if(this.title && this.title === Constant.PATIENTS_STATUS_DEACTIVATED){
    //   this.patientsSearch.controls['status'].setValue(this.title);
    // }
    this.startLoader();
    // console.log("agency id before find all service :: ",this.currentLoggedInAgencyId);
    this.patientsServices.findAllPatients(this.patientsSearchForm.value, null, null, this.loggedInUserType, this.loggedInRefId).subscribe(
      res => {
        if (res['message'] == 'Success') {
          this.patientList = res['data'];
        } else {
          this.patientList = [];
        }
        this.stopLoader();
        this.getPatientCounts();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  getPatientCounts() {
    this.totalPatients = this.patientList.length;

    this.activePatients = this.patientList.filter(d => {
      return d.status == 'Active';
    }).length;

    this.deActivatedPatients = this.patientList.filter(d => {
      return d.status == 'Deactivated';
    }).length;
  }

  startLoader() {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }

  showBasicDialog2() {
    this.displayBasic2 = true;
  }

  selectDashboard(dashboardStr) {
    this.showMainDashboard = true;
    switch (dashboardStr) {
      case 'cases':
        this.showCases = true;
        break;
      case 'invoicing':
        this.showInvoicing = true;
        break;
      case 'expenses':
        this.showExp = true;
        break;
      case 'contacts':
        this.showContacts = true;
        break;
      case 'clinics':
        this.showClinics = true;
        break;

      case 'appointments':
        this.showAppointments = true;
        break;


    }
  }
  countExpenses() {
    this.expensesServices.countExpenses(this.expensesSearchForm.value).subscribe(res => {
      if (res['message'] == 'Success') {
        this.totalExpenses = res['data'][0];
      } else {
        this.totalExpenses = 0;
      }
    }, err => {
      console.error('err: ', err);
    });
  }

  findAllInvoice() {
    if (this.loggedInUserType === 'admin') {
      this.invoiceSearchForm.controls['issueTo'].setValue(this.loggedInRefId);
    }
    // this.invoiceSearchForm.controls['invoiceStatus'].setValue(this.title);
    this.startLoader();
    this.invoiceServices.findAllInvoice(this.invoiceSearchForm.value, null, null).subscribe(
        res => {
          if (res['message'] == 'Success') {
            this.invoiceList = res['data'];
            this.getInvoiceCounts();
          } else {
            this.invoiceList = [];
            this.totalInvoicesCount = 0;
            this.paidInvoicesCount = 0;
            this.pendingInvoicesCount = 0;
          }
          this.stopLoader();
        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });
  }
  getInvoiceCounts() {
    this.totalInvoicesCount = this.invoiceList.length;

    this.paidInvoicesCount = this.invoiceList.filter(d => {
      return d.invoiceStatus == 'Paid';
    }).length;

    this.pendingInvoicesCount = this.invoiceList.filter(d => {
      return d.invoiceStatus != 'Paid';
    }).length;
  }
}
