<!-- Header Component-->
<!--<p-toast position="top-center" life="30000"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
  <div class="container">
    <section #sectionAgency class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
      <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
        <div class="overlaypaneldiv" id="overlaymain agency-subs-overlay">
          <p-panel class="pngPanelMain" id="patients-pngPanelMain">
            <p-header>{{title? title: 'Generate '}} {{translate(labels.entityNameAgency)}}
              <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                  (click)="showHideSearchDialog()" [hidden]="showSearchBtn" pButton pTooltip="Advanced Search"
                  tooltipPosition="bottom" type="button">
                </button>
                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                  (click)="showHideAddDialog()" [hidden]="showAddBtn" pButton
                  pTooltip="{{addEditTitle}} {{translate(labels.entityNameAgency)}}" tooltipPosition="bottom"
                  type="button">
                </button>
              </div>
            </p-header>

            <!--Advance Search DialogBox-->
            <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"
              class="overlaypaneldiv" id="overlaypaneldiv-search">
              <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameAgency)}}</p-header>
                <form (ngSubmit)="onSearch()" [formGroup]="agencySearch" class="pngForm"
                  id="invitationpage-searchmemeber-pngForm">
                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyTitle)}}</label> <span class="p-input-icon-right">
                        <i (click)="fieldReset('agencyTitle')" *ngIf="diplayeIconInField('agencyTitle')"
                          class="pi pi-times color-danger">
                        </i>
                        <input class="pngInputtext" formControlName="agencyTitle" name="agencyTitle" pInputText
                          type="text">
                      </span>
                    </div>
                    <div class="p-field p-col-12  p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyType)}} </label>
                      <p-dropdown [filter]="true" [options]="agencyTypeList" [showClear]="true" class="pngDropdown"
                        formControlName="agencyType" placeholder="Select"></p-dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyName)}}</label> <span class="p-input-icon-right"><i
                          (click)="fieldReset('agencyName')" *ngIf="diplayeIconInField('agencyName')"
                          class="pi pi-times color-danger"></i> <input class="pngInputtext" formControlName="agencyName"
                          name="agencyName" pInputText type="text"> </span>
                    </div>
                  </div>
                  <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                    <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                    <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button" class="pngBlueBtn">
                    </p-button>
                    <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                      class="pngBtnTransparent"></p-button>
                  </div>
                </form>
              </p-panel>
            </div>
            <!--Add DialogBox-->
            <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"
              class="overlaypaneldiv" id="overlaypaneldiv-add">
              <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                <p-header>{{addEditTitle}} {{translate(labels.entityNameAgency)}}</p-header>
                <form (ngSubmit)="onSubmit()" [formGroup]="agencyForm" class="pngForm"
                  id="invitationpage-addmemeber-pngForm">
                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyTitle)}}</label> <span class="p-input-icon-right"><i
                          (click)="fieldReset('agencyTitle')" *ngIf="diplayeIconInField('agencyTitle')"
                          class="pi pi-times color-danger"></i> <input
                          [class.p-invalid]="inputFieldDanger('agencyTitle')" class="pngInputtext"
                          formControlName="agencyTitle" name="agencyTitle" pInputText type="text"></span>
                      <app-custom-error [control]="'agencyTitle'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyFirstname)}}</label>
                      <span class="p-input-icon-right">
                        <i (click)="fieldReset('agencyFirstname')" *ngIf="diplayeIconInField('agencyFirstname')"
                          class="pi pi-times color-danger">
                        </i>
                        <input [class.p-invalid]="inputFieldDanger('agencyFirstname')" class="pngInputtext"
                          formControlName="agencyFirstname" name="agencyFirstname" pInputText type="text">
                      </span>
                      <app-custom-error [control]="'agencyFirstname'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyLastname)}}</label> <span
                        class="p-input-icon-right"><i (click)="fieldReset('agencyLastname')"
                          *ngIf="diplayeIconInField('agencyLastname')" class="pi pi-times color-danger"></i> <input
                          [class.p-invalid]="inputFieldDanger('agencyLastname')" class="pngInputtext"
                          formControlName="agencyLastname" name="agencyLastname" pInputText type="text">
                      </span>
                      <app-custom-error [control]="'agencyLastname'" [form]="agencyForm"></app-custom-error>
                    </div>

                    <div class="p-field p-col-12  p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyType)}}</label>
                      <p-dropdown [filter]="true" [options]="agencyTypeList" [showClear]="true" class="pngDropdown"
                        formControlName="agencyType" placeholder="Select">
                      </p-dropdown>
                      <app-custom-error [control]="'agencyType'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyName)}}</label> <span class="p-input-icon-right"><i
                          (click)="fieldReset('agencyName')" *ngIf="diplayeIconInField('agencyName')"
                          class="pi pi-times color-danger"></i> <input
                          [class.p-invalid]="inputFieldDanger('agencyName')" class="pngInputtext"
                          formControlName="agencyName" name="agencyName" pInputText type="text"></span>
                      <app-custom-error [control]="'agencyName'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyAddress)}}</label> <span class="p-input-icon-right"><i
                          (click)="fieldReset('agencyAddress')" *ngIf="diplayeIconInField('agencyAddress')"
                          class="pi pi-times color-danger"></i>
                        <input [class.p-invalid]="inputFieldDanger('agencyAddress')" class="pngInputtext"
                          formControlName="agencyAddress" name="agencyAddress" pInputText type="text">
                      </span>
                      <app-custom-error [control]="'agencyAddress'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyPhoneNumber)}}</label>
                      <p-inputNumber [class.p-invalid]="inputFieldDanger('agencyPhoneNumber')"
                        class="p-invalid pngInputnumber" formControlName="agencyPhoneNumber" name="agencyPhoneNumber"
                        [useGrouping]="false" pNumberInput type="number">
                      </p-inputNumber>
                      <app-custom-error [control]="'agencyPhoneNumber'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnAgencyEmail)}}</label>
                      <span class="p-input-icon-right">
                        <i (click)="fieldReset('agencyEmail')" *ngIf="diplayeIconInField('agencyEmail')"
                          class="pi pi-times color-danger">
                        </i>
                        <input [class.p-invalid]="inputFieldDanger('agencyEmail')" class="pngInputtext"
                          formControlName="agencyEmail" name="agencyEmail" pInputText type="text">
                      </span>
                      <app-custom-error [control]="'agencyEmail'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnCommunicationPrefs)}}</label>
                      <p-selectButton [options]="communicationPrefList" formControlName="communicationPrefs"
                        [optionValue]="communicationPrefList['listOfValuesId']">
                      </p-selectButton>
                      <app-custom-error [control]="'communicationPrefs'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnMarketingInfo)}}</label>
                      <p-selectButton [options]="marketingInfoList" formControlName="marketingInfo"
                        [optionValue]="marketingInfoList['listOfValuesId']"></p-selectButton>
                      <app-custom-error [control]="'marketingInfo'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12  p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnBilling)}}</label>
                      <p-dropdown [filter]="true" [options]="billingList" [showClear]="true" class="pngDropdown"
                        formControlName="billing" placeholder="Select">
                      </p-dropdown>
                      <app-custom-error [control]="'billing'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12  p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnInvoiceInfo)}}</label>
                      <p-dropdown [filter]="true" [options]="invoiceInfoList" [showClear]="true" class="pngDropdown"
                        formControlName="invoiceInfo" placeholder="Select">
                      </p-dropdown>
                      <app-custom-error [control]="'invoiceInfo'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12  p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnUniqueReferenceNumber)}}</label>
                      <p-dropdown [filter]="true" [options]="uniqueRefList" [showClear]="true" class="pngDropdown"
                        formControlName="uniqueReferenceNumber" placeholder="Select">
                      </p-dropdown>
                      <app-custom-error [control]="'uniqueReferenceNumber'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12  p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnReferralSource)}}</label>
                      <p-dropdown [filter]="true" [options]="referralSourceList" [showClear]="true" class="pngDropdown"
                        formControlName="referralSource" placeholder="Select">
                      </p-dropdown>
                      <app-custom-error [control]="'referralSource'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnNumberOfUsers)}}</label>
                      <p-inputNumber [class.p-invalid]="inputFieldDanger('numberOfUsers')" [useGrouping]="false"
                        class="p-invalid pngInputnumber" formControlName="numberOfUsers" mode="decimal"
                        name="numberOfUsers" pNumberInput type="number"></p-inputNumber>
                      <app-custom-error [control]="'numberOfUsers'" [form]="agencyForm"></app-custom-error>
                    </div>
                    <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnUserEmail)}}</label>
                      <span class="p-input-icon-right">
                        <i (click)="fieldReset('userEmail')" *ngIf="diplayeIconInField('userEmail')"
                          class="pi pi-times color-danger">
                        </i>
                        <input [class.p-invalid]="inputFieldDanger('userEmail')" class="pngInputtext"
                          formControlName="userEmail" name="userEmail" pInputText [readOnly]="editTag" type="text">
                      </span>
                      <app-custom-error [control]="'userEmail'" [form]="agencyForm"></app-custom-error>
                      <span *ngIf="duplicateEmail" class="duplicate-Email">Email already exists</span>
                    </div>
                    <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.agencyColumnUserPassword)}}</label>
                      <span class="p-input-icon-right">
                        <i (click)="fieldReset('userPassword')" *ngIf="diplayeIconInField('userPassword')"
                          class="pi pi-times color-danger rightallign">
                        </i>
                        <input [class.p-invalid]="inputFieldDanger('userPassword')" class="pngInputtext"
                          formControlName="userPassword" name="userPassword" pInputText [readOnly]="editTag"
                          [type]="passwordType">
                        <i [ngClass]="passwordType === 'text'?'pi pi-eye':'pi pi-eye-slash'"
                          (click)="showHidePassword()"></i>
                      </span>
                      <app-custom-error [control]="'userPassword'" [form]="agencyForm"></app-custom-error>
                    </div>
                  </div>
                  <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                    <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                      type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                    <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button"
                      class="pngBtnTransparent"></p-button>
                  </div>
                </form>
              </p-panel>
            </div>
            <!--Subscription checklist div-->
            <div *ngIf="tableDataToggle" class="subsChecklistdiv">
              <div class="helping-and-chart-wrap p-grid paddingBottom" id="checklist-subTitle-Bar">
                <div class="p-md-9 p-col-12 headerbarwidth toolbarContent">
                  <div class="toolbarwrapL">
                    <p-toolbar styleClass="toolbars">
                      <div class="p-toolbar-group-left">
                        <h4 class="headertext pToolBarText textBold">{{checklistTitle}}</h4>
                      </div>
                      <div class="p-toolbar-group-right">
                        <h5 class="headertext pToolBarText textBold">{{checklistDate | date: 'yyyy-MM-dd'}}</h5>
                      </div>
                    </p-toolbar>
                  </div>
                </div>
                <div class="p-col-1"></div>
                <div class="p-md-2 p-col-12 knobContent ">
                  <div class="toolbarwrapR">
                    <p-knob [(ngModel)]="knobValue" [strokeWidth]="8" [size]="230" [readonly]="true"
                      valueTemplate="{value}%"></p-knob>
                  </div>
                </div>
              </div>

              <div class="p-grid checkListTabs">
                <div *ngFor="let info of selectedChecklist, let i = index">
                  <div class="checkListTabsCard">
                    <!--                                        <div class="topActiveIcon" [ngClass]="{'hidden':info.itemsStatus === 'Draft'}">-->
                    <div class="topActiveIcon" [ngClass]="{'hidden':info.itemsStatus === 'Pending'}">
                      <i class="pi pi-check-circle"
                        [ngClass]="{'icon-color-success':info.itemsStatus === 'Completed'}"></i>
                    </div>
                    <div (click)="stepsDone(i,info)" class="p-tag icon-center"
                      [ngClass]="{'p-tag-green': info.itemsStatus === 'Completed','p-tag-grey' : info.itemsStatus === 'Pending' }">
                      <!--                                            <img *ngIf="info.itemsStatus === 'Pending'" src="{{imageList[i].iconGrey}}" class="checkListTabsIcon">-->
                      <!--                                            <img *ngIf="info.itemsStatus === 'Completed'" src="{{imageList[i].iconGreen}}" class="checkListTabsIcon">-->
                      <img *ngIf="info.itemsStatus === 'Pending'" src="{{incompleteImagesourceList[i]}}"
                        class="checkListTabsIcon">
                      <img *ngIf="info.itemsStatus === 'Completed'" src="{{activeImagesourceList[i]}}"
                        class="checkListTabsIcon">
                    </div>
                    <h6 class="tab-desc">{{info.checklistItemsId.label}}</h6>
                  </div>
                </div>
              </div>
            </div>
            <!--Table Data -->
            <div class="overlaypaneldiv manage-subs-btns" id="overlaypaneldiv-list">
              <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                <p-header>
                  <span class="p-input-icon-right globalSearch" id="invitationpage-memberslist-globalsearch">
                    <i class="pi pi-search" (click)="onGlobalSearchClick(global.value)"></i>
                    <input #global class="pngInputtext" pInputText
                      placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}' type="text" />
                  </span>
                </p-header>

                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                  [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'" [lazy]="true"
                  [paginator]="true" [resizableColumns]="true" [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                  [showCurrentPageReport]=true [alwaysShowPaginator]="false" [totalRecords]="totalRecords"
                  [value]="agencyList" class="pngTable checkListTable"
                  [ngClass]="{'pngTableEmptyMessage': agencyList?.length == 0}" id="agency-subs-table"
                  styleClass="p-datatable-striped">
                  <ng-template pTemplate="header">
                    <tr>
                      <th> Title</th>
                      <th> Company Type</th>
                      <th> Company Name</th>
                      <th> Status</th>
                      <th> Percentage</th>
                      <th>{{translate(labels.headerAction)}}</th>
                    </tr>
                  </ng-template>
                  <ng-template let-element let-i="rowIndex" pTemplate="body">
                    <tr>
                      <td> {{element?.agencyTitle}} </td>
                      <td> {{element?.agencyType.agencyTypeTitle}} </td>
                      <td> {{element?.agencyName}} </td>
                      <td>
                        <div class="p-tag tableStatusTag"
                          [ngClass]="{'p-tag-warning': element?.percentage < 100 ,'p-tag-success':  element?.percentage === 100}">
                          {{element?.status}}
                        </div>
                      </td>
                      <td>
                        <span class="p-mb-1 progressValueText">{{element?.percentage}}%
                          <p-progressBar [value]="element?.percentage" [showValue]="false"></p-progressBar>
                        </span>
                      </td>
                      <td>
                        <div class="pngTable-icons">
                          <p-button label="" pTooltip="{{title === 'Deactivated' ? 'Activate': 'Deactivate'}}"
                                    (click)="changeAgencyStatus(element, i)"
                                    [hidden]="hideActiveDeactive"
                                    [icon]="title === 'Deactivated' ? 'activate-icon': 'deactivate-icon'"
                            styleClass="p-button pbtn-transparent">
                          </p-button>



                          <p-button label="" (click)="loadAgencyToEdit(element.agencyId, i)" icon="pi pi-pencil"
                            styleClass="p-button pbtn-transparent" pTooltip="Edit">
                          </p-button>

                          <p-button label="" (click)="deleteByIdAgency(element.agencyId,i)" icon="pi pi-trash"
                            styleClass="p-button pbtn-transparent" pTooltip="Delete">
                          </p-button>

                          <p-button label="" (click)="viewAgencyDetail(element.agencyId)" icon="pi pi-eye"
                            styleClass="p-button pbtn-transparent" pTooltip="View">
                          </p-button>


                          <button type="button" pButton icon="pi pi-ellipsis-v" label="" class="menu-actions"
                          (click)="menu.toggle($event); getIndex(i, element.agencyId, element?.percentage, element?.agencyName, element?.insertionTimestamp)"></button>
                        <p-menu #menu
                                [popup]="true"
                                [model]="items"
                                appendTo="body"
                                class="action-btns-menu"
                                styleClass="p-button pbtn-transparent">
                        </p-menu>

                          <!-- <p-button label="Manage Subscription" (click)="getUserSubscriptionList(element.agencyId, i)"
                            icon="pi pi-eye" styleClass="p-button pbtn-transparent">
                          </p-button> 

                          <p-button label="OnBoarding Steps" (click)="showModalDialog(element.agencyId)"
                            icon="pi pi-plus" styleClass="p-button pbtn-transparent">
                          </p-button>

                          <p-button label="{{buttonIndex === i ? 'Hide OnBoarding': 'View OnBoarding'}}"
                            icon="pi pi-eye"
                            (click)="showCheckListStats(i,element?.agencyId,element?.percentage,element?.agencyName, element?.insertionTimestamp)"
                            styleClass="p-button pbtn-transparent">
                          </p-button> -->
                          <!--Subscription Checklist Dialog -->
                          <p-dialog header="Subscription Checklist"
                                    [(visible)]="displayModal"
                                    [modal]="true"
                                    [style]="{width: '50vw'}"
                                    [baseZIndex]="10000"
                                    [draggable]="false">
                            <div *ngFor="let checkbox of subscriptionChecklist; let i = index"
                              class="p-field-checkbox subsCheckboxView">
                              <input type="checkbox" value="" id="flexCheckChecked"
                                (change)="onChange(checkbox,i,element)" [checked]="checkbox.itemsStatus !== 'Draft'"
                                [disabled]="checkbox.itemsStatus === 'Completed'" />
                              <label>{{checkbox.checklistItemsId.label}}</label>
                            </div>

                            <ng-template pTemplate="footer">
                              <p-button icon="pi pi-check" (click)="saveSubscription(element)" label="Ok"
                                styleClass="p-button-text"></p-button>
                            </ng-template>
                          </p-dialog>

<!--Manage Subscription dialog box-->
                          <p-dialog header="Manage Subscription"
                                    [(visible)]="subscriptionViewModal"
                                    [modal]="true"
                                    [style]="{width: '50vw', height: '75%'}"
                                    [baseZIndex]="10000"
                                    [draggable]="false">

                            <ng-template *ngIf="!userSubscriptionList" pTemplate="emptymessage">
                              {{translate(labels.mat_pagination_no_data_msg)}}
                            </ng-template>

                            <div *ngFor="let item of userSubscriptionList; let i = index"
                              class="p-field-checkbox subsCheckboxView manageSubsClass">
                              <div class="p-grid manageSubsGrid">
                                <div class="p-col-6 manageSubsLabel">
                                  <label class="manageSubsLabel">{{translate(item?.userSubscriptionTitle)}}</label>
                                </div>
                                <!--                                                                <div class="p-col-2"></div>-->
                                <div class="p-col-6 manageSubsSelectBtn">
                                  <p-selectButton [(ngModel)]="item.value" [name]="item.value"
                                    [optionValue]="manageOptions['value']" [options]="manageOptions"
                                    (onChange)="onSelect(item,i,element, $event)">
                                  </p-selectButton>
                                </div>
                              </div>
                            </div>
                            <ng-template pTemplate="footer">
                              <div class="p-d-flex p-jc-end">
                                <div class="p-mr-3">
                                  <p-button (click)="cancelUserSubscription()" label="Cancel" styleClass="p-button"
                                    class="pngBtnTransparent">
                                  </p-button>
                                </div>
                                <div *ngIf="userSubscriptionList && userSubscriptionList.length > 0">
                                  <p-button (click)="saveUserSubscription($event)" label="Save" styleClass="p-button"
                                    class="pngBlueBtn">
                                  </p-button>
                                </div>
                              </div>
                            </ng-template>
                          </p-dialog>
<!--////////-->
                        </div>
                      </td>

                    </tr>
                  </ng-template>
                  <!--                                    <ng-template pTemplate="emptymessage">{{translate(labels.mat_pagination_no_data_msg)}}</ng-template>-->
                </p-table>
                <div *ngIf="totalRecords < 1">
                  <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show
                  </h3>
                </div>
              </p-panel>
            </div>
          </p-panel>
        </div>
      </div>
    </section>
  </div>
</section>
