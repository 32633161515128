import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { SharedService } from '../sharedservice/shared.service';
import { Observable } from 'rxjs';
import { ApplicationI } from '../../utilities/interfaces/interfaces';
import { AppSettings } from '../../utilities/constants/constants';
import {Constant} from "../../util/constant";

@Injectable({
  providedIn: 'root'
})
export class WebService {

  private _apiEndPoint: any = "";
  private _initialUmsUrl: any = "";
  private notificationHttp: HttpClient;
  constructor(private http: HttpClient,private handler: HttpBackend, private sharedService: SharedService) {
    this.notificationHttp = new HttpClient(handler);
   }

  private XIGTCoreUrl = this.getApiEndPoint() + AppSettings.NTFY_API_ENDPOINT;
  private searchSuggesUrl = AppSettings.SNOWMED_API_ENDPOINT + 'search/suggest?';
  private searchSearchUrl = AppSettings.SNOWMED_API_ENDPOINT + 'search/search?';
  private getByConceptUrl = AppSettings.SNOWMED_API_ENDPOINT + 'lookup/concept?';
  // private getByRelationshipUrl = AppSettings.SNOWMED_API_ENDPOINT + 'lookup/relationship';
  private exploreAllDescendantsUrl = AppSettings.SNOWMED_API_ENDPOINT + 'explore/alldescendants?';
  private exploreParentsUrl = AppSettings.SNOWMED_API_ENDPOINT + 'explore/parents?';
  private exploreChildrenUrl = AppSettings.SNOWMED_API_ENDPOINT + 'explore/children?';
  private exploreConceptUrl = AppSettings.SNOWMED_API_ENDPOINT + 'explore/concept?';
  private mapUrl = AppSettings.SNOWMED_API_ENDPOINT + 'map/icdmap?';

  //  Ums Urls
  private applicationUrl = AppSettings.UMS_API_ENDPOINT + 'getMenu';
  private addQuickLinkUrl = AppSettings.UMS_API_ENDPOINT + 'addQuickLink';
  private deleteQuickLinkUrl = AppSettings.UMS_API_ENDPOINT + 'deleteQuickLink';
  private getLanguageUrl = AppSettings.UMS_API_ENDPOINT + 'getLanguage';
  private getVersionsUrl = AppSettings.UMS_API_ENDPOINT + 'getVersions';
  private addUpdateUserPreferencesUrl = AppSettings.UMS_API_ENDPOINT + 'addUpdateUserPreferences';
  private getConstantsUrl = AppSettings.UMS_API_ENDPOINT + 'getConstants';
  private translateLabelUrl = AppSettings.TRANSLATION_API_ENDPOINT + 'TranslateService/translateLabel';
  private getLoggedInUserDetailUrl = AppSettings.UMS_API_ENDPOINT + 'getLoggedInUserDetail';
  private addVersionUrl = AppSettings.UMS_API_ENDPOINT + 'addVersion';
  private findUserUrl = AppSettings.UMS_API_ENDPOINT + 'findUser';
  private getUserPreferenceUrl = AppSettings.UMS_API_ENDPOINT + 'getUserPreference';
  private findApplicationUrl = AppSettings.UMS_API_ENDPOINT + 'findApplication';
  private getAllApplicationUrl = AppSettings.UMS_API_ENDPOINT + 'getAllApplication';
  private getBusinessRoleListUrl = AppSettings.UMS_API_ENDPOINT + 'getBusinessRole';

  //  XIGTCore Urls
  private copyProjectUrl = AppSettings.XIGT_API_ENDPOINT + 'ProjectService/copyProject';

  //  Ntfy Urls
  // New Subscription Services
  private notificationTypeUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/getNtfyTypeList'
  private templateUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/getTemplateList'
  private subscribeUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/subscribeNewEvent'
  private allSubscribedEventsUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/getAllSubscribedEvents'
  private unSubscribeEventUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/unsubscribEvent'
  // All notification Services
  private getAllNotificationUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/';
  private notificationsUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getNotifications';
  private allNotificationsCountUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getAllNotificationsCount';
  private allNotificationsUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getAllNotifications';
  private updateStatusToReadUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/updateNotificationStatustoRead';
  private getNextNotificationUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getNextNotification';
  private deleteNotificationUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/deleteNotification';
  private markAsReadUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/markAsRead';
  // Register Event Service
  private registerEvent_Url = AppSettings.NTFY_API_ENDPOINT + 'EventRegisterServiceClass/registerNewEvent';

  private switchApplicationDetailsUrl = AppSettings.UMS_API_ENDPOINT + 'switchApplicationDetails';

  // Snowmed

  searchSuggest(searchTerm, state, semanticTag, acceptability) {
    return this.http.get<any>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.searchSuggesUrl + "term=" + searchTerm + "&state=" + state + "&semantictag=" + semanticTag + "&acceptability=" + acceptability + "&returnlimit=" + AppSettings.SNOWMED_TOTAL_NUMBER_OF_Suggestion + "&groupbyconcept=false&refsetid=null&parentid=null");
  }
  searchSearch(searchTerm, state, semanticTag, acceptability) {
    return this.http.get<any>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.searchSearchUrl + "term=" + searchTerm + "&state=" + state + "&semantictag=" + semanticTag + "&acceptability=" + acceptability + "&returnlimit=" + AppSettings.SNOWMED_TOTAL_NUMBER_OF_ITEMS + "&refsetid=null&parentid=null&groupbyconcept=false");
  }
  getByConcept() {
    return this.http.get<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getByConceptUrl, {});
  }
  // getByRelationship() {
  //   return this.http.get<string>(this.getByRelationshipUrl, {});
  // }
  exploreAllDescendants() {
    return this.http.get<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.exploreAllDescendantsUrl, {});
  }
  exploreParents() {
    return this.http.get<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.exploreParentsUrl, {});
  }
  exploreChildren() {
    return this.http.get<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.exploreChildrenUrl, {});
  }
  exploreConcept() {
    return this.http.get<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.exploreConceptUrl, {});
  }
  map(conceptId) {
    return this.http.get<any>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.mapUrl + "id=" + conceptId);
  }

  switchApplicationDetails(token, appId) {
    return this.http.post<ApplicationI[]>(this._initialUmsUrl + this.switchApplicationDetailsUrl, { "applicationId": appId, "url": this.getInitialUrl(), "token": token });
  }


  // Get Menu WebService Calling
  getMenu(user, type, appId, projectId, activatedRole): Observable<ApplicationI[]> {
    if (projectId) { }
    else { projectId = 0 }
    if (activatedRole) { }
    else { activatedRole = 0 }

    console.log("this.sharedService.getSession('userActivatedRole') " , this.sharedService.getSession("userActivatedRole"));
    return this.http.post<ApplicationI[]>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.applicationUrl, { "userId": user, "type": type, "appId": appId, "projectId": projectId, "businessRoleId": this.sharedService.getSession("userActivatedRole") });
  }

  getLanguage() {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getLanguageUrl, {});
  }

  getLoggedInUserDetail() {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getLoggedInUserDetailUrl, { "token": this.sharedService.getCookie('token') });
  }

  getVersions(projectId) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getVersionsUrl, { "cprojectId": projectId, "clientId": parseInt(this.sharedService.getCookie("client")) });
  }

  addVersion(title, type) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.addVersionUrl, { "projectId": this.sharedService.projectId, "clientId": parseInt(this.sharedService.getCookie("client")), "userId": parseInt(this.sharedService.getCookie("loggedInUser")), "title": title, "type": type });
  }

  findUser() {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.findUserUrl, { "userId": parseInt(this.sharedService.getCookie("loggedInUser")) });
  }

  getUser(userId) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.findUserUrl, { "userId": userId });
  }

  getQuickLinks(userId) {

    return this.http.post<string>(Constant.AUTH_SERVICE + "menu/user/" + userId +"/quickLinks" , {});
  }

  getSideMenu(userId) {


    return this.http.get<string>(Constant.AUTH_SERVICE + "menu/user/" + userId , {});
  }



  // Add Update User Preferences
  addUpdateUserPreferences(languageId, skin, version, userPreferencesId) {
    if (languageId == null) {
      languageId = 0;
    }
    else {
      skin = ""
    }
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.addUpdateUserPreferencesUrl, { "userPreferences": { "language": languageId, "userPreferenceId": userPreferencesId, "skin": skin, "version": version }, "userId": this.sharedService.getCookie("loggedInUser") });;
  }

  // Add Update User Preferences
  updateUserPreferences(userPreferences) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.addUpdateUserPreferencesUrl, { userPreferences, "userId": this.sharedService.getCookie("loggedInUser") });;
  }

  // Add QuickLink WebService Calling
  addQuickLink(user, appId): Observable<string> {

    return this.http.post<string>(Constant.UMS_URL + 'addQuickLink', { "userId": user, "appRoleId": appId });
  }

  //delete QuickLink WebService Calling
  deleteQuickLink(quickLinkId): Observable<string> {
    return this.http.post<string>(Constant.BASE_URL + this.deleteQuickLinkUrl, { "quickLinkId": quickLinkId });
  }

  // Copy Project
  copyProject(title) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.XIGT_APPLICATION_ID) + this.copyProjectUrl, { "sourceProjectId": this.sharedService.projectId, "title": title, "loggedInUserId": this.sharedService.getCookie("loggedInUser") });
  }

  // Get Forms Constants
  // getFormConstants(formId) {
  //   var languageId: any;
  //   if (this.sharedService.getCookie("userPreference")) {
  //     var languageId = JSON.parse(this.sharedService.getCookie("userPreference"))['defaultLanguage'];
  //   }
  //   else {
  //     languageId = 1;
  //   }
  //   return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getConstantsUrl, { "form": formId, "language": languageId });
  // }

  getFormConstants(formId) {
    let langId;
    if (this.sharedService.getCookie("userPreference") != '' && this.sharedService.getCookie("userPreference") != undefined) {
      console.log(this.sharedService.getCookie("userPreference"))
      var userPref = JSON.parse(this.sharedService.getCookie("userPreference"));
    }
    if (this.sharedService.getCookie("userPreference") != '' && this.sharedService.getCookie("userPreference") != undefined && userPref['defaultLanguage']) {
      langId = JSON.parse(this.sharedService.getCookie("userPreference"))['defaultLanguage']
    } else {
      langId = 1;
    }
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getConstantsUrl, { "form": formId, "language": langId });
  }

  translateLabel(formId, label) {
    // let langId;
    // if (this.sharedService.getCookie("userPreference") != '' && this.sharedService.getCookie("userPreference") != undefined) {
    //   console.log(this.sharedService.getCookie("userPreference"))
    //   var userPref = JSON.parse(this.sharedService.getCookie("userPreference"));
    // }
    // if (this.sharedService.getCookie("userPreference") != '' && this.sharedService.getCookie("userPreference") != undefined && userPref['defaultLanguage']) {
    //   langId = JSON.parse(this.sharedService.getCookie("userPreference"))['defaultLanguage']
    // } else {
    //   langId = 1;
    // }
    var languageId = 1
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.translateLabelUrl, { data: { appId: this.sharedService.getCookie('applicationId'), "formId": formId, "languageID": languageId, label: label, translated: label } });
  }

  getProjectBusinessRoleList(userId, page, businessRoleId, projectId, type) {
    var projectObject = this.sharedService.getCookie("project");
    return this.http.post<any>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getBusinessRoleListUrl, { "cprojectId": projectId, "userId": userId, "page": page, "businessRoleId": businessRoleId, "itemsPerPage": AppSettings.ITEMS_PER_PAGE, "type": type });
  }

  // Notification Services

  // All Applications WebService Calling
  getAllApplications(user, type, appId): Observable<string> {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.applicationUrl, { "userId": user, "type": type, "appId": appId, "projectId": 0, "businessRoleId": 0 });
  }

  // All Events WebService Calling
  getAllEvents(user, type, appId): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.applicationUrl, { "userId": user, "type": type, "appId": appId })
  }

  // All NotificationTypes WebService Calling
  getAllNtfyTypes(): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.notificationTypeUrl, {})
  }

  // All TemplateList WebService Calling
  getAllTemplates(ntfyType): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.templateUrl, { "ntfyType": ntfyType })
  }

  // Subscribe New Event WebService Calling
  subscribeNewEvent(jsonObject): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.subscribeUrl, { "subscription": jsonObject })
  }

  // get All Subscribed Events WebService Calling
  getSubscribedEvents(jsonObject): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.allSubscribedEventsUrl, { "subscription": jsonObject })
  }

  // unSubscribe Event WebService Calling
  unsubscribeEvent(eventtoUnsubscribe): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.unSubscribeEventUrl, { "event": eventtoUnsubscribe })
  }

  //----------------------- All Notification Services --------------------------

  // Get Max 10 Notifications in Menu WebService Calling
  getNotifications(user, timeStamp): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.notificationsUrl, { userId: user, timeStamp: timeStamp });
  }

  // Get All Notifications WebService Calling
  getAllNotificationsCount(user): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.allNotificationsCountUrl, { userId: user });
  }

  // Get All Notifications WebService Calling
  getAllNotifications(user, pageNo, itemsPerPage): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.allNotificationsUrl, { userId: user, pageNo: pageNo, itemsPerPage: itemsPerPage });
  }

  // Update Notification Status to Read
  updateNotificationStatustoRead(ntfyEventDetailId, user): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.updateStatusToReadUrl, { ntfyEventLogDetailId: ntfyEventDetailId, userId: user });
  }

  // Get Next Notification
  getNextNotification(ntfyEventDetailIds, user): Observable<string> {
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getNextNotificationUrl, { ntfyEventLogDetailIds: ntfyEventDetailIds, userId: user });
  }

  // Get Next Notification
  deleteNotification(ntfyEventDetailId): Observable<string> {
    if (ntfyEventDetailId == null) {
      var jsonParams = {}
    }
    else {
      jsonParams = { ntfyEventLogDetailId: ntfyEventDetailId }
    }
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.deleteNotificationUrl, jsonParams);
  }

  // Get Next Notification
  markAsRead(ntfyEventDetailId): Observable<string> {
    if (ntfyEventDetailId == null) {
      var jsonParams = {}
    }
    else {
      jsonParams = { ntfyEventLogDetailId: ntfyEventDetailId }
    }
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.markAsReadUrl, jsonParams);
  }

  // Exception Handling
  // exceptionHandling(error, serviceName): Observable<string> {
  //   return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + 'HiveExceptionLogger/rest/ExpLog/ExpLogging', { "AppName": this.sharedService.applicationName, "exceptionLog": error, "userId": this.sharedService.getCookie("loggedInUser"), "serviceName": serviceName });
  // }

  registerEvent(projectId, applicationId, fromUser, eventId, JSONParameters): Observable<string> {  //  Register Event

    if (projectId === null || applicationId === null) {
      projectId = 0; applicationId = 0;
    }
    this.sharedService.Print(projectId + applicationId + fromUser + eventId + JSONParameters);
    return this.notificationHttp.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.registerEvent_Url, { projectId: projectId, applicationId: applicationId, fromUser: fromUser, eventId: eventId, JSONParameters: JSONParameters });
  }

  getUserPreference(userId) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getUserPreferenceUrl, { "userId": userId });
  }

  getUserDetail(token) {
    return this.http.post(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.getLoggedInUserDetailUrl, { "token": token });
  }

  findApplication(appId) {
    return this.http.post(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + this.findApplicationUrl, { "appId": appId });
  }

  getAllApplication() {
    return this.http.post(this.getInitialUrl() + this.getAllApplicationUrl, {});
  }

  post(obj, url) {
    var mapForm = document.createElement("form");
    // mapForm.target = "_blank";
    // mapForm.method = "post"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  public setApiEndPoint(apiEndPoint) {
    this._apiEndPoint = apiEndPoint;
  }

  public getApiEndPoint() {
    return this._apiEndPoint;
  }

  public setInitialUrl(initialUrl) {
    this._initialUmsUrl = initialUrl;
  }

  public getInitialUrl() {
    return this._initialUmsUrl;
  }

  applicationPath = "RRE";
  serviceBaseLocation = "/Hive-Worx";
  templateClassPath = "/RreTemplate";
  reportClassPath = "/RreReports";
  dataSourceClassPath = "/RreDatasources";
  getRenderChartOrReport(obj) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.VRE_APPLICATION_ID) + this.applicationPath + this.serviceBaseLocation + this.reportClassPath + "/renderChartOrReport",
      obj);
  }

  generatePDF(html) {
    return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.VRE_APPLICATION_ID) + this.applicationPath + this.serviceBaseLocation + this.reportClassPath + "/generatePDF", {
      html
    });

  }

}
