import {Component, OnInit} from '@angular/core';

@Component({
               selector: 'app-analytics',
               templateUrl: './analytics.component.html',
               styleUrls: ['./analytics.component.css']
           })
export class AnalyticsComponent implements OnInit {


    newCompanies: any;
    cancelledSubscription: any;
    usage: any;
    differentPortals: any;
    chartSmall: boolean;
    innerWidth: any;

    constructor() {
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 376) {
            this.chartSmall = false;
        } else {
            this.chartSmall = true;
        }

        this.newCompanies = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
            datasets: [
                {
                    label: 'Number',
                    backgroundColor: '#009aff',
                    data: [65, 11, 5, 81, 16]
                },
                {
                    label: 'Average',
                    backgroundColor: '#43d5cb',
                    data: [28, 18, 20, 19, 7]
                }
            ]
        };
        this.cancelledSubscription = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
            datasets: [
                {
                    label: 'Number',
                    backgroundColor: '#009aff',
                    data: [65, 12, 30, 81, 16]
                },
                {
                    label: 'Average',
                    backgroundColor: '#43d5cb',
                    data: [10, 50, 20, 15, 65]
                }
            ]
        };
        this.usage = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
            datasets: [
                {
                    label: 'Number',
                    backgroundColor: '#009aff',
                    data: [65, 59, 30, 81, 16]
                },
                {
                    label: 'Average',
                    backgroundColor: '#43d5cb',
                    data: [8, 30, 55, 90, 23]
                }
            ]
        };
        this.differentPortals = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
            datasets: [
                {
                    label: 'Number',
                    backgroundColor: '#009aff',
                    data: [11, 54, 60, 22, 16]
                },
                {
                    label: 'Average',
                    backgroundColor: '#43d5cb',
                    data: [10, 50, 20, 15, 65]
                }
            ]
        };
    }

}
