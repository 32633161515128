import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
// import { AlertService } from './../../services/alertservice/alert.service';
// import { SharedService } from './../../services/sharedservice/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AppSettings } from './../../utilities/constants/constants';
// import { WebService } from './../../services/webservice/web.service';
// import { UserPreferences } from './../../utilities/interfaces/interfaces';
// import { CollaborationSharedServiceService } from '../../collaboration/services/collaboration-shared-service/collaboration-shared-service.service';
// import { ResetPasswordService } from '../../services/reset-password-service/reset-password.service';
import { Router } from '@angular/router';
import {AlertService} from '../services/alertservice/alert.service';
import {CollaborationSharedServiceService} from '../collaboration/services/collaboration-shared-service/collaboration-shared-service.service';
import {WebService} from '../services/webservice/web.service';
import {ResetPasswordService} from '../services/reset-password-service/reset-password.service';
import {MyErrorStateMatcher} from '../utilities/constants/error-matcher';
import {SharedService} from '../services/sharedservice/shared.service';
import {AppSettings} from '../collaboration/constants';
import {UserPreferences} from '../utilities/interfaces/interfaces';
// import { MyErrorStateMatcher } from '../../utilities/constants/error-matcher';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.css']
})
export class UserProfileDialogComponent implements OnInit {
  userPreference: any;
  element;
  constantList: string[];
  hide1 = false;
  hide2 = false;
  hide3 = false;
  matcher: any;
  oldLanguage: number = 0;
  changePasswordForm: FormGroup;
  public pageControlMap = new Map<string, boolean>().set("addUpdateUserPreferences", false);
  languages: string[];
  btnshow = 0;
  userPreferenceForm = new FormGroup({
    language: new FormControl(""),
    chatIinterval: new FormControl(""),
    notificationIinterval: new FormControl(""),
    userPreferenceId: new FormControl(''),
    project: new FormControl(''),
  })
  userRoles: string[];
  userPref: any;
  constructor(private alertService: AlertService,
    public localService: SharedService,
    private formBuilder: FormBuilder,
    private router: Router,
    private collaborationSharedService: CollaborationSharedServiceService,
    private cookieService: CookieService,
    private webService: WebService,
    private sharedService: SharedService,
    private resetPasswordService: ResetPasswordService,
    public dialogRef: MatDialogRef<UserProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.userPref = data['userPreferences'];
    console.log("data :: ", data)
  }


  ngOnInit() {

    this.element = this.data['user'];
    this.matcher = new MyErrorStateMatcher();
    if (this.localService.getCookie(this.localService.getCookie("applicationShortName") + '_roles') && this.localService.getCookie(this.localService.getCookie("applicationShortName") + '_roles') != undefined) {
      this.userRoles = this.localService.getCookie(this.localService.getCookie("applicationShortName") + '_roles');
      this.pageControlMap = this.localService.checkRole(this.pageControlMap, this.userRoles);
    }
    // this.pageControlMap = this.localService.checkRole(this.pageControlMap, this.userRoles);
    this.getConstants(AppSettings.USER_PROFILE_FORM_ID);
    this.getUserPreferences(this.localService.getCookie("loggedInUser"))
    this.getLanguages();
    // this.userPreferenceForm.controls['project'].disable()

    this.initiateResetPasswordFormField()
    this.checkVersion()
  }

  currentVersion = this.sharedService.getCookie("currentVersion");
  checkVersion() {
    this.sharedService.CurrentVersion.subscribe(data => {
      this.currentVersion = data;
    });
  }

  getLanguages() {
    this.webService.getLanguage().subscribe((data: string) => {
      if (data != undefined && data != null) {
        if (data['message'] = "Success") {

          ;
          this.languages = data['data'] as string[];

        }
      }
    });

  }

  addUpdateUserPreferences() {
    console.log("form :: ", this.userPreferenceForm.value)
    this.webService.updateUserPreferences(this.userPreferenceForm.value).subscribe((data: string) => {
      console.log("form :: ", this.userPreferenceForm.value)
      if (data != undefined && data != null) {
        if (data['message'] = "Success") {
          let up = data['data'][0] as UserPreferences;
          let userPreferenceCookie = {} as UserPreferences;
          userPreferenceCookie.chatInterval = up['chatInterval'];

          userPreferenceCookie.defaultLanguage = up['defaultLanguage'] != undefined ? up['defaultLanguage']['languageId'] : 0;
          userPreferenceCookie.defaultProject = up['defaultProject']['projectId'];
          userPreferenceCookie.defaultProjectRole = up['defaultProjectRole']['businessRolesId'];
          userPreferenceCookie.notificationInterval = up['notificationInterval'];
          userPreferenceCookie.userPreferenceId = up['userPreferenceId'];

          this.localService.timeStamp = userPreferenceCookie.notificationInterval;
          this.collaborationSharedService.timeStamp = userPreferenceCookie.chatInterval;


          this.localService.setCookies("userPreference", JSON.stringify(userPreferenceCookie));
          this.alertService.showSuccess("Create", "User Preference")
          if (this.oldLanguage != undefined && up['defaultLanguage'] != undefined && this.oldLanguage != up['defaultLanguage']['languageId']) {
            var url = window.location.href;
            this.cookieService.set("umsUrl", this.localService.umsUrl, null, "/");
            this.cookieService.set("applicationId", this.localService.getCookie("applicationId"), null, "/");
            this.cookieService.set("token", this.localService.getCookie("token"), null, "/");
            // this.cookieService.set("token", this.localService.getCookie("token"));
            // this.cookieService.set("applicationId", "1");
            // this.cookieService.set("umsUrl", AppSettings.UMSServerURL);
            window.location.assign(url);
          }
        } else {
          this.alertService.showError("Create", "User Preference")

        }
      } else {
        this.alertService.showError("Create", "User Preference")
      }

    });

  }

  getUserPreferences(userId) {

    // this.webService.getUserPreference(userId).subscribe((data: string) => {
    // if (data != undefined && data != null) {

    //   if (data['message'] == "Success") {
    // added by me now;
    this.userPreference = this.userPref;
    if (this.userPreference) {
      let up = this.userPreference as UserPreferences
      // this.userPreference = data['data'][0];

      // let up = data['data'][0] as UserPreferences;
      console.log(up)
      let userPreferenceCookie = {} as UserPreferences;
      userPreferenceCookie.chatInterval = up['chatInterval'];
      userPreferenceCookie.defaultLanguage = up['defaultLanguage'] != undefined ? up['defaultLanguage']['languageId'] : 0;
      this.oldLanguage = up['defaultLanguage'] != undefined ? up['defaultLanguage']['languageId'] : 0;
      userPreferenceCookie.defaultProject = up['defaultProject']['projectId'];
      userPreferenceCookie.defaultProjectRole = up['defaultProjectRole']['businessRolesId'];
      userPreferenceCookie.notificationInterval = up['notificationInterval'];
      userPreferenceCookie.userPreferenceId = up['userPreferenceId'];

      this.userPreferenceForm.patchValue({
        language: up['defaultLanguage'] != undefined ? up['defaultLanguage']['languageId'] : 0,
        chatIinterval: up['chatInterval'],
        notificationIinterval: up['notificationInterval'],
        userPreferenceId: up['userPreferenceId'],
        project: up['defaultProject']['title'],
      })

      console.log("User Preferences Value", this.userPreferenceForm.value)

    }

    // }
    // }
    // });
  }

  verifyLabel(label) {

    return this.localService.verifyLabel(label, this.constantList , AppSettings.USER_PROFILE_FORM_ID);
  }

  getConstants(formId) {
    this.webService.getFormConstants(formId).subscribe((data: string) => {
      this.constantList = data['data'] as string[];
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  resetForm() {
    if (this.userPreference != undefined) {
      this.userPreferenceForm.patchValue({
        language: this.userPreference['defaultLanguage'] != undefined ? this.userPreference['defaultLanguage']['languageId'] : "",
        chatIinterval: this.userPreference['chatInterval'],
        notificationIinterval: this.userPreference['notificationInterval'],
        userPreferenceId: this.userPreference['userPreferenceId'],
        project: this.userPreference['defaultProject']['title']
      })
    } else {
      this.userPreferenceForm = new FormGroup({
        language: new FormControl(""),
        chatIinterval: new FormControl(""),
        notificationIinterval: new FormControl(""),
        userPreferenceId: new FormControl(''),
        project: new FormControl('')
      })
    }
  }


  changeTab(event) {
    if (event['index'] == 0) {
      this.btnshow = 0;
    } else {
      this.btnshow = 1;
    }
  }


  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  changePasswordSubmit() {
    // console.log(this.changePasswordForm.value)
    this.resetPasswordService.changePassword(this.changePasswordForm.value).subscribe((data: string) => {
      if (data['statusCode'] == 200) {
        this.alertService.showCustomSuccessAlert(data['documentation']);
        this.initiateResetPasswordFormField();
        console.log(this.element.userId, this.localService.getCookie("loggedInUser"))
        if (this.element.userId === parseInt(this.localService.getCookie("loggedInUser"))) {
          //  console.log("I am here")
          // this.router.navigate(['/logout'])
          this.logout();

        }
      } else {
        this.alertService.showCustomErrorAlert(data['documentation']);
      }
    })
  }

  initiateResetPasswordFormField() {
    this.changePasswordForm = this.formBuilder.group({
      email: this.element.email,
      oldPassword: ['', [Validators.required, Validators.pattern(/^\S{0,50}$/)]],
      password: ['', [Validators.required, Validators.pattern(/^\S{0,50}$/), Validators.minLength(8), Validators.maxLength(15)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
    });
    Object.keys(this.changePasswordForm.controls).forEach(key => {

      // console.log(key);
      this.changePasswordForm.controls[key].setErrors(null)
    });
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  logout() {
    this.checkUrl(AppSettings.UMS_APPLICATION_ID, AppSettings.APPLICATION_SHORT_NAME, '/logout');
  }

  checkUrl(applicationId, appName, route) {
    var url = window.location.href;
    if (url.includes(appName)) {
      this.router.navigate([route]);
    } else {
      if (this.sharedService.getApplicationFrontEndUrl(applicationId)) {
        window.location.assign(this.sharedService.getApplicationFrontEndUrl(applicationId) + route);
      }
      else {
        window.location.assign('https://dev.hive-worx.com/' + AppSettings.APPLICATION_SHORT_NAME + route);
      }
    }
  }


}
