import { Component, OnInit } from '@angular/core';
import Painterro from 'painterro'
import { SharedService } from '../web-services/shared.service';
import { ImageFileUploadService } from '../web-services/image-file-upload.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService, ConfirmationService } from '../../../node_modules/primeng/api';
import { Labels } from '../util/labels';


@Component({
  selector: 'app-image-file-upload',
  templateUrl: './image-file-upload.component.html',
  styleUrls: ['./image-file-upload.component.css']
})
export class ImageFileUploadComponent implements OnInit {
  bodyAny: any;
  showImages: boolean = false;
  visibleFlag;
  constructor(private confirmation: ConfirmationService,
              private sharedService: SharedService,
              private imageFileService: ImageFileUploadService,
              private messageService: MessageService,
              private loader: NgxUiLoaderService
  ) { }
  fileUrls = [];
  sessionDetails;
  public labels = Labels;

  ngOnInit(): void {
    // this.newPainterro();

    this.visibleFlag = true;
  }

  // newPainterro(){
  //   var ptro =new Painterro();
  //    ptro = Painterro({
  //     fixMobilePageReloader: false,
  //     id: 'customPainterro',
  //     colorScheme: {
  //       main: '#fdf6b8',
  //       control: '#FECF67' // change controls color
  //     },

  //     hiddenTools: ['close'],

  //     saveHandler:  (image, done) => {
  //       // of course, instead of raw XHR you can use fetch, jQuery, etc
  //       // var xhr = new XMLHttpRequest();
  //       console.log(image, done);
  //       const type = 'image/png';
  //       const files = new File([image.asBlob(type)],  image.suggestedFileName(), {
  //           type: type,
  //       });
  //         let url = this.sharedService.uploadFile(files);
  //         url.subscribe(res => {
  //             console.log(res);
  //           this.fileUrls.push(res);
  //           done(true);
  //         });
  //     }
  //   });
  //   ptro.show();
  // }

  saveImage() {
    // console.log("save imagesss");

    var ptro = Painterro({


      saveHandler: (image, done) => {
        // of course, instead of raw XHR you can use fetch, jQuery, etc
        // var xhr = new XMLHttpRequest();
        // console.log(image, done);
        const type = 'image/png';
        const file = new File([image.asBlob(type)], image.suggestedFileName(), {
          type: type,
        });
        done(true);


        // xhr.open("POST", "http://127.0.0.1:5000/save-as-base64/");
        // xhr.setRequestHeader("Content-Type", "application/json");
        // xhr.send(JSON.stringify({
        //   image: image.asDataURL()
        // }));
        // xhr.onload = function (e) {
        //   // after saving is done, call done callback
        //   done(true); //done(true) will hide painterro, done(false) will leave opened
        // }
      },
      activeColor: '#00b400'  // change active color to green
    });
    ptro.show()

    // console.log(ptro);



  }



  fileUrlEmitterFunc(event) {
    // console.log(event);
    this.visibleFlag = false;
    // this.fileUrls.push(event);
    if (sessionStorage.getItem("patientId") != null && sessionStorage.getItem("expertId") && sessionStorage.getItem("appointmentId") && sessionStorage.getItem("loggedInRefId")) {
      let data = {
        patientId: parseInt(sessionStorage.getItem("patientId")),
        expertId: parseInt(sessionStorage.getItem("expertId")),
        appointmentId: parseInt(sessionStorage.getItem("appointmentId")),
        userId: parseInt(sessionStorage.getItem("loggedInRefId")),
        fileurls: event
      }
      this.loader.start();

      this.imageFileService.addImageFile(data).subscribe(res => {
        console.log(res);
        this.messageService.add(
          {
            severity: 'success',
            summary: this.translate(this.labels.entityNameImage) +
                this.translate(this.labels.addedSuccessfully),
            // detail: this.translate(this.labels.entityNameImage)
          });
        setTimeout(() => {                           // <<<---using ()=> syntax
          this.visibleFlag = true;
        }, 1000);
        this.loader.stop();

      })

    }else{
      this.messageService.add(
        {
          severity: 'warn',
          summary: this.translate("Image Not Added "),
          detail: this.translate(this.labels.entityNameImage)
        });
    }


  }

  findAllImages() {

    let imageFileUpload = {
      patientId: parseInt(sessionStorage.getItem("patientId")),
      expertId: parseInt(sessionStorage.getItem("expertId")),
      appointmentId: parseInt(sessionStorage.getItem("appointmentId")),
      userId: parseInt(sessionStorage.getItem("loggedInRefId")),
    }

    // let data = {imageFileUpload:imageFileUpload};
    this.imageFileService.findAllImages(imageFileUpload, 0, 0).subscribe(res => {
      console.log(res);
      if (res['message'] == 'Success') {
        this.showImages = true;
        this.fileUrls = res['data'];
      }else {
        this.showImages = true;
      }
    });
  }
  constantList = [];
  translate(label) {
    return this.sharedService.verifyLabel(label, this.constantList);
  }
  deleteImage(file) {

    // this.confirmation.confirm({
    //   message: this.translate(this.labels.DELETE_MSG), accept: () => {

    this.imageFileService.deleteByIdImage(file.imageFileUploadId).subscribe(res => {
      if (res) {
        let index = this.fileUrls.findIndex(res => res.imageFileUploadId == file.imageFileUploadId);
        this.fileUrls.splice(index, 1);
        this.messageService.add(
          {
            severity: 'warn',
            summary: this.translate(this.labels.entityNameImage) +
                this.translate(this.labels.deletedSuccessfully),
            detail: ''
            // detail: this.translate(this.labels.entityNameImage)
          });
      }
    })
    // }
    // })



  }

  getFile(file) {
    console.log(file['fileurls'])
    return file['fileurls'];
  }

  downloadImageFile(url) {
    console.log(url);
  }

  forceDownload(url) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = 'Image';
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
    xhr.send();
  }
}
