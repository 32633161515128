import {Constant} from '../../util/constant';
import {MultiSelect} from '../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class SocialHistoryMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['occupation']: {

      name: Constant.SOCIAL_LOV_OCCUPATION,
      dbField: 'occupation',
      label: 'Occupation',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    },
    ['adl']: {
      name: Constant.SOCIAL_LOV_ADL,
      dbField: 'adl',
      label: 'ADL',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['mobility']: {
      name: Constant.SOCIAL_LOV_MOBILITY,
      dbField: 'mobility',
      label: 'Mobility',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['hobbies']: {
      name: Constant.SOCIAL_LOV_HOBBIES,
      dbField: 'hobbies',
      label: 'Hobbies',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
  };


  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
