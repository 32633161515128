import { Component, OnInit } from '@angular/core';
import {ComponentFormBase} from "../util/ComponentFormBase";
import {ClientDetailsService} from "../web-services/client-details.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-cpa-clients',
  templateUrl: './cpa-clients.component.html',
  styleUrls: ['./cpa-clients.component.css']
})
export class CpaClientsComponent implements OnInit {

  constructor(private componentFormBase: ComponentFormBase,
              private clientDetailService: ClientDetailsService,
              private messageService: MessageService
              ) { }

  baseComponent = this.componentFormBase;
  clients = [];

  ngOnInit(): void {

    this.getClients();

    this.baseComponent.baseForm = new FormGroup(
      {

        companyName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),

      }
    )
  }

  onSubmit(): void {


    this.clientDetailService.addClientCpa(this.baseComponent.baseForm.value).subscribe(

      response => {

       if (response.status === 200) {

         this.messageService.add({
           severity: 'success',
           summary: this.baseComponent.translate(this.baseComponent.labels.entityNameClientDetails) +
             this.baseComponent.translate(this.baseComponent.labels.addedSuccessfully),
         });

         this.baseComponent.showHideAddDialog();


       }

      },
    err => {
      this.messageService.add({
        severity: 'error',
        summary: this.baseComponent.translate(this.baseComponent.labels.entityNameClientDetails) +
         "Client was not saved fully",
      });

      console.error('err: ', err);
      this.baseComponent.stopLoader();
    });


  }

  getClients(): void {

    this.clientDetailService.findAllClients().subscribe(
      response => {
        if (response.status === 200) {
          this.clients = response.body;

        }
        this.baseComponent.stopLoader();
      }, err => {


        console.error('err: ', err);
        this.baseComponent.stopLoader();
      });


  }

}
