import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";

@Injectable({providedIn: 'root'})
export class TreatmentService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdTreatment(treatmentId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Treatment/deleteTreatment', {'treatmentId': treatmentId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countTreatment(treatment): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Treatment/count', {'treatment': treatment}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateTreatment(treatment, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Treatment/updateTreatment', [treatment, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addTreatment(treatment, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Treatment/addTreatment', [treatment, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

  createTreatment(treatmentDTO: FormDataDTO): Observable<any> {
    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'treatments', treatmentDTO, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));
  }

    findAllTreatment(treatment, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Treatment/findAllTreatment',
            {'treatment': treatment, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
    // custom service for global search


    findAllTreatmentsByPatientId(patientId: number): Observable<any> {
      return this.httpClient.get<any>(
        Constant.CPA_SPRING_API_URL + 'patients/' + patientId  + '/treatments', {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      ).pipe(
        retry(3), catchError(Constant.handleError));

    }

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Treatment/countGlobalTreatment',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Treatment/findGlobalTreatment',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }


}
