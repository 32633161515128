<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deleteTestingMuscle" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameTestingMuscle)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameTestingMuscle)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameTestingMuscle)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="testingMuscleSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnMuscleGroup)}} </label>
                                            <p-dropdown [filter]="true" [options]="muscleGroupList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="muscleGroup"
                                                        placeholder="Select"></p-dropdown>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnMuscleSide)}} </label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="muscleSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnComment)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('comment')"
                                                                              *ngIf="diplayeIconInField('comment')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="comment"
                                                name="comment"
                                                pInputText
                                                type="text">
                                        </span>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnMeasures)}} </label>
                                            <p-dropdown [filter]="true" [options]="measureList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="measures"
                                                        placeholder="Select"></p-dropdown>
                                        </div>

                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <form (ngSubmit)="onSubmit()" [formGroup]="testingMuscleForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnMuscleGroup)}}</label>
                                            <p-dropdown [filter]="true"
                                                        [options]="muscleGroupList"
                                                        [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="muscleGroup"
                                                        placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'muscleGroup'" [form]="testingMuscleForm"></app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnMuscleSide)}}</label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="muscleSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                            <app-custom-error [control]="'muscleSide'" [form]="testingMuscleForm"></app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnComment)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('comment')"
                                                   *ngIf="diplayeIconInField('comment')"
                                                   class="pi pi-times color-danger">
                                                </i>
                                                <input  [class.p-invalid]="inputFieldDanger('comment')"
                                                        class="pngInputtext"
                                                        formControlName="comment"
                                                        name="comment"
                                                        pInputText
                                                        type="text">
                                            </span>
                                            <app-custom-error [control]="'comment'" [form]="testingMuscleForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingMuscleColumnMeasures)}}</label>
                                            <p-dropdown [filter]="true"
                                                        [options]="measureList"
                                                        [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="measures"
                                                        placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'measures'" [form]="testingMuscleForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext"
                                               pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="testingMuscleList.length > 0"
                                         [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true"
                                         [paginator]="true"
                                         [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]"
                                         [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [alwaysShowPaginator]="false"
                                         [totalRecords]="totalRecords"
                                         [value]="testingMuscleList"
                                         class="pngTable"
                                         [ngClass]="{'pngTableEmptyMessage': testingMuscleList?.length == 0}"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Muscle Group</th>
                                            <th> Muscle Side</th>
                                            <th> Comment</th>
                                            <th> Measures</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.muscleGroup}} </td>
                                            <td> {{element?.muscleSide}} </td>
                                            <td> {{element?.comment}} </td>
                                            <td> {{element?.measures}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadTestingMuscleToEdit(element.testingMuscleId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdTestingMuscle(element.testingMuscleId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
