import {Constant} from "../util/constant";
import * as moment from 'moment';

export class AppointmentFormDataDTO {

  patientId: any;
  expertId: any;
  caseId: any;
  clinicId: any;
  isInitialAssessment = true;
  appointmentDate: any;
  appointmentStartTime: any;
  appointmentEndTime: any;
  appointmentStartDateTimeAsString: string;
  appointmentEndDateTimeAsString: string;
  appointmentTypeId =  Constant.APPOINTMENT_NEW_ID;
  clinicExpertScheduleId: any;
  isFollowUp: boolean;

  status = 'Confirmed';


  constructor(patientId: number, expertId: number, caseId: number, clinicId: number, formData: any, chosenEvent: any) {

    this.patientId = patientId;
    this.expertId = expertId;
    this.clinicId = clinicId;
   // this.patientId = { patientId: patientId };
   // this.expertId = { expertId: expertId };
    this.caseId = { caseId: caseId };
   // this.clinicId = { clinicId: clinicId };
    this.addFormDataToDTO(formData, this);
    this.addAppointmentEvent(chosenEvent, this);

  }

  addFormDataToDTO(formData: any, currentClass: AppointmentFormDataDTO): void {

    for (let key in formData.controls) {
      currentClass[key] =  formData.controls[key].value;
    }

  }

  addAppointmentEvent(chosenEvent: any, currentClass: AppointmentFormDataDTO): void {

      currentClass.appointmentDate = moment(chosenEvent.start).format('YYYY-MM-DD');
      currentClass.appointmentStartTime = moment(chosenEvent.start).format('HH:mm');
      currentClass.appointmentEndTime =  moment(chosenEvent.end).format('HH:mm');
      currentClass.appointmentStartDateTimeAsString=  chosenEvent.startStr;
      currentClass.appointmentEndDateTimeAsString =  chosenEvent.endStr;
      currentClass.clinicExpertScheduleId = chosenEvent.extendedProps.clinicExpertScheduleId;
  }
}
