import { Injectable } from '@angular/core';
import { SharedService } from '../sharedservice/shared.service';
import { AppSettings } from '../../utilities/constants/constants';
import { NonInterceptService } from '../non-intercept/non-intercept.service';
import { CookieService } from 'ngx-cookie-service';
import { WebService } from '../webservice/web.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private sharedService: SharedService,
    private nonInterceptService: NonInterceptService,
    private cookieService: CookieService,
    private webService: WebService) { }

  updateCurrentRole(applicationUrl, category_url, application_shortName) {
    var activatedRole = this.sharedService.getCookie('activatedRole');
    sessionStorage.setItem("activatedRole", activatedRole);
    if (!activatedRole) {
      activatedRole = AppSettings.ZERO;
    }
    if (this.sharedService.getCookie("applicationId")) {
      var applicationId = this.sharedService.getCookie("applicationId");
    }
    this.nonInterceptService.updateCurrentRole(activatedRole, 0, applicationId).subscribe(data => {
      if (data) {
        this.sharedService.setCookies('token', data['token']);
        this.sharedService.setCookies('applicationShortName', application_shortName);
        this.sharedService.setCookies(application_shortName + "_roles", data['applicationRole']);
        this.sharedService.setCookies("expirationDate", data['expirationDate']);
        if (applicationUrl != null) {
          // console.log(applicationUrl + category_url)
          this.getTokenAndNavigate(applicationUrl + category_url);
        }
      }
      else { }
    }, error => {
      // this.sharedService.checkHttpCodes(error);
    })
  }

  getTokenAndNavigate(applicationUrl) {
    var sessions = this.createSessionObject(this.sharedService.getCookie("applicationId"), this.sharedService.getCookie("token") , this.sharedService.rocketChatUserTokenForIframe);
    if (this.checkDomainIsSameOrNot(applicationUrl)) {
      this.cookieService.set("applicationId", this.sharedService.getCookie("applicationId"), null, '/');
      this.cookieService.set("token", this.sharedService.getCookie("token"), null, '/');
      this.cookieService.set("rocketChatUserTokenForIframe", this.sharedService.rocketChatUserTokenForIframe, null, '/');
      
      window.location.assign(applicationUrl)
    }
    else {
      // this.webService.post(sessions, "http://localhost:4201/tree");
      // console.log(applicationUrl);
      this.webService.post(sessions, applicationUrl);
    }
  }

  createSessionObject(applicationId, token , rocketChatUserTokenForIframe) {
    var sessionObject = {
      "applicationId": applicationId,
      "token": token , 
      "rocketChatUserTokenForIframe" : rocketChatUserTokenForIframe,
    }
    return sessionObject;
  }

  checkDomainIsSameOrNot(applicationUrl) {
    var origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    var applicationUrl = applicationUrl;
    if (applicationUrl.includes(origin)) {
      return true;
    }
    return false;
  }

}
