import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ListOfValuesService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdListOfValues(listOfValuesId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValues/deleteListOfValues', {'listOfValuesId': listOfValuesId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countListOfValues(listOfValues): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'ListOfValues/count', {'listOfValues': listOfValues}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateListOfValues(listOfValues): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'ListOfValues/updateListOfValues', [listOfValues], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addListOfValues(listOfValues): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'ListOfValues/addListOfValues', [listOfValues], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllListOfValues(listOfValues, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ListOfValues/findAllListOfValues',
            {'listOfValues': listOfValues, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllListOfValuesByName(listOfValueName): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValues/findListOfValuesByName', {'listOfValueName': listOfValueName}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllListOfValuesByCat(listOfValueName,refId,refType): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValues/findListOfValuesByCat', {'listOfValueName': listOfValueName,"ref_type":refType,"ref_id":refId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    //custome service to get all list of values in a form by passing just array of those labels
    getAllListOfValuesByName(listOfValueNamesArray): Observable<string>{
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValues/getAllListOfValuesByName', {'lov_names_array': listOfValueNamesArray}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addListOfValueByCat(catString,lovValue){
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValues/addListOfValueByCategory', {'categoryString': catString,'lovValue':lovValue}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

  findListOfValuesDiagnosisByListOfValuesId(listOfValuesId: number): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'list-of-values-diagnoses/list-of-values/' + listOfValuesId ,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));
  }

  createListOfCaseDiagnosisPredictions(caseDiagnosisPredictionsList: any): Observable<any> {

    return this.httpClient.post<any>(
      Constant.CPA_SPRING_API_URL + 'case-diagnosis-predictions/collection' ,  caseDiagnosisPredictionsList, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));

  }

  createListOfCaseRedFlags(caseRedFlags: any): Observable<any> {

    return this.httpClient.post<any>(
      Constant.CPA_SPRING_API_URL + 'case-red-flags/collection' ,  caseRedFlags, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));

  }

  getListOfCaseRedFlagsByCase(caseId: number): Observable<any> {

    return this.httpClient.get<any>(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'red-flags/case/' + caseId ,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));

  }




}
