import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { CompanyUsersService } from '../web-services/company-users.service';
import { UsersService } from '../web-services/users.service';
import { SessionService } from '../services/session.service';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../services/validation.service';
import {ChatService} from '../collaboration/services/chat/chat.service';
import { SubscriptionService } from '../web-services/subscription.service';
import {HttpStatusCode} from '@angular/common/http';
import {UserLevelService} from '../web-services/user-level.service';
import {User} from '../dto/User';

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-company-users',
               templateUrl: './company-users.component.html',
               styleUrls: ['./company-users.component.css'],
               providers: [DialogService]
           })
export class CompanyUsersComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateCompanyUsersId = null;
    usersList: any = [];
    companyUsersList: any = [];
    companyUsersSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    companyUsersForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    passwordType = 'password';
    loggedInUserType: any;
    loggedInRefId: any;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    addEditTitle = '';
    searchTerm = null;
    userLevelList: any = [];
    clientId: number;
    user: User;
    userLevelPermissions: any = [];

    constructor(
        private companyUsersServices: CompanyUsersService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService,
        private dialogService: DialogService,
        private _validation: ValidationService,
        private chatService: ChatService,
        private subscriptionService: SubscriptionService,
        private userLevelService: UserLevelService,
    ) {
    };
    ref: DynamicDialogRef;

    get addUpdateTitle() {
        return this.updateCompanyUsersId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.companyUsersSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countCompanyUsers();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countCompanyUsers() {
        this.companyUsersSearch.controls['companyId'].setValue(this.loggedInRefId);
        this.companyUsersServices.countCompanyUsers(this.companyUsersSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllCompanyUsers();
            } else {
                this.totalRecords = 0;
                this.companyUsersList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

  getDefaultUserLevels() {

    this.stopLoader();

    this.userLevelService.findDefaultUserLevels().subscribe(
      res => {

        if (res.status === HttpStatusCode.Ok) {
          this.userLevelList = res.body;

          this.stopLoader();
        }
      }, err => {
        console.error('err: ', err);
      });

  }

    findAllCompanyUsers() {
        this.startLoader();
        this.companyUsersSearch.controls['companyId'].setValue(this.loggedInRefId);
        this.companyUsersServices.findAllCompanyUsers(this.companyUsersSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.companyUsersList = res['data'];
                } else {
                    this.totalRecords = 0;
                    this.companyUsersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    userLevelChanged() {

      let chossenUserLevelId = this.companyUsersForm.controls['userLevelId'].value;
      this.userLevelPermissions = this.userLevelList.find(ul => ul.userLevelId === chossenUserLevelId).permissions;


    }

    onSubmit() {

        this.companyUsersForm.controls['clientId'].setValue(this.clientId);


        this.companyUsersForm.markAllAsTouched();


        console.log("company users form :: ", this.companyUsersForm.value);
        if (this.companyUsersForm.valid) {

          this.usersServices.addClientUser(this.companyUsersForm.value).subscribe(res => {

            alert(res.status);

            if (res.status === HttpStatusCode.Created) {
              this.messageService.add({
                severity: 'success',
                summary: 'User Added Successfully',
                detail: 'User Added Successfully'
              });
              this.findAllCompanyUsers();
              this.submitted = false;
             // this.onClearAdd();
            }
          }, err => {

            this.messageService.add({
              severity: 'error',
              summary: err
            });
          });

          }


           // this.addUpdateCompanyUsers(this.companyUsersForm.value);
          //  this.submitted = false;
            //this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllCompanyUsers();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.companyUsersSearch.reset();
        this.countCompanyUsers();
        // this.findAllCompanyUsers();
    }

    addUpdateCompanyUsers(companyUsers) {
        this.startLoader();
        if (this.updateCompanyUsersId == null) {
            this.companyUsersServices.addCompanyUsers(this.companyUsersForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    companyUsers = res['data'][0];
                    this.companyUsersList.push(companyUsers);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameClinics) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameCompanyUsers)
                        });

                    // Register Event on user creation
                    let subscribeObj = {
                        "subscription": {
                            "loggedInUser": res['data'][0].userId.toString(),
                            "project": "1",
                            "event": [
                                { "id": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                    "title": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                    "Code": "New Message" }
                            ],
                            "notificationType": 3,
                            "template": 4,
                            "applicationId": 5
                        }
                    }
                    this.chatService.subscribeEvent(subscribeObj).subscribe(res => {
                        if (res['message'] == 'Success') {
                            console.log('user subscribed Successfully');
                        } else {
                            console.log('Error');
                        }
                    });

                    this.submitted = true;
                    this. showHideAddDialog();
                    this.countCompanyUsers();
                } else {
                    this.messageService.add(
                        {
                            severity: 'error',
                            summary: this.translate(this.labels.btnAdd),
                            detail: 'Max Number of Users Reached'
                        });
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            companyUsers.companyUsersId = this.updateCompanyUsersId;
            this.companyUsersServices.updateCompanyUsers(companyUsers).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.companyUsersList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate(this.labels.btnUpdate),
                        detail: this.translate(this.labels.entityNameCompanyUsers)
                    });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateCompanyUsersId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {

        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));

        this.user =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser'));

        this.clientId = this.user.clientId;

      // this.findAllUsers();
        this.companyUsersForm = new FormGroup({
            userId: new FormControl(),
            clientId: new FormControl(0),
            username : new FormControl('',[Validators.required, Validators.pattern(this._validation.regex.email)]),
            firstName : new FormControl('',Validators.required),
            lastName : new FormControl('',Validators.required),
            userLevelId: new FormControl('',Validators.required),
        });
        this.companyUsersSearch = new FormGroup({
            companyId: new FormControl(),
            userId: new FormControl(),
            parentUserId: new FormControl(),
            userName : new FormControl(),
            loginEmail : new FormControl(),
          ///  password : new FormControl()
        });

     ///   this.countCompanyUsers();
        this.getDefaultUserLevels();
      ///  this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.companyUsersSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    deleteByIdCompanyUsers(companyUsersId: number, index) {
        this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.companyUsersServices.deleteByIdCompanyUsers(companyUsersId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.companyUsersList.splice(index, 1);
                        this.messageService.add({
                            severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameCompanyUsers) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                            // detail: this.translate(this.labels.entityNameCompanyUsers)
                        });
                        this.countCompanyUsers();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }

    loadCompanyUsersToEdit(companyUsersId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let companyUsers = this.companyUsersList.find(x => x['companyUsersId'] == companyUsersId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateCompanyUsersId = companyUsers['companyUsersId'];
        this.selectedIndex = index;
        this.companyUsersForm.controls['companyId'].setValue(companyUsers['companyId']);
        this.companyUsersForm.controls['userId'].setValue(companyUsers['userId']);
        this.companyUsersForm.controls['mUserId'].setValue(companyUsers['mUserId']['mUserId']);
        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        // this.disableColumn = true;
    }

    onClearAdd() {
        this.companyUsersForm.reset();
        this.companyUsersForm.markAsUntouched();
        this.updateCompanyUsersId = null;
        // closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.companyUsersForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.companyUsersForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.companyUsersForm.get(control)?.valid && this.companyUsersForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // closeAddOverlaydiv();
        this.companyUsersForm.reset();
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.companyUsersSearch.reset();
    }

    refresh() {
        this.countCompanyUsers();
    }

    onAddOverlayDiv() {
        // openAddOverlaydiv();
        this.companyUsersForm.reset();
    }

    showHidePassword() {
        if (this.passwordType === 'text') {
            this.passwordType = 'password';
        } else {
            this.passwordType = 'text';
        }
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.companyUsersForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }

    viewUserDetail(companyUsersId){
        this.ref = null;
        let companyUsers = this.companyUsersList.find(x => x['companyId'] == companyUsersId);
        console.log('its company users', companyUsers);
        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Schedule Detail',
            width: '80%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {

                mainData: [
                    {label: this.labels.entityNameCompanyUsers, value: companyUsers['userName']},
                    {label: this.labels.companyUsersColumnLoginEmail, value: companyUsers['loginEmail']},
                    {label: this.labels.companyUsersColumnPassword, value: companyUsers['password']}
                ]
            }
        });
        this.ref.onClose.subscribe(res => {
            // this.countInvoice();
        });
    }

    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.companyUsersGlCount();
    }

    companyUsersGlCount() {
        this.startLoader();
        // this.companyUsersSearch.controls['companyId'].setValue(this.loggedInRefId);
        this.companyUsersServices.companyUsersGlcount(this.searchTerm, this.loggedInRefId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchCompanyUsers();
            } else {
                this.totalRecords = 0;
                this.companyUsersList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    findAllGlSearchCompanyUsers() {
        this.companyUsersServices.findGlobalCompanyUsers(this.searchTerm, this.loggedInRefId, this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.companyUsersList = result['data'];
            } else {
                this.companyUsersList = [];
            }
            console.log(result, 'result');
            this.stopLoader();
        });
    }

    userSubscriptionList: any = [];
    subscriptionViewModal = false;
    manageOptions = [
        {label: 'Required', value: 'Active'},
        {label: 'Not Required', value: 'Inactive'}
    ];
    showManageSubscription(userId){
        this.getUserSubscriptionList(userId);
        this.subscriptionViewModal = true;
    }

    userId : any;
    getUserSubscriptionList(userId) {
        this.startLoader();
        this.userSubscriptionList = [];
        this.userId = userId;
        this.subscriptionService.findUserSubscription(userId).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.userSubscriptionList = res['data'];
                    console.log('user subs data : ', this.userSubscriptionList);
                    this.userSubscriptionList.forEach(element=>{
                        element['value'] = element['subscriptionStatus']
                    });
                    this.subscriptionViewModal = true;
                } else {
                    this.userSubscriptionList = [];
                    this.subscriptionViewModal = true;
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    onSelect(item: any, i: number, element: any, event) {
        if(event.value === 'Active'){
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Active';
        }else {
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Inactive';
        }
        // console.log(this.userSubscriptionList, 'changed user subscription list');
    }

    cancelUserSubscription() {
        this.subscriptionViewModal = false;
    }

    saveUserSubscription(event) {
        this.startLoader();
        // let jsonObj = {'userSubscriptionList': this.userSubscriptionList};
        this.subscriptionService.activateUserSubscriptionlist(this.userId,Constant.USER_TYPE_CORPORATE,this.userSubscriptionList).subscribe(res => {
            if (res['message'] == 'Success') {
                console.log('check list item updated => ', res['data']);
                this.subscriptionViewModal = false;
                this.stopLoader();
            } else {
                this.subscriptionViewModal = false;
                this.stopLoader();

            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    refreshSubs(patientUserId) {
        this.subscriptionService.refreshSubscriptionlist(this.loggedInRefId,Constant.USER_TYPE_CORPORATE,patientUserId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.userSubscriptionList = [];
                this.userSubscriptionList = res['data'];
                this.userSubscriptionList.forEach(element=>{
                    element['value'] = element['subscriptionStatus']
                });
                this.stopLoader();
            } else {
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });

    }
}
