import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SessionService} from './session.service';
import {element} from "protractor";

@Injectable({
                providedIn: 'root'
            })
export class DataSharedService {

    constructor(
        private sessionService: SessionService
    ) {
    }

    private loggedIn = new BehaviorSubject<boolean>(false);
    umsApplicationFrontendUrl;
    private isMenuInitialized = new BehaviorSubject<boolean>(false);

    public setIsMenuInitialized(value) {
        this.isMenuInitialized.next(value);
    }

    get IsMenuInitialized() {
        return this.isMenuInitialized.asObservable();
    }

    get isLoggedIn() {
        return this.loggedIn.asObservable(); // {2}
    }

    checkIsUserLoggedIn(value) {
        this.loggedIn.next(value);
    }

    roles = new Array<string>();

    getRoles() {
        return this.roles;
    }

    setRoles(roles) {
        this.roles = roles;
    }

    setUmsApplicationFrontendUrl(umsApplicationFrontendUrl) {
        this.umsApplicationFrontendUrl = umsApplicationFrontendUrl;
    }

    getUmsApplicationFrontendUrl() {
        return this.umsApplicationFrontendUrl;
    }

    roleMatch(allowedRoles): boolean {

        if (sessionStorage.getItem('token') && sessionStorage.getItem('cpa_roles')) {

          var isMatch = false;

            let userRoles: any[] = JSON.parse(sessionStorage.getItem('cpa_roles'));

            //var userRoles: string[] = JSON.parse(this.sessionService.getSession('cpa_roles'));

            userRoles.forEach(userRole => {

              if (allowedRoles.includes(userRole.authority)) {
                  isMatch = true;
              }
          });
        }
        return isMatch;
    }
}
