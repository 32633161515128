import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ExercisesService} from '../web-services/exercises.service';
import {ExerciseCategoryService} from '../web-services/exercise-category.service';
import {AppointmentsService} from '../web-services/appointments.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-exercises', templateUrl: './exercises.component.html', styleUrls: ['./exercises.component.css']})
export class ExercisesComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateExerciseId = null;
    exerciseCategoryList: any = [];
    appointmentsList: any = [];
    exercisesList: any = [];
    exercisesSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    exercisesForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private exercisesServices: ExercisesService, private exerciseCategoryServices: ExerciseCategoryService,
        private appointmentsServices: AppointmentsService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateExerciseId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.exercisesSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countExercises();
        this.findAllExercises();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countExercises() {
        this.exercisesServices.countExercises(this.exercisesSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllExercises();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateExercises(this.exercisesForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllExercises();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.exercisesSearch.reset();
        this.countExercises();
        // this.findAllExercises();
    }

    addUpdateExercises(exercises) {
        this.startLoader();
        if (this.updateExerciseId == null) {
            this.exercisesServices.addExercises(this.exercisesForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    exercises = res['data'][0];
                    this.exercisesList.push(exercises);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameExercises) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameExercises)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countExercises();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            exercises.exerciseId = this.updateExerciseId;
            this.exercisesServices.updateExercises(exercises).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.exercisesList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameExercises) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameExercises)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateExerciseId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllExerciseCategory();
        this.findAllAppointments();
        this.exercisesForm = new FormGroup({
                                               exerciseImageUrl: new FormControl('', [Validators.required, validateSpaces]),
                                               exerciseCategoryId: new FormControl(),
                                               appointmentId: new FormControl(),
                                               userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                               exerciseVideoUrl: new FormControl('', [Validators.required, validateSpaces]),
                                           });
        this.exercisesSearch = new FormGroup({
                                                 exerciseImageUrl: new FormControl(),
                                                 exerciseCategoryId: new FormControl(),
                                                 appointmentId: new FormControl(),
                                                 userId: new FormControl(),
                                                 exerciseVideoUrl: new FormControl(),
                                             });
        this.countExercises();
        this.findAllExercises();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.exercisesSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllExercises() {
        this.startLoader();
        this.exercisesServices.findAllExercises(this.exercisesSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.exercisesList = res['data'];
                } else {
                    this.exercisesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdExercises(exerciseId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.exercisesServices.deleteByIdExercises(exerciseId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.exercisesList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameExercises) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameExercises)
                            });
                        this.countExercises();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadExercisesToEdit(exerciseId: number, index) {
        let exercises = this.exercisesList.find(x => x['exerciseId'] == exerciseId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateExerciseId = exercises['exerciseId'];
        this.selectedIndex = index;
        this.exercisesForm.controls['exerciseImageUrl'].setValue(exercises['exerciseImageUrl']);
        this.exercisesForm.controls['exerciseCategoryId'].setValue(exercises['exerciseCategoryId']['exerciseCategoryId']);
        this.exercisesForm.controls['appointmentId'].setValue(exercises['appointmentId']['appointmentId']);
        this.exercisesForm.controls['userId'].setValue(exercises['userId']);
        this.exercisesForm.controls['exerciseVideoUrl'].setValue(exercises['exerciseVideoUrl']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.exercisesForm.reset();
        this.exercisesForm.markAsUntouched();
        this.updateExerciseId = null;
        closeAddOverlaydiv();
    }

    findAllExerciseCategory() {
        let temp = [];
        this.startLoader();
        this.exerciseCategoryServices.findAllExerciseCategory(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['exerciseCategoryId'];
                        dropDown.label = object['exerciseCategoryTitle'];
                        this.exerciseCategoryList.push(dropDown);
                    }
                } else {
                    this.exerciseCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllAppointments() {
        let temp = [];
        this.startLoader();
        this.appointmentsServices.findAllAppointments(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['appointmentId'];
                        dropDown.label = object['appointmentId'];
                        this.appointmentsList.push(dropDown);
                    }
                } else {
                    this.appointmentsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.exercisesForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.exercisesForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.exercisesForm.get(control)?.valid && this.exercisesForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.exercisesForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.exercisesSearch.reset();
    }

    refresh() {
        this.countExercises();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.exercisesForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.exercisesForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateExerciseId = null;
    }
}
