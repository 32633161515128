import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencySubscriptionChecklistService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgencySubscriptionChecklist(agencySubscriptionChecklistId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/deleteAgencySubscriptionChecklist',
            {'agencySubscriptionChecklistId': agencySubscriptionChecklistId}, this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countAgencySubscriptionChecklist(agencySubscriptionChecklist): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/count', {'agencySubscriptionChecklist': agencySubscriptionChecklist},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateAgencySubscriptionChecklist(agencySubscriptionChecklist): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/updateAgencySubscriptionChecklist', [agencySubscriptionChecklist],
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    addAgencySubscriptionChecklist(agencySubscriptionChecklist): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/addAgencySubscriptionChecklist', [agencySubscriptionChecklist],
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    //custom created service (not by code generator)
    addSubscriptionChecklist(agencySubscriptionChecklist): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/addSubscriptionChecklist', [agencySubscriptionChecklist],
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    //custom created service (not by code generator)
    updateSubscriptionChecklist(agencySubscriptionChecklist): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/updateSubscriptionChecklist', [agencySubscriptionChecklist],
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }


    findAllAgencySubscriptionChecklist(agencySubscriptionChecklist, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'AgencySubscriptionChecklist/findAllAgencySubscriptionChecklist',
            {'agencySubscriptionChecklist': agencySubscriptionChecklist, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

}
