import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { BodyChartDetailService } from '../web-services/body-chart-detail.service';
import { BodyChartMasterService } from '../web-services/body-chart-master.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { ListOfValuesMasterService } from '../web-services/list-of-values-master.service';
import { SessionService } from '../services/session.service';
import {InitialAssessmentSteps} from '../initial-assessment-steps/InitialAssessmentSteps';
import {BodyChartMultiSelects} from '../uiUtils/pages/body-chart/BodyChartMultiSelects';
import {BodyChartFormDataDTO} from '../dto/BodyChartFormDataDTO';
import {Canvg} from 'canvg';
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../web-services/clinical-data.service';
import {EditHistoryFormDataDTO} from '../dto/EditHistoryFormDataDTO';

declare var $: any;
declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component(
    {
        selector: 'app-body-chart-detail',
        templateUrl: './body-chart-detail.component.html',
        styleUrls: ['./body-chart-detail.component.css'],

    })


export class BodyChartDetailComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateBodyChartDetailId = null;
    bodyChartMasterList: any = [];
    listOfValuesList: any = [];
    listOfValuesMasterList: any = [];
    bodyChartDetailList: any = [];
    bodyPartsList: any = [];
    durationsList: any = [];
    vasList: any = [];
    hourlySymptomsList: any = [];
    bodyChartDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    bodyChartDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;


    addEditTitle = '';
    resetTag = true;
    description = Constant.BODY_CHART_LOV_DESCRIPTION;
    aggs = Constant.BODY_CHART_LOV_AGGS;
    eases = Constant.BODY_CHART_LOV_EASES;
    intervalDescription = Constant.BODY_CHART_LOV_INTERVAL_DESCRIPTION;
    bodyAnatomyInformation = {
      bodyChartDetailResponseDTO: [],
      bodyChartMasterResponseDTO: null,

    };

    bodyAnatomyNonSavedInformation = [];
    editedBodyAnatomyInformation = {

      bodyChartDetailId:0,
      patchJson: [],
      bodyChartDetailEditDTO: null,

    };
    bodyAnatomyFrontImageString = '';
    bodyAnatomyBackImageString = '';
    bodyAnatomySkeletonImageString  = '';
      expertId;
      patientId;
      appointmentId;
      caseId;

    stepsItems: any;
    activeStep: number;

    visibleFlag = false;
    defaultImage = "assets/images/logo/bodychart.png";
    bodyChartAnatomyDetailState: any = 'out';
    duration: any;
    hourlySymptoms: any;
    existingBodyDataLoaded = false;

  // defaultImage = "https://devbe.hive-worx.com:8443/HWAssets/Image/ULU4K2IP6SMJQQ8PA7.png";

    constructor(
        private bodyChartDetailServices: BodyChartDetailService,
        private bodyChartMasterServices: BodyChartMasterService,
        private listOfValuesServices: ListOfValuesService,
        private listOfValuesMasterServices: ListOfValuesMasterService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService,
        private lovServices: ListOfValuesService,
        private router: Router,
        private bodyChartMultiSelects: BodyChartMultiSelects,
        private clinicalDataService: ClinicalDataService
    ) {
    };

    lovDataObject: any = {};
    responseData: any;
    refDataObj: any;
    patientObj: any;
    objectMultiSelects: any;
    bodyChartDataList: any;


  ngOnInit() {
        this.visibleFlag = true;
        if (this.route.url.includes('/body_chart')) {
            this.urlCalled = true;
        }
        this.addEditTitle = this.labels.btnAdd;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));

        this.objectMultiSelects = this.bodyChartMultiSelects.objectMultiSelects;

        this.lovDataObject = {"refId":0,"refType":""};

        this.bodyChartDetailForm = new FormGroup({
                                                     bodyPart: new FormControl('', [Validators.required]),
                                                     duration: new FormControl('', [Validators.required]),
                                                     vas: new FormControl('', [Validators.required]),
                                                     hourlySymptoms: new FormControl('', [Validators.required]),
                                                     imageUrl: new FormControl(),
                                                     userId: new FormControl(0),
                                                 });
        this.bodyChartDetailSearch = new FormGroup({
                                                       bodyPart: new FormControl(),
                                                       duration: new FormControl(),
                                                       description: new FormControl(),
                                                       vas: new FormControl(),
                                                       aggs: new FormControl(),
                                                       eases: new FormControl(),
                                                       hourlySymptoms: new FormControl(),
                                                       imageUrl: new FormControl(),
                                                       expertId: new FormControl(),
                                                       patientId: new FormControl(),
                                                       appointmentId: new FormControl(),
                                                       userId: new FormControl(),
                                                   });


        this.findBodyParts();
        this.findDurations();
        this.findVas();
        this.findHourlysymptoms();


        this.findBodyChartDataByAppointmentId();
     ///   this.countBodyChartDetail();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.bodyChartDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });

        this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;
        this.stepsItems = InitialAssessmentSteps.stepItems;
        this.activeStep = this.stepsItems.find(step => step.route === 'body_chart').number;

    }

  findBodyChartDataByAppointmentId(): void {

    this.bodyChartDetailServices.findBodyChartDataByAppointmentId(this.appointmentId).subscribe(res => {

        if (res.status === HttpStatusCode.Ok) {


          if (res.body !== '') {

           /// alert(JSON.stringify(res.body))

           /// this.bodyChartAnatomyDetailsForm.controls["duration"] =
           // bodyChartAnatomyDetailsForm
          }

          console.log(res.body);

          this.bodyAnatomyInformation = res.body;

          this.stopLoader();
          this.existingBodyDataLoaded = true;

        }

      },
      err => {

        this.messageService.add({
          severity: 'error',
          summary: err,
          detail: ''
        });

        //    console.error('err: ', err);
        this.stopLoader();
      });
  }


bodyAnatomyInformationChanged($emittedData): void {



/* this means its changed values */
if ($emittedData.isEdit) {



  let dataToPatch = [];

  $emittedData.changedFields.forEach(element => {

    let fieldToUpdate = {
      "op": "replace",
      "path": "/" + element.fieldName,
      "value": null

    }

    let value = element.newValue;

    if (element.fieldName === 'duration' ||element.fieldName === 'hourlySymptoms') {

      value =  {
        "listOfValuesId": element.newValue,
      }

    }
    fieldToUpdate.value = value;


    dataToPatch.push(fieldToUpdate);

  });


  const bodyChartDetailEditDTO = new EditHistoryFormDataDTO($emittedData.objectMultiSelects, null);


  const bodyChartDetailPatch = {
    bodyChartDetailId: $emittedData.bodyChartDetailId,
    patchJson: dataToPatch,
    bodyChartDetailEditDTO: bodyChartDetailEditDTO

  };

  this.editedBodyAnatomyInformation = bodyChartDetailPatch;

}
else {

  const bodyChartDTO = new BodyChartFormDataDTO(
    this.appointmentId,
    this.patientId,
    this.expertId,
    this.caseId,
    $emittedData.objectMultiSelects,
    $emittedData.bodyChartAnatomyDetailsForm,
    $emittedData.selectedBodyPartInfo,
  );

  console.log(bodyChartDTO);
  console.log($emittedData.objectMultiSelects);



  this.bodyAnatomyNonSavedInformation.push(bodyChartDTO);
}

  }


  bodyAnatomyFrontImage($emittedData): void {

    this.bodyAnatomyFrontImageString = $emittedData;

  }

  bodyAnatomyBackImage($emittedData): void {


    this.bodyAnatomyBackImageString = $emittedData;

  }


  bodyAnatomySkeletonImage($emittedData): void {

    this.bodyAnatomySkeletonImageString = $emittedData;

  }


  urlChanged(stepNumber: number): void {

    let url = this.stepsItems.find(step => step.number === stepNumber).route;
    this.router.navigate([url]);
    }

    getResponse($event: any): void {
        this.bodyChartMultiSelects.getResponseObjects($event);
    }

    get addUpdateTitle(): void {
        return this.updateBodyChartDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch(): void {
        this.bodyChartDetailSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch(): void {
        this.clearSearchBtn = true;
        this.countBodyChartDetail();
        this.showHideSearchDialog();
    }

    startLoader(): void {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader(): void {
        this.loader.stop();
    }

    countBodyChartDetail(): void {
        this.bodyChartDetailSearch.controls['expertId'].setValue(this.expertId);
        this.bodyChartDetailSearch.controls['patientId'].setValue(this.patientId);
        this.bodyChartDetailSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.bodyChartDetailServices.countBodyChartDetail(this.bodyChartDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllBodyChartDetail();
            } else {
                this.totalRecords = 0;
                this.bodyChartDetailList = [];
            }
            this.stopLoader();
        }, err => {
            console.error('err: ', err)
            this.stopLoader();
        });
    }

  async getCanvasBlob(canvas: any, imageName: string) {
    return new Promise(async function(resolve, reject) {
      canvas.toBlob(async function(blob) {
        const file =  new File([blob], imageName, {
          type: 'image/png',
        });
        resolve(file);
      });
    });
  }


  async toBlobCallback(blob: any) {

    const myPromise = new Promise((resolve, reject) => {
       const file =  new File([blob], 'front.png', {
         type: 'image/png',
       });

       resolve(file);

    });

    return myPromise;


  }




  async onSubmit() { ///alert('osubmit');


    const app = {


          appointmentId: this.appointmentId


    };

    const patientId = {


        patientId: this.patientId


    };

    const expertId = {

        expertId: this.expertId

    };

    const caseId = {

        caseId: this.caseId


    };

    const serializer = new XMLSerializer();




    if (this.bodyAnatomyFrontImageString === '') {
      this.bodyAnatomyFrontImageString = serializer.serializeToString(document.getElementById('svg-image-front'));
    }
    if (this.bodyAnatomyBackImageString === '') {
      this.bodyAnatomyBackImageString = serializer.serializeToString(document.getElementById('svg-image-back'));
    }

    if (this.bodyAnatomySkeletonImageString === '') {
      this.bodyAnatomySkeletonImageString = serializer.serializeToString(document.getElementById('svg-image-skeleton'));
    }


    const canvases = document.querySelectorAll('canvas');

    var arr = Array.prototype.slice.call(canvases); // Now it's an Array.
    let canvas  = arr[0];

    const ctx = canvas.getContext('2d');
    $('#back-image').css({"display": 'none'});
    console.log("done fiorst one");
    const v = await Canvg.from(ctx, this.bodyAnatomyFrontImageString);

    v.start();
    await v.ready();
    console.log("done fiorst one");
    const canvases2 = document.querySelectorAll('canvas');
    var arr2 = Array.prototype.slice.call(canvases2); // Now it's an Array.
    let canvas2  = arr2[0]; // T



    let canvasBack  = arr[1]; // The index of your element :)

    const ctxBack = canvasBack.getContext('2d');
    const vBack = await Canvg.from(ctxBack, this.bodyAnatomyBackImageString);
    vBack.start();
    await vBack.ready();
    const canvasesBack2 = document.querySelectorAll('canvas');
    var arrBack2 = Array.prototype.slice.call(canvasesBack2); // Now it's an Array.
    let canvasBack2  = arrBack2[1]; // T


    let canvasSkeleton  = arr[2]; // The index of your element :)

    const ctxSkeleton = canvasSkeleton.getContext('2d');
    const vSkeleton = await Canvg.from(ctxSkeleton, this.bodyAnatomySkeletonImageString);
    vSkeleton.start();
    await vSkeleton.ready();
    const canvasesSkeleton2 = document.querySelectorAll('canvas');
    var arrSkeleton2 = Array.prototype.slice.call(canvasesSkeleton2); // Now it's an Array.
    let canvasSkeleton2  = arrSkeleton2[2]; // T


    let bodyChartMasterDTO = {

     /// bodyPartId: this.bodyChartDetailForm.controls["bodyPart"].value,
      appointmentId: app,
      patientId: patientId,
      expertId: expertId,
      caseId: caseId,
      bodyPartDetails: this.bodyAnatomyNonSavedInformation,
      bodyChartMasterId: 0
    };


   // this.sessionService.setSession('existingBodyPartData',JSON.stringify(this.bodyAnatomyInformation));
    let bodyChartMasterId = 0;

    if (this.bodyAnatomyInformation.bodyChartMasterResponseDTO !== null) {

      /* need to add the current bodyPartDetails to the existing bodyChartMaster - worry about edited ones later, then the rest of hte code
      * should just run and update the image urls with the new image urls , should just work!!!
      * */
      bodyChartMasterId = this.bodyAnatomyInformation.bodyChartMasterResponseDTO.bodyChartMaster;
      bodyChartMasterDTO.bodyChartMasterId = bodyChartMasterId;

      if (this.bodyAnatomyNonSavedInformation.length > 0) {
        let addedInfo  =   await this.addBodyChartDetail(bodyChartMasterDTO).then(res => { alert('done resloved') })
      }


      /*now look for edited ones and update them*/
      if (this.editedBodyAnatomyInformation.bodyChartDetailId >  0) {



          if (!this.editedBodyAnatomyInformation.bodyChartDetailEditDTO.hasMultiSelectErrors && this.editedBodyAnatomyInformation.bodyChartDetailEditDTO.listOfValueDetailsIdsToDelete.length > 0 || this.editedBodyAnatomyInformation.bodyChartDetailEditDTO.historyDataTOAdd !== null) {

          this.bodyChartDetailServices.updateBodyChartDetailMultiSelects(this.editedBodyAnatomyInformation.bodyChartDetailEditDTO, this.editedBodyAnatomyInformation.bodyChartDetailId).subscribe(res => {

            if (res.status === HttpStatusCode.Accepted) {
              this.stopLoader();
              this.messageService.add({
                severity: 'success',
                summary: "MultiSelects" +
                  this.translate(this.labels.updatedSuccessfully),
              });

              // this.findAllPresentConditionHistoriesByAppointmentId();

              this.submitted = true;
              this.showHideAddDialog();
            }
          });

        }

        if (this.editedBodyAnatomyInformation.patchJson.length > 0) {

          this.bodyChartDetailServices.updateBodyChartDetailViaPatch(this.editedBodyAnatomyInformation.patchJson, this.editedBodyAnatomyInformation.bodyChartDetailId).subscribe(res => {


            if (res.status === HttpStatusCode.Accepted) {
              this.stopLoader();
              this.messageService.add({
                severity: 'success',
                summary: "Body Chart Detail Info" +
                  this.translate(this.labels.updatedSuccessfully),
              });

              // this.findAllPresentConditionHistoriesByAppointmentId();

              this.submitted = true;
              this.showHideAddDialog();
            }

          });
        }

      }

    }
    else {

      let bodyChartMasterId  = await this.addBodyChartMaster(bodyChartMasterDTO);


    }



  //  this.bodyChartMasterServices.addBodyChartMasterCpa(bodyChartMasterDTO).subscribe(async res => {


      const bodyAnatomyFrontImageFile = await this.getCanvasBlob(canvas2, bodyChartMasterId + "-front.jpg");
      const frontAnatomyImageUrl = this.sharedService.uploadFile(bodyAnatomyFrontImageFile);

      frontAnatomyImageUrl.subscribe(async frontAnatomyImageUrlRes => {

        const bodyAnatomyBackImageFile = await this.getCanvasBlob(canvasBack2, bodyChartMasterId + "-back.jpg");
        const backAnatomyImageUrl = this.sharedService.uploadFile(bodyAnatomyBackImageFile);


        let anatomyImageUrls = {

          frontAnatomyImageUrl: frontAnatomyImageUrlRes,
          backAnatomyImageUrl: '',
          skeletonAnatomyImageUrl: ''

        };

        backAnatomyImageUrl.subscribe(async res => {
          anatomyImageUrls.backAnatomyImageUrl = res;
          const bodyAnatomySkeletonImageFile = await this.getCanvasBlob(canvasSkeleton2, bodyChartMasterId + "-skeleton.jpg");
          const skeletonAnatomyImageUrl = this.sharedService.uploadFile(bodyAnatomySkeletonImageFile);

          skeletonAnatomyImageUrl.subscribe(res => {
            anatomyImageUrls.skeletonAnatomyImageUrl = res;

            this.bodyChartMasterServices.updateBodyChartMasterWithImageUrls(bodyChartMasterId, anatomyImageUrls).subscribe(res => {


              if (res.status === 202) {
                this.messageService.add({
                  severity: 'success',
                  summary: this.translate("Body Chart Images Saved") +
                    this.translate(this.labels.addedSuccessfully),
                });

                this.showHideAddDialog();
              }

            });

          });



        });
      });
   // });

  }

 async addBodyChartMaster(bodyChartMasterDTO) {
   return new Promise((resolve, reject) => {

     this.bodyChartMasterServices.addBodyChartMasterCpa(bodyChartMasterDTO).subscribe(async res => {

       if (res.status === HttpStatusCode.Created) {

         resolve(res.body.bodyChartMaster)
       }


     }, err => {
       reject(err);
       //this.stopLoader();
     });

   });
 }

 async addBodyChartDetail(bodyChartMasterDTO) {

  return new Promise( async (resolve, reject) => {
   let result =  await this.bodyChartDetailServices.addBodyChartDetailCpa(bodyChartMasterDTO).toPromise().catch(err => {alert(err)});
    if (result.status === HttpStatusCode.Created) {

      resolve(result.body);
      this.messageService.add({
        severity: 'success',
        summary: this.translate("Body Chart Information") +
          this.translate(this.labels.addedSuccessfully),
      });

      resolve(result.body.bodyChartMaster);
    }
  /// alert(result);

    /* return await this.bodyChartDetailServices.addBodyChartDetailCpa(bodyChartMasterDTO).toPromise( res => {

       if (res.status === HttpStatusCode.Created) {
         this.messageService.add({
           severity: 'success',
           summary: this.translate("Body Chart Information") +
             this.translate(this.labels.addedSuccessfully),
         });
alert('done1');
          return resolve(res.body.bodyChartMaster)
       }
     },err => {
       this.messageService.add({
         severity: 'error',
         summary: err ,
       });
       reject(err);
       //this.stopLoader();
     });*/
   });
 }


    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
       // this.findAllBodyChartDetail();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.bodyChartDetailSearch.reset();
        this.countBodyChartDetail();
        // this.findAllBodyChartDetail();
    }

    addUpdateBodyChartDetail(bodyChartDetail) {
        this.startLoader();
        if (this.updateBodyChartDetailId == null) {
            this.bodyChartDetailServices.addBodyChartDetail(this.bodyChartDetailForm.value, this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    bodyChartDetail = res['data'][0];
                    this.bodyChartDetailList.push(bodyChartDetail);
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNameAppointments) +
                                                    this.translate(this.labels.updatedSuccessfully),
                        detail: this.translate(this.labels.entityNameBodyChartDetail)
                    });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countBodyChartDetail();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            bodyChartDetail.bodyChartDetailId = this.updateBodyChartDetailId;
            this.bodyChartDetailServices.updateBodyChartDetail(bodyChartDetail, this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.bodyChartDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate(this.labels.btnUpdate),
                        detail: this.translate(this.labels.entityNameBodyChartDetail)
                    });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countBodyChartDetail();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateBodyChartDetailId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }
    urlCalled = false;


    findBodyParts() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('BodyPart').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.bodyPartsList.push(dropDown);
                    }
                } else {
                    this.bodyPartsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findDurations() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Duration').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.durationsList.push(dropDown);
                    }
                } else {
                    this.durationsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findVas() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('VAS').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.vasList.push(dropDown);
                    }
                } else {
                    this.vasList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findHourlysymptoms() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('HourlySymptoms').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.hourlySymptomsList.push(dropDown);
                    }
                } else {
                    this.hourlySymptomsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllBodyChartDetail() {
        this.startLoader();
        this.bodyChartDetailSearch.controls['expertId'].setValue(this.expertId);
        this.bodyChartDetailSearch.controls['patientId'].setValue(this.patientId);
        this.bodyChartDetailSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.bodyChartDetailServices.findAllBodyChartDetail(this.bodyChartDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.bodyChartDetailList = res['data'];
                } else {
                    this.bodyChartDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdBodyChartDetail(bodyChartDetailId: number, index) {
        this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.bodyChartDetailServices.deleteByIdBodyChartDetail(bodyChartDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.bodyChartDetailList.splice(index, 1);
                        this.messageService.add({
                            severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameBodyChartDetail) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                            // detail: this.translate(this.labels.entityNameBodyChartDetail)
                        });
                        this.countBodyChartDetail();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }

    loadBodyChartDetailToEdit(bodyChartDetailId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let bodyChartDetail = this.bodyChartDetailList.find(x => x['bodyChartDetailId'] == bodyChartDetailId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateBodyChartDetailId = bodyChartDetail['bodyChartDetailId'];
        this.selectedIndex = index;
        this.lovDataObject = { "refId": bodyChartDetail['bodyChartDetailId'], "refType": "" };

        if (bodyChartDetail['bodyPart']) {
            this.bodyChartDetailForm.controls['bodyPart'].setValue(bodyChartDetail['bodyPart']['listOfValuesId']);
        }
        if (bodyChartDetail['duration']) {
            this.bodyChartDetailForm.controls['duration'].setValue(bodyChartDetail['duration']['listOfValuesId']);
        }

        if (bodyChartDetail['description']['listOfValuesMasterId']) {
            this.bodyChartDetailForm.controls['description'].setValue(bodyChartDetail['description']['listOfValuesMasterId']);
        }
        if (bodyChartDetail['vas']) {
            this.bodyChartDetailForm.controls['vas'].setValue(bodyChartDetail['vas']['listOfValuesId']);
        }
        if (bodyChartDetail['aggs']['listOfValuesMasterId']) {
            this.bodyChartDetailForm.controls['aggs'].setValue(bodyChartDetail['aggs']['listOfValuesMasterId']);
        }
        if (bodyChartDetail['eases']['listOfValuesMasterId']) {
            this.bodyChartDetailForm.controls['eases'].setValue(bodyChartDetail['eases']['listOfValuesMasterId']);
        }
        if (bodyChartDetail['hourlySymptoms']) {
            this.bodyChartDetailForm.controls['hourlySymptoms'].setValue(bodyChartDetail['hourlySymptoms']['listOfValuesId']);
        }
        if (bodyChartDetail['intervalDescription']['listOfValuesMasterId']) {
            this.bodyChartDetailForm.controls['intervalDescription'].setValue(bodyChartDetail['intervalDescription']['listOfValuesMasterId']);
        }
        if (bodyChartDetail['expertId']) {
            this.bodyChartDetailForm.controls['expertId'].setValue(bodyChartDetail['expertId']['expertId']);
        }
        if (bodyChartDetail['patientId']) {
            this.bodyChartDetailForm.controls['patientId'].setValue(bodyChartDetail['patientId']['patientId']);
        }
        if (bodyChartDetail['appointmentId']) {
            this.bodyChartDetailForm.controls['appointmentId'].setValue(bodyChartDetail['appointmentId']['appointmentId']);
        }
        // this.bodyChartDetailForm.controls['userId'].setValue(bodyChartDetail['userId']);

        // by ali
        if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.bodyChartDetailForm.reset();
        this.bodyChartDetailForm.markAsUntouched();
        this.updateBodyChartDetailId = null;
        closeAddOverlaydiv();
    }

    findAllBodyChartMaster() {
        let temp = [];
        this.startLoader();
        this.bodyChartMasterServices.findAllBodyChartMaster(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['bodyChartMasterId'];
                        dropDown.label = object['bodyChartMasterId'];
                        this.bodyChartMasterList.push(dropDown);
                    }
                } else {
                    this.bodyChartMasterList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllListOfValues() {
        let temp = [];
        this.startLoader();
        this.listOfValuesServices.findAllListOfValues(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['value'];
                        this.listOfValuesList.push(dropDown);
                    }
                } else {
                    this.listOfValuesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllListOfValuesMaster() {
        let temp = [];
        this.startLoader();
        this.listOfValuesMasterServices.findAllListOfValuesMaster(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['value'];
                        this.listOfValuesMasterList.push(dropDown);
                    }
                } else {
                    this.listOfValuesMasterList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.bodyChartDetailForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.bodyChartDetailForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.bodyChartDetailForm.get(control)?.valid && this.bodyChartDetailForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.bodyChartDetailForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.bodyChartDetailSearch.reset();
    }

    refresh() {
        this.countBodyChartDetail();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.bodyChartDetailForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.bodyChartDetailForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateBodyChartDetailId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        // console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.bodyChartDetailServices.globalSearchCount(this.searchTerm, this.appointmentId, this.expertId, this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.bodyChartDetailList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.bodyChartDetailServices.globalSearchFind(this.searchTerm, this.appointmentId, this.expertId, this.patientId, this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.bodyChartDetailList = result['data'];
            } else {
                this.bodyChartDetailList = [];
            }
            // console.log(result, 'result');
        });
    }


    fileUrlEmitterFunc(event) {
        // console.log("image saved :: ",event);
        this.bodyChartDetailForm.controls['imageUrl'].setValue(event);
        this.visibleFlag = false;
        // this.fileUrls.push(event);
        // if (sessionStorage.getItem("patientId") != null && sessionStorage.getItem("expertId") && sessionStorage.getItem("appointmentId") && sessionStorage.getItem("loggedInRefId")) {
        //   let data = {
        //     patientId: parseInt(sessionStorage.getItem("patientId")),
        //     expertId: parseInt(sessionStorage.getItem("expertId")),
        //     appointmentId: parseInt(sessionStorage.getItem("appointmentId")),
        //     userId: parseInt(sessionStorage.getItem("loggedInRefId")),
        //     fileurls: event
        //   }
        //   this.loader.start();

        //   this.imageFileService.addImageFile(data).subscribe(res => {
        //     console.log(res);
        //     this.messageService.add(
        //       {
        //         severity: 'success',
        //         summary: this.translate(this.labels.btnAdd),
        //         detail: this.translate(this.labels.entityNameImage)
        //       });
            setTimeout(() => {                           // <<<---using ()=> syntax
              this.visibleFlag = true;
            }, 1000);
            this.loader.stop();

        //   })

    }

}
