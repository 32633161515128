<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="print-data">
    <div class="container">
        <div class="readonlyContainer" id="PatientsviewDetailspopup-readonlyContainer1a">
            <div class="p-fluid p-formgrid p-grid p-mb-5" id="PatientsviewDetailspopup-formgrid1a">
                <div class="p-col-12 p-md-12 print-preview">
                    <p-button (click)="printWindow()"
                              label="Print"
                              type="submit"
                              styleClass="p-button" class="pngBlueBtn"></p-button>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">Issue To</div>
                    <div class="readOnlyValue">{{invoiceObj?.issueTo?.agencyFirstname}}</div>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">Invoice Date</div>
                    <div class="readOnlyValue">{{invoiceIssueDate}}</div>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">Amount</div>
                    <div class="readOnlyValue">{{invoiceObj?.invoiceAmount}}</div>
                </div>
            </div>
        </div>
        <section class="sectioncontent" id="print-data">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameInvoiceItemsDetail)}}</p-header>
                        <!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-table
                                        [value]="invoiceItemsDetailList"
                                        class="pngTable print-preview-table"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        [paginator]="true"
                                        [resizableColumns]="true" [autoLayout]="true" [showCurrentPageReport]="true"
                                        [rowsPerPageOptions]="[5,10,20, 30]" [rows]="10" styleClass="p-datatable-striped"
                                        [ngClass]="{'pngTableEmptyMessage': invoiceItemsDetailList?.length == 0}"
                                        styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Item</th>
                                            <th> Quantity</th>
                                            <th> Unit Price</th>
                                            <th> Discount</th>
                                            <th> Tax Amount</th>
                                            <th> Total Amount</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element pTemplate="body">
                                        <tr>
                                            <td> {{element?.itemId?.label}} </td>
                                            <td> {{element?.quantity}} </td>
                                            <td> {{element?.unitPrice}} </td>
                                            <td> {{element?.discount}} </td>
                                            <td> {{element?.taxAmount}} </td>
                                            <td> {{element?.totalAmount}} </td>
                                        </tr>
                                    </ng-template>
                                </p-table> 
                                </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
