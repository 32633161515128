import { Injectable } from '@angular/core';
import { AppSettings } from '../../utilities/constants/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DependancyServiceService {

  constructor(private http: HttpClient) { }

  private translateLabelUrl = AppSettings.TRANSLATION_API_ENDPOINT + 'TranslateService/translateLabel';

  translateLabel(formId, label, serverURL, applicationId) {
    // let langId;
    // if (this.sharedService.getCookie("userPreference") != '' && this.sharedService.getCookie("userPreference") != undefined) {
    //   console.log(this.sharedService.getCookie("userPreference"))
    //   var userPref = JSON.parse(this.sharedService.getCookie("userPreference"));
    // }
    // if (this.sharedService.getCookie("userPreference") != '' && this.sharedService.getCookie("userPreference") != undefined && userPref['defaultLanguage']) {
    //   langId = JSON.parse(this.sharedService.getCookie("userPreference"))['defaultLanguage']
    // } else {
    //   langId = 1;
    // }
    var languageId = 1
    return this.http.post<string>(serverURL + this.translateLabelUrl, { data: { appId: applicationId, "formId": formId, "languageId": languageId, label: label, translated: label } });
  }
}
