
<div class="card-cpa-rounded-20 background-white-m-30">
  <p-steps [model]="stepsItems"  [(activeIndex)]="activeStep"
           [readonly]="false" (activeIndexChange)="urlChanged($event)">
  </p-steps>
</div>
<div class="p-row initial-assessment-container" style="height:auto">

  <div class="p-col-12">
    <div class="row">
      <div class="p-col-3 m-5">

        <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>


      </div>
    </div>

    <div class="row">

      <div class="p-col-11 m-5">
        <p-card styleClass="card-cpa-rounded card-shadow">

          <div class=" displayBlock overlaypaneldiv" id="overlaypaneldiv-add">
            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
              <p-header>Follow Up - Testing</p-header>

              <div class="row">
                <div class="p-col-12">
                  <div class="middle">
                    <label *ngFor="let bodyPart of existingBodyPartData.bodyChartDetailResponseDTO">
                      <input type="radio" name="radio" (click)="bodyPartChanged(bodyPart.bodyChartAnatomyDiagramId)"/>
                      <div class="box">
                        <span>  {{bodyPart.bodyChartAnatomyDiagramName}}</span>
                      </div>
                    </label>


                  </div>
                </div>

                <div class="p-col-10 m-5" *ngIf="chosenBodyChartAnatomyDiagramId">
                  <p-card styleClass="card-cpa-rounded card-shadow">


                    <p-tabView class="expertAnalyticsTabs">

                      <p-tabPanel header="AROM" headerStyleClass="h3-style">
                        <app-testing-arom [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-arom>
                      </p-tabPanel>


                      <p-tabPanel header="Neuro Testing" headerStyleClass="h3-style ">
                        <app-testing-neuro [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-neuro>
                      </p-tabPanel>

                      <p-tabPanel header="Muscle Testing" headerStyleClass="h3-style">
                        <app-testing-muscle [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-muscle>
                      </p-tabPanel>

                      <p-tabPanel header="Special Testing" headerStyleClass="h3-style">
                        <app-testing-special [chosenBodyChartAnatomyDiagramId]="chosenBodyChartAnatomyDiagramId"></app-testing-special>
                      </p-tabPanel>

                    </p-tabView>

                  </p-card>

                </div>
              </div>

            </p-panel>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>
