import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {cookieObject} from '../../utilities/model/interfaces';
// import { SharedService } from '../../../services/sharedservice/shared.service';
import {BehaviorSubject} from 'rxjs';
import {DependancyServiceService} from '../../services/dependancy-services/dependancy-service.service';

@Injectable({
                providedIn: 'root'
            })
export class CollaborationSharedServiceService {

    public timeStamp = 30000;  // Polling Timestamp Set Here
    constructor(private cookieService: CookieService, private dependancyService: DependancyServiceService
    ) {
    }

    // public loggedInUser = parseInt(this.getCookie("loggedInUser")); // getting loggedInUser
    // public participants = [this.loggedInUser]; //setting participant Array
    public participants = [];
    groupCreated = 'Create Group';
    groupParticipantAdded = 'Add Group Participant';
    groupparticipantDeleted = 'Delete Group Participant';
    messageReceived = 'Message Received';
    contextualGroupCreated = 'New Contexual Group Created';
    contextualMessageReceived = 'Contexual Message Received';
    applicationId = 2;
    applicationName = 'Collaboration';
    constantlist = [];
    messageModel: any;
    messagesTimeStamp = '';
    messages = [];
    consoleEnabled = false;
    ZER0 = 0;
    chats = [];
    contexualChats = [];
    alreadyAddedParticipants = [];

    cookieObject: cookieObject;

    // setCookie(cookieName, cookieValue) {
    //   // alert("Collaboration : : : " + cookieName)
    //   // this.cookieService.set(key, value, null, '/');
    //   this.cookieObject[cookieName] = cookieValue;
    // }

    // getCookie(cookiename) {
    //   // return this.cookieService.get(cookiename);
    //   return this.cookieObject[cookiename];
    // }

    private currentVersion = new BehaviorSubject<string>('0');

    public setCurrentVersion(value) {
        this.currentVersion.next(value);
    }

    get CurrentVersion() {
        return this.currentVersion.asObservable();
    }

    deleteCookie(key) {
        this.cookieService.delete(key, '/');
    }

    setSession(key, value) {
        sessionStorage.setItem(key, value);
    }

    getSession(key) {
        return sessionStorage.getItem(key);
    }

    clearSession() {
        sessionStorage.clear();
    }

    Print(message) {
        if (this.consoleEnabled) {
            console.log(message);
        } else {
        }
    }

    //------------------Role Match------------------

    applicationShortName = 'ums';

    roleMatch(allowedRoles): boolean {

        if (this.cookieService.get('applicationShortName')) {
            this.applicationShortName = this.cookieService.get('applicationShortName');
        }
        if (this.cookieService.get('token') && this.getSession(this.applicationShortName + '_roles')) {
            var isMatch = false;
            //console.log(allowedRoles)
            var userRoles: string[] = JSON.parse(this.getSession(this.applicationShortName + '_roles'));
            allowedRoles.forEach(element => {
                if (userRoles.indexOf(element) > -1) {
                    isMatch = true;
                    return false;
                }
            });
        }
        return isMatch;
    }

    // ----------------Verify Labels--------------------

    constants = [];

    verifyLabel(name, constantlist, formId) {
        // console.log("constantList :: in Service :: " , constantlist)
        if (constantlist != undefined && constantlist.length > 0) {
            if (constantlist.find(x => x?.code == name) != undefined) {
                return constantlist.find(x => x['code'] == name)['value'];
            } else {
                var constants = this.constants.find(x => x.formId === formId);
                if (constants) {
                    const index = constants['constants'].findIndex(element => element === name);
                    if (index == -1) {
                        constants['constants'].push(name);
                        // this.translateLabel(formId, name, constantlist)
                    }
                } else {
                    var obj = {
                        'formId': formId,
                        'constants': [name]
                    };
                    this.constants.push(obj);
                    // this.translateLabel(formId, name, constantlist)
                    // console.log("constants Collaboration :: ----------------------------- ::  ", this.constants);
                }
                return name;
            }
        } else {
            return name;
        }
    }

    // translateLabel(formId, label, constantList) {
    //   var serverURL = this.globalSharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID)
    //   var applicationId = this.globalSharedService.getCookie('applicationId');
    //   this.dependancyService.translateLabel(formId, label, serverURL, applicationId).subscribe((data) => {
    //     if (data && data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
    //       constantList.push(data['data'][0]['constant']);
    //     }
    //   });
    // }

    checkRole(pageControls: Map<string, boolean>, roles: string[]): Map<string, boolean> {
        for (let key of Array.from(pageControls.keys())) {
            if (roles) {
                if (roles.find(x => x == key)) {
                    pageControls.set(key, true);
                }
            }
        }
        return pageControls;
    }

    //Creating Json Object

    makeJson(placeholderName, eventTitle, projectName, eventDescription, insertionTime, participant) {
        //console.log(placeholderName + eventTitle + projectName + eventDescription + insertionTime + participant)
        var fieldName1 = 'placeholderName';
        var fieldValue1 = placeholderName;
        var fieldName2 = 'eventTitle';
        var fieldValue2 = eventTitle;
        var fieldName3 = 'projectName';
        var fieldValue3 = projectName;
        var fieldName4 = 'eventDescription';
        var fieldValue4 = eventDescription;
        var fieldName5 = 'insertionTime';
        var fieldValue5 = insertionTime;
        var fieldName6 = 'participant';
        var fieldValue6 = participant;
        var object = {};
        object[fieldName1] = fieldValue1;
        object[fieldName2] = fieldValue2;
        object[fieldName3] = fieldValue3;
        object[fieldName4] = fieldValue4;
        object[fieldName5] = fieldValue5;
        object[fieldName6] = fieldValue6;
        return object;
    }

    public filterArray(items: any[], searchText: string): any[] {
        // console.log(items)
        // console.log(searchText)
        var loggedInUser = 2;
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            if (it.username) {
                return it.username.toLowerCase().includes(searchText);
            }
            if (it.cbGroupData == null) {
                if (it.firstUser.userId == loggedInUser) {
                    return it.secondUser.username.toLowerCase().includes(searchText);
                } else {
                    return it.firstUser.username.toLowerCase().includes(searchText);
                }
            } else {
                return it.cbGroupData.title.toLowerCase().includes(searchText);
            }
        });
    }

}
