import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {SocialHistoryService} from '../web-services/social-history.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {SocialHistoryMultiSelects} from "../uiUtils/pages/SocialHistoryMultiSelects";
import {InitialHistoryFormDTO} from "../dto/InitialHistoryFormDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";


@Component({selector: 'app-social-history', templateUrl: './social-history.component.html', styleUrls: ['./social-history.component.css']})
export class SocialHistoryComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateSocialHistoryId = null;
    listOfValuesDetailList: any = [];
    socialHistoryList: any = [];
    booleanCategoryList: any = [];
    affectedCategoryList: any = [];
    socialHistorySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    socialHistoryForm: FormGroup;
    constantList = [];
    pageNo = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;
    occupation = Constant.SOCIAL_LOV_OCCUPATION;
    mobility = Constant.SOCIAL_LOV_MOBILITY;
    hobbies = Constant.SOCIAL_LOV_HOBBIES;

    lovDataObject : any = {};
    expertId;
    patientId;
    appointmentId;
    caseId: number;

    constructor(
        private socialHistoryServices: SocialHistoryService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private socialHistoryMultiSelects: SocialHistoryMultiSelects
    ) {
    };

    responseData : any;
    refDataObj: any;

    occupationList: any = [];
    mobilityList: any = [];
    hobbiesList: any = [];
    occupationError = false;
    mobilityError = false;
    hobbiesError = false;
    objectMultiSelects: any;

    getResponse($event) {

      this.socialHistoryMultiSelects.getResponseObjects($event);
    }

    findBooleanCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('BooleanCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.booleanCategoryList.push(dropDown);
                    }
                } else {
                    this.booleanCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAffectedCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('AffectCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.affectedCategoryList.push(dropDown);
                    }
                } else {
                    this.affectedCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.lovDataObject = {"refId":0,"refType":""};

        this.objectMultiSelects = this.socialHistoryMultiSelects.objectMultiSelects;


        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));

        this.findBooleanCategory();
        this.findAffectedCategory();
        this.findAllListOfValuesDetail();
        this.socialHistoryForm = new FormGroup({});
        this.socialHistorySearch = new FormGroup({});
        this.findSocialHistoriesByAppointmentId();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.socialHistorySearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findSocialHistoriesByPatientId(): void {

      this.socialHistoryServices.findSocialHistoriesByPatientId(this.patientId).subscribe(res => {

        if (res.status === 200) {

          this.socialHistoryList = res.body;
        }

      });

    }

  findSocialHistoriesByAppointmentId(): void {

    this.socialHistoryServices.findSocialHistoriesByAppointmentId(this.appointmentId).subscribe(res => {

      if (res.status === 200) {
        this.stopLoader();
        this.socialHistoryList = res.body;
      }

    });

  }

    get addUpdateTitle() {
        return this.updateSocialHistoryId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.socialHistorySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countSocialHistory();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countSocialHistory() {
        this.socialHistorySearch.controls['expertId'].setValue(this.expertId);
        this.socialHistorySearch.controls['patientId'].setValue(this.patientId);
        this.socialHistorySearch.controls['appointmentId'].setValue(this.appointmentId);
        this.socialHistorySearch.controls['caseId'].setValue(this.caseId);
        this.socialHistoryServices.countSocialHistory(this.socialHistorySearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllSocialHistory();
            } else {
                this.totalRecords = 0;
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {
        this.socialHistoryForm.markAllAsTouched();

      const socialHistoryDTO = new InitialHistoryFormDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.socialHistoryForm);

      const diagnosisData = new DiagnosisDataHelper(socialHistoryDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      console.log(uniqueCaseDiagnosisPredictionList);
      console.log(socialHistoryDTO.redFlagData);

      if (!socialHistoryDTO.hasMultiSelectErrors) {
        this.socialHistoryServices.addSocialHistoryCpa(socialHistoryDTO).subscribe(res => {

          if (res.status === 200) {
            this.stopLoader();

            this.messageService.add({
              severity: 'success',
              summary: this.translate(this.labels.entityNameSocialHistory) +
                this.translate(this.labels.addedSuccessfully),
            });

            this.findSocialHistoriesByAppointmentId();

            if (uniqueCaseDiagnosisPredictionList.length > 0) {
              this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

                console.log(res);
              });
            }

            if (socialHistoryDTO.redFlagData.length > 0) {

              this.lovServices.createListOfCaseRedFlags(socialHistoryDTO.redFlagData).subscribe(res => {

                if (res.status === 201) {
                  this.stopLoader();
                  this.messageService.add({
                    severity: 'success',
                    summary: this.translate("Red Flags Updated"),
                  });
                }

              });
            }

            this.submitted = true;
            this.showHideAddDialog();
          } else {
            alert('eror');
          }

        });

      }
      else {

        socialHistoryDTO.multiSelectErrorFields.forEach(multiSelectError => {

          document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
        });
      }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllSocialHistory();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.socialHistorySearch.reset();
        this.countSocialHistory();
    }

    addUpdateSocialHistory(socialHistory) {
        this.startLoader();
        if (this.updateSocialHistoryId == null) {
            this.socialHistoryServices.addSocialHistory(this.socialHistoryForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    socialHistory = res['data'][0];
                    this.socialHistoryList.push(socialHistory);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameSocialHistory) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameSocialHistory)
                                            });
                    this.submitted = true;
                    this. showHideAddDialog();
                    this.countSocialHistory();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            socialHistory.socialHistoryId = this.updateSocialHistoryId;
            this.socialHistoryServices.updateSocialHistory(socialHistory,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.socialHistoryList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameSocialHistory) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameSocialHistory)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countSocialHistory();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateSocialHistoryId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }


    findAllSocialHistory() {
        this.startLoader();
        this.socialHistorySearch.controls['expertId'].setValue(this.expertId);
        this.socialHistorySearch.controls['patientId'].setValue(this.patientId);
        this.socialHistorySearch.controls['appointmentId'].setValue(this.appointmentId);
        this.socialHistorySearch.controls['caseId'].setValue(this.caseId);
        this.socialHistoryServices.findAllSocialHistory(this.socialHistorySearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.socialHistoryList = res['data'];
                } else {
                    this.socialHistoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdSocialHistory(socialHistoryId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.socialHistoryServices.deleteByIdSocialHistory(socialHistoryId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.socialHistoryList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameSocialHistory) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameSocialHistory)
                                                });
                        this.countSocialHistory();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deleteSocialHistory"
                                  });
    }

    loadSocialHistoryToEdit(socialHistoryId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let socialHistory = this.socialHistoryList.find(x => x['socialHistoryId'] == socialHistoryId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateSocialHistoryId = socialHistory['socialHistoryId'];
        this.selectedIndex = index;
        this.lovDataObject = {"refId":socialHistory['socialHistoryId'],"refType":""};

        if(socialHistory['occupation']['listOfValuesMasterId']) {
            this.socialHistoryForm.controls['occupation'].setValue(socialHistory['occupation']['listOfValuesMasterId']);
        }
        if(socialHistory['retired']) {
            this.socialHistoryForm.controls['retired'].setValue(socialHistory['retired']['listOfValuesId']);
        }
        if(socialHistory['adl']) {
            this.socialHistoryForm.controls['adl'].setValue(socialHistory['adl']['listOfValuesId']);
        }
        if(socialHistory['mobility']['listOfValuesMasterId']) {
            this.socialHistoryForm.controls['mobility'].setValue(socialHistory['mobility']['listOfValuesMasterId']);
        }
        if(socialHistory['hobbies']['listOfValuesMasterId']) {
            this.socialHistoryForm.controls['hobbies'].setValue(socialHistory['hobbies']['listOfValuesMasterId']);
        }
        if(socialHistory['patientId']) {
            this.socialHistoryForm.controls['patientId'].setValue(socialHistory['patientId']['patientId']);
        }
        if(socialHistory['expertId']) {
            this.socialHistoryForm.controls['expertId'].setValue(socialHistory['expertId']['expertId']);
        }

        if(socialHistory['caseId']) {
        this.socialHistoryForm.controls['caseId'].setValue(socialHistory['caseId']['caseId']);
      }
        // this.socialHistoryForm.controls['userId'].setValue(socialHistory['userId']['userId']);

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.socialHistoryForm.reset();
        this.socialHistoryForm.markAsUntouched();
        this.updateSocialHistoryId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.socialHistoryForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.socialHistoryForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.socialHistoryForm.get(control)?.valid && this.socialHistoryForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.socialHistoryForm.reset();
    }

    onCloseSearch() {
        this.socialHistorySearch.reset();
    }

    refresh() {
        this.countSocialHistory();
    }

    onAddOverlayDiv() {
        this.socialHistoryForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.socialHistorySearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.socialHistoryForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateSocialHistoryId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.socialHistoryServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.socialHistoryList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.socialHistoryServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.socialHistoryList = result['data'];
            } else {
                this.socialHistoryList = [];
            }
            console.log(result, 'result');
        });
    }
}
