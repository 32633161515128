import {Component, OnInit, SimpleChanges,Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {TestingSpecialService} from '../web-services/testing-special.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {UsersService} from '../web-services/users.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {TestingSpecialMultiSelects} from "../uiUtils/pages/testing/TestingSpecialMultiSelects";
import {FormDataDTO} from "../dto/FormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../web-services/clinical-data.service';
import {TestingFormData} from '../dto/TestingFormData';

@Component(
    {selector: 'app-testing-special', templateUrl: './testing-special.component.html', styleUrls: ['./testing-special.component.css']})
export class TestingSpecialComponent implements OnInit {

  @Input() chosenBodyChartAnatomyDiagramId;
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateTestingSpecialId = null;
    listOfValuesDetailList: any = [];
    testingSpecialList: any = [];
    booleanCategoryList: any = [];
    sidesCategoryList: any = [];
    testingSpecialSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    testingSpecialForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;
    type = Constant.TESTING_SPECIAL_LOV_TYPE;

    expertId;
    patientId;
    appointmentId;
    caseId;

    objectMultiSelects: any;


  constructor(
        private testingSpecialServices: TestingSpecialService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private testingSpecialObjectMultiSelects: TestingSpecialMultiSelects,
        private clinicalDataService: ClinicalDataService
    ) {
    };

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    typeList: any = [];
    typeError = false;


  ngOnChanges(changes: SimpleChanges) {

    if (!changes.chosenBodyChartAnatomyDiagramId.firstChange)  {
      this.findAllTestingSpecialByAppointmentIdAndBodyChartAnatomyDiagramId();
    }

  }

    getResponse($event) {

      this.testingSpecialObjectMultiSelects.getResponseObjects($event);

    }

    get addUpdateTitle() {
        return this.updateTestingSpecialId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.testingSpecialSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countTestingSpecial();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countTestingSpecial() {
        this.testingSpecialSearch.controls['expertId'].setValue(this.expertId);
        this.testingSpecialSearch.controls['patientId'].setValue(this.patientId);
        this.testingSpecialSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingSpecialSearch.controls['caseId'].setValue(this.caseId);
        this.testingSpecialServices.countTestingSpecial(this.testingSpecialSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllTestingSpecial();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.testingSpecialForm.markAllAsTouched();

      const testingSpecialDTO = new TestingFormData(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.testingSpecialForm);
      const diagnosisData = new DiagnosisDataHelper(testingSpecialDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      testingSpecialDTO.bodyChartAnatomiesDiagramId = this.chosenBodyChartAnatomyDiagramId;

      console.log(testingSpecialDTO);

      if (!testingSpecialDTO.hasMultiSelectErrors) {

        this.testingSpecialServices.addTestingSpecialCpa(testingSpecialDTO).subscribe(res => {

          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameTestingSpecial) +
              this.translate(this.labels.addedSuccessfully),
          });

          this.lovDataObject = {"refId":0,"refType":"","reset": true};
          this.lovDataObject = {"refId":0,"refType":"","reset": false};
          this.submitted = true;
          this.showHideAddDialog();
          this.findAllTestingSpecialByAppointmentIdAndBodyChartAnatomyDiagramId();

          if (uniqueCaseDiagnosisPredictionList.length > 0) {
            this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

              console.log(res);
            });
          }

          if (testingSpecialDTO.redFlagData.length > 0) {

            this.lovServices.createListOfCaseRedFlags(testingSpecialDTO.redFlagData).subscribe(res => {

              if (res.status === 201) {

                this.messageService.add({
                  severity: 'success',
                  summary: this.translate("Red Flags Updated"),
                });
              }

            });
          }
        });
      }
      else {

        testingSpecialDTO.multiSelectErrorFields.forEach(multiSelectError => {

          document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
        });
      }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllTestingSpecial();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.testingSpecialSearch.reset();
        this.countTestingSpecial();
    }

    addUpdateTestingSpecial(testingSpecial) {

        this.startLoader();
        if (this.updateTestingSpecialId == null) {
            this.testingSpecialServices.addTestingSpecial(this.testingSpecialForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    testingSpecial = res['data'][0];
                    this.testingSpecialList.push(testingSpecial);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameTestingSpecial) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameTestingSpecial)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countTestingSpecial();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            testingSpecial.testingSpecialId = this.updateTestingSpecialId;
            this.testingSpecialServices.updateTestingSpecial(testingSpecial,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.testingSpecialList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameTestingSpecial) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameTestingSpecial)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateTestingSpecialId = null;
            // this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findSidesCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('SidesCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.sidesCategoryList.push(dropDown);
                    }
                } else {
                    this.sidesCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findBooleanCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('BooleanCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.booleanCategoryList.push(dropDown);
                    }
                } else {
                    this.booleanCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));
        this.objectMultiSelects = this.testingSpecialObjectMultiSelects.objectMultiSelects;


      this.lovDataObject = {"refId":0,"refType":""};
        this.findBooleanCategory();
        this.findSidesCategory();
        this.testingSpecialForm = new FormGroup({
                                                    side: new FormControl('', [Validators.required]),
                                                    testComments: new FormControl('', [Validators.required, validateSpaces]),
                                                    bodyChartId: new FormControl(0),
                                                    userId: new FormControl(0),
                                                });
        this.testingSpecialSearch = new FormGroup({
                                                      testType: new FormControl(),
                                                      side: new FormControl(),
                                                      testComments: new FormControl(),
                                                      patientId: new FormControl(),
                                                      expertId: new FormControl(),
                                                      appointmentId: new FormControl(),
          caseId: new FormControl(),
                                                      bodyChartId: new FormControl(),
                                                      userId: new FormControl(),
                                                  });
        this.findAllTestingSpecialByAppointmentIdAndBodyChartAnatomyDiagramId();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.testingSpecialSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

  findAllTestingSpecialByAppointmentIdAndBodyChartAnatomyDiagramId(): void {


    this.clinicalDataService.findAllTestingSpecialByAppointmentIdAndBodyChartAnatomyDiagramId(this.appointmentId, this.chosenBodyChartAnatomyDiagramId).subscribe(res => {

      this.startLoader();

      if (res.status === HttpStatusCode.Ok) {

        this.testingSpecialList = res.body;
        this.stopLoader();

      }
    }, err => {

      this.stopLoader();
      this.messageService.add(
        {
          severity: 'error',
          summary: err,
          // detail: this.translate(this.labels.entityNameTestingArom)
        });
    });
  }



  findAllTestingSpecial() {
        this.startLoader();
        this.testingSpecialServices.findAllTestingSpecial(this.testingSpecialSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.testingSpecialList = res['data'];
                } else {
                    this.testingSpecialList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdTestingSpecial(testingSpecialId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.testingSpecialServices.deleteByIdTestingSpecial(testingSpecialId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.testingSpecialList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameTestingSpecial) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameTestingSpecial)
                                                });
                        this.countTestingSpecial();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deleteTestingSpecial"
                                  });
    }

    loadTestingSpecialToEdit(testingSpecialId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let testingSpecial = this.testingSpecialList.find(x => x['testingSpecialId'] == testingSpecialId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateTestingSpecialId = testingSpecial['testingSpecialId'];
        this.selectedIndex = index;

        this.lovDataObject = {"refId":testingSpecial['testingSpecialId'],"refType":""};

        if(testingSpecial['specialTestsPerformed']) {
            this.testingSpecialForm.controls['specialTestsPerformed'].setValue(testingSpecial['specialTestsPerformed']['listOfValuesId']);
        }
        if(testingSpecial['testType']['listOfValuesMasterId']) {
            this.testingSpecialForm.controls['testType'].setValue(testingSpecial['testType']['listOfValuesMasterId']);
        }
        if(testingSpecial['side']) {
            this.testingSpecialForm.controls['side'].setValue(testingSpecial['side']['listOfValuesId']);
        }
        if(testingSpecial['testComments']) {
            this.testingSpecialForm.controls['testComments'].setValue(testingSpecial['testComments']);
        }
        if(testingSpecial['patientId']) {
            this.testingSpecialForm.controls['patientId'].setValue(testingSpecial['patientId']['patientId']);
        }
        if(testingSpecial['expertId']) {
            this.testingSpecialForm.controls['expertId'].setValue(testingSpecial['expertId']['expertId']);
        }
        if(testingSpecial['appointmentId']) {
            this.testingSpecialForm.controls['appointmentId'].setValue(testingSpecial['appointmentId']['appointmentId']);
        }

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.testingSpecialForm.reset();
        this.testingSpecialForm.markAsUntouched();
        this.updateTestingSpecialId = null;
    }

    // findAllListOfValuesDetail() {
    //     let temp = [];
    //     this.startLoader();
    //     this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = {label: '', value: ''};
    //                     dropDown.value = object['listOfValuesDetailId'];
    //                     dropDown.label = object['referenceType'];
    //                     this.listOfValuesDetailList.push(dropDown);
    //                 }
    //             } else {
    //                 this.listOfValuesDetailList = [];
    //             }
    //             this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }

    fieldReset(control) {
        return this.testingSpecialForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.testingSpecialForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.testingSpecialForm.get(control)?.valid && this.testingSpecialForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.testingSpecialForm.reset();
    }

    onCloseSearch() {
        this.testingSpecialSearch.reset();
    }

    refresh() {
        this.countTestingSpecial();
    }

    onAddOverlayDiv() {
        this.testingSpecialForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.testingSpecialSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.testingSpecialForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateTestingSpecialId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.testingSpecialServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.testingSpecialList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.testingSpecialServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.testingSpecialList = result['data'];
            } else {
                this.testingSpecialList = [];
            }
            console.log(result, 'result');
        });
    }
}
