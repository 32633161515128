import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContactsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdContacts(contactsId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Contacts/deleteContacts', {'contactsId': contactsId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countContacts(contacts): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Contacts/count', {'contacts': contacts}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateContacts(contacts): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Contacts/updateContacts', [contacts], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addContacts(contacts): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Contacts/addContacts', [contacts], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllContacts(contacts, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Contacts/findAllContacts',
            {'contacts': contacts, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    //custom service for global searching
    contactsGlcount(searchTerm,refId): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Contacts/countGlobalContacts',
                                            {'searchTerm': searchTerm,"refId":refId},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalContacts(searchTerm,refId, pageNo: number, itemsPerPage: number): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Contacts/findGlobalContacts',
            {'searchTerm': searchTerm,'refId':refId,'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
