import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencyClinicService} from '../web-services/agency-clinic.service';
import {AgencyService} from '../web-services/agency.service';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-agency-clinic', templateUrl: './agency-clinic.component.html', styleUrls: ['./agency-clinic.component.css']})
export class AgencyClinicComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAgencyClinicId = null;
    agencyList: any = [];
    usersList: any = [];
    agencyClinicList: any = [];
    agencyClinicSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencyClinicForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private agencyClinicServices: AgencyClinicService, private agencyServices: AgencyService, private usersServices: UsersService,
        private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService, private messageService: MessageService,
        private route: Router, private loader: NgxUiLoaderService, private constantService: ConstantService,
        private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateAgencyClinicId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.agencyClinicSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgencyClinic();
        this.findAllAgencyClinic();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countAgencyClinic() {
        this.agencyClinicServices.countAgencyClinic(this.agencyClinicSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAgencyClinic();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateAgencyClinic(this.agencyClinicForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAgencyClinic();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencyClinicSearch.reset();
        this.countAgencyClinic();
        // this.findAllAgencyClinic();
    }

    addUpdateAgencyClinic(agencyClinic) {
        this.startLoader();
        if (this.updateAgencyClinicId == null) {
            this.agencyClinicServices.addAgencyClinic(this.agencyClinicForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    agencyClinic = res['data'][0];
                    this.agencyClinicList.push(agencyClinic);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameAgencyClinic) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameAgencyClinic)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countAgencyClinic();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            agencyClinic.agencyClinicId = this.updateAgencyClinicId;
            this.agencyClinicServices.updateAgencyClinic(agencyClinic).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencyClinicList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameAgencyClinic) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameAgencyClinic)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countAgencyClinic();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencyClinicId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllAgency();
        this.findAllUsers();
        this.agencyClinicForm = new FormGroup(
            {
                agencyId: new FormControl(),
                clinicId: new FormControl(0, [Validators.required, Validators.min(0)]),
                userId: new FormControl(),
            });
        this.agencyClinicSearch = new FormGroup({agencyId: new FormControl(), clinicId: new FormControl(), userId: new FormControl(),});
        this.countAgencyClinic();
        this.findAllAgencyClinic();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencyClinicSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllAgencyClinic() {
        this.startLoader();
        this.agencyClinicServices.findAllAgencyClinic(this.agencyClinicSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencyClinicList = res['data'];
                } else {
                    this.agencyClinicList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgencyClinic(agencyClinicId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencyClinicServices.deleteByIdAgencyClinic(agencyClinicId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.agencyClinicList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameAgencyClinic) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameAgencyClinic)
                                                });
                        this.countAgencyClinic();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadAgencyClinicToEdit(agencyClinicId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let agencyClinic = this.agencyClinicList.find(x => x['agencyClinicId'] == agencyClinicId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencyClinicId = agencyClinic['agencyClinicId'];
        this.selectedIndex = index;
        this.agencyClinicForm.controls['agencyId'].setValue(agencyClinic['agencyId']['agencyId']);
        this.agencyClinicForm.controls['clinicId'].setValue(agencyClinic['clinicId']);
        this.agencyClinicForm.controls['userId'].setValue(agencyClinic['userId']['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.agencyClinicForm.reset();
        this.agencyClinicForm.markAsUntouched();
        this.updateAgencyClinicId = null;
        closeAddOverlaydiv();
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.agencyClinicForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencyClinicForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencyClinicForm.get(control)?.valid && this.agencyClinicForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.agencyClinicForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.agencyClinicSearch.reset();
    }

    refresh() {
        this.countAgencyClinic();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.agencyClinicForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencyClinicForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAgencyClinicId = null;
    }
}
