<div class="h-100">
    <div class="loginContainer" id="loginforgotpassword-btn">

        <!--Login Header-->
        <div class="loginHeader" id="loginforgotpassword-Header">
            <div class="container">
                <div class="loginHeaderInner">
                    <div class="loginHeading">
                        <h3>Welcome to CPA</h3>
                    </div>
                    <div class="loginLogo">
                        <a> <img src="assets/theme1/custom-files/img/logos/cpalogo.png"/> </a>
                    </div>

                </div>
            </div>
        </div>

        <!-- Login Body Area -->
        <div class="container-fluid h-100" id="loginforgotpassword-Body">
            <div class="row justify-content-center align-items-center h-100">
                <div class="loginBox">
                    <p-card id="loginforgotpassword-card" styleClass="p-card-shadow" *ngIf="!submitted else elseBlock">
                        <div class="loginTitle">
                            <h3> Forgot Your Password? </h3>
                            <h5>Enter your email address below to receive password reset instructions.</h5>
                        </div>

                        <div class="footerForgotPassword" id="loginforgotpassword-secondfooter">
                            <p class="m-0"> Already have an account? </p>
                            <p-button [routerLink]="[ '/', 'login' ]" id="loginforgotpassword-loginherebtn"
                                      label="Login here" styleClass="p-button-link"></p-button>

                        </div>

                        <form (blur)="logValidationErrors()" [formGroup]="emailVerificationForm" class="formContainer"
                              id="loginforgotpassword-formContainer">
                            <div class="p-fluid p-formgrid p-grid">
                                <div [ngClass]="{ 'invalidfield': formErrors.Email }" class="p-field p-col-12 "
                                     id="loginforgotpassword-email">
                                    <label for="login-email">Email</label>
                                    <small *ngIf="formErrors.Email">{{formErrors.Email}}</small>
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-envelope"></i>
                                        <input (blur)="logValidationErrors()" formControlName="email" id="login-email"
                                               pInputText placeholder="brucewayne@hiveworx.com"
                                               type="email"/>
                                    </span>
                                </div>
                            </div>
                        </form>

                        <div class="footer-content p-d-flex p-jc-between mt-3 mb-5" id="loginforgotpassword-footer">
                            <p-button (click)="emailVerfication()"
                                      [disabled]="!emailVerificationForm.valid" class="btn-block" id="login-btn"
                                      label="Verify Email"
                                      styleClass="btn-block">
                            </p-button>
                        </div>
                    </p-card>
                  <ng-template #elseBlock>
                    <p-card id="loginforgotpassword-card" styleClass="p-card-shadow">
                      Thank you, if the email address you entered is associated with an account, you will receive an email with instructions on how to reset your password.
                    </p-card>
                  </ng-template>
                </div>
            </div>
        </div>


    </div>
</div>
