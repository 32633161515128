import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ObservationsMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['observations']: {
      name: Constant.OBSERVATIONS_DROPDOWN_CATEGORY_OBSERVATIONS,
      dbField: 'observations',
      label: 'Observations',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false


    }
  };

  reset = false;

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
