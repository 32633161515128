import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {InitialAssessmentSteps} from "../initial-assessment-steps/InitialAssessmentSteps";
import {SessionService} from "../services/session.service";

@Component({
  selector: 'app-observations-main',
  templateUrl: './observations-main.component.html',
  styleUrls: ['./observations-main.component.css']
})
export class ObservationsMainComponent implements OnInit {

  urlCalled = false;
  stepsItems: any;
  activeStep: number;
  patientObj: any;

  constructor(
      private router: Router,
      private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('/observations')) {
      this.urlCalled = true;
    }

    this.stepsItems = InitialAssessmentSteps.stepItems;
    this.activeStep = parseInt(this.stepsItems.find(step => step.route === 'observations').number);
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;

  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }

}
