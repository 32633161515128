import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ClinicExpertsService} from '../web-services/clinic-experts.service';
import {UsersService} from '../web-services/users.service';
import { SessionService } from '../services/session.service';
import { ClinicsService } from '../web-services/clinics.service';
import { ExpertService } from '../web-services/expert.service';
import {HttpStatusCode} from '@angular/common/http';
import {User} from '../dto/User';

@Component({selector: 'app-clinic-experts', templateUrl: './clinic-experts.component.html', styleUrls: ['./clinic-experts.component.css']})
export class ClinicExpertsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateClinicExpertsId = null;
    usersList: any = [];
    clinicExpertsList: any = [];
    clinicExpertsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    clinicExpertsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    clinicsList : any = [];
    expertsList : any = [];

    loggedInUserId : any;
    loggedInUserType : any;
    loggedInRefId : any;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    fullAuthenticatedUser: User;

    addEditTitle = 'Add';
    clientId: number;

    constructor(
        private clinicExpertsServices: ClinicExpertsService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService : SessionService,
        private clinicService : ClinicsService,
        private expertService : ExpertService
    ) {
    };

    get addUpdateTitle() {
        return this.updateClinicExpertsId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.clinicExpertsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countClinicExperts();
        this.findAllClinicExperts();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countClinicExperts() {
        this.clinicExpertsSearch.controls['userId'].setValue(this.loggedInUserId);
       // this.findAllClinicExperts();
       // this.totalRecords = this.clinicExpertsList.length;
        this.clinicExpertsServices.countClinicExperts(this.clinicExpertsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllClinicExperts();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.clinicExpertsForm.controls['userId'].setValue(this.loggedInUserId);
        this.clinicExpertsForm.controls['clientId'].setValue(this.clientId);
        this.clinicExpertsForm.markAllAsTouched();
        console.log("clinic expert form :: ",this.clinicExpertsForm.value);

        if(this.clinicExpertsForm.valid){
            this.addClinicExpert();
            this.submitted = false;
            this.onClearAdd();
        }

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllClinicExpertsByClientId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.clinicExpertsSearch.reset();
        this.findAllClinicExpertsByClientId();
        // this.findAllClinicExperts();
    }

    addClinicExpert(): void {

      this.startLoader();


      if (this.updateClinicExpertsId == null) {
        this.clinicExpertsServices.addClinicExpertCpa(this.clinicExpertsForm.value).subscribe(res => {

          if (res.status === HttpStatusCode.Created) {

            this.messageService.add({
              severity: 'success',
              summary: this.translate(this.labels.entityNameClinicExperts) +
                this.translate(this.labels.addedSuccessfully),
            });
            this.submitted = true;
            this.stopLoader();
            this.showHideAddDialog();
            this.findAllClinicExpertsByClientId();

          }

        }, err => {

          this.messageService.add({
            severity: 'error',
            summary: err,
            // detail: this.translate(this.labels.entityNameClinicExperts)
          });
        });

      }
    }

    findAllClinicExpertsByClientId(): void {
      this.startLoader();


      this.clinicExpertsServices.findAllClinicExpertsByClientId(this.clientId).subscribe(res => {

        if (res.status === HttpStatusCode.Ok) {

          this.clinicExpertsList = res.body;
          this.totalRecords  = this.clinicExpertsList.length;
        }

      }, err => {

        this.messageService.add({
          severity: 'error',
          summary: err,
          // detail: this.translate(this.labels.entityNameClinicExperts)
        });
      });

    }

    addUpdateClinicExperts(clinicExperts) {
        this.startLoader();
        this.clinicExpertsForm.controls['userId'].setValue(this.loggedInUserId);
        if (this.updateClinicExpertsId == null) {
            this.clinicExpertsServices.addClinicExperts(this.clinicExpertsForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    clinicExperts = res['data'][0];
                    this.clinicExpertsList.push(clinicExperts);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameClinicExperts) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameClinicExperts)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countClinicExperts();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            clinicExperts.clinicExpertsId = this.updateClinicExpertsId;
            this.clinicExpertsServices.updateClinicExperts(clinicExperts).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.clinicExpertsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameClinicExperts) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countClinicExperts();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateClinicExpertsId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        // this.findAllUsers();
        this.loggedInUserId = parseInt(this.sessionService.getSession('loggedInUser'));
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.fullAuthenticatedUser = JSON.parse(this.sessionService.getSession('fullAuthenticatedUser')) ;
        this.clientId = this.fullAuthenticatedUser.clientId;
        this.findAllClinics();
        this.findAllExperts();


        this.clinicExpertsForm = new FormGroup({
                                                   clinicId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                   expertId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                    clientId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                   userId: new FormControl(),
                                               });

        this.clinicExpertsSearch = new FormGroup({
            clinicId: new FormControl(),
            expertId: new FormControl(),
            userId: new FormControl(),
        });
        this.findAllClinicExpertsByClientId();
        // this.findAllClinicExperts();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.clinicExpertsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllClinics(){
        this.startLoader();
        this.clinicService.findByClientId(this.clientId).subscribe(
            res => {
                console.log(res, 'clinic listing');
                if (res.status === HttpStatusCode.Ok) {
                    let clinics = res.body;
                    // this.clinicsList = res['data'];
                    for (let object of clinics) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object.clinicId;
                        dropDown.label = object.clinicName;
                        this.clinicsList.push(dropDown);
                        console.log("drop down values array :: ",dropDown);
                    }
                } else {
                    this.clinicsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllExperts(){
        this.startLoader();
        this.expertService.findAllExpertsByClientId(this.fullAuthenticatedUser.clientId).subscribe(
            res => {
                if (res.status === HttpStatusCode.Ok) {
                    let experts = res.body;

                    // this.expertsList = res['data'];
                    for (let object of experts) {

                        let dropDown = {label: '', value: ''};
                        dropDown.value = object.expertId;
                        dropDown.label = object.firstName + ' ' + object.lastName;
                        this.expertsList.push(dropDown);
                        console.log("drop down values array :: ",dropDown);
                    }

                } else {
                    this.expertsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllClinicExperts() {
        this.startLoader();
        this.clinicExpertsServices.findAllClinicExperts(this.clinicExpertsSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.clinicExpertsList = res['data'];
                } else {
                    this.clinicExpertsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdClinicExperts(clinicExpertsId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.clinicExpertsServices.deleteByIdClinicExperts(clinicExpertsId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.clinicExpertsList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameClinicExperts) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameClinicExperts)
                                                });
                        this.countClinicExperts();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadClinicExpertsToEdit(clinicExpertsId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let clinicExperts = this.clinicExpertsList.find(x => x['clinicExpertsId'] == clinicExpertsId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateClinicExpertsId = clinicExperts['clinicExpertsId'];
        this.selectedIndex = index;

        if(clinicExperts['clinicId']){
            this.clinicExpertsForm.controls['clinicId'].setValue(clinicExperts['clinicId']['clinicId']);
        }

        if(clinicExperts['expertId']){
            this.clinicExpertsForm.controls['expertId'].setValue(clinicExperts['expertId']['expertId']);
        }

        if(clinicExperts['userId']){
            this.clinicExpertsForm.controls['userId'].setValue(clinicExperts['userId']);
        }

        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.clinicExpertsForm.reset();
        this.clinicExpertsForm.markAsUntouched();
        this.updateClinicExpertsId = null;
        // closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.clinicExpertsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.clinicExpertsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.clinicExpertsForm.get(control)?.valid && this.clinicExpertsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // closeAddOverlaydiv();
        this.clinicExpertsForm.reset();
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.clinicExpertsSearch.reset();
    }

    refresh() {
        this.countClinicExperts();
    }

    onAddOverlayDiv() {
        // openAddOverlaydiv();
        this.clinicExpertsForm.reset();
    }

    searchTerm : any = null;
    onGlobalSearchClick(searchTerm){

        this.searchTerm = searchTerm.trim();
        if(this.searchTerm) {
            this.clinicExpertGlCount();
        } else {
            this.countClinicExperts();
        }

    }

    clinicExpertGlCount(){
        this.clinicExpertsServices.clinicExpertGlcount(this.searchTerm,this.loggedInRefId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findGlClinicExpert();
            } else {
                this.totalRecords = 0;
                this.clinicExpertsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findGlClinicExpert(){
        this.clinicExpertsServices.findGlobalClinicExpert(this.searchTerm, this.loggedInRefId, this.pageNo, this.itemPerPage).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.clinicExpertsList = result['data'];
            }else{
                this.clinicExpertsList = [];
            }
            console.log(result,"result");
        });
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.clinicExpertsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }
}
