import {FormDataDTO} from "./FormDataDTO";

export class BodyChartFormDataDTO extends FormDataDTO {

  bodyAnatomyInformation: any;
  duration: number;
  hourlySymptoms: number;
  bodyChartAnatomyBodyPart: string;
  bodyChartAnatomiesDiagramId: string;
  bodyChartAnatomyPainScore: number;
  bodyChartAnatomyNotes: string;
  isFollowUp = false;

  constructor(appointmentId: number, patientId: number, expertId: number, caseId: number, multiSelectData: any, formData: any, bodyAnatomyInformation: any) {

    super(appointmentId, patientId, expertId, caseId, multiSelectData, formData);
    this.bodyChartAnatomyBodyPart = bodyAnatomyInformation.bodyChartAnatomyBodyPart;
    this.bodyChartAnatomyPainScore = bodyAnatomyInformation.bodyChartAnatomyPainScore;
    this.bodyChartAnatomyNotes = bodyAnatomyInformation.bodyChartAnatomyNotes;
    this.bodyChartAnatomiesDiagramId = bodyAnatomyInformation.bodyChartAnatomiesDiagramId;
   /// this.bodyAnatomyFrontImageFile = bodyAnatomyFrontImageFile;

  }


}
