import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Constant } from '../util/constant';


@Injectable({
  providedIn: 'root'
})
export class CommunicationsTypeService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  constructor(private httpClient: HttpClient) {};
  deleteByIdCommunicationsType (CommunicationsTypeId ):Observable<string> {
  return this.httpClient.post<string>(Constant.BASE_URL + "CommunicationsType/deleteCommunicationsType", { 'CommunicationsTypeId': CommunicationsTypeId }, this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  countCommunicationsType (CommunicationsType ):Observable<number> {
  return this.httpClient.post<number>(Constant.BASE_URL + "CommunicationsType/count", {"CommunicationsType": CommunicationsType } , this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  updateCommunicationsType (CommunicationsType ):Observable<string> {
  return this.httpClient.post<string>(Constant.BASE_URL + "CommunicationsType/updateCommunicationsType", [CommunicationsType], this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  addCommunicationsType (CommunicationsType ):Observable<string> {
  return this.httpClient.post<string>(Constant.BASE_URL + "CommunicationsType/addCommunicationsType", [CommunicationsType], this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  findAllCommunicationsType (CommunicationsType ,pageNo :number ,itemsPerPage :number ):Observable<string[]> {
  return this.httpClient.post<string[]>(Constant.BASE_URL + "CommunicationsType/findAllCommunicationsType",  { "CommunicationsType":  CommunicationsType , "pagination" :{ 'pageNo': pageNo, 'itemsPerPage': itemsPerPage }}, this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  

}
