<div class="chatoverlaydiv" id="createselectusernewchat-overlaydiv">
    <i (click)="backToChatComponent()" class="fas fa-arrow-left" style="float: left;"></i>

    <div class="addparticipantswrapper">
        <ng-container *ngIf="contextId>0 && !addParticipantButton">
            <div class="Contextual-option-div">
                {{verifyLabel('Contextual')}}
                <mat-slide-toggle (change)="changeContexualChat($event)" [(ngModel)]="contexutualGroupChat"
                                  class="angSlidetoggle" id=createnewchat-slidetoggle></mat-slide-toggle>
            </div>
        </ng-container>
        <ng-container *ngIf="!newChatButtonCliked && selectedParticipants.length>0">
            <h5 class="addparticipants-heading">{{verifyLabel('Added Contacts')}}</h5>

            <div class="addparticipantswrapper-body" id="createselectusernewchat-body-searchcontactsbox">
                <ng-container *ngFor="let item of selectedParticipants; let i = index;">
                    <div class="chat-addparticipants">
                        {{item.firstName}} {{item.lastName}}
                        <a (click)="onOptionDeleted(item)" class="chat-addparticipants-admin-closebtn">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>
                    <!-- <div class="chat-addparticipants">
                      Jonathan
                      <a href="#" class="chat-addparticipants-closebtn"  >
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                    <div class="chat-addparticipants">
                      Lorcan Neary
                      <a href="#" class="chat-addparticipants-closebtn"  >
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                    <div class="chat-addparticipants-admin">
                      Julia Roberts
                      <a href="#" class="chat-addparticipants-closebtn"  >
                        <i class="fa fa-times"></i>
                      </a>
                    </div> -->
                </ng-container>
            </div>
        </ng-container>
    </div>

     <mat-form-field class="angInput" id="createselectusernewchat-angInput-search">
      <input matInput type="text" name="searchText" [(ngModel)]="searchCollaborator" placeholder="Search Contacts">
      <button class="btnxs-transparent-icon-inputfields">
        <ng-container *ngIf="searchCollaborator!=''">
          <i (click)="[searchCollaborator='',searchCollaborators()]" class="close-icon"></i>
        </ng-container>
        <i (click)="searchCollaborators()" class="search-icon"></i>
      </button>
    </mat-form-field>

<!--    <span class="p-input-icon-right searchBarSpan"-->
<!--          id="addFriendsPage-Friends-searchBarSpan">-->
<!--    <input type="text" class="searchBarInput" pInputText placeholder="Search Contacts" [(ngModel)]="searchCollaborator">-->
<!--    <img src="assets/theme1/custom-files/img/headericons/Search.png" class="searchBarSpan-searchiconimg"-->
<!--         id="addFriendsPage-Friends-searchiconimg" (click)="searchCollaborators()">-->
<!--  </span>-->

    <div class="contacts_body" id="contacts_body_serachcontacts">
        <ng-container *ngIf="!addParticipantButton && !newChatButtonCliked">
            <button class="btnmd chat_go_btn3rdscreen" (click)="createGroup()">
                <i class="fas fa-arrow-right"></i>
            </button>
        </ng-container>
        <!-- <ng-container *ngIf="addParticipantButton">
          <button class="btnmd chat_go_btn3rdscreen" (click)="moveToParticipantPage()">
            <i class="fas fa-arrow-right"></i>
          </button>
        </ng-container> -->
        <ul class="contacts">
            <ng-container *ngFor="let collaborator of filteredCollaborators; let i = index">
                <li class="contact_user">
                    <div class="contact_user_content">
                        <div class="img_cont">
                            <!-- <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img"> -->
                            <img [src]="collaborator.profileImage"
                                 (error)="collaborator.profileImage='assets/theme1/custom-files/img/default_thumb/profilepic.png'"
                                 alt="" class="rounded-circle user_img">
                            <span class="online_icon"></span>
                        </div>
                        <div class="user_info">
                            <ng-container *ngIf="addParticipantButton">
                                <a (click)="openChat(collaborator)">
                                    <span>{{collaborator.firstName}} {{collaborator.lastName}}</span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="newChatButtonCliked">
                                <a (click)="openChat(collaborator)">
                                    <span>{{collaborator.firstName}} {{collaborator.lastName}}</span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!addParticipantButton && !newChatButtonCliked">
                                <a (click)="onOptionSelected(collaborator,i)">
                                    <span>{{collaborator.firstName}} {{collaborator.lastName}}</span>
                                </a>
                            </ng-container>
                            <!-- <span> {{collaborator.username}}</span> -->
                            <!-- <ng-container *ngIf="!addParticipantButton && !newChatButtonCliked">
                              <div class="chat-addparticipants-addadmin">
                                {{verifyLabel('Add as Admin')}}
                              </div>
                            </ng-container> -->

                            <!-- static online -->
                            <!-- <p> {{collaborator.username}} is
                              {{collaborator.status=='' || collaborator.status== null ? 'offline' : collaborator.status}}</p> -->

                            <p> {{collaborator.firstName}} is online
                            </p>
                        </div>
                    </div>
                </li>
            </ng-container>
            <!-- <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Jonathan</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li class="contact_user">
              <div class="contact_user_content">
                <div class="img_cont">
                  <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img">
                  <span class="online_icon"></span>
                </div>
                <div class="user_info">
                  <span>Manage Projects</span>
                  <div class="chat-addparticipants-addadmin">
                    Add as Admin
                  </div>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li> -->


        </ul>
    </div>


</div>


<div class="chatoverlaydiv" id="createnewchat-overlaydiv">
    <i (click)="backToUsersComponent()" class="fas fa-arrow-left" style="float: left;"></i>
    <div class="addparticipantswrapper">
        <h5 class="addparticipants-heading">{{verifyLabel('Added Contacts')}} </h5>
        <div class="addparticipantswrapper-body" id="addparticipantswrapper-body-createchat">
            <ng-container *ngFor="let item of selectedParticipants; let i = index;">
                <div class="chat-addparticipants">
                    {{item.firstName}}
                    <a (click)="onOptionDeleted(item)" class="chat-addparticipants-admin-closebtn">
                        <i class="fa fa-times"></i>
                    </a>
                </div>
            </ng-container>
            <!-- <div class="chat-addparticipants">
              Jonathan
              <a href="#" class="chat-addparticipants-closebtn"  >
                <i class="fa fa-times"></i>
              </a>
            </div>
            <div class="chat-addparticipants">
              Lorcan Neary
              <a href="#" class="chat-addparticipants-closebtn"  >
                <i class="fa fa-times"></i>
              </a>
            </div>
            <div class="chat-addparticipants">
              Julia Roberts
              <a href="#" class="chat-addparticipants-closebtn"  >
                <i class="fa fa-times"></i>
              </a>
            </div> -->
        </div>
    </div>

      <mat-form-field class="example-form-field">
      <input name="title" [(ngModel)]="groupModel.title" matInput type="text" placeholder="Chat Title">
    </mat-form-field>
<!--    <input pInputText type="text" class="form-control" [(ngModel)]="groupModel.title" placeholder="Chat Title"-->
<!--           pInputText>-->
    <!-- <input pInputText type="text" class="form-control" [(ngModel)]="groupModel.description" placeholder="Chat Description"
    pInputText> -->

<!--    <textarea [rows]="2" pInputTextarea class="form-control" [(ngModel)]="groupModel.description"-->
<!--              placeholder="Chat Description"></textarea>-->
     <mat-form-field class="example-form-field">
      <input matInput type="text" name="description" [(ngModel)]="groupModel.description" placeholder="Chat Description">
    </mat-form-field>
    <!-- <div class="Contextual-option-div">
      Contextual
      <mat-slide-toggle class="angSlidetoggle" id=createnewchat-slidetoggle></mat-slide-toggle>
    </div> -->
    <!-- <ng-container *ngIf="!addParticipantButton">
      <button class="btnmd chat_go_btn3rdscreen" (click)="moveToParticipantPage()">
        <i class="fas fa-arrow-right"></i>
      </button>
    </ng-container> -->
    <ng-container *ngIf="!addParticipantButton">
        <div class="createchat-btn-div">
            <button (click)="groupModelValues()" class="btnmd">{{verifyLabel('Create Chat')}} </button>
        </div>
    </ng-container>


</div>