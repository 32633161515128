<p-dialog header="Add Value" [modal]="true" [(visible)]="display">
  <div class="custom-dialog-content">
    <input type="text" pInputText [(ngModel)]="lovValue"/>

    <!-- <button pButton type="button" label="Add" class="pngBtnTransparent" (click)="addListOfValue()"></button> -->
    <p-button class="pngBlueBtn" label="Add" styleClass="p-button"
    (click)="addListOfValue()"></p-button>
  </div>
</p-dialog>
  <form [formGroup]="form">

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field" [ngClass]="half ? 'p-col-6' : 'p-col-12'">


        <!-- <label >ABC</label>
        <i class="pi pi-plus myplusicon" (click)="showDialog()"></i> -->

      <div class="label-and-btn">
        <label>{{label != ''? label: 'Field'}}</label>
        <i class="pi pi-plus addToDropdownBtn" (click)="showDialog()"></i>
      </div>

        <!-- <p-multiSelect [options]="listOfValuesList" formControlName="selectedValues" optionLabel="label" display="chip">
        </p-multiSelect> -->

        <p-multiSelect
                [options]="listOfValuesList"
                formControlName="selectedArray"
                optionLabel="label"
                (onChange)="selectedValues($event)"
                display="chip">
          <ng-template let-lov pTemplate="item">
            <div>
              <div>{{lov.value}}<i class="fa fa-flag" [ngClass]="'flag-' + lov['colour']" *ngIf="!hasNoRedFlags(lov)" style="padding-left:5px!important;color:red"></i> </div>
            </div>
          </ng-template>
        </p-multiSelect>

        <!-- <pre>{{form.value| json}}</pre> -->
      </div>
    </div>
  </form>
