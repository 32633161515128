import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    token = '';

    constructor(private deviceService: DeviceDetectorService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let body = {};

      if (request.url.includes('CPAUMS') || request.url.includes('Hive-Worx')) {

        body = {
          'subProjectId': '123Abc',
          'currentProjectId': 1234,
          'currentUserId': 1234,
          'applicationId': 1,
          'versionId': 122,
          'projectTitle': 'projectTitle',
          'username': 'userName',
          'projecttype': 'type',
          'clientId': 112,
          'serviceStatus': 'yes',
          'serviceMessage': 'Successfully',
          'serviceReturnCode': 123,
          'data': request.body

        };
      }
      else {
        console.log(request.body);

        body = request.body;
      }

        if (sessionStorage.getItem('token') != null) {
            this.token = sessionStorage.getItem('token');
            console.log(request);
            let reqContentType = request.headers.get('Content-Type');

         if (reqContentType == null) {

              reqContentType = 'application/json';
         }


            request = request.clone({


                                        setHeaders: {
                                            'Content-Type': reqContentType,
                                            Authorization: this.token,
                                            browser: this.deviceService.browser,
                                            browser_version: this.deviceService.browser_version,
                                            device: 'laptop',
                                            os: this.deviceService.os,
                                            os_version: this.deviceService.os_version

                                        },

                                        body: body
                                    }
            );
            return next.handle(request);
        } else {

            request = request.clone({


                                        setHeaders: {
                                            'Content-Type': 'application/json',
                                        },

                                        body: body
                                    }
            );
            return next.handle(request);
        }


    }

}
