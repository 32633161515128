import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {CategoriesService} from '../web-services/categories.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-categories', templateUrl: './categories.component.html', styleUrls: ['./categories.component.css']})
export class CategoriesComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateCategoryId = null;
    categoriesList: any = [];
    categoriesSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    categoriesForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private categoriesServices: CategoriesService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateCategoryId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.categoriesSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countCategories();
        this.findAllCategories();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countCategories() {
        this.categoriesServices.countCategories(this.categoriesSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllCategories();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateCategories(this.categoriesForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllCategories();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.categoriesSearch.reset();
        this.countCategories();
        // this.findAllCategories();
    }

    addUpdateCategories(categories) {
        this.startLoader();
        if (this.updateCategoryId == null) {
            this.categoriesServices.addCategories(this.categoriesForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    categories = res['data'][0];
                    this.categoriesList.push(categories);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.btnAdd),
                            detail: this.translate(this.labels.entityNameCategories)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countCategories();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            categories.categoryId = this.updateCategoryId;
            this.categoriesServices.updateCategories(categories).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.categoriesList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameCategories)
                                            });
                    this.showSearchBtn = true;
                    this.showAddBtn = true;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countCategories();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateCategoryId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.categoriesForm = new FormGroup({
                                                categoryTitle: new FormControl('', [Validators.required, validateSpaces]),
                                                description: new FormControl('', [Validators.required, validateSpaces]),
                                                userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                            });
        this.categoriesSearch = new FormGroup(
            {categoryTitle: new FormControl(), description: new FormControl(), userId: new FormControl(),});
        this.countCategories();
        this.findAllCategories();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.categoriesSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllCategories() {
        this.startLoader();
        this.categoriesServices.findAllCategories(this.categoriesSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.categoriesList = res['data'];
                } else {
                    this.categoriesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdCategories(categoryId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.categoriesServices.deleteByIdCategories(categoryId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.categoriesList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.btnDelete),
                                detail: this.translate(this.labels.entityNameCategories)
                            });
                        this.countCategories();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadCategoriesToEdit(categoryId: number, index) {
        let categories = this.categoriesList.find(x => x['categoryId'] == categoryId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateCategoryId = categories['categoryId'];
        this.selectedIndex = index;
        this.categoriesForm.controls['categoryTitle'].setValue(categories['categoryTitle']);
        this.categoriesForm.controls['description'].setValue(categories['description']);
        this.categoriesForm.controls['userId'].setValue(categories['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.categoriesForm.reset();
        this.categoriesForm.markAsUntouched();
        this.updateCategoryId = null;
        closeAddOverlaydiv();
    }

    fieldReset(control) {
        return this.categoriesForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.categoriesForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.categoriesForm.get(control)?.valid && this.categoriesForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.categoriesForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.categoriesSearch.reset();
    }

    refresh() {
        this.countCategories();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.categoriesForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.categoriesForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateCategoryId = null;
    }
}
