<div *ngIf="(form.get(control).dirty || form.get(control).touched)" class="customError">
    <span class="sr-only">(error)</span>
    <div *ngIf="form.get(control).hasError('required')" class="error-msg">
        {{translate(labels.validation_required)}}
    </div>
    <div *ngIf="form.get(control).hasError('validSpace')" class="error-msg">
        {{translate(labels.validation_emptySpace)}}
    </div>

    <div *ngIf="form.get(control).hasError('min')" class="error-msg">
        {{translate(labels.validation_min)}}
        Minimum value should be 1
    </div>
    <div *ngIf="form.get(control).hasError('max')" class="error-msg">
        {{translate(labels.validation_max)}}
    </div>

    <div *ngIf="form.get(control).hasError('minlength')" class="error-msg">
        {{translate(labels.validation_minLength)}}
    </div>
    <div *ngIf="form.get(control).hasError('maxlength')" class="error-msg">
        {{translate(labels.validation_maxLength)}}
    </div>
</div>
