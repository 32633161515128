<!--<app-app-header></app-app-header>-->
<section class="sectionmain checklistMainSection" id="sectionmain-checklist">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-checklist-sectioncontent">
            <p-card header="" styleClass="p-card-shadow">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="p-grid checklist-headerGrid" id="checklist-headerGrid">
                    <div class="checklist-top-title" id="checklist-title">
                        <h3 class="textBold h3-style superAdminTitles">Subscription Checklist</h3>
                    </div>
                    <div class="checklist-search-wrap" id="checklist-search">
            <span class="search-and-icon p-input-icon-right p-as-center">
              <i class="pi pi-search"></i>
              <input class="checklist-search" type="search" placeholder="Search Companies.." pInputText/>
            </span>
                    </div>
                </div>
                <div *ngIf="tableDataToggle">
                    <div class="helping-and-chart-wrap p-grid" id="checklist-subTitle-Bar">
                        <div class="p-md-9 p-col-12 headerbarwidth toolbarContent">
                            <div class="toolbarwrapL">
                                <p-toolbar styleClass="toolbars">
                                    <div class="p-toolbar-group-left">
                                        <h4 class="headertext pToolBarText textBold">Healing Helpers Group</h4>
                                    </div>
                                    <div class="p-toolbar-group-right">
                                        <h5 class="headertext pToolBarText textBold">01-27-2021</h5>
                                    </div>
                                </p-toolbar>
                            </div>
                        </div>
                        <div class="p-col-1"></div>
                        <div class="p-md-2 p-col-12 knobContent ">
                            <div class="toolbarwrapR">
                                <p-knob [(ngModel)]="knobValue" [strokeWidth]="8" [size]="230" [readonly]="true"
                                        valueTemplate="{value}%"></p-knob>
                            </div>
                        </div>
                    </div>

                    <div class="p-grid checkListTabs">
                        <div *ngFor="let info of checkListInfo, let i = index">
                            <div class="checkListTabsCard">
                                <div class="topActiveIcon" [ngClass]="{'hidden':info.status === 'inactive'}">
                                    <i class="pi pi-check-circle"
                                       [ngClass]="{'icon-color-success':info.colorstatus === 'active'}"></i>
                                </div>
                                <div (click)="stepsDone(i)" class="p-tag icon-center"
                                     [class]="{'p-tag-green': info.colorstatus === 'active', 'p-tag-blue': info.colorstatus === 'delete','p-tag-grey' : info.colorstatus === 'edit' }">
                                    <img *ngIf="info.colorstatus === 'edit'" src="{{info.iconGrey}}"
                                         class="checkListTabsIcon">
                                    <img *ngIf="info.colorstatus === 'active'" src="{{info.iconGreen}}"
                                         class="checkListTabsIcon">
                                </div>
                                <h6 class="tab-desc">{{info.text}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="p-mt-5 textBold h3-style superAdminTitles">Checklist</h3>
                <p-table *ngIf="!tableEmpty" [value]="tableData"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                         [paginator]="true" [alwaysShowPaginator]="false"
                         [resizableColumns]="true" [autoLayout]="true" [showCurrentPageReport]="true"
                         [rowsPerPageOptions]="[5,10,20, 30]" [rows]="10" styleClass="p-datatable-striped"
                         class="checkListTable">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Company Name</th>
                            <th>Company Type</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Percentage</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-tableData let-rowIndex="rowIndex">
                        <tr>
                            <td>
                                {{tableData.clinicName}}
                            </td>
                            <td>
                                <span>{{tableData.specialistType}}</span>
                            </td>
                            <td>
                                <span>{{tableData.joiningDate}}</span>
                            </td>
                            <td>
                                <div class="p-tag tableStatusTag"
                                     [ngClass]="{'p-tag-warning': tableData.status == 'InComplete','p-tag-success':  tableData.status == 'Active'}">
                                    {{tableData.status}}
                                </div>
                            </td>
                            <td>
                            <span class="p-mb-1 progressValueText">{{tableData.percentage}}%
                            <p-progressBar [value]="tableData.percentage" [showValue]="false"></p-progressBar>
                            </span>
                            </td>
                            <td>
                                <div class="table-icons">
                                    <p-button *ngIf="!tableData.displayCheckListStats" pTooltip="View" icon="pi pi-eye"
                                              (click)="showCheckListStats(rowIndex)"
                                              styleClass="p-button pbtn-transparent checklistViewBtn">
                                    </p-button>
                                    <p-button *ngIf="tableData.displayCheckListStats" pTooltip="Hide"
                                              icon="pi pi-eye-slash"
                                              (click)="showCheckListStats(rowIndex)"
                                              styleClass="p-button pbtn-transparent checklistViewBtn">
                                    </p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="tableEmpty">
                    <h3 class="p-mt-4 textBold h3-style superAdminTitles">No Record to Show</h3>
                </div>
                <ng-template pTemplate="footer">
                </ng-template>
            </p-card>
        </section>
    </div>
</section>
