import {Injectable} from '@angular/core';
import {CookieService} from '../../../node_modules/ngx-cookie-service';

@Injectable({
                providedIn: 'root'
            })
export class SessionService {

    constructor(private cookieService: CookieService) {
    }

    // user: UserDetail;
    // getUserDetails(): UserDetail {
    //   // console.log("userDetails::",this.getSession("userDetails"))
    //   if(this.getSession("userDetails")!=""){
    //   let json = JSON.parse(this.getSession("userDetails"));

    //   if(json != null){
    //      this.user = {
    //     anonymousId: json.anonymousId,
    //     businessRoleId: json.businessRoleId,
    //     city: json.city,
    //     country: json.country,
    //     dateOfBirth: json.dateOfBirth,
    //     designation: json.designation,
    //     email: json.email,
    //     firstName: json.firstName,
    //     gender: json.gender,
    //     identityHide: json.identityHide,
    //     isOnline: true,
    //     lastName: json.lastName,
    //     phoneNumber: json.phoneNumber,
    //     postalCode: json.postalCode,
    //     profileImageUrl: json.userProfilePicture,
    //     profilePercentage: json.profilePercentage,
    //     profileStatus: json.profileStatus,
    //     userId: json.userId,
    //     userAuth: {
    //       userAuthId: json.userAuth.userAuthId,
    //       username: json.userAuth.username,
    //       isVerified: json.userAuth.isVerified,
    //       linkStatus: json.userAuth.linkStatus,
    //       isFirstTimeLogin: json.userAuth.isFirstTimeLogin,
    //     }
    //   }
    //   }}
    //   return this.user;
    // }

    public getSession(name) {
        if (sessionStorage.getItem(name) != undefined) {
            return sessionStorage.getItem(name);
        } else {
            return this.cookieService.get(name);
        }
    }

    public getCookie(name) {

        return this.cookieService.get(name);
    }

    public setCookie(name, value) {

        return this.cookieService.set(name, value);
    }

    public deleteCookie(name) {
        this.cookieService.delete(name);
    }

    public setSession(name, value) {
        sessionStorage.setItem(name, value);
        this.cookieService.set(name, value);
    }

    public deleteAllCookies() {
        this.cookieService.deleteAll();
    }
    // this.sessionService.removeItem(key)
    public removeItem(name) {
        sessionStorage.removeItem(name);
        this.cookieService.delete(name);
    }
}
