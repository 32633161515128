import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";

@Injectable({providedIn: 'root'})
export class TestingNeuroService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdTestingNeuro(testingNeuroId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingNeuro/deleteTestingNeuro', {'testingNeuroId': testingNeuroId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countTestingNeuro(testingNeuro): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'TestingNeuro/count', {'testingNeuro': testingNeuro}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateTestingNeuro(testingNeuro, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'TestingNeuro/updateTestingNeuro', [testingNeuro, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addTestingNeuro(testingNeuro, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'TestingNeuro/addTestingNeuro', [testingNeuro, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllTestingNeuro(testingNeuro, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingNeuro/findAllTestingNeuro',
            {'testingNeuro': testingNeuro, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'TestingNeuro/countGlobalNeuro',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingNeuro/findGlobalNeuro',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addTestingNeuroCpa(testingNeuroDTO: FormDataDTO): Observable<any> {
    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'testing-neuros', testingNeuroDTO, {headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findAllTestingNeurosByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(

      Constant.CPA_SPRING_API_URL + 'patients/' + patientId  + '/testing-neuros', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }


}
