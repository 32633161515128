import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserLevelService {
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    observe: 'response'
  };
  private httpNonIntercept: HttpClient;

  constructor(private httpClient: HttpClient, private handler: HttpBackend) {
    this.httpNonIntercept = new HttpClient(handler);
  };

  findUsersByClientId(clientId: number): any {

    return this.httpClient.get<HttpResponse<any>>(Constant.CPA_SPRING_API_URL + 'user-levels/search?clientId=' + clientId, { observe: 'response' }).pipe(
      retry(3), catchError(Constant.handleError));
  }


  createUserLevelAndCpaRoles(userLevelAndCpaRoles: any): any {

    return this.httpClient.post<HttpResponse<any>>(Constant.AUTH_SERVICE + 'user-levels', userLevelAndCpaRoles, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findDefaultUserLevels(): any {

    alert(Constant.AUTH_SERVICE + 'user-levels/filter?default=true');

    return this.httpClient.get<HttpResponse<any>>(Constant.AUTH_SERVICE + 'user-levels/filter?default=true', { observe: 'response' }).pipe(
          retry(3), catchError(Constant.handleError));
  }


}
