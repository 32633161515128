import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FollowUpMultiSelects extends MultiSelect {

  objectMultiSelects = {};
  
  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }
}
