<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->

<p-dialog header="Add Appointment"
          [(visible)]="displayModal"
          [modal]="true"
          [style]="{width: '50vw'}"
          [baseZIndex]="10000"
          [draggable]="false">


</p-dialog>

<app-menu [@slideInOut]="menuState"  [name]="menuName"  [selectedClinicId]="selectedClinicId" [selectedExpertId]="selectedExpertId" [selectedExpertName]="selectedExpertName" [clickedEvent]="clickedEvent" [patientsList]="patientsList" [patientTypeList]="patientTypeList" [consentList]="consentList" [patientCases]="patientCases" (formSubmitted)="formSubmittedHandler($event)"></app-menu>

<section>
    <div class="container-calendar-schedule">
      <section>
        <div class="row mt-4">
          <div class="p-md-3 card-cpa-rounded card-shadow" style="background: white;height:600px;">
            <form  [formGroup]="appointmentsForm" class="pngForm">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                  <div class="row">
                    <div class="p-md-11" style="padding-left:20px">
                      <h3 class="card-title">Add Appointment</h3>
                    </div>
                  </div>

                  <div class="row">
                    <div class="p-md-2" style="height:100px">
                      <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:200px;height:100px">
                        <div class="custom-alert-icon" [ngClass]="appointmentsForm.controls['clinicId'].value > 0 ? 'icon-success' : 'icon-transparent'">
                          <i class="fa-solid" [ngClass]="appointmentsForm.controls['clinicId'].value > 0 ? 'fa-check' : 'fa-1'"></i></div>
                      </div>
                    </div>
                    <div class="p-md-9" style="vertical-align: middle!important;margin-left:25px;padding-top:28px">
                      <p-dropdown [filter]="true" [options]="clinicsList" [showClear]="true"
                                  formControlName="clinicId" placeholder="Select Clinic"
                                  (onChange)="onClinicChange($event)">
                      </p-dropdown>
                      <app-custom-error [control]="'clinicId'" [form]="appointmentsForm">
                      </app-custom-error>
                    </div>

                  </div>
                </div>
                <div class="p-field p-col-12 p-md-12">
                  <div class="row">
                    <div class="p-md-2" style="height:100px">
                      <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:200px;height:100px">
                        <div class="custom-alert-icon" [ngClass]="appointmentsForm.controls['expertId'].value > 0 ? 'icon-success' : 'icon-transparent'">
                          <i class="fa-solid"  [ngClass]="appointmentsForm.controls['expertId'].value > 0 ? 'fa-check' : 'fa-2'"></i></div>

                      </div>
                    </div>
                    <div class="p-md-9" style="vertical-align: middle!important;margin-left:25px;padding-top:28px">
                      <p-dropdown [filter]="true" [options]="clinicExpertsList" [showClear]="true"
                                  class="pngDropdown" formControlName="expertId" placeholder="Select Expert"  (onChange)="onExpertChange($event)">
                      </p-dropdown>
                      <app-custom-error [control]="'expertId'"
                                        [form]="appointmentsForm"></app-custom-error>
                    </div>

                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="p-md-8-5 card-cpa-rounded card-shadow" style="background: white;margin-left:30px" >
            <div style="margin:30px">
              <full-calendar #calendar [options]="calendarOptions"></full-calendar>
            </div>


          </div>
        </div>


      </section>
    </div>

</section>
