<div class="app-header">
  <nav class="navbar navbar-light navbar-expand-lg" >
    <div class="container-fluid">
      <div class="navbar-nav" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link hide-sidebar-toggle-button" href="#"><i class="material-icons">first_page</i>Menu</a>
          </li>

          <ng-container *ngFor="let ql of quickLinks ; let i = index">
            <li  class="nav-item">

              <a class="nav-link" (click)="navigateToPage(ql , ql.url)">

                <i class="{{ql.icon}} fa-2x" style="margin-left:30px;"></i>

              </a>
            </li>
          </ng-container>

        </ul>

      </div>
      <div class="d-flex">
        <ul class="navbar-nav">


          <li  class="nav-item">
            <a (click)="logout()" class="nav-link">
              <i class="fa-sign-out fa-solid" style="margin-left:30px;font-size:22px!important;"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
