import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complete-user-sign-up',
  templateUrl: './complete-user-sign-up.component.html',
  styleUrls: ['./complete-user-sign-up.component.css']
})
export class CompleteUserSignUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
