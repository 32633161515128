<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deleteArom" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameTestingArom)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameTestingArom)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameTestingArom)}}</p-header>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameTestingArom)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="testingAromForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingAromColumnJoint)}}</label>
                                            <p-dropdown [filter]="true" [options]="jointList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="joint"
                                                        placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'joint'" [form]="testingAromForm"></app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingAromColumnAromSide)}}</label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="aromSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                            <app-custom-error [control]="'aromSide'" [form]="testingAromForm"></app-custom-error>
                                        </div>

                                        <!-- Movement -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['movement'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['movement'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['movement'].currentSelected"></app-lov-details>
                                          <span id="#movementError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <!-- Measure -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['measure'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['measure'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['measure'].currentSelected"></app-lov-details>
                                          <span id="#measureError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <!-- limiting Factor -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['limitingFactor'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['limitingFactor'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['limitingFactor'].currentSelected"></app-lov-details>
                                          <span id="#limitingFactorError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn"></p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext"
                                               pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="testingAromList.length > 0"  [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [alwaysShowPaginator]="false"
                                         [value]="testingAromList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': testingAromList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Joint</th>
                                            <th> AROM Side</th>
                                          <th> Movement</th>
<!--                                            <th> Measure</th>-->
<!--                                            <th> Limiting Factor</th>-->
<!--                                            <th> PatientId</th>-->
<!--                                            <th> ExpertId</th>-->
<!--                                            <th> AppointmentId</th>-->
<!--                                            <th> BodyChartId</th>-->
<!--                                            <th> UserId</th>-->
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.joint}} </td>
                                            <td> {{element?.aromSide}} </td>
                                            <td> {{element?.movement[0].lovValues}} </td>
<!--                                            <td> {{element?.movement.listOfValuesId}} </td>-->
<!--                                            <td> {{element?.measure.listOfValuesDetailId}} </td>-->
<!--                                            <td> {{element?.limitigFactor.listOfValuesDetailId}} </td>-->
<!--                                            <td> {{element?.patientId.userId}} </td>-->
<!--                                            <td> {{element?.expertId.listOfValuesDetailId}} </td>-->
<!--                                            <td> {{element?.appointmentId.appointmentId}} </td>-->
<!--                                            <td> {{element?.bodyChartId.bodyChartMasterId}} </td>-->
<!--                                            <td> {{element?.userId}} </td>-->
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadTestingAromToEdit(element.testingAromId, i)"
                                                              icon="pi pi-pencil"
                                                              pTooltip="Edit"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <p-button (click)="deleteByIdTestingArom(element.testingAromId,i)"
                                                              icon="pi pi-trash"
                                                              pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent">-->
<!--                                                    </p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <!-- <ng-template
                                            pTemplate="emptymessage">{{translate(labels.mat_pagination_no_data_msg)}}</ng-template>
                                         -->

                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
