import { Injectable } from '@angular/core';
import { Subject, } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastr: ToastrService) {

  }

  showToaster(victim, operationType, resulttype, alertType) {
    if (alertType == "normal") {
      if (resulttype == "success") {
        this.toastr.success("Your " + operationType + " request for " + victim + " is completed Successfully.", null, {

          tapToDismiss: false,
          closeButton: true,
          timeOut: 2000,
          positionClass: "toast-top-center"
        });
      }
      if (resulttype == "error") {
        this.toastr.error("Your " + operationType + " request for " + victim + " is failed .", null, {

          tapToDismiss: false,
          closeButton: true,
          timeOut: 2000,
          positionClass: "toast-top-center"
        });

      }
    } else {
      if (resulttype == "success") {
        this.toastr.success(operationType, null, {

          tapToDismiss: false,
          closeButton: true,
          timeOut: 2000,
          positionClass: "toast-top-center"
        });
      }
      if (resulttype == "error") {
        this.toastr.error(operationType, null, {

          tapToDismiss: false,
          closeButton: true,
          timeOut: 2000,
          positionClass: "toast-top-center"
        });
      }
      if (resulttype == "info") {
        this.toastr.info(operationType, null, {

          tapToDismiss: false,
          closeButton: true,
          timeOut: 2000,
          positionClass: "toast-top-center"
        });
      }
    }
  }

  showSuccess(operationType, victim) {
    this.showToaster(victim, operationType, "success", "normal");
  }

  showError(operationType, victim) {
    this.showToaster(victim, operationType, "error", "normal");
  }

  showCustomSuccessAlert(message) {
    this.showToaster("", message, "success", "custom");
  }
  showCustomErrorAlert(message) {
    this.showToaster("", message, "error", "custom");
  }
  showCustomInfoAlert(message) {
    this.showToaster("", message, "info", "custom");
  }
}
