export class OnboardingSteps {

  static stepItems =  [
    {
      label: 'Company Info',
      number: 0,
      route: 'company-info'
    },
    {
      label: 'Legal Info',
      number: 1,
      route: 'legal-info'
    },
    {
      label: 'Add Clinic',
      number: 2,
      route: 'add-clinic'
    },
    {
      label: 'Add Expert',
      number: 3,
      route: 'add-expert'
    },
  ];
}
