import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PaymentsDetailService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };


    deleteByIdPaymentsDetail(paymentsDetailId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PaymentsDetail/deletePaymentsDetail', {'paymentsDetailId': paymentsDetailId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countPaymentsDetail(paymentsDetail): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'PaymentsDetail/count', {'paymentsDetail': paymentsDetail},
                                            this.httpOptions).pipe(retry(3), catchError(Constant.handleError));
    }

    updatePaymentsDetail(paymentsDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PaymentsDetail/updatePaymentsDetail', [paymentsDetail], this.httpOptions).pipe(retry(3), catchError(
            Constant.handleError));
    }

    addPaymentsDetail(paymentsDetail): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PaymentsDetail/addPaymentsDetail', [paymentsDetail], this.httpOptions).pipe(retry(3), catchError(
            Constant.handleError));
    }

    findAllPaymentsDetail(paymentsDetail, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PaymentsDetail/findAllPaymentsDetail', {
                'paymentsDetail': paymentsDetail,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}
            }, this.httpOptions).pipe(retry(3), catchError(Constant.handleError));
    }

    // custom service for global search
    paymentsDetailGlcount(searchTerm, paymentsId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'PaymentsDetail/countGlobalPaymentsDetail',
            {'searchTerm': searchTerm, 'invoice_id': paymentsId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findGlobalPaymentsDetail(searchTerm, pageNo: number, itemsPerPage: number, paymentsId): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PaymentsDetail/findGlobalPaymentsDetail',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}, 'invoice_id': paymentsId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}