import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Labels} from '../../util/labels';
import {ValidationService} from '../../services/validation.service';

@Component({
  selector: 'app-add-communications',
  templateUrl: './add-communications.component.html',
  styleUrls: ['./add-communications.component.css']
})
export class AddCommunicationsComponent implements OnInit {
  formGroup: FormGroup;
  @Input() visible = true;
  @Input() communicationType: any[];
  @Output() addCommunication = new EventEmitter();
  @Output() addCancelEvent = new EventEmitter();
  dropdownOptions:any;
  
  public labels = Labels;
  constructor(private formBuilder: FormBuilder, private _validation: ValidationService) { }

  ngOnInit(): void {
    this.createForm()
    console.log(this.communicationType);
    
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      title: [null, Validators.required],
      description: [null, [Validators.required]],
      address: [null,[Validators.required]],
      content: [null, [Validators.required]],
      communicationType: [0, [Validators.required]],
      sendTo: [null]
    });
  }
  addNewCommunication()
  {
    this.formGroup.markAllAsTouched();
    if(this.showPhoneNumber){
      this.formGroup.controls['sendTo'].setValue(this.formGroup.value.sendTo.toString());
    }

    if(this.formGroup.valid){
      this.addCommunication.emit(this.formGroup.value);
    }else
    {
      return;
    }
  }
  cancel(){
    this.addCancelEvent.emit()
  }
  onSubmit(){}

  fieldReset(control) {
    return this.formGroup.get(control).reset();
  }

  diplayeIconInField(control) {
    return this.formGroup.get(control)?.valid;
  }

  inputFieldDanger(control) {
    return !this.formGroup.get(control)?.valid && this.formGroup?.get(control)?.touched;
  }
  showEmail = false;
  showPhoneNumber = false;
  onTypeChange(event) {
    console.log('event value : ',event);
    this.unsetRequired();
    if(event.value == 1){
      // then its SMS
      this.formGroup.controls.sendTo.setValidators([Validators.required]);
      this.formGroup.controls.sendTo.updateValueAndValidity();
      this.showPhoneNumber = true;
      this.showEmail = false;
    }else if(event.value == 2){
      // then its Email
      this.formGroup.controls.sendTo.setValidators([Validators.required, Validators.pattern(this._validation.regex.email)]);
      this.formGroup.controls.sendTo.updateValueAndValidity();
      this.showPhoneNumber = false;
      this.showEmail = true;
    }
  }
  unsetRequired() {
    this.formGroup.controls.sendTo.setValidators(null);
    this.formGroup.controls.sendTo.updateValueAndValidity();
  }
}
