<!-- <app-app-header></app-app-header> -->
<section class="sectionmain main-section">
    <div class="container-fluid">
      <div class="paddingLeftRight cpaToolsPaddingLR">
  
        <p-card>
  
        <div class="cpaToolsTopHeading">
          <h3 class="cpaToolsTitleBlue cpaToolsTitle tool">CPA Tools</h3>
          <h3 class="cpaToolsTitle selectedItems selected-item">Selected Items : {{selectedItem}}</h3>
        </div>
  
        <!-- Tab Content Starts Here -->
        <div class="p-grid">
  
          <div class="p-col-12 p-lg-3 leftTabMenu ">
            <h3 class="categoriesTitle cpaToolsTitle selected-item">categories</h3>
            <p-tabView class="cpaToolsTabs " (onChange)="handleChange($event)" >
  
              <p-tabPanel [header]="item.exerciseCategoryTitle | uppercase" *ngFor="let item of categoriesList"
                [selected]="i == 0">
              </p-tabPanel>
            </p-tabView>
          </div>
          <div class="p-col-12 p-lg-9 cpaToolsCard">
            <div class="cpaTitleAndSearch">
              <h3 class="cpaToolsTitle selected-item">CPA Exercises</h3>
              <span class="p-input-icon-right">
                <i class="pi pi-search"></i>
                <input class="cpaToolsSearch" (keyup)="applyFilter($event)"  [(ngModel)]="searchterm" type="search" placeholder="Search.." pInputText />
            </span>
  
              
            </div>
  
            <div class="p-grid cpaToolsRightContent">
  
              <div class="p-col-12 p-md-6" *ngFor="let data of exercises;  let i = index">
                <!-- <p-card header="{{data.title}}" styleClass="p-card-shadow"> -->
                <p-card header="{{data.title}}" styleClass="p-card-shadow">

                  <ng-template pTemplate="header">
                    <img [src]="data.exerciseImageUrl">
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="cpaToolsBtns">




                      <p-button *ngIf="data.patientExercisesId == undefined; else RemoveButton"
                                class="pngBlueBtn cpaToolExBtn" (click)="addUpdatePatientExercises(data.exerciseId,i)" label="Select Exercise">
                      </p-button>
                      <!-- <p-button *ngIf="data.patientExercisesId == undefined; else RemoveButton"
                                class="blu-btn" (click)="addUpdatePatientExercises(data.exerciseId,i)" label="Select Exercise">
                      </p-button> -->
                    <ng-template #RemoveButton>
                      <p-button  class="pngBlueBtn cpaToolExBtn" (click)="deleteByIdPatientExercises(data.patientExercisesId,i)" label="Remove Exercise"></p-button>
                      <!-- <p-button  class="blu-btn" (click)="deleteByIdPatientExercises(data.patientExercisesId,i)" label="Remove Exercise"></p-button> -->
  
                    </ng-template>







                      <!-- <div class="cpaToolsIconsBtn">
                        <p-button class="cpaToolsBtnRed">
                          <img class="cpaToolsBtnImg" src="assets/images/superAdminDashboard/tv.png" /></p-button>
                        <p-button class="cpaToolsBtnBlue" icon="pi pi-exclamation-circle"></p-button>
                      </div> -->
  
                    </div>
                  </ng-template>
                </p-card>
              </div>
            </div>
  
  
          </div>
        </div>
      </p-card>
      </div>
    </div>
  </section>
  
