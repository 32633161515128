<div class="angDialog" id="userprofile-angDialog">
    <div class="modal-dialog-anuglar">

        <div *ngIf="element!=undefined" class="userprofile-header-angDialog">
            <div class="userprofile-header-leftside">
                <div class="userprofileimagediv">
                    <img
                        alt="User Image" class="img-fluid" id="userprofileimage"
                         src="assets/theme1/custom-files/img/default_thumb/profilepic.png">
                    <ng-template #displayImage>
                        <img *ngIf="element.gender=='Female'"
                            src="assets/theme1/custom-files/img/default_thumb/defaultfemalepic.png" class="img-fluid">
                        <img *ngIf="element.gender=='Male'"
                            src="assets/theme1/custom-files/img/default_thumb/defaultmalepic.png" class="img-fluid">
                    </ng-template>
                </div>
            </div>
            <div class="userprofile-header-rightside">
                <h2 class="userprofilename">{{element.firstName+' '+element.lastName}}</h2>
                <h2 class="userprofileid">{{element.username}}</h2>
            </div>

        </div>
        <div class="userprofile-angDialog-tabs" id="userprofile-angDialog-tabs">
<!--            <mat-tab-group (selectedTabChange)="changeTab($event)" animationDuration="0ms" class="angTabs">-->
<!--                <mat-tab *ngIf="element!=undefined" label="{{verifyLabel('PROFILE')}}">-->
                    <div class="rowreadyonly">
                        <div class="columnreadonly">
                            <h5 class="smallinfo"> {{verifyLabel('Name')}} </h5>
                            <h3 class="mediuminfo">{{element.firstName}}</h3>
                        </div>
                        <div class="columnreadonly">
<!--                            <h5 class="smallinfo">{{verifyLabel('Last Name')}} </h5>-->
<!--                            <h3 class="mediuminfo">{{element.lastName}}</h3>-->
                        </div>
                        <div class="columnreadonly">
                            <h5 class="smallinfo">{{verifyLabel('Email')}}</h5>
                            <h3 class="mediuminfo"><a href="mailto:{{element.email}}">{{element.email}}</a></h3>
                        </div>
                        <div class="columnreadonly">
<!--                            <h5 class="smallinfo"> {{verifyLabel('Gender')}}</h5>-->
<!--                            <h3 class="mediuminfo">{{element.gender}}</h3>-->
                        </div>
<!--                        <div class="columnreadonly">-->
<!--                            <h5 class="smallinfo">{{verifyLabel('Department')}}</h5>-->
<!--                            <h3 class="mediuminfo"> {{element.department?.title}}</h3>-->
<!--                        </div>-->

                        <div class="columnreadonly">
                            <h5 class="smallinfo">{{verifyLabel('Client')}} </h5>
                            <h3 class="mediuminfo">{{element.client?.title}}</h3>
                        </div>
                        <div *ngIf="element.contact!=undefined && element.contact!='' && element.contact!=null"
                            class="columnreadonlymd">
                            <h5 class="smallinfo">{{verifyLabel('Contact')}} </h5>
                            <h3 class="mediuminfo">{{element.contact}}</h3>
                        </div>
                        <div *ngIf="element.address!=undefined && element.address!='' && element.address!=null"
                            class="columnreadonlymd">
                            <h5 class="smallinfo">{{verifyLabel('Address')}} </h5>
                            <h3 class="mediuminfo"> {{element.address}}</h3>
                        </div>
                    </div>

<!--                </mat-tab>-->
<!--                <mat-tab *ngIf="userPreference" label="{{verifyLabel('PERSONALIZATION')}}">-->
<!--                    <form [formGroup]="userPreferenceForm" (ngSubmit)="addUpdateUserPreferences()" class="formContainer"-->
<!--                        id="userprofile-formContainer">-->
<!--                        <div class="formBlockWarpper" id="userprofile-formBlockWarpper">-->

<!--                            <div *ngIf="userPreference?.defaultProject!=undefined" class="fromBlock"-->
<!--                                id="userprofile-fromBlock-title">-->
<!--                                <mat-form-field class="angInput" id="userprofile-angInput-title">-->
<!--                                    <mat-label>{{verifyLabel('Default Project')}} </mat-label>-->
<!--                                    <input matInput readonly value="{{userPreference.defaultProject?.title}}">-->

<!--                                </mat-form-field>-->
<!--                            </div>-->

<!--                            <div *ngIf="userPreference?.defaultProjectRole!=undefined" class="fromBlock"-->
<!--                                id="userprofile-fromBlock-role">-->
<!--                                <mat-form-field class="angInput" id="userprofile-angInput-role">-->
<!--                                    <mat-label>{{verifyLabel('Default Role')}} </mat-label>-->
<!--                                    <input matInput readonly value="{{userPreference.defaultProjectRole?.title}}">-->
<!--                                </mat-form-field>-->
<!--                            </div>-->



<!--                            <div class="fromBlock" id="userprofile-fromBlock-language">-->
<!--                                <mat-form-field>-->
<!--                                    <mat-label>{{verifyLabel('Language')}} </mat-label>-->
<!--                                    <mat-select formControlName="language" [disabled]="currentVersion!=0"-->
<!--                                        class="angSelect" id="userprofile-angSelect-language">-->
<!--                                        <mat-option disabled="disabled" [value]="0"> Select </mat-option>-->
<!--                                        <mat-option *ngFor="let lang of languages" [value]="">-->
<!--                                            {{lang}}</mat-option>-->


<!--                                    </mat-select>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="fromBlock" id="userprofile-fromBlock-chatinterval">-->
<!--                                <mat-form-field>-->
<!--                                    <mat-label>{{verifyLabel('Chat Interval')}}</mat-label>-->
<!--                                    <mat-select formControlName="chatIinterval" [disabled]="currentVersion!=0"-->
<!--                                        class="angSelect" id="userprofile-angSelect-chatinterval">-->
<!--                                        <mat-option disabled="disabled" [value]="0"> Select </mat-option>-->
<!--                                        <mat-option [value]="120000"> 2 mintues </mat-option>-->
<!--                                        <mat-option [value]="180000"> 3 mintues </mat-option>-->
<!--                                        <mat-option [value]="300000"> 5 mintues </mat-option>-->
<!--                                    </mat-select>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="fromBlock" id="userprofile-fromBlock-notificationinterval">-->
<!--                                <mat-form-field>-->
<!--                                    <mat-label>{{verifyLabel('Notification Interval')}} </mat-label>-->
<!--                                    <mat-select formControlName="notificationIinterval" [disabled]="currentVersion!=0"-->
<!--                                        class="angSelect" id="userprofile-angSelect-notificationinterval">-->
<!--                                        <mat-option disabled="disabled" [value]="0"> Select </mat-option>-->
<!--                                        <mat-option [value]="120000"> 2 mintues </mat-option>-->
<!--                                        <mat-option [value]="300000"> 5 mintues </mat-option>-->
<!--                                        <mat-option [value]="600000"> 10 mintues </mat-option>-->

<!--                                    </mat-select>-->

<!--                                </mat-form-field>-->
<!--                                <input type="hidden" formControlName="userPreferenceId">-->
<!--                            </div>-->

<!--                        </div>-->




<!--                        <div mat-dialog-actions class="angDialog-footer">-->
<!--                            <button class="btnmd" type="submit" [disabled]="!userPreferenceForm.touched"-->
<!--                                id="userprofile-angDialog-footerbtnsave">{{verifyLabel('Save')}}</button>-->
<!--                            <button class="btnmd" [disabled]="!userPreferenceForm.touched" (click)="resetForm()">-->
<!--                                {{verifyLabel('Reset')}}-->
<!--                            </button>-->

<!--                        </div>-->
<!--                    </form>-->
<!--                </mat-tab>-->

<!--                <ng-container *ngIf="currentVersion==0">-->
<!--                    <mat-tab label="SECURITY">-->
<!--                        <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordSubmit()" class="formContainer"-->
<!--                            id="userprofile-formContainer-security">-->
<!--                            <div class="formBlockWarpper" id="userprofile-formBlockWarpper-security">-->

<!--                                <div class="fromBlock" id="userprofile-fromBlock-Password">-->
<!--                                    <mat-form-field class="angInput" id="userprofile-angInput-Password">-->
<!--                                        <mat-label>Password</mat-label>-->
<!--                                        <input formControlName="oldPassword" [errorStateMatcher]="matcher"-->
<!--                                            [type]="!hide1 ? 'password' : 'text'" matInput placeholder="Old Password">-->

<!--                                        <button type="button" class="btnxs-transparent-icon-inputfields">-->
<!--                                            <i class="lock-icon"></i>-->
<!--                                        </button>-->
<!--                                        <button type="button" (click)="hide1 = !hide1"-->
<!--                                            class="btnxs-transparent-icon-inputfields">-->
<!--                                            <i *ngIf="!hide1" matTooltip="View Password" class="viewdetails-icon"></i>-->
<!--                                            <i *ngIf="hide1" matTooltip="=Hide Password" class="hidedetails-icon"-->
<!--                                                matTooltip="Hide Password"></i>-->
<!--                                            &lt;!&ndash; <i class="hidedetails-icon"></i> &ndash;&gt;-->
<!--                                        </button>-->

<!--                                        <mat-error-->
<!--                                            *ngIf="f.oldPassword.errors?.required && (f.oldPassword.dirty || f.oldPassword.touched)">-->
<!--                                            Password is Required </mat-error>-->
<!--                                        <mat-error-->
<!--                                            *ngIf="f.oldPassword.errors?.pattern && (f.oldPassword.dirty || f.oldPassword.touched)">-->
<!--                                            Empty Space is not Alllowed</mat-error>-->
<!--                                    </mat-form-field>-->
<!--                                </div>-->

<!--                                <div class="fromBlock" id="userprofile-fromBlock-NewPassword">-->
<!--                                    <mat-form-field class="angInput" id="userprofile-angInput-NewPassword">-->
<!--                                        <mat-label>New Password</mat-label>-->
<!--                                        <input matInput [errorStateMatcher]="matcher" formControlName="password"-->
<!--                                            [type]="!hide2 ? 'password' : 'text'" placeholder="New Password">-->

<!--                                        <button type="button" class="btnxs-transparent-icon-inputfields">-->
<!--                                            <i class="lock-icon"></i>-->
<!--                                        </button>-->
<!--                                        <button type="button" (click)="hide2 = !hide2"-->
<!--                                            class="btnxs-transparent-icon-inputfields">-->
<!--                                            <i *ngIf="!hide2" matTooltip="View Password" class="viewdetails-icon"></i>-->
<!--                                            <i *ngIf="hide2" matTooltip="=Hide Password" class="hidedetails-icon"-->
<!--                                                matTooltip="Hide Password"></i>-->
<!--                                            &lt;!&ndash; <i class="hidedetails-icon"></i> &ndash;&gt;-->
<!--                                        </button>-->

<!--                                        <mat-error-->
<!--                                            *ngIf="f.password.errors?.required && (f.password.dirty || f.password.touched)">-->
<!--                                            Password is Required </mat-error>-->
<!--                                        <mat-error-->
<!--                                            *ngIf="f.password.errors?.pattern && (f.password.dirty || f.password.touched)">-->
<!--                                            Empty Space is not Alllowed</mat-error>-->
<!--                                        <mat-error-->
<!--                                            *ngIf="f.password.errors?.maxlength && (f.password.dirty || f.password.touched)">-->
<!--                                            Passwrod must be atmost 15 characters long</mat-error>-->
<!--                                        <mat-error-->
<!--                                            *ngIf="f.password.errors?.minlength && (f.password.dirty || f.password.touched)">-->
<!--                                            Password must be atleast 8 characters long</mat-error>-->
<!--                                    </mat-form-field>-->
<!--                                </div>-->

<!--                                <div class="fromBlock" id="userprofile-fromBlock-ConfirmPassword">-->
<!--                                    <mat-form-field class="angInput" id="userprofile-angInput-ConfirmPassword">-->
<!--                                        <mat-label>Confirm Password</mat-label>-->
<!--                                        <input matInput [errorStateMatcher]="matcher" formControlName="confirmPassword"-->
<!--                                            [type]="!hide3 ? 'password' : 'text'" placeholder="Confirm Password">-->

<!--                                        <button type="button" class="btnxs-transparent-icon-inputfields">-->
<!--                                            <i class="lock-icon"></i>-->
<!--                                        </button>-->
<!--                                        <button type="button" (click)="hide3 = !hide3"-->
<!--                                            class="btnxs-transparent-icon-inputfields">-->
<!--                                            <i *ngIf="!hide3" matTooltip="View Password" class="viewdetails-icon"></i>-->
<!--                                            <i *ngIf="hide3" matTooltip="=Hide Password" class="hidedetails-icon"-->
<!--                                                matTooltip="Hide Password"></i>-->
<!--                                            &lt;!&ndash; <i class="hidedetails-icon"></i> &ndash;&gt;-->
<!--                                        </button>-->


<!--                                        <mat-error-->
<!--                                            *ngIf="f.confirmPassword.errors?.required && (f.confirmPassword.dirty || f.confirmPassword.touched)">-->
<!--                                            Confirm Password is required</mat-error>-->
<!--                                        <mat-error-->
<!--                                            *ngIf="f.confirmPassword.errors?.mustMatch && (f.confirmPassword.dirty || f.confirmPassword.touched)">-->
<!--                                            Passwords must match </mat-error>-->
<!--                                    </mat-form-field>-->
<!--                                </div>-->

<!--                            </div>-->

<!--                            <div mat-dialog-actions class="angDialog-footer" >-->
<!--                                <button class="btnmd" type="submit" [disabled]=" !changePasswordForm.valid"-->
<!--                                    id="userprofile-angDialog-footerbtnupdate">{{verifyLabel('Update')}}</button>-->
<!--                                <button class="btnmd" type="button" [disabled]="!changePasswordForm.touched"-->
<!--                                    id="userprofile-angDialog-footerbtnreset"-->
<!--                                    (click)="initiateResetPasswordFormField()">{{verifyLabel('Clear')}}</button>-->


<!--                            </div>-->
<!--                        </form>-->


<!--                    </mat-tab>-->
<!--                </ng-container>-->



<!--            </mat-tab-group>-->


        </div>
        <div mat-dialog-actions class="angDialog-footer" id="userprofile-angDialog-footer">
            <button class="btnmd" type="button" id="userprofile-angDialog-footerbtncancel"
                (click)="onNoClick()">{{verifyLabel('Cancel')}}</button>
        </div>
    </div>
</div>
