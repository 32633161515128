import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeSlotService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient) {
  };

  findAllTimeSlots(date, clinicId, expertId): Observable<string[]> {
    return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'Appointments/findAllTimeSlots',
        {'date': date, 'clinic' : clinicId, 'expert' : expertId}, this.httpOptions
    ).pipe(
        retry(3), catchError(Constant.handleError));
  }
}
