import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {TreatmentService} from '../web-services/treatment.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {InitialAssessmentSteps} from "../initial-assessment-steps/InitialAssessmentSteps";
import {DiagnosisService} from "../web-services/diagnosis.service";
import {TreatmentPrescribedService} from "../web-services/treatment-prescribed.service";
import {InitialHistoryFormDTO} from "../dto/InitialHistoryFormDTO";
import {FormDataDTO} from "../dto/FormDataDTO";

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({selector: 'app-treatment', templateUrl: './treatment.component.html', styleUrls: ['./treatment.component.css']})
export class TreatmentComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateTreatmentId = null;
    listOfValuesDetailList: any = [];
    treatmentList: any = [];
    treatmentProvidedList: any = [];
    treatmentConsentList: any = [];
    treatmentRisksExplainedList: any = [];
    treatmentSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    treatmentForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;
    urlCalled = false;

    treatmentDescription = Constant.TREATMENT_LOV_TREATMENT_DESCRIPTION;
    treatmentPlan = Constant.TREATMENT_LOV_TREATMENT_PLAN;
    treatmentConsent = Constant.TREATMENT_LOV_TREATMENT_CONSENT;

    expertId;
    patientId;
    appointmentId;
    caseId;
    patientObj: any;
    stepsItems: any;
    activeStep: number;
    patientDiagnosisTreatments: any;
    treatmentsPrescribedList: any;
    selectedTreatmentsArray: any;

    constructor(
        private treatmentServices: TreatmentService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private diagnosisService: DiagnosisService,
        private treatmentPrescribedService: TreatmentPrescribedService
    ) {
    };

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    treatmentDescriptionList: any = [];
    treatmentsSelectedList: any = [];
    treatmentPlanList: any = [];
    treatmentDescriptionError = false;
    treatmentPlanError = false;
    treatmentConsentError = false;

    treatmentSelected($event): void {

      this.treatmentsSelectedList  =  this.patientDiagnosisTreatments.filter(treatment =>  $event.value.includes(treatment.treatmentPrescribedId));

    }

    getResponse($event) {
        this.responseData = $event;
        console.log('Response from child : ', this.responseData);
        if(this.responseData[0]['categoryId']['categoryTitle'] === Constant.TREATMENT_LOV_TREATMENT_DESCRIPTION){
            this.treatmentDescriptionList = this.responseData;
        } else if(this.responseData[0]['categoryId']['categoryTitle'] === Constant.TREATMENT_LOV_TREATMENT_PLAN){
            this.treatmentPlanList = this.responseData;
        }
    }

    get addUpdateTitle() {
        return this.updateTreatmentId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.treatmentSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countTreatment();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countTreatment() {
        this.treatmentSearch.controls['expertId'].setValue(this.expertId);
        this.treatmentSearch.controls['patientId'].setValue(this.patientId);
        this.treatmentSearch.controls['appointmentId'].setValue(this.appointmentId);

        this.treatmentServices.countTreatment(this.treatmentSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllTreatment();
            } else {
                this.totalRecords = 0;
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }


    onSubmit() {
        this.treatmentForm.markAllAsTouched();
        this.treatmentForm.controls['selectedTreatments'].setValue(this.treatmentsSelectedList);
        const treatmentDTO = new FormDataDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, {}, this.treatmentForm);
        if (!treatmentDTO.hasMultiSelectErrors) {

              this.treatmentServices.createTreatment(treatmentDTO).subscribe(res => {

                  if (res.status === 200) {

                    this.messageService.add({
                      severity: 'success',
                      summary: this.translate(this.labels.entityNameTreatment) +
                        this.translate(this.labels.addedSuccessfully),
                    });

                    this.submitted = true;
                    this.showHideAddDialog();
                  }
              });
        }
    }

    addTreatment(treatmentDTO: FormDataDTO): void {}

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllTreatment();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.treatmentSearch.reset();
        this.countTreatment();
        // this.findAllTreatment();
    }

    addUpdateTreatment(treatment) {
        this.startLoader();
        if (this.updateTreatmentId == null) {
            this.treatmentServices.addTreatment(this.treatmentForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    treatment = res['data'][0];
                    this.treatmentList.push(treatment);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameTreatment) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameTreatment)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countTreatment();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            treatment.treatmentId = this.updateTreatmentId;
            this.treatmentServices.updateTreatment(treatment,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.treatmentList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameTreatment) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameTreatment)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countTreatment();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateTreatmentId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findTreatmentProvided() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Treatment Provided').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.treatmentProvidedList.push(dropDown);
                    }
                } else {
                    this.treatmentProvidedList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }


  findTreatmentConsent() {
    let temp = [];
    this.startLoader();
    this.lovServices.findAllListOfValuesByName('Treatment Consent').subscribe(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          for (let object of temp) {
            let dropDown = {label: '', value: ''};
            dropDown.value = object['listOfValuesId'];
            dropDown.label = object['label'];
            this.treatmentConsentList.push(dropDown);
          }
        } else {
          this.treatmentConsentList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  findTreatmentRisksExplained() {
    let temp = [];
    this.startLoader();
    this.lovServices.findAllListOfValuesByName('Risks Explained').subscribe(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          for (let object of temp) {
            let dropDown = {label: '', value: ''};
            dropDown.value = object['listOfValuesId'];
            dropDown.label = object['label'];
            this.treatmentRisksExplainedList.push(dropDown);
          }
        } else {
          this.treatmentRisksExplainedList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

    ngOnInit() {
        if (this.route.url.includes('/treatment')) {
            this.urlCalled = true;
        }
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));
        this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;

        this.treatmentForm = new FormGroup({
                                               treatmentDescription: new FormControl(),
                                               treatmentConsent: new FormControl(),
                                               treatmentRisksExplained: new FormControl(),
                                               selectedTreatments: new FormControl([]),
                                               treatmentProvided: new FormControl('', [Validators.required]),

                                               comments: new FormControl('', [Validators.required, validateSpaces]),

                                           });
        this.treatmentSearch = new FormGroup({
                                                 treatmentDescription: new FormControl(),
                                                 treatmentProvided: new FormControl(),

                                                 comments: new FormControl(),
                                             });


        this.findTreatmentProvided();
        this.findTreatmentConsent();
        this.findTreatmentRisksExplained();
        this.findAllTreatmentsByPatientId();

        this.getConstantList(1234);

        this.findTreatmentPrescribedByForCaseDiagnosis();
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.treatmentSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
      this.stepsItems = InitialAssessmentSteps.stepItems;
      this.activeStep = parseInt(this.stepsItems.find(step => step.route === 'treatment').number);

    }

  findTreatmentPrescribedByForCaseDiagnosis(): void {

      this.diagnosisService.findAllDiagnosesByPatientIdAndCaseId(this.patientId, this.caseId).subscribe(res => {

        if (res.status === 200) {

        const predictiveTreatments = res.body;
        const predictiveIds = predictiveTreatments.map( item =>   { return item.treatmentPrescribedId; });

        this.treatmentPrescribedService.findAllTreatmentsPrescribed().subscribe(resTreatments => {

            if (resTreatments.status === 200) {

              /* remove dupes from predictive to all treatments */

              resTreatments.body = resTreatments.body.filter(item =>  {
                if (!predictiveIds.includes(item.treatmentPrescribedId)) {
                  return item;
                }
              });
              this.patientDiagnosisTreatments =  res.body.concat(resTreatments.body);

            }

          });
        }
      },
    err => {
        alert(err
        );
      console.error('err: ', err);
    });

  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.route.navigate([url]);
  }

  findAllTreatmentsByPatientId(): void {

      this.treatmentServices.findAllTreatmentsByPatientId(this.patientId).subscribe(res => {

        if (res.status === 200) {


          this.treatmentList = res.body;
        }
      });
  }

    findAllTreatment() {
        this.startLoader();
        this.treatmentSearch.controls['expertId'].setValue(this.expertId);
        this.treatmentSearch.controls['patientId'].setValue(this.patientId);
        this.treatmentSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.treatmentServices.findAllTreatment(this.treatmentSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.treatmentList = res['data'];
                } else {
                    this.treatmentList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdTreatment(treatmentId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.treatmentServices.deleteByIdTreatment(treatmentId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.treatmentList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameTreatment) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameTreatment)
                            });
                        this.countTreatment();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadTreatmentToEdit(treatmentId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let treatment = this.treatmentList.find(x => x['treatmentId'] == treatmentId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateTreatmentId = treatment['treatmentId'];
        this.selectedIndex = index;

        this.lovDataObject = {"refId":treatment['treatmentId'],"refType":""};

        if(treatment['treatmentDescription']['listOfValuesMasterId']) {
            this.treatmentForm.controls['treatmentDescription'].setValue(treatment['treatmentDescription']['listOfValuesMasterId']);
        }
        if(treatment['treatmentProvided']) {
            this.treatmentForm.controls['treatmentProvided'].setValue(treatment['treatmentProvided']['listOfValuesId']);
        }
        if(treatment['treatmentPlan']['listOfValuesMasterId']) {
            this.treatmentForm.controls['treatmentPlan'].setValue(treatment['treatmentPlan']['listOfValuesMasterId']);
        }
        if(treatment['comments']) {
            this.treatmentForm.controls['comments'].setValue(treatment['comments']);
        }
        if(treatment['patientId']) {
            this.treatmentForm.controls['patientId'].setValue(treatment['patientId']['patientId']);
        }
        if(treatment['expertId']) {
            this.treatmentForm.controls['expertId'].setValue(treatment['expertId']['expertId']);
        }
        if(treatment['appointmentId']) {
            this.treatmentForm.controls['appointmentId'].setValue(treatment['appointmentId']['appointmentId']);
        }
        // this.treatmentForm.controls['userId'].setValue(treatment['userId']);
        console.log(this.treatmentForm.value, 'valuesss');

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.treatmentForm.reset();
        this.treatmentForm.markAsUntouched();
        this.updateTreatmentId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.treatmentForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.treatmentForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.treatmentForm.get(control)?.valid && this.treatmentForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.treatmentForm.reset();
    }

    onCloseSearch() {
        this.treatmentSearch.reset();
    }

    refresh() {
        this.countTreatment();
    }

    onAddOverlayDiv() {
        this.treatmentForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.treatmentSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.treatmentForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateTreatmentId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.treatmentServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.treatmentList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.treatmentServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.treatmentList = result['data'];
            } else {
                this.treatmentList = [];
            }
            console.log(result, 'result');
        });
    }
}
