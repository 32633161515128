<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<ngx-ui-loader></ngx-ui-loader>
<section class="sectionmain">
  <div class="card-cpa-rounded-20 background-white-m-30">
    <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
             [readonly]="false">
    </p-steps>
  </div>
  <div class="p-row initial-assessment-container">

    <div class="p-col-12">
      <div class="row">
        <div class="p-col-3 m-5">

          <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
        </div>
      </div>
      <div class="row">
        <div class="p-col-8 m-5">
          <p-card styleClass="card-cpa-rounded card-shadow">
  <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameTreatment)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search"
                                        tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameTreatment)}}"
                                        tooltipPosition="bottom"
                                        type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameTreatment)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="treatmentSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.treatmentColumnTreatmentProvided)}} </label>
                                            <p-selectButton
                                                    [options]="treatmentProvidedList"
                                                    formControlName="treatmentProvided"
                                                    [optionValue]="treatmentProvidedList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.treatmentColumnComments)}}</label> <span
                                                class="p-input-icon-right"><i
                                                (click)="fieldReset('comments')" *ngIf="diplayeIconInField('comments')"
                                                class="pi pi-times color-danger"></i>
                                            <input   class="pngInputtext"
                                                     formControlName="comments"
                                                     name="comments"
                                                     pInputText
                                                     type="text">
                                        </span>
                                        </div>
                                        </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameTreatment)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="treatmentForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">

                                    <div class="p-fluid p-formgrid p-grid">
                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>{{translate(labels.treatmentColumnRecommendedTreatment)}}</label>
                                        <p-multiSelect
                                          [options]="patientDiagnosisTreatments"
                                          optionLabel="treatmentPrescribedName"
                                          optionValue="treatmentPrescribedId"
                                          display="chip"
                                          formControlName="selectedTreatments"
                                          (onChange)="treatmentSelected($event)">
                                          <ng-template let-treatment pTemplate="item">
                                            <div>
                                              <div>{{treatment.treatmentPrescribedName}} <i  *ngIf="treatment.predictive" class="pi p-1" pBadge value="P"  severity="warning"></i></div>
                                            </div>
                                          </ng-template>

                                        </p-multiSelect>
                                        <app-custom-error [control]="'selectedTreatments'" [form]="treatmentForm"></app-custom-error>
                                      </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.treatmentColumnTreatmentProvided)}}</label>
                                            <p-selectButton
                                                    [options]="treatmentProvidedList"
                                                    formControlName="treatmentProvided"
                                                    [optionValue]="treatmentProvidedList['listOfValuesId']">
                                            </p-selectButton>
                                            <app-custom-error [control]="'treatmentProvided'" [form]="treatmentForm"></app-custom-error>
                                        </div>
                                      <div class="p-field p-col-12  p-md-6 formfieldheight">
                                        <label>{{translate(labels.treatmentColumnTreatmentConsent)}}</label>

                                        <p-selectButton
                                        [options]="treatmentConsentList"
                                        formControlName="treatmentConsent"
                                        [optionValue]="treatmentConsentList['listOfValuesId']">
                                      </p-selectButton>
                                      <app-custom-error [control]="'treatmentConsent'" [form]="treatmentForm"></app-custom-error>
                                      </div>

                                      <div class="p-field p-col-12  p-md-6 formfieldheight">
                                        <label>{{translate(labels.treatmentColumnRisksExplained)}}</label>

                                        <p-selectButton
                                          [options]="treatmentRisksExplainedList"
                                          formControlName="treatmentRisksExplained"
                                          [optionValue]="treatmentRisksExplainedList['listOfValuesId']">
                                        </p-selectButton>
                                        <app-custom-error [control]="'treatmentRisksExplained'" [form]="treatmentForm"></app-custom-error>
                                      </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.treatmentColumnComments)}}</label>
                                            <span   class="p-input-icon-right">
                                                <i  *ngIf="diplayeIconInField('comments')"
                                                    (click)="fieldReset('comments')"
                                                    class="pi pi-times color-danger">
                                                </i>
                                               <textarea rows="5" [class.p-invalid]="inputFieldDanger('comments')"
                                                         class="form-control" formControlName="comments" name="comments"></textarea>
                                            </span>
                                            <app-custom-error [control]="'comments'" [form]="treatmentForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                          id="invitationpage-memberslist-globalsearch"><i
                                          class="pi pi-search"></i>
                                        <input  class="pngInputtext" pInputText
                                                placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="treatmentList?.length > 0"
                                         (onLazyLoad)="onPageChnage($event)"
                                         [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true"
                                         [paginator]="true"
                                         [resizableColumns]="true"
                                         [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]"
                                         [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="treatmentList"
                                         class="pngTable"
                                         [ngClass]="{'pngTableEmptyMessage': treatmentList?.length == 0}"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
<!--                                            <th> Treatment Description</th>-->
                                            <th> Risks Explained</th>
<!--                                            <th> Treatment Plan</th>-->
                                            <th> Consent </th>

                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
<!--                                            <td> {{element?.treatmentDescription.listOfValuesDetailId}} </td>-->
                                            <td> {{element?.risksExplained}} </td>
<!--                                            <td> {{element?.treatmentPlan.listOfValuesDetailId}} </td>-->
                                            <td> {{element?.consent}} </td>

                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadTreatmentToEdit(element.treatmentId, i)"
                                                              icon="pi pi-pencil"
                                                              pTooltip="Edit"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <p-button (click)="deleteByIdTreatment(element.treatmentId,i)"
                                                              icon="pi pi-trash"
                                                              pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button>
<!--                                                    <p-button icon="pi pi-eye"-->
<!--                                                              label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent">-->
<!--                                                    </p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
  </section></p-card>
    </div>
    </div>
    </div>
    </div>
</section>
