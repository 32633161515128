import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ClinicExpertsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdClinicExperts(clinicExpertsId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClinicExperts/deleteClinicExperts', {'clinicExpertsId': clinicExpertsId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countClinicExperts(clinicExperts): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'ClinicExperts/count', {'clinicExperts': clinicExperts}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateClinicExperts(clinicExperts): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClinicExperts/updateClinicExperts', [clinicExperts], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addClinicExperts(clinicExperts): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'ClinicExperts/addClinicExperts', [clinicExperts], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllClinicExperts(clinicExperts, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ClinicExperts/findAllClinicExperts',
            {'clinicExperts': clinicExperts, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service to handle global searching
    clinicExpertGlcount(searchTerm,refId): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'ClinicExperts/countGlobalClinicExpert',
                                            {'searchTerm': searchTerm,'refId':refId},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalClinicExpert(searchTerm, refId, pageNo: number, itemsPerPage: number): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ClinicExperts/findGlobalClinicExpert',
            {'searchTerm': searchTerm,'refId':refId, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addClinicExpertCpa(clinicExpert): Observable<any> {

    return this.httpClient.post<any>(Constant.CPA_EXPERT_PATIENT_SERVICE + 'clinic-experts',
      clinicExpert,{
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
  }

  findAllClinicExpertsByClientId(clientId): Observable<any> {

    return this.httpClient.get<string[]>(
      Constant.CPA_EXPERT_PATIENT_SERVICE + 'clinic-experts/filter?type=byClient&id=' + clientId,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));

  }

}
