import { Component, OnInit, ViewChild, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { OutcomeMeasureService } from '../web-services/outcome-measure.service';
import { AddOutcomeService } from '../web-services/add-outcome.service';
import { ListOfValuesDetailService } from '../web-services/list-of-values-detail.service';
import { UsersService } from '../web-services/users.service';
import { SessionService } from '../services/session.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component(
    { selector: 'app-outcome-measure', templateUrl: './outcome-measure.component.html', styleUrls: ['./outcome-measure.component.css'] })
export class OutcomeMeasureComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateOutcomeMeasureId = null;
    listOfValuesDetailList: any = [];
    outcomeMeasureList: any = [];
    updateOutcomeMeasureDetailId = null;
    outcomeMeasureDetailList = [];
    outcomeMeasureListId;
    outcomeMeasureIdTemp;
    outcomeMeasureSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    outcomeMeasureForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    @ViewChild('uploadRef') uploadRef: any;
    tncUploadedFiles: any = [];

    outcomeMeasureDetailListTemp: any = []

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';

    expertId;
    patientId;
    appointmentId;
    userId;
    constructor(
        private outcomeMeasureServices: OutcomeMeasureService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService,
        private outcomeMeasure: AddOutcomeService,
    ) {
    };

    get addUpdateTitle() {
        return this.updateOutcomeMeasureId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.outcomeMeasureSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countOutcomeMeasure();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countOutcomeMeasure() {
        this.outcomeMeasureSearch.controls['expertId'].setValue(this.expertId);
        this.outcomeMeasureSearch.controls['patientId'].setValue(this.patientId);
        this.outcomeMeasureSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.outcomeMeasureServices.countOutcomeMeasure(this.outcomeMeasureSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllOutcomeMeasure();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {

        this.outcomeMeasureForm.markAllAsTouched();
        console.log(this.expertId);
        console.log(this.patientId);
        console.log(this.appointmentId);
        this.outcomeMeasureForm.controls['expertId'].setValue(this.expertId);
        this.outcomeMeasureForm.controls['patientId'].setValue(this.patientId);
        this.outcomeMeasureForm.controls['appointmentId'].setValue(this.appointmentId);
        this.outcomeMeasureForm.controls['userId'].setValue(this.userId);

        this.outcomeMeasureForm.controls['classificationOnInitialAssessmentCommnets'].setValue("0");
        this.outcomeMeasureForm.controls['classificationPostTreatmentCommnets'].setValue("1");

        if (this.outcomeMeasureForm.valid) {
            console.log(typeof this.outcomeMeasureForm.get('uploadImage').value);
            if(typeof this.outcomeMeasureForm.get('uploadImage').value=='string'){
                this.outcomeMeasureForm.controls['uploadImage'].setValue(this.outcomeMeasureForm.get('uploadImage').value);
            }else{
                this.outcomeMeasureForm.controls['uploadImage'].setValue(JSON.stringify(this.outcomeMeasureForm.get('uploadImage').value));
            }
          
            this.addUpdateOutcomeMeasure(this.outcomeMeasureForm.value);
            this.submitted = false;
            this.onClearAdd();
        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllOutcomeMeasure();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.outcomeMeasureSearch.reset();
        this.countOutcomeMeasure();
    }

    addUpdateOutcomeMeasure(outcomeMeasure) {
        this.startLoader();
     console.log(outcomeMeasure);
        let tempObject = JSON.parse(JSON.stringify(this.outcomeMeasureForm.value));
        let tempObject11 = JSON.parse(JSON.stringify(this.outcomeMeasureForm.value));
        delete tempObject11["WAD"];
        let tempWAD = tempObject["WAD"];
        let tempWADObject = [];
        console.log(this.updateOutcomeMeasureId);
        if (this.updateOutcomeMeasureId == null) {
            for (let i = 0; i < tempWAD.length; i++) {
            for (let object in tempWAD[i]) {
                console.log(tempWAD[i][object])
                let dropDown = { label: '', value: '', outcomeMeasureId: this.updateOutcomeMeasureId, expertId: this.expertId, patientId: this.patientId, appointmentId: this.appointmentId };
                dropDown.value = tempWAD[i][object];
                dropDown.label = object;


                tempWADObject.push(dropDown);
            }
        }
            let obj1={"data":[tempObject11],"outcomeMeasuresDetail":tempWADObject}
            this.outcomeMeasure.addOutcomeMeasure(obj1).subscribe(res => {
                console.log(res);
                if (res['message'] == 'Success') {
                    outcomeMeasure = res['data'][0];
                    console.log(res['data'][0])
                    this.outcomeMeasureList.push(outcomeMeasure);
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNameOutcomeMeasure) +
                                                    this.translate(this.labels.addedSuccessfully),
                        // detail: this.translate(this.labels.entityNameOutcomeMeasure)
                    });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countOutcomeMeasure();

                    // var tempWAD = tempObject["WAD"];
                    // console.log(tempWAD)
                    // var tempWADObject = [];
                    // for (let i = 0; i < tempWAD.length; i++) {
                    //     console.log(tempWAD[i])
                    //     for (let object in tempWAD[i]) {
                    //         console.log(tempWAD[i][object])
                    //         let dropDown = { label: '', value: '', outcomeMeasureId: outcomeMeasure.outcomeMeasureId, expertId: this.expertId, patientId: this.patientId, appointmentId: this.appointmentId };
                    //         dropDown.value = tempWAD[i][object];
                    //         dropDown.label = object;


                    //         tempWADObject.push(dropDown);
                    //     }


                    // }
                    // this.addUpdateOutcomeMeasureDetail(outcomeMeasure, tempWADObject);
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            console.log(this.outcomeMeasureDetailListTemp )
            for (let i = 0; i < tempWAD.length; i++) {
                for (let object in tempWAD[i]) {
                    console.log(tempWAD[i][object])
                    let dropDown = { label: '', value: '', outcomeMeasureId: this.updateOutcomeMeasureId, expertId: this.expertId, patientId: this.patientId, appointmentId: this.appointmentId };
                    dropDown.value = tempWAD[i][object];
                    dropDown.label = object;
    for(let tempOutComeDetailIndex=0;tempOutComeDetailIndex<this.outcomeMeasureDetailListTemp.length;tempOutComeDetailIndex++ ){
if(this.outcomeMeasureDetailListTemp[tempOutComeDetailIndex]["label"]==object){
    console.log(object);
    // dropDown = { ...dropDown,outcomeMeasureDetailId: 'ww'};
    dropDown['outcomeMeasureDetailId']=this.outcomeMeasureDetailListTemp[tempOutComeDetailIndex]["outcomeMeasureDetailId"]
}

    }
    
                    tempWADObject.push(dropDown);
                }
            }
                var obj1={"data":[tempObject11],"outcomeMeasuresDetail":tempWADObject}
            tempObject11.outcomeMeasureId = this.updateOutcomeMeasureId;

            this.outcomeMeasure.updateOutcomeMeasure(obj1).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.outcomeMeasureList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNameOutcomeMeasure) +
                                                    this.translate(this.labels.updatedSuccessfully),
                        // detail: this.translate(this.labels.entityNameOutcomeMeasure)
                    });
                    this.showSearchBtn = true;
                    //   this.showAddBtn = true;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    var tempWAD = tempObject["WAD"];
                    console.log(tempWAD)
                    var tempWADObject = [];
                    for (let i = 0; i < tempWAD.length; i++) {
                        console.log(tempWAD[i])
                        for (let object in tempWAD[i]) {
                            console.log(tempWAD[i][object])
                            let dropDown = { outcomeMeasureDetailId: 0, label: '', value: '', outcomeMeasureId: tempObject11.outcomeMeasureId, expertId: this.expertId, patientId: this.patientId, appointmentId: this.appointmentId };
                            for (let j = 0; j < this.outcomeMeasureDetailListTemp.length; j++) {
                                if (this.outcomeMeasureDetailListTemp[j]["label"] == object) {
                                    dropDown.value = tempWAD[i][object];
                                    dropDown.label = object;
                                    dropDown.outcomeMeasureDetailId = this.outcomeMeasureDetailListTemp[j]["outcomeMeasureDetailId"];
                                }
                            }

                            tempWADObject.push(dropDown);
                        }


                    }
                    console.log(tempWADObject);
                    this.updateOutcomeMeasureDetailId = 0;
                    // this.addUpdateOutcomeMeasureDetail(outcomeMeasure, tempWADObject);

                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateOutcomeMeasureId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }
    addUpdateOutcomeMeasureDetail(outcomeMeasureDetail, temp) {

        this.startLoader();
        if (this.updateOutcomeMeasureDetailId == null) {

            this.outcomeMeasureServices.addOutcomeMeasureDetail(temp).subscribe(res => {
                this.form();
                if (res['message'] == 'Success') {
                    outcomeMeasureDetail = res['data'][0];
                    this.outcomeMeasureDetailListTemp.push(JSON.parse(JSON.stringify(outcomeMeasureDetail)))
                    this.outcomeMeasureDetailList.push(outcomeMeasureDetail);
                    // this.messageService.add({
                    //                             severity: 'success',
                    //                             summary: this.translate(this.labels.btnAdd),
                    //                             detail: this.translate(this.labels.entityNameOutcomeMeasureDetail) });
                    this.submitted = true;
                } else {
                    console.error(res['documentation']);
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        }
        else {
            outcomeMeasureDetail.outcomeMeasureDetailId = this.updateOutcomeMeasureDetailId;
            this.outcomeMeasureServices.updateOutcomeMeasureDetail(temp).subscribe(res => {
                this.form();
                this.updateOutcomeMeasureDetailId = null;
                if (res['message'] == 'Success') {
                    this.outcomeMeasureDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    // this.messageService.add({
                    //                             severity: 'success',
                    //                             summary: this.translate(this.labels.btnUpdate),
                    //                             detail: this.translate(this.labels.entityNameOutcomeMeasureDetail) });
                } else {
                    console.error(res['documentation']);
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateOutcomeMeasureDetailId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        } this.stopLoader();
    }
    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }
    form() {
        this.outcomeMeasureForm = new FormGroup({
            WAD: new FormArray([
                new FormGroup({
                    WAD0: new FormControl('', [Validators.required, validateSpaces]),
                })
            ]),
            classificationOnInitialAssesment: new FormControl('WAD0', [Validators.required, validateSpaces]),
            classificationPostTreatment: new FormControl('WAD0', [Validators.required, validateSpaces]),
            uploadImage: new FormControl('',[Validators.required, validateSpaces]),
            patientId: new FormControl(),
            expertId: new FormControl(),
            appointmentId: new FormControl(),

            userId: new FormControl(0, [Validators.required, Validators.min(0)]),
            classificationOnInitialAssessmentCommnets: new FormControl(
                '', [Validators.required, validateSpaces]),
            classificationPostTreatmentCommnets: new FormControl(
                '', [Validators.required, validateSpaces]),
        });
        this.outcomeMeasureForm.controls['classificationOnInitialAssesment'].setValue(
            'WAD0');
        this.outcomeMeasureForm.controls['classificationPostTreatment'].setValue(
            'WAD0');
        this.outcomeMeasureForm.updateValueAndValidity;

        this.tncUploadedFiles = [];
      
    }
    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.showSearchBtn = true;
        this.showAddBtn = true;
        this.expertId = parseInt(this.sessionService.getSession('expertId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
this.userId = parseInt(this.sessionService.getSession('loggedInUser'));
        // this.findAllListOfValuesDetail();
        this.form();
        this.outcomeMeasureSearch = new FormGroup({
            classificationOnInitialAssesment: new FormControl(),
            classificationPostTreatment: new FormControl(),
            uploadImage: new FormControl("", [Validators.required, validateSpaces]),
            patientId: new FormControl(),
            expertId: new FormControl(),
            appointmentId: new FormControl(),
            userId: new FormControl(),
            classificationOnInitialAssessmentCommnets: new FormControl(),
            classificationPostTreatmentCommnets: new FormControl(),
        });
        this.countOutcomeMeasure();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.outcomeMeasureSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });

    }

    findAllOutcomeMeasure() {
        this.startLoader();

        this.outcomeMeasureSearch.controls['expertId'].setValue(this.expertId);
        this.outcomeMeasureSearch.controls['patientId'].setValue(this.patientId);
        this.outcomeMeasureSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.outcomeMeasureServices.findAllOutcomeMeasure(this.outcomeMeasureSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.outcomeMeasureList = res['data'];
                    if (this.outcomeMeasureList.length > 0) {
                        this.outcomeMeasureIdTemp = this.outcomeMeasureList[0]["outcomeMeasureId"];
                        this.findAllOutcomeMeasureDetail();
                        this.listOfValuesDetailList = [];
                        var tempList = JSON.parse(JSON.stringify(this.outcomeMeasureForm.get("WAD").value));
                        for (let i = 0; i < tempList.length; i++) {
                            for (let object in tempList[i]) {
                                let dropDown = { label: '', value: '' };
                                dropDown.value =object;
                                dropDown.label = object;
                                this.listOfValuesDetailList.push(dropDown);
                            }
                        }
                        this.outcomeMeasureForm.updateValueAndValidity;
                    }



                    
                    
                } else {
                    this.outcomeMeasureList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findAllOutcomeMeasureDetail() {
        this.startLoader();
        var temp = {
            "expertId": this.expertId,
            "patientId": this.patientId,
            "appointmentId": this.appointmentId,
            "outcomeMeasureId": this.outcomeMeasureIdTemp
        }
        // this.outcomeMeasureSearch.controls['expertId'].setValue(this.expertId);
        // this.outcomeMeasureSearch.controls['patientId'].setValue(this.patientId);
        // this.outcomeMeasureSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.outcomeMeasureServices.findAllOutcomeMeasureDetail(temp, this.pageNo, this.itemPerPage).subscribe(
            res => {
                this.outcomeMeasureDetailListTemp = [];
                if (res['message'] == 'Success') {
                    this.outcomeMeasureDetailListTemp = res['data'];
                } else {
                    this.outcomeMeasureDetailListTemp = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    deleteByIdOutcomeMeasure(outcomeMeasureId: number, index) {
        this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.outcomeMeasureServices.deleteByIdOutcomeMeasure(outcomeMeasureId).subscribe(res => {
                    this.form();
                    this.uploadRef.clear();

                    if (res['message'] == 'Success') {
                        this.outcomeMeasureList.splice(index, 1);
                        this.showAddBtn = false
                        this.messageService.add({
                            severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameOutcomeMeasure) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                            // detail: this.translate(this.labels.entityNameOutcomeMeasure)
                        });
                        this.countOutcomeMeasure();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }
    deleteByIdOutcomeMeasureDetail(outcomeMeasureId: number, index) {
        var tempoutcomeMeasureDetailId;
        for (let i = 0; i < this.outcomeMeasureDetailListTemp.length; i++) {
            console.log((<FormArray>this.outcomeMeasureForm.get('WAD')).at(index).value);
            var temps = Object.keys((<FormArray>this.outcomeMeasureForm.get('WAD')).at(index).value);
            if (this.outcomeMeasureDetailListTemp[i]["label"] == temps[0]) {
                tempoutcomeMeasureDetailId = this.outcomeMeasureDetailListTemp[i]["outcomeMeasureDetailId"]
                console.log()
                // (<FormArray>this.outcomeMeasureForm.get('WAD')).at(index).get(tempFieldname)
            }
        }
        console.log(tempoutcomeMeasureDetailId)
        console.log(tempoutcomeMeasureDetailId==undefined)
        console.log(tempoutcomeMeasureDetailId!=undefined)
        if(tempoutcomeMeasureDetailId){
        this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.outcomeMeasureServices.deleteByIdOutcomeMeasureDetail(tempoutcomeMeasureDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        // this.outcomeMeasureList.splice(index, 1);
                        this.outcomeMeasureForm.updateValueAndValidity;
                        this.showAddBtn = false
                        this.messageService.add({
                            severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameOutcomeMeasure) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                            // detail: this.translate(this.labels.entityNameOutcomeMeasure)
                        });

                        this.removeWAD(index);

                        // var tempFormArray: FormArray = <FormArray>this.outcomeMeasureForm.get("WAD");
                        // tempFormArray.removeAt(index);
                        // this.countOutcomeMeasure();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }else{

    }
    }

    loadOutcomeMeasureToEdit(outcomeMeasureId: number, index) {
        console.log(outcomeMeasureId)
        console.log(this.outcomeMeasureDetailListTemp);
        this.form();
        var tempFormArray: FormArray = <FormArray>this.outcomeMeasureForm.get("WAD");
        for (let i = 0; i < this.outcomeMeasureDetailListTemp.length; i++) {
            if (this.outcomeMeasureDetailListTemp[i]["label"] != "WAD0") {
                console.log(i)
                var tempGroup: FormGroup = new FormGroup({});
                tempGroup.addControl(this.outcomeMeasureDetailListTemp[i]["label"], new FormControl(this.outcomeMeasureDetailListTemp[i]["value"]));
                tempFormArray.push(tempGroup)
            } else {

                tempFormArray.controls[i].setValue({ 'WAD0': this.outcomeMeasureDetailListTemp[i]["value"] })
            }
        }

        // for again value load indropdown
        this.listOfValuesDetailList = [];
        for (let i = 0; i < this.outcomeMeasureDetailListTemp.length; i++) {
            let dropDown = { label: '', value: '' };


            dropDown.value = this.outcomeMeasureDetailListTemp[i]["label"];
            dropDown.label = this.outcomeMeasureDetailListTemp[i]["label"];
            this.listOfValuesDetailList.push(dropDown);

        }
        let outcomeMeasure = this.outcomeMeasureList.find(x => x['outcomeMeasureId'] == outcomeMeasureId);
        console.log(outcomeMeasure)
        console.log(outcomeMeasure['classificationPostTreatment']);
        console.log(outcomeMeasure['classificationOnInitialAssesment']);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateOutcomeMeasureId = outcomeMeasure['outcomeMeasureId'];
        console.log(this.updateOutcomeMeasureId);
        this.selectedIndex = index;
        this.outcomeMeasureForm.controls['classificationOnInitialAssesment'].setValue(
            outcomeMeasure['classificationOnInitialAssesment']);
        this.outcomeMeasureForm.controls['classificationPostTreatment'].setValue(
            outcomeMeasure['classificationPostTreatment']);
        // this.outcomeMeasureForm.controls['uploadImage'].setValue(outcomeMeasure['uploadImage']);
        this.outcomeMeasureForm.controls['patientId'].setValue(outcomeMeasure['patientId']['patientId']);
        this.outcomeMeasureForm.controls['expertId'].setValue(outcomeMeasure['expertId']['expertId']);
        this.outcomeMeasureForm.controls['appointmentId'].setValue(outcomeMeasure['appointmentId']['appointmentId']);
        this.outcomeMeasureForm.controls['userId'].setValue(outcomeMeasure['userId']);
        this.outcomeMeasureForm.controls['classificationOnInitialAssessmentCommnets'].setValue(
            outcomeMeasure['classificationOnInitialAssessmentCommnets']);
        this.outcomeMeasureForm.controls['classificationPostTreatmentCommnets'].setValue(
            outcomeMeasure['classificationPostTreatmentCommnets']);


        let fileUrls = [];
        console.log(JSON.parse(outcomeMeasure['uploadImage']));
        if(JSON.parse(outcomeMeasure['uploadImage'])!=null){
        if(JSON.parse(outcomeMeasure['uploadImage']).length>0){
        JSON.parse(outcomeMeasure['uploadImage']).forEach(Image => {
            let tmp = {
                "src": Image
            }
            this.tncUploadedFiles.push(Image);
        });
        this.outcomeMeasureForm.controls['uploadImage'].setValue(outcomeMeasure['uploadImage']);
            }
        }
       this.showHideUpdateDialog1();
    }

    onClearAdd() {
        this.outcomeMeasureForm.reset();
        this.outcomeMeasureForm.markAsUntouched();
        this.updateOutcomeMeasureId = null;
        closeAddOverlaydiv();
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.showAddBtn = true
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }

                } else {
                    this.listOfValuesDetailList = [];
                    this.showAddBtn = false
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.outcomeMeasureForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.outcomeMeasureForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.outcomeMeasureForm.get(control)?.valid && this.outcomeMeasureForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.outcomeMeasureForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.outcomeMeasureSearch.reset();
    }

    refresh() {
        this.countOutcomeMeasure();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.outcomeMeasureForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        //  this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.outcomeMeasureForm.reset();

        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        //  this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateOutcomeMeasureId = null;
        this.tncUploadedFiles = [];

        this.form();
        this.listOfValuesDetailList = [];
        var tempList = JSON.parse(JSON.stringify(this.outcomeMeasureForm.get("WAD").value));
        for (let i = 0; i < tempList.length; i++) {
            for (let object in tempList[i]) {
                let dropDown = { label: '', value: '' };
                dropDown.value =object;
                dropDown.label = object;
                this.listOfValuesDetailList.push(dropDown);
            }
        }
    }

    showHideUpdateDialog() {

        this.addEditTitle = this.labels.btnUpdated
        this.showAddDialog = !this.showAddDialog;
        // this.showAddBtn = this.showAddDialog;
        //   this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateOutcomeMeasureId = null;
        this.outcomeMeasureForm.controls['classificationOnInitialAssesment'].setValue("WAD0");
        this.outcomeMeasureForm.controls['classificationPostTreatment'].setValue(
            "WAD0");
    }
    showHideUpdateDialog1() {
        this.addEditTitle = this.labels.btnUpdated
        // this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        // this.showAddBtn = this.showAddDialog;
        //   this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        // this.updateOutcomeMeasureId = null;

    }
    // by naeem
    addWAD(index) {
        var tempNumber = index + 1
        var tempFormArray: FormArray = <FormArray>this.outcomeMeasureForm.get("WAD");
        var tempGroup: FormGroup = new FormGroup({});
        tempGroup.addControl("WAD" + tempFormArray.value.length, new FormControl('',[Validators.required, validateSpaces]));
        tempFormArray.push(tempGroup)
        this.listOfValuesDetailList = this.outcomeMeasureForm.get("WAD").value;
        var tempList = this.outcomeMeasureForm.get("WAD").value;
        this.listOfValuesDetailList = [];
        for (let i = 0; i < tempList.length; i++) {
            for (let object in tempList[i]) {
                let dropDown = { label: '', value: '' };
                dropDown.value = object;
                dropDown.label = object;
                this.listOfValuesDetailList.push(dropDown);
            }
        }
        console.log(index);
        this.outcomeMeasureForm.updateValueAndValidity;
    }
    removeWAD(index) {
        var tempFormArray: FormArray = <FormArray>this.outcomeMeasureForm.get("WAD");
        tempFormArray.removeAt(index);
        this.listOfValuesDetailList = [];
        var tempList = this.outcomeMeasureForm.get("WAD").value;
        for (let i = 0; i < tempList.length; i++) {
            for (let object in tempList[i]) {
                let dropDown = { label: '', value: '' };
                dropDown.value = object;
                dropDown.label = object;
                this.listOfValuesDetailList.push(dropDown);
            }
        }
        this.outcomeMeasureForm.updateValueAndValidity;
        // this.outcomeMeasureForm
        // this.listOfValuesDetailList = this.outcomeMeasureForm.get("WAD").value;
    }
    onTNCUpload(event,form) {
        console.log("event :: ", event)
        console.log("event files:: ", event.files)
        this.tncUploadedFiles = [];

        event.files.map(file => {
            this.sharedService.uploadFile(file).subscribe(res => {
                    let fileObject = {} as string;
                    fileObject['name'] = file.name;
                    fileObject['src'] = res;
                    fileObject['size'] = file.size;
                    fileObject['type'] = file.type;
                    this.tncUploadedFiles.push(fileObject);
                    this.outcomeMeasureForm.controls['uploadImage'].setValue(this.tncUploadedFiles);
            });
        });
            this.messageService.add({ severity: 'success', summary: 'Files Uploaded Successfully', detail: '' });



        // for (let file of event.files) {
        //     this.tncUploadedFiles = [];
        //     console.log(count);
        //     console.log(file);
        //     let url = this.sharedService.uploadFile(file);
        //     url.subscribe(res => {
        //         console.log(res);
        //         count = count + 1;
        //         fileUrls.push(res);
        //         let fileObject = {} as string;
        //         fileObject['name'] = file.name;
        //         fileObject['src'] = res;
        //         fileObject['size'] = file.size;
        //         fileObject['type'] = file.type;
        //         this.tncUploadedFiles.push(fileObject);
        //         if (count == event.files.length) {
        //             this.outcomeMeasureForm.controls['uploadImage'].setValue(this.tncUploadedFiles);
        //             this.messageService.add({ severity: 'success', summary: 'Files Uploaded Successfully', detail: '' });
        //         }
        //
        //     });
        // }
        console.log('uploaded files : ',this.tncUploadedFiles)
        this.uploadRef.clear();
    }

    getGroupControl(index, item) {
        var temps = Object.keys(item.value);

        var   tempFieldname=temps[0];
        
        // var tempFormArray: FormArray = <FormArray>this.outcomeMeasureForm.get("WAD");
        return (<FormArray>this.outcomeMeasureForm.get('WAD')).at(index).get(tempFieldname);
    }
    Field(item) {
        var temps = Object.keys(item.value);

        return temps[0];
    }

    DeleteImage(image, index) {
        // console.log(image);

        if (this.tncUploadedFiles.find(x => (x.src == image.src))) {
            this.tncUploadedFiles.splice(index, 1);
            // console.log(this.uploadedFiles);
            // this.adForm.controls['adUploadImages'].setValue(this.uploadedFiles);
            let fileUrls = [];
            this.tncUploadedFiles.forEach(ele => {
                let fileObject = {} as string;
                fileObject['name'] = image.name;
                fileObject['src'] = image;
                fileObject['size'] = image.size;
                fileObject['type'] = image.type;
                fileUrls.push(ele.src);
            });
            this.outcomeMeasureForm.controls['uploadImage'].setValue( this.tncUploadedFiles);

        }
    }
}
