<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deleteSocialHistory" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!--<ngx-ui-loader></ngx-ui-loader>-->
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
  <div class="container card-cpa-rounded card-shadow">
        <section>
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameSocialHistory)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameSocialHistory)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameSocialHistory)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="socialHistorySearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.socialHistoryColumnAdl)}}</label>
                                            <p-selectButton
                                                    [options]="affectedCategoryList"
                                                    formControlName="adl"
                                                    [optionValue]="affectedCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameSocialHistory)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="socialHistoryForm" class="pngForm"
                                      id="invitationpage-addmember-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <!-- Occupation -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['occupation'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['occupation'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['occupation'].currentSelected"></app-lov-details>
                                          <span id="#occupationError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>


                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['adl'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['adl'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['adl'].currentSelected"></app-lov-details>
                                          <span id="#adlError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <!-- Mobility -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['mobility'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['mobility'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['mobility'].currentSelected"></app-lov-details>
                                          <span id="#mobilityError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <!-- Hobbies -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['hobbies'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['hobbies'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['hobbies'].currentSelected"></app-lov-details>
                                          <span id="#hobbiesError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header><span
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                    <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                    <input #global class="pngInputtext"
                                           pInputText
                                           placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                           type="text"/>
                                </span>
                                </p-header>
                                <p-table #dv *ngIf="socialHistoryList?.length > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="socialHistoryList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': socialHistoryList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped" dataKey="insertionTimestamp">
                                    <ng-template pTemplate="header">
                                        <tr>
                                          <th></th>
                                            <th>Date</th>
                                            <th>Occupation</th>
                                            <th>Adl</th>
                                            <th>Mobility</th>
                                            <th>Hobbies</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body" let-expanded="expanded">
                                        <tr>
                                          <td>
                                            <button type="button" pButton pRipple [pRowToggler]="element" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                          </td>
                                            <td> {{element?.insertionTimestamp | date: "dd-MM-yyyy" }} </td>
                                            <td> {{element?.occupation[0].lovValues}} </td>
                                            <td> {{element?.adl[0].lovValues}} </td>
                                            <td> {{element?.mobility[0].lovValues}} </td>
                                            <td> {{element?.hobbies[0].lovValues}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadSocialHistoryToEdit(element.socialHistoryId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdSocialHistory(element.socialHistoryId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                  <ng-template pTemplate="rowexpansion" let-element>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-briefcase fa-2x"></i> Occupation </span>
                                        <span *ngFor="let drinks of element.occupation; let b = index">
                                          {{occupation.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-chair fa-2x"></i> Adl  </span>
                                        <span *ngFor="let adl of element.adl; let b = index">
                                          {{adl.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-person-walking-with-cane fa-2x"></i> Mobility </span>

                                        <span *ngFor="let mobility of element.mobility; let c = index">
                                          {{mobility.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header multi-choice-title"><i class="fa-solid fa-futbol fa-2x"></i> Hobbies </span>
                                        <span *ngFor="let hobbies of element.hobbies; let d = index">
                                          {{hobbies.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
