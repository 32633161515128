<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deleteObservation" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>

<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameObservations)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameObservations)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameObservations)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="observationsSearch" class="pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.observationsColumnObservations)}} </label>
                                            <p-dropdown [filter]="true" [options]="observationDropDownList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="observations"
                                                        placeholder="Select"></p-dropdown>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.observationsColumnComments)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('comments')"
                                                                              *ngIf="diplayeIconInField('comments')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="comments"
                                                name="comments"
                                                pInputText
                                                type="text"> </span>
                                        </div>
                                        </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <!-- (click)="onSearch()" -->
                                        <p-button label="Search" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">

                                <form (ngSubmit)="onSubmit()" [formGroup]="observationsForm" class="pngForm">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">

                                          <app-lov-details [categoryString]="objectMultiSelects['observations'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['observations'].label}}"
                                                           [reset]=objectMultiSelects.reset
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['observations'].currentSelected"></app-lov-details>
                                          <span id="#observationsError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>



                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.observationsColumnComments)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('comments')"
                                                                              *ngIf="diplayeIconInField('comments')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('comments')"
                                                class="pngInputtext"
                                                formControlName="comments"
                                                name="comments"
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'comments'" [form]="observationsForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext"
                                               pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="observationsList?.length > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true [alwaysShowPaginator]="false"
                                         [totalRecords]="observationsList?.length"
                                         [value]="observationsList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': observationsList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped" dataKey="insertionTimestamp">
                                    <ng-template pTemplate="header">
                                        <tr>
                                          <th></th>
                                            <th> Date</th>
                                            <th> Observations</th>
                                            <th> Comments</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body" let-expanded="expanded">
                                        <tr>
                                          <td>
                                            <button type="button" pButton pRipple [pRowToggler]="element" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                          </td>
                                            <td> {{element?.insertionTimestamp | date: "dd-MM-YYYY" }} </td>
                                            <td> {{element?.observations[0].lovValues}} </td>
                                            <td> {{element?.comments}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadObservationsToEdit(element.observationsId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdObservations(element.observationsId,i)"
                                                              icon="pi pi-trash"
                                                              pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                  <ng-template pTemplate="rowexpansion" let-element>
                                    <app-initial-assessment-multi-select-info [valuesList]="element?.observations" [title]="'Observations'" [icon]="'fa-check'"></app-initial-assessment-multi-select-info>
                                  </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
