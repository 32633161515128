import {FormGroup} from "@angular/forms";
import {Injectable} from "@angular/core";
import {ConstantService} from "../web-services/constant.service";
import {SharedService} from "../web-services/shared.service";
import {Labels} from "./labels";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Injectable({
  providedIn: 'root'
})
export class ComponentFormBase {

  public labels = Labels;
  public showSearchDialog = false;
  public showSearchBtn = false;
  public showAddDialog = false;
  public showAddBtn = false;
  public editTag = false;
  public disableColumn = false;
  public baseForm: FormGroup;
  public viewEdit: false;
  constantList = [];
  addEditTitle = 'Add';
  totalRecords = 0;


  constructor(   private constantService: ConstantService,
                 private sharedService: SharedService,
                 private loader: NgxUiLoaderService,

  ) {
  }


  translate(label) {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

  showHideAddDialog(): void {
    if (!this.showAddDialog) {
      this.baseForm.reset();
    }
    this.addEditTitle = 'Add';
    this.showAddDialog = !this.showAddDialog;
    this.showAddBtn = this.showAddDialog;
    this.showSearchBtn = this.showAddDialog;
    this.editTag = false;
  }

  startLoader() {
    this.loader.start();
  }

  stopLoader() {
    this.loader.stop();
  }

}
