import {Injectable} from '@angular/core';
import {DataSharedService} from './data-shared.service';
import {SessionService} from './session.service';
import {Router} from '@angular/router';
import {Constant} from "../util/constant";

@Injectable({
                providedIn: 'root'
            })
export class LocalService {

    constructor(private sharedService: DataSharedService, private sessionService: SessionService, private router: Router) {
    }


    logout() {
        this.sharedService.checkIsUserLoggedIn(false);
        sessionStorage.removeItem('applicationId');
        sessionStorage.removeItem('fullAuthenticatedUser');
        sessionStorage.removeItem('clientId');
        sessionStorage.removeItem('userType');
        sessionStorage.removeItem('firstName');
        sessionStorage.removeItem('cpa_roles');
        sessionStorage.removeItem('loggedInUser');
        sessionStorage.removeItem('token');
        // sessionStorage.removeItem(this.main.sharedService.getCookie("applicationShortName") + "_roles");
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('applicationId');
        sessionStorage.removeItem('cpa_roles');
        sessionStorage.removeItem('currentUrl');
        sessionStorage.removeItem('activatedRole');
        sessionStorage.removeItem('loggedInUser');
        sessionStorage.removeItem('currentUrl');
        sessionStorage.removeItem('loggedInUserType');
        sessionStorage.removeItem('loggedInRefId');
        this.sessionService.deleteCookie('token');
        this.sessionService.deleteCookie('applicationId');
        this.sessionService.deleteCookie('umsUrl');

        // this.setIsMenuInitialized(false); //bilal
        let url = window.location.href;
        if (url.includes('localhost')) {
            this.router.navigate(['/login']);
        }
        else if (url.includes('dev.hive-worx.com')) {
            window.location.assign('https://dev.hive-worx.com/cpa/login');
        }else if (url.includes('https://qa.hive-worx.com/')) {
            window.location.assign('https://qa.hive-worx.com/cpa/login');
        } else {
            this.router.navigate(['/login']);
        }
    }
}
