// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.




/*
export const environment = {
    production: false,
    baseUrl: 'http://127.0.0.1:8081/',
    k8Cluster: 'http://192.168.244.132:30866/',
    cpaMainService: 'http://cpa-mico-cluster.info:30219/',
    appointmentsService: 'http://cpa-mico-cluster.info:32619/',
    jwtService: 'http://cpa-mico-cluster.info:31633/',
    clientService: 'http://cpa-mico-cluster.info:30843/',
    firebaseConfig: {
    apiKey: "AIzaSyBkKogSIR3BlfrE7fLCUSoc9H3gfJhtE7Q",
    authDomain: "cpa-dev-notifications.firebaseapp.com",
    projectId: "cpa-dev-notifications",
    storageBucket: "cpa-dev-notifications.appspot.com",
    messagingSenderId: "119660911040",
    appId: "1:119660911040:web:aa6d757fd7160f4c47d025",
      serviceWorkerPath: 'firebase-messaging-sw.js'



    }




};
*/








export const environment = {
  production: false,
  baseUrl: 'https://ec2.physioproassist.com/',
  k8Cluster: 'https://ec.physioproassist.com/',
  cpaMainService: 'http://cpa-mico-cluster.info:30219/',
  appointmentsService: 'http://cpa-mico-cluster.info:32619/',
  jwtService: 'http://cpa-mico-cluster.info:31633/',
  clientService: 'http://cpa-mico-cluster.info:30843/',
  firebaseConfig: {
    apiKey: "AIzaSyBkKogSIR3BlfrE7fLCUSoc9H3gfJhtE7Q",
    authDomain: "cpa-dev-notifications.firebaseapp.com",
    projectId: "cpa-dev-notifications",
    storageBucket: "cpa-dev-notifications.appspot.com",
    messagingSenderId: "119660911040",
    appId: "1:119660911040:web:aa6d757fd7160f4c47d025",


    }


};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


