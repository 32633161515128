import {Component, Input, OnInit} from '@angular/core';
import {Labels} from '../util/labels';
import {ConstantService} from '../web-services/constant.service';
import {SharedService} from '../web-services/shared.service';

@Component({
               selector: 'app-custom-error',
               templateUrl: './error.component.html',
               styleUrls: ['./error.component.css']
           })
export class ErrorComponent implements OnInit {

    @Input() form;
    @Input() control;
    public labels = Labels;
    constantList = [];

    constructor(private constantService: ConstantService, private sharedService: SharedService) {
    };

    ngOnInit() {
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

}
