import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MenuItem, MessageService, PrimeNGConfig} from 'primeng/api';
import {SessionService} from "../services/session.service";
import {InitialAssessmentSteps} from "../initial-assessment-steps/InitialAssessmentSteps";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  urlCalled = false;
  activeStep: number;
  stepsItems: any;
  loggedInUserType: any;
  loggedInRefId: any;
  loggedInUser: any;
  patientId: any;
  expertId: any;
  appointmentId: any;
  caseId;
  patientList: any;
  totalPatients: any;
  patientObj: any;



  constructor(
      private router: Router,
      private sessionService: SessionService

) { }

  ngOnInit(): void {
    if (this.router.url.includes('/history')) {
        this.urlCalled = true;
    }

    this.loggedInUser = parseInt(this.sessionService.getSession('loggedInUser'));
    this.expertId = parseInt(this.sessionService.getSession('expertId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;



    this.stepsItems = InitialAssessmentSteps.stepItems;
    this.activeStep = parseInt(this.stepsItems.find(step => step.route === 'history').number);
  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }
}
