import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataSharedService} from '../../services/data-shared.service';
import {NonInterceptService} from '../../services/non-intercept.service';
import {SessionService} from '../../services/session.service';
import {LocalService} from '../../services/local.service';

@Component({
               selector: 'app-logout',
               templateUrl: './logout.component.html',
               styleUrls: ['./logout.component.css']
           })
export class LogoutComponent implements OnInit, OnDestroy {

    constructor(
        private localService: LocalService,
        private dataSharedService: DataSharedService,
        private sessionService: SessionService,
        private nonInterceptService: NonInterceptService
    ) {
    }

    ngOnInit() {


        if (sessionStorage.getItem('loggedInUser') !== undefined || this.sessionService.getCookie('loggedInUser') !== undefined) {

       //    if (sessionStorage.getItem('loggedInUser') || this.sessionService.getCookie('loggedInUser')) {
            // debugger;
            this.updateUserStatus();
            // clearInterval();
        }
    }

    updateUserStatus(): void {
      this.dataSharedService.checkIsUserLoggedIn(false);
      this.localService.logout();
      sessionStorage.clear();
       /* this.nonInterceptService.updateUserStatus(0).subscribe((data: string) => {
            this.dataSharedService.checkIsUserLoggedIn(false);
            this.localService.logout();
            sessionStorage.clear();
        });*/
    }

    ngOnDestroy(): void {
        sessionStorage.clear();
        this.sessionService.deleteAllCookies();
    }

}
