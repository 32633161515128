import { Component, OnInit } from '@angular/core';
import {Labels} from '../util/labels';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {SessionService} from '../services/session.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InitialAssessmentSteps} from '../initial-assessment-steps/InitialAssessmentSteps';
import {ConstantService} from '../web-services/constant.service';
import {SharedService} from '../web-services/shared.service';
import {DiagnosisService} from '../web-services/diagnosis.service';
import {InitialHistoryFormDTO} from "../dto/InitialHistoryFormDTO";
import {FormDataDTO} from "../dto/FormDataDTO";

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css']
})
export class DiagnosisComponent implements OnInit {

  public labels = Labels;
  formButton: string = this.translate(this.labels.btnAdd);
  submitted = false;

  expertId: number;
  patientId: number;
  appointmentId: number;
  caseId: number;
  diagnosisForm: FormGroup;
  stepsItems: any;
  activeStep: number;
  constantList: any;
  addEditTitle = '';
  showAddBtn =  false;
  showSearchBtn = false;
  showSearchDialog = true;
  showAddDialog = false;
  editTag = false;
  disableColumn = false;
  diagnosisList = [];
  patientObj; any;
  caseDiagnosisPredictionsList = [];
  selectedPredictedDiagnosis = 0;



  constructor(private primengConfig: PrimeNGConfig,
              private confirmation: ConfirmationService,
              private messageService: MessageService,
              private router: Router,
              private loader: NgxUiLoaderService,
              private sessionService: SessionService,
              private constantService: ConstantService,
              private sharedService: SharedService,
              private diagnosisService: DiagnosisService
  ) { }

  ngOnInit(): void {

    this.addEditTitle = this.labels.btnAdd;

    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;

    this.diagnosisForm = new FormGroup({

                                diagnosis: new FormControl('', [Validators.required])
    });

    this.primengConfig.ripple = true;
    this.stepsItems = InitialAssessmentSteps.stepItems;
    this.activeStep = this.stepsItems.find(step => step.route === 'diagnosis').number;
    this.getConstantList(1234);
    this.getDiagnosisList();
    this.getCaseDiagnosisPredictionsByCaseId();

  }

  getCaseDiagnosisPredictionsByCaseId(): void {

      this.diagnosisService.getCaseDiagnosisPredictionsByCaseId(this.caseId).subscribe(res => {

        if (res.status === 200) {

          this.caseDiagnosisPredictionsList = res.body;
        }
      });


  }

  onSubmit(): void {

    this.diagnosisForm.markAllAsTouched();

    const patientDiagnosisFormDTO = new FormDataDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, {}, this.diagnosisForm);

    if (!patientDiagnosisFormDTO.hasMultiSelectErrors) {

      this.diagnosisService.createPatientDiagnosis(patientDiagnosisFormDTO).subscribe(res => {

        if (res.status === 200) {

          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameDiagnosis) +
              this.translate(this.labels.addedSuccessfully),
          });

          this.submitted = true;
          this.showHideAddDialog();
        } else {

          alert('eror');
        }

      });


    }
  }

  diagnosisSelect(diagnosisId: number): void {
    this.diagnosisForm.controls['diagnosis'].setValue(diagnosisId);

    this.selectedPredictedDiagnosis = diagnosisId;

  }
  urlChanged(stepNumber: number): void {

    const url = this.stepsItems.find(step => step.number === stepNumber).route;
    this.router.navigate([url]);
  }

  translate(label): any {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

  getDiagnosisList(): void {

    this.diagnosisService.findAllDiagnoses().subscribe(res => {

      if (res.status === 200) {

        this.diagnosisList = res.body;

      }


    });



  }


  getConstantList(formId): void {
    this.constantService.getFormConstantsList(formId).subscribe(data => {
      if (data['statusCode'] === 200) {

        this.constantList = data['data'];
      } else {
        this.constantList = [];
      }
    });
  }

  showHideAddDialog(): void {
    if (!this.showAddDialog) {

      this.diagnosisForm.reset();
    }
    this.addEditTitle = this.labels.btnAdd;
    this.showAddDialog = !this.showAddDialog;
    this.showAddBtn = this.showAddDialog;
    this.showSearchBtn = this.showAddDialog;
    this.editTag = false;
  }

}
