
<div class="cpaToolsTopHeading comm-accordion-table-headings">
    <!-- Using CPA Buttonsand Search CSS -->
    <h3 class="cpaToolsTitleBlue cpaToolsTitle">{{communicationType['title']}} Details</h3>
    <span class="p-input-icon-right">
        <i class="pi pi-search"></i>
        <input class="cpaToolsSearch" type="search" placeholder="Search.." pInputText
            (input)="dt.filterGlobal($event.target.value, 'contains')" />
    </span>
</div>
<p-table #dt [value]="value" [columns]="cols"
    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'" [rows]="10" [paginator]="true"
    [resizableColumns]="true" [autoLayout]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]=true
    [totalRecords]="value.length" styleClass="p-datatable-striped" class="comm-table tablePaginator">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <ng-container *ngFor="let col of columns">
                <td *ngIf="col.field != 'action' && col.field != 'insertionTimestamp'"> {{rowData[col.field]}}</td>
                <td *ngIf="col.field == 'insertionTimestamp'"> {{returnDateFromMilliSecond(rowData[col.field])}}</td>
                <td *ngIf="col.field == 'action'">
                    <div class="table-icons">
                    <!-- <p-button label="View" icon="pi pi-eye"
                      styleClass="p-button pbtn-transparent">
                    </p-button> -->
                    <p-button (click)="delete(rowData)" pTooltip="Delete" icon="pi pi-trash"
                      styleClass="p-button pbtn-transparent">
                    </p-button>
                  </div>
                </td>

            </ng-container>


            <!-- <ng-container *ngIf="col.field == 'action'">
                
              </ng-container> -->

        </tr>

    </ng-template>
</p-table>