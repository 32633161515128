<section class="sectionmain" id="sectionmain">
    <div class="container">
        <p-card header="" styleClass="p-card-shadow"  class="subs-balance-card">
            <div class="subscriptions-payment subs-balance">


                    <!-- Tab Content Starts Here -->
                    <div class="p-grid">
                        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                            <div class="adminCard adminCardBlue">
                              <ng-template pTemplate="header">
                              </ng-template>
                              <div class="dashboard-card-content noNumberCard">
                                <img src="assets/images/superAdminDashboard/Patient-case.png">
                                <div class="dashboard-card-text">
                                  <p>Annual Run Rate</p>
                                  <h3 class="dashboardText">£49, 373</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                            <div class="adminCard adminCardBlue">
                              <ng-template pTemplate="header">
                              </ng-template>
                              <div class="dashboard-card-content noNumberCard">
                                <img src="assets/images/superAdminDashboard/Patient-case.png">
                                <div class="dashboard-card-text">
                                  <p>Average Revenue</p>
                                  <h3 class="dashboardText">£49</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>

                    <div class="p-col-12 barDataGraph">

                        <div class="chartsHeading">
                           Subscription Balance
                        </div>
                        <!-- <p-card> -->
                        <div class="chart-wrapper">
                           <p-chart type="bar" [data]="subsBalance" [options]="options" [responsive]="chartSmall"></p-chart>
                        </div>
                       <!-- </p-card> -->
                     </div>
            </div>
            <!-- </div> -->
            <!-- </section> -->
        </p-card>

    </div>
</section>
