import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {CategoriesService} from '../web-services/categories.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
    selector: 'app-list-of-values',
    templateUrl: './list-of-values.component.html',
    styleUrls: ['./list-of-values.component.css']
})
export class ListOfValuesComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateListOfValuesId = null;
    categoriesList: any = [];
    listOfValuesList: any = [];
    listOfValuesSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    listOfValuesForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private listOfValuesServices: ListOfValuesService,
        private categoriesServices: CategoriesService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateListOfValuesId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.listOfValuesSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countListOfValues();
        this.findAllListOfValues();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countListOfValues() {
        this.listOfValuesServices.countListOfValues(this.listOfValuesSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllListOfValues();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.listOfValuesForm.markAllAsTouched();
        this.listOfValuesForm.controls['label'].setValue(this.listOfValuesForm.controls['value'].value);

        console.log(this.listOfValuesForm.value);

        if(this.listOfValuesForm.valid){
            this.addUpdateListOfValues(this.listOfValuesForm.value);
            this.submitted = false;
            this.onClearAdd();
        }

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllListOfValues();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.listOfValuesSearch.reset();
        this.countListOfValues();
        // this.findAllListOfValues();
    }

    addUpdateListOfValues(listOfValues) {
        this.startLoader();
        if (this.updateListOfValuesId == null) {
            this.listOfValuesServices.addListOfValues(this.listOfValuesForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    listOfValues = res['data'][0];
                    this.listOfValuesList.push(listOfValues);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: 'List of Value Added Successfully',
                            detail: ''
                        });
                        this.showHideAddDialog();
                        this.countListOfValues();
                        this.submitted = true;

                } else {
                    this.stopLoader();
                    console.error(res['documentation']);
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            listOfValues.listOfValuesId = this.updateListOfValuesId;
            this.listOfValuesServices.updateListOfValues(listOfValues).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.listOfValuesList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: 'List of Value Updated Successfully',
                                                detail: ''
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateListOfValuesId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllCategories();
        this.listOfValuesForm = new FormGroup({
                                                  categoryId: new FormControl('',[Validators.required]),
                                                  value: new FormControl('', [Validators.required, validateSpaces]),
                                                  label: new FormControl(),
                                                  userId: new FormControl(),
            isRedFlag: new FormControl(false),
            isYellowFlag: new FormControl(false),
            isOrangeFlag: new FormControl(false),
            isBlueFlag: new FormControl(false),
            isGreenFlag: new FormControl(false),
            isPartOfInitialAssessment: new FormControl(false),
            isPartOfFollowUp: new FormControl(false),
                                              });
        this.listOfValuesSearch = new FormGroup({
                categoryId: new FormControl(),
                value: new FormControl(),
                label: new FormControl(),
                userId: new FormControl(),});
        this.countListOfValues();
        // this.findAllListOfValues();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.listOfValuesSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllListOfValues() {
        this.startLoader();
        this.listOfValuesServices.findAllListOfValues(this.listOfValuesSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.listOfValuesList = res['data'];
                    console.log(res['data']);

                } else {
                    this.listOfValuesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdListOfValues(listOfValuesId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.listOfValuesServices.deleteByIdListOfValues(listOfValuesId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.listOfValuesList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: 'List of Value Deleted Successfully',
                                                    detail: ''
                                                });
                        this.countListOfValues();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadListOfValuesToEdit(listOfValuesId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let listOfValues = this.listOfValuesList.find(x => x['listOfValuesId'] == listOfValuesId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateListOfValuesId = listOfValues['listOfValuesId'];
        this.selectedIndex = index;
        if(listOfValues['categoryId']){
            this.listOfValuesForm.controls['categoryId'].setValue(listOfValues['categoryId']['categoryId']);
        }
        if(listOfValues['value']){
            this.listOfValuesForm.controls['value'].setValue(listOfValues['value']);
        }
        if(listOfValues['label']){
            this.listOfValuesForm.controls['label'].setValue(listOfValues['label']);
        }
        if(listOfValues['userId']){
            this.listOfValuesForm.controls['userId'].setValue(listOfValues['userId']);
        }

      if(listOfValues['isPartOfInitialAssessment']){
        this.listOfValuesForm.controls['isPartOfInitialAssessment'].setValue(listOfValues['isPartOfInitialAssessment']);
      }

      if(listOfValues['isRedFlag']){
        this.listOfValuesForm.controls['isRedFlag'].setValue(listOfValues['isRedFlag']);
      }


      if(listOfValues['isBlueFlag']){
        this.listOfValuesForm.controls['isBlueFlag'].setValue(listOfValues['isBlueFlag']);
      }


      if(listOfValues['isOrangeFlag']){
        this.listOfValuesForm.controls['isOrangeFlag'].setValue(listOfValues['isOrangeFlag']);
      }


      if(listOfValues['isYellowFlag']){
        this.listOfValuesForm.controls['isYellowFlag'].setValue(listOfValues['isYellowFlag']);
      }


      if(listOfValues['isGreenFlag']){
        this.listOfValuesForm.controls['isGreenFlag'].setValue(listOfValues['isGreenFlag']);
      }




      if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
        // openAddOverlaydiv();
    }

    onClearAdd() {
        this.listOfValuesForm.reset();
        this.listOfValuesForm.markAsUntouched();
        this.updateListOfValuesId = null;
        closeAddOverlaydiv();
    }

    findAllCategories() {
        let temp = [];
        this.startLoader();
        this.categoriesServices.findAllCategories(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['categoryId'];
                        dropDown.label = object['categoryTitle'];
                        this.categoriesList.push(dropDown);
                    }
                } else {
                    this.categoriesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.listOfValuesForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.listOfValuesForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.listOfValuesForm.get(control)?.valid && this.listOfValuesForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.listOfValuesForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.listOfValuesSearch.reset();
    }

    refresh() {
        this.countListOfValues();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.listOfValuesForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.listOfValuesForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateListOfValuesId = null;
    }
}
