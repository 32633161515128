import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesMasterService} from '../web-services/list-of-values-master.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-list-of-values-detail',
               templateUrl: './list-of-values-detail.component.html',
               styleUrls: ['./list-of-values-detail.component.css']
           })
export class ListOfValuesDetailComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateListOfValuesDetailId = null;
    listOfValuesMasterList: any = [];
    listOfValuesList: any = [];
    listOfValuesDetailList: any = [];
    listOfValuesDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    listOfValuesDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private listOfValuesDetailServices: ListOfValuesDetailService, private listOfValuesMasterServices: ListOfValuesMasterService,
        private listOfValuesServices: ListOfValuesService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateListOfValuesDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.listOfValuesDetailSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countListOfValuesDetail();
        this.findAllListOfValuesDetail();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countListOfValuesDetail() {
        this.listOfValuesDetailServices.countListOfValuesDetail(this.listOfValuesDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllListOfValuesDetail();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {


        this.addUpdateListOfValuesDetail(this.listOfValuesDetailForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllListOfValuesDetail();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.listOfValuesDetailSearch.reset();
        this.countListOfValuesDetail();
        // this.findAllListOfValuesDetail();
    }

    addUpdateListOfValuesDetail(listOfValuesDetail) {
        this.startLoader();
        if (this.updateListOfValuesDetailId == null) {
            this.listOfValuesDetailServices.addListOfValuesDetail(this.listOfValuesDetailForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    listOfValuesDetail = res['data'][0];
                    this.listOfValuesDetailList.push(listOfValuesDetail);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnAdd),
                                                detail: this.translate(this.labels.entityNameListOfValuesDetail)
                                            });
                    this.submitted = true;
                } else {
                    console.error(res['documentation']);
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            listOfValuesDetail.listOfValuesDetailId = this.updateListOfValuesDetailId;
            this.listOfValuesDetailServices.updateListOfValuesDetail(listOfValuesDetail).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.listOfValuesDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameListOfValuesDetail)
                                            });
                    this.showSearchBtn = true;
                    this.showAddBtn = true;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateListOfValuesDetailId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllListOfValuesMaster();
        this.findAllListOfValues();
        this.listOfValuesDetailForm = new FormGroup({
                                                        listOfValuesMasterId: new FormControl(),
                                                        listOfValuesId: new FormControl(),

                                                        referenceType: new FormControl('', [Validators.required, validateSpaces]),
                                                        referenceId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        lovValues: new FormControl('', [Validators.required, validateSpaces]),
                                                        userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                    });
        this.listOfValuesDetailSearch = new FormGroup({
                                                          listOfValuesMasterId: new FormControl(),
                                                          listOfValuesId: new FormControl(),
                                                          referenceType: new FormControl(),
                                                          referenceId: new FormControl(),
                                                          lovValues: new FormControl(),
                                                          userId: new FormControl(),
                                                      });
        this.countListOfValuesDetail();
        this.findAllListOfValuesDetail();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.listOfValuesDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllListOfValuesDetail() {
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(
            this.listOfValuesDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.listOfValuesDetailList = res['data'];
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdListOfValuesDetail(listOfValuesDetailId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.listOfValuesDetailServices.deleteByIdListOfValuesDetail(listOfValuesDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.listOfValuesDetailList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.btnDelete),
                                                    detail: this.translate(this.labels.entityNameListOfValuesDetail)
                                                });
                        this.countListOfValuesDetail();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadListOfValuesDetailToEdit(listOfValuesDetailId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let listOfValuesDetail = this.listOfValuesDetailList.find(x => x['listOfValuesDetailId'] == listOfValuesDetailId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateListOfValuesDetailId = listOfValuesDetail['listOfValuesDetailId'];
        this.selectedIndex = index;
        this.listOfValuesDetailForm.controls['listOfValuesMasterId'].setValue(
            listOfValuesDetail['listOfValuesMasterId']['listOfValuesMasterId']);
        this.listOfValuesDetailForm.controls['listOfValuesId'].setValue(listOfValuesDetail['listOfValuesId']['listOfValuesId']);
        this.listOfValuesDetailForm.controls['referenceType'].setValue(listOfValuesDetail['referenceType']);
        this.listOfValuesDetailForm.controls['referenceId'].setValue(listOfValuesDetail['referenceId']);
        this.listOfValuesDetailForm.controls['lovValues'].setValue(listOfValuesDetail['lovValues']);
        this.listOfValuesDetailForm.controls['userId'].setValue(listOfValuesDetail['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.listOfValuesDetailForm.reset();
        this.listOfValuesDetailForm.markAsUntouched();
        this.updateListOfValuesDetailId = null;
        closeAddOverlaydiv();
    }

    findAllListOfValuesMaster() {
        let temp = [];
        this.startLoader();
        this.listOfValuesMasterServices.findAllListOfValuesMaster(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesMasterId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesMasterList.push(dropDown);
                    }
                } else {
                    this.listOfValuesMasterList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllListOfValues() {
        let temp = [];
        this.startLoader();
        this.listOfValuesServices.findAllListOfValues(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['value'];
                        this.listOfValuesList.push(dropDown);
                    }
                } else {
                    this.listOfValuesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.listOfValuesDetailForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.listOfValuesDetailForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.listOfValuesDetailForm.get(control)?.valid && this.listOfValuesDetailForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.listOfValuesDetailForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.listOfValuesDetailSearch.reset();
    }

    refresh() {
        this.countListOfValuesDetail();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.listOfValuesDetailForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.listOfValuesDetailForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateListOfValuesDetailId = null;
    }
}
