import {Injectable} from '@angular/core';

@Injectable({
                providedIn: 'root'
            })
export class CollabSessionService {

    private _isContexualTab: boolean = false;
    private _chatId;
    private _isAddParticipantClicked: boolean = false;
    private _isLoggedInUser: boolean = false;
    private _isSingleChat: boolean = false;
    private _groupId;
    // private _groupTitle;
    private _status;
    private _userLog: any;
    private _username;
    private _lastName;
    private _firstName;
    private _secondUserId;
    private _isNewChatButtonClicked: boolean = false;
    private _contextId = 0;
    private _contextTitle = '';

    public get isContexualTab(): boolean {
        return this._isContexualTab;
    }

    public set isContexualTab(value: boolean) {
        this._isContexualTab = value;
    }

    public get userLog() {
        return this._userLog;
    }

    public set userLog(value) {
        this._userLog = value;
    }

    public get chatId() {
        return this._chatId;
    }

    public set chatId(value) {
        this._chatId = value;
    }

    public get isAddParticipantClicked(): boolean {
        return this._isAddParticipantClicked;
    }

    public set isAddParticipantClicked(value: boolean) {
        this._isAddParticipantClicked = value;
    }

    public get isLoggedInUser(): boolean {
        return this._isLoggedInUser;
    }

    public set isLoggedInUser(value: boolean) {
        this._isLoggedInUser = value;
    }

    public get isSingleChat(): boolean {
        return this._isSingleChat;
    }

    public set isSingleChat(value: boolean) {
        this._isSingleChat = value;
    }

    public get groupId() {
        return this._groupId;
    }

    public set groupId(value) {
        this._groupId = value;
    }

    // public get groupTitle() {
    //   return this._groupTitle;
    // }
    // public set groupTitle(value) {
    //   this._groupTitle = value;
    // }

    public get status() {
        return this._status;
    }

    public set status(value) {
        this._status = value;
    }

    public get username() {
        return this._username;
    }

    public set username(value) {
        this._username = value;
    }


    public get lastName() {
        return this._lastName;
    }

    public set lastName(value) {
        this._lastName = value;
    }

    public get firstName() {
        return this._firstName;
    }

    public set firstName(value) {
        this._firstName = value;
    }

    public get secondUserId() {
        return this._secondUserId;
    }

    public set secondUserId(value) {
        this._secondUserId = value;
    }

    public get isNewChatButtonClicked(): boolean {
        return this._isNewChatButtonClicked;
    }

    public set isNewChatButtonClicked(value: boolean) {
        this._isNewChatButtonClicked = value;
    }

    public get contextId() {
        return this._contextId;
    }

    public set contextId(value) {
        this._contextId = value;
    }

    public get contextTitle() {
        return this._contextTitle;
    }

    public set contextTitle(value) {
        this._contextTitle = value;
    }


    constructor() {
    }
}
