<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deletePosture" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNamePosture)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNamePosture)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePosture)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="postureSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.postureColumnPostureComment)}}</label> <span
                                                class="p-input-icon-right"><i
                                                (click)="fieldReset('postureComment')"
                                                *ngIf="diplayeIconInField('postureComment')"
                                                class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                                                             formControlName="postureComment"
                                                                                             name="postureComment"
                                                                                             pInputText
                                                                                             type="text"> </span></div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.postureColumnGaitComment)}}</label>
                                            <span
                                                class="p-input-icon-right">
                                                <i (click)="fieldReset('gaitComment')"
                                                    *ngIf="diplayeIconInField('gaitComment')"
                                                    class="pi pi-times color-danger">
                                                </i>
                                            <input   class="pngInputtext"
                                                     formControlName="gaitComment"
                                                     name="gaitComment"
                                                     pInputText
                                                     type="text">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <form (ngSubmit)="onSubmit()" [formGroup]="postureForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <!-- Posture Description -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['postureDescription'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['postureDescription'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['postureDescription'].currentSelected"></app-lov-details>
                                          <span id="#postureDescriptionError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>

                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.postureColumnPostureComment)}}</label> <span
                                                class="p-input-icon-right"><i
                                                (click)="fieldReset('postureComment')"
                                                *ngIf="diplayeIconInField('postureComment')"
                                                class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('postureComment')"
                                                class="pngInputtext"
                                                formControlName="postureComment"
                                                name="postureComment"
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'postureComment'" [form]="postureForm"></app-custom-error>
                                        </div>

                                        <!-- Gait Description -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">

                                          <app-lov-details [categoryString]="objectMultiSelects['gaitDescription'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['gaitDescription'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['gaitDescription'].currentSelected"></app-lov-details>
                                          <span id="#gaitDescriptionError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.postureColumnGaitComment)}}</label> <span
                                                class="p-input-icon-right"><i
                                                (click)="fieldReset('gaitComment')"
                                                *ngIf="diplayeIconInField('gaitComment')"
                                                class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('gaitComment')" class="pngInputtext"
                                                formControlName="gaitComment"
                                                name="gaitComment"
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'gaitComment'" [form]="postureForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext"
                                               pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="postureList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': postureList?.length == 0}" id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Date</th>
                                            <th> Posture Desc</th>
                                            <th> Posture Comment</th>
                                            <th> Gait Desc</th>
                                            <th> Gait Comment</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                          <td> {{element?.insertionTimestamp | date: "dd-MM-YYYY" }} </td>
                                          <td> {{element?.postureDescription[0].lovValues}} </td>
                                          <td> {{element?.postureComment}} </td>
                                          <td> {{element?.gaitDescription[0].lovValues}} </td>
                                          <td> {{element?.gaitComment}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadPostureToEdit(element.postureId, i)"
                                                              icon="pi pi-pencil" pTooltip="Edit"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdPosture(element.postureId,i)"
                                                              icon="pi pi-trash" pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
