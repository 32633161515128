import { Component, OnInit } from '@angular/core';
import {OnboardingSteps} from '../onboarding-steps/OnboardingSteps';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../dto/User';
import {SessionService} from '../services/session.service';
import {ClientDetailsService} from '../web-services/client-details.service';
import {HttpStatusCode} from '@angular/common/http';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-admin-landing-page',
  templateUrl: './admin-landing-page.component.html',
  styleUrls: ['./admin-landing-page.component.css']
})
export class AdminLandingPageComponent implements OnInit {

  constructor(private router: Router,
              private sessionService: SessionService,
              private clientService: ClientDetailsService,
              private messageService: MessageService
              ) { }

  stepsItems: any;
  activeStep: number;
  clientDetailsForm: FormGroup;
  fullAuthenticatedUser: User;
  clientId: number;
  clientInformation: any;


  ngOnInit(): void {

    this.fullAuthenticatedUser =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser'));

    this.clientId = this.fullAuthenticatedUser.clientId;



  this.stepsItems = OnboardingSteps.stepItems;
    this.clientDetailsForm = new FormGroup({
      companyName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
    });

    this.findClientInformation();

  }

  urlChanged(stepNumber: number): void {

    const url = this.stepsItems.find(step => step.number === stepNumber).route;
    this.router.navigate([url]);
  }

  findClientInformation(): void {

    this.clientService.findClientById(this.clientId).subscribe(
      (response) => {

        if (response.status === HttpStatusCode.Ok) {

          this.clientInformation = response.body;

          this.clientDetailsForm.controls['companyName'].setValue(this.clientInformation.companyName);
          this.clientDetailsForm.controls['email'].setValue(this.clientInformation.email);
          this.clientDetailsForm.controls['address1'].setValue(this.clientInformation.address1);
          this.clientDetailsForm.controls['address2'].setValue(this.clientInformation.address2);
          this.clientDetailsForm.controls['city'].setValue(this.clientInformation.city);
          this.clientDetailsForm.controls['postcode'].setValue(this.clientInformation.postcode);

        }

      });
  }

  onSubmit(): void {


    let fieldsToUpdate = [{
      "op": "replace",
      "path": "/" + "companyName",
      "value": this.clientDetailsForm.controls['companyName'].value
    },
      {
        "op": "replace",
        "path": "/" + "email",
        "value": this.clientDetailsForm.controls['email'].value
      },
      {
        "op": "replace",
        "path": "/" + "address1",
        "value": this.clientDetailsForm.controls['address1'].value
      },
      {
        "op": "replace",
        "path": "/" + "address2",
        "value": this.clientDetailsForm.controls['address2'].value
      },
      {
        "op": "replace",
        "path": "/" + "city",
        "value": this.clientDetailsForm.controls['city'].value
      },
      {
        "op": "replace",
        "path": "/" + "postcode",
        "value": this.clientDetailsForm.controls['postcode'].value
      },
      {
        "op": "replace",
        "path": "/" + "onboarding_complete",
        "value": true
      },
    ];

    alert(fieldsToUpdate);

    this.clientService.updateClientViaPatch(this.clientId, fieldsToUpdate).subscribe(
      (response) => {

          if (response.status === HttpStatusCode.Ok) {


            this.messageService.add({
              severity: 'success',
              summary: 'Client Updated ',
            });
          }

      },
      (error) => {

          this.messageService.add({
            severity: 'error',
            summary: 'Client Update Failed ',
          });
      });

    alert('Form submitted successfully');

  }


}
