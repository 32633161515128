import { Component, OnInit } from '@angular/core';
import {Labels} from "../util/labels";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Constant} from '../util/constant';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ExpensesService} from "../web-services/expenses.service";
import {ClinicsService} from "../web-services/clinics.service";
import {ConfirmationService, MessageService, PrimeNGConfig} from "primeng/api";
import {Router} from "@angular/router";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {ConstantService} from "../web-services/constant.service";
import {SharedService} from "../web-services/shared.service";
import {ListOfValuesService} from "../web-services/list-of-values.service";
import {AgencyService} from "../web-services/agency.service";
import {CpaRoleService} from "../web-services/cpa-role-service";
import {validateSpaces} from "../util/custom.validator";
import {ApplicationRoleService} from "../web-services/application-role.service";
import {CpaApplicationRoleService} from "../web-services/cpa-application-role.service";


@Component({
  selector: 'app-cpa-roles',
  templateUrl: './cpa-roles.component.html',
  styleUrls: ['./cpa-roles.component.css'],
  providers: [DialogService]
})
export class CpaRolesComponent implements OnInit {

  public labels = Labels;
  formButton: string = this.translate(this.labels.btnAdd);
  submitted = false;
  selectedIndex = -1;
  updateCpaRoleId = null;
  clinicsList: any = [];
  cpaRolesList: any = [];
  expensesSearch: FormGroup;
  clearSearchBtn = false;
  totalRecords;
  itemPerPage: number = Constant.ITEM_PER_PAGE;
  cpaRoleForm: FormGroup;
  constantList = [];
  pageNo: number = 0;
  onValueChange = true;
  ref: DynamicDialogRef;
  currentLoggedInAgencyId: any = 0;

  showSearchDialog = false;
  showSearchBtn = false;
  showAddDialog = false;
  showAddBtn = false;
  editTag = false;
  disableColumn = false;
  viewCpaApplicationRoles = false;
  applicationRoles: any = [];
  selectedCpaRoleId: number;
  selectedCpaApplicationRoles: any = [];

  addEditTitle = 'Add';
  options  =  [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];

  referenceId = 0;

  title: any;

  constructor(
    private expensesServices: ExpensesService,
    private clinicsServices: ClinicsService,
    private primengConfig: PrimeNGConfig,
    private confirmation: ConfirmationService,
    private messageService: MessageService,
    private route: Router,
    private loader: NgxUiLoaderService,
    private constantService: ConstantService,
    private sharedService: SharedService,
    private lovService: ListOfValuesService,
    private agencyService: AgencyService,
    private dialogService: DialogService,
    private cpaRoleService: CpaRoleService,
    private applicationRoleService: ApplicationRoleService,
    private cpaApplicationRoleService: CpaApplicationRoleService

  ) {




  }

  ngOnInit(): void {

    this.referenceId = parseInt(sessionStorage.getItem('loggedInRefId'));


    this.cpaRoleForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl(0),
    });
    this.findAllCpaRoles();
    this.findAllApplicationRoles();
  }

  translate(label) {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

  showHideAddDialog(): void {
    if (!this.showAddDialog) {
      this.cpaRoleForm.reset();
    }
    this.addEditTitle = 'Add';
    this.showAddDialog = !this.showAddDialog;
    this.showAddBtn = this.showAddDialog;
    this.showSearchBtn = this.showAddDialog;
    this.editTag = false;
  }

  onSubmit(): void {
    this.cpaRoleForm.markAllAsTouched();
    console.log('cpaRoleForm form value :: ', this.cpaRoleForm.value);
    if (this.cpaRoleForm.valid) {

      this.addUpdateCpaRole(this.cpaRoleForm.value);
      this.submitted = false;

    }

  }

  startLoader() {
    this.loader.start();
  }

  stopLoader() {
    this.loader.stop();
  }

  findAllCpaRoles() {
    this.startLoader();
    this.cpaRoleService.findAllLiveCpaRoles().subscribe(
      res => {
        if (res.status === 200) {
          this.cpaRolesList = res.body;
        } else {
          this.cpaRolesList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  findAllApplicationRoles() {

    this.startLoader();
    this.applicationRoleService.findAllApplicationRoles().subscribe(
      response => {

        if (response.status === 200) {

          this.applicationRoles = response.body;
        }
        this.stopLoader();
    }, err => {
        alert(err);

        console.error('err: ', err);
        this.stopLoader();
      });
  }
  editCpaApplicationRoles(cpaRoleId) {

    this.selectedCpaRoleId = cpaRoleId;

    this.viewCpaApplicationRoles = true;



  }

  selectApplicationRole(applicationRole: any, index: number, element: any, event: any) {

    if (applicationRole.value) {

        const cpaApplicationRole = {

            cpaRoleId: {
              cpaRoleId: this.selectedCpaRoleId,
            },
            applicationRoleId:  {
              applicationRoleId: applicationRole.applicationRoleId
            }
        };

        this.selectedCpaApplicationRoles.push(cpaApplicationRole);

    }
    else {

      this.selectedCpaApplicationRoles = this.selectedCpaApplicationRoles.filter(cpaApplicationRole => cpaApplicationRole.applicationRoleId === applicationRole.applicationRoleId);

    }
  }

  saveCpaApplicationRoles(event: any): any {

      this.startLoader();
      this.cpaApplicationRoleService.createCpaApplicationRoles(this.selectedCpaApplicationRoles).subscribe(res => {

        if (res.status === 201) {

          alert('sucesss created');

        }

      }, err => {

  this.messageService.add(
    {
      severity: 'error',
      summary: "opps something went wrong",
    });
  console.error('err: ', err);
  this.stopLoader();
});

  }

  addUpdateCpaRole(cpaRole) {
    this.startLoader();

    if (this.updateCpaRoleId == null) { console.log(cpaRole);
      this.cpaRoleService.addCpaRole(cpaRole).subscribe(res => {
        console.log(res);

        if (res.status === 201) {
          cpaRole = res.body;
         // this.expensesList.push(expenses);
          this.messageService.add(
            {
              severity: 'success',
              summary: this.translate(this.labels.entityNameCpaRole) +
                this.translate(this.labels.addedSuccessfully),
              // detail: this.translate(this.labels.entityNameExpenses)
            });
          this.submitted = true;
          this.showHideAddDialog();
          this.findAllCpaRoles();
        } else {
          console.error("error");
          this.stopLoader();
        }
      }, err => {

        this.messageService.add(
          {
            severity: 'error',
            summary: "opps something went wrong",
          });
        console.error('err: ', err);
        this.stopLoader();
      });
    } else {
      /*obj.expensesId = this.updateExpensesId;
      this.expensesServices.updateExpenses(obj).subscribe(res => {
        if (res['message'] == 'Success') {
          this.expensesList[this.selectedIndex] = res['data'][0];
          this.submitted = true;
          this.messageService.add(
            {
              severity: 'success',
              summary: this.translate(this.labels.entityNameExpenses) +
                this.translate(this.labels.updatedSuccessfully),
              // detail: this.translate(this.labels.entityNameExpenses)
            });
          this.showSearchBtn = false;
          this.showAddBtn = false;
          this.showSearchDialog = false;
          this.showAddDialog = false;
          this.editTag = false;
          this.refresh();
        } else {
          console.error(res['documentation']);
          this.stopLoader();
        }
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
      this.updateExpensesId = null;
      this.formButton = this.translate(this.labels.btnAdd);*/
    }
    // this.stopLoader();
  }

  loadCpaRoleToEdit(cpaRoleId: number, index) {
    this.addEditTitle = this.labels.btnEdit;
    let cpaRole = this.cpaRolesList.find(x => x['cpaRoleId'] == cpaRoleId);
    this.submitted = null;
    this.formButton = this.translate(this.labels.btnEdit);
    this.updateCpaRoleId = cpaRole['cpaRoleId'];
    this.selectedIndex = index;

    if (cpaRole['title']) {
      this.cpaRoleForm.controls['title'].setValue(cpaRole['title']);
    }

    if (cpaRole['description']) {
      this.cpaRoleForm.controls['description'].setValue(cpaRole['description']);
    }

    // by ali
    if (this.showAddDialog === false && this.editTag === false) {
      this.editTag = true;
      this.showAddDialog = true;
      this.showAddBtn = true;
      this.showSearchBtn = true;
      this.showSearchDialog = false;
    } else if (this.showAddDialog === true && this.editTag === false) {
      this.editTag = true;
      this.showAddBtn = true;
      this.showSearchBtn = true;
      this.showSearchDialog = false;
    } else if (this.showAddDialog === true && this.editTag === true) {
      this.editTag = false;
      this.showAddDialog = false;
      this.showAddBtn = false;
      this.showSearchBtn = false;
      this.showSearchDialog = false;
    }
    this.disableColumn = true;
  }

  pageControlMap = new Map<string, boolean>()
    .set("viewRoles", false)
    .set("addRole", false)
    .set("deleteRole", false)
    .set("editRole", false);

}
