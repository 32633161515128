import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from "../services/session.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-expert-landing-page',
  templateUrl: './expert-landing-page.component.html',
  styleUrls: ['./expert-landing-page.component.css']
})
export class ExpertLandingPageComponent implements OnInit {


  @Input() appointmentsList: any;
  basicData: any;
  basicOptions: any;
  lineStylesData: any;


  constructor(        private sessionService: SessionService,
                      private router: Router,

  ) { }

  ngOnInit(): void {

    this.basicData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: '#42A5F5',
          tension: .4
        },
      ]
    };

    this.basicOptions = {
      plugins: {
        legend: {
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };

    this.lineStylesData = {
      labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul'],
      datasets: [
        {
          data: [12, 51, 62, 33, 21, 62, 45],
          fill: true,
          borderColor: '#2593FF',
          tension: .4,
          backgroundColor: '#2593FF'
        }
      ]
    };


  }

  selectPatient(event) {
    this.sessionService.setSession('patientId', event.patientDTO.patientId);
    this.sessionService.setSession('expertId', event.expertId.expertId);
    this.router.navigate(['/patient-details']);

  }


  selectAppointment(event) {

    console.log(event);



    this.sessionService.setSession('appointment',  JSON.stringify(event));
    this.sessionService.setSession('appointmentId', event.appointmentId);

    this.sessionService.setSession('caseId', event.caseId);
    this.sessionService.setSession('patientId', event.patientDTO.patientId);
    this.sessionService.setSession('patientObj', JSON.stringify(event.patientDTO));
    this.sessionService.setSession('expertId', event.expertId.expertId);

    if (event.isFollowUp) {
     this.router.navigate(['/follow-up']);
    }
    else {
      this.router.navigate(['/history']);
    }
  }

}
