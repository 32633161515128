import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InvoiceService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    constructor(private httpClient: HttpClient) {
    };

    deleteByIdInvoice(invoiceId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Invoice/deleteInvoice', {'invoiceId': invoiceId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countInvoice(invoice): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'Invoice/count', {'invoice': invoice}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateInvoice(invoice): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(Constant.BASE_URL + 'Invoice/updateInvoice', [invoice], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addInvoice(invoice): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(Constant.BASE_URL + 'Invoice/addInvoice', [invoice], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllInvoice(invoice, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';

        console.log({'invoice': invoice, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}});
        console.log(this.httpOptions);

        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Invoice/findAllInvoice',
            {'invoice': invoice, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search
    invoiceGlcount(searchTerm,refId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Invoice/countGlobalInvoice',
            {'searchTerm': searchTerm,'refId':refId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findGlobalInvoice(searchTerm,refId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Invoice/findGlobalInvoice',
            {'searchTerm': searchTerm,'refId':refId, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service to get all invoices issued to a specific user
    findInvoiceByUserId(userId,userTypeId){
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Invoice/findInvoiceByUserId',
            {'userId': userId, 'userTypeId': userTypeId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
