<!-- Header Component-->


<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
  <div class="container">
    <div class="">
      <!--        <div class="topDate">-->
      <!--          <p>14:10 3<sup>rd</sup> of June 2020</p>-->
      <!--        </div>-->
      <!--
            Stepper
        -->
      <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
               [readonly]="false">
      </p-steps>
      <!-- STEPPER IMPLEMENTATION STARTS HERE -->

    </div>

  <div class="container">
    <div class="clientDetailBox">
      <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
          <div class="clientName">
            <p>{{patientObj?.firstName + ' '+ patientObj?.lastName}}</p>

          </div>
        </div>
      </div>

      <div class="p-grid p-details">
        <div class="p-col-12 p-md-6 p-lg-6 leftGroup">
          <p>DOB: {{utils.returnDateFromMilliSecond(patientObj?.dob)}}</p>
          <p>Admission Date: 28th June 1984</p>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6 rightGroup">
          <p>Client Reference: {{patientObj?.uniqueRefNumber}}</p>
          <p>Age: {{utils.getAge(patientObj?.dob)}}</p>
          <p>Last Seen: 28th June 1984</p>
          <p>Source: {{patientObj?.referralSource?.value}}</p>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12 bottomBtnGroup">

          <!---
          <p-button class="clientCommomBtn bottomBtn" label="Lock"></p-button>
          <p-button class="clientCommomBtn bottomBtn" label="Audit Case"></p-button>
          <p-button class="clientCommomBtn bottomBtn" label="Edit"></p-button>

          -->
        </div>
      </div>
    </div>
    <div *ngIf="activeStep === 0">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameClientDetails)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameClientDetails)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameClientDetails)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="clientDetailsSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnDateOfAccident)}}</label>
                                            <p-calendar appendTo="body" [monthNavigator]="true" [showIcon]="true"
                                                        class="pngCalendar " dataType="string"
                                                        dateFormat="yy-mm-dd"
                                                        formControlName="dateOfAccident" showButtonBar="true"></p-calendar>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnTitle)}}</label> <span
                                                class="p-input-icon-right"><i
                                                (click)="fieldReset('title')" *ngIf="diplayeIconInField('title')"
                                                class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                                                             formControlName="title"
                                                                                             name="title"
                                                                                             pInputText
                                                                                             type="text"> </span></div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientName')"
                                                                              *ngIf="diplayeIconInField('patientName')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="patientName"
                                                name="patientName"
                                                pInputText
                                                type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientAddress')"
                                                                              *ngIf="diplayeIconInField('patientAddress')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="patientAddress"
                                                name="patientAddress"
                                                pInputText
                                                type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientEmail')"
                                                                              *ngIf="diplayeIconInField('patientEmail')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="patientEmail"
                                                name="patientEmail"
                                                pInputText
                                                type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientContact)}}</label>
                                            <input
                                                    [class.p-invalid]="inputFieldDanger('patientContact')"
                                                    class="pngInputtext"
                                                    formControlName="patientContact"
                                                    name="patientContact"
                                                    pInputText
                                                    type="text">

                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientGender)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientGender')"
                                                                              *ngIf="diplayeIconInField('patientGender')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="patientGender"
                                                name="patientGender"
                                                pInputText
                                                type="text"> </span>
                                        </div>
                                        <!-- <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientId)}} </label>
                                            <p-dropdown [filter]="true" [options]="patientList" [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="patientId"
                                                        placeholder="Select"></p-dropdown>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnExpertId)}} </label>
                                            <p-dropdown [filter]="true" [options]="usersList" [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="expertId"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnUserId)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="userId" mode="decimal"
                                                           name="userId" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 pngbtnsdiv" id="invitationpage-searchmemeber-pngbtnsdiv"> -->
                                        <!-- <button pButton type="submit" id="invitationpage-searchmemeber-searchbtn" -->
                                        <!-- [disabled]="onValueChange" class="pngbtn btnprimaryblue" label={{translate(labels.btnSearch)}} ></button> -->
                                        <!-- <button pButton type="reset" id="invitationpage-searchmemeber-clearbtn" -->
                                        <!-- [disabled]="!this.clientDetailsSearch.touched" class="pngbtn btnprimaryblue-outline" -->
                                        <!-- label={{translate(labels.btnClear)}}></button> -->
                                        <!-- <button pButton type="button" (click)="onCloseSearch()" -->
                                        <!-- id="invitationpage-searchmemeber-cancelbtn" -->
                                        <!-- class="pngbtn btnprimaryblue-outline" label={{translate(labels.btnCancel)}}></button> -->
                                        <!-- </div> --></div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox -->

                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}}  {{translate(labels.entityNameClientDetails)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="clientDetailsForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnDateOfAccident)}}</label>
                                            <p-calendar appendTo="body" [class.p-invalid]="inputFieldDanger('dateOfAccident')"
                                                        [monthNavigator]="true"
                                                        [showIcon]="true"
                                                        class="p-invalid pngCalendar "
                                                        dataType="string" dateFormat="yy-mm-dd"
                                                        formControlName="dateOfAccident" showButtonBar="true"></p-calendar>
                                            <app-custom-error [control]="'dateOfAccident'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnTitle)}}</label> <span
                                                class="p-input-icon-right"><i
                                                (click)="fieldReset('title')" *ngIf="diplayeIconInField('title')"
                                                class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('title')" class="pngInputtext"
                                                formControlName="title"
                                                name="title"
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'title'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientName')"
                                                                              *ngIf="diplayeIconInField('patientName')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('patientName')"
                                                class="pngInputtext"
                                                formControlName="patientName"
                                                name="patientName"
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'patientName'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientAddress')"
                                                                              *ngIf="diplayeIconInField('patientAddress')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('patientAddress')"
                                                class="pngInputtext"
                                                formControlName="patientAddress"
                                                name="patientAddress"
                                                pInputText
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'patientAddress'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientEmail')"
                                                                              *ngIf="diplayeIconInField('patientEmail')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                [class.p-invalid]="inputFieldDanger('patientEmail')"
                                                class="pngInputtext"
                                                formControlName="patientEmail"
                                                name="patientEmail"
                                                email
                                                pInputText
                                                type="text"></span>
                                            <app-custom-error [control]="'patientEmail'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientContact)}}</label>
                                            <input
                                                    [class.p-invalid]="inputFieldDanger('patientContact')"
                                                    class="pngInputtext"
                                                    formControlName="patientContact"
                                                    name="patientContact"
                                                    pInputText
                                                    type="text">
                                            <app-custom-error [control]="'patientContact'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div>
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientGender)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('patientGender')"
                                                                              *ngIf="diplayeIconInField('patientGender')"
                                                                              class="pi pi-times color-danger"></i>
                                            <input
                                                [class.p-invalid]="inputFieldDanger('patientGender')"
                                                class="pngInputtext"
                                                formControlName="patientGender"
                                                name="patientGender"
                                                pInputText
                                                type="text">
                                        </span>
                                            <app-custom-error [control]="'patientGender'"
                                                              [form]="clientDetailsForm"></app-custom-error>
                                        </div> -->

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clientDetailsColumnPatientGender)}}</label>

                                            <p-selectButton [options]="genderArray" formControlName="patientGender"
                                                [optionValue]="genderArray['listOfValuesId']"></p-selectButton>
                                            <app-custom-error [control]="'patientGender'" [form]="clientDetailsForm">
                                            </app-custom-error>
                                        </div>

                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdated}}"  type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>

<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header [hidden]="showSearchBtn">
                                    <span
                                        class="p-input-icon-right globalSearch"><i
                                        class="pi pi-search"></i><input class="pngInputtext" pInputText
                                                                        placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                                        type="text"/></span></p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="clientDetailsList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': clientDetailsList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Date Of Accident</th>
                                            <th> Title</th>
                                            <th> Name</th>
                                            <th> Address</th>
                                            <th> Email</th>
                                            <th> Contact</th>
                                            <th> Gender</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.dateOfAccident | date: 'yyyy-MM-dd' }} </td>
                                            <td> {{element?.title}} </td>
                                            <td> {{element?.patientName}} </td>
                                            <td> {{element?.patientAddress}} </td>
                                            <td> {{element?.patientEmail}} </td>
                                            <td> {{element?.patientContact}} </td>
                                            <td> {{element?.patientGender?.label}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadClientDetailsToEdit(element.clientDetailsId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdClientDetails(element.clientDetailsId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                    [hidden]="showSearchBtn"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template
                                            pTemplate="emptymessage">{{translate(labels.mat_pagination_no_data_msg)}}</ng-template>
                                </p-table>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
    </div>
  </div>
</section>
