
<!--<app-app-header></app-app-header>-->
<section class="sectionmain" id="sectionmain">
    <div class="container p-mt-5">
        <p-card header="" styleClass="p-card-shadow" class="all-outer-card">
            <p-tabView class="expertAnalyticsTabs">

                <p-tabPanel header="Subscription Balance" headerStyleClass="h3-style">
                <app-subscription-balance></app-subscription-balance>
                </p-tabPanel>

                <p-tabPanel header="Last Purchase" headerStyleClass="h3-style ">
                    <app-expenses></app-expenses>
                </p-tabPanel>

                <p-tabPanel header="Auto Refill" headerStyleClass="h3-style">
                    <app-payment-form></app-payment-form>
                </p-tabPanel>

                <p-tabPanel header="Add Credit" headerStyleClass="h3-style">
                    <app-payment-form></app-payment-form>
                </p-tabPanel>

            </p-tabView>
        </p-card>

    </div>
</section>
