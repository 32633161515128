<!-- <app-gl-header></app-gl-header> -->
<!--<router-outlet></router-outlet>-->
<!-- <h1>Footer</h1> -->
<!--<app-main *ngIf="isLoggedIn$ | async;" (notifyParent)="getNotification($event)"-->
<!--          (emitCookieObject)="getCookieObject($event)">-->
<!--</app-main>-->
<!-- <p-toast position="top-center"></p-toast> -->
<!--&lt;!&ndash; <app-app-header></app-app-header> &ndash;&gt;-->
<!--<div class="body_Wrapper">-->
<!--    <router-outlet></router-outlet>-->
<!--</div>-->


<!--<ng-container *ngIf="isLoggedIn$ | async; then loggedIn; else loggedOut">-->
<!--<p-toast position="top-right" life="100000"></p-toast>-->
<ng-container *ngIf="isLoggedIn$ | async; then loggedIn; else loggedOut"></ng-container>

<ng-template #loggedIn>


    <app-main (notifyParent)="getNotification($event)"></app-main>

</ng-template>
<ng-template #loggedOut>
    <router-outlet></router-outlet>
</ng-template>
<!--<ng-template #loggedIn>-->
<!--&lt;!&ndash;    <app-collaboration></app-collaboration>&ndash;&gt;-->
<!--</ng-template>-->
<p-toast></p-toast>
