import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";

@Injectable({providedIn: 'root'})
export class BodyChartDetailAnatomiesDiagramService {
  httpOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json'),
    observe: 'response'
  };

  constructor(private httpClient: HttpClient) {
  };



  findAllBodyChartAnatomiesDiagram(): Observable<any> {


    return this.httpClient.get<any>(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'body-chart-anatomies-diagram/live',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }
}
