<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>

<div class="card-cpa-rounded-20 background-white-m-30">
  <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
           [readonly]="false">
  </p-steps>
</div>

<div class="p-row initial-assessment-container">

  <div class="p-col-12">
    <div class="row">
      <div class="p-col-3 m-5">

        <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
      </div>
    </div>

    <section>
      <div class="row mt-4 m-2">
        <div class="p-md-3 card-cpa-rounded card-shadow" style="background: white;height:600px;">
          <form>

                <div class="row">
                  <div class="p-md-11" style="padding-left:20px">
                    <h3 class="card-title">Body Chart Info</h3>
                  </div>
                </div>

            <div class="p-field p-col-12 p-md-12">
                <div class="row">
                  <div class="p-md-2" style="height:100px">
                    <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:100px;height:100px">
                      <div class="custom-alert-icon" [ngClass]="bodyAnatomyNonSavedInformation.length > 0   ? 'icon-success' : 'icon-transparent'">
                        <i class="fa-solid"  [ngClass]="bodyAnatomyNonSavedInformation.length > 0 ? 'fa-check' : 'fa-1'"></i></div>

                    </div>
                  </div>

                  <div class="p-md-8" style="margin-left:45px;padding-top:28px;" >

                    <span class="alert-title" *ngIf="!bodyAnatomyNonSavedInformation.length">Select a body part</span>
                    <div  *ngIf="bodyAnatomyNonSavedInformation.length > 0">
                      <div style="color:green"> selected</div>

                      <div *ngFor="let savedBodyChartItem of bodyAnatomyNonSavedInformation" >
                        <h5>{{savedBodyChartItem.bodyChartAnatomyBodyPart}}</h5>

                      </div>
                    </div>

                    <div  *ngIf="bodyAnatomyInformation.bodyChartDetailResponseDTO.length > 0">
                      <div style="color:green">Saved</div>

                      <div *ngFor="let previouslySavedBodyChartItem of bodyAnatomyInformation.bodyChartDetailResponseDTO" >
                        <h5>{{previouslySavedBodyChartItem.bodyChartAnatomyDiagramName}} - {{previouslySavedBodyChartItem.bodyChartAnatomyDiagramId}}</h5>
                      </div>
                    </div>

                  </div>

                </div>
            </div>
                <div class="row">
                  <div class="p-md-2" style="height:100px">
                    <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:100px;height:100px">
                      <div class="custom-alert-icon" [ngClass]="bodyAnatomyNonSavedInformation.length > 0 ? 'icon-success' : 'icon-transparent'">
                        <i class="fa-solid"  [ngClass]="bodyAnatomyNonSavedInformation.length > 0 ? 'fa-check' : 'fa-2'"></i></div>

                    </div>
                  </div>
                  <div class="p-md-8" style="margin-left:45px;padding-top:28px;">
                  <!--<div *ngFor="let bodyChartDataItem of bodyChartDataList.bodyChartDetailResponseDTO">
                    <h5>{{bodyChartDataItem.bodyChartAnatomyDiagramName}}</h5>
                    Aggravating factors
                    <ul *ngIf="bodyChartDataItem.aggs.length > 0">
                      <li *ngFor="let agg of bodyChartDataItem.aggs">
                        {{agg.lovValues}}


                      </li>
                    </ul>


                  </div>

                  <div *ngFor="let savedBodyChartItem of bodyAnatomyInformation">
                    Aggravating factors
                    <ul *ngIf="savedBodyChartItem.multiSelectData.aggs.length > 0">
                      <li *ngFor="let agg of savedBodyChartItem.multiSelectData.aggs">
                        {{agg.value}}


                      </li>
                    </ul>


                  </div>-->
                  </div>
                </div>


                <div class="row">
                  <div class="p-md-12">
                    <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                              type="submit"
                              styleClass="p-button"
                              class="pngBlueBtn" (onClick)="onSubmit()">
                    </p-button>
                    <p-button [label]="labels.btnClose"
                              (click)="showHideAddDialog()"
                              styleClass="p-button"
                              class="pngBtnTransparent">
                    </p-button>
                  </div>

                </div>



          </form>
        </div>
        <div class="p-md-8 card-cpa-rounded card-shadow m-l3" style="background: white;margin-left:25px">
          <app-interactive-body-chart  *ngIf="existingBodyDataLoaded" [existingPainScoreData]="bodyAnatomyInformation"
            (onBodyAnatomyInformationChanged)="bodyAnatomyInformationChanged($event)"
            (bodyAnatomyFrontImage)="bodyAnatomyFrontImage($event)"
            (bodyAnatomyBackImage)="bodyAnatomyBackImage($event)"
            (bodyAnatomySkeletonImage)="bodyAnatomySkeletonImage($event)"
          ></app-interactive-body-chart>

        </div>
      </div>
    </section>

    </div>

</div>




  <canvas id="canvas-front" style="display: none"></canvas>
  <canvas id="canvas-back" style="display: none"></canvas>
