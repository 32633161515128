<form (ngSubmit)="saveAppointment()" [formGroup]="appointmentsForm" class="pngForm" id="invitationpage-addmemeber-pngForm">
<div style="overflow-y: auto;height: 95vh!important;width:600px!important;">
  <div class="p-col-12" >
  <p-panel class="pngPanelMain" id="appointment-pngPanelMain">
    <p-header>Add Appointment</p-header>
    <hr/>
    <div class="p-fluid p-formgrid p-grid">
      <div class="row">
        <div class="p-col-3 p-offset-1">
          <img src="https://mdbcdn.b-cdn.net/img/new/avatars/8.webp" class="rounded-circle" style="width: 90px;"/>
        </div>
      <div class="p-field p-col-6 p-md-6" style="padding-left:30px!important;">
        <h5>{{selectedExpertName}}</h5>
        <br/>
        {{clickedEvent.formattedAppointmentDate}}
        {{clickedEvent.formattedStartTime}} - {{clickedEvent.formattedEndTime}}
      </div>
      </div>



    </div>
  </p-panel>
  <hr/>
</div>
  <p-panel class="pngPanelMain">
<div class="row">


  <div class="p-col-2">
    <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
      <div class="custom-alert-icon" [ngClass]="patientSelected ? 'icon-success' : 'icon-transparent'">
        <i class="fa-solid"  [ngClass]="patientSelected ? 'fa-check' : 'fa-1'"></i></div>
    </div>
  </div>
  <div class="p-col-10">
    <div class="text-center">
    <span (click)="editSelectedPatient()" *ngIf="patientSelected">Edit</span>
    <div *ngIf="patientSelected; else elseBlock">
      <div class="row">
        <div class="p-col-3">
          <img src="{{selectedPatient.classObject.avatarSrc}}" class="rounded-circle" style="width: 90px;"
               alt="Avatar" />
        </div>
        <div class="p-col-6">
          {{selectedPatient.classObject.firstName}}  {{selectedPatient.classObject.lastName}}
          <br/>
          {{selectedPatient.classObject.email}}
          <br/>
          {{selectedPatient.classObject.address}}
          <br/>
          {{selectedPatient.classObject.gender}}
          <br/>
          {{selectedPatient.classObject.consentGiven}}
        </div>

      </div>

    </div>
    </div>
    <ng-template  #elseBlock>
      <div class="text-center p-4">
        <p-dropdown [filter]="true"
                    [options]="patientsList"
                    [showClear]="true"
                    class="pngDropdown "
                    formControlName="patientId"
                    (onChange)="onPatientSelect($event)"
                    placeholder="Select Patient" styleClass="btn btn-outline-info patient-dropdown"></p-dropdown>
      </div>

    </ng-template>

  </div>
</div>
    <div class="row">
      <div class="p-col-2">
        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
          <div class="custom-alert-icon" [ngClass]="serviceSelected ? 'icon-success' : 'icon-transparent'">
            <i class="fa-solid"  [ngClass]="serviceSelected ? 'fa-check' : 'fa-2'"></i></div>
        </div>
      </div>
        <div class="p-col-10" style="padding-top:20px;">
          <div class="text-center">
            <p-dropdown [filter]="true"
                        [options]="patientTypeList"
                        [showClear]="true"
                        class="pngDropdown"
                        formControlName="patientFor"
                        (onChange)="onServiceSelect($event)"
                        placeholder="Select Service" styleClass="btn btn-outline-info patient-dropdown">
            </p-dropdown>
          </div>

        </div>
    </div>

    <div class="row">
      <div class="p-col-2">
        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
          <div class="custom-alert-icon" [ngClass]="consentSelected ? 'icon-success' : 'icon-transparent'">
            <i class="fa-solid" [ngClass]="consentSelected ? 'fa-check' : 'fa-3'"></i></div>
        </div>
      </div>
      <div class="p-col-10" style="padding-top:20px;">
        <div class="text-center">


        <p-dropdown [filter]="true"
                    [options]="consentList"
                    [showClear]="true"
                    class="pngDropdown"
                    formControlName="consent"
                    (onChange)="onConsentSelect($event)"
                    placeholder="Select Consent" styleClass="btn btn-outline-info patient-dropdown"></p-dropdown>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="p-col-2" style="padding-top:40px">
          <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
            <div class="custom-alert-icon" [ngClass]="caseId > 0 ? 'icon-success' : 'icon-transparent'">
              <i class="fa-solid" [ngClass]="caseId > 0 ? 'fa-check' : 'fa-4'"></i></div>
          </div>
        </div>
        <div class="p-col-10">
          <div class="row">
            <div class="p-col-12 text-center">
              Follow Up
              <p-inputSwitch formControlName="isFollowUp" (onChange)="toggleAppointmentType($event)"></p-inputSwitch>
            </div>
          </div>
          <div class="row">
            <div class="p-col-12 text-center"  *ngIf="appointmentsForm.controls['isFollowUp'].value === true else elseBlockType">
              <div class="text-center">
                <p-dropdown [filter]="true"
                            [options]="patientCasesList"
                            [showClear]="true"
                            optionLabel="caseId"
                            optionValue="caseId"
                            class="pngDropdown"
                            placeholder="Select Case"
                            (onChange)="onCaseSelect($event)" styleClass="btn btn-outline-info patient-dropdown">


                </p-dropdown>
              </div>

            </div>
            <ng-template  #elseBlockType>
              <div class="p-col-12 text-center">
                <div class="text-center">

              <div class="alert alert-success" role="alert" *ngIf="caseCreated">Case Created</div>

              <div *ngIf="!caseCreated" class="text-center">
                <div class="row">
                  <div class="p-col-12">
                    <input type="text" pInputText placeholder="Brief Case Info" formControlName="caseNotes" class="border-input"/>
                    <span><p-button class="small-square-button"  (click)="createCase()"><i class="fa-solid fa-check" style="color:white!important;"></i></p-button></span>
                  </div>
                </div>
              </div>

          </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top:20px;">
      <div class="p-col-2">
        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
          <div class="custom-alert-icon icon-success">
            <i class="fa-solid fa-question"></i></div>
        </div>
      </div>
      <div class="p-col-10" style="padding-top:20px;">
        <div class="text-center">

          <p-toggleButton formControlName="videoConference" onLabel="Add Video Conference" offLabel="Add Video Conference" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="p-col-2">
        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
          <div class="custom-alert-icon icon-success">
            <i class="fa-solid fa-question"></i></div>
        </div>
      </div>
    <div class="p-col-10" style="padding-top:20px;">
      <div class="text-center">
        <label for="duration" class="form-label">Appointment Duration (mins) </label>
        <br/>
        10 <input type="range" class="form-range" id="appointmentRange" min="10" value="30" max="60" step="5" formControlName="duration" (change)="rangeChange($event)"> 60
        <br/>
          {{appointmentsForm.controls['duration'].value}}
      </div>


  </div>
    </div>
    <div class="row">
      <div class="p-col-11 p-offset-1">
        <div  class="text-center">
          <p-button label="Save" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
          <p-button label="Close" (click)="toggleMenu()" styleClass="p-button" class="pngBtnTransparent" style="margin-left:10px"></p-button>
        </div>

      </div>
    </div>

  </p-panel>
</div>
</form>
