import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PostureMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['postureDescription']: {
      name: Constant.OBSERVATIONS_POSTURE_LOV_POSTURE_DESCRIPTION,
      dbField: 'postureDescription',
      label: 'Posture Description',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false


    },
    ['gaitDescription']: {
      name: Constant.OBSERVATIONS_POSTURE_LOV_GAIT_DESCRIPTION,
      dbField: 'gaitDescription',
      label: 'Gait Description',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    }
  };

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
