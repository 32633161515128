export class InitialAssessmentSteps {

  static stepItems =  [
    {
      label: 'History',
      number: 0,
      route: 'history'
    },
    {
      label: 'Body Chart',
      number: 1,
      route: 'body_chart'
    },
    {
      label: 'Observations',
      number: 2,
      route: 'observations'
    },
    {
      label: 'Testing',
      number: 3,
      route: 'testing'
    },
    {
      label: 'Diagnosis',
      number: 4,
      route: 'diagnosis'
    },
    {
      label: 'Treatment',
      number: 5,
      route: 'treatment'
    },
    {
        label: 'Review',
      number: 6,
      route: 'initial-assessment-review'
    },
  ];
}
