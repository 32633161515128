<p-confirmDialog key="deletePCH" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!--<ngx-ui-loader></ngx-ui-loader>-->
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container card-cpa-rounded card-shadow">

        <section>
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNamePresentConditionHistory)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn"
                                    icon="pi pi-search-plus" (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                    pButton pTooltip="Advanced Search" tooltipPosition="bottom" type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                    (click)="showHideAddDialog()" [hidden]="showAddBtn" pButton pTooltip="Add Present Condition History"
                                    tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePresentConditionHistory)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="presentConditionHistorySearch" class="pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnInitialAssesmentDate)}}</label>
                                            <p-calendar appendTo="body"
                                                        [monthNavigator]="true"
                                                        [showIcon]="true"
                                                        class="pngCalendar "
                                                        dataType="string"
                                                        dateFormat="yy-mm-dd"
                                                        formControlName="initialAssessmentDate"
                                                        showButtonBar="true">
                                            </p-calendar>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnHpc)}}</label>
                                            <span   class="p-input-icon-right">
                                                <i (click)="fieldReset('hpc')"
                                                    *ngIf="diplayeIconInField('hpc')"
                                                    class="pi pi-times color-danger">
                                                </i>
                                                <input class="pngInputtext"
                                                       formControlName="hpc"
                                                       name="hpc"
                                                       pInputText
                                                       type="text">
                                            </span>
                                        </div>
<!--                                        <div class="p-field p-col-12  p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.presentConditionHistoryColumnServicesSeen)}}</label>-->
<!--                                            <p-dropdown [filter]="true"-->
<!--                                                        [options]="listOfValuesDetailList"-->
<!--                                                        [showClear]="true"-->
<!--                                                        class="pngDropdown"-->
<!--                                                        formControlName="servicesSeen"-->
<!--                                                        placeholder="Select">-->
<!--                                            </p-dropdown>-->
<!--                                        </div>-->
<!--                                        <div class="p-field p-col-12  p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.presentConditionHistoryColumnTreatmentType)}}</label>-->
<!--                                            <p-dropdown [filter]="true"-->
<!--                                                        [options]="listOfValuesDetailList"-->
<!--                                                        [showClear]="true"-->
<!--                                                        class="pngDropdown"-->
<!--                                                        formControlName="treatmentType"-->
<!--                                                        placeholder="Select">-->
<!--                                            </p-dropdown>-->
<!--                                        </div>-->
<!--                                        <div class="p-field p-col-12  p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.presentConditionHistoryColumnInvestigation)}}</label>-->
<!--                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"-->
<!--                                                [showClear]="true" class="pngDropdown" formControlName="investigation"-->
<!--                                                        placeholder="Select"></p-dropdown>-->
<!--                                        </div>-->
<!--                                        <div class="p-field p-col-12  p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.presentConditionHistoryColumnPresentSymptoms)}}-->
<!--                                            </label>-->
<!--                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"-->
<!--                                                [showClear]="true" class="pngDropdown" formControlName="presentSymptoms"-->
<!--                                                        placeholder="Select"></p-dropdown>-->
<!--                                        </div>-->

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnOutcomeTreatmentType)}}</label>
                                            <span class="p-input-icon-right"><i
                                                    (click)="fieldReset('outcomeTreatmentType')"
                                                    *ngIf="diplayeIconInField('outcomeTreatmentType')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="outcomeTreatmentType" name="outcomeTreatmentType"
                                                    pInputText type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnOutcomeInvestigation)}}</label>
                                            <span class="p-input-icon-right"><i
                                                    (click)="fieldReset('outcomeInvestigation')"
                                                    *ngIf="diplayeIconInField('outcomeInvestigation')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="outcomeInvestigation" name="outcomeInvestigation"
                                                    pInputText type="text"> </span>
                                        </div>

                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <form (ngSubmit)="onSubmit()" [formGroup]="presentConditionHistoryForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">



                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnInitialAssesmentDate)}}</label>
                                            <p-calendar appendTo="body"
                                                        [class.p-invalid]="inputFieldDanger('initialAssessmentDate')"
                                                        [monthNavigator]="true"
                                                        [showIcon]="true"
                                                        class="p-invalid pngCalendar "
                                                        dataType="string"
                                                        dateFormat="yy-mm-dd"
                                                        formControlName="initialAssessmentDate"
                                                        showButtonBar="true">
                                            </p-calendar>
                                            <app-custom-error [control]="'initialAssessmentDate'" [form]="presentConditionHistoryForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnHpc)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('hpc')" *ngIf="diplayeIconInField('hpc')"
                                                    class="pi pi-times color-danger"></i>
                                                <input [class.p-invalid]="inputFieldDanger('hpc')" class="pngInputtext"
                                                    formControlName="hpc" name="hpc" pInputText type="text">
                                            </span>
                                            <app-custom-error [control]="'hpc'" [form]="presentConditionHistoryForm"></app-custom-error>
                                        </div>
                                      <!-- Present Symptoms  -->
                                      <div class="p-field p-col-12  p-md-6 formfieldheight">

                                        <app-lov-details [categoryString]="objectMultiSelects['presentSymptoms'].name" (getResponse)="getResponse($event)"
                                                         label="{{objectMultiSelects['presentSymptoms'].label}}"
                                                         [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['presentSymptoms'].currentSelected"></app-lov-details>
                                        <span id="#presentSymptomsError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                      </div>
                                        <!-- services seen -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['servicesSeen'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['servicesSeen'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['servicesSeen'].currentSelected"></app-lov-details>
                                          <span id="#servicesSeenError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <!-- Treatment Type  -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['treatmentType'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['treatmentType'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['treatmentType'].currentSelected"></app-lov-details>
                                          <span id="#treatmentTypeError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnOutcomeTreatmentType)}}</label>
                                            <span class="p-input-icon-right"><i
                                                    (click)="fieldReset('outcomeTreatmentType')"
                                                    *ngIf="diplayeIconInField('outcomeTreatmentType')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('outcomeTreatmentType')"
                                                    class="pngInputtext" formControlName="outcomeTreatmentType"
                                                    name="outcomeTreatmentType" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'outcomeTreatmentType'" [form]="presentConditionHistoryForm"></app-custom-error>
                                        </div>

                                        <!-- investigation  -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['investigation'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['investigation'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['investigation'].currentSelected"></app-lov-details>
                                          <span id="#investigationError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.presentConditionHistoryColumnOutcomeInvestigation)}}</label>
                                            <span class="p-input-icon-right"><i
                                                    (click)="fieldReset('outcomeInvestigation')"
                                                    *ngIf="diplayeIconInField('outcomeInvestigation')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('outcomeInvestigation')"
                                                    class="pngInputtext" formControlName="outcomeInvestigation"
                                                    name="outcomeInvestigation" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'outcomeInvestigation'" [form]="presentConditionHistoryForm"></app-custom-error>
                                        </div>


                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button   label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                    type="submit"
                                                    styleClass="p-button"
                                                    class="pngBlueBtn">
                                        </p-button>
                                        <p-button   label="Close"
                                                    (click)="showHideAddDialog()"
                                                    styleClass="p-button"
                                                    class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" />
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="presentConditionHistoryList?.length > 0" (onLazyLoad)="onPageChnage($event)"
                                    [autoLayout]="true"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                    [alwaysShowPaginator]="false" [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                    [showCurrentPageReport]=true [totalRecords]="totalRecords"
                                    [value]="presentConditionHistoryList" class="pngTable"
                                    [ngClass]="{'pngTableEmptyMessage': presentConditionHistoryList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Initial Assessment Date</th>
                                            <th> Hpc</th>
                                            <th> Investigation Outcome </th>
                                            <th> Treatment Type Outcome </th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.initialAssessmentDate | date: 'dd-MM-YYYY' }} </td>
                                            <td> {{element?.presentConditionHistoryId}} </td>
                                            <td> {{element?.outcomeInvestigation}}</td>
                                            <td> {{element?.outcomeTreatmentType}}</td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                        (click)="loadPresentConditionHistoryToEdit(element.presentConditionHistoryId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                        (click)="deleteByIdPresentConditionHistory(element.presentConditionHistoryId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                        styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
