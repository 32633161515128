<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- Main Invitation Page-->
<ngx-ui-loader></ngx-ui-loader>
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameInvoice)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn"
                                    icon="pi pi-search-plus" (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                    pButton pTooltip="Advanced Search" tooltipPosition="bottom" type="button"
                                    *ngIf="pageControlMap.get('viewInvoice')"></button>
                                <button class="p-button-rounded p-button-text" icon="pi pi-plus"
                                    (click)="showHideAddDialog()" [hidden]="showAddBtn" id="addOverlayBtn" pButton
                                    pTooltip="{{addEditTitle}} {{translate(labels.entityNameInvoice)}}"
                                    tooltipPosition="bottom" type="button" *ngIf="pageControlMap.get('Invoice')">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameInvoice)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="invoiceSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceColumnIssueDate)}}</label>
                                            <p-calendar appendTo="body" [monthNavigator]="true" [showIcon]="true"
                                                class="pngCalendar" dataType="string" dateFormat="yy-mm-dd"
                                                formControlName="issueDate" showButtonBar="true"></p-calendar>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceColumnIssueTo)}}</label>
                                            <p-dropdown [filter]="true" [options]="issueToUsersList" [showClear]="true"
                                                class="pngDropdown" formControlName="issueTo" placeholder="Select">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                                            class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!--Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameInvoice)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="invoiceForm" class="pngForm">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div *ngIf="editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceColumnInvoiceNumber)}}</label>
                                            <input [class.p-invalid]="inputFieldDanger('invoiceNumber')"
                                                class="pngInputtext" formControlName="invoiceNumber"
                                                name="invoiceNumber" [readOnly]="true" pInputText type="text">
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceColumnIssueDate)}}</label>
                                            <p-calendar appendTo="body"
                                                        [class.p-invalid]="inputFieldDanger('issueDate')"
                                                        [monthNavigator]="true"
                                                        [showIcon]="true"
                                                        class="p-invalid pngCalendar "
                                                        dataType="string"
                                                        dateFormat="yy-mm-dd"
                                                        formControlName="issueDate"
                                                        showButtonBar="true">
                                            </p-calendar>
                                            <app-custom-error [control]="'issueDate'" [form]="invoiceForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight" style="display: none;">
                                            <label>{{translate(labels.invoiceColumnIssueByType)}}</label>
                                            <p-dropdown [filter]="true" [options]="issueByTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="issueByType"
                                                placeholder="{{issueByTypeList['label']}}" [readonly]='true'>
                                            </p-dropdown>
                                            <app-custom-error [control]="'issueByType'" [form]="invoiceForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceColumnIssueBy)}}</label>
                                            <p-dropdown [filter]="true" [options]="issueByUsersList" [showClear]="true"
                                                class="pngDropdown" formControlName="issueBy" placeholder=""
                                                [readonly]='true'>
                                            </p-dropdown>
                                            <app-custom-error [control]="'issueBy'" [form]="invoiceForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight" style="display: none;">
                                            <label>{{translate(labels.invoiceColumnIssueToType)}}</label>
                                            <p-dropdown [filter]="true" [options]="issueToTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="issueToType" placeholder=""
                                                [readonly]='true'></p-dropdown>
                                            <app-custom-error [control]="'issueToType'" [form]="invoiceForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceColumnIssueTo)}}</label>
                                            <p-dropdown [filter]="true" [options]="issueToUsersList" [showClear]="true"
                                                class="pngDropdown" formControlName="issueTo" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'issueTo'" [form]="invoiceForm">
                                            </app-custom-error>
                                        </div>

                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer111">
                                        <p-button
                                            label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                            type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button"
                                            class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span *ngIf="pageControlMap.get('viewInvoice')"
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)="onGlobalSearchClick(global.value)">
                                        </i>
                                        <input #global class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" />
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="totalRecords >0" (onLazyLoad)="onPageChnage($event)"
                                    [autoLayout]="true"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                    [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [showCurrentPageReport]=true
                                    [alwaysShowPaginator]="false" [totalRecords]="totalRecords" [value]="invoiceList"
                                    class="pngTable" [ngClass]="{'pngTableEmptyMessage': invoiceList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Invoice Number</th>
                                            <th> Issue Date</th>
                                            <th> Invoice Amount</th>
                                            <th> Remaining Amount </th>
                                            <th> Issue By</th>
                                            <th> Received To</th>
                                            <th> Invoice Status</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        {{translate(labels.mat_pagination_no_data_msg)}}</ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.invoiceNumber}} </td>
                                            <td> {{element?.issueDate | date: 'yyyy-MM-dd' }} </td>
                                            <td> {{element?.invoiceAmount}} </td>
                                            <td> {{element?.remainingAmount}} </td>
                                            <td> {{element?.issueBy?.firstName}} </td>
                                            <td> {{element?.issueTo?.agencyFirstname}} </td>
                                            <td> {{element?.invoiceStatus}}</td>
                                            <td>
                                                <div class="pngTable-icons">
<!--                                                    <p-button (click)="loadInvoiceToEdit(element.invoiceId, i)"-->
<!--                                                        icon="pi pi-pencil" label="Edit"-->
<!--                                                        styleClass="p-button pbtn-transparent"-->
<!--                                                        *ngIf="pageControlMap.get('editInvoice')">-->
<!--                                                    </p-button>-->
                                                    <p-button (click)="deleteByIdInvoice(element.invoiceId,i)"
                                                        icon="pi pi-trash" label="" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"
                                                        *ngIf="pageControlMap.get('deleteInvoice')">
                                                    </p-button>
                                                    <p-button 
                                                        *ngIf="element?.invoiceAmount < 1"
                                                        icon="pi pi-plus" label="" pTooltip="Add Detail"
                                                        styleClass="p-button pbtn-transparent"
                                                        (click)="addItemDetails($event,element)">
                                                    </p-button>
                                                    <p-button label="" icon="pi pi-print"
                                                        styleClass="p-button pbtn-transparent" pTooltip="Print Preview"
                                                        (click)="printPreview($event,element)"
                                                        *ngIf="pageControlMap.get('viewInvoice')">
                                                    </p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <!--                                    <ng-template pTemplate="emptymessage">{{translate(labels.mat_pagination_no_data_msg)}}</ng-template>-->
                                    <!-- <div class="emptymessageTable" *ngIf="invoiceList.length == 0">{{translate(labels.mat_pagination_no_data_msg)}}</div> -->
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No
                                        Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
