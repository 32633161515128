<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header>{{title}} {{patientByProfessionTag === false?translate(labels.entityNamePatients): ''}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button *ngIf="!patientByProfessionTag" class="p-button-rounded p-button-text" id="searchOverlayBtn"
                                    icon="pi pi-search-plus" (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                    pButton pTooltip="Advanced Search" tooltipPosition="bottom" type="button">
                                </button>
                                <button *ngIf="!patientByProfessionTag" class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                    (click)="showHideAddDialog()" [hidden]="showAddBtn" pButton
                                    pTooltip="{{addEditTitle}} {{translate(labels.entityNamePatients)}}"
                                    tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePatients)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="patientsSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnFirstName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('firstName')"
                                                    *ngIf="diplayeIconInField('firstName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="firstName" name="firstName" pInputText type="text">
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnLastName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('lastName')"
                                                    *ngIf="diplayeIconInField('lastName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="lastName" name="lastName" pInputText type="text">
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnDob)}}</label>
                                            <p-calendar appendTo="body"
                                                        [monthNavigator]="true"
                                                        [yearNavigator]="true"
                                                        [showIcon]="true"
                                                        class="pngCalendar "
                                                        dataType="string"
                                                        dateFormat="yy-mm-dd"
                                                        formControlName="dob"
                                                        yearRange="1900:2050"
                                                        showButtonBar="true">
                                            </p-calendar>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnSex)}}</label>

                                            <p-selectButton [options]="sex_array" formControlName="sex"
                                                            [optionValue]="sex_array['listOfValuesId']">
                                            </p-selectButton>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('email')"
                                                    *ngIf="diplayeIconInField('email')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="email" name="email" pInputText type="text"> </span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                                            class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add overlay div -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNamePatients)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="patientsForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnFirstName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('firstName')"
                                                    *ngIf="diplayeIconInField('firstName')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('firstName')"
                                                    class="pngInputtext" formControlName="firstName" name="firstName"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'firstName'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnLastName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('lastName')"
                                                    *ngIf="diplayeIconInField('lastName')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('lastName')"
                                                    class="pngInputtext" formControlName="lastName" name="lastName"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'lastName'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnDob)}}</label>
                                            <p-calendar appendTo="body"
                                                        [class.p-invalid]="inputFieldDanger('dob')"
                                                        [monthNavigator]="true"
                                                        [yearNavigator]="true"
                                                        [showIcon]="true"
                                                        class="p-invalid pngCalendar "
                                                        dataType="string"
                                                        dateFormat="yy-mm-dd"
                                                        yearRange="1900:2050"
                                                        formControlName="dob"
                                                        showButtonBar="true">
                                            </p-calendar>
                                            <app-custom-error [control]="'dob'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnOccupation)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('occupation')"
                                                    *ngIf="diplayeIconInField('occupation')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('occupation')"
                                                    class="pngInputtext" formControlName="occupation" name="occupation"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'occupation'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>


                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnSex)}}</label>

                                            <p-selectButton [options]="sex_array" formControlName="sex"
                                                [optionValue]="sex_array['listOfValuesId']"></p-selectButton>

                                            <app-custom-error [control]="'sex'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnGenderIdentity)}}</label>

                                            <p-selectButton [options]="gender_array" formControlName="genderIdentity"
                                                [optionValue]="gender_array['listOfValuesId']"></p-selectButton>
                                            <app-custom-error [control]="'genderIdentity'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnEmergencyContact)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('emergencyContact')"
                                                    *ngIf="diplayeIconInField('emergencyContact')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('emergencyContact')"
                                                    class="pngInputtext" formControlName="emergencyContact"
                                                    name="emergencyContact" pInputText type="text"></span>
                                            <app-custom-error [control]="'emergencyContact'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- chnage it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnConsent)}}</label>

                                            <p-dropdown [filter]="true" [options]="conscent_array" [showClear]="true"
                                                class="pngDropdown" formControlName="consent" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'consent'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('email')"
                                                    *ngIf="diplayeIconInField('email')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('email')" class="pngInputtext"
                                                    formControlName="email" name="email" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'email'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('address')"
                                                    *ngIf="diplayeIconInField('address')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('address')" class="pngInputtext"
                                                    formControlName="address" name="address" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'address'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- change it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnCommPrefs)}}</label>

                                            <p-selectButton [options]="comm_prefs_array" formControlName="commPrefs"
                                                [optionValue]="comm_prefs_array['listOfValuesId']"></p-selectButton>
                                            <app-custom-error [control]="'commPrefs'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- change it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnMarketingInfo)}}</label>

                                            <p-selectButton [options]="marketing_array" formControlName="marketingInfo"
                                                [optionValue]="marketing_array['listOfValuesId']"></p-selectButton>
                                            <app-custom-error [control]="'marketingInfo'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- change it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnPatientType)}}</label>

                                            <p-dropdown [filter]="true" [options]="patientTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="patientType" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'patientType'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnBilling)}}</label>


                                            <p-dropdown [filter]="true" [options]="billing_array" [showClear]="true"
                                                class="pngDropdown" formControlName="billing" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'billing'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- change it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnConscessionType)}}</label>

                                            <p-dropdown [filter]="true" [options]="cons_type_array" [showClear]="true"
                                                class="pngDropdown" formControlName="conscessionType"
                                                placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'conscessionType'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- change it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnInvoice)}}</label>


                                            <p-dropdown [filter]="true" [options]="invoice_array" [showClear]="true"
                                                class="pngDropdown" formControlName="invoice" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'invoice'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnInvoiceInfo)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('invoiceInfo')"
                                                    *ngIf="diplayeIconInField('invoiceInfo')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('invoiceInfo')"
                                                    class="pngInputtext" formControlName="invoiceInfo"
                                                    name="invoiceInfo" pInputText type="text"></span>
                                            <app-custom-error [control]="'invoiceInfo'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnInsuranceNumber)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('insuranceNumber')"
                                                    *ngIf="diplayeIconInField('insuranceNumber')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('insuranceNumber')"
                                                    class="pngInputtext" formControlName="insuranceNumber"
                                                    name="insuranceNumber" pInputText type="text"></span>
                                            <app-custom-error [control]="'insuranceNumber'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnUniqueRefNumber)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('uniqueRefNumber')"
                                                    *ngIf="diplayeIconInField('uniqueRefNumber')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('uniqueRefNumber')"
                                                    class="pngInputtext" formControlName="uniqueRefNumber"
                                                    name="uniqueRefNumber" pInputText type="text"></span>
                                            <app-custom-error [control]="'uniqueRefNumber'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                        <!-- change it to dropdown -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnReferralSource)}}</label>

                                            <p-dropdown [filter]="true" [options]="ref_source_array" [showClear]="true"
                                                class="pngDropdown" formControlName="referralSource"
                                                placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'referralSource'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>

                                        <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnUserEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('userEmail')"
                                                    *ngIf="diplayeIconInField('userEmail')"
                                                    class="pi pi-times color-danger"></i>
                                                <input [class.p-invalid]="inputFieldDanger('userEmail')"
                                                    class="pngInputtext" formControlName="userEmail" name="userEmail"
                                                    pInputText type="text" [readonly]="disableColumn"></span>
                                            <app-custom-error [control]="'userEmail'" [form]="patientsForm"></app-custom-error>
                                            <span *ngIf="duplicateEmail" class="duplicate-Email">Email already exists</span>
                                        </div>

                                        <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.patientsColumnUserPassword)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('password')"
                                                    *ngIf="diplayeIconInField('password')"
                                                    class="pi pi-times color-danger rightallign">
                                                </i>
                                                <input [class.p-invalid]="inputFieldDanger('password')"
                                                    class="pngInputtext" formControlName="password" name="password"
                                                    pInputText [type]="passwordType" [readonly]="disableColumn">
                                                <i [ngClass]="passwordType === 'text'?'pi pi-eye':'pi pi-eye-slash'"
                                                    (click)="showHidePassword()"></i>
                                            </span>
                                            <app-custom-error [control]="'password'" [form]="patientsForm">
                                            </app-custom-error>
                                        </div>
                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                  type="submit"
                                                  styleClass="p-button"
                                                  class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                      Messages:
                      <div *ngIf="currentMessage | async as message">
                        {{ message | json }}
                      </div>
 <!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)="onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" /></span>
                                </p-header>
                                <p-table #dv *ngIf="totalRecords >0" (onLazyLoad)="onPageChnage($event)"
                                    [autoLayout]="true"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                         [alwaysShowPaginator]="false"
                                    [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [showCurrentPageReport]=true
                                    [totalRecords]="totalRecords" [value]="patientsList" class="pngTable"
                                    [ngClass]="{'pngTableEmptyMessage': patientsList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <!-- <th> UserId</th> -->
                                            <th> First Name</th>
                                            <th> Last Name</th>
                                            <th *ngIf="!patientByProfessionTag" > DOB</th>
<!--
                                            <th *ngIf="!patientByProfessionTag"> Sex</th>
-->
                                            <!-- <th> GenderIdentity</th> -->
                                             <th *ngIf="patientByProfessionTag"> Profession</th>
                                            <!-- <th> EmergencyContact</th> -->
                                            <!-- <th> Consent</th> -->
                                            <th *ngIf="!patientByProfessionTag"> Email</th>
                                            <!-- <th> Address</th> -->
                                            <!-- <th> CommPrefs</th> -->
                                            <!-- <th> MarketingInfo</th> -->
                                            <!-- <th> Billing</th> -->
                                            <!-- <th> ConscessionType</th> -->
                                            <!-- <th> Invoice</th> -->
                                            <!-- <th> InvoiceInfo</th> -->
                                            <!-- <th> InsuranceNumber</th> -->
                                            <!-- <th> UniqueRefNumber</th> -->
                                            <!-- <th> ReferralSource</th> -->
                                            <!-- <th> IsSystemUser</th> -->
                                            <!-- <th> PatientUserId</th> -->
                                            <!-- <th> CompanyId</th> -->
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <!-- <td> {{element?.userId.userId}} </td> -->
                                            <td> {{element?.firstName}} </td>
                                            <td> {{element?.lastName}} </td>
                                            <td *ngIf="!patientByProfessionTag"> {{element?.dob | date: 'yyyy-MM-dd' }} </td>
<!--
                                          <td *ngIf="!patientByProfessionTag"> {{element?.sex?.label}} </td>
-->
                                          <td *ngIf="patientByProfessionTag"> {{element?.occupation}} </td>
                                            <!-- <td> {{element?.genderIdentity}} </td>
                                            <td> {{element?.occupation}} </td>
                                            <td> {{element?.emergencyContact}} </td>
                                            <td> {{element?.consent}} </td> -->
                                            <td *ngIf="!patientByProfessionTag"> {{element?.email}} </td>
                                            <!-- <td> {{element?.address}} </td>
                                            <td> {{element?.commPrefs}} </td>
                                            <td> {{element?.marketingInfo}} </td>
                                            <td> {{element?.billing}} </td>
                                            <td> {{element?.conscessionType}} </td>
                                            <td> {{element?.invoice}} </td>
                                            <td> {{element?.invoiceInfo}} </td>
                                            <td> {{element?.insuranceNumber}} </td>
                                            <td> {{element?.uniqueRefNumber}} </td>
                                            <td> {{element?.referralSource}} </td> -->
                                            <!-- <td> {{element?.isSystemUser.listOfValuesDetailId}} </td> -->
                                            <!-- <td> {{element?.patientUserId}} </td>
                                            <td> {{element?.companyId.companyId}} </td> -->
                                            <td>
                                                <!-- <div class="rowbtnstable"> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text p-button-plain"></button> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-plain" (click)="loadPatientsToEdit(element.patientId, i)"></button> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-plain" (click)="deleteByIdPatients(element.patientId,i)"></button> -->
                                                <!-- </div> -->
                                                <div class="pngTable-icons">
                                                    <p-button *ngIf="!patientByProfessionTag"
                                                    pTooltip="{{title === 'Deactivated' ? 'Activate': 'Deactivate'}}"
                                                        (click)="changePatientStatus(element, i)"
                                                        [hidden]="hideActiveDeactive"
                                                        [icon]="title === 'Deactivated' ? 'activate-icon': 'deactivate-icon'"
                                                        styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <p-button *ngIf="!patientByProfessionTag"
                                                            (click)="loadPatientsToEdit(element.patientId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button *ngIf="!patientByProfessionTag"
                                                            (click)="deleteByIdPatients(element.patientId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                        (click)="viewPatientDetail(element.patientId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <!-- <p-button *ngIf="!patientByProfessionTag"
                                                    pTooltip="Manage Subscription"
                                                              (click)="getUserSubscriptionList(element.userId)"
                                                              icon="pi pi-eye"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button> -->
<!--                                                    <button pButton type="button" pButton icon="pi pi-ellipsis-v" label="" class="menu-actions"-->
<!--                                                    (click)="menu.toggle($event); getUserId(element.userId)"></button>-->
<!--                                                  <p-menu #menu [popup]="true" [model]="items" appendTo="body" class="action-btns-menu" styleClass="p-button pbtn-transparent">-->
<!--                                                  </p-menu>-->
                                                    <button type="button" pTooltip="Manage Subscription" pButton icon="manage-subs-icon" label="" class="menu-actions"
                                                            (click)="showManageSubscription(element.userId)"></button>
<!--Manage Subscription dialog box-->
                                                    <p-dialog header="Manage Subscription"
                                                              [(visible)]="subscriptionViewModal"
                                                              [modal]="true"
                                                              [blockScroll]="true"
                                                              [baseZIndex]="10000"
                                                              [draggable]="false">

                                                        <ng-template *ngIf="!userSubscriptionList" pTemplate="emptymessage">
                                                            {{translate(labels.mat_pagination_no_data_msg)}}
                                                        </ng-template>

                                                        <div *ngFor="let item of userSubscriptionList; let i = index"
                                                             class="p-field-checkbox subsCheckboxView manageSubsClass">
                                                            <div class="p-grid manageSubsGrid">
                                                                <div class="p-col-6 manageSubsLabel">
                                                                    <label class="manageSubsLabel">{{translate(item?.userSubscriptionTitle)}}</label>
                                                                </div>
                                                                <!--                                                                <div class="p-col-2"></div>-->
                                                                <div class="p-col-6 manageSubsSelectBtn">
                                                                    <p-selectButton
                                                                            [(ngModel)]="item.value"
                                                                            [name]="item.value"
                                                                            [optionValue]="manageOptions['value']"
                                                                            [options]="manageOptions"
                                                                            (onChange)="onSelect(item,i,element, $event)">
                                                                    </p-selectButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-template pTemplate="footer">
                                                            <div class="p-d-flex p-jc-end">
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="refreshSubs(element.userId)"
                                                                               label="Refresh" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="cancelUserSubscription()"
                                                                               label="Cancel" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div *ngIf="userSubscriptionList && userSubscriptionList.length > 0">
                                                                    <p-button (click)="saveUserSubscription($event)"
                                                                              label="Save" styleClass="p-button" class="pngBlueBtn">
                                                                    </p-button>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dialog>

                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No
                                        Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
