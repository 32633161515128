<!-- Header Component-->
<ngx-ui-loader></ngx-ui-loader>
<div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
  <div class="app-auth-background">

  </div>
  <div class="app-auth-container">
    <div class="logo">
      Physio Pro Assist
    </div>
    <div *ngIf="isTokenValid(); else elseBlock">
      <p class="auth-description">Please choose a password for your account.<br>Don't have an account? <a href="sign-up.html">Sign Up</a></p>
      <form  [formGroup]="CompleteSignUpForm" class="formContainer">

        <div class="auth-credentials m-b-xxl">
          <label for="signInPassword" class="form-label">Password</label>
          <!-- <small *ngIf="formErrors.Password">{{formErrors.Password}}</small>-->
          <input
            formControlName="password"
            id="login-email-id" pInputText
            placeholder="Password"
            type="password"
            class="form-control"
            aria-describedby="CompleteSignUpPassword"
          />
        </div>
      </form>
      <div class="auth-submit">
        <p-button [disabled]="!CompleteSignUpForm.valid" (click)="onSubmit()"
                  class="p-d-inline-flex p-ai-center pngBlueBtn" id="login-btn"
                  label="Save"
                  styleClass="p-button">
        </p-button>
    </div>

    </div>
    <ng-template  #elseBlock>
    <div>

      This token is invalid or expired. Please contact support.

    </div>
    </ng-template>
    <div class="divider"></div>
  </div>
</div>
