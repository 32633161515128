import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig, MenuItem} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencyService} from '../web-services/agency.service';
import {AgencyTypeService} from '../web-services/agency-type.service';
import {UsersService} from '../web-services/users.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {AgencySubscriptionChecklistService} from '../web-services/agency-subscription-checklist.service';
import {ValidationService} from '../services/validation.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {filter} from 'rxjs/operators';
import {SubscriptionService} from '../web-services/subscription.service';
import {ChatService} from '../collaboration/services/chat/chat.service';


declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;
// declare var openSearchOverlaydiv: Function;

@Component({
               selector: 'app-agency',
               templateUrl: './agency.component.html',
               styleUrls: ['./agency.component.css'],
               providers: [DialogService]
           })
export class AgencyComponent implements OnInit {
    displayModal: boolean;
    agencyViewModal: boolean;
    duplicateEmail = false;
    subscriptionViewModal: boolean;
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    // submitted = false;
    selectedIndex = -1;
    updateAgencyId = null;
    agencyTypeList: any = [];
    billingList: any = [];
    companyTypeList: any = [];
    invoiceInfoList: any = [];
    uniqueRefList: any = [];
    referralSourceList: any = [];
    marketingInfoList: any = [];
    communicationPrefList: any = [];
    subscriptionChecklist: any = [];
    checkList: any = [];
    selectedChecklist: any = [];
    tableDataToggle = false;
    knobValue = 0;
    checklistIndex = null;
    activeImage: any;
    incompleteImage: any;
    activeImagesourceList: any = [];
    incompleteImagesourceList: any = [];
    imageList = [
        {
            iconGrey: '',
            iconGreen: '',
        }
    ];

    viewButton = 'View';
    buttonIndex: any;
    checklistTitle = 'Healing Helpers Group';
    checklistDate: any;
    passwordType = 'password';


    usersList: any = [];
    agencyList: any = [];
    totalAgenciesList: any = [];
    agencyListDummy: any = [];
    subscriptionList: any = [];
    userSubscriptionList: any = [];
    agencySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencyForm: FormGroup;
    subscriptionForm: FormGroup;
    subscriptionSearch: FormGroup;
    constantList = [];
    // selectedCheckList : any = [];
    pageNo: number = 0;
    onValueChange = true;
    agencyId: any;
    tempToggle = false;
    title :any;
    hideActiveDeactive = true;
    addEditTitle = '';
    manageOptions = [
                    {label: 'Enable', value: 'Active'},
                    {label: 'Not Required', value: 'Inactive'}
                    ];
    disableLabel = false ;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    tag = false;
    items: MenuItem[];
    indexValue: any;
    percentageVal: any;
    agencyVal: any;
    insertionTimeStampVal: any;
    labelName: string;

    constructor(
        private agencyServices: AgencyService,
        private agencyTypeServices: AgencyTypeService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private agencySubscriptionService: AgencySubscriptionChecklistService,
        private _validation: ValidationService,
        private dialogService: DialogService,
        private subscriptionService: SubscriptionService,
        private chatService: ChatService
    ) {
        // console.log('construct');
    };

    ref: DynamicDialogRef;


    // Temporary -> will see to minimize this later
    viewAgencyDetail(agencyId: number) {
        this.closeChecklist();
        let agency = this.agencyList.find(x => x['agencyId'] == agencyId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Company Detail',
            width: '90%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {
                mainData: [
                    {label: this.labels.agencyColumnAgencyTitle, value: agency['agencyTitle']},
                    {label: this.labels.agencyColumnAgencyFirstname, value: agency['agencyFirstname']},
                    {label: this.labels.agencyColumnAgencyLastname, value: agency['agencyLastname']},
                    {label: this.labels.agencyColumnAgencyName, value: agency['agencyName']},
                    {label: this.labels.agencyColumnAgencyType, value: agency['agencyType']['agencyTypeTitle']},
                    {label: this.labels.agencyColumnAgencyAddress, value: agency['agencyAddress']},
                    {label: this.labels.agencyColumnAgencyPhoneNumber, value: agency['agencyPhoneNumber']},
                    {label: this.labels.agencyColumnAgencyEmail, value: agency['agencyEmail']},
                    {label: this.labels.agencyColumnCommunicationPrefs, value: agency['communicationPrefs']['label']},
                    {label: this.labels.agencyColumnMarketingInfo, value: agency['marketingInfo']['label']},
                    {label: this.labels.agencyColumnBilling, value: agency['billing']['label']},
                    {label: this.labels.agencyColumnInvoiceInfo, value: agency['invoiceInfo']['label']},
                    {label: this.labels.agencyColumnUniqueReferenceNumber, value: agency['uniqueReferenceNumber']['label']},
                    {label: this.labels.agencyColumnReferralSource, value: agency['referralSource']['label']},
                    {label: this.labels.agencyColumnNumberOfUsers, value: agency['numberOfUsers']},
                    {label: this.labels.agencyColumnUserEmail, value: agency['userEmail']},
                    // {label: this.labels.agencyColumnUserPassword, value: agency['userPassword']},
                ]
            }
        });
    }
     // title = null;
// ngOnChanges(){
//     if(this.buttonIndex === this.indexValue){
//         // console.log('inside view')
//         this.labelName = 'View Onboarding'
//     }
//     else {
//         this.labelName = 'Hide Onboarding'
//         // console.log('inside hide')
//     }
// }
        // this.getSelectedCheckList();
        // ngOnChanges() {
            // this.labelName = 'View Onboarding';

            // if(this.buttonIndex !== this.indexValue){
            //     // console.log('inside view')
            //     this.labelName = 'View Onboarding'
            // }
            // else {
            //     this.labelName = 'Hide Onboarding'
            //     // console.log('inside hide')
            // }

            // this.items = [
            //     {
            //         label: '',
            //         items: [
            //             {
            //                 label: 'Manage Subscription',
            //                 icon: 'manage-subs-icon-two',
            //                 // styleClass: 'pbtn-transparent',
            //                 command: () => {
            //                     this.getUserSubscriptionList(this.agencyId)
            //                 }
            //             },
            //             {
            //                 label: 'OnBoarding Steps',
            //                 icon: 'onboarding-steps-icon',
            //                 // styleClass: 'pbtn-transparent',
            //                 command: () => {
            //                     this.showModalDialog(this.agencyId)
            //                 }
            //             },
            //             {
            //                 label: 'Onboarding Statistics',//this.labelName
            //                 icon: 'onboarding-stats-icon',
            //                 // styleClass: 'pbtn-transparent',
            //                 command: () => {
            //                     this.showCheckListStats( this.buttonIndex, this.agencyId, this.percentageVal , this.agencyVal, this.insertionTimeStampVal)
            //                 }
            //             }
            //         ]
            //     }
            // ]
        // }
    ngOnInit() {
        this.items = [
            {
                label: '',
                items: [
                    {
                        label: 'Manage Subscription',
                        icon: 'manage-subs-icon-two',
                        // styleClass: 'pbtn-transparent',
                        command: () => {
                            this.getUserSubscriptionList(this.agencyId)
                        }
                    },
                    {
                        label: 'OnBoarding Steps',
                        icon: 'onboarding-steps-icon',
                        // styleClass: 'pbtn-transparent',
                        command: () => {
                            this.showModalDialog(this.agencyId)
                        }
                    },
                    {
                        label: 'Onboarding Statistics',//this.labelName
                        icon: 'onboarding-stats-icon',
                        // styleClass: 'pbtn-transparent',
                        command: () => {
                            this.showCheckListStats( this.buttonIndex, this.agencyId, this.percentageVal , this.agencyVal, this.insertionTimeStampVal)
                        }
                    }
                ]
            }
        ]
        this.addEditTitle = this.labels.btnAdd;
        // this.ngOnChanges();
        // this.agencyListDummy = [];
        if (this.router.url.includes('/active_agency')) {
            this.title = Constant.AGENCY_STATUS_ACTIVE;
            this.hideActiveDeactive = false;
        } else if (this.router.url.includes('/deactivated_agency')) {
            this.title = Constant.AGENCY_STATUS_DEACTIVATED;
            this.hideActiveDeactive = false;
        } else if (this.router.url.includes('/incomplete_agency')) {
            this.title = Constant.AGENCY_STATUS_INCOMPLETE;
            this.hideActiveDeactive = true;
        }
        // console.log(this.title, 'selected route');

        this.disableColumn = false;

        this.findcheckList();
        this.findCommunicationPref();
        this.findMarketingInfo();
        this.findBilling();
        this.findInvoiceInfo();
        this.findUniqueRefNumber();
        this.findReferralSource();
        this.findAllAgencyType();
        // this.findAllUsers();
        this.agencyForm = new FormGroup({
                                            agencyTitle: new FormControl('', [Validators.required, validateSpaces]),
                                            agencyFirstname: new FormControl('', [Validators.required, validateSpaces]),
                                            agencyLastname: new FormControl('', [Validators.required, validateSpaces]),
                                            agencyType: new FormControl('', [Validators.required]),
                                            agencyName: new FormControl('', [Validators.required]),
                                            agencyAddress: new FormControl('', [Validators.required]),
                                            agencyPhoneNumber: new FormControl(0, [Validators.required]),
                                            agencyEmail: new FormControl('', [Validators.required, Validators.pattern(this._validation.regex.email)]),
                                            communicationPrefs: new FormControl('', [Validators.required]),
                                            marketingInfo: new FormControl('', [Validators.required]),
                                            billing: new FormControl('', [Validators.required]),
                                            invoiceInfo: new FormControl('', [Validators.required]),
                                            uniqueReferenceNumber: new FormControl('', [Validators.required]),
                                            referralSource: new FormControl('', [Validators.required]),
                                            numberOfUsers: new FormControl(0, [Validators.required, Validators.min(0)]),
                                            userEmail: new FormControl('', [Validators.required, Validators.pattern(this._validation.regex.email)]),
                                            userPassword: new FormControl('', [Validators.required, validateSpaces]),
                                            userId:                 new FormControl(),
                                            status: new FormControl('InComplete'),
                                            percentage: new FormControl(0)
                                        });
        this.agencyForm.controls['status'].setValue('InComplete');
        this.agencyForm.controls['percentage'].setValue(0);

        this.agencySearch = new FormGroup({
                                              agencyTitle: new FormControl(),
                                              agencyFirstname: new FormControl(),
                                              agencyLastname: new FormControl(),
                                              agencyType: new FormControl(),
                                              agencyName: new FormControl(),
                                              agencyAddress: new FormControl(),
                                              agencyPhoneNumber: new FormControl(),
                                              agencyEmail: new FormControl(),
                                              communicationPrefs: new FormControl(),
                                              marketingInfo: new FormControl(),
                                              billing: new FormControl(),
                                              invoiceInfo: new FormControl(),
                                              uniqueReferenceNumber: new FormControl(),
                                              referralSource: new FormControl(),
                                              numberOfUsers: new FormControl(),
                                              userEmail: new FormControl(),
                                              userPassword: new FormControl(),
                                              // userId:                   new FormControl(),
                                              status: new FormControl(),
                                          });

        // add user subscriptions form
        this.subscriptionForm = new FormGroup({
                                                  user_id :     new FormControl(),
                                                  subscription_title :     new FormControl(),
                                                  subscription_status:     new FormControl(),
                                                  user_status:     new FormControl(),
                                                    });

        this.subscriptionSearch = new FormGroup({

                                                    userId:     new FormControl(),
                                                    subscriptionId:     new FormControl(),
                                                    subscriptionStatus:     new FormControl(),
                                                    userStatus:     new FormControl(),
                                                    userSubscriptionTitle:     new FormControl(),
                                                    agencyId:     new FormControl(),
                                                });


        this.countAgency();
        // this.findAllAgency();
        this.findAllSubscription();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencySearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });

        this.findTotalAgencies();

    }

    findCommunicationPref() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('CommunicationPreferences').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.communicationPrefList.push(dropDown);
                    }
                } else {
                    this.communicationPrefList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findMarketingInfo() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('MarketingInfo').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.marketingInfoList.push(dropDown);
                        // console.log('drop down values array :: ', dropDown);
                    }
                } else {
                    this.marketingInfoList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findBilling() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Billing').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.billingList.push(dropDown);
                    }
                } else {
                    this.billingList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findInvoiceInfo() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('invoiceInfo').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.invoiceInfoList.push(dropDown);
                    }
                } else {
                    this.invoiceInfoList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findUniqueRefNumber() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('uniqueReferenceNumber').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.uniqueRefList.push(dropDown);
                    }
                } else {
                    this.uniqueRefList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findReferralSource() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('referralSource').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.referralSourceList.push(dropDown);
                    }
                } else {
                    this.referralSourceList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findcheckList() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('SubscriptionChecklist').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let checkbox = {label: '', value: ''};
                        checkbox.value = object['listOfValuesId'];
                        checkbox.label = object['label'];
                        this.checkList.push(checkbox);
                        // console.log(this.checkList, 'susbcription');
                    }
                } else {
                    this.checkList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });

    }

    // to get the check list from subscription check list table
    // that has been added previously

    getAllChecklist() {

        let jsonObj = {'agencySubscriptionChecklistId': null, 'agencyId': this.agencyId, 'checklistItemsId': null, 'itemsStatus': null};
        this.agencySubscriptionService.findAllAgencySubscriptionChecklist(jsonObj, 0, 10).subscribe(res => {
            if (res['message'] == 'Success') {
                this.subscriptionChecklist = res['data'];
            }
        });
    }

    onClearSearch() {
        this.agencySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgency();
        // this.findAllAgency();
        // closeSearchOverlaydiv();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    countAgency() {
        this.startLoader();
        if(this.title && this.title === Constant.AGENCY_STATUS_ACTIVE){
            this.agencySearch.controls['status'].setValue(this.title);
        } else if(this.title && this.title === Constant.AGENCY_STATUS_DEACTIVATED){
            this.agencySearch.controls['status'].setValue(this.title);
        } else if(this.title && this.title === Constant.AGENCY_STATUS_INCOMPLETE){
            this.agencySearch.controls['status'].setValue(this.title);
            // console.log(this.agencySearch.value, 'serach form values');
        }
        // console.log(this.agencySearch.value, 'search form values');
            this.agencyServices.countAgency(this.agencySearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAgency();
            } else {
                this.totalRecords = 0;
                this.agencyList = [];
                this.stopLoader();
            }
        }, err => {
                console.error('err: ', err);
                this.stopLoader();
        });
    }



    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        if (!this.searchTerm) {
            this.findAllAgency();
        } else {
            this.findAllGlSearchAgency();
        }
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencySearch.reset();
        this.countAgency();
        // this.findAllAgency();
    }

    onSubmit() {
        this.agencyForm.markAllAsTouched();
        if(this.updateAgencyId == null){
            // let duplicateEmail = this.totalAgenciesList.find(x => x['userEmail'] == this.agencyForm.value.userEmail);
            // console.log('Email : ', duplicateEmail);
            // if(duplicateEmail){
            //     this.duplicateEmail =  true;
            //     return;
            // } else {
            //     this.duplicateEmail =  false;
            // }
            this.agencyForm.controls['status'].setValue('InComplete');
            this.agencyForm.controls['percentage'].setValue(0);
        }
        this.agencyForm.controls['agencyPhoneNumber'].setValue(this.agencyForm.value.agencyPhoneNumber.toString());

        if (this.agencyForm.valid) {
            this.addUpdateAgency(this.agencyForm.value);
            this.onClearAdd();
        }
    }

    async addSubscriptionChecklist(agency, checklist){
        // let jsonParams = {'agencyId': agency['agencyId'], 'subCheckList': this.checkList};
        let jsonParams = {'agencyId': agency['agencyId'], 'subCheckList':checklist};
       await this.agencySubscriptionService.addSubscriptionChecklist(jsonParams).toPromise().then(res => {
            if (res['message'] == 'Success') {
                console.log('check list added');
            }else {
                console.error(res['documentation']);
            }
        });
    }

   async addUserSubscriptionlist(agency){
        let subsJsonParams = {'agencyId': agency['agencyId'],'userId': sessionStorage.getItem('loggedInUser'), 'subscription': this.subscriptionList};
       await this.subscriptionService.addUserSubscriptionlist(subsJsonParams).toPromise().then(res => {
        if (res['message'] == 'Success') {
        console.log('Subscription list added in user subscription table');
    }else {
        console.error(res['documentation']);
    }
    });
    }

    addUpdateAgency(agency) {
        this.startLoader();
        this.duplicateEmail =  false;
        if (this.updateAgencyId == null) {
            this.agencyServices.addAgency(this.agencyForm.value).subscribe(res => {
                if(res['documentation'] == Constant.NOT_UNIQUE_EMAIL){
                    this.duplicateEmail = true;
                    this.stopLoader();
                    return;
                }else if (res['message'] == 'Success') {
                    agency = res['data'][0];
                    this.agencyList.push(agency);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: 'Company Added Successfully',
                            detail: ''
                        });
                    // this.submitted = true;
                    // this.showHideAddDialog();
                    this.showAddDialog = false;
                    this.showAddBtn = false;
                    this.showSearchBtn = false;
                    this.editTag = false;

                    // adding subscription checklist
                    this.addSubscriptionChecklist(agency,this.checkList );
                    // let jsonParams = {'agencyId': agency['agencyId'], 'subCheckList': this.checkList};
                    // this.agencySubscriptionService.addSubscriptionChecklist(jsonParams).subscribe(res => {
                    //     if (res['message'] == 'Success') {
                    //         console.log('check list added');
                    //     }else {
                    //         console.error(res['documentation']);
                    //     }
                    // });


                    // find all subscriptions list and then add in user subscriptions list against created user --> call add service
                    // this.findAllSubscription(); -- it is called on OnInit();
                    this.addUserSubscriptionlist(agency);
                    // let subsJsonParams = {'agencyId': agency['agencyId'],'userId': sessionStorage.getItem('loggedInUser'), 'subscription': this.subscriptionList};
                    // this.subscriptionService.addUserSubscriptionlist(subsJsonParams).subscribe(res => {
                    //     if (res['message'] == 'Success') {
                    //         console.log('Subscription list added in user subscription table');
                    //     }else {
                    //         console.error(res['documentation']);
                    //     }
                    // });

                    // Register Event on user creation
                    let subscribeObj = {
                        "subscription": {
                            "loggedInUser": res['data'][0].userId.toString(),
                            "project": "1",
                            "event": [
                                        { "id": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                            "title": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                            "Code": "New Message" }
                                     ],
                                            "notificationType": 3,
                                            "template": 4,
                                            "applicationId": 5
                        }
                    }
                    this.chatService.subscribeEvent(subscribeObj).subscribe(res => {
                        if (res['message'] == 'Success') {
                            console.log('user subscribed Successfully');
                        } else {
                            console.log('Error');
                        }
                    });


                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        }
        else {
            agency.agencyId = this.updateAgencyId;
            // console.log(agency, ' agency updated');
            this.agencyServices.updateAgency(agency).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencyList[this.selectedIndex] = res['data'][0];
                    // this.submitted = true;
                    // if (this.checklistIndex !== null) {
                    if (this.checklistIndex != null || this.checklistIndex != undefined) {
                        this.updateSubscriptionChecklist();
                    } else {
                        this.messageService.add(
                            {
                                severity: 'success',
                                summary: 'Company Updated Successfully',
                                detail: ''
                            });
                        this.showAddDialog = false;
                        this.showAddBtn = false;
                        this.showSearchBtn = false;
                        this.editTag = false;
                        this.countAgency();
                    }
                    // this.showHideAddDialog();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencyId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    updateSubscriptionChecklist() {
        // this.startLoader();
        // console.log(this.selectedChecklist[this.checklistIndex], 'checklist array before adding');
        let checklistFormObj = this.initChecklistForm(this.selectedChecklist[this.checklistIndex]);
        this.agencySubscriptionService.updateAgencySubscriptionChecklist(checklistFormObj.value).subscribe(res => {
            // console.log(data, 'result executed ');
            // console.log("after success ! check list item updated => ",data['data']);
            if (res['message'] == 'Success') {
                this.messageService.add(
                    {
                        severity: 'success',
                        summary: 'Subscription Checklist Updated',
                        detail: ''
                    });
                this.checklistIndex = null;
                this.countAgency();
                this.showSearchBtn = true;
                this.showAddBtn = true;
            } else {
                console.error(res['documentation']);
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);

        });
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findAllSubscription() {
        this.startLoader();
        // console.log('find all subscription function called');
        this.subscriptionService.findAllSubscription(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.subscriptionList = res['data'];
                    // console.log(this.subscriptionList, 'All subscriptions list');
                } else {
                    this.subscriptionList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }


    findAllAgency() {
        this.startLoader();
        this.agencyServices.findAllAgency(this.agencySearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencyList = res['data'];
                    console.log(this.agencyList, 'all agency list');
                    this.stopLoader();
                } else {
                    this.agencyList = [];
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findTotalAgencies() {
        this.startLoader();
        this.agencyServices.findAllAgency(this.agencySearch.value, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.totalAgenciesList = res['data'];
                    // console.log(this.agencyList, 'all agency list');
                    this.stopLoader();
                } else {
                    this.totalAgenciesList = [];
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgency(agencyId: number, index) {
        this.closeChecklist();
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencyServices.deleteByIdAgency(agencyId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.agencyList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'success',
                                summary: 'Company Deleted Successfully',
                                detail: ''
                            });
                        this.refresh();
                    } else {

                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }

                }, err => {
                    console.error('err: ', err);
                });
            }
                                  });
    }

    loadAgencyToEdit(agencyId: number, index) {
        this.closeChecklist();
        this.addEditTitle = this.labels.btnEdit;
        // this.disableLabel = true;
        let agency = this.agencyList.find(x => x['agencyId'] == agencyId);
        // this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencyId = agency['agencyId'];
        this.selectedIndex = index;
        this.agencyForm.controls['agencyTitle'].setValue(agency['agencyTitle']);
        this.agencyForm.controls['agencyFirstname'].setValue(agency['agencyFirstname']);
        this.agencyForm.controls['agencyLastname'].setValue(agency['agencyLastname']);
        this.agencyForm.controls['agencyName'].setValue(agency['agencyName']);
        if (agency.agencyType) {
            this.agencyForm.controls['agencyType'].setValue(agency['agencyType']['agencyTypeId']);
        }
        this.agencyForm.controls['agencyAddress'].setValue(agency['agencyAddress']);
        this.agencyForm.controls['agencyPhoneNumber'].setValue(agency['agencyPhoneNumber']);
        this.agencyForm.controls['agencyEmail'].setValue(agency['agencyEmail']);
        this.agencyForm.controls['communicationPrefs'].setValue(agency['communicationPrefs']['listOfValuesId']);
        this.agencyForm.controls['marketingInfo'].setValue(agency['marketingInfo']['listOfValuesId']);
        this.agencyForm.controls['billing'].setValue(agency['billing']['listOfValuesId']);
        this.agencyForm.controls['invoiceInfo'].setValue(agency['invoiceInfo']['listOfValuesId']);
        this.agencyForm.controls['uniqueReferenceNumber'].setValue(agency['uniqueReferenceNumber']['listOfValuesId']);
        this.agencyForm.controls['referralSource'].setValue(agency['referralSource']['listOfValuesId']);
        this.agencyForm.controls['numberOfUsers'].setValue(agency['numberOfUsers']);
        this.agencyForm.controls['userEmail'].setValue(agency['userEmail']);
        this.agencyForm.controls['userPassword'].setValue(agency['userPassword']);
        this.agencyForm.controls['percentage'].setValue(agency['percentage']);
        this.agencyForm.controls['status'].setValue(agency['status']);
        this.agencyForm.controls['userId'].setValue(agency['userId']);
        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
        this.tag = true;
    }

    onClearAdd() {
        // this.agencyForm.reset();
        // this.agencyForm.markAsUntouched();
        // this.updateAgencyId = null;
        // closeAddOverlaydiv();
    }

    findAllAgencyType() {
        let temp = [];
        this.startLoader();
        this.agencyTypeServices.findAllAgencyType(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyTypeId'];
                        dropDown.label = object['agencyTypeTitle'];
                        this.agencyTypeList.push(dropDown);
                    }
                } else {
                    this.agencyTypeList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    showModalDialog(agencyId) {
        this.closeChecklist();
        this.agencyId = agencyId;
        this.displayModal = true;
        this.getAllChecklist();
    }

    fieldReset(control) {
        return this.agencyForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencyForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencyForm.get(control)?.valid && this.agencyForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // closeAddOverlaydiv();
        this.agencyForm.reset();
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.agencySearch.reset();
    }

    refresh() {
        this.countAgency();
    }

    closeChecklist() {
        this.tableDataToggle = false;
        this.buttonIndex = -1;
        this.updateAgencyId = null;
    }

    onAddOverlayDiv() {
        this.closeChecklist();
        this.disableLabel = false;
        this.agencyForm.reset();
        // openAddOverlaydiv();
        // this.addEditTitle = 'Add';

    }

    onSearchOverlayDiv() {
        this.closeChecklist();
        this.agencySearch.reset();
        // openSearchOverlaydiv();

    }

    saveSubscription(agencyobj) {
        this.displayModal = false;
        this.loader.start();
        // for(let i =0;i<this.selectedChecklist.length;i++){
        let jsonObj = {
            'subCheckList': this.subscriptionChecklist,
        };
        this.agencySubscriptionService.updateSubscriptionChecklist(jsonObj).subscribe(data => {
            // console.log('check list item updated => ', data['data']);
            this.getPercentagelist(agencyobj);
        });
        // }
        // call service here to update all subscriptionChecked
        this.loader.stop();
    }

    updatePercentageIndex = null;

    onChange(event, i, agencyobj) {
        // console.log(i);
        if (event['itemsStatus'] == Constant.SUBSCRIPTION_STATUS_DRAFT) {

            this.subscriptionChecklist[i]['itemsStatus'] = Constant.SUBSCRIPTION_STATUS_PENDING;
        } else {
            this.subscriptionChecklist[i]['itemsStatus'] = Constant.SUBSCRIPTION_STATUS_DRAFT;
        }
        // this.getPercentagelist(agencyobj);
    }

    getSelectedCheckList(agencyId) {
        let jsonObj = {'agencySubscriptionChecklistId': null, 'agencyId': agencyId, 'checklistItemsId': null, 'itemsStatus': ''};
        this.agencySubscriptionService.findAllAgencySubscriptionChecklist(jsonObj, 0, 10).subscribe(res => {
            if (res['message'] == 'Success') {
                this.selectedChecklist = res['data'];
                this.selectedChecklist = this.selectedChecklist.filter(x => x.itemsStatus !== 'Draft');
                this.selectedChecklist.map(d => {
                    this.activeImagesourceList.push(
                        'assets/images/svgIcons/checklistIcons/checklist' + d['checklistItemsId']['listOfValuesId'] + 'active.svg');
                    this.incompleteImagesourceList.push(
                        'assets/images/svgIcons/checklistIcons/checklist' + d['checklistItemsId']['listOfValuesId'] + 'incomplete.svg');
                });
            }
        });
    }

    getPercentagelist(agencyobj) {
        // console.log('agency objecttttt : ', agencyobj);
        // return;
        let jsonObj = {'agencySubscriptionChecklistId': null, 'agencyId': agencyobj.agencyId, 'checklistItemsId': null, 'itemsStatus': ''};
        this.agencySubscriptionService.findAllAgencySubscriptionChecklist(jsonObj, 0, 10).subscribe(res => {
            if (res['message'] == 'Success') {
                this.selectedChecklist = res['data'];
                this.selectedChecklist = this.selectedChecklist.filter(x => x.itemsStatus !== 'Draft');
                this.updateAgencyPercentage(agencyobj);
            }
        });
    }

    updateAgencyPercentage(agencyobj) {
        // let agencyObj = checklistObj['agencyId'];
        let agencyObj = agencyobj;
        this.updateAgencyId = agencyObj['agencyId'];

        let completedAgency = this.selectedChecklist.filter(x => x.itemsStatus === Constant.SUBSCRIPTION_STATUS_COMPLETED);
        // if(this.selectedChecklist.length) {
            this.knobValue = parseInt(((completedAgency.length * 100) / this.selectedChecklist.length).toFixed(0));
        // } else {
        //     this.knobValue = 0;
        // }

        if (this.knobValue === 100) {
            agencyObj['status'] = Constant.AGENCY_STATUS_ACTIVE;
        } else {
            agencyObj['status'] = Constant.AGENCY_STATUS_INCOMPLETE;
        }
        // console.log(this.knobValue, 'knob value');
        // console.log(agencyObj['status'], 'status');
        agencyObj['percentage'] = this.knobValue;

        this.agencyForm.controls['agencyTitle'].setValue(agencyObj['agencyTitle']);
        this.agencyForm.controls['agencyFirstname'].setValue(agencyObj['agencyFirstname']);
        this.agencyForm.controls['agencyLastname'].setValue(agencyObj['agencyLastname']);
        this.agencyForm.controls['agencyName'].setValue(agencyObj['agencyName']);
        if (agencyObj.agencyType) {
            this.agencyForm.controls['agencyType'].setValue(agencyObj['agencyType']['agencyTypeId']);
        }
        this.agencyForm.controls['agencyAddress'].setValue(agencyObj['agencyAddress']);
        this.agencyForm.controls['agencyPhoneNumber'].setValue(agencyObj['agencyPhoneNumber']);
        this.agencyForm.controls['agencyEmail'].setValue(agencyObj['agencyEmail']);
        this.agencyForm.controls['communicationPrefs'].setValue(agencyObj['communicationPrefs']['listOfValuesId']);
        this.agencyForm.controls['marketingInfo'].setValue(agencyObj['marketingInfo']['listOfValuesId']);
        this.agencyForm.controls['billing'].setValue(agencyObj['billing']['listOfValuesId']);
        this.agencyForm.controls['invoiceInfo'].setValue(agencyObj['invoiceInfo']['listOfValuesId']);
        this.agencyForm.controls['uniqueReferenceNumber'].setValue(agencyObj['uniqueReferenceNumber']['listOfValuesId']);
        this.agencyForm.controls['referralSource'].setValue(agencyObj['referralSource']['listOfValuesId']);
        this.agencyForm.controls['numberOfUsers'].setValue(agencyObj['numberOfUsers']);
        this.agencyForm.controls['userEmail'].setValue(agencyObj['userEmail']);
        this.agencyForm.controls['userPassword'].setValue(agencyObj['userPassword']);
        this.agencyForm.controls['status'].setValue(agencyObj['status']);
        this.agencyForm.controls['percentage'].setValue(agencyObj['percentage']);
        if (agencyObj.userId) {
            this.agencyForm.controls['userId'].setValue(agencyObj['userId']);
        }

        // console.log(this.agencyForm.value, 'updated form values');
        this.addUpdateAgency(this.agencyForm.value);
    }

    showCheckListStats( buttonIndexVal, agencyId, percentage, agencyName, time) {


        // console.log('label::',this.labelName)
        // console.log('view/hide-onboarding buttonIndex', buttonIndexVal)
        // console.log('indexValue', this.indexValue)
        this.buttonIndex = buttonIndexVal,
        this.knobValue = percentage;
        this.checklistTitle = agencyName;
        // let date = new Date(time);
        this.checklistDate = time;
        if (this.buttonIndex !== this.indexValue) {
            this.tableDataToggle = false;
            this.buttonIndex = this.indexValue;
        } else {
            this.buttonIndex = -1;
        }
        if (this.tableDataToggle == false) {
            this.tableDataToggle = true;
            this.getSelectedCheckList(agencyId);
            this.updateAgencyId = agencyId;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
            this.showAddDialog = false;
        } else {
            this.tableDataToggle = false;
            this.updateAgencyId = null;
            this.showAddBtn = false;
            this.showSearchBtn = false;
        }
        // debugger
        if(this.buttonIndex !== this.indexValue){
            // console.log('inside view')
            this.labelName = 'View Onboarding'
        }
        else {
            this.labelName = 'Hide Onboarding'
            // console.log('inside hide')
        }
        this.editTag = false;

    }

    stepsDone(index, checklistObj) {
        this.checklistIndex = index;
        let agencyObj = checklistObj['agencyId'];
        this.updateAgencyId = agencyObj['agencyId'];

        if (this.selectedChecklist[index].itemsStatus !== Constant.SUBSCRIPTION_STATUS_COMPLETED) {
            this.selectedChecklist[index].itemsStatus = Constant.SUBSCRIPTION_STATUS_COMPLETED;
        } else {
            this.selectedChecklist[index].itemsStatus = Constant.SUBSCRIPTION_STATUS_PENDING;
        }

        let completedAgency = this.selectedChecklist.filter(x => x.itemsStatus === Constant.SUBSCRIPTION_STATUS_COMPLETED);
        this.knobValue = parseInt(((completedAgency.length * 100) / this.selectedChecklist.length).toFixed(0));

        // now save this calculated knob value in percentage column of agency

        if (this.knobValue === 100) {
            agencyObj['status'] = Constant.AGENCY_STATUS_ACTIVE;
        } else {
            agencyObj['status'] = Constant.AGENCY_STATUS_INCOMPLETE;
        }
        // console.log(this.knobValue, 'knob value');
        // console.log(agencyObj['status'], 'status');
        agencyObj['percentage'] = this.knobValue;

        this.agencyForm.controls['agencyTitle'].setValue(agencyObj['agencyTitle']);
        this.agencyForm.controls['agencyFirstname'].setValue(agencyObj['agencyFirstname']);
        this.agencyForm.controls['agencyLastname'].setValue(agencyObj['agencyLastname']);
        this.agencyForm.controls['agencyName'].setValue(agencyObj['agencyName']);
        if (agencyObj.agencyType) {
            this.agencyForm.controls['agencyType'].setValue(agencyObj['agencyType']['agencyTypeId']);
        }
        this.agencyForm.controls['agencyAddress'].setValue(agencyObj['agencyAddress']);
        this.agencyForm.controls['agencyPhoneNumber'].setValue(agencyObj['agencyPhoneNumber']);
        this.agencyForm.controls['agencyEmail'].setValue(agencyObj['agencyEmail']);
        this.agencyForm.controls['communicationPrefs'].setValue(agencyObj['communicationPrefs']['listOfValuesId']);
        this.agencyForm.controls['marketingInfo'].setValue(agencyObj['marketingInfo']['listOfValuesId']);
        this.agencyForm.controls['billing'].setValue(agencyObj['billing']['listOfValuesId']);
        this.agencyForm.controls['invoiceInfo'].setValue(agencyObj['invoiceInfo']['listOfValuesId']);
        this.agencyForm.controls['uniqueReferenceNumber'].setValue(agencyObj['uniqueReferenceNumber']['listOfValuesId']);
        this.agencyForm.controls['referralSource'].setValue(agencyObj['referralSource']['listOfValuesId']);
        this.agencyForm.controls['numberOfUsers'].setValue(agencyObj['numberOfUsers']);
        this.agencyForm.controls['userEmail'].setValue(agencyObj['userEmail']);
        this.agencyForm.controls['userPassword'].setValue(agencyObj['userPassword']);
        this.agencyForm.controls['status'].setValue(agencyObj['status']);
        this.agencyForm.controls['percentage'].setValue(agencyObj['percentage']);
        if (agencyObj.userId) {
            this.agencyForm.controls['userId'].setValue(agencyObj['userId']);
        }

        // console.log(this.agencyForm.value, 'updated form values');
        this.addUpdateAgency(this.agencyForm.value);

    }

    initChecklistForm(checklistObj) {
        let agencySubscriptionChecklistForm = new FormGroup({
                                                                agencyId: new FormControl(),
                                                                // userId: new FormControl(),
                                                                checklistItemsId: new FormControl(),
                                                                itemsStatus: new FormControl(),
                                                                agencySubscriptionChecklistId: new FormControl(),
                                                            });
        agencySubscriptionChecklistForm.controls['agencyId'].setValue(checklistObj['agencyId']['agencyId']);
        agencySubscriptionChecklistForm.controls['checklistItemsId'].setValue(checklistObj['checklistItemsId']['listOfValuesId']);
        agencySubscriptionChecklistForm.controls['itemsStatus'].setValue(checklistObj['itemsStatus']);
        agencySubscriptionChecklistForm.controls['agencySubscriptionChecklistId'].setValue(checklistObj['agencySubscriptionChecklistId']);

        return agencySubscriptionChecklistForm;
    }

    activeImageSource(infoElement) {
        // console.log(infoElement['checklistItemsId']['listOfValuesId'], 'image info element');
        // let activeImage
        // console.log('assets/images/svgIcons/checklistIcons/checklist_' + infoElement['checklistItemsId']['listOfValuesId'] + 'active');
        this.incompleteImage = 'assets/images/svgIcons/checklistIcons/checklist_' + infoElement['checklistItemsId']['listOfValuesId'] + '_active';
        return this.incompleteImage;

    }

    inCompleteImageSource(infoElement) {
        // console.log(infoElement['checklistItemsId']['listOfValuesId'], 'image info element');
        this.activeImage = 'assets/images/svgIcons/checklistIcons/checklist_' + infoElement['checklistItemsId']['listOfValuesId'] + '_incomplete';
        return this.activeImage;

    }

    imageUrl(index, info) {
        // console.log(index, 'index');
        // console.log(info, 'Info');
    }

    showHidePassword() {
        if (this.passwordType === 'text') {
            this.passwordType = 'password';
        } else {
            this.passwordType = 'text';
        }
    }

    searchTerm = null;
    status = "";
    onGlobalSearchClick(eventValue) {
        // console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.agencyGlCount();
    }

    agencyGlCount() {

        if (this.router.url.includes('/active_agency')) {
            this.status = Constant.AGENCY_STATUS_ACTIVE;
        } else if (this.router.url.includes('/deactivated_agency')) {
            this.status = Constant.AGENCY_STATUS_DEACTIVATED;
        } else if (this.router.url.includes('/incomplete_agency')) {
            this.status = Constant.AGENCY_STATUS_INCOMPLETE;
        }
        // console.log("status value :: ",this.status);

        this.agencyServices.agencyGlcount(this.searchTerm,this.status).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchAgency();
            } else {
                this.totalRecords = 0;
                this.agencyList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchAgency() {

        if (this.router.url.includes('/active_agency')) {
            this.status = Constant.AGENCY_STATUS_ACTIVE;
        } else if (this.router.url.includes('/deactivated_agency')) {
            this.status = Constant.AGENCY_STATUS_DEACTIVATED;
        } else if (this.router.url.includes('/incomplete_agency')) {
            this.status = Constant.AGENCY_STATUS_INCOMPLETE;
        }

        console.log("status value :: ",this.status);

        this.agencyServices.findGlobalAgency(this.searchTerm,this.status,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.agencyList = result['data'];
            } else {
                this.agencyList = [];
                this.totalRecords = 0;
            }
            // console.log(result, 'result');
        });
    }

    changeAgencyStatus(element, index) {
             this.checklistIndex = null;
            let agencyObj = element;
            this.updateAgencyId = element['agencyId'];

            let percentage = element['percentage'];
            if(this.title === Constant.AGENCY_STATUS_ACTIVE || this.title === Constant.AGENCY_STATUS_INCOMPLETE ){
                this.agencyForm.controls['status'].setValue(Constant.AGENCY_STATUS_DEACTIVATED);
            }
            if(this.title === Constant.AGENCY_STATUS_DEACTIVATED && percentage < 100 ){
                this.agencyForm.controls['status'].setValue(Constant.AGENCY_STATUS_INCOMPLETE);
            } else  if(this.title === Constant.AGENCY_STATUS_DEACTIVATED && percentage === 100 ){
                this.agencyForm.controls['status'].setValue(Constant.AGENCY_STATUS_ACTIVE);
            }

            this.agencyForm.controls['agencyTitle'].setValue(agencyObj['agencyTitle']);
            this.agencyForm.controls['agencyFirstname'].setValue(agencyObj['agencyFirstname']);
            this.agencyForm.controls['agencyLastname'].setValue(agencyObj['agencyLastname']);
            this.agencyForm.controls['agencyName'].setValue(agencyObj['agencyName']);
            if (agencyObj.agencyType) {
                this.agencyForm.controls['agencyType'].setValue(agencyObj['agencyType']['agencyTypeId']);
            }
            this.agencyForm.controls['agencyAddress'].setValue(agencyObj['agencyAddress']);
            this.agencyForm.controls['agencyPhoneNumber'].setValue(agencyObj['agencyPhoneNumber']);
            this.agencyForm.controls['agencyEmail'].setValue(agencyObj['agencyEmail']);
            this.agencyForm.controls['communicationPrefs'].setValue(agencyObj['communicationPrefs']['listOfValuesId']);
            this.agencyForm.controls['marketingInfo'].setValue(agencyObj['marketingInfo']['listOfValuesId']);
            this.agencyForm.controls['billing'].setValue(agencyObj['billing']['listOfValuesId']);
            this.agencyForm.controls['invoiceInfo'].setValue(agencyObj['invoiceInfo']['listOfValuesId']);
            this.agencyForm.controls['uniqueReferenceNumber'].setValue(agencyObj['uniqueReferenceNumber']['listOfValuesId']);
            this.agencyForm.controls['referralSource'].setValue(agencyObj['referralSource']['listOfValuesId']);
            this.agencyForm.controls['numberOfUsers'].setValue(agencyObj['numberOfUsers']);
            this.agencyForm.controls['userEmail'].setValue(agencyObj['userEmail']);
            this.agencyForm.controls['userPassword'].setValue(agencyObj['userPassword']);
            // this.agencyForm.controls['status'].setValue(agencyObj['status']);
            this.agencyForm.controls['percentage'].setValue(agencyObj['percentage']);
            if (agencyObj.userId) {
                this.agencyForm.controls['userId'].setValue(agencyObj['userId']);
            }

            console.log(this.agencyForm.value, 'updated form values');
            this.addUpdateAgency(this.agencyForm.value);

        }
    getUserSubscriptionList(agencyId) {
        this.userSubscriptionList = [];
        this.subscriptionSearch.controls['agencyId'].setValue(agencyId);
        // console.log(this.subscriptionSearch.value, 'search form');
        this.subscriptionService.findAllUserSubscription(this.subscriptionSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.userSubscriptionList = res['data'];

                    this.userSubscriptionList.forEach(element=>{
                       element['value'] = element['userStatus']
                    })
                    // console.log(this.userSubscriptionList, 'all user Subscription list');
                    this.subscriptionViewModal = true;
                } else {
                    this.userSubscriptionList = [];
                    this.subscriptionViewModal = true;
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });

    }
    saveUserSubscription(event) {

        this.loader.start();
        // for (let i = 0; i < this.userSubscriptionList.length; i++) {
            let jsonObj = {'userSubscriptionList': this.userSubscriptionList};
            this.subscriptionService.updateUserSubscriptionlist(jsonObj).subscribe(res => {
               if (res['message'] == 'Success') {
                   // console.log('check list item updated => ', res['data']);
                   this.subscriptionViewModal = false;
                   this.loader.stop();
               } else {
                   this.stopLoader();
                   this.subscriptionViewModal = false;
               }
           }, err => {
               console.error('err: ', err);
               this.stopLoader();
           }
            );
        // }
    }

    cancelUserSubscription() {
        this.subscriptionViewModal = false;
    }

    onSelect(item: any, i: number, element: any, event) {

        // console.log('item : ', item , ' at index ', i);
        // console.log('event : ', event.value );
        // console.log(this.userSubscriptionList, 'user sub list');
        if(event.value === 'Active'){
            this.userSubscriptionList[i]['userStatus'] = 'Active';
        }else {
            this.userSubscriptionList[i]['userStatus'] = 'Inactive';
        }
        // this.userSubscriptionList[i].controls['userStatus'].setValue('')
        // console.log(this.userSubscriptionList, 'changed user subscription list');
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencyForm.reset();
            this.agencyForm.markAsUntouched();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.disableColumn = false;
        this.tag = false;
        this.updateAgencyId = null;
    }
    getIndex(index, agencyIdVal, getPercentageVal, getAgencyNameVal, getInsertionTimestampVal){
        this.indexValue = index
        this.agencyId = agencyIdVal,
        this.percentageVal = getPercentageVal,
        this.agencyVal = getAgencyNameVal,
        this.insertionTimeStampVal = getInsertionTimestampVal
        // console.log('Menu Button::', this.indexValue)
    }
}
