import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencyPatientsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgencyPatients(agencyPatientsId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyPatients/deleteAgencyPatients', {'agencyPatientsId': agencyPatientsId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAgencyPatients(agencyPatients): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'AgencyPatients/count', {'agencyPatients': agencyPatients},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateAgencyPatients(agencyPatients): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyPatients/updateAgencyPatients', [agencyPatients], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addAgencyPatients(agencyPatients): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyPatients/addAgencyPatients', [agencyPatients], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAgencyPatients(agencyPatients, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'AgencyPatients/findAllAgencyPatients',
            {'agencyPatients': agencyPatients, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
