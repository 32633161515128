import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ObservationsService} from '../web-services/observations.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {UsersService} from '../web-services/users.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {ObservationsMultiSelects} from "../uiUtils/pages/observations/ObservationsMultiSelects";
import {FormDataDTO} from "../dto/FormDataDTO";
import {EditHistoryFormDataDTO} from "../dto/EditHistoryFormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {HttpStatusCode} from '@angular/common/http';

@Component({selector: 'app-observations', templateUrl: './observations.component.html', styleUrls: ['./observations.component.css']})
export class ObservationsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateObservationsId = null;
    listOfValuesDetailList: any = [];
    affectedCategoryList: any = [];
    usersList: any = [];
    observationsList: any = [];
    observationDropDownList: any = [];
    observationsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    observationsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    expertId;
    patientId;
    appointmentId;
    caseId;
    isEditing = true;
    editingObservationId: number;
    updatePresentConditionHistoryId: number;


  constructor(
        private observationsServices: ObservationsService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private observationsMultiSelects: ObservationsMultiSelects
    ) {}

    objectMultiSelects: any;
  lovDataObject : any = {};
reset = false;

  getResponse($event) {

    this.observationsMultiSelects.getResponseObjects($event);
  }

    findObservationsDropDown() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Observations').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.observationDropDownList.push(dropDown);
                    }
                } else {
                    this.observationDropDownList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));
        this.lovDataObject = {"refId":0,"refType":""};

        this.objectMultiSelects = this.observationsMultiSelects.objectMultiSelects;

        this.observationsForm = new FormGroup({

                                                  comments: new FormControl('', [Validators.required, validateSpaces]),
                                              });
        this.observationsSearch = new FormGroup({});

        this.getConstantList(1234);
        this.findAllObservationsByAppointmentId();
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        //this.observationsSearch.valueChanges.subscribe(data => {
          //  this.onValueChange = false;
       // });
    }

    get addUpdateTitle() {
        return this.updateObservationsId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.observationsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countObservations();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countObservations() {
        this.observationsSearch.controls['expertId'].setValue(this.expertId);
        this.observationsSearch.controls['patientId'].setValue(this.patientId);
        this.observationsSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.observationsServices.countObservations(this.observationsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllObservationsByAppointmentId();
            } else {
                this.totalRecords = 0;
                this.observationsList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {

      this.observationsForm.markAllAsTouched();

        if (this.isEditing) {

          /* get stuff to delete */
          const observationsEditDTO = new EditHistoryFormDataDTO(this.objectMultiSelects, this.observationsForm);

          console.log(observationsEditDTO);

          if (!observationsEditDTO.hasMultiSelectErrors) {

            /// do edit here ///

          }

          }

        const observationsDTO = new FormDataDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.observationsForm);

        console.log(observationsDTO);

      const diagnosisData = new DiagnosisDataHelper(observationsDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      console.log(uniqueCaseDiagnosisPredictionList);
      console.log(observationsDTO.redFlagData);


        if (!observationsDTO.hasMultiSelectErrors) {

          this.addObservation(observationsDTO);




          if (uniqueCaseDiagnosisPredictionList.length > 0) {
            this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

              console.log(res);
            });
          }

          if (observationsDTO.redFlagData.length > 0) {

            this.lovServices.createListOfCaseRedFlags(observationsDTO.redFlagData).subscribe(res => {

              if (res.status === 201) {

                this.messageService.add({
                  severity: 'success',
                  summary: this.translate("Red Flags Updated"),
                });
              }

            });
          }

        }
        else {

            observationsDTO.multiSelectErrorFields.forEach(multiSelectError => {

            document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
          });
        }
    }

    addObservation(observationsDTO: FormDataDTO): void {

      this.observationsServices.addObservationsCpa(observationsDTO).subscribe(res => {

        if (res.status === 200) {

          this.stopLoader();

          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameObservations) +
              this.translate(this.labels.addedSuccessfully),
          });

          this.lovDataObject = {"refId":0,"refType":"","reset": true};
          this.lovDataObject = {"refId":0,"refType":"","reset": false};


          this.submitted = true;
          this.showHideAddDialog();
          this.findAllObservationsByAppointmentId();

        }
        else {

          alert('error');
        }

      });

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllObservationsByAppointmentId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.observationsSearch.reset();
      ///  this.countObservations();
        // this.findAllObservations();
    }

    addUpdateObservations(observations) {
        this.startLoader();
        if (this.updateObservationsId == null) {
            this.observationsServices.addObservations(this.observationsForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    observations = res['data'][0];
                    this.observationsList.push(observations);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameObservations) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameObservations)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countObservations();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            observations.observationsId = this.updateObservationsId;
            this.observationsServices.updateObservations(observations).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.observationsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameObservations) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameObservations)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countObservations();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateObservationsId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findAffectedCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('AffectCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.affectedCategoryList.push(dropDown);
                    }
                } else {
                    this.affectedCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }


    findAllObservationsByPatientId(): void {
        this.startLoader();
        this.observationsServices.findAllObservationsCpa(this.patientId).subscribe(
            res => {

                if (res.status === 200) {

                    this.observationsList = res.body;
                } else {
                    this.observationsList = [];
                    this.totalRecords = 0;
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }


  findAllObservationsByAppointmentId(): void {
    this.startLoader();
    this.observationsServices.findAllObservationsByAppointmentId(this.appointmentId).subscribe(
      res => {

        if (res.status === HttpStatusCode.Ok) {

          this.observationsList = res.body;
        } else {
          this.observationsList = [];
          this.totalRecords = 0;
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

    deleteByIdObservations(observationsId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.observationsServices.deleteByIdObservations(observationsId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.observationsList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameObservations) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameObservations)
                                                });
                        this.countObservations();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deleteObservation"
                                  });
    }

    loadObservationsToEdit(observationsId: number, index) {




        this.addEditTitle = this.labels.btnEdit;
        let observationHistory = null;
        this.isEditing = true;
        this.editingObservationId = observationsId;
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);

        this.observationsServices.findObservationById(observationsId).subscribe(res => {

          if (res.status === 200) {


            if (res.body) {

              observationHistory = res.body;

              this.updatePresentConditionHistoryId = observationsId;

              for (let [key, value] of Object.entries(observationHistory.multiSelectData)) {
                this.observationsMultiSelects.objectMultiSelects[key].currentSelected = value;
                this.observationsMultiSelects.objectMultiSelects[key].currentListOfValuesMasterId = value[0].listOfValuesMasterId;

              }
              this.observationsForm.controls['comments'].setValue(observationHistory.comments);
              this.showAddDialog = true;

            }

          }
        });

        // by ali
       /* if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }*/
    }

    onClearAdd() {
        this.observationsForm.reset();
        this.observationsForm.markAsUntouched();
        this.updateObservationsId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.observationsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.observationsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.observationsForm.get(control)?.valid && this.observationsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.observationsForm.reset();
    }

    onCloseSearch() {
        this.observationsSearch.reset();
    }

    refresh() {
        this.countObservations();
    }

    onAddOverlayDiv() {
        this.observationsForm.reset();
    }

    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.observationsSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.observationsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateObservationsId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        // console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.observationsServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.observationsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.observationsServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.observationsList = result['data'];
            } else {
                this.observationsList = [];
            }
            // console.log(result, 'result');
        });
    }
}
