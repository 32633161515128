<ngx-ui-loader></ngx-ui-loader>

  <!--
<app-patient-details-information [patientObject]="patientObj" [patientCasesList]="patientCasesList"></app-patient-details-information>
<section class="sectionmain p-mt-3">


  <div class="container">
    <div class="p-grid p-details">
<div class="p-col-12">
  <p-accordion>
    <ng-container>
      <p-accordionTab  [selected]="false"  header="Flags Summary" >
        <div *ngFor="let patientFlag of patientFlags">
         <i class="fa  fa-flag" style="color:red;padding-right:8px"></i> {{patientFlag.value}}


        </div>
      </p-accordionTab>


    </ng-container>


  </p-accordion>
</div>
</div>
  </div>
  <div class="container">
    <div class="p-grid p-details">
      <div class="p-col-12">

</div>
    </div>
  </div>


</section>
-->

  <div class="side-slide-bar-steps" [style.left]="initialAssessmentWidthLeft" [style.width]="initialAssessmentWidth" style="position:absolute;border: 0 solid slateblue; top:130px; ;z-index: 10;background-color: white;text-align:end"
       [class.side-tab-slider-visible]="initialTabVisible">

    <div class="col-md-11">
      <div *ngFor="let patientCase of patientCases" style="display: inline;border: 1px red solid">
        {{patientCase.notes}}
      </div>
    </div>



    <div (click)="sideTabToggle('initial')" class="col-md-1"  style="background: #0981db !important;height: 200px;  display: flex;
  align-items: center;
  justify-content: center;letter-spacing: 2px;
  padding: 13px 20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;width:200px!important;  color: #fff;cursor: pointer;">

      <div style="transform: rotate(-90deg);text-align: center">
        Initial Assessment(s)
      </div>




    </div>
  </div>


  <div class="side-slide-bar-steps" style="width:400px;position:absolute;border: 0 solid slateblue; top:350px; left:-350px;;z-index: 10;background-color: white;text-align:end"
       [class.side-tab-slider-visible]="diagnosisTabVisible">
    <div style="float:right!important;">
      <button class="patient-side-menu" (click)="sideTabToggle(null)" style="float:right;margin-left:350px!important;">Diagnosis</button>
    </div>
  </div>


  <div class="side-slide-bar-steps row" [style.left]="bodyChartDetailsWidthLeft"  [style.width]="bodyChartDetailsWidth" style="position:absolute;border: 0px solid slateblue; top:600px;z-index: 10;background-color: white;text-align:end;height:200px!important;"
       [class.side-tab-slider-visible]="bodyChartTabVisible">



<div class="col-md-11">
  <div *ngFor="let patientBodyChartDetail of patientBodyChartDetailList" style="display: inline;">
    <img src="{{patientBodyChartDetail.frontAnatomyImageUrl}}" class="rounded-circle" style="width: 60px;height:90px;"/>
  </div>
</div>



<div (click)="sideTabToggle('bodyCharts')" class="col-md-1"  style="background: #0981db !important;height: 200px;  display: flex;
  align-items: center;
  justify-content: center;letter-spacing: 2px;
  padding: 13px 20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;width:200px!important;  color: #fff;cursor: pointer;">

    <div style="transform: rotate(-90deg);text-align: center">
      Body Charts
    </div>




</div>

  </div>





  <div class="p-row" style="z-index: 1">

    <div class="p-col-12" style="z-index: 9!important;position: absolute">
      <div class="row" style="z-index: 9!important;">
        <div class="p-col-3 p-offset-1" style="border: 0px solid green">

          <!--
                      <div style="margin:0px!important;padding: 10px!important;">
                        <div style="border: 0px solid red;border-radius: 30px;background-color: #e3ebed;margin: 0px!important;box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 5%)">
                          <div class="pt-3 pb-2">
                            <div *ngFor="let patientBodyChartDetail of patientBodyChartDetailList" style="border-radius: 50px;background-color: white;width: 30px;height:45px;display: inline;height:auto">
                              <img src="{{patientBodyChartDetail.frontAnatomyImageUrl}}" class="rounded-circle" style="width: 30px;height:45px;"/>
                            </div>

                          </div>
                        </div>
                      </div>-->


          <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>


          <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                  <div class="rounded-title-box text-center">
                    <span style="font-weight: bold!important;">Duration</span>

                  </div>
                  <div *ngFor="let bcd of caseReviewData.bodyChartDetails" class="ml-3">
                    <span> {{bcd.bodyChartAnatomyDiagramName}}</span>
                    <ul style="list-style-type: disc;font-size: 20px">
                      <li *ngFor="let duration of bcd.duration"  style="font-size: 15px;">
                        {{duration.lovValues}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                  <div class="rounded-title-box text-center">
                    <span style="font-weight: bold!important;">Aggravating factors</span>
                  </div>
                </div>
                <div *ngFor="let bcd of caseReviewData.bodyChartDetails" class="ml-3">
                  <span> {{bcd.bodyChartAnatomyDiagramName}}</span>
                    <ul style="list-style-type: disc;font-size: 20px">
                      <li *ngFor="let aggs of bcd.aggs"  style="font-size: 15px;">
                        {{aggs.lovValues}}
                      </li>
                    </ul>


                </div>
              </div>
            </div>
          </div>

          <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                  <div class="rounded-title-box text-center">
                    <span style="font-weight: bold!important;">Easing factors</span>
                  </div>
                  <div *ngFor="let bcd of caseReviewData.bodyChartDetails" class="ml-3">
                    <span> {{bcd.bodyChartAnatomyDiagramName}}</span>
                    <ul style="list-style-type: disc;font-size: 20px">
                      <li *ngFor="let eases of bcd.eases"  style="font-size: 15px;">
                        {{eases.lovValues}}
                      </li>
                    </ul>


                  </div>
                </div>
              </div>
            </div>
          </div>


         <!-- <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                  <div class="rounded-title-box text-center">
                    <span style="font-weight: bold!important;">Hobbies</span>
                  </div>
                </div>
                <div *ngFor="let sh of caseReviewData.socialHistories" class="ml-3">
                  <ul style="list-style-type: disc;font-size: 20px">
                    <li *ngFor="let  hobbies of sh.hobbies" style="font-size: 15px;">
                      {{hobbies.lovValues}}
                    </li>
                  </ul>


                </div>

              </div>
            </div>
          </div>
-->

        </div>



        <div class="p-col-3 p-offset-4" style="border: 0px solid green">

          <div class="card-cpa-rounded card-cpa outer-rounded-info-box">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                  <div class="rounded-title-box text-center">
                    <span style="font-weight: bold!important;">Description</span>
                  </div>
                  <div *ngFor="let bcd of caseReviewData.bodyChartDetails" class="ml-3">
                    <span> {{bcd.bodyChartAnatomyDiagramName}}</span>
                    <ul style="list-style-type: disc;font-size: 20px">
                      <li *ngFor="let description of bcd.description"  style="font-size: 15px;">
                        {{description.lovValues}}
                      </li>
                    </ul>


                  </div>

                </div>
              </div>
            </div>
          </div>

<!--
          <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                 nECK pain
                </div>
              </div>
            </div>
          </div>
-->
          <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">
                  <div class="rounded-title-box text-center">
                    <span style="font-weight: bold!important;">24 hour symptoms</span>
                  </div>
                  <div *ngFor="let bcd of caseReviewData.bodyChartDetails" class="ml-3">
                    <span> {{bcd.bodyChartAnatomyDiagramName}}</span>
                    <ul style="list-style-type: disc;font-size: 20px">
                      <li *ngFor="let hourlySymptoms of bcd.hourlySymptoms" style="font-size: 15px;">
                        {{hourlySymptoms.lovValues}}
                      </li>
                    </ul>


                  </div>


                </div>
              </div>
            </div>
          </div>

          <div class="card-cpa-rounded card-cpa outer-rounded-info-box mt-4">
            <div class="rounded-info-box">
              <div class="inner-rounded-info-box">
                <div class="pt-3 pb-2">

                  <div *ngFor="let dh of caseReviewData.drugHistories">
                    <ul style="list-style-type: disc;font-size: 20px">
                      <li *ngFor="let smoke of dh.smoke" class="mb-5" style="font-size: 15px;">
                        {{smoke.lovValues}}
                      </li>
                    </ul>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <div class="row">
              <div class="p-col-4 card-cpa-rounded card-cpa"
                   style="border-radius: 30px;background-color: #dee6e8;padding: 20px!important;margin:0px!important;margin-top:20px!important;box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 5%);color:black;font-weight: bolder">

                Muscular

              </div>


              <div class="p-col-4 card-cpa-rounded card-cpa"
                   style="border-radius: 30px;background-color: #dee6e8;padding: 20px!important;margin:0px!important;margin-top:20px!important;margin-left:30px!important;box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 5%);">

                Skeletal

              </div>
            </div>

          </div>


        </div>



      </div>
    </div>
    <div class="p-col-12 text-center">

                  <img class="bodychart-img-front" [src]="caseReviewData.bodyChartsMaster[0].frontAnatomyImageUrl" width="500px" height="800px">


    </div>

  </div>


