<div class="card-cpa-rounded card-cpa outer-rounded-info-box">
  <div class="rounded-info-box">
    <div class="inner-rounded-info-box">
      <div class="pt-3 pb-2">
        <div class="text-center">
          <img src="{{patientObj.avatarSrc}}" class="rounded-circle" style="width: 120px;"
               alt="Avatar"/>

          <div
            style="font-size: 15px;font-weight:bolder!important;"> {{patientObj.firstName}}  {{patientObj.lastName}}</div>

          <div style="font-size: 15px;font-weight: bolder"> {{patientObj.gender}}</div>
          <div style="font-size: 15px;font-weight: bolder"> {{patientObj.dob | date: 'dd-MM-YYYY' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
