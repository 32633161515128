<!-- <app-app-header></app-app-header> -->
<section class="sectionmain" id="sectionmain-admin">
    <div class="container-fluid">
        <div class="p-fluid p-formgrid p-grid admin-grid">
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">-->
<!--                &lt;!&ndash; [routerLink]="[ '/', 'cases-dashboard']" &ndash;&gt;-->
<!--                <div class="adminCard adminCardBlue" (click)="letTrue = true">-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/images/superAdminDashboard/view-medical-report.png">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>View Medical Report</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                <div class="adminCard adminCardBlue" (click)="viewInitialAssesmentReport()">
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/initial-assesment.svg">
                        <div class="dashboard-card-text">
                            <p>View Initial Assessment</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">-->
<!--                <div class="adminCard adminCardBlue" (click)="letTrue = true">-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/images/superAdminDashboard/view-notes.png">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>View Notes</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">-->
<!--                <div class="adminCard adminCardBlue" (click)="letTrue = true">-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/images/superAdminDashboard/view-discharge.png">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>View Discharge</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
    <p-dialog class="reportDialog" [modal]="true" [(visible)]="letTrue" [blockScroll]="true"
[style]="{width: '50vw', height: '75%'}"
[baseZIndex]="10000"
[draggable]="false" id="report-dialog"> 
    <!-- <div class="reportDialogHeader">
      <p>Initial Assessment</p>
      <div class="tapLogo">
        <img src="assets/images/logo/CPA-Logo-Blue.png">
      </div>
    </div> -->
    <!-- <div class="p-col-12 p-md-12 p-lg-12">
      <div class="clientName">
        <p>Mr. Stan Williams</p>
      </div>
    </div> -->
    <!-- <div class="p-grid p-details">
      <div class="p-col-12 p-md-6 p-lg-6 leftGroup">
        <p>Last Visit: 28th June 1984</p>
        <p>DOB: 28th June 1984</p>
        <p>Admission Date: 28th June 1984</p>
        <p>Practitioner: John Smith</p>
      </div>
      <div class="p-col-12 p-md-6 p-lg-6 rightGroup">
        <p>Client Reference:72938420</p>
        <p>Age: 36 Years 2 Months</p>
        <p>Last Seen: 28th June 1984</p>
        <p>Source: BUPA</p>
      </div>
    </div> -->
    <div class="reportLogo">
        <img src="assets/theme1/custom-files/img/logos/cpalogo.png">
    </div>

    <div class="reportDialogHeader">
        <p>Initial Assessment</p>
        <!-- <div class="tapLogo">
          <img src="assets/images/logo/CPA-Logo-Blue.png">
        </div> -->
    </div>
    <div class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Name</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Max Verstappen</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Address</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> 221B, Baker Street</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Email</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> MaxVerstappen@Redbull.com</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Contact</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> 7387168400</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Gender</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Male</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Date of Birth</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> 17/11/1996</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Date of Accident</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> 31/08/2018</span>
            </div>
        </div>
    </div>
    <div class="reportDialogHeader">
        <p>HPC</p>
    </div>
    <div class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Initial Assessment Date </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> 09-01-2019</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">HPC</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Following an R.T.A. the patient has neck and lower back pain</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Services Seen</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> G.P.</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Treatment Type</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Painkillers</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">After How Long </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Seen within 1 week</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Investigation</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> NIL</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Present Symptoms</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Moderate</span>
            </div>
        </div>
    </div>
    <div class="reportDialogHeader">
        <p>PMH</p>
    </div>
    <div class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">PMH</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> None to record</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">PMH Checked</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Yes</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Special Questions</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> No red flags</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Special Questions Checked</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Yes</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Systemic Symptoms </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">No</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Neurological Symptoms</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> No</span>
            </div>
        </div>
    </div>


    <div class="reportDialogHeader">
        <p>P1</p>
    </div>
    <div class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Duration</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> I/M</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Description</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Pain</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">V.A.S </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> V.A.S 3/10</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">AGGS</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Lifting</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Eases </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">Exercise</span>
            </div>
        </div>
    </div>





    <!-- QTFC Table -->
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="tablereportHeading">
                <p>QTFC (Quebec Task Force Classification)</p>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="reportTable">
                <p-table [value]="reportData" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <!-- <tr>
                        <th>Name</th>
                        <th>Time</th>
                      </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-reportlist>
                        <tr>
                            <td>{{reportlist.reportName}}</td>
                            <td>{{reportlist.reportSummary}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                No Records Found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="classificationTable">
                <p-table [value]="classificationTbl" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <!-- <tr>
                            <th>Name</th>
                            <th>Time</th>
                          </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-classification>
                        <tr>
                            <td>{{classification.initial}}</td>
                            <td>{{classification.post}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                No Records Found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="therapyListTable">
                <p-table [value]="therapyList | slice:0:5" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <!-- <tr>
                            <th>Name</th>
                            <th>Time</th>
                          </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-thpList>
                        <tr>
                            <td>{{thpList.theadings}}</td>
                            <td>{{thpList.name}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                No Records Found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</p-dialog>
</section>
<!-- Report  -->

