<ngx-ui-loader></ngx-ui-loader>


<div *ngIf="showAssessment">
<!-- CPA logo-->
    <div class="reportLogo">
        <img src="assets/theme1/custom-files/img/logos/cpalogo.png">
    </div>

<!-- Initial Assessment-->
    <div *ngIf="assessmentData?.initialAssessment && assessmentData?.initialAssessment.length>0" class="reportDialogHeader">
        <p>Initial Assessment</p>
    </div>
    <div *ngIf="assessmentData?.initialAssessment && assessmentData?.initialAssessment.length>0" class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Name</span>
            </div>
            <div class="p-col-12 p-md-6">
<!--                <span class="report-title-value">{{assessmentData?.initialAssessment[0]?.patientName}}</span>-->
                <span class="report-title-value">{{assessmentData?.initialAssessment?assessmentData?.initialAssessment[0].patientName :''}}</span>
<!--                <span class="report-title-value">{{checkUndefined(assessmentData,'initialAssessment')?.patientName}}</span>-->
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Address</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{assessmentData?.initialAssessment?assessmentData?.initialAssessment[0].patientAddress : ''}}</span>
<!--                <span class="report-title-value">{{checkUndefined(assessmentData,'initialAssessment')?.patientAddress}}</span>-->
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Email</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{assessmentData?.initialAssessment?assessmentData?.initialAssessment[0].patientEmail: ''}}</span>
<!--                <span class="report-title-value"> {{checkUndefined(assessmentData,'initialAssessment')?.patientEmail}}</span>-->
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Contact</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{assessmentData?.initialAssessment ? assessmentData?.initialAssessment[0].patientContact: ''}}</span>
<!--                <span class="report-title-value"> {{checkUndefined(assessmentData,'initialAssessment')?.patientContact}}</span>-->
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Gender</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{assessmentData?.initialAssessment ? assessmentData?.initialAssessment[0].patientGender.label: ''}}</span>
<!--                <span class="report-title-value"> {{checkUndefined(assessmentData,'initialAssessment')?.patientGender}}</span>-->
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Patient Date of Birth</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">
                    {{utils.returnDateFromMilliSecond(assessmentData?.initialAssessment? assessmentData.initialAssessment[0].patientId?.dob: '')}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Date of Accident</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{utils.returnDateFromMilliSecond(assessmentData?.initialAssessment? assessmentData.initialAssessment[0].dateOfAccident: '')}}</span>
            </div>
        </div>
    </div>

<!-- PCH Detail-->
    <div *ngIf="assessmentData?.pch && assessmentData?.pch.length>0" class="reportDialogHeader">
        <p>PCH</p>
    </div>
    <div *ngIf="assessmentData?.pch && assessmentData?.pch.length>0" class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Initial Assessment Date </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{utils.returnDateFromMilliSecond(assessmentData?.pch ? assessmentData.pch[0].initialAssesmentDate:'')}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">HPC</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{assessmentData?.pch ? assessmentData.pch[0]?.hpc:''}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Services Seen</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(assessmentData?.pch ? assessmentData.pch[0]?.serviceSeenDetail:'')}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Treatment Type</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(assessmentData?.pch ? assessmentData.pch[0]?.treatmentTypeDetail :'')}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">After How Long </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> Seen within 1 week</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Investigation</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(assessmentData?.pch ? assessmentData.pch[0]?.investigationDetail:'')}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Present Symptoms</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(assessmentData?.pch ? assessmentData.pch[0]?.presentSymptomsDetail:'')}}</span>
            </div>
        </div>
    </div>

    

<!-- PMH Detail-->
    <div *ngIf="assessmentData?.pmh && assessmentData?.pmh.length>0" class="reportDialogHeader">
        <p>PMH</p>
    </div>
    <div *ngIf="assessmentData?.pmh && assessmentData?.pmh.length>0" class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">PMH</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{assessmentData?.pmh ? assessmentData.pmh[0]?.pmh:''}}</span>
            </div>
        </div>
<!--        <div class="p-grid">-->
<!--            <div class="p-col-12 p-md-6">-->
<!--                <span class="report-title">PMH Checked</span>-->
<!--            </div>-->
<!--            <div class="p-col-12 p-md-6">-->
<!--                <span class="report-title-value"> Yes</span>-->
<!--            </div>-->
<!--        </div>-->
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Special Questions</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(assessmentData?.pmh ? assessmentData.pmh[0]?.specialQuestionsDetail:'')}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Special Questions Checked</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{assessmentData?.pmh ? assessmentData.pmh[0]?.specialQuestionsChecked?.value:''}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Systemic Symptoms </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{assessmentData?.pmh ? assessmentData.pmh[0]?.systemic?.value:''}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Neurological Symptoms</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{assessmentData?.pmh ? assessmentData.pmh[0]?.neurologicalSymptoms?.value:''}}</span>
            </div>
        </div>
    </div>
<!-- Exercise Prescription -->
<div *ngIf="assessmentData?.patientExercise && assessmentData?.patientExercise.length>0" class="reportDialogHeader">
    <p>Exercise Prescription</p>
</div>
<div *ngIf="assessmentData?.patientExercise && assessmentData?.patientExercise.length>0" class="report-wrapper">
    <div class="p-grid" *ngFor="let pe of assessmentData.patientExercise" >
        <div class="p-col-12 p-md-6">
            <span class="report-title">{{pe?.exerciseId?.title}} </span>
        </div>
        <div class="p-col-12 p-md-6">
            <span class="report-title-value"> {{pe?.exerciseId?.description}}</span>
        </div>
    </div>
</div>

<!-- Body Chart Detail-->
<div *ngFor=" let part of assessmentData?.bodyChart ; let i = index">
    <div class="reportDialogHeader">
        <p>P{{i+1}} - {{part.bodyPart?.label}}</p>
    </div>
<!--    Duration.label-->
<!--    descriptionDetail[0].lovValues-->
<!--    vas.label-->
<!--    aggsDetail[0].lovValues-->
<!--    easesDetail[0].lovValues-->
    <div class="report-wrapper">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Duration</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{part.duration?.label}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Description</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(part.descriptionDetail)}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">V.A.S </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value"> {{part.vas?.label}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">AGGS</span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(part.aggsDetail)}}</span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <span class="report-title">Eases </span>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="report-title-value">{{getLov(part.easesDetail)}}</span>
            </div>
        </div>
    </div>
</div>

<!-- QTFC Table -->
    <div *ngIf="assessmentData?.outcomeMeasureDetail && assessmentData?.outcomeMeasureDetail.length>0" class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="tablereportHeading">
                <p>QTFC (Quebec Task Force Classification)</p>
            </div>
        </div>
    </div>

    <div *ngIf="assessmentData?.outcomeMeasureDetail && assessmentData?.outcomeMeasureDetail.length>0" class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="reportTable">
                <p-table [value]="assessmentData?.outcomeMeasureDetail" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header"></ng-template>
                    <ng-template pTemplate="body" let-wadList>
                        <tr>
                            <td>{{wadList.label}}</td>
                            <td>{{wadList.value}}</td>
                        </tr>
                    </ng-template>
<!--                    <ng-template  pTemplate="emptymessage" let-columns>-->
<!--                        <tr>-->
<!--                            <td>-->
<!--                                No Records Found-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </ng-template>-->
                </p-table>
            </div>
        </div>
    </div>
    <div *ngIf="assessmentData?.outcomeMeasureDetail && assessmentData?.outcomeMeasureDetail.length>0" class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="classificationTable">
                <p-table [value]="classificationTbl" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header"></ng-template>
                    <ng-template pTemplate="body" let-classification>
                        <tr>
                            <td>classification On Initial Assesment</td>
                            <td>classification Post Treatment</td>
                        </tr>
                        <tr>
                            <td>{{assessmentData?.outcomeMeasure ? assessmentData.outcomeMeasure[0]?.classificationOnInitialAssesment:''}}</td>
                            <td>{{assessmentData?.outcomeMeasure ? assessmentData.outcomeMeasure[0]?.classificationPostTreatment:''}}</td>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                No Records Found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <!-- Notes -->
<div *ngIf="assessmentData?.treatment && assessmentData?.treatment.length>0" class="reportDialogHeader">
    <p>Notes</p>
</div>
<div *ngIf="assessmentData?.treatment && assessmentData?.treatment.length>0" class="report-wrapper">
    <div class="p-grid">
        <!-- <div  class="p-col-12 p-md-6">
            <span class="report-title">{{treatment.comments}} </span>
        </div> -->
        <div *ngFor="let treatment of assessmentData.treatment" class="p-col-12 p-md-6">
            <span class="report-title-value"> {{treatment.comments}}</span>
        </div>
    </div>
</div>

    <div *ngIf="assessmentData?.initialAssessment && assessmentData?.initialAssessment.length>0" class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="therapyListTable">
                <div class="p-grid">
                    <div class="p-col-12 p-md-6 report-title">Client Name</div>
                    <div class="p-col-12 p-md-6 report-title-value">{{assessmentData?.initialAssessment ? assessmentData.initialAssessment[0]?.patientName:''}}</div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-6 report-title">Therapist Name</div>
                    <div class="p-col-12 p-md-6 report-title-value"> {{assessmentData?.initialAssessment ? assessmentData.initialAssessment[0]?.expertId.firstName:'' + ' ' + assessmentData?.initialAssessment ? assessmentData.initialAssessment[0]?.expertId.lastName:'' }}</div>
                    <div class="p-col-12 p-md-6 report-title-value"> {{assessmentData?.initialAssessment ? assessmentData.initialAssessment[0]?.expertId.firstName:'' + ' ' + assessmentData?.initialAssessment ? assessmentData.initialAssessment[0]?.expertId.lastName:'' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!showAssessment">
    <span>No Initial Assessment data available</span>
</div>
