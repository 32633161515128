<!-- Header Component-->
<!-- <p-toast position="top-center"></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameClinics)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton pTooltip="Advanced Search"
                                    tooltipPosition="bottom" type="button"></button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton pTooltip="{{addEditTitle}} {{translate(labels.entityNameClinics)}}"  tooltipPosition="bottom"
                                    type="button"></button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameClinics)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="clinicsSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnClinicName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('clinicName')"
                                                    *ngIf="diplayeIconInField('clinicName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="clinicName" name="clinicName" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnContactName)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('contactName')"
                                                    *ngIf="diplayeIconInField('contactName')"
                                                    class="pi pi-times color-danger"></i>
                                                <input class="pngInputtext" formControlName="contactName"
                                                    name="contactName" pInputText type="text">
                                            </span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnTelNumber)}}</label>

                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('telNumber')"
                                                    *ngIf="diplayeIconInField('telNumber')"
                                                    class="pi pi-times color-danger"></i>
                                                <input class="pngInputtext" formControlName="telNumber" name="telNumber"
                                                    pInputText type="text">
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('address')"
                                                    *ngIf="diplayeIconInField('address')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="address" name="address" pInputText type="text">
                                            </span>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnClinicTypeId)}} </label>
                                            <p-dropdown [filter]="true" [options]="clinicTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="clinicTypeId"
                                                        placeholder="Select">
                                            </p-dropdown>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnNotes)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('notes')"
                                                    *ngIf="diplayeIconInField('notes')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="notes" name="notes" pInputText type="text"> </span>
                                        </div>


                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox  -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}}  {{translate(labels.entityNameClinics)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="clinicsForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnClinicName)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('clinicName')"
                                                    *ngIf="diplayeIconInField('clinicName')"
                                                    class="pi pi-times color-danger">
                                                </i>
                                                <input [class.p-invalid]="inputFieldDanger('clinicName')"
                                                    class="pngInputtext" formControlName="clinicName" name="clinicName"
                                                    pInputText type="text">
                                            </span>
                                            <app-custom-error [control]="'clinicName'" [form]="clinicsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnContactName)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('contactName')"
                                                    *ngIf="diplayeIconInField('contactName')"
                                                    class="pi pi-times color-danger"></i>
                                                <input [class.p-invalid]="inputFieldDanger('contactName')"
                                                    class="pngInputtext" formControlName="contactName"
                                                    name="contactName" pInputText type="text">
                                            </span>
                                            <app-custom-error [control]="'contactName'" [form]="clinicsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnTelNumber)}}</label>

                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('telNumber')"
                                                    *ngIf="diplayeIconInField('telNumber')"
                                                    class="pi pi-times color-danger"></i>
                                                <input [class.p-invalid]="inputFieldDanger('telNumber')"
                                                    class="pngInputtext" formControlName="telNumber" name="telNumber"
                                                    pInputText type="text">
                                            </span>
                                            <app-custom-error [control]="'telNumber'" [form]="clinicsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('address')"
                                                    *ngIf="diplayeIconInField('address')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('address')" class="pngInputtext"
                                                    formControlName="address" name="address" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'address'" [form]="clinicsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnClinicTypeId)}}</label>
                                            <p-dropdown [filter]="true" [options]="clinicTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="clinicTypeId"
                                                placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'clinicTypeId'" [form]="clinicsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.clinicsColumnNotes)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('notes')" *ngIf="diplayeIconInField('notes')"
                                                    class="pi pi-times color-danger"></i>
                                                <input [class.p-invalid]="inputFieldDanger('notes')"
                                                    class="pngInputtext" formControlName="notes" name="notes" pInputText
                                                    type="text">
                                            </span>
                                            <app-custom-error [control]="'notes'" [form]="clinicsForm">
                                            </app-custom-error>
                                        </div>

                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>

                                    <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" />
                                    </span>

                                </p-header>

                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChange($event)" [autoLayout]="true"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                    [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [alwaysShowPaginator]="false"
                                    [showCurrentPageReport]=true [totalRecords]="totalRecords" [value]="clinicsList"
                                    class="pngTable" [ngClass]="{'pngTableEmptyMessage': clinicsList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>

                                            <th> Clinic Name</th>
                                            <th> Contact Name</th>
                                            <!-- <th> Specialist Type</th> -->
                                            <th> Telephone Number</th>
                                            <th> Address</th>
                                            <!-- <th> Notes</th> -->

                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>

                                            <td> {{element?.clinicName}} </td>
                                            <td> {{element?.contactName}} </td>
                                            <!-- <td> {{element?.specialistType}} </td> -->
                                            <td> {{element?.telNumber}} </td>
                                            <td> {{element?.address}} </td>
                                            <!-- <td> {{element?.notes}} </td> -->

                                            <td>

                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadClinicsToEdit(element.clinicId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdClinics(element.clinicId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                    (click)="viewClinicDetail(element.clinicId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
