import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ObservationsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdObservations(observationsId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Observations/deleteObservations', {'observationsId': observationsId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countObservations(observations): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Observations/count', {'observations': observations}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateObservations(observations): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Observations/updateObservations', [observations], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addObservations(observations): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Observations/addObservations', [observations], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addObservationsCpa(observationsDTO): Observable<any> {
    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'observations', observationsDTO, {headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }

    findAllObservations(observations, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Observations/findAllObservations',
            {'observations': observations, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllObservationsCpa(patientId: number): Observable<any> {
      return this.httpClient.get<any>(

        Constant.CPA_SPRING_API_URL + 'patients/' + patientId  + '/observations', {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      ).pipe(
      retry(3), catchError(Constant.handleError));
    }

  findAllObservationsByAppointmentId(appointmentId: number): Observable<any> {
    return this.httpClient.get<any>(

      Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId  + '/observations', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }


  findObservationById(observationId: number): Observable<any> {
    return this.httpClient.get<any>(

      Constant.CPA_SPRING_API_URL + 'observations/' + observationId, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
    }


  // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Observations/countGlobalObservations',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Observations/findGlobalObservations',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
