import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencySubscriptionChecklistService} from '../web-services/agency-subscription-checklist.service';
import {AgencyService} from '../web-services/agency.service';
import {UsersService} from '../web-services/users.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-agency-subscription-checklist',
               templateUrl: './agency-subscription-checklist.component.html',
               styleUrls: ['./agency-subscription-checklist.component.css']
           })
export class AgencySubscriptionChecklistComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAgencySubscriptionChecklistId = null;
    agencyList: any = [];
    usersList: any = [];
    listOfValuesDetailList: any = [];
    agencySubscriptionChecklistList: any = [];
    agencySubscriptionChecklistSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencySubscriptionChecklistForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private agencySubscriptionChecklistServices: AgencySubscriptionChecklistService, private agencyServices: AgencyService,
        private usersServices: UsersService, private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService, private messageService: MessageService,
        private route: Router, private loader: NgxUiLoaderService, private constantService: ConstantService,
        private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateAgencySubscriptionChecklistId == null ? this.translate(this.labels.btnAdd) : this.translate(
            this.labels.btnUpdate);
    }

    onClearSearch() {
        this.agencySubscriptionChecklistSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgencySubscriptionChecklist();
        this.findAllAgencySubscriptionChecklist();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countAgencySubscriptionChecklist() {
        this.agencySubscriptionChecklistServices.countAgencySubscriptionChecklist(this.agencySubscriptionChecklistSearch.value).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.totalRecords = res['data'][0];
                    this.findAllAgencySubscriptionChecklist();
                } else {
                    this.totalRecords = 0;
                }
            }, err => {
                console.error('err: ', err);
            });
    }

    onSubmit() {
        this.addUpdateAgencySubscriptionChecklist(this.agencySubscriptionChecklistForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAgencySubscriptionChecklist();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencySubscriptionChecklistSearch.reset();
        this.countAgencySubscriptionChecklist();
        // this.findAllAgencySubscriptionChecklist();
    }

    addUpdateAgencySubscriptionChecklist(agencySubscriptionChecklist) {
        this.startLoader();
        if (this.updateAgencySubscriptionChecklistId == null) {
            this.agencySubscriptionChecklistServices.addAgencySubscriptionChecklist(this.agencySubscriptionChecklistForm.value).subscribe(
                res => {
                    if (res['message'] == 'Success') {
                        agencySubscriptionChecklist = res['data'][0];
                        this.agencySubscriptionChecklistList.push(agencySubscriptionChecklist);
                        this.messageService.add({
                                                    severity: 'success',
                                                    summary: this.translate(this.labels.btnAdd),
                                                    detail: this.translate(this.labels.entityNameAgencySubscriptionChecklist)
                                                });
                        this.submitted = true;
                        this.showHideAddDialog();
                        this.countAgencySubscriptionChecklist();
                    } else {
                        console.error(res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
        } else {
            agencySubscriptionChecklist.agencySubscriptionChecklistId = this.updateAgencySubscriptionChecklistId;
            this.agencySubscriptionChecklistServices.updateAgencySubscriptionChecklist(agencySubscriptionChecklist).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencySubscriptionChecklistList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameAgencySubscriptionChecklist)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countAgencySubscriptionChecklist();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencySubscriptionChecklistId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllAgency();
        this.findAllUsers();
        this.findAllListOfValuesDetail();
        this.agencySubscriptionChecklistForm = new FormGroup({
                                                                 agencyId: new FormControl(),
                                                                 userId: new FormControl(),
                                                                 checklistItemsId: new FormControl(),
                                                                 itemsStatus: new FormControl('', [Validators.required, validateSpaces]),
                                                             });
        this.agencySubscriptionChecklistSearch = new FormGroup(
            {agencyId: new FormControl(), userId: new FormControl(), checklistItemsId: new FormControl(), itemsStatus: new FormControl(),});
        this.countAgencySubscriptionChecklist();
        this.findAllAgencySubscriptionChecklist();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencySubscriptionChecklistSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllAgencySubscriptionChecklist() {
        this.startLoader();
        this.agencySubscriptionChecklistServices.findAllAgencySubscriptionChecklist(
            this.agencySubscriptionChecklistSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencySubscriptionChecklistList = res['data'];
                } else {
                    this.agencySubscriptionChecklistList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgencySubscriptionChecklist(agencySubscriptionChecklistId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencySubscriptionChecklistServices.deleteByIdAgencySubscriptionChecklist(agencySubscriptionChecklistId).subscribe(
                    res => {
                        if (res['message'] == 'Success') {
                            this.agencySubscriptionChecklistList.splice(index, 1);
                            this.messageService.add({
                                                        severity: 'warn',
                                                        summary: this.translate(this.labels.entityNameAgencySubscriptionChecklist) +
                                                            this.translate(this.labels.deletedSuccessfully),
                                                        detail: ''
                                                        // detail: this.translate(this.labels.entityNameAgencySubscriptionChecklist)
                                                    });
                            this.countAgencySubscriptionChecklist();
                        } else {
                            console.error('error: ', res['documentation']);
                            this.startLoader();
                        }
                    }, err => {
                        console.error('err: ', err);
                        this.stopLoader();
                    });
            }
                                  });
    }

    loadAgencySubscriptionChecklistToEdit(agencySubscriptionChecklistId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let agencySubscriptionChecklist = this.agencySubscriptionChecklistList.find(
            x => x['agencySubscriptionChecklistId'] == agencySubscriptionChecklistId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencySubscriptionChecklistId = agencySubscriptionChecklist['agencySubscriptionChecklistId'];
        this.selectedIndex = index;
        this.agencySubscriptionChecklistForm.controls['agencyId'].setValue(agencySubscriptionChecklist['agencyId']['agencyId']);
        this.agencySubscriptionChecklistForm.controls['userId'].setValue(agencySubscriptionChecklist['userId']['userId']);
        this.agencySubscriptionChecklistForm.controls['checklistItemsId'].setValue(
            agencySubscriptionChecklist['checklistItemsId']['checklistItemsId']);
        this.agencySubscriptionChecklistForm.controls['itemsStatus'].setValue(agencySubscriptionChecklist['itemsStatus']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.agencySubscriptionChecklistForm.reset();
        this.agencySubscriptionChecklistForm.markAsUntouched();
        this.updateAgencySubscriptionChecklistId = null;
        closeAddOverlaydiv();
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.agencySubscriptionChecklistForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencySubscriptionChecklistForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencySubscriptionChecklistForm.get(control)?.valid && this.agencySubscriptionChecklistForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.agencySubscriptionChecklistForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.agencySubscriptionChecklistSearch.reset();
    }

    refresh() {
        this.countAgencySubscriptionChecklist();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.agencySubscriptionChecklistForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencySubscriptionChecklistForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAgencySubscriptionChecklistId = null;
    }
}
