<p-dialog header="Add Value" [modal]="true" [(visible)]="display">
    <input type="text" pInputText [(ngModel)]="dropdown"/>
  
    <button pButton type="button" label="Click" (click)="adddropdown()"></button>
  </p-dialog>
  <form [formGroup]="form">
  
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-6">
        <label for="firstname2">Eases</label>
        <i class="pi pi-plus myplusicon" (click)="showDialog()"></i>
  
        <p-multiSelect [options]="cities" formControlName="selectedCities" optionLabel="name" display="chip">
        </p-multiSelect>
        
  
        <!-- <pre>{{form.value| json}}</pre> -->
      </div>
    </div>
  </form>
