import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PatientExercisesService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdPatientExercises(patientExercisesId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PatientExercises/deletePatientExercises', {'patientExercisesId': patientExercisesId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countPatientExercises(patientExercises): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'PatientExercises/count', {'patientExercises': patientExercises},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updatePatientExercises(patientExercises): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PatientExercises/updatePatientExercises', [patientExercises], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPatientExercises(patientExercises): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PatientExercises/addPatientExercises', [patientExercises], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllPatientExercises(patientExercises, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PatientExercises/findAllPatientExercises',
            {'patientExercises': patientExercises, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
