<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
  <div class="container">
    <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
      <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
        <div class="overlaypaneldiv" id="overlaymain">
          <p-panel class="pngPanelMain" id="patients-pngPanelMain">
            <p-header> Cpa Clients {{clients[0].companyName}}
              <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                <button
                  class="p-button-rounded p-button-text" icon="pi pi-plus"
                  (click)="baseComponent.showHideAddDialog()" [hidden]="baseComponent.showAddBtn"
                  pButton pTooltip="{{baseComponent.addEditTitle}} {{baseComponent.translate(baseComponent.labels.entityNameCpaRole)}}"  tooltipPosition="bottom"
                  type="button" id="addOverlayBtn"></button>
              </div>
            </p-header>
            <p-dialog header="CPA Clients"
                      [(visible)]="baseComponent.viewEdit"
                      [modal]="true"
                      [style]="{width: '50vw', height: '75%'}"
                      [baseZIndex]="10000"
                      [draggable]="false">

              <ng-template *ngIf="!clients" pTemplate="emptymessage">
                {{baseComponent.translate(baseComponent.labels.mat_pagination_no_data_msg)}}
              </ng-template>

              <div *ngFor="let item of clients; let i = index">sss
                sssss
              </div>
              <ng-template pTemplate="footer">
                <div class="p-d-flex p-jc-end">
                  <div class="p-mr-3">

                  </div>
                  <div *ngIf="clients && clients.length > 0">

                  </div>
                </div>
              </ng-template>
            </p-dialog>
            <!-- Add DialogBox-->
            <div [ngClass]="{'displayBlock' : baseComponent.showAddDialog == true, 'displayNone' : baseComponent.showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
              <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                <p-header>{{baseComponent.addEditTitle}} {{baseComponent.translate(baseComponent.labels.entityNameCpaRole)}}
                </p-header>
                <form (ngSubmit)="onSubmit()" [formGroup]="baseComponent.baseForm" class="pngForm"
                      id="invitationpage-addmemeber-pngForm">
                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>Company Name</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="companyName" name="companyName"
                             type="text">
                    </div>
                  </div>

                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>Email Address</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="email" name="email"
                             type="text">
                    </div>
                  </div>

                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>Password</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="password" name="password"
                             type="password">
                    </div>
                  </div>

                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>First Name</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="firstName" name="firstName"
                             type="text">
                    </div>
                  </div>

                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>Last Name</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="lastName" name="lastName"
                             type="text">
                    </div>
                  </div>




                  <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                    <p-button label="{{baseComponent.addEditTitle === baseComponent.labels.btnAdd? baseComponent.labels.btnSave : baseComponent.labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                    <p-button label="Close" (click)="baseComponent.showHideAddDialog()" styleClass="p-button" class="pngBtnTransparent">
                    </p-button>
                  </div>
                </form>
              </p-panel>
            </div>
            <div class="overlaypaneldiv" id="overlaypaneldiv-list">
              <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                <p-header>


                </p-header>
                <p-table    #dv *ngIf="clients.length > 0"
                             [autoLayout]="true"
                            [lazy]="true" [paginator]="true" [resizableColumns]="true"
                            [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [showCurrentPageReport]=true
                            [totalRecords]="baseComponent.totalRecords" [value]="clients" class="pngTable" [ngClass]="{'pngTableEmptyMessage': clients?.length == 0}"
                            id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                  <ng-template pTemplate="header">
                    <tr>
                      <th> Client Id</th>
                      <th> Company Name</th>
                      <th>

                      </th>
                    </tr>
                  </ng-template>
                  <ng-template let-element let-i="rowIndex" pTemplate="body">
                    <tr>
                      <td> {{element?.clientId }} </td>
                      <td> {{element?.companyName}} </td>
                      <td>

                        <div class="pngTable-icons">
                          <p-button
                            (click)="loadCpaRoleToEdit(element.cpaRoleId, i)"
                            icon="pi pi-pencil" pTooltip="Edit"
                            styleClass="p-button pbtn-transparent">
                          </p-button>
                          <p-button
                            (click)="deleteByIdExpenses(element.expensesId,i)"
                            icon="pi pi-trash" pTooltip="Delete"
                            styleClass="p-button pbtn-transparent">
                          </p-button>
                          <p-button
                            (click)="editCpaApplicationRoles(element.cpaRoleId,i)"
                            icon="pi pi-exclamation-triangle" pTooltip="Cpa Application Roles"
                            styleClass="p-button pbtn-transparent">
                          </p-button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div *ngIf="baseComponent.totalRecords < 1">
                  <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                </div>
              </p-panel>
            </div>

          </p-panel>
        </div>
      </div>
    </section>
  </div>
</section>
