import {HttpPatchItem} from "./HttpPatchItem";

export class HttpPatch {


   jsonPatch = [];

  constructor() {}

  pushToJsonPatch(httpPatchItem: HttpPatchItem): any {

    this.jsonPatch.push(httpPatchItem);



  }
}
