<!-- <app-app-header></app-app-header> -->
<section class="sectionmain" id="sectionmain-admin">
    <div class="container-fluid">
        <div class="p-fluid p-formgrid p-grid admin-grid">
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/physiotherapy_patients']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/Patient.svg">
                        <div class="dashboard-card-text">
                            <p>Physiotherapy Patients</p>
                            <h3 class="dashboardText">{{physiotherapyPatients}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/psychology_patients']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/Patient.svg">
                        <div class="dashboard-card-text">
                            <p>Psychology Patients</p>
                            <h3 class="dashboardText">{{psychologyPatients}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/patients']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/patient%20(1).svg">
                        <div class="dashboard-card-text">
                            <p>Total Patients</p>
                            <h3 class="dashboardText">{{totalPatients}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/active_patient']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/live-patients.svg">
                        <div class="dashboard-card-text">
                            <p>Live Patients</p>
                            <h3 class="dashboardText">{{activePatients}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/deactivated_patient']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/deactivated-patients.svg">
                        <div class="dashboard-card-text">
                            <p>Deactivated Patients</p>
                            <h3 class="dashboardText">{{deActivatedPatients}}</h3>
                        </div>
                    </div>
                </div>
            </div>

<!--Patient by Profession-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/patient_by_profession']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/Patient.svg">
                        <div class="dashboard-card-text">
                            <p>Patients By Profession</p>
                        </div>
                    </div>
                </div>
            </div>

<!--Start-Commented for UAT-->

<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/pending-invoices.svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Pending Invoice</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/Patient-case%20(2).svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Total Completed</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/Patient-case%20(2).svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Patient By Profession</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--End-Commented for UAT-->
        </div>
    </div>
</section>
