import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DrugHistoryService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdDrugHistory(drugHistoryId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'DrugHistory/deleteDrugHistory', {'drugHistoryId': drugHistoryId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countDrugHistory(drugHistory): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'DrugHistory/count', {'drugHistory': drugHistory}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateDrugHistory(drugHistory, refData): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(Constant.BASE_URL + 'DrugHistory/updateDrugHistory', [drugHistory, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addDrugHistory(drugHistory, refData): Observable<string> {
      console.log(drugHistory);
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(Constant.BASE_URL + 'DrugHistory/addDrugHistory', [drugHistory, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllDrugHistory(drugHistory, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'DrugHistory/findAllDrugHistory',
            {'drugHistory': drugHistory, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findDrugHistoryByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'patients/' + patientId + '/drug-histories', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
    }

  findDrugHistoryByAppointmentId(appointmentId: number): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/drug-histories', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }



  // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'DrugHistory/countGlobalDH',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'DrugHistory/findGlobalDH',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addDrugHistoryCpa(drugHistory): Observable<any> {
    console.log('drugHistory', drugHistory);

    return this.httpClient.post<string>(
      Constant.CPA_SPRING_API_URL + 'drug-histories', drugHistory, {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }

}
