import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {CollaborationSharedServiceService} from '../collaboration-shared-service/collaboration-shared-service.service';
import {AppSettings} from '../../constants';
// import { SharedService } from '../../../services/sharedservice/shared.service';
// import {SessionService} from 'src/app/web-services/session.service';
import {Constant} from 'src/app/util/constant';

@Injectable({
                providedIn: 'root'
            })
export class ChatService {

    //--------------Initializing Urls-------------

    private _getAllChatsUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'ChatServiceClass/getallchats';    // Getting All Chats Url
    private _getContexualChats = AppSettings.COLLABORATION_API_ENDPOINT + 'ChatServiceClass/getallcontexualchats';  // getting all Contexual Chats Url
    private _getChatsByUserUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'ChatServiceClass/getchat';  // Finding Single Chat Between two individuals
    private _deleteChatUrls = AppSettings.COLLABORATION_API_ENDPOINT + 'ChatServiceClass/deletechat';

    //--------------- Group Url -----------------

    private _insertGroupUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'GroupServiceClass/insertgroup';    // inserting group
    private _updateGroupUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'GroupServiceClass/updategroup';    // inserting group

    //-------------- Message Url ----------------

    private _getAllMessagesUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'MessageServiceClass/getallmessages';    // display all messages url
    private _insertMessageUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'MessageServiceClass/insertmessage';    // insert messages url
    private _getPreviousMessagesUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'MessageServiceClass/getpreviousmessages';
    private _deleteMessageUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'MessageServiceClass/deletemessage';

    //------------ Participants Url -------------

    private commonParticipantUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'GroupParticipantServiceClass/';
    private _getAllParticipantUrl = this.commonParticipantUrl + 'getallgroupparticipants';    // display all groupparticipants url
    private _deleteParticipantUrl = this.commonParticipantUrl + 'deletegroupparticipant';    // display all groupparticipants url
    private _leaveGroupUrl = this.commonParticipantUrl + 'leavegroup';    // display all groupparticipants url
    private _insertGroupParticipantUrl = this.commonParticipantUrl + 'insertnewgroupparticipant';    // display all groupparticipants url

    private exceptionServiceUrl = 'HiveExceptionLogger/rest/ExpLog/ExpLogging';


    //-------- Register Notification Url----------
    // private mainUrl1 = 'http://192.168.2.40:8080/';
    private registerEvent_Url = AppSettings.NTFY_API_ENDPOINT + 'EventRegisterServiceClass/registerNewEvent';

    //---------------- User Url ------------------

    private commonUserUrl = AppSettings.COLLABORATION_API_ENDPOINT + 'UserServiceClass/';
    private _getAllCollaboratorUrl = this.commonUserUrl + 'getallcollaborators';    // display all chats url

    private getProjectTeamUrl = AppSettings.UMS_API_ENDPOINT + 'getProjectTeam';

    //-------------- Constructor ----------------
    constructor(private http: HttpClient,
                private handler: HttpBackend,
                private sharedService: CollaborationSharedServiceService,
                private cookieService: CookieService,
                // private sessionSerive: SessionService
    ) {
        this.http = new HttpClient(handler);
    }

    //-------------Chat Web Services -------------

    // getLoggedInUserDetail() {
    //   return this.http.post<string>(Constant.CHAT_URL + AppSettings.UMS_API_ENDPOINT + "getLoggedInUserDetail", { "token": this.globalSharedService.getCookie('token') });
    // }

    // updateCurrentRole(activatedRole, projectId, applicationId) {
    //   // console.log(this.cookieService.get("loggedInUser"));
    //   return this.http.post<AuthToken>(Constant.CHAT_URL + AppSettings.UMS_API_ENDPOINT + "updateActiveRole", { "projectId": projectId, "userId": this.globalSharedService.getCookie("loggedInUser"), "businessRoleId": activatedRole, "applicationId": applicationId }).pipe(catchError(this.errorHandler));
    // }


    getChats(chat, timestamp): Observable<string> {   // Get All Chats
        // console.log("chat  : :", Constant.CHAT_URL);
        // console.log(chat, timestamp)
        // console.log(chat);

        return this.http.post<string>(Constant.CHAT_URL + this._getAllChatsUrl, {chat, 'timeStamp': timestamp}).pipe(
            catchError(this.errorHandler));
    }

    getContexualChats(chat, timestamp): Observable<string> { // Get All Contextual Chats
        return this.http.post<string>(Constant.CHAT_URL + this._getContexualChats, {chat, 'timeStamp': timestamp}).pipe(
            catchError(this.errorHandler));
    }

    getChatbyusers(chat): Observable<string> {  //  Get Single Chat when Collaborator Selected
        return this.http.post<string>(Constant.CHAT_URL + this._getChatsByUserUrl, chat).pipe(catchError(this.errorHandler));
    }

    deleteChat(chatId,userId): Observable<string> {  //  Get Single Chat when Collaborator Selected
        return this.http.post<string>(Constant.CHAT_URL + this._deleteChatUrls, {'chatId': chatId,'currentUserId':userId}).pipe(catchError(this.errorHandler));
    }

    //-------------Group Web Services -------------

    InsertGroup(group, referenceId): Observable<string> { // Insert Group
        return this.http.post<string>(Constant.CHAT_URL + this._insertGroupUrl, {group, 'reference_id': referenceId}).pipe(
            catchError(this.errorHandler));
    }

    updateGroup(group): Observable<string> { // Insert Group
        return this.http.post<string>(Constant.CHAT_URL + this._updateGroupUrl, group).pipe(catchError(this.errorHandler));
    }

    //----------- Message Web Service -------------

    getMessages(chat, timestamp): Observable<string> { // Get All Messages
        return this.http.post<string>(Constant.CHAT_URL + this._getAllMessagesUrl, {chat, 'timeStamp': timestamp, 'loggedInUser': 2}).pipe(
            catchError(this.errorHandler));
    }

    insertMessage(message, reference_id): Observable<string> { //Insert new Message
        return this.http.post<string>(Constant.CHAT_URL + this._insertMessageUrl, {message, 'reference_id': parseInt(reference_id)}).pipe(
            catchError(this.errorHandler));
    }

    getpreviousMessages(chat, lastMessageId): Observable<string> { //  Get Previous Messages
        return this.http.post<string>(Constant.CHAT_URL + this._getPreviousMessagesUrl, {chat, 'lastMessageId': lastMessageId}).pipe(
            catchError(this.errorHandler));
    }

    deleteMessage(message): Observable<string> { //  Get Previous Messages
        return this.http.post<string>(Constant.CHAT_URL + this._deleteMessageUrl, message).pipe(catchError(this.errorHandler));
    }

    //-------- Participant Web Service ----------

    getParticipants(group_id): Observable<string> { //  Get All Group Participants
        return this.http.post<string>(Constant.CHAT_URL + this._getAllParticipantUrl, group_id).pipe(catchError(this.errorHandler));
    }

    deleteGroupParticipant(group): Observable<string> { //  Get Delete Group Participant
        return this.http.post<string>(Constant.CHAT_URL + this._deleteParticipantUrl, group).pipe(catchError(this.errorHandler));
    }

    leaveGroup(user, group): Observable<string> {  //  Leave Group
        return this.http.post<string>(Constant.CHAT_URL + this._leaveGroupUrl, {'user_id': user, 'group_id': group}).pipe(
            catchError(this.errorHandler));
    }

    insertGroupParticipant(group, participant, user): Observable<string> { //  insert Group Participants
        return this.http.post<string>(
            Constant.CHAT_URL + this._insertGroupParticipantUrl, {'group_id': group, 'participant_id': participant, 'user_id': user}).pipe(
            catchError(this.errorHandler));
    }

    //------ Register Event Web Service -------

    registerEvent(projectId, applicationId, fromUser, toUser, eventId, JSONParameters): Observable<string> {  //  Register Event
        if (projectId === null || projectId === undefined) {
            projectId = 0;
        }
        if (applicationId === null || applicationId === undefined) {
            applicationId = 0;
        }
        return this.http.post<string>(
            Constant.CHAT_URL + this.registerEvent_Url, {
                projectId: projectId,
                applicationId: applicationId,
                fromUser: fromUser,
                toUser: toUser,
                eventId: eventId,
                JSONParameters: JSONParameters
            }).pipe(catchError(this.errorHandler));
    }

    //---------- User Web Service --------------

    getCollaborators(user_id): Observable<string> { //  Get All Collaborators
        return this.http.post<string>(Constant.CHAT_URL + this._getAllCollaboratorUrl, user_id).pipe(catchError(this.errorHandler));
    }

    getMenuItems(userId) {
        return this.http.post<string>(Constant.CHAT_URL + 'SSO/rest/sso/getMenu', {'userId': userId}).pipe(catchError(this.errorHandler));
    }

    // add Task Service
    // addUpdateTask(task: Task) {
    //   var projectObject = this.globalSharedService.getCookie("project");

    //   return this.http.post<string>(Constant.CHAT_URL + 'TMS/rest/tms/addUpdateTask', { task, "project_id": JSON.parse(projectObject)['projectId'], "user_id": this.globalSharedService.getCookie("loggedInUser") });
    // }

    // getFormConstants(formId) {
    //   var languageId = 0;
    //   if (this.globalSharedService.getCookie("userPreference")) {
    //     languageId = JSON.parse(this.globalSharedService.getCookie("userPreference"))['defaultLanguage'];
    //   }
    //   if(languageId){}
    //   else {
    //     languageId = 1;
    //   }

    //   console.log(languageId);

    //   // var languageId = 1;
    //   return this.http.post<string>(Constant.CHAT_URL + AppSettings.UMS_API_ENDPOINT + "getConstants", { "form": formId, "language": languageId });
    // }

    // getProjectTeam() {
    //   var projectObject = this.globalSharedService.getCookie("project");
    //   return this.http.post<string>(Constant.CHAT_URL + this.getProjectTeamUrl, { "userId": 0, "cprojectId": JSON.parse(projectObject)['projectId'], "businessRoleId": 0, "page": -1, "itemsPerPage": 10, "type": "users", "searchUsername": "", "gender": "" }).pipe(catchError(this.errorHandler));
    // }

    // Exception Handling
    // exceptionHandling(error, serviceName): Observable<string> {
    //   return this.http.post<string>(Constant.CHAT_URL + this.exceptionServiceUrl, { "AppName": this.sharedService.applicationName, "exceptionLog": error.toString(), "userId": this.globalSharedService.getCookie("loggedInUser"), "serviceName": serviceName }).pipe(catchError(this.errorHandler));
    // }

    errorHandler(error: HttpErrorResponse) {
        if (!navigator.onLine) {
            // Handle offline error
            return throwError('Error : Connection Error');
        } else {
            return throwError('Error : Server Error');
        }
    }


    // notification services
    private notificationTypeUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/getNtfyTypeList';
    private templateUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/getTemplateList';
    private subscribeUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/subscribeNewEvent';
    private allSubscribedEventsUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/getAllSubscribedEvents';
    private unSubscribeEventUrl = AppSettings.NTFY_API_ENDPOINT + 'NewSubServiceClass/unsubscribEvent';

    private notificationsUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getNotifications';
    private allNotificationsCountUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getAllNotificationsCount';
    private allNotificationsUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getAllNotifications';
    private updateStatusToReadUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/updateNotificationStatustoRead';
    private getNextNotificationUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/getNextNotification';
    private deleteNotificationUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/deleteNotification';
    private markAsReadUrl = AppSettings.NTFY_API_ENDPOINT + 'SendNotificationResourceClass/markAsRead';
    // Register Event Service
    private applicationUrl = AppSettings.UMS_API_ENDPOINT + 'getMenu';

    getAllApplications(user, type, appId): Observable<string> {
        return this.http.post<string>(
            Constant.CHAT_URL + this.applicationUrl, {'userId': user, 'type': type, 'appId': appId, 'projectId': 0, 'businessRoleId': 0});
    }

    // All Events WebService Calling
    getAllEvents(user, type, appId): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.applicationUrl, {'userId': user, 'type': type, 'appId': appId});
    }

    // All NotificationTypes WebService Calling
    getAllNtfyTypes(): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.notificationTypeUrl, {});
    }

    // All TemplateList WebService Calling
    getAllTemplates(ntfyType): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.templateUrl, {'ntfyType': ntfyType});
    }

    // Subscribe New Event WebService Calling
    subscribeNewEvent(jsonObject): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.subscribeUrl, {'subscription': jsonObject});
    }

    // get All Subscribed Events WebService Calling
    getSubscribedEvents(jsonObject): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.allSubscribedEventsUrl, {'subscription': jsonObject});
    }

    // unSubscribe Event WebService Calling
    unsubscribeEvent(eventtoUnsubscribe): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.unSubscribeEventUrl, {'event': eventtoUnsubscribe});
    }

    //----------------------- All Notification Services --------------------------

    // Get Max 10 Notifications in Menu WebService Calling
    getNotifications(user, timeStamp): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.notificationsUrl, {userId: user, timeStamp: timeStamp});
    }

    // Get All Notifications WebService Calling
    getAllNotificationsCount(user): Observable<string> {
        return this.http.post<string>(Constant.CHAT_URL + this.allNotificationsCountUrl, {userId: user});
    }

    // Get All Notifications WebService Calling
    getAllNotifications(user, pageNo, itemsPerPage): Observable<string> {
        return this.http.post<string>(
            Constant.CHAT_URL + this.allNotificationsUrl, {userId: user, pageNo: pageNo, itemsPerPage: itemsPerPage});
    }

    // Update Notification Status to Read
    updateNotificationStatustoRead(ntfyEventDetailId, user): Observable<string> {
        return this.http.post<string>(
            Constant.CHAT_URL + this.updateStatusToReadUrl, {ntfyEventLogDetailId: ntfyEventDetailId, userId: user});
    }

    // Get Next Notification
    getNextNotification(ntfyEventDetailIds, user): Observable<string> {
        return this.http.post<string>(
            Constant.CHAT_URL + this.getNextNotificationUrl, {ntfyEventLogDetailIds: ntfyEventDetailIds, userId: user});
    }

    // Get Next Notification
    deleteNotification(ntfyEventDetailId): Observable<string> {
        if (ntfyEventDetailId == null) {
            var jsonParams = {};
        } else {
            jsonParams = {ntfyEventLogDetailId: ntfyEventDetailId};
        }
        return this.http.post<string>(Constant.CHAT_URL + this.deleteNotificationUrl, jsonParams);
    }

    // Get Next Notification
    markAsRead(ntfyEventDetailId): Observable<string> {
        if (ntfyEventDetailId == null) {
            var jsonParams = {};
        } else {
            jsonParams = {ntfyEventLogDetailId: ntfyEventDetailId};
        }
        return this.http.post<string>(Constant.CHAT_URL + this.markAsReadUrl, jsonParams);
    }

    marknotificationAsRead(ntfyEventDetailId, userId): Observable<string> {
            var jsonParams = {currentUserId: userId};
        return this.http.post<string>(Constant.CHAT_URL + this.markAsReadUrl, jsonParams);
    }

    // Exception Handling
    // exceptionHandling(error, serviceName): Observable<string> {
    // return this.http.post<string>(Constant.CHAT_URL + 'HiveExceptionLogger/rest/ExpLog/ExpLogging', { "AppName": this.sharedService.applicationName, "exceptionLog": error, "userId": this.sharedService.getCookie("loggedInUser"), "serviceName": serviceName });
    // }

    private unreadNotifications = new BehaviorSubject<number>(0);

    public setUnreadNotifications(value) {
        this.unreadNotifications.next(value);
    }

    get getUnreadNotifications() {
        return this.unreadNotifications.asObservable();
    }

    subscribeEvent(data)
    {
        return this.http.post<string>(
            Constant.CHAT_URL +"CPANTFY/rest/NewSubServiceClass/subscribeNewEvent",data)
        .pipe(retry(3),
              catchError(Constant.handleError));
    }
}