import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class OutcomeMeasureImagesService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdOutcomeMeasureImages(outcomeMeasureImageId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasureImages/deleteOutcomeMeasureImages', {'outcomeMeasureImageId': outcomeMeasureImageId},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countOutcomeMeasureImages(outcomeMeasureImages): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'OutcomeMeasureImages/count',
            {'outcomeMeasureImages': outcomeMeasureImages},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateOutcomeMeasureImages(outcomeMeasureImages): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasureImages/updateOutcomeMeasureImages', [outcomeMeasureImages], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addOutcomeMeasureImages(outcomeMeasureImages): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasureImages/addOutcomeMeasureImages', [outcomeMeasureImages], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllOutcomeMeasureImages(outcomeMeasureImages, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'OutcomeMeasureImages/findAllOutcomeMeasureImages',
            {'outcomeMeasureImages': outcomeMeasureImages, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

}
