<section class="sectionmain" id="sectionmain">
    <div class="container">
        <p-card header="" styleClass="p-card-shadow" class="add-payment-outer-card">
            <div class="subscriptions-payment">

                <p-card class="add-payment-inner-card">

                    <div class="cpaToolsTopHeading">
                        <h3 class="cpaToolsTitleBlue cpaToolsTitle">Add Credit</h3>
                    </div>

                    <!-- Tab Content Starts Here -->
                    <div class="p-grid">

                        <div class="p-col-12">
                            <div class="credit-cards">
                                <div class="p-field-checkbox">
                                    <p-checkbox name="cre-card" value="Visa" inputId="vi"></p-checkbox>
                                    <img src="assets/images/visa.svg">
                                </div>
                                <div class="p-field-checkbox">
                                    <p-checkbox name="cre-card" value="Master" inputId="mas"></p-checkbox>
                                    <img src="assets/images/master-card.svg">
                                </div>
                                <div class="p-field-checkbox">
                                    <p-checkbox name="cre-card" value="Paypal" inputId="pp"></p-checkbox>
                                    <img src="assets/images/paypal.svg">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                            <label>Card Holder Name</label>
                            <input class="pngInputtext"
                                   name="agencyFirstname"
                                   pInputText
                                   type="text">
                        </div>
                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                            <label>Card Number</label>
                            <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="false">
                            </p-inputNumber>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                            <label>Expiry Date</label>
                            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2000:2030"></p-calendar>
                        </div>
                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                            <label>CVV</label>
                            <p-inputNumber inputId="withoutgrouping" [useGrouping]="false">
                            </p-inputNumber>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                            <label>Amount</label>
                            <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                                           [maxFractionDigits]="4">
                            </p-inputNumber>
                        </div>
                    </div>
                    <div class="credit-card-btn">
                        <p-button class="pngBlueBtn" label="Submit" styleClass="p-button"></p-button>
                    </div>
                </p-card>
            </div>
            <!-- </div> -->
            <!-- </section> -->
        </p-card>

    </div>
</section>
