export class AppSettings {
    public static API_ENDPOINT = "";
    public static EXCEPTION_API_ENDPOINT = "/HiveExceptionLogger/rest/ExpLog/";
    // public static UMS_API_ENDPOINT = 'UMS/rest/ums/';
    // for Tap Deployment we need to uncomment CPAUMS/rest/ums/ and Have to Comment UMS/rest/ums/
    public static UMS_API_ENDPOINT = 'CPAUMS/rest/ums/';
    public static NTFY_API_ENDPOINT = 'CPANTFY/rest/';
    public static XIGT_API_ENDPOINT = 'XIGT2.0/rest/';
    public static SNOWMED_API_ENDPOINT = 'hwts/api/';
    public static COLLABORATION_API_ENDPOINT = "CPACHAT/rest/";
    public static TRANSLATION_API_ENDPOINT = 'LangTranslate/rest/';
    // public static APPLICATION_SHORT_NAME = "ums";
    public static APPLICATION_SHORT_NAME = "cpa";
    public static SNOWMED_URL = "";
    public static TOKEN_REFRESH_TIME = 37000;
    public static TOKEN_REFRESH_TIME_NEW = 37000;
    // public static TOKEN_REFRESH_TIME = 3000;
    public static TOKEN_EXPIRE_TIME = 240000;
    public static MAX_QUICK_LINKS = 10;
    public static ZERO = 0;
    public static API_SUCCESS_CODE = 200;
    public static API_NO_CONTENT_CODE = 204;
    public static API_BAD_REQUEST_CODE = 400;
    public static API_UNAUTHORIZED_CODE = 401;
    public static API_FORBIDDEN_CODE = 403;
    public static API_NOT_FOUND_CODE = 404;
    public static API_TIME_OUT_CODE = 408;
    public static API_CONFLICT_CODE = 409;
    public static API_INTERNAL_SERVER_ERROR_CODE = 500;
    public static DEFAULT_NOTIFICATION_INTERVAL = 120000;
    public static SNOWMED_TOTAL_NUMBER_OF_Suggestion = 50;
    public static SNOWMED_TOTAL_NUMBER_OF_ITEMS = 50;
    public static INPUT_FILED_MINUIMUM_VALUE = 3;
    public static ITEMS_PER_PAGE = 10;

    public static NOTIFICATION_TIME_INTERVAL = 120000;
    public static CHAT_INTERVAL = 120000;

    public static DATE_FORMAT = "dd-MMM-yyyy hh-mm";

    // Application Ids

    public static UMS_APPLICATION_ID = 1;
    public static COLLABORATION_APPLICATION_ID = 2;
    public static RRE_APPLICATION_ID = 3;
    public static NOTIFICATION_APPLICATION_ID = 4;
    public static EPM_APPLICATION_ID = 5;
    public static TMS_APPLICATION_ID = 6;
    public static XIGT_APPLICATION_ID = 7;
    public static PYTHON_SCRIPT_ID = 9;
    public static EXCEPTION_LOGGER_APPLICATION_ID = 10;
    public static VRE_APPLICATION_ID = 16;

    // Cookies Token
    public static TOKEN_COOKIE = 'token';
    public static LOGGED_IN_USER_COOKIE = 'loggedInUser';
    public static USER_NAME_COOKIE = 'userName';
    public static USER_IMAGE_COOKIE = 'userImage';
    public static ACTIVATED_ROLE_COOKIE = 'activatedRole';
    public static APPLICATION_ID_COOKIE = 'applicationId';
    public static APPLICATION_SHORT_NAME_COOKIE = 'applicationShortName';
    public static USER_PREFERENCE_COOKIE = 'userPreference';
    public static PROJECT_COOKIE = 'project';
    public static CURRENT_VERSION_COOKIE = 'currentVersion';

    // Forms Constants
    public static MENU_FORM_ID = 94;
    public static ALL_NOITIFICATION_FORM_ID = 47;
    public static NEW_SUBSCRIPTION_FORM_ID = 52;
    public static COLLABORATION_FORM_ID = 19;

    public static BUTTONS_DELAY = 500;
    public static INITIAL_DELAY = 3000;
    public static INITIAL_DELAY_TO_GET_PARAMS_FROM_URL = 100;
    public static USER_PROFILE_FORM_ID = 19;

    public static PAGE_SIZE_OPTIONS= [10,20,50]

    public static setApiEndPoint(endPoint) {
        AppSettings.API_ENDPOINT = endPoint;
    }

    public static filterArray(items: any[], searchText: string): any[] {

        console.log(items, searchText)
        if (!items) {
            return [];
        }
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            if (it.title) {
                return it.title.toLowerCase().includes(searchText)
            }
            if (it.ntfyEvent) {
                return it.ntfyEvent.toLowerCase().includes(searchText);
            }
        });
    }

}
