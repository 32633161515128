import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { PaymentsDetailService } from '../web-services/payments-detail.service';
import { PaymentsService } from '../web-services/payments.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { InvoiceService } from '../web-services/invoice.service';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';

@Component(
    { selector: 'app-payments-detail', templateUrl: './payments-detail.component.html', styleUrls: ['./payments-detail.component.css'] })
export class PaymentsDetailComponent implements OnInit {
    constructor(
        private paymentsDetailServices: PaymentsDetailService,
        private paymentsServices: PaymentsService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private listOfValuesService: ListOfValuesService,
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private dialogService: DialogService,
        private invoiceServices: InvoiceService
    ) {
    };

    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePaymentsDetailId = null;
    paymentsList: any = [];
    paymentsDetailList: any = [];
    paymentsDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    paymentsDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    paymentObj: any = null;
    paymentId: any = 0;
    itemsList: any = [];
    invoiceList: any = [];

    showSearchDialog = false;
    showSearchBtn = false;
    showAddBtn = false;
    showAddDialog = false;
    editTag = false;
    disableColumn = false;

    addEditTitle = '';
    resetTag = true;

    onClearSearch() {
        this.paymentsDetailSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countPaymentsDetail();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countPaymentsDetail() {
        this.paymentsDetailSearch.controls['paymentsId'].setValue(this.paymentId);
        this.paymentsDetailServices.countPaymentsDetail(this.paymentsDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllPaymentsDetail();
            } else {
                this.totalRecords = 0;
                this.startLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.startLoader();
        });
    }

    onSubmit() {

        this.paymentsDetailForm.markAllAsTouched();
        this.paymentsDetailForm.controls['paymentsId'].setValue(this.paymentId);
        if (this.paymentsDetailForm.valid) {
            this.addUpdatePaymentsDetail(this.paymentsDetailForm.value);
            this.submitted = false;
            this.onClearAdd();
        }

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllPaymentsDetail();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.paymentsDetailSearch.reset();
        this.countPaymentsDetail();
    }

    addUpdatePaymentsDetail(paymentsDetail) {
        this.startLoader();
        if (this.updatePaymentsDetailId == null) {
            this.paymentsDetailServices.addPaymentsDetail(this.paymentsDetailForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    paymentsDetail = res['data'][0];
                    this.paymentsDetailList.push(paymentsDetail);
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNamePaymentsDetail) +
                                                    this.translate(this.labels.addedSuccessfully),
                        // detail: this.translate(this.labels.entityNamePaymentsDetail)
                    });
                    this.showHideAddDialog();
                    this.submitted = true;
                    this.countPaymentsDetail();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            paymentsDetail.paymentsDetailId = this.updatePaymentsDetailId;
            this.paymentsDetailServices.updatePaymentsDetail(paymentsDetail).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.paymentsDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNamePaymentsDetail) +
                                                    this.translate(this.labels.updatedSuccessfully),
                        // detail: this.translate(this.labels.entityNamePaymentsDetail)
                    });

                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countPaymentsDetail();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePaymentsDetailId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    get addUpdateTitle() {
        return this.updatePaymentsDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        console.log(this.config.data.paymentObj, ' :: Payment object on init ');
        this.paymentObj = this.config.data.paymentObj;
        this.paymentId = this.config.data.paymentObj.paymentId;
        this.findAllPayments();
        this.findAllInvoice();
        this.paymentsDetailForm = new FormGroup({
            paymentsId: new FormControl(),
            invoiceId: new FormControl(0, [Validators.required]),
            itemId: new FormControl(),
            quantity: new FormControl(),
            unitPrice: new FormControl(),
            discount: new FormControl(),
            taxAmount: new FormControl(),
            invoiceAmount: new FormControl(),
            totalAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
            description: new FormControl('', [Validators.required, validateSpaces]),
            userId: new FormControl(),
        });
        this.paymentsDetailSearch = new FormGroup({
            paymentsId: new FormControl(this.paymentId),
            invoiceId: new FormControl(),
            itemId: new FormControl(),
            quantity: new FormControl(),
            unitPrice: new FormControl(),
            discount: new FormControl(),
            taxAmount: new FormControl(),
            invoiceAmount: new FormControl(),
            totalAmount: new FormControl(),
            description: new FormControl(),
            userId: new FormControl(),
        });
        this.countPaymentsDetail();
        this.getConstantList(1234);
        this.findAllLov();
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.paymentsDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllInvoice() {
        let temp = [];
        this.startLoader();
        this.invoiceServices.findInvoiceByUserId(parseInt(sessionStorage.getItem("loggedInRefId")),
            parseInt(sessionStorage.getItem("userActivatedRole"))).subscribe(
                res => {
                    if (res['message'] == 'Success') {
                        temp = res['data'];
                        for (let object of temp) {
                            let dropDown = { label: '', value: '', amount: '' };
                            dropDown.value = object['invoiceId'];
                            if (object['remainingAmount'] > 0 &&
                                object['invoiceStatus'] == Constant.INVOICE_STATUS_PARTIAL) {

                                dropDown.label = object['invoiceNumber'];
                                dropDown.amount = object['remainingAmount']
                            } else {
                                dropDown.label = object['invoiceNumber'];
                                dropDown.amount = object['invoiceAmount']
                            }

                            this.invoiceList.push(dropDown);
                        }
                    } else {
                        this.invoiceList = [];
                    }
                    this.stopLoader();
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
    }

    findAllLov() {
        let temp = [];
        this.startLoader();
        this.listOfValuesService.getAllListOfValuesByName(Constant.PAYMENTS_DETAIL_LOV_ITMES_LIST).subscribe(result => {

            console.log("result of Lov values :: ", result);

            if (result['statusCode'] == 200) {
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];
                    console.log("element of data arrrray :: ", index, " index obj :: ", element);

                    if (element[Constant.PAYMENTS_DETAIL_LOV_ITMES]) {
                        console.log("source array :: ", element[Constant.PAYMENTS_DETAIL_LOV_ITMES]);

                        for (let object of element[Constant.PAYMENTS_DETAIL_LOV_ITMES]) {
                            let dropDown = { label: '', value: '' };
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.itemsList.push(dropDown);
                            console.log("drop down values array :: ", dropDown);
                        }

                    }
                }
            }

        });
    }

    findAllPaymentsDetail() {
        this.startLoader();
        this.paymentsDetailSearch.controls['paymentsId'].setValue(this.paymentId);
        this.paymentsDetailServices.findAllPaymentsDetail(this.paymentsDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.paymentsDetailList = res['data'];
                } else {
                    this.paymentsDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdPaymentsDetail(paymentsDetailId: number, index) {
        this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.paymentsDetailServices.deleteByIdPaymentsDetail(paymentsDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.paymentsDetailList.splice(index, 1);
                        this.messageService.add({
                            severity: 'warn',
                                                    summary: this.translate(this.labels.entityNamePaymentsDetail) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                            // detail: this.translate(this.labels.entityNamePaymentsDetail)
                        });
                        this.countPaymentsDetail();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }

    loadPaymentsDetailToEdit(paymentsDetailId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let paymentsDetail = this.paymentsDetailList.find(x => x['paymentsDetailId'] == paymentsDetailId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updatePaymentsDetailId = paymentsDetail['paymentsDetailId'];
        this.selectedIndex = index;
        if (paymentsDetail['paymentsId']) {
            this.paymentsDetailForm.controls['paymentsId'].setValue(paymentsDetail['paymentsId']['paymentId']);
        }

        if (paymentsDetail['itemId']) {
            this.paymentsDetailForm.controls['itemId'].setValue(paymentsDetail['itemId']['listOfValuesId']);
        }

        if (paymentsDetail['quantity']) {
            this.paymentsDetailForm.controls['quantity'].setValue(paymentsDetail['quantity']);
        }

        if (paymentsDetail['unitPrice']) {
            this.paymentsDetailForm.controls['unitPrice'].setValue(paymentsDetail['unitPrice']);
        }

        if (paymentsDetail['discount']) {
            this.paymentsDetailForm.controls['discount'].setValue(paymentsDetail['discount']);
        }

        if (paymentsDetail['totalAmount']) {
            this.paymentsDetailForm.controls['totalAmount'].setValue(paymentsDetail['totalAmount']);
        }

        if (paymentsDetail['taxAmount']) {
            this.paymentsDetailForm.controls['taxAmount'].setValue(paymentsDetail['taxAmount']);
        }

        if (paymentsDetail['description']) {
            this.paymentsDetailForm.controls['description'].setValue(paymentsDetail['description']);
        }

        if (paymentsDetail['userId']) {
            this.paymentsDetailForm.controls['userId'].setValue(paymentsDetail['userId']);
        }

        // by ali
        if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;

    }

    onClearAdd() {
        this.paymentsDetailForm.reset();
        this.paymentsDetailForm.markAsUntouched();
        this.updatePaymentsDetailId = null;
    }

    findAllPayments() {
        let temp = [];
        this.startLoader();
        this.paymentsServices.findAllPayments(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['paymentId'];
                        dropDown.label = object['paymentDescription'];
                        this.paymentsList.push(dropDown);
                    }
                } else {
                    this.paymentsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.paymentsDetailForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.paymentsDetailForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.paymentsDetailForm.get(control)?.valid && this.paymentsDetailForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.paymentsDetailForm.reset();
    }

    onCloseSearch() {
        this.paymentsDetailSearch.reset();
    }

    refresh() {
        this.countPaymentsDetail();
    }

    onSearchOverlayDiv() {
    }

    onAddOverlayDiv() {
        this.paymentsDetailForm.reset();
    }

    calculateAmount() {

        if (this.paymentsDetailForm.value.quantity) {

        } else {
            this.paymentsDetailForm.controls['quantity'].setValue(0);
        }
        if (this.paymentsDetailForm.value.unitPrice) {

        } else {
            this.paymentsDetailForm.controls['unitPrice'].setValue(0);
        }
        if (this.paymentsDetailForm.value.discount) {

        } else {
            this.paymentsDetailForm.controls['discount'].setValue(0);
        }
        if (this.paymentsDetailForm.value.taxAmount) {

        } else {
            this.paymentsDetailForm.controls['taxAmount'].setValue(0);
        }
        let totalamount = 0;
        totalamount = (this.paymentsDetailForm.value.quantity * this.paymentsDetailForm.value.unitPrice -
            this.paymentsDetailForm.value.discount + this.paymentsDetailForm.value.taxAmount);

        console.log('Total amount is : ', totalamount);
        this.paymentsDetailForm.controls['totalAmount'].setValue(totalamount);
    }

    onInvoiceChange(event) {
        // console.log("invoice obj found :: ",this.invoiceList," event value :: ",event.value);
        this.paymentsDetailForm.controls['totalAmount'].setValue(0);
        this.paymentsDetailForm.controls['invoiceAmount'].setValue(0);
        let invoiceObj = this.invoiceList.find(x => x.value == event.value);
        // console.log("invoice obj found :: ",invoiceObj);
        if (invoiceObj) {
            if (invoiceObj.amount > this.paymentObj.paymentAmount) {

                this.paymentsDetailForm.controls['totalAmount'].setValue((this.paymentObj.paymentAmount - this.paymentObj.paidAmount));

            } else {
                this.paymentsDetailForm.controls['totalAmount'].setValue(invoiceObj.amount);
            }
            this.paymentsDetailForm.controls['invoiceAmount'].setValue(invoiceObj.amount);
        }

    }

    viewPaymentDetail(paymentDetailId: number) {
        // console.log('modal called');
        this.ref = null;
        let paymentDetail = this.paymentsDetailList.find(x => x['paymentsDetailId'] == paymentDetailId);


        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Expense Detail',
            width: '80%',
            contentStyle: { 'max-height': '750px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                mainData: [
                    { label: "Invoice Amount ", value: paymentDetail['invoiceId']['invoiceAmount'] },
                    { label: "Total Amount ", value: paymentDetail['invoiceId']['invoiceAmount'] },
                    { label: "Description", value: paymentDetail['description'] },

                ]
            },

        });
        this.ref.onClose.subscribe(res => {
            // this.countInvoice();
        });
    }

    showHideSearchDialog() {
        if (this.resetTag) {
            this.paymentsDetailSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.paymentsDetailForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePaymentsDetailId = null;
    }

    searchTerm = null;

    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue.trim();
        if(this.searchTerm) {
            this.paymentsDetailGlCount();
        } else {
            this.countPaymentsDetail();
        }
    }

    paymentsDetailGlCount() {
        this.paymentsDetailServices.paymentsDetailGlcount(this.searchTerm, this.paymentId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchPaymentsDetail();
            } else {
                this.totalRecords = 0;
                this.paymentsDetailList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchPaymentsDetail() {
        this.paymentsDetailServices.findGlobalPaymentsDetail(this.searchTerm, this.pageNo, this.itemPerPage, this.paymentId).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.paymentsDetailList = result['data'];
            } else {
                this.paymentsDetailList = [];
            }
            console.log(result, 'result');
        });
    }
}