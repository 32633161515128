export class EditHistoryFormDataDTO {

  historyDataToAdd: any;
  historyDataToDelete: any;
  listOfValueDetailsIdsToDelete =  [];
  hasMultiSelectErrors = false;
  multiSelectErrorFields = [];

  constructor(objectMultiSelectData: any, formData: any) {

    this.createEditHistoryData(objectMultiSelectData, this);
    this.addFormDataToDTO(formData, this);


  }

  createEditHistoryData(objectMultiSelectData: any, currentClass: EditHistoryFormDataDTO): void {

      let historyDataToDelete = [];
      let historyDataToAdd = {};

    for (let [key, value] of Object.entries(objectMultiSelectData)) {

      if (objectMultiSelectData[key] !== undefined) {


  historyDataToDelete = historyDataToDelete.concat(objectMultiSelectData[key].list.filter(multiSelect => multiSelect.previouslySelected !== undefined && !multiSelect.selected).map(i => i.listOfValuesDetailId));

        let dataToAdd = objectMultiSelectData[key].list.filter(multiSelect => multiSelect.previouslySelected === undefined && multiSelect.selected);
        dataToAdd.map(item => item.listOfValuesMasterId = objectMultiSelectData[key].currentListOfValuesMasterId);

       /// historyDataToAdd[objectMultiSelectData[key].dbField].currentListOfValuesMasterId = objectMultiSelectData[key].currentListOfValuesMasterId;
        historyDataToAdd[objectMultiSelectData[key].dbField] = dataToAdd;
         /// listOfValuesMasterId: objectMultiSelectData[key].currentListOfValuesMasterId,
         /// historyDataToAdd: objectMultiSelectData[key].list.filter(multiSelect => multiSelect.previouslySelected === undefined && multiSelect.selected)
       // };

      }
    }
      currentClass.listOfValueDetailsIdsToDelete = historyDataToDelete;
      currentClass.historyDataToAdd = historyDataToAdd;
  }

  addFormDataToDTO(formData: any, currentClass: EditHistoryFormDataDTO): void {

    if (formData !== null) {
      for (let key in formData.controls) {

        currentClass[key] = formData.controls[key].value;
      }
    }
  }
}
