import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BodyChartMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['description']: {
      name: Constant.BODY_CHART_LOV_DESCRIPTION,
      dbField: 'description',
      label: 'Description',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false


    },
    ['aggs']: {
      name: Constant.BODY_CHART_LOV_AGGS,
      dbField: 'aggs',
      label: 'Aggravating Factors',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false


    },
    ['eases']: {
      name: Constant.BODY_CHART_LOV_EASES,
      dbField: 'eases',
      label: 'Easing Factors',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false


    }
  };

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
