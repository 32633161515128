import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExpertPatientService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient) {
  };

  findExpertPatientByExpertId(expertId): Observable<string> {

    let expert = {
      Expert: {
        expertId:expertId
      }
    }
console.log(expert);

    return this.httpClient.post<string>(Constant.BASE_URL + 'ExpertPatient/findExpertPatientByExpertId', {Expert: { expertId:expertId} }, this.httpOptions).pipe(
      retry(3), catchError(Constant.handleError));
  }

}
