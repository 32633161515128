<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
  <div class="container">
    <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
      <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
        <div class="overlaypaneldiv" id="overlaymain">
          <p-panel class="pngPanelMain" id="patients-pngPanelMain">
            <p-header> {{translate(labels.entityNameCpaRole)}}
              <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                <button
                        class="p-button-rounded p-button-text" icon="pi pi-plus"
                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                        pButton pTooltip="{{addEditTitle}} {{translate(labels.entityNameCpaRole)}}"  tooltipPosition="bottom"
                        type="button" id="addOverlayBtn"></button>
              </div>
            </p-header>
            <p-dialog header="CPA Application Roles"
                      [(visible)]="viewCpaApplicationRoles"
                      [modal]="true"
                      [style]="{width: '50vw', height: '75%'}"
                      [baseZIndex]="10000"
                      [draggable]="false">

              <ng-template *ngIf="!applicationRoles" pTemplate="emptymessage">
                {{translate(labels.mat_pagination_no_data_msg)}}
              </ng-template>

              <div *ngFor="let item of applicationRoles; let i = index"
                   class="p-field-checkbox subsCheckboxView manageSubsClass">
                <div class="p-grid manageSubsGrid">
                  <div class="p-col-6 manageSubsLabel">
                    <label class="manageSubsLabel">{{item.name}}</label>
                  </div>
                  <!--                                                                <div class="p-col-2"></div>-->
                  <div class="p-col-6 manageSubsSelectBtn">

                    <p-inputSwitch (onChange)="selectApplicationRole(item,$event)" [(ngModel)]="item.value"></p-inputSwitch>

                  </div>
                </div>
              </div>
              <ng-template pTemplate="footer">
                <div class="p-d-flex p-jc-end">
                  <div class="p-mr-3">
                    <p-button (click)="cancelUserSubscription()" label="Cancel" styleClass="p-button"
                              class="pngBtnTransparent">
                    </p-button>
                  </div>
                  <div *ngIf="applicationRoles && applicationRoles.length > 0">
                    <p-button (click)="saveCpaApplicationRoles($event)" label="Save" styleClass="p-button"
                              class="pngBlueBtn">
                    </p-button>
                  </div>
                </div>
              </ng-template>
            </p-dialog>
            <!-- Add DialogBox-->
            <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
              <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                <p-header>{{addEditTitle}} {{translate(labels.entityNameCpaRole)}}
                </p-header>
                <form (ngSubmit)="onSubmit()" [formGroup]="cpaRoleForm" class="pngForm"
                      id="invitationpage-addmemeber-pngForm">
                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.cpaRoleTitle)}}</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="title" name="title"
                             type="text">
                    </div>
                  </div>

                  <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                    <div class="p-field p-col-12 p-md-6 formfieldheight">
                      <label>{{translate(labels.cpaRoleDescription)}}</label>
                      <input class="pngInputtext"
                             pInputText
                             formControlName="description" name="description"
                             type="text">
                    </div>
                  </div>

                  <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                    <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                    <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button" class="pngBtnTransparent">
                    </p-button>
                  </div>
                </form>
              </p-panel>
            </div>
            <!-- Table Data -->
            <div class="overlaypaneldiv" id="overlaypaneldiv-list">
              <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                <p-header>
                                    <span   *ngIf="pageControlMap.get('viewExpenses')"
                                            class="p-input-icon-right globalSearch"
                                            id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)= "onGlobalSearchClick(global.value)"></i>
                                        <input #global
                                               class="pngInputtext" pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text" /></span>

                </p-header>
                <p-table    #dv *ngIf="cpaRolesList.length > 0"
                            (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                            [lazy]="true" [paginator]="true" [resizableColumns]="true"
                            [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [showCurrentPageReport]=true
                            [totalRecords]="totalRecords" [value]="cpaRolesList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': cpaRolesList?.length == 0}"
                            id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                  <ng-template pTemplate="header">
                    <tr>
                      <th> Title</th>
                      <th> Description</th>
                      <th>
                        {{translate(labels.headerAction)}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template let-element let-i="rowIndex" pTemplate="body">
                    <tr>
                      <td> {{element?.title }} </td>
                      <td> {{element?.description}} </td>
                      <td>

                        <div class="pngTable-icons">
                          <p-button
                                      (click)="loadCpaRoleToEdit(element.cpaRoleId, i)"
                                      icon="pi pi-pencil" pTooltip="Edit"
                                      styleClass="p-button pbtn-transparent">
                          </p-button>
                          <p-button
                                      (click)="deleteByIdExpenses(element.expensesId,i)"
                                      icon="pi pi-trash" pTooltip="Delete"
                                      styleClass="p-button pbtn-transparent">
                          </p-button>
                          <p-button
                            (click)="editCpaApplicationRoles(element.cpaRoleId,i)"
                            icon="pi pi-exclamation-triangle" pTooltip="Cpa Application Roles"
                            styleClass="p-button pbtn-transparent">
                          </p-button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div *ngIf="totalRecords < 1">
                  <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                </div>
              </p-panel>
            </div>

          </p-panel>
        </div>
      </div>
    </section>
  </div>
</section>
