import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AgencyService} from '../../web-services/agency.service';

@Component({
  selector: 'app-super-dashboard',
  templateUrl: './super-dashboard.component.html',
  styleUrls: ['./super-dashboard.component.css']
})
export class SuperDashboardComponent implements OnInit {


  private title: any;
  // agencySearch: FormGroup;
  agencyList: any = [];
  activeRecords;
  deActivatedRecords;
  inCompleteRecords;

  constructor( private router: Router, private agencyServices: AgencyService) {
  }


  ngOnInit(): void {
    this.activeRecords = 0;
    this.deActivatedRecords = 0;
    this.inCompleteRecords = 0;
    // this.agencySearch = new FormGroup({
    //                                     agencyTitle: new FormControl(),
    //                                     agencyFirstname: new FormControl(),
    //                                     agencyLastname: new FormControl(),
    //                                     agencyType: new FormControl(),
    //                                     agencyName: new FormControl(),
    //                                     agencyAddress: new FormControl(),
    //                                     agencyPhoneNumber: new FormControl(),
    //                                     agencyEmail: new FormControl(),
    //                                     communicationPrefs: new FormControl(),
    //                                     marketingInfo: new FormControl(),
    //                                     billing: new FormControl(),
    //                                     invoiceInfo: new FormControl(),
    //                                     uniqueReferenceNumber: new FormControl(),
    //                                     referralSource: new FormControl(),
    //                                     numberOfUsers: new FormControl(),
    //                                     userEmail: new FormControl(),
    //                                     userPassword: new FormControl(),
    //                                     // userId:                   new FormControl(),
    //                                     status: new FormControl(),
    //                                   });
    this.findAllAgency();
  }

  findAllAgency() {
    // this.startLoader();
    this.agencyServices.findAllAgency(null,null, null).subscribe(
        res => {
          if (res['message'] == 'Success') {
            this.agencyList = res['data'];
            console.log(this.agencyList, 'all agency list');
          } else {
            this.agencyList = [];
          }
          this.getCounts();
        }, err => {
          console.error('err: ', err);
          // this.stopLoader();
        });
  }

  getCounts(){
    this.activeRecords = this.agencyList.filter(d => {
      return d.status == 'Active';
    }).length;

    this.inCompleteRecords = this.agencyList.filter(d => {
      return d.status == 'InComplete';
    }).length;

    this.deActivatedRecords = this.agencyList.filter(d => {
      return d.status == 'Deactivated';
    }).length;
  }

}
