import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BodyChartMasterService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdBodyChartMaster(bodyChartMasterId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'BodyChartMaster/deleteBodyChartMaster', {'bodyChartMasterId': bodyChartMasterId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countBodyChartMaster(bodyChartMaster): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'BodyChartMaster/count', {'bodyChartMaster': bodyChartMaster},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateBodyChartMaster(bodyChartMaster): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'BodyChartMaster/updateBodyChartMaster', [bodyChartMaster], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addBodyChartMaster(bodyChartMaster): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'BodyChartMaster/addBodyChartMaster', [bodyChartMaster], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllBodyChartMaster(bodyChartMaster, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'BodyChartMaster/findAllBodyChartMaster',
            {'bodyChartMaster': bodyChartMaster, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addBodyChartMasterCpa(bodyChartMasterDTO: any): Observable<any> {
    return this.httpClient.post<any>(
      Constant.CPA_SPRING_API_URL + 'body-charts-master', bodyChartMasterDTO, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
  }

  updateBodyChartMasterWithImageUrls(bodyChartMasterId, anatomyImageUrls): Observable<any> {
    return this.httpClient.put<any>(
      Constant.CPA_SPRING_API_URL + 'body-charts-master/' + bodyChartMasterId, anatomyImageUrls, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));
  }


}
