<div class="rounded-info-box">
  <div class="inner-rounded-info-box">
    <div class="pt-3 pb-2">
      <div>
        Previous Diagnosis
      </div>

    </div>
  </div>
</div>
