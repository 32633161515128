import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataSharedService} from '../services/data-shared.service';

@Component({
               selector: 'app-initializer',
               templateUrl: './initializer.component.html',
               styleUrls: ['./initializer.component.css']
           })
export class InitializerComponent implements OnInit {

    constructor(private sharedService: DataSharedService,
                private router: Router, private ngxSpinnerService: NgxUiLoaderService,
    ) {
    }

//   isMenuInitialized: Boolean;
//
    ngOnInit() {
    }

//     this.ngxSpinnerService.startLoader('initializerLoader');
//     this.sharedService.IsMenuInitialized.subscribe(data => {
//
//       this.isMenuInitialized = data;
//       if (this.isMenuInitialized) {
//         this.routeToPage();
//       }
//
//     });
//   }
//
//   routeToPage() {
//     var currentUrl = sessionStorage.getItem("currentUrl");
//
//     console.log("currentUrl ------------ :::: ",currentUrl);
//     this.router.navigate([currentUrl]);
//   }
// }

}
