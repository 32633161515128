import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {OutcomeMeasureImagesService} from '../web-services/outcome-measure-images.service';
import {OutcomeMeasureService} from '../web-services/outcome-measure.service';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-outcome-measure-images',
               templateUrl: './outcome-measure-images.component.html',
               styleUrls: ['./outcome-measure-images.component.css']
           })
export class OutcomeMeasureImagesComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateOutcomeMeasureImageId = null;
    outcomeMeasureList: any = [];
    usersList: any = [];
    outcomeMeasureImagesList: any = [];
    outcomeMeasureImagesSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    outcomeMeasureImagesForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private outcomeMeasureImagesServices: OutcomeMeasureImagesService, private outcomeMeasureServices: OutcomeMeasureService,
        private usersServices: UsersService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateOutcomeMeasureImageId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.outcomeMeasureImagesSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countOutcomeMeasureImages();
        this.findAllOutcomeMeasureImages();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countOutcomeMeasureImages() {
        this.outcomeMeasureImagesServices.countOutcomeMeasureImages(this.outcomeMeasureImagesSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllOutcomeMeasureImages();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateOutcomeMeasureImages(this.outcomeMeasureImagesForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllOutcomeMeasureImages();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.outcomeMeasureImagesSearch.reset();
        this.countOutcomeMeasureImages();
        // this.findAllOutcomeMeasureImages();
    }

    addUpdateOutcomeMeasureImages(outcomeMeasureImages) {
        this.startLoader();
        if (this.updateOutcomeMeasureImageId == null) {
            this.outcomeMeasureImagesServices.addOutcomeMeasureImages(this.outcomeMeasureImagesForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    outcomeMeasureImages = res['data'][0];
                    this.outcomeMeasureImagesList.push(outcomeMeasureImages);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnAdd),
                                                detail: this.translate(this.labels.entityNameOutcomeMeasureImages)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countOutcomeMeasureImages();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            outcomeMeasureImages.outcomeMeasureImageId = this.updateOutcomeMeasureImageId;
            this.outcomeMeasureImagesServices.updateOutcomeMeasureImages(outcomeMeasureImages).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.outcomeMeasureImagesList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameOutcomeMeasureImages)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateOutcomeMeasureImageId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllOutcomeMeasure();
        this.findAllUsers();
        this.findAllUsers();
        // this.findAllAppointments();
        this.outcomeMeasureImagesForm = new FormGroup({
                                                          imageUrl: new FormControl('', [Validators.required, validateSpaces]),
                                                          outcomeMeasureId: new FormControl(),
                                                          patientId: new FormControl(),
                                                          expertId: new FormControl(),
                                                          appointmentId: new FormControl(),
                                                          userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                          imageComments: new FormControl('', [Validators.required, validateSpaces]),
                                                      });
        this.outcomeMeasureImagesSearch = new FormGroup({
                                                            imageUrl: new FormControl(),
                                                            outcomeMeasureId: new FormControl(),
                                                            patientId: new FormControl(),
                                                            expertId: new FormControl(),
                                                            appointmentId: new FormControl(),
                                                            userId: new FormControl(),
                                                            imageComments: new FormControl(),
                                                        });
        this.countOutcomeMeasureImages();
        this.findAllOutcomeMeasureImages();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.outcomeMeasureImagesSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllOutcomeMeasureImages() {
        this.startLoader();
        this.outcomeMeasureImagesServices.findAllOutcomeMeasureImages(
            this.outcomeMeasureImagesSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.outcomeMeasureImagesList = res['data'];
                } else {
                    this.outcomeMeasureImagesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdOutcomeMeasureImages(outcomeMeasureImageId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.outcomeMeasureImagesServices.deleteByIdOutcomeMeasureImages(outcomeMeasureImageId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.outcomeMeasureImagesList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameOutcomeMeasureImages) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameOutcomeMeasureImages)
                                                });
                        this.countOutcomeMeasureImages();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadOutcomeMeasureImagesToEdit(outcomeMeasureImageId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let outcomeMeasureImages = this.outcomeMeasureImagesList.find(x => x['outcomeMeasureImageId'] == outcomeMeasureImageId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateOutcomeMeasureImageId = outcomeMeasureImages['outcomeMeasureImageId'];
        this.selectedIndex = index;
        this.outcomeMeasureImagesForm.controls['imageUrl'].setValue(outcomeMeasureImages['imageUrl']);
        this.outcomeMeasureImagesForm.controls['outcomeMeasureId'].setValue(outcomeMeasureImages['outcomeMeasureId']['outcomeMeasureId']);
        this.outcomeMeasureImagesForm.controls['patientId'].setValue(outcomeMeasureImages['patientId']['patientId']);
        this.outcomeMeasureImagesForm.controls['expertId'].setValue(outcomeMeasureImages['expertId']['expertId']);
        this.outcomeMeasureImagesForm.controls['appointmentId'].setValue(outcomeMeasureImages['appointmentId']['appointmentId']);
        this.outcomeMeasureImagesForm.controls['userId'].setValue(outcomeMeasureImages['userId']);
        this.outcomeMeasureImagesForm.controls['imageComments'].setValue(outcomeMeasureImages['imageComments']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.outcomeMeasureImagesForm.reset();
        this.outcomeMeasureImagesForm.markAsUntouched();
        this.updateOutcomeMeasureImageId = null;
        closeAddOverlaydiv();
    }

    findAllOutcomeMeasure() {
        let temp = [];
        this.startLoader();
        this.outcomeMeasureServices.findAllOutcomeMeasure(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['outcomeMeasureId'];
                        dropDown.label = object['null'];
                        this.outcomeMeasureList.push(dropDown);
                    }
                } else {
                    this.outcomeMeasureList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.outcomeMeasureImagesForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.outcomeMeasureImagesForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.outcomeMeasureImagesForm.get(control)?.valid && this.outcomeMeasureImagesForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.outcomeMeasureImagesForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.outcomeMeasureImagesSearch.reset();
    }

    refresh() {
        this.countOutcomeMeasureImages();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.outcomeMeasureImagesForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.outcomeMeasureImagesForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateOutcomeMeasureImageId = null;
    }
}
