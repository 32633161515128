<!-- <p-button icon="pi pi-youtube" (click)="displayLogoutModal = true" label="Click Me" styleClass="p-button-text">
</p-button> -->
<p-dialog [(visible)]="displayLogoutModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" class="logout-popup">

    <div class="logout-message">
        <h4>User Already Logged In</h4>
        <p>Do you want to login again?</p>
    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayLogoutModal = false" label="No" styleClass="p-button-text">
        </p-button>
        <p-button icon="pi pi-check" class="pngBlueBtn" label="Yes" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>