import {Component, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { CompanyService } from '../web-services/company.service';
import { UsersService } from '../web-services/users.service';
import { Console } from 'console';
import { SessionService } from '../services/session.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';
import { ValidationService } from '../services/validation.service';
import {SubscriptionService} from '../web-services/subscription.service';
import {ChatService} from '../collaboration/services/chat/chat.service';

@Component(
    { selector: 'app-company', 
    templateUrl: './company.component.html', 
    styleUrls: ['./company.component.css'],
    providers: [DialogService] 
})

export class CompanyComponent implements OnInit {
    items: MenuItem[];
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateCompanyId = null;
    duplicateEmail = false;
    usersList: any = [];
    companyList: any = [];
    companySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    companyForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    loggedInUserType : any;
    loggedInRefId : any;
    parentRefId : any;

    passwordType = 'password';
    enableUserNamePassword = false;
    addEditTitle = 'Add';
    ref: DynamicDialogRef;
    @ViewChild('menu') menu: any;


    subscriptionViewModal: boolean;
    userSubscriptionList: any = [];
    subscriptionSearch: FormGroup;
    manageOptions = [
        {label: 'Required', value: 'Active'},
        {label: 'Not Required', value: 'Inactive'}
    ];


    constructor(
        private companyServices: CompanyService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService,
        private dialogService: DialogService,
        private _validation: ValidationService,
        private subscriptionService: SubscriptionService,
        private chatService: ChatService
    ) {
    };

    get addUpdateTitle() {
        return this.updateCompanyId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.companySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        
        this.countCompany();
        this.findAllCompany();
        // closeSearchOverlaydiv();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countCompany() {
        this.companyForm.controls['agencyId'].setValue(this.loggedInRefId);
        this.companyServices.countCompany(this.companySearch.value,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllCompany();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        // this.enableUserNamePassword = false;
        this.companyForm.markAllAsTouched();
        console.log("company form :: ",this.companyForm.value);
        this.companyForm.controls['agencyId'].setValue(this.loggedInRefId);
        if (this.companyForm.valid) {
            this.addUpdateCompany(this.companyForm.value);
            this.submitted = false;
            // this.onClearAdd();
        }

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllCompany();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.companySearch.reset();
        this.countCompany();
        // this.findAllCompany();
    }

    addUpdateCompany(company) {
        this.startLoader();
        this.duplicateEmail =  false;
        if (this.updateCompanyId == null) {
            this.companyServices.addCompany(this.companyForm.value,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
                if(res['documentation'] == Constant.NOT_UNIQUE_EMAIL){
                    this.duplicateEmail =  true;
                    this.stopLoader();
                    return;
                }else if (res['message'] == 'Success') {
                    company = res['data'][0];
                    this.companyList.push(company);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameCompany) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameCompany)
                        });

                    // Register Event on user creation
                    let subscribeObj = {
                        "subscription": {
                            "loggedInUser": res['data'][0].userId.toString(),
                            "project": "1",
                            "event": [
                                { "id": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                    "title": Constant.MESSAGE_RECIEVED_NOTIFICATION_EVENT,
                                    "Code": "New Message" }
                            ],
                            "notificationType": 3,
                            "template": 4,
                            "applicationId": 5
                        }
                    }
                    this.chatService.subscribeEvent(subscribeObj).subscribe(res => {
                        if (res['message'] == 'Success') {
                            console.log('user subscribed Successfully');
                        } else {
                            console.log('Error');
                        }
                    });

                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countCompany();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            company.companyId = this.updateCompanyId;
            this.companyServices.updateCompany(company).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.companyList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameCompany) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameCompany)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateCompanyId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {

        this.items = [
            {
                label: '',
                items: [
                    {
                        label: 'Manage Subscription',
                        icon: 'manage-subs-icon',
                        // styleClass: 'pbtn-transparent',
                        command: () => {
                            this.getUserSubscriptionList(this.userId);
                        }
                    }
                ]
            }
        ]


        // this.items = [
        //     {
        //         label: 'Manage Subscriptions',
        //         icon: 'manage-subs-icon',
        //         // url: 'assets/iamges/manage-subscribtion.png',
        //         command: (event) => {
        //             console.log('this is event called : ', event);
        //             this.getUserSubscriptionList(this.userId);
        //         }
        //     },
        // ]
        this.addEditTitle = this.labels.btnAdd;
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));

        // this.findAllUsers();
        this.companyForm = new FormGroup({
            companyName: new FormControl('', [Validators.required, validateSpaces]),
            companyEmail: new FormControl('', [Validators.required, validateSpaces,Validators.pattern(this._validation.regex.email)]),
            companyAddress: new FormControl('', [Validators.required, validateSpaces]),
            companyPhoneNumber: new FormControl('', [Validators.required, validateSpaces]),
            userId: new FormControl(),
            numberOfUsers: new FormControl(0, [Validators.required, Validators.min(0)]),
            userEmail : new FormControl('',[Validators.required,validateSpaces,Validators.pattern(this._validation.regex.email)]),
            password : new FormControl('',[Validators.required,validateSpaces]),
            agencyId : new FormControl()
        });
        this.companySearch = new FormGroup({
            companyName: new FormControl(),
            companyEmail: new FormControl(),
            companyAddress: new FormControl(),
            companyPhoneNumber: new FormControl(),
            userId: new FormControl(),
            numberOfUsers: new FormControl(),
            agencyId : new FormControl()
        });
        this.countCompany();
        // this.findAllCompany();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.companySearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllCompany() {
        this.startLoader();
        this.companyServices.findAllCompany(this.companySearch.value, this.pageNo, this.itemPerPage,this.loggedInRefId,this.loggedInUserType).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.companyList = res['data'];
                } else {
                    this.companyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    deleteByIdCompany(companyId: number, index) {
          this.confirmation.confirm({
                                message: this.translate(this.labels.DELETE_MSG),
                                accept: () => {
                                    this.startLoader();
                                    this.companyServices.deleteByIdCompany(companyId).subscribe(res => {
                                        if (res['message'] == 'Success') {
                                            this.companyList.splice(index, 1);
                                            this.messageService.add(
                                                {
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameCompany) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameCompany)
                                                });
                                            this.countCompany();
                                        } else {
                                            console.error('error: ', res['documentation']);
                                            this.startLoader();
                                        }
                                    }, err => {
                                        console.error('err: ', err);
                                        this.stopLoader();
                                    });
                                }
            });
    }

    loadCompanyToEdit(companyId: number, index) {
        this.  addEditTitle = 'Edit';
        let company = this.companyList.find(x => x['companyId'] == companyId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateCompanyId = company['companyId'];
        this.selectedIndex = index;
        if(company['companyName']){
            this.companyForm.controls['companyName'].setValue(company['companyName']);
        }

        if(company['companyEmail']){
            this.companyForm.controls['companyEmail'].setValue(company['companyEmail']);
        }
        
        if(company['companyAddress']){
            this.companyForm.controls['companyAddress'].setValue(company['companyAddress']);
        }
        
        if(company['companyPhoneNumber']){
            this.companyForm.controls['companyPhoneNumber'].setValue(company['companyPhoneNumber']);
        }

        if(company['userId']){
            this.companyForm.controls['userId'].setValue(company['userId']['userId']);
        }

        if(company['numberOfUsers']){
            this.companyForm.controls['numberOfUsers'].setValue(company['numberOfUsers']);
        }

        if(company['userEmail']){
            this.companyForm.controls['userEmail'].setValue(company['userEmail']);
        }
        
        if(company['password']){
            this.companyForm.controls['password'].setValue(company['password']);
        }
        if(company['agencyId']){
            this.companyForm.controls['agencyId'].setValue(company['agencyId']);
        }
        
        // this.enableUserNamePassword = true;
        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        // this.companyForm.reset();
        // this.companyForm.markAsUntouched();
        this.updateCompanyId = null;
        // closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.companyForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.companyForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.companyForm.get(control)?.valid && this.companyForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // closeAddOverlaydiv();
        this.companyForm.reset();
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.companySearch.reset();
    }

    refresh() {
        this.countCompany();
    }

    onAddOverlayDiv() {
        // openAddOverlaydiv();
        this.companyForm.reset();
    }

    showHidePassword() {
        if (this.passwordType === 'text') {
            this.passwordType = 'password';
        } else {
            this.passwordType = 'text';
        }
    }

    onCloseAddOverlayDiv(){
        // this.enableUserNamePassword = false;
        // closeAddOverlaydiv();
    }


    searchTerm : any = null;
    onGlobalSearchClick(searchTerm){

        this.searchTerm = searchTerm;
        this.companyGlcount();

    }

    companyGlcount(){
        this.companyServices.companyGlcount(this.searchTerm,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findGlCompany();
            } else {
                this.totalRecords = 0;
                this.companyList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findGlCompany(){
        this.companyServices.findGlobalCompany(this.searchTerm, this.pageNo, this.itemPerPage,this.loggedInRefId,this.loggedInUserType).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.companyList = result['data'];
            }else{
                this.companyList = [];
            }
            console.log(result,"result");
        });
    }

    viewCompanyDetail(contactId){
        let company = this.companyList.find(x => x['companyId'] == contactId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Company Detail',
            width: '90%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {
                mainData: [
                    {label: this.labels.companyColumnCompanyName, value: company['companyName']},
                    {label: this.labels.companyColumnCompanyEmail, value: company['companyEmail']},
                    {label: this.labels.companyColumnCompanyPhoneNumber, value: company['companyPhoneNumber']},
                    {label: this.labels.companyColumnCompanyAddress, value: company['companyAddress']},
                    {label: this.labels.companyColumnNumberOfUsers, value: company['numberOfUsers']},
                    {label: this.labels.companyUsersColumnLoginEmail, value: company['userEmail']},
                    // {label: this.labels.companyUsersColumnPassword, value: company['password']},
                ]
            }
        });
    }


    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.companyForm.reset();
            this.companyForm.markAsUntouched();
        }
        this.addEditTitle = this.labels.btnAdd;

        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }
    userId : any;
    getUserSubscriptionList(userId) {
        this.startLoader();
        this.userSubscriptionList = [];
        this.userId = userId;
        this.subscriptionService.findUserSubscription(userId).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.userSubscriptionList = res['data'];
                    console.log('user subs data : ', this.userSubscriptionList);
                    this.userSubscriptionList.forEach(element=>{
                        element['value'] = element['subscriptionStatus']
                    });
                    this.subscriptionViewModal = true;
                } else {
                    this.userSubscriptionList = [];
                    this.subscriptionViewModal = true;
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });

    }

    onSelect(item: any, i: number, element: any, event) {

        // console.log('item : ', item , ' at index ', i);
        // console.log('event : ', event.value );
        console.log(this.userSubscriptionList, 'user sub list');
        if(event.value === 'Active'){
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Active';
        }else {
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Inactive';
        }
        // this.userSubscriptionList[i].controls['userStatus'].setValue('')
        console.log(this.userSubscriptionList, 'changed user subscription list');
    }
    cancelUserSubscription(event) {
        this.subscriptionViewModal = false;
        this.menu.toggle(event);
    }
    saveUserSubscription(event) {

        this.startLoader();
        for (let i = 0; i < this.userSubscriptionList.length; i++) {
            let jsonObj = {'userSubscriptionList': this.userSubscriptionList};
            this.subscriptionService.activateUserSubscriptionlist(this.userId,Constant.USER_TYPE_CORPORATE,this.userSubscriptionList).subscribe(res => {
              if (res['message'] == 'Success') {
                  console.log('check list item updated => ', res['data']);
                  this.subscriptionViewModal = false;
                  this.stopLoader();
              } else {
                  this.subscriptionViewModal = false;
                  this.stopLoader();
              }
          }, err => {
              console.error('err: ', err);
              this.stopLoader();
          });
        }
    }

    refreshSubs(companyUserId) {
        this.subscriptionService.refreshSubscriptionlist(this.loggedInRefId,Constant.USER_TYPE_CORPORATE,companyUserId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.userSubscriptionList = [];
                this.userSubscriptionList = res['data'];
                this.userSubscriptionList.forEach(element=>{
                    element['value'] = element['subscriptionStatus']
                });
                this.stopLoader();
            } else {
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });

    }
    getUserId(userId){
        this.userId = userId;
    }

    hiddd(event) {
        console.log('on hide called : ',event);
    }

    showww(event) {
        console.log('on show called : ',event);
    }

    showManageSubscription(userId) {

        this.getUserSubscriptionList(userId);
        this.subscriptionViewModal = true;
    }
}
