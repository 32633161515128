import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";
import {FollowUpFormData} from "../dto/FollowUpFormData";


@Injectable({providedIn: 'root'})
export class ClinicalDataService {
  httpOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json'),
    observe: 'response'
  };

  constructor(
    private httpClient: HttpClient) {}

  createFollowUpData(followUpFormData: FollowUpFormData): Observable<any> {

    return this.httpClient.post<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'follow-up', followUpFormData, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(0), catchError(Constant.handleError));

  }

  getRedFlagsForFollowUp(): Observable<any> {

    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'red-flags/filter?follow-up=true', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }

  getRedFlagsByCaseId(caseId: number): Observable<any> {
    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'red-flags/case/' + caseId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }
  getBodyChartDataByAppointmentId(appointmentId: number): Observable<any> {

    alert(Constant.CPA_CLINICAL_DATA_SERVICE + 'body-chart-detail/appointments/' + appointmentId);

    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'body-chart-detail/appointments/' + appointmentId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }


  findAllTestingAromsByAppointmentIdAndBodyChartAnatomyDiagramId(appointmentId: number, bdcId: number): Observable<any> {
    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/testing-aroms/body-chart-diagram-id/' + bdcId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }

  findAllTestingNeuroByAppointmentIdAndBodyChartAnatomyDiagramId(appointmentId: number, bdcId: number): Observable<any> {
    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/testing-neuro/body-chart-diagram-id/' + bdcId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }

  findAllTestingMusclesByAppointmentIdAndBodyChartAnatomyDiagramId(appointmentId: number, bdcId: number): Observable<any> {
    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/testing-muscles/body-chart-diagram-id/' + bdcId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }

  findAllTestingSpecialByAppointmentIdAndBodyChartAnatomyDiagramId(appointmentId: number, bdcId: number): Observable<any> {
    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/testing-special/body-chart-diagram-id/' + bdcId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }

  getReviewByAppointmentId(appointmentId: number): Observable<any> {
    alert(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/review');
    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/review', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(2), catchError(Constant.handleError));

  }

  getFollowUpDataByAppointmentId(appointmentId: number): Observable<any> {

    return this.httpClient.get<any>(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/follow-ups', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(0), catchError(Constant.handleError));

  }

  updateFollowUp(followUpData: any, followUpId: number): Observable<any> {
    return this.httpClient.patch<any>(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'follow-up/' + followUpId, followUpData, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/merge-patch+json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
  }



  }
