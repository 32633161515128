import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import { map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class CpaApplicationRoleService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient) {

  }

  createCpaApplicationRoles(cpaApplicationRoles): Observable<HttpResponse<any>> {

    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'cpa-application-roles', cpaApplicationRoles, { observe: 'response' }).pipe(
      retry(3), catchError(Constant.handleError));
  }


  findAllLiveCpaRoles(): Observable<HttpResponse<any>> {


    return this.httpClient.get<HttpResponse<any>>(Constant.CPA_SPRING_API_URL + 'cpa-roles', { observe: 'response' }).pipe(
      retry(3), catchError(Constant.handleError));
  }

}
