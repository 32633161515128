import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {PresentConditionHistoryService} from '../web-services/present-condition-history.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import { SessionService } from '../services/session.service';
import {Utils} from '../util/utils';
import {PresentConditionHistoryMultiSelects} from "../uiUtils/pages/PresentConditionHistoryMultiSelects";
import {InitialHistoryFormDTO} from "../dto/InitialHistoryFormDTO";
import {EditHistoryFormDataDTO} from "../dto/EditHistoryFormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {ListOfValuesService} from "../web-services/list-of-values.service";
import {HttpStatusCode} from '@angular/common/http';

@Component({
               selector: 'app-present-condition-history',
               templateUrl: './present-condition-history.component.html',
               styleUrls: ['./present-condition-history.component.css']
           })
export class PresentConditionHistoryComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePresentConditionHistoryId = null;
    listOfValuesDetailList: any = [];
    presentConditionHistoryList: any = [];
    presentConditionHistorySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    presentConditionHistoryForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    expertId;
    patientId;
    appointmentId;
    caseId: number;


    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;
    isEditing = false;
    editingPresentConditionHistoryId: number;

    constructor(
        private presentConditionHistoryServices: PresentConditionHistoryService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService,
        private lovServices: ListOfValuesService,
        private presentConditionHistoryMultiSelects: PresentConditionHistoryMultiSelects
    ) {
    };
    ref: DynamicDialogRef;

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    servicesSeenList: any = [];
    treatmentTypeList: any = [];
    investigationList: any = [];
    presentSymptomsList: any = [];
    servicesSeenError = false;
    treatmentTypeError = false;
    investigationError = false;
    presentSymptomsError = false;
    multiSelects: any;
    objectMultiSelects: any;

    getResponse($event) {


       // this.presentConditionHistoryMultiSelects.getResponse($event);
        this.presentConditionHistoryMultiSelects.getResponseObjects($event);

        console.log("getResponse " , this.presentConditionHistoryMultiSelects.objectMultiSelects);
    }

    updatedListOfValuesForEdit(event) {

    }

    get addUpdateTitle() {
        return this.updatePresentConditionHistoryId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.presentConditionHistorySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countPresentConditionHistory();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }
    utils = new Utils();
    countPresentConditionHistory() {
        this.presentConditionHistorySearch.controls['expertId'].setValue(this.expertId);
        this.presentConditionHistorySearch.controls['patientId'].setValue(this.patientId);
        this.presentConditionHistorySearch.controls['appointmentId'].setValue(this.appointmentId);
        let obj =this.utils.getUpdatedMillissecondObject(this.presentConditionHistorySearch.value,"initialAssessmentDate");
        if(obj['initialAssessmentDate'] === '0'){
            delete obj['initialAssessmentDate'];
        }
        this.presentConditionHistoryServices.countPresentConditionHistory(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllPresentConditionHistoriesByAppointmentId();
            } else {
                this.totalRecords = 0;
                this.presentConditionHistoryList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }
//     if((this.treatmentTypeList.filter(d => {return d.selected === 1}).length) < 1){
//     this.treatmentTypeError = true;
// } else {
//     this.treatmentTypeError = false;
// }


    onSubmit(): void {
        this.presentConditionHistoryForm.markAllAsTouched();

        if (this.isEditing) {

          /* get stuff to delete */
          const presentConditionHistoryEditDTO = new EditHistoryFormDataDTO(this.objectMultiSelects, this.presentConditionHistoryForm);

          console.log(presentConditionHistoryEditDTO);

          if (!presentConditionHistoryEditDTO.hasMultiSelectErrors) {

            this.presentConditionHistoryServices.updatePresentConditionHistoryCpa(presentConditionHistoryEditDTO, this.editingPresentConditionHistoryId).subscribe(res => {

              if (res.status === 200) {
                this.stopLoader();
                this.messageService.add({
                  severity: 'success',
                  summary: this.translate(this.labels.entityNamePresentConditionHistory) +
                    this.translate(this.labels.updatedSuccessfully),
                });

                  this.findAllPresentConditionHistoriesByAppointmentId();

                this.submitted = true;
                this.showHideAddDialog();
              }
            });

          }
        }
        else {
          const presentConditionHistoryDTO = new InitialHistoryFormDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.presentConditionHistoryForm);

          const diagnosisData = new DiagnosisDataHelper(presentConditionHistoryDTO.diagnosisData);

          let uniqueCaseDiagnosisPredictionList = [];
          uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
          console.log(uniqueCaseDiagnosisPredictionList);
          console.log(presentConditionHistoryDTO.redFlagData);



          if (!presentConditionHistoryDTO.hasMultiSelectErrors) {
            this.presentConditionHistoryServices.addPresentConditionHistory(presentConditionHistoryDTO).subscribe(res => {

              if (res.status === 200) {
                this.stopLoader();

                this.messageService.add({
                  severity: 'success',
                  summary: this.translate(this.labels.entityNamePresentConditionHistory) +
                    this.translate(this.labels.addedSuccessfully),
                });

                if (uniqueCaseDiagnosisPredictionList.length > 0) {
                  this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

                    console.log(res);
                  });
                }

                if (presentConditionHistoryDTO.redFlagData.length > 0) {

                  this.lovServices.createListOfCaseRedFlags(presentConditionHistoryDTO.redFlagData).subscribe(res => {

                    if (res.status === 201) {

                      this.messageService.add({
                        severity: 'success',
                        summary: this.translate("Red Flags Updated"),
                      });
                    }

                  });
                }
                this.findAllPresentConditionHistoriesByAppointmentId();

                this.submitted = true;
                this.showHideAddDialog();
              } else {
                alert('eror');
              }

            });

        }
          else {

            presentConditionHistoryDTO.multiSelectErrorFields.forEach(multiSelectError => {

              document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
            });
          }

      }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllPresentConditionHistoriesByAppointmentId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.presentConditionHistorySearch.reset();
        this.countPresentConditionHistory();
    }

    addUpdatePresentConditionHistory(presentConditionHistory) {
        this.startLoader();
        let obj =this.utils.getUpdatedMillissecondObject(this.presentConditionHistoryForm.value,"initialAssessmentDate");

          obj.appointmentId = {

              appointmentId: this.appointmentId

          };

        obj.patientId = {

          patientId: this.patientId

        };

        obj.expertId = {

          expertId: this.expertId

        };

        obj.caseId = {

          caseId: this.caseId
        };

   /*   this.servicesSeenList =   this.servicesSeenList.map(serviceSeen => {
            serviceSeen.listOfValuesId =  {
                listOfValuesId: serviceSeen.listOfValuesId
            };

            return serviceSeen;
          });

      this.treatmentTypeList=     this.treatmentTypeList.map(treatmentType => {
            treatmentType.listOfValuesId =  {
          listOfValuesId: treatmentType.listOfValuesId
        };
          return treatmentType;
      });
      this.investigationList =   this.investigationList.map(investigation => {
        investigation.listOfValuesId =  {
          listOfValuesId: investigation.listOfValuesId
        };
        return investigation;
      });

      this.presentSymptomsList =   this.presentSymptomsList.map(presentSymptoms => {
        presentSymptoms.listOfValuesId =  {
          listOfValuesId: presentSymptoms.listOfValuesId
        };

        return presentSymptoms;
      });*/


          obj.historyData = {

            servicesSeen:  this.servicesSeenList.filter(serviceSeen => serviceSeen.selected === 1),
            treatmentType: this.treatmentTypeList.filter(treatmentType => treatmentType.selected === 1) ,
            investigation: this.investigationList.filter(investigation => investigation.selected === 1) ,
            presentSymptoms: this.presentSymptomsList.filter(presentSymptom => presentSymptom.selected === 1),
            refDataCheck : true


          };

        if (this.updatePresentConditionHistoryId == null) {
            this.presentConditionHistoryServices.addPresentConditionHistory(obj).subscribe(res => {
                console.log(res);
                if (res.status === 200) {
                    this.stopLoader();
                    presentConditionHistory = res.body;
                    this.presentConditionHistoryList.push(presentConditionHistory);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePresentConditionHistory) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePresentConditionHistory)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countPresentConditionHistory();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.presentConditionHistoryId = this.updatePresentConditionHistoryId;
            this.presentConditionHistoryServices.updatePresentConditionHistory(obj,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.presentConditionHistoryList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePresentConditionHistory) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePresentConditionHistory)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countPresentConditionHistory();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePresentConditionHistoryId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.lovDataObject = {"refId":0,"refType":""};
        this.multiSelects = this.presentConditionHistoryMultiSelects.multiSelects;
        this.objectMultiSelects = this.presentConditionHistoryMultiSelects.objectMultiSelects;

        // this.sharedService.lovDataObservable.next(this.lovDataObject);

        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));


        this.presentConditionHistoryForm = new FormGroup({
             initialAssessmentDate: new FormControl('', [Validators.required]),
             hpc: new FormControl('', [Validators.required, validateSpaces]),
             outcomeTreatmentType: new FormControl('', [Validators.required, validateSpaces] ),
             outcomeInvestigation: new FormControl('', [Validators.required, validateSpaces]),

             userId: new FormControl(0),
                                                         });
        this.presentConditionHistorySearch = new FormGroup({
                                                               initialAssessmentDate: new FormControl(),
                                                               hpc: new FormControl(),

                                                               rootPresentConditionHistoryId: new FormControl(),
                                                               outcomeTreatmentType: new FormControl(),
                                                               outcomeInvestigation: new FormControl(),
                                                               userId: new FormControl(),
                                                           });

        this.findAllPresentConditionHistoriesByAppointmentId();
       //// this.countPresentConditionHistory();
       ///// this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
     //   this.presentConditionHistorySearch.valueChanges.subscribe(data => {
          //  this.onValueChange = false;
      //  });

    }

    findAllPresentConditionHistoriesByAppointmentId(): void {

      this.startLoader();

      this.presentConditionHistoryServices.findPresentConditionHistoriesByAppointmentId(this.appointmentId).subscribe(
        res => {

          if (res.status === HttpStatusCode.Ok) {
            this.presentConditionHistoryList = res.body;
          } else {
            this.presentConditionHistoryList = [];
          }
          this.stopLoader();
        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });


    }

    deleteByIdPresentConditionHistory(presentConditionHistoryId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.presentConditionHistoryServices.deleteByIdPresentConditionHistory(presentConditionHistoryId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.presentConditionHistoryList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNamePresentConditionHistory) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNamePresentConditionHistory)
                                                });
                        this.countPresentConditionHistory();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deletePCH"
                                  });
    }

    async loadPresentConditionHistoryToEdit(presentConditionHistoryId: number, index): Promise<void> {
alert(index);
alert(presentConditionHistoryId);

        this.addEditTitle = this.labels.btnEdit;
        let presentConditionHistory = null;
        this.isEditing = true;
        this.editingPresentConditionHistoryId = presentConditionHistoryId;


        const res = await this.presentConditionHistoryServices.findPresentConditionHistoriesById(presentConditionHistoryId).toPromise();

       // this.presentConditionHistoryServices.findPresentConditionHistoriesById(presentConditionHistoryId).subscribe(res => {

            if (res.status === HttpStatusCode.Ok) {

              if (res.body) {

                presentConditionHistory = res.body;
                console.log(presentConditionHistory);
                this.updatePresentConditionHistoryId = presentConditionHistoryId;

                for (let [key, value] of Object.entries(presentConditionHistory.historyData)) {


                ///  alert(JSON.stringify(value));

                    this.presentConditionHistoryMultiSelects.objectMultiSelects[key].currentSelected = value;


                    this.presentConditionHistoryMultiSelects.objectMultiSelects[key].currentListOfValuesMasterId = value[0].listOfValuesMasterId;

                  }
                console.log(this.presentConditionHistoryMultiSelects.objectMultiSelects);


                this.presentConditionHistoryForm.controls['initialAssessmentDate'].setValue(
                  new Date(presentConditionHistory.initialAssessmentDate));

                this.presentConditionHistoryForm.controls['hpc'].setValue(presentConditionHistory.hpc);
                this.presentConditionHistoryForm.controls['outcomeInvestigation'].setValue(presentConditionHistory.outcomeInvestigation);
                this.presentConditionHistoryForm.controls['outcomeTreatmentType'].setValue(presentConditionHistory.outcomeTreatmentType);

                this.showAddDialog = true;
              }
              else {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translate(this.labels.entityNamePresentConditionHistory) +
                    'cannot find ',
                  detail: ''
                  // detail: this.translate(this.labels.entityNamePresentConditionHistory)
                });

              }

            }


        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);

        this.selectedIndex = index;

        this.lovDataObject = {"refId": presentConditionHistory['presentConditionHistoryId'],"refType":""};
        // this.sharedService.lovDataObservable.next(this.lovDataObject);

        if(presentConditionHistory['initialAssessmentDate']){
            this.presentConditionHistoryForm.controls['initialAssessmentDate'].setValue(
                new Date(presentConditionHistory['initialAssessmentDate']));
        }
        if(presentConditionHistory['hpc']){
            this.presentConditionHistoryForm.controls['hpc'].setValue(presentConditionHistory['hpc']);
        }

        if(presentConditionHistory['patientId']['patientId']){
            this.presentConditionHistoryForm.controls['patientId'].setValue(presentConditionHistory['patientId']['patientId']);
        }
        if(presentConditionHistory['expertId']['expertId']){
            this.presentConditionHistoryForm.controls['expertId'].setValue(presentConditionHistory['expertId']['expertId']);
        }

        if(presentConditionHistory['appointmentId']['appointmentId']){
            this.presentConditionHistoryForm.controls['appointmentId'].setValue(presentConditionHistory['appointmentId']['appointmentId']);
        }

      if(presentConditionHistory['caseId']['caseId']){
        this.presentConditionHistoryForm.controls['caseId'].setValue(presentConditionHistory['caseId']['caseId']);
      }
        if(presentConditionHistory['outcomeTreatmentType']){
            this.presentConditionHistoryForm.controls['outcomeTreatmentType'].setValue(presentConditionHistory['outcomeTreatmentType']);
        }
        if(presentConditionHistory['outcomeInvestigation']){
            this.presentConditionHistoryForm.controls['outcomeInvestigation'].setValue(presentConditionHistory['outcomeInvestigation']);
        }

        if(presentConditionHistory['userId']){
            this.presentConditionHistoryForm.controls['userId'].setValue(presentConditionHistory['userId']);
        }

        // by ali
      if(this.showAddDialog === false && this.editTag === false){
        this.editTag = true;
        this.showAddDialog = true;
        this.showAddBtn = true;
        this.showSearchBtn = true;
        this.showSearchDialog = false;
      } else if(this.showAddDialog === true && this.editTag === false){
        this.editTag = true;
        this.showAddBtn = true;
        this.showSearchBtn = true;
        this.showSearchDialog = false;
      } else if(this.showAddDialog === true && this.editTag === true) {
        this.editTag = false;
        this.showAddDialog = false;
        this.showAddBtn = false;
        this.showSearchBtn = false;
        this.showSearchDialog = false;
      }
    }

    onClearAdd() {
        this.presentConditionHistoryForm.reset();
        this.presentConditionHistoryForm.markAsUntouched();
        this.updatePresentConditionHistoryId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.presentConditionHistoryForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.presentConditionHistoryForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.presentConditionHistoryForm.get(control)?.valid && this.presentConditionHistoryForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.presentConditionHistoryForm.reset();
    }

    onCloseSearch() {
        this.presentConditionHistorySearch.reset();
    }

    refresh() {
        this.countPresentConditionHistory();
    }

    onAddOverlayDiv() {
        this.presentConditionHistoryForm.reset();
    }

    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.presentConditionHistorySearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.presentConditionHistoryForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePresentConditionHistoryId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.presentConditionHistoryServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.presentConditionHistoryList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.presentConditionHistoryServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.presentConditionHistoryList = result['data'];
            } else {
                this.presentConditionHistoryList = [];
            }
            console.log(result, 'result');
        });
    }
}
