import {Component, Input, OnInit} from '@angular/core';
import {CaseService} from "../web-services/case.service";
import {SessionService} from "../services/session.service";
import {Router} from "@angular/router";
import {FlagsService} from "../web-services/flags.service";
import {PatientsService} from "../web-services/patients.service";

@Component({
  selector: 'app-patient-details-information',
  templateUrl: './patient-details-information.component.html',
  styleUrls: ['./patient-details-information.component.css']
})
export class PatientDetailsInformationComponent implements OnInit {

  @Input() patientObject;
  @Input() patientCasesList;

  patientInformation: any;
  patientCases: any;
  btnStatus: string;


  constructor( private caseService: CaseService,
               private sessionService: SessionService,
               private router: Router,
               private flagsService: FlagsService,
               private patientsServices: PatientsService

  ) { }

  ngOnInit(): void {
        this.patientInformation = this.patientObject;
        this.patientCases = this.patientCasesList;
      //  alert(this.patientCasesList.length);

  }

  findAllCasesByPatientId(): void
  {
    this.patientsServices.findAllCasesByPatientId(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {

      if (res.status === 200) {

        this.patientCasesList = res.body;

      }

    });

  }

  videoConference(): any {

    window.open("https://meet.clinicalproassist.com/newroom",'_blank','height=570,width=520,scrollbars=no,status=no');
  }

  selectCase($event: any): void {

    console.log($event.value);

    this.sessionService.setSession('caseId',$event.value);
    this.router.navigate(['history']);
  }




  createCase() {


    this.caseService.addCaseForPatient(parseInt(this.sessionService.getSession('patientId')),parseInt(this.sessionService.getSession('loggedInUser'))).subscribe(res => {
     /// alert('got response case');
      console.log(res);
      if (res['message'] == 'Success') {



        this.sessionService.setSession('caseId',res['data'][0].caseId);
        this.router.navigate(['history']);

      } else {
        console.error('error: ', res['documentation']);
      }

    },err => {
      console.error('err: ', err);
     /// this.stopLoader();
    });

  }

}
