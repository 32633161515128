import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class NeuroTestingMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['dermatomeDescription']: {
      name: Constant.TESTING_NEURO_LOV_DERMATOME_DESCRIPTION,
      dbField: 'dermatomeDescription',
      label: 'Dermatome Description',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false


    },
    ['reflexes']: {
      name: Constant.TESTING_NEURO_LOV_REFLEXES,
      dbField: 'reflexes',
      label: 'Reflexes',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    ['reflexesDescription']: {
      name: Constant.TESTING_NEURO_LOV_REFLEXES_DESCRIPTION,
      dbField: 'reflexesDescription',
      label: 'Reflexes Description',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    }
  };

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
