import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {WadDetailService} from '../web-services/wad-detail.service';
import {UsersService} from '../web-services/users.service';
import {OutcomeMeasureService} from '../web-services/outcome-measure.service';
import {AppointmentsService} from '../web-services/appointments.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-wad-detail',
               templateUrl: './wad-detail.component.html',
               styleUrls: ['./wad-detail.component.css']
           })
export class WadDetailComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateWadDetailId = null;
    usersList: any = [];
    outcomeMeasureList: any = [];
    wadDetailList: any = [];
    wadDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    wadDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private wadDetailServices: WadDetailService,
        private outcomeMeasureServices: OutcomeMeasureService,
        private usersServices: UsersService,
        private appointmentsServices: AppointmentsService, private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService, private messageService: MessageService, private route: Router,
        private loader: NgxUiLoaderService, private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateWadDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.wadDetailSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countWadDetail();
        this.findAllWadDetail();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countWadDetail() {
        this.wadDetailServices.countWadDetail(this.wadDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllWadDetail();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateWadDetail(this.wadDetailForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllWadDetail();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.wadDetailSearch.reset();
        this.countWadDetail();
        // this.findAllWadDetail();
    }

    addUpdateWadDetail(wadDetail) {
        this.startLoader();
        if (this.updateWadDetailId == null) {
            this.wadDetailServices.addWadDetail(this.wadDetailForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    wadDetail = res['data'][0];
                    this.wadDetailList.push(wadDetail);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameWadDetail) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameWadDetail)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countWadDetail();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            wadDetail.wadDetailId = this.updateWadDetailId;
            this.wadDetailServices.updateWadDetail(wadDetail).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.wadDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameWadDetail) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameWadDetail)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateWadDetailId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllUsers();
        this.findAllOutcomeMeasure();
        this.findAllUsers();
        // this.findAllAppointments();
        this.wadDetailForm = new FormGroup({
                                               wadDetailTitle: new FormControl('', [Validators.required, validateSpaces]),
                                               wadDetail: new FormControl('', [Validators.required, validateSpaces]),
                                               wadDetailImage: new FormControl('', [Validators.required, validateSpaces]),
                                               patientId: new FormControl(),
                                               outcomeMeasureId: new FormControl(),
                                               expertId: new FormControl(),
                                               appointmentId: new FormControl(),
                                               userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                           });
        this.wadDetailSearch = new FormGroup({
                                                 wadDetailTitle: new FormControl(),
                                                 wadDetail: new FormControl(),
                                                 wadDetailImage: new FormControl(),
                                                 patientId: new FormControl(),
                                                 outcomeMeasureId: new FormControl(),
                                                 expertId: new FormControl(),
                                                 appointmentId: new FormControl(),
                                                 userId: new FormControl(),
                                             });
        this.countWadDetail();
        this.findAllWadDetail();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.wadDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllWadDetail() {
        this.startLoader();
        this.wadDetailServices.findAllWadDetail(this.wadDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.wadDetailList = res['data'];
                } else {
                    this.wadDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdWadDetail(wadDetailId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.wadDetailServices.deleteByIdWadDetail(wadDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.wadDetailList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameWadDetail) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameWadDetail)
                            });
                        this.countWadDetail();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadWadDetailToEdit(wadDetailId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let wadDetail = this.wadDetailList.find(x => x['wadDetailId'] == wadDetailId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateWadDetailId = wadDetail['wadDetailId'];
        this.selectedIndex = index;
        this.wadDetailForm.controls['wadDetailTitle'].setValue(wadDetail['wadDetailTitle']);
        this.wadDetailForm.controls['wadDetail'].setValue(wadDetail['wadDetail']);
        this.wadDetailForm.controls['wadDetailImage'].setValue(wadDetail['wadDetailImage']);
        this.wadDetailForm.controls['patientId'].setValue(wadDetail['patientId']['patientId']);
        this.wadDetailForm.controls['outcomeMeasureId'].setValue(wadDetail['outcomeMeasureId']['outcomeMeasureId']);
        this.wadDetailForm.controls['expertId'].setValue(wadDetail['expertId']['expertId']);
        this.wadDetailForm.controls['appointmentId'].setValue(wadDetail['appointmentId']['appointmentId']);
        this.wadDetailForm.controls['userId'].setValue(wadDetail['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.wadDetailForm.reset();
        this.wadDetailForm.markAsUntouched();
        this.updateWadDetailId = null;
        closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllOutcomeMeasure() {
        let temp = [];
        this.startLoader();
        this.outcomeMeasureServices.findAllOutcomeMeasure(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['outcomeMeasureId'];
                        dropDown.label = object['null'];
                        this.outcomeMeasureList.push(dropDown);
                    }
                } else {
                    this.outcomeMeasureList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.wadDetailForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.wadDetailForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.wadDetailForm.get(control)?.valid && this.wadDetailForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.wadDetailForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.wadDetailSearch.reset();
    }

    refresh() {
        this.countWadDetail();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.wadDetailForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.wadDetailForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateWadDetailId = null;
    }
}
