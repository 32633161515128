

<form (ngSubmit)="saveAnatomyInformation()" [formGroup]="bodyChartAnatomyDetailsForm" class="pngForm">



  <div style="overflow-y: auto;height: 95vh!important;">
    <div class="p-col-12" >
      <p-panel class="pngPanelMain" id="appointment-pngPanelMain">
        <p-header>Symptoms Details</p-header>
        <hr/>

          <div class="row" style="margin-bottom:20px">
            <div class="col-md-1">
              <!--
              <i class="fa fa-check fa-2x" style="color:green;padding:10px;"></i> -->
            </div>
            <div class="col-md-11">

              <ul class="pain-scale" *ngIf="selectedBodyPartInfo.isExisting">



                <li *ngFor='let pain of counter(10) ;let i = index' (click)="painScore(i+1,painScoreColours[i])"
                    [ngClass]="['pain-scale__level pain-scale__level--' + (i+1),i+1 <= selectedPainScore   ? 'pain-scale__level--selected pain-scale__level--blink': '']">
                  <span class="pain-scale__number">{{i+1}}</span>

                </li>
              </ul>


          <ul class="pain-scale" *ngIf="!selectedBodyPartInfo.isExisting">
            <li class="pain-scale__level pain-scale__level--1" (click)="painScore(1,'#7AD047')"><span class="pain-scale__number">1</span></li>
            <li class="pain-scale__level pain-scale__level--2" (click)="painScore(2,'#95CE49')"><span class="pain-scale__number">2</span></li>
            <li class="pain-scale__level pain-scale__level--3" (click)="painScore(3,'#ACE24E')"><span class="pain-scale__number">3</span></li>
            <li class="pain-scale__level pain-scale__level--4" (click)="painScore(4,'#F9E104')"><span class="pain-scale__number">4</span></li>
            <li class="pain-scale__level pain-scale__level--5" (click)="painScore(5,'#F8CE07')"><span class="pain-scale__number">5</span></li>
            <li class="pain-scale__level pain-scale__level--6" (click)="painScore(6,'#FCB129')"><span class="pain-scale__number">6</span></li>
            <li class="pain-scale__level pain-scale__level--7" (click)="painScore(7,'#FCA329')"><span class="pain-scale__number">7</span></li>
            <li class="pain-scale__level pain-scale__level--8" (click)="painScore(8,'#FC8A29')"><span class="pain-scale__number">8</span></li>
            <li class="pain-scale__level pain-scale__level--9" (click)="painScore(9,'#F96B2D')"><span class="pain-scale__number">9</span></li>
            <li class="pain-scale__level pain-scale__level--10" (click)="painScore(10,'#E21B15')"><span class="pain-scale__number">10</span></li></ul>
        </div></div>


        <section *ngIf="!selectedBodyPartInfo.isExisting || !isFollowUp">

        <div class="row text-center" style="margin:40px">
          <div class="col-md-1">  <!--
            <i class="fa fa-check fa-2x" style="color:green;padding:10px;"></i> --->
          </div>

          <div class="p-field p-col-11  formfieldheight p-pt-3">
            <label style="text-align: left!important;">{{translate(labels.bodyChartDetailColumnDuration)}}</label>
            <p-dropdown [filter]="true"
                        [options]="durationsList"
                        [showClear]="true"
                        class="pngDropdown"
                        formControlName="duration"
                        placeholder="Select">
            </p-dropdown>
            <app-custom-error [control]="'duration'" [form]="bodyChartAnatomyDetailsForm"></app-custom-error>
          </div>
        </div>
        <div class="row text-center" style="margin:20px">
          <div class="col-md-1">

          </div> {{objectMultiSelects['description'].currentSelected.length}}
          <div class="p-field p-col-11 p-md-11 formfieldheight p-pt-3">
            <app-lov-details [categoryString]="objectMultiSelects['description'].name" (getResponse)="getResponse($event)"
                             label="{{objectMultiSelects['description'].label}}"
                             [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['description'].currentSelected"></app-lov-details>
            <span id="#descriptionError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
          </div>
        </div>

        <div class="row text-center" style="margin:20px">
          <div class="col-md-1">
          </div>
          <div class="p-field p-col-11  formfieldheight">
            <app-lov-details [categoryString]="objectMultiSelects['aggs'].name" (getResponse)="getResponse($event)"
                             label="{{objectMultiSelects['aggs'].label}}"
                             [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['aggs'].currentSelected"></app-lov-details>
            <span id="#aggsError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
          </div>

        </div>


        <div class="row text-center" style="margin:20px">
          <div class="col-md-1">
           <!--
            <i class="fa fa-check fa-2x" style="color:green;padding:10px;"></i>
            -->
          </div>
          <div class="col-md-11 text-center">
            <div class="p-field formfieldheight">
              <app-lov-details [categoryString]="objectMultiSelects['eases'].name" (getResponse)="getResponse($event)"
                               label="{{objectMultiSelects['eases'].label}}"
                               [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['eases'].currentSelected"></app-lov-details>
              <span id="#easesError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
            </div>
          </div>
        </div>
        <div class="row text-center" style="margin:20px">
          <div class="col-md-1">
            <!--
            <i class="fa fa-check fa-2x" style="color:green;padding:10px;"></i>
            -->
          </div>

        <div class="p-field p-col-11 formfieldheight">
          <label>{{translate(labels.bodyChartDetailColumnHourlySymptoms)}}</label>
          <p-dropdown [filter]="true"
                      [options]="hourlySymptomsList"
                      [showClear]="true"
                      class="pngDropdown"
                      formControlName="hourlySymptoms"
                      placeholder="Select">
          </p-dropdown>
          <app-custom-error [control]="'hourlySymptoms'" [form]="bodyChartAnatomyDetailsForm"></app-custom-error>
        </div>

        </div>
        </section>

          <div class="row" style="margin:20px"><div class="col-md-12"><h5>Additional Notes</h5>
            <textarea cols="15" rows="5" class="form-control" id="anatomy-notes" formControlName="bodyChartAnatomyNotes"></textarea></div></div>



        <div class="row m-5">
          <div class="p-col-11 p-offset-1">
            <div  class="text-center">
              <p-button label="Add" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
              <p-button label="Close" (click)="toggleMenu()" styleClass="p-button" class="pngBtnTransparent" style="margin-left:10px"></p-button>
            </div>

          </div>
        </div>

      </p-panel>
      <hr/>
    </div>
  </div>
</form>
