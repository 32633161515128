<p-confirmDialog key="imageDialog" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<section class="sectionmain">
    <div class="container-fluid">
      <div class="bodyChartPadding" style="padding: 0 70px">
        <!-- <p-card> -->
          <div class="">
            <h3 class="imgUploadBodyChartTitle">Upload Image</h3>
          </div>
  
          <div  class="p-grid bodyChartWrapper">
            <div class="p-col-12 bodyChartPainterro">
              <!-- <div class="painterroClass" id="customPainterro"></div> -->
              <app-painterro-component *ngIf = "visibleFlag == true" (fileUrlEmitter) = "fileUrlEmitterFunc($event)"></app-painterro-component>
            </div>
          
            <div class="buttonsClass uploadImgBtns">
              <div class="p-mr-4">
                <p-button class="pngBtnTransparent" label="View Images" (click)="findAllImages()" styleClass="p-button"></p-button>
              </div><!-- (click)="showImages = true" -->
              <!-- <div>
                <p-button class="pngBlueBtn" label="Save" (click) = "saveImage()" styleClass="p-button"></p-button>
              </div> -->
            </div>
          </div>
  
  
        <!-- </p-card> -->
  
      </div>
    </div>
  </section>
  <p-dialog header="Saved Images" [(visible)]="showImages" [modal]="true" [draggable]="false" styleClass="textBold">
    <div class="p-grid">
      <!-- <div class="p-col-12 bodyChartPainterro">
        <div class="painterroClass" id="customPainterro"></div>
  
      </div> -->
       <div class="p-col-12 sideImgs"> 
      <div class="p-grid rightImagesOne" >

     
          <div class="p-col-12 p-md-6 extractedImg" *ngFor = "let file of fileUrls">
            <div>
              <img [src]="file.fileurls">            </div>
  
            <div class="iconsDiv">
              <p-button class="blueBtnBodyChart1" icon="pi pi-download" (click)= downloadImageFile(file.fileurls)></p-button>
              <p-button class="redBtnBodyChart1" icon="pi pi-trash" (click)= "deleteImage(file)"></p-button>
            </div>
          </div>
         
        </div>
  
  
        <!-- Rsponsive Division   -->

         <div class="p-grid rightImagesTwo" >
          <div class="p-col-12 p-md-4 extractedImg" *ngFor = "let file of fileUrls">

  
            <img [src]="file.fileurls">
  
  
            <p-button class="redBtnBodyChart"icon="pi pi-trash" (click)= "deleteImage(file)"></p-button>
            <p-button class="blueBtnBodyChart" icon="pi pi-download" (click)= forceDownload(file.fileurls)></p-button>
          </div>
        
        </div>
  
      </div> 
      <div class="buttonsClass uploadImgBtns">
        <div class="buttonsMargin">
          <p-button class="pngBlueBtn" label="Close" (click)="showImages = false;  fileUrls = []" styleClass="p-button"></p-button>
        </div>
        <!-- <div class="buttonsMargin">
          <p-button class="pngBlueBtn" label="Save" styleClass="p-button"></p-button>
        </div> -->
      </div>
    </div>  
  </p-dialog>
