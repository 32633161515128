<!-- Header Component-->
<!-- <p-toast position="top-center"></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameCompanyUsers)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn"
                                    icon="pi pi-search-plus" (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                    pButton pTooltip="Advanced Search" tooltipPosition="bottom" type="button"></button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                    (click)="showHideAddDialog()" [hidden]="showAddBtn" pButton
                                    pTooltip="{{addEditTitle}} {{translate(labels.entityNameCompanyUsers)}}"
                                    tooltipPosition="bottom" type="button"></button>
                            </div>
                        </p-header>
                        <!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameCompanyUsers)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="companyUsersSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">


                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyUsersColumnUserName)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('userName')"
                                                    *ngIf="diplayeIconInField('userName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="userName" name="userName" pInputText type="text">
                                            </span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyUsersColumnLoginEmail)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('loginEmail')"
                                                    *ngIf="diplayeIconInField('loginEmail')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="loginEmail" name="loginEmail" pInputText
                                                    type="text"> </span>
                                        </div>


                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                        <!-- Add DialogBox  -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameCompanyUsers)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="companyUsersForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>First Name</label> <span
                                        class="p-input-icon-right"><i (click)="fieldReset('firstName')"
                                                                      *ngIf="diplayeIconInField('firstName')"
                                                                      class="pi pi-times color-danger"></i> <input
                                        [class.p-invalid]="inputFieldDanger('firstName')"
                                        class="pngInputtext" formControlName="firstName" name="firstName"
                                        pInputText type="text"></span>
                                        <app-custom-error [control]="'firstName'" [form]="companyUsersForm">
                                        </app-custom-error>
                                      </div>

                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>Last Name</label> <span
                                        class="p-input-icon-right"><i (click)="fieldReset('lastName')"
                                                                      *ngIf="diplayeIconInField('lastName')"
                                                                      class="pi pi-times color-danger"></i> <input
                                        [class.p-invalid]="inputFieldDanger('lastName')"
                                        class="pngInputtext" formControlName="lastName" name="lastName"
                                        pInputText type="text"></span>
                                        <app-custom-error [control]="'lastName'" [form]="companyUsersForm">
                                        </app-custom-error>
                                      </div>


                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyUsersColumnLoginEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('username')"
                                                    *ngIf="diplayeIconInField('username')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('username')"
                                                    class="pngInputtext" formControlName="username" name="username"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'username'" [form]="companyUsersForm">
                                            </app-custom-error>
                                        </div>



                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>User Type</label>
                                        <p-dropdown [filter]="false" [options]="userLevelList" [showClear]="false" optionLabel="userLevelName"
                                                optionValue="userLevelId"
                                                    formControlName="userLevelId" placeholder="Select User Level"
                                          (onChange)="userLevelChanged()"
                                        >
                                        </p-dropdown>
                                        <app-custom-error [control]="'userLevelId'" [form]="companyUsersForm">
                                        </app-custom-error>
                                      </div>




                                    </div>
                                  <div *ngIf="userLevelPermissions.length > 0">
                                    <div>Has Permissions To</div>
                                      <div *ngFor="let userLevelPermission of userLevelPermissions">
                                      <i class="fa fa-check icon-color-success"></i> {{userLevelPermission.title}}
                                    </div>

                                  </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button
                                            label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                            type="submit" styleClass="p-button" class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                        <!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)="onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" />
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)"
                                    [autoLayout]="true"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                    [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [alwaysShowPaginator]="false"
                                    [showCurrentPageReport]=true [totalRecords]="totalRecords"
                                    [value]="companyUsersList" class="pngTable manage-users-datatable"
                                    [ngClass]="{'pngTableEmptyMessage': companyUsersList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <!-- <th> CompanyId</th> -->
                                            <!-- <th> UserId</th> -->
                                            <!-- <th> MUserId</th> -->
                                            <th> User Name </th>
                                            <th> Login Email </th>

                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <!-- <td> {{element?.companyId}} </td> -->
                                            <!-- <td> {{element?.userId}} </td> -->
                                            <!-- <td> {{element?.mUserId.userId}} </td> -->
                                            <td> {{element?.userName}}</td>
                                            <td> {{element?.loginEmail}}</td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <!--                                                    <p-button-->
                                                    <!--                                                        (click)="loadCompanyUsersToEdit(element.companyUsersId, i)"-->
                                                    <!--                                                        icon="pi pi-pencil" label="Edit"-->
                                                    <!--                                                        styleClass="p-button pbtn-transparent">-->
                                                    <!--                                                    </p-button>-->
                                                    <p-button (click)="deleteByIdCompanyUsers(element.companyUsersId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <!--                                                    <p-button   icon="pi pi-eye"-->
                                                    <!--                                                                label="View"-->
                                                    <!--                                                                (click)="viewUserDetail(element.companyId)"-->
                                                    <!--                                                                styleClass="p-button pbtn-transparent"></p-button>-->
                                                    <button *ngIf="element.muserId != 0" type="button" pTooltip="Manage Subscription" pButton
                                                        icon="manage-subs-icon" label="" class="menu-actions"
                                                        (click)="showManageSubscription(element.userId)"></button>

                                                        <!-- show subscription -->
                                                        <p-dialog header="Manage Subscription"
                                                              [(visible)]="subscriptionViewModal"
                                                              [modal]="true"
                                                              [blockScroll]="true"
                                                              [baseZIndex]="10000"
                                                              [draggable]="false">

                                                        <ng-template *ngIf="!userSubscriptionList" pTemplate="emptymessage">
                                                            {{translate(labels.mat_pagination_no_data_msg)}}
                                                        </ng-template>

                                                        <div *ngFor="let item of userSubscriptionList; let i = index"
                                                             class="p-field-checkbox subsCheckboxView manageSubsClass">
                                                            <div class="p-grid manageSubsGrid">
                                                                <div class="p-col-6 manageSubsLabel">
                                                                    <label class="manageSubsLabel">{{translate(item?.userSubscriptionTitle)}}</label>
                                                                </div>
                                                                <!-- <div class="p-col-2"></div> -->
                                                                <div class="p-col-6 manageSubsSelectBtn">
                                                                    <p-selectButton
                                                                            [(ngModel)]="item.value"
                                                                            [name]="item.value"
                                                                            [optionValue]="manageOptions['value']"
                                                                            [options]="manageOptions"
                                                                            (onChange)="onSelect(item,i,element, $event)">
                                                                    </p-selectButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-template pTemplate="footer">
                                                            <div class="p-d-flex p-jc-end">
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="refreshSubs(element.userId)"
                                                                               label="Refresh" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="cancelUserSubscription()"
                                                                               label="Cancel" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div *ngIf="userSubscriptionList && userSubscriptionList.length > 0">
                                                                    <p-button (click)="saveUserSubscription($event)"
                                                                              label="Save" styleClass="p-button" class="pngBlueBtn">
                                                                    </p-button>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dialog>

                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No
                                        Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
