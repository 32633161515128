import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExerciseCategoryService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdExerciseCategory(exerciseCategoryId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ExerciseCategory/deleteExerciseCategory', {'exerciseCategoryId': exerciseCategoryId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countExerciseCategory(exerciseCategory): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'ExerciseCategory/count', {'exerciseCategory': exerciseCategory},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateExerciseCategory(exerciseCategory): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ExerciseCategory/updateExerciseCategory', [exerciseCategory], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addExerciseCategory(exerciseCategory): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ExerciseCategory/addExerciseCategory', [exerciseCategory], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllExerciseCategory(exerciseCategory, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ExerciseCategory/findAllExerciseCategory',
            {'exerciseCategory': exerciseCategory, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
