import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-patient-information-pill',
  templateUrl: './patient-information-pill.component.html',
  styleUrls: ['./patient-information-pill.component.css']
})
export class PatientInformationPillComponent implements OnInit {

  constructor() { }
  @Input() patientObj: any;
  ngOnInit(): void {
  }



}
