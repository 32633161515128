<!-- <app-app-header></app-app-header> -->
<section class="sectionmain" id="sectionmain-admin">
    <div class="container-fluid">
        <div class="p-fluid p-formgrid p-grid admin-grid">
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/client-history']">
                <div class="adminCard adminCardBlue" >
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/Patient-case%20(2).svg">
                        <div class="dashboard-card-text">
                            <p>Client History</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>

<!--Start-Commented for UAT-->

<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/']">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content noNumberCard">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/invoicing.svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Invoicing</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--End-Commented for UAT-->

        </div>
    </div>
</section>