import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ChatService} from '../services/chat/chat.service';
import {CollaborationSharedServiceService} from '../services/collaboration-shared-service/collaboration-shared-service.service';
import {CollabSessionService} from '../services/session/collab-session.service';
import {Participant} from '../utilities/model/chat';
import {FormBuilder, Validators} from '@angular/forms';
// import {DataSharedService} from 'src/app/web-services/data-shared.service';
// import {SessionService} from 'src/app/web-services/session.service';
// import {UserDetail} from 'src/app/interfaces/user';
import {closeDiv} from '../common/common';
import {SessionService} from '../../services/session.service';

@Component({
               selector: 'app-participant',
               templateUrl: './participant.component.html',
               styleUrls: ['./participant.component.css'],
           })
export class ParticipantComponent implements OnInit, OnChanges {

    @Input() selectedChat;
    @Output() emitSelectedGroup = new EventEmitter<any>();
    @Output() goToUserComponentToAddParticipants = new EventEmitter<any>();
    @Output() afterGroupUpdated = new EventEmitter<any>();
    @Output() closeParticipantComponent = new EventEmitter<any>();
    @Output() chatDeleted = new EventEmitter<any>();

    public participants;
    public participantModel;
    public groupId;
    public admin;
    public loggedInUser;
    public errorMessage;
    public applicationShortName = 'ums';

    public pageControlMap = new Map<string, boolean>().set('AddGroupParticipant', false).set('LeaveGroup', false);
    userRoles: string[];

    updateGroupForm = this.formBuilder.group({
                                                 title: ['', Validators.required],
                                                 description: ['', Validators.required],
                                             });

    // userDetail: any;

    constructor(
        private _webService: ChatService,
        public sharedService: CollaborationSharedServiceService,
        private collabSession: CollabSessionService,
        private formBuilder: FormBuilder,
        private sessionService: SessionService,
    ) {
        // this.userDetail = this.sessionService.getUserDetails();

    }


    ngOnInit() {
        this.checkVersion();
        // this.userDetail = this.sessionService.getUserDetails();
        // this.userDetail = sessionStorage.getItem('loggedInUser');
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
    }


    currentVersion = '0';

    checkVersion() {
        this.sharedService.CurrentVersion.subscribe(data => {
            this.currentVersion = data;
        });
    }

    groupName = '';
    groupDescription = '';

    ngOnChanges() {

        if (this.selectedChat) {
            // this.checkApplicationShortNameAndAssign();
            // this.assignUserRolesAndPageControlMap();
            this.loggedInUser = sessionStorage.getItem('loggedInUser');
            // this.loggedInUser = this.userDetail.userId;
            this.groupId = this.selectedChat.cbGroupData.groupDataId;
            this.groupName = this.selectedChat.cbGroupData.title;
            this.groupDescription = this.selectedChat.cbGroupData.description;
            this.participantModel = new Participant(0, '', '', this.groupId, 0, 0, 0);
            this.getAllparticipants();
        }
    }

    getAllparticipants() {
        this._webService.getParticipants(this.participantModel).subscribe(data => {
            if (data && data['statusCode'] == 200) {
                this.participants = data['data'];
                this.sharedService.alreadyAddedParticipants = this.participants;
            }
        });
    }

    // checkApplicationShortNameAndAssign() {
    //   if (this.globalSharedService.getCookie('applicationShortName')) {
    //     this.applicationShortName = this.globalSharedService.getCookie('applicationShortName')
    //   }
    // }

    // assignUserRolesAndPageControlMap() {
    //   if (this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') && this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') != undefined) {
    //     this.userRoles = this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles');
    //     this.pageControlMap = this.globalSharedService.checkRole(this.pageControlMap, this.userRoles);
    //   }
    // }

    //-----------------When AddParticipant Button Is Clicked----------------

    openUser() {
        this.collabSession.isNewChatButtonClicked = false;
        this.collabSession.isAddParticipantClicked = true;
        this.goToUserComponentToAddParticipants.emit(this.selectedChat);
    }

    //----------------------Remove Group Participant-------------------------

    removeParticipant(groupparticipant, index) {
        this._webService.deleteGroupParticipant(groupparticipant).subscribe(
            data => {
                if (data && data['statusCode'] == 200) {
                    // this.toastr.showSuccess("Remove", "Participant");
                    this.participants.splice(index, 1);
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    //-----------Remove Group Participant------------------

    leaveGroup() {
        this._webService.leaveGroup(this.loggedInUser, this.groupId).subscribe(
            data => {
                if (data && data['statusCode'] == 200) {
                    closeDiv('groupinfochat-overlaydiv');
                    this._webService.getParticipants(this.participantModel).subscribe(data => {
                        if (data && data['statuCode'] == 200) {
                            this.participants = data['data'];
                            if (data['data'] == null) {
                                this.deleteGroup();
                            }
                            this.sharedService.alreadyAddedParticipants = this.participants;
                        } else {
                            this.chatDeleted.emit(this.selectedChat);
                        }
                    });
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    verifyLabel(label) {
        return label;
    }

    backToChatScreen() {
        closeDiv('groupinfochat-overlaydiv');
        this.closeParticipantComponent.emit();
    }

    deleteGroup(): void {
        this._webService.deleteChat(this.selectedChat.chatId,parseInt(sessionStorage.getItem('loggedInUser'))).subscribe(
            data => {
                this.sharedService.Print(data);
                if (data && data['statusCode'] == 200) {
                    closeDiv('groupinfochat-overlaydiv');
                    this.chatDeleted.emit(this.selectedChat);
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    updateGroup() {
        let Object1 =
            {
                'groupId': this.selectedChat.cbGroupData.groupDataId,
                'title': this.groupName,
                'description': this.groupDescription
            };
        // console.log(Object1);
        this._webService.updateGroup(Object1).subscribe(data => {
            if (data && data['statusCode'] == 200) {
                this.selectedChat.cbGroupData = data['data'][0];
                this.afterGroupUpdated.emit(true);
            } else {
                this.afterGroupUpdated.emit(false);
            }
        });
    }
}