import { Component, OnInit } from '@angular/core';
import { ExercisesService } from '../web-services/exercises.service';
import { PatientExercisesService } from '../web-services/patient-exercises.service';
import { ExerciseCategoryService } from '../web-services/exercise-category.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-cpa-tools',
  templateUrl: './cpa-tools.component.html',
  styleUrls: ['./cpa-tools.component.css']
})
export class CpaToolsComponent implements OnInit {
// selected: boolean = false;
constructor(private patientExercisesServices:PatientExercisesService,
            private cpaToolService:ExerciseCategoryService,
            private exerciseService:ExercisesService,
            private loader: NgxUiLoaderService
)
{ }


ngOnInit(): void {

  this.findAllPatientExercises();
// this.findAllExerciseCategory();
}

categoriesList= new Array<any>();
findAllExerciseCategory()
{
  this.cpaToolService.findAllExerciseCategory(null,-1,0).subscribe(data=>
  {
   if(data['statusCode']==200)
   {
       this.categoriesList=data['data'];
       this.findAllExercises(this.categoriesList[0]['exerciseCategoryId'])
   }

  });
}

 exercises= new Array<any>();
 originalExecises=new Array<any>();
 handleChange(event)
 {
   console.log("Selected Index:::",event)
   this.findAllExercises(this.categoriesList[event.index]['exerciseCategoryId'])
 }

findAllExercises(categoryId)
{
 let data= {"exerciseCategoryId":categoryId}
  this.exerciseService.findAllExercises(data,-1,0).subscribe(data=>
  {
   if(data['statusCode']==200)
   {
       this.exercises=data['data'];
       this.originalExecises=data['data'];
       console.log("pateint exercise : ",this.patientExercises);
       console.log("exercise : ",this.exercises);

       if(this.patientExercises!=null && this.patientExercises.length>0)
       {
         for(let i=0;i<this.exercises.length;i++)
         {
           if(this.patientExercises.find(x=>x['exerciseId']['exerciseId']==this.exercises[i]['exerciseId']))
           {
             this.exercises[i]['patientExercisesId']=this.patientExercises.find(x=>x['exerciseId']['exerciseId']==this.exercises[i]['exerciseId'])['patientExercisesId']
             this.originalExecises[i]['patientExercisesId']=this.patientExercises.find(x=>x['exerciseId']['exerciseId']==this.exercises[i]['exerciseId'])['patientExercisesId']
           }
         }
       }
      
    console.log('exercises list on init :: ',this.exercises);
   }else
   {
     this.exercises=new Array<any>();
     this.originalExecises=new Array<any>();
   }

  });
}

addUpdatePatientExercises(exerciseId,index) {

  let patientExercice={
    exerciseId: exerciseId,
    patientId: parseInt(sessionStorage.getItem("patientId")),
    expertId:parseInt(sessionStorage.getItem("expertId")),
    appointmentId: parseInt(sessionStorage.getItem("appointmentId")),
    userId: parseInt(sessionStorage.getItem("loggedInRefId")),
}
      this.patientExercisesServices.addPatientExercises(patientExercice).subscribe(res => {
          if (res['message'] == 'Success') {
            this.patientExercises.push(res['data'][0])
           this.selectedItem=this.selectedItem+1;
            this.exercises[index]['patientExercisesId']=res['data'][0]['patientExercisesId']
            this.originalExecises[index]['patientExercisesId']=this.patientExercises.find(x=>x['exerciseId']['exerciseId']==this.exercises[index]['exerciseId'])['patientExercisesId']
          } else {
              console.error(res['documentation']);

          }
      }, err => {
          console.error('err: ', err);
        
      });
 
  }

   patientExercises=new Array<any>();
  
   
   selectedItem=0;
   findAllPatientExercises() {
    this.selectedItem=0;
    let patientExercice={
      patientId: parseInt(sessionStorage.getItem("patientId")),
      expertId:parseInt(sessionStorage.getItem("expertId")),
      appointmentId: parseInt(sessionStorage.getItem("appointmentId")),
  }
        this.patientExercisesServices.findAllPatientExercises(patientExercice,-1,0).subscribe(res => {
            if (res['message'] == 'Success') {
               this.patientExercises=res['data'];
               this.selectedItem=this.patientExercises.length
                this.findAllExerciseCategory();
            } else {
                this.findAllExerciseCategory();
                console.error(res['documentation']);
             
            }
        }, err => {
            this.findAllExerciseCategory();
            console.error('err: ', err);
          
        });
   
    }

    deleteByIdPatientExercises(patientExercisesId,index) {

          this.patientExercisesServices.deleteByIdPatientExercises(patientExercisesId).subscribe(res => {
              if (res['message'] == 'Success') {
                this.selectedItem=this.selectedItem-1;
                 let patientExercises=this.patientExercises.find(x=>x['patientExercisesId']==patientExercisesId);

                 // this.exercises.splice(index,1,patientExercises['exerciseId']['exerciseId']);
                 //  this.exercises[index]['patientExercisesId'].setValue(undefined);
                 // this.originalExecises.splice(index,1,patientExercises['exerciseId']['exerciseId']);
                 // this.patientExercises.splice(this.patientExercises.findIndex(x=>x['patientExercisesId']==patientExercisesId),1);
                  this.findAllPatientExercises();
              } else {
                  console.error(res['documentation']);
               
              }
          }, err => {
              console.error('err: ', err);
            
          });
     
      }
  // this.stopLoader();
  searchterm="";
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.exercises = new Array<string>();
    this.exercises = this.filterArray(this.originalExecises, filterValue)
  }

  filterArray(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if (it.title) {
        return it.title.toLowerCase().includes(searchText);
      }
    });
  }
    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }
  

}


