import { Component, OnInit, ElementRef, Renderer2, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {Labels} from '../util/labels';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { PrimeIcons} from 'primeng/api';
import {PatientsService} from "../web-services/patients.service";
import {SessionService} from "../services/session.service";
import {CaseService} from "../web-services/case.service";
import {FormDataDTO} from "../dto/FormDataDTO";
import {AppointmentFormDataDTO} from "../dto/AppointmentFormDataDTO";
import {Constant} from "../util/constant";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
  @ViewChild('#initialAssessmentToggle') initialAssessmentToggle: ElementRef;
  public labels = Labels;
  @Input() name: string;
  @Input() clickedEvent: any;
  @Input() patientsList: any;
  @Input() patientTypeList: any;
  @Input() consentList: any;
  @Input() selectedExpertName: any;
  @Input() selectedExpertId: any;
  @Input() selectedClinicId: any;
  @Input() patientCases: any;

  patientSelected = false;
  selectedPatient: any;
  serviceSelected = false;
  consentSelected = false;
  durationSlider: number;
  patientCasesList: any;
  selectedValue: string;
  patientId;
  caseId = 0;
  caseCreated = false;
  caseCreatedSelected = false;


  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  appointmentsForm: FormGroup;


  constructor(private el:ElementRef,
    renderer: Renderer2,
    private patientsService: PatientsService,
              private sessionService: SessionService,
              private caseService: CaseService

              ) { }

  ngOnInit(): void {

    this.appointmentsForm = new FormGroup({
      appointmentTypeId: new FormControl(),
      isFollowUp: new FormControl(false),
      caseNotes: new FormControl(''),
      duration: new FormControl(30),
      consent: new FormControl(0, [Validators.required, Validators.min(0)]),
      patientFor: new FormControl(0, [Validators.required, Validators.min(0)]),
      videoConference: new FormControl(false),
      //// duration:  new FormControl(this.clickedEvent.extendedProps.slotTime, [Validators.required, Validators.min(0)]),


    });
  }
  handleSliderChange($event) {

    console.log($event);

  }

  findAllCasesByPatientId(patientId: number): void
  {
    this.patientsService.findAllCasesByPatientId(patientId).subscribe(res => {

      if (res.status === 200) {

        this.patientCasesList = res.body;

      }

    });

  }

  selectPatient($event): void {

    console.log($event);


  }

  ngOnChanges(changes): void {



  ///  this.appointmentsForm.addControl('clinicExpertScheduleId', new FormControl(changes.clickedEvent.currentValue.extendedProps.clinicExpertScheduleId, [Validators.required]));

  }

  createCase(): void {

    const formCaseData = {

      patientId: {
        patientId:  this.patientId,
      },
      caseNotes: this.appointmentsForm.controls['caseNotes'].value
    };

    this.caseService.addCaseForPatientCpa(formCaseData).subscribe(res => {

      if (res.status === 201) {
        this.caseCreated  = true;
        this.caseId = res.body.caseId;
        this.caseCreatedSelected = !this.caseCreatedSelected;
      }
    });
  }

  rangeChange($event: any): void {

    console.log($event);
  }
  saveAppointment(): void {

    const appointmentDataDTO = new AppointmentFormDataDTO(this.patientId, this.selectedExpertId, this.caseId, this.selectedClinicId, this.appointmentsForm, this.clickedEvent);

    /* do final hack here if is follow up then set initial assessment to false */

      if (appointmentDataDTO.isFollowUp) {

          appointmentDataDTO.isInitialAssessment = false;
      }
      this.appointmentsForm.reset();
      this.formSubmitted.emit(appointmentDataDTO);

  }

  toggleMenu(): void {

    this.formSubmitted.emit(null);
  }

  onPatientSelect(event): void {

    this.patientSelected = true;
    this.selectedPatient = this.patientsList.find(patient => patient.value === event.value);
    this.patientId = this.selectedPatient.classObject.patientId;

    this.findAllCasesByPatientId(this.patientId);

  }

  onServiceSelect(event): void {

    this.serviceSelected = !this.serviceSelected;

  }

  onConsentSelect(event): void {

    this.consentSelected = !this.consentSelected;

  }

  onCaseSelect(event): void {

    this.caseId = event.value;
    this.caseCreatedSelected = !this.caseCreatedSelected;
  }

  editSelectedPatient(): void {
    this.patientSelected = false;
  }

  toggleAppointmentType(event): void {


  }

}
