import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencyClinicService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgencyClinic(agencyClinicId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyClinic/deleteAgencyClinic', {'agencyClinicId': agencyClinicId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAgencyClinic(agencyClinic): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'AgencyClinic/count', {'agencyClinic': agencyClinic}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateAgencyClinic(agencyClinic): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyClinic/updateAgencyClinic', [agencyClinic], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addAgencyClinic(agencyClinic): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyClinic/addAgencyClinic', [agencyClinic], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAgencyClinic(agencyClinic, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'AgencyClinic/findAllAgencyClinic',
            {'agencyClinic': agencyClinic, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
