import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.css']
})
export class LogoutPopupComponent implements OnInit {

  displayLogoutModal: boolean = true
  constructor(private config: DynamicDialogConfig) { }

  openDialogObj;
  
  ngOnInit(): void {
    
  }

}
