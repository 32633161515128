import {Constant} from "../util/constant";

export class MultiSelect {

   multiSelects = [
    {

      name: '',
      dbField: '',
      label: '',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false,
    },
    ];

  objectMultiSelects: any;
  reset = false;

   getResponse($event) {

     const lovResponseData = $event;

     this.multiSelects.map(multiSelected => {

       if (multiSelected.name === lovResponseData[0]['categoryId']['categoryTitle']) {

            multiSelected.list = lovResponseData;
       }
     });
  }

  getResponseObjects($event) {

    const lovResponseData = $event;
    for (let [key, value] of Object.entries(this.objectMultiSelects)) {

            if (this.objectMultiSelects[key].name === lovResponseData[0]['categoryId']['categoryTitle']) {

              this.objectMultiSelects[key].list = lovResponseData;
            }
    }
  }

  resetMultiSelects() {

     this.objectMultiSelects = this.objectMultiSelects.map(ms =>  {

       ms.selected = false;
       ms.currentSelected =  [];
       ms.currentListOfValuesMasterId =  0;

       return ms;

     });
  }


}
