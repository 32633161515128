import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ClinicExpertsSchedualService} from '../web-services/clinic-experts-schedual.service';
import {ClinicExpertsService} from '../web-services/clinic-experts.service';
import {UsersService} from '../web-services/users.service';
import {ClinicsService} from '../web-services/clinics.service';
import {SessionService} from '../services/session.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {CompanyService} from '../web-services/company.service';
import {timestamp, Timestamp} from 'rxjs/internal/operators/timestamp';
import {Utils} from '../util/utils';
import {CalendarOptions, FullCalendarComponent} from "@fullcalendar/angular";
import * as Moment from 'moment';
import * as MomentRange from 'moment-range';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {User} from "../dto/User";

const moment = MomentRange.extendMoment(Moment);

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
  selector: 'app-clinic-experts-schedual',
  templateUrl: './clinic-experts-schedual.component.html',
  styleUrls: ['./clinic-experts-schedual.component.css'],
  providers: [DialogService],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})


export class ClinicExpertsSchedualComponent implements OnInit {
  public labels = Labels;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  formButton: string = this.translate(this.labels.btnAdd);
  submitted = false;
  selectedIndex = -1;
  updateClinicExpertsSchedualId = null;
  clinicExpertsList: any = [];
  usersList: any = [];
  clinicExpertsSchedualList: any = [];
  clinicExpertsSchedualSearch: FormGroup;
  clearSearchBtn = false;
  totalRecords;
  itemPerPage: number = Constant.ITEM_PER_PAGE;
  clinicExpertsSchedualForm: FormGroup;
  constantList = [];
  pageNo: number = 0;
  onValueChange = true;
  clinicsList: any = [];
  daysArray: any = [];
  selectDays: any = [];
  appointmentTimeMins: number = 0;
  loggedInUserType: any;
  loggedInRefId: any;
  loggedInUserId: any;
  showSearchDialog = false;
  showSearchBtn = false;
  showAddDialog = false;
  showAddBtn = false;
  editTag = false;
  disableColumn = false;
  weekDays = Constant.weekDays;
  selectedDays = [];
  selectedCalendarDays = [];
  selectedExpertId: number;
  selectedClinicId: number;
  addEditTitle = 'Add';
  drawerState: any = 'out';
  clickedEvent: any = {

    start: ''


  };
  fullAuthenticatedUser: User;
  selectedSchedule = [];


  // @ts-ignore
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    allDaySlot: false,
    expandRows: true,
    nowIndicator: true,
    selectable: true,
    weekends: true,
    dateClick: this.handleDateClick.bind(this), // bind is important!
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventBackgroundColor: 'red',
    themeSystem: 'bootstrap',
    headerToolbar: {
      left: 'timeGridWeek',
      end: 'today prev,next'
    }, // buttons for switching between views
    dayCellClassNames: this.handleDayClassNames.bind(this),
    eventOverlap: false,
    selectOverlap: false,
    slotMinTime: '07:00:00',
    slotMaxTime: '20:00:00',
    events: [

      // business hours 1
      {
        id: "not-working",
        start: '09:00',
        end: '17:00',
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // monday - thursday
        display: 'background'
      },
    ],

   /// eventConstraint: "not-working",

  };

  ref: DynamicDialogRef;

  constructor(
    private clinicExpertsSchedualServices: ClinicExpertsSchedualService,
    private clinicExpertsServices: ClinicExpertsService,
    private usersServices: UsersService,
    private primengConfig: PrimeNGConfig,
    private confirmation: ConfirmationService,
    private messageService: MessageService,
    private route: Router,
    private loader: NgxUiLoaderService,
    private constantService: ConstantService,
    private sharedService: SharedService,
    private clinicService: ClinicsService,
    private sessionService: SessionService,
    private dialogService: DialogService,
    private companyServices: CompanyService,
    private clinicExpertScheduleService: ClinicExpertsSchedualService

  ) {
  };

  toggleDrawer(): any {

    this.drawerState = this.drawerState === 'out' ? 'in' : 'out';
  //  alert(this.drawerState);
  }

  handleEventClick(arg) {

    this.clickedEvent = arg.event;
    console.log(this.clickedEvent);

    this.toggleDrawer();

  }


  handleDateClick(arg) {

    let calendarApi = this.calendarComponent.getApi();

  }

  handleDayClassNames(arg) {

    console.log(arg);

    arg.unselect = true;
    //// arg.isDisabled = true;

  }


  handleDateSelect(selectionInfo) {

    const calendarApi = selectionInfo.view.calendar;


    console.log(selectionInfo.start.getTime());


    calendarApi.addEventSource(
      [
        {
          id: 'working',
          start: selectionInfo.start,
          end: selectionInfo.end,
          className: 'fc-event-full',
          block: true,
          backgroundColor: 'green',
          editable: true

        }]
    );

    this.selectedSchedule.push("1");
  }

  handleDayRender(dayRenderInfo) {



  }

  clinicExpertScheduleDeleteHandler(clinicExpertScheduleId) {

    alert(clinicExpertScheduleId);

  }

  ngOnInit() {

    this.addEditTitle = this.labels.btnAdd;
    this.loggedInUserId = parseInt(this.sessionService.getSession('loggedInUser'));
    this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
    this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.fullAuthenticatedUser =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser')) as User;


    // this.findAllClinicExperts();
    // this.findAllUsers();
    this.clinicExpertsSchedualForm = new FormGroup({
      clinicId: new FormControl(0, [Validators.required, Validators.min(0)]),
      expertsId: new FormControl(0, [Validators.required, Validators.min(0)]),
      clinicExpertId: new FormControl(),
      userId: new FormControl(),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      startTime: new FormControl(0, [Validators.required, Validators.min(0)]),
      endTime: new FormControl(0, [Validators.required, Validators.min(0)]),
      slotTime: new FormControl(0, [Validators.required, Validators.min(0)]),
      workingDays: new FormControl(0, [Validators.required, Validators.min(0)]),

    });
    this.clinicExpertsSchedualSearch = new FormGroup({
      clinicId: new FormControl(),
      expertsId: new FormControl(),
      clinicExpertId: new FormControl(),
      userId: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      startTime: new FormControl(),
      endTime: new FormControl(),
      slotTime: new FormControl(),
      workingDays: new FormControl(),
    });
    this.findAllClinicsByClientId();
    //this.countClinicExpertsSchedual();
    //this.findAllClinicExpertsSchedual();
    this.findClinicExpertScheduleByExpertId();
    this.getConstantList(1234);
    this.primengConfig.ripple = true;
    this.clearSearchBtn = false;
    this.clinicExpertsSchedualSearch.valueChanges.subscribe(data => {
      this.onValueChange = false;
    });
  }

  get addUpdateTitle() {
    return this.updateClinicExpertsSchedualId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
  }

  parentRefId: any;

  findAllClinicsByClientId(): void {

    this.startLoader();

    this.clinicService.findByClientId(this.fullAuthenticatedUser.clientId).subscribe(
      res => {

        if (res.status === 200) {
          let temp = res.body;
          for (let object of temp) {
            let dropDown = {label: '', value: ''};
            dropDown.value = object['clinicId'];
            dropDown.label = object['clinicName'];
            this.clinicsList.push(dropDown);
          }
        } else {
          this.clinicsList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });

  }

  async findAllClinicExperts(clinicExpertObj) {
    let paramsObj = {"clinicId": clinicExpertObj};
    let temp = [];
    this.startLoader();
    await this.clinicExpertsServices.findAllClinicExperts(paramsObj, null, null).toPromise().then(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          console.log(temp);
          for (let object of temp) {
            let dropDown = {label: '', value: '', clinicalExpertId: '', appointmentTimeMins: '', weekdaysWorked: ''};
            dropDown.value = object['expertId']['expertId'];
            dropDown.label = object['expertId']['firstName'] + " " + object['expertId']['lastName'];
            dropDown.clinicalExpertId = object['clinicExpertsId'];
            dropDown.appointmentTimeMins = object['expertId']['appointmentTimeMins'];
            dropDown.weekdaysWorked = object['expertId']['weekdaysWorked'];
            this.clinicExpertsList.push(dropDown);

          }
          // console.log("before");
        } else {
          this.clinicExpertsList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  onClinicChange(event): any {

    this.clinicExpertsList = [];
    this.selectedClinicId = event.value;
    this.findAllClinicExperts(this.selectedClinicId);
  }

  // tslint:disable-next-line:typedef
  onExpertChange(event): any {

    this.selectedExpertId = event.value;

    const selectedExpert = this.clinicExpertsList.find(expert => expert.value === event.value);

    const calendarApi = this.calendarComponent.getApi();

    this.appointmentTimeMins = parseInt(selectedExpert.appointmentTimeMins);

    const slotDuration = '00:' + selectedExpert.appointmentTimeMins + ':00';

    calendarApi.setOption('slotDuration', slotDuration);

    let weekdaysWorked = selectedExpert.weekdaysWorked.split(',').map(day => +day);

    const allDaysOfWeek = [0, 1, 2, 3, 4, 5, 6];

    const hiddenDays = allDaysOfWeek.filter(day => !weekdaysWorked.includes(day));

    const notWorkingCalendarEvents = calendarApi.getEventById('not-working');
    notWorkingCalendarEvents.remove();

    const notWorking = [

      {
        id: 'not-working',
        daysOfWeek: hiddenDays,
        display: 'background',
        selectable: true,
        editable: true,

      },

    ];

    calendarApi.addEventSource(notWorking);

    this.clinicExpertScheduleService.findAllClinicExpertSchedulesByClinicAndExpertIdCpa(this.selectedClinicId, this.selectedExpertId).subscribe(res => {

      if (res.status === 200) {


        let schedules = res.body.map(schedule => {

          return {
            id: 'working',
            start: schedule.scheduleStartDateTimeAsString,
            end: schedule.scheduleEndDateTimeAsString,
            className: 'fc-working',
            selectable: true,
            backgroundColor: 'green',
            display: 'background',
            overlap: true,
            extendedProps: {
              clinicExpertScheduleId: schedule.clinicExpertScheduleId,
              slotTime: schedule.slotTime
            }
          };


        });

        const workingEventSoruce = {

          events: schedules,
          id: 'working-events'

        };

        console.log(schedules);
        //this.schedules = workingEventSoruce;


        calendarApi.addEventSource(workingEventSoruce);

        /* also need to find all appointments for expert and clinic so we can add to calendar */

        const appointments = {

          clinicId: this.selectedClinicId,
          expertId: this.selectedExpertId
        };

      }

    }, err => {
      console.error('err: ', err);
      this.stopLoader();
    });


    const clinicExpertSchedule = {

      expertId: this.selectedExpertId,
      clinicId: this.selectedClinicId

    };



    this.clinicExpertsSchedualServices.findAllClinicExpertSchedulesByClinicAndExpertId(clinicExpertSchedule).subscribe(res => {



      if (res['message'] == 'Success') {

        console.log(res['data']);

        let schedules = res['data'].map(schedule => {

          let color = 'green';
          let editable = true;
          let selectable = true;
          let id = 'working';

          if (schedule[1] !== null)  {

            color = 'blue';
            editable = false;
            selectable = false;
            id = 'appointment';
          }

          return {
            id: id,
            start: schedule[0].scheduleStartDateTimeAsString,
            end: schedule[0].scheduleEndDateTimeAsString,
            className: 'fc-event-full',
            selectable: true,
            backgroundColor: color,
            editable: editable,
            overlap: selectable,
            extendedProps: {
                clinicExpertScheduleId: schedule[0].clinicExpertScheduleId
            }

          };
        });

        const workingEventSoruce = {

          events: schedules,
          id: 'working-events'

        };

       // console.log(schedules);
        calendarApi.addEventSource(workingEventSoruce);

        /* also need to find all appointments for expert and clinic so we can add to calendar */

      }
    }, err => {
      alert('error');

      console.error('err: ', err);
      this.stopLoader();
    });
  }
  onClearSearch(): any {
    this.clinicExpertsSchedualSearch.reset();
    this.selectedIndex = -1;
  }

  onSearch() {
    this.clearSearchBtn = true;
    this.countClinicExpertsSchedual();
    this.showHideSearchDialog();
  }

  startLoader() {
    this.loader.start();
    // setTimeout(() => {
    //     this.loader.stop();
    // }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }

  utils = new Utils();

  countClinicExpertsSchedual() {
    this.clinicExpertsSchedualSearch.controls['userId'].setValue(this.loggedInUserId);
    let obj = this.utils.getUpdatedMillissecondObject(this.clinicExpertsSchedualSearch.value, "startDate");
    obj = this.utils.getUpdatedMillissecondObject(obj, "endDate");
    if (obj['startDate'] === '0') {
      delete obj['startDate'];
    }
    if (obj['endDate'] === '0') {
      delete obj['endDate'];
    }

    this.clinicExpertsSchedualServices.countClinicExpertsSchedual(obj).subscribe(res => {
      if (res['message'] == 'Success') {
        this.totalRecords = res['data'][0];
        this.findAllClinicExpertsSchedual();
      } else {
        this.totalRecords = 0;
      }
    }, err => {
      console.error('err: ', err);
    });
  }

  workingDays: any;

  convertEventsToSaveableSchedule(): any {

    const calendarApi = this.calendarComponent.getApi();

    const workingEvents = calendarApi.getEvents().filter(event => event.id === 'working');
    console.log(workingEvents);

    const saveableSchedules = workingEvents.map(event => {

        const startTime = moment(event.start);
        const endTime = moment(event.end);
        const slotTime = this.appointmentTimeMins;

        const duration = moment.duration(endTime.diff(startTime));

        const durationInMins = duration.asMinutes();

    ///    alert(durationInMins);

        if (durationInMins !== slotTime) {

         // alert('this is  adiff ' + startTime.diff(endTime));
          let schedulesForTimeRange = [];

          while (startTime.diff(endTime) < 0) {

          let scheduleEndTime = moment(startTime);
            scheduleEndTime.add(slotTime, 'minutes');
          //scheduleEndTime.add(slotTime, 'minutes');
            //return {
          let schedule = {
              scheduleDate: moment(event.start).format('YYYY-MM-DD'),
              scheduleStartTime: startTime.format('HH:mm'),
              scheduleEndTime: scheduleEndTime.format('HH:mm'),
              scheduleStartDateTimeAsString: startTime.format('YYYY-MM-DD HH:mm:ss'),
              scheduleEndDateTimeAsString: scheduleEndTime.format('YYYY-MM-DD HH:mm:ss'),
              clinicId: this.clinicExpertsSchedualForm.controls[ 'clinicId' ].value,
              expertId: this.clinicExpertsSchedualForm.controls[ 'expertsId' ].value,
              slotTime: this.clinicExpertsSchedualForm.controls[ 'slotTime' ].value,
              userId: this.loggedInUserId

            };
          schedulesForTimeRange.push(schedule);
          console.log(schedule);
            //  alert('one time slot here ');
            //}
            startTime.add(slotTime, 'minutes');
            //scheduleEndTime.add(slotTime, 'minutes');

          }
          return schedulesForTimeRange;
        }
        else {

          return {

            scheduleDate: moment(event.start).format('YYYY-MM-DD'),
            scheduleStartTime: moment(event.start).format('HH:mm'),
            scheduleEndTime: moment(event.end).format('HH:mm'),
            scheduleStartDateTimeAsString: event.startStr,
            scheduleEndDateTimeAsString: event.endStr,
            clinicId: this.clinicExpertsSchedualForm.controls[ 'clinicId' ].value,
            expertId: this.clinicExpertsSchedualForm.controls[ 'expertsId' ].value,
            slotTime: this.clinicExpertsSchedualForm.controls[ 'slotTime' ].value,
            userId: this.loggedInUserId

          };
        }


    });
    console.log(saveableSchedules);
    let merged = [].concat.apply([], saveableSchedules);
    return merged;
  }

  onSubmit() {

    this.submitted = true;

    this.clinicExpertsSchedualForm.markAllAsTouched();

    ///TODO - need to put back code for validation //
    if (true) {

      const schedulesToSave = this.convertEventsToSaveableSchedule();

      console.log(schedulesToSave);

      this.addUpdateClinicExpertsSchedule(schedulesToSave);
      this.submitted = false;
      this.onClearAdd();
    }
  }

  onPageChnage(event: LazyLoadEvent) {
    this.itemPerPage = event.rows;
    this.pageNo = event.first / event.rows;
    this.findAllClinicExpertsSchedual();
  }

  clearSearchResult() {
    this.clearSearchBtn = false;
    this.clinicExpertsSchedualSearch.reset();
    this.countClinicExpertsSchedual();
    // this.findAllClinicExpertsSchedual();
  }

  addUpdateClinicExpertsSchedule(schedulesToSave): any {
    this.startLoader();

    ///TODO - need to fix this so it works out what to update and what not to

    if (true) {
      this.clinicExpertsSchedualServices.addClinicExpertsSchedule(schedulesToSave).subscribe(res => {

        if (res['message'] == 'Success') {
         // clinicExpertsSchedual = res['data'][0];
          //this.clinicExpertsSchedualList.push(clinicExpertsSchedual);
          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameClinicExpertsSchedual) +
              this.translate(this.labels.addedSuccessfully),
            // detail: this.translate(this.labels.entityNameClinicExpertsSchedual)
          });
          this.submitted = true;
          this.showHideAddDialog();
          this.countClinicExpertsSchedual();
        } else {
          this.stopLoader();
        }

      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
    } else {
      let obj = {};
      //obj.clinicExpertsSchedualId = this.updateClinicExpertsSchedualId;
      this.clinicExpertsSchedualServices.updateClinicExpertsSchedual(obj).subscribe(res => {
        if (res['message'] == 'Success') {
          this.clinicExpertsSchedualList[this.selectedIndex] = res['data'][0];
          this.submitted = true;
          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameClinicExpertsSchedual) +
              this.translate(this.labels.updatedSuccessfully),
            // detail: this.translate(this.labels.entityNameClinicExpertsSchedual)
          });
          this.showSearchBtn = false;
          this.showAddBtn = false;
          this.showSearchDialog = false;
          this.showAddDialog = false;
          this.editTag = false;
          this.countClinicExpertsSchedual();
        } else {
          console.error(res['documentation']);
          this.stopLoader();
        }
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
      this.updateClinicExpertsSchedualId = null;
      this.formButton = this.translate(this.labels.btnAdd);
    }
  }

  translate(label) {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

  getConstantList(formId) {
    this.constantService.getFormConstantsList(formId).subscribe(data => {
      if (data['statusCode'] == 200) {
        this.constantList = data['data'];
      } else {
        this.constantList = [];
      }
    });
  }

  findAllClinicExpertsSchedual() {
    this.startLoader();
    let obj = this.utils.getUpdatedMillissecondObject(this.clinicExpertsSchedualSearch.value, "startDate");
    obj = this.utils.getUpdatedMillissecondObject(obj, "endDate");
    if (obj['startDate'] === '0') {
      delete obj['startDate'];
    }
    if (obj['endDate'] === '0') {
      delete obj['endDate'];
    }

    this.clinicExpertsSchedualServices.findAllClinicExpertsSchedual(
      this.clinicExpertsSchedualSearch.value, this.pageNo, this.itemPerPage).subscribe(
      res => {
        if (res['message'] == 'Success') {
          this.clinicExpertsSchedualList = res['data'];
          // console.log(this.clinicExpertsSchedualList, 'For working days check');
        } else {
          this.clinicExpertsSchedualList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  findClinicExpertScheduleByExpertId() {

    let clinicExpertsSchedual = {

      "clinicId": 130,
      "expertId": 83

    }


    this.clinicExpertsSchedualServices.findAllClinicExpertsSchedual(clinicExpertsSchedual, 0, 10).subscribe(
      res => {

        if (res['message'] == 'Success') {
          console.log(res['data']);

          // this.clinicExpertsSchedualList = res['data'];
          // console.log(this.clinicExpertsSchedualList, 'For working days check');
        } else {

          //this.clinicExpertsSchedualList = [];
        }


      }, err => {
        alert('err: ' + err);
      });

  }

  deleteByIdClinicExpertsSchedual(clinicExpertsSchedualId: number, index) {
    this.confirmation.confirm({
      message: this.translate(this.labels.DELETE_MSG), accept: () => {
        this.startLoader();
        this.clinicExpertsSchedualServices.deleteByIdClinicExpertsSchedual(clinicExpertsSchedualId).subscribe(res => {
          if (res['message'] == 'Success') {
            this.clinicExpertsSchedualList.splice(index, 1);
            this.messageService.add({
              severity: 'warn',
              summary: this.translate(this.labels.entityNameClientDetails) +
                this.translate(this.labels.deletedSuccessfully),
              detail: ''
              // detail: this.translate(this.labels.entityNameClinicExpertsSchedual)
            });
            this.countClinicExpertsSchedual();
          } else {
            console.error('error: ', res['documentation']);
            this.startLoader();
          }
        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });
      }
    });
  }

  async loadClinicExpertsSchedualToEdit(clinicExpertsSchedualId: number, index) {
    this.addEditTitle = this.labels.btnEdit;

    let clinicExpertsSchedual = this.clinicExpertsSchedualList.find(x => x['clinicExpertsSchedualId'] == clinicExpertsSchedualId);
    // console.log("clinic expert schedual :: ",clinicExpertsSchedual);
    this.submitted = null;
    this.formButton = this.translate(this.labels.btnEdit);
    this.updateClinicExpertsSchedualId = clinicExpertsSchedual['clinicExpertsSchedualId'];
    this.selectedIndex = index;
    if (clinicExpertsSchedual['clinicId']) {
      this.clinicExpertsSchedualForm.controls['clinicId'].setValue(clinicExpertsSchedual['clinicId']['clinicId']);
      await this.findAllClinicExperts(clinicExpertsSchedual['clinicId']['clinicId']);
      // console.log("after await :: ")
    }

    if (clinicExpertsSchedual['expertsId']) {

      this.clinicExpertsSchedualForm.controls['expertsId'].setValue(clinicExpertsSchedual['expertsId']['expertId']);
    }

    if (clinicExpertsSchedual['clinicExpertId']) {
      this.clinicExpertsSchedualForm.controls['clinicExpertId'].setValue(clinicExpertsSchedual['clinicExpertId']['clinicExpertId']);
    }

    if (clinicExpertsSchedual['userId']) {
      this.clinicExpertsSchedualForm.controls['userId'].setValue(clinicExpertsSchedual['userId']);
    }

    if (clinicExpertsSchedual['startTime']) {
      this.clinicExpertsSchedualForm.controls['startTime'].setValue(clinicExpertsSchedual['startTime']);
    }

    if (clinicExpertsSchedual['endTime']) {
      this.clinicExpertsSchedualForm.controls['endTime'].setValue(clinicExpertsSchedual['endTime']);
    }

    if (clinicExpertsSchedual['startDate']) {
      this.clinicExpertsSchedualForm.controls['startDate'].setValue(new Date(clinicExpertsSchedual['startDate']));
    }

    if (clinicExpertsSchedual['endDate']) {
      this.clinicExpertsSchedualForm.controls['endDate'].setValue(new Date(clinicExpertsSchedual['endDate']));
    }

    if (clinicExpertsSchedual['slotTime']) {
      this.clinicExpertsSchedualForm.controls['slotTime'].setValue(clinicExpertsSchedual['slotTime']);
    }

    if (clinicExpertsSchedual['workingDays']) {
      this.workingDays = clinicExpertsSchedual['workingDays'].split(',');
      this.selectDays = [];
      for (let i = 0; i < this.workingDays.length; i++) {
        this.weekDays.map(d => {
          if (d.name === this.workingDays[i]) {
            this.selectDays.push(d);
          }
        });
      }
      this.clinicExpertsSchedualForm.controls['workingDays'].setValue(this.selectDays);
    }

    // by ali
    if (this.showAddDialog === false && this.editTag === false) {
      this.editTag = true;
      this.showAddDialog = true;
      this.showAddBtn = true;
      this.showSearchBtn = true;
      this.showSearchDialog = false;
    } else if (this.showAddDialog === true && this.editTag === false) {
      this.editTag = true;
      this.showAddBtn = true;
      this.showSearchBtn = true;
      this.showSearchDialog = false;
    } else if (this.showAddDialog === true && this.editTag === true) {
      this.editTag = false;
      this.showAddDialog = false;
      this.showAddBtn = false;
      this.showSearchBtn = false;
      this.showSearchDialog = false;
    }
    this.disableColumn = true;
  }

  onClearAdd() {
    this.clinicExpertsSchedualForm.reset();
    this.clinicExpertsSchedualForm.markAsUntouched();
    this.updateClinicExpertsSchedualId = null;
    this.submitted = false;
    this.selectedSchedule = [];
  }

  findAllUsers() {
    let temp = [];
    this.startLoader();
    this.usersServices.findAllUsers(null, null, null).subscribe(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          for (let object of temp) {
            let dropDown = {label: '', value: ''};
            dropDown.value = object['userId'];
            dropDown.label = object['userName'];
            this.usersList.push(dropDown);
          }
        } else {
          this.usersList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  fieldReset(control) {
    return this.clinicExpertsSchedualForm.get(control).reset();
  }

  diplayeIconInField(control) {
    return this.clinicExpertsSchedualForm.get(control)?.valid;
  }

  inputFieldDanger(control) {
    return !this.clinicExpertsSchedualForm.get(control)?.valid && this.clinicExpertsSchedualForm?.get(control)?.touched;
  }

  onCloseAdd() {
    closeAddOverlaydiv();
    this.clinicExpertsSchedualForm.reset();
  }

  onCloseSearch() {
    closeSearchOverlaydiv();
    this.clinicExpertsSchedualSearch.reset();
  }

  refresh() {
    this.countClinicExpertsSchedual();
  }

  onAddOverlayDiv() {
    openAddOverlaydiv();
    this.clinicExpertsSchedualForm.reset();
  }

  viewSchedualDetail(schedualId) {
    this.ref = null;
    let schedual = this.clinicExpertsSchedualList.find(x => x['clinicExpertsSchedualId'] == schedualId);

    this.ref = this.dialogService.open(ViewdetailComponent, {
      header: 'Schedule Detail',
      width: '80%',
      contentStyle: {'max-height': '750px', 'overflow': 'auto'},
      baseZIndex: 10000,
      data: {

        mainData: [
          {label: this.labels.clinicExpertsSchedualColumnClinicId, value: schedual['clinicId']['clinicName']},
          {
            label: this.labels.clinicExpertsSchedualColumnExpertsId,
            value: schedual['expertsId']['firstName'] + " " + schedual['expertsId']['lastName']
          },
          {label: this.labels.clinicExpertsSchedualColumnStartTime, value: schedual['startTime']},
          {label: this.labels.clinicExpertsSchedualColumnEndTime, value: schedual['endTime']},
          {label: 'Start Date ', value: new Date(schedual['startDate']).toDateString()},
          {label: 'End Date ', value: new Date(schedual['endDate']).toDateString()},
          {label: this.labels.clinicExpertsSchedualColumnSlotTime, value: schedual['slotTime']}
        ]

      }
    });
    this.ref.onClose.subscribe(res => {
      // this.countInvoice();
    });
  }

  searchTerm: any = null;

  onGlobalSearchClick(searchTerm) {

    this.searchTerm = searchTerm;
    this.schedualGlCount();

  }

  schedualGlCount() {
    this.clinicExpertsSchedualServices.schedualGlcount(this.searchTerm).subscribe(res => {
      if (res['message'] == 'Success') {
        this.totalRecords = res['data'][0];
        this.findGlSchedual();
      } else {
        this.totalRecords = 0;
        this.clinicExpertsSchedualList = [];
      }
    }, err => {
      console.error('err: ', err);
    });
  }

  findGlSchedual() {
    this.clinicExpertsSchedualServices.findGlobalschedual(this.searchTerm, this.pageNo, this.itemPerPage).subscribe(result => {
      if (result['statusCode'] == 200) {
        this.clinicExpertsSchedualList = result['data'];
      } else {
        this.clinicExpertsSchedualList = [];
      }
      // console.log(result,"result");
    });
  }

  // by ali
  showHideSearchDialog() {
    this.showSearchDialog = !this.showSearchDialog;
    this.showSearchBtn = this.showSearchDialog;
    this.showAddBtn = this.showSearchDialog;
  }

  showHideAddDialog() {
    if (!this.showAddDialog) {
      this.clinicExpertsSchedualForm.reset();
    }
    this.addEditTitle = this.labels.btnAdd;
    this.showAddDialog = !this.showAddDialog;
    this.showAddBtn = this.showAddDialog;
    this.showSearchBtn = this.showAddDialog;
    this.editTag = false;
    this.updateClinicExpertsSchedualId = null;
  }

  days: any;

}
