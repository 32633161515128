import { FlatTreeControl } from '@angular/cdk/tree';
import {Component, HostListener, Output, OnInit, ViewChild, Input, AfterViewInit, AfterViewChecked} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router, ActivatedRoute } from '@angular/router';
import { WebService } from '../services/webservice/web.service';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from '../services/sharedservice/shared.service';
import { AlertService } from '../services/alertservice/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '../services/navigateion-service/navigation.service';
import { NonInterceptService } from '../services/non-intercept/non-intercept.service';
import { MakeSkinsService } from '../services/skin-service/make-skins.service';
import { cookieObject, UserPreferences, CookieProject, ApplicationUrl, MenuI } from '../utilities/interfaces/interfaces';
import { AppSettings } from '../utilities/constants/constants';
import { EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserIdleService } from 'angular-user-idle';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CollaborationSharedServiceService } from '../collaboration/services/collaboration-shared-service/collaboration-shared-service.service';
import { CollaborationComponent } from '../collaboration/collaboration.component';
import {Constant} from '../util/constant';
import {SessionService} from '../services/session.service';
import {UserProfileDialogComponent} from '../user-profile-dialog/user-profile-dialog.component';
import {ConfirmationService} from 'primeng/api';
import {ChatService} from '../collaboration/services/chat/chat.service';
import {User} from '../dto/User';
declare var $: any;
// declare function toggleMenu(): any

/**
 * Food data with nested structure.
 * Each node has a name and an optiona list of children.
 */
interface FoodNode {
  name: string;
  path?: string,
  application_url: string,
  application_id: number,
  id: number,
  icon: string,
  quick_link: boolean,
  quick_link_id: number,
  children?: FoodNode[];
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  path: string;
  application_url: string,
  application_id: number,
  id: number,
  icon: string,
  quick_link: boolean,
  quick_link_id: number,
  level: number;
}


interface MenuNode {
  name: string;
  applicationroleid: number;
  menuicon: string;
  menuurl?: string;
  children?: MenuNode[];
}

/** Flat node with expandable and level information */
interface MenuFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit, AfterViewChecked {


  @Output() emitQuickLinks = new EventEmitter<any>();

  @Output() emitNotificationCount = new EventEmitter<any>();

  @Output() emitProject = new EventEmitter<any>();

  rocketChatUserTokenForIframe
  toggleM = false;
  expandedSideMenu = [];


  @ViewChild(CollaborationComponent) collaborationComponent;

  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      application_url: node.application_url,
      application_id: node.application_id,
      id: node.id,
      icon: node.icon,
      quick_link: node.quick_link,
      quick_link_id: node.quick_link_id,
      path: node.path,
    };
  }


  private _transformer2 = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      menuicon: node.menuicon,
      menuurl: node.menuurl,

      level: level,
    };
  };

  treeControl2 = new FlatTreeControl<MenuFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener2 = new MatTreeFlattener(
    this._transformer2,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  Application_Short_Name = AppSettings.APPLICATION_SHORT_NAME;

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.children);


 // dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSource = new MatTreeFlatDataSource(this.treeControl2, this.treeFlattener2);



  // constructor(private router:Router) {

  //   this.dataSource.data = TREE_DATA;
  // }

 // hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  hasChild = (_: number, node: MenuFlatNode) => node.expandable;

  openPage(node_name) {
    this.router.navigate(['/' + node_name]);
  }


  //runs on app refresh
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    // this.sharedService.resetCookieObject();
    this.cookieService.set("umsUrl", this.sharedService.umsUrl, null, "/");
    this.cookieService.set("applicationId", this.getCookie("applicationId"), null, "/");
    this.cookieService.set("token", this.getCookie("token"), null, "/");
    this.cookieService.set("rocketChatUserTokenForIframe", this.rocketChatUserTokenForIframe, null, "/");
    // console.log(this.cookieService.get(""))

  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private webService: WebService,
    private router: Router,
    private cookieService: CookieService,
    public sharedService: SharedService,
    private toastr: AlertService,
    // private sideBarComponent: SidebarComponent,
    private ngxSpinnerService: NgxUiLoaderService,
    // private _previewService: RestServiceService,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private nonInterceptService: NonInterceptService,
    private skinService: MakeSkinsService,
    private collaborationSharedService: CollaborationSharedServiceService,
    private sessionService: SessionService,
    private confirmation: ConfirmationService,
    private chatService: ChatService
  ) {
    // this.dataSource.data = TREE_DATA;
  }

  roleId: any;
  userTitle: any;
  // date: any;
  monthList = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  hours: any;
  mins: any;
  date: any;
  month: any;
  year: any;
  superSet: any;
  isShowing: boolean;
  fullAuthenticatedUser: any;
  currentMenuUrl: any


  public pageControlMap = new Map<string, boolean>().set("ChatApplication", false).set("MainMenu", false).set("AllNotifications", false).set("SubscribeNewEvent", false);

  // cookieObject as an input to child Module i-e Collaboration.
  public inputToChatModule: cookieObject = this.sharedService.cookieObject;

  @Output() emitCookieObject: EventEmitter<any> = new EventEmitter();
  sendObject() {
    // triggered whenever currentVersion in changed
    this.emitCookieObject.emit(this.sharedService.cookieObject);
  }

  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  sendNotification(value) {
    // console.log("sendnotification Called");
    // triggered boolen value when menu is initialized completely
    this.notifyParent.emit(value);
  }
  hideloginScreen = false;
  ngOnInit() {
    this.startLoader();

    this.currentMenuUrl = this.sessionService.getSession('currentUrl');



    // console.log("Menu Initializing...");
    console.log('url : ', this.router.url);
    if(this.router.url.includes('/login')){
      this.hideloginScreen = true;
    } else {
      this.hideloginScreen = false;
    }
    this.checkVersion();
    //this.checkUnreadNotifications();
    // console.log("currentVersion :: ", this.currentVersion);
    ///this.getRocketChatUserTokenFromSession();  // Getting rocketchat token for user
    var url = this.getUmsUrlFromSession();  // Getting Initial Url Of Server
    this.webService.setInitialUrl(url);  // Setting Initial Url Of Server
    this.callOtherFunctionsAfterAllWorkDoneInMenu();

    // setTimeout(() => {
     /// this.getParamertersFromUrl();
    ///   $('.sidebar-menu').tree();
   // }, AppSettings.ZERO);

    const user =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser')) as User;
    console.log(user);

    this.fullAuthenticatedUser = user;
    this.userTitle = user.firstName + ' ' + user.lastName;

    this.roleId = parseInt(this.sessionService.getSession('userType'));

    let d = new Date();
    this.hours = d.getHours().toString();
    // this.mins = d.getMinutes();
    if(d.getMinutes() <10) {
      this.mins = '0' + d.getMinutes().toString()
    } else {
      this.mins = d.getMinutes().toString()
    }
    this.date = d.getDate().toString();
    this.month = this.monthList[d.getMonth()];
    this.year = d.getFullYear().toString();

    let day = d.getDate().toString().split('');
    // let day = ['2'];
    let length = day.length;
    if (length < 2 && day[0] == '1') {
      this.superSet = 'st';
    } else if (length < 2 && day[0] == '2') {
      this.superSet = 'nd';
    } else if (length < 2 && day[0] == '3') {
      this.superSet = 'rd';
    } else if(parseInt(this.date) >10 && parseInt(this.date) < 21){
      this.superSet = 'th';
    }
    else if (length > 1 && day[length-1] == '1') {
      this.superSet = 'st';
    }else if (length > 1 && day[length-1] == '2') {
      this.superSet = 'nd';
    }else if (length > 1 && day[length-1] == '3') {
      this.superSet = 'rd';
    }else {
      this.superSet = 'th';
    }

    this.stopLoader();


   /* if(this.roleId === Constant.USER_TYPE_SUPERADMIN){
      // In case it is super-admin
      this.userTitle = 'Super Admin';
    }
    else if(this.roleId === Constant.USER_TYPE_ADMIN){
      // In case it is admin
      this.userTitle = 'Admin';
    }
    else if(this.roleId === Constant.USER_TYPE_EXPERT){
      // In case it is expert
      this.userTitle = 'Expert';
    }
    else if(this.roleId === Constant.USER_TYPE_CORPORATE){
      // In case it is corporate
      this.userTitle = 'Corporate Client !';
    }
    else if(this.roleId === Constant.USER_TYPE_INDIVIDUAL){
      // In case it is individual
      this.userTitle = 'Individual Client !';
    }*/



  }



  // setRocketChatUserToken(){
  //   if(this.rocketChatTokenFromUMS && this.rocketChatTokenFromUMS!=""){
  //     this.rocketChatUserTokenForIframe = this.rocketChatTokenFromUMS
  //     console.log(this.rocketChatUserTokenForIframe)
  //   }
  //   else {
  //     this.rocketChatUserTokenForIframe = this.cookieService.get("rocketChatUserToken")
  //   }
  // }

  openUserProfileDialog() {
    const dialogRef = this.dialog.open(UserProfileDialogComponent, {
      // width: "350px",
      data: {
        user: this.getCookie("userInfo"),
        // userPreferences : this.userPreferences
        userPreferences : this.userPreferences1
      }
    });
    // dialogRef.afterClosed().subscribe(result => {
    //
    //   this.changeInterval();
    //
    //   // this was added for testing whter on project change contexual chats changes or not
    //
    //   this.project = {
    //     image: "https://devbe.hive-worx.com:8443/HWAssets/Image/VB1V5WFQKSQQGO1M5O.png",
    //     owner: "Huda",
    //     projectId: 694,
    //     shortTitle: "HSE ",
    //     startDate: 1569783600000,
    //     status: "Draft",
    //     subProjectId: 0,
    //     title: "Demo Project 11 (HSE)",
    //     type: "Project",
    //   }
    //   console.log("project", this.project, this.project.toString())
    //   this.sharedService.setCookies("project", JSON.stringify(this.project));
    //
    //
    // });
  }

  openAboutUsDailog() {
    // const dialogRef = this.dialog.open(AbouUsDialog, {
    // });
  }


  ngAfterViewInit() {
    // console.log("called ngAfterViewInit");
    // this.mouseTrapEvents();
    if(this.router.url.includes('/login')){
      this.hideloginScreen = true;
    } else {
      this.hideloginScreen = false;
    }
  }


ngAfterViewChecked() {
  if(this.router.url.includes('/login')){
    this.hideloginScreen = true;
  } else {
    this.hideloginScreen = false;
    this.stopLoader();
  }
}

  // mouseTrapEvents() {
  //   var mousetrap = require('mousetrap');
  //   // const mousetrap = new Mousetrap;
  //   mousetrap.bind('m', () => {
  //     console.log("event called ");
  //     this.navigateApplication2("https://dev.hive-worx.com/xigt", "/tree")
  //   }, "keyup");
  // }

  // Checkng Current Version
  currentVersion = this.sharedService.cookieObject.currentVersion;
  checkVersion() {
    this.sharedService.CurrentVersion.subscribe(data => {
      this.currentVersion = data;
      // console.log("current Version :: :: :: ", this.currentVersion);
    });
  }
  unreadNotification: any;
  checkUnreadNotifications() {
    this.sharedService.getUnreadNotifications.subscribe(data => {
      this.unreadNotification = data;
    });
  }

  expandNode(node) {
    console.log(node)
    // this.treeControl.expand(this.treeControl.dataNodes[4]);
    // this.treeControl.expand(this.treeControl.dataNodes[5]);
  }

  getUmsUrlFromSession() {
    var umsUrl = this.cookieService.get("umsUrl");
    if (umsUrl) {
      this.sharedService.umsUrl = umsUrl;
      this.sharedService.setSession("umsUrl", umsUrl);
      this.sharedService.deleteCookie("umsUrl");
    }
    if (this.sharedService.getSession("umsUrl")) {
      umsUrl = this.sharedService.getSession("umsUrl");
      this.sharedService.removeSession("umsUrl");
      this.sharedService.umsUrl = umsUrl;
    }
    else {
      var url = window.location.href;
      if (!(url.includes("login"))) {
        this.logout();
      }
    }
    return umsUrl;
  }

  getRocketChatUserTokenFromSession() {
    this.rocketChatUserTokenForIframe = this.cookieService.get("rocketChatUserTokenForIframe");
    console.log(this.rocketChatUserTokenForIframe)
    console.log(this.sharedService.getSession("rocketChatUserTokenForIframe"))
    if (this.rocketChatUserTokenForIframe) {
      console.log("in get from cookie")
      this.sharedService.rocketChatUserTokenForIframe = this.rocketChatUserTokenForIframe;
      this.sharedService.setSession("rocketChatUserTokenForIframe", this.rocketChatUserTokenForIframe);
      this.sharedService.deleteCookie("rocketChatUserTokenForIframe");
    }
    if (this.sharedService.getSession("rocketChatUserTokenForIframe")) {
      console.log("in get from session")
      this.rocketChatUserTokenForIframe = this.sharedService.getSession("rocketChatUserTokenForIframe");
      this.sharedService.removeSession("rocketChatUserTokenForIframe");
      this.sharedService.rocketChatUserTokenForIframe = this.rocketChatUserTokenForIframe;

    }
    else {
      var url = window.location.href;
      if (!(url.includes("login"))) {
        // this.logout();
      }
    }
    return this.rocketChatUserTokenForIframe;
  }

  applicationId = 1;
  token: string = "";
  routedFrom = {};
  getParamertersFromUrl() {

    this.treeObject = [];
    var param: any;
    setTimeout(() => {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.hasOwnProperty('token') && params.hasOwnProperty('applicationId')) {

          param = Object.entries(params);
          for (var i = 0; i < param.length; i++) {
            if (param[i][0] == "applicationId") {
              this.applicationId = param[i][1];
              this.sharedService.setCookies("applicationId", this.applicationId);
            }
            if (param[i][0] == "token") {
              this.token = param[i][1];
              this.sharedService.setCookies("token", this.token);
            }
            if (param[i][0] == "rocketChatUserTokenForIframe") {
              this.rocketChatUserTokenForIframe = param[i][1];
              this.sharedService.rocketChatUserTokenForIframe = this.rocketChatUserTokenForIframe ;
            }
          }
          this.getAllApplication(this.applicationId, this.token);
        }
        else {
          this.token = this.getTokenFromSession();
          this.routedFrom = this.getRoutedFromInfoFromSession();
          this.applicationId = parseInt(this.getApplicationIdfromSession());
          if (this.token && this.applicationId) {

            this.sharedService.setCookies("token", this.token);
            this.sharedService.setCookies("routedFrom", this.token);

            this.sharedService.setCookies("applicationId", this.applicationId);
            this.getAllApplication(this.applicationId, this.token);
          }
          else {
            this.setCookie("authenticationStatus", false);
            this.sendNotification(this.getCookie("authenticationStatus"));
          }
        }
      });
    }, AppSettings.INITIAL_DELAY_TO_GET_PARAMS_FROM_URL)
  }

  getTokenFromSession() {
    var getTokenFromCookie = this.cookieService.get("token");
    if (getTokenFromCookie) {
      this.sharedService.setSession("token", getTokenFromCookie);
      // this.sharedService.deleteCookie("token");
    }
    var token = this.sharedService.getSession("token");
    return token;
  }

  getRoutedFromInfoFromSession() {
    var getRoutedFromCookie = this.cookieService.get("routedFrom");
    if (getRoutedFromCookie) {
      this.sharedService.setSession("routedFrom", getRoutedFromCookie);
      this.sharedService.deleteCookie("routedFrom");
    }
    var routedFrom = this.sharedService.getSession("routedFrom");
    return routedFrom;
  }

  getApplicationIdfromSession() {
    var getApplicationIdFromCookie = this.cookieService.get("applicationId");
    if (getApplicationIdFromCookie) {
      this.sharedService.setSession("applicationId", getApplicationIdFromCookie);
      this.sharedService.deleteCookie("applicationId");
    }
    var applicationId = this.sharedService.getSession("applicationId");
    return applicationId;
  }

  applicationInfo: any;
  userInfo: any;
  userPreferences1: any;
  switchApplicationDetails(token, appId) {
    this.webService.switchApplicationDetails(token, appId).subscribe(data => {
      // console.log("switchApplicationDetails :: ", data);
      if (data && data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
        this.applicationInfo = data['data'][0];
        this.userInfo = data['data'][1];
        this.getUserInfo();
        this.getApplicationInfo();
        this.allApplications = data['data'][2];
        // this.settingApplicationUrls(this.allApplications, appId);
        if (data['data'][3]) {
          this.userPreferences1 = data['data'][3];
          this.getUserPreferences();
        }
        else {
          this.callOtherFunctionsAfterAllWorkDoneInMenu();
          this.ngAfterViewInit2();
        }
        this.setCookie("authenticationStatus", true);
        this.sendNotification(this.getCookie("authenticationStatus"));
      }
      else {
        alert('logout');
        this.logout();
      }
    });
  }

  applicationShortName = "";
  getApplicationInfo() {
    // console.log("applicationInfo :: ", this.applicationInfo);
    this.setCookie("loggedInUser", this.applicationInfo['loggedInUser']);
    this.setCookie("expirationDate", this.applicationInfo['expirationDate']);
    this.setCookie("expirationDate", this.applicationInfo['expirationDate']);
    this.setCookie(this.getCookie("applicationShortName") + "_roles", this.applicationInfo['applicationRole']);
    // console.log("cookieObject :: ", this.sharedService.cookieObject);
  }
  getUserInfo() {
    this.setCookie("userInfo", this.userInfo);
    console.log(this.getCookie("userInfo"));
    ////this.setCookie("activatedRole", this.userInfo['businessRole']["businessRolesId"]);
    this.setCookie("userImage", this.userInfo['profileImage']);
    this.setCookie("userName", this.userInfo['username']);
    this.setCookie("client", this.userInfo['client']['clientId']);
    this.setCookie("gender", this.userInfo['gender']);

  }
  getUserPreferences() {
    var userPreferenceObject: UserPreferences = {
      chatInterval: this.userPreferences1["chatInterval"],
      defaultLanguage: this.userPreferences1['defaultLanguage'] != undefined ? this.userPreferences1['defaultLanguage']['languageId'] : 0,
      defaultProject: this.userPreferences1['defaultProject'] != undefined ? this.userPreferences1['defaultProject']['projectId'] : 0,
      defaultProjectRole: this.userPreferences1['defaultProjectRole'] != undefined ? this.userPreferences1['defaultProjectRole']['businessRolesId'] : 0,
      defaultSkin: this.userPreferences1["defaultSkin"],
      idleTimeout: this.userPreferences1['idleTimeout'],
      userPreferenceId: this.userPreferences1['userPreferenceId'],
      notificationInterval: this.userPreferences1['notificationInterval'],
    }
    this.sharedService.setCookies("activatedRole", this.userPreferences1['defaultProjectRole']['businessRolesId']);
    this.sharedService.setCookies("currentVersion", this.userPreferences1['version']);
    this.sharedService.setCurrentVersion(this.userPreferences1['version']);
    this.setCookie("userPreference", JSON.stringify(userPreferenceObject));

    if (this.userPreferences1['defaultProject'] != undefined) {
      var defaultProject: CookieProject = {
        projectId: this.userPreferences1['defaultProject']['projectId'],
        title: this.userPreferences1['defaultProject']['title'],
        status: this.userPreferences1['defaultProject']['status'],
        startDate: this.userPreferences1['defaultProject']['startDate'],
        shortTitle: this.userPreferences1['defaultProject']['shortTitle'],
        owner: this.userPreferences1['defaultProject']['owner'],
        image: this.userPreferences1['defaultProject']['image'],
        type: this.userPreferences1['defaultProject']['projectType']['title'],
        subProjectId: 0
      }
      if (defaultProject.type == "Scope") {
        defaultProject.subProjectId = defaultProject.projectId;
      }
      this.setCookie("project", JSON.stringify(defaultProject));
    }
    this.callOtherFunctionsAfterAllWorkDoneInMenu();
    this.ngAfterViewInit2();
  }

  allApplications: any;
  getAllApplication(appId, token) {
    // console.log("applicationId :: ", appId);
    // console.log("token", this.token);
    this.webService.getAllApplication().subscribe(data => {
      // console.log("Applications :: ", data);
      this.allApplications = data;
      this.settingApplicationUrls(this.allApplications, appId);
      this.switchApplicationDetails(this.token, this.applicationId);
    });
  }

  settingApplicationUrls(allApplications, appId) {
    // console.log(allApplications)
    for (var i = 0; i < allApplications.length; i++) {
      if (allApplications[i]['id'] == appId) {
        // console.log("matching Id :: ", allApplications[i]['id'], appId)
        this.setCookie("applicationShortName", allApplications[i]['code']);
      }
      var application: ApplicationUrl = {
        applicationId: allApplications[i]['id'],
        applicationName: allApplications[i]['code'],
        applicationFrontendUrl: allApplications[i]['frontendURL'],
        applicationBackendUrl: allApplications[i]['backendURL']
      }
      this.sharedService.applicationUrls.push(application);
    }
    console.log(this.sharedService.applicationUrls)
  }

  callOtherFunctionsAfterAllWorkDoneInMenu() {
    // console.log("here");
    this.findTokenAndOtherRelatedCookies();
    this.checkApplicationShortNameAlreadyExistInCookies();
    this.updateProject();
    this.checkCurrentApplicationRolesExistInSession();
    this.getCurrentUrl();
    this.checkUserPreferenceInCookiesAndModifySkinsAndTimeIntervals();

    this.callServicesOnUserLoggedIn();

    this.allowCollaboration();
    if (this.project) {
      this.getBusinessRole(this.project);
    }
    console.log(this.sharedService.cookieObject)
  }

  activatedRole: any;
  loggedInUser: any;
  userImage: any;
  userName: any;
  findTokenAndOtherRelatedCookies() {
    if (this.sessionService.getSession("token")) {
      this.userName = this.sharedService.getSession("userName");
      this.loggedInUser = this.sessionService.getSession("loggedInUser");
      ///this.loggedInUser = this.sharedService.getCookie("loggedInUser");
      this.userImage = this.sharedService.getSession("userImage");
      this.activatedRole = this.sharedService.getSession('activatedRole');
      if (this.sharedService.getSession("applicationId")) {
        this.applicationId = parseInt(this.sharedService.getSession("applicationId"));
      }
    }
  }

  checkApplicationShortNameAlreadyExistInCookies() {
    if (this.sharedService.getCookie('applicationShortName')) {
      this.applicationShortName = this.sharedService.getCookie('applicationShortName');
      this.sharedService.applicationShortName = this.applicationShortName;
    }
    else {
      this.applicationShortName = AppSettings.APPLICATION_SHORT_NAME;
      this.sharedService.setCookies("applicationShortName", this.applicationShortName);
    }
  }

  //------------Project Related Variables ---------------
  projectObject; // project Object From Cookie
  project; // project
  projectId;  // project Id
  projectTitle; // project Start Date\
  startDate; // project Start Date\
  status; // project Status
  owner; // project Owner
  projectImage; // project Image
  projectType;
  showAddVersion = true;
  updateProject() {
    this.projectObject = this.sharedService.getCookie("project");
    if (this.projectObject) {
      this.project = JSON.parse(this.projectObject);
      this.projectId = this.project['projectId'];
      this.getVersions();
      this.projectTitle = this.project["title"];
      this.sharedService.projectId = this.projectId;
      this.sharedService.projectTitle = this.projectTitle;
      this.projectImage = this.project["image"];
      this.projectType = this.project["type"];
      this.status = this.project["status"];
      this.owner = this.project["owner"];
      this.startDate = this.project["startDate"];
    }
    this.emitProject.emit(this.projectObject)
  }

  versions = [];
  selectedVersion: any;
  getVersions() {
    let object = {
      "versionId": 0,
      "title": "Current Version"
    }
    this.webService.getVersions(this.projectId).subscribe(data => {
      if (data && data["statusCode"] == AppSettings.API_SUCCESS_CODE) {
        this.versions = data['data'];
        this.versions.unshift(object);
        this.checkSelectedVersion();
      }
      else {
        this.versions.push(object);
        this.checkSelectedVersion();
      }
    }, error => {
      this.sharedService.checkHttpCodes(error);
    });
  }

  checkSelectedVersion() {
    console.log(this.sharedService.getCookie("currentVersion"));
    // console.log("checkSelectedVersion")
    if (this.sharedService.getCookie("currentVersion") == "0" || this.sharedService.getCookie("currentVersion") > 0) {
      this.selectedVersion = this.getSelectedVersion(this.sharedService.getCookie("currentVersion"));
    }
    else {
      this.sharedService.setCookies("currentVersion", 0);
      this.selectedVersion = this.getSelectedVersion(this.sharedService.getCookie("currentVersion"));
    }
    // console.log(this.selectedVersion)
    this.sharedService.setCurrentVersion(this.selectedVersion.versionId);
    this.sharedService.currentVersionTitle = this.selectedVersion.title;
    // console.log("VersionId :: ", this.selectedVersion);
    this.sendObject();
  }

  getSelectedVersion(id) {
    var obj = this.versions.filter(function (node) {
      return node.versionId == id;
    });
    return obj[0];
  }

  userRoles: string[];
  checkCurrentApplicationRolesExistInSession() {
    if (this.sharedService.getCookie(this.applicationShortName + "_roles")) {
      // console.log("ApplicationShortName Roles :: ", this.sharedService.getCookie(this.applicationShortName + "_roles"));
      this.userRoles = this.sharedService.getCookie(this.applicationShortName + '_roles');
      this.pageControlMap = this.sharedService.checkRole(this.pageControlMap, this.userRoles);
    }
  }

  currentUrl;
  getCurrentUrl() {
    this.currentUrl = window.location.href;
  }

  skinsAlreadyPresentFlag = false;
  userPreferences: any;
  userPreferencesObject: any;
  checkUserPreferenceInCookiesAndModifySkinsAndTimeIntervals() {
    this.userPreferencesObject = this.sharedService.getCookie("userPreference");
    if (this.userPreferencesObject) {
      this.userPreferences = JSON.parse(this.userPreferencesObject);
      if (this.userPreferences['notificationInterval'] != null) {
        this.sharedService.timeStamp = this.userPreferences['notificationInterval'];
      }
      if (this.userPreferences['chatInterval'] != null) {
        this.collaborationSharedService.timeStamp = this.userPreferences['chatInterval'];
      }
      else {
        this.sharedService.timeStamp = AppSettings.DEFAULT_NOTIFICATION_INTERVAL;
      }
      if (!this.skinsAlreadyPresentFlag) {
        setTimeout(() => {
          // console.log("changing Skins Now :: ");
          // this.skinService.makeSkins();
        }, 0)
      }
    }

    // console.log("chat_interval :: ", this.collaborationSharedService.timeStamp);
    // console.log("notification_interval :: ", this.sharedService.timeStamp);
  }

  changeInterval() {
    // console.log("changeInterval")
    clearInterval(this.timerSubscription); // stop the setInterval()
    this.setNotificatioonInterval(); // start the setInterval()
    // console.log("adsad", this.sharedService.timeStamp)
    this.setCollaborationInterval();
  }

  setCollaborationInterval() {
    // console.log("colaboration component")
    this.collaborationComponent.changeInterval();
  }

  getQuickLinks() {
    this.webService.getQuickLinks(this.loggedInUser).subscribe(data => {
      console.log(data);

      this.quickLinks = data;
    });

  }

  getSideMenu() {

    this.webService.getSideMenu(this.loggedInUser).subscribe(data => {

      if (Array.isArray(data)) {
        this.dataSource.data = data;
        this.sideMenuItems = data;
        console.log(this.dataSource.data);




      }

      });
    }

  callServicesOnUserLoggedIn() {

    if (this.loggedInUser) {
      // this.findUser();
      // this.getMenu();
      this.getQuickLinks();
      console.log("this.getQuickLinks()");

      this.getSideMenu();
      console.log("this.getSideMenu()");

      /// this.getMenu1();
      this.getNotifications();
      this.timerSubscription = setInterval(() => {
        // console.log("notification this.sharedService.timeStamp :: ", this.sharedService.timeStamp);
        this.getNotificationsAgain();
        // console.log("notification this.sharedService.timeStamp :: ", this.sharedService.timeStamp);
      }, this.getNotificationTimeInterval());
      this.userIdleFnc();
      this.getConstants(AppSettings.MENU_FORM_ID);
    }
  }

  getNotificationTimeInterval() {
    return this.sharedService.timeStamp;
  }

  setNotificatioonInterval() {
    this.timerSubscription = setInterval(() => {
      // console.log("notification this.sharedService.timeStamp :: ", this.sharedService.timeStamp);
      this.getNotificationsAgain();
      // console.log("notification this.sharedService.timeStamp :: ", this.sharedService.timeStamp);
    }, this.getNotificationTimeInterval());
  }





  menu: any;
  appRoleMap = new Map<string, MenuI[]>();
  applicationName: any;
  applicationUrl: any;
  timerSubscription: any;
  quickLinks: any = [];
  menuLinks: any = [];
  sideMenuItems: any = [];
  // Getting Menu
  // getMenu() {
  //   this.webService.getMenu(this.loggedInUser, 'menu', 0, this.projectId, this.activatedRole).subscribe(data => {
  //     this.menu = data;
  //     try {
  //       for (var i = 0; i < this.menu.length; i++) {
  //         this.applicationName = this.menu[0].application_name;
  //         this.applicationUrl = this.menu[0].application_url;
  //         for (var j = 0; j < this.menu[i].menu_item.length; j++) {
  //           if (this.menu[i].menu_item[j].SubCategory) {
  //             for (var k = 0; k < this.menu[i].menu_item[j].SubCategory.length; k++) {
  //               if (this.menu[i].menu_item[j].SubCategory[k].quick_link == "true") {
  //                 this.menu[i].menu_item[j].SubCategory[k]['application_id'] = this.menu[i].application_id;
  //                 this.menu[i].menu_item[j].SubCategory[k]['application_url'] = this.menu[i].application_url;
  //                 this.menu[i].menu_item[j].SubCategory[k]['categoryTitle'] = this.menu[i].menu_item[j].Categorytitle;
  //                 this.quickLinks.push(this.menu[i].menu_item[j].SubCategory[k]);
  //               }
  //             }
  //           }
  //           else {
  //             if (this.menu[i].menu_item[j].quick_link == "true") {
  //               this.menu[i].menu_item[j]['application_id'] = this.menu[i].application_id;
  //               this.menu[i].menu_item[j]['application_url'] = this.menu[i].application_url;
  //               this.menu[i].menu_item[j]['categoryTitle'] = this.menu[i].Categorytitle;
  //               this.quickLinks.push(this.menu[i].menu_item[j]);
  //             }
  //           }
  //         }
  //         // console.log("QuickLinks :: ", this.quickLinks);
  //       }
  //     } catch (error) {
  //       this.sharedService.checkHttpCodes(error);
  //     }
  //   }, error => {
  //     this.sharedService.checkHttpCodes(error);
  //   });
  // }


  treeObject = [];
  // Getting Menu
  getMenu1() {

    this.webService.getMenu(this.loggedInUser, 'menu', 1, this.projectId, this.activatedRole).subscribe(data => {
      this.menu = data;
      console.log('all menu : ', data);
      try {
        for (var i = 0; i < this.menu.length; i++) {
          var treeObject = {
            "name": "Report Engine",
            "children": []
          }
          this.applicationName = this.menu[0].application_name;
          this.applicationUrl = this.menu[0].application_url;
          treeObject['name'] = this.menu[i]['application_name'];
          treeObject['icon'] = this.menu[i]['app_icon'];
          for (var j = 0; j < this.menu[i].menu_item.length; j++) {
            if ((this.menu[i].menu_item[j]['Categorytitle'] == 'Project Scope' || this.menu[i].menu_item[j]['Categorytitle'] == 'Model Management') && (this.projectType.toLowerCase() != 'project' || this.currentVersion != 0)) {
              continue;;
            }
            var childrenObject = {
              "name": "Report Viewer",
            };

            childrenObject['name'] = this.menu[i].menu_item[j].Categorytitle;
            childrenObject['icon'] = this.menu[i].menu_item[j].CategoryIcon;
            if (this.menu[i].menu_item[j].SubCategory) {
              var childrenObjects = [];
              childrenObject['sort'] = 1;
              // this.treeObject['name'] = this.menu[i]['application_name'];
              for (var k = 0; k < this.menu[i].menu_item[j].SubCategory.length; k++) {
                var subChildrenObject = {
                  "name": "Report Viewer",
                };
                subChildrenObject['name'] = this.menu[i].menu_item[j].SubCategory[k]['subCategory']
                subChildrenObject['path'] = this.menu[i].menu_item[j].SubCategory[k]['url'];
                subChildrenObject['application_id'] = this.menu[i].application_id;
                subChildrenObject['application_url'] = this.menu[i].application_url;
                subChildrenObject['quick_link'] = this.menu[i].menu_item[j].SubCategory[k]['quick_link']
                subChildrenObject['quick_link_id'] = this.menu[i].menu_item[j].SubCategory[k]['quick_link_id']
                subChildrenObject['id'] = this.menu[i].menu_item[j].SubCategory[k]['id']
                subChildrenObject['icon'] = this.menu[i].menu_item[j].SubCategory[k]['icon']



                if (this.menu[i].menu_item[j].SubCategory[k].quick_link == "true") {
                  this.menu[i].menu_item[j].SubCategory[k]['application_id'] = this.menu[i].application_id;
                  this.menu[i].menu_item[j].SubCategory[k]['application_url'] = this.menu[i].application_url;
                  this.menu[i].menu_item[j].SubCategory[k]['categoryTitle'] = this.menu[i].menu_item[j].Categorytitle;
                  this.quickLinks.push(this.menu[i].menu_item[j].SubCategory[k]);
                }

                childrenObjects.push(subChildrenObject);
              }
              childrenObject['children'] = childrenObjects;
            }
            else {
              childrenObject['icon'] = this.menu[i].menu_item[j].icon;
              childrenObject['path'] = this.menu[i].menu_item[j].url;
              childrenObject['application_id'] = this.menu[i].application_id;
              childrenObject['application_url'] = this.menu[i].application_url;
              childrenObject['quick_link'] = this.menu[i].menu_item[j].quick_link;
              childrenObject['quick_link_id'] = this.menu[i].menu_item[j]['quick_link_id']
              childrenObject['id'] = this.menu[i].menu_item[j]['id']
              childrenObject['sort'] = parseInt(this.menu[i].menu_item[j]['sort']);
              if (this.menu[i].menu_item[j].quick_link == "true") {
                this.menu[i].menu_item[j]['application_id'] = this.menu[i].application_id;
                this.menu[i].menu_item[j]['application_url'] = this.menu[i].application_url;
                this.menu[i].menu_item[j]['categoryTitle'] = this.menu[i].Categorytitle;
                this.quickLinks.push(this.menu[i].menu_item[j]);
              }
            }

            console.log("this.quickLinks :: ",this.quickLinks)

            treeObject['children'].push(childrenObject);

          }
          this.treeObject.push(treeObject);
          this.treeObject[0].children.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))
          console.log("treeObject :: ", this.treeObject);
          this.dataSource.data = this.treeObject;
          // console.log("treeObject :: ", this.treeObject)
          this.emitQuickLinks.emit(this.quickLinks);
          // console.log("Q ", this.quickLinks);
        }
        // console.log(this.dataSource.data)
      } catch (error) {
        this.sharedService.checkHttpCodes(error);
      }
    }, error => {
      this.sharedService.checkHttpCodes(error);
    });
  }

  timeStamp = "";
  notifications = [];
  unreadNotifications = 0;
  notificationIds = [];
  //Getting All Notifications in menu Bar at once
  getNotifications() {
    this.webService.getNotifications(this.loggedInUser, this.timeStamp).subscribe(data => {
      if (data) {
        this.sharedService.Print("getNotification");
        this.sharedService.Print(data);
        try {
          this.notifications = data['data'];
          this.unreadNotifications = data['unreadNotifications'];
          if (this.unreadNotifications == null) {
            this.unreadNotifications = 0;
          }
          this.sharedService.setUnreadNotifications(this.unreadNotifications);
          this.timeStamp = data['timeStamp'];
          if (this.notifications) {
            for (var i = 0; i < this.notifications.length; i++) {
              this.notificationIds.push(this.notifications[i]['eventLogDetailId']);
            }
          }

          this.emitNotificationCount.emit(this.unreadNotifications);
        } catch (error) {
        }
      }
    }, error => {
      // this.sharedService.checkHttpCodes(error);
    });
  }

  initiateCollaboration = false;
  allowCollaboration() {
    this.initiateCollaboration = true;
  }

  businessRole;
  activatedRoleTitle: string = "";
  getBusinessRole(project) {
    this.webService.getProjectBusinessRoleList(this.sharedService.getCookie("loggedInUser"), -2, 0, project.projectId, "users").subscribe((data: Array<any>) => {
      if (data != null && data != undefined) {
        this.businessRole = data;
        if (this.businessRole.length > 0) {
          if (this.businessRole.length == 1) {
            this.activatedRole = this.businessRole[0].businessRolesId;
            // console.log("activatedRole in getBusiness ROle", this.activatedRole);
            this.sharedService.setCookies("activatedRole", this.activatedRole);
            this.activatedRoleTitle = this.businessRole[0].title;
          }
          else {
          }
        } else {
        }
      }
      else {
      }
      error => {
        this.toastr.showError("Retrieval", "Business Role List");
      }
    });
  }

  ngAfterViewInit2() {
    // console.log("ngAfterViewInit2");
    setTimeout(() => {
      // console.log("imageChange :: ");
      // let imageUrl = (<HTMLInputElement>document.getElementById("skinName")).value;
      // this.checkUserDefaultSkin();
      if (this.projectObject && this.projectType == "Project") {
        // this.loadHeaderContextMenu();
      }
      // this.setCookie("authenticationStatus", true);
      // this.sendNotification(this.getCookie("authenticationStatus"));
    }, AppSettings.ZERO);
  }

  checkProjectTypeForHeaderContextMenu() {
    if (this.projectObject && this.projectType == "Project") {
      setTimeout(() => {
        // this.loadHeaderContextMenu();
      }, AppSettings.INITIAL_DELAY)
    }
  }

  defaultSkin: any;
  userPreferenceId: any;
  checkUserDefaultSkin() {
    setTimeout(() => {
      // console.log("changing Default Skin")
      if (this.userPreferences && this.userPreferences['defaultSkin'] != null) {
        this.defaultSkin = this.userPreferences['defaultSkin'];
        let body = document.getElementsByTagName('body')[0];
        for (var i = 0; i < body.classList.length; i++) {
          if (body.classList[i].includes('skin-')) {
            body.classList.remove(body.classList[i]);
            body.classList.add(this.userPreferences['defaultSkin']);
          }
        }
      }
      if (this.userPreferences && this.userPreferences['userPreferenceId']) {
        this.userPreferenceId = this.userPreferences['userPreferenceId'];
      }
    }, AppSettings.INITIAL_DELAY);
  }


  showBrowseObjectButton = false;
  showDrawingToolButton = false;
  showRuleEngineButton = false;
  showMenuButtons(whichButtonToShow) {
    // console.log("whichButtonToShow");
    switch (whichButtonToShow) {
      case "tree":
        // console.log("called tree button");
        this.showBrowseObjectButton = true;
        setTimeout(() => {
          // this.manipulateMenuButtonClasses("menu-browse-object");
        }, AppSettings.BUTTONS_DELAY);
        this.showDrawingToolButton = false;
        this.showRuleEngineButton = false;
        break;
      case "ruleEngine":
        this.showRuleEngineButton = true;
        setTimeout(() => {
          this.manipulateMenuButtonClasses("menu-rule-engine");
        }, AppSettings.BUTTONS_DELAY);

        this.showDrawingToolButton = false;
        break;
      case "DrawingManager":
        this.showDrawingToolButton = true;
        setTimeout(() => {
          this.manipulateMenuButtonClasses("menu-drawing-tool");
        }, AppSettings.BUTTONS_DELAY);
        this.showRuleEngineButton = false;
        break;

      default:
        this.showBrowseObjectButton = false;
        this.showDrawingToolButton = false;
        setTimeout(() => {
          this.manipulateMenuButtonClasses("menu-applications");
        }, AppSettings.BUTTONS_DELAY);
        this.showRuleEngineButton = false;
        break;
    }
  }

  manipulateMenuButtonClasses(button) {
    var elementMenuBrowse = document.getElementById("menu-browse-object") as HTMLElement;
    var elementMenuApplication = document.getElementById("menu-application") as HTMLElement;
    var elementMenuDrawingTool = document.getElementById("menu-drawing-tool") as HTMLElement;
    var elementMenuRuleEngine = document.getElementById("menu-rule-engine") as HTMLElement;
    switch (button) {
      case "menu-browse-object":
        if (elementMenuBrowse) {
          elementMenuBrowse.classList.add("active");
        }
        if (elementMenuApplication) {
          elementMenuApplication.classList.remove("active");
        }
        if (elementMenuDrawingTool) {
          elementMenuDrawingTool.classList.remove("active");
        }
        if (elementMenuRuleEngine) {
          elementMenuRuleEngine.classList.remove("active");
        }
        break;

      case "menu-applications":
        if (elementMenuBrowse) {
          elementMenuBrowse.classList.remove("active");
        }
        if (elementMenuApplication) {
          elementMenuApplication.classList.add("active");
        }
        if (elementMenuDrawingTool) {
          elementMenuDrawingTool.classList.remove("active");
        }
        if (elementMenuRuleEngine) {
          elementMenuRuleEngine.classList.remove("active");
        }
        break;

      case "menu-drawing-tool":
        if (elementMenuBrowse) {
          elementMenuBrowse.classList.remove("active");
        }
        if (elementMenuApplication) {
          elementMenuApplication.classList.remove("active");
        }
        if (elementMenuDrawingTool) {
          elementMenuDrawingTool.classList.add("active");
        }
        if (elementMenuRuleEngine) {
          elementMenuRuleEngine.classList.remove("active");
        }
        break;

      case "menu-rule-engine":
        if (elementMenuBrowse) {
          elementMenuBrowse.classList.remove("active");
        }
        if (elementMenuApplication) {
          elementMenuApplication.classList.remove("active");
        }
        if (elementMenuDrawingTool) {
          elementMenuDrawingTool.classList.remove("active");
        }
        if (elementMenuRuleEngine) {
          elementMenuRuleEngine.classList.add("active");
        }
        break;
    }
  }

  sessionCounter = 0;
  userIdleFnc() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => {
    //  console.log("timer :: ", count)
      if (!(this.sessionCounter > 0)) {
        console.log("sessionCounter :: ", this.sessionCounter);
        this.openSessionDialog();
      }
    });
    this.userIdle.onTimeout().subscribe(() => this.stopWatchingIdle());
  }

  dialogRef;
  openSessionDialog() {
    this.sessionCounter += 1;
    this.confirmation.confirm({
                                message: 'Do You Want To Extend This Session?',
                                header: 'Extend Session',
                                accept: () => {
                                  this.userIdle.resetTimer();
                                  this.sessionCounter = 0;
                                  this.refreshToken(this.applicationId);
                                },
                                reject: (type) => {
                                  this.checkUrl(1, this.Application_Short_Name, '/logout');
                                  this.sessionCounter = 0;
                                }
                              });

    // this.sessionCounter += 1;
    // this.dialogRef = this.dialog.open(DeleteAlertDialog, {
    //   data: {
    //     title: "Extend Session",
    //     message: "Do You Want To Extend This Session"
    //   }
    // });
    // this.dialogRef = this.dialog.open(SessionDialog, {
    //   width: '450px',
    // });

    // this.dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.userIdle.resetTimer();
    //     this.sessionCounter = 0;
    //     this.refreshToken(this.applicationId);
    //   }
    //   else {
    //     this.checkUrl(1, this.Application_Short_Name, '/logout');
    //     this.sessionCounter = 0;
    //   }
    // });
  }

  // openReportDialogue(reportModel: any, reportComingFrom?: String) {
  //   // console.log("openReportDialogue Report Type : " + reportComingFrom);

  //   this._previewService.setSessions('reportType', reportComingFrom);

  //   if (reportComingFrom.localeCompare('Report List') == 0 || reportComingFrom.localeCompare('Generate Report') == 0) {
  //     //   // console.log("In Report List condition!");
  //     //   // this._previewService.setSessions('reportType', 'View Report');
  //     //   // this._previewService.pageRefresh = true;
  //     //   // this._previewService.setComingFromPage("Report List");
  //     this._previewService.reportParamJson = reportModel;
  //   }
  //   // // else if(reportComingFrom.localeCompare('Generate Report')==0)
  //   // // {
  //   // // // this._previewService.setComingFromPage("Generate Report");

  //   // // // this._previewService.setCookies("reportType", "Generate Report");
  //   // // // this._previewService.setLastPageUrl("Generate Report");

  //   // // // this._previewService.pageRefresh = true;
  //   // // this._previewService.reportParamJson = reportModel;
  //   // // }

  //   this.dialogRef = this.dialog.open(ReportPreviewComponent, {

  //     // width: '"' + window.innerWidth + '"',
  //     // width: '650px',
  //   });
  //   // this.dialogRef.afterClosed().subscribe(result => {
  //   // if (result) {
  //   // this.userIdle.resetTimer();
  //   // this.sessionCounter = 0;
  //   // this.refreshToken(this.applicationId, null, null);
  //   // }
  //   // else {
  //   // this.checkUrl(1, 'ums', '/logout');
  //   // this.sessionCounter = 0;
  //   // }
  //   // });
  // }

  // openSessionDialogue(reportModel: any, reportComingFrom?: String) {
  //   // alert(h);
  //   // this.dialogRef = this.dialog.open(SessionDialog, {
  //   // width: '450px',
  //   // });
  //   if (reportComingFrom.localeCompare('Report List') == 0) {
  //     this._previewService.setCookies('reportType', 'View Report');
  //     this._previewService.pageRefresh = true;
  //     this._previewService.setComingFromPage("Report List");
  //     this._previewService.reportParamJson = reportModel;
  //   }
  //   else if (reportComingFrom.localeCompare('Generate Report') == 0) {
  //     this._previewService.setComingFromPage("Generate Report");

  //     this._previewService.setCookies("reportType", "Generate Report");
  //     this._previewService.setLastPageUrl("Generate Report");

  //     this._previewService.pageRefresh = true;
  //     this._previewService.reportParamJson = reportModel;
  //   }

  //   this.dialogRef = this.dialog.open(ReportPreviewComponent, {

  //     width: '"' + window.innerWidth + '"',
  //   });
  //   // this.dialogRef.afterClosed().subscribe(result => {
  //   // if (result) {
  //   // this.userIdle.resetTimer();
  //   // this.sessionCounter = 0;
  //   // this.refreshToken(this.applicationId, null, null);
  //   // }
  //   // else {
  //   // this.checkUrl(1, 'ums', '/logout');
  //   // this.sessionCounter = 0;
  //   // }
  //   // });
  // }

  stopWatchingIdle() {
    this.closeSessionDialog();
    // this.userIdle.stopTimer();
    this.sessionCounter = 0;
    this.checkUrl(AppSettings.UMS_APPLICATION_ID, this.Application_Short_Name, 'logout');
    clearInterval(this.timerSubscription);
  }

  closeSessionDialog() {
    this.dialogRef.close();
  }

  openConnectionErrorDialog() {
    // $('#connection_error_dialog').modal('show');
    let title = "No Internet Connection";
    let message = "Connection Error ! Do You Want To Retry";
    this.openConfirmationDialog(title, message);
  }

  openConfirmationDialog(title, message) {
    // const dialogRef = this.dialog.open(DeleteAlertDialog, {
    //   data: {
    //     title: title,
    //     message: message
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.refreshToken(this.applicationId);
    //     // this.refresh();
    //   }
    //   else {
    //     this.logout();
    //   }
    // });
  }

  constantlist = [];
  getConstants(formId) {
    this.webService.getFormConstants(formId).subscribe((data: string) => {
      this.constantlist = data['data'] as string[];
    });
  }

  notificationsInService = [];
  // HeartBeat Service to Get New Notifications
  getNotificationsAgain() {
    if (this.sharedService.getCookie('token')) {
      this.webService.getNotifications(this.loggedInUser, this.timeStamp).subscribe(data => {
        if (data) {
          try {
            this.notificationsInService = data['data'];
            this.timeStamp = data['timeStamp'];
            if (this.notificationsInService) {
              for (var i = 0; i < this.notificationsInService.length; i++) {
                this.notifications = this.notifications || [];
                this.notifications.unshift(this.notificationsInService[i]);
                if (this.unreadNotifications == null) {
                  this.unreadNotifications = 1;
                }
                else {
                  this.unreadNotifications += 1
                }
                this.sharedService.setUnreadNotifications(this.unreadNotifications);
                this.toastr.showCustomInfoAlert("Hey! You've Got A New Notification");
              }
            }
          } catch (error) {
            // this.webService.exceptionHandling(error, "getNotificationsAgainMethodError").subscribe(data => {
            // });
          }
        }
      }, error => {
        if (error == "Error : Connection Error") {
          this.openConnectionErrorDialog();
        }
        this.sharedService.checkHttpCodes(error);
        clearInterval(this.timerSubscription);
      });
    }
  }

  navigateToPage(application, category_url, menuItem = null) {

    if (menuItem.children != null) {

      const index = this.expandedSideMenu.indexOf(menuItem.applicationroleid);

      if (index > -1) {

        this.expandedSideMenu.splice(index, 1);
      }
      else {
        this.expandedSideMenu.push(menuItem.applicationroleid);
      }




    }
    else {
      this.sessionService.setSession("currentUrl", category_url);
      this.currentMenuUrl = category_url;
      this.router.navigate([category_url]);
    }



  }

  navigateApplication(application, category_url) {
    // console.log(application, category_url)
    if ((this.currentUrl.includes(application.application_url))) {
      this.router.navigate([category_url]);
    }
    else {
      this.startLoader();
      this.sharedService.setCookies("applicationId", application.application_id);
      // if (application.short_name == undefined || application.short_name == null) {
      //   applicationShortName = this.getSecondPart(application.application_url);
      // }
      // else {
      //   applicationShortName = application.short_name;
      // }
      // this.updateCurrentRole(application.application_url, category_url, applicationShortName);
      this.navigationService.updateCurrentRole(application.application_url, category_url, this.applicationShortName);

      let cookieObject = {};
      cookieObject["applicationId"] = this.applicationId;
      cookieObject["applicationName"] = this.applicationShortName;
      this.cookieService.set("routedFrom", JSON.stringify(cookieObject), null, "/");
    }
  }

  navigateApplication2(application_url, category_url) {
    var applicationShortName;
    if ((this.currentUrl.includes(application_url))) {
      this.router.navigate([category_url]);
    }
    else {
      this.startLoader();
      this.sharedService.setCookies("applicationId", 7);
      applicationShortName = this.getSecondPart(application_url);
      // this.updateCurrentRole(application.application_url, category_url, applicationShortName);
      this.navigationService.updateCurrentRole(application_url, category_url, applicationShortName);
    }
  }

  // getTokenAndNavigate(applicationUrl) {
  //   var sessions = this.createSessionObject(this.sharedService.getCookie("applicationId"), this.sharedService.getCookie("token"));
  //   if (this.checkDomainIsSameOrNot(applicationUrl)) {
  //     this.cookieService.set("applicationId", this.sharedService.getCookie("applicationId"), null, '/');
  //     this.cookieService.set("token", this.sharedService.getCookie("token"), null, '/');
  //     window.location.assign(applicationUrl)
  //   }
  //   else {
  //     this.webService.post(sessions, "http://localhost:4203/tree/importWizard/importType");
  //     // this.webService.post(sessions, applicationUrl);
  //   }
  // }


  showTreeButton() {
    var element = document.getElementById('showTreeButton') as HTMLElement;
    element.click();
  }

  hideTreeButton() {
    var element = document.getElementById('hideTreeButton') as HTMLElement;
    element.click();
  }

  enableTreeButton() {
    this.showMenuButtons("tree");
  }

  disableTreeButton() {
    this.showMenuButtons("");
  }

  loaderText = "";
  startLoader(message?: string) {
    // console.log("Loader");
    this.loaderText = message;
    this.ngxSpinnerService.startLoader("mainLoader");
  }

  stopLoader() {
    // this.ngxSpinnerService.stop();
    this.ngxSpinnerService.stopLoader("mainLoader");
  }

  createSessionObject(applicationId, token) {
    var sessionObject = {
      "applicationId": applicationId,
      "token": token
    }
    return sessionObject;
  }

  checkDomainIsSameOrNot(applicationUrl) {
    var origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    var applicationUrl = applicationUrl;
    if (applicationUrl.includes(origin)) {
      return true;
    }
    return false;
  }

  getSecondPart(str) {
    return str.split('/').pop();
  }


  addQuickLink(node) {

    if (this.quickLinks.length < AppSettings.MAX_QUICK_LINKS) {

      if (node.quick_link == 'true') {

        this.toastr.showCustomInfoAlert(node.title + " Already Added");
      }
      else {


        this.webService.addQuickLink(parseInt(this.loggedInUser), node.applicationroleid).subscribe(data => {
          try {
            var res = data;
            if (data['quick_link_id']) {
              // appRoleId['application_url'] = application.application_url;
              node.quick_link_id = res['quick_link_id'];
              node.icon = res['icon'];
              node.quick_link = res['quick_link'];
              node.url = res['url'];
              this.quickLinks.push(node);
              this.toastr.showSuccess('Add', 'Quick Link');
              this.emitQuickLinks.emit(this.quickLinks);
            }
          } catch (error) {
          }
        }, error => {
          this.sharedService.checkHttpCodes(error);
          this.toastr.showError("Add", "Quick Link");
        })
      }
    }
    else {
      this.toastr.showCustomInfoAlert("Total Number of QuickLinks Reached")
    }
  }

  deleteQuickLink(quickLinkId) {
    // console.log(quickLinkId);
    this.webService.deleteQuickLink(quickLinkId['quick_link_id']).subscribe(data => {
      if (data) {
        quickLinkId['quick_link'] = "false";
        const index = this.quickLinks.findIndex(item => item.quick_link_id === quickLinkId.quick_link_id);
        if (index > -1) {
          this.quickLinks.splice(index, 1);
        }
        this.emitQuickLinks.emit(this.quickLinks);
        // this.quickLinks.splice(index, 1);
        this.toastr.showSuccess('Delete', 'Quick Link');
      }
    }, error => {
      this.sharedService.checkHttpCodes(error);
    });
  }

  onVersionChange(event) {
    console.log(event);
    this.selectedVersion = event;
    console.log(this.selectedVersion.versionId);
    this.sharedService.setCookies("currentVersion", this.selectedVersion.versionId);
    this.sharedService.currentVersionTitle = this.selectedVersion.title;
    // console.log(this.selectedVersion.versionId);
    this.sendObject();
    this.sharedService.setCurrentVersion(this.selectedVersion.versionId);
    this.addUpdateUserPreferences(null, this.sharedService.getCookie("currentVersion").toString(), this.imageUrl);
  }

  refreshToken(applicationId) {
    if (applicationId == null) {
      applicationId = 1;
    }
    var activatedRole = this.sharedService.getCookie('activatedRole');
    var projectId = this.projectId;
    if (!activatedRole) {
      this.activatedRole = AppSettings.ZERO;
    }
    if (!projectId) {
      this.projectId = AppSettings.ZERO;
    }

    this.nonInterceptService.updateCurrentRole(activatedRole, projectId, applicationId).pipe(first()).subscribe(data => {
      if (data) {
        this.sharedService.setCookies('token', data['token']);
        this.sharedService.setCookies("expirationDate", data['expirationDate']);
      }
      else {
        this.checkUrl(AppSettings.UMS_APPLICATION_ID, this.Application_Short_Name, 'logout');
      }
    }, error => {
      this.sharedService.checkHttpCodes(error);
      this.checkUrl(AppSettings.UMS_APPLICATION_ID, this.Application_Short_Name, 'logout');
    })
  }

  refresh() {
    sessionStorage.setItem("umsUrl", this.webService.getInitialUrl());
    window.location.assign(this.currentUrl);
  }

  addVersionTitle = "";
  versionType = "minor"
  // Opening Copy Project Pop-Up
  addVersionDialogue() {
    document.getElementById("addVersionButton").click();
  }

  changeVersion(version) {
    this.versionType = version;
  }

  checkVersionType() {
    if (this.versionType === "minor") {
      return true;
    }
    else {
      return false;
    }
  }

  addVersionDialogueCancel() {
    this.addVersionTitle = "";
    this.versionType = "minor"
  }

  addVersion(versionForm) {
    this.webService.addVersion(this.addVersionTitle, this.versionType).subscribe(data => {
      if (data) {
        if (data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
          this.versionType = "minor"
          this.toastr.showSuccess('add', 'Version');
          this.getVersions();
          versionForm.resetForm();
        }
      }
    });
  }

  copyProjectTitle = "";
  // Opening Copy Project Pop-Up
  copyProjectDialogue() {
    document.getElementById("openModalButton").click();
  }

  // Closing Copy Project Pop-Up
  projectCopyCancel() {
    this.copyProjectTitle = "";
  }

  copyProject() {
    this.startLoader("Copying...");
    this.webService.copyProject(this.copyProjectTitle).subscribe(data => {
      if (data) {
        if (data['statusCode'] == 200) {
          this.toastr.showSuccess("Copy", "Project");
          this.copyProjectTitle = "";
          this.stopLoader();
        }
        else {
          this.toastr.showCustomInfoAlert("Project is not copied.");
          this.copyProjectTitle = "";
          this.stopLoader();
        }
      }
      else {
        this.toastr.showSuccess("Copy", "Project");
        this.copyProjectTitle = "";
        this.stopLoader();
      }
    }, error => {
      this.copyProjectTitle = "";
      this.stopLoader();
      this.sharedService.checkHttpCodes(error);
    });
  }


  //Check Status Of Notification
  checkStatus(notification) {
    if (notification.status == "initiated" || notification.status == "received") {
      return "font-bold";
    }
    else {
      return "";
    }
  }

  notificationTitle: any = "";
  template;
  insertionTime
  // When Any Unread Notification is clicked
  updateNotificationStatustoRead(notification, index) {
    this.notificationTitle = notification['ntfyEventLog']['ntfyEvent'];
    this.template = notification['template'];
    this.insertionTime = notification['insertionTimestamp'];
    if (notification['status'] == 'received') {
      this.webService.updateNotificationStatustoRead(notification['eventLogDetailId'], this.loggedInUser).subscribe(data => {
        var response = data;
        this.sharedService.Print(response);
        try {
          this.removeElementFromArray(index).then(data1 => {
            if (response['statusCode'] == 200) {
              this.getNextNotification();
              if (this.unreadNotifications > 1) {
                this.unreadNotifications--;
              }
              else {
                this.unreadNotifications = null;
              }
            }
          });
        } catch (error) {
          // this.webService.exceptionHandling(error, "removeElementFromArray in updateNotificationStatus").subscribe(data => {
          // });
        }
      }, error => {
        this.sharedService.checkHttpCodes(error);
      });
    }
    else { }
  }

  getNextNotification() {
    var res;
    this.webService.getNextNotification(this.notificationIds, this.loggedInUser).subscribe(data => {
      if (data) {
        res = data['data'];
        if (res) {
          this.notifications.push(res[0]);
          this.notificationIds.push(res[0]['eventLogDetailId']);
        }
      }
    }, error => {
      this.sharedService.checkHttpCodes(error);
    });
  }

  addUpdateUserPreferences(languageId, version, skin) {
    this.webService.addUpdateUserPreferences(languageId, skin, version, this.userPreferenceId).subscribe((data: string) => {
      if (data != undefined && data != null) {
        if (data['message'] = "Success") {
          let up = data['data'][0] as UserPreferences;
          let userPreferenceCookie = {} as UserPreferences;
          userPreferenceCookie.chatInterval = up['chatInterval'];
          userPreferenceCookie.defaultLanguage = up['defaultLanguage'] != undefined ? up['defaultLanguage']['languageId'] : 0;
          if (up['defaultProject']) {
            userPreferenceCookie.defaultProject = up['defaultProject']['projectId'];
          }
          if (up['defaultProjectRole']) {
            userPreferenceCookie.defaultProjectRole = up['defaultProjectRole']['businessRolesId'];
            this.sharedService.setCookies("activatedRole", up['defaultProjectRole']['businessRolesId']);
          }
          userPreferenceCookie.defaultSkin = up['defaultSkin'];
          userPreferenceCookie.idleTimeout = up['idleTimeout'];
          userPreferenceCookie.notificationInterval = up['notificationInterval'];
          userPreferenceCookie.userPreferenceId = up['userPreferenceId'];
          this.sharedService.setCookies("userPreference", JSON.stringify(userPreferenceCookie));
          this.toastr.showSuccess("Add", "User Preference")
          if (languageId != null) {
            sessionStorage.setItem("umsUrl", this.webService.getInitialUrl());
            window.location.assign(this.currentUrl);
          }
        } else {
          this.toastr.showError("Add", "User Preference")
        }
      } else {
        this.toastr.showError("Add", "User Preference")
      }
    });
  }

  removeElementFromArray(index) {
    try {
      this.notifications.splice(index, 1);
      this.notificationIds.splice(index, 1);
      return Promise.resolve((() => {
        return this.notificationIds;
      })
        ());
    } catch (error) {
      // this.webService.exceptionHandling(error, "removeElementFromArray in MenuComponent").subscribe(data => {
      // });
    }
  }

  // clickProjectUpdate() calls This Function To Setup Project Details In Menu
  updateMenu() {
    this.quickLinks = [];
    this.skinsAlreadyPresentFlag = true;
    this.ngOnInit();
    // this.sideBarComponent.updateContextualChat();
  }

  // Function Called From ums When Project Is Changed
  clickProjectUpdate() {
    var element = document.getElementById('updateMenubutton') as HTMLElement;
    element.click();
  }

  subscribeNewEvent() {
    this.router.navigate(['newsubscription']);
  }

  viewAllNotification() {
    this.notifications = [];
    this.unreadNotifications = 0;
    // this.router.navigate(['/allnotifications']);
    this.markAsRead(null, 0);

  }

  markAsRead(notificationId, index) {
    var notification_id = null;
    if (notificationId == null) {
    } else {
      notification_id = notificationId.eventLogDetailId;
    }
    if (notification_id && notificationId.status == 'read') {
    } else {
      this.ngxSpinnerService.startLoader('ntfyLoader');
      this.chatService.marknotificationAsRead(notification_id, this.loggedInUser.toString()).subscribe(data => {
        if (data) {
          try {
            var deletentfy = data['data'];
            if (data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
              this.unreadNotifications = 0;
              this.chatService.setUnreadNotifications(0);
              // this.toastr.showSuccess('Mark as read', 'Notifications');
              if (notificationId == null) {
                for (var i = 0; i < this.notifications.length; i++) {
                  this.notifications[i]['status'] = 'read';
                }

                // this.dataSource = new MatTableDataSource<Element>(this.notifications);
              } else {
                if (index != -1) {
                  this.notifications[index]['status'] = 'read';
                  var notificationCount: any;
                  this.chatService.getUnreadNotifications.subscribe(data => {
                    notificationCount = data;
                  });
                  if (notificationCount > 0) {
                    this.chatService.setUnreadNotifications(notificationCount - 1);
                  }
                  // this.dataSource = new MatTableDataSource<Element>(this.notifications);
                }
              }

              this.ngxSpinnerService.stopLoader('ntfyLoader');
            } else {
              // this.toastr.showError('Mark as read', 'Notification');
              this.ngxSpinnerService.stopLoader('ntfyLoader');
            }
          } catch (error) {
            // this.chatService.exceptionHandling(error, "allNotifications").subscribe(data => { this.errorMessage = data });
            this.ngxSpinnerService.stopLoader('ntfyLoader');
          }
        }
      }, error => {
        this.ngxSpinnerService.stopLoader('ntfyLoader');
        // this.sharedService.checkHttpCodes(error)
      });
    }
  }

  openSwitchRolePopUp() {
    document.getElementById("openSwitchRoleButton").click();
  }

  checkUrl(applicationId, appName, route) {
    var url = window.location.href;
    if (url.includes(appName)) {
      console.log("route in app name check :: ",route);
      this.router.navigate([route]);
    } else {
      if (this.sharedService.getApplicationFrontEndUrl(applicationId)) {
        console.log("route in get application :: ",this.sharedService.getApplicationFrontEndUrl(applicationId) + route);
        window.location.assign(this.sharedService.getApplicationFrontEndUrl(applicationId) + route);
      }
      else {
        console.log("route in else get application :: ",'https://dev.hive-worx.com/' + this.Application_Short_Name + route);
        // window.location.assign('https://dev.hive-worx.com/' + this.Application_Short_Name + route);
        window.location.assign('https://dev.hive-worx.com/' + route);
      }
    }
  }

  mainPage() {
    if (this.currentUrl.includes(AppSettings.APPLICATION_SHORT_NAME)) {
      this.router.navigate(['/home/maindashboard']);
    }
    else {
      this.sharedService.setCookies('applicationId', 1);
      var applicationUrl = this.getApplicationFrontEndUrl(AppSettings.UMS_APPLICATION_ID) + '/home/maindashboard';
      var sessions = this.createSessionObject(this.sharedService.getCookie("applicationId"), this.sharedService.getCookie("token"));
      this.updateRole(applicationUrl, AppSettings.UMS_APPLICATION_ID, sessions);
      // this.navigationService.updateCurrentRole()
    }
  }

  updateRole(applicationUrl, applicationId, sessions) {
    var activatedRole = parseInt(this.getCookie('activatedRole'));
    if (!activatedRole) {
      activatedRole = 0;
    }
    this.nonInterceptService.updateCurrentRole(activatedRole, 0, applicationId).subscribe(data => {
      if (data) {
        this.setCookie('token', data['token']);
        this.setCookie('applicationId', applicationId);
        this.setCookie("expirationDate", data['expirationDate']);
        if (applicationUrl != null) {
          this.getTokenAndNavigate1(applicationUrl, sessions);
        }
      }
      else { }
    });
  }

  getTokenAndNavigate1(applicationUrl, sessions) {
    sessions['applicationId'] = this.getCookie("applicationId");
    sessions['token'] = this.getCookie("token");
    if (this.checkDomainIsSameOrNot(applicationUrl)) {
      this.cookieService.set("applicationId", this.getCookie("applicationId"), null, '/');
      this.cookieService.set("token", this.getCookie("token"), null, '/');
      window.location.assign(applicationUrl);
    }
    else {
      this.webService.post(sessions, applicationUrl);
    }
  }

  openReport(reportType) {
    // Setting Report Type in Session && SelectedObjectId
    // is already Stored In Session Required For Reports To Render
    // this.sharedService.setSession("reportType", name);
    // this.openReportDialogue('', reportType);

  }

  logout() {
    $('#session_dialog').modal('hide');
    this.checkUrl(AppSettings.UMS_APPLICATION_ID, this.Application_Short_Name, 'logout');
    clearInterval(this.timerSubscription);
  }

  verifyLabel(label) {
    return this.sharedService.verifyLabel(label, this.constantlist , AppSettings.MENU_FORM_ID);
  }

  // Function called when ever a skin is changed.
  skinChange = "skin-grey";
  counterToCheckSkin = 0;
  imageUrl = "";

  getCookie(name) {
    return this.sharedService.getCookie(name);
  }

  setCookie(name, value) {
    this.sharedService.setCookies(name, value);
  }

  getApplicationBackEndUrl(applicationId) {
    return this.sharedService.getApplicationBackEndUrl(applicationId);
  }

  getApplicationFrontEndUrl(applicationId) {
    return this.sharedService.getApplicationFrontEndUrl(applicationId);
  }

  openDialogReViewreportParameters(reportId, jsonParameters) {
    var obj = {};
    obj['reportId'] = reportId;
    obj["parameters"] = JSON.stringify(jsonParameters);
    this.openPreviewDialog(obj, null);
  }



  openPreviewDialog(obj, index) {
    // const dialogRef = this.dialog.open(ReportPreviewComponent, {
    //   data: obj,
    //   width: '800px ',
    //   height: '800px',
    // });
    // dialogRef.afterClosed().subscribe((data: any) => {
    // });
  }


    goToLandingPage() {
    if(this.sessionService.getSession('loggedInUserType') === Constant.EXPERT_BUSINESS_ROLE){
      this.sessionService.removeItem(Constant.SESSION_EXPERT_ID);
      this.sessionService.removeItem(Constant.SESSION_PATIENT_ID);
      this.sessionService.removeItem(Constant.SESSION_APPOINTMENT_ID);

    }

    this.router.navigate(['cpa/landingpage']);


    }

     toggleMenu() {
        this.toggleM = !this.toggleM;
    }

}
