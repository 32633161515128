<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameExpenses)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button *ngIf="pageControlMap.get('viewExpenses')"
                                        class="p-button-rounded p-button-text" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton pTooltip="Advanced Search"
                                    tooltipPosition="bottom" type="button" id="searchOverlayBtn" ></button>
                                <button *ngIf="pageControlMap.get('addExpenses')"
                                        class="p-button-rounded p-button-text" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton pTooltip="{{addEditTitle}} {{translate(labels.entityNameExpenses)}}"  tooltipPosition="bottom"
                                    type="button" id="addOverlayBtn"></button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameExpenses)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="expensesSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
<!--                                        <div class="p-field p-col-12 p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.expensesColumnAppointmentId)}}</label>-->
<!--                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"-->
<!--                                                formControlName="appointmentId" mode="decimal" name="appointmentId"-->
<!--                                                pNumberInput type="number"></p-inputNumber>-->
<!--                                        </div>-->
<!--                                        <div class="p-field p-col-12  p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.expensesColumnClinicId)}} </label>-->
<!--                                            <p-dropdown [filter]="true" [options]="clinicsList" [showClear]="true"-->
<!--                                                class="pngDropdown" formControlName="clinicId"-->
<!--                                                placeholder={{translate(labels.expensesColumnClinicId)}}></p-dropdown>-->
<!--                                        </div>-->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnPaidOn)}}</label>
                                            <p-calendar appendTo="body" [monthNavigator]="true" [showIcon]="true"
                                                class="pngCalendar " dataType="string"
                                                dateFormat="yy-mm-dd" formControlName="paidOn" showButtonBar="true"></p-calendar>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnCompany)}}</label>
                                            <input class="pngInputtext"
                                                   [value]="companyName"
                                                   pInputText
                                                   [readOnly]="true"
                                                   type="text">
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnSource)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('source')"
                                                    *ngIf="diplayeIconInField('source')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="source" name="source" pInputText type="text">
                                            </span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnExpenseType)}}</label>
                                            <p-dropdown [filter]="true" [options]="expense_type_array"
                                                        [showClear]="true" class="pngDropdown" formControlName="expenseType"
                                                        placeholder="Select">
                                            </p-dropdown>
                                        </div>

<!--                                        <div class="p-field p-col-12 p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.expensesColumnExpenseType)}}</label>-->

<!--                                            <p-dropdown [filter]="true" [options]="expense_type_array"-->
<!--                                                        [showClear]="true" class="pngDropdown" formControlName="expenseType"-->
<!--                                                        placeholder="Select">-->
<!--                                            </p-dropdown>-->
<!--                                            <app-custom-error [control]="'expenseType'" [form]="expensesForm">-->
<!--                                            </app-custom-error>-->
<!--                                        </div>-->
<!--                                        <div class="p-field p-col-12 p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.expensesColumnUserId)}}</label>-->
<!--                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"-->
<!--                                                formControlName="userId" mode="decimal" name="userId" pNumberInput-->
<!--                                                type="number"></p-inputNumber>-->
<!--                                        </div>-->
                                        <!-- <div class="p-field p-col-12 pngbtnsdiv" id="invitationpage-searchmemeber-pngbtnsdiv"> -->
                                        <!-- <button pButton type="submit" id="invitationpage-searchmemeber-searchbtn" -->
                                        <!-- [disabled]="onValueChange" class="pngbtn btnprimaryblue" label={{translate(labels.btnSearch)}} ></button> -->
                                        <!-- <button pButton type="reset" id="invitationpage-searchmemeber-clearbtn" -->
                                        <!-- [disabled]="!this.expensesSearch.touched" class="pngbtn btnprimaryblue-outline" -->
                                        <!-- label={{translate(labels.btnClear)}}></button> -->
                                        <!-- <button pButton type="button" (click)="onCloseSearch()" -->
                                        <!-- id="invitationpage-searchmemeber-cancelbtn" -->
                                        <!-- class="pngbtn btnprimaryblue-outline" label={{translate(labels.btnCancel)}}></button> -->
                                        <!-- </div> -->
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button" class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"  styleClass="p-button" class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameExpenses)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="expensesForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnPaidOn)}}</label>
                                            <p-calendar appendTo="body" [class.p-invalid]="inputFieldDanger('paidOn')"
                                                [monthNavigator]="true" [showIcon]="true"
                                                class="p-invalid pngCalendar " dataType="string"
                                                dateFormat="yy-mm-dd" formControlName="paidOn" showButtonBar="true"></p-calendar>
                                            <app-custom-error [control]="'paidOn'" [form]="expensesForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnCompany)}}</label>
                                            <input class="pngInputtext"
                                                   [value]="companyName"
                                                   pInputText
                                                   [readOnly]="true"
                                                   type="text">
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnSource)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('source')"
                                                    *ngIf="diplayeIconInField('source')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('source')" class="pngInputtext"
                                                    formControlName="source" name="source" pInputText pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'source'" [form]="expensesForm">
                                            </app-custom-error>
                                        </div>

                                        <!-- added fields -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expensesColumnExpenseType)}}</label>

                                            <p-dropdown [filter]="true" [options]="expense_type_array"
                                                [showClear]="true" class="pngDropdown" formControlName="expenseType"
                                                placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'expenseType'" [form]="expensesForm">
                                            </app-custom-error>
                                        </div>
                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button" class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span   *ngIf="pageControlMap.get('viewExpenses')"
                                            class="p-input-icon-right globalSearch"
                                            id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)= "onGlobalSearchClick(global.value)"></i>
                                        <input #global
                                            class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" /></span>
                                    
                                </p-header>
                                <p-table    #dv *ngIf="totalRecords > 0"
                                            (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                            [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                            [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                            [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [showCurrentPageReport]=true
                                            [totalRecords]="totalRecords" [value]="expensesList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': expensesList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <!-- <th> AppointmentId</th>
                                            <th> ClinicId</th> -->
                                            <th> Paid On</th>
                                            <!-- <th> Company</th> -->
                                            <th> Source</th>
                                            <th> Expense Type </th>
                                            <th> Expense Amount </th>
                                            <!-- <th> UserId</th> -->
                                            <th *ngIf="pageControlMap.get('addExpenses') || pageControlMap.get('editExpenses') || pageControlMap.get('deleteExpenses') ">
                                                {{translate(labels.headerAction)}}
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <!-- <td> {{element?.appointmentId}} </td>
                                            <td> {{element?.clinicId.clinicId}} </td> -->
                                            <td> {{element?.paidOn | date: 'yyyy-MM-dd' }} </td>
                                            <!-- <td> {{element?.company}} </td> -->
                                            <td> {{element?.source}} </td>
                                            <td> {{element?.expenseType?.label}}</td>
                                            <td> {{element?.expenseAmount}}</td>
                                            <!-- <td> {{element?.userId}} </td> -->
                                            <td>
                                                
                                                <div class="pngTable-icons">
                                                    <p-button   *ngIf="pageControlMap.get('editExpenses')"
                                                                (click)="loadExpensesToEdit(element.expensesId, i)"
                                                                icon="pi pi-pencil" pTooltip="Edit"
                                                                styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <p-button   *ngIf="pageControlMap.get('deleteExpenses')"
                                                                (click)="deleteByIdExpenses(element.expensesId,i)"
                                                                icon="pi pi-trash" pTooltip="Delete"
                                                                styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                    <p-button   *ngIf="pageControlMap.get('addExpenses')"
                                                                icon="pi pi-plus" pTooltip="Add Detail"
                                                                (click)="addExpenseDetail($event,element)"
                                                                styleClass="p-button pbtn-transparent">
                                                    </p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
