import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AgencyComponent} from './agency/agency.component';
import {AgencyClinicComponent} from './agency-clinic/agency-clinic.component';
import {AgencyCompanyComponent} from './agency-company/agency-company.component';
import {AgencyContactsComponent} from './agency-contacts/agency-contacts.component';
import {AgencyExpertComponent} from './agency-expert/agency-expert.component';
import {AgencyPatientsComponent} from './agency-patients/agency-patients.component';
import {AgencySubscriptionChecklistComponent} from './agency-subscription-checklist/agency-subscription-checklist.component';
import {AgencyTypeComponent} from './agency-type/agency-type.component';
import {AppointmentTypeComponent} from './appointment-type/appointment-type.component';
import {AppointmentsComponent} from './appointments/appointments.component';
import {BodyChartDetailComponent} from './body-chart-detail/body-chart-detail.component';
import {CategoriesComponent} from './categories/categories.component';
import {ClientDetailsComponent} from './client-details/client-details.component';
import {ClinicExpertsComponent} from './clinic-experts/clinic-experts.component';
import {ClinicExpertsSchedualComponent} from './clinic-experts-schedual/clinic-experts-schedual.component';
import {ClinicTypeComponent} from './clinic-type/clinic-type.component';
import {ClinicsComponent} from './clinics/clinics.component';
import {CompanyComponent} from './company/company.component';
import {CompanyUsersComponent} from './company-users/company-users.component';
import {CompnayPatientsComponent} from './compnay-patients/compnay-patients.component';
import {ContactsComponent} from './contacts/contacts.component';
import {DischargeComponent} from './discharge/discharge.component';
import {DrugHistoryComponent} from './drug-history/drug-history.component';
import {ExerciseCategoryComponent} from './exercise-category/exercise-category.component';
import {ExercisesComponent} from './exercises/exercises.component';
import {ExpensesComponent} from './expenses/expenses.component';
import {ExpertComponent} from './expert/expert.component';
import {FlagsComponent} from './flags/flags.component';
import {GoalsComponent} from './goals/goals.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {InvoiceItemsDetailComponent} from './invoice-items-detail/invoice-items-detail.component';
import {ListOfValuesComponent} from './list-of-values/list-of-values.component';
import {ListOfValuesDetailComponent} from './list-of-values-detail/list-of-values-detail.component';
import {ListOfValuesMasterComponent} from './list-of-values-master/list-of-values-master.component';
import {ObservationsComponent} from './observations/observations.component';
import {OutcomeMeasureComponent} from './outcome-measure/outcome-measure.component';
import {OutcomeMeasureImagesComponent} from './outcome-measure-images/outcome-measure-images.component';
import {PalpationComponent} from './palpation/palpation.component';
import {PastMedicalHistoryComponent} from './past-medical-history/past-medical-history.component';
import {PatientExercisesComponent} from './patient-exercises/patient-exercises.component';
import {PatientsComponent} from './patients/patients.component';
import {PaymentsComponent} from './payments/payments.component';
import {PostureComponent} from './posture/posture.component';
import {PresentConditionHistoryComponent} from './present-condition-history/present-condition-history.component';
import {SocialHistoryComponent} from './social-history/social-history.component';
import {TestingAromComponent} from './testing-arom/testing-arom.component';
import {TestingMuscleComponent} from './testing-muscle/testing-muscle.component';
import {TestingNeuroComponent} from './testing-neuro/testing-neuro.component';
import {TestingSpecialComponent} from './testing-special/testing-special.component';
import {TreatmentComponent} from './treatment/treatment.component';
import {UsersComponent} from './users/users.component';
import {WadDetailComponent} from './wad-detail/wad-detail.component';
import {LoginComponent} from './auth/login/login.component';
import {LogintypesComponent} from './logintypes/logintypes.component';
import {LoginForgotpasswordComponent} from './login-forgotpassword/login-forgotpassword.component';
import {LoginChangepasswordComponent} from './login-changepassword/login-changepassword.component';
import {LogoutComponent} from './auth/logout/logout.component';
import {ExpensesDetailComponent} from './expenses-detail/expenses-detail.component';
import {PaymentsDetailComponent} from './payments-detail/payments-detail.component';
import {AuthGuard} from './services/auth.guard';
import {InitializerComponent} from './initializer/initializer.component';
import {LandingpageComponent} from './landingpage/landingpage.component';
import {ChecklistComponent} from './checklist/checklist.component';
import {AnalyticsComponent} from './analytics/analytics.component';
import {ManagesubscriptionComponent} from './managesubscription/managesubscription.component';
import {SuperDashboardComponent} from './Dashboards/super-dashboard/super-dashboard.component';
import {AdminDashboardComponent} from './Dashboards/admin-dashboard/admin-dashboard.component';
import {ExpertDashboardComponent} from './Dashboards/expert-dashboard/expert-dashboard.component';
import {CorporateDashboardComponent} from './Dashboards/corporate-dashboard/corporate-dashboard.component';
import {IndividualDashboardComponent} from './Dashboards/individual-dashboard/individual-dashboard.component';
import {MainDashboardComponent} from './Dashboards/main-dashboard/main-dashboard.component';
import {AddContactComponent} from './add-contact/add-contact.component';
import { TimeSlotsComponent } from './time-slots/time-slots.component';
import {ClientHistoryComponent} from './client-history/client-history.component';
import { InitialAssessmentComponent } from './initial-assessment/initial-assessment.component';
import {HistoryComponent} from './history/history.component';
import {TestingComponent} from './testing/testing.component';
import { LovDetailsComponent } from './lov-details/lov-details.component';
import {ObservationsMainComponent} from './observations-main/observations-main.component';
import { ImageFileUploadComponent } from './image-file-upload/image-file-upload.component';
import {CpaToolsComponent} from './cpa-tools/cpa-tools.component';
import {ReportsComponent} from './reports/reports.component';
import {CommunicationComponent} from './communication/communication.component';
import {SubscriptionComponent} from './subscription/subscription.component';
import {SubscriptionBalanceComponent} from './subscription-balance/subscription-balance.component';
import {SsoNotificationsComponent} from './notification/sso-notifications/sso-notifications.component';
import {PatientDetailsComponent} from './patient-details/patient-details.component';
import {CheckoutSuccessComponent} from './stripe/checkout-success/checkout-success.component';
import {CpaRolesComponent} from './cpa-roles/cpa-roles.component';
import {CpaApplicationRolesComponent} from './cpa-application-roles/cpa-application-roles.component';
import {UserLevelsComponent} from './user-levels/user-levels.component';
import {MetricsComponent} from './metrics/metrics.component';
import {ZComponent} from "./z/z.component";
import {DiagnosisComponent} from "./diagnosis/diagnosis.component";
import {VideoConferencingComponent} from "./video-conferencing/video-conferencing.component";
import {InitialAssessmentReviewComponent} from "./initial-assessment-review/initial-assessment-review.component";
import {AssociateDiagnosisLovComponent} from "./associate-diagnosis-lov/associate-diagnosis-lov.component";
import {FollowUpComponent} from "./follow-up/follow-up.component";
import {CpaClientsComponent} from "./cpa-clients/cpa-clients.component";
import {FollowUpBodyChartComponent} from './follow-up/body-chart/follow-up-body-chart.component';
import { FollowUpTestingComponent} from './follow-up/testing/follow-up-testing.component';
import { FollowUpReviewComponent} from './follow-up/review/follow-up-review.component'
import {CompleteSignUpComponent} from './complete-sign-up/complete-sign-up.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';




const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {path: 'initializing', component: InitializerComponent},
    {path: 'login', component: LoginComponent},
    // {path: 'cpa/logout', component: LogoutComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'logintypes', component: LogintypesComponent},
    {path: 'forgotPassword', component: LoginForgotpasswordComponent},
    {path: 'changePassword/:hashvalue/:email', component: LoginChangepasswordComponent},
    {path: 'manage-subs', component: ManagesubscriptionComponent},
    {
        path: 'cpaTools',
        component: CpaToolsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['CPA Tools']}
    },
    {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'cpa/landingpage',
        component: LandingpageComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'dashboard',
        component: MainDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'super-admin-dashboard',
        component: SuperDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin-dashboard',
        component: AdminDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'expert-dashboard',
        component: ExpertDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'corporate-dashboard',
        component: CorporateDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'individual-dashboard',
        component: IndividualDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'client-history',
        component: ClientHistoryComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'patients',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },
    {
        path: 'physiotherapy_patients',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },

    {
        path: 'psychology_patients',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },
    {
        path: 'active_patient',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },
    {
        path: 'deactivated_patient',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },
    {
        path: 'patient_by_profession',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['getPatient']}
    },
    {
        path: 'agency',
        component: AgencyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Generate Companies', 'deleteCompanies', 'viewCompanies']}
    },
    {
        path: 'active_agency',
        component: AgencyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Generate Companies', 'deleteCompanies', 'viewCompanies']}
    },
    {
        path: 'deactivated_agency',
        component: AgencyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Generate Companies', 'deleteCompanies', 'viewCompanies']}
    },
    {
        path: 'incomplete_agency',
        component: AgencyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Generate Companies', 'deleteCompanies', 'viewCompanies']}
    },
    {
        path: 'agency_clinic',
        component: AgencyClinicComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateClinics', 'deleteClinics', 'getClinics']}
    },
    {
        path: 'agency_company',
        component: AgencyCompanyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'agency_contacts',
        component: AgencyContactsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'agency_expert',
        component: AgencyExpertComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'agency_patients',
        component: AgencyPatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },
    {
        path: 'agency_subscription_checklist',
        component: AgencySubscriptionChecklistComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Checklist', 'deleteChecklist', 'viewChecklist']}
    },
    {
        path: 'agency_type',
        component: AgencyTypeComponent
    },
    {
        path: 'appointment_type',
        component: AppointmentTypeComponent,
    },
    {
        path: 'appointments',
        component: AppointmentsComponent,
        canActivate: [AuthGuard],
      ///  data: {roles: ['Add Appointment', 'Update Appointment', 'Delete Appointment', 'Get Appointment']}
    }, {
        path: 'appointments_confirmation',
        component: AppointmentsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Appointment', 'Update Appointment', 'Delete Appointment', 'Get Appointment']}
    }, {
        path: 'subscription',
        component: SubscriptionComponent,
        canActivate: [AuthGuard]
    }, {
        path: 'subscription_balance',
        component: SubscriptionBalanceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'follow-up',
        component: FollowUpComponent,
       // canActivate: [AuthGuard],
       // data: {roles: ['Add Appointment', 'Update Appointment', 'Delete Appointment', 'Get Appointment']}
    },

    {
      path: 'follow-up/body-chart',
      component: FollowUpBodyChartComponent,
      canActivate: [AuthGuard],
    // data: {roles: ['Add Appointment', 'Update Appointment', 'Delete Appointment', 'Get Appointment']}
    },

  {
    path: 'follow-up/testing',
    component: FollowUpTestingComponent,
    canActivate: [AuthGuard],
    // data: {roles: ['Add Appointment', 'Update Appointment', 'Delete Appointment', 'Get Appointment']}
  },

  {
    path: 'follow-up/review',
    component: FollowUpReviewComponent,
    canActivate: [AuthGuard],
    // data: {roles: ['Add Appointment', 'Update Appointment', 'Delete Appointment', 'Get Appointment']}
  },

    {
        path: 'body_chart',
        component: BodyChartDetailComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Body Chart']}
    },
    {
        path: 'categories',
        component: CategoriesComponent
    },
    {
        path: 'client_details',
        component: ClientDetailsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Client Details']}
    },
    {
        path: 'clinic_experts',
        component: ClinicExpertsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateClinicSchedule', 'deleteClinicSchedule', 'Clinic Schedule']}
    },
    {
        path: 'clinic_experts_schedule',
        component: ClinicExpertsSchedualComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateClinicSchedule', 'deleteClinicSchedule', 'Clinic Schedule']}
    },
    {
        path: 'clinic_type',
        component: ClinicTypeComponent
    },
    {
        path: 'clinics',
        component: ClinicsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateClinics', 'deleteClinics', 'getClinics']}
    },
    {
        path: 'active-clinics',
        component: ClinicsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateClinics', 'deleteClinics', 'getClinics']}
    },
    {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'manage-users',
        component: CompanyUsersComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Manage Users']}
    },
    {
        path: 'company_patients',
        component: CompnayPatientsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Add Patient', 'getPatient', 'deletePatient']}
    },
    {
        path: 'contacts',
        component: ContactsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'add-contact',
        component: AddContactComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'view-contacts',
        component: ContactsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'view-companies',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'view-experts',
        component: ExpertComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'discharge',
        component: DischargeComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Discharge']}
    },
    {
        path: 'exercise_category',
        component: ExerciseCategoryComponent
    },
    {
        path: 'exercises',
        component: ExercisesComponent,
        canActivate: [AuthGuard],
        data: {roles: ['CPA Tools']}
    },
    {
        path: 'history',
        component: HistoryComponent,
        canActivate: [AuthGuard],
        data: {roles: ['History']}
    },
    {
        path: 'testing',
        component: TestingComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Testing']}
    },
    {
        path: 'expenses',
        component: ExpensesComponent,
        // canActivate: [AuthGuard],
        // data: {roles: ['addUpdateAccounts', 'getAccounts', 'getAccounts']}
    },
    {
        path: 'expert',
        component: ExpertComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateContacts', 'deleteContacts', 'getContacts']}
    },
    {
        path: 'flags',
        component: FlagsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Flags']}
    },
    {
        path: 'goals',
        component: GoalsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Goals']}
    },
    {
        path: 'invoice',
        component: InvoiceComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Invoice', 'deleteInvoice', 'viewInvoice']}
    },
    {
        path: 'paid-invoice',
        component: InvoiceComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Invoice', 'deleteInvoice', 'viewInvoice']}
    },
    {
        path: 'pending-invoice',
        component: InvoiceComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Invoice', 'deleteInvoice', 'viewInvoice']}
    },
    {
        path: 'invoice_items_detail',
        component: InvoiceItemsDetailComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Invoice', 'deleteInvoice', 'viewInvoice']}
    },
    {
        path: 'listOfValue',
        component: ListOfValuesComponent
    },
    {
        path: 'list_of_values_detail',
        component: ListOfValuesDetailComponent
    },
    {
        path: 'list_of_values_master',
        component: ListOfValuesMasterComponent
    },
    {
        path: 'observations',
        component: ObservationsMainComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Observations']}
    },
    {
        path: 'outcome_measure',
        component: OutcomeMeasureComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Outcome Measures']}
    },
    {
        path: 'outcome_measure_images',
        component: OutcomeMeasureImagesComponent
    },

    {path: 'patient_exercises', component: PatientExercisesComponent, canActivate: [AuthGuard], data: {roles: ['CPA Tools']}},
    {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateAccounts', 'getAccounts', 'getAccounts']}
    },



    {
        path: 'treatment',
        component: TreatmentComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Treatment']}
    },
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'wad_detail',
        component: WadDetailComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Outcome Measures']}
    },
    {
        path: 'expense_detail',
        component: ExpensesDetailComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateAccounts', 'getAccounts', 'getAccounts']}
    },
    {
        path: 'payment_detail',
        component: PaymentsDetailComponent,
        canActivate: [AuthGuard],
        data: {roles: ['addUpdateAccounts', 'getAccounts', 'getAccounts']}
    },
    {
        path: 'time-slot',
        component: TimeSlotsComponent,
        // canActivate: [AuthGuard],
        // data: {roles: ['Generate Companies', 'deleteCompanies', 'viewCompanies']}

    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Reports']}

    },
    {
        path: 'initial-assessment',
        component: InitialAssessmentComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Initial Assessment']}

    },
    {
        path: 'patient-details',
        component: PatientDetailsComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Initial Assessment']}

    },

    {
        path: 'image-file-upload',
        component: ImageFileUploadComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Image File Upload']}

    },
    {
        path: 'communications',
        component: CommunicationComponent,
        canActivate: [AuthGuard],
        data: {roles: ['Communications']}
    },
    {
      path : 'lov-details',
      component : LovDetailsComponent,
    //   canActivate:[AuthGuard],
    //   data:{roles : ['Initial Assessment']}
    },
    {
        path : 'allnotifications',
        component : SsoNotificationsComponent,
        //   canActivate:[AuthGuard],
        //   data:{roles : ['Initial Assessment']}
    },
  {
    path : 'complete-sign-up',
    component : CompleteSignUpComponent,
  },

  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },


  {
    path: 'stripe-success',
    component: CheckoutSuccessComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },

  {
    path: 'cpa-clients',
    component: CpaClientsComponent,
    //canActivate: [AuthGuard],
    // data: {roles: ['Body Chart']}
  },

  {
    path: 'cpa-roles',
    component: CpaRolesComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },
  {
    path: 'cpa-application-roles',
    component: CpaApplicationRolesComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },
  {
    path: 'user-levels',
    component: UserLevelsComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },

  {
    path: 'metrics',
    component: MetricsComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },

  {
    path: 'diagnosis',
    component: DiagnosisComponent,
    canActivate: [AuthGuard],
    data: {roles: ['Body Chart']}
  },

  {
    path: 'initial-assessment-review',
    component: InitialAssessmentReviewComponent,
    canActivate: [AuthGuard],
    data: {roles: ['Body Chart']}
  },

  {
    path: 'video-conferencing',
    component: VideoConferencingComponent,
    //canActivate: [AuthGuard],
   // data: {roles: ['Body Chart']}
  },
  {
    path: 'associate-diagnosis',
    component: AssociateDiagnosisLovComponent,
    //canActivate: [AuthGuard],
    // data: {roles: ['Body Chart']}
  },


  {
    path: 'z',
    component: ZComponent,
    ///canActivate: [AuthGuard],
    ///data: {roles: ['Initial Assessment']}
  },
];

@NgModule({
              imports:[RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
              exports: [RouterModule]
          })
export class AppRoutingModule {
}
