<!--<app-app-header></app-app-header>-->
<section class="sectionmain" id="sectionmain-manageSubs">

    <div class="container">
        <section class="sectioncontent manageSubsContent" id="sectionmain-manageSubs-sectioncontent">
            <!-- <div class="manageSubs-card p-d-flex p-jc-center" id="manageSubs-card-wrap"> -->
            <p-card header="" styleClass="p-card-shadow">
                <ng-template pTemplate="header">
                    <h3 class="textBold h3-style superAdminTitles companiesTitle">Companies</h3>
                </ng-template>
                <form class="formContainer">
                    <div class="mSubs-search-field p-fluid p-formgrid p-grid" id="manageSubs-search-field">
                        <div class="p-field p-col-12">
                            <label>Search Companies</label>
                            <p-dropdown [options]="searchCompanies" [filter]="true" class="dropdown-class"
                                        placeholder="Select" optionLabel="name" [showClear]="true">
                            </p-dropdown>
                        </div>
                    </div>
                </form>

                <div class="manageSubs-content" id="manageSubs-contentWrap">
                    <h3 class="h3-style textBold superAdminTitles">Manage Subscription</h3>

                    <p-table [value]="subscriptions"
                             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                             [paginator]="true" [resizableColumns]="true" [autoLayout]="true"
                             [showCurrentPageReport]="true" [alwaysShowPaginator]="false"
                             [rowsPerPageOptions]="[5,10,20, 30]" [rows]="10"
                             styleClass="p-datatable-striped" class="manageSubsTable">
                        <!-- <ng-template pTemplate="header">
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </ng-template> -->
                        <ng-template pTemplate="body" let-subs let-rowIndex="rowIndex">
                            <tr>
                                <td>{{subs.title}}</td>
                                <td class="text-right"><!--  [(ngModel)]="selectBtnValueOne" -->
                                    <p-selectButton class="selectBtn" *ngIf="subs.sButton == 'required'"
                                                    [options]="selectionButtonDataOne" [(ngModel)]="selectBtnValueOne"
                                                    optionLabel="label" optionValue="value">
                                    </p-selectButton>

                                    <p-selectButton class="selectBtn" *ngIf="subs.sButton == 'activate'"
                                                    [options]="selectionButtonDataTwo"
                                                    [(ngModel)]="selectBtnValueTwo"
                                                    optionLabel="label" optionValue="value">
                                    </p-selectButton>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <ng-template pTemplate="footer">
                    <div class="buttonsClass">
                        <div class="p-mr-4">
                            <p-button class="pngBtnTransparent" id="manageSubs-cancelBtn" label="Cancel"
                                      styleClass="p-button"></p-button>
                        </div>
                        <div>
                            <p-button class="pngBlueBtn" id="manageSubs-saveBtn" label="Save"
                                      styleClass="p-button"></p-button>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </section>
    </div>
</section>
