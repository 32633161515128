import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { ExpensesService } from '../web-services/expenses.service';
import { ExpensesDetailService } from '../web-services/expenses-detail.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

declare var openDialogAddOverlaydiv: Function;
declare var closeDialogAddOverlaydiv: Function;
declare var openDialogSearchOverlaydiv: Function;
declare var closeDialogSearchOverlaydiv: Function;

declare var closeDialogAddSerachOverlaydiv: Function;

@Component({
    selector: 'app-expenses-detail',
    templateUrl: './expenses-detail.component.html',
    styleUrls: ['./expenses-detail.component.css']
})
export class ExpensesDetailComponent implements OnInit {
    constructor(
        private expensesDetailServices: ExpensesDetailService,
        private expensesServices: ExpensesService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovService: ListOfValuesService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private dialogService: DialogService
    ) {
    };

    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateExpensesDetailId = null;
    expensesList: any = [];
    expensesDetailList: any = [];
    expensesDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    expensesDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    expense_items_array: any = [];
    expenseObj: any = null;
    expenseId: number = 0;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;

    addEditTitle = 'Add';

    onClearSearch() {
        this.expensesDetailSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countExpensesDetail();
        this.findAllExpensesDetail();
        // closeDialogSearchOverlaydiv();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countExpensesDetail() {
        this.expensesDetailServices.countExpensesDetail(this.expensesDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllExpensesDetail();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.expensesDetailForm.markAllAsTouched();

        if (this.expenseId) {
            this.expensesDetailForm.get('expensesId').setValue(this.expenseId);
        }
        // console.log("expenses detail form :: ",this.expensesDetailForm.value);
        if (this.expensesDetailForm.valid) {
            this.addUpdateExpensesDetail(this.expensesDetailForm.value);
            this.submitted = false;
            this.onClearAdd();
        }

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllExpensesDetail();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.expensesDetailSearch.reset();
        this.countExpensesDetail();
        this.findAllExpensesDetail();
    }

    addUpdateExpensesDetail(expensesDetail) {
        this.startLoader();
        if (this.updateExpensesDetailId == null) {
            this.expensesDetailServices.addExpensesDetail(this.expensesDetailForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    expensesDetail = res['data'][0];
                    this.expensesDetailList.push(expensesDetail);
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNameExpensesDetail) +
                                                    this.translate(this.labels.addedSuccessfully),
                        // detail: this.translate(this.labels.entityNameExpensesDetail)
                    });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countExpensesDetail();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            expensesDetail.expensesDetailId = this.updateExpensesDetailId;
            this.expensesDetailServices.updateExpensesDetail(expensesDetail).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.expensesDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                        severity: 'success',
                                                summary: this.translate(this.labels.entityNameExpensesDetail) +
                                                    this.translate(this.labels.updatedSuccessfully),
                        // detail: this.translate(this.labels.entityNameExpensesDetail)
                    });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateExpensesDetailId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    get addUpdateTitle() {
        return this.updateExpensesDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        console.log(this.config.data.expenseObj, ' :: invoice object on init ');
        this.expenseObj = this.config.data.expenseObj;
        this.expenseId = this.config.data.expenseObj.expensesId;
        // console.log("expenses Obj :: ",this.expenseObj," expense id :: ",this.expenseId);
        this.findAllExpenses();
        this.expensesDetailForm = new FormGroup({
            expensesId: new FormControl(this.expenseId),
            itemId: new FormControl(0, [Validators.required, Validators.min(0)]),
            quantity: new FormControl(0, [Validators.required, Validators.min(0)]),
            unitPrice: new FormControl(0, [Validators.required, Validators.min(0)]),
            discount: new FormControl(0, [Validators.required, Validators.min(0)]),
            taxAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
            totalAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
            description: new FormControl('', [Validators.required, validateSpaces]),
            userId: new FormControl(0),
        });
        this.expensesDetailSearch = new FormGroup({
            expensesId: new FormControl(this.expenseId),
            itemId: new FormControl(),
            quantity: new FormControl(),
            unitPrice: new FormControl(),
            discount: new FormControl(),
            taxAmount: new FormControl(),
            totalAmount: new FormControl(),
            description: new FormControl(),
            userId: new FormControl(),
        });
        this.countExpensesDetail();
        // this.findAllExpensesDetail();
        this.findAllListOfValues();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.expensesDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
        // closeDialogAddSerachOverlaydiv();
    }


    findAllListOfValues() {

        this.startLoader();
        this.lovService.getAllListOfValuesByName(Constant.EXPENSE_DETAIL_LOV_ITEMS).subscribe(result => {

            console.log("result of Lov values :: ", result);

            if (result['statusCode'] == 200) {
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];

                    if (element[Constant.EXPENSE_DETAIL_LOV_EXPENSE_ITEMS]) {
                        console.log("items array :: ", element[Constant.EXPENSE_DETAIL_LOV_EXPENSE_ITEMS]);
                        // this.sex_array = element[Constant.PATIENT_SEX];
                        for (let object of element[Constant.EXPENSE_DETAIL_LOV_EXPENSE_ITEMS]) {
                            let dropDown = { label: '', value: '' };
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.expense_items_array.push(dropDown);
                            console.log("drop down values array :: ", dropDown);
                        }

                    }
                }
            }

        });
    }

    findAllExpensesDetail() {
        this.startLoader();
        this.expensesDetailSearch.controls['expensesId'].setValue(this.expenseId);
        this.expensesDetailServices.findAllExpensesDetail(this.expensesDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.expensesDetailList = res['data'];
                } else {
                    this.expensesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteRef: any;
    deleteByIdExpensesDetail(expensesDetailId: number, index) {
        this.deleteRef = this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.expensesDetailServices.deleteByIdExpensesDetail(expensesDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.expensesDetailList.splice(index, 1);
                        this.messageService.add({
                            severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameExpensesDetail) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                            // detail: this.translate(this.labels.entityNameExpensesDetail)
                        });
                        this.countExpensesDetail();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
        console.log(this.deleteRef, 'delete reference');
        this.deleteRef = false;
    }

    loadExpensesDetailToEdit(expensesDetailId: number, index) {
        this.addEditTitle = 'Edit'
        let expensesDetail = this.expensesDetailList.find(x => x['expensesDetailId'] == expensesDetailId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateExpensesDetailId = expensesDetail['expensesDetailId'];
        this.selectedIndex = index;

        if (expensesDetail['expensesId']) {
            this.expensesDetailForm.controls['expensesId'].setValue(expensesDetail['expensesId']['expensesId']);
        }

        if (expensesDetail['itemId']) {
            this.expensesDetailForm.controls['itemId'].setValue(expensesDetail['itemId']['listOfValuesId']);
        }

        if (expensesDetail['quantity']) {
            this.expensesDetailForm.controls['quantity'].setValue(expensesDetail['quantity']);
        }

        if (expensesDetail['unitPrice']) {
            this.expensesDetailForm.controls['unitPrice'].setValue(expensesDetail['unitPrice']);
        }

        if (expensesDetail['discount']) {
            this.expensesDetailForm.controls['discount'].setValue(expensesDetail['discount']);
        }

        if (expensesDetail['taxAmount']) {
            this.expensesDetailForm.controls['taxAmount'].setValue(expensesDetail['taxAmount']);
        }

        if (expensesDetail['totalAmount']) {
            this.expensesDetailForm.controls['totalAmount'].setValue(expensesDetail['totalAmount']);
        }

        if (expensesDetail['description']) {
            this.expensesDetailForm.controls['description'].setValue(expensesDetail['description']);
        }

        if (expensesDetail['userId']) {
            this.expensesDetailForm.controls['userId'].setValue(expensesDetail['userId']);
        }

        // openDialogAddOverlaydiv();

        // by ali
        if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.expensesDetailForm.reset();
        this.expensesDetailForm.markAsUntouched();
        this.updateExpensesDetailId = null;
        // closeDialogAddOverlaydiv();
    }

    findAllExpenses() {
        let temp = [];
        this.startLoader();
        this.expensesServices.findAllExpenses(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['expensesId'];
                        dropDown.label = object['null'];
                        this.expensesList.push(dropDown);
                    }
                } else {
                    this.expensesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.expensesDetailForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.expensesDetailForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.expensesDetailForm.get(control)?.valid && this.expensesDetailForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeDialogAddOverlaydiv();
        this.expensesDetailForm.reset();
    }

    onCloseSearch() {
        closeDialogSearchOverlaydiv();
        this.expensesDetailSearch.reset();
    }

    refresh() {
        this.countExpensesDetail();
    }

    onSearchOverlayDiv() {
        openDialogSearchOverlaydiv();
    }

    onAddOverlayDiv() {
        openDialogAddOverlaydiv();
        this.expensesDetailForm.reset();
    }

    onCloseSearchOverlayDiv() {
        closeDialogSearchOverlaydiv();
    }

    onCloseAddOverlayDiv() {
        closeDialogAddOverlaydiv();
    }

    calculateAmount() {

        if (this.expensesDetailForm.value.quantity) {

        } else {
            this.expensesDetailForm.controls['qyuantity'].setValue(0);
        }
        if (this.expensesDetailForm.value.unitPrice) {

        } else {
            this.expensesDetailForm.controls['unitPrice'].setValue(0);
        }
        if (this.expensesDetailForm.value.discount) {

        } else {
            this.expensesDetailForm.controls['discount'].setValue(0);
        }
        if (this.expensesDetailForm.value.taxAmount) {

        } else {
            this.expensesDetailForm.controls['taxAmount'].setValue(0);
        }
        let totalamount = 0;
        totalamount = (this.expensesDetailForm.value.quantity * this.expensesDetailForm.value.unitPrice -
            this.expensesDetailForm.value.discount + this.expensesDetailForm.value.taxAmount);

        console.log('Total amount is : ', totalamount);
        this.expensesDetailForm.controls['totalAmount'].setValue(totalamount);
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log("global search clicked ", eventValue);
        this.searchTerm = eventValue;
        this.expenseDetailGlCount();
    }

    expenseDetailGlCount() {
        this.expensesDetailServices.expenseDetailGlcount(this.searchTerm, this.expenseId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchExpenseDetail();
            } else {
                this.totalRecords = 0;
                this.expensesDetailList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchExpenseDetail() {
        this.expensesDetailServices.findGlobalExpenseDetail(this.searchTerm, this.expenseId, this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.expensesDetailList = result['data'];
            } else {
                this.expensesDetailList = [];
            }
            console.log(result, "result");
        });
    }

    viewExpenseDetail(invoiceItemsDetailId: number) {
        // console.log('modal called');
        this.ref = null;
        let expenseItemsDetail = this.expensesDetailList.find(x => x['expensesDetailId'] == invoiceItemsDetailId);
        console.log("expense detail obj :: ", expenseItemsDetail);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Expense Detail',
            width: '80%',
            contentStyle: { 'max-height': '750px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                mainData: [
                    { label: this.labels.invoiceItemsDetailColumnItemId, value: expenseItemsDetail['itemId']['label'] },
                    { label: this.labels.invoiceItemsDetailColumnQuantity, value: expenseItemsDetail['quantity'] },
                    { label: this.labels.invoiceItemsDetailColumnUnitPrice, value: expenseItemsDetail['unitPrice'] },
                    { label: this.labels.invoiceItemsDetailColumnDiscount, value: expenseItemsDetail['discount'] },
                    { label: this.labels.invoiceItemsDetailColumnTaxAmount, value: expenseItemsDetail['taxAmount'] },
                    { label: this.labels.invoiceItemsDetailColumnTotalAmount, value: expenseItemsDetail['totalAmount'] },
                    { label: "Description", value: expenseItemsDetail['description'] },

                ]
            },

        });
        this.ref.onClose.subscribe(res => {
            // this.countInvoice();
        });
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.expensesDetailForm.reset();
        }
        this.addEditTitle = 'Add';
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }
}