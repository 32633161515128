import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cpa-application-roles',
  templateUrl: './cpa-application-roles.component.html',
  styleUrls: ['./cpa-application-roles.component.css']
})
export class CpaApplicationRolesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
