import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../collaboration/constants';
import {Router} from '@angular/router';
// import { AlertService } from '../services/alertservice/alert.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ChatService} from 'src/app/collaboration/services/chat/chat.service';
// import {SessionService} from 'src/app/web-services/session.service';
// import {UserDetail} from 'src/app/interfaces/user';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {SharedService} from 'src/app/web-services/shared.service';
import {Labels} from 'src/app/util/labels';


@Component({
               selector: 'app-sso-notifications',
               templateUrl: './sso-notifications.component.html',
               styleUrls: ['./sso-notifications.component.css']
           })
export class SsoNotificationsComponent implements OnInit {

    displayedColumns: string[] = ['notificationdetails', 'notificationdate', 'action'];
    dataSource: MatTableDataSource<Element>;
    // userDetail: UserDetail;
    pageSizeOptions = AppSettings.PAGE_SIZE_OPTIONS;
    public labels = Labels;
    constantList = [];
    totalRecords;
    itemPerPage = 10;
    page = 0;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    rows: number = 10;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private chatService: ChatService,
        // private sessionService: SessionService,
        private ngxSpinnerService: NgxUiLoaderService,
        private confirmation: ConfirmationService,
        private sharedService: SharedService
        // private toastr: AlertService
    ) {
    }

    openDeleteDialog(notificationId, index) {
        // console.log(this.pageNumber);
        // console.log(this.limit);
        // console.log();
        // console.log(notificationId, index);
        // const dialogRef = this.dialog.open(DeleteAlertDialog, {
        //   data: {
        //     title: "Delete Notification",
        //     message: "Are you sure you want to Delete this Notification"
        //   }
        // });

        // dialogRef.afterClosed().subscribe(result => {
        //   if (result) {
        //     this.deleteNotification(notificationId.eventLogDetailId, index)
        //   }
        // });
    }

    deleteNotification(notificationId, index) {
        this.ngxSpinnerService.startLoader('ntfyLoader');
        this.chatService.deleteNotification(notificationId).subscribe(data => {
            if (data) {
                try {
                    var deletentfy = data['data'];
                    if (data['statusCode'] == AppSettings.API_SUCCESS_CODE) {

                        // this.toastr.showSuccess('Delete', 'Notification');
                        if (notificationId == null) {
                            this.totalNotificationsCount = 0;
                            this.notifications = [];
                            this.dataSource = new MatTableDataSource<Element>(this.notifications);
                        } else {
                            if (index != -1) {
                                this.notifications.splice(index, 1);
                                this.totalNotificationsCount -= 1;
                                this.dataSource = new MatTableDataSource<Element>(this.notifications);
                                this.getAllNotificationsCount(this.page, this.itemPerPage);

                            }

                        }
                        this.ngxSpinnerService.stopLoader('ntfyLoader');
                    } else {
                        // this.toastr.showError('Delete', 'Notification');
                        this.ngxSpinnerService.stopLoader('ntfyLoader');
                    }
                } catch (error) {
                    // this.chatService.exceptionHandling(error, "allNotifications").subscribe(data => { this.errorMessage = data });
                    this.ngxSpinnerService.stopLoader('ntfyLoader');
                }
            }
        }, error => {
            this.ngxSpinnerService.stopLoader('ntfyLoader');
            // this.sharedService.checkHttpCodes(error)
        });
    }

    ngOnInit() {
        // this.userDetail = this.sessionService.getUserDetails();
        this.getNotifications();
        this.getAllNotificationsCount(this.page, this.itemPerPage);

        // this.checkVersion();


        this.notificationspagemenulist = [
            {
                label: 'Mark All as Read',
                command: () => {
                    this.markAllRead();
                }
            },
            {
                label: 'Delete All',
                command: () => {
                    this.deleteAll();
                }
            }
        ];
    }

    currentVersion = 0;
    // checkVersion() {
    //   this.sharedService.CurrentVersion.subscribe(data => {
    //     this.currentVersion = data;
    //   });
    // }

    totalNotificationsCount = 0;

    getAllNotificationsCount(pageNo, itemPerPage) {
        this.ngxSpinnerService.startLoader('ntfyLoader');
        this.chatService.getAllNotificationsCount(sessionStorage.getItem('loggedInUser')).subscribe(data => {
            if (data && data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
                try {
                    this.totalNotificationsCount = data['data'];
                    this.totalRecords = this.totalNotificationsCount;
                    this.getAllNotifications(pageNo, itemPerPage);
                } catch (error) {
                    // this.menuComponent.stopLoader();
                    this.ngxSpinnerService.stopLoader('ntfyLoader');
                    // this.chatService.exceptionHandling(error, "getallNotificationsCount").subscribe(data => { this.errorMessage = data });
                }
            } else {
                this.ngxSpinnerService.stopLoader('ntfyLoader');
                // this.menuComponent.stopLoader();
            }
        }, error => {
            // this.menuComponent.stopLoader();
            this.ngxSpinnerService.stopLoader('ntfyLoader');
            // this.sharedService.checkHttpCodes(error);
        });
    }


    notifications = [];

    getAllNotifications(pageNo, itemPerPage) {
        this.chatService.getAllNotifications(sessionStorage.getItem('loggedInUser'), pageNo, itemPerPage).subscribe(data => {
            if (data) {
                try {
                    let tmpData = data['data'];
                    // console.log(tmpData);

                    // tmpData.forEach(notificationDetail => {
                    //   let imageUrls = [];
                    //   let tmpImage = JSON.parse(notificationDetail['fromUser']['userProfilePicture']);
                    //   tmpImage.forEach(image => {
                    //     let imageLink = {
                    //       "previewImageSrc": image,
                    //     }
                    //     imageUrls.push(imageLink);
                    //   });
                    //   if (imageUrls != undefined && imageUrls.length > 0) {
                    //     notificationDetail['fromUser'].imageUrls = imageUrls;
                    //   }
                    //   console.log(notificationDetail);

                    //   this.notifications.push(notificationDetail);
                    // });
                    this.notifications = data['data'];
                    // console.log("notifications :: ", this.notifications);
                    // this.dataSource.data = this.notifications;
                    this.dataSource = new MatTableDataSource<Element>(this.notifications);
                    // this.dataSource.paginator = this.paginator;
                    // console.log("dataSource :: ", this.dataSource)
                    this.ngxSpinnerService.stopLoader('ntfyLoader');
                } catch (error) {
                    this.ngxSpinnerService.stopLoader('ntfyLoader');


                    // this.chatService.exceptionHandling(error, "getallNotifications").subscribe(data => { this.errorMessage = data });
                }
            } else {
                this.ngxSpinnerService.stopLoader('ntfyLoader');
            }
        }, error => {
            this.ngxSpinnerService.stopLoader('ntfyLoader');
            // this.sharedService.checkHttpCodes(error);
        });
    }

    timeStamp = '';
    unreadNotifications = 0;
    notificationIds = [];

    getNotifications() {
        this.chatService.getNotifications(sessionStorage.getItem('loggedInUser'), this.timeStamp).subscribe(data => {
            if (data) {
                try {
                    this.unreadNotifications = data['unreadNotifications'];
                    if (this.unreadNotifications == null) {
                        this.unreadNotifications = 0;
                    }
                    this.chatService.setUnreadNotifications(this.unreadNotifications);
                } catch (error) {
                }
            }
        }, error => {
            // this.chatService.checkHttpCodes(error);
        });
    }

    markAsRead(notificationId, index) {
        var notification_id = null;
        if (notificationId == null) {
        } else {
            notification_id = notificationId.eventLogDetailId;
        }
        if (notification_id && notificationId.status == 'read') {
        } else {
            this.ngxSpinnerService.startLoader('ntfyLoader');
            this.chatService.markAsRead(notification_id).subscribe(data => {
                if (data) {
                    try {
                        var deletentfy = data['data'];
                        if (data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
                            // this.toastr.showSuccess('Mark as read', 'Notifications');
                            if (notificationId == null) {
                                for (var i = 0; i < this.notifications.length; i++) {
                                    this.notifications[i]['status'] = 'read';
                                }
                                this.chatService.setUnreadNotifications(0);
                                this.dataSource = new MatTableDataSource<Element>(this.notifications);
                            } else {
                                if (index != -1) {
                                    this.notifications[index]['status'] = 'read';
                                    var notificationCount: any;
                                    this.chatService.getUnreadNotifications.subscribe(data => {
                                        notificationCount = data;
                                    });
                                    if (notificationCount > 0) {
                                        this.chatService.setUnreadNotifications(notificationCount - 1);
                                    }
                                    this.dataSource = new MatTableDataSource<Element>(this.notifications);
                                }
                            }
                            this.ngxSpinnerService.stopLoader('ntfyLoader');
                        } else {
                            // this.toastr.showError('Mark as read', 'Notification');
                            this.ngxSpinnerService.stopLoader('ntfyLoader');
                        }
                    } catch (error) {
                        // this.chatService.exceptionHandling(error, "allNotifications").subscribe(data => { this.errorMessage = data });
                        this.ngxSpinnerService.stopLoader('ntfyLoader');
                    }
                }
            }, error => {
                this.ngxSpinnerService.stopLoader('ntfyLoader');
                // this.sharedService.checkHttpCodes(error)
            });
        }
    }

    pageNumber = 0;
    limit = 10;
    pageSize = [1, 5, 10, 20, 50];

    pageEvent(pageNo) {
        this.limit = pageNo['pageSize'];
        this.pageNumber = pageNo['pageIndex'];
        this.getAllNotificationsCount(pageNo['pageIndex'], this.limit);

    }

    subscribeNewEvent() {
        this.router.navigate(['newsubscription']);
    }


    notificationslist: any[] = [
        {
            'representative ': 'Jessica Jordan',
            'details': 'I\'m looking to work with a desinger that. I\'m looking to work with a desinger that.',
            'date': '01-01-2021',
            'time': '3:15PM',
            'action': ''
        },
        {
            'representative ': 'Jessica Jordan',
            'details': 'I\'m looking to work with a desinger that. I\'m looking to work with a desinger that.',
            'date': '01-01-2021',
            'time': '3:15PM',
            'action': ''
        },
        {
            'representative ': 'Jessica Jordan',
            'details': 'I\'m looking to work with a desinger that. I\'m looking to work with a desinger that.',
            'date': '01-01-2021',
            'time': '3:15PM',
            'action': ''
        },
        {
            'representative ': 'Jessica Jordan',
            'details': 'I\'m looking to work with a desinger that. I\'m looking to work with a desinger that.',
            'date': '01-01-2021',
            'time': '3:15PM',
            'action': ''
        },
        {
            'representative ': 'Jessica Jordan',
            'details': 'I\'m looking to work with a desinger that. I\'m looking to work with a desinger that.',
            'date': '01-01-2021',
            'time': '3:15PM',
            'action': ''
        },

    ];
    notificationspagemenulist: MenuItem[];

    notificationsListAdvSearchDialog() {
        this.notificationsadvancedsearchpopup = true;
    }

    confirmationDeleteDialog(nofificationId, index) {
        // this.confirmationdeletepopup = true;

        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.markAsRead(nofificationId, index);
                this.deleteNotification(nofificationId.eventLogDetailId, index);
            }
                                  });
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    confirmationdeletepopup: boolean;


    notificationsadvancedsearchpopup: boolean;


    deleteAll() {
        this.deleteNotification(null, 0);
    }

    markAllRead() {
        this.chatService.setUnreadNotifications(this.unreadNotifications);
        this.markAsRead(null, 0);
    }

    onPageChnage(event: LazyLoadEvent) {
        this.rows = event.rows;
        this.page = event.first / this.rows;
        if (this.totalRecords > 0 && this.page >= 0) {
            this.getAllNotificationsCount(this.page, this.rows);
        } else {

        }
    }


}
