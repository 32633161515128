<!-- Header Component-->
<!-- <p-toast position="top-center"></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>

<app-drawer [@slideInOut]="drawerState" [clickedEvent]="clickedEvent"  (clinicExpertScheduleIdToDelete)="clinicExpertScheduleDeleteHandler($event)"></app-drawer>


<!-- Main Invitation Page-->
<section>
    <div class="container-calendar-schedule">

        <section>
          <div class="row mt-4">
            <div class="p-md-3 card-cpa-rounded card-shadow" style="background: white;height:600px;">
              <form (ngSubmit)="onSubmit()" [formGroup]="clinicExpertsSchedualForm" class="pngForm">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12 p-md-12">
                    <div class="row">
                      <div class="p-md-11" style="padding-left:20px">
                        <h3 class="card-title">Add Clinic Schedule</h3>
                      </div>
                    </div>

                    <div class="row">
                      <div class="p-md-2" style="height:100px">
                        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:200px;height:100px">
                          <div class="custom-alert-icon" [ngClass]="clinicExpertsSchedualForm.controls['clinicId'].value > 0 ? 'icon-success' : 'icon-transparent'">
                            <i class="fa-solid" [ngClass]="clinicExpertsSchedualForm.controls['clinicId'].value > 0 ? 'fa-check' : 'fa-1'"></i></div>
                        </div>
                      </div>
                      <div class="p-md-9" style="vertical-align: middle!important;margin-left:25px;padding-top:28px">
                        <p-dropdown [filter]="true" [options]="clinicsList" [showClear]="true"
                                    formControlName="clinicId" placeholder="Select Clinic"
                                    (onChange)="onClinicChange($event)">
                        </p-dropdown>
                        <app-custom-error [control]="'clinicId'" [form]="clinicExpertsSchedualForm">
                        </app-custom-error>
                      </div>

                    </div>
                  </div>

                  <div class="p-field p-col-12 p-md-12">
                    <div class="row">
                      <div class="p-md-2" style="height:100px">
                        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:200px;height:100px">
                          <div class="custom-alert-icon" [ngClass]="clinicExpertsSchedualForm.controls['expertsId'].value > 0 ? 'icon-success' : 'icon-transparent'">
                            <i class="fa-solid"  [ngClass]="clinicExpertsSchedualForm.controls['expertsId'].value > 0 ? 'fa-check' : 'fa-2'"></i></div>

                        </div>
                      </div>
                      <div class="p-md-9" style="vertical-align: middle!important;margin-left:25px;padding-top:28px">
                        <p-dropdown [filter]="true" [options]="clinicExpertsList" [showClear]="true"
                                    class="pngDropdown" formControlName="expertsId" placeholder="Select Expert"  (onChange)="onExpertChange($event)">
                        </p-dropdown>
                        <app-custom-error [control]="'expertsId'"
                                          [form]="clinicExpertsSchedualForm"></app-custom-error>
                      </div>

                    </div>
                  </div>
                  <div class="p-field p-col-12 p-md-12">
                    <div class="row">
                      <div class="p-md-2" style="height:100px">
                        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
                          <div class="custom-alert-icon" [ngClass]="selectedSchedule.length ? 'icon-success' : 'icon-transparent'">
                            <i class="fa-solid"  [ngClass]="selectedSchedule.length ? 'fa-check' : 'fa-3'"></i></div>

                        </div>
                      </div>
                      <div class="p-md-9" style="margin-left:25px;padding-top:28px;">
                        <span *ngIf="!selectedSchedule.length" class="alert-title">
                           Please select schedule slots from the calendar
                        </span>
                        <span *ngIf="selectedSchedule.length" style="color:green">
                          {{selectedSchedule.length}} slots selected (you can add more)
                        </span>

                      </div>

                    </div>
                  </div>
                  <div class="p-field p-col-12 p-md-12">
                    <div class="row">
                      <div class="p-md-2" style="height:100px">
                        <div class="alert alert-custom" role="alert" style="box-shadow: none!important; width:90px;height:100px">
                          <div class="custom-alert-icon" [ngClass]="submitted ? 'icon-success' : 'icon-transparent'">
                            <i class="fa-solid"  [ngClass]="submitted ? 'fa-check' : 'fa-4'"></i></div>

                        </div>
                      </div>
                      <div class="p-md-9" style="margin-left:25px;padding-top:28px;">

                        <p-button *ngIf="!selectedSchedule.length" label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn" [disabled]="true"></p-button>
                        <p-button *ngIf="selectedSchedule.length" label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn" [disabled]="false"></p-button>

                      </div>

                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="p-md-8-5 card-cpa-rounded card-shadow" style="background: white;margin-left:30px" >
              <div style="margin:30px">
                <full-calendar #calendar [options]="calendarOptions"></full-calendar>
              </div>


            </div>
          </div>


        </section>
    </div>
</section>
