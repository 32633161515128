import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ChatService} from '../services/chat/chat.service';
import {Router} from '@angular/router';
import {CollaborationSharedServiceService} from '../services/collaboration-shared-service/collaboration-shared-service.service';
import {CookieService} from 'ngx-cookie-service';
import {CollabSessionService} from '../services/session/collab-session.service';
import {Message} from '../utilities/model/chat';
import {closeDiv} from '../common/common';
import {Task} from '../utilities/model/task';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationService} from 'primeng/api';
import {SessionService} from '../../services/session.service';

@Component({
               selector: 'app-message-box',
               templateUrl: './message-box.component.html',
               styleUrls: ['./message-box.component.css']
           })
export class MessageBoxComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('scrollMe') private myScrollContainer: ElementRef;

    @Output() destroyMessageBoxComponent = new EventEmitter<any>();
    @Output() chatDeleted = new EventEmitter<any>();
    @Output() onLeaveGroup = new EventEmitter<any>();
    @Output() openGroupParticipants = new EventEmitter<any>();
    @Output() emitNewChat = new EventEmitter<any>();

    @Output() updateLastMessage = new EventEmitter<any>();

    @Input() changing: Subject<boolean>;
    @Input() selectedChat;

    // messageBox: Object;
    messages = [];
    messagesinservice = [];
    previousMessages = [];
    response;
    singleChat;
    status;
    username;
    chatId;
    groupId;
    secondUserId;
    loggedInUser;
    messageModel: any;
    errorMessage;
    task = '';
    timerSubscription;
    timeStamp = '';
    loadMoreButton = false;
    messageContent: string = '';
    showLoadMoreButton = true;
    submitted = false;
    // userDetail: any ;
    public pageControlMap = new Map<string, boolean>().set('ViewGroupParticipant', false).set('SendTextAsTask', false);
    userRoles: string[];

    //------------------------- Constructor ----------------------------------

    constructor(
        private _webService: ChatService,
        private sessionService: SessionService,
        private router: Router,
        public sharedService: CollaborationSharedServiceService,
        private cookieService: CookieService,
        private dialog: MatDialog,
        public collabSession: CollabSessionService,
        private confirmation: ConfirmationService
    ) {
        if (this.messages.length < 10) {          //Hiding and Showing Load More Button
            this.showLoadMoreButton = false;
        } else {
            this.showLoadMoreButton = true;
        }

        this.messageModel = new Message(this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'Normal', 0);
    }

    //-------------------------------OnInit-------------------------------------
    applicationShortName = 'ums';

    ngOnInit() {
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
        // this.userDetail = sessionStorage.getItem('loggedInUser');
        // this.userDetail = this.sessionService.getUserDetails();
        // console.log(this.userDetail);
        if (this.selectedChat) {
            this.selectedChat.messageCount = 0;
            // this.checkApplicationShortNameAndAssign();
            // this.assignUserRolesAndPageControlMap();
            // this.loggedInUser = sessionStorage.getItem('loggedInUser');
            this.chatId = this.collabSession.chatId; // Getting ChatId to display Messages
            this.groupId = this.collabSession.groupId; // Getting GroupId
            this.secondUserId = this.collabSession.secondUserId; //  Getting secondUserId
            this.messageModel = new Message(
                this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'Normal', 0);
            this.sharedService.messageModel = this.messageModel;
            this.singleChat = this.collabSession.isSingleChat;
            this.status = this.collabSession.status;
            console.log('collab session : ', this.collabSession);
            if (this.selectedChat.cbGroupData == null) {
                // this.username = this.collabSession.lastName;
                this.username = this.collabSession.firstName + ' ' + this.collabSession.lastName;
                // this.username = this.selectedChat.user.firstName + ' ' + this.selectedChat.user.lastName;
                console.log('called message box');
            } else {
                this.username = this.collabSession.username;
                // this.username = this.collabSession.firstName + ' ' + this.collabSession.lastName;
                // this.username = this.selectedChat.user.firstName + ' ' + this.selectedChat.user.lastName;
            }

            // console.log("collabration session ", this.collabSession);

            this.getAllMessages('');
        } else {
            // this.loggedInUser = this.userDetail.userId; // Getting LoggedIn User
            this.chatId = this.collabSession.chatId; // Getting ChatId to display Messages
            this.groupId = this.collabSession.groupId; // Getting GroupId
            this.secondUserId = this.collabSession.secondUserId; //  Getting secondUserId
            this.messageModel = new Message(
                this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'Normal', 0);
            this.sharedService.messageModel = this.messageModel;
            //this.sharedService.Print(this.messageModel);

            this.singleChat = this.collabSession.isSingleChat;
            this.status = this.collabSession.status;
            // this.username = this.collabSession.username;
            this.username = this.selectedChat.secondUser.firstName + ' ' + this.selectedChat.secondUser.lastName;
        }
        // this.checkVersion()

    }

    currentVersion = 0;
    // checkVersion() {
    //   this.sharedService.CurrentVersion.subscribe(data => {
    //     this.currentVersion = data;
    //   });
    // }

    // checkApplicationShortNameAndAssign() {
    //   if (this.globalSharedService.getCookie('applicationShortName')) {
    //     this.applicationShortName = this.globalSharedService.getCookie('applicationShortName')
    //   }
    // }

    // assignUserRolesAndPageControlMap() {
    //   if (this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') && this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') != undefined) {
    //     this.userRoles = this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles');
    //     this.pageControlMap = this.globalSharedService.checkRole(this.pageControlMap, this.userRoles);
    //   }
    // }

    //--------------------------After View Checked-------------------------------

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    //-----------------Checking Change In Selected Chat And User-----------------

    // ngOnChanges() {
    //   this.singleChat = this.collabSession.isSingleChat;
    //   this.status = this.collabSession.status;
    //   this.username = this.collabSession.username;

    // }

    onTaskTypeChange(event) {
        // console.log(event)
        // console.log(this.messageModel.task_type)
        if (this.messageModel.task_type == 'Normal') {
            this.messageModel.task = false;
        } else {
            this.messageModel.task = true;
        }
        // console.log(this.messageModel);
    }

    ngOnChanges() {
        // if (this.selectedChat) {
        //   console.log(this.selectedChat);
        //   this.checkApplicationShortNameAndAssign();
        //   this.assignUserRolesAndPageControlMap();
        //   // this.diagramRef.nativeElement.style.display='block';

        //   //this.sharedService.Print("Visiting Messages onInit () ");
        //   this.loggedInUser = this.globalSharedService.getCookie('loggedInUser'); // Getting LoggedIn User
        //   this.chatId = this.collabSession.chatId; // Getting ChatId to display Messages
        //   this.groupId = this.collabSession.groupId; // Getting GroupId
        //   this.secondUserId = this.collabSession.secondUserId; //  Getting secondUserId
        //   this.messageModel = new Message(this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'General', 0);
        //   this.sharedService.messageModel = this.messageModel;
        //   //this.sharedService.Print(this.messageModel);

        //   this.singleChat = this.collabSession.isSingleChat;
        //   this.status = this.collabSession.status;
        //   this.username = this.collabSession.username;
        //   console.log("user", this.username);

        //   this.getAllMessages("");
        // }
        // else {
        //   this.loggedInUser = this.globalSharedService.getCookie('loggedInUser'); // Getting LoggedIn User
        //   this.chatId = this.collabSession.chatId; // Getting ChatId to display Messages
        //   this.groupId = this.collabSession.groupId; // Getting GroupId
        //   this.secondUserId = this.collabSession.secondUserId; //  Getting secondUserId
        //   this.messageModel = new Message(this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'General', 0);
        //   this.sharedService.messageModel = this.messageModel;
        //   //this.sharedService.Print(this.messageModel);

        //   this.singleChat = this.collabSession.isSingleChat;
        //   this.status = this.collabSession.status;
        //   this.username = this.collabSession.username;
        // }
    }

    leaveGroup(chat) {
        this.onLeaveGroup.emit(chat);
    }

    ngOnDestroy() {

        this.messages = [];
        // this.collabSession.isContexualTab = false;
        closeDiv('chatbox-overlaydiv');

        // console.log("on Destro called");
        setTimeout(() => {
            this.destroyMessageBoxComponent.emit(null);
            this.selectedChat = null;
        }, 10);
    }

    openDeleteDialog() {
        // console.log("delete");
        this.confirmation.confirm({
                                      message: 'Are you sure that you want to Delete ?', accept: () => {
                this.deleteChat();
            }
                                  });

        // const dialogRef = this.dialog.open(DeleteAlertDialog, {
        //   data: {
        //     title: "Delete Subscription",
        //     message: "Are you sure you want to Delete this Chat"
        //   }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   if (result) {
        //     this.deleteChat()
        //   }
        //   else {
        //   }
        // });
    }

    deleteChat(): void {
        this._webService.deleteChat(this.selectedChat.chatId,parseInt(sessionStorage.getItem('loggedInUser'))).subscribe(
            data => {
                this.sharedService.Print(data);
                if (data && data['statusCode'] == 200) {
                    this.chatDeleted.emit(this.selectedChat);
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    //-------------------------Getting All Messages-------------------------------

    getAllMessages(timestamp?: any): void {
        if (this.timeStamp = '') {
            this.timeStamp = '';
        }
        this._webService.getMessages(this.messageModel, this.timeStamp).subscribe(
            data => {
                try {
                    this.messages = data['data'];
                    this.sharedService.messages = this.messages;
                    this.timeStamp = data['timeStamp'];
                    this.sharedService.messagesTimeStamp = this.timeStamp;

                    if (this.messages) {
                        if (this.messages.length < 10) {          //Hiding and Showing Load More Button
                            this.showLoadMoreButton = false;
                        } else {
                            this.showLoadMoreButton = true;
                        }
                        this.messages.sort((val1, val2) => {
                            return val1.messageId - val2.messageId;
                        });
                    }
                } catch (error) {
                    // this._webService.exceptionHandling(error, "getAllMessages() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
                }
            },
            error => {
                // this.response = error; this.toastr.showCustomErrorAlert(this.response);
                // this._webService.exceptionHandling(error, "getAllMessages() in Collaboration messae").subscribe(data => { this.errorMessage = data });
            }
        );
    }

    //------------------------Getting Messages Again------------------------------

    getMessagesAgain(): void {
        this._webService.getMessages(this.sharedService.messageModel, this.sharedService.messagesTimeStamp).subscribe(
            data => {
                try {
                    this.messagesinservice = data['data'];
                    this.timeStamp = data['timeStamp'];
                    this.sharedService.messagesTimeStamp = this.timeStamp;
                    if (this.messagesinservice) {
                        // console.log(this.messagesinservice.length);
                        if (this.messagesinservice.length) {
                            for (var i = 0; i < this.messagesinservice.length; i++) {
                                var found = false;
                                for (var j = 0; j < this.messages.length; j++) {
                                    if (this.messagesinservice[i].messageId == this.messages[j].messageId) {
                                        found = true;
                                        break;
                                    }
                                }
                                if (found == true) {
                                } else {
                                    this.sharedService.messages.push(this.messagesinservice[i]);
                                    this.messages = this.sharedService.messages;
                                    this.messages.sort((val1, val2) => {
                                        return val1.messageId - val2.messageId;
                                    });
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                    // this._webService.exceptionHandling(error, "getMessagesAgain() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
                }

            },
            // this.sharedService.Print(this.messagesinservice) },
            error => {
                // this.response = error; this.toastr.showCustomErrorAlert(this.response);
                // this._webService.exceptionHandling(error, "getMessagesAgain() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
            }
        );

    }

    //-------------------------Load Previous Messages-----------------------------

    getPreviousMessages(): void {
        var lowest = this.findLowestMessageId(); // returning Lowest MessageId
        this._webService.getpreviousMessages(this.messageModel, lowest).subscribe(
            data => {
                try {
                    this.previousMessages = data['data'];
                    if (this.previousMessages) {   //Checking If Previous Messages Are There ? then do this
                        for (var i = 0; i < this.previousMessages.length; i++) {
                            this.messages.push(this.previousMessages[i]);
                        }
                        this.messages.sort((val1, val2) => {
                            return val1.messageId - val2.messageId;
                        });
                    } else {
                        // this.toastr.showCustomInfoAlert("Sorry - No More Messages ! ");
                        // alert("Sorry - No More Messages ! ");
                    }
                } catch (error) {
                    console.log(error);
                    // this._webService.exceptionHandling(error, "gePreviousMessages() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
                }
            },
            error => {
                // this.response = error; this.toastr.showCustomErrorAlert(this.response);
                // this._webService.exceptionHandling(error, "getPreviousMessages() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
            }
        );
    }

    //----------------------------Insert Messages---------------------------------

    insertMessages(): void {
        // console.log("insert message");

        var contextId = 0;
        this.submitted = true;
        if (this.collabSession.isContexualTab) {

            if (this.collabSession.contextId > 0) {
                contextId = this.collabSession.contextId;
            }
                // if (this.globalSharedService.getCookie('contextId')) {
                //   contextId = parseInt(this.globalSharedService.getCookie('contextId'));
            // }
            else {
                contextId = 0;
            }
        }
        // console.log("contextId :: ", contextId);
        if (this.messageModel.task) {
            var task: Task;
            task = {
                title: this.messageModel.content,
                assignTo: this.messageModel.second_contact_id,
                createdBy: this.messageModel.first_contact_id,
                priority: this.messageModel.task_type,
            };
            // this._webService.addUpdateTask(task).subscribe(data => {
            //   console.log(data)
            //   if (data) {
            //     if (data["statusCode"] == 200) {
            //       this.messageModel.task_id = data['data'][0]['taskId'];
            //       this._webService.insertMessage(this.messageModel, contextId).subscribe(data => {
            //         try {
            //           this.response = data;
            //           // alert(this.response['errorMessage']+","+this.response['errorCode']+","+this.response['documentation']);
            //           // this.sharedService.Print(this.response);
            //           if (this.response['statusCode'] == 200) {
            //             if (this.chatId == 0) {
            //               // console.log("chatId " + this.chatId)
            //               this.chatId = this.response['data'][0]['cbChat']['chatId'];
            //               this.selectedChat = this.response['data'][0]['cbChat'];
            //               console.log(this.selectedChat);
            //               this.emitNewChat.emit(this.selectedChat);
            //             }
            //             else {
            //               this.selectedChat = this.response['data'][0]['cbChat'];
            //               // console.log(this.selectedChat);
            //               // this.emitNewChat.emit(this.selectedChat);
            //             }
            //             if (contextId > 0) {
            //               //this.sharedService.Print("with");
            //               var res;
            //               let arr: number[] = [];
            //               arr.push(parseInt(this.secondUserId));
            //               var currentTime = new Date();
            //               var username = this.cookieService.get("userName");
            //               if (this.collabSession.contextTitle != "") {
            //                 var contextTitle = this.collabSession.contextTitle;
            //               }
            //               // if (this.globalSharedService.getCookie('contextTitle')) {
            //               //   var contextTitle = this.globalSharedService.getCookie('contextTitle');
            //               // }
            //               else { contextTitle = '' }
            //               var JSONObject = this.sharedService.makeJson(username, "ContexualMessageReceived", contextTitle, "when New Message Received in contexual Chat", currentTime, 0);
            //               //this.sharedService.Print(username +" "+ currentTime + " " +JSONObject+" "+this.loggedInUser +" "+ this.sharedService.messageReceived);
            //               this._webService.registerEvent(contextId, this.sharedService.applicationId, parseInt(this.loggedInUser), arr, this.sharedService.contextualMessageReceived, JSONObject).subscribe(data => {
            //                 res = data;
            //                 // this.sharedService.Print(res)
            //               });
            //             }
            //             else {
            //               var res;
            //               let arr: number[] = [];
            //               arr.push(parseInt(this.secondUserId));
            //               var currentTime = new Date();
            //               var username = this.cookieService.get("userName");
            //               if (this.collabSession.contextTitle != "") {
            //                 var contextTitle = this.collabSession.contextTitle;
            //               }
            //               // if (this.globalSharedService.getCookie('contextTitle')) {
            //               //   var contextTitle = this.globalSharedService.getCookie('contextTitle');
            //               // }
            //               else { contextTitle = '' }
            //               var JSONObject = this.sharedService.makeJson(username, "messageReceived", "", "when New Message Received", currentTime, 0);
            //               this.sharedService.Print(username + " " + currentTime + " " + JSONObject + " " + this.loggedInUser + " " + this.sharedService.messageReceived);
            //               this.sharedService.Print(JSONObject);
            //               try {
            //                 this._webService.registerEvent(0, this.sharedService.applicationId, parseInt(this.loggedInUser), arr, this.sharedService.messageReceived, JSONObject).subscribe(data => {
            //                   res = data;
            //                   this.sharedService.Print(res)
            //                 });
            //               } catch (error) {
            //                 console.log(error);
            //                 // this._webService.exceptionHandling(error, "insertMessage() RegisterEventService in Collaboration message.component").subscribe(data => { this.errorMessage = data });
            //               }
            //             }
            //           }
            //           this.messages = this.messages || [];
            //           this.messages.push(this.response['data'][0]);
            //           this.loadMoreButton = false;
            //           this.messageModel = new Message(this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'Normal', 0);
            //         } catch (error) {
            //           console.log(error);
            //           // this._webService.exceptionHandling(error, "insertMessage() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
            //         }
            //       },
            //         error => {
            //           this.response = error;
            //           console.log(error)
            //           // this._webService.exceptionHandling(error, "insertMessage() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
            //           // this.sharedService.Print(this.response);
            //         });
            //     }
            //   }
            // });

        } else {
            this._webService.insertMessage(this.messageModel, contextId).subscribe(
                data => {
                    try {
                        this.response = data;
                        // alert(this.response['errorMessage']+","+this.response['errorCode']+","+this.response['documentation']);
                        // this.sharedService.Print(this.response);
                        if (this.response['statusCode'] == 200) {
                            if (this.chatId == 0) {
                                // console.log("chatId " + this.chatId)
                                this.chatId = this.response['data'][0]['cbChat']['chatId'];
                                this.selectedChat = this.response['data'][0]['cbChat'];
                                // console.log(this.selectedChat);
                                this.emitNewChat.emit(this.selectedChat);
                            } else {
                                this.selectedChat = this.response['data'][0]['cbChat'];
                                this.updateLastMessage.emit(this.selectedChat);
                                // console.log(this.selectedChat);
                                // this.emitNewChat.emit(this.selectedChat);
                            }
                            if (contextId > 0) {
                                //this.sharedService.Print("with");
                                var res;
                                let arr: number[] = [];
                                arr.push(parseInt(this.secondUserId));
                                var currentTime = new Date();
                                var username = this.cookieService.get('userName');
                                if (this.collabSession.contextTitle != '') {
                                    var contextTitle = this.collabSession.contextTitle;
                                }
                                    // if (this.globalSharedService.getCookie('contextTitle')) {
                                    //   var contextTitle = this.globalSharedService.getCookie('contextTitle');
                                // }
                                else {
                                    contextTitle = '';
                                }
                                var JSONObject = this.sharedService.makeJson(
                                    username, 'ContexualMessageReceived', contextTitle, 'when New Message Received in contexual Chat',
                                    currentTime, 0
                                );
                                //this.sharedService.Print(username +" "+ currentTime + " " +JSONObject+" "+this.loggedInUser +" "+ this.sharedService.messageReceived);
                                this._webService.registerEvent(
                                    contextId, this.sharedService.applicationId, parseInt(this.loggedInUser), arr,
                                    this.sharedService.contextualMessageReceived, JSONObject
                                ).subscribe(data => {
                                    res = data;
                                    // this.sharedService.Print(res)
                                });
                            } else {
                                var res;
                                let arr: number[] = [];
                                arr.push(parseInt(this.secondUserId));
                                var currentTime = new Date();
                                var username = this.cookieService.get('userName');
                                if (this.collabSession.contextTitle != '') {
                                    var contextTitle = this.collabSession.contextTitle;
                                }
                                    // if (this.globalSharedService.getCookie('contextTitle')) {
                                    //   var contextTitle = this.globalSharedService.getCookie('contextTitle');
                                // }
                                else {
                                    contextTitle = '';
                                }
                                var JSONObject = this.sharedService.makeJson(
                                    username, 'messageReceived', '', 'when New Message Received', currentTime, 0);
                                this.sharedService.Print(
                                    username + ' ' + currentTime + ' ' + JSONObject + ' ' + this.loggedInUser + ' ' + this.sharedService.messageReceived);
                                this.sharedService.Print(JSONObject);
                                try {
                                    this._webService.registerEvent(
                                        0, this.sharedService.applicationId, parseInt(this.loggedInUser), arr,
                                        this.sharedService.messageReceived, JSONObject
                                    ).subscribe(data => {
                                        res = data;
                                        this.sharedService.Print(res);
                                    });
                                } catch (error) {
                                    console.log(error);
                                    // this._webService.exceptionHandling(error, "insertMessage() RegisterEventService in Collaboration message.component").subscribe(data => { this.errorMessage = data });
                                }
                            }
                        }
                        this.messages = this.messages || [];
                        this.messages.push(this.response['data'][0]);
                        this.loadMoreButton = false;
                        this.messageModel = new Message(
                            this.chatId, parseInt(this.loggedInUser), this.secondUserId, this.groupId, '', false, 'Normal', 0);
                        this.submitted = false;
                    } catch (error) {
                        console.log(error);
                        // this._webService.exceptionHandling(error, "insertMessage() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
                    }
                },
                error => {
                    this.response = error;
                    // this._webService.exceptionHandling(error, "insertMessage() in Collaboration message.component").subscribe(data => { this.errorMessage = data });
                    // this.sharedService.Print(this.response);
                }
            );
        }
    }

    checkUserImage(chat) {
        console.log(chat);

        if (chat) {
            if (chat.firstUser.userId == this.loggedInUser) {
                if (chat.secondUser) {
                    return chat.secondUser.profileImage;
                } else {
                    return null;
                }
            } else {
                return chat.firstUser.profileImage;
            }
        }
    }

    selectedMessageId;
    selectedMessage;
    selectedMessageIndex;

    delete(message, messageIndex) {
        this.selectedMessage = message;
        this.selectedMessageId = message.messageId;
        this.selectedMessageIndex = messageIndex;
    }

    deleteMessage(): void {
        this._webService.deleteMessage(this.selectedMessage).subscribe(
            data => {
                this.sharedService.Print(data);
                if (data) {
                    try {
                        if (data['statusCode'] == 200) {
                            this.messages.splice(this.selectedMessageIndex, 1);
                        }
                    } catch (error) {
                        console.log(error);
                        // this._webService.exceptionHandling(error, "deleteMessage() in Collaboration").subscribe(data => { this.errorMessage = data });
                    }
                }
            },
            error => {
                this.errorMessage = error;
                // this._webService.exceptionHandling(error, "deleteMessage()").subscribe(data => { this.errorMessage = data });
            }
        );
    }

    //-------------------------Returning Status Icon Class------------------------

    statusIcon(chat): string {
        if (chat) {
            const current = new Date();
            current.setHours(0);
            current.setMinutes(0);
            current.setSeconds(0);
            current.setMilliseconds(0);
            const currentTime = current.getTime();
            try {
                if (chat.secondUser && chat.secondUser.userId == this.loggedInUser) {
                    if (chat.firstUser.userLog != null) {
                        if (chat.firstUser.status == 'online' && chat.firstUser.userLog.tokenExpiry > currentTime) {
                            return 'online_icon';
                        } else {
                            return 'user-status is-offline';
                        }
                    } else {
                        return 'user-status is-offline';
                    }
                } else {
                    if (chat.secondUser && chat.secondUser.userLog != null) {
                        if (chat.secondUser.status == 'online' && chat.secondUser.userLog.tokenExpiry > currentTime) {
                            return 'online_icon';
                        } else {
                            return 'user-status is-offline';
                        }
                    } else {
                        return 'user-status is-offline';
                    }
                }
            } catch (error) {
                console.log(error);

            }
        }
        return '';
    }

    checkUserStatus() {
        if (this.status && this.status == 'online') {
            return 'online_icon';
        } else {
            return '';
        }
    }


    checkParticipantStatus(messageSender) {
        if (messageSender.firstContact.status == 'online') {
            return 'online_icon';
        } else {
            return '';
        }

    }

    //-----------------------Message Sender If loggedIn User----------------------

    meSender(message): Boolean {
        if (message.firstContact.userId == this.loggedInUser) {
            return true;
        } else {
            return false;
        }
    }

    //------------------------Message Sender If Other User------------------------

    youSender(message): Boolean {
        if (message.firstContact.userId != this.loggedInUser) {
            return true;
        } else {
            return false;
        }
    }

    //-------------------------Opening participant Modal--------------------------

    openParticipant(): void {
        this.collabSession.isAddParticipantClicked = true;
        this.collabSession.isNewChatButtonClicked = false;
        this.openGroupParticipants.emit(this.selectedChat);
    }

    //--------------------------------Submit Message------------------------------

    onSubmit(): void {
        if (!(typeof this.messageModel.content == 'undefined' || this.messageModel.content === '' || this.messageModel.content == null || this.messageModel.content == '\n')) {

            this.insertMessages();
        } else {
            this.messageModel.content = this.messageModel.content.replace(/(\r\n|\n|\r)/gm, '');
        }
    }

    //------------------------------Displaying Task------------------------------

    showTask(): void {
        // var applicationUrl = this.globalSharedService.getApplicationFrontEndUrl(AppSettings.TMS_APPLICATION_ID) + '/home/taskmanager';
        // this.utilityService.updateCurrentRole(applicationUrl, AppSettings.TMS_APPLICATION_ID);
    }

    createSessionObject(applicationId, token) {
        var sessionObject = {
            'applicationId': applicationId,
            'token': token
        };
        return sessionObject;
    }

    //---------------------------Scrolling To Bottom on Load-----------------------

    scrollToBottom(): void {
        if (this.loadMoreButton) {
            return;
        }
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) {
        }
    }

    //----------------------------Finding Lowest MessageId-------------------------

    findLowestMessageId(): number {
        try {
            var lowest = Number.POSITIVE_INFINITY;
            var highest = Number.NEGATIVE_INFINITY;
            var lastMessageId;
            for (var i = this.messages.length - 1; i >= 0; i--) {
                lastMessageId = this.messages[i].messageId;
                if (lastMessageId < lowest) {
                    lowest = lastMessageId;
                }
                if (lastMessageId > highest) {
                    highest = lastMessageId;
                }
            }
        } catch (error) {
            console.log(error);
        }
        return lowest;
    }

    //---------------------onScroll whe new Messages Are Loaded --------------------

    onScroll() {
        let element = this.myScrollContainer.nativeElement;
        let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
        if (this.loadMoreButton && atBottom) {
            this.loadMoreButton = false;
        } else {
            this.loadMoreButton = true;
        }
    }

    verifyLabel(label) {
        return label;
    }

    // updateCurrentRole(applicationUrl, category_url, application_shortName) {
    //   var activatedRole = parseInt(this.cookieService.get('activatedRole'));
    //   var projectId = JSON.parse(this.globalSharedService.getCookie("project"))['projectId'];
    //   if ((activatedRole) && (projectId)) { }
    //   else {
    //     activatedRole = 0;
    //     projectId = 0;
    //   }
    //   this.nonInterceptService.updateCurrentRole(activatedRole, 0, AppSettings.TMS_APPLICATION_ID).subscribe(data => {
    //     if (data) {
    //       this.globalSharedService.setCookies('token', data['token']);
    //       sessionStorage.setItem(application_shortName + "_roles", data['applicationRole']);
    //       this.globalSharedService.setCookies("expirationDate", data['expirationDate']);
    //       var url = window.location.href;
    //       if (url.includes('tms')) {
    //         this.router.navigate(['/taskmanager']);
    //       }
    //       else {
    //         window.location.assign('https://' + window.location.host + '/' + 'tms/taskmanager');
    //       }
    //     }
    //   }, error => {
    //     console.log(error);
    //   })
    // }

}
