<!-- Header Component-->

<!-- <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog> -->
<!-- Notifications Page-->
<section class="sectionmain" id="sectionmain-notificationspage">
    <section class="sectioncontent" id="sectionmain-notificationspage-sectioncontent">
        <div class="container">
            <p-panel class="pngPanel" id="invitationpage-notificationspage-pngPanel">

                <p-header>
                    <i class="far fa-bell" style="margin-right:5px"></i> Notifications
                    <div class="notificationMenuDiv" id="notificationspage-notificationMenuDiv">
                        <button class="dropdownMenuPrimengbtn" id="dropdownMenuPrimengbtn-notificationspage"
                                type="button" pButton
                                icon="pi pi-ellipsis-v" id="dropdownMenuPrimengbtn-suitesoption"
                                (click)="lobbypageslideroptionsmenu.toggle($event)"></button>
                        <p-menu #lobbypageslideroptionsmenu [popup]="true" [model]="notificationspagemenulist"
                                id="notificationspagemenulist-dropdownMenuPrimeng"
                                styleClass="dropdownMenuPrimeng notificationspagemenulist"></p-menu>
                    </div>
                </p-header>

                <!-- <div class="pngbtnsdiv" id="notificationspage-pngbtndiv">
                  <span class="p-input-icon-right" id="notificationspage-globalsearch">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" placeholder="Global Search" />
                  </span>
                  <button pButton type="button" (click)="notificationsListAdvSearchDialog()" id="notificationspage-advsearch"
                    class="pngbtn btnprimaryblue-outline" label="Advanced Search"></button>
                </div> -->


                <!-- <p-table [value]="notifications" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [paginator]="notifications.length>0" [resizableColumns]="true" [autoLayout]="true"
                  id="notificationslist-table"  [rowsPerPageOptions]="[10,20,30]"   [showCurrentPageReport]="true"  styleClass="p-datatable-striped" [rows]="10">
                  -->

                <p-table [value]="notifications" [lazy]="true" (onLazyLoad)="onPageChnage($event)"
                         [resizableColumns]="true"
                         [paginator]="notifications.length > 0" [rows]="rows" [autoLayout]="true"
                         [totalRecords]="totalNotificationsCount" [alwaysShowPaginator]="false"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                         [showCurrentPageReport]="true">

                    <ng-template pTemplate="header">
                        <tr>
                            <th>Representative</th>
                            <th>Details</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-notificationslist let-i="rowIndex">
                        <tr [class]="notificationslist.status=='read'? 'abc': 'markasUnread'">
                            <td>
                                <div class="usernameimagediv-sm" id="notificationspage-usernameimagediv-sm">
                  <span class="userimg" id="notificationspage-userimg">
                    <img [src]="notificationslist?.fromUser?.userProfilePicture"
                         (error)="notificationslist.fromUser.userProfilePicture='assets/theme1/custom-files/img/default_thumb/profilepic.png'"
                         alt=""/>
                    <span>
                    </span>
                  </span>
                                    <h5 class="notificationslist-username" id="notificationspage-username">
                                        {{notificationslist?.fromUser.username}}</h5>
                                </div>
                            </td>
                            <td [pTooltip]="notificationslist?.template" tooltipPosition="bottom"
                                class="maxwidthTableTd"
                                (click)="markAsRead(notificationslist,(i))">{{notificationslist?.template}}</td>
                            <td>
                                {{notificationslist.insertionTimestamp | date}}
                            </td>
                            <td>
                                {{notificationslist.insertionTimestamp | date:'shortTime'}}
                            </td>
                            <td>
                                <div class="rowbtnstable" id="notificationslist-rowbtntable">
                                    <button pButton type="button" icon="pi pi-trash"
                                            (click)="confirmationDeleteDialog(notificationslist,i)"
                                            class="p-button-rounded p-button-text p-button-plain"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6"> No data found</td>
                        </tr>
                    </ng-template>

                </p-table>
            </p-panel>


        </div>
    </section>
</section>

<!-- notificationspage Advance Search DialogBox-->
<p-dialog header="Advanced Search" [(visible)]="notificationsadvancedsearchpopup" [modal]="true"
          class="pngDialog pngDialogSm" id="notificationspage-pngDialog-advsearchh">
    <form class="formContainer" id="notificationspage-formContainer">
        <div class="p-fluid p-formgrid p-grid" id="notificationspage-pngDialog-pgrid">

            <div class="p-field p-col-12 formfieldheight invalidfield" id="notificationspage-pngDialog-Name">
                <label>Search by Name</label>
                <input type="text" pInputText/>
                <small>Error Message</small>
            </div>
            <div class="p-field p-col-12 formfieldheight" id="notificationspage-pngDialog-Details">
                <label>Search by Details</label>
                <input type="text" pInputText/>
                <small>Error Message</small>
            </div>
            <div class="p-field p-col-12 formfieldheight" id="notificationspage-stDate">
                <label>Start Date</label>
                <p-calendar appendTo="body" [showIcon]="true" class="p-invalid" inputId="buttonbar" inputId="icon"
                            dateFormat="dd.mm.yy">
                </p-calendar>
                <small>Error Message</small>
            </div>
            <div class="p-field p-col-12 formfieldheight" id="notificationspage-endDate">
                <label>End Date</label>
                <p-calendar appendTo="body" [showIcon]="true" class="p-invalid" inputId="buttonbar" inputId="icon"
                            dateFormat="dd.mm.yy">
                </p-calendar>
                <small>Error Message</small>
            </div>

        </div>
    </form>
    <ng-template pTemplate="footer" class="pngDialog-footer" id="notificationspage-pngDialog-footerr">
        <div class="pngbtnsdiv" id="notificationspage-pngDialog-pngbtndivv">
            <button pButton type="button" (click)="notificationsadvancedsearchpopup=false" class="pngbtn btnprimaryblue btnblock"
                    label="Search"></button>
        </div>
    </ng-template>
</p-dialog>
<!-- notificationspage Advance Search DialogBox-->


<!-- Confirmation Delete DIALOG-->
<p-dialog header="Delete Confirmation" [(visible)]="confirmationdeletepopup"
          class="pngDialog pngDialogSm deleteconfirmationDialog" id="notificationspage-pngDialog-advsearch">
    <div class="confirmationcontent">
    <span class="p-confirm-dialog-message">Are you sure you want to delete <span> Jessica Jordan </span> from
      notifications
      list?</span>
    </div>
    <ng-template pTemplate="footer" class="pngDialog-footer" id="notificationspage-pngDialog-footer">
        <div class="pngbtnsdiv" id="notificationspage-pngDialog-pngbtndiv">
            <button pButton type="button" (click)="confirmationdeletepopup=false" class="pngbtn btnprimaryblue-outline"
                    label="Yes"></button>
            <button pButton type="button" (click)="confirmationdeletepopup=false" class="pngbtn btnprimaryblue"
                    label="No"></button>
        </div>
    </ng-template>
</p-dialog>


<!-- END Confirmation Delete DIALOG-->