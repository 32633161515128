<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deletePMH" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!--<ngx-ui-loader></ngx-ui-loader>-->
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
  <div class="container card-cpa-rounded card-shadow">
        <section>
            <div class="overlaypaneldivContainer">
                <div class="overlaypaneldiv">
                    <p-panel class="pngPanelMain">
                        <p-header> {{translate(labels.entityNamePastMedicalHistory)}}
                            <div class="pngbtnsdiv" >
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNamePastMedicalHistory)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePastMedicalHistory)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="pastMedicalHistorySearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">

<!--                                        <div class="p-field p-col-12  p-md-6 formfieldheight">-->
<!--                                            <label>{{translate(labels.pastMedicalHistoryColumnSpecialQuestions)}} </label>-->
<!--                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"-->
<!--                                                        [showClear]="true"-->
<!--                                                        class="pngDropdown" formControlName="specialQuestions"-->
<!--                                                        placeholder="Select"></p-dropdown>-->
<!--                                        </div>-->


                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">

                                <form (ngSubmit)="onSubmit()" [formGroup]="pastMedicalHistoryForm" class="pngForm">
                                    <div class="p-fluid p-formgrid p-grid">
                                            <!-- Special Questions -->
                                            <div class="p-field p-col-12  p-md-6 formfieldheight">
                                                <app-lov-details label="{{multiSelects[0].label}}" [categoryString]="multiSelects[0].name" [dataObj]="lovDataObject"
                                                                 (getResponse)="getResponse($event)"></app-lov-details>
                                              <span  id="#previousMskError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                            </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details label="{{multiSelects[1].label}}" [categoryString]="multiSelects[1].name" [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#bloodsError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>

                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details label="{{multiSelects[2].label}}" [categoryString]="multiSelects[2].name" [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#imagingError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details label="{{multiSelects[3].label}}" [categoryString]="multiSelects[3].name" [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#systemicError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details label="{{multiSelects[4].label}}" [categoryString]="multiSelects[4].name" [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#illnessError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>

                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details label="{{multiSelects[5].label}}" [categoryString]="multiSelects[5].name" [dataObj]="lovDataObject"
                                                           (getResponse)="getResponse($event)"></app-lov-details>
                                          <span  id="#threadsError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                         <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                                                        placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                                        type="text"/></span></p-header>
                                <p-table #dv *ngIf="pastMedicalHistoryList.length > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="pastMedicalHistoryList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': pastMedicalHistoryList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped" dataKey="insertionTimestamp">
                                    <ng-template pTemplate="header">
                                        <tr>
                                          <th></th>
                                            <th> Date</th>
                                            <th> Bloods</th>
                                            <th> Imaging</th>
                                            <th> Systemic</th>
                                            <th> Illness</th>
                                            <th> Threads</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                  <td>

                                  </td>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body" let-expanded="expanded">
                                        <tr>
                                          <td>
                                            <button type="button" pButton pRipple [pRowToggler]="element" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                          </td>

                                            <td> {{element?.insertionTimestamp | date: 'dd-MM-yyyy'}}  </td>
                                            <td> {{element?.previousMsk[0].lovValues}} </td>
                                            <td> {{element?.bloods[0].lovValues}} </td>
                                            <td> {{element?.imaging[0].lovValues}} </td>
                                            <td> {{element?.systemic[0].lovValues}} </td>
                                            <td> {{element?.illness[0].lovValues}} </td>
                                            <td> {{element?.threads[0].lovValues}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadPastMedicalHistoryToEdit(element.pastMedicalHistoryId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdPastMedicalHistory(element.pastMedicalHistoryId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                  <ng-template pTemplate="rowexpansion" let-element>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header" style="font-size: 14px;display: inline">Previous Msk: </span>
                                        <span *ngFor="let previousMsk of element.previousMsk; let b = index">
                                          {{previousMsk.lovValues}}  <span class="p-panel-header" style="font-size: 14px;display: inline"><i class="fa-duotone fa-pipe"></i></span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header" style="font-size: 14px"><i class="fa-solid fa-vial fa-2x"> </i>Bloods  </span>
                                        <span *ngFor="let blood of element.bloods; let b = index">
                                          {{blood.lovValues}} <span class="p-panel-header" style="font-size: 14px;display: inline">|</span>
                                        </span>
                      </td>
                      </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header" style="font-size: 14px"><i class="fa-solid fa-x-ray"></i> Imaging</span>
                                        <span *ngFor="let imaging of element.imaging; let c = index">
                                          {{imaging.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header" style="font-size: 14px">Systemic</span>
                                        <span *ngFor="let systemic of element.systemic; let d = index">
                                          {{systemic.lovValues}}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header" style="font-size: 14px">Illness</span>
                                        <span *ngFor="let illness of element.illness; let e = index">
                                          {{illness.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8">
                                        <span class="p-panel-header" style="font-size: 14px">Threads</span>
                                        <span *ngFor="let threads of element.threads; let f = index">
                                          {{threads.lovValues}}
                                        </span>
                                      </td>
                                    </tr>
                      </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>


                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
