<div class="collaboration-wrapper" id="sso-collaboration-wrapper">

    <mat-expansion-panel class="angExpensionpanel" id="sso-collaboration-angExpensionpanel">
        <mat-expansion-panel-header class="angExpensionpanelheader" id="sso-collaboration-angExpensionpanelheader">

            <mat-panel-title id="sso-collaboration-angExpensionpaneltitle">
                <i class="chat-icon"></i>
                {{verifyLabel('Collaboration')}}
            </mat-panel-title>

        </mat-expansion-panel-header>

<!--         <iframe id="rcChannel" name="rcChannel" style="width: 100%;height:100%;" src="http://192.168.2.42:3000/home"></iframe>-->
<!--         <iframe id="rcChannel" name="rcChannel" style="width: 100%;height:100%;" src="localhost:4200"></iframe>-->

        <app-message-box *ngIf="loggedInUser && showMessageBoxComponent"
                         (emitNewChat)="emitNewChat($event)"
                         (updateLastMessage)="updateLastMessage($event)"
                         (onLeaveGroup)="onLeaveGroup($event)"
                         (destroyMessageBoxComponent)="destroyMessageBoxComponent($event)"
                         (chatDeleted)="ChatDeleted($event)"
                         (openGroupParticipants)="openGroupParticipants($event)"
                         [selectedChat]="selectedChat">
        </app-message-box>


        <app-usersCollab (afterGroupCreated)="afterGroupCreated($event)" (afterParticipantAdded)="closeParticipantComponent()"
                   (moveToGroupParticipantScreen)="moveToGroupParticipantScreen()"
                   (emitSelectedUser)="onEmittedSelectedUser($event)"
                   (emitUsersClose)="showUsers()" *ngIf="loggedInUser && showUsersComponent">
        </app-usersCollab>


        <app-participant (chatDeleted)=ChatDeleted($event) (closeParticipantComponent)="closeParticipantComponent()"
                         (afterGroupUpdated)="afterGroupUpdated($event)"
                         (goToUserComponentToAddParticipants)="goToUserComponentToAddParticipants($event)"
                         [selectedChat]="selectedChat"
                         *ngIf="loggedInUser && showGroupParticipantComponent"></app-participant>


        <!-- assets/theme1/custom-files/img/default_thumb/profilepic.png -->
        <!-- <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChanged($event);"> -->
        <mat-tab-group animationDuration="0ms" [disableRipple]="true">
            <mat-tab label="General">


                <div class="contacts_body" id="contacts_body_generaltab">

                    <ul class="contacts">
                        <ng-container *ngFor="let chat of filteredChats; let i= index">
                            <li class="contact_user">
                                <div class="contact_user_content">
                                    <div class="img_cont">
                                        <ng-container *ngIf="checkStatus(chat)">
                                            <img [src]="checkUserImage(chat)"
                                                 onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/profilepic.png'"
                                                 class="rounded-circle user_img">
                                            <span [class]="statusIcon(chat)"></span>
                                        </ng-container>
                                        <ng-container *ngIf="!checkStatus(chat)">
                                            <img src="assets/theme1/custom-files/img/default_thumb/groupImage.png"
                                                 onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/groupImage.png'"
                                                 class="rounded-circle user_img">
                                        </ng-container>
                                    </div>
                                    <div class="user_info">
                                        <div class="user_info_leftdiv">
                                            <a (click)="onSelect(chat)">
                                                <span class="username">{{ chatCheck(chat) | titlecase }}</span>
                                            </a>
                                            <p> {{chat.lastMessageContent}}</p>
                                        </div>
                                        <div class="user_info_rightdiv">
                                            <span *ngIf="chat.lastUpdateByUser!=loggedInUser && chat.lastMessageReadStatus=='unread'"
                                                    class="messageunread-badge"></span>
                                             <span *ngIf="chat.messageCount>0 && chat.lastMessageReadStatus=='unread'"
                                                   class="messageunread-badge"></span>
                                            <span class="user_info_datetime"> {{chat.insertionTimestamp | date:'short'}} </span>
                                            <ng-container *ngIf="chat.cbGroupData">
<!--                                                <div class="icongroup" id="collaboration-icongroupp">-->
<!--                                                    <button class="btnxs-transparent-icon" title="Leave group"-->
<!--                                                            (click)="leaveGroup(chat, i)">-->
<!--                                                        <i class="leavechat-icon" title="Leave Chat"></i>-->
<!--                                                    </button>-->

<!--                                                    <button class="btnxs-transparent-icon" (click)="editGroup(chat)">-->
<!--                                                        <i class="edit-icon" title="Edit Chat"></i>-->
<!--                                                    </button>-->
<!--                                                </div>-->

                                                <button mat-icon-button class="matDropdownmeubtn"
                                                  id="chatlistboxgendercontxtual-matDropdownmeubtn22"
                                                  [matMenuTriggerFor]="chatgenconextualdropdownmenu">
                                                  <i class="vertical3dotsmenu-icon"></i>
                                                </button>
                                                <mat-menu #chatgenconextualdropdownmenu="matMenu" class="angDropdownmenu" xPosition="before">
                                                  <button *ngIf="chat.cbGroupData" (click)="leaveGroup(chat, i)" mat-menu-item>
                                                    <i class="leavechat-icon"></i>
                                                    <span class="dropdownmenu-text"> {{verifyLabel('Leave Chat')}} </span>
                                                  </button>
                                                  <button *ngIf="chat.cbGroupData" (click)="editGroup(chat)" mat-menu-item>
                                                    <i class="edit-icon"></i>
                                                    <span class="dropdownmenu-text"> {{verifyLabel('Edit Group')}} </span>
                                                  </button>
                                                </mat-menu>
                                            </ng-container>
                                            <ng-container *ngIf="!chat.cbGroupData">
<!--                                                <div class="icongroup" id="collaboration-icongroup">-->
<!--                                                    <button class="btnxs-transparent-icon"-->
<!--                                                            (click)="deleteChat(chat , i)">-->
<!--                                                        <i class="hidedetails-icon" title="Hide chat"></i>-->
<!--                                                        &lt;!&ndash; <span class="dropdownmenu-text"> {{verifyLabel('Hide Chat')}} </span> &ndash;&gt;-->
<!--                                                    </button>-->
<!--                                                </div>-->
                                                <button mat-icon-button class="matDropdownmeubtn"
                                                  id="chatlistboxgendercontxtual-matDropdownmeubtn121321"
                                                  [matMenuTriggerFor]="chatgenconextualdropdownmenu">
                                                  <i class="vertical3dotsmenu-icon"></i>
                                                </button>
                                                <mat-menu #chatgenconextualdropdownmenu="matMenu" class="angDropdownmenu" xPosition="before">
                                                  <button (click)="openDeleteDialog(chat , i)" mat-menu-item>
                                                    <i class="deletechat-icon"></i>
                                                    <span class="dropdownmenu-text"> {{verifyLabel('Hide Chat')}} </span>
                                                  </button>
                                                </mat-menu>
                                            </ng-container>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </mat-tab>

            <mat-tab label="Contextual">
              <div class="contacts_body" id="contacts_body_contextualtab">
                <h5 class="contextual-heading">{{contextTitle}}</h5>
                <ul class="contacts">
                  <ng-container *ngFor="let chat of filteredContexualChats; let i= index">
                    <li class="contact_user">
                      <div class="contact_user_content">
                        <div class="img_cont">
                          <ng-container *ngIf="checkStatus(chat)">
                            <img src="checkUserImage(chat)"
                              onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/profilepic.png'"
                              class="rounded-circle user_img">
                            <span [class]="statusIcon(chat)"></span>
                          </ng-container>
                          <ng-container *ngIf="!checkStatus(chat)">
                            <img src="ums/assets/theme1/custom-files/img/default_thumb/groupImage.png"
                              onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/groupImage.png'"
                              class="rounded-circle user_img">
                          </ng-container>
                        </div>
                        <div class="user_info">
                          <div class="user_info_leftdiv">
                            <a (click)="onSelect(chat)">
                              <span class="username">{{ chatCheck(chat) | titlecase }}</span>
                            </a>
                            <p>{{chat.lastMessageContent}}</p>
                          </div>

                          <div class="user_info_rightdiv">
                            <span *ngIf="chat.messageCount>0" class="messageunread-badge">{{chat.messageCount}}</span>
                            <span class="user_info_datetime"> {{chat.insertionTimestamp | date:'short'}}</span>
                            <ng-container *ngIf="chat.cbGroupData">
                              <button mat-icon-button class="matDropdownmeubtn"
                                id="chatlistboxgendercontxtual-matDropdownmeubtn1"
                                [matMenuTriggerFor]="chatgenconextualdropdownmenu">
                                <i class="vertical3dotsmenu-icon"></i>
                              </button>
                              <mat-menu #chatgenconextualdropdownmenu="matMenu" class="angDropdownmenu" xPosition="before">
                                <button *ngIf="chat.cbGroupData" (click)="leaveGroup(chat, i)" mat-menu-item>
                                  <i class="leavechat-icon"></i>
                                  <span class="dropdownmenu-text"> {{verifyLabel('Leave Chat')}} </span>
                                </button>
                                <button *ngIf="chat.cbGroupData" (click)="editGroup(chat)" mat-menu-item>
                                  <i class="edit-icon"></i>
                                  <span class="dropdownmenu-text"> {{verifyLabel('Edit Group')}} </span>
                                </button>
                              </mat-menu>
                            </ng-container>

                            <ng-container *ngIf="!chat.cbGroupData">
                              <button mat-icon-button class="matDropdownmeubtn"
                                id="chatlistboxgendercontxtual-matDropdownmeubtn"
                                [matMenuTriggerFor]="chatgenconextualdropdownmenu">
                                <i class="vertical3dotsmenu-icon"></i>
                              </button>
                              <mat-menu #chatgenconextualdropdownmenu="matMenu" class="angDropdownmenu" xPosition="before">
                                <button (click)="openDeleteDialog(chat , i)" mat-menu-item>
                                  <i class="deletechat-icon"></i>
                                  <span class="dropdownmenu-text"> {{verifyLabel('Hide Chat')}} </span>
                                </button>
                              </mat-menu>
                            </ng-container>

                          </div>

                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>

            </mat-tab>

            <ng-container>
                <mat-tab disabled>
                    <ng-template mat-tab-label>
                        <ng-container>
                          <div class="newchatoptionsingalorgroup">
                              <button mat-icon-button class="matDropdownmeubtn" id="newchatoptionsingalorgroup-matDropdownmeubtn"
                                [matMenuTriggerFor]="chatgenconextualdropdownmenu">
                                <i class="vertical3dotsmenu-icon"></i>
                              </button>
                              <mat-menu #chatgenconextualdropdownmenu="matMenu" class="angDropdownmenu" xPosition="before">
                                <button (click)="clickedTab('newChat')" mat-menu-item>
                                  <i class="chat-icon"></i>
                                  <span class="dropdownmenu-text"> {{verifyLabel('New Chat')}} </span>
                                </button>
                                <button (click)="clickedTab('groupChat')" mat-menu-item>
                                  <i class="chatgroup-icon"></i>
                                  <span class="dropdownmenu-text"> {{verifyLabel('New Group Chat')}} </span>
                                </button>

                              </mat-menu>
                            </div>

<!--                            <button class="btnxs-transparent-icon" (click)="clickedTab('newChat')">-->
<!--                                <i class="chat-icon" title="New chat" TooltipPosition="bottom"></i>-->
<!--                            </button>-->
<!--                            <button (click)="clickedTab('groupChat')" class="btnxs-transparent-icon">-->
<!--                                <i class="chatgroup-icon" title="Group Chat"></i>-->
<!--                            </button>-->
                        </ng-container>
                    </ng-template>
                </mat-tab>
            </ng-container>

        </mat-tab-group>

    </mat-expansion-panel>
</div>


<!-- <p-confirmDialog width="425" #cd>

</p-confirmDialog> -->