import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommunicationsService } from '../web-services/communications.service'
import {CommunicationsTypeService} from '../web-services/communications-type.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {

  btnStatus: any;
  communicationsType ={}
  communications ={"appointmentsId": parseInt(sessionStorage.getItem("appointmentId")),"expertId":parseInt(sessionStorage.getItem("expertId")),"patientId":parseInt(sessionStorage.getItem("patientId"))}
  comFlag = false;
  communicationObject = {};
  communication = [];
  communicationTypes = [];
  addPopupFlag = false;
  communicationsTypes: any
  typeflag = false;

  constructor(
      public ctService: CommunicationsTypeService,
      public cService: CommunicationsService,
      private messageService: MessageService,
      private loader: NgxUiLoaderService
  )
  { };
  ngOnInit(): void {
    this.btnStatus = 'Active';
    this.findAllComm(this.communications, 0, -1);
    this.findAllCommType(this.communicationsType, 0, -1);
    

    
  }
  // find all communication types
  findAllCommType(comTypeObj, pageNo, itemsPerPage) {
    this.ctService.findAllCommunicationsType(comTypeObj, pageNo,itemsPerPage).subscribe(res => {
      if(res['data']){
        this.communicationTypes = res["data"];
      }

      this.typeflag = true;
    });
  }
// find all communications
  findAllComm(comObj, pageNo, itemsPerPage) {
    this.cService.findAllCommunications(comObj, pageNo, itemsPerPage).subscribe(res => {
      if(res['data']) {
        this.communication = res["data"];
      }
      this.comFlag = true
    })
  }
  // get data for each accordian
  getData(communicationType) {
    var returnArr = this.communication.filter(x => { if (x['communicationsTypeId']['communicationsTypeId'] == communicationType['communicationsTypeId']) { return x } })
    return returnArr;

  }
  //delete a communication
  deleteRow(event) {
    var communicationsId= event["communicationsId"];
    this.cService.deleteByIdCommunications(communicationsId).subscribe(res=>{
      if(res && res ["message"]== "Success"){
        var index = this.communication.indexOf(event);
         this.communication.splice(index,1)
         this.messageService.add({ severity: 'success', summary: 'Deleted Successfully', detail: '' });
      }
    })
  }
 
  addCommunication(event){

    let communication={
      patientId: parseInt(sessionStorage.getItem("patientId")),
      expertId:parseInt(sessionStorage.getItem("expertId")),
      appointmentsId: parseInt(sessionStorage.getItem("appointmentId")),
      title:event['title'],
      address:event['address'],
      communicationsTypeId: parseInt(event['communicationType']),
      content:event['content'],
      description:event['description'],
      sendTo:event['sendTo'],
  }
  this.cService.addCommunications(communication).subscribe(res=>{
    if(res && res ["message"]== "Success"){
      var data = res ["data"][0];
       this.communication.push(data)
       this.addPopupFlag = false;
       this.messageService.add({ severity: 'success', summary: 'Added Successfully', detail: '' });
    }


  })

  }
  openAddCommunications(){
    this.addPopupFlag = true;
  }
  cancelAdd(){
    this.addPopupFlag = false;
  }



}
