import {Component, OnInit, SimpleChanges,Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {TestingMuscleService} from '../web-services/testing-muscle.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {FormDataDTO} from "../dto/FormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../web-services/clinical-data.service';
import {TestingFormData} from '../dto/TestingFormData';

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({selector: 'app-testing-muscle', templateUrl: './testing-muscle.component.html', styleUrls: ['./testing-muscle.component.css']})
export class TestingMuscleComponent implements OnInit {


    @Input() chosenBodyChartAnatomyDiagramId;
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateTestingMuscleId = null;
    listOfValuesDetailList: any = [];
    testingMuscleList: any = [];
    muscleGroupList: any = [];
    sidesCategoryList: any = [];
    measureList: any = [];
    testingMuscleSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    testingMuscleForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    expertId;
    patientId;
    appointmentId;
    caseId;

  lovDataObject : any = {};
    constructor(
        private testingMuscleServices: TestingMuscleService,
        private listOfValuesDetailServices: ListOfValuesDetailService, private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService, private messageService: MessageService, private route: Router,
        private loader: NgxUiLoaderService, private constantService: ConstantService, private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private clinicalDataService: ClinicalDataService
    ) {
    };

    ngOnChanges(changes: SimpleChanges) {

      if (!changes.chosenBodyChartAnatomyDiagramId.firstChange)  {
        this.findAllTestingMusclesByAppointmentIdAndBodyChartAnatomyDiagramId();
      }

    }

    get addUpdateTitle() {
        return this.updateTestingMuscleId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.testingMuscleSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countTestingMuscle();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countTestingMuscle() {
        this.testingMuscleSearch.controls['expertId'].setValue(this.expertId);
        this.testingMuscleSearch.controls['patientId'].setValue(this.patientId);
        this.testingMuscleSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingMuscleServices.countTestingMuscle(this.testingMuscleSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllTestingMuscle();
            } else {
                this.totalRecords = 0;
                this.testingMuscleList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {

        this.testingMuscleForm.markAllAsTouched();

        const testingMuscleDTO = new TestingFormData(this.appointmentId, this.patientId, this.expertId, this.caseId, {}, this.testingMuscleForm);

      const diagnosisData = new DiagnosisDataHelper(testingMuscleDTO.diagnosisData);
      testingMuscleDTO.bodyChartAnatomiesDiagramId = this.chosenBodyChartAnatomyDiagramId;

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();

        console.log(testingMuscleDTO);
        if (this.testingMuscleForm.valid) {

          this.testingMuscleServices.addTestingMuscleCpa(testingMuscleDTO).subscribe(res => {

            if (res.status === 200) {

              this.messageService.add({
                severity: 'success',
                summary: this.translate(this.labels.entityNameTestingMuscle) +
                  this.translate(this.labels.addedSuccessfully),
              });

              this.lovDataObject = {"refId":0,"refType":"","reset": true};
              this.lovDataObject = {"refId":0,"refType":"","reset": false};

              this.submitted = true;
              this.showHideAddDialog();
              this.findAllTestingMusclesByAppointmentIdAndBodyChartAnatomyDiagramId();
              this.onClearAdd();

              if (uniqueCaseDiagnosisPredictionList.length > 0) {
                this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

                  console.log(res);
                });
              }

              if (testingMuscleDTO.redFlagData.length > 0) {

                this.lovServices.createListOfCaseRedFlags(testingMuscleDTO.redFlagData).subscribe(res => {

                  if (res.status === 201) {

                    this.messageService.add({
                      severity: 'success',
                      summary: this.translate("Red Flags Updated"),
                    });
                  }

                });
              }
            }

          });

        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllTestingMuscle();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.testingMuscleSearch.reset();
        this.countTestingMuscle();
        // this.findAllTestingMuscle();
    }

    addUpdateTestingMuscle(testingMuscle) {
        this.startLoader();
        if (this.updateTestingMuscleId == null) {
            this.testingMuscleServices.addTestingMuscle(this.testingMuscleForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    testingMuscle = res['data'][0];
                    this.testingMuscleList.push(testingMuscle);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameTestingMuscle) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameTestingMuscle)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countTestingMuscle();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            testingMuscle.testingMuscleId = this.updateTestingMuscleId;
            this.testingMuscleServices.updateTestingMuscle(testingMuscle).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.testingMuscleList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameTestingMuscle) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameTestingMuscle)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateTestingMuscleId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findSidesCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('SidesCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.sidesCategoryList.push(dropDown);
                    }
                } else {
                    this.sidesCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllMuscleGroup() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Muscle Group').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.muscleGroupList.push(dropDown);
                    }
                } else {
                    this.muscleGroupList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findAllMeasure() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Measures').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.measureList.push(dropDown);
                    }
                } else {
                    this.measureList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));
        this.findAllMuscleGroup();
        this.findAllMeasure();
        this.findSidesCategory();
        this.findAllListOfValuesDetail();
        this.testingMuscleForm = new FormGroup({
                                                   muscleGroup: new FormControl('',[Validators.required]),
                                                   muscleSide: new FormControl('', [Validators.required]),
                                                   comment: new FormControl('', [Validators.required, validateSpaces]),
                                                   measures: new FormControl('',[Validators.required]),
                                                   bodyChartId: new FormControl(0),
                                                   userId: new FormControl(0),
                                               });
        this.testingMuscleSearch = new FormGroup({
                                                     muscleGroup: new FormControl(),
                                                     muscleSide: new FormControl(),
                                                     comment: new FormControl(),
                                                     measures: new FormControl(),
                                                     patientId: new FormControl(),
                                                     expertId: new FormControl(),
                                                     appointmentId: new FormControl(),
                                                     bodyChartId: new FormControl(),
                                                     userId: new FormControl(),
                                                 });
        this.findAllTestingMusclesByAppointmentIdAndBodyChartAnatomyDiagramId();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.testingMuscleSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }


  findAllTestingMusclesByAppointmentIdAndBodyChartAnatomyDiagramId(): void {

    this.startLoader();

    this.clinicalDataService.findAllTestingMusclesByAppointmentIdAndBodyChartAnatomyDiagramId(this.appointmentId, this.chosenBodyChartAnatomyDiagramId).subscribe(res => {



      if (res.status === HttpStatusCode.Ok) {

        this.testingMuscleList = res.body;

        this.totalRecords = this.testingMuscleList.length;
        this.stopLoader();

      }
    }, err => {

      this.stopLoader();
      this.messageService.add(
        {
          severity: 'error',
          summary: err,
        });
    });
  }


  findAllTestingMuscle() {
        this.startLoader();
        this.testingMuscleSearch.controls['expertId'].setValue(this.expertId);
        this.testingMuscleSearch.controls['patientId'].setValue(this.patientId);
        this.testingMuscleSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingMuscleServices.findAllTestingMuscle(this.testingMuscleSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.testingMuscleList = res['data'];
                } else {
                    this.testingMuscleList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdTestingMuscle(testingMuscleId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.testingMuscleServices.deleteByIdTestingMuscle(testingMuscleId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.testingMuscleList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameTestingMuscle) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameTestingMuscle)
                                                });
                        this.countTestingMuscle();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deleteTestingMuscle"
                                  });
    }

    loadTestingMuscleToEdit(testingMuscleId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let testingMuscle = this.testingMuscleList.find(x => x['testingMuscleId'] == testingMuscleId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateTestingMuscleId = testingMuscle['testingMuscleId'];
        this.selectedIndex = index;

        if(testingMuscle['muscleGroup']) {
            this.testingMuscleForm.controls['muscleGroup'].setValue(testingMuscle['muscleGroup']['listOfValuesId']);
        }
        if(testingMuscle['muscleSide']) {
            this.testingMuscleForm.controls['muscleSide'].setValue(testingMuscle['muscleSide']['listOfValuesId']);
        }
        if(testingMuscle['comment']) {
            this.testingMuscleForm.controls['comment'].setValue(testingMuscle['comment']);
        }
        if(testingMuscle['measures']) {
            this.testingMuscleForm.controls['measures'].setValue(testingMuscle['measures']['listOfValuesId']);
        }
        if(testingMuscle['patientId']) {
            this.testingMuscleForm.controls['patientId'].setValue(testingMuscle['patientId']['patientId']);
        }
        if(testingMuscle['expertId']) {
            this.testingMuscleForm.controls['expertId'].setValue(testingMuscle['expertId']);
        }
        if(testingMuscle['appointmentId']) {
            this.testingMuscleForm.controls['appointmentId'].setValue(testingMuscle['appointmentId']['appointmentId']);
        }

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.testingMuscleForm.reset();
        this.testingMuscleForm.markAsUntouched();
        this.updateTestingMuscleId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.testingMuscleForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.testingMuscleForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.testingMuscleForm.get(control)?.valid && this.testingMuscleForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.testingMuscleForm.reset();
    }

    onCloseSearch() {
        this.testingMuscleSearch.reset();
    }

    refresh() {
        this.countTestingMuscle();
    }

    onAddOverlayDiv() {
        this.testingMuscleForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.testingMuscleSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.testingMuscleForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateTestingMuscleId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.testingMuscleServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.testingMuscleList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.testingMuscleServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.testingMuscleList = result['data'];
            } else {
                this.testingMuscleList = [];
            }
            console.log(result, 'result');
        });
    }
}
