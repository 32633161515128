import { Component, OnInit } from '@angular/core';
import {SessionService} from "../services/session.service";
import {LovSingleDropDown} from "../util/LovSingleDropDown";
import {ListOfValuesService} from "../web-services/list-of-values.service";
import {FormControl, FormGroup, Validators, NgModel} from "@angular/forms";
import {SliderModule} from 'primeng/slider';
import {ClinicalDataService} from "../web-services/clinical-data.service";
import {FollowUpFormData} from "../dto/FollowUpFormData";
import {constants} from "http2";
import {HttpStatusCode} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {ComponentFormBase} from "../util/ComponentFormBase";
import {MultiSelect} from '../uiUtils/MultiSelect';
import {FollowUpMultiSelects} from '../uiUtils/pages/follow-up/FollowUpMultiSelects';
import {Constant} from '../util/constant';
import {BodyChartFormDataDTO} from '../dto/BodyChartFormDataDTO';
import {InitialAssessmentSteps} from '../initial-assessment-steps/InitialAssessmentSteps';
import {FollowUpSteps} from '../uiUtils/FollowUpSteps';
import {Router} from '@angular/router';
import {BodyChartDetailService} from '../web-services/body-chart-detail.service';
declare var $: any;

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.css']
})
export class FollowUpComponent implements OnInit {

  constructor(private componentFormBase: ComponentFormBase,
              private sessionService: SessionService,
              private lovServices: ListOfValuesService,
              private clinicalDataService: ClinicalDataService,
              private messageService: MessageService,
              private followUpMultiSelects: FollowUpMultiSelects,
              private router: Router,
              private bodyChartDetailService: BodyChartDetailService
              ) { }

  patientObj: any;
  consentOptions = [];
  ngModel: NgModel;
  val: number;
  appointmentId: number;
  caseId: number;
  patientId: number;
  expertId: number;
  baseComponent = this.componentFormBase;
  lovDataObject : any = {};
  responseData : any;
  refDataObj: any;
  currentRedFlags = [];
  stepsItems: any;
  activeStep: number;
  fullAppointment: any;
  selectedRedFlags = [];
  caseAppointmentObject = {};
  bodyAnatomyInformation: any;
  existingFollowUpData: any;
  bodyChartAnatomyDetailsForm: FormGroup;
  redFlagsLoaded: boolean = false;
  isEdit: boolean = false;



getResponse($event) {

    this.selectedRedFlags = $event.filter(lov => lov.selected === 1);

  }

  ngOnInit(): void {


    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.fullAppointment = JSON.parse(this.sessionService.getSession('appointment'));


    this.caseAppointmentObject = {

        caseId: {

          caseId: this.caseId
        },

        appointmentId: {

          appointmentId: this.appointmentId
        }

    }

    this.baseComponent.baseForm  = new FormGroup({
      consent: new FormControl('', [Validators.required]),
      isSoap: new FormControl(false, [Validators.required]),
      percentageImprovement: new FormControl(0, ),
      soapText: new FormControl('', ),


    });



    this.getFollowUpDataByAppointmentId();
    this.getRedFlagsByCaseId();
    this.findAllConsent();


    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;



    this.stepsItems = FollowUpSteps.stepItems;
    this.activeStep = parseInt(this.stepsItems.find(step => step.name === 'general').number);

  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }

   findAllConsent(): void {

    let _self = this;
    LovSingleDropDown.getLovValuesByCategoryName('Consent', this.lovServices).then(data => {


      _self.consentOptions = data;

    });

  }

  getFollowUpDataByAppointmentId(): void {

    const _self = this;

    this.clinicalDataService.getFollowUpDataByAppointmentId(this.appointmentId).subscribe(response => {

      if (response.status === HttpStatusCode.Ok) {


        this.existingFollowUpData = response.body;

        if (this.existingFollowUpData !== null || this.existingFollowUpData !== undefined) {

          _self.baseComponent.addEditTitle = _self.baseComponent.labels.btnEdit;
          _self.isEdit = true;
          _self.baseComponent.baseForm.controls['consent'].setValue(this.existingFollowUpData.consentId);
          _self.baseComponent.baseForm.controls['isSoap'].setValue(this.existingFollowUpData.isSoap);
          _self.baseComponent.baseForm.controls['percentageImprovement'].setValue(this.existingFollowUpData.percentageImprovement);
          _self.baseComponent.baseForm.controls['soapText'].setValue(this.existingFollowUpData.soapText);
        }
      }

    });


  }

   async getRedFlagsByCaseId(): Promise<void> {



   await this.clinicalDataService.getRedFlagsByCaseId(this.caseId).toPromise().then(response => {


      if (response.status === HttpStatusCode.Ok) {

          this.currentRedFlags = response.body;
          this.redFlagsLoaded = true;


      }



    }, err => {
      alert(err);
      console.error('err: ', err);

    });


  }


    onSubmit(): void {



    const followUpFormData = new FollowUpFormData(this.appointmentId, this.baseComponent.baseForm);
    this.selectedRedFlags = this.selectedRedFlags.map(redFlag => {

      redFlag.caseId =  { caseId: this.caseId};
      redFlag.appointmentId =  { appointmentId: this.appointmentId};

      return redFlag;

    });

    /* we need to know if it is an edit or add */

    if (this.isEdit) {

      let followUpUpdates = [
        {
          "op": "replace",
          "path": "/consent",
          "value": {
            "listOfValuesId": this.baseComponent.baseForm.controls['consent'].value
          }
        },
        {
          "op": "replace",
          "path": "/isSoap",
          "value": this.baseComponent.baseForm.controls['isSoap'].value

        },
        {
          "op": "replace",
          "path": "/percentageImprovement",
          "value": this.baseComponent.baseForm.controls['percentageImprovement'].value

        },
        {
          "op": "replace",
          "path": "/soapText",
          "value": this.baseComponent.baseForm.controls['soapText'].value

        }
    ];


      this.clinicalDataService.updateFollowUp(followUpUpdates, this.existingFollowUpData.followUpId).subscribe( response => {

        if (response.status === HttpStatusCode.Accepted) {

            this.messageService.add({
              severity: 'success',
              summary: this.baseComponent.translate('Follow Up') +
                this.baseComponent.translate(this.baseComponent.labels.updatedSuccessfully),
            });

          this.lovServices.createListOfCaseRedFlags(this.selectedRedFlags).subscribe(res => {

            if (res.status === 201) {

              this.messageService.add({
                severity: 'success',
                summary: this.baseComponent.translate("Red Flags Updated"),
              });
            }
          });
        }

      }, err => {
        this.messageService.add({
          severity: 'error',
          summary: err,
        });

      }


      );


    }
    else {

      this.clinicalDataService.createFollowUpData(followUpFormData).subscribe( response => {

        if (response.status === HttpStatusCode.Created) {




          this.messageService.add({
            severity: 'success',
            summary: this.baseComponent.translate('Follow Up') +
              this.baseComponent.translate(this.baseComponent.labels.addedSuccessfully),
          });

          this.lovServices.createListOfCaseRedFlags(this.selectedRedFlags).subscribe(res => {

            if (res.status === 201) {

              this.messageService.add({
                severity: 'success',
                summary: this.baseComponent.translate("Red Flags Updated"),
              });
            }


          }, err => {
            this.messageService.add({
              severity: 'error',
              summary: this.baseComponent.translate(err.message),
            });

          });

          this.baseComponent.showHideAddDialog();

        }

      }, err => {
        this.messageService.add({
          severity: 'error',
          summary: this.baseComponent.translate(err),
        });
      });

    }

  }

}
