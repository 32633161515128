import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { NonInterceptService } from '../../services/non-intercept.service';
import { ToastService } from '../../services/toast.service';
import { DataSharedService } from '../../services/data-shared.service';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LogintypesComponent } from '../../logintypes/logintypes.component';
import { Constant } from '../../util/constant';
import { AgencyService } from '../../web-services/agency.service';
import { SharedService } from '../../services/sharedservice/shared.service';
import { ExpertService } from '../../web-services/expert.service';
import { CompanyService } from '../../web-services/company.service';
import { PatientsService } from '../../web-services/patients.service';
import { async } from 'rxjs';
import { CompanyUsersService } from 'src/app/web-services/company-users.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService } from 'primeng/api';
import { LocalService } from 'src/app/services/local.service';
import {User} from "../../dto/User";
// import {MainComponent} from 'menu-app';
import { environment } from '../../../environments/environment';
import {HttpStatusCode} from '@angular/common/http';


@Component({
    providers: [DialogService],
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    LoginForm: FormGroup;
    currentToken = '';
    currentTime = new Date();
    user = {} as string;
    today;
    exp;
    ipAddress;
    currentDate: number;
    submitted: boolean = false;
    businessRole = new Array<string>();
    curretToken = {} as string;
    data: any;
    islogin;
    loginAgain = false;
    fullyAuthenticatedUser: User;
    loginError = false;
    validationMessages = {

        'Email': {
            'required': 'Email is required.',
            'pattern': 'Please provide valid Email ID'
        },
        'Password': {
            'required': 'Password is required.',
            // 'pattern':'Please Enter a Strong Password'
        },
        // 'ConfirmPassword': {
        //   'required': 'Confirm Password is required.',
        //   'mismatch': 'Password and Confirm Password do not match'
        // }
    };
    formErrors = { Email: '', Password: '' };

    constructor(private dialogService: DialogService,
        private non_interceptServices: NonInterceptService,
        private router: Router,
        private sessionService: SessionService,
        private localService: LocalService,
        private cookieService: CookieService,
        private confirmation: ConfirmationService,
        private toastService: ToastService,
        private dataSharedService: DataSharedService,
        private fb: FormBuilder,
        private _validation: ValidationService,
        private sharedService: SharedService,
        private agencyService: AgencyService,
        private expertServices: ExpertService,
        private companyServices: CompanyService,
        private companyUserService: CompanyUsersService,
        private patientsServices: PatientsService,
        private loader: NgxUiLoaderService,

        // private menuComponent: MainComponent
    ) {

    }

    ref: DynamicDialogRef;

    ngOnInit() {
        this.loginAgain = false;
        this.initializeForm();
        this.islogin = false
        this.LoginForm.valueChanges.subscribe(
            value => {
               // this.logValidationErrors();
            }
        );

    }


    logValidationErrors() {
        this.formErrors = this._validation.getValidationErrors(this.LoginForm, this.validationMessages);
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    onSubmit() {

        this.submitted = true;

        if (this.LoginForm.invalid) {
            return;
        }

      //////  alert(environment.baseUrl);
        this.startLoader();

      //  this.non_interceptServices.userLogin(this.LoginForm.value, this.ipAddress).subscribe(data => {
       this.non_interceptServices.userLoginSpring(this.LoginForm.value).subscribe(data => {


         const  User = data.body as User;
         this.fullyAuthenticatedUser = User;

         console.log(this.fullyAuthenticatedUser);

         this.user_type = User.userTypeLovId;




            if (data.status === 200) {


                this.currentToken = data.headers.get('Authorization');
                this.sessionService.setSession('token', this.currentToken);
                this.sessionService.setSession('fullAuthenticatedUser',  JSON.stringify(User));



                this.sessionService.setSession('loggedInUser', User.userId);
                this.sessionService.setSession('firstName', User.firstName);
                this.sessionService.setSession('clientId', User.clientId);
                this.sessionService.setSession('userType', User.userTypeLovId);
                this.sessionService.setSession('cpa_roles', JSON.stringify(User.authorities));

                this.sessionService.setSession('umsUrl', Constant.BASE_URL_SSO);
                this.sessionService.setSession('applicationId', '1');
                this.sessionService.setSession('userActivatedRole', 888);
                this.dataSharedService.checkIsUserLoggedIn(true);
                this.islogin = true;
                this.assignUserTypeRefId();

                this.submitted = false;


            } else if (data.status !== 200){
                this.submitted = false;
                this.toastService.showErrorWithCustomMessage(data['message']);
                this.stopLoader();


            }else{
                this.loginAgain = true;

                this.sessionService.setSession('loggedInUser', this.user['loggedInUserId']);
                // console.log("inside login again popup",this.loginAgain);
            }
        } ,err => {
         this.loginError = true;

           this.stopLoader();
         })
       ;

    }

    user_type: any;
    getBusinessRole() {
        this.non_interceptServices.getProjectBusinessRoleList(this.user['userId'], -2, 0, 1, 'users').subscribe((data: Array<string>) => {
            // if (data != null && data != undefined) {
            if (data) {
                this.businessRole = data as string[];

                this.dataSharedService.setRoles(this.businessRole);
                this.user_type = this.businessRole[0]['users'][0]['UserType'];
                this.sessionService.setSession('userType', this.user_type);

                if (this.businessRole.length > 0) {

                    if (this.businessRole.length == 1) {
                        console.log(this.businessRole[0], 'business roles');
                        this.updateCurrentRoleData(this.businessRole[0]['users'][0]['role']);

                    } else {
                        this.showRolesDialog();
                    }
                } else {
                    this.toastService.showInfo('You are not Authorized Person for this Project');
                    this.stopLoader();
                }
            } else {
                this.toastService.showInfo('You are not Authorized Person for this Project');
                this.stopLoader();
            }
            error => {
                this.toastService.showError('Retrieval', 'Business Role List');
                this.stopLoader();
            };
        });
    }

    updateCurrentRoleData(roleId) {

        this.non_interceptServices.updateCurrentRole(roleId, 1, this.user['userId']).subscribe((data: string) => {
            this.curretToken = data['token'];
            this.sessionService.setSession('cpa_roles', data['applicationRole']);
            this.sessionService.setSession('token', this.curretToken);
            this.sessionService.setSession('userActivatedRole', roleId);
            this.sessionService.setSession('umsUrl', Constant.BASE_URL_SSO);
            this.sessionService.setSession('applicationId', '1');
            this.dataSharedService.checkIsUserLoggedIn(true);
            this.islogin = true;
            this.assignUserTypeRefId(roleId);
        });
    }

    async assignUserTypeRefId(roleId = null) {
        // by ali --starts
        this.startLoader();
        this.data = { "userId": parseInt(sessionStorage.getItem('loggedInUser')) };



        if (this.fullyAuthenticatedUser.isSuperAdmin) {
            this.sessionService.setSession('loggedInUserType', Constant.SUPER_ADMIN_BUSINESS_ROLE);
            this.sessionService.setSession('loggedInRefId', parseInt(sessionStorage.getItem('loggedInUser')));
            this.router.navigate(['cpa/landingpage']);
            this.stopLoader();
        }
        else if (this.fullyAuthenticatedUser.isAdmin) {
          this.sessionService.setSession('loggedInUserType', Constant.ADMIN_BUSINESS_ROLE);
          this.sessionService.setSession('loggedInRefId',  parseInt(sessionStorage.getItem('loggedInUser')));
          this.router.navigate(['cpa/landingpage']);
            /*await this.agencyService.findAllAgency(this.data, 0, 10).toPromise().then(result => {


                if (result['statusCode'] == 200) {
                    this.sessionService.setSession('loggedInUserType', Constant.ADMIN_BUSINESS_ROLE);
                    this.sessionService.setSession('loggedInRefId', parseInt(result['data'][0]['agencyId']));
                    this.router.navigate(['cpa/landingpage']);
                    this.stopLoader();
                } else {   alert(result);
                    this.router.navigate(['/logout']);
                    this.stopLoader();
                }
            });*/
        }
        else if (this.fullyAuthenticatedUser.isExpert) {


            this.startLoader();
            await this.expertServices.findExpertByUserId(this.fullyAuthenticatedUser.userId).subscribe( result => {

                if (result.status === HttpStatusCode.Ok) {

                    this.startLoader();
                    this.sessionService.setSession('loggedInUserType', Constant.EXPERT_BUSINESS_ROLE);
                    this.sessionService.setSession('expertId', result.body.expertId);

                    this.sessionService.setSession('loggedInRefId', result.body.expertId);

                    this.router.navigate(['cpa/landingpage']);
                    this.stopLoader();
                } else {
                    this.router.navigate(['/logout']);
                    this.stopLoader();
                }
            });

        }
        else if (this.fullyAuthenticatedUser.isCorporate) {
            // In case it is corporate
            await this.companyUserService.findAllCompanyUsers(this.data, 0, 10).toPromise().then(result => {
                if (result['statusCode'] == 200) {
                    this.sessionService.setSession('loggedInUserType', Constant.CORPORATE_BUSINESS_ROLE);
                    this.sessionService.setSession('loggedInRefId', parseInt(result['data'][0]['companyId']['companyId']));
                    this.sessionService.setSession("parentId", parseInt(result['data'][0]['companyId']['agencyId']));
                    this.router.navigate(['cpa/landingpage']);
                    this.stopLoader();
                } else {
                    this.router.navigate(['/logout']);
                    this.stopLoader();
                }
            });

        }
        else if (this.fullyAuthenticatedUser.isIndividual) {
            // In case it is individual
            await this.patientsServices.findPatientsByUserId(this.data).subscribe(result => {
                if (result['statusCode'] == 200) {
                    this.sessionService.setSession('loggedInUserType', Constant.INDIVIDUAL_BUSINESS_ROLE);
                    this.sessionService.setSession('loggedInRefId', parseInt(result['data'][0]['patientId']));
                    this.sessionService.setSession('patientId', parseInt(result['data'][0]['patientId']));

                    this.getPatientParentId(parseInt(result['data'][0]['patientId']));
                    this.router.navigate(['cpa/landingpage']);
                    this.stopLoader();
                } else {
                    this.router.navigate(['/logout']);
                    this.stopLoader();
                }
            });
        }
        // by ali --ends
    }

    async getPatientParentId(patientId) {
        await this.patientsServices.findParentIdByPatient(patientId).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.sessionService.setSession("parentId", parseInt(result['data'][0]['referenceId']));
            }
        });
    }

    initializeForm() {
        this.LoginForm = this.fb.group(
            {
                // Name: ['', [Validators.required]],
                username: ['', [Validators.required, Validators.pattern(this._validation.regex.email)]],
                password: ['', [Validators.required]],
                // ConfirmPassword: ['', [Validators.required]],
            }
        );
    }


    showRolesDialog() {
        this.ref = this.dialogService.open(LogintypesComponent, {
            // header: 'Choose a Product',
            width: '85%',
            contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
            baseZIndex: 10000
        });
        this.stopLoader();
        this.ref.onClose.subscribe((businessRoleId) => {
            if (businessRoleId > 0) {
                console.log(businessRoleId, ':: role id');
                this.updateCurrentRoleData(businessRoleId);
            } else {

                this.router.navigate(['/logout']);
            }
        });
    }

    onNoClick() {
        this.loginAgain = false;
    }

    onLoginAgainClick() {
        this.loginAgain = false;
        // call logout service first
        this.updateUserStatus();
    }

    updateUserStatus() {
        this.non_interceptServices.updateUserStatus(0).subscribe((data: string) => {
            if (data['message'] == 'Success') {
                // this.dataSharedService.checkIsUserLoggedIn(false);
                // this.localService.logout();
                // sessionStorage.clear();
                this.onSubmit();
            } else {
                this.toastService.showErrorWithCustomMessage(data['documentation']);
            }
        });
    }

}

