import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExpensesService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdExpenses(expensesId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Expenses/deleteExpenses', {'expensesId': expensesId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countExpenses(expenses): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Expenses/count', {'expenses': expenses}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateExpenses(expenses): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Expenses/updateExpenses', [expenses], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addExpenses(expenses): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Expenses/addExpenses', [expenses], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllExpenses(expenses, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Expenses/findAllExpenses',
            {'expenses': expenses, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    //custom service for global searching
    expenseGlcount(searchTerm,referenceId): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Expenses/countGlobalExpenses',
                                            {'searchTerm': searchTerm,'refId':referenceId},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalExpense(searchTerm,referenceId, pageNo: number, itemsPerPage: number): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Expenses/findGlobalExpense',
            {'searchTerm': searchTerm,'refId':referenceId, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAdminIdByExpertId(expertId): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'UserTypeExpertService/getUserTypeExpert', {'expertId': expertId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
