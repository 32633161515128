import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencyExpertService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgencyExpert(agencyExpertId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyExpert/deleteAgencyExpert', {'agencyExpertId': agencyExpertId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAgencyExpert(agencyExpert): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'AgencyExpert/count', {'agencyExpert': agencyExpert}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateAgencyExpert(agencyExpert): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyExpert/updateAgencyExpert', [agencyExpert], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addAgencyExpert(agencyExpert): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyExpert/addAgencyExpert', [agencyExpert], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAgencyExpert(agencyExpert, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'AgencyExpert/findAllAgencyExpert',
            {'agencyExpert': agencyExpert, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
