<!-- <app-app-header></app-app-header> -->
<ngx-ui-loader></ngx-ui-loader>
<section class="sectionmain" id="sectionmain-communcationPage">
   
    <div class="container-fluid">
      <div class="ContentWrapperClass">
        
       
  
        <p-card class="cardAccordion ">
            <div class="comm-title-btn">
                <h3 class="cpaToolsTitleBlue cpaToolsTitle">Communication</h3>
                <p-button class="pngBlueBtn" label="New Communication" styleClass="p-button" (click)="openAddCommunications()"></p-button>
            </div>

<!--            <div class="btn-new">-->
<!--              <p-button class="pngBlueBtn" label="New Communication" styleClass="p-button" (click)="openAddCommunications()"></p-button>-->
<!--          </div>-->
          <div class="p-grid">
              <div class="p-col-12" *ngIf="typeflag && comFlag">
                <p-accordion class="customAccordion">
                  <ng-container *ngFor ="let communicationType of communicationTypes">
                  <p-accordionTab [header]="communicationType['title']" [selected]="true">
                    <app-dt-table [value]="getData(communicationType)" [communicationType]="communicationType" (deleteEventEmitter)="deleteRow($event)">  </app-dt-table>
                  </p-accordionTab>
                  </ng-container>

                 
                </p-accordion>
              </div>
            </div>
        </p-card>
      </div>
    </div>

  </section>

  <app-add-communications  *ngIf="typeflag && comFlag && addPopupFlag" [communicationType]="communicationTypes" [visible]="addPopupFlag" (addCommunication)="addCommunication($event)" (addCancelEvent)="cancelAdd()"> </app-add-communications>
