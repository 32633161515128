import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dt-table',
  templateUrl: './dt-table.component.html',
  styleUrls: ['./dt-table.component.css']
})
export class DtTableComponent implements OnInit {
@Input () communicationType;
@Input () value;
@Output() deleteEventEmitter = new EventEmitter();
cols = [
  { field: 'insertionTimestamp', header: 'Date' },
  {field: 'content', header: 'Content' },
  { field: 'address', header: 'Address' } ,
  { field: 'sendTo', header: 'Send To' } ,
  { field: 'action', header: 'Action' }
];
  constructor() { }

  ngOnInit(): void {
  }
  delete(rowData){
    this.deleteEventEmitter.emit(rowData);
  }
   format(date) {
    date = new Date(date);
  
    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
  
    return year + '-' + month + '-' + day;
  }
  returnDateFromMilliSecond(userdate){
    var date = new Date(userdate);
  
    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
  
    return year + '-' + month + '-' + day;
    
  }
}
