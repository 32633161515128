import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";

@Injectable({providedIn: 'root'})
export class TestingMuscleService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdTestingMuscle(testingMuscleId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingMuscle/deleteTestingMuscle', {'testingMuscleId': testingMuscleId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countTestingMuscle(testingMuscle): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'TestingMuscle/count', {'testingMuscle': testingMuscle}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateTestingMuscle(testingMuscle): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingMuscle/updateTestingMuscle', [testingMuscle], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addTestingMuscle(testingMuscle): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'TestingMuscle/addTestingMuscle', [testingMuscle], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllTestingMuscle(testingMuscle, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingMuscle/findAllTestingMuscle',
            {'testingMuscle': testingMuscle, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'TestingMuscle/countGlobalTestMuscle',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingMuscle/findGlobalTestMuscle',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addTestingMuscleCpa(testingMuscleDTO: FormDataDTO): Observable<any> {
    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'testing-muscles', testingMuscleDTO, {headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }
}
