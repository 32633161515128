<!-- <app-app-header></app-app-header> -->

<section class="sectionmain" id="sectionmain-admin">
    <div class="container-fluid">
        <div class="p-fluid p-formgrid p-grid admin-grid">
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2"  [routerLink]="['/appointments_confirmation']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/send-sms.svg">
                        <div class="dashboard-card-text">
                            <p>Send SMS</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/appointments_confirmation']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/appointment-confirmation.svg">
                        <div class="dashboard-card-text">
                            <p>Appointment Confirmation</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/appointments_confirmation']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/message-board.svg">
                        <div class="dashboard-card-text">
                            <p>Message Board</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/appointments_confirmation']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/send-mail.svg">
                        <div class="dashboard-card-text">
                            <p>Send Email</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/subscription']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/subscription_expert.svg">
                        <div class="dashboard-card-text">
                            <p>Subscription</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>

            <!--Start-Commented for UAT-->

            <!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
            <!--                <div class="adminCard adminCardBlue">-->
            <!--                    <ng-template pTemplate="header">-->
            <!--                    </ng-template>-->
            <!--                    <div class="dashboard-card-content noNumberCard">-->
            <!--                        <img src="assets/theme1/custom-files/img/icons/pending-invoices.svg">-->
            <!--                        <div class="dashboard-card-text">-->
            <!--                            <p>Pending Invoice</p>-->
            <!--                            <h3 class="dashboardText"></h3>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
            <!--                <div class="adminCard adminCardBlue">-->
            <!--                    <ng-template pTemplate="header">-->
            <!--                    </ng-template>-->
            <!--                    <div class="dashboard-card-content noNumberCard">-->
            <!--                        <img src="assets/theme1/custom-files/img/icons/Patient-case%20(2).svg">-->
            <!--                        <div class="dashboard-card-text">-->
            <!--                            <p>Total Completed</p>-->
            <!--                            <h3 class="dashboardText"></h3>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
            <!--                <div class="adminCard adminCardBlue">-->
            <!--                    <ng-template pTemplate="header">-->
            <!--                    </ng-template>-->
            <!--                    <div class="dashboard-card-content noNumberCard">-->
            <!--                        <img src="assets/theme1/custom-files/img/icons/Patient-case%20(2).svg">-->
            <!--                        <div class="dashboard-card-text">-->
            <!--                            <p>Patient By Profession</p>-->
            <!--                            <h3 class="dashboardText"></h3>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <!--End-Commented for UAT-->
        </div>
    </div>
</section>
