<p-dialog [header]="labels.addCommunicationHeader" class="reportDialog communication-dialog" [closable]="false" [blockScroll]="true" [modal]="true" [draggable]="false" [visible]="visible"
  [style]="{width: '50vw'}" [baseZIndex]="10010" id="comm-dialog">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form">
<div class="p-fluid p-formgrid p-grid">
  <div class="p-field p-col-12 p-md-6 formfieldheight">
    <label>Title</label>
        <input class="pngInputtext"
               name="title"
               pInputText
               type="text" formControlName="title">

    <app-custom-error [control]="'title'" [form]="formGroup"></app-custom-error>

</div>
    <div class="p-field p-col-12 p-md-6 formfieldheight">
      <label>Description</label>
          <input class="pngInputtext"
                 name="description"
                 pInputText
                 type="text" formControlName="description">
      <app-custom-error [control]="'description'" [form]="formGroup"></app-custom-error>

</div>

</div>
<div class="p-fluid p-formgrid p-grid">
  <div class="p-field p-col-12 p-md-6 formfieldheight">
    <label>Address</label>
        <input class="pngInputtext"
               name="agencyFirstname"
               pInputText
               type="text" formControlName="address">
    <app-custom-error [control]="'address'" [form]="formGroup"></app-custom-error>
</div>

    <div class="p-field p-col-12 p-md-6 formfieldheight">
      <label>Content</label>
          <input class="pngInputtext"
                 name="agencyFirstname"
                 pInputText
                 type="text" formControlName="content">
      <app-custom-error [control]="'content'" [form]="formGroup"></app-custom-error>
</div>
</div>

<div class="p-fluid p-formgrid p-grid" style="padding-bottom: 3rem;">
  <div class="p-field p-col-12 p-md-6 formfieldheight comm-dropdown ">
      <label>Communication Type </label>
      <p-dropdown [filter]="true" [options]="communicationType" [showClear]="true" optionLabel="title" optionValue="communicationsTypeId"
                  class="pngDropdown comm-type-dropdown"
                  (onChange)="onTypeChange($event)"
                  placeholder="Select" formControlName="communicationType"></p-dropdown>
                  <app-custom-error [control]="'communicationType'" [form]="formGroup"></app-custom-error>
  </div>

    <div *ngIf="showEmail" class="p-field p-col-12 p-md-6 formfieldheight">
        <label>Email</label>
        <span class="p-input-icon-right">
            <i (click)="fieldReset('sendTo')"
               *ngIf="diplayeIconInField('sendTo')"
               class="pi pi-times color-danger">
            </i>
            <input  [class.p-invalid]="inputFieldDanger('sendTo')"
                    class="pngInputtext"
                    formControlName="sendTo"
                    name="sendTo"
                    pInputText
                    type="text">
        </span>
        <app-custom-error [control]="'sendTo'" [form]="formGroup"></app-custom-error>
    </div>

<!--Phone Number-->
    <div *ngIf="showPhoneNumber" class="p-field p-col-12 p-md-6 formfieldheight">
        <label>Phone Number</label>
        <p-inputNumber [class.p-invalid]="inputFieldDanger('sendTo')"
                       class="p-invalid pngInputnumber"
                       formControlName="sendTo"
                       name="sendTo"
                       [useGrouping]="false"
                       pNumberInput
                       type="number">
        </p-inputNumber>
        <app-custom-error [control]="'sendTo'" [form]="formGroup"></app-custom-error>
    </div>

</div>


<div class="pngPanel-footer">

  <p-button label="Close"
  styleClass="p-button" class="pngBtnTransparent" (click)="cancel()"></p-button>

  <p-button label="Add"
            styleClass="p-button"
            class="pngBlueBtn"
            (click)="addNewCommunication()"></p-button>
</div>
  </form>
</p-dialog>
