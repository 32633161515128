import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ClinicsService} from '../web-services/clinics.service';
import {UsersService} from '../web-services/users.service';
import {AgencyService} from '../web-services/agency.service';
import {ClinicTypeService} from '../web-services/clinic-type.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';
import { SessionService } from '../services/session.service';
import {HttpStatusCode} from '@angular/common/http';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-clinics',
               templateUrl: './clinics.component.html',
               styleUrls: ['./clinics.component.css'],
               providers: [DialogService]
           })
export class ClinicsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    // submitted = false;
    selectedIndex = -1;
    updateClinicId = null;
    usersList: any = [];
    agencyList: any = [];
    clinicTypeList: any = [];
    clinicsList: any = [];
    clinicsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    clinicsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    clientId: number;
    loggedInUserType : any;
    loggedInRefId : any;
    loggedInUserId : any;
    addEditTitle = 'Add';

    ref: DynamicDialogRef;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;


    constructor(
        private clinicsServices: ClinicsService,
        private usersServices: UsersService,
        private agencyServices: AgencyService,
        private clinicTypeServices: ClinicTypeService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private dialogService: DialogService,
        private sessionService: SessionService,
    ) {
    };

    get addUpdateTitle() {
        return this.updateClinicId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.clinicsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.findAllClinicsByClientId();
        // this.findAllClinics();
        // closeSearchOverlaydiv();
        this.showHideSearchDialog();

    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    onSubmit() {

        this.clinicsForm.markAllAsTouched();
        this.clinicsForm.controls['clientId'].setValue(this.clientId);
        console.log('submit called',this.clinicsForm);
        if (this.clinicsForm.valid) {
            this.addClinic();
            // this.submitted = false;
            this.onClearAdd();
        }
    }

    addClinic(): void {

      this.clinicsForm.controls['userId'].setValue(this.loggedInUserId);
      this.startLoader();

      if (this.updateClinicId === null) {

        this.clinicsServices.addClinicsCpa(this.clinicsForm.value).subscribe(res => {

          if (res.status === HttpStatusCode.Created) {

            this.messageService.add(
              {
                severity: 'success',
                summary: this.translate(this.labels.entityNameClinics) +
                  this.translate(this.labels.addedSuccessfully),
                // detail: this.translate(this.labels.entityNameClinics)
              });

            this.showHideAddDialog();
            this.refresh();



          }

        }, err => {
          this.messageService.add(
            {
              severity: 'error',
              summary: err,
              // detail: this.translate(this.labels.entityNameClinics)
            });
          this.stopLoader();
        });

      }

    }

    findAllClinicsByClientId(): void {


      this.startLoader();
      this.clinicsServices.findByClientId(this.clientId).subscribe(res => {

        if (res.status === HttpStatusCode.Ok) {

          this.clinicsList = res.body;
          this.totalRecords = this.clinicsList.length;

        }

      }, err => {
          this.messageService.add(
            {
              severity: 'error',
              summary: err,
              // detail: this.translate(this.labels.entityNameClinics)
            });
          this.stopLoader();
        });
    }

    addUpdateClinics(clinics) {
        console.log('clinics form :: > ', this.clinicsForm.value);
        console.log(clinics, 'clinics object');
        this.clinicsForm.controls['userId'].setValue(this.loggedInUserId);
        this.startLoader();
        if (this.updateClinicId == null) {
            this.clinicsServices.addClinics(this.clinicsForm.value,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
                if (res['message'] == 'Success') {
                    clinics = res['data'][0];
                    this.clinicsList.push(clinics);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameClinics) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameClinics)
                        });
                    // this.submitted = true;
                    this.showHideAddDialog();
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            clinics.clinicId = this.updateClinicId;
            this.clinicsServices.updateClinics(clinics).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.clinicsList[this.selectedIndex] = res['data'][0];
                    // this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameClinics) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameClinics)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateClinicId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    onPageChange(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllClinicsByClientId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.clinicsSearch.reset();
        this.findAllClinicsByClientId();
        // this.findAllClinics();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        // this.findAllUsers();
        // this.findAllAgency();
        this.loggedInUserId = parseInt(this.sessionService.getSession('loggedInUser'));
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.clientId = parseInt(this.sessionService.getSession('clientId'));

        this.findAllClinicType();
        this.clinicsForm = new FormGroup({
                                             clientId: new FormControl(),
                                             userId: new FormControl(),
                                             agencyId: new FormControl(),
                                             clinicName: new FormControl('', [Validators.required, validateSpaces]),
                                             contactName: new FormControl('', [Validators.required, validateSpaces]),
                                             specialistType: new FormControl(),
                                             telNumber: new FormControl('', [Validators.required, validateSpaces]),
                                             address: new FormControl('', [Validators.required, validateSpaces]),
                                             notes: new FormControl('', [Validators.required, validateSpaces]),
                                             clinicTypeId: new FormControl('', [Validators.required]),
                                         });
        this.clinicsSearch = new FormGroup({
                                               userId: new FormControl(),
                                               agencyId: new FormControl(),
                                               clinicName: new FormControl(),
                                               contactName: new FormControl(),
                                               specialistType: new FormControl(),
                                               telNumber: new FormControl(),
                                               address: new FormControl(),
                                               notes: new FormControl(),
                                               clinicTypeId: new FormControl(),
                                           });
        this.findAllClinicsByClientId();
        // this.findAllClinics();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.clinicsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllClinics() {
        this.startLoader();
        this.clinicsServices.findAllClinics(this.clinicsSearch.value, this.pageNo, this.itemPerPage,this.loggedInRefId,this.loggedInUserType).subscribe(
            res => {
                console.log(res, 'clinic listing');
                if (res['message'] == 'Success') {
                    this.clinicsList = res['data'];
                } else {
                    this.clinicsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdClinics(clinicId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.clinicsServices.deleteByIdClinics(clinicId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.clinicsList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameClinics) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameClinics)
                            });
                        this.refresh();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadClinicsToEdit(clinicId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        console.log('load clinics called');
        let clinics = this.clinicsList.find(x => x['clinicId'] == clinicId);
        // this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateClinicId = clinics['clinicId'];
        this.selectedIndex = index;
        if (clinics['userId']) {
            this.clinicsForm.controls['userId'].setValue(clinics['userId']);
        }
        if (clinics['agencyId']) {
            this.clinicsForm.controls['agencyId'].setValue(clinics['agencyId']['agencyId']);
        }

        if(clinics['clinicName']){
            this.clinicsForm.controls['clinicName'].setValue(clinics['clinicName']);
        }

        if(clinics['contactName']){
            this.clinicsForm.controls['contactName'].setValue(clinics['contactName']);
        }
        if(clinics['specialistType']){
            this.clinicsForm.controls['specialistType'].setValue(clinics['specialistType']);
        }
        if(clinics['telNumber']){
            this.clinicsForm.controls['telNumber'].setValue(clinics['telNumber']);
        }
        if(clinics['address']){
            this.clinicsForm.controls['address'].setValue(clinics['address']);
        }
        if(clinics['notes']){
            this.clinicsForm.controls['notes'].setValue(clinics['notes']);
        }

        if (clinics['clinicTypeId']) {
            this.clinicsForm.controls['clinicTypeId'].setValue(clinics['clinicTypeId']['clinicTypeId']);
        }
        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.clinicsForm.reset();
        this.clinicsForm.markAsUntouched();
        this.updateClinicId = null;
        closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllClinicType() {
        let temp = [];
        this.startLoader();
        this.clinicTypeServices.findAllClinicType(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['clinicTypeId'];
                        dropDown.label = object['clinicType'];
                        this.clinicTypeList.push(dropDown);
                    }
                } else {
                    this.clinicTypeList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.clinicsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.clinicsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.clinicsForm.get(control)?.valid && this.clinicsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.clinicsForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.clinicsSearch.reset();
    }

    refresh() {
        this.findAllClinicsByClientId();
    }

    onAddOverlayDiv() {
        this.clinicsForm.reset();
        openAddOverlaydiv();
    }

    viewClinicDetail(clinicId){
        this.ref = null;
        let clinic = this.clinicsList.find(x => x['clinicId'] == clinicId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Clinic Detail',
            width: '80%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {

                mainData: [
                    {label: this.labels.clinicsColumnClinicName, value: clinic['clinicName']},
                    {label: this.labels.clinicsColumnContactName, value: clinic['contactName']},
                    {label: this.labels.clinicsColumnTelNumber, value: clinic['telNumber']},
                    {label: this.labels.clinicsColumnAddress, value: clinic['address']},
                    {label: this.labels.clinicsColumnClinicTypeId, value: clinic['clinicTypeId']['clinicType']},
                    {label: this.labels.clinicsColumnNotes, value: clinic['notes']},
                ]

             }
        });
        this.ref.onClose.subscribe(res => {
            // this.countInvoice();
        });
    }

    searchTerm : any = null;
    onGlobalSearchClick(searchTerm){

        this.searchTerm = searchTerm;
        this.clinicGlcount();

    }

    clinicGlcount(){
        this.clinicsServices.clinicGlcount(this.searchTerm,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findGlClinic();
            } else {
                this.totalRecords = 0;
                this.clinicsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findGlClinic(){
        this.clinicsServices.findGlobalclinic(this.searchTerm, this.pageNo, this.itemPerPage,this.loggedInRefId,this.loggedInUserType).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.clinicsList = result['data'];
            }else{
                this.clinicsList = [];
            }
            console.log(result,"result");
        });
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.clinicsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }
}
