import {Component, OnInit, Output, EventEmitter, OnDestroy, Input, AfterViewInit} from '@angular/core';
import Painterro from 'painterro'
import { SharedService } from '../../web-services/shared.service';
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-painterro-component',
  templateUrl: './painterro-component.component.html',
  styleUrls: ['./painterro-component.component.css']
})
export class PainterroComponentComponent implements OnInit,OnDestroy,AfterViewInit  {
  @Output() fileUrlEmitter = new EventEmitter();
  @Input() defaultImage;
  constructor(private sharedService: SharedService,
              private messageService: MessageService) { }
  ngOnDestroy(): void {
    // console.log("destored");

  }

  paintErro: any;

  ngOnInit(): void {
    // console.log("in painterroo");
    // console.log("Default image :: ",this.defaultImage);
    this.newPainterro();
  }
  newPainterro() {
    this.paintErro = Painterro({
      fixMobilePageReloader: false,
      id: 'customPainterro',
      colorScheme: {
        main: '#fdf6b8',
        control: '#FECF67' // change controls color
      },
      hiddenTools: ['close'],
      defaultTool: 'select',

      saveHandler: (image, done) => {
        // of course, instead of raw XHR you can use fetch, jQuery, etc
        // var xhr = new XMLHttpRequest();
        // console.log(image, done);
        const type = 'image/png';
        const files = new File([image.asBlob(type)], image.suggestedFileName(), {
          type: type,
        });
        let url = this.sharedService.uploadFile(files);
        url.subscribe(res => {

          let fileUrl = res;
          this.fileUrlEmitter.emit(fileUrl);
          this.messageService.add({
            severity: 'success',
            summary: "Body Chart saved Please complete the rest of the form "
          });



        });
      }
    });
    // if(this.defaultImage){
    //   // console.log("inside default image");
    //   this.paintErro.show(this.defaultImage);
    //   // ptro.onzoom();
    // }else{
    //   this.paintErro.show();
    // }



  }
  ngAfterViewInit() {
    if(this.defaultImage){
      // console.log("inside default image");
      // this.paintErro.show();
      this.paintErro.show(this.defaultImage);
      // ptro.onzoom();
      // this.paintErro.defaultTool('zoomOut');
      // this.paintErro.defaultTool["select"];
    }else{
      this.paintErro.show();
    }
  }

}
