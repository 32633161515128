import {Component, OnInit} from '@angular/core';
import {Labels} from '../util/labels';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Constant} from '../util/constant';
import {InvoiceItemsDetailService} from '../web-services/invoice-items-detail.service';
import {InvoiceService} from '../web-services/invoice.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConstantService} from '../web-services/constant.service';
import {SharedService} from '../web-services/shared.service';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {validateSpaces} from '../util/custom.validator';

// declare var openAddOverlaydiv: Function;
// declare var openDialogAddOverlaydiv: Function;
// declare var closeDialogAddOverlaydiv: Function;
// declare var openDialogSearchOverlaydiv: Function;
// declare var closeDialogSearchOverlaydiv: Function;

@Component({
               selector: 'app-printpreview',
               templateUrl: './printpreview.component.html',
               styleUrls: ['./printpreview.component.css']
           })
export class PrintpreviewComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateInvoiceItemsDetailId = null;
    invoiceList: any = [];
    // listOfValuesDetailList: any = [];
    itemsList: any = [];
    invoiceItemsDetailList: any = [];
    invoiceItemsDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    invoiceItemsDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    invoiceId: any;
    invoiceObj: any;
    invoiceIssueDate: any;
    width: any;
    hidePrintButton = false;

    constructor(
        private invoiceItemsDetailServices: InvoiceItemsDetailService,
        private invoiceServices: InvoiceService,
        private lovServices: ListOfValuesService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private dialogService: DialogService
    ) {
    };

    // ref: DynamicDialogRef;

    convertDate(issueDate) {
        let date = new Date(issueDate);
        // console.log(date.toString());
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();
        this.invoiceIssueDate = yyyy + '/' + mm + '/' + dd;
    }

    ngOnInit() {
        this.width = window.innerWidth;
        // console.log(this.config.data.invoiceObj, 'invoice object');
        this.invoiceObj = this.config.data.invoiceObj;
        this.invoiceId = this.config.data.invoiceObj.invoiceId;
        this.convertDate(this.config.data.invoiceObj.issueDate);
        this.findItems();
        this.invoiceItemsDetailForm = new FormGroup({
                                                        invoiceId: new FormControl(this.invoiceId),
                                                        itemId: new FormControl(),
                                                        quantity: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        unitPrice: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        discount: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        taxAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        totalAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        additionalNotes: new FormControl('', [Validators.required, validateSpaces]),
                                                        // userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                    });
        this.invoiceItemsDetailSearch = new FormGroup({
                                                          invoiceId: new FormControl(this.invoiceId),
                                                          itemId: new FormControl(),
                                                          quantity: new FormControl(0),
                                                          unitPrice: new FormControl(0),
                                                          discount: new FormControl(0),
                                                          taxAmount: new FormControl(0),
                                                          totalAmount: new FormControl(0),
                                                          additionalNotes: new FormControl(),
                                                          // userId: new FormControl(),
                                                      });
        this.invoiceItemsDetailSearch.controls['invoiceId'].setValue(this.invoiceId);
        this.invoiceItemsDetailForm.controls['invoiceId'].setValue(this.invoiceId);
        console.log(this.invoiceItemsDetailSearch, 'seacrh value patched');
        this.countInvoiceItemsDetail();
        // this.findAllInvoiceItemsDetail();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.invoiceItemsDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    get addUpdateTitle() {
        return this.updateInvoiceItemsDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }


    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countInvoiceItemsDetail() {
        this.startLoader();
        this.invoiceItemsDetailSearch.controls['quantity'].setValue(0);
        this.invoiceItemsDetailSearch.controls['unitPrice'].setValue(0);
        this.invoiceItemsDetailSearch.controls['discount'].setValue(0);
        this.invoiceItemsDetailSearch.controls['taxAmount'].setValue(0);
        this.invoiceItemsDetailSearch.controls['totalAmount'].setValue(0);

        this.invoiceItemsDetailServices.countInvoiceItemsDetail(this.invoiceItemsDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllInvoiceItemsDetail();
            } else {
                this.totalRecords = 0;
                this.invoiceItemsDetailList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }


    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllInvoiceItemsDetail();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.invoiceItemsDetailSearch.reset();
        this.countInvoiceItemsDetail();
        // this.findAllInvoiceItemsDetail();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findAllInvoiceItemsDetail() {
        this.startLoader();
        this.invoiceItemsDetailServices.findAllInvoiceItemsDetail(
            this.invoiceItemsDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.invoiceItemsDetailList = res['data'];
                } else {
                    this.invoiceItemsDetailList = [];
                }
                this.stopLoader();
                console.log(this.invoiceItemsDetailList, 'invoice detail list');
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllInvoice() {
        let temp = [];
        this.startLoader();
        this.invoiceServices.findAllInvoice(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['invoiceId'];
                        dropDown.label = object['invoiceId'];
                        this.invoiceList.push(dropDown);
                    }
                    // console.log(this.invoiceList, 'invoice list');
                } else {
                    this.invoiceList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findItems() {
        console.log('finditems called');
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Items').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.itemsList.push(dropDown);
                    }
                    console.log(this.itemsList, 'items list');
                } else {
                    this.itemsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    refresh() {
        this.countInvoiceItemsDetail();
    }

    onSearchOverlayDiv() {
        // openDialogSearchOverlaydiv();
    }

    onCloseSearchOverlayDiv() {
        // closeDialogSearchOverlaydiv();
    }

    onAddOverlayDiv() {
        // openDialogAddOverlaydiv();
        this.invoiceItemsDetailForm.reset();
    }

    onCloseAddOverlayDiv() {
        // closeDialogAddOverlaydiv();
    }

    async printWindow() {
        let userAgentString = navigator.userAgent;
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        // let originalContent = document.body.innerHTML;
        // let printData = document.getElementById('print-data').innerHTML;
        // document.body.innerHTML = printData;
        //    await window.print();
        console.log('Await');
        // document.body.innerHTML = originalContent;
        // setTimeout(() => {
        //     window.location.reload();
        // }, 5000)
        document.body.innerHTML = document.getElementById('print-data').innerHTML;
        // let printWindow = document.getElementById('print-data').innerHTML;
        console.log('Outside Agent if ::', userAgentString)
        console.log('Outside Agent if 2 ::', safariAgent)
        if ((chromeAgent) && (safariAgent)) {
            console.log('batman')
            safariAgent = false;
        }
        if (safariAgent) {
            console.log('execCommand ::')
            // window.location.reload();
            window.print()
            // setTimeout(() => {
                window.location.reload();
                // window.close();
            // }, 5000);
        }
        if (!safariAgent) {
            console.log('Inside Agent not safari')

            // let printContents = document.getElementById('print-data').innerHTML;
            // let originalContents = document.body.innerHTML;
            //
            // document.body.innerHTML = printContents;

            // window.print();

            // document.body.innerHTML = originalContents;

            window.print();
            window.location.reload();
            // window.close();
        }
    }  
}