import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../sharedservice/shared.service';
import { AppSettings } from '../../utilities/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private http : HttpClient , private sharedService : SharedService) {

   }

  changePassword(data)
  {
     return this.http.post<string>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID)+"UMS/rest/ForgetPasswordService/changepassword",data);
  }
}
