import { Component, Input, OnInit } from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AppointmentsService} from '../web-services/appointments.service';
import {TimeSlotService} from '../web-services/time-slot.service';
import {DatePipe} from '@angular/common';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.css'],
  providers : [DynamicDialogRef,DynamicDialogConfig]
})

export class TimeSlotsComponent implements OnInit {

  @Input() dataObj;

  constructor(
              private formBuilder: FormBuilder,
              private appointmentsServices: AppointmentsService,
              private timeSlotService : TimeSlotService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig
  ) {
  };


  cities: City[];
  public form: FormGroup;
  dropdown: any;
  selectedCities3 = [];

  display: boolean = false;

  showDialog() {
    this.display = true;
  }

 /// ref: DynamicDialogRef;

  ngOnInit(): void {

    console.log("data obj :: ",this.dataObj);

    this.form = this.formBuilder.group({
      selectedCities: [
        [{ name: 'New York', code: 'NY' }, { name: 'Rome', code: 'RM' }]
      ]
    });

    // this.openpainterro()
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
  }

  get items() {
    return this.form.get('selectedCities') as FormArray;
  }

  adddropdown() {
    this.cities.push({ name: this.dropdown, code: this.dropdown });

    this.selectedCities3 = this.form.controls['selectedCities'].value;
    this.selectedCities3.push({ name: this.dropdown, code: this.dropdown });
    this.form.controls['selectedCities'].patchValue(this.selectedCities3);
    console.log(this.form.value);

    this.display = false;
  }
}
