<!-- <app-app-header></app-app-header> -->
<section class="sectionmain" >

  <div class="container">
    <div class="">
      <!--        <div class="topDate">-->
      <!--          <p>14:10 3<sup>rd</sup> of June 2020</p>-->
      <!--        </div>-->
      <!--
            Stepper
        -->
      <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
        [readonly]="false">
      </p-steps>
      <!-- STEPPER IMPLEMENTATION STARTS HERE -->

    </div>
    <div class="clientDetailBox">
      <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
          <div class="clientName">
            <p>{{patientObj?.firstName + ' '+ patientObj?.lastName}}</p>
            <p-button label="{{btnStatus}}" class="statusBtnCommon"
              [ngClass]="{'p-tag-warning': btnStatus == 'Incomplete','p-tag-success':  btnStatus == 'Active'}">
            </p-button>
          </div>
        </div>
      </div>

      <div class="p-grid p-details">
        <div class="p-col-12 p-md-6 p-lg-6 leftGroup">
          <p>DOB: {{utils.returnDateFromMilliSecond(patientObj?.dob)}}</p>
          <p>Admission Date: 28th June 1984</p>
          <p>Practionar: {{expertObj?.firstName + ' ' + expertObj?.lastName}}</p>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6 rightGroup">
          <p>Client Reference: {{patientObj?.uniqueRefNumber}}</p>
          <p>Age: {{utils.getAge(patientObj?.dob)}}</p>
          <p>Last Seen: 28th June 1984</p>
          <p>Source: {{patientObj?.referralSource?.value}}</p>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12 bottomBtnGroup">
          <p-button class="clientCommomBtn bottomBtn" label="Discharge"></p-button>
          <p-button class="clientCommomBtn bottomBtn" label="Lock"></p-button>
          <p-button class="clientCommomBtn bottomBtn" label="Audit Case"></p-button>
          <p-button class="clientCommomBtn bottomBtn" label="Edit"></p-button>
        </div>
      </div>
    </div>



  </div>
  <div *ngIf="activeStep === 0">

<section class="sectionmain p-mt-3 bodychart-ui" id="sectionmain-communcationPage">
  <div class="container">
    <!-- Carousel Testing -->
    <div class="body-chart-summary">
      <p-carousel class="carousel-btns" [value]="allData" [numVisible]="1" [numScroll]="1" [circular]="false"
        orientation="horizontal" [responsiveOptions]="responsiveOptions">
        <!-- <ng-template pTemplate="header">
      <h5>Basic</h5>
  </ng-template> -->
        <ng-template let-data let-rowIndex="rowIndex" pTemplate="item">
          <!-- <div class="body-chart-summary"> -->
          <div class="p-grid">
            <div class="p-col-12 p-md-6">
              <h4 class="body-chart-headings"> Body Chart Summary</h4>
              <div class="p-grid">
                <div class="p-col-12 p-md-4">

                  <div class="summary-wrap">
                    <p class="symptom-title-right">24h Symptoms</p>
                    <div class="summary-right-col" dir="rtl">
                      <ul>
                        <li>Burning</li>
                        <li>Stabbing</li>
                      </ul>
                    </div>
                  </div>

                  <div class="summary-wrap">
                    <p class="symptom-title-right">{{data.name}}</p>
                    <div class="summary-right-col" dir="rtl">
                      <ul>
                        <li>Flag 1</li>
                        <li>Flag 2</li>
                      </ul>
                    </div>
                  </div>

                  <div class="summary-wrap">
                    <p class="symptom-title-right">{{data.name}}</p>
                    <div class="summary-right-col" dir="rtl">
                      <ul>
                        <li>Flag 1</li>
                        <li>Flag 2</li>
                      </ul>
                    </div>
                  </div>

                </div>

                <div class="p-col-12 p-md-4">
                  <img class="bodychart-img" src="assets/images/logo/bodychart.png">
                </div>

                <div class="p-col-12 p-md-4">
                  <div class="summary-wrap">
                    <p class="symptom-title-left">{{data.name}}</p>
                    <div class="summary-left-col">
                      <ul>
                        <li>Burning</li>
                        <li>Stabbing</li>
                      </ul>
                    </div>
                  </div>

                  <div class="summary-wrap">
                    <p class="symptom-title-left">{{data.name}}</p>
                    <div class="summary-left-col">
                      <ul>
                        <li>Lifting</li>
                        <li>Carrying</li>
                      </ul>
                    </div>
                  </div>

                  <div class="summary-wrap">
                    <p class="symptom-title-left">{{data.name}}</p>
                    <div class="summary-left-col">
                      <ul>
                        <li>Medication</li>
                        <li>Heating</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- <div class="p-col-12 p-md-2"></div> -->
              </div>

            </div>
            <div class="p-col-12 p-md-6">
              <h4 class="body-chart-headings pain-score-top">Pain Scores</h4>

              <div class="happiness-metre">

                <div class="metre-item metre-item-second">1
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">1/10 <br> Extreme Pain</span>
                </div>
                <div class="metre-item metre-item-second">2
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">2/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">3
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">3/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item">4
                  <span class="metre-btn-random" style="display: block;">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png" style="display: initial;">
                  </span>
                  <span class="metre-img-text" style="display: block;">4/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">5
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">5/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">6
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">6/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">7
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">7/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">8
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">8/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">9
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">9/10 <br> Very Intense Pain</span>
                </div>
                <div class="metre-item metre-item-second">10
                  <span class="metre-btn-random">abc</span>
                  <span class="metre-img">
                    <img src="assets/images/logo/emoji-1.png">
                  </span>
                  <span class="metre-img-text">10/10 <br>All Good</span>
                </div>
              </div>

            </div>
          </div>
          <!-- </div> -->
        </ng-template>
      </p-carousel>
    </div>
    <div class="body-chart-edit-btn">
      <p-button class="pngBlueBtn" label="Edit"  icon="pi pi-pencil" styleClass="p-button"></p-button>
    </div>
    <!-- Pain Scores and Goals Analytics Carousel -->
    <div class="body-chart-summary">
      <div class="p-grid">
        <div class="p-col-12 p-md-6">
          <h4 class="body-chart-headings">Pain Scores</h4>
          <div class="p-grid">

            <div class="p-col-5 p-md-0"></div>
            <div class="p-col-2">
              <div class="pain-score-bar">
                <span class="metre-btn-random2">abc</span>
                <span class="percentage-value">72%</span>
              </div>
            </div>
            <div class="p-col-5 p-md-0"></div>
          </div>
        </div>
        <div class="p-col-12 p-md-6 goals-carousel">
          <h4 class="body-chart-headings">Goals</h4>
          <p-carousel class="carousel-btns" [value]="allData" [numVisible]="1" [numScroll]="1" [circular]="false"
            [responsiveOptions]="responsiveOptions">
            <!-- <ng-template pTemplate="header">
            <h5>Basic</h5>
        </ng-template> -->
            <ng-template pTemplate="item">
              <div class="goals-bar">
                <div>
                  <p-chart type="bar" [data]="barData" [options]="options" [responsive]="true"></p-chart>
                </div>
              </div>
            </ng-template>
          </p-carousel>

        </div>
      </div>
    </div>
  </div>
  </section>
  </div>

  <div *ngIf="activeStep === 0">
    <!--Initial Assessment -->

    <app-initial-assessment *ngIf="stepper" ></app-initial-assessment>

  </div>

  <div *ngIf="activeStep === 1">
    <!--Client Detail -->

    <app-client-details *ngIf="stepper"></app-client-details>

  </div>
  <div *ngIf="activeStep === 2">
    <!-- History Tabs -->

    <app-history *ngIf="stepper"></app-history>

  </div>
  <div *ngIf="activeStep === 3">
    <!-- Body Chart -->

    <app-body-chart-detail *ngIf="stepper"></app-body-chart-detail>

  </div>
  <div *ngIf="activeStep === 4">
    <!-- Observations Tabs -->

    <app-observations-main *ngIf="stepper"></app-observations-main>

  </div>
  <div *ngIf="activeStep === 5">
    <!-- Testing Tabs -->

    <app-testing *ngIf="stepper"></app-testing>

  </div>
  <div *ngIf="activeStep === 6">
    <!-- Treatment -->

    <app-treatment *ngIf="stepper"></app-treatment>

  </div>
</section>
