<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="contactsDialog" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameContacts)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton pTooltip="Advanced Search"
                                        tooltipPosition="bottom" type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton pTooltip="{{addEditTitle}} {{translate(labels.entityNameContacts)}}"  tooltipPosition="bottom"
                                        type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameContacts)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="contactsSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsFirstname)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsFirstname')"
                                                    *ngIf="diplayeIconInField('contactsFirstname')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="contactsFirstname" name="contactsFirstname"
                                                    pInputText type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsLastname)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsLastname')"
                                                    *ngIf="diplayeIconInField('contactsLastname')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="contactsLastname" name="contactsLastname"
                                                    pInputText type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsOccupation)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsOccupation')"
                                                    *ngIf="diplayeIconInField('contactsOccupation')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="contactsOccupation" name="contactsOccupation"
                                                    pInputText type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsCompany)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsCompany')"
                                                    *ngIf="diplayeIconInField('contactsCompany')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="contactsCompany" name="contactsCompany" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsEmail')"
                                                    *ngIf="diplayeIconInField('contactsEmail')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="contactsEmail" name="contactsEmail" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsAddress')"
                                                    *ngIf="diplayeIconInField('contactsAddress')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="contactsAddress" name="contactsAddress" pInputText
                                                    type="text"> </span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" type = "submit"  class="pngBlueBtn" styleClass="p-button"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()"  class="pngBlueBtn" styleClass="p-button">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" class="pngBtnTransparent" styleClass="p-button">
                                        </p-button>

                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"   class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameContacts)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="contactsForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsFirstname)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsFirstname')"
                                                    *ngIf="diplayeIconInField('contactsFirstname')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('contactsFirstname')"
                                                    class="pngInputtext" formControlName="contactsFirstname"
                                                    name="contactsFirstname" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'contactsFirstname'" [form]="contactsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsLastname)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsLastname')"
                                                    *ngIf="diplayeIconInField('contactsLastname')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('contactsLastname')"
                                                    class="pngInputtext" formControlName="contactsLastname"
                                                    name="contactsLastname" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'contactsLastname'" [form]="contactsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsOccupation)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsOccupation')"
                                                    *ngIf="diplayeIconInField('contactsOccupation')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('contactsOccupation')"
                                                    class="pngInputtext" formControlName="contactsOccupation"
                                                    name="contactsOccupation" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'contactsOccupation'" [form]="contactsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsCompany)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsCompany')"
                                                    *ngIf="diplayeIconInField('contactsCompany')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('contactsCompany')"
                                                    class="pngInputtext" formControlName="contactsCompany"
                                                    name="contactsCompany" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'contactsCompany'" [form]="contactsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsEmail')"
                                                    *ngIf="diplayeIconInField('contactsEmail')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('contactsEmail')"
                                                    class="pngInputtext" formControlName="contactsEmail"
                                                    name="contactsEmail" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'contactsEmail'" [form]="contactsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.contactsColumnContactsAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('contactsAddress')"
                                                    *ngIf="diplayeIconInField('contactsAddress')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('contactsAddress')"
                                                    class="pngInputtext" formControlName="contactsAddress"
                                                    name="contactsAddress" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'contactsAddress'" [form]="contactsForm">
                                            </app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  class="pngBtnTransparent"
                                                  styleClass="p-button">
                                        </p-button>

                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    
                                        <span
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global
                                            class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" /></span>
                                    
                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0"
                                         (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" 
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [alwaysShowPaginator]="false"
                                         [totalRecords]="totalRecords"
                                         [value]="contactsList" class="pngTable" id="invitationpage-memberslist-table" [ngClass]="{'pngTableEmptyMessage': contactsList?.length == 0}"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> First Name</th>
                                            <th> Last Name</th>
                                            <th> Occupation</th>
                                            <th> Company</th>
                                            <th> Email</th>
                                            <th> Address</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.contactsFirstname}} </td>
                                            <td> {{element?.contactsLastname}} </td>
                                            <td> {{element?.contactsOccupation}} </td>
                                            <td> {{element?.contactsCompany}} </td>
                                            <td> {{element?.contactsEmail}} </td>
                                            <td> {{element?.contactsAddress}} </td>
                                            <td>
                                                
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadContactsToEdit(element.contactsId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdContacts(element.contactsId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                    (click)="viewContactDetail(element.contactsId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>