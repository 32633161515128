import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { ExpensesService } from '../web-services/expenses.service';
import { ClinicsService } from '../web-services/clinics.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { AgencyService } from '../web-services/agency.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ExpensesDetailComponent } from '../expenses-detail/expenses-detail.component';
import {Utils} from '../util/utils';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
    selector: 'app-expenses',
    templateUrl: './expenses.component.html',
    styleUrls: ['./expenses.component.css'],
    providers: [DialogService]
})
export class ExpensesComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateExpensesId = null;
    clinicsList: any = [];
    expensesList: any = [];
    expensesSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    expensesForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    ref: DynamicDialogRef;

    expense_type_array: any = [];
    currentLoggedInAgencyId: any = 0;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;

    addEditTitle = 'Add';

    referenceId = 0;
    companyName : any;
    expertId : any;

    constructor(
        private expensesServices: ExpensesService,
        private clinicsServices: ClinicsService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovService: ListOfValuesService,
        private agencyService: AgencyService,
        private dialogService: DialogService,
    ) {
    };

    get addUpdateTitle() {
        return this.updateExpensesId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.expensesSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countExpenses();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }
    utils = new Utils();
    countExpenses() {
        if (!this.route.url.includes('/subscription')) {
            this.expensesSearch.controls['referenceId'].setValue(parseInt(sessionStorage.getItem('loggedInRefId')));
        }
        // this.expensesSearch.controls['userId'].setValue(parseInt(sessionStorage.getItem('loggedInUser')));
        let obj =this.utils.getUpdatedMillissecondObject(this.expensesSearch.value,"paidOn");
        if(obj['paidOn'] === '0'){
            delete obj['paidOn'];
        }
        this.expensesServices.countExpenses(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllExpenses();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.expensesForm.markAllAsTouched();
        console.log('expenses form value :: ', this.expensesForm.value);
        this.expensesForm.controls['referenceId'].setValue(parseInt(sessionStorage.getItem('loggedInRefId')));
        // this.expensesForm.controls['userId'].setValue(parseInt(sessionStorage.getItem('loggedInUser')));
        if (this.expensesForm.valid) {
            this.addUpdateExpenses(this.expensesForm.value);
            // this.submitted = false;
            this.onClearAdd();
        }

    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllExpenses();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.expensesSearch.reset();

        this.countExpenses();
        // this.findAllExpenses();
    }

    addUpdateExpenses(expenses) {
        this.startLoader();
        let obj =this.utils.getUpdatedMillissecondObject(this.expensesForm.value,"paidOn");
        if (this.updateExpensesId == null) {
            this.expensesServices.addExpenses(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    expenses = res['data'][0];
                    this.expensesList.push(expenses);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameExpenses) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameExpenses)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countExpenses();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.expensesId = this.updateExpensesId;
            this.expensesServices.updateExpenses(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.expensesList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameExpenses) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameExpenses)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateExpensesId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    async ngOnInit() {
        this.pageControlMap = this.sharedService.checkRole(this.pageControlMap);
        this.addEditTitle = this.labels.btnAdd;
        this.companyName = sessionStorage.getItem('firstName');
        // this.findAllClinics();

        this.referenceId = parseInt(sessionStorage.getItem('loggedInRefId'));
        this.expensesForm = new FormGroup({
            appointmentId: new FormControl(0),
            clinicId: new FormControl(),
            paidOn: new FormControl('', [Validators.required]),
            company: new FormControl(0),
            source: new FormControl('', [Validators.required, validateSpaces]),
            userId: new FormControl(0),
            expenseType: new FormControl('', [Validators.required]),
            expenseAmount: new FormControl(),
            referenceId: new FormControl(),
        });
        this.expensesSearch = new FormGroup({
            appointmentId: new FormControl(),
            clinicId: new FormControl(),
            paidOn: new FormControl(),
            company: new FormControl(),
            source: new FormControl(),
            userId: new FormControl(),
            expenseType: new FormControl(),
            expenseAmount: new FormControl(),
            referenceId: new FormControl()
        });

        this.expensesSearch.controls['referenceId'].setValue(parseInt(sessionStorage.getItem('loggedInRefId')));
        // this.expensesSearch.controls['userId'].setValue(parseInt(sessionStorage.getItem('loggedInUser')));
        // await this.findCurrentAgency();
        if (this.route.url.includes('/subscription')) {
            this.expertId = parseInt(sessionStorage.getItem('loggedInRefId'));
            this.expensesServices.findAdminIdByExpertId(this.expertId).subscribe(res => {
                if (res['message'] == 'Success') {
                    console.log('result : ', res['data'][0].referenceId);
                    let refId = res['data'][0].referenceId;
                    this.expensesSearch.controls['referenceId'].setValue(refId);
                    this.countExpenses();
                } else {
                    this.countExpenses();
                }
            }, err => {
                console.error('err: ', err);
                this.countExpenses();
            });
        } else {
            this.countExpenses();
        }
        // this.countExpenses();
        this.getConstantList(1234);
        this.findAllListOfValues();
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.expensesSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllListOfValues() {
        this.startLoader();
        this.lovService.getAllListOfValuesByName(Constant.EXPENSE_LOV_ITMES).subscribe(result => {

            console.log("result of Lov values :: ", result);

            if (result['statusCode'] == 200) {
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];
                    console.log("element of data arrrray :: ", index, " index obj :: ", element);

                    if (element[Constant.EXPENSE_LOV_EXPENSE_TYPE]) {
                        console.log("sex array :: ", element[Constant.EXPENSE_LOV_EXPENSE_TYPE]);
                        // this.sex_array = element[Constant.PATIENT_SEX];
                        for (let object of element[Constant.EXPENSE_LOV_EXPENSE_TYPE]) {
                            let dropDown = { label: '', value: '' };
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.expense_type_array.push(dropDown);
                            console.log("drop down values array :: ", dropDown);
                        }

                    }
                }
            }

        });
    }

    findAllExpenses() {
        this.startLoader();
        if (!this.route.url.includes('/subscription')) {
            this.expensesSearch.controls['referenceId'].setValue(parseInt(sessionStorage.getItem('loggedInRefId')));
        }

        let obj =this.utils.getUpdatedMillissecondObject(this.expensesSearch.value,"paidOn");
        if(obj['paidOn'] === '0'){
            delete obj['paidOn'];
        }
        this.expensesServices.findAllExpenses(obj, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.expensesList = res['data'];
                } else {
                    this.expensesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteRef: any;
    deleteByIdExpenses(expensesId: number, index) {
        this.deleteRef = this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.expensesServices.deleteByIdExpenses(expensesId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.expensesList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameExpenses) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameExpenses)
                            });
                        this.countExpenses();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }

    loadExpensesToEdit(expensesId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let expenses = this.expensesList.find(x => x['expensesId'] == expensesId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateExpensesId = expenses['expensesId'];
        this.selectedIndex = index;

        if (expenses['appointmentId']) {
            this.expensesForm.controls['appointmentId'].setValue(expenses['appointmentId']);
        }

        if (expenses['clinicId']) {
            this.expensesForm.controls['clinicId'].setValue(expenses['clinicId']);
        }

        if (expenses['paidOn']) {
            this.expensesForm.controls['paidOn'].setValue(new Date(expenses['paidOn']));
        }

        if (expenses['company']) {
            this.expensesForm.controls['company'].setValue(expenses['company']);
        }

        if (expenses['source']) {
            this.expensesForm.controls['source'].setValue(expenses['source']);
        }
        if (expenses['userId']) {
            this.expensesForm.controls['userId'].setValue(expenses['userId']);
        }

        if (expenses['referenceId']) {
            this.expensesForm.controls['referenceId'].setValue(expenses['referenceId']);
        }

        if (expenses['expenseType']) {
            this.expensesForm.controls['expenseType'].setValue(expenses['expenseType']['listOfValuesId']);
        }

        if (expenses['expenseAmount']) {
            this.expensesForm.controls['expenseAmount'].setValue(expenses['expenseAmount']);
        }
        // openAddOverlaydiv();

        // by ali
        if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.expensesForm.reset();
        this.expensesForm.markAsUntouched();
        this.updateExpensesId = null;
        closeAddOverlaydiv();
    }

    // findAllClinics() {
    //     let temp = [];
    //     this.startLoader();
    //     this.clinicsServices.findAllClinics(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = {label: '', value: ''};
    //                     dropDown.value = object['clinicId'];
    //                     dropDown.label = object['clinicName'];
    //                     this.clinicsList.push(dropDown);
    //                 }
    //             } else {
    //                 this.clinicsList = [];
    //             }
    //             this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }

    fieldReset(control) {
        return this.expensesForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.expensesForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.expensesForm.get(control)?.valid && this.expensesForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.expensesForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.expensesSearch.reset();
    }

    refresh() {
        this.countExpenses();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.expensesForm.reset();
    }

    addExpenseDetail(event, element) {
        this.ref = this.dialogService.open(ExpensesDetailComponent, {
            header: 'Expense Detail',
            width: '90%',
            contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                expenseObj: element
            }
        });
        this.ref.onClose.subscribe(res => {
            this.countExpenses();
        });
        console.log(this.ref, 'onclose reference');
        this.ref.onClose.subscribe(res => {
            console.log(' before confirmation closing');
            this.confirmation.confirm(null).close();
            this.countExpenses();
        });
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log("global search clicked ", eventValue);
        this.searchTerm = eventValue;
        this.expenseGlCount();
    }

    expenseGlCount() {
        this.expensesServices.expenseGlcount(this.searchTerm, this.referenceId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchExpense();
            } else {
                this.totalRecords = 0;
                this.expensesList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchExpense() {
        this.expensesServices.findGlobalExpense(this.searchTerm, this.referenceId, this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.expensesList = result['data'];
            } else {
                this.expensesList = [];
            }
            console.log(result, "result");
        });
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.expensesForm.reset();
        }
        this.addEditTitle = 'Add';
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }
    pageControlMap = new Map<string, boolean>()
    .set("viewExpenses", false)
    .set("addExpenses", false)
    .set("deleteExpenses", false)
    .set("editExpenses", false);
}
