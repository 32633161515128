<!-- Header Component-->
<ngx-ui-loader></ngx-ui-loader>
<div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
  <div class="app-auth-background">

  </div>
  <div class="app-auth-container">
    <div class="logo">
      <a href="index.html">Physio Pro Assist</a>
    </div>
    <p class="auth-description">Please sign-in to your account and continue to the dashboard.<br>Don't have an account? <a href="sign-up.html">Sign Up</a></p>
    <form (blur)="logValidationErrors()" [formGroup]="LoginForm" class="formContainer">

    <div class="auth-credentials m-b-xxl">
      <label for="signInEmail" class="form-label">Email address</label>
      <small *ngIf="formErrors.Email"> {{formErrors.Email}}</small>
      <input (blur)="logValidationErrors()"

             formControlName="username"
             id="login-email-id" pInputText
             placeholder="cpa@clinicalproassist.com"
             type="email"
             class="form-control"
      />
      <label for="signInPassword" class="form-label">Password</label>
      <small *ngIf="formErrors.Password">{{formErrors.Password}}</small>
      <input (blur)="logValidationErrors()"
             formControlName="password"
             id="login-email-id" pInputText
             placeholder="Password"
             type="password"
             class="form-control"
             aria-describedby="signInPassword"
      />
    </div>
    <div *ngIf="loginError" class="m-2">
      <span class="error-text">Incorrect username or password please try again </span>
    </div>
    </form>
    <div class="auth-submit">
      <p-button [disabled]="!LoginForm.valid" (click)="onSubmit()"
                class="p-d-inline-flex p-ai-center pngBlueBtn" id="login-btn"
                label="Login"
                styleClass="p-button">
      </p-button>
      <a href="#" class="auth-forgot-password float-end" [routerLink]="[ '/','forgotPassword' ]" style="float: right !important;">Forgot password?</a>
    </div>
    <div class="divider"></div>
  </div>
</div>

<!-- <p-toast position="top-center"></p-toast> -->
