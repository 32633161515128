import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencyPatientsService} from '../web-services/agency-patients.service';
import {AgencyService} from '../web-services/agency.service';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component(
    {selector: 'app-agency-patients', templateUrl: './agency-patients.component.html', styleUrls: ['./agency-patients.component.css']})
export class AgencyPatientsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAgencyPatientsId = null;
    agencyList: any = [];
    usersList: any = [];
    agencyPatientsList: any = [];
    agencyPatientsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencyPatientsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private agencyPatientsServices: AgencyPatientsService, private agencyServices: AgencyService, private usersServices: UsersService,
        private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService, private messageService: MessageService,
        private route: Router, private loader: NgxUiLoaderService, private constantService: ConstantService,
        private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateAgencyPatientsId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.agencyPatientsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgencyPatients();
        this.findAllAgencyPatients();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countAgencyPatients() {
        this.agencyPatientsServices.countAgencyPatients(this.agencyPatientsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAgencyPatients();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateAgencyPatients(this.agencyPatientsForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAgencyPatients();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencyPatientsSearch.reset();
        this.countAgencyPatients();
        // this.findAllAgencyPatients();
    }

    addUpdateAgencyPatients(agencyPatients) {
        this.startLoader();
        if (this.updateAgencyPatientsId == null) {
            this.agencyPatientsServices.addAgencyPatients(this.agencyPatientsForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    agencyPatients = res['data'][0];
                    this.agencyPatientsList.push(agencyPatients);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameAgencyPatients) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameAgencyPatients)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countAgencyPatients();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            agencyPatients.agencyPatientsId = this.updateAgencyPatientsId;
            this.agencyPatientsServices.updateAgencyPatients(agencyPatients).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencyPatientsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameAgencyPatients) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameAgencyPatients)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countAgencyPatients();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencyPatientsId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllAgency();
        this.findAllUsers();
        this.agencyPatientsForm = new FormGroup(
            {
                agencyId: new FormControl(),
                patientId: new FormControl(0, [Validators.required, Validators.min(0)]),
                userId: new FormControl(),
            });
        this.agencyPatientsSearch = new FormGroup({agencyId: new FormControl(), patientId: new FormControl(), userId: new FormControl(),});
        this.countAgencyPatients();
        this.findAllAgencyPatients();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencyPatientsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllAgencyPatients() {
        this.startLoader();
        this.agencyPatientsServices.findAllAgencyPatients(this.agencyPatientsSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencyPatientsList = res['data'];
                } else {
                    this.agencyPatientsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgencyPatients(agencyPatientsId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencyPatientsServices.deleteByIdAgencyPatients(agencyPatientsId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.agencyPatientsList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.btnDelete),
                                                    detail: this.translate(this.labels.entityNameAgencyPatients)
                                                });
                        this.countAgencyPatients();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadAgencyPatientsToEdit(agencyPatientsId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let agencyPatients = this.agencyPatientsList.find(x => x['agencyPatientsId'] == agencyPatientsId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencyPatientsId = agencyPatients['agencyPatientsId'];
        this.selectedIndex = index;
        this.agencyPatientsForm.controls['agencyId'].setValue(agencyPatients['agencyId']['agencyId']);
        this.agencyPatientsForm.controls['patientId'].setValue(agencyPatients['patientId']);
        this.agencyPatientsForm.controls['userId'].setValue(agencyPatients['userId']['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.agencyPatientsForm.reset();
        this.agencyPatientsForm.markAsUntouched();
        this.updateAgencyPatientsId = null;
        closeAddOverlaydiv();
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.agencyPatientsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencyPatientsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencyPatientsForm.get(control)?.valid && this.agencyPatientsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.agencyPatientsForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.agencyPatientsSearch.reset();
    }

    refresh() {
        this.countAgencyPatients();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.agencyPatientsForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencyPatientsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAgencyPatientsId = null;
    }
}
