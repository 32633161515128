import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Constant } from '../util/constant';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  constructor(private httpClient: HttpClient) {};
  deleteByIdCommunications (communicationsId ):Observable<string> {
  return this.httpClient.post<string>(Constant.BASE_URL + "Communications/deleteCommunications", { 'communicationsId': communicationsId }, this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  countCommunications (communications ):Observable<number> {
  return this.httpClient.post<number>(Constant.BASE_URL + "Communications/count", {"communications": communications } , this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  updateCommunications (communications ):Observable<string> {
  return this.httpClient.post<string>(Constant.BASE_URL + "Communications/updateCommunications", [communications], this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  addCommunications (communications ):Observable<string> {
  return this.httpClient.post<string>(Constant.BASE_URL + "Communications/addCommunications", [communications], this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  findAllCommunications (communications ,pageNo :number ,itemsPerPage :number ):Observable<string[]> {
  return this.httpClient.post<string[]>(Constant.BASE_URL + "Communications/findAllCommunications",  { "communications":  communications , "pagination" :{ 'pageNo': pageNo, 'itemsPerPage': itemsPerPage }}, this.httpOptions).pipe(retry(3),catchError(Constant.handleError));
  }
  
}
