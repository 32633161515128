import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {InitialAssessmentSteps} from "../initial-assessment-steps/InitialAssessmentSteps";
import {SessionService} from "../services/session.service";
import {HttpStatusCode} from '@angular/common/http';
import {ComponentFormBase} from '../util/ComponentFormBase';
import {SharedService} from '../web-services/shared.service';
import {ClinicalDataService} from '../web-services/clinical-data.service';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  urlCalled = false;
  stepsItems: any;
  activeStep: number;
  patientObj: any;
  chosenBodyChartAnatomyDiagramId: any;
  existingBodyPartData: any = [];
  fullAppointment: any;
  bodyPart: any;


  constructor(
      private router: Router,
      private sessionService: SessionService,
      private clinicalDataService: ClinicalDataService,

      private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('/testing')) {
      this.urlCalled = true;
    }

    this.stepsItems = InitialAssessmentSteps.stepItems;
    this.activeStep = parseInt(this.stepsItems.find(step => step.route === 'testing').number);
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;
    this.fullAppointment = JSON.parse(this.sessionService.getSession('appointment'));
    this.getBodyChartDataByAppointmentId();

  }


  getBodyChartDataByAppointmentId(): void {

    this.clinicalDataService.getBodyChartDataByAppointmentId(this.fullAppointment.appointmentId).subscribe(response => {

      if (response.status === HttpStatusCode.Ok) {

        this.existingBodyPartData = response.body;

        this.sessionService.setSession('existingBodyPartData',JSON.stringify(this.existingBodyPartData));


      }


    }, err => {
      alert(err);
      console.error('err: ', err);

    });


  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }

  bodyPartChanged(id) {

    this.chosenBodyChartAnatomyDiagramId =  id

  }

}
