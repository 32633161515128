<div class="angDialog" id="deletedialog-angDialog">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content> 
         <P> {{ data.message }} ? </P>
    </div>
    
    <div mat-dialog-actions class="float-right footer-btns-modals">
        <button (click)="onConfirm()" class="btnmd-danger"> Yes </button>
        <button class="btnmd" (click)="onNoClick()">No</button> 
    </div>
</div>