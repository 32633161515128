<section class="sectionmain">
  <div class="content-body">
    <div class="container-fluid">
      <div>Welcome - To Onboarding </div>

      <div class="p-md-10 card-cpa-rounded card-shadow" style="background: white;margin-left:30px" >

        <form  (ngSubmit)="onSubmit()" [formGroup]="clientDetailsForm" class="pngForm"
              id="invitationpage-addmemeber-pngForm">
          <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
            <div class="p-field p-col-12 p-md-6 formfieldheight">
              <label>Company Name</label>
              <input class="pngInputtext"
                     pInputText
                     formControlName="companyName" name="companyName"
                     type="text">
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
            <div class="p-field p-col-12 p-md-6 formfieldheight">
              <label>Email Address</label>
              <input class="pngInputtext"
                     pInputText
                     formControlName="email" name="email"
                     type="text">
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
            <div class="p-field p-col-12 p-md-6 formfieldheight">
              <label>Address 1</label>
              <input class="pngInputtext"
                     pInputText
                     formControlName="address1" name="address1"
                     type="text">
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
            <div class="p-field p-col-12 p-md-6 formfieldheight">
              <label>Address 2</label>
              <input class="pngInputtext"
                     pInputText
                     formControlName="address2" name="address2"
                     type="text">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
            <div class="p-field p-col-12 p-md-6 formfieldheight">
              <label>City</label>
              <input class="pngInputtext"
                     pInputText
                     formControlName="city" name="city"
                     type="text">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
            <div class="p-field p-col-12 p-md-6 formfieldheight">
              <label>Postcode</label>
              <input class="pngInputtext"
                     pInputText
                     formControlName="postcode" name="postcode"
                     type="text">
            </div>
          </div>
          <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
            <p-button label="Update" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>

          </div>
        </form>

      </div>
    </div>
  </div>
</section>
