<section class="content-header" id="ssocontentheaderr">
    <div class="project-header" id="ssoprojectheader">
        <div class="project-logo" id="ssoprojectheader2">
            <img src={{project.image}}
                onerror="this.src='assets/theme1/custom-files/img/default_thumb/defaultproject.png'"
                id="ssocontentheaderimg" class="header-logo" alt="Project Logo">
        </div>
        <div class="project-content" id="ssoprojectcontent">
            <h1 class="main-heading">{{project.title}} </h1>
            <h2 class="sub-heading"> {{project.shortTitle}} </h2>
            <span *ngIf="project.status=='Draft'" matTooltip="Status" class="badgeinfoheader"
                id="sso-badge-projectstatus1">{{project.status}}</span>
            <span *ngIf="project.status=='Review'" matTooltip="Status" class="badgewarningdarkheader"
                id="sso-badge-projectstatus2">{{project.status}}</span>
            <span *ngIf="project.status=='Approved'" matTooltip="Status" class="badegesucessheader"
                id="sso-badge-projectstatus3">{{project.status}}</span>
            <span *ngIf="project.status=='Published'" matTooltip="Status" class="badegesucessdarkheader"
                id="sso-badge-projectstatus4">{{project.status}}</span>
            <span matTooltip="Current version" class="badgeinfoheader"
                id="sso-badge-startdate">{{sharedService.currentVersionTitle}}</span>
        </div>

        <div class="project-right-button" id="ssocontentheaderrightbtns">
            <ul id="sso-content-header-rightbtns-ul">
                <!-- <li class="sso-content-header-rightbtns-li">
                    <a href="#">
                        <i class="fas fa-sitemap"></i>
                    </a>
                </li>

                <li class="sso-content-header-rightbtns-li">
                    <a href="#">
                        <i class="fas fa-users"></i>
                    </a>
                </li>

                <li class="sso-content-header-rightbtns-li">
                    <a href="#">
                        <i class="fas fa-folder-open"></i>
                    </a>
                </li> -->
                <li class="sso-content-header-rightbtns-li">
                    <button mat-icon-button class="matDropdownmeubtn" id="header-matDropdownmeubtn"
                        [matMenuTriggerFor]="projectdropdownmenu">
                        <i class="vertical3dotsmenu-icon"></i>
                    </button>
                    <mat-menu #projectdropdownmenu="matMenu" class="angDropdownmenu"
                        id="sso-content-header-angDropdownmenu">
                        <ng-container *ngIf="currentVersion==0 && project?.type=='Project'">
                            <button mat-menu-item [matMenuTriggerFor]="headerproject">
                                <i class="project-icon"></i>
                                <span class="dropdownmenu-text"> Project </span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="project?.type=='Project'">
                            <button mat-menu-item [matMenuTriggerFor]="headerversion">
                                <i class="addversion-icon"></i>
                                <span class="dropdownmenu-text"> Version </span>
                            </button>
                        </ng-container>
<button mat-menu-item [matMenuTriggerFor]="headerreports">
                            <i class="reports-icon"></i>
                            <span class="dropdownmenu-text"> Reports </span>
                        </button>
                    </mat-menu>


                    <mat-menu class="child-dropdown" #headerproject="matMenu">
                        <button mat-menu-item (click)="openCopyProjectDialog()">
                            <i class="copyproject-icon"></i>
                            <span class="dropdownmenu-text"> Copy Project</span>
                        </button>
                    </mat-menu>
                    <mat-menu class="child-dropdown" #headerversion="matMenu">
                        <ng-container *ngIf="currentVersion==0">
                            <button mat-menu-item (click)="openAddVersionDialog()">
                                <i class="addversion-icon"></i>
                                <span class="dropdownmenu-text"> Add version</span>
                            </button>
                        </ng-container>
                        <button mat-menu-item (click)="openChangeVersionDialog()">
                            <i class="addversion-icon"></i>
                            <span class="dropdownmenu-text"> Change version</span>
                        </button>
                        <!-- <button mat-menu-item (click)="openDeleteVersionDialog()">
                            <i class="addversion-icon"></i>
                            <span class="dropdownmenu-text"> Delete version</span>
                        </button> -->
                        <!-- this will be delete i wrote here for testing purpose-->
                        <!-- <button mat-menu-item (click)="openAboutusDialog()">
                            <i class="fa fa-bars"></i>
                            <span class="dropdownmenu-text"> About Us for check purpose </span>
                        </button> -->
                        <!-- Delete this button was just opening About US popup u will open it from Menu-->

                    </mat-menu>

                    <mat-menu class="child-dropdown" #headerreports="matMenu">
                        <!-- <button mat-menu-item (click)="openReport('Project_Report')">
                            <i class="projectreport-icon"></i>
                            <span class="dropdownmenu-text"> Project Report</span>
                        </button>
                        <button mat-menu-item (click)="openReport('Tool_Standard_Report')">
                            <i class="toolstandardreport-icon"></i>
                            <span class="dropdownmenu-text"> Tool Standard Report</span>
                        </button> -->
                        <button mat-menu-item (click)="callProjectReport(802)">
                            <i class="projectreport-icon"></i>
                            <span class="dropdownmenu-text"> Project Report</span>
                        </button>
                        <!-- <button mat-menu-item (click)="openDialogReViewreportParameters(802,jsonParamter)">
                            <i class="toolstandardreport-icon"></i>
                            <span class="dropdownmenu-text"> Tool Standard Report</span>
                        </button> -->
                    </mat-menu>
                </li>
            </ul>
        </div>

    </div>

</section>
