<p-confirmDialog key="expertDialog" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameExpert)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn"
                                        icon="pi pi-search-plus" pButton
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pTooltip="Advanced Search" tooltipPosition="bottom" type="button"></button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton pTooltip="{{addEditTitle}} {{translate(labels.entityNameExpert)}}"  tooltipPosition="bottom"
                                        type="button"></button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameExpert)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="expertSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnExpertType)}}</label>

                                            <p-dropdown [filter]="true" [options]="expertTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="expertType" placeholder="Select">
                                            </p-dropdown>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnTitle)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('title')"
                                                    *ngIf="diplayeIconInField('title')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="title" name="title" pInputText type="text">
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnFirstName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('firstName')"
                                                    *ngIf="diplayeIconInField('firstName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="firstName" name="firstName" pInputText type="text">
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnLastName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('lastName')"
                                                    *ngIf="diplayeIconInField('lastName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="lastName" name="lastName" pInputText type="text">
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnOccupation)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('occupation')"
                                                    *ngIf="diplayeIconInField('occupation')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="occupation" name="occupation" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnCompanyName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyName')"
                                                    *ngIf="diplayeIconInField('companyName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="companyName" name="companyName" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('email')"
                                                    *ngIf="diplayeIconInField('email')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="email" name="email" pInputText type="text"> </span>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('address')"
                                                    *ngIf="diplayeIconInField('address')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="address" name="address" pInputText type="text">
                                            </span>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnProfessionalDetail)}}</label>

                                            <p-dropdown [filter]="true" [options]="professionalDetailList"
                                                [showClear]="true" class="pngDropdown"
                                                formControlName="professionalDetail" placeholder="Select"></p-dropdown>

                                        </div> -->


                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"  styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add dialog -->
                        <div  [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameExpert)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="expertForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnExpertType)}}</label>

                                            <p-dropdown [filter]="true" [options]="expertTypeList" [showClear]="true"
                                                class="pngDropdown" formControlName="expertType" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'expertType'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnTitle)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('title')"
                                                    *ngIf="diplayeIconInField('title')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('title')"
                                                    class="pngInputtext" formControlName="title" name="title"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'title'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnFirstName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('firstName')"
                                                    *ngIf="diplayeIconInField('firstName')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('firstName')"
                                                    class="pngInputtext" formControlName="firstName" name="firstName"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'firstName'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnLastName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('lastName')"
                                                    *ngIf="diplayeIconInField('lastName')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('lastName')"
                                                    class="pngInputtext" formControlName="lastName" name="lastName"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'lastName'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnOccupation)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('occupation')"
                                                    *ngIf="diplayeIconInField('occupation')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('occupation')"
                                                    class="pngInputtext" formControlName="occupation" name="occupation"
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'occupation'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnCompanyName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyName')"
                                                    *ngIf="diplayeIconInField('companyName')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('companyName')"
                                                    class="pngInputtext" formControlName="companyName"
                                                    name="companyName" pInputText type="text"></span>
                                            <app-custom-error [control]="'companyName'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('email')"
                                                    *ngIf="diplayeIconInField('email')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('email')" class="pngInputtext"
                                                    formControlName="email" name="email" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'email'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('address')"
                                                    *ngIf="diplayeIconInField('address')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('address')" class="pngInputtext"
                                                    formControlName="address" name="address" pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'address'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnProfessionalDetail)}}</label>

                                            <p-dropdown [filter]="true" [options]="professionalDetailList" [showClear]="true"
                                                class="pngDropdown" formControlName="professionalDetail"
                                                placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'professionalDetail'" [form]="expertForm">
                                            </app-custom-error>
                                        </div>
                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>Days</label>
                                        <p-multiSelect
                                          [options]="weekDays"
                                          formControlName="weekdaysWorked"
                                          defaultLabel="Select a Day"
                                          optionLabel="name"
                                          (onChange)="daysSelect($event)"
                                          display="chip">
                                        </p-multiSelect>
                                        <app-custom-error [control]="'weekdaysWorked'" [form]="expertForm"></app-custom-error>
                                      </div>

                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>Average Appointment Time</label>
                                        <input
                                          [class.p-invalid]="inputFieldDanger('appointmentTimeMins')" class="pngInputtext"
                                          formControlName="appointmentTimeMins" name="appointmentTimeMins" pInputText
                                          type="number">
                                        <app-custom-error [control]="'appointmentTimeMins'" [form]="expertForm"></app-custom-error>
                                      </div>
                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                        <label>Additional Professional Information</label>
                                        <textarea [class.p-invalid]="inputFieldDanger('additionalProfessionalInformation')"
                                                  formControlName="additionalProfessionalInformation" name="additionalProfessionalInformation" class="form-control" pInputTextarea  [rows]="5" [cols]="30">

                                        </textarea>
                                        <app-custom-error [control]="'additionalProfessionalInformation'" [form]="expertForm"></app-custom-error>
                                      </div>

                                        <!-- file upload component -->
                                        <!-- url="./upload.php" -->
                                        <div class="p-field p-col-12 formfieldheight">
                                            <label>{{translate(labels.expertColumnTncUrl)}}</label>
                                            <p-fileUpload   #tncRef
                                                            name="demo[]"
                                                            (uploadHandler)="onTNCUpload($event, tncRef)"
                                                            multiple="multiple"
                                                            customUpload="true"
                                                            maxFileSize="10000000"
                                                            class="pngFileUpload">
                                                <ng-template pTemplate="content">
                                                    <div *ngIf="tncUploadedFiles.length > 0" class="fileuploadEditContainer">
                                                        <div class="fileuploadRow" *ngFor="let image of tncUploadedFiles,  let i = index">

                                                            <div >
                                                                <img *ngIf="image.type=='image/jpeg'" [src]="image.src" />
                                                                <img *ngIf="image.type=='text/plain'" src="assets/theme1/custom-files/img/icons/text.svg" />
                                                                <img *ngIf="image.type=='application/pdf'" src="assets/theme1/custom-files/img/icons/pdf.svg" />
                                                                <img *ngIf="image.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" src="assets/theme1/custom-files/img/icons/excel.svg" />
                                                                <img *ngIf="image.type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" src="assets/theme1/custom-files/img/icons/word.svg" />
                                                            </div>
                                                            <div>
                                                                {{image?.name}}
                                                            </div>
                                                            <div>
                                                                {{image?.size}} B
                                                            </div>
                                                            <div>
                                                                <button pButton type="button"
                                                                        class="p-button p-component p-button-icon-only"
                                                                        (click)="DeleteTncImage(image,i)">
                                                                    <span class="p-button-icon pi pi-times"></span> </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-fileUpload>
                                        </div>


                                        <div class="p-field p-col-12 formfieldheight">
                                            <label>{{translate(labels.expertColumnCvUrl)}}</label>
                                            <p-fileUpload   #cvRef
                                                            name="demo[]"
                                                            (uploadHandler)="onCVUpload($event, cvRef)"
                                                            multiple="multiple"
                                                            customUpload="true"
                                                            maxFileSize="10000000"
                                                            class="pngFileUpload">
                                                <ng-template pTemplate="content">
                                                    <div *ngIf="cvUploadedFiles.length > 0" class="fileuploadEditContainer">
                                                        <div class="fileuploadRow" *ngFor="let image of cvUploadedFiles,  let i = index">

                                                            <div >
                                                                <img *ngIf="image.type=='image/jpeg'" [src]="image.src" />
                                                                <img *ngIf="image.type=='text/plain'" src="assets/theme1/custom-files/img/icons/text.svg" />
                                                                <img *ngIf="image.type=='application/pdf'" src="assets/theme1/custom-files/img/icons/pdf.svg" />
                                                                <img *ngIf="image.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" src="assets/theme1/custom-files/img/icons/excel.svg" />
                                                                <img *ngIf="image.type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" src="assets/theme1/custom-files/img/icons/word.svg" />
<!--                                                                <img *ngIf="image.type==''" src="assets/theme1/custom-files/img/icons/file.svg" />-->
                                                            </div>
                                                            <div>
                                                                {{image?.name}}
                                                            </div>
                                                            <div>
                                                                {{image?.size}} B
                                                            </div>
                                                            <div>
                                                                <button pButton type="button"
                                                                        class="p-button p-component p-button-icon-only"
                                                                        (click)="DeleteCvImage(image,i)">
                                                                    <span class="p-button-icon pi pi-times"></span> </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-fileUpload>
                                        </div>

                                        <div class="p-field p-col-12 formfieldheight">
                                            <label>{{translate(labels.expertColumnSignatureUrl)}}</label>
                                            <p-fileUpload   #signatureRef
                                                            name="demo[]"
                                                            (uploadHandler)="onSignUpload($event, signatureRef)"
                                                            multiple="multiple"
                                                            customUpload="true"
                                                            maxFileSize="10000000"
                                                            class="pngFileUpload">
                                                <ng-template pTemplate="content">
                                                    <div *ngIf="signUploadedFiles.length > 0" class="fileuploadEditContainer">
                                                        <div class="fileuploadRow" *ngFor="let image of signUploadedFiles,  let i = index">

                                                            <div>
                                                                <img *ngIf="image.type=='image/jpeg'" [src]="image.src" />
                                                                <img *ngIf="image.type=='text/plain'" src="assets/theme1/custom-files/img/icons/text.svg" />
                                                                <img *ngIf="image.type=='application/pdf'" src="assets/theme1/custom-files/img/icons/pdf.svg" />
                                                                <img *ngIf="image.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" src="assets/theme1/custom-files/img/icons/excel.svg" />
                                                                <img *ngIf="image.type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" src="assets/theme1/custom-files/img/icons/word.svg" />
                                                            </div>
                                                            <div>
                                                                {{image?.name}}
                                                            </div>
                                                            <div>
                                                                {{image?.size}} B
                                                            </div>
                                                            <div>
                                                                <button pButton type="button"
                                                                        class="p-button p-component p-button-icon-only"
                                                                        (click)="DeleteSignatureImage(image,i)">
                                                                    <span class="p-button-icon pi pi-times"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-fileUpload>
                                        </div>

                                        <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnCreateEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('createEmail')"
                                                    *ngIf="diplayeIconInField('createEmail')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('createEmail')"
                                                    class="pngInputtext" formControlName="createEmail"
                                                    name="createEmail" pInputText type="text"></span>
                                            <app-custom-error [control]="'createEmail'" [form]="expertForm"></app-custom-error>
                                            <span *ngIf="duplicateEmail" class="duplicate-Email">Email already exists</span>
                                        </div>
                                        <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.expertColumnCreatePassword)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('createPassword')"
                                                    *ngIf="diplayeIconInField('createPassword')"
                                                    class="pi pi-times color-danger rightallign"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('createPassword')"
                                                    class="pngInputtext" formControlName="createPassword"
                                                    name="createPassword" pInputText [type]="passwordType">
                                                    <i [ngClass]="passwordType === 'text'?'pi pi-eye':'pi pi-eye-slash'"
                                                   (click)="showHidePassword()"></i>
                                                </span>
                                            <app-custom-error [control]="'createPassword'" [form]="expertForm"></app-custom-error>
                                        </div>
                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn">
                                        </p-button>
                                        <p-button label="Close" (onClick)= "onCloseAdd()" (click)="showHideAddDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global
                                            class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" /></span>

                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0"
                                         (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                        [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                        [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                         [alwaysShowPaginator]="false"
                                        [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage" [showCurrentPageReport]=true
                                        [totalRecords]="totalRecords" [value]="expertList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': expertList?.length == 0}"
                                        id="invitationpage-memberslist-table" styleClass="p-datatable-striped">

                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Title</th>
                                            <th> First Name</th>
                                            <th> Last Name</th>
                                            <th> Occupation</th>
                                            <th> Company Name</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>

                                            <td> {{element?.title}} </td>
                                            <td> {{element?.firstName}} </td>
                                            <td> {{element?.lastName}} </td>
                                            <td> {{element?.occupation}} </td>
                                            <td> {{element?.companyName}} </td>
                                            <td>

                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadExpertToEdit(element.expertId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdExpert(element.expertId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                    (click)="viewExpertDetail(element.expertId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <!-- <p-button label="Manage Subscription"
                                                              (click)="getUserSubscriptionList(element.userId)"
                                                              icon="pi pi-eye"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button> -->
<!--                                                    <button type="button" pButton icon="pi pi-ellipsis-v" label="" class="menu-actions"-->
<!--                                                    (click)="menu.toggle($event); getUserId(element.userId)"></button>-->
<!--                                                  <p-menu #menu [popup]="true" [model]="items" appendTo="body" class="action-btns-menu" styleClass="p-button pbtn-transparent">-->
<!--                                                  </p-menu>-->
                                                   <!-- <button type="button" pTooltip="Manage Subscription" pButton icon="manage-subs-icon" label="" class="menu-actions"
                                                            (click)="showManageSubscription(element.userId)"></button>-->

<!--Manage Subscription dialog box-->
                                                 <!--   <p-dialog header="Manage Subscription"
                                                              [(visible)]="subscriptionViewModal"
                                                              [blockScroll]="true"
                                                              [modal]="true"
                                                              [baseZIndex]="10000"
                                                              [draggable]="false">
                                                        <ng-template *ngIf="!userSubscriptionList" pTemplate="emptymessage">
                                                            {{translate(labels.mat_pagination_no_data_msg)}}
                                                        </ng-template>

                                                        <div *ngFor="let item of userSubscriptionList; let i = index"
                                                             class="p-field-checkbox subsCheckboxView manageSubsClass">
                                                            <div class="p-grid manageSubsGrid">
                                                                <div class="p-col-6 manageSubsLabel">
                                                                    <label class="manageSubsLabel">{{translate(item?.userSubscriptionTitle)}}</label>
                                                                </div>
                                                                <div class="p-col-6 manageSubsSelectBtn">
                                                                    <p-selectButton
                                                                            [(ngModel)]="item.value"
                                                                            [name]="item.value"
                                                                            [optionValue]="manageOptions['value']"
                                                                            [options]="manageOptions"
                                                                            (onChange)="onSelect(item,i,element, $event)">
                                                                    </p-selectButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-template pTemplate="footer">
                                                            <div class="p-d-flex p-jc-end">
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="refreshSubs(element.userId)"
                                                                               label="Refresh" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div *ngIf="userSubscriptionList && userSubscriptionList.length > 0">
                                                                    <p-button (click)="saveUserSubscription($event)"
                                                                              label="Save" styleClass="p-button" class="pngBlueBtn">
                                                                    </p-button>
                                                                </div>
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="cancelUserSubscription()"
                                                                               label="Cancel" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dialog>-->
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
<!--    <pre>{{expertForm.value | json}}</pre>-->
</section>
