import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
// import { DeleteAlertDialog } from '../pop-ups/delete-popup/dialog-delete-alert';
import {FormBuilder, Validators} from '@angular/forms';
import {AppSettings} from '../../collaboration/constants';
// import { chatService } from '../services/chatService/web.service';
import {CookieService} from 'ngx-cookie-service';
// import { SharedService } from '../services/sharedservice/shared.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {showDiv} from '../../collaboration/common/common';
import {ChatService} from 'src/app/collaboration/services/chat/chat.service';
// import {SessionService} from 'src/app/web-services/session.service';
// import {UserDetail} from 'src/app/interfaces/user';

@Component({
               selector: 'app-sso-activesubscriptions',
               templateUrl: './sso-activesubscriptions.component.html',
               styleUrls: ['./sso-activesubscriptions.component.css']
           })
export class SsoActivesubscriptionsComponent implements OnInit {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    // toppings = new FormControl();
    // toppingList: string[] = ['Create Object ', 'Add Properties ', 'Delete Object '];

    displayedColumns: string[] = ['subscribednotification', 'application', 'ntfyType', 'subscribedtemplate', 'subscriptiontime', 'action'];
    dataSource: MatTableDataSource<Element>;

    pageSizeOptions = AppSettings.PAGE_SIZE_OPTIONS;

    searchSubscribedEvent = '';

    // Forms
    subscriptionForm = this.formBuilder.group({
                                                  application: ['', Validators.required],
                                                  event: ['', Validators.required],
                                                  type: ['', Validators.required],
                                                  template: ['', Validators.required]
                                              });

    searchForm = this.formBuilder.group({
                                            searchfield: [''],
                                        });

    applications;
    applicationId;
    events = [];
    notificationTypes = [];
    templates = [];
    templateBody = '';
    subscription = [''];          // newly Subscribed Event
    subscribedEvents = [];  //Getting All Subscribed Events

    //Preparing Data to be Sent
    loggedInUser;
    projectId = 5;
    notificationTypeId;
    type = 'event';
    template;
    applicationShortName = '';

    showSearchForm = false;
    showSubscriptionForm = false;

    public pageControlMap = new Map<string, boolean>().set('UnsubscribedEvent', false).set('SubscribeNewEvent', false);
    userRoles: string[];
    //---------------------------
    // userDetail: UserDetail;

    constructor(
        public dialog: MatDialog,
        // public chatService: chatService,
        private ngxSpinnerService: NgxUiLoaderService,
        public cookieService: CookieService,
        public chatService: ChatService,
        // public sessionService: SessionService,
        // public sharedService: SharedSsessionStorage.getItem('loggedInUser')ervice,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        // this.checkApplicationShortNameAndAssign();
        // this.assignUserRolesAndPageControlMap();
        // this.getConstants(AppSettings.NEW_SUBSCRIPTION_FORM_ID);
        // this.userDetail = this.sessionService.getUserDetails();
        this.getLoggedInUser();
        this.getAllApplications();
        this.getAllSubscribedEvents();
        // this.checkVersion();
    }

    setSubscriptionForm() {
        this.showSubscriptionForm = true;
        this.showSearchForm = false;
        setTimeout(() => {
            showDiv('ssoaddsubscription-overlaydiv');
        }, 10);
    }

    currentVersion = 0;
    // checkVersion() {
    //   this.sharedService.CurrentVersion.subscribe(data => {
    //     this.currentVersion = data;
    //   });
    // }


    setSearchForm() {
        this.showSubscriptionForm = false;
        this.showSearchForm = true;
        setTimeout(() => {
            showDiv('ssosearchsubscription-overlaydiv');
        }, 10);
    }

    openDeleteDialog(element, index) {

        // const dialogRef = this.dialog.open(DeleteAlertDialog, {
        //   data: {
        //     title: "Delete Subscription",
        //     message: "Are you sure you want To Unsubscribe this Event"
        //   }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   if (result) {
        //     this.unSubscribe(element, index)
        //   }
        //   else {
        //   }
        // });
    }

    onApplicationChange(event) {
        this.events = event.value.events;
        this.applicationId = event.value.application_id;
        this.getAllNtfyType();
    }

    onNtfyTypeChange(event) {
        this.notificationTypeId = event.value.ntfyTypeId;
        this.getAllTemplates(event.value);

    }

    onTemplateChange(event) {
        this.template = event.value.templateId;
        this.templateBody = event.value.body;
    }

    clearForm() {
        this.showSubscriptionForm = false;
        this.subscriptionForm.reset();
        this.searchForm.reset();
    }

    filteredSubscribedEvent = [];

    searchSubscribedEvents() {
        this.showSearchForm = false;
        this.filteredSubscribedEvent = AppSettings.filterArray(this.subscribedEvents, this.searchSubscribedEvent);
        this.dataSource = new MatTableDataSource<Element>(this.filteredSubscribedEvent);
        this.dataSource.paginator = this.paginator;
    }

    onCancel() {
        this.showSearchForm = false;
        this.showSubscriptionForm = false;
        // this.searchForm.reset();
        // searchSubscribedEvent = ""
    }


    // checkApplicationShortNameAndAssign() {
    //   if (this.sharedService.getCookie('applicationShortName')) {
    //     this.applicationShortName = this.sharedService.getCookie('applicationShortName')
    //   }
    // }

    // assignUserRolesAndPageControlMap() {
    //   if (this.sharedService.getCookie(this.sharedService.getCookie("applicationShortName") + '_roles') && this.sharedService.getCookie(this.sharedService.getCookie("applicationShortName") + '_roles') != undefined) {
    //     this.userRoles = this.sharedService.getCookie(this.sharedService.getCookie("applicationShortName") + '_roles');
    //     this.pageControlMap = this.sharedService.checkRole(this.pageControlMap, this.userRoles);
    //   }
    // }

    // getUserRoles() {
    //   if (this.sharedService.getCookie(AppSettings.APPLICATION_SHORT_NAME + '_roles')) {
    //     this.userRoles = JSON.parse(this.sharedService.getCookie(AppSettings.APPLICATION_SHORT_NAME + '_roles'));
    //   }
    // }

    getLoggedInUser() {
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
    }

    getAllApplications() {
        this.chatService.getAllApplications(this.loggedInUser, this.type, 0).subscribe(
            data => {
                this.applications = data;
            },
            error => {
            }
        );
    }

    selectedApplication: any;
    filteredEvents = [];

    getAllEvents() {
        this.applicationId = this.selectedApplication[0].application_id;
        try {
            this.events = this.selectedApplication[0]['events'];
            this.filteredEvents = this.events;
        } catch (error) {
        }
    }

    selectedEvent: any;
    filteredNotificationTypes = [];

    getAllNtfyType() {
        this.chatService.getAllNtfyTypes().subscribe(
            data => {
                this.notificationTypes = data['data'];
            },
            error => {
            }
        );
    }

    selectedNtfyType: any;
    filteredTemplates = [];

    getAllTemplates(ntfyType) {
        this.chatService.getAllTemplates(ntfyType).subscribe(
            data => {
                this.templates = data['data'];
                this.filteredTemplates = this.templates;
            },
            error => {
            }
        );
    }

    selectedTemplate: any;

    getTemplateBody() {
        this.template = this.selectedTemplate[0].templateId;
        this.templateBody = this.selectedTemplate[0].body;
    }

    totalSubscribedEvents = 0;

    getAllSubscribedEvents() {
        var object = this.makeJson();
        this.chatService.getSubscribedEvents(object).subscribe(
            data => {

                if (data) {
                    if (data['data']) {
                        this.subscribedEvents = data['data'];
                        this.subscribedEvents.sort((val1, val2) => {
                            return val2.insertionTimestamp - val1.insertionTimestamp;
                        });
                        this.filteredSubscribedEvent = this.subscribedEvents;
                        this.totalSubscribedEvents = this.filteredSubscribedEvent.length;
                        this.dataSource = new MatTableDataSource<Element>(this.filteredSubscribedEvent);
                        this.dataSource.paginator = this.paginator;
                    }
                }
            },
            error => {
            }
        );
    }

    subscribe() {
        this.ngxSpinnerService.startLoader('subscriptionLoader');
        var object = this.makeJson();
        this.chatService.subscribeNewEvent(object).subscribe(
            data => {
                if (data && data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
                    for (var i = 0; i < data['data'].length; i++) {
                        this.subscription = data['data'][i];
                        this.subscribedEvents = this.subscribedEvents || [];
                        this.subscribedEvents.unshift(this.subscription);
                        this.filteredSubscribedEvent = this.subscribedEvents;
                    }
                    this.totalSubscribedEvents = this.filteredSubscribedEvent.length;
                    this.dataSource = new MatTableDataSource<Element>(this.filteredSubscribedEvent);
                    this.dataSource.paginator = this.paginator;
                    // this.toastr.showSuccess('Subscription', 'Event');
                    this.ngxSpinnerService.stopLoader('subscriptionLoader');
                    this.clearForm();
                    //
                } else {
                    // this.toastr.showCustomInfoAlert("Event Already Subscribed");
                    this.ngxSpinnerService.stopLoader('subscriptionLoader');
                    this.clearForm();
                }
            },
            error => {
                this.ngxSpinnerService.stopLoader('subscriptionLoader');
                this.clearForm();
            }
        );
        this.events = [];
        this.notificationTypes = [];
        this.templates = [];
        this.templateBody = '';
        this.subscribedEvents.sort((val1, val2) => {
            return val2.insertionTimestamp - val1.insertionTimestamp;
        });

    }

    unSubscribe(eventToUnsubscribe, index) {
        this.ngxSpinnerService.startLoader('subscriptionLoader');
        this.chatService.unsubscribeEvent(eventToUnsubscribe).subscribe(
            data => {
                if (data && data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
                    // this.toastr.showSuccess('UnSubscription', 'Event');
                    this.subscribedEvents.splice(index, 1);
                    this.totalSubscribedEvents = this.totalSubscribedEvents - 1;
                    this.dataSource = new MatTableDataSource<Element>(this.filteredSubscribedEvent);
                    this.ngxSpinnerService.stopLoader('subscriptionLoader');
                } else {

                    this.ngxSpinnerService.stopLoader('subscriptionLoader');
                }
            },
            error => {
                this.ngxSpinnerService.stopLoader('subscriptionLoader');
            }
        );

    }

    makeJson() {
        try {
            var fieldName1 = 'loggedInUser';
            var fieldValue1 = this.loggedInUser.toString();
            var fieldName2 = 'project';
            var fieldValue2 = this.projectId;
            var fieldName3 = 'event';
            var fieldValue3 = this.subscriptionForm.value['event'];
            var fieldName4 = 'notificationType';
            var fieldValue4 = this.subscriptionForm.value['type']['ntfyTypeId'];
            var fieldName5 = 'template';
            var fieldValue5 = this.subscriptionForm.value['template']['templateId'];
            var fieldName6 = 'applicationId';
            var fieldValue6 = this.subscriptionForm.value['application']['application_id'];
            var object = {};
            object[fieldName1] = fieldValue1;
            object[fieldName2] = fieldValue2;
            object[fieldName3] = fieldValue3;
            object[fieldName4] = fieldValue4;
            object[fieldName5] = fieldValue5;
            object[fieldName6] = fieldValue6;
        } catch (error) {
        }

        return object;
    }

    pageNumber = 0;
    limit = 10;
    pageSize = [1, 5, 10, 20, 50];

    pageEvent(pageNo) {
        this.limit = pageNo['pageSize'];
        this.pageNumber = pageNo['pageIndex'];
    }

    constantlist = [];


    verifyLabel(label) {
        return label;
    }

}
