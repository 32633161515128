import {Component, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { validateSpaces } from 'src/app/util/custom.validator';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { ExpertService } from '../web-services/expert.service';
import { UsersService } from '../web-services/users.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { SessionService } from '../services/session.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';
import { ValidationService } from '../services/validation.service';
import {SubscriptionService} from '../web-services/subscription.service';
import {ChatService} from '../collaboration/services/chat/chat.service';
import {HttpStatusCode} from '@angular/common/http';
import {HttpPatchItem} from '../util/HttpPatchItem';
import {HttpPatch} from '../util/HttpPatch';


// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({
    selector: 'app-expert',
    templateUrl: './expert.component.html',
    styleUrls: ['./expert.component.css'],
    providers: [DialogService]
 })
export class ExpertComponent implements OnInit {
    public labels = Labels;
    subscriptionViewModal: boolean;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateExpertId = null;
    duplicateEmail = false;
    usersList: any = [];
    expertList: any = [];
    expertSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    expertForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    passwordType = 'password';
    loggedInUserType : any;
    loggedInRefId : any;
    weekDays: any = Constant.weekDays;
    professionalDetailList : any = [];
    expertTypeList : any = [];
    days: any = [];

    cvUploadedFiles: any = [];
    tncUploadedFiles: any = [];
    signUploadedFiles: any = [];
    clientId: number;
    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    addEditTitle = 'Add';
    items: MenuItem[];
    ref: DynamicDialogRef;
    @ViewChild('tncRef') tncRef: any;
    @ViewChild('cvRef') cvRef: any;
    @ViewChild('signatureRef') signatureRef: any;

    userSubscriptionList: any = [];
    subscriptionSearch: FormGroup;
    manageOptions = [
        {label: 'Required', value: 'Active'},
        {label: 'Not Required', value: 'Inactive'}
    ];


    constructor(
        private expertServices: ExpertService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovService : ListOfValuesService,
        private sessionService: SessionService,
        private dialogService: DialogService,
        private _validation : ValidationService,
        private subscriptionService: SubscriptionService,
        private chatService: ChatService
    ) {
    };

    onCVUpload(event, ref) {
        event.files.map(file => {
            this.sharedService.uploadFile(file).subscribe(res => {
                let fileObject = {} as string;
                fileObject['name'] = file.name;
                fileObject['src'] = res;
                fileObject['size'] = file.size;
                fileObject['type'] = file.type;
                this.cvUploadedFiles.push(fileObject);
                this.expertForm.controls['cvUrl'].setValue(this.cvUploadedFiles);
            });
        });
        this.messageService.add({ severity: 'success', summary: 'Files Uploaded Successfully', detail: '' });
        this.cvRef.clear();

    }

    onTNCUpload(event, ref) {
        event.files.map(file => {
            this.sharedService.uploadFile(file).subscribe(res => {
                let fileObject = {} as string;
               // fileObject['name'] = file.name;
                fileObject['src'] = res;
                //fileObject['size'] = file.size;
                //fileObject['type'] = file.type;
                this.tncUploadedFiles.push(fileObject);
                this.expertForm.controls['tncUrl'].setValue(this.tncUploadedFiles);
            });
        });
        this.messageService.add({ severity: 'success', summary: 'Files Uploaded Successfully', detail: '' });
        this.tncRef.clear();
    }

    onTNCRemove(event){
        console.log("event :: ",event);
        console.log("tncImages list : ",this.tncUploadedFiles);

        // if(event.file.name === this.)
        this.tncUploadedFiles = this.tncUploadedFiles.filter( x => x.name == event.file.name).splice(1);
        console.log("tncImages list after filter :: ",this.tncUploadedFiles);
    }

    onSignUpload(event, ref) {
        event.files.map(file => {
            this.sharedService.uploadFile(file).subscribe(res => {
                let fileObject = {} as string;
                fileObject['name'] = file.name;
                fileObject['src'] = res;
                fileObject['size'] = file.size;
                fileObject['type'] = file.type;
                this.signUploadedFiles.push(fileObject);
                this.expertForm.controls['signatureUrl'].setValue(this.signUploadedFiles);
            });
        });
        this.messageService.add({ severity: 'success', summary: 'Files Uploaded Successfully', detail: '' });
        this.signatureRef.clear();
    }

    get addUpdateTitle() {
        return this.updateExpertId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.expertSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
       //// this.countExpert();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    onSubmit() {

        this.expertForm.markAllAsTouched();
        this.expertForm.controls['clientId'].setValue(this.clientId);
        // For CV URL
        if(typeof this.expertForm.get('cvUrl').value=='string'){
            this.expertForm.controls['cvUrl'].setValue(this.expertForm.get('cvUrl').value);
        }else{
            this.expertForm.controls['cvUrl'].setValue(JSON.stringify(this.expertForm.get('cvUrl').value));
        }
        // For TNC URL
        if(typeof this.expertForm.get('tncUrl').value=='string'){
            this.expertForm.controls['tncUrl'].setValue(this.expertForm.get('tncUrl').value);
        }else{
            this.expertForm.controls['tncUrl'].setValue(JSON.stringify(this.expertForm.get('tncUrl').value));
        }
        // For Signature URL
        if(typeof this.expertForm.get('signatureUrl').value=='string'){
            this.expertForm.controls['signatureUrl'].setValue(this.expertForm.get('signatureUrl').value);
        }else{
            this.expertForm.controls['signatureUrl'].setValue(JSON.stringify(this.expertForm.get('signatureUrl').value));
        }
        /* calculate the data for the working_weekdays */
        const selectedDayCodes = this.days.map(day => day.code);

        const workingWeekDays = selectedDayCodes.join(',');
           this.expertForm.controls['weekdaysWorked'].setValue(workingWeekDays);
        console.log(workingWeekDays);
        console.log(this.expertForm.value);

       // if (this.expertForm.valid){
            this.addUpdateExpert();
            this.submitted = false;
            this.onClearAdd();
     //   }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllExpertsByClientId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.expertSearch.reset();
    }

    addUpdateExpert(): void {
      this.startLoader();
      this.duplicateEmail = false;

      if (this.updateExpertId == null) {
        this.expertServices.addExpertCpa(this.expertForm.value).subscribe(res => {


            if (res.status === HttpStatusCode.Created) {

              this.messageService.add(
                {
                  severity: 'success',
                  summary: this.translate(this.labels.entityNameExpert) +
                    this.translate(this.labels.addedSuccessfully),
                });

              this.showHideAddDialog();
              this.submitted = true;

            }

          },
          err => {

            this.messageService.add({
              severity: 'error',
              summary: err,
              detail: ''
            });

          });
      } else {

        const updateFieldsToExclude = ['userId', 'clientId', 'createEmail', 'createPassword'];

        let httpPatch = new HttpPatch();

        Object.keys(this.expertForm.controls).forEach(key => {

          if (!updateFieldsToExclude.includes(key)) {


                  httpPatch.pushToJsonPatch(new HttpPatchItem('replace', '/' + key, this.expertForm.get(key).value));

          }


        });

        console.log(httpPatch.jsonPatch);

        this.expertServices.updateExpertPatched(httpPatch.jsonPatch, this.updateExpertId).subscribe(res => {

            if (res.status === HttpStatusCode.NoContent) {

              this.messageService.add(
                {
                  severity: 'success',
                  summary: this.translate(this.labels.entityNameExpert) +
                    this.translate(this.labels.updatedSuccessfully),
                });

              this.showHideAddDialog();
              this.submitted = true;

            }

          },
          err => {

            this.messageService.add({
              severity: 'error',
              summary: err,
              detail: ''
            });

          });
      }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.items = [
            {
                label: 'Manage Subscription',
                icon: 'manage-subs-icon',
                // url: 'assets/iamges/manage-subscribtion.png',
                command: () => {
                    this.getUserSubscriptionList(this.userId)
                }
            },
        ]
        this.addEditTitle = this.labels.btnAdd;
        // this.findAllUsers();
        // this.findAllUsers();

        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.clientId = parseInt(this.sessionService.getSession('clientId'));

        this.cvUploadedFiles = [];
        this.tncUploadedFiles = [];
        this.signUploadedFiles = [];
        this.findAllListOfValues();
        this.expertForm = new FormGroup({
            userId: new FormControl(),
            clientId: new FormControl(),
            expertType: new FormControl(0, [Validators.required]),
            title: new FormControl('', [Validators.required, validateSpaces]),
            firstName: new FormControl('', [Validators.required, validateSpaces]),
            lastName: new FormControl('', [Validators.required, validateSpaces]),
            occupation: new FormControl('', [Validators.required, validateSpaces]),
            companyName: new FormControl('', [Validators.required, validateSpaces]),
            email: new FormControl('', [Validators.required, validateSpaces,Validators.pattern(this._validation.regex.email)]),
            address: new FormControl('', [Validators.required, validateSpaces]),
            professionalDetail: new FormControl(0, [Validators.required]),
            cvUrl: new FormControl('', [Validators.required, validateSpaces]),
            tncUrl: new FormControl('', [Validators.required, validateSpaces]),
            signatureUrl: new FormControl('', [Validators.required, validateSpaces]),
            createEmail: new FormControl('', [Validators.required, validateSpaces,Validators.pattern(this._validation.regex.email)]),
            createPassword: new FormControl('', [Validators.required, validateSpaces]),
            weekdaysWorked: new FormControl(0, [Validators.required]),
            appointmentTimeMins: new FormControl(0, [Validators.required]),
            additionalProfessionalInformation: new FormControl(''),

        });
        this.expertSearch = new FormGroup({
            userId: new FormControl(),
            clientId: new FormControl(),
            expertType: new FormControl(),
            title: new FormControl(),
            firstName: new FormControl(),
            lastName: new FormControl(),
            occupation: new FormControl(),
            companyName: new FormControl(),
            email: new FormControl(),
            address: new FormControl(),
            professionalDetail: new FormControl(),
            cvUrl: new FormControl(),
            tncUrl: new FormControl(),
            signatureUrl: new FormControl(),
            createEmail: new FormControl(),
            createPassword: new FormControl(),
        });
       //// this.countExpert();
        this.findAllExpertsByClientId();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.expertSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    showHidePassword() {
        if (this.passwordType === 'text') {
            this.passwordType = 'password';
        } else {
            this.passwordType = 'text';
        }
    }

    findAllListOfValues(){
        this.startLoader();
        this.lovService.getAllListOfValuesByName(Constant.EXPERT_LOV_ITEMS).subscribe(result => {

            console.log("result of Lov values :: ",result);

            if(result['statusCode'] == 200){
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];
                    console.log("element of data arrrray :: ",index," index obj :: ",element);

                    if(element[Constant.EXPERT_LOV_EXPRT_TYPE]){
                        console.log("sex array :: ",element[Constant.EXPERT_LOV_EXPRT_TYPE]);
                        // this.sex_array = element[Constant.PATIENT_SEX];
                        for (let object of element[Constant.EXPERT_LOV_EXPRT_TYPE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.expertTypeList.push(dropDown);
                            console.log("drop down values array :: ",dropDown);
                        }

                    }else if(element[Constant.EXPERT_LOV_PRO_DETAIL]){

                        for (let object of element[Constant.EXPERT_LOV_PRO_DETAIL]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.professionalDetailList.push(dropDown);
                            console.log("drop down values array :: ",dropDown);
                        }
                    }
                }
            }

        });
    }


    findAllExpertsByClientId(): void {

      this.expertServices.findAllExpertsByClientId(this.clientId).subscribe(res => {

        console.log(res);


        if (res.status === HttpStatusCode.Ok) {

          this.expertList = res.body;

          this.totalRecords = this.expertList.length;

          this.stopLoader();

        }


      }, err => {

        this.messageService.add({
          severity: 'error',
          summary: err,
          detail: ''
        });

      });

    }





    deleteByIdExpert(expertId: number, index) {
          this.confirmation.confirm({
                    message: this.translate(this.labels.DELETE_MSG), accept: () => {
                    this.startLoader();
                    const httpPatchItem = new HttpPatchItem('replace', '/dmlStatus', 3);

                    let httpPatch = new HttpPatch();
                    httpPatch.pushToJsonPatch(httpPatchItem);

                    this.expertServices.updateExpertPatched(httpPatch.jsonPatch, expertId).subscribe(res => {

                      if (res.status === HttpStatusCode.NoContent) {
                            this.stopLoader();
                            this.expertList.splice(index, 1);
                            this.messageService.add(
                                {
                                    severity: 'warn',
                                    summary: this.translate(this.labels.entityNameExpert) +
                                        this.translate(this.labels.deletedSuccessfully),
                                    detail: ''
                                });

                        } else {
                            console.error('error: ', res['documentation']);
                            this.startLoader();
                        }
                    }, err => {
                        console.error('err: ', err);
                        this.stopLoader();
                    });
                }, key: "expertDialog"
                                                       });
    }

    loadExpertToEdit(expertId: number, index) {
       // this.tncUploadedFiles = [];
      //  this.cvUploadedFiles = [];
       // this.signUploadedFiles = [];
        this.addEditTitle = this.labels.btnEdit;
        let expert = this.expertList.find(x => x['expertId'] == expertId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateExpertId = expert.expertId;
        this.selectedIndex = index;


        if(expert['expertType']){
            this.expertForm.controls['expertType'].setValue(expert.expertType);
        }
        if(expert['title']){
            this.expertForm.controls['title'].setValue(expert.title);
        }
        if(expert['firstName']){
            this.expertForm.controls['firstName'].setValue(expert.firstName);
        }
        if(expert['lastName']){
            this.expertForm.controls['lastName'].setValue(expert.lastName);
        }
        if(expert['occupation']){
            this.expertForm.controls['occupation'].setValue(expert.occupation);
        }
        if(expert['companyName']){
            this.expertForm.controls['companyName'].setValue(expert.companyName);
        }

      if(expert['professionalDetail']){

        this.expertForm.controls['professionalDetail'].setValue(expert.professionalDetail);
      }

      if(expert['appointmentTimeMins']){
        this.expertForm.controls['appointmentTimeMins'].setValue(expert.appointmentTimeMins);
      }

      if(expert['additionalProfessionalInformation']){
        this.expertForm.controls['additionalProfessionalInformation'].setValue(expert.additionalProfessionalInformation);
      }



      if(expert['email']){
            this.expertForm.controls['email'].setValue(expert['email']);
        }
        if(expert['address']){
            this.expertForm.controls['address'].setValue(expert['address']);
        }

        if(expert['weekdaysWorked']){

          let weekdaysWorked = expert.weekdaysWorked.split(',').map(day => +day);

          let selectDays = this.weekDays.filter(day => weekdaysWorked.includes(day.code));
          this.days = selectDays;

            this.expertForm.controls['weekdaysWorked'].setValue(selectDays);
          }
        // openAddOverlaydiv();
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    DeleteTncImage(image, index) {
        // console.log(image);

        if (this.tncUploadedFiles.find(x => (x.src == image.src))) {
            this.tncUploadedFiles.splice(index, 1);
            // console.log(this.uploadedFiles);
            // this.adForm.controls['adUploadImages'].setValue(this.uploadedFiles);
            let fileUrls = [];
            this.tncUploadedFiles.forEach(ele => {
                let fileObject = {} as string;
                fileObject['name'] = image.name;
                fileObject['src'] = image;
                fileObject['size'] = image.size;
                fileObject['type'] = image.type;
                fileUrls.push(ele.src);
            });
            this.expertForm.controls['tncUrl'].setValue( this.tncUploadedFiles);

        }
    }
    DeleteCvImage(image, index) {
        // console.log(image);

        if (this.cvUploadedFiles.find(x => (x.src == image.src))) {
            this.cvUploadedFiles.splice(index, 1);
            // console.log(this.uploadedFiles);
            // this.adForm.controls['adUploadImages'].setValue(this.uploadedFiles);
            let fileUrls = [];
            this.cvUploadedFiles.forEach(ele => {
                let fileObject = {} as string;
                fileObject['name'] = image.name;
                fileObject['src'] = image;
                fileObject['size'] = image.size;
                fileObject['type'] = image.type;
                fileUrls.push(ele.src);
            });
            this.expertForm.controls['cvUrl'].setValue( this.cvUploadedFiles);

        }
    }
    DeleteSignatureImage(image, index) {
        // console.log(image);

        if (this.signUploadedFiles.find(x => (x.src == image.src))) {
            this.signUploadedFiles.splice(index, 1);
            // console.log(this.uploadedFiles);
            // this.adForm.controls['adUploadImages'].setValue(this.uploadedFiles);
            let fileUrls = [];
            this.signUploadedFiles.forEach(ele => {
                let fileObject = {} as string;
                fileObject['name'] = image.name;
                fileObject['src'] = image;
                fileObject['size'] = image.size;
                fileObject['type'] = image.type;
                fileUrls.push(ele.src);
            });
            this.expertForm.controls['signatureUrl'].setValue( this.signUploadedFiles);

        }
    }

    onClearAdd() {
        // this.expertForm.reset();
        // this.expertForm.markAsUntouched();
        // this.updateExpertId = null;
        this.cvUploadedFiles = [];
        this.tncUploadedFiles = [];
        this.signUploadedFiles = [];
        // closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.expertForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.expertForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.expertForm.get(control)?.valid && this.expertForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // console.log("tncUploadedFiles clear : ",this.tncUploadedFiles);

        this.expertForm.reset();
        this.cvUploadedFiles = [];
        this.tncUploadedFiles = [];
        this.signUploadedFiles = [];
        // closeAddOverlaydiv();
        // console.log("tncUploadedFiles cleared : ",this.tncUploadedFiles);
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.expertSearch.reset();
    }

    refresh() {
       /// this.countExpert();
    }

    onAddOverlayDiv() {
        // openAddOverlaydiv();
        this.expertForm.reset();
    }

    searchTerm : any = null;
    onGlobalSearchClick(searchTerm){

        this.searchTerm = searchTerm;
        this.expertGlCount();

    }

    expertGlCount(){
        this.expertServices.expertGlcount(this.searchTerm,this.loggedInRefId,this.loggedInUserType).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findGlExpert();
            } else {
                this.totalRecords = 0;
                this.expertList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findGlExpert(){
        this.expertServices.findGlobalExpert(this.searchTerm, this.pageNo, this.itemPerPage,this.loggedInRefId,this.loggedInUserType).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.expertList = result['data'];
            }else{
                this.expertList = [];
            }
            console.log(result,"result");
        });
    }

    viewExpertDetail(expertId){
        this.ref = null;
        let expert = this.expertList.find(x => x['expertId'] == expertId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Expert Detail',
            width: '80%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {

                mainData: [{label: this.labels.expertColumnExpertType, value: expert['expertType']['label']},
                {label: this.labels.expertColumnTitle, value: expert['title']},
                {label: this.labels.expertColumnFirstName, value: expert['firstName']},
                {label: this.labels.expertColumnLastName, value: expert['lastName']},
                {label: this.labels.expertColumnCompanyName, value: expert['companyName']},
                {label: this.labels.expertColumnEmail, value: expert['email']},
                {label: this.labels.expertColumnAddress, value: expert['address']},
                {label: this.labels.expertColumnProfessionalDetail, value: expert['professionalDetail']['label']},
                {label: this.labels.expertColumnCreateEmail, value: expert['createEmail']},
                {label: this.labels.expertColumnAppointmentTime, value: expert['appointmentTimeMins']},
                {label: this.labels.expertColumnDaysWorked, value: expert['weekdaysWorked']},

                ],
                cvUrls :  {label: 'View CV Files', value: expert['cvUrl']},
                signUrls :  {label: 'View Signature Files', value: expert['signatureUrl']},
                tncUrls :  {label: 'View Terms And Conditions', value: expert['tncUrl']}

             }
        });
        this.ref.onClose.subscribe(res => {
            // this.countInvoice();
        });
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.expertForm.reset();
            this.expertForm.markAsUntouched();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateExpertId = null;
        this.tncUploadedFiles = [];
        this.cvUploadedFiles = [];
        this.signUploadedFiles = [];
    }

    userId : any;
    getUserSubscriptionList(userId) {
        this.startLoader();
        this.userSubscriptionList = [];
        this.userId = userId;
        this.subscriptionService.findUserSubscription(userId).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.userSubscriptionList = res['data'];
                    console.log('user subs data : ', this.userSubscriptionList);
                    this.userSubscriptionList.forEach(element=>{
                        element['value'] = element['subscriptionStatus']
                    });
                    this.subscriptionViewModal = true;
                } else {
                    this.userSubscriptionList = [];
                    this.subscriptionViewModal = true;
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    onSelect(item: any, i: number, element: any, event) {
        if(event.value === 'Active'){
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Active';
        }else {
            this.userSubscriptionList[i]['subscriptionStatus'] = 'Inactive';
        }
        // console.log(this.userSubscriptionList, 'changed user subscription list');
    }

    cancelUserSubscription() {
        this.subscriptionViewModal = false;
    }

    saveUserSubscription(event) {

        this.startLoader();
            let jsonObj = {'userSubscriptionList': this.userSubscriptionList};
            // this.subscriptionService.updateUserSubscriptionlist(jsonObj).subscribe(res => {
            this.subscriptionService.activateUserSubscriptionlist(this.userId,Constant.USER_TYPE_EXPERT,this.userSubscriptionList).subscribe(res => {
               if (res['message'] == 'Success') {
                   console.log('check list item updated => ', res['data']);
                   this.subscriptionViewModal = false;
                   this.stopLoader();
               } else {
                   this.subscriptionViewModal = false;
                   this.stopLoader();
               }
           }, err => {
               console.error('err: ', err);
               this.stopLoader();
           });
    }

    refreshSubs(expertUserId) {
        this.subscriptionService.refreshSubscriptionlist(this.loggedInRefId,Constant.USER_TYPE_EXPERT,expertUserId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.userSubscriptionList = [];
                this.userSubscriptionList = res['data'];
                this.userSubscriptionList.forEach(element=>{
                    element['value'] = element['subscriptionStatus']
                });
                this.stopLoader();
            } else {
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });

    }

    showManageSubscription(userId) {

        this.getUserSubscriptionList(userId);
        this.subscriptionViewModal = true;
    }

  daysSelect(event): any {

    this.days = [];
    this.days = event.value;

  }
}
