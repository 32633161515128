import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-balance',
  templateUrl: './subscription-balance.component.html',
  styleUrls: ['./subscription-balance.component.css']
})
export class SubscriptionBalanceComponent implements OnInit {

  subsBalance: any;
  chartSmall: boolean;
  innerWidth: any;
  options;

  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 376) { 
        this.chartSmall = false;
      }else {
        this.chartSmall = true;
      }
      
    this.subsBalance = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      datasets: [
          {
              label: 'Number',
              backgroundColor: '#009aff',
              data: [65, 11, 5, 81, 16]
          },
          {
              label: 'Average',
              backgroundColor: '#43d5cb',
              data: [28, 18, 20, 19, 7]
          }
      ]
  };
  this.options = {
  legend: {
    position: 'top',
    align: 'end'
}
};
  }

}
