<nav class="navbar navbar-expand-lg  navbar-dark bg-primary">
    <a [routerLink]="['/home']" class="navbar-brand" routerLinkActive="router-link-active">CPA</a>
    <button aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
            data-target="#navbarNavDropdown" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a [routerLink]="['/agency']" class="nav-link" routerLinkActive="router-link-active">agency</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_clinic']" class="nav-link" routerLinkActive="router-link-active">agency
                                                                                                            Clinic</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_company']" class="nav-link" routerLinkActive="router-link-active">agency
                                                                                                             Company</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_contacts']" class="nav-link" routerLinkActive="router-link-active">agency
                                                                                                              Contacts</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_expert']" class="nav-link" routerLinkActive="router-link-active">agency
                                                                                                            Expert</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_patients']" class="nav-link" routerLinkActive="router-link-active">agency
                                                                                                              Patients</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_subscription_checklist']" class="nav-link"
                   routerLinkActive="router-link-active">agency
                                                         Subscription Checklist</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/agency_type']" class="nav-link" routerLinkActive="router-link-active">agency
                                                                                                          Type</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/appointment_type']" class="nav-link" routerLinkActive="router-link-active">appointment
                                                                                                               Type</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/appointments']" class="nav-link"
                   routerLinkActive="router-link-active">appointments</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/body_chart_detail']" class="nav-link" routerLinkActive="router-link-active">body
                                                                                                                Chart
                                                                                                                Detail</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/body_chart_master']" class="nav-link" routerLinkActive="router-link-active">body
                                                                                                                Chart
                                                                                                                Master</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/categories']" class="nav-link" routerLinkActive="router-link-active">categories</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/client_details']" class="nav-link" routerLinkActive="router-link-active">client
                                                                                                             Details</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/clinic_experts']" class="nav-link" routerLinkActive="router-link-active">clinic
                                                                                                             Experts</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/clinic_experts_schedual']" class="nav-link" routerLinkActive="router-link-active">clinic
                                                                                                                      Experts
                                                                                                                      Schedual</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/clinic_type']" class="nav-link" routerLinkActive="router-link-active">clinic
                                                                                                          Type</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/clinics']" class="nav-link" routerLinkActive="router-link-active">clinics</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/company']" class="nav-link" routerLinkActive="router-link-active">company</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/company_users']" class="nav-link" routerLinkActive="router-link-active">company
                                                                                                            Users</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/compnay_patients']" class="nav-link" routerLinkActive="router-link-active">compnay
                                                                                                               Patients</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/contacts']" class="nav-link" routerLinkActive="router-link-active">contacts</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/discharge']" class="nav-link" routerLinkActive="router-link-active">discharge</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/drug_history']" class="nav-link" routerLinkActive="router-link-active">drug
                                                                                                           History</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/exercise_category']" class="nav-link" routerLinkActive="router-link-active">exercise
                                                                                                                Category</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/exercises']" class="nav-link" routerLinkActive="router-link-active">exercises</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/expenses']" class="nav-link" routerLinkActive="router-link-active">expenses</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/expert']" class="nav-link" routerLinkActive="router-link-active">expert</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/flags']" class="nav-link" routerLinkActive="router-link-active">flags</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/goals']" class="nav-link" routerLinkActive="router-link-active">goals</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/invoice']" class="nav-link" routerLinkActive="router-link-active">invoice</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/invoice_items_detail']" class="nav-link" routerLinkActive="router-link-active">invoice
                                                                                                                   Items
                                                                                                                   Detail</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/list_of_values']" class="nav-link" routerLinkActive="router-link-active">list Of
                                                                                                             Values</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/list_of_values_detail']" class="nav-link" routerLinkActive="router-link-active">list
                                                                                                                    Of
                                                                                                                    Values
                                                                                                                    Detail</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/list_of_values_master']" class="nav-link" routerLinkActive="router-link-active">list
                                                                                                                    Of
                                                                                                                    Values
                                                                                                                    Master</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/observations']" class="nav-link"
                   routerLinkActive="router-link-active">observations</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/outcome_measure']" class="nav-link" routerLinkActive="router-link-active">outcome
                                                                                                              Measure</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/outcome_measure_images']" class="nav-link" routerLinkActive="router-link-active">outcome
                                                                                                                     Measure
                                                                                                                     Images</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/palpation']" class="nav-link" routerLinkActive="router-link-active">palpation</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/past_medical_history']" class="nav-link" routerLinkActive="router-link-active">past
                                                                                                                   Medical
                                                                                                                   History</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/patient_exercises']" class="nav-link" routerLinkActive="router-link-active">patient
                                                                                                                Exercises</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/patients']" class="nav-link" routerLinkActive="router-link-active">patients</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/payments']" class="nav-link" routerLinkActive="router-link-active">payments</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/posture']" class="nav-link" routerLinkActive="router-link-active">posture</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/present_condition_history']" class="nav-link" routerLinkActive="router-link-active">present
                                                                                                                        Condition
                                                                                                                        History</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/social_history']" class="nav-link" routerLinkActive="router-link-active">social
                                                                                                             History</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/testing_arom']" class="nav-link" routerLinkActive="router-link-active">testing
                                                                                                           Arom</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/testing_muscle']" class="nav-link" routerLinkActive="router-link-active">testing
                                                                                                             Muscle</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/testing_neuro']" class="nav-link" routerLinkActive="router-link-active">testing
                                                                                                            Neuro</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/testing_special']" class="nav-link" routerLinkActive="router-link-active">testing
                                                                                                              Special</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/treatment']" class="nav-link" routerLinkActive="router-link-active">treatment</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/users']" class="nav-link" routerLinkActive="router-link-active">users</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/wad_detail']" class="nav-link" routerLinkActive="router-link-active">wad Detail</a>
            </li>

        </ul>
    </div>
</nav>
