import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FlagsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdFlags(flagId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Flags/deleteFlags', {'flagId': flagId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countFlags(flags): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Flags/count', {'flags': flags}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateFlags(flags): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Flags/updateFlags', [flags], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addFlags(flags): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Flags/addFlags', [flags], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllFlags(flags, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Flags/findAllFlags', {'flags': {patientId: 179}, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Flags/countGlobalFlags',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Flags/findGlobalFlags',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
