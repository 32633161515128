import {Constant} from '../../../util/constant';
import {MultiSelect} from '../../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AromMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['movement']: {
      name: Constant.TESTING_AROM_LOV_MOVEMENT,
      dbField: 'movement',
      label: 'Movement',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false


    },
    ['measure']: {
      name: Constant.TESTING_AROM_LOV_MEASURE,
      dbField: 'measure',
      label: 'Measures',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    ['limitingFactor']: {
      name: Constant.TESTING_AROM_LOV_LIMITING_FACTOR,
      dbField: 'limitingFactor',
      label: 'Limiting Factors',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    }
  };

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
