import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from "rxjs/operators";
import {FormDataDTO} from "../dto/FormDataDTO";


@Injectable({providedIn: 'root'})
export class DiagnosisService {
  httpOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json'),
    observe: 'response'
  };

  observe: 'response';

  constructor(
    private httpClient: HttpClient) {}


  createPatientDiagnosis(diagnosisDTO: FormDataDTO): Observable<any> {

    return this.httpClient.post<any>(Constant.CPA_SPRING_API_URL + 'patient-diagnoses', diagnosisDTO, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));

  }


  findAllDiagnoses(): Observable<any> {

    return this.httpClient.get<any>(Constant.CPA_SPRING_API_URL + 'diagnoses', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findAllDiagnosesByPatientIdAndCaseId(patientId: number, caseId: number): Observable<any> {
    return this.httpClient.get<any>(Constant.CPA_SPRING_API_URL + 'patients/' + patientId + '/cases/' + caseId + '/patient-diagnoses', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));
  }

  getCaseDiagnosisPredictionsByCaseId(caseId: number): Observable<any> {

    return this.httpClient.get<any>(Constant.CPA_SPRING_API_URL + 'cases/' + caseId + '/case-diagnosis-predictions', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));


  }
}
