<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deletePalpation" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNamePalpation)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNamePalpation)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePalpation)}}</p-header>
                                <form class="pngForm" id="invitationpage-searchmemeber-pngForm"
                                      [formGroup]="palpationSearch" (ngSubmit)="onSearch()">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnTenderness)}}</label>
                                            <p-selectButton
                                                    [options]="booleanCategoryList"
                                                    formControlName="tenderness"
                                                    [optionValue]="booleanCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnTendernessSeverity)}}</label>
                                            <p-selectButton
                                                    [options]="severityCategoryList"
                                                    formControlName="tendernessSeverity"
                                                    [optionValue]="severityCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnTendernessComments)}}</label> <span
                                                class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                                              (click)="fieldReset('tendernessComments')"
                                                                              *ngIf="diplayeIconInField('tendernessComments')"></i> <input
                                                pInputText type="text" name="tendernessComments"
                                                formControlName="tendernessComments" class="pngInputtext"> </span></div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnMuscleSpasm)}}</label>
                                            <p-selectButton
                                                    [options]="booleanCategoryList"
                                                    formControlName="muscleSpasm"
                                                    [optionValue]="booleanCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnMuscleSpasmSeverity)}}</label>
                                            <p-selectButton
                                                    [options]="severityCategoryList"
                                                    formControlName="muscleSpasmSeverity"
                                                    [optionValue]="severityCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnMuscleSpasmComments)}}</label>
                                            <span class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                                                (click)="fieldReset('muscleSpasmComments')"
                                                                                *ngIf="diplayeIconInField('muscleSpasmComments')"></i> <input
                                                    pInputText type="text" name="muscleSpasmComments"
                                                    formControlName="muscleSpasmComments" class="pngInputtext"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnBonyTenderness)}}</label>
                                            <p-selectButton
                                                    [options]="booleanCategoryList"
                                                    formControlName="bonyTenderness"
                                                    [optionValue]="booleanCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnBonyTendernessSeverity)}}</label>
                                            <p-selectButton
                                                    [options]="severityCategoryList"
                                                    formControlName="bonyTendernessSeverity"
                                                    [optionValue]="severityCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnBonyTendernessComments)}}</label>
                                            <span class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                                                (click)="fieldReset('bonyTendernessComments')"
                                                                                *ngIf="diplayeIconInField('bonyTendernessComments')"></i> <input
                                                    pInputText type="text" name="bonyTendernessComments"
                                                    formControlName="bonyTendernessComments"
                                                    class="pngInputtext"> </span></div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <form class="pngForm" [formGroup]="palpationForm" (ngSubmit)="onSubmit()">
                                    <div class="p-fluid p-formgrid p-grid">
                                      <div class="p-field p-col-12 p-md-6 formfieldheight">
                                      <app-lov-details [categoryString]="objectMultiSelects['palpation'].name" (getResponse)="getResponse($event)"
                                                       label="{{objectMultiSelects['palpation'].label}}"
                                                       [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['palpation'].currentSelected"></app-lov-details>
                                      <span id="#palpationError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                      </div>


                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.palpationColumnMuscleSpasmComments)}}</label>
                                            <span class="p-input-icon-right">
                                                <i class="pi pi-times color-danger"
                                                   (click)="fieldReset('palpationComments')"
                                                   *ngIf="diplayeIconInField('palpationComments')">
                                                </i>
                                                <input  type="text" name="palpationComments"
                                                        [class.p-invalid]="inputFieldDanger('palpationComments')"
                                                        pInputText formControlName="palpationComments"
                                                        class="pngInputtext">
                                            </span>
                                            <app-custom-error [form]="palpationForm" [control]="'palpationComments'"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                   <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="palpationList.length > 0"
                                         [value]="palpationList"
                                         [lazy]="true"
                                         (onLazyLoad)="onPageChnage($event)"
                                         [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]"
                                         [paginator]="true"
                                         [rows]="itemPerPage"
                                         [autoLayout]="true"
                                         [totalRecords]="palpationList.length"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [showCurrentPageReport]=true
                                         [alwaysShowPaginator]="false"
                                         id="invitationpage-memberslist-table"
                                         [ngClass]="{'pngTableEmptyMessage': palpationList?.length == 0}"
                                         class="pngTable" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Date</th>
                                            <th> Palpation</th>
                                            <th> Palpation Comments</th>

                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-element let-i="rowIndex">
                                        <tr>
                                            <td> {{element?.insertionTimestamp | date: "dd-MM-YYYY"}} </td>
                                          <td> {{element?.palpations[0].lovValues}} </td>
                                          <td> {{element?.palpationComments}} </td>

                                          <td>
                                                <div class="pngTable-icons">
                                                    <p-button pTooltip="Edit" icon="pi pi-pencil"
                                                              (click)="loadPalpationToEdit(element.palpationId, i)"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button pTooltip="Delete" icon="pi pi-trash"
                                                              (click)="deleteByIdPalpation(element.palpationId,i)"
                                                              styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button label="View" icon="pi pi-eye"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
