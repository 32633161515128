import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class WadDetailService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdWadDetail(wadDetailId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'WadDetail/deleteWadDetail', {'wadDetailId': wadDetailId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countWadDetail(wadDetail): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'WadDetail/count', {'wadDetail': wadDetail}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateWadDetail(wadDetail): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'WadDetail/updateWadDetail', [wadDetail], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addWadDetail(wadDetail): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'WadDetail/addWadDetail', [wadDetail], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllWadDetail(wadDetail, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'WadDetail/findAllWadDetail',
            {'wadDetail': wadDetail, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
