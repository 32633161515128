import { Component, OnInit } from '@angular/core';
import {Labels} from "../util/labels";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Constant} from "../util/constant";
import {TreatmentPrescribedService} from "../web-services/treatment-prescribed.service";
import {SharedService} from "../web-services/shared.service";

@Component({
  selector: 'app-associate-diagnosis-lov',
  templateUrl: './associate-diagnosis-lov.component.html',
  styleUrls: ['./associate-diagnosis-lov.component.css']
})
export class AssociateDiagnosisLovComponent implements OnInit {

  public labels = Labels;
  formButton: string = this.translate(this.labels.btnAdd);
  submitted = false;
  selectedIndex = -1;
  updateListOfValuesId = null;
  categoriesList: any = [];
  listOfValuesList: any = [];
  listOfValuesSearch: FormGroup;
  clearSearchBtn = false;
  totalRecords;
  itemPerPage: number = Constant.ITEM_PER_PAGE;
  associateDiagnosisForm: FormGroup;
  constantList = [];
  pageNo: number = 0;
  onValueChange = true;

  showSearchDialog = false;
  showSearchBtn = false;
  showAddDialog = false;
  showAddBtn = false;
  editTag = false;
  disableColumn = false;
  passwordType = 'password';
  addEditTitle = '';
  treatmentsList = [];
  constructor(        private treatmentPrescribedService: TreatmentPrescribedService,
                      private sharedService: SharedService
  ) { }

  ngOnInit(): void {

    this.associateDiagnosisForm = new FormGroup({

      diagnosis: new FormControl('', [Validators.required])
    });

    this.findTreatmentPrescribedByForCaseDiagnosis();

  }

  onSubmit() {}

  findTreatmentPrescribedByForCaseDiagnosis(): void {

          this.treatmentPrescribedService.findAllTreatmentsPrescribed().subscribe(resTreatments => {

            if (resTreatments.status === 200) {

              /* remove dupes from predictive to all treatments */
              console.log(resTreatments.body);
              this.treatmentsList = resTreatments.body;
            }

          });
  }

  translate(label) {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

}
