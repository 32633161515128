import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ClientDetailsService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdClientDetails(clientDetailsId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClientDetails/deleteClientDetails', {'clientDetailsId': clientDetailsId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countClientDetails(clientDetails): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'ClientDetails/count', {'clientDetails': clientDetails}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateClientDetails(clientDetails): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClientDetails/updateClientDetails', [clientDetails], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addClientDetails(clientDetails): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(Constant.BASE_URL + 'ClientDetails/addClientDetails', [clientDetails], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllClientDetails(clientDetails, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ClientDetails/findAllClientDetails',
            {'clientDetails': clientDetails, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllClients():Observable<any> {


      return this.httpClient.get<any>(Constant.CPA_CLIENT_SERVICE + 'clients', { observe: 'response' }).pipe(
        retry(3), catchError(Constant.handleError));


    }

    addClientCpa(clientDetails):Observable<any> {

      return this.httpClient.post<any>(Constant.CPA_CLIENT_SERVICE + 'clients', clientDetails,{observe: 'response'}).pipe();
    }

  findClientById(clientId: number):Observable<any> {

    return this.httpClient.get<any>(Constant.CPA_CLIENT_SERVICE + 'clients/' + clientId, { observe: 'response' }).pipe(
      retry(3), catchError(Constant.handleError));
  }

  updateClientViaPatch(clientId: number, clientPatch: any): Observable<any> {
    return this.httpClient.patch<any>(


      Constant.CPA_CLIENT_SERVICE + 'clients/' + clientId, clientPatch, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/merge-patch+json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
  }








}
