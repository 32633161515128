import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {TestingNeuroService} from '../web-services/testing-neuro.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {ObservationsMultiSelects} from "../uiUtils/pages/observations/ObservationsMultiSelects";
import {NeuroTestingMultiSelects} from "../uiUtils/pages/testing/NeuroTestingMultiSelects";
import {FormDataDTO} from "../dto/FormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {ClinicalDataService} from '../web-services/clinical-data.service';
import {HttpStatusCode} from '@angular/common/http';
import {TestingFormData} from '../dto/TestingFormData';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-testing-neuro', templateUrl: './testing-neuro.component.html', styleUrls: ['./testing-neuro.component.css']})
export class TestingNeuroComponent implements OnInit {

  @Input() chosenBodyChartAnatomyDiagramId;

  public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateTestingNeuroId = null;
    listOfValuesDetailList: any = [];
    testingNeuroList: any = [];
    testingNeuroListOfItems: any = [];
    dermatomeList: any = [];
    sidesCategoryList: any = [];
    testingNeuroSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    testingNeuroForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    dermatomeDescription = Constant.TESTING_NEURO_LOV_DERMATOME_DESCRIPTION;
    reflexes = Constant.TESTING_NEURO_LOV_REFLEXES;
    reflexesDescription = Constant.TESTING_NEURO_LOV_REFLEXES_DESCRIPTION;

    expertId;
    patientId;
    appointmentId;
    caseId;
    objectMultiSelects: any;

    constructor(
        private testingNeuroServices: TestingNeuroService,
        private listOfValuesDetailServices: ListOfValuesDetailService, private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService, private messageService: MessageService, private route: Router,
        private loader: NgxUiLoaderService, private constantService: ConstantService, private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private neuroTestingMultiSelects: NeuroTestingMultiSelects,
        private clinicalDataService: ClinicalDataService

    ) {
    };

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    dermatomeDescriptionList: any = [];
    reflexesList: any = [];
    reflexesDescriptionList: any = [];

    dermatomeDescriptionError = false;
    reflexesError = false;
    reflexesDescriptionError = false;

  ngOnChanges(changes: SimpleChanges) {

    if (!changes.chosenBodyChartAnatomyDiagramId.firstChange)  {
      this.findAllTestingNeuroByAppointmentIdAndBodyChartAnatomyDiagramId();
    }

  }

  getResponse($event): void {

    this.neuroTestingMultiSelects.getResponseObjects($event);
  }
    findAllDermatome() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Dermatome').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.dermatomeList.push(dropDown);
                    }
                } else {
                    this.dermatomeList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findSidesCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('SidesCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.sidesCategoryList.push(dropDown);
                    }
                } else {
                    this.sidesCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

  findAllTestingNeuroByAppointmentIdAndBodyChartAnatomyDiagramId(): void {


    this.clinicalDataService.findAllTestingNeuroByAppointmentIdAndBodyChartAnatomyDiagramId(this.appointmentId, this.chosenBodyChartAnatomyDiagramId).subscribe(res => {

      this.startLoader();

      if (res.status === HttpStatusCode.Ok) {

        this.testingNeuroListOfItems = res.body;
        this.stopLoader();

      }
    }, err => {

      this.stopLoader();
      this.messageService.add(
        {
          severity: 'error',
          summary: err,
          // detail: this.translate(this.labels.entityNameTestingArom)
        });
    });
  }




  ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;

        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));
        this.lovDataObject = {"refId":0,"refType":""};

        this.objectMultiSelects = this.neuroTestingMultiSelects.objectMultiSelects;



        this.findAllDermatome();
        this.findSidesCategory();
        this.findAllListOfValuesDetail();
        this.testingNeuroForm = new FormGroup({
                                                  dermatome: new FormControl('',[Validators.required]),
                                                  dermatomeSide: new FormControl('', [Validators.required]),
                                                  dermatomeComments: new FormControl('', [Validators.required, validateSpaces]),
                                                  reflexesSide: new FormControl('', [Validators.required]),
                                                  bodyChartId: new FormControl(0),
                                                  userId: new FormControl(0),
                                              });
        this.testingNeuroSearch = new FormGroup({
                                                    dermatome: new FormControl(),
                                                    dermatomeSide: new FormControl(),
                                                    dermatomeDescription: new FormControl(),
                                                    dermatomeComments: new FormControl(),
                                                    reflexes: new FormControl(),
                                                    reflexesSide: new FormControl(),
                                                    reflexesDescription: new FormControl(),
                                                    patientId: new FormControl(),
                                                    expertId: new FormControl(),
                                                    appointmentId: new FormControl(),
                                                    bodyChartId: new FormControl(),
                                                    userId: new FormControl(),
                                                });
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.findAllTestingNeuroByAppointmentIdAndBodyChartAnatomyDiagramId();
        this.testingNeuroSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }



    get addUpdateTitle() {
        return this.updateTestingNeuroId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.testingNeuroSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countTestingNeuro();
        this.findAllTestingNeuro();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countTestingNeuro() {
        this.testingNeuroSearch.controls['expertId'].setValue(this.expertId);
        this.testingNeuroSearch.controls['patientId'].setValue(this.patientId);
        this.testingNeuroSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingNeuroServices.countTestingNeuro(this.testingNeuroSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllTestingNeuro();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.testingNeuroForm.markAllAsTouched();


      const neuroDTO = new TestingFormData(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.testingNeuroForm);

      const diagnosisData = new DiagnosisDataHelper(neuroDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      neuroDTO.bodyChartAnatomiesDiagramId = this.chosenBodyChartAnatomyDiagramId;
      console.log(neuroDTO);

      if (!neuroDTO.hasMultiSelectErrors) {

        this.testingNeuroServices.addTestingNeuroCpa(neuroDTO).subscribe(res => {

          if (res.status === 201) {

            this.messageService.add(
              {
                severity: 'success',
                summary: this.translate(this.labels.entityNameTestingNeuro) +
                  this.translate(this.labels.addedSuccessfully),
              });

            this.lovDataObject = {"refId":0,"refType":"","reset": true};
            this.lovDataObject = {"refId":0,"refType":"","reset": false};
            this.submitted = true;
            this.showHideAddDialog();
            this.findAllTestingNeuroByAppointmentIdAndBodyChartAnatomyDiagramId();

            if (uniqueCaseDiagnosisPredictionList.length > 0) {
              this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

                console.log(res);
              });
            }

            if (neuroDTO.redFlagData.length > 0) {

              this.lovServices.createListOfCaseRedFlags(neuroDTO.redFlagData).subscribe(res => {

                if (res.status === 201) {

                  this.messageService.add({
                    severity: 'success',
                    summary: this.translate("Red Flags Updated"),
                  });
                }

              });
            }
            ///this.countTestingNeuro();

          }
        });

      }
      else {

        neuroDTO.multiSelectErrorFields.forEach(multiSelectError => {

          document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
        });
      }
    }

  findAllTestingNeuroByPatientId(): void {

    this.testingNeuroServices.findAllTestingNeurosByPatientId(this.patientId).subscribe(res => {

      if (res.status === 200) {

        this.testingNeuroListOfItems = res.body;

      }
    });
  }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllTestingNeuro();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.testingNeuroSearch.reset();
        this.countTestingNeuro();
        // this.findAllTestingNeuro();
    }

    addUpdateTestingNeuro(testingNeuro) {
        this.startLoader();
        if (this.updateTestingNeuroId == null) {
            this.testingNeuroServices.addTestingNeuro(this.testingNeuroForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    testingNeuro = res['data'][0];
                    this.testingNeuroList.push(testingNeuro);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameTestingNeuro) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameTestingNeuro)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countTestingNeuro();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            testingNeuro.testingNeuroId = this.updateTestingNeuroId;
            this.testingNeuroServices.updateTestingNeuro(testingNeuro,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.testingNeuroList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameTestingNeuro) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameTestingNeuro)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateTestingNeuroId = null;
            // this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findAllTestingNeuro() {
        this.startLoader();
        this.testingNeuroSearch.controls['expertId'].setValue(this.expertId);
        this.testingNeuroSearch.controls['patientId'].setValue(this.patientId);
        this.testingNeuroSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingNeuroServices.findAllTestingNeuro(this.testingNeuroSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.testingNeuroList = res['data'];
                } else {
                    this.testingNeuroList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdTestingNeuro(testingNeuroId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.testingNeuroServices.deleteByIdTestingNeuro(testingNeuroId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.testingNeuroList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameTestingNeuro) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameTestingNeuro)
                                                });
                        this.countTestingNeuro();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deleteTestingNeuro"
                                  });
    }

    loadTestingNeuroToEdit(testingNeuroId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let testingNeuro = this.testingNeuroList.find(x => x['testingNeuroId'] == testingNeuroId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateTestingNeuroId = testingNeuro['testingNeuroId'];
        this.selectedIndex = index;
        this.lovDataObject = {"refId":testingNeuro['testingNeuroId'],"refType":""};

        if(testingNeuro['dermatome']) {
            this.testingNeuroForm.controls['dermatome'].setValue(testingNeuro['dermatome']['listOfValuesId']);
        }
        if(testingNeuro['dermatomeSide']) {
            this.testingNeuroForm.controls['dermatomeSide'].setValue(testingNeuro['dermatomeSide']['listOfValuesId']);
        }
        if(testingNeuro['dermatomeDescription']['listOfValuesMasterId']) {
            this.testingNeuroForm.controls['dermatomeDescription'].setValue(testingNeuro['dermatomeDescription']['listOfValuesMasterId']);
        }
        if(testingNeuro['dermatomeComments']) {
            this.testingNeuroForm.controls['dermatomeComments'].setValue(testingNeuro['dermatomeComments']);
        }
        if(testingNeuro['reflexes']['listOfValuesMasterId']) {
            this.testingNeuroForm.controls['reflexes'].setValue(testingNeuro['reflexes']['listOfValuesMasterId']);
        }
        if(testingNeuro['reflexesSide']) {
            this.testingNeuroForm.controls['reflexesSide'].setValue(testingNeuro['reflexesSide']['listOfValuesId']);
        }
        if(testingNeuro['reflexesDescription']['listOfValuesMasterId']) {
            this.testingNeuroForm.controls['reflexesDescription'].setValue(testingNeuro['reflexesDescription']['listOfValuesMasterId']);
        }
        if(testingNeuro['patientId']) {
            this.testingNeuroForm.controls['patientId'].setValue(testingNeuro['patientId']['patientId']);
        }
        if(testingNeuro['expertId']) {
            this.testingNeuroForm.controls['expertId'].setValue(testingNeuro['expertId']['expertId']);
        }
        if(testingNeuro['appointmentId']) {
            this.testingNeuroForm.controls['appointmentId'].setValue(testingNeuro['appointmentId']['appointmentId']);
        }
        // this.testingNeuroForm.controls['bodyChartId'].setValue(testingNeuro['bodyChartId']);
        // this.testingNeuroForm.controls['userId'].setValue(testingNeuro['userId']);
        // openAddOverlaydiv();
        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.testingNeuroForm.reset();
        this.testingNeuroForm.markAsUntouched();
        this.updateTestingNeuroId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.testingNeuroForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.testingNeuroForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.testingNeuroForm.get(control)?.valid && this.testingNeuroForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.testingNeuroForm.reset();
    }

    onCloseSearch() {
        this.testingNeuroSearch.reset();
    }

    refresh() {
        this.countTestingNeuro();
    }

    onAddOverlayDiv() {
        this.testingNeuroForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.testingNeuroSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.testingNeuroForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateTestingNeuroId = null;
    }
    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        // console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.testingNeuroServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.testingNeuroList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.testingNeuroServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.testingNeuroList = result['data'];
            } else {
                this.testingNeuroList = [];
            }
            // console.log(result, 'result');
        });
    }
}
