import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {PostureService} from '../web-services/posture.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {UsersService} from '../web-services/users.service';
import {SessionService} from '../services/session.service';
import {PostureMultiSelects} from "../uiUtils/pages/observations/PostureMultiSelects";
import {FormDataDTO} from "../dto/FormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {ListOfValuesService} from "../web-services/list-of-values.service";

@Component({
               selector: 'app-posture',
               templateUrl: './posture.component.html',
               styleUrls: ['./posture.component.css']
})
export class PostureComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePostureId = null;
    listOfValuesDetailList: any = [];
    usersList: any = [];
    postureList: any = [];
    postureSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    postureForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    postureDescription = Constant.OBSERVATIONS_POSTURE_LOV_POSTURE_DESCRIPTION;
    gaitDescription = Constant.OBSERVATIONS_POSTURE_LOV_GAIT_DESCRIPTION;

    expertId;
    patientId;
    appointmentId;
    caseId;

    constructor(
        private postureServices: PostureService, private listOfValuesDetailServices: ListOfValuesDetailService,
        private usersServices: UsersService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService,
        private sessionService: SessionService,
        private postureMultiSelects: PostureMultiSelects,
        private lovServices: ListOfValuesService,

    ) {
    };

    lovDataObject : any = {};
    responseData : any;
    refDataObj : any;

    postureDescriptionList: any = [];
    gaitDescriptionList: any = [];

    postureDescriptionError = false;
    gaitDescriptionError = false;
    objectMultiSelects: any;

    getResponse($event) {
      this.postureMultiSelects.getResponseObjects($event);

    }

    get addUpdateTitle() {
        return this.updatePostureId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.postureSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;

    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countPosture() {
        this.postureSearch.controls['expertId'].setValue(this.expertId);
        this.postureSearch.controls['patientId'].setValue(this.patientId);
        this.postureSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.postureServices.countPosture(this.postureSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
               /// this.findAllPosture();
            } else {
                this.totalRecords = 0;
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {
        this.postureForm.markAllAsTouched();


      const postureDTO = new FormDataDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.postureForm);

      const diagnosisData = new DiagnosisDataHelper(postureDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      console.log(uniqueCaseDiagnosisPredictionList);
      console.log(postureDTO.redFlagData);

      console.log(postureDTO);
      if (!postureDTO.hasMultiSelectErrors && this.postureForm.valid) {

        this.addPosture(postureDTO);

        if (uniqueCaseDiagnosisPredictionList.length > 0) {
          this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

            console.log(res);
          });
        }


        if (postureDTO.redFlagData.length > 0) {

          this.lovServices.createListOfCaseRedFlags(postureDTO.redFlagData).subscribe(res => {

            if (res.status === 201) {

              this.messageService.add({
                severity: 'success',
                summary: this.translate("Red Flags Updated"),
              });
            }

          });
        }


      }
      else {

        postureDTO.multiSelectErrorFields.forEach(multiSelectError => {

          document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
        });
      }
    }

    addPosture(postureDTO: FormDataDTO): void {

        this.postureServices.addPostureCpa(postureDTO).subscribe(res => {

          if (res.status === 200) {

            this.messageService.add({
              severity: 'success',
              summary: this.translate(this.labels.entityNamePosture) +
                this.translate(this.labels.addedSuccessfully),
            });
            this.lovDataObject = {"refId":0,"refType":"","reset": true};
            this.lovDataObject = {"refId":0,"refType":"","reset": false};
            this.submitted = true;
            this.showHideAddDialog();
            this.findAllPosturesByAppointmentId();

          } else {
            alert('eror');
          }

        });
    }

  findAllPostureByPatientId(): void {
    this.startLoader();
    this.postureServices.findAllPostureByPatientId(this.patientId).subscribe(
      res => {

        if (res.status === 200) {

          this.postureList = res.body;
          this.totalRecords = this.postureList.length;
        } else {
          this.postureList = [];
          this.totalRecords = 0;
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  findAllPosturesByAppointmentId(): void {
    this.startLoader();
    this.postureServices.findAllPosturesByAppointmentId(this.appointmentId).subscribe(
      res => {

        if (res.status === 200) {

          this.postureList = res.body;
          this.totalRecords = this.postureList.length;
        } else {
          this.postureList = [];
          this.totalRecords = 0;
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
       // this.findAllPosture();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.postureSearch.reset();
      //  this.countPosture();
        // this.findAllPosture();
    }

    addUpdatePosture(posture) {
        this.startLoader();
        if (this.updatePostureId == null) {
            this.postureServices.addPosture(this.postureForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    posture = res['data'][0];
                    this.postureList.push(posture);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNamePosture) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNamePosture)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countPosture();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            posture.postureId = this.updatePostureId;
            this.postureServices.updatePosture(posture,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.postureList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNamePosture) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNamePosture)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePostureId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));

        this.lovDataObject = {"refId":0,"refType":""};
        this.objectMultiSelects = this.postureMultiSelects.objectMultiSelects;

      this.postureForm = new FormGroup({
                                             postureDescription: new FormControl(),
                                             postureComment: new FormControl('', [Validators.required, validateSpaces]),
                                             gaitDescription: new FormControl(),
                                             gaitComment: new FormControl('', [Validators.required, validateSpaces]),
                                         });
        this.postureSearch = new FormGroup({
                                               postureDescription: new FormControl(),
                                               postureComment: new FormControl(),
                                               gaitDescription: new FormControl(),
                                               gaitComment: new FormControl(),
                                               patientId: new FormControl(),
                                               expertId: new FormControl(),
                                               appointmentId: new FormControl(),
                                               userId: new FormControl(),
                                           });
        this.findAllPosturesByAppointmentId();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.postureSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }


    deleteByIdPosture(postureId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.postureServices.deleteByIdPosture(postureId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.postureList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNamePosture) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNamePosture)
                            });
                        this.countPosture();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deletePosture"
                                  });
    }

    loadPostureToEdit(postureId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let posture = this.postureList.find(x => x['postureId'] == postureId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updatePostureId = posture['postureId'];
        this.selectedIndex = index;

        this.lovDataObject = {"refId":posture['postureId'],"refType":""};

        if(posture['postureDescription']['listOfValuesMasterId']) {
            this.postureForm.controls['postureDescription'].setValue(posture['postureDescription']['listOfValuesMasterId']);
        }
        if(posture['postureComment']) {
            this.postureForm.controls['postureComment'].setValue(posture['postureComment']);
        }
        if(posture['gaitDescription']['listOfValuesMasterId']) {
            this.postureForm.controls['gaitDescription'].setValue(posture['gaitDescription']['listOfValuesMasterId']);
        }
        if(posture['gaitComment']) {
            this.postureForm.controls['gaitComment'].setValue(posture['gaitComment']);
        }
        if(posture['patientId']) {
            this.postureForm.controls['patientId'].setValue(posture['patientId']['patientId']);
        }
        if(posture['expertId']) {
            this.postureForm.controls['expertId'].setValue(posture['expertId']['expertId']);
        }
        if(posture['appointmentId']) {
            this.postureForm.controls['appointmentId'].setValue(posture['appointmentId']['appointmentId']);
        }
        this.postureForm.controls['userId'].setValue(posture['userId']);

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.postureForm.reset();
        this.postureForm.markAsUntouched();
        this.updatePostureId = null;
    }

    fieldReset(control) {
        return this.postureForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.postureForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.postureForm.get(control)?.valid && this.postureForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.postureForm.reset();
    }

    onCloseSearch() {
        this.postureSearch.reset();
    }

    refresh() {
        this.countPosture();
    }

    onAddOverlayDiv() {
        this.postureForm.reset();
    }

    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.postureSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.postureForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePostureId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.postureServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.postureList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.postureServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.postureList = result['data'];
            } else {
                this.postureList = [];
            }
            console.log(result, 'result');
        });
    }
}
