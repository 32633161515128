<div class="container">
  <div class="row">
  <div class="p-col-4 p-md-4 p-lg-4 p-0 m-0" style="border: 0px solid red">
  <div class="clientDetailBox">
        <div class="text-center">
          <img src="{{patientObject.avatarSrc}}" class="rounded-circle" style="width: 120px;"
               alt="Avatar" />
        </div>
    </div>
  </div>

  <div class="p-col-8 p-md-8 p-lg-8 p-0 m-0">
    <div class="clientDetailBox-patient">
      <div class="row" style="padding-left:20px;">
        <div class="p-col-3 p-md-3 p-lg-3">
          <span class="p-panel-header" style="font-size:14px;">Name</span>
          <div style="font-size: 13px"> {{patientObject.firstName}}  {{patientObject.lastName}}</div>
        </div>
      <div class="p-col-3 p-md-3 p-lg-3">
        <span class="p-panel-header" style="font-size:14px;">Gender</span>
        <div style="font-size: 13px"> {{patientObject.sex.label}}</div>
      </div>
      <div class="p-col-3 p-md-3 p-lg-3">
        <span class="p-panel-header" style="font-size:14px">Date of Birth</span>
        <div style="font-size: 13px"> {{patientObject.dob | date: 'dd-MM-YYYY' }}</div>
      </div>

      </div>
      <div class="row" style="padding-left:20px;">
        <div class="p-col-3 p-md-3 p-lg-3">
          <span class="p-panel-header" style="font-size:14px">Phone</span>
          <div style="font-size: 13px"> {{patientObject.emergencyContact }}</div>
        </div>
        <div class="p-col-3 p-md-3 p-lg-3">
          <span class="p-panel-header" style="font-size:14px">Email</span>
          <div style="font-size: 13px"> {{patientObject.email}}</div>
        </div>
        <div class="p-col-3 p-md-3 p-lg-3">
          <span class="p-panel-header" style="font-size:14px">Address</span>
          <div style="font-size: 13px"> {{patientObject.address }}</div>
        </div>

      </div>
      <div class="row" style="padding-left:20px;">
        <div class="p-col-3 p-md-3 p-lg-3">
          <span class="p-panel-header" style="font-size:14px">Registered Date</span>
          <div style="font-size: 13px"> {{patientObject.insertionTimestamp | date: 'dd-MM-YYYY' }}</div>
        </div>
        <div class="p-col-3 p-md-3 p-lg-3">
          <span class="p-panel-header" style="font-size:14px">Video</span>
          <div (click)="videoConference()" class="p-panel-header"><i class="fa-solid fa-video"></i></div>
        </div>

      </div>


    </div>
  </div>
  </div>
</div>
