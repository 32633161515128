export class DiagnosisDataHelper {

  diagnosisData: any;

  constructor(diagnosisData: any) {

    this.diagnosisData = diagnosisData;
  }

  getUniqueDiagnosisData(): any {

    let uniqueCaseDiagnosisPredictionList = [];

    this.diagnosisData.map(diagData =>  {

      uniqueCaseDiagnosisPredictionList = [...uniqueCaseDiagnosisPredictionList, ...diagData.diagnosis];
    });

    uniqueCaseDiagnosisPredictionList = this.unique(uniqueCaseDiagnosisPredictionList, 'nonObjectDiagnosisId');

    return uniqueCaseDiagnosisPredictionList;

  }

  unique(array, propertyName): any {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
  }



}
