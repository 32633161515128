import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencyService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgency(agencyId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Agency/deleteAgency', {'agencyId': agencyId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAgency(agency): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Agency/count', {'agency': agency}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateAgency(agency): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Agency/updateAgency', [agency], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addAgency(agency): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Agency/addAgency', [agency], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAgency(agency, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Agency/findAllAgency', {'agency': agency, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search
    agencyGlcount(searchTerm,status): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Agency/countGlobalAgency',
            {'searchTerm': searchTerm,'status':status},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findGlobalAgency(searchTerm,status,pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Agency/findGlobalAgency',
            {'searchTerm': searchTerm,'status':status, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }


}
