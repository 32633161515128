<div class="h-100">
    <div class="loginContainer" id="loginContainer-loginTypes">

        <!-- Login Types Header-->
        <!--        <div class="loginHeader" id="loginTypes-header">-->
        <!--            <div class="container">-->
        <!--                <div class="loginHeaderInner">-->
        <!--                    <div class="loginHeading">-->
        <!--                        <h3>Welcome to CPA</h3>-->
        <!--                    </div>-->
        <!--                    <div class="loginLogo">-->
        <!--                        &lt;!&ndash; <div style="background: url(../../../assets/images/logo.png);"></div> &ndash;&gt;-->
        <!--                        <a> <img src="assets/images/logo/logo.png"/> </a>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!-- Login Types Body Area-->
        <div class="container-fluid h-100" id="loginTypes-body">
            <div class="row justify-content-center h-100">
                <div class="container">
                    <div class="loginUsertypesTitle">
                        <!--                        <h3>System will automatically detect type of user logging in</h3>-->
                        <h3 class="allign-Center">Please Select Your Role</h3>
                    </div>
                </div>
                <div class="col-md-4" style="margin-top: 40px">
                    <p-card id="loginTypes-card" styleClass="p-card-shadow">
                        <form [formGroup]="roleSelectionForm" class="formContainer">
                            <p-listbox [options]="loggedInType" formControlName="role" (click)="onClickBusinessRole()"
                                       optionLabel="title" optionValue="businessRolesId"
                                       styleClass="loggedInTypeListbox">
                            </p-listbox>
                        </form>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
</div>
