import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ExerciseCategoryService} from '../web-services/exercise-category.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component(
    {
        selector: 'app-exercise-category',
        templateUrl: './exercise-category.component.html',
        styleUrls: ['./exercise-category.component.css']
    })
export class ExerciseCategoryComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateExerciseCategoryId = null;
    exerciseCategoryList: any = [];
    exerciseCategorySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    exerciseCategoryForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private exerciseCategoryServices: ExerciseCategoryService, private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService, private messageService: MessageService, private route: Router,
        private loader: NgxUiLoaderService, private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateExerciseCategoryId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.exerciseCategorySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countExerciseCategory();
        this.findAllExerciseCategory();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countExerciseCategory() {
        this.exerciseCategoryServices.countExerciseCategory(this.exerciseCategorySearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllExerciseCategory();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateExerciseCategory(this.exerciseCategoryForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllExerciseCategory();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.exerciseCategorySearch.reset();
        this.countExerciseCategory();
        // this.findAllExerciseCategory();
    }

    addUpdateExerciseCategory(exerciseCategory) {
        this.startLoader();
        if (this.updateExerciseCategoryId == null) {
            this.exerciseCategoryServices.addExerciseCategory(this.exerciseCategoryForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    exerciseCategory = res['data'][0];
                    this.exerciseCategoryList.push(exerciseCategory);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnAdd),
                                                detail: this.translate(this.labels.entityNameExerciseCategory)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countExerciseCategory();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            exerciseCategory.exerciseCategoryId = this.updateExerciseCategoryId;
            this.exerciseCategoryServices.updateExerciseCategory(exerciseCategory).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.exerciseCategoryList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameExerciseCategory)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateExerciseCategoryId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.exerciseCategoryForm = new FormGroup({
                                                      exerciseCategoryTitle: new FormControl('', [Validators.required, validateSpaces]),
                                                      patientId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                      expertId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                      userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                  });
        this.exerciseCategorySearch = new FormGroup(
            {
                exerciseCategoryTitle: new FormControl(),
                patientId: new FormControl(),
                expertId: new FormControl(),
                userId: new FormControl(),
            });
        this.countExerciseCategory();
        this.findAllExerciseCategory();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.exerciseCategorySearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllExerciseCategory() {
        this.startLoader();
        this.exerciseCategoryServices.findAllExerciseCategory(this.exerciseCategorySearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.exerciseCategoryList = res['data'];
                } else {
                    this.exerciseCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdExerciseCategory(exerciseCategoryId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.exerciseCategoryServices.deleteByIdExerciseCategory(exerciseCategoryId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.exerciseCategoryList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.btnDelete),
                                                    detail: this.translate(this.labels.entityNameExerciseCategory)
                                                });
                        this.countExerciseCategory();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadExerciseCategoryToEdit(exerciseCategoryId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let exerciseCategory = this.exerciseCategoryList.find(x => x['exerciseCategoryId'] == exerciseCategoryId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateExerciseCategoryId = exerciseCategory['exerciseCategoryId'];
        this.selectedIndex = index;
        this.exerciseCategoryForm.controls['exerciseCategoryTitle'].setValue(exerciseCategory['exerciseCategoryTitle']);
        this.exerciseCategoryForm.controls['patientId'].setValue(exerciseCategory['patientId']);
        this.exerciseCategoryForm.controls['expertId'].setValue(exerciseCategory['expertId']);
        this.exerciseCategoryForm.controls['userId'].setValue(exerciseCategory['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.exerciseCategoryForm.reset();
        this.exerciseCategoryForm.markAsUntouched();
        this.updateExerciseCategoryId = null;
        closeAddOverlaydiv();
    }

    fieldReset(control) {
        return this.exerciseCategoryForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.exerciseCategoryForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.exerciseCategoryForm.get(control)?.valid && this.exerciseCategoryForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.exerciseCategoryForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.exerciseCategorySearch.reset();
    }

    refresh() {
        this.countExerciseCategory();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.exerciseCategoryForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.exerciseCategoryForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateExerciseCategoryId = null;
    }
}
