<!--<p-dialog header="Add Friends" [(visible)]="PatientsviewDetailspopup" [breakpoints]="{'960px': '75vw'}"-->
<!--          [style]="{width: '50vw'}" [modal]="true" [draggable]="false" [resizable]="false"-->
<!--          class="pngDialog pngDialogSm" id="PatientsviewDetailspopup-pngDialog">-->
<!--<ngx-ui-loader></ngx-ui-loader>-->
<form class="formContainer">
    <div class="p-fluid p-formgrid p-grid" id="PatientsviewDetailspopup-pngDialog-pgrid">

        <!-- MAIN FORM GRID CONTENT AREA-->
        <div class="readonlyContainer" id="PatientsviewDetailspopup-readonlyContainer">
            <div class="p-fluid p-formgrid p-grid p-mb-5" id="PatientsviewDetailspopup-formgrid">
                <div *ngFor="let item of columnsList, let i = index" class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">{{item.label}}</div>
                    <div class="readOnlyValue">{{item.value}}</div>
                </div>
                <!-- only shows cv links -->
                <div class="p-col-12 p-md-12"  *ngIf="cvArray && cvArray.length > 0">
                    <div class="p-col-12 p-md-12 readOnlyTitle noPaddingClass">{{labels.cvUrls.label}}</div>
                    <div *ngFor="let cv of cvArray, let i = index" class="p-col-12 p-md-12 noPaddingClass">
                        <a [href]="cv.src" target="_blank">File {{i+1}}</a>
                    </div>
                </div>

                <!-- only shows signature files -->
                <div class="p-col-12 p-md-12" *ngIf="signArray && signArray.length > 0">
                    <div class="p-col-12 p-md-12 readOnlyTitle noPaddingClass">{{labels.signUrls.label}}</div>
                    <div *ngFor="let cv of signArray, let i = index" class="p-col-12 p-md-12 noPaddingClass">
                        <a [href]="cv.src" target="_blank">File {{i+1}}</a>
                    </div>
                </div>
                <!-- only shows T&C files -->
                <div class="p-col-12 p-md-12" *ngIf="tncArray && tncArray.length > 0">
                    <div class="p-col-12 p-md-12 readOnlyTitle noPaddingClass">{{labels.tncUrls.label}}</div>
                    <div *ngFor="let cv of tncArray, let i = index" class="p-col-12 p-md-12 noPaddingClass">
                        <a [href]="cv.src" target="_blank">File {{i+1}}</a>
                    </div>
                </div>
            </div>

        </div>

    </div>
</form>
<!--</p-dialog>-->
