import {throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';

export class Constant {

    public static API_ENDPOINT = '';
    public static API_BASE_URL = '';
    public static ITEM_PER_PAGE = 10;
    public static PAGE_NUMBER = 0;
    public static CPA_APPLICATION_ID = 1;

    //For local
    // public static UMS_URL = 'http://localhost:8080/CPAUMS/rest/ums/';
    // public static BASE_URL = 'http://localhost:8080/CPA/Hive-Worx/';
    // public static Server_URL = 'https://devbe.hive-worx.com:8443/CPAUMS/rest/';
    // public static BASE_URL_SSO = "https://devbe.hive-worx.com:8443/"
    // public static CHAT_URL = "https://devbe.hive-worx.com:8443/";

    // public static UMS_URL = 'https://devbe.hive-worx.com:8443/CPAUMS/rest/ums/';
    // public static BASE_URL = 'http://localhost:8080/CPA/Hive-Worx/';
    // public static Server_URL = 'https://devbe.hive-worx.com:8443/CPAUMS/rest/';
    // public static BASE_URL_SSO = "https://devbe.hive-worx.com:8443/"
    // public static CHAT_URL = "https://devbe.hive-worx.com:8443/";


    // // For QA Server
    // public static UMS_URL = 'https://qabe.hive-worx.com:8443/CPAUMS/rest/ums/';
    // public static BASE_URL = 'https://qabe.hive-worx.com:8443/CPA/Hive-Worx/';
    // public static Server_URL="https://qabe.hive-worx.com:8443/CPAUMS/rest/"
    // public static BASE_URL_SSO="https://qabe.hive-worx.com:8443/"
    // public static CHAT_URL = "https://qabe.hive-worx.com:8443/";

    // // For DEV Server
    // public static UMS_URL = 'https://devbe.hive-worx.com:8443/CPAUMS/rest/ums/';
    // public static BASE_URL = 'https://devbe.hive-worx.com:8443/CPA/Hive-Worx/';
    // public static Server_URL = "https://devbe.hive-worx.com:8443/CPAUMS/rest/"
    // public static BASE_URL_SSO = "https://devbe.hive-worx.com:8443/"
    // public static CHAT_URL = "https://devbe.hive-worx.com:8443/";

    // public static UMS_URL = Constant.API_ENDPOINT+'CPAUMS/rest/ums/';
    // public static BASE_URL = Constant.API_ENDPOINT+'CPA/Hive-Worx/';
    // public static Server_URL = Constant.API_ENDPOINT+'CPAUMS/rest/';
    // public static BASE_URL_SSO = Constant.API_ENDPOINT;
    // public static CHAT_URL = Constant.API_ENDPOINT;


    public static UMS_URL =  environment.baseUrl  + 'CPAUMS/rest/ums/';
    public static BASE_URL = environment.baseUrl  + 'CPA/Hive-Worx/';
    public static Server_URL = environment.baseUrl;
    public static BASE_URL_SSO = environment.baseUrl;
    public static CHAT_URL =  environment.baseUrl;
    public static CPA_SPRING_API_URL = environment.k8Cluster  + 'cpa-main-service/cpa-spring/api/';
    public static CPA_SPRING_PUBLIC_API_URL = environment.k8Cluster  + 'cpa-main-service/cpa-spring/api/public/';

    public static AUTH_SERVICE = environment.k8Cluster  + 'auth-service/api/';
    public static AUTH_SERVICE_PUBLIC =  environment.k8Cluster  + 'auth-service/api/public/';

    public static CPA_APPOINTMENTS_SERVICE = environment.k8Cluster + 'appointment-service/api/';
    public static CPA_JWT_SERVICE = environment.jwtService + 'api/';
    public static CPA_CLIENT_SERVICE = environment.k8Cluster + 'client-service/api/';
    public static CPA_CLINICAL_DATA_SERVICE = environment.k8Cluster + 'clinical-data-service/api/';
    public static CPA_EXPERT_PATIENT_SERVICE = environment.k8Cluster + 'expert-patient-service/api/';




    // public static FILE_UPLOAD_SERVER_URL = "https://qabe.hive-worx.com:8443/";
    public static FILE_UPLOAD_SERVER_URL = '';
    public static NOTIFICATION_TIME = 30000;


    public static CPAFrontEndURL = '';
    private static yearList: any[] = null;


    public static getYearList(): any[] {
        if (this.yearList == null) {
            this.yearList = [];
            for (let i = 2020; i >= 1700; i--) {
                this.yearList.push({name: i + '', value: i});
            }
        }
        return this.yearList;
    }

    public static handleError(error: HttpErrorResponse) {
        // console.log(error.message);
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error.message);
    }
///TODO NOT NEEDED ANYMORE NEEDS REMOVING

    public static setApiEndPoint(value) {

        Constant.API_ENDPOINT = value;

        this.UMS_URL = Constant.API_ENDPOINT+'CPAUMS/rest/ums/';
        this.BASE_URL = Constant.API_ENDPOINT+'CPA/Hive-Worx/';
        this.Server_URL = Constant.API_ENDPOINT+'CPAUMS/rest/';
        this.CPA_SPRING_API_URL = Constant.API_ENDPOINT + 'cpa-spring/api/';
        this.CPA_SPRING_PUBLIC_API_URL = Constant.API_ENDPOINT + 'cpa-spring/api/public/';
        this.BASE_URL_SSO = Constant.API_ENDPOINT;
        this.CHAT_URL = Constant.API_ENDPOINT;
        this.FILE_UPLOAD_SERVER_URL = Constant.API_ENDPOINT;
    }
///TODO NOT NEEDED ANYMORE NEEDS REMOVING
    public static setApiBaseUrl(value){

        Constant.API_BASE_URL = value;
        console.log("value of end point :: ",Constant.API_BASE_URL);
    }

    public static SUBSCRIPTION_STATUS_DRAFT = 'Draft';
    public static SUBSCRIPTION_STATUS_PENDING = 'Pending';
    public static SUBSCRIPTION_STATUS_COMPLETED = 'Completed';

    public static AGENCY_STATUS_ACTIVE = 'Active';
    public static AGENCY_STATUS_INCOMPLETE = 'InComplete';
    public static AGENCY_STATUS_DEACTIVATED = 'Deactivated';

    public static SUPER_ADMIN_BUSINESS_ROLE_ID = 394;
    public static ADMIN_BUSINESS_ROLE_ID = 395;
    public static EXPERT_BUSINESS_ROLE_ID = 396;
    public static CORPORATE_BUSINESS_ROLE_ID = 397;
    public static INDIVIDUAL_BUSINESS_ROLE_ID = 398;

    public static SUPER_ADMIN_BUSINESS_ROLE = 'superAdmin';
    public static ADMIN_BUSINESS_ROLE = 'admin';
    public static EXPERT_BUSINESS_ROLE = 'expert';
    public static CORPORATE_BUSINESS_ROLE = 'corporate';
    public static INDIVIDUAL_BUSINESS_ROLE = 'individual';

    public static PATIENTS_LOV_ITMES = ['Sex', 'GenderIdentity', 'Consent', 'CommunicationPreferences', 'MarketingInfo', 'PatientType', 'Billing', 'ConscessionType', 'invoice', 'refSource'];
    public static EXPENSE_LOV_ITMES = ['expenseType'];
    public static EXPENSE_DETAIL_LOV_ITEMS = ['expenseItems'];
    public static PAYMENTS_LOV_ITMES = ['source','balanceSummary','paymentType'];
    public static PAYMENTS_DETAIL_LOV_ITMES_LIST = ['paymentItems'];
    public static EXPERT_LOV_ITEMS = ['expertType','professionalDetail'];

    public static EXPERT_LOV_PRO_DETAIL = 'professionalDetail';
    public static EXPERT_LOV_EXPRT_TYPE = 'expertType';
    public static PAYMENTS_DETAIL_LOV_ITMES = 'paymentItems';
    public static PAYMENTS_LOV_PAYMENT_TYPE = 'paymentType';
    public static PAYMENTS_LOV_BALANCE_SUMMARY = 'balanceSummary';
    public static PAYMENTS_LOV_SOURCE = 'source';
    public static EXPENSE_DETAIL_LOV_EXPENSE_ITEMS = 'expenseItems';
    public static EXPENSE_LOV_EXPENSE_TYPE = 'expenseType';
    public static PATIENT_SEX = 'Sex';
    public static PATIENT_GENDERIDENTITY = 'GenderIdentity';
    public static PATIENT_CONSENT = 'Consent';
    public static PATIENT_COMM_PREFS = 'CommunicationPreferences';
    public static PATIENT_MARKETING_INFO = 'MarketingInfo';
    public static PATIENT_TYPE = 'PatientType';
    public static PATIENT_BILLING = 'Billing';
    public static PATIENT_CONSCESSION_TYPE = 'ConscessionType';
    public static PATIENT_INVOICE = 'invoice';
    public static PATIENT_REF_SOURCE = 'refSource';

    // public static PATIENTS_STATUS_ACTIVE = 'Active';
    public static PATIENT_REF_DEACTIVATED = 'Deactivated';

    public static PATIENTS_STATUS_PHYSIOTHERAPY = 'Physiotherapy';
    public static PATIENTS_STATUS_PSYCHOLOGY = 'Psychology';
    public static PATIENTS_STATUS_ACTIVE = 'Active';
    public static PATIENTS_STATUS_DEACTIVATED = 'Deactivated';
    public static PATIENTS_BY_PROFESSION = 'Patients By Profession';

    public static INVOICE_STATUS_PAID = "Paid";
	public static INVOICE_STATUS_PENDING = "Pending";
	public static INVOICE_STATUS_PARTIAL = "PartiallyPaid";

	public static JSON_KEY_DAYS = "days";

    public static weekDays = [
        {name: 'Sunday', code: 0},
        {name: 'Monday', code: 1},
        {name: 'Tuesday', code: 2},
        {name: 'Wednesday', code: 3},
        {name: 'Thursday', code: 4},
        {name: 'Friday', code: 5},
        {name: 'Saturday', code: 6}

    ];

    // PRESENT CONDITION HISTORY
    public static PRESENT_CONDITION_LOV_SERVICE_SEEN = "Services Seen";
    public static PRESENT_CONDITION_LOV_TREATMENT_TYPE = "Treatment Type";
    public static PRESENT_CONDITION_LOV_INVESTIGATION = "Investigation";
    public static PRESENT_CONDITION_LOV_PRESENT_SYMPTOMS = "Present Symptoms";

    // PAST MEDICAL HISTORY
    public static PAST_MEDICAL_LOV_SPECIAL_QUESTIONS = "Special Questions";
    public static PAST_MEDICAL_HISTORY_PREVIOUS_MSK = "Previous Msk";
    public static PAST_MEDICAL_HISTORY_BLOODS = "Bloods";
    public static PAST_MEDICAL_HISTORY_IMAGING = "Imaging";
    public static PAST_MEDICAL_HISTORY_SYSTEMIC = "Systemic";
    public static PAST_MEDICAL_HISTORY_ILLNESS = "Illness";
    public static PAST_MEDICAL_HISTORY_THREADS = "Threads";

    // DRUG HISTORY
    public static DRUG_LOV_DRUG_HISTORY = "Drug History";
    public static VACCINATIONS = "Vaccinations";
    public static DRINKS = "Drinks";
    public static RECREATION = "Recreation";
    public static SMOKING = "Smoke";
    public static CAFFEINATEDDRINK = "Amount of Caffeinated Drink";

    // SOCIAL HISTORY
    public static SOCIAL_LOV_OCCUPATION = "Occupation";
    public static SOCIAL_LOV_MOBILITY = "Mobility";
    public static SOCIAL_LOV_HOBBIES = "Hobbies";
    public static SOCIAL_LOV_ADL = "Adl";

    // BODY CHART
    public static BODY_CHART_LOV_DESCRIPTION = "Description";
    public static BODY_CHART_LOV_AGGS = "AGGS";
    public static BODY_CHART_LOV_EASES = "Eases";
    public static BODY_CHART_LOV_INTERVAL_DESCRIPTION = "Interval Description";

    // OBSERVATIONS
    public static OBSERVATIONS_DROPDOWN_CATEGORY_OBSERVATIONS = "Observations";
    public static OBSERVATIONS_DROPDOWN_CATEGORY_PALPATION = "Palpation";

    public static OBSERVATIONS_POSTURE_LOV_POSTURE_DESCRIPTION = "Posture Description";
    public static OBSERVATIONS_POSTURE_LOV_GAIT_DESCRIPTION = "Gait Description";

    // AROM TESTING
    public static TESTING_AROM_LOV_MOVEMENT = "Movement";
    public static TESTING_AROM_LOV_MEASURE = "Measures";
    public static TESTING_AROM_LOV_LIMITING_FACTOR = "limiting Factor";

    // NEURO TESTING
    public static TESTING_NEURO_LOV_DERMATOME_DESCRIPTION = "Dermatome Description";
    public static TESTING_NEURO_LOV_REFLEXES = "Reflexes";
    public static TESTING_NEURO_LOV_REFLEXES_DESCRIPTION = "Reflexes Description";

    // SPECIAL TESTING
    public static TESTING_SPECIAL_LOV_TYPE = "Type";
    public static TESTING_SPECIAL_LOV_TEST_TYPE = "type";

    // TREATMENT
    public static TREATMENT_LOV_TREATMENT_DESCRIPTION = "Treatment Description";
    public static TREATMENT_LOV_TREATMENT_PLAN = "Treatment Plan";
    public static TREATMENT_LOV_TREATMENT_CONSENT = "Treatment Consent";

    // GOALS
    public static GOALS_LOV_GOALS = "Goals";

    // DISCHARGE
    public static DISCHARGE_LOV_SHORT_TERM_BENEFITS = "Short Term Benefits";
    public static DISCHARGE_LOV_LONG_TERM_BENEFITS = "Long Term Benefits";
    public static DISCHARGE_LOV_OUTCOME_MEASURES_IMPLEMENT = "Outcome Measures To Be Implemented";

    public static APPOINTMENT_NEW_ID = 1;
    public static APPOINTMENT_FOLLOWUP_ID = 2;


    public static NOT_UNIQUE_EMAIL = 'NotUnique';

    public static SESSION_EXPERT_ID = 'expertId';
    public static SESSION_PATIENT_ID = 'patientId';
    public static SESSION_APPOINTMENT_ID = 'appointmentId';


    public static APPOINTMENT_STATUS_PENDING = 'Pending';
    public static APPOINTMENT_STATUS_CONFIRMED = 'Confirmed';
    public static APPOINTMENT_STATUS_CANCELLED = 'Cancelled';

    public static USER_TYPE_SUPERADMIN = 1;
    public static USER_TYPE_ADMIN = 2;
    public static USER_TYPE_EXPERT = 3;
    public static USER_TYPE_CORPORATE = 4;
    public static USER_TYPE_INDIVIDUAL = 5;


    public static MESSAGE_RECIEVED_NOTIFICATION_EVENT= 'Message Received';
    public static NEW_MESSAGE_RECIEVED= 'New Message Received';

    public getHttpResponseObject(): any {

      type Response = {

          headers: any;
          status: number;
          body: any;

      }
    }
}
