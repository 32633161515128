import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PatientsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdPatients(patientId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Patients/deletePatients', {'patientId': patientId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findPatientsById(patientId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Patients/findPatients', {'patientId': patientId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countPatients(patients,userType,refId): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'Patients/count', {'patients': patients,'referenceData':{'userType':userType,'refId':refId}}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updatePatients(patients): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(Constant.BASE_URL + 'Patients/updatePatients', [patients], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPatients(patients,userType,refId): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        let refObj = {'userType':userType,'refId':refId};
        return this.httpClient.post<string>(Constant.BASE_URL + 'Patients/addPatients', [patients,refObj], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllPatients(patients, pageNo: number, itemsPerPage: number, userType, refId): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Patients/findAllPatients',
            {'patients': patients,'referenceData':{'userType':userType,'refId':refId}  ,'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllPatientsByClientId(clientId): Observable<any> {
      return this.httpClient.get<any>(
        Constant.CPA_SPRING_API_URL + 'patients/search?clientId=' + clientId,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      ).pipe(
       retry(3), catchError(Constant.handleError));
  }

  /* this is the one using the micro services now */
  findPatientsByClientId(clientId): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_EXPERT_PATIENT_SERVICE + 'patients/filter?type=byClient&id=' + clientId,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findAllCasesByPatientId(patientId: number): Observable<any> {

    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'patients/ ' +  patientId + '/cases',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }


  findBodyChartMasterByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'patients/ ' +  patientId + '/body-charts-master',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findBodyRedFlagsByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'patients/ ' +  patientId + '/red-flags',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }


  findLatestInitialAssessmentByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'patients/ ' +  patientId + '/red-flags',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }



  // getPatientByUserId
    findPatientsByUserId(userId): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Patients/getPatientByUserId',
            {'userId': userId}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // getParentIdByPatient
    findParentIdByPatient(patientId): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Patients/getParentIdByPatient',
            {'patientId': patientId}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom services to handle globalSearching
    patientGlcount(searchTerm,refId,userType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Patients/countGlobalPatients',
                                            {'searchTerm': searchTerm,'refId':refId,'userType':userType},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalPatient(searchTerm, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Patients/findGlobalPatients',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage},
            'refId':refId,'userType':userType},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // Manage Subscriptions
    addUserSubscriptionlist(userId, UserSubscriptionlist): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Patients/addPatientsSubscriptions', {'patientId': userId, 'subscription': UserSubscriptionlist},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }


  addPatient(patient): Observable<any> {
    return this.httpClient.post<any>(
      Constant.CPA_EXPERT_PATIENT_SERVICE + 'patients', patient,
      {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'}
    ).pipe(retry(0), catchError(Constant.handleError));
  }

  updatePatientPatched(patientPatch: any, patientId): Observable<any> {

    return this.httpClient.patch<string>(Constant.CPA_EXPERT_PATIENT_SERVICE + 'patients/' + patientId, patientPatch, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/merge-patch+json'),
      observe: 'response'
    }).pipe(
      retry(0), catchError(Constant.handleError));


  }
}
