import { Component, OnInit } from '@angular/core';
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../../web-services/clinical-data.service';
import {SessionService} from '../../services/session.service';
import {FollowUpSteps} from '../../uiUtils/FollowUpSteps';
import {BodyChartFormDataDTO} from '../../dto/BodyChartFormDataDTO';
import {Router} from '@angular/router';
import {Canvg} from 'canvg';
import {BodyChartDetailService} from '../../web-services/body-chart-detail.service';
import {BodyChartMasterService} from '../../web-services/body-chart-master.service';
import {MessageService} from 'primeng/api';
import {ComponentFormBase} from '../../util/ComponentFormBase';
import {SharedService} from '../../web-services/shared.service';
declare var $: any;

@Component({
  selector: 'app-body-chart',
  templateUrl: './follow-up-body-chart.component.html',
  styleUrls: ['./follow-up-body-chart.component.css']
})
export class FollowUpBodyChartComponent implements OnInit {

  appointmentId: number;
  caseId: number;
  patientId: number;
  expertId: number;
  fullAppointment: any;
  painScoreColours = ['#7AD047', '#95CE49', '#ACE24E', '#F9E104', '#F8CE07', '#FCB129', '#FCA329', '#FC8A29', '#F96B2D', '#E21B15']
  patientObj: any;
  bodyPartData: any;
  stepsItems: any;
  activeStep: number;
  bodyAnatomyFrontImageString = '';
  bodyAnatomyBackImageString = '';
  bodyAnatomySkeletonImageString  = '';
  bodyAnatomyInformation = {
    bodyChartDetailResponseDTO: [],
    bodyChartMasterResponseDTO: null,

  };
  bodyAnatomyFollowUpInformation = {
    bodyChartDetailResponseDTO: [],
    bodyChartMasterResponseDTO: null,

  };
  editedBodyAnatomyInformation = []
  baseComponent = this.componentFormBase;
  existingBodyDataLoaded = false;
  bodyAnatomyNonSavedInformation = [];
  addEditTitle = '';



  constructor(
    private clinicalDataService: ClinicalDataService,
    private sessionService: SessionService,
    private router: Router,
    private bodyChartDetailServices: BodyChartDetailService,
    private bodyChartMasterServices: BodyChartMasterService,
    private messageService: MessageService,
    private componentFormBase: ComponentFormBase,
    private sharedService: SharedService,


  ) { }



  ngOnInit(): void {

    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.fullAppointment = JSON.parse(this.sessionService.getSession('appointment'));
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;
   /* we need to do this , so we get parent body chart data first and then follow up body chart data */
  //  this. bodyAnatomyInformation = await this.findBodyChartDataByAppointmentId(this.fullAppointment.parentAppointmentId, true);
    //this.findBodyChartDataByAppointmentId(this.appointmentId, false);
    this.combineParentAndFollowUpBodyChartInformation();

    this.stepsItems = FollowUpSteps.stepItems;
    this.activeStep = parseInt(this.stepsItems.find(step => step.label === 'Body Chart').number);

  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }


  async combineParentAndFollowUpBodyChartInformation() {

    this.bodyAnatomyInformation =   await this.findBodyChartDataByAppointmentId(this.fullAppointment.parentAppointmentId, true);
    this.sessionService.setSession('existingBodyPartData', JSON.stringify(this.bodyAnatomyInformation));
    this.bodyAnatomyFollowUpInformation = await  this.findBodyChartDataByAppointmentId(this.appointmentId, false);

    let _self = this;
    let followUpAnatomy = null;

    this.bodyAnatomyInformation.bodyChartDetailResponseDTO =  this.bodyAnatomyInformation.bodyChartDetailResponseDTO.map((item, index) => {

      followUpAnatomy =   _self.bodyAnatomyFollowUpInformation.bodyChartDetailResponseDTO.find(bdto => bdto.bodyChartAnatomyDiagramId === item.bodyChartAnatomyDiagramId);
      if (followUpAnatomy !== null && followUpAnatomy !== undefined)  {
        /* note - updating pain score and notes and also we need to update the bodychartdetailId so when we patch we dont patch the original */




        item.bodyChartDetailId = followUpAnatomy.bodyChartDetailId;
        item.bodyChartAnatomyPainScore = followUpAnatomy.bodyChartAnatomyPainScore;
        item.bodyChartAnatomyNotes = followUpAnatomy.bodyChartAnatomyNotes;
        item.hasFollowUpData = true;
        followUpAnatomy = null;

     }

     return item;
    });

    this.existingBodyDataLoaded = true;

  }

  async addBodyChartMaster(bodyChartMasterDTO) {
    return new Promise((resolve, reject) => {

      this.bodyChartMasterServices.addBodyChartMasterCpa(bodyChartMasterDTO).subscribe(async res => {

        if (res.status === HttpStatusCode.Created) {

          resolve(res.body.bodyChartMaster)
        }


      }, err => {
        reject(err);
      });

    });
  }


  async  findBodyChartDataByAppointmentId(appointmentId: number, isParent: boolean): Promise<any> {
    return new Promise((resolve, reject) => {

      this.bodyChartDetailServices.findBodyChartDataByAppointmentId(appointmentId).subscribe(res => {



          if (res.status === HttpStatusCode.Ok) {

            resolve(res.body);

        /*    if (isParent) {


              this.bodyAnatomyInformation = res.body;
              this.bodyPartData = res.body;
            }
            else {

              this.bodyAnatomyFollowUpInformation =  res.body;
              this.combineParentAndFollowUpBodyChartInformation();

            }
            this.existingBodyDataLoaded = true;*/

            //this.bodyAnatomyInformation = res.body;
            ///   this.sessionService.setSession('existingBodyPartData', JSON.stringify(this.bodyAnatomyInformation));

            this.baseComponent.stopLoader();
            //this.existingBodyDataLoaded = true;

          }

        },
        err => {

        reject(err);
       /*   this.messageService.add({
            severity: 'error',
            summary: err,
            detail: ''
          });
*/
          //    console.error('err: ', err);
          this.baseComponent.startLoader();
        });

    });


  }


  getBodyChartDataByAppointmentId(): void {

    this.clinicalDataService.getBodyChartDataByAppointmentId(this.fullAppointment.parentAppointmentId).subscribe(response => {

      if (response.status === HttpStatusCode.Ok) {

        this.bodyPartData = response.body;

        this.sessionService.setSession('existingBodyPartData', JSON.stringify(this.bodyPartData));


      }


    }, err => {
      alert(err);
      console.error('err: ', err);

    });


  }

  bodyAnatomyFrontImage($emittedData): void {

    this.bodyAnatomyFrontImageString = $emittedData;

  }

  bodyAnatomyBackImage($emittedData): void {

    this.bodyAnatomyBackImageString = $emittedData;

  }


  bodyAnatomySkeletonImage($emittedData): void {

    this.bodyAnatomySkeletonImageString = $emittedData;

  }

  bodyAnatomyInformationChanged($emittedData): void {


    const app = {


      appointmentId: this.appointmentId


    };

    const patientId = {


      patientId: this.patientId


    };

    const expertId = {

      expertId: this.expertId

    };

    const caseId = {

      caseId: this.caseId


    };


    const followUpData  = {
      appointmentId: app,
      patientId: patientId,
      expertId: expertId,
      caseId: caseId,
      bodyChartAnatomyPainScore: $emittedData.bodyChartAnatomyPainScore,
      bodyChartAnatomyNotes: $emittedData.bodyChartAnatomyNotes,
      bodyChartAnatomiesDiagramId: $emittedData.bodyChartAnatomiesDiagramId,
      bodyChartDetailId: $emittedData.bodyChartDetailId,
      multiSelectData: {},
      bodyAnatomyInformation: [],
      isFollowUp: true,
      hasFollowUpData: $emittedData.hasFollowUpData
    }

    /*const bodyChartDTO = new BodyChartFormDataDTO(
      this.appointmentId,
      this.patientId,
      this.expertId,
      this.caseId,
     [],
      $emittedData.bodyChartAnatomyDetailsForm,
      $emittedData.selectedBodyPartInfo,
    );*/

    followUpData.isFollowUp = true;



    this.editedBodyAnatomyInformation.push(followUpData);


    /// console.log($emittedData.selectedBodyPartInfo.bodyChartAnatomyPainScore);


  }

  async getCanvasBlob(canvas: any, imageName: string) {
    return new Promise(async function(resolve, reject) {
      canvas.toBlob(async function(blob) {
        const file =  new File([blob], imageName, {
          type: 'image/png',
        });
        resolve(file);
      });
    });
  }

  async onSubmit() {


    const app = {


      appointmentId: this.appointmentId


    };

    const patientId = {


      patientId: this.patientId


    };

    const expertId = {

      expertId: this.expertId

    };

    const caseId = {

      caseId: this.caseId


    };

    const serializer = new XMLSerializer();


    if (this.bodyAnatomyFrontImageString === '') {
      this.bodyAnatomyFrontImageString = serializer.serializeToString(document.getElementById('svg-image-front'));
    }
    if (this.bodyAnatomyBackImageString === '') {
      this.bodyAnatomyBackImageString = serializer.serializeToString(document.getElementById('svg-image-back'));
    }

    if (this.bodyAnatomySkeletonImageString === '') {
      this.bodyAnatomySkeletonImageString = serializer.serializeToString(document.getElementById('svg-image-skeleton'));
    }


    const canvases = document.querySelectorAll('canvas');

    var arr = Array.prototype.slice.call(canvases); // Now it's an Array.
    let canvas = arr[0];

    const ctx = canvas.getContext('2d');
    $('#back-image').css({"display": 'none'});
    console.log("done fiorst one");
    const v = await Canvg.from(ctx, this.bodyAnatomyFrontImageString);

    v.start();
    await v.ready();
    console.log("done fiorst one");
    const canvases2 = document.querySelectorAll('canvas');
    var arr2 = Array.prototype.slice.call(canvases2); // Now it's an Array.
    let canvas2 = arr2[0]; // T


    let canvasBack = arr[1]; // The index of your element :)

    const ctxBack = canvasBack.getContext('2d');
    const vBack = await Canvg.from(ctxBack, this.bodyAnatomyBackImageString);
    vBack.start();
    await vBack.ready();
    const canvasesBack2 = document.querySelectorAll('canvas');
    var arrBack2 = Array.prototype.slice.call(canvasesBack2); // Now it's an Array.
    let canvasBack2 = arrBack2[1]; // T


    let canvasSkeleton = arr[2]; // The index of your element :)

    const ctxSkeleton = canvasSkeleton.getContext('2d');
    const vSkeleton = await Canvg.from(ctxSkeleton, this.bodyAnatomySkeletonImageString);
    vSkeleton.start();
    await vSkeleton.ready();
    const canvasesSkeleton2 = document.querySelectorAll('canvas');
    var arrSkeleton2 = Array.prototype.slice.call(canvasesSkeleton2); // Now it's an Array.
    let canvasSkeleton2 = arrSkeleton2[2]; // T


    let bodyChartMasterDTO = {

      appointmentId: app,
      patientId: patientId,
      expertId: expertId,
      caseId: caseId,
      bodyPartDetails: this.editedBodyAnatomyInformation

    };

    let anatomyToPatch = this.editedBodyAnatomyInformation.filter(x => x.hasFollowUpData === true);

    let _self = this;
    anatomyToPatch.forEach((anatomy) => {

      let fieldsToUpdate = [{
        "op": "replace",
        "path": "/" + "bodyChartAnatomyNotes",
        "value": anatomy.bodyChartAnatomyNotes
      },
        {
          "op": "replace",
          "path": "/" + "bodyChartAnatomyPainScore",
          "value": anatomy.bodyChartAnatomyPainScore
        },
      ];


      this.bodyChartDetailServices.updateBodyChartDetailViaPatch(fieldsToUpdate, anatomy.bodyChartDetailId).subscribe(res => {


        if (res.status === HttpStatusCode.Accepted) {
          _self.baseComponent.stopLoader();
          this.messageService.add({
            severity: 'success',
            summary: "Body Chart Detail Info" +
              this.baseComponent.translate(this.baseComponent.labels.updatedSuccessfully),
          });

          _self.baseComponent.showHideAddDialog();
        }

      });


    });

    if (this.editedBodyAnatomyInformation.length > anatomyToPatch.length) {

      this.bodyChartMasterServices.addBodyChartMasterCpa(bodyChartMasterDTO).subscribe(async res => {

        let bodyChartMasterId = res.body.bodyChartMaster;

        const bodyAnatomyFrontImageFile = await this.getCanvasBlob(canvas2, bodyChartMasterId + "-front.jpg");
        const frontAnatomyImageUrl = this.sharedService.uploadFile(bodyAnatomyFrontImageFile);

        frontAnatomyImageUrl.subscribe(async frontAnatomyImageUrlRes => {

          const bodyAnatomyBackImageFile = await this.getCanvasBlob(canvasBack2, bodyChartMasterId + "-back.jpg");
          const backAnatomyImageUrl = this.sharedService.uploadFile(bodyAnatomyBackImageFile);


          let anatomyImageUrls = {

            frontAnatomyImageUrl: frontAnatomyImageUrlRes,
            backAnatomyImageUrl: '',
            skeletonAnatomyImageUrl: ''

          };

          backAnatomyImageUrl.subscribe(async res => {
            anatomyImageUrls.backAnatomyImageUrl = res;
            const bodyAnatomySkeletonImageFile = await this.getCanvasBlob(canvasSkeleton2, bodyChartMasterId + "-skeleton.jpg");
            const skeletonAnatomyImageUrl = this.sharedService.uploadFile(bodyAnatomySkeletonImageFile);

            skeletonAnatomyImageUrl.subscribe(res => {
              anatomyImageUrls.skeletonAnatomyImageUrl = res;

              this.bodyChartMasterServices.updateBodyChartMasterWithImageUrls(bodyChartMasterId, anatomyImageUrls).subscribe(res => {


                if (res.status === 202) {
                  this.messageService.add({
                    severity: 'success',
                    summary: this.baseComponent.translate("Body Chart Information") +
                      this.baseComponent.translate(this.baseComponent.labels.addedSuccessfully),
                  });

                  this.baseComponent.showHideAddDialog();
                }

              });

            });


          });
        });
      });

    }


  }

}
