import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CompanyService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdCompany(companyId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Company/deleteCompany', {'companyId': companyId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countCompany(company,refId,userType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Company/count', 
        {'company': company,'referenceData':{'userType':userType,'refId':refId}}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateCompany(company): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Company/updateCompany', [company], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addCompany(company,refId,userType): Observable<string> {
        let refObj = {'userType':userType,'refId':refId};
        return this.httpClient.post<string>(Constant.BASE_URL + 'Company/addCompany', 
        [company,refObj], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllCompany(company, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]> {

        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Company/findAllCompany',
            {'company': company, 'referenceData':{'userType':userType,'refId':refId}, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // global searching of company
    companyGlcount(searchTerm,refId,userType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Company/countGlobalCompany',
                                            {'searchTerm': searchTerm,'refId':refId,'userType':userType},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalCompany(searchTerm, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Company/findGlobalCompany',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage},
            'refId':refId,'userType':userType},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    //Manage Subsriptions
    findUserSubscription(agencyId, userTypeId): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'UserSubscription/getActiveSubscriptionlist',
            {'agencyId': agencyId, 'userTypeId': userTypeId },
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
    updateUserSubscriptionlist(UserSubscriptionlist): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'UserSubscription/updateUserSubscription', [UserSubscriptionlist],
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    // Manage Subscription
    addUserSubscriptionlist(userId, UserSubscriptionlist): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Company/addCompanySubscriptions', {'companyId': userId, 'subscription': UserSubscriptionlist},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }
}
