import { Component, OnInit } from '@angular/core';
import {FollowUpSteps} from '../../uiUtils/FollowUpSteps';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../../web-services/clinical-data.service';
import {MessageService} from 'primeng/api';
import {ComponentFormBase} from '../../util/ComponentFormBase';
import {HttpPatchItem} from '../../util/HttpPatchItem';
import {HttpPatch} from '../../util/HttpPatch';

@Component({
  selector: 'app-follow-up-review',
  templateUrl: './follow-up-review.component.html',
  styleUrls: ['./follow-up-review.component.css']
})
export class FollowUpReviewComponent implements OnInit {

  stepsItems: any;
  activeStep: number;
  appointmentId: number;
  caseId: number;
  patientId: number;
  expertId: number;
  fullAppointment: any;
  patientObj: any;
  reviewData: any;
  baseComponent = this.componentFormBase;



  constructor(private sessionService: SessionService,
              private router: Router,
              private clinicalDataService: ClinicalDataService,
              private messageService: MessageService,
              private componentFormBase: ComponentFormBase,
              ) { }

  ngOnInit(): void {


    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.fullAppointment = JSON.parse(this.sessionService.getSession('appointment'));
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;

    this.stepsItems = FollowUpSteps.stepItems;

    this.getReviewAppointmentId();

  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }

  getReviewAppointmentId(): void {

    this.baseComponent.startLoader();

    this.clinicalDataService.getReviewByAppointmentId(this.appointmentId).subscribe(response => {

      if (response.status === HttpStatusCode.Ok) {

        this.reviewData = response.body;
      }


    }, err => {
      this.baseComponent.stopLoader();
      this.messageService.add(
        {
          severity: 'error',
          summary: err,
          // detail: this.translate(this.labels.entityNameTestingArom)
        });
    });


  }

  markFollowUpAsComplete(): void {

    this.reviewData.isCompleted = true;

   // const httpPatchItem = new HttpPatchItem('replace', '/initialAssessmentComplete', true);

   // let httpPatch = new HttpPatch();

   // httpPatch.pushToJsonPatch(httpPatchItem);
    //console.log(httpPatch);

  }

}
