import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DataSharedService} from './data-shared.service';
import {SessionService} from './session.service';
import {Constant} from '../util/constant';


@Injectable({
                providedIn: 'root'
            })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private sharedService: DataSharedService,
        private sessionService: SessionService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (sessionStorage.getItem('token') && sessionStorage.getItem('token') != '') {


            let roles = next.data['roles'] as Array<string>;
            if (roles) {
                if(this.sessionService.getSession('loggedInUserType') === Constant.EXPERT_BUSINESS_ROLE &&
                    !this.sessionService.getSession(Constant.SESSION_APPOINTMENT_ID) &&
                    !this.router.url.includes('/expert-dashboard'))
                {
                  //  this.router.navigate(['cpa/landingpage']);
                   // return false;
                }

                console.log("roles in authGuard :: ", roles);
                return this.sharedService.roleMatch(roles);


            } else {
                return true;
            }
        } else {

            if (state.url.split('?')[1]) {
                let param1 = state.url.split('?')[1];
                let idParam = param1.split('=')[0];
                let objectId = param1.split('=')[1];
                if (idParam == 'id') {
                    this.sessionService.setSession('objectId', objectId);
                }
            }

            this.sessionService.setSession('currentUrl', state.url.split('?')[0]);
            this.sessionService.setSession('applicationId', state.root.queryParams['applicationId']);
            this.sessionService.setSession('token', state.root.queryParams['token']);

            this.router.navigate(['/login']);
        }
        return false;
    }
}
