import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {DischargeService} from '../web-services/discharge.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {SessionService} from '../services/session.service';
import { Utils } from '../util/utils';

@Component({selector: 'app-discharge', templateUrl: './discharge.component.html', styleUrls: ['./discharge.component.css']})
export class DischargeComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateDischargeId = null;
    listOfValuesDetailList: any = [];
    dischargeList: any = [];
    dischargeSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    dischargeForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    utils = new Utils();

    shortTermBenefits = Constant.DISCHARGE_LOV_SHORT_TERM_BENEFITS;
    longTermBenefits = Constant.DISCHARGE_LOV_LONG_TERM_BENEFITS;
    outcomeMeasuresImplemented = Constant.DISCHARGE_LOV_OUTCOME_MEASURES_IMPLEMENT;

    expertId;
    patientId;
    appointmentId;

    constructor(
        private dischargeServices: DischargeService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService
    ) {
    };

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    shortTermBenefitsList: any = [];
    longTermBenefitsList: any = [];
    outcomeMeasuresImplementedList: any = [];

    shortTermBenefitsError = false;
    longTermBenefitsError = false;
    outcomeMeasuresImplementedError = false;

    getResponse($event) {
        this.responseData = $event;
        console.log('Response from child : ', this.responseData);
        if(this.responseData[0]['categoryId']['categoryTitle'] === Constant.DISCHARGE_LOV_SHORT_TERM_BENEFITS){
            this.shortTermBenefitsList = this.responseData;
        } else if(this.responseData[0]['categoryId']['categoryTitle'] === Constant.DISCHARGE_LOV_LONG_TERM_BENEFITS){
            this.longTermBenefitsList = this.responseData;
        }  else if(this.responseData[0]['categoryId']['categoryTitle'] === Constant.DISCHARGE_LOV_OUTCOME_MEASURES_IMPLEMENT){
            this.outcomeMeasuresImplementedList = this.responseData;
        }
    }

    get addUpdateTitle() {
        return this.updateDischargeId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.dischargeSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countDischarge();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countDischarge() {
        this.dischargeSearch.controls['expertId'].setValue(this.expertId);
        this.dischargeSearch.controls['patientId'].setValue(this.patientId);
        this.dischargeSearch.controls['appointmentId'].setValue(this.appointmentId);
        let obj = this.utils.getUpdatedMillissecondObject(this.dischargeSearch.value,"dischargeDate");
        if(obj['dischargeDate'] === '0'){
            delete obj['dischargeDate'];
        }
        this.dischargeServices.countDischarge(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllDischarge();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.dischargeForm.markAllAsTouched();

        this.shortTermBenefitsError = (this.shortTermBenefitsList.filter(d => {return d.selected === 1}).length) < 1;
        this.longTermBenefitsError = (this.longTermBenefitsList.filter(d => {return d.selected === 1}).length) < 1;
        this.outcomeMeasuresImplementedError = (this.outcomeMeasuresImplementedList.filter(d => {return d.selected === 1}).length) < 1;
        if(this.shortTermBenefitsError || this.longTermBenefitsError || this.outcomeMeasuresImplementedError){
            return;
        }
        this.refDataObj = {
            'Short Term Benefits': this.shortTermBenefitsList,
            'Long Term Benefits':this.longTermBenefitsList ,
            'Outcome Measures To Be Implemented':this.outcomeMeasuresImplementedList ,
            'refDataCheck' : true
        }

        this.dischargeForm.controls['expertId'].setValue(this.expertId);
        this.dischargeForm.controls['patientId'].setValue(this.patientId);
        this.dischargeForm.controls['appointmentId'].setValue(this.appointmentId);

        if (this.dischargeForm.valid) {
            this.addUpdateDischarge(this.dischargeForm.value);
            this.submitted = false;
            this.onClearAdd();
        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllDischarge();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.dischargeSearch.reset();
        this.countDischarge();
        // this.findAllDischarge();
    }

    addUpdateDischarge(discharge) {
        this.startLoader();
        let obj =this.utils.getUpdatedMillissecondObject(this.dischargeForm.value,"dischargeDate");
        console.log(obj, 'date object');


        if (this.updateDischargeId == null) {
            this.dischargeServices.addDischarge(obj,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    discharge = res['data'][0];
                    this.dischargeList.push(discharge);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameDischarge) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameDischarge)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countDischarge();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.dischargeId = this.updateDischargeId;
            this.dischargeServices.updateDischarge(obj,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.dischargeList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameDischarge) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameDischarge)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countDischarge();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateDischargeId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.expertId = parseInt(this.sessionService.getSession('expertId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.lovDataObject = {"refId":0,"refType":""};
        this.dischargeForm = new FormGroup({
                                               detailsOfTreatment: new FormControl('', [Validators.required, validateSpaces]),
                                               shortTermBenefits: new FormControl(),
                                               longTermBenefits: new FormControl(),
                                               outcomeMeasuresToBeImplemented: new FormControl(),
                                               patientId: new FormControl(),
                                               expertId: new FormControl(),
                                               appointmentId: new FormControl(),
                                               userId: new FormControl(0),
                                               dischargeDate: new FormControl('', [Validators.required]),
                                           });
        this.dischargeSearch = new FormGroup({
                                                 detailsOfTreatment: new FormControl(),
                                                 shortTermBenefits: new FormControl(),
                                                 longTermBenefits: new FormControl(),
                                                 outcomeMeasuresToBeImplemented: new FormControl(),
                                                 patientId: new FormControl(),
                                                 expertId: new FormControl(),
                                                 appointmentId: new FormControl(),
                                                 userId: new FormControl(),
                                                 dischargeDate: new FormControl(),
                                             });
        this.countDischarge();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.dischargeSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllDischarge() {
        this.startLoader();
        this.dischargeSearch.controls['expertId'].setValue(this.expertId);
        this.dischargeSearch.controls['patientId'].setValue(this.patientId);
        this.dischargeSearch.controls['appointmentId'].setValue(this.appointmentId);
        let obj = this.utils.getUpdatedMillissecondObject(this.dischargeSearch.value,"dischargeDate");
        if(obj['dischargeDate'] === '0'){
            delete obj['dischargeDate'];
        }
        this.dischargeServices.findAllDischarge(obj, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.dischargeList = res['data'];
                } else {
                    this.dischargeList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdDischarge(dischargeId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.dischargeServices.deleteByIdDischarge(dischargeId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.dischargeList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameDischarge) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameDischarge)
                            });
                        this.countDischarge();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadDischargeToEdit(dischargeId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let discharge = this.dischargeList.find(x => x['dischargeId'] == dischargeId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateDischargeId = discharge['dischargeId'];
        this.selectedIndex = index;

        this.lovDataObject = {"refId":discharge['dischargeId'],"refType":""};

        if(discharge['detailsOfTreatment']) {
            this.dischargeForm.controls['detailsOfTreatment'].setValue(discharge['detailsOfTreatment']);
        }
        if(discharge['shortTermBenefits']['listOfValuesMasterId']) {
            this.dischargeForm.controls['shortTermBenefits'].setValue(discharge['shortTermBenefits']['listOfValuesMasterId']);
        }
        if(discharge['longTermBenefits']['listOfValuesMasterId']) {
            this.dischargeForm.controls['longTermBenefits'].setValue(discharge['longTermBenefits']['listOfValuesMasterId']);
        }
        if(discharge['outcomeMeasuresToBeImplemented']['listOfValuesMasterId']) {
            this.dischargeForm.controls['outcomeMeasuresToBeImplemented'].setValue(discharge['outcomeMeasuresToBeImplemented']['listOfValuesMasterId']);
        }
        if(discharge['patientId']) {
            this.dischargeForm.controls['patientId'].setValue(discharge['patientId']['patientId']);
        }
        if(discharge['expertId']) {
            this.dischargeForm.controls['expertId'].setValue(discharge['expertId']['expertId']);
        }
        if(discharge['appointmentId']) {
            this.dischargeForm.controls['appointmentId'].setValue(discharge['appointmentId']['appointmentId']);
        }
            this.dischargeForm.controls['dischargeDate'].setValue(new Date(discharge['dischargeDate']));

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.dischargeForm.reset();
        this.dischargeForm.markAsUntouched();
        this.updateDischargeId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.dischargeForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.dischargeForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.dischargeForm.get(control)?.valid && this.dischargeForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.dischargeForm.reset();
    }

    onCloseSearch() {
        this.dischargeSearch.reset();
    }

    refresh() {
        this.countDischarge();
    }

    onAddOverlayDiv() {
        this.dischargeForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.dischargeForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateDischargeId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.dischargeServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.dischargeList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.dischargeServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.dischargeList = result['data'];
            } else {
                this.dischargeList = [];
            }
            console.log(result, 'result');
        });
    }
}
