<div class="chatoverlaydiv" id="chatbox-overlaydiv">
    <ul class="contacts_chatbox">
        <div class="chatbox-userheader">

        </div>
        <li class="contact_user">
            <div class="contact_user_content">

                <div class="user_info_chatbox" id="chatbox-overlaydiv-header">
                    <i (click)="ngOnDestroy()" class="fas fa-arrow-left"></i>
                    <span>{{username}}</span>
                    <p>{{status}}</p>

                </div>
                <ng-container *ngIf="selectedChat?.cbGroupData">
                    <div class="img_cont_chatbox">
                        <img src="assets/theme1/custom-files/img/default_thumb/groupImage.png"
                             onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/groupImage.png'"
                             class="rounded-circle user_img">
                        <span [class]="statusIcon(selectedChat)"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!(selectedChat?.cbGroupData)">
                    <div class="img_cont_chatbox">
<!--                        <img [src]="checkUserImage(selectedChat)"-->
<!--                             onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/profilepic.png'"-->
<!--                             class="rounded-circle user_img">-->
                        <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png"
                             class="rounded-circle user_img">
                        <span [class]="statusIcon(selectedChat)"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentVersion==0 && selectedChat&&  selectedChat.cbGroupData">
                    <!-- <button mat-icon-button class="matDropdownmeubtn" id="chatlistbox-matDropdownmeubtn"
                      [matMenuTriggerFor]="chatlistdropdownmenu">
                      <i class="vertical3dotsmenu-icon"></i>
                    </button>
                    <mat-menu #chatlistdropdownmenu="matMenu" class="angDropdownmenu" xPosition="before">
                      <button *ngIf="selectedChat?.cbGroupData" (click)="leaveGroup(selectedChat)" mat-menu-item>
                        <i class="leavechat-icon"></i>
                        <span class="dropdownmenu-text"> {{verifyLabel('Leave Chat')}} </span>
                      </button>
                      <button (click)="openDeleteDialog()" mat-menu-item>
                        <i class="deletechat-icon"></i>
                        <span class="dropdownmenu-text"> {{verifyLabel('Delete Chat')}} </span>
                      </button>
                      <button (click)="openParticipant()" *ngIf="selectedChat?.cbGroupData" mat-menu-item>
                        <i class="editgroup-icon"></i>
                        <span class="dropdownmenu-text"> {{verifyLabel('Edit Group')}} </span>
                      </button>
                    </mat-menu> -->
                    <div class="icongroup">
                        <button class="btnxs-transparent-icon" *ngIf="selectedChat?.cbGroupData"
                                (click)="leaveGroup(selectedChat)">
                            <i class="leavechat-icon" title="Leave Chat"></i>
                        </button>
                        <button class="btnxs-transparent-icon" (click)="openDeleteDialog()">
                            <i class="delete-icon" title="Delete Chat"></i>
                        </button>
                        <button class="btnxs-transparent-icon" (click)="openParticipant()"
                                *ngIf="selectedChat?.cbGroupData">
                            <i class="edit-icon" title="Edit Chat"></i>
                        </button>
                    </div>


                </ng-container>
            </div>
        </li>

    </ul>

    <div class="mesgs">

        <div class="msg_history">
            <button *ngIf="showLoadMoreButton" (click)="getPreviousMessages()" class="btnxs">
                {{verifyLabel('Load More')}}
                ...
            </button>
            <ng-container *ngFor="let message of messages;let i = index">
                <p class="messagedateandtime"></p>
                <ng-container *ngIf="youSender(message)">
                    <div class="incoming_msg">
                        <div class="incoming_msg_img">
<!--                            <img [src]="message.firstContact.profileImage"-->
<!--                                 onerror="this.onerror=null; this.src='assets/theme1/custom-files/img/default_thumb/profilepic.png'"-->
<!--                                 class="rounded-circle user_img" alt="sunil">-->
                            <img [src]="message.firstContact.profileImage? message.firstContact.profileImage :'assets/theme1/custom-files/img/default_thumb/profilepic.png'"
                                 class="rounded-circle user_img" >
                            <span *ngIf="!(message.group!=null)" [class]="checkUserStatus()"></span>
                            <span *ngIf="message.group!=null" [class]="checkParticipantStatus(message)"></span>

                        </div>
                        <div class="received_msg">
                            <div class="received_withd_msg">
                                <p class="message-text">{{message.content}}<br> <a
                                        (click)="showTask()"><strong>{{message.referenceType}}</strong></a>
                                </p>

                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="meSender(message)">
                    <div class="outgoing_msg">
                        <div class="sent_msg">
                            <p class="message-text">
                                {{message.content}}<br> <a
                                    (click)="showTask()"><strong>{{message.referenceType}}</strong></a>
                            </p>

                        </div>
                    </div>
                </ng-container>

                <!-- <p class="messagedateandtime">Today</p>
                <div class="incoming_msg">
                  <div class="incoming_msg_img">
                    <img src="assets/theme1/custom-files/img/default_thumb/profilepic.png" class="rounded-circle user_img"
                      alt="sunil">
                    <span class="online_icon"></span>
                  </div>
                  <div class="received_msg">
                    <div class="received_withd_msg">
                      <p>Hi Jon, We are good, Having a Standup today! (msg 1)
                      </p>

                    </div>
                  </div>
                </div>

                <div class="outgoing_msg">
                  <div class="sent_msg">
                    <p>
                      Hi Jeremy, how are things?</p>

                  </div>
                </div> -->
            </ng-container>
        </div>


    </div>
    <div class="portlet-footer">
        <div class="reply-main">
            <!-- <div class="reply-emojis">
              <i class="fa fa-smile-o"></i>
            </div> -->
            <div class="reply-messagearea">
        <textarea [disabled]="submitted" (keydown.enter)="onSubmit()" maxlength="999"
                  [(ngModel)]="messageModel.content" class="form-control" rows="1" id="comment"
                  placeholder="Write Something !"></textarea>
            </div>
            <div class="reply-voicemsg">
                <i (click)="onSubmit()" class="send-icon"></i>
            </div>
            <!-- <div class="reply-voicemsg">
              <i class="fa fa-smile-o"></i>
            </div> -->
        </div>

        <div *ngIf="(selectedChat?.referenceId>0 || this.collabSession.isContexualTab) && currentVersion==0"
             class="sendastaks-category">
            <mat-select (selectionChange)="onTaskTypeChange($event)" [(value)]="messageModel.task_type"
                        class="angSelect"
                        id="fromBlock-angSelect-category">
                <mat-option value="Normal">
                    <i class="normalchat-icon"></i> {{verifyLabel('Normal Chat')}}
                </mat-option>
                <mat-option value="General">
                    <i class="generalchat-icon"></i> {{verifyLabel('Send as General Task')}}
                </mat-option>
                <mat-option value="Important">
                    <i class="sendastask-icon"></i> {{verifyLabel('Send as Important Task')}}
                </mat-option>
            </mat-select>

        </div>

    </div>


</div>