import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {Form, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {TestingAromService} from '../web-services/testing-arom.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {AromMultiSelects} from "../uiUtils/pages/testing/AromMultiSelects";
import {FormDataDTO} from "../dto/FormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {ClinicalDataService} from '../web-services/clinical-data.service';
import {HttpStatusCode} from '@angular/common/http';
import {TestingFormData} from '../dto/TestingFormData';

@Component({
               selector: 'app-testing-arom',
               templateUrl: './testing-arom.component.html',
               styleUrls: ['./testing-arom.component.css']
})
export class TestingAromComponent implements OnInit {

  @Input() chosenBodyChartAnatomyDiagramId;

  public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateTestingAromId = null;
    listOfValuesDetailList: any = [];
    testingAromList: any = [];
    sidesCategoryList: any = [];
    jointList: any = [];
    testingAromSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    testingAromForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    movement = Constant.TESTING_AROM_LOV_MOVEMENT;
    measure = Constant.TESTING_AROM_LOV_MEASURE;
    limitingFactor = Constant.TESTING_AROM_LOV_LIMITING_FACTOR;

    constructor(
        private testingAromServices: TestingAromService,
        private listOfValuesDetailServices: ListOfValuesDetailService, private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService, private messageService: MessageService, private route: Router,
        private loader: NgxUiLoaderService, private constantService: ConstantService, private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private aromMultiSelects: AromMultiSelects,
        private clinicalDataService: ClinicalDataService
    ) {
    };
    ref: DynamicDialogRef;

    viewAgencyDetail(flagId: number) {
        // let flag = this.flagsList.find(x => x['flagId'] == flagId);
        //
        // this.ref = this.dialogService.open(ViewdetailComponent, {
        //     header: 'Company Detail',
        //     width: '90%',
        //     contentStyle: {'max-height': '750px', 'overflow': 'auto'},
        //     baseZIndex: 10000,
        //     data: {
        //         mainData: [
        //             {label: this.labels.flagsColumnFlag, value: flag['flag']['label']},
        //             {label: this.labels.flagsColumnFlagValue, value: flag['flagValue']['label']},
        //             {label: this.labels.agencyColumnAgencyLastname, value: flag['flagStatus']['label']},
        //         ]
        //     }
        // });
    }

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    movementList: any = [];
    measureList: any = [];
    limitingFactorList: any = [];

    movementError = false;
    measureError = false;
    limitingFactorError = false;

    expertId;
    patientId;
    appointmentId;
    caseId;

    objectMultiSelects: any;
    existingBodyPartData: any = [];

    ngOnChanges(changes: SimpleChanges) {

      if (!changes.chosenBodyChartAnatomyDiagramId.firstChange)  {
        this.findAllTestingAromsByAppointmentIdAndBodyChartAnatomyDiagramId();
      }

    }

    getResponse($event) {

        this.aromMultiSelects.getResponseObjects($event);
    }

    get addUpdateTitle() {
        return this.updateTestingAromId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.testingAromSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countTestingArom();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countTestingArom() {
        this.testingAromSearch.controls['expertId'].setValue(this.expertId);
        this.testingAromSearch.controls['patientId'].setValue(this.patientId);
        this.testingAromSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingAromServices.countTestingArom(this.testingAromSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllTestingArom();
            } else {
                this.totalRecords = 0;
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {



        this.testingAromForm.markAllAsTouched();


        const testingAromDTO = new TestingFormData(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.testingAromForm);

      const diagnosisData = new DiagnosisDataHelper(testingAromDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();

      testingAromDTO.bodyChartAnatomiesDiagramId = this.chosenBodyChartAnatomyDiagramId;


        console.log(testingAromDTO);


        if (!testingAromDTO.hasMultiSelectErrors && this.testingAromForm.valid) {


            this.addTestingArom(testingAromDTO);

          if (uniqueCaseDiagnosisPredictionList.length > 0) {
            this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

              console.log(res);
            });
          }

          if (testingAromDTO.redFlagData.length > 0) {

            alert('red flags');

            this.lovServices.createListOfCaseRedFlags(testingAromDTO.redFlagData).subscribe(res => {

              if (res.status === 201) {

                this.messageService.add({
                  severity: 'success',
                  summary: this.translate("Red Flags Updated"),
                });
              }

            });
          }
      }
      else {

        testingAromDTO.multiSelectErrorFields.forEach(multiSelectError => {

          document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
        });
      }
    }

    addTestingArom(testingAromDTO: FormDataDTO): void {


      this.testingAromServices.addTestingAromCpa(testingAromDTO).subscribe(res => {


        if (res.status === 200) {

          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameTestingArom) +
              this.translate(this.labels.addedSuccessfully),
          });

          this.lovDataObject = {"refId":0,"refType":"","reset": true};
          this.lovDataObject = {"refId":0,"refType":"","reset": false};

          this.submitted = true;
          this.showHideAddDialog();
          this.findAllTestingAromsByAppointmentIdAndBodyChartAnatomyDiagramId();


        }

      }, err => {
          console.error('err: ', err);
          alert("err " + err);
          this.stopLoader();
        });



    }

    findAllTestingAromsByPatientId(): void {

      this.testingAromServices.findAllTestingAromsByPatientId(this.patientId).subscribe(res => {

        if (res.status === 200) {

          this.testingAromList = res.body;

        }
      });
    }
    findAllTestingAromsByAppointmentIdAndBodyChartAnatomyDiagramId(): void {


    this.clinicalDataService.findAllTestingAromsByAppointmentIdAndBodyChartAnatomyDiagramId(this.appointmentId, this.chosenBodyChartAnatomyDiagramId).subscribe(res => {

      this.startLoader();

      if (res.status === HttpStatusCode.Ok) {

        this.testingAromList = res.body;
        this.stopLoader();

      }
    }, err => {

      this.stopLoader();
      this.messageService.add(
        {
          severity: 'error',
          summary: err,
          // detail: this.translate(this.labels.entityNameTestingArom)
        });
    });
    }





    clearSearchResult() {
       // this.clearSearchBtn = false;
      ///  this.testingAromSearch.reset();
      ///  this.countTestingArom();
    }

    addUpdateTestingArom(testingArom) {
        this.startLoader();
        if (this.updateTestingAromId == null) {

            this.testingAromServices.addTestingArom(this.testingAromForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    testingArom = res['data'][0];
                    this.testingAromList.push(testingArom);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameTestingArom) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameTestingArom)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countTestingArom();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            testingArom.testingAromId = this.updateTestingAromId;
            this.testingAromServices.updateTestingArom(testingArom,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.testingAromList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameTestingArom) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameTestingArom)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countTestingArom();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateTestingAromId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findSidesCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('SidesCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.sidesCategoryList.push(dropDown);
                    }
                } else {
                    this.sidesCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    ngOnInit() {

      this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));


        this.objectMultiSelects = this.aromMultiSelects.objectMultiSelects;

      this.lovDataObject = {"refId":0,"refType":""};
        this.findAllJoints();
        this.findSidesCategory();
        this.findAllListOfValuesDetail();
        this.testingAromForm = new FormGroup({
                                                 joint: new FormControl('', [Validators.required]),
                                                 aromSide: new FormControl('', [Validators.required]),
                                                 bodyChartId: new FormControl(),
                                                 userId: new FormControl(0),
                                             });

        this.findAllTestingAromsByAppointmentIdAndBodyChartAnatomyDiagramId();
        //alert('init');
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;

    }

    findAllJoints() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Joint').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.jointList.push(dropDown);
                    }
                } else {
                    this.jointList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllTestingArom() {
        this.startLoader();
        this.testingAromSearch.controls['expertId'].setValue(this.expertId);
        this.testingAromSearch.controls['patientId'].setValue(this.patientId);
        this.testingAromSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.testingAromServices.findAllTestingArom(this.testingAromSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.testingAromList = res['data'];
                    console.log('testing arom data : ', this.testingAromList);
                } else {
                    this.testingAromList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdTestingArom(testingAromId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.testingAromServices.deleteByIdTestingArom(testingAromId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.testingAromList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameTestingArom) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameTestingArom)
                                                });
                        this.countTestingArom();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deleteArom"
                                  });
    }

    loadTestingAromToEdit(testingAromId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let testingArom = this.testingAromList.find(x => x['testingAromId'] == testingAromId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateTestingAromId = testingArom['testingAromId'];
        this.selectedIndex = index;

        this.lovDataObject = {"refId":testingArom['testingAromId'],"refType":""};

        if(testingArom['joint']) {
            this.testingAromForm.controls['joint'].setValue(testingArom['joint']['listOfValuesId']);
        }
        if(testingArom['aromSide']) {
            this.testingAromForm.controls['aromSide'].setValue(testingArom['aromSide']['listOfValuesId']);
        }
        if(testingArom['movement']['listOfValuesMasterId']) {
            this.testingAromForm.controls['movement'].setValue(testingArom['movement']['listOfValuesMasterId']);
        }
        if(testingArom['measure']['listOfValuesMasterId']) {
            this.testingAromForm.controls['measure'].setValue(testingArom['measure']['listOfValuesMasterId']);
        }
        if(testingArom['limitigFactor']['listOfValuesMasterId']) {
            this.testingAromForm.controls['limitigFactor'].setValue(testingArom['limitigFactor']['listOfValuesMasterId']);
        }
        if(testingArom['patientId']) {
            this.testingAromForm.controls['patientId'].setValue(testingArom['patientId']['patientId']);
        }
        this.testingAromForm.controls['expertId'].setValue(testingArom['expertId']['expertId']);
        this.testingAromForm.controls['appointmentId'].setValue(testingArom['appointmentId']['appointmentId']);
        // this.testingAromForm.controls['bodyChartId'].setValue(testingArom['bodyChartId']['bodyChartId']);
        // this.testingAromForm.controls['userId'].setValue(testingArom['userId']);

        console.log(this.testingAromForm.value, 'valuesss');

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.testingAromForm.reset();
        this.testingAromForm.markAsUntouched();
        this.updateTestingAromId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.testingAromForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.testingAromForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.testingAromForm.get(control)?.valid && this.testingAromForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.testingAromForm.reset();
    }

    onCloseSearch() {
        this.testingAromSearch.reset();
    }

    refresh() {
        this.countTestingArom();
    }

    onAddOverlayDiv() {
        this.testingAromForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.testingAromSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.testingAromForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateTestingAromId = null;
    }


    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.testingAromServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.testingAromList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.testingAromServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.testingAromList = result['data'];
            } else {
                this.testingAromList = [];
            }
            console.log(result, 'result');
        });
    }
}
