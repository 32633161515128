import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";
import {EditHistoryFormDataDTO} from '../dto/EditHistoryFormDataDTO';

@Injectable({providedIn: 'root'})
export class BodyChartDetailService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdBodyChartDetail(bodyChartDetailId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'BodyChartDetail/deleteBodyChartDetail', {'bodyChartDetailId': bodyChartDetailId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countBodyChartDetail(bodyChartDetail): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'BodyChartDetail/count', {'bodyChartDetail': bodyChartDetail},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateBodyChartDetail(bodyChartDetail, refData): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'BodyChartDetail/updateBodyChartDetail', [bodyChartDetail, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addBodyChartDetail(bodyChartDetail, refData): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'BodyChartDetail/addBodyChartDetail', [bodyChartDetail, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }


    addBodyChartDetailCpa(bodyChartMasterDTO: FormDataDTO): Observable<any> {
    return this.httpClient.post<any>(
      Constant.CPA_SPRING_API_URL + 'body-chart-details', bodyChartMasterDTO, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
    }

  updateBodyChartDetailViaPatch(bodyChartDetail: any, bodyChartDetailId: number): Observable<any> {
    alert(Constant.CPA_CLINICAL_DATA_SERVICE + 'body-chart-detail/' + bodyChartDetailId);
    return this.httpClient.patch<any>(


      Constant.CPA_CLINICAL_DATA_SERVICE + 'body-chart-detail/' + bodyChartDetailId, bodyChartDetail, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/merge-patch+json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
  }


  updateBodyChartDetailMultiSelects(bodyChartDetailEditDTO: EditHistoryFormDataDTO, bodyChartDetailId: number): Observable<any> {

    return this.httpClient.put<any>(
      Constant.CPA_SPRING_API_URL + 'body-chart-details/' + bodyChartDetailId + '/multi-selects', bodyChartDetailEditDTO, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));


  }


    updateBodyChartDetailWithImageUrls(bodyChartDetailId, anatomyImageUrls): Observable<any> {
    return this.httpClient.put<any>(
      Constant.CPA_SPRING_API_URL + 'body-charts/' + bodyChartDetailId, anatomyImageUrls, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));
    }


  findAllBodyChartDetail(bodyChartDetail, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'BodyChartDetail/findAllBodyChartDetail',
            {'bodyChartDetail': bodyChartDetail, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  findBodyChartDataByAppointmentId(appointmentId): Observable<any> {

     /// alert(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId +  '/body-charts-detail');
    return this.httpClient.get<any>(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId +  '/body-charts-detail', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }

    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'BodyChartDetail/countGlobalBodyChartDetail',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'BodyChartDetail/findGlobalBodyChartDetail',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
