import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NonInterceptService} from '../services/non-intercept.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpStatusCode} from '@angular/common/http';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-complete-sign-up',
  templateUrl: './complete-sign-up.component.html',
  styleUrls: ['./complete-sign-up.component.css']
})
export class CompleteSignUpComponent implements OnInit {

  CompleteSignUpForm: FormGroup;
  token: any;
  userPasswordToken: any;


  constructor(private nonInterceptServices: NonInterceptService,
              private activatedRoute: ActivatedRoute,
              private messageService: MessageService,
              private router: Router
              ) {

  }

  ngOnInit(): void {

    this.activatedRoute.queryParams
      .subscribe(params => {
          this.token = params.token;
        }
      );

    this.CompleteSignUpForm = new FormGroup(
      {
        password: new FormControl('', [Validators.required])
      }
    );
    this.getUserPasswordTokenByToken();
  }

  getUserPasswordTokenByToken(): void {

    this.nonInterceptServices.getUserPasswordTokenByToken(this.token).subscribe(
      (response: any) => {

        if (response.status === HttpStatusCode.Ok) {
           this.userPasswordToken = response.body;
        }
        console.log(response);
      }
    );
  }

  isTokenValid(): boolean {

    return this.userPasswordToken !== undefined &&
      this.userPasswordToken.isValid &&
      !this.userPasswordToken.isExpired &&
      !this.userPasswordToken.isUsed;

  }

  onSubmit(): void {

    const tokenPassword = {
      password: this.CompleteSignUpForm.value.password,
      token: this.token,
      userUuid: this.userPasswordToken.userUuid
    };



    this.nonInterceptServices.UpdatePasswordByUserPasswordToken(tokenPassword).subscribe(
      (response: any) => {

        if (response.status === HttpStatusCode.Accepted) {

          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Password updated successfully, please login'});
          this.router.navigate(['login']);

        }
      });


    console.log(this.CompleteSignUpForm.value);
  }

}
