import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import { constants } from 'buffer';

@Injectable({providedIn: 'root'})
export class ClinicExpertsSchedualService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdClinicExpertsSchedual(clinicExpertsSchedualId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClinicExpertsSchedual/deleteClinicExpertsSchedual', {'clinicExpertsSchedualId': clinicExpertsSchedualId},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countClinicExpertsSchedual(clinicExpertsSchedual): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'ClinicExpertsSchedual/count',
            {'clinicExpertsSchedual': clinicExpertsSchedual},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateClinicExpertsSchedual(clinicExpertsSchedual): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClinicExpertsSchedual/updateClinicExpertsSchedual', [clinicExpertsSchedual], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addClinicExpertsSchedule(schedulesToSave): Observable<string> {
       /* let refObj = {'days' :selectedDays,'selectedCalendarDays': JSON.stringify(selectedCalendarDays)};

        /!* now for the selectedCalendarDays use that to map new data objects for the api database *!/

        const clinicExpertScheduleDataObjects = selectedCalendarDays.map(selectedDayText => {

          return  {

              clinicId:   clinicExpertsSchedual.clinicId,
              expertsId:  clinicExpertsSchedual.expertsId,
              clinicExpertsId:  clinicExpertsSchedual.clinicExpertsId,
              userId:  clinicExpertsSchedual.userId,
              startTime:  clinicExpertsSchedual.startTime,
              endTime:  clinicExpertsSchedual.endTime,
              slotTime:  clinicExpertsSchedual.slotTime,
              workingDays:  clinicExpertsSchedual.workingDays,
              startDate:  selectedDayText,
              clinicExpertId: 188
            };

        });*/

        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ClinicExpertSchedule/addExpertScheduleForClinic', schedulesToSave, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllClinicExpertSchedulesByClinicAndExpertId(clinicExpertSchedule): Observable<string[]> {

      return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'ClinicExpertSchedule/findClinicExpertsScheduleByClinicAndExpertId',
        {'clinicExpertSchedule': clinicExpertSchedule},
        this.httpOptions
      ).pipe(retry(3), catchError(Constant.handleError));

    }

  findAllClinicExpertSchedulesByClinicAndExpertIdCpa(clinicId: number, expertId: number): Observable<any> {

    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'clinics/' + clinicId + '/experts/' + expertId + '/clinicExpertSchedules',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(retry(0), catchError(Constant.handleError));

  }

    findAllClinicExpertsSchedual(clinicExpertsSchedual, pageNo: number, itemsPerPage: number): Observable<string[]> {

        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ClinicExpertsSchedual/findAllClinicExpertsSchedual',
            {'clinicExpertsSchedual': clinicExpertsSchedual, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    // custom services to handle global searching
    schedualGlcount(searchTerm): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'ClinicExpertsSchedual/countGlobalSchedual',
                                            {'searchTerm': searchTerm},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalschedual(searchTerm, pageNo: number, itemsPerPage: number): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ClinicExpertsSchedual/findGlobalSchedual',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
