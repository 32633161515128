import { Component, OnInit } from '@angular/core';
import {InitialAssessmentReportComponent} from '../initial-assessment-report/initial-assessment-report.component';
import {SessionService} from '../services/session.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TimeSlotService} from '../web-services/time-slot.service';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';

@Component({
              selector: 'app-reports',
              templateUrl: './reports.component.html',
              styleUrls: ['./reports.component.css'],
              providers: [DialogService]
})
export class ReportsComponent implements OnInit {



  letTrue = false;
  displaySummaryDialog = false;
  constructor(
              private sessionService: SessionService,
              private dialogService: DialogService,
              private confirmation: ConfirmationService
  ) {
  };
  ref: DynamicDialogRef;


  ngOnInit(): void {
    // this.letTrue = true;
    console.log('Reports Called');
  }
  showSummaryDialog(){
    this.letTrue=true;
  }
  reportData: any[] = [
    {"reportName":"QTFC Grade","reportSummary": "Clinical presentation : Physical and psychological impairments present", },
    {"reportName":"WAD 0","reportSummary": "Client does not complain of painNo physical signs are observed by the examining health care practitioner.", },
    {"reportName":"WAD I","reportSummary": "Client complains of pain to health practitioner.No physical signs are found i.e. normal range of motion strength and no swelling. Small muscle lesions that is not signitifcant enough to cause muscle spasm.", },
    {"reportName":"WAD II","reportSummary": "Client complains of pain to health practitioner.Musculoskeletal signs are found that could include:Limited range of motionSpasm or swellingPoint tenderness in neck or shouldersUsually sustain sprained ligaments and muscle tears.", },
    {"reportName":"WAD III","reportSummary": "Musculoskeletal signs includingNeurological signs including: Decreased or absent deep tendon reflexAltered muscle recruitment patternsMuscle weaknesssensory deficits (mechanical, thermal, ULNT)Usually the clients suffer from injuries to the neurologic system because of pressure on nerves or irritation secondary to sustained stretch of neural tissue.", },
    {"reportName":"WAD IV","reportSummary": "Fracture or dislocation", },
  ];
  classificationTbl: any[] = [
    {"initial":"Classification on initial assessment",
      "post": "Classification post treatment",},
  ];
  therapyList: any[] = [
    {"theadings":"Client Name:","name": "Imran Choudhry",},
    {"theadings":"Therapist:","name": "Raymond Parker",},
  ];

    viewInitialAssesmentReport() {
      let appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
      console.log(appointmentId, 'appointment data');
      console.log('Initial Assessment Called');
      this.ref = this.dialogService.open(InitialAssessmentReportComponent, {
        header: 'Initial Assessment Report',
        width: '90%',
        contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
        baseZIndex: 10000,
        data: {
          appointmentId: appointmentId
        }
      });
      console.log(this.ref, 'onclose reference');
      this.ref.onClose.subscribe(res => {
        console.log(' before confirmation closing');
        this.confirmation.confirm(null).close();
      });
    }
}
