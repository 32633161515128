<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNamePaymentsDetail)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button pButton type="button" icon="pi pi-search-plus" pTooltip="Advanced Search"
                                        tooltipPosition="bottom"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        class="p-button-rounded p-button-text" id="dialogSearchOverlayBtn" >
                                </button>
                                <button pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-text"
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNamePaymentsDetail)}}"
                                        tooltipPosition="bottom"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn" id="dialogAddOverlayBtn" >
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="dialogOverlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePaymentsDetail)}}
                                </p-header>
                                <form class="pngForm" id="invitationpage-searchmemeber-pngForm"
                                    [formGroup]="paymentsDetailSearch" (ngSubmit)="onSearch()">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnInvoiceId)}}</label>
                                            <p-dropdown [options]="invoiceList" formControlName="invoiceId"
                                                        [filter]="true" class="pngDropdown" [showClear]="true"
                                                        placeholder="Select" (onChange)="onInvoiceChange($event)">
                                            </p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsDetailColumnTotalAmount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="totalAmount"
                                                name="totalAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsDetailColumnDescription)}}</label> <span
                                                class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                    (click)="fieldReset('description')"
                                                    *ngIf="diplayeIconInField('description')"></i> <input pInputText
                                                    type="text" name="description" formControlName="description"
                                                    class="pngInputtext"> </span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search"
                                                  type = "submit"
                                                  class="pngBlueBtn"
                                                  styleClass="p-button">
                                        </p-button>
                                        <p-button label="Clear"
                                                  (click)="clearSearchResult()"
                                                  class="pngBlueBtn"
                                                  styleClass="p-button">
                                        </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideSearchDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>

                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="dialogOverlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}}  {{translate(labels.entityNamePaymentsDetail)}}</p-header>
                                <form class="pngForm" id="invitationpage-addmemeber-pngForm"
                                    [formGroup]="paymentsDetailForm" (ngSubmit)="onSubmit()">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnInvoiceId)}}</label>
                                            
                                            <p-dropdown [options]="invoiceList" formControlName="invoiceId"
                                                [filter]="true" class="pngDropdown" [showClear]="true"
                                                placeholder="Select" (onChange)="onInvoiceChange($event)">
                                            </p-dropdown>
                                            <app-custom-error [form]="paymentsDetailForm" [control]="'invoiceId'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsDetailColumnTotalAmount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="totalAmount"
                                                name="totalAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('totalAmount')"
                                                [useGrouping]="false" [disabled] = "true" ></p-inputNumber>
                                            <app-custom-error [form]="paymentsDetailForm" [control]="'totalAmount'">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsDetailColumnDescription)}}</label> <span
                                                class="p-input-icon-right"><i class="pi pi-times color-danger"
                                                    (click)="fieldReset('description')"
                                                    *ngIf="diplayeIconInField('description')"></i> <input pInputText
                                                    type="text" name="description"
                                                    [class.p-invalid]="inputFieldDanger('description')" pInputText
                                                    formControlName="description" class="pngInputtext"></span>
                                            <app-custom-error [form]="paymentsDetailForm" [control]="'description'">
                                            </app-custom-error>
                                        </div>

                                        <!-- invoice amount field -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>Invoice Amount</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="invoiceAmount"
                                                name="invoiceAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [class.p-invalid]="inputFieldDanger('invoiceAmount')"
                                                [useGrouping]="false" [disabled] = "true" ></p-inputNumber>
                                            <app-custom-error [form]="paymentsDetailForm" [control]="'invoiceAmount'">
                                            </app-custom-error>
                                        </div>
                                    </div>
                                   
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                  type = "submit"
                                                  class="pngBlueBtn"
                                                  styleClass="p-button">
                                        </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>

                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                          id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search"
                                           (click)="onGlobalSearchClick(global.value)">
                                        </i>
                                    <input #global
                                           class="pngInputtext"
                                           pInputText
                                           placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                           type="text"/>
                                    </span>
                                </p-header>
                                <p-table *ngIf="totalRecords > 0 " [value]="paymentsDetailList" #dv [lazy]="true"
                                         (onLazyLoad)="onPageChnage($event)" [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]" [paginator]="true" [rows]="itemPerPage" 
                                         [autoLayout]="true" [totalRecords]="totalRecords"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [showCurrentPageReport]=true id="invitationpage-memberslist-table"
                                         class="pngTable paymentsDetailTable" [ngClass]="{'pngTableEmptyMessage': paymentsDetailList?.length == 0}" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <!-- <th> PaymentsId</th> -->
                                            <!-- <th> Items </th> -->
                                            <!-- <th> Quantity</th> -->
                                            <!-- <th> Unit Price</th> -->
                                            <!-- <th> Discount</th> -->
                                            <!-- <th> Tax Amount</th> -->
                                            <th> Total Amount</th>
                                            <th> Description</th>
                                            <th> Invoice Amount </th>
                                            <!-- <th> UserId</th> -->
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-element let-i="rowIndex">
                                        <tr>
                                            <!-- <td> {{element?.paymentsId.paymentId}} </td> -->
                                            <!-- <td> {{element?.itemId.label}} </td> -->
                                            <!-- <td> {{element?.quantity}} </td> -->
                                            <!-- <td> {{element?.unitPrice}} </td> -->
                                            <!-- <td> {{element?.discount}} </td> -->
                                            <!-- <td> {{element?.taxAmount}} </td> -->
                                            <td> {{element?.totalAmount}} </td>
                                            <td> {{element?.description}} </td>
                                            <td> {{element?.invoiceId?.invoiceAmount}}</td>
                                            <!-- <td> {{element?.userId}} </td> -->
                                            <td>
                                                <div class="pngTable-icons">
                                                    <!-- <p-button label="Edit" icon="pi pi-pencil"
                                                        (click)="loadPaymentsDetailToEdit(element.paymentsDetailId, i)"
                                                        styleClass="p-button pbtn-transparent">
                                                    </p-button> -->
                                                    <p-button pTooltip="Delete" icon="pi pi-trash"
                                                        (click)="deleteByIdPaymentsDetail(element.paymentsDetailId,i)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button pTooltip="View" icon="pi pi-eye"
                                                    (click) = "viewPaymentDetail(element.paymentsDetailId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>