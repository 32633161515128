<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameFlags)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameFlags)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameFlags)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="flagsSearch" class="pngForm" id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.flagsColumnFlag)}} </label>
                                            <p-selectButton
                                                    [options]="flagColorList"
                                                    formControlName="flag"
                                                    [optionValue]="flagColorList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.flagsColumnFlagValue)}} </label>
                                            <p-dropdown [filter]="true" [options]="flagValueList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="flagValue"
                                                        placeholder="Select">
                                            </p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.flagsColumnFlagStatus)}} </label>
                                            <p-dropdown [filter]="true" [options]="flagStatusList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="flagStatus"
                                                        placeholder="Select">
                                            </p-dropdown>
                                        </div>
                                       </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameFlags)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="flagsForm" class="pngForm" id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.flagsColumnFlag)}}</label>
                                            <p-selectButton
                                                    [options]="flagColorList"
                                                    formControlName="flag"
                                                    [optionValue]="flagColorList['listOfValuesId']">
                                            </p-selectButton>
                                            <app-custom-error [control]="'flag'" [form]="flagsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.flagsColumnFlagValue)}}</label>
                                            <p-dropdown [filter]="true"
                                                        [options]="flagValueList"
                                                        [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="flagValue"
                                                        placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'flagValue'" [form]="flagsForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.flagsColumnFlagStatus)}}</label>
                                            <p-dropdown [filter]="true"
                                                        [options]="flagStatusList"
                                                        [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="flagStatus"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'flagStatus'" [form]="flagsForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv">
                            <p-panel class="pngPanel pngPanelList">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                               placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                               type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="totalRecords >0"
                                         (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="flagsList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': flagsList?.length == 0}" id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Flag</th>
                                            <th> Value</th>
                                            <th> Status</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.flag?.label}} </td>
                                            <td> {{element?.flagValue?.label}} </td>
                                            <td> {{element?.flagStatus?.label}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadFlagsToEdit(element.flagId, i)"
                                                              icon="pi pi-pencil" pTooltip="Edit"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdFlags(element.flagId,i)"
                                                              icon="pi pi-trash" pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              (click)="viewAgencyDetail(element.flagId)"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
