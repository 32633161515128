import {Constant} from '../../util/constant';
import {MultiSelect} from '../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PresentConditionHistoryMultiSelects extends MultiSelect {

  objectMultiSelects = {
    ['presentSymptoms']: {
      name: Constant.PRESENT_CONDITION_LOV_PRESENT_SYMPTOMS,
      dbField: 'presentSymptoms',
      label: 'Present Symptoms',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    },
    ['servicesSeen']: {
      name: Constant.PRESENT_CONDITION_LOV_SERVICE_SEEN,
      dbField: 'servicesSeen',
      label: 'Services Seen',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    },
    ['treatmentType']: {
      name: Constant.PRESENT_CONDITION_LOV_TREATMENT_TYPE,
      dbField: 'treatmentType',
      label: 'Treatment Type',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    },
    ['investigation']: {
      name: Constant.PRESENT_CONDITION_LOV_INVESTIGATION,
      dbField: 'investigation',
      label: 'Investigation',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    }



  };

  multiSelects = [
    {

      name: Constant.PRESENT_CONDITION_LOV_PRESENT_SYMPTOMS,
      dbField: 'presentSymptoms',
      label: 'Present Symptoms',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false,
    },
    {

      name: Constant.PRESENT_CONDITION_LOV_SERVICE_SEEN,
      dbField: 'servicesSeen',
      label: 'Services Seen',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    {

      name: Constant.PRESENT_CONDITION_LOV_TREATMENT_TYPE,
      dbField: 'treatmentType',
      label: 'Treatment Type',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    {

      name: Constant.PRESENT_CONDITION_LOV_INVESTIGATION,
      dbField: 'investigation',
      label: 'Investigation',
      list: [],
      currentSelected: [{
        "listOfValuesId": {
          "listOfValuesId": 77
        },
        "categoryId": {
          "categoryId": 32,
          "categoryTitle": "Investigation",
          "description": "Investigation",
          "dmlStatus": 1,
          "insertionTimestamp": 1627898930875,
          "closeTimestamp": null,
          "userId": 0
        },
        "value": "X-Ray",
        "dmlStatus": 1,
        "insertionTimestamp": 1627898930918,
        "closeTimestamp": null,
        "label": "X-Ray",
        "userId": 0,
        "selected": 1,
        "lovDetailId": 0
      }],
      isRequired: true,
      error: false
    }

  ];

  getResponse($event): any {
    return super.getResponse($event);
  }

}
