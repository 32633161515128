export class InitialHistoryFormDTO {

  appointmentId: any;
  patientId: any;
  expertId: any;
  caseId: any;
  historyData: any;
  diagnosisData: any;
  redFlagData = [];
  hasMultiSelectErrors = false;
  multiSelectErrorFields = [];
  historyAndDiagnosisData: any;


    constructor(appointmentId: number, patientId: number, expertId: number, caseId: number, multiSelectData: any, formData: any) {

            this.appointmentId = { appointmentId: appointmentId };
            this.patientId = { patientId: patientId };
            this.expertId = { expertId: expertId };
            this.caseId = { caseId: caseId };
            this.historyAndDiagnosisData = this.createHistoryDataFromObjectMultiSelects(multiSelectData, this);
            this.historyData = this.historyAndDiagnosisData.historyData;
            this.diagnosisData = this.historyAndDiagnosisData.diagnosisData;
            this.addFormDataToDTO(formData, this);


   }

/*  createDiagnosisDataFromObjectMultiSelects(multiSelectsData: any, currentClass: InitialHistoryFormDTO): any {

   return multiSelectsData.forEach(multiSelect => {

        const hasDiagnosisData = multiSelect.list.filter(item => {

          if (item.selected === 1) {
            if (item.diagnosis !== undefined && item.diagnosis.length > 0) {

                return item;
            }
          }
        });

      });
  }*/


    createHistoryDataFromMultiSelects(multiSelectsData: any, currentClass: InitialHistoryFormDTO): any {

      let historyData = {};
    ////  let diagnosisData = {};

      multiSelectsData.forEach(multiSelect => {

        const selected = multiSelect.list.filter(item => item.selected === 1);

        if (multiSelect.isRequired && !selected.length) {

            multiSelect.error = true;
            currentClass.hasMultiSelectErrors = true;
            this.multiSelectErrorFields.push(multiSelect);

        }

          historyData[multiSelect.dbField] = multiSelect.list.filter(item => item.selected === 1);



      });

      return historyData;

    }

  createHistoryDataFromObjectMultiSelects(objectMultiSelectsData: any, currentClass: InitialHistoryFormDTO): any {

    let historyData = {};
    let diagnosisData = [];
    let redFlagData = [];

    for (let [key, value] of Object.entries(objectMultiSelectsData)) {

      const selected = objectMultiSelectsData[key].list.filter(item => item.selected === 1);

      if (objectMultiSelectsData[key].isRequired && !selected.length) {

        objectMultiSelectsData[key].error = true;
        currentClass.hasMultiSelectErrors = true;
        this.multiSelectErrorFields.push(objectMultiSelectsData[key]);
        console.log("cs " + objectMultiSelectsData[key]);
      }

      historyData[objectMultiSelectsData[key].dbField] = objectMultiSelectsData[key].list.filter(item => item.selected === 1);

      const currentKeyRedFlagData =  objectMultiSelectsData[key].list.filter(item => {
        if (item.selected === 1 && currentClass.hasRedFlag(item)) {

          item.caseId = this.caseId;
          item.appointmentId = this.appointmentId;
          return item;
        }
      });


      if (currentKeyRedFlagData.length > 0 ) {
        console.log(currentKeyRedFlagData);

        redFlagData = [...redFlagData, ...currentKeyRedFlagData];
      }

      const multiSelectDiagnosisData = objectMultiSelectsData[key].list.filter(item =>  {

        if (item.selected === 1) {
          if (item.diagnosis !== undefined && item.diagnosis.length > 0) {

            const itemDiagnosis = item.diagnosis.map(itemD =>  {

              itemD.caseId = currentClass.caseId;
              itemD.nonObjectDiagnosisId = itemD.diagnosisId;
              itemD.diagnosisId = {

                  diagnosisId: itemD.diagnosisId
              };

              return itemD;

            });

            return itemDiagnosis;
          }
        }
      });

      if (multiSelectDiagnosisData.length > 0 ) {
        diagnosisData = [...diagnosisData, ...multiSelectDiagnosisData];
      }

    }
    currentClass.redFlagData = redFlagData;
    return { historyData: historyData, diagnosisData: diagnosisData};
  }

    hasRedFlag(item: any): any {

      return item.isRedFlag || item.isOrangeFlag || item.isYellowFlag || item.isBlueFlag || item.isGreenFlag;

    }

    addFormDataToDTO(formData: any, currentClass: InitialHistoryFormDTO): void {

      for (let key in formData.controls) {

        currentClass[key] =  formData.controls[key].value;
      }

    }
}
