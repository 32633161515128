import {Constant} from '../../util/constant';
import {MultiSelect} from '../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class DrugHistoryMultiSelects extends MultiSelect {


  objectMultiSelects = {
    ['drugHistory']: {

      name: Constant.DRUG_LOV_DRUG_HISTORY,
      dbField: 'drugHistory',
      label: 'Prescription Drugs',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    },
    ['vaccinations']: {
      name: Constant.VACCINATIONS,
      dbField: 'vaccinations',
      label: 'Vaccinations',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['drinks']: {

      name: Constant.DRINKS,
      dbField: 'drinks',
      label: 'Drinks',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false
    },
    ['recreation']: {
      name: Constant.RECREATION,
      dbField: 'recreation',
      label: 'Recreation',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['smoke']: {
      name: Constant.SMOKING,
      dbField: 'smoke',
      label: 'Smoking',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['caffeinatedDrinks']: {
      name: Constant.CAFFEINATEDDRINK,
      dbField: 'caffeinatedDrinks',
      label: 'Caffeinated Drinks',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false
    }
  };

  getResponse($event): any {
    return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
