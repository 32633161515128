import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constant } from '../util/constant';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constantList = [];

    lovDataObservable = new BehaviorSubject(null);
    //******************  verify Label********************** */
    private httpClient1: HttpClient;
    constructor(private httpClient: HttpClient, handler: HttpBackend) {
        this.httpClient1 = new HttpClient(handler);
    }

    verifyLabel(name: string, constantList) {
        // Null Conditions
        if (constantList == undefined || name == undefined || name == null) {
            return name;
        }
        if (constantList.length == 0) {
            return name;
        }
        let findConstant = constantList.find(x => x['code'].toLowerCase() == name.toLowerCase());
        if (findConstant) {
            let result = findConstant['value'];
            return result;
        }
        return name;
    }


    uploadFile(file: any): Observable<string> {
        console.log('upload file service called: ', file);
        let extension = file.name.split('.');
        let observable: Observable<string> = Observable.create(observer => {
            async function run(fileUploadService: SharedService): Promise<string> {
                let formData = new FormData();
                formData.append('file', <File>file);
                try {
                    // console.log("uplload image");

                    let imageUrl = await fileUploadService.uploadFileServiceCall(formData, extension[1]).toPromise();

                    return imageUrl;
                } catch (errorResponse) {
                    console.error(errorResponse);
                    observer.error([errorResponse]);
                    return null;
                }
            }
            run(this).then(result => {
                if (!result)
                    return;
                observer.next(result);
                observer.complete();
            });
        });
        return observable;
    }

    uploadFileServiceCall(formData, ext) {
        console.log('form data in upload File Service Call: ', formData);
      //  return this.httpClient1.post(Constant.FILE_UPLOAD_SERVER_URL + "HWAssets/rest/Assets/upload/Ghanem/Image/" + ext, formData, { responseType: "text" })
      return this.httpClient1.post(Constant.CPA_SPRING_API_URL + "files/uploadFile", formData, { responseType: "text" })
    }

    checkRole(pageControls: Map<string, boolean>): Map<string, boolean> {
        let rolesArray = JSON.parse(sessionStorage.getItem("cpa_roles"));
        // console.log("roles from session :: ",rolesArray);
        for (let key of Array.from(pageControls.keys())) {
          if (rolesArray.find(x => x == key)) {
            pageControls.set(key, true);
          }
        }
        return pageControls;
     }
}
