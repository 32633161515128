<!--<app-app-header></app-app-header>-->
<section class="sectionmain" id="sectionmain-superAdmin">
    <!-- <div class="container-fluid">
          <section class="sectionheading" id="sectionmain-superAdmin-sectionheading">
            <h1 class="h1-style ">Welcome Back Super Admin</h1>
            <h3 class="h3-style "> 14:10 3rd of June 2020 </h3>
          </section>
        </div> -->
    <div class="container">
        <!-- <section class="sectioncontent" id="sectionmain-superAdmin-sectioncontent"> -->
        <div class="p-fluid p-formgrid p-grid superAdmin-grid" id="superAdmin-grid">

<!--Active Clients-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-aClientsWrap">
                <div class="superAdminCard superAdminBlue" [routerLink]="['/active_agency']">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-aClients">
                        <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                        <div class="dashboard-card-text">
                            <p>Active Clients</p>
                            <h3 class="dashboardText">{{activeRecords}}</h3>
                        </div>

                    </div>
                </div>
            </div>
<!--Deactivated Clients-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-dClientsWrap">
                <div class="superAdminCard superAdminBlue" [routerLink]="['/deactivated_agency']">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-dClients">
                        <img src="assets/theme1/custom-files/img/icons/deactivatedClients.svg">
                        <div class="dashboard-card-text">
                            <p>Deactivated Clients</p>
                            <h3 class="dashboardText">{{deActivatedRecords}}</h3>
                        </div>

                    </div>
                </div>
            </div>
<!--InComplete Clients-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-icClientsWrap">
                <div class="superAdminCard superAdminBlue" [routerLink]="['/incomplete_agency']">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-icClients">
                        <img src="assets/theme1/custom-files/img/icons/incompleteClients.svg">
                        <div class="dashboard-card-text">
                            <p>InComplete Clients</p>
                            <h3 class="dashboardText">{{inCompleteRecords}}</h3>
                        </div>


                    </div>
                </div>
            </div>
<!--Cases-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-casesWrap" [routerLink]="[]">
                <div class="superAdminCard superAdminBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-cases">
                        <img src="assets/theme1/custom-files/img/icons/cases.svg">
                        <div class="dashboard-card-text">
                            <p>Cases</p>
                            <h3 class="dashboardText"></h3>
                        </div>

                    </div>
                </div>
            </div>
<!--Total Subscriptions-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-tSubsWrap" [routerLink]="[]">
                <div class="superAdminCard superAdminBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-tSubs">
                        <img src="assets/images/superAdminDashboard/subscription.png">
                        <div class="dashboard-card-text">
                            <p>Total Subscriptions</p>
                            <h3 class="dashboardText"></h3>
                        </div>

                    </div>
                </div>
            </div>
<!--Active Direct Debits-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-aDDWrap" [routerLink]="[]">
                <div class="superAdminCard superAdminBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-aDD">
                        <img src="assets/theme1/custom-files/img/icons/active-direct-debit.svg">
                        <div class="dashboard-card-text">
                            <p>Active Direct Debits</p>
                            <h3 class="dashboardText"></h3>
                        </div>

                    </div>
                </div>
            </div>
<!--Cancelled Direct Debits-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-cDDWrap" [routerLink]="[]">
                <div class="superAdminCard superAdminBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-cDD">
                        <img src="assets/theme1/custom-files/img/icons/cancelled-direct-debit.svg">
                        <div class="dashboard-card-text">
                            <p>Cancelled Direct Debits</p>
                            <h3 class="dashboardText"></h3>
                        </div>

                    </div>
                </div>
            </div>
<!--Company Types-->
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-cTypesWrap">
                <div class="superAdminCard superAdminBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-cTypes">
                        <img src="assets/images/superAdminDashboard/generate-companies.png">
                        <div class="dashboard-card-text">
                            <p>Company Types</p>
                            <h3 class="dashboardText"></h3>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- </section> -->
    </div>
</section>
