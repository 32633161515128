import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationService} from '../services/validation.service';
import {ActivatedRoute, Router} from '../../../node_modules/@angular/router';
import {NonInterceptService} from '../services/non-intercept.service';
import {ToastService} from '../services/toast.service';

@Component({
               selector: 'app-login-changepassword',
               templateUrl: './login-changepassword.component.html',
               styleUrls: ['./login-changepassword.component.scss']
           })
export class LoginChangepasswordComponent implements OnInit {

    resetPasswordForm: FormGroup;
    hashvalue: string = '';
    hide: boolean = false;
    passwordhide: boolean = false;
    email: string = '';


    validationMessages = {
        'password': {
            'required': 'Password is required.',
            'pattern': 'Please Enter a Strong Password without Space'
        },
        'confirmPassword': {
            'required': 'Confirm Password is required.',
            'mismatch': 'Password and Confirm Password do not match'
        }
    };
    formErrors = {Email: '', password: '', confirmPassword: ''};

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertService: ToastService,
        private fb: FormBuilder,
        private nonInterceptService: NonInterceptService,
        private router: Router,
        private _validation: ValidationService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.hashvalue = this.activatedRoute.snapshot.paramMap.get('hashvalue');
            this.email = this.activatedRoute.snapshot.paramMap.get('email');
        });
    }

    ngOnInit() {
        console.log('CP called');
        this.verificationForPasswordReset();
        // this.resetPasswordForm = this.fb.group(
        //     {
        // password: ['', [Validators.required, Validators.pattern(this._validation.regex.password),Validators.pattern(this._validation.regex.nospace)]],
        // confirmPassword: ['', [Validators.required]],
        //     },
        //     {
        //         validator: this._validation.matchConfirmItems('Password', 'ConfirmPassword'),
        //     }
        // );

        this.resetPasswordForm =new FormGroup({
                password: new FormControl('', [Validators.required, Validators.pattern(this._validation.regex.password),Validators.pattern(this._validation.regex.nospace)]),
                confirmPassword: new FormControl ('', [Validators.required]),
            },
            {
                validators: this._validation.matchConfirmItems('password', 'confirmPassword'),
            }
        );


        this.resetPasswordForm.valueChanges.subscribe(
            value => {
                this.logValidationErrors();
            }
        );
    }


    logValidationErrors() {
        this.formErrors = this._validation.getValidationErrors(this.resetPasswordForm, this.validationMessages);
    }


    resetPassword() {
        if(this.resetPasswordForm.valid)
        {
            console.log('its valid');
            this.nonInterceptService.resetPassword(this.resetPasswordForm.get('password').value, this.email, this.hashvalue).subscribe(
                (data: string) => {
                    if (data['message'] == 'Success') {
                        this.router.navigate(['/login']);
                        this.alertService.showSuccessWithCustomMessage(data['documentation']);

                    } else {
                        this.alertService.showErrorWithCustomMessage(data['documentation']);
                        this.router.navigate(['/login']);
                    }
                });
        }else {
            console.log('not valid');
        }
    }

    verificationForPasswordReset() {
        this.nonInterceptService.verificationForPasswordReset(this.email, this.hashvalue).subscribe((data: string) => {
            if (data['message'] == 'Success') {
                console.log('successfully matched hash and email');
            } else {
                this.alertService.showErrorWithCustomMessage('Invalid Request');
                this.router.navigate(['/login']);
            }
        });
    }

}
