import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class OutcomeMeasureService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdOutcomeMeasure(outcomeMeasureId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasure/deleteOutcomeMeasure', {'outcomeMeasureId': outcomeMeasureId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    deleteByIdOutcomeMeasureDetail(outcomeMeasureId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasureDetail/deleteOutcomeMeasureDetail', {'outcomeMeasureDetailId': outcomeMeasureId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countOutcomeMeasure(outcomeMeasure): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'OutcomeMeasure/count', {'outcomeMeasure': outcomeMeasure},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateOutcomeMeasure(outcomeMeasure): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasure/updateOutcomeMeasure', [outcomeMeasure], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addOutcomeMeasure(outcomeMeasure): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasure/addUpdateOutcomeMeasureWithDetail', outcomeMeasure, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    addOutcomeMeasureDetail(outcomeMeasure): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasureDetail/addOutcomeMeasureDetail', outcomeMeasure, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    updateOutcomeMeasureDetail(outcomeMeasure): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'OutcomeMeasureDetail/updateOutcomeMeasureDetail', outcomeMeasure, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findAllOutcomeMeasureDetail(outcomeMeasure, pageNo: number, itemsPerPage: number){
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'OutcomeMeasureDetail/findAllOutcomeMeasureDetail',
            {'outcomeMeasureDetail': outcomeMeasure, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findAllOutcomeMeasure(outcomeMeasure, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'OutcomeMeasure/findAllOutcomeMeasure',
            {'outcomeMeasure': outcomeMeasure, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
