<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameCompany)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton pTooltip="Advanced Search"
                                        tooltipPosition="bottom" type="button"></button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton pTooltip="{{addEditTitle}} {{translate(labels.entityNameCompany)}}"  tooltipPosition="bottom"
                                        type="button"></button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameCompany)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="companySearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyName')"
                                                    *ngIf="diplayeIconInField('companyName')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="companyName" name="companyName" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyEmail')"
                                                    *ngIf="diplayeIconInField('companyEmail')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="companyEmail" name="companyEmail" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyAddress')"
                                                    *ngIf="diplayeIconInField('companyAddress')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="companyAddress" name="companyAddress" pInputText
                                                    type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyPhoneNumber)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyPhoneNumber')"
                                                    *ngIf="diplayeIconInField('companyPhoneNumber')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="companyPhoneNumber" name="companyPhoneNumber"
                                                    pInputText type="text"> </span>
                                        </div>
                                        <!-- <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnUserId)}} </label>
                                            <p-dropdown [filter]="true" [options]="usersList" [showClear]="true"
                                                class="pngDropdown" formControlName="userId"
                                                placeholder={{translate(labels.companyColumnUserId)}}></p-dropdown>
                                        </div> -->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnNumberOfUsers)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                formControlName="numberOfUsers" mode="decimal" name="numberOfUsers"
                                                pNumberInput type="number"></p-inputNumber>
                                        </div>

                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" class="pngBlueBtn" styleClass="p-button"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" class="pngBlueBtn" styleClass="p-button">
                                        </p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" class="pngBtnTransparent" styleClass="p-button">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameCompany)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="companyForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyName)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyName')"
                                                    *ngIf="diplayeIconInField('companyName')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('companyName')"
                                                    class="pngInputtext" formControlName="companyName"
                                                    name="companyName" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'companyName'" [form]="companyForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyEmail)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyEmail')"
                                                    *ngIf="diplayeIconInField('companyEmail')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('companyEmail')"
                                                    class="pngInputtext" formControlName="companyEmail"
                                                    name="companyEmail" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'companyEmail'" [form]="companyForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyAddress)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyAddress')"
                                                    *ngIf="diplayeIconInField('companyAddress')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('companyAddress')"
                                                    class="pngInputtext" formControlName="companyAddress"
                                                    name="companyAddress" pInputText type="text"></span>
                                            <app-custom-error [control]="'companyAddress'" [form]="companyForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnCompanyPhoneNumber)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('companyPhoneNumber')"
                                                    *ngIf="diplayeIconInField('companyPhoneNumber')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('companyPhoneNumber')"
                                                    class="pngInputtext" formControlName="companyPhoneNumber"
                                                    name="companyPhoneNumber" pInputText type="text"></span>
                                            <app-custom-error [control]="'companyPhoneNumber'" [form]="companyForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.companyColumnNumberOfUsers)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('numberOfUsers')"
                                                [useGrouping]="false" class="p-invalid pngInputnumber"
                                                formControlName="numberOfUsers" mode="decimal" name="numberOfUsers"
                                                pNumberInput type="number"></p-inputNumber>
                                            <app-custom-error [control]="'numberOfUsers'" [form]="companyForm">
                                            </app-custom-error>
                                        </div>

                                        <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencyColumnUserEmail)}}</label>
                                            <span class="p-input-icon-right" >
                                                <i (click)="fieldReset('userEmail')"
                                                    *ngIf="diplayeIconInField('userEmail')"
                                                    class="pi pi-times color-danger">
                                                </i>
                                                <input  [class.p-invalid]="inputFieldDanger('userEmail')"
                                                        class="pngInputtext" formControlName="userEmail" name="userEmail"
                                                        pInputText type="text">
                                            </span>
                                            <app-custom-error [control]="'userEmail'" [form]="companyForm"></app-custom-error>
                                            <span *ngIf="duplicateEmail" class="duplicate-Email">Email already exists</span>
                                        </div>

                                        <div *ngIf="!editTag" class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencyColumnUserPassword)}}</label>
                                            <span class="p-input-icon-right">
                                                <i (click)="fieldReset('password')"
                                                    *ngIf="diplayeIconInField('password')"
                                                    class="pi pi-times color-danger rightallign">
                                                </i>
                                                <input  [class.p-invalid]="inputFieldDanger('password')"
                                                        class="pngInputtext" formControlName="password" name="password"
                                                        pInputText [type]="passwordType" >
                                                <i [ngClass]="passwordType === 'text'?'pi pi-eye':'pi pi-eye-slash'"
                                                    (click)="showHidePassword()"></i>
                                            </span>
                                            <app-custom-error [control]="'password'" [form]="companyForm"></app-custom-error>
                                        </div>
                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close"  (click)="showHideAddDialog()" class="pngBtnTransparent"  styleClass="p-button">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global
                                            class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" /></span>

                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0"
                                         (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" 
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [alwaysShowPaginator]="false"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="companyList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': companyList?.length == 0}" id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Name</th>
                                            <th> Email</th>
                                            <th> Address</th>
                                            <th> Phone Number</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.companyName}} </td>
                                            <td> {{element?.companyEmail}} </td>
                                            <td> {{element?.companyAddress}} </td>
                                            <td> {{element?.companyPhoneNumber}} </td>
                                            <!-- <td> {{element?.userId.userId}} </td> -->
                                            <!-- <td> {{element?.numberOfUsers}} </td> -->
                                            <td>

                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadCompanyToEdit(element.companyId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdCompany(element.companyId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                    (click)="viewCompanyDetail(element.companyId)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <!-- <p-button pTooltip="Manage Subscription"
                                                              (click)="getUserSubscriptionList(element.userId)"
                                                              icon="pi pi-eye"
                                                              styleClass="p-button pbtn-transparent">
                                                    </p-button> -->
<!--                                                    <button type="button" pButton icon="pi pi-ellipsis-v" label="" class="menu-actions"-->
<!--                                                    (click)="menu.toggle($event); getUserId(element.userId)"></button> -->
                                                    <button type="button" pTooltip="Manage Subscription" pButton icon="manage-subs-icon" label="" class="menu-actions"
                                                    (click)="showManageSubscription(element.userId)"></button>
<!--                                                  <p-menu #menu-->
<!--                                                          [popup]="true"-->
<!--                                                          [model]="items"-->
<!--                                                          (onHide)="hiddd($event)"-->
<!--                                                          (onShow)="showww($event)"-->
<!--                                                          appendTo="body"-->
<!--                                                          class="action-btns-menu" styleClass="p-button pbtn-transparent">-->
<!--                                                  </p-menu>-->
<!--Manage Subscription dialog box-->
                                                    <p-dialog header="Manage Subscription"
                                                              [(visible)]="subscriptionViewModal"
                                                              [modal]="true"
                                                              [baseZIndex]="10000"
                                                              [draggable]="false">

                                                        <ng-template *ngIf="!userSubscriptionList" pTemplate="emptymessage">
                                                            {{translate(labels.mat_pagination_no_data_msg)}}
                                                        </ng-template>

                                                        <div *ngFor="let item of userSubscriptionList; let i = index"
                                                             class="p-field-checkbox subsCheckboxView manageSubsClass">
                                                            <div class="p-grid manageSubsGrid">
                                                                <div class="p-col-6 manageSubsLabel">
                                                                    <label class="manageSubsLabel">{{translate(item?.userSubscriptionTitle)}}</label>
                                                                </div>
                                                                <!--                                                                <div class="p-col-2"></div>-->
                                                                <div class="p-col-6 manageSubsSelectBtn">
                                                                    <p-selectButton
                                                                            [(ngModel)]="item.value"
                                                                            [name]="item.value"
                                                                            [optionValue]="manageOptions['value']"
                                                                            [options]="manageOptions"
                                                                            (onChange)="onSelect(item,i,element, $event)">
                                                                    </p-selectButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-template pTemplate="footer">
                                                            <div class="p-d-flex p-jc-end">
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="refreshSubs(element.userId)"
                                                                               label="Refresh" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div class="p-mr-3">
                                                                    <p-button  (click)="cancelUserSubscription($event)"
                                                                               label="Cancel" styleClass="p-button" class="pngBtnTransparent">
                                                                    </p-button>
                                                                </div>
                                                                <div *ngIf="userSubscriptionList && userSubscriptionList.length > 0">
                                                                    <p-button (click)="saveUserSubscription($event)"
                                                                              label="Save" styleClass="p-button" class="pngBlueBtn">
                                                                    </p-button>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dialog>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
