/* tslint:disable */
import {Component, ElementRef, EventEmitter, OnInit, Output,ViewChild,Input,AfterContentInit} from '@angular/core';
import { Canvg } from 'canvg';
import {SharedService} from '../web-services/shared.service';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BodyChartDetailService} from '../web-services/body-chart-detail.service';
import {BodyChartDetailAnatomiesDiagramService} from '../web-services/body-chart-detail-anatomies-diagram.service';
import {HttpStatusCode} from '@angular/common/http';

@Component({
  selector: 'app-interactive-body-chart',
  templateUrl: './interactive-body-chart.component.html',
  styleUrls: ['./interactive-body-chart.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]

})
export class InteractiveBodyChartComponent implements OnInit {

  @ViewChild('bodyAnatomyInformationInput') bodyAnatomyInformationInput: ElementRef;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()  onBodyAnatomyInformationChanged = new EventEmitter<any>();
  @Output() bodyAnatomyFrontImage = new EventEmitter<any>();
  @Output() bodyAnatomyBackImage = new EventEmitter<any>();
  @Output() bodyAnatomySkeletonImage = new EventEmitter<any>();
  @Output() showAnatomyPainDetails = new EventEmitter<any>();
  @Input() existingPainScoreData;
  @Input() isFollowUp;

  anaconfig = {
    "default":{
      "outlineColor": "#FF0000"
    },
    /*==============================================================================*/
    /*==============================================================================*/
    /*================ !!! CUSTOMIZATION OF THE FIRST SIDE !!!  ====================*/
    /*==============================================================================*/
    /*==============================================================================*/
    "ana1":{//sternocleidomastoid-rt-front
      "hover": "STERNOCLEIDOMASTOID [RT]",//info of the popup
      "url": "https://www.humananatomyillustrations.com/",//link to any webpage
      "target": "modal",// use "new_window", "same_window", "modal" with bootstrap only, or "none"
      "enabled": true//true/false to activate/deactivate
    },
    "ana2":{//sternocleidomastoid-lt-front
      "hover": "STERNOCLEIDOMASTOID [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana3":{//trapezius-rt-front
      "hover": "TRAPEZIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana4":{//trapezius-lt-front
      "hover": "TRAPEZIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana5":{//deltoid-rt-front
      "hover": "DELTOID [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana6":{//deltoid-lt-front
      "hover": "DELTOID [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana7":{//pectoralis-major-rt-front
      "hover": "PECTORALIS MAJOR [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana8":{//pectoralis-major-lt-front
      "hover": "PECTORALIS MAJOR [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana9":{//rectus-abdominis-front
      "hover": "RECTUS ABDOMINIS",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana10":{//serratus-anterior-rt-front
      "hover": "SERRATUS ANTERIOR [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana11":{//serratus-anterior-lt-front
      "hover": "SERRATUS ANTERIOR [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana12":{//abdominal-external-oblique-rt-front
      "hover": "ABDOMINAL EXTERNAL OBLIQUE [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana13":{//abdominal-external-oblique-lt-front
      "hover": "ABDOMINAL EXTERNAL OBLIQUE [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana14":{//biceps-brachii-rt-front
      "hover": "BICEPS BRACHII [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana15":{//biceps-brachii-lt-front
      "hover": "BICEPS BRACHII [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana16":{//brachioradialis-rt-front
      "hover": "BRACHIORADIALIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana17":{//brachioradialis-lt-front
      "hover": "BRACHIORADIALIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana18":{//flexor-carpi-radialis-rt-front
      "hover": "FLEXOR CARPI RADIALIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana19":{//flexor-carpi-radialis-lt-front
      "hover": "FLEXOR CARPI RADIALIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana20":{//palmaris-longus-rt-front
      "hover": "PALMARIS LONGUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana21":{//palmaris-longus-lt-front
      "hover": "PALMARIS LONGUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana22":{//gluteus-medius-rt-front
      "hover": "GLUTEUS MEDIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana23":{//gluteus-medius-lt-front
      "hover": "GLUTEUS MEDIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana24":{//vastus-lateralis-rt-front
      "hover": "VASTUS LATERALIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana25":{//vastus-lateralis-lt-front
      "hover": "VASTUS LATERALIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana26":{//rectus-femoris-rt-front
      "hover": "RECTUS FEMORIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana27":{//rectus-femoris-lt-front
      "hover": "RECTUS FEMORIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana28":{//sartorius-rt-front
      "hover": "SARTORIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana29":{//sartorius-lt-front
      "hover": "SARTORIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana30":{//pectineus-rt-front
      "hover": "PECTINEUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana31":{//pectineus-lt-front
      "hover": "PECTINEUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana32":{//adductor-longus-rt-front
      "hover": "ADDUCTOR LONGUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana33":{//adductor-longus-lt-front
      "hover": "ADDUCTOR LONGUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana34":{//vastus-medialis-rt-front
      "hover": "VASTUS MEDIALIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana35":{//vastus-medialis-lt-front
      "hover": "VASTUS MEDIALIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana36":{//gracilis-rt-front
      "hover": "GRACILIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana37":{//gracilis-lt-front
      "hover": "GRACILIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana38":{//gastrocnemius-rt-front
      "hover": "GASTROCNEMIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana39":{//gastrocnemius-lt-front
      "hover": "GASTROCNEMIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana40":{//soleus-rt-front
      "hover": "SOLEUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana41":{//soleus-lt-front
      "hover": "SOLEUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana42":{//tibialis-anterior-rt-front
      "hover": "TIBIALIS ANTERIOR [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana43":{//tibialis-anterior-lt-front
      "hover": "TIBIALIS ANTERIOR [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana44":{//peroneus-longus-rt-front
      "hover": "PERONEUS LONGUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana45":{//peroneus-longus-lt-front
      "hover": "PERONEUS LONGUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    /*==============================================================================*/
    /*==============================================================================*/
    /*================  !!! CUSTOMIZATION OF THE BACK SIDE !!!  ====================*/
    /*==============================================================================*/
    /*==============================================================================*/
    "ana46":{//trapezius-back
      "hover": "TRAPEZIUS",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana47":{//infraspinatus-rt-back
      "hover": "INFRASPINATUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana48":{//infraspinatus-lt-back
      "hover": "INFRASPINATUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana49":{//teres-major-rt-back
      "hover": "TERES MAJOR [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana50":{//teres-major-lt-back
      "hover": "TERES MAJOR [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana51":{//latissimus-dorsi-rt-back
      "hover": "LATISSIMUS DORSI [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana52":{//latissimus-dorsi-lt-back
      "hover": "LATISSIMUS DORSI [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana53":{//deltoid-rt-back
      "hover": "DELTOID [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana54":{//deltoid-lt-back
      "hover": "DELTOID [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana55":{//triceps-brachii-rt-back
      "hover": "TRICEPS BRACHII [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana56":{//triceps-brachii-lt-back
      "hover": "TRICEPS BRACHII [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana57":{//extensor-carpi-ulnaris-rt-back
      "hover": "EXTENSOR CARPI ULNARIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana58":{//extensor-carpi-ulnaris-lt-back
      "hover": "EXTENSOR CARPI ULNARIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana59":{//flexor-carpi-ulnaris-rt-back
      "hover": "FLEXOR CARPI ULNARIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana60":{//flexor-carpi-ulnaris-lt-back
      "hover": "FLEXOR CARPI ULNARIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana61":{//gluteus-medius-rt-back
      "hover": "GLUTEUS MEDIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana62":{//gluteus-medius-lt-back
      "hover": "GLUTEUS MEDIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana63":{//gluteus-maximus-rt-back
      "hover": "GLUTEUS MAXIMUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana64":{//gluteus-maximus-lt-back
      "hover": "GLUTEUS MAXIMUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana65":{//vastus-lateralis-rt-back
      "hover": "VASTUS LATERALIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana66":{//vastus-lateralis-lt-back
      "hover": "VASTUS LATERALIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana67":{//biceps-femoris-rt-back
      "hover": "BICEPS FEMORIS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana68":{//biceps-femoris-lt-back
      "hover": "BICEPS FEMORIS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana69":{//semitendinosus-rt-back
      "hover": "SEMITENDINOSUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana70":{//semitendinosus-lt-back
      "hover": "SEMITENDINOSUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana71":{//semimembranosus-rt-back
      "hover": "SEMIMEMBRANOSUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana72":{//semimembranosus-lt-back
      "hover": "SEMIMEMBRANOSUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana73":{//adductor-magnus-rt-back
      "hover": "ADDUCTOR MAGNUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana74":{//adductor-magnus-lt-back
      "hover": "ADDUCTOR MAGNUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana75":{//gastrocnemius-rt-back
      "hover": "GASTROCNEMIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana76":{//gastrocnemius-lt-back
      "hover": "GASTROCNEMIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana77":{//skull
      "hover": "SKULL",//info of the popup
      "url": "https://www.humananatomyillustrations.com/",//link to any webpage
      "target": "same_window",// use "new_window", "same_window", "modal" with bootstrap only, or "none"
      "enabled": true//true/false to activate/deactivate
    },
    "ana78":{//spine
      "hover": "SPINE",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana79":{//sacrum
      "hover": "SACRUM",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana80":{//pelvis
      "hover": "PELVIS",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana81":{//ribs
      "hover": "RIBS",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana82":{//sternum
      "hover": "STERNUM",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana83":{//clavicle-rt
      "hover": "CLAVICLE [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana84":{//clavicle-lt
      "hover": "CLAVICLE [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana85":{//scapula-rt
      "hover": "SCAPULA [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana86":{//scapula-lt
      "hover": "SCAPULA [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana87":{//humerus-rt
      "hover": "HUMERUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana88":{//humerus-lt
      "hover": "HUMERUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana89":{//radius-rt
      "hover": "RADIUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana90":{//radius-lt
      "hover": "RADIUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana91":{//ulna-rt
      "hover": "ULNA [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana92":{//ulna-lt
      "hover": "ULNA [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana93":{//hand-rt
      "hover": "HAND [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana94":{//hand-lt
      "hover": "HAND [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana95":{//femur-rt
      "hover": "FEMUR [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana96":{//femur-lt
      "hover": "FEMUR [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana97":{//patella-rt
      "hover": "PATELLA [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana98":{//patella-lt
      "hover": "PATELLA [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana99":{//tibia-rt
      "hover": "TIBIA [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana100":{//tibia-lt
      "hover": "TIBIA [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana101":{//fibula-rt
      "hover": "FIBULA [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana102":{//fibula-lt
      "hover": "FIBULA [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana103":{//foot-rt
      "hover": "FOOT [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana104":{//foot-lt
      "hover": "FOOT [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana105":{//carpals-rt
      "hover": "CARPALS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana106":{//carpals-lt
      "hover": "CARPALS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana107":{//metacarpals-rt
      "hover": "METACARPALS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana108":{//metacarpals-lt
      "hover": "METACARPALS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana109":{//hand-phalanges-rt
      "hover": "HAND PHALANGES [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana110":{//hand-phalanges-lt
      "hover": "HAND PHALANGES [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana111":{//calcaneus-rt
      "hover": "CALCANEUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana112":{//calcaneus-lt
      "hover": "CALCANEUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana113":{//talus-rt
      "hover": "TALUS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana114":{//talus-lt
      "hover": "TALUS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana115":{//tarsals-rt
      "hover": "TARSALS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana116":{//tarsals-lt
      "hover": "TARSALS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana117":{//metatarsals-rt
      "hover": "METATARSALS [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana118":{//metatarsals-lt
      "hover": "METATARSALS [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana119":{//foot-phalanges-rt
      "hover": "FOOT PHALANGES [RT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    },
    "ana120":{//foot-phalanges-lt
      "hover": "FOOT PHALANGES [LT]",
      "url": "https://www.humananatomyillustrations.com/", "target": "same_window",
      "enabled": true
    }
  };


  painScoreColours = ['#7AD047', '#95CE49', '#ACE24E', '#F9E104', '#F8CE07', '#FCB129', '#FCA329', '#FC8A29', '#F96B2D', '#E21B15']



  selectedBodyPartInfo = {

    bodyChartAnatomyBodyPart: '',
    id: 0,
    bodyChartAnatomyNotes: '',
    bodyChartAnatomyPainScore: 0,
    bodyChartAnatomiesDiagramId: 0,

  };
  selectedAnatomyList = [];
  bodyChartAnatomiesFront = [];
  bodyChartAnatomiesBack = [];
  showBack = false;
  showFront = true;
  selectedPainScore = 0;
  bodyChartAnatomyDetailState: any = 'out';

  constructor(private sharedService: SharedService,
              private bodyChartDetailAnatomiesDiagramService: BodyChartDetailAnatomiesDiagramService

  ) {}

  ngOnInit() {

    $('#baseb').hide();
    this.findAllBodyChartAnatomiesDiagram();




  }

  ngAfterContentChecked(): void {

    let _self = this;

    $("#base-skeleton").hide().animate({"opacity":"0"}, 100);



    $(".go-muscle").on("click", function(){
      $("#baseb").hide().animate({"opacity":"0"}, 100);
      $("#basea").show().animate({"opacity":"1"}, 100);
      $("#base-skeleton").hide().animate({"opacity":"0"}, 100);

    });





    $(".go-skeleton").on("click", function(){
      $("#baseb").hide().animate({"opacity":"0"}, 100);
      $("#basea").hide().animate({"opacity":"0"}, 100);
      $("#base-skeleton").show().animate({"opacity":"1"}, 100);
    });


   // $(function () {
      $("path[id^=ana]").each(function (i, e) {

        _self.anaaddEvent( $(e).attr("id"),0);
    //  });
    });



  }
  toggleBodyChartAnatomyDetailState(): void {
    this.bodyChartAnatomyDetailState = this.bodyChartAnatomyDetailState === 'out' ? 'in' : 'out';
  }

  showBackImage() {


    $("#baseb").show().animate({"opacity":"1"}, 100);
    $("#basea").hide().animate({"opacity":"0"}, 100);
    $("#base-skeleton").hide().animate({"opacity":"0"}, 100);
      //$("#base-skeleton").hide().animate({"opacity":"0"}, 100);
  }

  showFrontImage() {


      $("#baseb").hide().animate({"opacity":"0"}, 100);
      $("#basea").show().animate({"opacity":"1"}, 100);
      $("#base-skeleton").hide().animate({"opacity":"0"}, 100);


  }

  anatomySelected(bodyChartAnatomy: any) {


    const anaId = 'ana' + bodyChartAnatomy.bodyChartAnatomyDiagramId;


    let selectedBodyPartInfo = {

      bodyChartAnatomyBodyPart: $("#" + anaId).attr("class"),
      id: bodyChartAnatomy.bodyChartAnatomyDiagramId,
      bodyChartAnatomyNotes: '',
      bodyChartAnatomyPainScore: 0,
      bodyChartAnatomiesDiagramId: bodyChartAnatomy.bodyChartAnatomyDiagramId,
      duration: 0,
      hourlySymptoms: 0,
      description: null,
      aggs: bodyChartAnatomy.aggs,
      eases: bodyChartAnatomy.eases,
      isExisting: false,
      bodyChartDetailId: 0,
      hasFollowUpData: false,
    }





    if (!this.existingPainScoreData || (this.existingPainScoreData && !this.isFollowUp)) {

      if ($('#' + anaId).attr('data-selected') !== '1') {

        $('#' + anaId).css({'fill': 'rgba(255, 200, 0, 0.7)'});
        $('#' + anaId).attr('data-selected', '1');
      }

      if (bodyChartAnatomy.isExisting) {

        this.selectedPainScore = bodyChartAnatomy.painScore;

        selectedBodyPartInfo.bodyChartAnatomyPainScore = bodyChartAnatomy.painscore;
        selectedBodyPartInfo.isExisting = true
        selectedBodyPartInfo.bodyChartDetailId = bodyChartAnatomy.bodyChartDetailId;
        selectedBodyPartInfo.description = bodyChartAnatomy.description;
        selectedBodyPartInfo.hourlySymptoms = bodyChartAnatomy.hourlySymptoms;
        selectedBodyPartInfo.duration = bodyChartAnatomy.duration;
        selectedBodyPartInfo.bodyChartAnatomyNotes = bodyChartAnatomy.bodyChartAnatomyNotes;


      }
      this.selectedBodyPartInfo = selectedBodyPartInfo;
      this.toggleBodyChartAnatomyDetailState();

    }
    else {

      if (bodyChartAnatomy.isExisting) {
        this.selectedPainScore = bodyChartAnatomy.painScore;
        selectedBodyPartInfo.isExisting = true

        selectedBodyPartInfo.bodyChartAnatomyPainScore = bodyChartAnatomy.painscore;

        if (bodyChartAnatomy.hasFollowUpData) {
          selectedBodyPartInfo.hasFollowUpData = bodyChartAnatomy.hasFollowUpData;
          selectedBodyPartInfo.bodyChartAnatomyNotes = bodyChartAnatomy.bodyChartAnatomyNotes;
          selectedBodyPartInfo.bodyChartDetailId = bodyChartAnatomy.bodyChartDetailId;

        }
        this.toggleBodyChartAnatomyDetailState();
      }

    }

    this.selectedBodyPartInfo = selectedBodyPartInfo;

    $('#appliationmenuTogglebtn').hide();






    //this.selectedBodyPartInfo.bodyChartAnatomyBodyPart  = $("#" + anaId).attr("class");
   // this.selectedBodyPartInfo.id  = bodyChartAnatomy.bodyChartAnatomyDiagramId;


    ///   _self.toggleBodyChartAnatomyDetailState();

  }



  anaaddEvent(id,relationId): void {
    let _self = this;
    var arr = id.split("");
    var _obj = $("#" + id + "," + arr.slice().join(""));
    _obj.attr({ "stroke":_self.anaconfig.default.outlineColor});
    _obj.attr({"cursor": "default"});
    if (_self.anaconfig[id].enabled === true) {
      _obj.attr({"cursor": "pointer"});
      _obj.hover(function () {
      //  alert($("#" + id).attr("data-selected"));
        if ($("#" + id).attr("data-selected") !== "1") {
         /// alert('here');
          $("#anatip").show().html(_self.anaconfig[id].hover);
          _obj.css({"fill": "rgba(255, 0, 0, 0.3)"});
        }
      }, function () {
        $("#anatip").hide();
        if ($("#" + id).attr("data-selected") !== "1") {
          $("#" + id).css({"fill": "rgba(255, 0, 0, 0)"});
        }
      });
      if (_self.anaconfig[id].target !== "none") {
      /*  _obj.mousedown(function (event) {
          if ($("#" + id).attr("data-selected") !== "1") {
            $("#" + id).css({"fill": "rgba(255, 200, 0, 0.7)"});
            $("#" + id).attr("data-selected", "1");



            let selectedBodyPartInfo = {

              bodyChartAnatomyBodyPart: $("#" + id).attr("class"),
              id: id,
              bodyChartAnatomyNotes: '',
              bodyChartAnatomyPainScore: 0
            }

            _self.selectedBodyPartInfo.bodyChartAnatomyBodyPart  = $("#" + id).attr("class");
            _self.selectedBodyPartInfo.id  = id;

            _self.toggleBodyChartAnatomyDetailState();
            $('#appliationmenuTogglebtn').hide();
            ///   _self.toggleBodyChartAnatomyDetailState();
          }
          else {
            $("#" + id).attr("data-selected", "0");
            $("#" + id).css({"fill":"rgba(255, 0, 0, 0.7)"});
          }
        });*/
      }
      _obj.mouseup(function () {
        //  jQuery("#" + id).css({"fill":"rgba(255, 0, 0, 0.3)"});
        // if (anaconfig[id].target === "new_window") {
        //   window.open(anaconfig[id].url);
        // } else if (anaconfig[id].target === "same_window") {
        // //    window.parent.location.href = anaconfig[id].url;
        //   } else if (anaconfig[id].target === "modal") {
        //    jQuery(anaconfig[id].url).modal("show");
        //  }
      });
      _obj.mousemove(function (e) {
        var x = e.pageX + 10, y = e.pageY + 15;
        var tipw =$("#anatip").outerWidth(), tiph =$("#anatip").outerHeight(),
          x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw - (20 * 2) : x ;
        y = (y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() - tiph - 10 : y ;
        $("#anatip").css({left: x, top: y});
      });
      if (_self.isTouchEnabled()) {
        _obj.on("touchstart", function (e) {
          var touch = e.originalEvent.touches[0];
          var x = touch.pageX + 10, y = touch.pageY + 15;
          var tipw =$("#anatip").outerWidth(), tiph =$("#anatip").outerHeight(),
            x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw -(20 * 2) : x ;
          y =(y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() -tiph - 10 : y ;
          $("#" + id).css({"fill":"rgba(255, 0, 0, 0.7)"});
          $("#anatip").show().html(this.anaconfig[id].hover);
          $("#anatip").css({left: x, top: y});
        });
        _obj.on("touchend", function () {
          $("#" + id).css({"fill":"rgba(255, 0, 0, 0)"});
          if (this.anaconfig[id].target === "new_window") {
            window.open(this.anaconfig[id].url);
          } else if (this.anaconfig[id].target === "same_window") {
            window.parent.location.href = this.anaconfig[id].url;
          } else if (this.anaconfig[id].target === "modal") {
            $(this.anaconfig[id].url).modal("show");
          }
        });
      }
    }
  }

   isTouchEnabled() {
    return (("ontouchstart" in window)
      || (navigator.maxTouchPoints > 0)
      || (navigator.maxTouchPoints > 0));
  }


  findAllBodyChartAnatomiesDiagram(): void {

    let _self = this;

    this.bodyChartDetailAnatomiesDiagramService.findAllBodyChartAnatomiesDiagram().subscribe(response => {

      if (response.status === HttpStatusCode.Ok) {

        let bodyChartAnatomyDiagramIds = _self.existingPainScoreData.bodyChartDetailResponseDTO.map(existingFilter => {

                 return existingFilter.bodyChartAnatomyDiagramId;

        });



        this.bodyChartAnatomiesFront = response.body.filter(bodyChartAnatomyDiagram => bodyChartAnatomyDiagram.bodyChartAnatomyDiagramId  < 11);

        this.bodyChartAnatomiesFront = this.bodyChartAnatomiesFront.map(bodyChartAnatomyDiagramFront => {

          bodyChartAnatomyDiagramFront.fill = "transparent";
          bodyChartAnatomyDiagramFront.stroke = "#FF0000";
          bodyChartAnatomyDiagramFront.opacity = 0.5;
          bodyChartAnatomyDiagramFront.isExisting = false;
          bodyChartAnatomyDiagramFront.painScore = 0;
          bodyChartAnatomyDiagramFront.selected  = 0;

          if (_self.existingPainScoreData ) {

            if (_self.isFollowUp) {

              bodyChartAnatomyDiagramFront.fill = "grey";
              bodyChartAnatomyDiagramFront.stroke = "grey";
            }



              if (bodyChartAnatomyDiagramIds.includes(bodyChartAnatomyDiagramFront.bodyChartAnatomyDiagramId)) {


                      let existingPainScoreItem = _self.existingPainScoreData.bodyChartDetailResponseDTO.filter(existingFilter => existingFilter.bodyChartAnatomyDiagramId ===
                            bodyChartAnatomyDiagramFront.bodyChartAnatomyDiagramId);

              bodyChartAnatomyDiagramFront.isExisting = true;
              bodyChartAnatomyDiagramFront.painScore = existingPainScoreItem[0].bodyChartAnatomyPainScore;
              bodyChartAnatomyDiagramFront.fill = _self.painScoreColours[existingPainScoreItem[0].bodyChartAnatomyPainScore-1];
              bodyChartAnatomyDiagramFront.duration = existingPainScoreItem[0].duration;
              bodyChartAnatomyDiagramFront.hourlySymptoms = existingPainScoreItem[0].hourlySymptoms;
              bodyChartAnatomyDiagramFront.description = existingPainScoreItem[0].description;
              bodyChartAnatomyDiagramFront.aggs = existingPainScoreItem[0].aggs;
              bodyChartAnatomyDiagramFront.eases = existingPainScoreItem[0].eases;
              bodyChartAnatomyDiagramFront.stroke = "#FE6847";
              bodyChartAnatomyDiagramFront.selected  = 1;
              bodyChartAnatomyDiagramFront.bodyChartDetailId = existingPainScoreItem[0].bodyChartDetailId;
              bodyChartAnatomyDiagramFront.bodyChartAnatomyNotes = existingPainScoreItem[0].bodyChartAnatomyNotes;
              bodyChartAnatomyDiagramFront.hasFollowUpData = existingPainScoreItem[0].hasFollowUpData;

            }
          }



          return bodyChartAnatomyDiagramFront;


        })



      }


    })


  }

  updateInfo(): void {


    const serializer = new XMLSerializer();

    let arrayOfAnatomy = JSON.parse(this.bodyAnatomyInformationInput.nativeElement.value);
    let latestAnatomy = arrayOfAnatomy.pop();

    if (latestAnatomy.bodyChartAnatomyBodyPart.includes("back") ) {

      const svgAnatomyBackImageAsString = serializer.serializeToString(document.getElementById('svg-image-back'));
      this.bodyAnatomyBackImage.emit(svgAnatomyBackImageAsString);

    }
    else {
      const svgAnatomyFrontImageAsString = serializer.serializeToString(document.getElementById('svg-image-front'));
      this.bodyAnatomyFrontImage.emit(svgAnatomyFrontImageAsString);

    }
          this.onBodyAnatomyInformationChanged.emit(this.bodyAnatomyInformationInput.nativeElement.value);
  }

  anatomyPainScore($emittedData) {

    let id = this.selectedBodyPartInfo.id;

    $("#" + "ana" + id).css({"fill": $emittedData});
    $("#" +"ana"  + id).css({"opacity": 0.5});
    $("#" + "ana" + id).css({"stroke": $emittedData});

  }

  anatomyDetailsFormSubmitted($emittedData) {

    $('#appliationmenuTogglebtn').show();

    if ($emittedData === null) this.toggleBodyChartAnatomyDetailState();



    let selectedBodyPartInfo = $emittedData.selectedBodyPartInfo;
    let bodyChartAnatomyDetailsForm = $emittedData.bodyChartAnatomyDetailsForm;

    let fieldsToCheckForChanges = ['bodyChartAnatomyPainScore', 'duration', 'hourlySymptoms','bodyChartAnatomyNotes'];
    let previousValue = this.existingPainScoreData.bodyChartDetailResponseDTO.find(x => x.bodyChartAnatomyDiagramId === $emittedData.selectedBodyPartInfo.bodyChartAnatomiesDiagramId);


    let changedFields = [];
    /* this part is for ediiting when not a follow up */
    if ($emittedData.selectedBodyPartInfo.isExisting && !this.isFollowUp) {

      fieldsToCheckForChanges.forEach(field => {

        if (field === 'bodyChartAnatomyPainScore' ) {
          if (previousValue[field]  !== $emittedData.selectedBodyPartInfo[field] && $emittedData.selectedBodyPartInfo[field] !== undefined) {

            alert('change ' +  $emittedData.selectedBodyPartInfo[field] + ' === ' + previousValue[field])
            changedFields.push({fieldName: field, newValue: $emittedData.selectedBodyPartInfo[field]});

          ///  changedFields.push(field);
          }
        }
        else {


          if (field === 'bodyChartAnatomyNotes') {

            if ($emittedData.bodyChartAnatomyDetailsForm.controls[field].value  !== previousValue[field] ) {
              changedFields.push({fieldName: field, newValue: $emittedData.bodyChartAnatomyDetailsForm.controls[field].value});

              alert('bodyChartAnatomyNotes ' + field);
            }
          }
          else {

            if ($emittedData.bodyChartAnatomyDetailsForm.controls[field].value  !== previousValue[field][0].listOfValuesMasterId ) {
              changedFields.push({fieldName: field, newValue: $emittedData.bodyChartAnatomyDetailsForm.controls[field].value});

              alert('change form ' + field);


            }

          }


        }



      });

     /* for (let [key, value] of Object.entries(this.existingPainScoreData.bodyChartDetailResponseDTO[0])) {

        if (fieldsToCheckForChanges.includes(key)) {

          alert(key)
          alert(value + ' === ' + $emittedData.selectedBodyPartInfo[key]);

          if (JSON.stringify(value) !== JSON.stringify($emittedData.selectedBodyPartInfo[key]) && $emittedData.selectedBodyPartInfo[key] !== undefined) {

            alert(key + 'changed' + value + ' ' + $emittedData.selectedBodyPartInfo[key]);
          }

        }
      }*/


    //  let previousValue = this.existingPainScoreData.bodyChartDetailResponseDTO.find(x => x.bodyChartAntomyDiagramId === $emittedData.selectedBodyPartInfo.bodyChartAntomyDiagramId);





      let dataToEmit = {

        isEdit: true,
        changedFields: changedFields,
        bodyChartDetailId: $emittedData.selectedBodyPartInfo.bodyChartDetailId,
        objectMultiSelects: $emittedData.objectMultiSelects,


      };


      this.onBodyAnatomyInformationChanged.emit(dataToEmit);


    } else {


      /* this part is for when it is a follow up */
      if (this.isFollowUp) {

        /* by simply adding the has follow up Data to the  object we can check and use in the code where this is emitted to */
        let followUpData = {
            bodyChartAnatomyPainScore: $emittedData.selectedBodyPartInfo.bodyChartAnatomyPainScore,
            bodyChartAnatomyNotes: $emittedData.bodyChartAnatomyDetailsForm.controls['bodyChartAnatomyNotes'].value,
            bodyChartAnatomiesDiagramId: $emittedData.selectedBodyPartInfo.bodyChartAnatomiesDiagramId,
            hasFollowUpData: $emittedData.selectedBodyPartInfo.hasFollowUpData,
            bodyChartDetailId: $emittedData.selectedBodyPartInfo.bodyChartDetailId,

        }

        this.onBodyAnatomyInformationChanged.emit(followUpData);


      }
      /* this part saving of a new body part editing or not */
      else {
        this.onBodyAnatomyInformationChanged.emit($emittedData);

      }
    }

    this.emitCanvasImages($emittedData.selectedBodyPartInfo);


    this.selectedBodyPartInfo = {

      bodyChartAnatomyBodyPart: '',
      id: 0,
      bodyChartAnatomyNotes: '',
      bodyChartAnatomyPainScore: 0,
      bodyChartAnatomiesDiagramId: 0,

    };
    this.toggleBodyChartAnatomyDetailState();

  }

  painScore(score, colour) {

    this.selectedBodyPartInfo.bodyChartAnatomyPainScore = score;
    let id = this.selectedBodyPartInfo.id;
    $("#" + id).css({"fill": colour});
    $("#" + id).css({"opacity": 0.5});
    $("#" + id).css({"stroke": colour});
  }

  emitCanvasImages(selectedBodyPartInfo) {

    const serializer = new XMLSerializer();
    if (selectedBodyPartInfo.bodyChartAnatomyBodyPart.includes("back") ) {
      $(".goa").css({"display": 'none'});
      const svgAnatomyBackImageAsString = serializer.serializeToString(document.getElementById('svg-image-back'));
      this.bodyAnatomyBackImage.emit(svgAnatomyBackImageAsString);
      $(".goa").css({"display": 'block'});
    }
    else if(selectedBodyPartInfo.bodyChartAnatomyBodyPart.includes("front")) {
      $(".gob").css({"display": 'none'});
      const svgAnatomyFrontImageAsString = serializer.serializeToString(document.getElementById('svg-image-front'));
      this.bodyAnatomyFrontImage.emit(svgAnatomyFrontImageAsString);
      $(".gob").css({"display": 'block'});

    }
    else {
      $(".go-skeleton").css({"display": 'none'});
      const svgAnatomySkeletonImageAsString = serializer.serializeToString(document.getElementById('svg-image-skeleton'));
      this.bodyAnatomySkeletonImage.emit(svgAnatomySkeletonImageAsString);
      $(".go-skeleton").css({"display": 'block'});
    }
  }


  async renderSvg() {

    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');


    const svgImageFront = document.getElementById('svg-image');
   /// const backImage = svgImageFront.removeChild('back-image');

    //let throwawayNode = d.removeChild(d_nested);

    const s = new XMLSerializer();
    const str = s.serializeToString(svgImageFront);



    console.log(str);
    const v = await Canvg.from(ctx, str);
    v.start();
    await v.ready();
    const canvas2 = document.querySelector('canvas');
    const _self = this;

    canvas2.toBlob(async function(blob) {
      const file = await new File([blob], 'front.png', {
        type: 'image/png',
      });

      console.log(file);
      const url = _self.sharedService.uploadFile(file);
      url.subscribe(res => {
        alert('done');

      });
      // Run code
    });


  }

  async getCanvasBlob(canvas) {
    return new Promise(async function(resolve, reject) {
      canvas.toBlob(async function(blob) {
        const file =  new File([blob], 'front.png', {
          type: 'image/png',
        });
        resolve(file);
      });
    });
  }
}
