import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CaseService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient) {
  };

  addCaseForPatient(patientId,userId): Observable<string> {

    let patientCase = {
      patientId: patientId,
      userId: userId,
      notes: "these are test notes"
    }

    let data = [patientCase];

    return this.httpClient.post<string>(Constant.BASE_URL + 'Case/addCase', data, this.httpOptions).pipe(
      retry(3), catchError(Constant.handleError));
  }

  addCaseForPatientCpa(addCaseDTO: any): Observable<any> {

    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'cases', addCaseDTO, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));
  }

  getReviewByCaseId(caseId: number): Observable<any> {

    alert(Constant.CPA_SPRING_API_URL + 'cases/' + caseId + '/review');

    return this.httpClient.get<any>(Constant.CPA_SPRING_API_URL + 'cases/' + caseId + '/review', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(3), catchError(Constant.handleError));

  }

  updateCase(casePatch: any, caseId): Observable<any> {
    console.log(casePatch);
    return this.httpClient.patch<string>(Constant.CPA_APPOINTMENTS_SERVICE + 'case/' + caseId, casePatch, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/merge-patch+json'),
      observe: 'response'
    }).pipe(
      retry(1), catchError(Constant.handleError));


  }

}

