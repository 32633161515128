import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TreatmentPrescribedService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient) {
  };


  findAllTreatmentsPrescribed(): any {
    return this.httpClient.get<string[]>(
      Constant.CPA_SPRING_API_URL + 'treatments-prescribed', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }
}

