import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(    private router: Router,

  ) { }

  @Input() quickLinks: any;
  ngOnInit(): void {
  }

  navigateToPage(application, category_url) {

    this.router.navigate([category_url]);

  }

  logout() {

    this.router.navigate(['logout']);
  }

}

