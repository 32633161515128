<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameListOfValues)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">

                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn"
                                    icon="pi pi-search-plus" (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                    pButton pTooltip="Advanced Search" tooltipPosition="bottom" type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                    (click)="showHideAddDialog()" [hidden]="showAddBtn" pButton
                                    pTooltip="{{addEditTitle}} {{translate(labels.entityNameListOfValues)}}"
                                    tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
                        <!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameListOfValues)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="listOfValuesSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnCategoryId)}} </label>
                                            <p-dropdown [filter]="true" [options]="categoriesList" [showClear]="true"
                                                class="pngDropdown" formControlName="categoryId" placeholder="Select">
                                            </p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnValue)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('value')"
                                                    *ngIf="diplayeIconInField('value')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="value" name="value" pInputText type="text"> </span>
                                        </div>
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnLabel)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('label')"
                                                    *ngIf="diplayeIconInField('label')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="label" name="label" pInputText type="text"> </span>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnUserId)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                formControlName="userId" mode="decimal" name="userId" pNumberInput
                                                type="number"></p-inputNumber>
                                        </div> -->

                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()" styleClass="p-button"
                                            class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                        <!-- Add DialogBox  -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }"
                            class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{translate(labels.btnAdd)}} {{translate(labels.entityNameListOfValues)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="listOfValuesForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnCategoryId)}}</label>
                                            <p-dropdown [filter]="true" [options]="categoriesList" [showClear]="true"
                                                class="pngDropdown" formControlName="categoryId" placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'categoryId'" [form]="listOfValuesForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnValue)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('value')"
                                                    *ngIf="diplayeIconInField('value')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('value')" class="pngInputtext"
                                                    formControlName="value" name="value" pInputText pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'value'" [form]="listOfValuesForm">
                                            </app-custom-error>
                                        </div>
                                        <div>
                                          <p-checkbox label='Part of Initial Assessment' formControlName="isPartOfInitialAssessment" [value]="true" [binary]="true"></p-checkbox>

                                        </div>

                                      <div>
                                        <p-checkbox label='Part of Follow Up' formControlName="isPartOfFollowUp" [value]="true" [binary]="true"></p-checkbox>

                                      </div>
                                        <div>
                                          <br/>
                                          <p-checkbox label='Red Flag' formControlName="isRedFlag" [value]="true" [binary]="true"></p-checkbox>
                                          <p-checkbox label='Orange Flag' formControlName="isOrangeFlag" [value]="true" [binary]="true"></p-checkbox>
                                          <p-checkbox label='Blue Flag' formControlName="isBlueFlag" [value]="true" [binary]="true"></p-checkbox>
                                          <p-checkbox label='Yellow Flag' formControlName="isYellowFlag" [value]="true" [binary]="true"></p-checkbox>
                                          <p-checkbox label='Green Flag' formControlName="isGreenFlag" [value]="true" [binary]="true"> </p-checkbox>
                                        </div>
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnLabel)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('label')"
                                                    *ngIf="diplayeIconInField('label')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('label')" class="pngInputtext"
                                                    formControlName="label" name="label" pInputText pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'label'" [form]="listOfValuesForm">
                                            </app-custom-error>
                                        </div> -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.listOfValuesColumnUserId)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('userId')"
                                                [useGrouping]="false" class="p-invalid pngInputnumber"
                                                formControlName="userId" mode="decimal" name="userId" pNumberInput
                                                type="number"></p-inputNumber>
                                            <app-custom-error [control]="'userId'" [form]="listOfValuesForm">
                                            </app-custom-error>
                                        </div> -->
                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button
                                            label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                            type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()" styleClass="p-button"
                                            class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                        <!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <!-- <span class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search"></i>
                                        <input
                                            class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" />
                                        </span> -->

                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)"
                                    [autoLayout]="true"
                                    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                    [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                    [alwaysShowPaginator]="false" [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                    [showCurrentPageReport]=true [totalRecords]="totalRecords"
                                    [value]="listOfValuesList" class="pngTable"
                                    [ngClass]="{'pngTableEmptyMessage': listOfValuesList?.length == 0}"
                                    id="invitationpage-memberslist-table" styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Category</th>
                                            <th> Value</th>
                                            <!-- <th> Label</th> -->
                                            <!-- <th> UserId</th> -->
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.categoryId.categoryTitle}} </td>
                                            <td> {{element?.value}} </td>
                                            <!-- <td> {{element?.label}} </td>
                                            <td> {{element?.userId}} </td> -->
                                            <td>

                                                <div class="pngTable-icons">
                                                    <p-button
                                                        (click)="loadListOfValuesToEdit(element.listOfValuesId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdListOfValues(element.listOfValuesId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <!-- <p-button icon="pi pi-eye" pTooltip="View"
                                                        styleClass="p-button pbtn-transparent"></p-button> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <!-- <ng-template pTemplate="emptymessage">
                                        {{translate(labels.mat_pagination_no_data_msg)}}</ng-template> -->
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No
                                        Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
