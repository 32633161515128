import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../web-services/shared.service';
import {Router} from '@angular/router';
import {FollowUpSteps} from '../../uiUtils/FollowUpSteps';
import {Session} from 'protractor';
import {SessionService} from '../../services/session.service';


@Component({
  selector: 'app-testing',
  templateUrl: './follow-up-testing.component.html',
  styleUrls: ['./follow-up-testing.component.css']
})
export class FollowUpTestingComponent implements OnInit {

  appointmentId: number;
  caseId: number;
  patientId: number;
  expertId: number;
  fullAppointment: any;
  patientObj: any;
  stepsItems: any;
  activeStep: number;
  existingBodyPartData: any = [];
  chosenBodyChartAnatomyDiagramId: any;


  constructor(private sessionService: SessionService,
              private router: Router,

              ) { }

  ngOnInit(): void {

    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));
    this.fullAppointment = JSON.parse(this.sessionService.getSession('appointment'));
    this.patientObj = JSON.parse(this.sessionService.getSession('patientObj')) ;

    this.existingBodyPartData = JSON.parse(this.sessionService.getSession("existingBodyPartData"));

    console.info(this.existingBodyPartData[0]);


    this.stepsItems = FollowUpSteps.stepItems;
    this.activeStep = parseInt(this.stepsItems.find(step => step.label === 'Testing').number);

  }

  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }

  bodyPartChanged(id) {

      this.chosenBodyChartAnatomyDiagramId =  id;

  }

}
