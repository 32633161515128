import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MenuItem, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ClientDetailsService} from '../web-services/client-details.service';
import {UsersService} from '../web-services/users.service';
import { ConstantPool } from '@angular/compiler';
import { PatientsService } from '../web-services/patients.service';
import { SessionService } from '../services/session.service';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { Utils } from '../util/utils';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-client-details', templateUrl: './client-details.component.html', styleUrls: ['./client-details.component.css']})
export class ClientDetailsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateClientDetailsId = null;
    usersList: any = [];
    clientDetailsList: any = [];
    clientDetailsSearch: FormGroup;
    patientsSearchForm: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    clientDetailsForm: FormGroup;
    constantList = [];
    genderArray = [];
    utils = new Utils();
    pageNo: number = 0;
    onValueChange = true;
    loggedInUserType
    loggedInRefId
    loggedInUser
    patientId
    expertId
    appointmentId
    patientList
    totalPatients
    showSearchDialog = false;
    showSearchBtn = true;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';
    activeStep = 0;
    stepsItems: MenuItem[];
    addEditTitle = '';
    urlCalled = false;
    stepper = true;
    patientObj: any;



  constructor(
        private clientDetailsServices: ClientDetailsService,
        private usersServices: UsersService,
        private sessionService: SessionService,
        private patientsServices: PatientsService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private lovService : ListOfValuesService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private router: Router

  ) {
    };



    get addUpdateTitle() {
        return this.updateClientDetailsId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.clientDetailsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countClientDetails();

        this.showHideSearchDialog()
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countClientDetails() {
        this.clientDetailsServices.countClientDetails(this.clientDetailsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllClientDetails();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        // debugger
        this.clientDetailsForm.controls['expertId'].setValue(this.expertId);
        this.clientDetailsForm.controls['userId'].setValue(this.loggedInUser);
        this.clientDetailsForm.controls['patientId'].setValue(this.patientId);
        this.clientDetailsForm.controls['appointmentId'].setValue(this.appointmentId);
        this.clientDetailsForm.controls['patientContact'].setValue(""+this.clientDetailsForm.controls['patientContact'].value);
        if(this.clientDetailsForm.valid){
        this.addUpdateClientDetails(this.clientDetailsForm.value);
        this.submitted = false;
        this.onClearAdd();
        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllClientDetails();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.clientDetailsSearch.reset();
        this.countClientDetails();
        // this.findAllClientDetails();
    }

    addUpdateClientDetails(clientDetails) {
        this.startLoader();
        let obj =this.utils.getUpdatedMillissecondObject(this.clientDetailsForm.value,"dateOfAccident");
        if (this.updateClientDetailsId == null) {
            this.clientDetailsServices.addClientDetails(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    clientDetails = res['data'][0];
                    this.clientDetailsList.push(clientDetails);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameClientDetails) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameClientDetails)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countClientDetails();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.clientDetailsId = this.updateClientDetailsId;
            this.clientDetailsServices.updateClientDetails(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.clientDetailsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameClientDetails) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameClientDetails)
                                            });
                    this.showSearchBtn = true;
                    if(this.clientDetailsList.length > 0){
                        this.showAddBtn = true;
                    }else{
                        this.showAddBtn = false;
                    }

                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countClientDetails();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateClientDetailsId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        if (this.route.url.includes('/client_details')) {
            this.urlCalled = true;
        }
        this.showAddBtn = false;
        this.showSearchBtn = true;
        // debugger
     this.loggedInUser = parseInt(this.sessionService.getSession('loggedInUser'));
     this.expertId = parseInt(this.sessionService.getSession('expertId'));
     this.patientId = parseInt(this.sessionService.getSession('patientId'));
     this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));

     this.getAllLov();

        this.addEditTitle = this.labels.btnAdd;
        // this.findAllUsers();
        // this.findAllUsers();
        this.clientDetailsForm = new FormGroup({
                                                   dateOfAccident: new FormControl('',[Validators.required]),
                                                   title: new FormControl('', [Validators.required, validateSpaces]),
                                                   patientName: new FormControl('', [Validators.required, validateSpaces]),
                                                   patientAddress: new FormControl('', [Validators.required, validateSpaces]),
                                                   patientEmail: new FormControl('', [Validators.required, validateSpaces]),
                                                   patientContact: new FormControl('', [Validators.required, Validators.min(0)]),
                                                   patientGender: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                   patientId: new FormControl(),
                                                   appointmentId: new FormControl(),
                                                   expertId: new FormControl(this.expertId),
                                                   userId: new FormControl(this.loggedInUser, [Validators.required, Validators.min(0)]),
                                               });
        this.clientDetailsSearch = new FormGroup({
                                                     dateOfAccident: new FormControl(null),
                                                     title: new FormControl(null),
                                                     patientName: new FormControl(null),
                                                     patientAddress: new FormControl(null),
                                                     patientEmail: new FormControl(null),
                                                     patientContact: new FormControl(null),
                                                     patientGender: new FormControl(null),
                                                     patientId: new FormControl(this.patientId),
                                                     appointmentId: new FormControl(this.appointmentId),
                                                     expertId: new FormControl(this.expertId),
                                                     userId: new FormControl(this.loggedInUser),
                                                 });


        this.patientsSearchForm = new FormGroup({
            userId: new FormControl(),
            firstName: new FormControl(),
            lastName: new FormControl(),
            dob: new FormControl(),
            sex: new FormControl(),
            genderIdentity: new FormControl(),
            occupation: new FormControl(),
            emergencyContact: new FormControl(),
            consent: new FormControl(),
            email: new FormControl(),
            address: new FormControl(),
            commPrefs: new FormControl(),
            marketingInfo: new FormControl(),
            billing: new FormControl(),
            patientType: new FormControl(),
            conscessionType: new FormControl(),
            invoice: new FormControl(),
            invoiceInfo: new FormControl(),
            insuranceNumber: new FormControl(),
            uniqueRefNumber: new FormControl(),
            referralSource: new FormControl(),
            isSystemUser: new FormControl(),
            patientUserId: new FormControl(),
            companyId: new FormControl(),
            agencyId: new FormControl(),
            status: new FormControl(),
            userEmail : new FormControl(),
            password : new FormControl()
        });
          this.findPatientsById();
       // this.countClientDetails();
     //   this.findAllClientDetails();
        this.getConstantList(1234);

        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.clientDetailsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });

      this.stepsItems = [{
          label: 'Patient Details'
      },
        {
          label: 'History'
        },
        {
          label: 'Body Chart'
        },
        {
          label: 'Observations'
        },
        {
          label: 'Testing'
        },
        {
          label: 'Treatment'
        },
      ];


      if (this.router.url.includes('/testing')) {
        this.stepper = false;
        this.activeStep = 4;
      } else if (this.router.url.includes('/history')) {
        this.stepper = false;
        this.activeStep = 1;
      } else if (this.router.url.includes('/body_chart')) {
        this.stepper = false;
        this.activeStep = 2;
      } else if (this.router.url.includes('/observations')) {
        this.stepper = false;
        this.activeStep = 3;
      } else if (this.router.url.includes('/treatment')) {
        this.stepper = false;
        this.activeStep = 5;
      }else if (this.router.url.includes('/client_details')) {
        this.stepper = false;
        this.activeStep = 0;
      }
    }

  findPatientsById() {
    this.startLoader();
    this.patientsServices.findPatientsById(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {
      if (res['message'] == 'Success') {
        this.patientObj = res['data'][0];
        console.log(this.patientObj, 'patient object');
      } else {
        console.error('error: ', res['documentation']);
      }
      this.stopLoader();
    }, err => {
      console.error('err: ', err);
      this.stopLoader();
    });
  }



    findAllClientDetails() {
        this.startLoader();

        this.clientDetailsServices.findAllClientDetails(this.clientDetailsSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.clientDetailsList = res['data'];
                    this.showAddBtn = true;
                } else {
                    this.clientDetailsList = [];
                    if(this.clientDetailsList.length > 0){
                        this.showAddBtn = true;
                    }else{
                        this.showAddBtn = false;
                    }
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdClientDetails(clientDetailsId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.clientDetailsServices.deleteByIdClientDetails(clientDetailsId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.clientDetailsList.splice(index, 1);
                        if(this.clientDetailsList.length > 0){
                            this.showAddBtn = true;
                        }else{
                            this.showAddBtn = false;
                        }
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameClientDetails) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameClientDetails)
                                                });
                        this.countClientDetails();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
                                  if(this.clientDetailsList.length > 0){
                                    this.showAddBtn = true;
                                }else{
                                    this.showAddBtn = false;
                                }
    }

    loadClientDetailsToEdit(clientDetailsId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let clientDetails = this.clientDetailsList.find(x => x['clientDetailsId'] == clientDetailsId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateClientDetailsId = clientDetails['clientDetailsId'];
        this.selectedIndex = index;
        this.clientDetailsForm.controls['dateOfAccident'].setValue(new Date(clientDetails['dateOfAccident']));
        this.clientDetailsForm.controls['title'].setValue(clientDetails['title']);
        this.clientDetailsForm.controls['patientName'].setValue(clientDetails['patientName']);
        this.clientDetailsForm.controls['patientAddress'].setValue(clientDetails['patientAddress']);
        this.clientDetailsForm.controls['patientEmail'].setValue(clientDetails['patientEmail']);
        this.clientDetailsForm.controls['patientContact'].setValue(clientDetails['patientContact']);
        this.clientDetailsForm.controls['patientGender'].setValue(clientDetails['patientGender']['listOfValuesId']);
      //  this.clientDetailsForm.controls['patientId'].setValue(clientDetails['patientId']['patientId']);
      //  this.clientDetailsForm.controls['expertId'].setValue(clientDetails['expertId']['expertId']);
        this.clientDetailsForm.controls['userId'].setValue(clientDetails['userId']);
        this.showHideUpdateDialog();
    }

    onClearAdd() {
        this.clientDetailsForm.reset();
        this.clientDetailsForm.markAsUntouched();
        this.updateClientDetailsId = null;
        closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.clientDetailsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.clientDetailsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.clientDetailsForm.get(control)?.valid && this.clientDetailsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.clientDetailsForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.clientDetailsSearch.reset();
    }

    refresh() {
        this.countClientDetails();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.clientDetailsForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
       // this.showSearchBtn = this.showSearchDialog;
       if(this.clientDetailsList.length > 0){
        this.showAddBtn = true;
    }else{
        this.showAddBtn = false;
    }
    }

    showHideAddDialog() {
        // debugger
        if(!this.showAddDialog) {
            this.clientDetailsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        if(this.clientDetailsList.length > 0){
            this.showAddBtn = true;
        }else{
            this.showAddBtn = false;
        }
       // this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateClientDetailsId = null;
    }

    showHideUpdateDialog() {

        this.addEditTitle = this.labels.btnUpdated;
        this.showAddDialog = !this.showAddDialog;
        // this.showAddBtn = this.showAddDialog;
     //   this.showSearchBtn = this.showAddDialog;
        this.editTag = false;

    }

    getAllLov(){
        // Constant.PATIENTS_LOV_ITMES;
        this.startLoader();
        this.lovService.getAllListOfValuesByName(Constant.PATIENTS_LOV_ITMES).subscribe(result => {

            console.log("result of Lov values :: ",result);

            if(result['statusCode'] == 200){
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];

                    if(element[Constant.PATIENT_SEX]){
                        for (let object of element[Constant.PATIENT_SEX]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.genderArray.push(dropDown);
                        }
                    }
                }
                this.countClientDetails();
            }else{

            }

        });
    }

  urlChanged(number) {
    if (number === 0) {
      this.router.navigate(['patient-details']);
    } else if (number === 1) {
      this.router.navigate(['history']);
    } else if (number === 2) {
      this.router.navigate(['body_chart']);
    } else if (number === 3) {
      this.router.navigate(['observations']);
    } else if (number === 4) {
      this.router.navigate(['testing']);
    } else if (number === 5) {
      this.router.navigate(['treatment']);
    }
  }
  }
