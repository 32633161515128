import { Component, OnInit } from '@angular/core';
import {Constant} from '../../util/constant';
import {PatientsService} from '../../web-services/patients.service';
import {SessionService} from '../../services/session.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-corporate-dashboard',
  templateUrl: './corporate-dashboard.component.html',
  styleUrls: ['./corporate-dashboard.component.css']
})
export class CorporateDashboardComponent implements OnInit {

  displayBasic2: boolean;
  showCases: boolean = false;
  showInvoicing: boolean = false;
  showExp: boolean = false;
  showContacts: boolean = false;
  showClinics: boolean = false;
  showMainDashboard: boolean = false;

  patientList: any = [];
  physiotherapyPatients: any = [];
  psychologyPatients: any = [];
  activePatients;
  deActivatedPatients;
  totalPatients;
  loggedInUserType: any;
  loggedInRefId: any;
  patientsSearch: FormGroup;

  constructor(
      private patientsServices: PatientsService,
      private sessionService: SessionService,
      private loader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
    this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientsSearch = new FormGroup({
                                          userId: new FormControl(),
                                          firstName: new FormControl(),
                                          lastName: new FormControl(),
                                          dob: new FormControl(),
                                          sex: new FormControl(),
                                          genderIdentity: new FormControl(),
                                          occupation: new FormControl(),
                                          emergencyContact: new FormControl(),
                                          consent: new FormControl(),
                                          email: new FormControl(),
                                          address: new FormControl(),
                                          commPrefs: new FormControl(),
                                          marketingInfo: new FormControl(),
                                          billing: new FormControl(),
                                          patientType: new FormControl(),
                                          conscessionType: new FormControl(),
                                          invoice: new FormControl(),
                                          invoiceInfo: new FormControl(),
                                          insuranceNumber: new FormControl(),
                                          uniqueRefNumber: new FormControl(),
                                          referralSource: new FormControl(),
                                          isSystemUser: new FormControl(),
                                          patientUserId: new FormControl(),
                                          companyId: new FormControl(),
                                          agencyId: new FormControl(),
                                          status: new FormControl(),
                                          userEmail : new FormControl(),
                                          password : new FormControl()
                                        });
    this.findAllPatients();
  }

  findAllPatients() {
    this.startLoader();
    this.patientsServices.findAllPatients(this.patientsSearch.value, null, null,this.loggedInUserType,this.loggedInRefId).subscribe(
        res => {
          if (res['message'] == 'Success') {
            this.patientList = res['data'];
          } else {
            this.patientList = [];
          }
          this.stopLoader();
          this.getCounts();
        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });
  }
  getCounts(){
    this.physiotherapyPatients = this.patientList.filter(d => {
      if(d.patientType) {
        return d.patientType.value == 'Physiotherapy';
      }
    }).length;

    this.psychologyPatients = this.patientList.filter(d => {
      if(d.patientType) {
        return d.patientType.value == 'Psychology';
      }
    }).length;

    this.totalPatients = this.patientList.length;

    this.activePatients = this.patientList.filter(d => {
      if(d.status) {
        return d.status == 'Active';
      }
    }).length;

    this.deActivatedPatients = this.patientList.filter(d => {
      if(d.status) {
        return d.status == 'Deactivated';
      }
    }).length;
  }





  startLoader() {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }

  showBasicDialog2() {
    this.displayBasic2 = true;
  }
  selectDashboard(dashboardStr){
    this.showMainDashboard = true;
    switch(dashboardStr){
      case 'cases':
        this.showCases = true;
        break;
      case 'invoicing':
        this.showInvoicing = true;
        break;
      case 'expenses':
        this.showExp = true;
        break;
      case 'contacts':
        this.showContacts = true;
        break;
      case 'clinics':
        this.showClinics = true;
        break;

    }
  }

}
