import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {DrugHistoryService} from '../web-services/drug-history.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {InitialHistoryFormDTO} from "../dto/InitialHistoryFormDTO";
import {DrugHistoryMultiSelects} from "../uiUtils/pages/DrugHistoryMultiSelects";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";
import {HttpStatusCode} from '@angular/common/http';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
  selector: 'app-drug-history',
  templateUrl: './drug-history.component.html',
  styleUrls: ['./drug-history.component.css']
})
export class DrugHistoryComponent implements OnInit {
  public labels = Labels;
  formButton: string = this.translate(this.labels.btnAdd);
  submitted = false;
  selectedIndex = -1;
  updateDrugHistoryId = null;
  listOfValuesDetailList: any = [];
  drugHistoryList: any = [];
  booleanCategoryList: any = [];
  drugHistorySearch: FormGroup;
  clearSearchBtn = false;
  totalRecords;
  itemPerPage: number = Constant.ITEM_PER_PAGE;
  drugHistoryForm: FormGroup;
  constantList = [];
  pageNo: number = 0;
  onValueChange = true;

  showSearchDialog = false;
  showSearchBtn = false;
  showAddDialog = false;
  showAddBtn = false;
  editTag = false;
  disableColumn = false;
  passwordType = 'password';

  addEditTitle = '';
  resetTag = true;
  drugHistory = Constant.DRUG_LOV_DRUG_HISTORY;
  vaccinations = Constant.VACCINATIONS;
  drinks = Constant.DRINKS;
  recreation = Constant.RECREATION;
  smoke = Constant.SMOKING;
  caffeinatedDrink = Constant.CAFFEINATEDDRINK;


  vaccinationCategoryList: any = [];
  lifeStyleCategoryList: any = [];
  drinksCategoryList: any = [];
  smokeCategoryList: any = [];
  recreationCategoryList: any = [];
  drugsCategoryList: any = [];

  lovDataObject: any = {};
  expertId;
  patientId;
  appointmentId;
  caseId: number;

  constructor(
    private drugHistoryServices: DrugHistoryService,
    private listOfValuesDetailServices: ListOfValuesDetailService,
    private primengConfig: PrimeNGConfig,
    private confirmation: ConfirmationService,
    private messageService: MessageService,
    private route: Router,
    private loader: NgxUiLoaderService,
    private constantService: ConstantService,
    private sharedService: SharedService,
    private lovServices: ListOfValuesService,
    private sessionService: SessionService,
    private drugHistoryMultiSelects: DrugHistoryMultiSelects,

  ) {}

  responseData: any;
  refDataObj: any;
  objectMultiSelects: any;

  getResponse($event) {

    this.drugHistoryMultiSelects.getResponseObjects($event);
  }

  get addUpdateTitle() {
    return this.updateDrugHistoryId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
  }

  onClearSearch() {
    this.drugHistorySearch.reset();
    this.selectedIndex = -1;
  }

  onSearch() {
    this.clearSearchBtn = true;
    this.countDrugHistory();
    this.showHideSearchDialog();
  }

  startLoader() {
    this.loader.start();
    // setTimeout(() => {
    //     this.loader.stop();
    // }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }

  countDrugHistory() {
    this.drugHistorySearch.controls['expertId'].setValue(this.expertId);
    this.drugHistorySearch.controls['patientId'].setValue(this.patientId);
    this.drugHistorySearch.controls['appointmentId'].setValue(this.appointmentId);
    this.drugHistoryServices.countDrugHistory(this.drugHistorySearch.value).subscribe(res => {
      if (res['message'] == 'Success') {
        this.totalRecords = res['data'][0];
        this.findAllDrugHistory();
      } else {
        this.totalRecords = 0;
      }
    }, err => {
      console.error('err: ', err);
    });
  }

   unique(array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
  }

  onSubmit() {
    this.drugHistoryForm.markAllAsTouched();
    const drugHistoryDTO = new InitialHistoryFormDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.drugHistoryForm);

    const diagnosisData = new DiagnosisDataHelper(drugHistoryDTO.diagnosisData);

    let uniqueCaseDiagnosisPredictionList = [];
    uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
    console.log(uniqueCaseDiagnosisPredictionList);
    console.log(drugHistoryDTO.redFlagData);

   /* let uniqueCaseDiagnosisPredictionList = [];

    drugHistoryDTO.diagnosisData.map(diagData => {

      uniqueCaseDiagnosisPredictionList = [...uniqueCaseDiagnosisPredictionList, ...diagData.diagnosis];
    });

    uniqueCaseDiagnosisPredictionList = this.unique(uniqueCaseDiagnosisPredictionList, 'nonObjectDiagnosisId');*/



    if (!drugHistoryDTO.hasMultiSelectErrors) {

      this.drugHistoryServices.addDrugHistoryCpa(drugHistoryDTO).subscribe(res => {

        if (res.status === 200) {

          this.messageService.add({
            severity: 'success',
            summary: this.translate(this.labels.entityNameDrugHistory) +
              this.translate(this.labels.addedSuccessfully),
          });

          this.getDrugHistoryByAppointmentId();

          if (uniqueCaseDiagnosisPredictionList.length > 0) {
            this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

              console.log(res);
            });
          }

          if (drugHistoryDTO.redFlagData.length > 0) {

            this.lovServices.createListOfCaseRedFlags(drugHistoryDTO.redFlagData).subscribe(res => {

              if (res.status === 201) {

                this.messageService.add({
                  severity: 'success',
                  summary: this.translate("Red Flags Updated"),
                });
              }

            });
          }



          this.submitted = true;
          this.showHideAddDialog();


        }
        else {
          alert ('eror');
        }

      });
    }
    else {

      drugHistoryDTO.multiSelectErrorFields.forEach(field => {
        document.getElementById('#' + field.dbField  + 'Error').classList.remove('hidden');
      });
    }

  }

  onPageChnage(event: LazyLoadEvent) {
    this.itemPerPage = event.rows;
    this.pageNo = event.first / event.rows;
    this.findAllDrugHistory();
  }

  clearSearchResult() {
    this.clearSearchBtn = false;
    this.drugHistorySearch.reset();
    this.countDrugHistory();
  }

  translate(label) {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

  getConstantList(formId) {
    this.constantService.getFormConstantsList(formId).subscribe(data => {
      if (data['statusCode'] == 200) {
        this.constantList = data['data'];
      } else {
        this.constantList = [];
      }
    });
  }

  ngOnInit() {
    this.addEditTitle = this.labels.btnAdd;
    this.resetTag = true;
    this.lovDataObject = {"refId": 0, "refType": ""};
    this.objectMultiSelects = this.drugHistoryMultiSelects.objectMultiSelects;

    /* no idea why on this page expert Id from the session dont work */

    this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
    this.patientId = parseInt(this.sessionService.getSession('patientId'));
    this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
    this.caseId = parseInt(this.sessionService.getSession('caseId'));

    this.drugHistoryForm = new FormGroup({
      additionalComments: new FormControl(''),
    });
    this.drugHistorySearch = new FormGroup({
      drugHistory: new FormControl(),
      vaccination: new FormControl(),
      drinks: new FormControl(),
      smoke: new FormControl(),
      recreation: new FormControl(),
      drugs: new FormControl(),
    });
    this.getConstantList(1234);
    this.getDrugHistoryByAppointmentId();
    this.primengConfig.ripple = true;
    this.clearSearchBtn = false;
    this.drugHistorySearch.valueChanges.subscribe(data => {
      this.onValueChange = false;
    });
  }

  getDrugHistoryByPatientId(): void {

    this.drugHistoryServices.findDrugHistoryByPatientId(this.patientId).subscribe(res => {

      if (res.status === 200) {
        this.drugHistoryList = res.body;
      }

    });
  }

  getDrugHistoryByAppointmentId(): void {

    this.drugHistoryServices.findDrugHistoryByAppointmentId(this.appointmentId).subscribe(res => {

      if (res.status === HttpStatusCode.Ok) {
        this.stopLoader();
        this.drugHistoryList = res.body;
      }

    },
      err => {

        this.messageService.add({
          severity: 'error',
          summary: err,
          detail: ''
        });

    //    console.error('err: ', err);
        this.stopLoader();
      });
  }

  findAllDrugHistory() {
    this.startLoader();

    this.drugHistorySearch.controls['expertId'].setValue(this.expertId);
    this.drugHistorySearch.controls['patientId'].setValue(this.patientId);
    this.drugHistorySearch.controls['appointmentId'].setValue(this.appointmentId);
    this.drugHistoryServices.findAllDrugHistory(this.drugHistorySearch.value, this.pageNo, this.itemPerPage).subscribe(
      res => {
        if (res['message'] == 'Success') {
          this.drugHistoryList = res['data'];
        } else {
          this.drugHistoryList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  deleteByIdDrugHistory(drugHistoryId: number, index) {
    this.confirmation.confirm({
      message: this.translate(this.labels.DELETE_MSG), accept: () => {
        this.startLoader();
        this.drugHistoryServices.deleteByIdDrugHistory(drugHistoryId).subscribe(res => {
          if (res['message'] == 'Success') {
            this.drugHistoryList.splice(index, 1);
            this.messageService.add({
              severity: 'warn',
              summary: this.translate(this.labels.entityNameDrugHistory) +
                this.translate(this.labels.deletedSuccessfully),
              detail: ''
              // detail: this.translate(this.labels.entityNameDrugHistory)
            });
            this.countDrugHistory();
          } else {
            console.error('error: ', res['documentation']);
            this.stopLoader();
          }
        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });
      },
      key: "deleteDrugHistory"
    });
  }

  loadDrugHistoryToEdit(drugHistoryId: number, index) {
    this.addEditTitle = this.labels.btnEdit;
    let drugHistory = this.drugHistoryList.find(x => x['drugHistoryId'] == drugHistoryId);
    this.submitted = null;
    this.formButton = this.translate(this.labels.btnEdit);
    this.updateDrugHistoryId = drugHistory['drugHistoryId'];
    this.selectedIndex = index;
    this.lovDataObject = {"refId": drugHistory['drugHistoryId'], "refType": ""};

    if (drugHistory['drugHistory']['listOfValuesMasterId']) {
      this.drugHistoryForm.controls['drugHistory'].setValue(drugHistory['drugHistory']['listOfValuesMasterId']);
    }

    if (drugHistory['vaccination']) {
      this.drugHistoryForm.controls['vaccination'].setValue(drugHistory['vaccination']['listOfValuesId']);
    }

    if (drugHistory['lifestyle']) {
      this.drugHistoryForm.controls['lifestyle'].setValue(drugHistory['lifestyle']['listOfValuesId']);
    }
    if (drugHistory['drinks']) {
      this.drugHistoryForm.controls['drinks'].setValue(drugHistory['drinks']['listOfValuesId']);
    }
    if (drugHistory['smoke']) {
      this.drugHistoryForm.controls['smoke'].setValue(drugHistory['smoke']['listOfValuesId']);
    }
    if (drugHistory['recreation']) {
      this.drugHistoryForm.controls['recreation'].setValue(drugHistory['recreation']['listOfValuesId']);
    }
    if (drugHistory['drugs']) {
      this.drugHistoryForm.controls['drugs'].setValue(drugHistory['drugs']['listOfValuesId']);
    }
    if (drugHistory['additionalComments']) {
      this.drugHistoryForm.controls['additionalComments'].setValue(drugHistory['additionalComments']);
    }
    if (drugHistory['patientId']) {
      this.drugHistoryForm.controls['patientId'].setValue(drugHistory['patientId']['patientId']);
    }
    if (drugHistory['expertId']) {
      this.drugHistoryForm.controls['expertId'].setValue(drugHistory['expertId']['expertId']);
    }

    if (drugHistory['caseId']) {
      this.drugHistoryForm.controls['caseId'].setValue(drugHistory['caseId']['caseId']);
    }
    // this.drugHistoryForm.controls['userId'].setValue(drugHistory['userId']['userId']);

    // by ali
    if (this.showAddDialog === false && this.editTag === false) {
      this.editTag = true;
      this.showAddDialog = true;
      this.showAddBtn = true;
      this.showSearchBtn = true;
      this.showSearchDialog = false;
    } else if (this.showAddDialog === true && this.editTag === false) {
      this.editTag = true;
      this.showAddBtn = true;
      this.showSearchBtn = true;
      this.showSearchDialog = false;
    } else if (this.showAddDialog === true && this.editTag === true) {
      this.editTag = false;
      this.showAddDialog = false;
      this.showAddBtn = false;
      this.showSearchBtn = false;
      this.showSearchDialog = false;
    }
  }

  onClearAdd() {
    this.drugHistoryForm.reset();
    this.drugHistoryForm.markAsUntouched();
    this.updateDrugHistoryId = null;
  }

  findAllListOfValuesDetail() {
    let temp = [];
    this.startLoader();
    this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          for (let object of temp) {
            let dropDown = {label: '', value: ''};
            dropDown.value = object['listOfValuesDetailId'];
            dropDown.label = object['referenceType'];
            this.listOfValuesDetailList.push(dropDown);
          }
        } else {
          this.listOfValuesDetailList = [];
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  fieldReset(control) {
    return this.drugHistoryForm.get(control).reset();
  }

  diplayeIconInField(control) {
    return this.drugHistoryForm.get(control)?.valid;
  }

  inputFieldDanger(control) {
    return !this.drugHistoryForm.get(control)?.valid && this.drugHistoryForm?.get(control)?.touched;
  }

  onCloseAdd() {
    this.drugHistoryForm.reset();
  }

  onCloseSearch() {
    this.drugHistorySearch.reset();
  }

  refresh() {
    this.countDrugHistory();
  }

  onAddOverlayDiv() {
    this.drugHistoryForm.reset();
  }

  // by ali
  showHideSearchDialog() {
    if (this.resetTag) {
      this.drugHistorySearch.reset();
      this.resetTag = false;
    }
    this.showSearchDialog = !this.showSearchDialog;
    this.showSearchBtn = this.showSearchDialog;
    this.showAddBtn = this.showSearchDialog;
  }

  showHideAddDialog() {
    if (!this.showAddDialog) {
      this.drugHistoryForm.reset();
      this.lovDataObject = {"refId": 0, "refType": ""};
    }
    this.addEditTitle = this.labels.btnAdd;
    this.showAddDialog = !this.showAddDialog;
    this.showAddBtn = this.showAddDialog;
    this.showSearchBtn = this.showAddDialog;
    this.editTag = false;
    this.updateDrugHistoryId = null;
  }

  searchTerm = null;

  onGlobalSearchClick(eventValue) {
    console.log('global search clicked ', eventValue);
    this.searchTerm = eventValue;
    this.globalSearchCount();
  }

  globalSearchCount() {
    this.drugHistoryServices.globalSearchCount(this.searchTerm, this.appointmentId, this.expertId, this.patientId).subscribe(res => {
      if (res['message'] == 'Success') {
        this.totalRecords = res['data'][0];
        this.globalSearchFind();
      } else {
        this.totalRecords = 0;
        this.drugHistoryList = [];
      }
    }, err => {
      console.error('err: ', err);
    });
  }

  globalSearchFind() {
    this.drugHistoryServices.globalSearchFind(this.searchTerm, this.appointmentId, this.expertId, this.patientId, this.pageNo, this.itemPerPage).subscribe(result => {
      if (result['statusCode'] == 200) {
        this.drugHistoryList = result['data'];
      } else {
        this.drugHistoryList = [];
      }
      console.log(result, 'result');
    });
  }
}
