
<div class="card-cpa-rounded-20 background-white-m-30">
  <p-steps [model]="stepsItems"  [(activeIndex)]="activeStep"
           [readonly]="false"  (activeIndexChange)="urlChanged($event)" >
  </p-steps>
</div>
<div class="p-row initial-assessment-container" style="height:auto">

  <div class="p-col-12">
    <div class="row">
      <div class="p-col-3 m-5">

        <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>


      </div>
    </div>
    <div class="row">
      <div class="p-col-11 m-5">
        <p-card styleClass="card-cpa-rounded card-shadow">

          <div class=" displayBlock overlaypaneldiv" id="overlaypaneldiv-add">
            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
              <p-header>Follow Up</p-header>



              <form  (ngSubmit)="onSubmit()" [formGroup]="baseComponent.baseForm" >
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12  p-md-4 formfieldheight">
                <label>Consent</label>

                <p-dropdown [filter]="false"
                            [options]="consentOptions"
                            [showClear]="true"
                            class="pngDropdown"
                            formControlName="consent"
                            placeholder="Select">
                </p-dropdown>


                  <app-custom-error [control]="'consent'" [form]="baseComponent.baseForm"></app-custom-error>
              </div>
              <div class="p-field p-col-12 p-md-5 p-offset-1 formfieldheight">
                <label>Improvement</label>
                {{baseComponent.baseForm.value.percentageImprovement}}
              <p-slider formControlName="percentageImprovement"></p-slider>

              </div>
              <div class="p-field p-col-12 p-md-1 formfieldheight">
                <label>Is Soap</label>
                <p-inputSwitch formControlName="isSoap"></p-inputSwitch>
              </div>


              <div class="p-field p-col-12  p-md-6 formfieldheight" *ngIf="redFlagsLoaded">

                <app-lov-details alternateData="followUpRedFlags" (getResponse)="getResponse($event)"
                                 label="Red Flags"
                                 [dataObj]="lovDataObject" [currentSelectedArray]="currentRedFlags"></app-lov-details>
              </div>

              <div class="row" style="margin:20px"><div class="col-md-12" *ngIf="baseComponent.baseForm.value.isSoap"><h5>Soap Notes</h5>
                <textarea cols="15" rows="5" class="form-control" formControlName="soapText"></textarea></div>
              </div>



            </div>
            <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
              <p-button label="{{baseComponent.addEditTitle === baseComponent.labels.btnAdd? baseComponent.labels.btnSave : baseComponent.labels.btnUpdate}}"
                        type="submit"
                        styleClass="p-button"
                        class="pngBlueBtn">
              </p-button>
              <p-button [label]="baseComponent.labels.btnClose"
                        (click)="baseComponent.showHideAddDialog()"
                        styleClass="p-button"
                        class="pngBtnTransparent">
              </p-button>
            </div>

          </form>

            </p-panel>
          </div>


        </p-card>
      </div>
    </div>



  </div>

</div>
