import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ClinicTypeService} from '../web-services/clinic-type.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-clinic-type', templateUrl: './clinic-type.component.html', styleUrls: ['./clinic-type.component.css']})
export class ClinicTypeComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateClinicTypeId = null;
    clinicTypeList: any = [];
    clinicTypeSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    clinicTypeForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private clinicTypeServices: ClinicTypeService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateClinicTypeId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.clinicTypeSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countClinicType();
        this.findAllClinicType();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countClinicType() {
        this.clinicTypeServices.countClinicType(this.clinicTypeSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllClinicType();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateClinicType(this.clinicTypeForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllClinicType();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.clinicTypeSearch.reset();
        this.countClinicType();
        // this.findAllClinicType();
    }

    addUpdateClinicType(clinicType) {
        this.startLoader();
        if (this.updateClinicTypeId == null) {
            this.clinicTypeServices.addClinicType(this.clinicTypeForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    clinicType = res['data'][0];
                    this.clinicTypeList.push(clinicType);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.btnAdd),
                            detail: this.translate(this.labels.entityNameClinicType)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countClinicType();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            clinicType.clinicTypeId = this.updateClinicTypeId;
            this.clinicTypeServices.updateClinicType(clinicType).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.clinicTypeList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameClinicType)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countClinicType();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateClinicTypeId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.clinicTypeForm = new FormGroup({
                                                clinicType: new FormControl('', [Validators.required, validateSpaces]),
                                                description: new FormControl('', [Validators.required, validateSpaces]),
                                                userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                            });
        this.clinicTypeSearch = new FormGroup({clinicType: new FormControl(), description: new FormControl(), userId: new FormControl(),});
        this.countClinicType();
        this.findAllClinicType();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.clinicTypeSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllClinicType() {
        this.startLoader();
        this.clinicTypeServices.findAllClinicType(this.clinicTypeSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.clinicTypeList = res['data'];
                } else {
                    this.clinicTypeList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdClinicType(clinicTypeId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.clinicTypeServices.deleteByIdClinicType(clinicTypeId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.clinicTypeList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.btnDelete),
                                detail: this.translate(this.labels.entityNameClinicType)
                            });
                        this.countClinicType();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadClinicTypeToEdit(clinicTypeId: number, index) {
        let clinicType = this.clinicTypeList.find(x => x['clinicTypeId'] == clinicTypeId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateClinicTypeId = clinicType['clinicTypeId'];
        this.selectedIndex = index;
        this.clinicTypeForm.controls['clinicType'].setValue(clinicType['clinicType']);
        this.clinicTypeForm.controls['description'].setValue(clinicType['description']);
        this.clinicTypeForm.controls['userId'].setValue(clinicType['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.clinicTypeForm.reset();
        this.clinicTypeForm.markAsUntouched();
        this.updateClinicTypeId = null;
        closeAddOverlaydiv();
    }

    fieldReset(control) {
        return this.clinicTypeForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.clinicTypeForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.clinicTypeForm.get(control)?.valid && this.clinicTypeForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.clinicTypeForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.clinicTypeSearch.reset();
    }

    refresh() {
        this.countClinicType();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.clinicTypeForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.clinicTypeForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateClinicTypeId = null;
    }
}
