import {ListOfValuesService} from '../web-services/list-of-values.service';

export class LovSingleDropDown {


  public lovData: any;

  public static async getLovValuesByCategoryName(categoryName: string, listOfValuesServices: ListOfValuesService): Promise<any> {

    let dataForDropDown = [];

    return new Promise((resolve, reject) => {
      /* do something that takes time, and then call resolve or maybe reject */

      listOfValuesServices.findAllListOfValuesByName(categoryName).subscribe(
        res => {

          if (res['message'] == 'Success') {
            let temp = res['data'];
            for (let object of temp) {
              let dropDown = { label: '', value: '' };
              dropDown.value = object['listOfValuesId'];
              dropDown.label = object['label'];
              dataForDropDown.push(dropDown);

            }
          } else {
            dataForDropDown = [];
          }

          resolve(dataForDropDown);
        }, err => {
          reject(err);
          console.error('err: ', err);
        }
      );
    });
  }


}


