import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { AddVersionDialog } from '../pop-ups/add-version/dialog-add-version';
// import { DeleteVersionComponent } from '../pop-ups/delete-version/delete-version.component'
// import { CopyProjectDialog } from '../pop-ups/copy-project/dialog-copy-project';
// import { DeleteAlertDialog } from '../pop-ups/delete-popup/dialog-delete-alert';
// import { AbouUsDialog } from '../pop-ups/about-us/dialog-about-us';
import { SharedService } from '../services/sharedservice/shared.service';
// import { RestServiceService } from '../report-preview/rest-service.service';
// import { ReportPreviewComponent } from '../reports-revamp/report-preview/report-preview.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { ChangeVersionDialog } from '../pop-ups/add-change-version/dialog-change-version';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
  @Input() project: any;
  @Input() versions: any;
  @Input() selectedVersion: any;
  @Input() constantList: any;
  currentVerion: any;
  @Output() emitVersionObject: EventEmitter<any> = new EventEmitter();

  sendVersionObject() {
    // triggered whenever currentVersion in changed
    // console.log(this.selectedVersion)
    this.emitVersionObject.emit(this.selectedVersion);
    this.currentVerion = this.selectedVersion;
  }

  constructor(
      public dialog: MatDialog,
      // private _previewService: RestServiceService,
      private ngxSpinnerService: NgxUiLoaderService,
      public sharedService: SharedService
  ) {
  }

  jsonParamter = {}
  projectObject: any;

  ngOnInit() {
    // console.log("project :: ", this.project)
    this.checkVersion();
    this.projectObject = this.sharedService.getCookie("project");
    JSON.parse(this.projectObject)['projectId']
    this.jsonParamter = {"cprojectId": JSON.parse(this.projectObject)['projectId']}
  }

  currentVersion = this.sharedService.getCookie("currentVersion");

  checkVersion() {
    this.sharedService.CurrentVersion.subscribe(data => {
      this.currentVersion = data;
    });
  }


  openCopyProjectDialog() {
    // const dialogRef = this.dialog.open(CopyProjectDialog, {
    // });

  }

  openAddVersionDialog() {
    // this.sharedService.projectId
    // const dialogRef = this.dialog.open(AddVersionDialog, {
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.versions.push(result);
    //   }
    // });
  }

  openDeleteVersionDialog() {
    // const dialogRef = this.dialog.open(DeleteVersionComponent, {
    //   data: {
    //     versions: this.versions,
    //     constantList : this.constantList
    //   }
    // });
  }

  openChangeVersionDialog() {
    // const dialogRef = this.dialog.open(ChangeVersionDialog, {
    //   data: {
    //     versions: this.versions,
    //     selectedVersion: this.selectedVersion,
    //     constantList : this.constantList
    //   }
    // });
    //
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     console.log(result)
    //     this.selectedVersion = result;
    //     this.sendVersionObject();
    //   }
    // });
  }


  openDeleteDialog() {
    // const dialogRef = this.dialog.open(DeleteAlertDialog, {
    // });
  }

  openAboutusDialog() {
    // const dialogRef = this.dialog.open(AbouUsDialog, {
    // });
  }

  // openReport(reportType) {
  //   // Setting Report Type in Session && SelectedObjectId 
  //   // is already Stored In Session Required For Reports To Render
  //   // this.sharedService.setSession("reportType", name);
  //   this.openReportDialogue('', reportType);

  // }

  // openReportDialogue(reportModel: any, reportComingFrom?: String) {
  //   this._previewService.setSessions('reportType', reportComingFrom);

  //   if (reportComingFrom.localeCompare('Report List') == 0 || reportComingFrom.localeCompare('Generate Report') == 0) {
  //     this._previewService.reportParamJson = reportModel;
  //   }
  //   const dialogRef = this.dialog.open(ReportPreviewComponent, {
  //   });
  // }

  callProjectReport(reportId) {
    this.projectObject = this.sharedService.getCookie("project");
    this.jsonParamter = {"cprojectId": JSON.parse(this.projectObject)['projectId']}
    this.openDialogReViewreportParameters(reportId, this.jsonParamter)
  }


  openDialogReViewreportParameters(reportId, jsonParameters) {

    // console.log("Testing 123");
    var obj = {};
    obj['parameters'] = JSON.stringify(jsonParameters)
    obj['reportId'] = reportId;
    // console.log(obj);
    this.openPreviewDialog(obj, null);

  }


  openPreviewDialog(obj, index) {
    //   const dialogRef = this.dialog.open(ReportPreviewComponent, {
    //     data: obj,
    //     // width: ' ',
    //     height: '700px',
    //   });
    //
    //   dialogRef.afterClosed().subscribe((data: any) => {
    //   });
    // }

  }
}

