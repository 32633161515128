import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {FlagsService} from '../web-services/flags.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {DatePipe} from '@angular/common';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SessionService} from '../services/session.service';

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-flags',
               templateUrl: './flags.component.html',
               styleUrls: ['./flags.component.css'],
               providers: [DialogService]
            })
export class FlagsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateFlagId = null;
    listOfValuesDetailList: any = [];
    flagsList: any = [];
    flagColorList: any = [];
    flagValueList: any = [];
    flagStatusList: any = [];
    flagsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    flagsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    expertId;
    patientId;
    appointmentId;

    constructor(
        private flagsServices: FlagsService,
        private listOfValuesDetailServices: ListOfValuesDetailService, private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService, private messageService: MessageService, private route: Router,
        private loader: NgxUiLoaderService, private constantService: ConstantService, private sharedService: SharedService,
        private lovService : ListOfValuesService,
        private dialogService: DialogService,
        private sessionService: SessionService

    ) {
    };
    ref: DynamicDialogRef;

    viewAgencyDetail(flagId: number) {
        let flag = this.flagsList.find(x => x['flagId'] == flagId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Company Detail',
            width: '90%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {
                mainData: [
                    {label: this.labels.flagsColumnFlag, value: flag['flag']['label']},
                    {label: this.labels.flagsColumnFlagValue, value: flag['flagValue']['label']},
                    {label: this.labels.agencyColumnAgencyLastname, value: flag['flagStatus']['label']},
                ]
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('expertId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.flagsForm = new FormGroup({
                                           flag: new FormControl('',[Validators.required]),
                                           flagValue: new FormControl('',[Validators.required]),
                                           flagStatus: new FormControl('',[Validators.required]),
                                           patientId: new FormControl(),
                                           expertId: new FormControl(),
                                           appointmentId: new FormControl(),
                                           userId: new FormControl(),
                                           flagDate: new FormControl(''),
                                       });
        this.flagsSearch = new FormGroup({
                                             flag: new FormControl(),
                                             flagValue: new FormControl(),
                                             flagStatus: new FormControl(),
                                             patientId: new FormControl(),
                                             expertId: new FormControl(),
                                             appointmentId: new FormControl(),
                                             userId: new FormControl(),
                                             flagDate: new FormControl(),
                                         });
        this.findFlagColor();
        this.findFlagValue();
        this.findFlagStatus();
        this.countFlags();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.flagsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

  async findFlagColor() {
        let temp = [];
        this.startLoader();
       await this.lovService.findAllListOfValuesByName('flagColor').toPromise().then(res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.flagColorList.push(dropDown);
                    }
                } else {
                    this.flagColorList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    async findFlagValue() {
        let temp = [];
        this.startLoader();
        await this.lovService.findAllListOfValuesByName('flagValue').toPromise().then(res => {
            if (res['message'] == 'Success') {
                temp = res['data'];
                for (let object of temp) {
                    let dropDown = {label: '', value: ''};
                    dropDown.value = object['listOfValuesId'];
                    dropDown.label = object['label'];
                    this.flagValueList.push(dropDown);
                }
            } else {
                this.flagValueList = [];
            }
            this.stopLoader();
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    async findFlagStatus() {
        let temp = [];
        this.startLoader();
        await this.lovService.findAllListOfValuesByName('flagStatus').toPromise().then(res => {
            if (res['message'] == 'Success') {
                temp = res['data'];
                for (let object of temp) {
                    let dropDown = {label: '', value: ''};
                    dropDown.value = object['listOfValuesId'];
                    dropDown.label = object['label'];
                    this.flagStatusList.push(dropDown);
                }
            } else {
                this.flagStatusList = [];
            }
            this.stopLoader();
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    countFlags() {
        this.flagsSearch.controls['expertId'].setValue(this.expertId);
        this.flagsSearch.controls['patientId'].setValue(this.patientId);
        this.flagsSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.flagsServices.countFlags(this.flagsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllFlags();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    get addUpdateTitle() {
        return this.updateFlagId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.flagsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countFlags();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllFlags();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.flagsSearch.reset();
        this.countFlags();
    }

    onSubmit() {
        this.flagsForm.markAllAsTouched();
        this.flagsForm.controls['expertId'].setValue(this.expertId);
        this.flagsForm.controls['patientId'].setValue(this.patientId);
        this.flagsForm.controls['appointmentId'].setValue(this.appointmentId);
        if (this.flagsForm.valid) {
            this.addUpdateFlags(this.flagsForm.value);
            this.submitted = false;
        }
    }

    addUpdateFlags(flags) {
        this.startLoader();
        if (this.updateFlagId == null) {
            this.flagsForm.controls['appointmentId'].setValue(this.appointmentId);
            this.flagsForm.controls['patientId'].setValue(this.patientId);
            this.flagsForm.controls['expertId'].setValue(this.expertId);
            this.flagsForm.controls['userId'].setValue(0);
            let today = new Date();
            let d = new DatePipe('en-US').transform(today, 'yyyy-MM-dd')
            this.flagsForm.controls['flagDate'].setValue(d.toString());
            this.flagsServices.addFlags(this.flagsForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    flags = res['data'][0];
                    this.flagsList.push(flags);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameFlags) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameFlags)
                        });
                    this. showHideAddDialog();
                    this.submitted = true;
                    this.countFlags();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.onClearAdd();
        }
        else {
            flags.flagId = this.updateFlagId;
            this.flagsServices.updateFlags(flags).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.flagsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameFlags) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameFlags)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countFlags();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateFlagId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }



    findAllFlags() {
        this.startLoader();
        this.flagsSearch.controls['expertId'].setValue(this.expertId);
        this.flagsSearch.controls['patientId'].setValue(this.patientId);
        this.flagsSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.flagsServices.findAllFlags(this.flagsSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.flagsList = res['data'];
                } else {
                    this.flagsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdFlags(flagId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.flagsServices.deleteByIdFlags(flagId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.flagsList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameFlags) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameFlags)
                            });
                        this.countFlags();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadFlagsToEdit(flagId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let flags = this.flagsList.find(x => x['flagId'] == flagId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateFlagId = flags['flagId'];
        this.selectedIndex = index;
        this.flagsForm.controls['flag'].setValue(flags['flag']['listOfValuesId']);
        this.flagsForm.controls['flagValue'].setValue(flags['flagValue']['listOfValuesId']);
        this.flagsForm.controls['flagStatus'].setValue(flags['flagStatus']['listOfValuesId']);
        this.flagsForm.controls['patientId'].setValue(flags['patientId']['patientId']);
        this.flagsForm.controls['expertId'].setValue(flags['expertId']['expertId']);
        this.flagsForm.controls['appointmentId'].setValue(flags['appointmentId']['appointmentId']);
        this.flagsForm.controls['userId'].setValue(flags['userId']);
        this.flagsForm.controls['flagDate'].setValue(new Date(flags['flagDate']));
        // openAddOverlaydiv();

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.flagsForm.reset();
        this.flagsForm.markAsUntouched();
        this.updateFlagId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.flagsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.flagsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.flagsForm.get(control)?.valid && this.flagsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.flagsForm.reset();
    }

    onCloseSearch() {
        this.flagsSearch.reset();
    }

    refresh() {
        this.countFlags();
    }

    onAddOverlayDiv() {
        this.flagsForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.flagsSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.flagsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateFlagId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.flagsServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.flagsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.flagsServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.flagsList = result['data'];
            } else {
                this.flagsList = [];
            }
            console.log(result, 'result');
        });
    }
}
