import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { NonInterceptService } from '../non-intercept/non-intercept.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SharedService } from '../sharedservice/shared.service';
import { AppSettings } from '../../utilities/constants/constants';

@Injectable({
  providedIn: 'root'
})

export class TokenRefreshService {

  currentTime;
  activatedRole;
  applicationShortName = "";
  projectId = 0;
  expirationDate;
  applicationId;
  constructor(private nonInterceptService: NonInterceptService, private cookieService: CookieService, private router: Router, private sharedService: SharedService) {
    // var url = window.location.href;
    this.checkRefreshToken();
    setInterval(() => {
      console.log("in Interval");
    var url = window.location.href;
    if (!(url.includes("login") || url.includes("forgetpasswordinitiative") || url.includes("resetpassword"))) {
      console.log("refrehToken :: " , this.refreshToken);
      // if (this.refreshToken) {
        // setInterval(() => {
          console.log("tokenRefresh found True");
          this.currentTime = new Date();
          this.applicationId = this.sharedService.getCookie("applicationId");
          this.activatedRole = this.sharedService.getCookie('activatedRole');
          this.applicationShortName = this.sharedService.getCookie('activatedRole');
          var projectObject = this.sharedService.getCookie("project");
          if (projectObject) {
            var project = JSON.parse(projectObject);
            this.projectId = project['projectId'];
          }

          if (this.activatedRole) { }
          else {
            this.activatedRole = AppSettings.ZERO;
          }
          if (this.projectId) { }
          else {
            this.projectId = AppSettings.ZERO;
          }
          console.log("time :: ", (this.currentTime.getTime() + AppSettings.TOKEN_EXPIRE_TIME), "expiry Time", this.sharedService.getCookie("expirationDate"))
          if ((this.currentTime.getTime() + AppSettings.TOKEN_EXPIRE_TIME) > (this.sharedService.getCookie("expirationDate"))) {
            console.log("token Refresh Update Active Role Service Called");
            this.nonInterceptService.updateCurrentRole(this.activatedRole, this.projectId, this.applicationId).pipe(first()).subscribe(data => {
              console.log("update active role service called")
              if (data) {
                this.sharedService.setCookies('token', data['token']);
                this.sharedService.setCookies(this.sharedService.getCookie("applicationShortName") + "_roles", data['applicationRole']);
                // this.checkApplicationShortNameAndSetRoles(this.sharedService.getCookie("applicationShortName"), data['applicationRole']);
                this.sharedService.setCookies("expirationDate", data['expirationDate']);
              }
              else {
                this.checkUrl(AppSettings.UMS_APPLICATION_ID, AppSettings.APPLICATION_SHORT_NAME, '/logout');
              }
            }, error => {
              this.sharedService.checkHttpCodes(error);
              this.checkUrl(AppSettings.UMS_APPLICATION_ID, AppSettings.APPLICATION_SHORT_NAME, '/logout');
            })
          }
        // }, AppSettings.TOKEN_REFRESH_TIME)
      // }
      // else {
      //   // console.log("token Refresh Found False");
      // }
    }
  }, AppSettings.TOKEN_REFRESH_TIME_NEW);

    
  }

  checkUrl(applicationId, appName, route) {
    var url = window.location.href;
    if (!(url.includes("login"))) {
      if (url.includes(appName)) {
        this.router.navigate([route]);
      } else {
        window.location.assign(this.sharedService.getApplicationFrontEndUrl(applicationId) + route);
      }
    }
  }

  refreshToken = true;
  checkRefreshToken() {
    this.sharedService.refreshToken.subscribe(data => {
      console.log("refreshToken :: ")
      console.log("data refresh Token :: " , data )
      // this.refreshToken = data;
    });
  }

  // checkApplicationShortNameAndSetRoles(applicationShortName, roles) {
  //   switch (applicationShortName) {
  //     case "xigt":
  //       this.sharedService.setCookies("xigt_roles", roles);
  //       break;
  //     case "ums":
  //       this.sharedService.setCookies("ums_roles", roles);
  //       break;
  //     case "re":
  //       this.sharedService.setCookies("re_roles", roles);
  //       break;
  //     case "tms":
  //       this.sharedService.setCookies("tms_roles", roles);
  //       break;
  //     case "ams":
  //       this.sharedService.setCookies("ams_roles", roles);
  //       break;
  //   }
  // }
}
