import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ListOfValuesDetailService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdListOfValuesDetail(listOfValuesDetailId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValuesDetail/deleteListOfValuesDetail', {'listOfValuesDetailId': listOfValuesDetailId},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countListOfValuesDetail(listOfValuesDetail): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'ListOfValuesDetail/count', {'listOfValuesDetail': listOfValuesDetail},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateListOfValuesDetail(listOfValuesDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValuesDetail/updateListOfValuesDetail', [listOfValuesDetail], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addListOfValuesDetail(listOfValuesDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ListOfValuesDetail/addListOfValuesDetail', [listOfValuesDetail], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllListOfValuesDetail(listOfValuesDetail, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ListOfValuesDetail/findAllListOfValuesDetail',
            {'listOfValuesDetail': listOfValuesDetail, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
