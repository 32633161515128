import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { Constant } from '../util/constant';
import { SharedService } from '../web-services/shared.service';
import { ConstantService } from '../web-services/constant.service';
import { Labels } from '../util/labels';
import { InvoiceService } from '../web-services/invoice.service';
import { PatientsService } from '../web-services/patients.service';
import { AgencyService } from '../web-services/agency.service';
import { CompanyService } from '../web-services/company.service';
import { ListOfValuesDetailService } from '../web-services/list-of-values-detail.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvoiceItemsDetailComponent } from '../invoice-items-detail/invoice-items-detail.component';
import { UsersService } from '../web-services/users.service';
import { PrintpreviewComponent } from '../printpreview/printpreview.component';
import { first } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import {Utils} from '../util/utils';

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.css'],
    providers: [DialogService]
})
export class InvoiceComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateInvoiceId = null;
    patientsList: any = [];
    agencyList: any = [];
    companyList: any = [];
    listOfValuesDetailList: any = [];
    invoiceList: any = [];
    invoiceListDummy: any = [];
    invoiceSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    invoiceForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    issueToUsersList: any = [];
    issueByUsersList: any = [];

    issueByTypeList: any = [];
    issueToTypeList: any = [];
    unSubService; any;
    searchTerm = null;
    loggedInUserType: any;
    loggedInRefId: any;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    addEditTitle = '';
    resetTag = true;

    constructor(
        private invoiceServices: InvoiceService,
        private patientsServices: PatientsService,
        private agencyServices: AgencyService,
        private companyServices: CompanyService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private router: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private dialogService: DialogService,
        private userService: UsersService,
        private sessionService: SessionService,
    ) {
    };

    ref: DynamicDialogRef;


    get addUpdateTitle() {
        return this.updateInvoiceId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.invoiceSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countInvoice();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }
    utils = new Utils();
    countInvoice() {
        this.startLoader();
        if (this.loggedInUserType === 'admin') {
            this.invoiceSearch.controls['issueTo'].setValue(this.loggedInRefId);
        }


        this.invoiceSearch.controls['invoiceStatus'].setValue(this.title);

        this.invoiceSearch.controls['invoiceAmount'].setValue(0);

        let obj =this.utils.getUpdatedMillissecondObject(this.invoiceSearch.value,"issueDate");
        if(obj['issueDate'] === '0'){
            delete obj['issueDate'];
        }

        this.invoiceServices.countInvoice(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllInvoice();
            } else {
                this.totalRecords = 0;
                this.invoiceList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {
        this.invoiceForm.markAllAsTouched();
        this.invoiceForm.controls['issueBy'].setValue(this.issueByUsersList[0].value);
        this.invoiceForm.controls['issueByType'].setValue(this.issueByTypeList[0].value);
        this.invoiceForm.controls['issueToType'].setValue(this.issueToTypeList[0].value);
        if (this.invoiceForm.valid) {
            this.addUpdateInvoice(this.invoiceForm.value);
            this.submitted = false;
            this.onClearAdd();
        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        if (!this.searchTerm) {
            this.findAllInvoice();
        } else {
            this.findAllGlSearchInvoice();
        }

    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.invoiceSearch.reset();
        this.countInvoice();
    }

    addUpdateInvoice(invoice) {
        this.startLoader();
        // this.invoiceForm.controls['userId'].setValue();
        this.invoiceForm.controls['invoiceStatus'].setValue(Constant.INVOICE_STATUS_PENDING);

        let obj =this.utils.getUpdatedMillissecondObject(this.invoiceForm.value,"issueDate");
        console.log(obj, 'date object');

        if (this.updateInvoiceId == null) {
            console.log('invoice form value :: ', this.invoiceForm.value);
            this.invoiceServices.addInvoice(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    invoice = res['data'][0];
                    this.invoiceList.push(invoice);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: 'Invoice Added Successfully',
                            detail: ''
                        });
                    this.showHideAddDialog();
                    this.countInvoice();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.invoiceId = this.updateInvoiceId;
            this.invoiceServices.updateInvoice(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.invoiceList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: 'Invoice Updated Successfully',
                            detail: ''
                        });
                    this.showHideAddDialog();
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateInvoiceId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }
    title: any;
    ngOnInit() {
        this.pageControlMap = this.sharedService.checkRole(this.pageControlMap);
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;

        if (this.router.url.includes('/paid-invoice')) {
            this.title = Constant.INVOICE_STATUS_PAID;
        } else if (this.router.url.includes('/pending-invoice')) {
            this.title = Constant.INVOICE_STATUS_PENDING;
        } else {
            this.title = null;
        }

        console.log(this.invoiceList.length)
        this.findAllAgency();
        this.invoiceForm = new FormGroup({
            patientId: new FormControl(),
            agencyId: new FormControl(),
            invoiceTo: new FormControl(),
            issueDate: new FormControl('', [Validators.required]),
            issueTo: new FormControl(0, [Validators.required, Validators.min(0)]),
            issueBy: new FormControl(),
            userId: new FormControl(),
            companyId: new FormControl(),
            issueByType: new FormControl(),
            issueToType: new FormControl(),
            invoiceAmount: new FormControl(0),
            invoiceStatus: new FormControl(),
            invoiceNumber: new FormControl()
        });

        // setting default values for super admin and admin types
        // it will be changed and dynamically set when required


        this.invoiceSearch = new FormGroup({
            patientId: new FormControl(),
            agencyId: new FormControl(),
            invoiceTo: new FormControl(),
            issueDate: new FormControl(),
            issueTo: new FormControl(),
            issueBy: new FormControl(),
            userId: new FormControl(),
            companyId: new FormControl(),
            issueByType: new FormControl(),
            issueToType: new FormControl(),
            invoiceAmount: new FormControl(0),
            invoiceStatus: new FormControl(),
        });

        this.countInvoice();
        this.getConstantList(1234);

        this.findAllAdmin();
        this.findAllSuperAdmin();

        this.findIssueByTypes();
        this.findIssueToTypes();

        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.invoiceSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }


    findAllInvoice() {

        this.startLoader();
        if (this.loggedInUserType === 'admin') {
            this.invoiceSearch.controls['issueTo'].setValue(this.loggedInRefId);
        }
        this.invoiceSearch.controls['invoiceStatus'].setValue(this.title);

        let obj = this.utils.getUpdatedMillissecondObject(this.invoiceSearch.value,"issueDate");

        console.table(obj)
        if(obj['issueDate'] === '0'){
            delete obj['issueDate'];
        }
        console.log(obj);
        this.invoiceServices.findAllInvoice(obj, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {


                    // if(this.title === Constant.INVOICE_STATUS_PAID){
                    //     this.invoiceList = res['data'].filter(d => {
                    //         return d.invoiceStatus == 'Paid';
                    //       });

                    // }else if(this.title === Constant.INVOICE_STATUS_PENDING){
                    //     this.invoiceList = res['data'].filter(d => {
                    //         return d.invoiceStatus != 'Paid';
                    //       });

                    // }else{
                    this.invoiceList = res['data'];

                    // }

                } else {
                    this.invoiceList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllAdmin() {
        this.startLoader();
        // currently sending user id from session that is logged in...
        // it will be changed once types of user is Dynamic. right now we are using admin and super admin as
        // read only inputs..
        // this.userService.findAllUsersByBusinessRoleId(Constant.ADMIN_BUSINESS_ROLE_ID).subscribe(result => {
        // this.agencyServices.findAllAgency(null, this.pageNo, this.itemPerPage).subscribe(result => {
        this.agencyServices.findAllAgency(null, 0, 0).subscribe(result => {
            // console.log("result from ums => ",result);
            if (result['statusCode'] == 200) {
                let temp = result['data'];
                for (let object of temp) {
                    let dropDown = { label: '', value: '' };
                    dropDown.value = object['agencyId'];
                    dropDown.label = object['agencyFirstname'];
                    this.issueToUsersList.push(dropDown);
                }
            } else {
                this.issueToUsersList = [];
            }
        });
    }

    findAllSuperAdmin() {
        this.startLoader();
        // currently sending business role id from constants...
        // it will be changed once types of user is Dynamic. right now we are using admin and super admin as
        // read only inputs..
        this.userService.findUserById(sessionStorage.getItem('loggedInUser')).subscribe(result => {
            console.log('user object from ums :: ', result);
            if (result['statusCode'] == 200) {
                let data = result['data'][0];
                let obj = { label: '', value: '' };
                obj.label = data['firstName'];
                obj.value = data['userId'];
                this.issueByUsersList.push(obj);
                console.log('issue by users list :: ', this.issueByUsersList);
                this.stopLoader();
            } else {
                this.issueByUsersList = [];
                this.stopLoader();
            }
        });
    }

    findIssueByTypes() {
        // call a web service when required to provide actual
        // businessroles list for this dropdown;
        let obj = { label: 'Super Admin', value: 394 };
        this.issueByTypeList.push(obj);
    }

    findIssueToTypes() {
        let obj = { label: 'Admin', value: 395 };
        this.issueToTypeList.push(obj);
    }
    deleteRef: any;
    deleteByIdInvoice(invoiceId: number, index) {
        this.deleteRef = this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.invoiceServices.deleteByIdInvoice(invoiceId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.invoiceList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: 'Invoice Deleted Successfully',
                                detail: ''
                            });
                        this.refresh();
                        this.stopLoader();
                    } else {
                        console.error('error: ', res['documentation']);
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
    }

    loadInvoiceToEdit(invoiceId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let invoice = this.invoiceList.find(x => x['invoiceId'] == invoiceId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateInvoiceId = invoice['invoiceId'];
        this.selectedIndex = index;
        if (invoice['patientId']) {
            this.invoiceForm.controls['patientId'].setValue(invoice['patientId']['patientId']);
        }

        if (invoice['agencyId']) {
            this.invoiceForm.controls['agencyId'].setValue(invoice['agencyId']['agencyId']);
        }

        if (invoice['invoiceTo']) {
            this.invoiceForm.controls['invoiceTo'].setValue(invoice['invoiceTo']);
        }

        this.invoiceForm.controls['issueDate'].setValue(new Date(invoice['issueDate']));

        if (invoice['issueTo']) {
            this.invoiceForm.controls['issueTo'].setValue(invoice['issueTo']['agencyId']);
        }

        if (invoice['issueBy']) {
            this.invoiceForm.controls['issueBy'].setValue(invoice['issueBy']['userId']);
        }

        if (invoice['userId']) {
            this.invoiceForm.controls['userId'].setValue(invoice['userId']);
        }

        if (invoice['companyId']) {
            this.invoiceForm.controls['companyId'].setValue(invoice['companyId']['companyId']);
        }

        if (invoice['issueByType']) {
            this.invoiceForm.controls['issueByType'].setValue(invoice['issueByType']);
        }
        if (invoice['issueToType']) {
            this.invoiceForm.controls['issueToType'].setValue(invoice['issueToType']);
        }

        if (invoice['invoiceAmount']) {
            this.invoiceForm.controls['invoiceAmount'].setValue(invoice['invoiceAmount']);
        }

        if (invoice['invoiceNumber']) {
            this.invoiceForm.controls['invoiceNumber'].setValue(invoice['invoiceNumber']);
        }

        // openAddOverlaydiv();

        // by ali
        if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.invoiceForm.reset();
        this.invoiceForm.markAsUntouched();
        this.updateInvoiceId = null;
        // closeAddOverlaydiv();
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    // findAllCompany() {
    //     let temp = [];
    //     this.startLoader();
    //     this.companyServices.findAllCompany(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = {label: '', value: ''};
    //                     dropDown.value = object['companyId'];
    //                     dropDown.label = object['companyName'];
    //                     this.companyList.push(dropDown);
    //                 }
    //             } else {
    //                 this.companyList = [];
    //             }
    //             this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.invoiceForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.invoiceForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.invoiceForm.get(control)?.valid && this.invoiceForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // closeAddOverlaydiv();
        this.invoiceForm.reset();
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.invoiceSearch.reset();
    }

    refresh() {
        this.countInvoice();
    }

    onAddOverlayDiv() {
        this.invoiceForm.reset();
        this.addEditTitle = 'Add';
        // openAddOverlaydiv();
    }

    addItemDetails(event, invoiceObj) {
        // if (this.unSubService !== undefined) {
        //     this.unSubService.unsubscribe();
        // }
        this.ref = this.dialogService.open(InvoiceItemsDetailComponent, {
            header: 'Invoice Detail',
            width: '90%',
            contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                invoiceObj: invoiceObj
            }
        });
        console.log(this.ref, 'onclose reference');
        this.ref.onClose.subscribe(res => {
            console.log(' before confirmation closing');
            this.confirmation.confirm(null).close();
            this.countInvoice();
        });
    }

    printPreview(event, invoiceObj) {
        this.ref = this.dialogService.open(PrintpreviewComponent, {
            header: 'Invoice Detail',
            width: '90%',
            contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                invoiceObj: invoiceObj
            }
        });
    }

    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        // this.searchTerm = eventValue;
        // this.invoiceGlCount();
        this.searchTerm = eventValue.trim();
        if(this.searchTerm) {
            this.invoiceGlCount();
        } else {
            this.countInvoice();
        }
    }

    invoiceGlCount() {
        this.startLoader();
        this.invoiceServices.invoiceGlcount(this.searchTerm,this.loggedInRefId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchInvoice();
            } else {
                this.totalRecords = 0;
                this.invoiceList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    findAllGlSearchInvoice() {
        this.invoiceServices.findGlobalInvoice(this.searchTerm,this.loggedInRefId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.invoiceList = result['data'];
            } else {
                this.invoiceList = [];
            }
            console.log(result, 'result');
            this.stopLoader();
        });
    }

    // by ali
    showHideSearchDialog() {
        if (this.resetTag) {
            this.invoiceSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.invoiceForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateInvoiceId = null;
    }

    // control level security method
    // all users having following roles will be able to see
    // their respective view
    pageControlMap = new Map<string, boolean>()
        .set("viewInvoice", false)
        .set("Invoice", false)
        .set("deleteInvoice", false)
        .set("editInvoice", false);
}
