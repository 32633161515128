import {HttpBackend, HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Constant} from '../util/constant';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
                providedIn: 'root'
            })
export class NonInterceptService {
    private httpClient: HttpClient;

    constructor(handler: HttpBackend, private cookieService: CookieService, private deviceService: DeviceDetectorService) {
        this.httpClient = new HttpClient(handler);
    }

    userLogin(formValue, ip): any {

        console.log(Constant.UMS_URL);


        return this.httpClient.post<string>(
            Constant.UMS_URL + 'login', {
                'staySignedIn': formValue['staySignedIn'],
                'username': formValue.loginusername,
                'password': formValue.password,
                browser: this.deviceService.browser,
                browser_version: this.deviceService.browser_version,
                device: 'laptop',
                os: this.deviceService.os,
                os_version: this.deviceService.os_version,
                'ip': '123'
            });
    }




  userLoginSpring(formValue):Observable<any> {



    return this.httpClient.post<any>(Constant.AUTH_SERVICE_PUBLIC + 'login', {
      'username': formValue.username,
      'password': formValue.password
    }, {

      observe: 'response'
    }).pipe(
      retry(0), catchError(Constant.handleError));


  }

    updateUserStatus(status) {
        return this.httpClient.post<string>(
            Constant.UMS_URL + 'updateUserStatus', {'userId': parseInt(sessionStorage.getItem('loggedInUser')), 'status': status});
    }

    verifyEmail(form) {
        // let SERVER_URL = "http://localhost:8080/CPAUMS/rest/";
        return this.httpClient.post<string>(Constant.UMS_URL + 'ForgetPasswordService/verifyEmail', form);
    }

    findUserByEmail(email) {
        return this.httpClient.post<string>(Constant.UMS_URL + 'findUserByEmail', {email});
    }


    resetPasswordByEmail(form): Observable<HttpResponse<any>> {

    return this.httpClient.post<string>(Constant.AUTH_SERVICE_PUBLIC + 'reset-password', form, { observe: 'response' }).pipe(
      retry(0), catchError(Constant.handleError));
    }



  resetPassword(password, email, hashvalue) {
        return this.httpClient.post<string>(
            Constant.UMS_URL + 'ForgetPasswordService/resetPassword', {'email': email, 'hashValue': hashvalue, 'password': password});
    }



    verificationForPasswordReset(email, hashValue) {
        return this.httpClient.post<string>(
            Constant.Server_URL + 'ForgetPasswordService/verificationForPasswordReset', {'email': email, 'hashValue': hashValue});
    }

    getProjectBusinessRoleList(userId, page, businessRoleId, projectId, type) {

      console.log(userId);
      console.log(businessRoleId);
      console.log(projectId);
      console.log(type);
        return this.httpClient.post<string[]>(
            Constant.UMS_URL + 'getBusinessRole', {
                'cprojectId': projectId,
                'userId': userId,
                'page': page,
                'businessRoleId': businessRoleId,
                'itemsPerPage': Constant.ITEM_PER_PAGE,
                'type': 'none'
            });
    }

    updateCurrentRole(activatedRole, projectId, userId) {
        return this.httpClient.post<string>(
            Constant.UMS_URL + 'updateActiveRole',
            {'cprojectId': projectId, 'userId': userId, 'businessRoleId': activatedRole, 'applicationId': 1}
        );
    }
    getUserPasswordTokenByToken(token): Observable<any>  {
        return this.httpClient.get<string>(
            Constant.AUTH_SERVICE_PUBLIC + 'token/' + token, {headers: new HttpHeaders({'Content-Type': 'application/json'}),
            observe: 'response'} );
    }

    UpdatePasswordByUserPasswordToken(tokenPassword): Observable<any>  {
        return this.httpClient.post<string>(
            Constant.AUTH_SERVICE_PUBLIC + 'user/update-password', tokenPassword, {headers: new HttpHeaders({'Content-Type': 'application/json'}),
            observe: 'response'} );
    }



}
