import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rounded-info-box',
  templateUrl: './rounded-info-box.component.html',
  styleUrls: ['./rounded-info-box.component.css']
})
export class RoundedInfoBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
