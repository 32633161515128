import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {PastMedicalHistoryService} from '../web-services/past-medical-history.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {PastMedicalHistoryMultiSelects} from '../uiUtils/pages/PastMedicalHistoryMultiSelects';
import {InitialHistoryFormDTO} from "../dto/InitialHistoryFormDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";

@Component({
               selector: 'app-past-medical-history',
               templateUrl: './past-medical-history.component.html',
               styleUrls: ['./past-medical-history.component.css']
           })
export class PastMedicalHistoryComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePastMedicalHistoryId = null;
    listOfValuesDetailList: any = [];
    pastMedicalHistoryList: any = [];
    booleanCategoryList: any = [];
    pastMedicalHistorySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    pastMedicalHistoryForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;
    specialQuestions = Constant.PAST_MEDICAL_HISTORY_PREVIOUS_MSK;

    lovDataObject : any = {};

    expertId;
    patientId;
    appointmentId;
    caseId: number;

    constructor(
        private pastMedicalHistoryServices: PastMedicalHistoryService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private pastMedicalHistoryMultiSelects: PastMedicalHistoryMultiSelects
    ) {
    };
    ref: DynamicDialogRef;

    responseData : any;
    refDataObj: any;

    multiSelects: any;
    objectMultiSelects: any;
    multiSelectNames: any;

    specialQuestionsList: any = [];
    specialQuestionsError = false;

    getResponse($event) {

            this.pastMedicalHistoryMultiSelects.getResponseObjects($event);
            console.log(this.pastMedicalHistoryMultiSelects.multiSelects);
    }

    get addUpdateTitle() {
        return this.updatePastMedicalHistoryId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.pastMedicalHistorySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countPastMedicalHistory();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countPastMedicalHistory() {
        this.pastMedicalHistorySearch.controls['expertId'].setValue(this.expertId);
        this.pastMedicalHistorySearch.controls['patientId'].setValue(this.patientId);
        this.pastMedicalHistorySearch.controls['appointmentId'].setValue(this.appointmentId);
        this.pastMedicalHistoryServices.countPastMedicalHistory(this.pastMedicalHistorySearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllPastMedicalHistoryByAppointmentId();
            } else {
                this.totalRecords = 0;
                this.pastMedicalHistoryList = [];
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }

    onSubmit() {
       // this.pastMedicalHistoryForm.markAllAsTouched();
      const pastMedicalHistoryDTO = new InitialHistoryFormDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.pastMedicalHistoryForm);
      console.log(pastMedicalHistoryDTO);
      const diagnosisData = new DiagnosisDataHelper(pastMedicalHistoryDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      console.log(uniqueCaseDiagnosisPredictionList);
      console.log(pastMedicalHistoryDTO.redFlagData);


      if (!pastMedicalHistoryDTO.hasMultiSelectErrors) {
        this.pastMedicalHistoryServices.addPastMedicalHistoryCpa(pastMedicalHistoryDTO).subscribe(res => {

          if (res.status === 200) {
            this.stopLoader();

            this.messageService.add({
              severity: 'success',
              summary: this.translate(this.labels.entityNamePastMedicalHistory) +
                this.translate(this.labels.addedSuccessfully),
            });

            this.findAllPastMedicalHistoryByAppointmentId();

            if (uniqueCaseDiagnosisPredictionList.length > 0) {
              this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

                console.log(res);
              });
            }

            if (pastMedicalHistoryDTO.redFlagData.length > 0) {

              this.lovServices.createListOfCaseRedFlags(pastMedicalHistoryDTO.redFlagData).subscribe(res => {

                if (res.status === 201) {

                  this.messageService.add({
                    severity: 'success',
                    summary: this.translate("Red Flags Updated"),
                  });
                }

              });
            }

            this.submitted = true;
            this.showHideAddDialog();
          }
          else {
            alert ('eror');
          }

        });
      }
      else {

        pastMedicalHistoryDTO.multiSelectErrorFields.forEach(field => {
          document.getElementById('#' + field.dbField  + 'Error').classList.remove('hidden');
        });
      }
     // if (this.pastMedicalHistoryForm.valid) {
           // this.addUpdatePastMedicalHistory(this.pastMedicalHistoryForm.value);
           // this.submitted = false;
            ///this.onClearAdd();
       // }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllPastMedicalHistoryByAppointmentId();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.pastMedicalHistorySearch.reset();
        this.findAllPastMedicalHistoryByAppointmentId();
    }

    addUpdatePastMedicalHistory(pastMedicalHistory) {
        this.startLoader();
        if (this.updatePastMedicalHistoryId == null) {
            this.pastMedicalHistoryServices.addPastMedicalHistory(this.pastMedicalHistoryForm.value,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    pastMedicalHistory = res['data'][0];
                    this.pastMedicalHistoryList.push(pastMedicalHistory);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePastMedicalHistory) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePastMedicalHistory)
                                            });
                    this. showHideAddDialog();
                    this.submitted = true;
                    this.findAllPastMedicalHistoryByAppointmentId();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            pastMedicalHistory.pastMedicalHistoryId = this.updatePastMedicalHistoryId;
            this.pastMedicalHistoryServices.updatePastMedicalHistory(pastMedicalHistory,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.pastMedicalHistoryList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePastMedicalHistory) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePastMedicalHistory)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countPastMedicalHistory();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePastMedicalHistoryId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findBooleanCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('BooleanCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.booleanCategoryList.push(dropDown);
                    }
                } else {
                    this.booleanCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    bloodsCategoryList: any = [];
    imagingCategoryList: any = [];
    systemicCategoryList: any = [];
    illnessCategoryList: any = [];
    threadsCategoryList: any = [];
    findBloods() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Bloods').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.bloodsCategoryList.push(dropDown);
                    }
                } else {
                    this.bloodsCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findImaging() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Imaging').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.imagingCategoryList.push(dropDown);
                    }
                } else {
                    this.imagingCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findSystemic() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Systemic').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.systemicCategoryList.push(dropDown);
                    }
                } else {
                    this.systemicCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findIllness() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Illness').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.illnessCategoryList.push(dropDown);
                    }
                } else {
                    this.illnessCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    findThreads() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Threads').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.threadsCategoryList.push(dropDown);
                    }
                } else {
                    this.threadsCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.lovDataObject = {"refId":0,"refType":""};
        this.multiSelects = this.pastMedicalHistoryMultiSelects.multiSelects;
        this.objectMultiSelects = this.pastMedicalHistoryMultiSelects.objectMultiSelects;

        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));

        this.findBooleanCategory();
        this.findAllListOfValuesDetail();
        this.pastMedicalHistoryForm = new FormGroup({
                                                        specialQuestions: new FormControl(),
                                                        userId: new FormControl(0),
                                                    });
        this.pastMedicalHistorySearch = new FormGroup({
                                                          specialQuestions: new FormControl(),
                                                      });

        this.findAllPastMedicalHistoryByAppointmentId();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.pastMedicalHistorySearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllPastMedicalHistory(): void {
        this.startLoader();

        this.pastMedicalHistoryServices.findAllPastMedicalHistoryCpa(
            this.patientId).subscribe(
            res => {
                if (res.status === 200) {
                    this.pastMedicalHistoryList = res.body;
                } else {
                    this.pastMedicalHistoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllPastMedicalHistoryByAppointmentId(): void {

      this.pastMedicalHistoryServices.findAllPastMedicalHistoryByAppointmentId(
        this.appointmentId).subscribe(
        res => {
          if (res.status === 200) {
            this.pastMedicalHistoryList = res.body;
          } else {
            this.pastMedicalHistoryList = [];
          }
          this.stopLoader();
        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });
    }

    deleteByIdPastMedicalHistory(pastMedicalHistoryId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.pastMedicalHistoryServices.deleteByIdPastMedicalHistory(pastMedicalHistoryId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.pastMedicalHistoryList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNamePastMedicalHistory) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNamePastMedicalHistory)
                                                });
                        this.countPastMedicalHistory();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deletePMH"
                                  });
    }

    loadPastMedicalHistoryToEdit(pastMedicalHistoryId: number, index) {



        this.addEditTitle = this.labels.btnEdit;
        let pastMedicalHistory = this.pastMedicalHistoryList.find(x => x['pastMedicalHistoryId'] == pastMedicalHistoryId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updatePastMedicalHistoryId = pastMedicalHistory['pastMedicalHistoryId'];
        this.selectedIndex = index;

        this.lovDataObject = {"refId":pastMedicalHistory['pastMedicalHistoryId'],"refType":""};

        if(pastMedicalHistory['specialQuestions']['listOfValuesMasterId']) {
            this.pastMedicalHistoryForm.controls['specialQuestions'].setValue(pastMedicalHistory['specialQuestions']['listOfValuesMasterId']);
        }
        if(pastMedicalHistory['bloods']) {
            this.pastMedicalHistoryForm.controls['bloods'].setValue(pastMedicalHistory['bloods']['listOfValuesId']);
        }
        if(pastMedicalHistory['imaging']) {
            this.pastMedicalHistoryForm.controls['imaging'].setValue(pastMedicalHistory['imaging']['listOfValuesId']);
        }
        if(pastMedicalHistory['systemic']) {
            this.pastMedicalHistoryForm.controls['systemic'].setValue(pastMedicalHistory['systemic']['listOfValuesId']);
        }
        if(pastMedicalHistory['illness']) {
            this.pastMedicalHistoryForm.controls['illness'].setValue(pastMedicalHistory['illness']['listOfValuesId']);
        }
        if(pastMedicalHistory['threads']) {
            this.pastMedicalHistoryForm.controls['threads'].setValue(pastMedicalHistory['threads']['listOfValuesId']);
        }
        if(pastMedicalHistory['patientId']) {
            this.pastMedicalHistoryForm.controls['patientId'].setValue(pastMedicalHistory['patientId']['patientId']);
        }
        if(pastMedicalHistory['expertId']) {
            this.pastMedicalHistoryForm.controls['expertId'].setValue(pastMedicalHistory['expertId']['expertId']);
        }

      if(pastMedicalHistory['caseId']) {
        this.pastMedicalHistoryForm.controls['caseId'].setValue(pastMedicalHistory['caseId']['caseId']);
      }
        // this.pastMedicalHistoryForm.controls['userId'].setValue(pastMedicalHistory['userId']);

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
      //  this.pastMedicalHistoryForm.reset();
      //  this.pastMedicalHistoryForm.markAsUntouched();
      //  this.updatePastMedicalHistoryId = null;
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.pastMedicalHistoryForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.pastMedicalHistoryForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.pastMedicalHistoryForm.get(control)?.valid && this.pastMedicalHistoryForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.pastMedicalHistoryForm.reset();
    }

    onCloseSearch() {
        this.pastMedicalHistorySearch.reset();
    }

    refresh() {
        this.countPastMedicalHistory();
    }

    onAddOverlayDiv() {
        this.pastMedicalHistoryForm.reset();
    }

    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.pastMedicalHistorySearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
         //   this.pastMedicalHistoryForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePastMedicalHistoryId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.pastMedicalHistoryServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.pastMedicalHistoryList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.pastMedicalHistoryServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.pastMedicalHistoryList = result['data'];
            } else {
                this.pastMedicalHistoryList = [];
            }
            console.log(result, 'result');
        });
    }
}
