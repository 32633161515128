import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ChatService} from './services/chat/chat.service';
import {CollaborationSharedServiceService} from './services/collaboration-shared-service/collaboration-shared-service.service';
// import { SharedService } from '../services/sharedservice/shared.service';
// import { AlertService } from '../services/alertservice/alert.service';
import {CollabSessionService} from './services/session/collab-session.service';
import {Chat, Participant} from './utilities/model/chat';
import {MatDialog} from '@angular/material/dialog';
// import { DeleteAlertDialog } from '../pop-ups/delete-popup/dialog-delete-alert';
import {showDiv} from './common/common';
// import {SessionService} from '../web-services/session.service';
// import {UserDetail} from '../interfaces/user';
import {ConfirmationService} from 'primeng/api';

@Component({
               selector: 'app-collaboration',
               templateUrl: './collaboration.component.html',
               styleUrls: ['./collaboration.component.scss'],
           })
export class CollaborationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() data;
    @Input() projectToChatModule;
    @Input() selectedVersion;
    @Input() rocketChatUserAuthToken;

    public chats = []; //When First Time Loads
    public chatsinservice = [];   // When Polling start getting chats in this array :)
    public contexualChats = [];  // Storing Contexual Chats
    public contexualChatsinService = [];
    public chatModel;
    public loggedInUser;
    public addParticipantButton;
    public timerSubscription;  // To Poll
    public timeStamp = '';
    public contextTitle;
    public contextId;
    public participantModel;
    public participants = [];

    public pageControlMap = new Map<string, boolean>().set('GeneralChat', false).set('ContextualChat', false);
    userRoles: string[];
    // userDetail: UserDetail;

    constructor(
        private _webService: ChatService,
        public sharedService: CollaborationSharedServiceService,
        // private sessionService: SessionService,
        private dialog: MatDialog,
        private collabSession: CollabSessionService,
        private confirmation: ConfirmationService
    ) {
    }

    //-----------------------------------OnInit-------------------------------
    applicationShortName = 'ums';
    pollingStartFlag = -1;

    ngOnInit() {
        // console.log("hereeee");

        // this.userDetail = this.sessionService.getUserDetails();
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
        // this.loggedInUser = this.userDetail.userId;
        this.currentVersion = this.selectedVersion;
        // setTimeout(() => {
        this.logUserIntoRocketChat();
        // this.getConstants(AppSettings.COLLABORATION_FORM_ID);
        // }, 3000)
        // this.getProjectFromMenu()

        this.chatModel = new Chat(0, '', 0, '', 0, 0, 0, 0, this.loggedInUser, 0);
        this.getAllChats(this.chatModel);
        this.startPolling(this.contextId);

    }


    logUserIntoRocketChat() {
        // console.log(this.rocketChatUserAuthToken)
        // let frame: any = document.getElementById('rcChannel');
        // this.rocketChatUserAuthToken = "Fx66r9JgLRiTEcVGP1CNqXz2D24nDdmRzL0ktbaLHQK"
        // window.parent.postMessage({event: 'login-with-token',loginToken: 'bER_mjdHKDqSFRyM2kBu_OxensCEa1L3lvjFbtuOKMu'}, 'http://192.168.2.42:3000');
        // console.log(frame)
        // setTimeout(() => {
        //     frame.contentWindow.postMessage(
        //         {event: 'login-with-token', loginToken: this.rocketChatUserAuthToken}, 'http://192.168.2.42:3000');
        // }, 3000);
        // console.log("event posted")
    }

    constantList = [];
    // getConstants(formId) {
    //   this._webService.getFormConstants(formId).subscribe((data: string) => {
    //     if (data && data['statusCode'] == 200) {

    //       this.constantList = data['data'] as string[];
    //       this.sharedService.constantlist = this.constantList;
    //       // console.log("constantList :: ipdated :: " , this.constantList);
    //     }
    //   });
    // }

    currentVersion = 0;
    // checkVersion() {
    //   this.sharedService.CurrentVersion.subscribe(data => {
    //     this.currentVersion = data;
    //   });
    // }

    ngOnChanges() {
        // this.timeStamp = '';
        this.getProjectFromMenu();
        // this.pollingStartFlag += 1;
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
        // this.loggedInUser = this.userDetail.userId;
        // if (this.pollingStartFlag > 0) {
        this.filteredContexualChats = [];
        this.filteredChats = [];
        this.timeStamp = '';
        this.clickedTab('');
        // }
        // console.log("currentVersion", this.selectedVersion, this.data['currentVersion'])
        this.sharedService.setCurrentVersion(this.data['currentVersion']);
        // this.checkVersion()
        this.startPolling(this.contextId);

        // this.getConstants(AppSettings.COLLABORATION_FORM_ID);
    }


    public projectObject;
    public project;
    public projectId;
    public projectTitle;

    getProjectFromMenu() {
        // this.projectObject = this.globalSharedService.getCookie("project");
        if (this.projectObject) {
            this.project = JSON.parse(this.projectObject);
            // console.log(this.project);
            this.projectId = this.project['projectId'];
            this.projectTitle = this.project['title'];
            this.contextId = this.projectId;
            this.contextTitle = this.projectTitle;
            this.collabSession.contextId = this.contextId;
            this.collabSession.contextTitle = this.contextTitle;
        }
        // this.checkApplicationShortNameAndAssign();
        // this.assignUserRolesAndPageControlMap();
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
        // this.loggedInUser = this.userDetail.userId;
        this.collabSession.chatId = this.sharedService.ZER0;
        this.collabSession.isAddParticipantClicked = false; //setting addParticipantButton Value to false;
        this.chatModel = new Chat(0, '', 0, '', 0, 0, 0, 0, this.loggedInUser, 0); //  Initializing the Chat Model
        if (this.loggedInUser) {
            if (this.pageControlMap.get('GeneralChat')) {
                this.getAllChats(this.chatModel);
            }
            this.startPolling(this.contextId);
        }
    }

    onTabChanged(event) {
        // console.log(event)
        switch (event.index) {
            case 0:
                this.collabSession.isContexualTab = false;
                // console.log("contextTab :: ", this.collabSession.isContexualTab)
                break;

            case 1:
                this.collabSession.isContexualTab = true;
                // console.log("contextTab :: ", this.collabSession.isContexualTab)
                break;
        }
    }


    openDeleteDialog(element, index) {
        // const dialogRef = this.dialog.open(DeleteAlertDialog, {
        //   data: {
        //     title: "Delete Subscription",
        //     message: "Are you sure you want to Delete this Chat"
        //   }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   if (result) {
        //     this.deleteChat(element, index)
        //   }
        //   else {
        //   }
        // });
        // console.log('delete or hide');

        this.confirmation.confirm({
                                      message: 'Are you sure that you want to Hide ?', accept: () => {
                this.deleteChat(element, index);

            }
                                  });
    }

    selectedChat: any;
    // onSelectedChat(chat) {
    //   this.selectedChat = chat;
    // }

    // showUsersComponent = false;
    showUsers() {
        this.sharedService.alreadyAddedParticipants = [];
        this.showUsersComponent = false;
        this.sharedService.participants = [];
    }


    searchChatText = '';

    searchGeneralChats() {
        this.filteredChats = this.sharedService.filterArray(this.chats, this.searchChatText);
        if (this.filteredChats.length === 0) {
            // this.toastr.showCustomInfoAlert("No Chats Found");
        }
    }

    searchContexualText = '';

    searchContexualChats() {
        this.filteredContexualChats = this.sharedService.filterArray(this.contexualChats, this.searchContexualText);
        if (this.filteredContexualChats.length === 0) {
            // this.toastr.showCustomInfoAlert("No Chats Found");
        }
    }

    // checkApplicationShortNameAndAssign() {
    //   if (this.globalSharedService.getCookie('applicationShortName')) {
    //     this.applicationShortName = this.globalSharedService.getCookie('applicationShortName')
    //   }
    // }

    // assignUserRolesAndPageControlMap() {
    //   if (this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') && this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') != undefined) {
    //     this.userRoles = this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles');
    //     this.pageControlMap = this.globalSharedService.checkRole(this.pageControlMap, this.userRoles);
    //   }
    // }

    //-----------------------------Getting All Chats---------------------------

    filteredChats = [];

    getAllChats(chatModel): void {
        this.chats = [];
        this._webService.getChats(chatModel, this.timeStamp).subscribe(
            data => {
                if (data && data['statusCode'] == 200) {
                    try {
                        this.sharedService.chats = data['data'];
                        this.chats = this.sharedService.chats;
                        this.filteredChats = this.chats;
                        this.timeStamp = data['timeStamp'];
                        if (this.chats) {
                            this.chats.sort((val1, val2) => {
                                return val2.lastUpdateTimestamp - val1.lastUpdateTimestamp;
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            error => {
                console.log(error);
            }
        );
        this.chatModel = new Chat(0, '', 0, '', 0, 0, 0, 0, this.loggedInUser, 0);
    }

    deleteChat(chat, index): void {
        this._webService.deleteChat(chat.chatId,parseInt(sessionStorage.getItem('loggedInUser'))).subscribe(
            data => {
                if (data && data['statusCode'] == 200) {
                    if (chat.referenceId > 0) {
                        this.contexualChats.splice(index, 1);
                    } else {
                        this.chats.splice(index, 1);
                    }
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    //-----------------------Getting All Chats Again in polling-----------------

    getChatsAgain(): void {
        this._webService.getChats(this.chatModel, this.timeStamp).subscribe(
            data => {
                if (data && data['statusCode'] == 200) {
                    try {
                        this.chatsinservice = data['data'];
                        this.timeStamp = data['timeStamp'];

                        if (this.chatsinservice != null) {
                            for (var i = 0; i < this.chatsinservice.length; i++) {
                                var updated = false;
                                for (var j = 0; j < this.chats.length; j++) {
                                    if (this.chatsinservice[i].chatId == this.chats[j].chatId) {
                                        this.chats[j].lastMessageId = this.chatsinservice[i].lastMessageId;
                                        this.chats[j].lastMessageReadStatus = this.chatsinservice[i].lastMessageReadStatus;
                                        this.chats[j].lastMessageContent = this.chatsinservice[i].lastMessageContent;
                                        this.chats[j].lastUpdateByUser = this.chatsinservice[i].lastUpdateByUser;
                                        this.chats[j].lastUpdateTimestamp = this.chatsinservice[i].lastUpdateTimestamp;
                                        this.chats[j].messageCount = this.chatsinservice[i].messageCount;
                                        updated = true;

                                        if (this.chats[j].cbGroupData == null) {
                                            if (this.chats[j].chatId == this.collabSession.chatId) {
                                            } else if (this.chats[j].lastUpdateByUser == this.loggedInUser) {
                                            } else {
                                                if (this.chats[j].firstUser.userId == this.loggedInUser) {
                                                    // this.toastr.showCustomInfoAlert("new Message From " + this.chats[j].secondUser.username);
                                                } else {
                                                    // this.toastr.showCustomInfoAlert("new Message From " + this.chats[j].firstUser.username);
                                                }
                                            }
                                        }
                                        //currently working here for participant check
                                        else if (this.chats[j].cbGroupData != null) {
                                            this.participantModel = new Participant(
                                                0, '', '', this.chats[j].cbGroupData['groupDataId'], 0, 0, 0);
                                            this._webService.getParticipants(this.participantModel).subscribe(data => {
                                                this.participants = data['data'];

                                                var flag_found;
                                                for (var k = 0; k < this.participants.length; k++) {
                                                    if (this.participants[k]['participant']['userId'] == this.loggedInUser) {
                                                        flag_found = true;
                                                    }
                                                }
                                                if (flag_found == true) {
                                                } else {
                                                    this.chats.splice(this.chats[j], 1);
                                                }

                                            });

                                            if (this.chats[j].chatId == this.collabSession.chatId) {
                                            } else if (this.chats[j].lastUpdateByUser == this.loggedInUser) {
                                            } else {
                                                // this.toastr.showCustomInfoAlert("new Message From " + this.chats[j].cbGroupData.title);
                                            }
                                        }
                                        break;
                                    }
                                }

                                if (updated == true) {
                                } else {
                                    this.chats.push(this.chatsinservice[i]);
                                }
                            }
                        }

                        if (this.chats) {
                            // console.log("chats found");
                            this.chats.sort((val1, val2) => {
                                return val2.lastUpdateTimestamp - val1.lastUpdateTimestamp;
                            });
                            this.filteredChats = this.chats;
                        }

                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            error => {
                console.log(error);
                clearInterval(this.timerSubscription);
            }
        );
        this.chatModel = new Chat(0, '', 0, '', 0, 0, 0, 0, this.loggedInUser, 0);

    }

    //-----------------------------------Contexual Chats---------------------------------


    getContexualChatsAgain() {
        // // console.log("here");
        if (this.contextId > 0) {
            var contextchatModel = new Chat(0, '', this.contextId, '', 0, 0, 0, 0, this.loggedInUser, 0);
            this._webService.getContexualChats(contextchatModel, this.timeStamp).subscribe(
                data => {
                    try {
                        if (data && data['statusCode'] == 200) {
                            this.contexualChatsinService = data['data'];
                            this.timeStamp = data['timeStamp'];
                            if (this.contexualChatsinService) {
                                for (var i = 0; i < this.contexualChatsinService.length; i++) {
                                    // // console.log(this.contexualChatsinService.length);
                                    var updated = false;
                                    if (this.contexualChats != null || this.contexualChats != undefined) {
                                        for (var k = 0; k < this.contexualChats.length; k++) {
                                            if (this.contexualChatsinService[i].chatId == this.contexualChats[k].chatId) {
                                                this.contexualChats[k].lastMessageId = this.contexualChatsinService[i].lastMessageId;
                                                this.contexualChats[k].lastMessageContent = this.contexualChatsinService[i].lastMessageContent;
                                                this.contexualChats[k].lastMessageReadStatus = this.contexualChatsinService[i].lastMessageReadStatus;
                                                this.contexualChats[k].lastUpdateByUser = this.contexualChatsinService[i].lastUpdateByUser;
                                                this.contexualChats[k].lastUpdateTimestamp = this.contexualChatsinService[i].lastUpdateTimestamp;
                                                this.contexualChats[k].messageCount = this.contexualChatsinService[i].messageCount;
                                                updated = true;
                                                if (this.contexualChats[k].cbGroupData == null) {
                                                    if (this.contexualChats[k].chatId == this.collabSession.chatId) {
                                                    } else if (this.contexualChats[k].lastUpdateByUser == this.loggedInUser) {
                                                    } else {
                                                        if (this.contexualChats[k].firstUser.userId == this.loggedInUser) {
                                                            // this.toastr.showCustomInfoAlert("new Message From " + this.contexualChats[k].secondUser.username);
                                                        } else {
                                                            // this.toastr.showCustomInfoAlert("new Message From " + this.contexualChats[k].firstUser.username);
                                                        }
                                                    }
                                                }

                                                //currently working here for participant check
                                                else if (this.contexualChats[k].cbGroupData != null) {
                                                    this.participantModel = new Participant(
                                                        0, '', '', this.contexualChats[k].cbGroupData['groupDataId'], 0, 0, 0);
                                                    this._webService.getParticipants(this.participantModel).subscribe(data => {
                                                        this.participants = data['data'];
                                                        var flag_found;
                                                        for (var k = 0; k < this.participants.length; k++) {
                                                            if (this.participants[k]['participant']['userId'] == this.loggedInUser) {
                                                                flag_found = true;
                                                            }
                                                        }
                                                        if (flag_found == true) {
                                                        } else {
                                                            this.contexualChats.splice(this.contexualChats[k], 1);
                                                        }

                                                    });

                                                    if (this.contexualChats[k].chatId == this.collabSession.chatId) {
                                                    } else if (this.contexualChats[k].lastUpdateByUser == this.loggedInUser) {
                                                    } else {
                                                        // this.toastr.showCustomInfoAlert("new Message From " + this.contexualChats[k].cbGroupData.title);
                                                    }
                                                }
                                                break;
                                            }
                                        }
                                    } else {
                                        updated = true;
                                        this.contexualChats = [];
                                        this.contexualChats.push(this.contexualChatsinService[i]);
                                    }

                                    if (updated == true) {
                                    } else {
                                        this.contexualChats.push(this.contexualChatsinService[i]);
                                    }
                                }
                                this.contexualChats.sort((val1, val2) => {
                                    return val2.lastUpdateTimestamp - val1.lastUpdateTimestamp;
                                });
                                this.filteredContexualChats = this.contexualChats;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
                error => {
                    console.log(error);
                }
            );
            this.chatModel = new Chat(0, '', 0, '', 0, 0, 0, 0, this.loggedInUser, 0);
        }

    }


    //------------------------Getting All Contexual Chats-----------------------

    filteredContexualChats = [];

    getAllContexualChats(): void {
        this.chatModel = new Chat(0, '', this.contextId, '', 0, 0, 0, 0, this.loggedInUser, 0);
        this._webService.getContexualChats(this.chatModel, this.timeStamp).subscribe(
            data => {
                if (data && data['statusCode'] == 200) {
                    this.contexualChats = data['data'];
                    this.sharedService.contexualChats = this.contexualChats;
                    this.filteredContexualChats = this.contexualChats;
                    if (this.contexualChats) {
                        this.contexualChats.sort((val1, val2) => {
                            return val2.lastUpdateTimestamp - val1.lastUpdateTimestamp;
                        });
                    }
                }
            },
            error => {
                console.log(error);
            }
        );

    }

    //-------------------------On Collabortator Selection------------------------


    onSelect(chat): void {                               // When Any Chat is Selected
        this.selectedChat = chat;
        this.selectedChat.messageCount = 0;
        // console.log(chat);
        try {
            this.collabSession.chatId = chat.chatId; //  Setting ChatId for getting the messages
            if (chat.cbGroupData != null) {  // If Selected Chat is GROUP CHAT and Not an INDIVIDUAL CHAT.

                //----------Setting Group Properties--------------------
                if (chat.firstUser.userId == this.loggedInUser) {
                    this.collabSession.isLoggedInUser = true;
                } else {
                    this.collabSession.isLoggedInUser = false;
                }
                this.collabSession.isSingleChat = false; // Setting that chat is a group chat
                this.collabSession.groupId = chat.cbGroupData.groupDataId; // setting Group Id
                this.collabSession.username = chat.cbGroupData.title; // Setting Group Title
                this.collabSession.status = null; // Status will be null of Group
                this.collabSession.secondUserId = this.sharedService.ZER0; // Setting Second User 0 mean No Second User.
            }

            //----Checking if Chat's Second User is loggedIn One------
            else if (chat.secondUser.userId == this.loggedInUser) {  // If Selected Chat is an INDIVIDUAL CHAT And Chat is Initialized By Other User..

                this.collabSession.isSingleChat = true;  // Setting that chat is an individual chat
                this.collabSession.groupId = this.sharedService.ZER0; // Setting Second User 0 mean No Group Chat.
                this.collabSession.username = chat.firstUser.username;  // username of User
                this.collabSession.firstName = chat.firstUser.firstName;
                this.collabSession.lastName = chat.firstUser.lastName;
                this.collabSession.status = chat.firstUser.status; // // Status of user whether online or offline.
                this.collabSession.userLog = chat.firstUser.userLog;
                this.collabSession.secondUserId = chat.firstUser.userId; // // userid of person who initialized the chat.
            }

            //----Chat Second first User is loggedIn One------------
            else { // If Selected Chat is an INDIVIDUAL CHAT And Chat is Initialized By LoggedIn User..

                this.collabSession.isSingleChat = true;  // Setting that chat is an individual chat
                this.collabSession.groupId = this.sharedService.ZER0; // Setting Second User 0 mean No Group Chat.
                this.collabSession.username = chat.secondUser.username;  // username of User
                this.collabSession.firstName = chat.secondUser.firstName;
                this.collabSession.lastName = chat.secondUser.lastName;
                this.collabSession.status = chat.secondUser.status; // Status of user whether online or offline.
                this.collabSession.status = chat.secondUser.status;
                this.collabSession.userLog = chat.secondUser.userLog;
                this.collabSession.secondUserId = chat.secondUser.userId; // userid of person whom chat is started.
            }

            //----------------------Navigating To Chat Component-------------------
        } catch (error) {
        }
        chat.lastMessageReadStatus = 'read';
        this.clickedTab('messageBox');
    }


    //-----------------Checking Whether Chat is Individual Or Group-------------

    chatCheck(chat): string {
        try {
            if (chat.cbGroupData == null) { // checking if group chat or single chat
                if (chat.secondUser.userId == this.loggedInUser) { //Checking Whether Second User is Logged In user or the first one
                    return chat.firstUser.firstName;
                    // return chat.firstUser.username + ' ' + chat.firstUser.lastName;
                    // return chat.firstUser.username;
                } else {
                    return chat.secondUser.firstName;
                    // return chat.secondUser.username + ' ' + chat.secondUser.lastName;
                    // return chat.secondUser.username;
                }
            } else {
                return chat.cbGroupData.title;
            }
        } catch (error) {
            // console.log(error);
        }
    }

    //------------------------Returning Status Icon Class-----------------------

    checkUserStatus(chat): string {
        const current = new Date();
        current.setHours(0);
        current.setMinutes(0);
        current.setSeconds(0);
        current.setMilliseconds(0);
        const currentTime = current.getTime();
        try {
            if (chat.secondUser && chat.secondUser.userId == this.loggedInUser) {
                if (chat.firstUser.userLog != null) {
                    if (chat.firstUser.status == 'online' && chat.firstUser.userLog.tokenExpiry > currentTime) {
                        return 'is online';
                    } else {
                        return 'is offline';
                    }
                } else {
                    return 'is offline';
                }
            } else {
                if (chat.secondUser && chat.secondUser.userLog != null) {
                    if (chat.secondUser.status == 'online' && chat.secondUser.userLog.tokenExpiry > currentTime) {
                        return 'is online';
                    } else {
                        return 'is offline';
                    }
                } else {
                    return 'is offline';
                }
            }
        } catch (error) {
            console.log(error);

        }
    }

    //------------------------Returning Status Icon Class-----------------------

    statusIcon(chat): string {
        const current = new Date();
        current.setHours(0);
        current.setMinutes(0);
        current.setSeconds(0);
        current.setMilliseconds(0);
        const currentTime = current.getTime();
        try {
            if (chat.secondUser && chat.secondUser.userId == this.loggedInUser) {
                if (chat.firstUser.userLog != null) {
                    if (chat.firstUser.status == 'online' && chat.firstUser.userLog.tokenExpiry > currentTime) {
                        return 'online_icon';
                    } else {
                        return 'user-status is-offline';
                    }
                } else {
                    return 'user-status is-offline';
                }
            } else {
                if (chat.secondUser && chat.secondUser.userLog != null) {
                    if (chat.secondUser.status == 'online' && chat.secondUser.userLog.tokenExpiry > currentTime) {
                        return 'online_icon';
                    } else {
                        return 'user-status is-offline';
                    }
                } else {
                    return 'user-status is-offline';
                }
            }
        } catch (error) {
            console.log(error);

        }
    }

    checkUserImage(chat) {
        if (chat.firstUser.userId == this.loggedInUser) {
            if (chat.secondUser) {
                return chat.secondUser.profileImage;
            } else {
                return null;
            }
        } else {
            return chat.firstUser.profileImage;
        }
    }

    //------------------Showing And Hiding Online Offline Icon------------------

    checkStatus(chat): Boolean {
        try {
            if (chat.cbGroupData == null) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    //---------------------Opening New Chat And GroupChat Model-------------------

    openUsers(): void {
        this.collabSession.isNewChatButtonClicked = true; // Setting it for Hiding Save Button On Individual New Chat Pop Up.
        this.collabSession.isAddParticipantClicked = false;
    }

    //------------------------Load Both tabs----------------------------------------


    startPolling(contextId) {
        // console.log("polling");

        // console.log("Chatting this.sharedService.timeStamp", this.sharedService.timeStamp);
        if (contextId > 0) {
            this.getAllContexualChats();

        }
        // this.timerSubscription = setInterval(() => {
        this.getChatsAgain();

        this.getContexualChatsAgain();

        if (this.collabSession.chatId > 0) {
        }
        // }, this.getChattingTimeInterval());
    }


    changeInterval() {
        // // console.log("collaboration Component");
        clearInterval(this.timerSubscription); // stop the setInterval()
        this.setChattingInterval(); // start the setInterval()
    }

    setChattingInterval() {
        this.timerSubscription = setInterval(() => {
            if (this.pageControlMap.get('GeneralChat')) {
                this.getChatsAgain();
            }
            if (this.pageControlMap.get('ContextualChat')) {
                this.getContexualChatsAgain();
            }
            if (this.collabSession.chatId > 0) {
            }
        }, this.getChattingTimeInterval());
    }

    getChattingTimeInterval() {
        return this.sharedService.timeStamp;
    }

    ngOnDestroy() {
        clearInterval(this.timerSubscription);
    }

    verifyLabel(label) {
        return label;
    }

    leaveGroup(chat, index) {
        this._webService.leaveGroup(this.loggedInUser, chat.cbGroupData.groupDataId).subscribe(
            data => {
                try {
                    if (data && data['statusCode'] == 200) {
                        // this.toastr.showSuccess("Leave", "Group");
                        // Checking Participants Again And Deleteing Group
                        this.participantModel = new Participant(0, '', '', chat.cbGroupData.groupDataId, 0, 0, 0);
                        this._webService.getParticipants(this.participantModel).subscribe(data => {
                            this.participants = data['data'];
                            if (data['data'] == null) {
                                this.deleteChat(chat, index);
                            } else {
                                if (chat.referenceId > 0) {
                                    this.contexualChats.splice(index, 1);
                                } else {
                                    this.chats.splice(index, 1);
                                }
                            }
                            this.sharedService.alreadyAddedParticipants = this.participants;
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    editGroup(chat) {

        this.collabSession.groupId = chat.cbGroupData.groupDataId;
        this.selectedChat = chat;
        this.clickedTab('participant');
    }

    ChatDeleted(event) {
        if (event.referenceId > 0) {
            var index = this.contexualChats.findIndex(x => x === event);
            if (index != -1) {
                this.contexualChats.splice(index, 1);
            }
        } else {
            var index = this.chats.findIndex(x => x === event);
            if (index != -1) {
                this.chats.splice(index, 1);
            }
        }
        this.collabSession.isAddParticipantClicked = false;
        this.clickedTab('');
    }

    onLeaveGroup(event) {
        if (event.referenceId > 0) {
            var index = this.contexualChats.findIndex(x => x === event);
            if (index != -1) {
                this.leaveGroup(event, index);
                // this.deleteChat(event, index)
            }
        } else {
            var index = this.chats.findIndex(x => x === event);
            if (index != -1) {
                this.leaveGroup(event, index);
                // this.deleteChat(event, index)
            }
        }
        this.clickedTab('');
    }


    // When Creating New Chat UserComponent emit the Chat
    emitNewChat(event) {
        // console.log("log event emit new chat");

        if (event.referenceId > 0) {
            if (this.contexualChats) {
                var index = this.contexualChats.findIndex(x => x === event);
                if (index === -1) {
                    this.contexualChats.unshift(event);
                    this.filteredContexualChats = this.contexualChats;
                }
            } else {
                this.contexualChats.push(event);
                this.filteredContexualChats = this.contexualChats;
            }
        } else {
            if (this.chats) {
                var index = this.chats.findIndex(x => x === event);
                if (index === -1) {
                    this.chats.unshift(event);
                    this.filteredChats = this.chats;
                }
            } else {
                this.chats.push(event);
                this.filteredChats = this.chats;
            }
        }

    }

    updateLastMessage(event) {
        // console.log("last message to see");

        this.selectedChat.lastMessageContent = event.lastMessageContent;

        if (this.chats) {
            var index = this.chats.findIndex(x => x.chatId === event.chatId);
            var findInList = this.chats.find(x => x.chatId == event.chatId);
            // console.log(findInList);
            if (findInList == undefined) {
                this.chats.unshift(event);
                this.filteredChats = this.chats;
            }
        } else {
            this.chats.push(event);
            this.filteredChats = this.chats;
        }
    }


    afterGroupCreated(event) {
        this.sharedService.alreadyAddedParticipants = [];
        // console.log("here chatsAgain");
        this.getChatsAgain();
        this.getContexualChatsAgain();
        this.clickedTab('');
    }

    afterGroupUpdated(event) {
        this.sharedService.alreadyAddedParticipants = [];
        this.collabSession.isAddParticipantClicked = false;
        if (event) {
            this.getChatsAgain();
            this.getContexualChatsAgain();
            this.clickedTab('');
        } else {
            this.clickedTab('');
        }
    }

    closeParticipantComponent() {
        this.sharedService.alreadyAddedParticipants = [];
        this.collabSession.isAddParticipantClicked = false;
        this.clickedTab('');
    }


    openGroupParticipants(event) {
        this.selectedChat = event;
        this.clickedTab('participant');
    }


    destroyMessageBoxComponent(event) {
        this.selectedChat = event;
        this.clickedTab('');
    }

    goToUserComponentToAddParticipants(event) {
        this.clickedTab('groupChat');
    }

    moveToGroupParticipantScreen() {
        this.clickedTab('participant');
    }

    onEmittedSelectedChat(event) {
        this.selectedChat = event;
        this.clickedTab('');
    }

    onEmittedSelectedUser(event) {
        this.sharedService.participants = [];
        this.selectedChat = event;
        this.clickedTab('messageBox');
    }


    onEmittedSelectedGroup(event) {
        if (event) {
            this.selectedChat = event;
            setTimeout(() => {
                showDiv('createselectusernewchat-overlaydiv');
                // openselectusercreatenewchatoverlaydiv();
            }, 1000);
        }

    }

    showMessageBoxComponent = false;
    showChatComponent = false;
    showUsersComponent = false;
    showGroupParticipantComponent = false;

    clickedTab(action) {
        switch (action) {
            case 'messageBox':
                this.showMessageBoxComponent = true;
                this.showChatComponent = false;
                this.showUsersComponent = false;
                this.showGroupParticipantComponent = false;
                setTimeout(() => {
                    showDiv('chatbox-overlaydiv');
                }, 10);
                break;
            case 'newChat':
                this.showMessageBoxComponent = false;
                this.showChatComponent = false;
                this.showUsersComponent = true;
                this.showGroupParticipantComponent = false;
                this.newChatButtonClicked();
                setTimeout(() => {
                    showDiv('createselectusernewchat-overlaydiv');
                }, 10);
                break;
            case 'groupChat':
                this.showMessageBoxComponent = false;
                this.showChatComponent = false;
                this.showUsersComponent = true;
                this.showGroupParticipantComponent = false;
                this.newGroupChatButtonClicked();
                setTimeout(() => {
                    showDiv('createselectusernewchat-overlaydiv');
                }, 10);
                break;

            case 'participant':
                this.showMessageBoxComponent = false;
                this.showChatComponent = false;
                this.showUsersComponent = false;
                this.showGroupParticipantComponent = true;
                this.newGroupChatButtonClicked();
                setTimeout(() => {
                    showDiv('groupinfochat-overlaydiv');
                }, 10);
                break;

            default:
                this.collabSession.isAddParticipantClicked = false;
                this.collabSession.isNewChatButtonClicked = false;
                this.showMessageBoxComponent = false;
                this.showChatComponent = false;
                this.showUsersComponent = false;
                this.showGroupParticipantComponent = false;
                break;
        }

    }

    newChatButtonClicked(): void {
        this.collabSession.isNewChatButtonClicked = true;
        this.collabSession.isAddParticipantClicked = false;
        setTimeout(() => {
            showDiv('createselectusernewchat-overlaydiv');
        }, 1000);

    }

    //------------------When New Group Chat Button is Clicked------------------

    newGroupChatButtonClicked(): void {
        this.collabSession.isNewChatButtonClicked = false;
        // this.collabSession.isAddParticipantClicked = false;
        var index = this.sharedService.participants.findIndex(x => x == sessionStorage.getItem('loggedInUser'));
        if (index === -1) {
            this.sharedService.participants.push(sessionStorage.getItem('loggedInUser'));
        }
    }


}
