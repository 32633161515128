<!-- Header Component-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">

        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNamePayments)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNamePayments)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNamePayments)}}
                                </p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="paymentsSearch" class="pngForm"
                                    id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnPaidOn)}}</label>
                                            <p-calendar appendTo="body" [monthNavigator]="true" [showIcon]="true"
                                                class="pngCalendar " dataType="string"
                                                dateFormat="yy-mm-dd" formControlName="paidOn" showButtonBar="true"></p-calendar>
                                        </div>
                                       
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnSource)}}</label>
                                           <p-dropdown [filter]="true" [options]="sourcesList" [showClear]="true"
                                                class="pngDropdown" formControlName="source"
                                                placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnBalanceSummary)}}</label>
                                            
                                                <p-dropdown [filter]="true" [options]="balanceSummaryList" [showClear]="true"
                                                class="pngDropdown" formControlName="balanceSummary"
                                                placeholder="Select"></p-dropdown>
                                        </div>
                                        
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnPaymentDescription)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('paymentDescription')"
                                                    *ngIf="diplayeIconInField('paymentDescription')"
                                                    class="pi pi-times color-danger"></i> <input class="pngInputtext"
                                                    formControlName="paymentDescription" name="paymentDescription"
                                                    pInputText type="text"> </span>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnPaymentType)}} </label>
                                            <p-dropdown [filter]="true" [options]="paymentTypeList"
                                                [showClear]="true" class="pngDropdown" formControlName="paymentType"
                                                placeholder="Select">
                                            </p-dropdown>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnAmount)}}</label>
                                            <p-inputNumber type="number" pNumberInput formControlName="paymentAmount"
                                                name="paymentAmount" class="p-invalid pngInputnumber" mode="decimal"
                                                [useGrouping]="false"></p-inputNumber>
                                        </div>
                                        
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search"
                                                  (click)="onSearch()"
                                                  class="pngBlueBtn"
                                                  styleClass="p-button">
                                        </p-button>
                                        <p-button label="Clear"
                                                  (click)="clearSearchResult()"
                                                  class="pngBlueBtn"
                                                  styleClass="p-button">
                                        </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideSearchDialog()"
                                                  class="pngBtnTransparent"
                                                  styleClass="p-button">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DiallogBox -->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNamePayments)}}
                                </p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="paymentsForm" class="pngForm"
                                    id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnPaidOn)}}</label>
                                            <p-calendar appendTo="body" [class.p-invalid]="inputFieldDanger('paidOn')"
                                                [monthNavigator]="true" [showIcon]="true"
                                                class="p-invalid pngCalendar " dataType="string"
                                                dateFormat="yy-mm-dd" formControlName="paidOn" showButtonBar="true"></p-calendar>
                                            <app-custom-error [control]="'paidOn'" [form]="paymentsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnSource)}}</label>
                                            
                                                <p-dropdown [filter]="true" [options]="sourcesList" 
                                                [showClear]="true"
                                                class="pngDropdown" formControlName="source"
                                                placeholder="Select">
                                                </p-dropdown>
                                            <app-custom-error [control]="'source'" [form]="paymentsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnBalanceSummary)}}</label>
                                                <p-dropdown [filter]="true" [options]="balanceSummaryList" [showClear]="true"
                                                class="pngDropdown" formControlName="balanceSummary"
                                                placeholder="Select">
                                                </p-dropdown>
                                            <app-custom-error [control]="'balanceSummary'" [form]="paymentsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnPaymentDescription)}}</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('paymentDescription')"
                                                    *ngIf="diplayeIconInField('paymentDescription')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('paymentDescription')"
                                                    class="pngInputtext" formControlName="paymentDescription"
                                                    name="paymentDescription" pInputText pInputText type="text"></span>
                                            <app-custom-error [control]="'paymentDescription'" [form]="paymentsForm">
                                            </app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnPaymentType)}}</label>
                                            <p-dropdown [filter]="true" [options]="paymentTypeList"
                                                [showClear]="true" class="pngDropdown" formControlName="paymentType"
                                                placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'paymentType'" [form]="paymentsForm">
                                            </app-custom-error>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.paymentsColumnAmount)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('paymentAmount')"
                                                           [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="paymentAmount"
                                                           mode="decimal" name="paymentAmount"
                                                           pNumberInput
                                                           type="number">
                                            </p-inputNumber>
                                            <app-custom-error [control]="'paymentAmount'" [form]="paymentsForm"></app-custom-error>
                                        </div>

                                    </div>

                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                  type="submit"
                                                  class="pngBlueBtn"
                                                  styleClass="p-button">
                                        </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  class="pngBtnTransparent"
                                                  styleClass="p-button">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                   <span
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search" (click)= "onGlobalSearchClick(global.value)"></i>
                                        <input #global
                                            class="pngInputtext" pInputText
                                            placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                            type="text" /></span>
                                   
                                </p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" 
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="paymentsList" class="pngTable" id="invitationpage-memberslist-table" [ngClass]="{'pngTableEmptyMessage': paymentsList?.length == 0}"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Paid On</th>
                                            <th> Source</th>
                                            <th> Balance Summary</th>
                                            <th> Payment Type</th>
                                            <th> Total Amount </th>
                                            <th> Paid Amount </th>
                                            <th> Remaining Amount </th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.paidOn | date: 'yyyy-MM-dd' }} </td>
                                            <td> {{element?.source?.label}} </td>
                                            <td> {{element?.balanceSummary?.label}} </td>
                                            <td> {{element?.paymentType.label}} </td>
                                            <td> {{element?.paymentAmount}} </td>
                                            <td> {{element?.paidAmount}} </td>
                                            <td> {{calculateRemainigAmount(element)}}</td>
                                            <!-- <td> {{element?.paymentAmount - element?.paidAmount}}</td> -->
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button (click)="loadPaymentsToEdit(element.paymentId, i)"
                                                        icon="pi pi-pencil" pTooltip="Edit"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdPayments(element.paymentId,i)"
                                                        icon="pi pi-trash" pTooltip="Delete"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                        <!-- *ngIf = "element?.paymentAmount > element?.paidAmount" -->
                                                    <p-button  icon="pi pi-plus" pTooltip="Add Detail"
                                                    (click)="addPaymentDetail($event,element)"
                                                        styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>