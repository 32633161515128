import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ListOfValuesMasterService} from '../web-services/list-of-values-master.service';
import {CategoriesService} from '../web-services/categories.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-list-of-values-master',
               templateUrl: './list-of-values-master.component.html',
               styleUrls: ['./list-of-values-master.component.css']
           })
export class ListOfValuesMasterComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateListOfValuesMasterId = null;
    categoriesList: any = [];
    listOfValuesMasterList: any = [];
    listOfValuesMasterSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    listOfValuesMasterForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private listOfValuesMasterServices: ListOfValuesMasterService, private categoriesServices: CategoriesService,
        private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService, private messageService: MessageService,
        private route: Router, private loader: NgxUiLoaderService, private constantService: ConstantService,
        private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateListOfValuesMasterId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.listOfValuesMasterSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countListOfValuesMaster();
        this.findAllListOfValuesMaster();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countListOfValuesMaster() {
        this.listOfValuesMasterServices.countListOfValuesMaster(this.listOfValuesMasterSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllListOfValuesMaster();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateListOfValuesMaster(this.listOfValuesMasterForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllListOfValuesMaster();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.listOfValuesMasterSearch.reset();
        this.countListOfValuesMaster();
        // this.findAllListOfValuesMaster();
    }

    addUpdateListOfValuesMaster(listOfValuesMaster) {
        this.startLoader();
        if (this.updateListOfValuesMasterId == null) {
            this.listOfValuesMasterServices.addListOfValuesMaster(this.listOfValuesMasterForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    listOfValuesMaster = res['data'][0];
                    this.listOfValuesMasterList.push(listOfValuesMaster);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnAdd),
                                                detail: this.translate(this.labels.entityNameListOfValuesMaster)
                                            });
                    this.submitted = true;
                } else {
                    console.error(res['documentation']);
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            listOfValuesMaster.listOfValuesMasterId = this.updateListOfValuesMasterId;
            this.listOfValuesMasterServices.updateListOfValuesMaster(listOfValuesMaster).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.listOfValuesMasterList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameListOfValuesMaster)
                                            });
                    this.showSearchBtn = true;
                    this.showAddBtn = true;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateListOfValuesMasterId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllCategories();
        this.listOfValuesMasterForm = new FormGroup({
                                                        categoryId: new FormControl(),
                                                        referenceType: new FormControl('', [Validators.required, validateSpaces]),
                                                        referenceId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                        userId: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                    });
        this.listOfValuesMasterSearch = new FormGroup(
            {categoryId: new FormControl(), referenceType: new FormControl(), referenceId: new FormControl(), userId: new FormControl(),});
        this.countListOfValuesMaster();
        this.findAllListOfValuesMaster();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.listOfValuesMasterSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllListOfValuesMaster() {
        this.startLoader();
        this.listOfValuesMasterServices.findAllListOfValuesMaster(
            this.listOfValuesMasterSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.listOfValuesMasterList = res['data'];
                } else {
                    this.listOfValuesMasterList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdListOfValuesMaster(listOfValuesMasterId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.listOfValuesMasterServices.deleteByIdListOfValuesMaster(listOfValuesMasterId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.listOfValuesMasterList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.btnDelete),
                                                    detail: this.translate(this.labels.entityNameListOfValuesMaster)
                                                });
                        this.countListOfValuesMaster();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadListOfValuesMasterToEdit(listOfValuesMasterId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let listOfValuesMaster = this.listOfValuesMasterList.find(x => x['listOfValuesMasterId'] == listOfValuesMasterId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateListOfValuesMasterId = listOfValuesMaster['listOfValuesMasterId'];
        this.selectedIndex = index;
        this.listOfValuesMasterForm.controls['categoryId'].setValue(listOfValuesMaster['categoryId']['categoryId']);
        this.listOfValuesMasterForm.controls['referenceType'].setValue(listOfValuesMaster['referenceType']);
        this.listOfValuesMasterForm.controls['referenceId'].setValue(listOfValuesMaster['referenceId']);
        this.listOfValuesMasterForm.controls['userId'].setValue(listOfValuesMaster['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.listOfValuesMasterForm.reset();
        this.listOfValuesMasterForm.markAsUntouched();
        this.updateListOfValuesMasterId = null;
        closeAddOverlaydiv();
    }

    findAllCategories() {
        let temp = [];
        this.startLoader();
        this.categoriesServices.findAllCategories(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['categoryId'];
                        dropDown.label = object['categoryTitle'];
                        this.categoriesList.push(dropDown);
                    }
                } else {
                    this.categoriesList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.listOfValuesMasterForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.listOfValuesMasterForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.listOfValuesMasterForm.get(control)?.valid && this.listOfValuesMasterForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.listOfValuesMasterForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.listOfValuesMasterSearch.reset();
    }

    refresh() {
        this.countListOfValuesMaster();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.listOfValuesMasterForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.listOfValuesMasterForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateListOfValuesMasterId = null;
    }
}
