<!-- <ngx-ui-loader [loaderId]="'subscriptionLoader'"></ngx-ui-loader> -->
<div class="angCard" id="ssosubscriptions-angCard">
    <div class="angCard-header">
        <div class="angCard-title">
            <i class="subscribe-icon" id="ssosubscriptions-angCard-icon"></i>
            <span> {{verifyLabel('Active Subscription')}}(s)</span>
        </div>
        <div class="angCard-header-btns" id="ssosubscriptions-angCard-header-btns">
            <button (click)="setSearchForm()" class="btnxs-transparent-icon" id="ssosearchsubscription-openoverlaydiv"
            >
                <i class="search-icon"></i>
            </button>

            <ng-container *ngIf="currentVersion==0">
                <button (click)="setSubscriptionForm()" class="btnxs-transparent-icondanger"
                        id="ssoaddsubscription-openoverlaydiv">
                    <i class="add-icon"></i>
                </button>
            </ng-container>
        </div>
    </div>
    <div class="angCard-content" id="ssosubscriptions-angCard-content">

        <ng-container *ngIf="showSubscriptionForm && currentVersion==0">
            <!-- Add Subscription Area -->
            <div class="overlaydiv" id="ssoaddsubscription-overlaydiv">
                <div class="overlay-content">
                    <div class="angCard" id="ssoaddsubscription-angCard">
                        <div class="angCard-header">
                            <div class="angCard-title">
                                <i class="subscribe-icon" id="ssoaddsubscription-angCard-icon"></i>
                                <span>{{verifyLabel('New Subscription')}} </span>
                            </div>
                        </div>
                        <div class="angCard-content">

                            <form [formGroup]="subscriptionForm" class="formContainer"
                                  id="ssoaddsubscription-formContainer">
                                <div class="formBlockWarpper" id="ssoaddsubscription-formBlockWarpper">

                                    <div class="fromBlock" id="ssoaddsubscription-fromBlock-applications">
                                        <mat-form-field>
                                            <mat-label>{{verifyLabel('Select Application')}} </mat-label>
                                            <mat-select (selectionChange)="onApplicationChange($event)"
                                                        formControlName="application"
                                                        class="angSelect"
                                                        id="ssoaddsubscription-angSelect-applications">
                                                <mat-option>None</mat-option>
                                                <ng-container *ngFor="let application of applications">
                                                    <mat-option
                                                            [value]="application"> {{application.application_name}} </mat-option>
                                                </ng-container>
                                            </mat-select>
                                            <mat-error> {{verifyLabel('Application Is Required')}} </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="fromBlock" id="ssoaddsubscription-fromBlock-events">
                                        <mat-form-field>
                                            <mat-label> {{verifyLabel('Events')}} </mat-label>
                                            <mat-select formControlName="event" multiple class="angSelectmultiple"
                                                        id="ssoaddsubscription-angSelectmultiple-events">
                                                <mat-select-trigger>
                                                    <!-- {{toppings.value ? toppings.value[0] : ''}} -->
                                                    <!-- <span *ngIf="toppings.value?.length > 1" class="example-additional-selection"> -->
                                                    <!-- (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}}) -->
                                                    <!-- </span> -->
                                                </mat-select-trigger>
                                                <ng-container *ngFor="let event of events">
                                                    <mat-option [value]="event">{{event.title}}</mat-option>
                                                </ng-container>
                                            </mat-select>
                                            <mat-error>{{verifyLabel('Events Are Required')}} </mat-error>
                                        </mat-form-field>

                                    </div>


                                    <div class="fromBlock" id="ssoaddsubscription-fromBlock-notificationtype">
                                        <mat-label class="matLabelblocklevel"
                                                   id="ssoaddsubscription-label-notificationtype">
                                            {{verifyLabel('Notification Type')}} </mat-label>
                                        <mat-button-toggle-group formControlName="type" class="angTogglebtns"
                                                                 id="reportengineregisterapi-angTogglebtns-notificationtype">
                                            <ng-container *ngFor="let notificationType of notificationTypes">
                                                <mat-button-toggle [value]="notificationType"
                                                                   (change)="onNtfyTypeChange($event)">
                                                    {{notificationType.title}}</mat-button-toggle>
                                            </ng-container>
                                        </mat-button-toggle-group>
                                    </div>

                                    <div class="fromBlock" id="ssoaddsubscription-fromBlock-selectemplate">
                                        <mat-form-field>
                                            <mat-label>{{verifyLabel('Select Template')}} </mat-label>
                                            <mat-select (selectionChange)="onTemplateChange($event)"
                                                        formControlName="template"
                                                        class="angSelect" id="ssoaddsubscription-angSelect-category">
                                                <mat-option>None</mat-option>
                                                <ng-container *ngFor="let template of templates">
                                                    <mat-option [value]="template"> {{template.title}} </mat-option>
                                                </ng-container>
                                            </mat-select>
                                            <mat-error>{{verifyLabel('Template Is Required')}} </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div *ngIf="templateBody" class="angCardsmall"
                                         id="ssoaddsubscriptiontempalte-angCardsmall">
                                        <div class="angCardsmall-header"
                                             id="ssoaddsubscriptiontempalte-angCardsmall-header">
                                            <div class="angCardsmall-title">
                                                <span> {{verifyLabel('Template Detail')}} </span>
                                            </div>
                                        </div>
                                        <div class="angCardsmall-content"
                                             id="ssoaddsubscriptiontempalte-angCardsmall-content">
                                            <p class="angCardsmall-content-text"
                                               id="ssoaddsubscriptiontempalte-content-text">
                                                {{templateBody}}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <!-- <pre>{{subscriptionForm.valid | json}}</pre> -->
                                <div class="angCard-footer" id="ssoaddsubscription-angCard-footer">
                                    <button [disabled]="subscriptionForm.invalid" (click)="subscribe()" class="btnmd"
                                            id="ssoaddsubscription-savebtn">
                                        {{verifyLabel('Subscribe')}} </button>
                                    <button (click)="clearForm()" class="btnmd"
                                            id="ssoaddsubscription-cancelbtn">{{verifyLabel('Cancel')}}</button>
                                </div>

                            </form>

                        </div>
                    </div>


                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="showSearchForm">
            <!-- Search Subscription Area -->
            <div class="overlaydiv" id="ssosearchsubscription-overlaydiv">
                <div class="overlay-content">
                    <div class="angCard" id="ssosearchsubscription-angCard">
                        <div class="angCard-header">
                            <div class="angCard-title">
                                <i class="subscribe-icon" id="ssosearchsubscription-angCard-icon"></i>
                                <span> {{verifyLabel('Search Subscription')}} </span>
                            </div>
                        </div>
                        <div class="angCard-content">

                            <form [formGroup]="searchForm" class="formContainer"
                                  id="ssosearchsubscription-formContainer">
                                <div class="formBlockWarpper" id="ssosearchsubscription-formBlockWarpper">

                                    <div class="fromBlock" id="ssocopyproject-fromBlockpopups-title">
                                        <mat-form-field class="angInput" id="ssocopyproject-angInput-title">
                                            <input formControlName="searchfield" [(ngModel)]="searchSubscribedEvent"
                                                   type="text" matInput
                                                   placeholder="Search">
                                        </mat-form-field>
                                    </div>

                                </div>

                                <div class="angCard-footer" id="ssosearchsubscription-angCard-footer">
                                    <button (click)="searchSubscribedEvents()" class="btnmd"
                                            id="ssosearchsubscription-savebtnn">
                                        {{verifyLabel('Search')}}
                                    </button>
                                    <button (click)="[searchSubscribedEvent='',searchSubscribedEvents()]" class="btnmd"
                                            id="ssosearchsubscription-savebtn">
                                        {{verifyLabel('Clear')}}
                                    </button>
                                    <button (click)="onCancel()" class="btnmd"
                                            id="ssosearchsubscription-cancelbtn">{{verifyLabel('Cancel')}}</button>
                                </div>

                            </form>

                        </div>
                    </div>


                </div>

            </div>
        </ng-container>


        <!-- Notifications -->
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="angTable" id="ssosubscriptions-table">
                <ng-container matColumnDef="subscribednotification">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="blockheader-with-text"> Subscribed Notification</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="block-with-text"> {{element.ntfyEvent}} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="application">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="blockheader-with-text"> Application</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="block-with-text"> {{element.ntfyApplication.title}} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ntfyType">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="blockheader-with-text"> Type</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="block-with-text"> {{element.ntfyType.title}} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="subscribedtemplate">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="blockheader-with-text"> Subscribed Template</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="block-with-text"> {{element.ntfyTemplate.body}} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="subscriptiontime">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="blockheader-with-text"> Subscription Date</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="block-with-text"> {{element.insertionTimestamp | date:'medium'}} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                        <div class="blockheader-with-text"> Action</div>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="text-center">
                        <ng-container *ngIf="currentVersion==0">
                            <button class="btnxs-transparent-icon" (click)="openDeleteDialog(element , i)">
                                <i class="unsubscribe-icon"></i>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nodata">
                    <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length"
                        class="no-data text-center">
                        No Data Available
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
                <tr [hidden]="dataSource?.data.length >0" mat-footer-row *matFooterRowDef="['nodata']"></tr>
            </table>

            <!-- <mat-paginator [length]="totalSubscribedEvents" [pageIndex]="pageNumber" [pageSize]="limit"
              (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator> -->
        </div>
        <mat-paginator [length]="totalSubscribedEvents" [pageIndex]="pageNumber" [pageSize]="limit"
                       (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions"
                       showFirstLastButtons></mat-paginator>


    </div>

</div>