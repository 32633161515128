import { Component, OnInit } from '@angular/core';
import {
  MenuItem
} from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientsService } from '../web-services/patients.service';
import { SessionService } from '../services/session.service';
import { ExpertService } from '../web-services/expert.service';
import { Utils } from '../util/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-initial-assessment',
  templateUrl: './initial-assessment.component.html',
  styleUrls: ['./initial-assessment.component.css']
})
export class InitialAssessmentComponent implements OnInit {


  //temporary 
  // btnStatus: any;
  options;
  innerWidth: any;
  barData: any;
  responsiveOptions: any;
  allData: any;
  // temp end
  utils = new Utils();
  btnStatus: any;
  stepsItems: MenuItem[];
  activeStep = 0;
  stepper = true;

  patientObj: any;
  expertObj: any;

  constructor(
    public loader: NgxUiLoaderService,
    private patientsServices: PatientsService,
    private expertsServices: ExpertService,
    private sessionService: SessionService,
    private router: Router
  ) { };

  ngOnInit(): void {

    // temp start
    this.barData = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      datasets: [
        {
          label: 'Number',
          backgroundColor: '#009AFF',
          data: [65, 59, 80, 81, 56]
        },
        {
          label: 'Average',
          backgroundColor: '#43D5CB',
          data: [28, 48, 40, 19, 86]
        }
      ]
    };
    this.options = {
      aspectRatio: 3,
      // maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            fontColor: '#a1a1a1'
          }
        }],

      },
      legend: {
        position: 'top',
        align: 'end'
      }
    };

    this.allData = [
      {
        name: '24h Symptoms',
        list_item: [
          {
            item: 'Burning'
          },
          {
            item: 'Stabbing'
          }
        ]
      },
      {
        name: '24h Symptoms',
        list_item: [
          {
            item: 'Burning'
          },
          {
            item: 'Stabbing'
          }
        ]
      },
      {
        name: '24h Symptoms',
        list_item: [
          {
            item: 'Burning'
          },
          {
            item: 'Stabbing'
          }
        ]
      },
    ]

    // To resize chart on smaller screen
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.options.aspectRatio = 0;
      this.options.legend.align = 'center';
    }
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    // temp end
    this.findPatientsById();
    this.findExpertsById();
    this.stepsItems = [{
      label: 'Initial Assessment'
    },
    {
      label: 'Client Detail'
    },
    {
      label: 'History'
    },
    {
      label: 'Body Chart'
    },
    {
      label: 'Observations'
    },
    {
      label: 'Testing'
    },
    {
      label: 'Treatment'
    },
    ];

    this.btnStatus = 'Active';

    if (this.router.url.includes('/testing')) {
      this.stepper = false;
      this.activeStep = 5;
    } else if (this.router.url.includes('/history')) {
      this.stepper = false;
      this.activeStep = 2;
    } else if (this.router.url.includes('/client_details')) {
      this.stepper = false;
      this.activeStep = 1;
    } else if (this.router.url.includes('/body_chart')) {
      this.stepper = false;
      this.activeStep = 3;
    } else if (this.router.url.includes('/observations')) {
      this.stepper = false;
      this.activeStep = 4;
    } else if (this.router.url.includes('/treatment')) {
      this.stepper = false;
      this.activeStep = 6;
    }else if (this.router.url.includes('/initial-assessment')) {
      this.stepper = false;
      this.activeStep = 0;
    }
  }

  findPatientsById() {
    this.startLoader();
    this.patientsServices.findPatientsById(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {
      if (res['message'] == 'Success') {
        this.patientObj = res['data'][0];
        // console.log(this.patientObj, 'patient object');
      } else {
        console.error('error: ', res['documentation']);
      }
      this.stopLoader();
    }, err => {
      console.error('err: ', err);
      this.stopLoader();
    });
  }
  
  findExpertsById() {
    this.startLoader();
    this.expertsServices.findExpertsById(parseInt(this.sessionService.getSession('expertId'))).subscribe(res => {
      if (res['message'] == 'Success') {
        this.expertObj = res['data'][0];
        // console.log(this.expertObj, 'expert object');
      } else {
        console.error('error: ', res['documentation']);
      }
      this.stopLoader();
    }, err => {
      console.error('err: ', err);
      this.stopLoader();
    });
  }

  startLoader() {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }


  // previous() {
  //   if (this.activeStep < 0) {
  //   this.activeStep = 0;
  //   } else {
  //   this.activeStep--;
  //   }
  //   }
  //   next() {
  //   if (this.activeStep == 6) {
  //   this.activeStep = 0;
  //   } else {
  //   this.activeStep++;
  //   }
  //   }
  urlChanged(number) {
    if (number === 0) {
      this.router.navigate(['initial-assessment']);
    } else if (number === 1) {
      this.router.navigate(['client_details']);
    } else if (number === 2) {
      this.router.navigate(['history']);
    } else if (number === 3) {
      this.router.navigate(['body_chart']);
    } else if (number === 4) {
      this.router.navigate(['observations']);
    } else if (number === 5) {
      this.router.navigate(['testing']);
    } else if (number === 6) {
      this.router.navigate(['treatment']);
    }

    /*if (this.router.url.includes('/testing')) {
      this.stepper = false;
      this.activeStep = 5;
    } else if (this.router.url.includes('/history')) {
      this.stepper = false;
      this.activeStep = 2;
    }else if (this.router.url.includes('/client_details')) {
      this.stepper = false;
      this.activeStep = 0;
    }else if (this.router.url.includes('/body_chart')) {
      this.stepper = false;
      this.activeStep = 3;
    }else if (this.router.url.includes('/observations')) {
      this.stepper = false;
      this.activeStep = 4;
    }else if (this.router.url.includes('/treatment')) {
      this.stepper = false;
      this.activeStep = 6;
    }*/
  }
}
