<section class="sectionmain">
  <div class="card-cpa-rounded-20 background-white-m-30">
    <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
             [readonly]="false">
    </p-steps>
  </div>

  <div class="p-row initial-assessment-container">

    <div class="p-col-12">
      <div class="row">
        <div class="p-col-3 m-5">

          <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
        </div>
      </div>
<div class="row">

      <div class="p-col-8 m-5">
        <p-card styleClass="card-cpa-rounded card-shadow">

      <h5 class="superAdminTitles">Observations</h5>
      <p-tabView class="observations-tabs">

        <p-tabPanel header="Observations" headerStyleClass="h3-style">
          <app-observations></app-observations>
        </p-tabPanel>

        <p-tabPanel header="Posture" headerStyleClass="h3-style ">
          <app-posture></app-posture>
        </p-tabPanel>

        <p-tabPanel header="Palpation" headerStyleClass="h3-style">
          <app-palpation></app-palpation>
        </p-tabPanel>

      </p-tabView>
    </p-card>

  </div>
</div>
    </div>
  </div>
</section>
