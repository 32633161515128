<ngx-ui-loader></ngx-ui-loader>
<section class="sectionmain" id="sectionmain">
  <div class="card-cpa-rounded-20 background-white-m-30">
    <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
             [readonly]="false">
    </p-steps>
  </div>
  <div class="p-row initial-assessment-container">

    <div class="p-col-12">
      <div class="row">
        <div class="p-col-3 m-5">

          <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
        </div>
      </div>
      <div class="row">

        <div class="p-col-8 m-5">

      <p-tabView>
        <p-tabPanel header="Review">
          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNamePresentConditionHistory)}}</h2>
                  <button *ngIf="!caseReviewData.initialAssessmentComplete" class="btn-success" (click)="markInitialAssessmentAsComplete()">Mark Initial Assessment As Completed</button>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let pch of caseReviewData.presentConditionHistory">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Initial Assessment Date:</strong>
                      <span class="mb-0"> {{pch.initialAssessmentDate  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>HPC:</strong>
                      <span class="mb-0">{{pch.hpc  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Outcome Investigation:</strong>
                      <span class="mb-0">{{pch.outcomeInvestigation  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Outcome Treatment Type:</strong>
                      <span class="mb-0">{{pch.outcomeTreatmentType  }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Services Seen:</strong>
                      <span *ngFor="let servicesSeen of pch.servicesSeen" style="display: block;"> {{servicesSeen.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Present Symptoms:</strong>
                      <span *ngFor="let presentSymptoms of pch.presentSymptoms" style="display: block;"> {{presentSymptoms.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Treatment Type:</strong>
                      <span *ngFor="let treatmentType of pch.treatmentType" style="display: block;"> {{treatmentType.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Investigation:</strong>
                      <span *ngFor="let investigation of pch.investigation" style="display: block;"> {{investigation.lovValues}} </span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNamePastMedicalHistory)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let pmh of caseReviewData.pastMedicalHistories">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{pmh.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Previous Msk:</strong>
                      <span *ngFor="let previousMsk of pmh.previousMsk" style="display: block;"> {{previousMsk.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Bloods:</strong>
                      <span *ngFor="let  bloods of pmh.bloods" style="display: block;"> {{bloods.lovValues}} </span>
                    </li>


                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Imaging:</strong>
                      <span *ngFor="let  imaging of pmh.imaging" style="display: block;"> {{imaging.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Systemic:</strong>
                      <span *ngFor="let  systemic of pmh.systemic" style="display: block;"> {{systemic.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Illness:</strong>
                      <span *ngFor="let illness of pmh.illness" style="display: block;"> {{illness.lovValues}} </span>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameDrugHistory)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let dh of caseReviewData.drugHistories">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{dh.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Drug History:</strong>
                      <span *ngFor="let drugHistory of dh.drugHistory" style="display: block;"> {{drugHistory.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Recreational Drugs:</strong>
                      <span *ngFor="let  recreation of dh.recreation" style="display: block;"> {{recreation.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Drinks:</strong>
                      <span *ngFor="let  drinks of dh.drinks" style="display: block;"> {{drinks.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Smoking:</strong>
                      <span *ngFor="let  smoke of dh.smoke" style="display: block;"> {{smoke.lovValues}} </span>
                    </li>


                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Caffeinated Drinks:</strong>
                      <span *ngFor="let  caffeinatedDrinks of dh.caffeinatedDrinks" style="display: block;"> {{caffeinatedDrinks.lovValues}} </span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameSocialHistory)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let sh of caseReviewData.socialHistories">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{sh.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Occupation:</strong>
                      <span *ngFor="let occupation of sh.occupation" style="display: block;"> {{occupation.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>ADL:</strong>
                      <span *ngFor="let  adl of sh.adl" style="display: block;"> {{adl.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Mobility:</strong>
                      <span *ngFor="let  mobility of sh.adl" style="display: block;"> {{mobility.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Hobbies:</strong>
                      <span *ngFor="let  hobbies of sh.hobbies" style="display: block;"> {{hobbies.lovValues}} </span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameBodyChartMaster)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let bcm of caseReviewData.bodyChartsMaster">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>

                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Body Part:</strong>
                      <span *ngFor="let bodyPartId of bcm.bodyPartId" style="display: block;"> {{bodyPartId.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <img class="bodychart-img-front" [src]="bcm.frontAnatomyImageUrl" width="200px" height="300px">
                      <img class="bodychart-img-front" [src]="bcm.backAnatomyImageUrl" width="200px" height="300px">
                      <img class="bodychart-img-front" [src]="bcm.skeletonAnatomyImageUrl" width="200px" height="300px">

                    </li>
                  </ul>
                  <ul class="list-group list-group-flush" *ngFor="let bcd of caseReviewData.bodyChartsDetail">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">{{bcd.bodyChartAnatomyBodyPart}}</h5>
                      </div>

                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Duration:</strong>
                      <span *ngFor="let duration of bcd.duration" style="display: block;"> {{duration.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Description:</strong>
                      <span *ngFor="let description of bcd.description" style="display: block;"> {{description.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Aggravating Factors:</strong>
                      <span *ngFor="let aggs of bcd.aggs" style="display: block;"> {{aggs.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Easing Factors:</strong>
                      <span *ngFor="let eases of bcd.eases" style="display: block;"> {{eases.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>24 Hourly Symptoms:</strong>
                      <span *ngFor="let hourlySymptoms of bcd.hourlySymptoms" style="display: block;"> {{hourlySymptoms.lovValues}} </span>
                    </li>
                  </ul>


                </div>
              </div>
            </div>
          </div>


          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameObservations)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let ob of caseReviewData.observations">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{ob.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Observations:</strong>
                      <span *ngFor="let observations of ob.observations" style="display: block;"> {{observations.lovValues}} </span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNamePosture)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let pos of caseReviewData.postures">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{pos.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Posture Description:</strong>
                      <span *ngFor="let postureDescription of pos.postureDescription" style="display: block;"> {{postureDescription.lovValues}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Posture Comment:</strong>
                      <span> {{pos.postureComment}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Gait Description:</strong>
                      <span *ngFor="let gaitDescription of pos.gaitDescription" style="display: block;"> {{gaitDescription.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Gait Comment:</strong>
                      <span> {{pos.gaitComment}} </span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNamePalpation)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let pal of caseReviewData.palpations">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{pal.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Palpation:</strong>
                      <span *ngFor="let palpation of pal.palpations" style="display: block;"> {{palpation.lovValues}} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameTestingArom)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let arom of caseReviewData.testingAroms">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{arom.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Joint:</strong>
                      <span> {{arom.joint}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Side:</strong>
                      <span> {{arom.aromSide}} </span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Movement:</strong>
                      <span *ngFor="let movement of arom.movement" style="display: block;"> {{movement.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Measures:</strong>
                      <span *ngFor="let measures of arom.measure" style="display: block;"> {{measures.lovValues}} </span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Limiting Factors:</strong>
                      <span *ngFor="let limitingFactors of arom.limitingFactors" style="display: block;"> {{limitingFactors.lovValues}} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameTestingNeuro)}}</h2>
                </div>
                <div class="card-body pb-0">
                  <ul class="list-group list-group-flush" *ngFor="let testingNeuros of caseReviewData.testingNeuro">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{testingNeuros.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Dermatome:</strong>
                      <span class="mb-0"> {{testingNeuros.dermatome }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Dermatome Side:</strong>
                      <span class="mb-0"> {{testingNeuros.dermatomeSide }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Dermatome Comments:</strong>
                      <span class="mb-0"> {{testingNeuros.dermatomeComments }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Reflexes Side:</strong>
                      <span class="mb-0"> {{testingNeuros.reflexesSide }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Dermatome Description:</strong>
                      <span *ngFor="let dermatomeDescription of testingNeuros.dermatomeDescription" style="display: block;"> {{dermatomeDescription.lovValues}} </span>
                    </li>


                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Reflexes:</strong>
                      <span *ngFor="let reflexes of testingNeuros.reflexes" style="display: block;"> {{reflexes.lovValues}} </span>
                    </li>


                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Reflexes Description:</strong>
                      <span *ngFor="let reflexesDescription of testingNeuros.reflexesDescription" style="display: block;"> {{reflexesDescription.lovValues}} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameTestingSpecial)}}</h2>
                </div>
                <div class="card-body pb-0 m-2" *ngFor="let testingSpecials of caseReviewData.testingSpecial">
                  <ul class="list-group list-group-flush" >
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{testingSpecials.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Special Tests Performed:</strong>
                      <span *ngFor="let testType of testingSpecials.testType" style="display: block;"> {{testType.lovValues}} </span>
                    </li>


                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Side:</strong>
                      <span class="mb-0"> {{testingSpecials.side }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Comments:</strong>
                      <span class="mb-0"> {{testingSpecials.testComments }}</span>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="row" style="margin:20px">
            <div class="col-xl-12">

              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h2 class="card-title">{{translate(labels.entityNameTestingMuscle)}}</h2>
                </div>
                <div class="card-body pb-0 m-2" *ngFor="let testingMuscles of caseReviewData.testingMuscles">
                  <ul class="list-group list-group-flush" >
                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Date:</strong>
                      <span class="mb-0"> {{testingMuscles.insertionTimestamp  | date: 'dd-MM-YYYY'  }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Muscle Group:</strong>
                      <span class="mb-0"> {{testingMuscles.muscleGroup }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Muscle Side:</strong>
                      <span class="mb-0"> {{testingMuscles.muscleSide }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Measures:</strong>
                      <span class="mb-0"> {{testingMuscles.measures }}</span>
                    </li>

                    <li class="list-group-item d-flex px-0 justify-content-between">
                      <strong>Comments:</strong>
                      <span class="mb-0"> {{testingMuscles.comment }}</span>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Exercises">
          <div class="row" style="margin:20px">
            <div class="col-xl-6">
              <h4 class="p-panel-header">Cable Machine</h4>
              <video id="player" playsinline controls width="400px" height="300px">
                <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/6-Cable%20machine%20rear%20deltoid%20row.mp4" type="video/mp4" />

                <!-- Captions are optional -->
              </video>
            </div>
            <div class="col-xl-6">
              <h4 class="p-panel-header">Shoulder Internal</h4>

              <video id="player" playsinline controls  width="400px" height="300px">
                <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/49-Shoulder%20internal%20and%20external%20rotation%20supine%20with%20weight.mp4" type="video/mp4" />

                <!-- Captions are optional -->
              </video>
            </div>
          </div>

          <div class="row" style="margin:20px">
            <div class="col-xl-6 ">
              <h4 class="p-panel-header">Scapular Retraction</h4>
              <video id="player" playsinline controls  width="400px" height="300px">
                <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/42-Scapular%20retraction_protraction%20supine%20arm%20with%20weight.mp4" type="video/mp4" />

                <!-- Captions are optional -->
              </video>
            </div>
            <div class="col-xl-6 ">
              <h4 class="p-panel-header">Posture and Scapula</h4>

              <video id="player" playsinline controls  width="400px" height="300px">
                <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/27-Posture%20and%20scapula%20stability%20against%20wall.mp4" type="video/mp4" />

                <!-- Captions are optional -->
              </video>
            </div>
          </div>


          <div class="row" style="margin:20px">
            <div class="col-xl-6">
              <h4 class="p-panel-header">Prone GHJt</h4>

              <video id="player" playsinline controls  width="400px" height="300px">
                <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/28-Prone%20GHjt%20ER%2890%29%20with%20weight.mp4" type="video/mp4" />

                <!-- Captions are optional -->
              </video>
            </div>
            <div class="col-xl-6 ">
              <h4 class="p-panel-header">Shoulder Abduction</h4>

              <video id="player" playsinline controls  width="400px" height="300px">
                <source src="https://bodychart.fra1.digitaloceanspaces.com/videos/44-Shoulder%20abduction%20with%20weight%20%2890%20degrees%29.mp4" type="video/mp4" />

                <!-- Captions are optional -->
              </video>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Treatment">
          Strengthening Exercises for Rotator Cuff and Scapula<br>
          Advice & Education
        </p-tabPanel>
        <p-tabPanel header="Advice Leaflets">
          <div>Your advice leaflets are below please use as part of your treatment plan</div>
          <div class="row m-5">
            <div class="col-md-12">
                <span style="margin-right:20px!important;">
              <a target="_blank" href="https://bodychart.fra1.digitaloceanspaces.com/videos/advice/120149_Rotator_cuff_tear%20NHS.pdf">
                Click here to view
                <i class="fa fa-2x fa-file-pdf-o" style="color:red"></i></a>
          </span>

              <span>

              <a target="_blank" href="https://bodychart.fra1.digitaloceanspaces.com/videos/advice/Non-surg.-RC-tear.pdf">
                 Click here to view
                <i class="fa fa-2x fa-file-pdf-o" style="color:red"></i></a>
          </span>
            </div>

          </div>


        </p-tabPanel>
      </p-tabView>

    </div>




</div>
    </div>




  </div>
</section>
