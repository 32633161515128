import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CompanyUsersService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdCompanyUsers(companyUsersId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'CompanyUsers/deleteCompanyUsers', {'companyUsersId': companyUsersId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countCompanyUsers(companyUsers): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'CompanyUsers/count', {'companyUsers': companyUsers}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateCompanyUsers(companyUsers): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'CompanyUsers/updateCompanyUsers', [companyUsers], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addCompanyUsers(companyUsers): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'CompanyUsers/addCompanyUsers', [companyUsers], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllCompanyUsers(companyUsers, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'CompanyUsers/findAllCompanyUsers',
            {'companyUsers': companyUsers, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search
    companyUsersGlcount(searchTerm, userId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'CompanyUsers/countGlobalCompanyUsers',
            {'searchTerm': searchTerm, 'userId': userId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findGlobalCompanyUsers(searchTerm, userId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'CompanyUsers/findGlobalCompanyUsers',
            {'searchTerm': searchTerm, 'userId': userId, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
