import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencyCompanyService} from '../web-services/agency-company.service';
import {AgencyService} from '../web-services/agency.service';
import {CompanyService} from '../web-services/company.service';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component({selector: 'app-agency-company', templateUrl: './agency-company.component.html', styleUrls: ['./agency-company.component.css']})
export class AgencyCompanyComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAgencyCompanyId = null;
    agencyList: any = [];
    companyList: any = [];
    usersList: any = [];
    agencyCompanyList: any = [];
    agencyCompanySearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencyCompanyForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private agencyCompanyServices: AgencyCompanyService, private agencyServices: AgencyService, private companyServices: CompanyService,
        private usersServices: UsersService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateAgencyCompanyId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.agencyCompanySearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgencyCompany();
        this.findAllAgencyCompany();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countAgencyCompany() {
        this.agencyCompanyServices.countAgencyCompany(this.agencyCompanySearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAgencyCompany();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateAgencyCompany(this.agencyCompanyForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAgencyCompany();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencyCompanySearch.reset();
        this.countAgencyCompany();
        // this.findAllAgencyCompany();
    }

    addUpdateAgencyCompany(agencyCompany) {
        this.startLoader();
        if (this.updateAgencyCompanyId == null) {
            this.agencyCompanyServices.addAgencyCompany(this.agencyCompanyForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    agencyCompany = res['data'][0];
                    this.agencyCompanyList.push(agencyCompany);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnAdd),
                                                detail: this.translate(this.labels.entityNameAgencyCompany)
                                            });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countAgencyCompany();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            agencyCompany.agencyCompanyId = this.updateAgencyCompanyId;
            this.agencyCompanyServices.updateAgencyCompany(agencyCompany).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencyCompanyList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameAgencyCompany)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countAgencyCompany();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencyCompanyId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.findAllAgency();
        // this.findAllCompany();
        this.findAllUsers();
        this.agencyCompanyForm = new FormGroup({agencyId: new FormControl(), companyId: new FormControl(), userId: new FormControl(),});
        this.agencyCompanySearch = new FormGroup({agencyId: new FormControl(), companyId: new FormControl(), userId: new FormControl(),});
        this.countAgencyCompany();
        this.findAllAgencyCompany();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencyCompanySearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllAgencyCompany() {
        this.startLoader();
        this.agencyCompanyServices.findAllAgencyCompany(this.agencyCompanySearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencyCompanyList = res['data'];
                } else {
                    this.agencyCompanyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgencyCompany(agencyCompanyId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencyCompanyServices.deleteByIdAgencyCompany(agencyCompanyId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.agencyCompanyList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.btnDelete),
                                                    detail: this.translate(this.labels.entityNameAgencyCompany)
                                                });
                        this.countAgencyCompany();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadAgencyCompanyToEdit(agencyCompanyId: number, index) {
        let agencyCompany = this.agencyCompanyList.find(x => x['agencyCompanyId'] == agencyCompanyId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencyCompanyId = agencyCompany['agencyCompanyId'];
        this.selectedIndex = index;
        this.agencyCompanyForm.controls['agencyId'].setValue(agencyCompany['agencyId']['agencyId']);
        this.agencyCompanyForm.controls['companyId'].setValue(agencyCompany['companyId']['companyId']);
        this.agencyCompanyForm.controls['userId'].setValue(agencyCompany['userId']['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.agencyCompanyForm.reset();
        this.agencyCompanyForm.markAsUntouched();
        this.updateAgencyCompanyId = null;
        closeAddOverlaydiv();
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    // findAllCompany() {
    //     let temp = [];
    //     this.startLoader();
    //     this.companyServices.findAllCompany(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = {label: '', value: ''};
    //                     dropDown.value = object['companyId'];
    //                     dropDown.label = object['companyName'];
    //                     this.companyList.push(dropDown);
    //                 }
    //             } else {
    //                 this.companyList = [];
    //             }
    //             this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.agencyCompanyForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencyCompanyForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencyCompanyForm.get(control)?.valid && this.agencyCompanyForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.agencyCompanyForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.agencyCompanySearch.reset();
    }

    refresh() {
        this.countAgencyCompany();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.agencyCompanyForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencyCompanyForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAgencyCompanyId = null;
    }
}
