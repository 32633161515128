import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {AppointmentFormDataDTO} from "../dto/AppointmentFormDataDTO";

@Injectable({providedIn: 'root'})
export class AppointmentsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAppointments(appointmentId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'Appointments/deleteAppointments', {'appointmentId': appointmentId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAppointments(appointments): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Appointments/count', {'appointments': appointments}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateAppointments(appointments): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Appointments/updateAppointments', [appointments], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addAppointment(appointment): Observable<string> {
    return this.httpClient.post<string>(Constant.BASE_URL + 'Appointments/addSingleAppointment', [appointment], this.httpOptions).pipe(
      retry(3), catchError(Constant.handleError));
  }

  addAppointmentCpa(appointmentDataDTO: AppointmentFormDataDTO): Observable<any> {
    return this.httpClient.post<any>(Constant.CPA_APPOINTMENTS_SERVICE + 'appointments', appointmentDataDTO, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(
      retry(0), catchError(Constant.handleError));
  }





    addAppointments(appointments): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Appointments/addAppointments', [appointments], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAppointments(appointments, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Appointments/findAllAppointments',
            {'appointments': appointments, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAppointmentsByClinicExpertId(appointments): Observable<string[]> {

      return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'Appointments/findAppointmentByClinicExpertId',
        {'appointments': appointments}, this.httpOptions
      ).pipe(
        retry(3), catchError(Constant.handleError));



    }

  findAppointmentsByClinicExpertIdCpa(clinicId: number, expertId: number): Observable<any> {

    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'clinics/' + clinicId + '/experts/' + expertId + '/appointments',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(3), catchError(Constant.handleError));
  }
    getAppointmentsOfCurrentMonth(dateString,expertId, status){
        let obj = {'date':dateString,'expertId':83, 'status':status};
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Appointments/getAppointmentsOfCurrentMonth',obj, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));

    }

    getAppointmentsByDate(dateString,expertId, status){

        let obj = {'date':dateString,'expertId':83, 'status':status};
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Appointments/getAppointmentsByDate',obj, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));

    }

    findAllOpenAppointmentsByExpertId(expertId: number): Observable<any> {

      return this.httpClient.get<any>(
        Constant.CPA_SPRING_API_URL + 'experts/' + expertId + '/appointments/open',
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      ).pipe(
        retry(3), catchError(Constant.handleError));
    }


  // custom service for global search

    globalSearchCount(searchTerm, userId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Appointments/countGlobalAppointments',
            {'searchTerm': searchTerm, 'userId': userId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm, userId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Appointments/findGlobalAppointments',
            {'searchTerm': searchTerm, 'userId': userId,  'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    getInitialAssessmentReport(appointmentId): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Appointments/getInitialAssessmentReport',
            {'appointmentId': appointmentId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }




    // done for landing page of expert
    landingPageAppointmentsCount(currentDate, expertId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'Appointments/dashboardCounts',
            {'currentDate': currentDate, 'expertId': expertId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
