<ngx-ui-loader [loaderId]="'mainLoader'"></ngx-ui-loader>
<div class="app align-content-stretch d-flex flex-wrap" >
  <div class="app-sidebar-cpa">
    <div class="logo hidden-sidebar-logo">
      <a href="/" class="logo-icon"><span class="logo-text">Clinical Pro Assist</span></a>
      <div class="sidebar-user-switcher user-activity-online">
        <a href="#">
          <img src="https://mdbcdn.b-cdn.net/img/new/avatars/1.webp">
          <span class="activity-indicator"></span>
          <span class="user-info-text">{{userTitle}}<span class="user-state-info"></span></span>
        </a>
      </div>
    </div>

    <div class="app-menu">
      <ul class="accordion-menu" style="background-color: #f8f9fa !important;">
          <li class="sidebar-title">
            Menu
          </li>
          <li *ngFor="let sideMenuItem of sideMenuItems">
            <a  (click)="navigateToPage(null,sideMenuItem.menuurl, sideMenuItem)" class="nav-item-holder"

                [ngClass]="{'shadow nav-item-holder-selected':sideMenuItem.children == null && currentMenuUrl === sideMenuItem.menuurl || sideMenuItem.children != null &&  expandedSideMenu.includes(sideMenuItem.applicationroleid),'nav-item-holder-with-children' : sideMenuItem.children != null}">
                    <div class="{{sideMenuItem.menuicon}} icon icon-shape icon-sm text-center align-items-center justify-content-center"
                         [ngClass]="sideMenuItem.children == null && currentMenuUrl === sideMenuItem.menuurl || expandedSideMenu.includes(sideMenuItem.applicationroleid) ? 'background-white nav-item-selected'  : 'shadow border-radius-md background-white'"
                         style="font-size:18px!important;vertical-align: middle!important;padding-top:13px;">


                    </div>



              <span style="margin-left:15px;">
                  {{sideMenuItem.name}}
              </span>
            </a>
            <div class="collapse" *ngIf="sideMenuItem.children != null" style="background-color: transparent!important;"
                  [ngClass]="expandedSideMenu.includes(sideMenuItem.applicationroleid) ? 'show' : 'hide'">
              <ul class="pt-2 mt-0" style="background-color: transparent!important;">
                <li *ngFor="let childItem of sideMenuItem.children" class="ml-0">
                  <a  (click)="navigateToPage(null,childItem.menuurl, childItem)" class="child-nav ml-0 pl-3">
                     <span>{{childItem.name}} </span>
                  </a>
                </li>


              </ul>
            </div>


            <!--  <i (click)="addQuickLink(sideMenuItem)" class=" fa-solid fa-arrow-up-right-from-square fa-solid" style="font-size:10px!important;"></i>-->
          </li>

      </ul>
    </div>
  </div>
  <div class="app-container" >


<app-header [quickLinks]="quickLinks"></app-header>
<div class="app-content">
    <div class="content-wrapper">
      <div class="container-fluid">
  <ul class="nav nav-pills nav-pills-quicklinks listMaxScroll">

  </ul>
        <section>
  <router-outlet *ngIf="!hideloginScreen"></router-outlet>

        </section>
</div>



</div>

</div>
</div>
</div>



<!--<div class="collapse  show " id="applicationsExamples">
  <ul class="nav ms-4 ps-3">
    <li class="nav-item active">
      <a class="nav-link active" href="../../pages/applications/kanban.html">
        <span class="sidenav-mini-icon"> K </span>
        <span class="sidenav-normal"> Kanban </span>
      </a>
    </li>
    <li class="nav-item ">
      <a class="nav-link " href="../../pages/applications/wizard.html">
        <span class="sidenav-mini-icon"> W </span>
        <span class="sidenav-normal"> Wizard </span>
      </a>
    </li>
    <li class="nav-item ">
      <a class="nav-link " href="../../pages/applications/datatables.html">
        <span class="sidenav-mini-icon"> D </span>
        <span class="sidenav-normal"> DataTables </span>
      </a>
    </li>
    <li class="nav-item ">
      <a class="nav-link " href="../../pages/applications/calendar.html">
        <span class="sidenav-mini-icon"> C </span>
        <span class="sidenav-normal"> Calendar </span>
      </a>
    </li>
    <li class="nav-item ">
      <a class="nav-link " href="../../pages/applications/analytics.html">
        <span class="sidenav-mini-icon"> A </span>
        <span class="sidenav-normal"> Analytics </span>
      </a>
    </li>
  </ul>
</div>-->
