<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameOutcomeMeasure)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <!-- *ngIf="totalRecords == 0" -->
                                <button *ngIf="totalRecords == 0" class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" 
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameOutcomeMeasure)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameOutcomeMeasure)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="outcomeMeasureSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationOnInitialAssesment)}} </label>
                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"
                                                        [showClear]="true" class="pngDropdown"
                                                        formControlName="classificationOnInitialAssesment"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationPostTreatment)}} </label>
                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"
                                                        [showClear]="true" class="pngDropdown"
                                                        formControlName="classificationPostTreatment"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>Upload File</label> <span
                                                class="p-input-icon-right"><i (click)="fieldReset('uploadImage')"
                                                                              *ngIf="diplayeIconInField('uploadImage')"
                                                                              class="pi pi-times color-danger"></i> <input
                                                class="pngInputtext"
                                                formControlName="uploadImage"
                                                name="uploadImage"
                                                pInputText
                                                type="text"> </span>
                                        </div>
                                        <!--                    <div class="p-field p-col-12  p-md-6 formfieldheight">-->
                                        <!--                      <label>{{translate(labels.outcomeMeasureColumnPatientId)}} </label>-->
                                        <!--                      <p-dropdown [filter]="true" [options]="usersList" [showClear]="true" class="pngDropdown"-->
                                        <!--                                  formControlName="patientId"-->
                                        <!--                                  placeholder={{translate(labels.outcomeMeasureColumnPatientId)}}></p-dropdown>-->
                                        <!--                    </div>-->
                                        <!--                    <div class="p-field p-col-12  p-md-6 formfieldheight">-->
                                        <!--                      <label>{{translate(labels.outcomeMeasureColumnExpertId)}} </label>-->
                                        <!--                      <p-dropdown [filter]="true" [options]="usersList" [showClear]="true" class="pngDropdown"-->
                                        <!--                                  formControlName="expertId"-->
                                        <!--                                  placeholder={{translate(labels.outcomeMeasureColumnExpertId)}}></p-dropdown>-->
                                        <!--                    </div>-->
                                        <!--                    <div class="p-field p-col-12  p-md-6 formfieldheight">-->
                                        <!--                      <label>{{translate(labels.outcomeMeasureColumnAppointmentId)}} </label>-->
                                        <!--                      <p-dropdown [filter]="true" [options]="appointmentsList" [showClear]="true"-->
                                        <!--                                  class="pngDropdown" formControlName="appointmentId"-->
                                        <!--                                  placeholder={{translate(labels.outcomeMeasureColumnAppointmentId)}}></p-dropdown>-->
                                        <!--                    </div>-->
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnUserId)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="userId" mode="decimal"
                                                           name="userId" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationOnInitialAssessmentCommnets)}}</label>
                                            <span class="p-input-icon-right"><i
                                                    (click)="fieldReset('classificationOnInitialAssessmentCommnets')"
                                                    *ngIf="diplayeIconInField('classificationOnInitialAssessmentCommnets')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    class="pngInputtext"
                                                    formControlName="classificationOnInitialAssessmentCommnets"
                                                    name="classificationOnInitialAssessmentCommnets"
                                                    pInputText type="text"> </span></div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationPostTreatmentCommnets)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('classificationPostTreatmentCommnets')"
                                                    *ngIf="diplayeIconInField('classificationPostTreatmentCommnets')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    class="pngInputtext"
                                                    formControlName="classificationPostTreatmentCommnets"
                                                    name="classificationPostTreatmentCommnets"
                                                    pInputText type="text">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameOutcomeMeasure)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="outcomeMeasureForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                       <ng-container formArrayName="WAD" *ngFor="let item of outcomeMeasureForm.get('WAD')['controls']; let i = index ; let last = last;let first = first;">
                                        <div class="p-field p-col-12 p-md-8 formfieldheight outcome_measure" [formGroupName]="i" >
                                            <label><p>{{Field(item)}}</p></label>

                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('classificationPostTreatmentCommnets')"
                                                    *ngIf="diplayeIconInField('classificationPostTreatmentCommnets')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('classificationPostTreatmentCommnets')"
                                                    class="pngInputtext"
                                                    formControlName="{{Field(item)}}"
                                                    name="classificationPostTreatmentCommnets" 
                                                    pInputText type="text"></span>
                                                    <div *ngIf="getGroupControl(i,item).touched || getGroupControl(i,item).dirty " class="customError">
                         
                                                        <span class="sr-only">(error)</span>
                                                        <div *ngIf="getGroupControl(i,item).hasError('required')" class="error-msg">
                                                            {{translate(labels.validation_required)}}
                                                        </div>
                                                        <div *ngIf="getGroupControl(i,item).hasError('validSpace')" class="error-msg">
                                                            {{translate(labels.validation_emptySpace)}}
                                                        </div>
                                                    
                                                        <div *ngIf="getGroupControl(i,item).hasError('min')" class="error-msg">
                                                            {{translate(labels.validation_min)}}
                                                            Minimum value should be 1
                                                        </div>
                                                        <div *ngIf="getGroupControl(i,item).hasError('max')" class="error-msg">
                                                            {{translate(labels.validation_max)}}
                                                        </div>
                                                    
                                                        <div *ngIf="getGroupControl(i,item).hasError('minlength')" class="error-msg">
                                                            {{translate(labels.validation_minLength)}}
                                                        </div>
                                                        <div *ngIf="getGroupControl(i,item).hasError('maxlength')" class="error-msg">
                                                            {{translate(labels.validation_maxLength)}}
                                                        </div>
                                                    </div>
                                                              
                                                           
                                        </div>
                                        <div class="p-col-12 p-md-4 p-d-flex p-jc-center p-ai-center outcome_measure_btn" style="padding: 0 0 1rem 0 !important;
                                        align-items: flex-end;" >
                                            <p-button class="pngBlueBtn " label="+ Add" styleClass="p-button"
                                            *ngIf="first " (click)="addWAD(i)"
                                            
                                            ></p-button>

                                        <p-button  class="pngBlueBtn ml-1" label="Remove" 
                                        *ngIf="!first && updateOutcomeMeasureId==null" (click)="removeWAD(i)"></p-button>
                                        
                                        <p-button  class="pngBlueBtn ml-1" label="Remove" styleClass="p-button"
                                        *ngIf="!first && updateOutcomeMeasureId!=null" (click)="deleteByIdOutcomeMeasureDetail(0,i)"></p-button>
                                     
                                    </div>

                                        <!-- <button *ngIf="!first" (click)="removeWAD(i)">Remove</button>
                                        <button *ngIf="last" (click)="addWAD(i)">+Add</button> -->
                                    </ng-container>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationOnInitialAssesment)}}</label>
                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"
                                                        [showClear]="true" class="pngDropdown"
                                                        formControlName="classificationOnInitialAssesment"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'classificationOnInitialAssesment'"
                                                              [form]="outcomeMeasureForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationPostTreatment)}}</label>
                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"
                                                        [showClear]="true" class="pngDropdown"
                                                        formControlName="classificationPostTreatment"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'classificationPostTreatment'"
                                                              [form]="outcomeMeasureForm"></app-custom-error>
                                        </div>
            
                                        <div class="p-field p-col-12 formfieldheight">
                                            <label > Upload File</label>
                                            <!-- <label>{{translate(labels.expertColumnTncUrl)}}</label> -->


                                            <!-- <p-fileUpload name="demo[]" (uploadHandler)="onTNCUpload($event)"
                                                multiple="multiple" accept="image/*" customUpload="true" 
                                                maxFileSize="1000000" class="pngFileUpload">
                                                <ng-template pTemplate="content">
                                                    <ul *ngIf="tncUploadedFiles.length>0">
                                                         <li *ngFor="let file of tncUploadedFiles">{{file.name}} -
                                                            {{file.size}}
                                                            bytes</li> 
                                                    </ul>
                                                </ng-template>
                                            </p-fileUpload> -->

                                            <p-fileUpload #uploadRef
                                                          name="file[]"
                                                          customUpload="true"
                                                          (uploadHandler)="onTNCUpload($event,uploadRef)"
                                                          multiple="multiple"
                                                          accept="image/*">
                        <!-- multiple="multiple" -->
                        <ng-template pTemplate="content">
                         
                                    <div *ngIf="tncUploadedFiles.length > 0" class="fileuploadEditContainer">
                                        <div class="fileuploadRow" *ngFor="let image of tncUploadedFiles,  let i = index">
                                           
                                            <div >
                                                <img *ngIf="image.type!='text/plain'" [src]="image.src" />
                                            </div>
                                            <div>
                                                {{image?.name}}
                                            </div>
                                            <div>
                                                {{image?.size}} B
                                            </div>
                                            <!-- <div>Screen-09.png Screen-09.pngScreen-09.png</div>
                                            <div>369.587 KB</div> -->
                                            <div>
                                                <button icon="pi pi-times" pbutton type="button"
                                                    class="p-button p-component p-button-icon-only"
                                                    (click)="DeleteImage(image,i)">
                                                    <span class="p-button-icon pi pi-times"></span> </button>
                                            </div>
                                        </div>
                                    </div>

                       
                        </ng-template>
                    </p-fileUpload>


                                            
                                        </div>






                                        <!--                    <div class="p-field p-col-12  p-md-6 formfieldheight">-->
                                        <!--                      <label>{{translate(labels.outcomeMeasureColumnPatientId)}}</label>-->
                                        <!--                      <p-dropdown [filter]="true" [options]="usersList" [showClear]="true" class="pngDropdown"-->
                                        <!--                                  formControlName="patientId"-->
                                        <!--                                  placeholder={{translate(labels.outcomeMeasureColumnPatientId)}}></p-dropdown>-->
                                        <!--                      <app-custom-error [control]="'patientId'" [form]="outcomeMeasureForm"></app-custom-error>-->
                                        <!--                    </div>-->
                                        <!--                    <div class="p-field p-col-12  p-md-6 formfieldheight">-->
                                        <!--                      <label>{{translate(labels.outcomeMeasureColumnExpertId)}}</label>-->
                                        <!--                      <p-dropdown [filter]="true" [options]="usersList" [showClear]="true" class="pngDropdown"-->
                                        <!--                                  formControlName="expertId"-->
                                        <!--                                  placeholder={{translate(labels.outcomeMeasureColumnExpertId)}}></p-dropdown>-->
                                        <!--                      <app-custom-error [control]="'expertId'" [form]="outcomeMeasureForm"></app-custom-error>-->
                                        <!--                    </div>-->
                                        <!--                    <div class="p-field p-col-12  p-md-6 formfieldheight">-->
                                        <!--                      <label>{{translate(labels.outcomeMeasureColumnAppointmentId)}}</label>-->
                                        <!--                      <p-dropdown [filter]="true" [options]="appointmentsList" [showClear]="true"-->
                                        <!--                                  class="pngDropdown" formControlName="appointmentId"-->
                                        <!--                                  placeholder={{translate(labels.outcomeMeasureColumnAppointmentId)}}></p-dropdown>-->
                                        <!--                      <app-custom-error [control]="'appointmentId'" [form]="outcomeMeasureForm"></app-custom-error>-->
                                        <!--                    </div>-->



                                        <!-- comment by naeem -->
                                        <!-- <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnUserId)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('userId')"
                                                           [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="userId"
                                                           mode="decimal" name="userId"
                                                           pNumberInput
                                                           type="number"></p-inputNumber>
                                            <app-custom-error [control]="'userId'"
                                                              [form]="outcomeMeasureForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationOnInitialAssessmentCommnets)}}</label>
                                            <span class="p-input-icon-right"><i
                                                    (click)="fieldReset('classificationOnInitialAssessmentCommnets')"
                                                    *ngIf="diplayeIconInField('classificationOnInitialAssessmentCommnets')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('classificationOnInitialAssessmentCommnets')"
                                                    class="pngInputtext"
                                                    formControlName="classificationOnInitialAssessmentCommnets"
                                                    name="classificationOnInitialAssessmentCommnets" pInputText
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'classificationOnInitialAssessmentCommnets'"
                                                              [form]="outcomeMeasureForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.outcomeMeasureColumnClassificationPostTreatmentCommnets)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('classificationPostTreatmentCommnets')"
                                                    *ngIf="diplayeIconInField('classificationPostTreatmentCommnets')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('classificationPostTreatmentCommnets')"
                                                    class="pngInputtext"
                                                    formControlName="classificationPostTreatmentCommnets"
                                                    name="classificationPostTreatmentCommnets" pInputText
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'classificationPostTreatmentCommnets'"
                                                              [form]="outcomeMeasureForm"></app-custom-error>
                                        </div> -->

                                        <!-- <--coment end by naeem> -->
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header [hidden]="showSearchBtn" >
                                    <span
                                        class="p-input-icon-right globalSearch"><i
                                        class="pi pi-search"></i><input class="pngInputtext" pInputText
                                                                        placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                                        type="text"/></span></p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="outcomeMeasureList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': outcomeMeasureList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Classification OnInitial Assesment</th>
                                            <th> Classification Post Treatment</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.classificationOnInitialAssesment}} </td>
                                            <td> {{element?.classificationPostTreatment}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadOutcomeMeasureToEdit(element.outcomeMeasureId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdOutcomeMeasure(element.outcomeMeasureId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View" [hidden]="showSearchBtn"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
<!-- {{outcomeMeasureForm.value | json}} -->
