import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {FormDataDTO} from "../dto/FormDataDTO";

@Injectable({providedIn: 'root'})
export class TestingAromService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdTestingArom(testingAromId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'TestingArom/deleteTestingArom', {'testingAromId': testingAromId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countTestingArom(testingArom): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'TestingArom/count', {'testingArom': testingArom}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateTestingArom(testingArom, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'TestingArom/updateTestingArom', [testingArom, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addTestingArom(testingArom, refData): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'TestingArom/addTestingArom', [testingArom, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addTestingAromCpa(testingAromDTO: FormDataDTO): Observable<any> {
    return this.httpClient.post<string>(Constant.CPA_SPRING_API_URL + 'testing-aroms', testingAromDTO, {headers: new HttpHeaders()
        .set('Content-Type', 'application/json'),
      observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }

    findAllTestingArom(testingArom, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingArom/findAllTestingArom',
            {'testingArom': testingArom, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'TestingArom/countGlobalArom',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'TestingArom/findGlobalArom',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  findAllTestingAromsByPatientId(patientId: number): Observable<any> {
    return this.httpClient.get<any>(

      Constant.CPA_SPRING_API_URL + 'patients/' + patientId  + '/testing-aroms', {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }



}
