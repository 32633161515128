import {Constant} from '../../util/constant';
import {MultiSelect} from '../MultiSelect';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PastMedicalHistoryMultiSelects extends MultiSelect {

  objectMultiSelects = {
    ['previousMsk']: {

      name: Constant.PAST_MEDICAL_HISTORY_PREVIOUS_MSK,
      dbField: 'previousMsk',
      label: 'Previous Msk',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false,

    },
    ['bloods']: {
      name: Constant.PAST_MEDICAL_HISTORY_BLOODS,
      dbField: 'bloods',
      label: 'Bloods',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['imaging']: {
      name: Constant.PAST_MEDICAL_HISTORY_IMAGING,
      dbField: 'imaging',
      label: 'Imaging',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['systemic']: {
      name: Constant.PAST_MEDICAL_HISTORY_SYSTEMIC,
      dbField: 'systemic',
      label: 'Systemic',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['illness']: {
      name: Constant.PAST_MEDICAL_HISTORY_ILLNESS,
      dbField: 'illness',
      label: 'Illness',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false

    },
    ['threads']: {
      name: Constant.PAST_MEDICAL_HISTORY_THREADS,
      dbField: 'threads',
      label: 'Threads',
      list: [],
      currentSelected: [],
      currentListOfValuesMasterId: 0,
      isRequired: true,
      error: false
    }
  };


    multiSelects = [

    {

      name: Constant.PAST_MEDICAL_HISTORY_PREVIOUS_MSK,
      dbField: 'previousMsk',
      label: 'Previous Msk',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false,
    },
    {

      name: Constant.PAST_MEDICAL_HISTORY_BLOODS,
      dbField: 'bloods',
      label: 'Bloods',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    {

      name: Constant.PAST_MEDICAL_HISTORY_IMAGING,
      dbField: 'imaging',
      label: 'Imaging',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    {

      name: Constant.PAST_MEDICAL_HISTORY_SYSTEMIC,
      dbField: 'systemic',
      label: 'Systemic',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    {

      name: Constant.PAST_MEDICAL_HISTORY_ILLNESS,
      dbField: 'illness',
      label: 'Illness',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },
    {

      name: Constant.PAST_MEDICAL_HISTORY_THREADS,
      dbField: 'threads',
      label: 'Threads',
      list: [],
      currentSelected: [],
      isRequired: true,
      error: false
    },

  ];

    getResponse($event): any {
     return super.getResponse($event);
  }

  getResponseObjects($event): any {
    return super.getResponseObjects($event);
  }

}
