<div class="chatoverlaydiv" id="groupinfochat-overlaydiv">
    <i (click)="backToChatScreen()" class="fas fa-arrow-left" style="float: left;"></i>
    <div class="groupinfochat-header">

        <div class="groupinfochat-logodiv">
            <img src="assets/theme1/custom-files/img/login/Hive-logo.png" class="img-fluid">
        </div>

        <div class="icongroup" id="collaboration-icongroup">
            <button class="btnxs-transparent-icon" (click)="deleteGroup()">
                <i class="delete-icon" title="Delete Group"></i>
            </button>

            <button class="btnxs-transparent-icon" (click)="leaveGroup()">
                <i class="leavechat-icon" title="Leave Group"></i>
            </button>
        </div>

        <!-- NOTE ********** FAISAL BEHRAM
          Below menu i have comments and above these comments i have added buttons

        -->
        <!-- <button mat-icon-button class="matDropdownmeubtn" id="groupinfo-matDropdownmeubtn"
          [matMenuTriggerFor]="projectdropdownmenu">
          <i _ngcontent-thb-c6="" class="vertical3dotsmenu-icon"></i>
        </button>
        <mat-menu #projectdropdownmenu="matMenu" class="profile-menu" xPosition="before">
          <button (click)="leaveGroup()" mat-menu-item>
            <i class="leavechat-icon"></i>
            <span class="dropdownmenu-text"> {{verifyLabel('Leave Chat')}} </span>
          </button>
          <button (click)="deleteGroup()" mat-menu-item>
            <i class="delete-icon"></i>
            <span class="dropdownmenu-text"> {{verifyLabel('Delete Chat')}} </span>
          </button>
        </mat-menu> -->

    </div>

    <div class="groupinfochat-body">

        <form [formGroup]="updateGroupForm">
            <mat-form-field class="angInput" id="groupinfochat-angInput-title">
              <input name="groupName" formControlName="title" [(ngModel)]="groupName" matInput type="text"
                placeholder="{{verifyLabel('Title')}}">
              <button [disabled]="true" class="btnxs-transparent-icon-inputfields">
                <i class="edit-icon"></i>
              </button>
              <mat-error> {{verifyLabel('Title')}} {{verifyLabel('Is Required')}} </mat-error>
            </mat-form-field>

<!--            <input pInputText type="text" class="form-control" [(ngModel)]="groupName" formControlName="title"-->
<!--                   placeholder="Chat Title"-->
<!--                   pInputText>-->

<!--            <textarea [rows]="2" pInputTextarea [(ngModel)]="groupDescription" class="form-control"-->
<!--                      formControlName="description" placeholder="Chat Description"></textarea>-->


            <mat-form-field class="angInput" id="groupinfochat-angInput-description">
              <textarea matInput formControlName="description" name="groupDescription"
                [(ngModel)]="groupDescription"></textarea>
              <button [disabled]="true" class="btnxs-transparent-icon-inputfields">
                <i class="edit-icon"></i>
              </button>
              <mat-error> {{verifyLabel('Description')}} {{verifyLabel('Is Required')}} </mat-error>
            </mat-form-field>
        </form>

        <div class="addparticipantswrapper" id="addparticipantswrapper-groupinfochat">
            <h5 class="addparticipants-heading">{{verifyLabel('Added Contacts')}} </h5>
            <div class="addparticipantswrapper-body">
                <ng-container *ngFor="let groupparticipant of participants; let i = index">
                    <div class="chat-addparticipants">
                        {{groupparticipant.participant.username}}    {{groupparticipant.participant.lastName}}
                        <ng-container *ngIf="(groupparticipant.participant.userId!=loggedInUser)">
                            <a (click)="removeParticipant(groupparticipant , i)"
                               class="chat-addparticipants-admin-closebtn"
                            >
                                <i class="fa fa-times"></i>
                            </a>
                        </ng-container>
                    </div>
                    <!-- <div class="chat-addparticipants-admin">
                      Julia Roberts
                      <a href="#" class="chat-addparticipants-closebtn">
                        <i class="fa fa-times"></i>
                      </a>
                    </div> -->
                </ng-container>
            </div>
        </div>
        <!-- <pre>{{updateGroupForm.valid|json}}</pre> -->
        <div class="groupinfochat-btmbtns" id="add-chat-group-bottombtn">
            <button (click)="openUser()" class="btnmd">
                <i class="fa fa-user-plus"></i> {{verifyLabel('Add Contact')}} </button>
        </div>
        <div class="groupinfochat-btmbtns" id="save-chat-group-bottombtn">
            <button [disabled]="updateGroupForm.invalid" (click)="updateGroup()"
                    class="btnmd">{{verifyLabel('Save')}}</button>
        </div>

    </div>
</div>