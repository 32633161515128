export class Labels {
    public static btnAdd = 'Add';
    public static btnEdit = 'Edit';
    public static btnUpdated = 'Updated';
    public static btnSave = 'Save';
    public static btnClose = 'Close';
    public static btnUpdate = 'Update';
    public static btnSearch = 'Search';
    public static btnClear = 'Clear';
    public static btnCancel = 'Cancel';
    public static btnDelete = 'Deleted';
    public static headerAction = 'Action';
    public static validation_required = 'This field is required';
    public static validation_emptySpace = 'Empty Space is not allowed';
    public static validation_minLength = 'This value should only have min 1 digits';
    public static validation_maxLength = 'This value should only have maximum 10 digits';
    public static validation_min = 'Minimum length should be 1';
    public static validation_max = 'Maximum value should be 10';
    public static mat_pagination_no_data_msg = 'No Data Available';
    public static DELETE_MSG = 'Are you sure that you want to perform this action?';
    public static DATA = 'data';
    public static LIST = 'list';
    public static FORM = 'form';
    public static GLOBEL = 'Global';
    public static ADVANCED = 'Advanced';
    public static MEMBER = 'Member';
    public static I_CONFIRM = 'I confirm';
    public static I_REJECT = 'I reject';


    public static entityNameAgency = 'Company';
    public static agencyColumnAgencyTitle = 'Title';
    public static agencyColumnAgencyFirstname = 'First Name';
    public static agencyColumnAgencyLastname = 'Last Name';
    public static agencyColumnAgencyName = 'Company Name';
    public static agencyColumnAgencyType = 'Company Type';
    public static agencyColumnAgencyAddress = 'Address';
    public static agencyColumnAgencyPhoneNumber = 'Phone Number';
    public static agencyColumnAgencyEmail = 'Company Email';
    public static agencyColumnCommunicationPrefs = 'Communication Preference';
    public static agencyColumnMarketingInfo = 'Marketing Info';
    public static agencyColumnBilling = 'Billing';
    public static agencyColumnInvoiceInfo = 'Invoice Info';
    public static agencyColumnUniqueReferenceNumber = 'Unique Reference Number';
    public static agencyColumnReferralSource = 'Referral Source';
    public static agencyColumnNumberOfUsers = 'Number Of Users';
    public static agencyColumnUserEmail = 'User Email';
    public static agencyColumnUserPassword = 'User Password';
    public static agencyColumnUserId = 'User Id';
    public static entityNameAgencyClinic = 'Agency Clinic';
    public static agencyClinicColumnAgencyId = 'agency Id';
    public static agencyClinicColumnClinicId = 'clinic Id';
    public static agencyClinicColumnUserId = 'user Id';
    public static entityNameAgencyCompany = 'Agency Company';
    public static agencyCompanyColumnAgencyId = 'agency Id';
    public static agencyCompanyColumnCompanyId = 'company Id';
    public static agencyCompanyColumnUserId = 'user Id';
    public static entityNameAgencyContacts = 'Agency Contacts';
    public static agencyContactsColumnAgencyId = 'agency Id';
    public static agencyContactsColumnContacts = 'contacts';
    public static agencyContactsColumnUserId = 'user Id';
    public static entityNameAgencyExpert = 'Agency Expert';
    public static agencyExpertColumnAgencyId = 'agency Id';
    public static agencyExpertColumnExpertId = 'expert Id';
    public static agencyExpertColumnUserId = 'user Id';
    public static entityNameAgencyPatients = 'Agency Patients';
    public static agencyPatientsColumnAgencyId = 'agency Id';
    public static agencyPatientsColumnPatientId = 'patient Id';
    public static agencyPatientsColumnUserId = 'user Id';
    public static entityNameAgencySubscriptionChecklist = 'Agency Subscription Checklist';
    public static agencySubscriptionChecklistColumnAgencyId = 'agency Id';
    public static agencySubscriptionChecklistColumnUserId = 'user Id';
    public static agencySubscriptionChecklistColumnChecklistItemsId = 'checklist Items Id';
    public static agencySubscriptionChecklistColumnItemsStatus = 'items Status';
    public static entityNameAgencyType = 'Agency Type';
    public static agencyTypeColumnAgencyTypeTitle = 'agency Type Title';
    public static agencyTypeColumnUserId = 'user Id';
    public static entityNameAppointmentType = 'Appointment Type';
    public static appointmentTypeColumnAppointmentType = 'appointment Type';
    public static appointmentTypeColumnUserId = 'user Id';
    public static entityNameAppointments = 'Appointments';
    public static entityNameFollowUp = 'Follow Up';
    public static appointmentsColumnAppointmentTypeId = 'Appointment Type';
    public static appointmentsColumnAppointmentDate = 'Appointment Date';
    public static appointmentsColumnAppointmentTime = 'Appointment Time';
    public static appointmentsColumnPatientId = 'Patient';
    public static appointmentsColumnExpertId = 'Expert';
    public static appointmentsColumnParentAppointmentId = 'parent Appointment Id';
    public static appointmentsColumnClinicId = 'Clinic';
    public static appointmentsColumnUserId = 'user Id';
    public static appointmentsColumnConsent = 'Consent';
    public static appointmentsColumnPatientFor = 'Patient For';
    public static appointmentsColumnAllocateTo = 'Allocate To';
    public static appointmentsColumnClinicExpertSchedualId = 'Clinic Expert SchedualId';
    public static appointmentsColumnAgencyId = 'Agency Id';
    public static appointmentsColumnCompanyId = 'Company Id';
    public static entityNameBodyChartDetail = 'Body Chart';
    public static bodyChartDetailColumnBodyChartMasterId = 'body Chart MasterId';
    public static bodyChartDetailColumnBodyPart = 'Body Part';
    public static bodyChartDetailColumnDuration = 'Duration';
    public static bodyChartDetailColumnDescription = 'Description';
    public static bodyChartDetailColumnVas = 'VAS';
    public static bodyChartDetailColumnAggs = 'AGGS';
    public static bodyChartDetailColumnEases = 'Eases';
    public static bodyChartDetailColumnHourlySymptoms = '24 Hourly Symptoms';
    public static bodyChartDetailColumnIntervalDescription = 'Interval Description';
    public static bodyChartDetailColumnAppointmentId = 'appointment Id';
    public static bodyChartDetailColumnUserId = 'user Id';
    public static entityNameBodyChartMaster = 'Body Chart Master';
    public static bodyChartMasterColumnBodyPartId = 'body Part Id';
    public static bodyChartMasterColumnPatientId = 'patient Id';
    public static bodyChartMasterColumnExpertId = 'expert Id';
    public static bodyChartMasterColumnAppointmentId = 'appointment Id';
    public static bodyChartMasterColumnUserId = 'user Id';

    public static entityNameDiagnosis = 'Diagnosis';
    public static entityNamePatientDiagnosis = 'Patient Diagnosis';
    public static reviewPage = 'Review';

    public static treatmentColumnPredictiveTreatment = 'Predictive Treatment';
    public static treatmentColumnRecommendedTreatment = 'Recommended Treatment';

    public static entityNameCategories = 'Categories';
    public static categoriesColumnCategoryTitle = 'category Title';
    public static categoriesColumnDescription = 'Description';
    public static categoriesColumnUserId = 'user Id';
    public static entityNameClientDetails = 'Client Details';
    public static clientDetailsColumnDateOfAccident = 'Date Of Accident';
    public static clientDetailsColumnTitle = 'Title';
    public static clientDetailsColumnPatientName = 'Name';
    public static clientDetailsColumnPatientAddress = 'Address';
    public static clientDetailsColumnPatientEmail = 'Email';
    public static clientDetailsColumnPatientContact = 'Contact';
    public static clientDetailsColumnPatientGender = 'Gender';
    public static clientDetailsColumnPatientId = 'patient Id';
    public static clientDetailsColumnExpertId = 'expert Id';
    public static clientDetailsColumnUserId = 'user Id';
    public static entityNameClinicExperts = 'Clinic Experts';
    public static clinicExpertsColumnClinicId = 'Clinics';
    public static clinicExpertsColumnExpertId = 'Experts';
    public static clinicExpertsColumnUserId = 'user Id';
    public static entityNameClinicExpertsSchedual = 'Clinic Experts Schedule';
    public static clinicExpertsSchedualColumnClinicId = 'Clinics';
    public static clinicExpertsSchedualColumnExpertsId = 'Experts';
    public static clinicExpertsSchedualColumnClinicExpertId = 'clinic Expert Id';
    public static clinicExpertsSchedualColumnUserId = 'user Id';
    public static clinicExpertsSchedualColumnStartTime = 'Start Time';
    public static clinicExpertsSchedualColumnEndTime = 'End Time';
    public static clinicExpertsSchedualColumnSlotTime = 'Average Slot Time (mins)';
    public static entityNameClinicType = 'Clinic Type';
    public static clinicTypeColumnClinicType = 'clinic Type';
    public static clinicTypeColumnDescription = 'Description';
    public static clinicTypeColumnUserId = 'user Id';
    public static entityNameClinics = 'Clinics';
    public static clinicsColumnUserId = 'user Id';
    public static clinicsColumnAgencyId = 'agency Id';
    public static clinicsColumnClinicName = 'Clinic Name';
    public static clinicsColumnContactName = 'Contact Name';
    public static clinicsColumnSpecialistType = 'Specialist Type';
    public static clinicsColumnTelNumber = 'Telephone Number';
    public static clinicsColumnAddress = 'Address';
    public static clinicsColumnNotes = 'Notes';
    public static clinicsColumnClinicTypeId = 'Clinic Type';
    public static entityNameCompany = 'Company';
    public static companyColumnCompanyName = 'Company Name';
    public static companyColumnCompanyEmail = 'Company Email';
    public static companyColumnCompanyAddress = 'Company Address';
    public static companyColumnCompanyPhoneNumber = 'Company Phone Number';
    public static companyColumnUserId = 'user Id';
    public static companyColumnNumberOfUsers = 'Number Of Users';
    public static entityNameCompanyUsers = 'Manage Users';
    public static companyUsersColumnCompanyId = 'company Id';
    public static companyUsersColumnUserName = 'User Name';
    public static companyUsersColumnLoginEmail = 'Login Email';
    public static companyUsersColumnPassword = 'Password'
    public static companyUsersColumnUserId = 'user Id';
    public static companyUsersColumnMUserId = 'M User Id';
    public static entityNameCompnayPatients = 'Company Patients';
    public static compnayPatientsColumnCompanyId = 'company Id';
    public static compnayPatientsColumnPatientId = 'patient Id';
    public static compnayPatientsColumnUserId = 'user Id';
    public static entityNameContacts = 'Contacts';
    public static contactsColumnContactsFirstname = 'First Name';
    public static contactsColumnContactsLastname = 'Last Name';
    public static contactsColumnContactsOccupation = 'Occupation';
    public static contactsColumnContactsCompany = 'Company';
    public static contactsColumnContactsEmail = 'Email';
    public static contactsColumnContactsPassword = 'Password';
    public static contactsColumnContactsAddress = 'Address';
    public static contactsColumnUserId = 'user Id';
    public static entityNameDischarge = 'Discharge';
    public static dischargeColumnDetailsOfTreatment = 'Details Of Treatment';
    public static dischargeColumnShortTermBenefits = 'Short Term Benefits';
    public static dischargeColumnLongTermBenefits = 'Long Term Benefits';
    public static dischargeColumnOutcomeMeasuresToBeImplemented = 'Outcome Measures To Be Implemented';
    public static dischargeColumnPatientId = 'patient Id';
    public static dischargeColumnExpertId = 'expert Id';
    public static dischargeColumnAppointmentId = 'appointment Id';
    public static dischargeColumnUserId = 'user Id';
    public static dischargeColumnDischargeDate = 'Discharge Date';
    public static entityNameDrugHistory = 'Drug History';
    public static drugHistoryColumnDrugHistory = 'Drug History';
    public static drugHistoryColumnDrugHistoryChecked = 'Drug History Checked';
    public static drugHistoryColumnRedFlagMedication = 'Red Flag Medication';
    public static drugHistoryColumnRedFlagMedicationComment = 'Comment';
    public static drugHistoryColumnVaccination = 'Vaccinations';
    public static drugHistoryColumnVaccinationAffected = 'Vaccination Affected';
    public static drugHistoryColumnVaccinationComment = 'Comment';
    public static drugHistoryColumnLifestyle = 'Life Style';
    public static drugHistoryColumnLifestyleAffected = 'Life Style Affected';
    public static drugHistoryColumnLifestyleComment = 'Comment';
    public static drugHistoryColumnDrinks = 'Drinks';
    public static drugHistoryColumnDrinksAffected = 'Drinks Affected';
    public static drugHistoryColumnDrinksComment = 'Comment';
    public static drugHistoryColumnSmoke = 'Smoking Frequency';
    public static drugHistoryColumnSmokeAffected = 'Smoke Affected';
    public static drugHistoryColumnSmokeComment = 'Comment';
    public static drugHistoryColumnRecreation = 'Recreation';
    public static drugHistoryColumnRecreationAffected = 'Recreation Affected';
    public static drugHistoryColumnRecreationComment = 'Comment';
    public static drugHistoryColumnDrugs = 'Drugs';
    public static drugHistoryColumnDrugsAffected = 'Drugs Affected';
    public static drugHistoryColumnDrugsComment = 'Comment';
    public static drugHistoryColumnAdditionalComments = 'Additional Comments';
    public static drugHistoryColumnPatientId = 'patient Id';
    public static drugHistoryColumnExpertId = 'expert Id';
    public static drugHistoryColumnUserId = 'user Id';
    public static entityNameExerciseCategory = 'Exercise Category';
    public static exerciseCategoryColumnExerciseCategoryTitle = 'Exercise Category Title';
    public static exerciseCategoryColumnPatientId = 'patient Id';
    public static exerciseCategoryColumnExpertId = 'expert Id';
    public static exerciseCategoryColumnUserId = 'user Id';
    public static entityNameExercises = 'Exercises';
    public static exercisesColumnExerciseImageUrl = 'Exercise Image Url';
    public static exercisesColumnExerciseCategoryId = 'exercise Category Id';
    public static exercisesColumnAppointmentId = 'appointment Id';
    public static exercisesColumnUserId = 'user Id';
    public static exercisesColumnExerciseVideoUrl = 'exercise Video Url';
    public static entityNameExpenses = 'Expenses';
    public static expensesColumnAppointmentId = 'appointment Id';
    public static expensesColumnClinicId = 'clinic Id';
    public static expensesColumnPaidOn = 'Paid On';
    public static expensesColumnCompany = 'Company';
    public static expensesColumnSource = 'Source';
    public static expensesColumnUserId = 'User Id';
    public static expensesColumnExpenseType = 'Expense Type';
    public static expensesColumnExpenseAmount = 'Expense Amount';
    public static entityNameExpert = 'Expert';
    public static expertColumnUserId = 'user Id';
    public static expertColumnClientId = 'client Id';
    public static expertColumnExpertType = 'Expert Type';
    public static expertColumnTitle = 'Title';
    public static expertColumnFirstName = 'First Name';
    public static expertColumnLastName = 'Last Name';
    public static expertColumnOccupation = 'Occupation';
    public static expertColumnCompanyName = 'Company Name';
    public static expertColumnEmail = 'Email';
    public static expertColumnAddress = 'Address';
    public static expertColumnProfessionalDetail = 'Professional Detail';
    public static expertColumnCvUrl = 'Upload CV';
    public static expertColumnTncUrl = 'Upload Terms And Conditions';
    public static expertColumnSignatureUrl = 'Upload Signature File';
    public static expertColumnCreateEmail = 'Create Email';
    public static expertColumnCreatePassword = 'Create Password';
    public static expertColumnAppointmentTime = 'Appointment Time';
    public static expertColumnDaysWorked = 'Days Worked';
    public static entityNameFlags = 'Flags';
    public static entityNameImage = 'Image';

    public static flagsColumnFlag = 'Flag';
    public static flagsColumnFlagValue = 'Value';
    public static flagsColumnFlagStatus = 'Status';
    public static flagsColumnPatientId = 'patient Id';
    public static flagsColumnExpertId = 'expert Id';
    public static flagsColumnAppointmentId = 'appointment Id';
    public static flagsColumnUserId = 'user Id';
    public static flagsColumnFlagDate = 'Date';
    public static entityNameGoals = 'Goals';
    public static goalsColumnGoalDate = 'Date';
    public static goalsColumnGoals = 'Goals';
    public static goalsColumnSmartGoal = 'Smart Goal';
    public static goalsColumnPatientId = 'patient Id';
    public static goalsColumnExpertId = 'expert Id';
    public static goalsColumnAppointmentId = 'appointment Id';
    public static goalsColumnUserId = 'user Id';
    public static entityNameInvoice = 'Invoice';
    public static invoiceColumnPatientId = 'patient Id';
    public static invoiceColumnAgencyId = 'agency Id';
    public static invoiceColumnInvoiceTo = 'invoice To';
    public static invoiceColumnIssueDate = 'Issue Date';
    public static invoiceColumnInvoiceNumber = 'Invoice Number';
    public static invoiceColumnStartDate = 'Start Date';
    public static invoiceColumnEndDate = 'End Date';
    // public static invoiceColumnIssueTo = 'Issue To';
    public static invoiceColumnIssueTo = 'Received To';
    public static invoiceColumnIssueBy = 'Issue By';
    public static invoiceColumnUserId = 'user Id';
    public static invoiceColumnCompanyId = 'company Id';
    public static invoiceColumnIssueByType = 'Issue By Type';
    // public static invoiceColumnIssueToType = 'Issue To Type';
    public static invoiceColumnIssueToType = 'Received To Type';
    public static invoiceColumnInvoiceAmount = 'Invoice Amount';
    public static entityNameInvoiceItemsDetail = 'Items Detail';
    public static invoiceItemsDetailColumnInvoiceId = 'invoice Id';
    public static invoiceItemsDetailColumnItemId = 'Item';
    public static invoiceItemsDetailColumnQuantity = 'Quantity';
    public static invoiceItemsDetailColumnUnitPrice = 'Unit Price';
    public static invoiceItemsDetailColumnDiscount = 'Discount';
    public static invoiceItemsDetailColumnTaxAmount = 'Tax Amount';
    public static invoiceItemsDetailColumnTotalAmount = 'Total Amount';
    public static invoiceItemsDetailColumnAdditionalNotes = 'Additional Notes';
    public static invoiceItemsDetailColumnUserId = 'user Id';
    public static entityNameListOfValues = 'List Of Values';
    public static listOfValuesColumnCategoryId = 'Category';
    public static listOfValuesColumnValue = 'Value';
    public static listOfValuesColumnLabel = 'Label';
    public static listOfValuesColumnUserId = 'user Id';
    public static entityNameListOfValuesDetail = 'list Of Values Detail';
    public static listOfValuesDetailColumnListOfValuesMasterId = 'list Of Values MasterId';
    public static listOfValuesDetailColumnListOfValuesId = 'list Of ValuesId';
    public static listOfValuesDetailColumnReferenceType = 'Reference Type';
    public static listOfValuesDetailColumnReferenceId = 'Reference Id';
    public static listOfValuesDetailColumnLovValues = 'Lov Values';
    public static listOfValuesDetailColumnUserId = 'user Id';
    public static entityNameListOfValuesMaster = 'list Of Values Master';
    public static listOfValuesMasterColumnCategoryId = 'category Id';
    public static listOfValuesMasterColumnReferenceType = 'reference Type';
    public static listOfValuesMasterColumnReferenceId = 'reference Id';
    public static listOfValuesMasterColumnUserId = 'user Id';
    public static entityNameObservations = 'Observations';
    public static observationsColumnObservations = 'Observations';
    public static observationsColumnAffected = 'Affected';
    public static observationsColumnComments = 'Comments';
    public static observationsColumnPatientId = 'patient Id';
    public static observationsColumnExpertId = 'expert Id';
    public static observationsColumnAppointmentId = 'appointment Id';
    public static observationsColumnUserId = 'user Id';
    public static entityNameOutcomeMeasure = 'Outcome Measure';
    public static entityNameOutcomeMeasureDetail = 'Outcome Measure Detail';
    public static outcomeMeasureColumnClassificationOnInitialAssesment = 'Classification On Initial Assesment';
    public static outcomeMeasureColumnClassificationPostTreatment = 'Classification Post Treatment';
    public static outcomeMeasureColumnUploadImage = 'upload Image';
    public static outcomeMeasureColumnPatientId = 'patient Id';
    public static outcomeMeasureColumnExpertId = 'expert Id';
    public static outcomeMeasureColumnAppointmentId = 'appointment Id';
    public static outcomeMeasureColumnUserId = 'user Id';
    public static outcomeMeasureColumnClassificationOnInitialAssessmentCommnets = 'classification On Initial Assessment Comments';
    public static outcomeMeasureColumnClassificationPostTreatmentCommnets = 'classification Post Treatment Comments';
    public static entityNameOutcomeMeasureImages = 'Outcome Measure Images';
    public static outcomeMeasureImagesColumnImageUrl = 'Image Url';
    public static outcomeMeasureImagesColumnOutcomeMeasureId = 'Outcome Measure Id';
    public static outcomeMeasureImagesColumnPatientId = 'patient Id';
    public static outcomeMeasureImagesColumnExpertId = 'expert Id';
    public static outcomeMeasureImagesColumnAppointmentId = 'appointment Id';
    public static outcomeMeasureImagesColumnUserId = 'user Id';
    public static outcomeMeasureImagesColumnImageComments = 'Image Comments';
    public static entityNamePalpation = 'Palpation';
    public static palpationColumnPatientId = 'patient Id';
    public static palpationColumnExpertId = 'expert Id';
    public static palpationColumnAppointmentId = 'appointment Id';
    public static palpationColumnUserId = 'user Id';
    public static palpationColumnPalpationTypeId = 'palpation Type Id';
    public static palpationColumnPalpationAffectedId = 'palpation Affected Id';
    public static palpationColumnPalpationSeverityId = 'palpation Severity Id';
    public static palpationColumnPalpationComments = 'palpation Comments';


    public static palpationColumnTenderness = 'Tenderness';
    public static palpationColumnTendernessSeverity = 'Severity';
    public static palpationColumnTendernessComments = 'Comment';
    public static palpationColumnMuscleSpasm = 'Muscle Spasm';
    public static palpationColumnMuscleSpasmSeverity = 'Severity';
    public static palpationColumnMuscleSpasmComments = 'Comment';
    public static palpationColumnBonyTenderness = 'Bony Tenderness';
    public static palpationColumnBonyTendernessSeverity = 'Severity';
    public static palpationColumnBonyTendernessComments = 'Comment';



    public static entityNamePastMedicalHistory = 'Medical History';
    public static pastMedicalHistoryColumnPmh = 'PMH';
    public static pastMedicalHistoryColumnSpecialQuestions = 'Special Questions';
    public static pastMedicalHistoryColumnSpecialQuestionsChecked = 'Special Questions Checked';
    public static pastMedicalHistoryColumnNeurologicalSymptoms = 'Neurological Symptoms';
    public static pastMedicalHistoryColumnBloods = 'Bloods';
    public static pastMedicalHistoryColumnImaging = 'Imaging';
    public static pastMedicalHistoryColumnSystemic = 'Systemic';
    public static pastMedicalHistoryColumnIllness = 'Illness';
    public static pastMedicalHistoryColumnThreads = 'Threads';
    public static pastMedicalHistoryColumnPatientId = 'patient Id';
    public static pastMedicalHistoryColumnExpertId = 'expert Id';
    public static pastMedicalHistoryColumnUserId = 'user Id';
    public static entityNamePatientExercises = 'Patient Exercises';
    public static patientExercisesColumnExerciseId = 'exercise Id';
    public static patientExercisesColumnPatientId = 'patient Id';
    public static patientExercisesColumnExpertId = 'expert Id';
    public static patientExercisesColumnAppointmentId = 'appointment Id';
    public static patientExercisesColumnUserId = 'user Id';
    public static entityNamePatients = 'Patients';
    public static patientsColumnUserId = 'User Id';
    public static patientsColumnFirstName = 'First Name';
    public static patientsColumnLastName = 'Last Name';
    public static patientsColumnDob = 'Date of Birth';
    public static patientsColumnSex = 'Sex';
    public static patientsColumnGenderIdentity = 'Gender Identity';
    public static patientsColumnOccupation = 'Occupation';
    public static patientsColumnEmergencyContact = 'Emergency Contact';
    public static patientsColumnConsent = 'Consent';
    public static patientsColumnEmail = 'Email';
    public static patientsColumnUserEmail = 'User Email';
    public static patientsColumnUserPassword = 'Password';
    public static patientsColumnAddress = 'Address';
    public static patientsColumnCommPrefs = 'Communication Preference';
    public static patientsColumnMarketingInfo = 'Marketing Info';
    public static patientsColumnPatientType = 'Patient For';
    public static patientsColumnBilling = 'Billing';
    public static patientsColumnConscessionType = 'Conscession Type';
    public static patientsColumnInvoice = 'Invoice';
    public static patientsColumnInvoiceInfo = 'Invoice Info';
    public static patientsColumnInsuranceNumber = 'Insurance Number';
    public static patientsColumnUniqueRefNumber = 'Unique Reference Number';
    public static patientsColumnReferralSource = 'Referral Source';
    public static patientsColumnIsSystemUser = 'is System User';
    public static patientsColumnPatientUserId = 'patient User Id';
    public static patientsColumnCompanyId = 'company Id';
    public static entityNamePayments = 'Payments';
    public static paymentsColumnInvoiceId = 'Invoices';
    public static paymentsColumnAgencyId = 'Agency';
    public static paymentsColumnAmount = 'Total Amount';
    public static paymentsColumnPaidOn = 'Paid On';
    public static paymentsColumnPaidAt = 'Paid At';
    public static paymentsColumnSource = 'Source';
    public static paymentsColumnBalanceSummary = 'Balance Summary';
    public static paymentsColumnUserId = 'User Id';
    public static paymentsColumnPaymentDescription = 'Payment Description';
    public static paymentsColumnPaymentType = 'Payment Type';
    public static entityNamePosture = 'Posture';
    public static postureColumnPostureDescription = 'Posture Description';
    public static postureColumnPostureComment = 'Posture Comment';
    public static postureColumnGaitDescription = 'Gait Description';
    public static postureColumnGaitComment = 'Gait Comment';
    public static postureColumnPatientId = 'patient Id';
    public static postureColumnExpertId = 'expert Id';
    public static postureColumnAppointmentId = 'appointment Id';
    public static postureColumnUserId = 'user Id';
    public static entityNamePresentConditionHistory = 'History Present Condition';
    public static presentConditionHistoryColumnInitialAssesmentDate = 'Initial Assesment Date';
    public static presentConditionHistoryColumnHpc = 'HPC';
    public static presentConditionHistoryColumnServicesSeen = 'Services Seen';
    public static presentConditionHistoryColumnTreatmentType = 'Treatment Type';
    public static presentConditionHistoryColumnInvestigation = 'Investigation';
    public static presentConditionHistoryColumnPresentSymptoms = 'Present Symptoms';
    public static presentConditionHistoryColumnPatientId = 'patient Id';
    public static presentConditionHistoryColumnExpertId = 'expert Id';
    public static presentConditionHistoryColumnRootPresentConditionHistoryId = 'root Present Condition HistoryId';
    public static presentConditionHistoryColumnAppointmentId = 'appointment Id';
    public static presentConditionHistoryColumnOutcomeTreatmentType = 'Treatment Outcome';
    public static presentConditionHistoryColumnOutcomeInvestigation = 'Investigation Outcome';
    public static presentConditionHistoryColumnUserId = 'user Id';
    public static entityNameSocialHistory = 'Social History';
    public static socialHistoryColumnOccupation = 'Occupation';
    public static socialHistoryColumnRetired = 'Retired';
    public static socialHistoryColumnAdl = 'ADL';
    public static socialHistoryColumnMobility = 'Mobility';
    public static socialHistoryColumnHobbies = 'Hobbies';
    public static socialHistoryColumnPatientId = 'patient Id';
    public static socialHistoryColumnExpertId = 'expert Id';
    public static socialHistoryColumnUserId = 'user Id';
    public static entityNameTestingArom = 'AROM';
    public static testingAromColumnJoint = 'Joint';
    public static testingAromColumnAromSide = 'Side';
    public static testingAromColumnMovement = 'Movement';
    public static testingAromColumnMeasure = 'Measure';
    public static testingAromColumnLimitigFactor = 'Limiting Factor';
    public static testingAromColumnPatientId = 'patient Id';
    public static testingAromColumnExpertId = 'expert Id';
    public static testingAromColumnAppointmentId = 'appointment Id';
    public static testingAromColumnBodyChartId = 'body Chart Id';
    public static testingAromColumnUserId = 'user Id';
    public static entityNameTestingMuscle = 'Muscle Testing';
    public static testingMuscleColumnMuscleGroup = 'Muscle Group';
    public static testingMuscleColumnMuscleSide = 'Muscle Side';
    public static testingMuscleColumnComment = 'Comment';
    public static testingMuscleColumnMeasures = 'Measures';
    public static testingMuscleColumnPatientId = 'patient Id';
    public static testingMuscleColumnExpertId = 'expert Id';
    public static testingMuscleColumnAppointmentId = 'appointment Id';
    public static testingMuscleColumnBodyChartId = 'body Chart Id';
    public static testingMuscleColumnUserId = 'user Id';
    public static entityNameTestingNeuro = 'Neuro Testing';
    public static testingNeuroColumnDermatome = 'Dermatome';
    public static testingNeuroColumnDermatomeSide = 'Dermatome Side';
    public static testingNeuroColumnDermatomeDescription = 'Dermatome Description';
    public static testingNeuroColumnDermatomeComments = 'Dermatome Comments';
    public static testingNeuroColumnReflexes = 'Reflexes';
    public static testingNeuroColumnReflexesSide = 'Reflexes Side';
    public static testingNeuroColumnReflexesDescription = 'Reflexes Description';
    public static testingNeuroColumnPatientId = 'patient Id';
    public static testingNeuroColumnExpertId = 'expert Id';
    public static testingNeuroColumnAppointmentId = 'appointment Id';
    public static testingNeuroColumnBodyChartId = 'body Chart Id';
    public static testingNeuroColumnUserId = 'user Id';
    public static entityNameTestingSpecial = 'Special Test';
    public static testingSpecialColumnSpecialTestsPerformed = 'Special Tests Performed';
    public static testingSpecialColumnTestType = 'Type';
    public static testingSpecialColumnSide = 'Side';
    public static testingSpecialColumnTestComments = 'Comment';
    public static testingSpecialColumnPatientId = 'patient Id';
    public static testingSpecialColumnExpertId = 'expert Id';
    public static testingSpecialColumnAppointmentId = 'appointment Id';
    public static testingSpecialColumnBodyChartId = 'body Chart Id';
    public static testingSpecialColumnUserId = 'user Id';
    public static entityNameTreatment = 'Treatment';
    public static treatmentColumnTreatmentDescription = 'Treatment Description';
    public static treatmentColumnTreatmentProvided = 'Treatment Provided';
    public static treatmentColumnTreatmentConsent = 'Treatment Consent';
    public static treatmentColumnRisksExplained = 'Risks Explained';
    public static treatmentColumnTreatmentMethod = 'Treatment Method';
    public static treatmentColumnTreatmentPlan = 'Treatment Plan';
    public static treatmentColumnComments = 'Notes';
    public static treatmentColumnPatientId = 'patient Id';
    public static treatmentColumnExpertId = 'expert Id';
    public static treatmentColumnAppointmentId = 'appointment Id';
    public static treatmentColumnUserId = 'user Id';
    public static entityNameUsers = 'users';
    public static usersColumnUserName = 'user Name';
    public static entityNameWadDetail = 'WAD Detail';
    public static wadDetailColumnWadDetailTitle = 'WAD Detail Title';
    public static wadDetailColumnWadDetail = 'WAD Detail';
    public static wadDetailColumnWadDetailImage = 'WAD Detail Image';
    public static wadDetailColumnPatientId = 'patient Id';
    public static wadDetailColumnOutcomeMeasureId = 'outcome Measure Id';
    public static wadDetailColumnExpertId = 'expert Id';
    public static wadDetailColumnAppointmentId = 'appointment Id';
    public static wadDetailColumnUserId = 'user Id';
    public static entityNamePaymentsDetail = 'Payments Detail';
    public static paymentsDetailColumnPaymentsId = 'payments Id';
    public static paymentsDetailColumnItemId = 'Item';
    public static paymentsDetailColumnQuantity = 'Quantity';
    public static paymentsDetailColumnUnitPrice = 'Unit Price';
    public static paymentsDetailColumnDiscount = 'Discount';
    public static paymentsDetailColumnTaxAmount = 'Tax Amount';
    public static paymentsDetailColumnTotalAmount = 'Payment Amount';
    public static paymentsDetailColumnDescription = 'Description';
    public static paymentsDetailColumnUserId = 'user Id';
    public static entityNameExpensesDetail = 'Expenses Detail';
    public static expensesDetailColumnExpensesId = 'expenses Id';
    public static expensesDetailColumnItemId = 'Items';
    public static expensesDetailColumnQuantity = 'Quantity';
    public static expensesDetailColumnUnitPrice = 'Unit Price';
    public static expensesDetailColumnDiscount = 'Discount';
    public static expensesDetailColumnTaxAmount = 'Tax Amount';
    public static expensesDetailColumnTotalAmount = 'Total Amount';
    public static expensesDetailColumnDescription = 'Description';
    public static expensesDetailColumnUserId = 'user Id';
    public static requiredFieldMessage = 'This field is required';
    public static addCommunicationHeader = 'Add Communication';
    public static addedSuccessfully = ' Added Successfully';
    public static updatedSuccessfully = ' Updated Successfully';
    public static deletedSuccessfully = ' Deleted Successfully';

    public static entityNameCpaRole = 'CPA Roles';
    public static cpaRoleTitle = 'Role Title';
    public static cpaRoleDescription = 'Role Description';

    public static entityNameCpaApplicationRole = 'CPA Application Roles';

    public static entityNameUserLevels = 'User Levels';
    public static userLevelTitle = 'User Level Title';
    public static userLevelDescription = 'User Level Description';







}
