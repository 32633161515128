<!-- <app-app-header></app-app-header> -->
<section class="sectionmain" id="sectionmain-admin">
    <div class="container-fluid">
        <div class="p-fluid p-formgrid p-grid admin-grid" *ngIf="!showMainDashboard">

          <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
            <div class="adminCard adminCardBlue" [routerLink]="['/appointments']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content noNumberCard">
                <img src="assets/theme1/custom-files/img/icons/invoicing.svg">
                <div class="dashboard-card-text">
                  <p>Appointments</p>
                  <h3 class="dashboardText"></h3>
                </div>
              </div>
            </div>
          </div>

          <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
            <div class="adminCard adminCardBlue"[routerLink]="['/cpa-roles']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content noNumberCard">
                <img src="assets/theme1/custom-files/img/icons/invoicing.svg">
                <div class="dashboard-card-text">
                  <p>Cpa Roles</p>
                  <h3 class="dashboardText"></h3>
                </div>
              </div>
            </div>
          </div>

          <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
            <div class="adminCard adminCardBlue" [routerLink]="['/user-levels']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content noNumberCard">
                <img src="assets/theme1/custom-files/img/icons/invoicing.svg">
                <div class="dashboard-card-text">
                  <p>User Levels</p>
                  <h3 class="dashboardText"></h3>
                </div>
              </div>
            </div>
          </div>



          <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">

                <div class="adminCard adminCardBlue" (click)="selectDashboard('cases')">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/Patient-case%20(2).svg">
                        <div class="dashboard-card-text">
                            <p>Cases</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                      <div class="adminCard adminCardBlue" (click)="selectDashboard('invoicing')">
                        <ng-template pTemplate="header">
                        </ng-template>
                        <div class="dashboard-card-content noNumberCard">
                          <img src="assets/theme1/custom-files/img/icons/invoicing.svg">
                          <div class="dashboard-card-text">
                            <p>Invoicing</p>
                            <h3 class="dashboardText"></h3>
                          </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                <div class="adminCard adminCardBlue" (click)="selectDashboard('expenses')">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/Expenses.svg">
                        <div class="dashboard-card-text">
                            <p>Expenses</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                <div class="adminCard adminCardBlue" (click)="selectDashboard('contacts')">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/contacts.svg">
                        <div class="dashboard-card-text">
                            <p>Contacts</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2">
                <div class="adminCard adminCardBlue" (click)="selectDashboard('clinics')">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content noNumberCard">
                        <img src="assets/theme1/custom-files/img/icons/clinics.svg">
                        <div class="dashboard-card-text">
                            <p>Clinics</p>
                            <h3 class="dashboardText"></h3>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>

<!-- Cases Dashboard -->
<div class="container-fluid" *ngIf="showCases">
    <div class="p-fluid p-formgrid p-grid admin-grid">

        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/patients']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/Patient.svg">
                    <div class="dashboard-card-text">
                        <p>Add Patient</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/patients']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content">
                    <img src="assets/theme1/custom-files/img/icons/patient%20(1).svg">
                    <div class="dashboard-card-text">
                        <p>Total Patients</p>
                        <h3 class="dashboardText">{{totalPatients}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/active_patient']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content">
                    <img src="assets/theme1/custom-files/img/icons/live-patients.svg">
                    <div class="dashboard-card-text">
                        <p>Live Patients</p>
                        <h3 class="dashboardText">{{activePatients}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/deactivated_patient']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content">
                    <img src="assets/theme1/custom-files/img/icons/deactivated-patients.svg">
                    <div class="dashboard-card-text">
                        <p>Deactivated Patients</p>
                        <h3 class="dashboardText">{{deActivatedPatients}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboardBackBtn">
            <p-button class="pngBlueBtn" label="Back" styleClass="p-button"
                      (click)="showMainDashboard = false; showCases = false"></p-button>
        </div>
    </div>

</div>

<!-- Invoicing Dashboard -->
<div class="container-fluid" *ngIf="showInvoicing">
    <div class="p-fluid p-formgrid p-grid admin-grid">
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/payments']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/add-payment.svg">
                    <div class="dashboard-card-text">
                        <p>Add Payment</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--            <div class="adminCard adminCardBlue">-->
<!--                <ng-template pTemplate="header">-->
<!--                </ng-template>-->
<!--                <div class="dashboard-card-content noNumberCard">-->
<!--                    <img src="assets/images/superAdminDashboard/add-expense.png">-->
<!--                    <div class="dashboard-card-text">-->
<!--                        <p>Add Expense</p>-->
<!--                        <h3 class="dashboardText"></h3>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--            <div class="adminCard adminCardBlue">-->
<!--                <ng-template pTemplate="header">-->
<!--                </ng-template>-->
<!--                <div class="dashboard-card-content">-->
<!--                    <img src="assets/images/superAdminDashboard/expenses.png">-->
<!--                    <div class="dashboard-card-text">-->
<!--                        <p>Total Expenses</p>-->
<!--                        <p class="dashboardText">150</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/paid-invoice']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content">
                    <img src="assets/theme1/custom-files/img/icons/paid-invoices.svg">
                    <div class="dashboard-card-text">
                        <p>Paid Invoices</p>
                        <h3 class="dashboardText">{{paidInvoicesCount}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/pending-invoice']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content">
                    <img src="assets/theme1/custom-files/img/icons/pending-invoices.svg">
                    <div class="dashboard-card-text">
                        <p>Pending Invoices</p>
                        <h3 class="dashboardText">{{pendingInvoicesCount}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboardBackBtn">
            <p-button class="pngBlueBtn" label="Back" styleClass="p-button"
                      (click)="showMainDashboard = false; showInvoicing = false"></p-button>
        </div>
    </div>

</div>

<!-- Expenses Dashboard -->
<div class="container-fluid" *ngIf="showExp">
    <div class="p-fluid p-formgrid p-grid admin-grid">
<!--        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--            <div class="adminCard adminCardBlue">-->
<!--                <ng-template pTemplate="header">-->
<!--                </ng-template>-->
<!--                <div class="dashboard-card-content noNumberCard">-->
<!--                    <img src="assets/images/superAdminDashboard/add-payment.png">-->
<!--                    <div class="dashboard-card-text">-->
<!--                        <p>Add Payment</p>-->
<!--                        <h3 class="dashboardText"></h3>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/expenses']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/add-expense.svg">
                    <div class="dashboard-card-text">
                        <p>Add Expense</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/expenses']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content">
                    <img src="assets/theme1/custom-files/img/icons/Expenses.svg">
                    <div class="dashboard-card-text">
                        <p>Total Expenses</p>
                        <h3 class="dashboardText">{{totalExpenses}}</h3>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--            <div class="adminCard adminCardBlue">-->
<!--                <ng-template pTemplate="header">-->
<!--                </ng-template>-->
<!--                <div class="dashboard-card-content">-->
<!--                    <img src="assets/images/superAdminDashboard/paid-invoices.png">-->
<!--                    <div class="dashboard-card-text">-->
<!--                        <p>Paid Invoices</p>-->
<!--                        <p class="dashboardText">100</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--            <div class="adminCard adminCardBlue">-->
<!--                <ng-template pTemplate="header">-->
<!--                </ng-template>-->
<!--                <div class="dashboard-card-content">-->
<!--                    <img src="assets/images/superAdminDashboard/pending-invoices.png">-->
<!--                    <div class="dashboard-card-text">-->
<!--                        <p>Pending Invoices</p>-->
<!--                        <p class="dashboardText">20</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<div class="dashboardBackBtn">
    <p-button class="pngBlueBtn" label="Back" styleClass="p-button"
              (click)="showMainDashboard = false; showExp = false"></p-button>
</div>
    </div>

</div>

<!-- Contacts Dashboard -->
<div class="container-fluid" *ngIf="showContacts">
    <div class="p-fluid p-formgrid p-grid admin-grid">
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/add-contact']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/add-contact.svg">
                    <div class="dashboard-card-text">
                        <p>Add Contact</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/view-contacts']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/view-contact.svg">
                    <div class="dashboard-card-text">
                        <p>View Contacts</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/view-companies']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/view-companies.svg">
                    <div class="dashboard-card-text">
                        <p>View Companies</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/view-experts']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/view-expert.svg">
                    <div class="dashboard-card-text">
                        <p>View Experts</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboardBackBtn">
            <p-button class="pngBlueBtn" label="Back" styleClass="p-button"
                      (click)="showMainDashboard = false; showContacts = false"></p-button>
        </div>
    </div>

</div>

<!-- Clinics Dashboard -->
<div class="container-fluid" *ngIf="showClinics">
    <div class="p-fluid p-formgrid p-grid admin-grid">
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/clinics']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/add-clinics.svg">
                    <div class="dashboard-card-text">
                        <p>Add Clinic</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/active-clinics']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/active-clinics.svg">
                    <div class="dashboard-card-text">
                        <p>Active Clinics</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/clinic_experts']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/active-clinics.svg">
                    <div class="dashboard-card-text">
                        <p>Clinic Expert</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="['/clinic_experts_schedule']">
            <div class="adminCard adminCardBlue">
                <ng-template pTemplate="header">
                </ng-template>
                <div class="dashboard-card-content noNumberCard">
                    <img src="assets/theme1/custom-files/img/icons/active-clinics.svg">
                    <div class="dashboard-card-text">
                        <p>clinic Schedule</p>
                        <h3 class="dashboardText"></h3>
                    </div>
                </div>
            </div>
        </div>

<!--Start-Commented for UAT-->

<!--        <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" [routerLink]="">-->
<!--            <div class="adminCard adminCardBlue">-->
<!--                <ng-template pTemplate="header">-->
<!--                </ng-template>-->
<!--                <div class="dashboard-card-content noNumberCard">-->
<!--                    <img src="assets/theme1/custom-files/img/icons/clinic-archive.svg">-->
<!--                    <div class="dashboard-card-text">-->
<!--                        <p>Clinic Archive</p>-->
<!--                        <h3 class="dashboardText"></h3>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--End-Commented for UAT-->
<div class="dashboardBackBtn">
    <p-button class="pngBlueBtn" label="Back" styleClass="p-button"
              (click)="showMainDashboard = false; showClinics = false"></p-button>
</div>
    </div>

</div>
