import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import '../../vendor/jitsi/external_api';
declare var JitsiMeetExternalAPI: any;
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-video-conferencing',
  templateUrl: './video-conferencing.component.html',
  styleUrls: ['./video-conferencing.component.css']
})
export class VideoConferencingComponent  {

  domain: string = '8x8.vc'; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;


  constructor(        private router: Router
  ) { }

  @HostListener('window:load')
  onLoad() {
    console.log('is window:load');
    const api = new JitsiMeetExternalAPI("meet.clinicalproassist.com", {
      roomName: "vpaas-magic-cookie-cb4ed61746dc4e0387f69bc5807ecddb/SampleAppParticularDivisionsCryObnoxiously",
      parentNode: document.querySelector('#meet')
    })
  }

  ngOnInit(): void {

    this.room = 'bwb-bfqi-vmh'; // Set your room name
    this.user = {
      name: 'Waseem Aslam' // Set your username
    };
  }

  ngAfterViewInit(): void {


    ////const api  = new JitsiMeetExternalAPI(this.domain, this.options);

  }

}
