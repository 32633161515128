import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AgencyContactsService} from '../web-services/agency-contacts.service';
import {AgencyService} from '../web-services/agency.service';
import {ContactsService} from '../web-services/contacts.service';
import {UsersService} from '../web-services/users.service';

declare var closeAddOverlaydiv: Function;
declare var closeSearchOverlaydiv: Function;
declare var openAddOverlaydiv: Function;

@Component(
    {selector: 'app-agency-contacts', templateUrl: './agency-contacts.component.html', styleUrls: ['./agency-contacts.component.css']})
export class AgencyContactsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAgencyContactsId = null;
    agencyList: any = [];
    contactsList: any = [];
    usersList: any = [];
    agencyContactsList: any = [];
    agencyContactsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    agencyContactsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    constructor(
        private agencyContactsServices: AgencyContactsService, private agencyServices: AgencyService,
        private contactsServices: ContactsService,
        private usersServices: UsersService, private primengConfig: PrimeNGConfig, private confirmation: ConfirmationService,
        private messageService: MessageService, private route: Router, private loader: NgxUiLoaderService,
        private constantService: ConstantService, private sharedService: SharedService
    ) {
    };

    get addUpdateTitle() {
        return this.updateAgencyContactsId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.agencyContactsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAgencyContacts();
        this.findAllAgencyContacts();
        closeSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countAgencyContacts() {
        this.agencyContactsServices.countAgencyContacts(this.agencyContactsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAgencyContacts();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.addUpdateAgencyContacts(this.agencyContactsForm.value);
        this.submitted = false;
        this.onClearAdd();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAgencyContacts();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.agencyContactsSearch.reset();
        this.countAgencyContacts();
        // this.findAllAgencyContacts();
    }

    addUpdateAgencyContacts(agencyContacts) {
        this.startLoader();
        if (this.updateAgencyContactsId == null) {
            this.agencyContactsServices.addAgencyContacts(this.agencyContactsForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    agencyContacts = res['data'][0];
                    this.agencyContactsList.push(agencyContacts);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnAdd),
                                                detail: this.translate(this.labels.entityNameAgencyContacts)
                                            });
                    this.submitted = true;
                    this. showHideAddDialog();
                    this.countAgencyContacts();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            agencyContacts.agencyContactsId = this.updateAgencyContactsId;
            this.agencyContactsServices.updateAgencyContacts(agencyContacts).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.agencyContactsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.btnUpdate),
                                                detail: this.translate(this.labels.entityNameAgencyContacts)
                                            });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countAgencyContacts();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAgencyContactsId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.findAllAgency();
        this.findAllContacts();
        this.findAllUsers();
        this.agencyContactsForm = new FormGroup({agencyId: new FormControl(), contacts: new FormControl(), userId: new FormControl(),});
        this.agencyContactsSearch = new FormGroup({agencyId: new FormControl(), contacts: new FormControl(), userId: new FormControl(),});
        this.countAgencyContacts();
        this.findAllAgencyContacts();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.agencyContactsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllAgencyContacts() {
        this.startLoader();
        this.agencyContactsServices.findAllAgencyContacts(this.agencyContactsSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.agencyContactsList = res['data'];
                } else {
                    this.agencyContactsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdAgencyContacts(agencyContactsId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.agencyContactsServices.deleteByIdAgencyContacts(agencyContactsId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.agencyContactsList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.btnDelete),
                                                    detail: this.translate(this.labels.entityNameAgencyContacts)
                                                });
                        this.countAgencyContacts();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadAgencyContactsToEdit(agencyContactsId: number, index) {
        let agencyContacts = this.agencyContactsList.find(x => x['agencyContactsId'] == agencyContactsId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAgencyContactsId = agencyContacts['agencyContactsId'];
        this.selectedIndex = index;
        this.agencyContactsForm.controls['agencyId'].setValue(agencyContacts['agencyId']['agencyId']);
        this.agencyContactsForm.controls['contacts'].setValue(agencyContacts['contacts']['contacts']);
        this.agencyContactsForm.controls['userId'].setValue(agencyContacts['userId']['userId']);
        openAddOverlaydiv();
    }

    onClearAdd() {
        this.agencyContactsForm.reset();
        this.agencyContactsForm.markAsUntouched();
        this.updateAgencyContactsId = null;
        closeAddOverlaydiv();
    }

    findAllAgency() {
        let temp = [];
        this.startLoader();
        this.agencyServices.findAllAgency(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['agencyId'];
                        dropDown.label = object['agencyTitle'];
                        this.agencyList.push(dropDown);
                    }
                } else {
                    this.agencyList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllContacts() {
        let temp = [];
        this.startLoader();
        this.contactsServices.findAllContacts(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['contactsId'];
                        dropDown.label = object['contactsFirstname'];
                        this.contactsList.push(dropDown);
                    }
                } else {
                    this.contactsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.agencyContactsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.agencyContactsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.agencyContactsForm.get(control)?.valid && this.agencyContactsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        closeAddOverlaydiv();
        this.agencyContactsForm.reset();
    }

    onCloseSearch() {
        closeSearchOverlaydiv();
        this.agencyContactsSearch.reset();
    }

    refresh() {
        this.countAgencyContacts();
    }

    onAddOverlayDiv() {
        openAddOverlaydiv();
        this.agencyContactsForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.agencyContactsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAgencyContactsId = null;
    }
}
