import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';
import { Constant } from 'src/app/util/constant';
import {User} from "../../dto/User";

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.css']
})
export class MainDashboardComponent implements OnInit {
    roleId: any;
    fullAuthenticatedUser: User;
  constructor( private sessionService: SessionService,
               private router: Router
              )
  { }

  ngOnInit(): void {
    // this.roleId = this.sessionService.getSession('userActivatedRole');
    this.fullAuthenticatedUser =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser')) as User;
 console.log(this.fullAuthenticatedUser);
    if(this.fullAuthenticatedUser.isSuperAdmin){
        // In case it is super-admin
        this.router.navigate(['/super-admin-dashboard']);
    } else if(this.fullAuthenticatedUser.isAdmin){
        // In case it is admin
        this.router.navigate(['/admin-dashboard']);
    }else if(this.fullAuthenticatedUser.isExpert){
        // In case it is expert
        this.router.navigate(['/expert-dashboard']);
    }else if(this.fullAuthenticatedUser.isCorporate){
        // In case it is corporate
        this.router.navigate(['/corporate-dashboard']);
    }else if(this.fullAuthenticatedUser.isIndividual){
        // In case it is individual
        this.router.navigate(['/individual-dashboard']);
    }

  }

}
