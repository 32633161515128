import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PastMedicalHistoryService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdPastMedicalHistory(pastMedicalHistoryId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PastMedicalHistory/deletePastMedicalHistory', {'pastMedicalHistoryId': pastMedicalHistoryId},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countPastMedicalHistory(pastMedicalHistory): Observable<number> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<number>(Constant.BASE_URL + 'PastMedicalHistory/count', {'pastMedicalHistory': pastMedicalHistory},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updatePastMedicalHistory(pastMedicalHistory, refData): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PastMedicalHistory/updatePastMedicalHistory', [pastMedicalHistory, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPastMedicalHistory(pastMedicalHistory, refData): Observable<string> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PastMedicalHistory/addPastMedicalHistory', [pastMedicalHistory, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllPastMedicalHistory(pastMedicalHistory, pageNo: number, itemsPerPage: number): Observable<string[]> {
        // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PastMedicalHistory/findAllPastMedicalHistory',
            {'pastMedicalHistory': pastMedicalHistory, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  findAllPastMedicalHistoryCpa(patientId: number): Observable<any> {

    return this.httpClient.get<any>(
      Constant.CPA_SPRING_API_URL + 'patients/' + patientId + '/past-medical-histories',
      {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json'),
      observe: 'response'
    }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }

  findAllPastMedicalHistoryByAppointmentId(appointmentId: number): Observable<any> {

    return this.httpClient.get<any>(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/past-medical-histories',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    ).pipe(
      retry(3), catchError(Constant.handleError));
  }

    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'PastMedicalHistory/countGlobalPMH',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PastMedicalHistory/findGlobalPMH',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

  addPastMedicalHistoryCpa(pastMedicalHistory): Observable<any> {
    // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
    return this.httpClient.post<string>(
      Constant.CPA_SPRING_API_URL + 'past-medical-histories', pastMedicalHistory, {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }


}
