
<!--<app-app-header></app-app-header>-->

<section class="sectionmain" id="sectionmain">
    <div class="container">
        <p-card header="" styleClass="p-card-shadow">
            <p-tabView>

                <p-tabPanel header="General Contacts" headerStyleClass="h3-style">
                  <app-contacts></app-contacts>
                </p-tabPanel>

                <p-tabPanel header="Company" headerStyleClass="h3-style ">
                  <app-company></app-company>
                </p-tabPanel>

                <p-tabPanel header="Experts" headerStyleClass="h3-style">
                  <app-expert></app-expert>
                </p-tabPanel>

              </p-tabView>
        </p-card>

    </div>
</section>
