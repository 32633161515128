import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent implements OnInit {

  @Input() clickedEvent: any;
  @Output() clinicExpertScheduleIdToDelete: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes) {

    this.clickedEvent = changes.clickedEvent;

  ///  alert('something changed');
  }

  deleteSchedule() {

   /// alert(this.clickedEvent.currentValue.extendedProps.clinicExpertScheduleId);

    this.clinicExpertScheduleIdToDelete.emit(this.clickedEvent.currentValue.extendedProps.clinicExpertScheduleId);


  }



}
