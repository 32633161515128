import {Component, OnInit} from '@angular/core';

@Component({
               selector: 'app-checklist',
               templateUrl: './checklist.component.html',
               styleUrls: ['./checklist.component.css']
           })
export class ChecklistComponent implements OnInit {


    tableData;
    data;
    knobValue: number = 65;
    displayCheckListStats: boolean = false;
    checkListViewDetail: boolean;
    tableDataToggle;
    tablePaginator: boolean = false;
    tableEmpty: boolean = false;

    checkListViewDetailpDialog() {
        this.checkListViewDetail = true;
    }

    checkListInfo = [
        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/companyPackSentGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/companyPackSentGreen.svg',
            text: 'Company Pack Sent',
            colorstatus: 'edit'


        }
        ,
        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/directDebitGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/directDebitGreen.svg',
            text: 'Direct Debit Form Emailed',
            colorstatus: 'edit'

        }
        ,
        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/bespokeGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/bespokeGreen.svg',
            text: 'Bespoke Requirement',
            colorstatus: 'edit'

        }
        ,
        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/telephoneConsultGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/telephoneConsultGreen.svg',
            text: 'Telephone Consultation Completed',
            colorstatus: 'edit'

        }
        ,
        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/tutorialComGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/tutorialComGreen.svg',
            text: 'Tutorial Completed',
            colorstatus: 'edit'

        },

        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/termsAndCondGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/termsAndCondGreen.svg',
            text: 'Terms And Conditions Emailed',
            colorstatus: 'edit'

        },

        {
            status: 'inactive',
            iconGrey: 'assets/images/svgIcons/checklistIcons/FAQGrey.svg',
            iconGreen: 'assets/images/svgIcons/checklistIcons/FAQGreen.svg',
            text: 'FAQ Documents Emailed',
            colorstatus: 'edit'
        }
    ];

    constructor() {
    }

    showCheckListStats(index) {
        this.tableData[index].displayCheckListStats = !this.tableData[index].displayCheckListStats;
        this.tableDataToggle = this.tableData[index].displayCheckListStats;
    };

    stepsDone(index) {
        if (this.checkListInfo[index].status !== 'active') {
            this.checkListInfo[index].status = 'active';
        } else {
            this.checkListInfo[index].status = 'inactive';
        }
        if (this.checkListInfo[index].colorstatus !== 'active') {
            this.checkListInfo[index].colorstatus = 'active';
        } else {
            this.checkListInfo[index].colorstatus = 'edit';
        }
    }

    ngOnInit(): void {


        this.tableData = [
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'Active',
                'percentage': '60',
                'action': '',
                'displayCheckListStats': false
            },
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'InComplete',
                'percentage': '20',
                'action': '',
                'displayCheckListStats': false
            },
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'InComplete',
                'percentage': '45',
                'action': '',
                'displayCheckListStats': false
            },
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'Active',
                'percentage': '10',
                'action': '',
                'displayCheckListStats': false
            },
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'InComplete',
                'percentage': '90',
                'action': '',
                'displayCheckListStats': false
            },
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'InComplete',
                'percentage': '90',
                'action': '',
                'displayCheckListStats': false
            },
            {
                'clinicName': 'Clinic 1',
                'specialistType': 'Specialist Type 1',
                'joiningDate': '01-27-2021',
                'status': 'InComplete',
                'percentage': '90',
                'action': '',
                'displayCheckListStats': false
            }
        ];
        if (this.tableData.length === 0) {
            console.log(this.tableData.len);
            this.tablePaginator = false;
            this.tableEmpty = true;
        }
    }
}
