import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MenubarModule} from 'primeng/menubar';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {PanelModule} from 'primeng/panel';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorComponent} from './errors/error.component';
import {JwtInterceptor} from './jwt.interceptor';
import {AgencyComponent} from './agency/agency.component';
import {AgencyClinicComponent} from './agency-clinic/agency-clinic.component';
import {AgencyCompanyComponent} from './agency-company/agency-company.component';
import {AgencyContactsComponent} from './agency-contacts/agency-contacts.component';
import {AgencyExpertComponent} from './agency-expert/agency-expert.component';
import {AgencyPatientsComponent} from './agency-patients/agency-patients.component';
import {AgencySubscriptionChecklistComponent} from './agency-subscription-checklist/agency-subscription-checklist.component';
import {AgencyTypeComponent} from './agency-type/agency-type.component';
import {AppointmentTypeComponent} from './appointment-type/appointment-type.component';
import {AppointmentsComponent} from './appointments/appointments.component';
import {BodyChartDetailComponent} from './body-chart-detail/body-chart-detail.component';
import {BodyChartMasterComponent} from './body-chart-master/body-chart-master.component';
import {CategoriesComponent} from './categories/categories.component';
import {ClientDetailsComponent} from './client-details/client-details.component';
import {ClinicExpertsComponent} from './clinic-experts/clinic-experts.component';
import {ClinicExpertsSchedualComponent} from './clinic-experts-schedual/clinic-experts-schedual.component';
import {ClinicTypeComponent} from './clinic-type/clinic-type.component';
import {ClinicsComponent} from './clinics/clinics.component';
import {CompanyComponent} from './company/company.component';
import {CompanyUsersComponent} from './company-users/company-users.component';
import {CompnayPatientsComponent} from './compnay-patients/compnay-patients.component';
import {ContactsComponent} from './contacts/contacts.component';
import {DischargeComponent} from './discharge/discharge.component';
import {DrugHistoryComponent} from './drug-history/drug-history.component';
import {ExerciseCategoryComponent} from './exercise-category/exercise-category.component';
import {ExercisesComponent} from './exercises/exercises.component';
import {ExpensesComponent} from './expenses/expenses.component';
import {ExpertComponent} from './expert/expert.component';
import {FlagsComponent} from './flags/flags.component';
import {GoalsComponent} from './goals/goals.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {InvoiceItemsDetailComponent} from './invoice-items-detail/invoice-items-detail.component';
import {ListOfValuesComponent} from './list-of-values/list-of-values.component';
import {ListOfValuesDetailComponent} from './list-of-values-detail/list-of-values-detail.component';
import {ListOfValuesMasterComponent} from './list-of-values-master/list-of-values-master.component';
import {ObservationsComponent} from './observations/observations.component';
import {OutcomeMeasureComponent} from './outcome-measure/outcome-measure.component';
import {OutcomeMeasureImagesComponent} from './outcome-measure-images/outcome-measure-images.component';
import {PalpationComponent} from './palpation/palpation.component';
import {PastMedicalHistoryComponent} from './past-medical-history/past-medical-history.component';
import {PatientExercisesComponent} from './patient-exercises/patient-exercises.component';
import {PatientsComponent} from './patients/patients.component';
import {PaymentsComponent} from './payments/payments.component';
import {PostureComponent} from './posture/posture.component';
import {PresentConditionHistoryComponent} from './present-condition-history/present-condition-history.component';
import {SocialHistoryComponent} from './social-history/social-history.component';
import {TestingAromComponent} from './testing-arom/testing-arom.component';
import {TestingMuscleComponent} from './testing-muscle/testing-muscle.component';
import {TestingNeuroComponent} from './testing-neuro/testing-neuro.component';
import {TestingSpecialComponent} from './testing-special/testing-special.component';
import {TreatmentComponent} from './treatment/treatment.component';
import {UsersComponent} from './users/users.component';
import {WadDetailComponent} from './wad-detail/wad-detail.component';
import {HomeComponent} from './home/home.component';
import {LogintypesComponent} from './logintypes/logintypes.component';
import {LoginComponent} from './auth/login/login.component';
import {LoginForgotpasswordComponent} from './login-forgotpassword/login-forgotpassword.component';
import {LoginChangepasswordComponent} from './login-changepassword/login-changepassword.component';
import {PasswordModule} from 'primeng/password';
import {ListboxModule} from 'primeng/listbox';
import {CookieService} from '../../node_modules/ngx-cookie-service';
import {LogoutComponent} from './auth/logout/logout.component';
import {ConfigAssetLoaderServiceService} from './services/config-service/config-asset-loader-service.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ExpensesDetailComponent} from './expenses-detail/expenses-detail.component';
import {PaymentsDetailComponent} from './payments-detail/payments-detail.component';
import {InitializerComponent} from './initializer/initializer.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LandingpageComponent} from './landingpage/landingpage.component';
import {AnalyticsComponent} from './analytics/analytics.component';
import {ManagesubscriptionComponent} from './managesubscription/managesubscription.component';
import {ChecklistComponent} from './checklist/checklist.component';
import {ChartModule} from 'primeng/chart';
import {ToolbarModule} from 'primeng/toolbar';
import {KnobModule} from 'primeng/knob';
import {ViewdetailComponent} from './viewdetail/viewdetail.component';
import {PrintpreviewComponent} from './printpreview/printpreview.component';
import {MessageBoxComponent} from './collaboration/message-box/message-box.component';
import {ParticipantComponent} from './collaboration/participant/participant.component';
import {CollaborationComponent} from './collaboration/collaboration.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {UsersCollabComponent} from './collaboration/users/users.component';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {SsoActivesubscriptionsComponent} from './notification/sso-activesubscriptions/sso-activesubscriptions.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {SsoNotificationsComponent} from './notification/sso-notifications/sso-notifications.component';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MainComponent} from './main/main.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTreeModule} from '@angular/material/tree';
import {MainHeaderComponent} from './main-header/main-header.component';
import {TokenRefreshService} from './services/token-refresh/token-refresh.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ErrorInterceptor} from './services/auth/error.inteceptor';
import {ToastrModule} from 'ngx-toastr';
import { MainDashboardComponent } from './Dashboards/main-dashboard/main-dashboard.component';
import { SuperDashboardComponent } from './Dashboards/super-dashboard/super-dashboard.component';
import { AdminDashboardComponent } from './Dashboards/admin-dashboard/admin-dashboard.component';
import { CorporateDashboardComponent } from './Dashboards/corporate-dashboard/corporate-dashboard.component';
import { IndividualDashboardComponent } from './Dashboards/individual-dashboard/individual-dashboard.component';
import { ExpertDashboardComponent } from './Dashboards/expert-dashboard/expert-dashboard.component';
import {CommonModule} from '@angular/common';
import {AddContactComponent} from './add-contact/add-contact.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { TimeSlotsComponent } from './time-slots/time-slots.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { InitialAssessmentComponent } from './initial-assessment/initial-assessment.component';
import { HistoryComponent } from './history/history.component';
import { TestingComponent } from './testing/testing.component';
import { LovDetailsComponent } from './lov-details/lov-details.component';
import { ObservationsMainComponent } from './observations-main/observations-main.component';
import { ImageFileUploadComponent } from './image-file-upload/image-file-upload.component';
import { PainterroComponentComponent } from './image-file-upload/painterro-component/painterro-component.component';
import { CpaToolsComponent } from './cpa-tools/cpa-tools.component';
import { ReportsComponent } from './reports/reports.component';
import { CommunicationComponent } from './communication/communication.component';
import {DtTableComponent} from './communication/dt-table/dt-table.component';
import {AddCommunicationsComponent} from './communication/add-communications/add-communications.component';
import { InitialAssessmentReportComponent } from './initial-assessment-report/initial-assessment-report.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import {SubscriptionBalanceComponent} from './subscription-balance/subscription-balance.component';
import {DividerModule} from 'primeng/divider';
import { MenuComponent } from './menu/menu.component';
import {AvatarModule} from 'primeng/avatar';
import {TimelineModule} from 'primeng/timeline';
import {BadgeModule} from 'primeng/badge';
import {SliderModule} from 'primeng/slider';
import {UserProfileDialogComponent} from './user-profile-dialog/user-profile-dialog.component';
import {DeleteAlertDialog} from './main/delete-popup/dialog-delete-alert';
import { LogoutPopupComponent } from './loginagain-popup/logout-popup.component';
import { DrawerComponent } from './side-drawers/clinic-schedule/drawer/drawer.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { CheckoutSuccessComponent } from './stripe/checkout-success/checkout-success.component';
import { CpaRolesComponent } from './cpa-roles/cpa-roles.component';
import { CpaApplicationRolesComponent } from './cpa-application-roles/cpa-application-roles.component';
import { UserLevelsComponent } from './user-levels/user-levels.component';
import { MetricsComponent } from './metrics/metrics.component';
import { ZComponent } from './z/z.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { PatientDetailsInformationComponent } from './patient-details-information/patient-details-information.component';
import { VideoConferencingComponent } from './video-conferencing/video-conferencing.component';
import { InitialAssessmentReviewComponent } from './initial-assessment-review/initial-assessment-review.component';
import { InitialAssessmentMultiSelectInfoComponent } from './initial-assessment-multi-select-info/initial-assessment-multi-select-info.component';
import { InteractiveBodyChartComponent } from './interactive-body-chart/interactive-body-chart.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BodyChartAnatomyDetailsComponent } from './body-chart-anatomy-details/body-chart-anatomy-details.component';
import {SidebarModule} from 'primeng/sidebar';
import { ExpertLandingPageComponent } from './expert-landing-page/expert-landing-page.component';
import { AssociateDiagnosisLovComponent } from './associate-diagnosis-lov/associate-diagnosis-lov.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HeaderComponent } from './2022-project/partials/header/header.component';
import { RoundedInfoBoxComponent } from './2022-project/partials/rounded-info-box/rounded-info-box.component';
import { FollowUpComponent } from './follow-up/follow-up.component';
import { PatientInformationPillComponent } from './patient-information-pill/patient-information-pill.component';
import { CpaClientsComponent } from './cpa-clients/cpa-clients.component';
import { FollowUpBodyChartComponent} from './follow-up/body-chart/follow-up-body-chart.component';
import { FollowUpTestingComponent} from './follow-up/testing/follow-up-testing.component';
import { FollowUpReviewComponent } from './follow-up/review/follow-up-review.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import {environment} from '../environments/environment';
import { AdminLandingPageComponent } from './admin-landing-page/admin-landing-page.component';
import { CompleteUserSignUpComponent } from './complete-user-sign-up/complete-user-sign-up.component';
import { CompleteSignUpComponent } from './complete-sign-up/complete-sign-up.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  bootstrapPlugin,
  timeGridPlugin
]);

/*const firebaseConfig = [
  AngularFireMessagingModule,
  AngularFireModule.initializeApp(environment.firebaseConfig) // Your config
];*/

@NgModule({

  declarations: [
                  AppComponent,
                  AgencyComponent,
                  AgencyClinicComponent,
                  AgencyCompanyComponent,
                  AgencyContactsComponent,
                  AgencyExpertComponent,
                  AgencyPatientsComponent,
                  AgencySubscriptionChecklistComponent,
                  AgencyTypeComponent,
                  AppointmentTypeComponent,
                  AppointmentsComponent,
                  BodyChartDetailComponent,
                  BodyChartMasterComponent,
                  CategoriesComponent,
                  ClientDetailsComponent,
                  ClinicExpertsComponent,
                  ClinicExpertsSchedualComponent,
                  ClinicTypeComponent,
                  ClinicsComponent,
                  CompanyComponent,
                  CompanyUsersComponent,
                  CompnayPatientsComponent,
                  ContactsComponent,
                  DischargeComponent,
                  DrugHistoryComponent,
                  ExerciseCategoryComponent,
                  ExercisesComponent,
                  ExpensesComponent,
                  ExpertComponent,
                  FlagsComponent,
                  GoalsComponent,
                  InvoiceComponent,
                  InvoiceItemsDetailComponent,
                  ListOfValuesComponent,
                  ListOfValuesDetailComponent,
                  ListOfValuesMasterComponent,
                  ObservationsComponent,
                  OutcomeMeasureComponent,
                  OutcomeMeasureImagesComponent,
                  PalpationComponent,
                  PastMedicalHistoryComponent,
                  PatientExercisesComponent,
                  PatientsComponent,
                  PaymentsComponent,
                  PostureComponent,
                  PresentConditionHistoryComponent,
                  SocialHistoryComponent,
                  TestingAromComponent,
                  TestingMuscleComponent,
                  TestingNeuroComponent,
                  TestingSpecialComponent,
                  TreatmentComponent,
                  UsersComponent,
                  WadDetailComponent,
                  ErrorComponent,
                  HomeComponent,
                  AppComponent,
                  // AppHeaderComponent,
                  // AppSidebarComponent,
                  LoginComponent,
                  LogintypesComponent,
                  LoginForgotpasswordComponent,
                  LoginChangepasswordComponent,
                  LogoutComponent,
                  ExpensesDetailComponent,
                  PaymentsDetailComponent,
                  InitializerComponent,
                  DashboardComponent,
                  LandingpageComponent,
                  AnalyticsComponent,
                  ManagesubscriptionComponent,
                  ChecklistComponent,
                  ViewdetailComponent,
                  PrintpreviewComponent,
                  MessageBoxComponent,
                  ParticipantComponent,
                  CollaborationComponent,
                  UsersComponent,
                  UsersCollabComponent,
                  SsoActivesubscriptionsComponent,
                  SsoNotificationsComponent,
                  MainComponent,
                  MainHeaderComponent,
                  MainDashboardComponent,
                  SuperDashboardComponent,
                  AdminDashboardComponent,
                  CorporateDashboardComponent,
                  IndividualDashboardComponent,
                  ExpertDashboardComponent,
                  AddContactComponent,
                  TimeSlotsComponent,
                  ClientHistoryComponent,
                  InitialAssessmentComponent,
                  HistoryComponent,
                  TestingComponent,
                  LovDetailsComponent,
                  ObservationsMainComponent,
                  CpaToolsComponent,
                  ReportsComponent,
                  ImageFileUploadComponent,
                  PainterroComponentComponent,
                  CpaToolsComponent,
                  CommunicationComponent,
                  DtTableComponent,
                  AddCommunicationsComponent,
                  InitialAssessmentReportComponent,
                  PaymentFormComponent,
                  SubscriptionComponent,
                  SubscriptionBalanceComponent,
                  UserProfileDialogComponent,
                  DeleteAlertDialog,
                  LogoutPopupComponent,
                  MenuComponent,
                  DrawerComponent,
                  PatientDetailsComponent,
                  CheckoutSuccessComponent,
                //  UserManagementComponent,
                  CpaRolesComponent,
                CpaApplicationRolesComponent,
                UserLevelsComponent,
                MetricsComponent,
                ZComponent,
                DiagnosisComponent,
                PatientDetailsInformationComponent,
                VideoConferencingComponent,
                InitialAssessmentReviewComponent,
                InitialAssessmentMultiSelectInfoComponent,
                InteractiveBodyChartComponent,
                BodyChartAnatomyDetailsComponent,
                ExpertLandingPageComponent,
                AssociateDiagnosisLovComponent,
                HeaderComponent,
                RoundedInfoBoxComponent,
                FollowUpComponent,
                PatientInformationPillComponent,
                CpaClientsComponent,
                FollowUpBodyChartComponent,
                FollowUpTestingComponent,
                FollowUpReviewComponent,
                AdminLandingPageComponent,
                CompleteUserSignUpComponent,
                CompleteSignUpComponent,
                ResetPasswordComponent

              ],
              imports: [
                  AngularFireModule.initializeApp(environment.firebaseConfig),
                  AngularFireMessagingModule,
                  MatTooltipModule,
                  CommonModule,
                  BrowserModule,
                  AppRoutingModule,
                  ButtonModule,
                  SelectButtonModule,
                  SplitButtonModule,
                  ToastModule,
                  MessagesModule,
                  MessageModule,
                  RouterModule,
                  BrowserAnimationsModule,
                  AccordionModule,
                  CardModule,
                  TableModule,
                  ReactiveFormsModule,
                  ToggleButtonModule,
                  DataViewModule,
                  PanelModule,
                  InputTextModule,
                  ConfirmDialogModule,
                  CheckboxModule,
                  DialogModule,
                  RadioButtonModule,
                  InputTextareaModule,
                  DropdownModule,
                  FormsModule,
                  RatingModule,
                  RippleModule,
                  FileUploadModule,
                  HttpClientModule,
                  TabViewModule,
                  MenubarModule,
                  SlideMenuModule,
                  GalleriaModule,
                  CarouselModule,
                  InputNumberModule,
                  InputMaskModule,
                  CalendarModule,
                  StepsModule,
                  InputSwitchModule,
                  PasswordModule,
                  ListboxModule,
                  // MenuSSOModule,
                  MatMenuModule,
                  MatButtonModule,
                  MatIconModule,
                  ChartModule,
                  ToolbarModule,
                  KnobModule,
                  MatExpansionModule,
                  MatTabsModule,
                  MatSelectModule,
                  MatSlideToggleModule,
                  NgxUiLoaderModule,
                  MatButtonToggleModule,
                  MatTableModule,
                  MatInputModule,
                  MatPaginatorModule,
                  MenuModule,
                  TooltipModule,
                  MatDialogModule,
                  MatSidenavModule,
                  MatTreeModule,
                  ToastrModule.forRoot(),
                  MultiSelectModule,
                  DividerModule,
                  FullCalendarModule,
                  ChartModule,
                  AvatarModule,
                TimelineModule,
                BadgeModule,
                SliderModule,
                SweetAlert2Module.forRoot(),
                SidebarModule,
                NgCircleProgressModule.forRoot({
                  // set defaults here
                  radius: 100,
                  outerStrokeWidth: 16,
                  innerStrokeWidth: 8,
                  outerStrokeColor: "#78C000",
                  innerStrokeColor: "#C7E596",
                  animationDuration: 300,

                })

              ],
              providers: [ConfirmationService, MessageService, CookieService,


                  MainComponent,TokenRefreshService,
                  MatDatepickerModule,
                  CookieService,
                  DeviceDetectorService,

                  // {
                  //     provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
                  // },
                  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
                  {
                      provide: HTTP_INTERCEPTORS,
                      useClass: JwtInterceptor,
                      multi: true
                  },
              ],
              bootstrap: [AppComponent]
          })
export class AppModule {
}

export function loadConfigurations(configService: ConfigAssetLoaderServiceService) {
    console.log("");
    return () => configService.loadConfigurations().toPromise();
}
