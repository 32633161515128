<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <!-- <section class="sectionheading" id="sectionmain-invitationpage-sectionheading"> -->
        <!-- <h1 class="h1-style1">{{translate(labels.entityNameExerciseCategory)}}</h1> -->
        <!-- </section> -->
        <!-- <hr class="hrseprator"> -->
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameExerciseCategory)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <!-- <span class="p-input-icon-right" id="invitationpage-memberslist-globalsearch"> -->
                                <!-- <i class="pi pi-search"></i> -->
                                <!-- <input pInputText type="text" placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}' /> -->
                                <!-- </span> -->
                                <!-- <span *ngIf="clearSearchBtn"> -->
                                <!-- <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-text" (click)="clearSearchResult()"></button> -->
                                <!-- </span> -->
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameExerciseCategory)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
                        <!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameExerciseCategory)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="exerciseCategorySearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnExerciseCategoryTitle)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('exerciseCategoryTitle')"
                                                    *ngIf="diplayeIconInField('exerciseCategoryTitle')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    class="pngInputtext" formControlName="exerciseCategoryTitle"
                                                    name="exerciseCategoryTitle" pInputText
                                                    type="text"> </span></div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnPatientId)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="patientId" mode="decimal"
                                                           name="patientId" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnExpertId)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="expertId" mode="decimal"
                                                           name="expertId" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnUserId)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="userId" mode="decimal"
                                                           name="userId" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <!-- <div class="p-field p-col-12 pngbtnsdiv" id="invitationpage-searchmemeber-pngbtnsdiv"> -->
                                        <!-- <button pButton type="submit" id="invitationpage-searchmemeber-searchbtn" -->
                                        <!-- [disabled]="onValueChange" class="pngbtn btnprimaryblue" label={{translate(labels.btnSearch)}} ></button> -->
                                        <!-- <button pButton type="reset" id="invitationpage-searchmemeber-clearbtn" -->
                                        <!-- [disabled]="!this.exerciseCategorySearch.touched" class="pngbtn btnprimaryblue-outline" -->
                                        <!-- label={{translate(labels.btnClear)}}></button> -->
                                        <!-- <button pButton type="button" (click)="onCloseSearch()" -->
                                        <!-- id="invitationpage-searchmemeber-cancelbtn" -->
                                        <!-- class="pngbtn btnprimaryblue-outline" label={{translate(labels.btnCancel)}}></button> -->
                                        <!-- </div> --></div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameExerciseCategory)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="exerciseCategoryForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnExerciseCategoryTitle)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('exerciseCategoryTitle')"
                                                    *ngIf="diplayeIconInField('exerciseCategoryTitle')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('exerciseCategoryTitle')"
                                                    class="pngInputtext" formControlName="exerciseCategoryTitle"
                                                    name="exerciseCategoryTitle" pInputText
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'exerciseCategoryTitle'"
                                                              [form]="exerciseCategoryForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnPatientId)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('patientId')"
                                                           [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="patientId"
                                                           mode="decimal" name="patientId"
                                                           pNumberInput
                                                           type="number"></p-inputNumber>
                                            <app-custom-error [control]="'patientId'"
                                                              [form]="exerciseCategoryForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnExpertId)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('expertId')"
                                                           [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="expertId"
                                                           mode="decimal" name="expertId"
                                                           pNumberInput
                                                           type="number"></p-inputNumber>
                                            <app-custom-error [control]="'expertId'"
                                                              [form]="exerciseCategoryForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.exerciseCategoryColumnUserId)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('userId')"
                                                           [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="userId"
                                                           mode="decimal" name="userId"
                                                           pNumberInput
                                                           type="number"></p-inputNumber>
                                            <app-custom-error [control]="'userId'"
                                                              [form]="exerciseCategoryForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <!-- <div class="p-field p-col-12 pngbtnsdiv" id="invitationpage-addmemeber-pngbtnsdiv"> -->
                                    <!-- <button pButton type="submit" [disabled]="!this.exerciseCategoryForm.valid" -->
                                    <!-- [label]="addUpdateTitle" icon="pi pi-check" -->
                                    <!-- class="pngbtn btnprimaryblue"></button><button pButton type="button" -->
                                    <!-- (click)="onClearAdd()" -->
                                    <!-- [disabled]="!this.exerciseCategoryForm.touched || updateExerciseCategoryId == null" label={{translate(labels.btnClear)}} icon="pi pi-times" class="pngbtn btnprimaryblue-outline"></button> -->
                                    <!-- <button pButton type="button" (click)="onCloseAdd()" -->
                                    <!-- id="invitationpage-addmemeber-cancelbtn" class="pngbtn btnprimaryblue-outline" -->
                                    <!-- label={{translate(labels.btnCancel)}}></button> --><!-- </div> -->
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div><!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <!-- {{translate(labels.entityNameExerciseCategory)}} {{translate(labels.LIST)}} -->
                                    <!-- <div class="pngbtnsdiv" id="invitationpage-memberslist-pngbtnsdiv"> --><span
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch"><i
                                        class="pi pi-search"></i><input class="pngInputtext" pInputText
                                                                        placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                                        type="text"/></span>
                                    <!-- <button pButton type="button" icon="pi pi-search-plus" onclick="openSearchOverlaydiv()" class="p-button-rounded p-button-text"></button> -->
                                    <!-- <button pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-text" onclick="openAddOverlaydiv()"></button> -->
                                    <!-- <button pButton type="button" icon="pi pi-replay" class="p-button-rounded p-button-text" (click)="refresh()"></button> -->
                                    <!-- <span *ngIf="clearSearchBtn"> -->
                                    <!-- <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-text" (click)="clearSearchResult()"></button> -->
                                    <!-- </span> --><!-- </div> --></p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="exerciseCategoryList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': exerciseCategoryList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> ExerciseCategoryTitle</th>
                                            <th> PatientId</th>
                                            <th> ExpertId</th>
                                            <th> UserId</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.exerciseCategoryTitle}} </td>
                                            <td> {{element?.patientId}} </td>
                                            <td> {{element?.expertId}} </td>
                                            <td> {{element?.userId}} </td>
                                            <td><!-- <div class="rowbtnstable"> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text p-button-plain"></button> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-plain" (click)="loadExerciseCategoryToEdit(element.exerciseCategoryId, i)"></button> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-plain" (click)="deleteByIdExerciseCategory(element.exerciseCategoryId,i)"></button> -->
                                                <!-- </div> -->
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadExerciseCategoryToEdit(element.exerciseCategoryId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdExerciseCategory(element.exerciseCategoryId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template
                                            pTemplate="emptymessage">{{translate(labels.mat_pagination_no_data_msg)}}</ng-template>
                                </p-table>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
