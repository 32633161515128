import {Component, OnInit} from '@angular/core';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import {SessionService} from '../services/session.service';
import {Constant} from '../util/constant';
import {Router} from '@angular/router';
import {ChatService} from '../collaboration/services/chat/chat.service';
import { AppointmentsService } from '../web-services/appointments.service';
import { DatePipe } from '@angular/common';
import {SharedService} from '../web-services/shared.service';
import {ExpertPatientService} from "../web-services/expert-patient.service";
import {User} from "../dto/User";

@Component({
               selector: 'app-landingpage',
               templateUrl: './landingpage.component.html',
               styleUrls: ['./landingpage.component.css']
           })
export class LandingpageComponent implements OnInit {
    private timerSubscription: any;
    private unreadNotifications: any;
    private timeStamp = new Date();
    private notifications: any;

    displayviewbutton: boolean = false;

    loggedInUserType: any;
    loggedInRefId: any;
    currentDate = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
    fullAuthenticatedUser: User;

    displayAppointmentsDiv = true;

    appointmentDatesList: any = [];
    appointmentsList: any = [];
    patientsList: any = [];

    // appointmentGraph: any;
    // appointmentGraphOptions: any;
    // patientCallsGraph: any;
    // patientCallsGraphOptions: any;
    // therapyTypeGraph: any;

    appointmentGraph = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        datasets: [
            {
                label: 'Number',
                backgroundColor: '#009AFF',
                data: [0, 50, 100, 150, 200, 250]
            },
            {
                label: 'Average',
                backgroundColor: '#43D5CB',
                data: [0, 50, 100, 150, 200, 250]
            }
        ]
    };
    appointmentGraphOptions = {
        legend: {
            position: 'top',
            align: 'end'
        }
    };

    patientCallsGraph = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        datasets: [
            {
                label: 'Number',
                backgroundColor: '#009AFF',
                data: [0, 50, 100, 150, 200, 250]
            },
            {
                label: 'Average',
                backgroundColor: '#43D5CB',
                data: [0, 50, 100, 150, 200, 250]
            }
        ]
    };
    patientCallsGraphOptions = {
        legend: {
            position: 'top',
            align: 'end'
        }
    };
    therapyTypeGraph = {
        labels: ['Dammam','Jeddah','Khober','Riyadh'],
        datasets: [
            {
                data: [32, 54, 50,11,21],
                backgroundColor: [
                    "#045490",
                    "#006E91",
                    "#009091",
                    "#43D5CB",
                    "#009AFF"
                ],
                hoverBackgroundColor: [
                    "#009AFF",
                    "#006E91",
                    "#009091",
                    "#43D5CB",
                    "#009AFF"
                ]
            }
        ]
    };

    constructor(
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private sessionService: SessionService,
        private router: Router,
        private chatService: ChatService,
        private appointmentsServices: AppointmentsService,
        private expertPatientService: ExpertPatientService,

    ) {
    }

    confirm2() {
        this.confirmationService.confirm({
                                             message: 'Do you want to delete this record?',
                                             header: 'Delete Confirmation',
                                             icon: 'pi pi-info-circle',
                                             accept: () => {
                                                 this.messageService.add(
                                                     {severity: 'success', summary: 'success', detail: 'Record deleted'});
                                             },
                                             reject: (type) => {
                                                 switch (type) {
                                                     case ConfirmEventType.REJECT:
                                                         this.messageService.add(
                                                             {severity: 'error', summary: 'Rejected', detail: 'Rejected'});
                                                         break;
                                                     case ConfirmEventType.CANCEL:
                                                         this.messageService.add(
                                                             {severity: 'warn', summary: 'Cancelled', detail: 'Cancelled'});
                                                         break;
                                                 }
                                             }
                                         });

    }
    appointmentsToday: any;
    totalConfirmedAppointments: any;
    totalPendingAppointments: any;
    treatedPatients: any;
    getLandingPageCounts(dateString, expertId){
        this.appointmentsServices.landingPageAppointmentsCount(dateString, expertId).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    console.log('landing Page Counts : ', res['data'][0]);
                    this.appointmentsToday =  res['data'][0].appoinmentsToday;
                    this.totalConfirmedAppointments = res['data'][0].totalConfirmedAppointments;
                    this.totalPendingAppointments = res['data'][0].totalPendingAppointments;
                    this.treatedPatients = res['data'][0].treatedPatients;
                } else {
                    console.log("could not get appointments!");
                }
            });
    }

    ngOnInit(): void {
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.fullAuthenticatedUser =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser')) as User;

        if (!this.fullAuthenticatedUser.isExpert) {
            this.displayAppointmentsDiv = false;
        } else {
            this.displayAppointmentsDiv = true;
        }
        if (this.appointmentsList.length > 5) {
            this.displayviewbutton = false;
        } else {
            this.displayviewbutton = true;
        }
        if (!this.fullAuthenticatedUser.isExpert) {
            this.getLandingPageCounts(this.currentDate, this.loggedInRefId);
        }
        console.log("landing page");
    ///    this.getNotifications();

        this.timerSubscription = setInterval(() => {
            // console.log("notification this.sharedService.timeStamp :: ", this.sharedService.timeStamp);
      //      this.getNotificationsAgain();
            // console.log("notification this.sharedService.timeStamp :: ", this.sharedService.timeStamp);
        }, Constant.NOTIFICATION_TIME);
     ///   this.getNotifications();
      ///  this.checkUnreadNotifications();

        this.findAllOpenAppointmentsByExpertId(this.loggedInRefId);

        //this.getAppointmentsOfCurrentMonth(this.currentDate, this.loggedInRefId);
        this.getPatientsForExpert(this.loggedInRefId);

      //  this.getAppointmentsByDate(this.currentDate, this.loggedInRefId);
    }

  getPatientsForExpert(expertId) {
    this.expertPatientService.findExpertPatientByExpertId(expertId).subscribe(
      res => {
      //  alert(res['message']);
        if (res['message'] == 'Success') {
         // alert('here');
          console.log( res['data']);
          this.patientsList = res['data'];
        } else {
     //     alert('error');
          console.log("could not get patients!");
        }
      });
  }

  findAllOpenAppointmentsByExpertId(expertId: number): void {

      this.appointmentsServices.findAllOpenAppointmentsByExpertId(expertId).subscribe(

        res => {
          if (res.status === 200) {

            this.appointmentsList = res.body;

          }
        }
      );
  }


    getAppointmentsOfCurrentMonth(dateString, expertId) {
        this.appointmentsServices.getAppointmentsOfCurrentMonth(dateString, expertId, Constant.APPOINTMENT_STATUS_CONFIRMED).subscribe(
            res => {
                if (res['message'] == 'Success') {
                  console.log( res['data']);
                    this.appointmentsList = res['data'];
                } else {
                //  alert('error');
                    console.log("could not get appointments!");
                }
            });
    }

    getAppointmentsByDate(selectedDate) {



        this.appointmentsServices.getAppointmentsByDate(selectedDate, this.loggedInRefId, Constant.APPOINTMENT_STATUS_CONFIRMED).subscribe(
            res => {
                if (res['message'] == 'Success') {

                    console.log("appointments list :: ", res);
                    this.appointmentsList = res['data'];
                } else {
               //   alert('error');
                    this.appointmentsList = [];
                    console.log("appointments not found!");

                }
            });

    }

    getSelectedDate(event) {
        console.log("event selected :: ", new DatePipe('en-US').transform(event, 'yyyy-MM-dd'));
        let selectedDate = new DatePipe('en-US').transform(event, 'yyyy-MM-dd');
        this.getAppointmentsByDate(selectedDate);

    }


    onGlobalSearchClick(event) {
        console.log("event value :: ", event);
    }

    // table data
    patientList: any[] = [
        {
            'name': 'Anthony  Williams', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'John William', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'Winston John', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'Anthony William', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'Anthony William', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'Anthony William', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'Winston John', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'Anthony William', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
        {
            'name': 'John William', 'time': '10 AM - 11 AM', 'no': '2nd', 'lastVisit': '4 June 2021',
            'action': ''
        },
    ];

    getNotification(event) {
        // this.dataSharedService.setUmsApplicationFrontendUrl(this.menuComponent.getApplicationFrontEndUrl(Constant.CPA_APPLICATION_ID));
        this.router.navigateByUrl('allnotifications');
    }

    getCookieObject(event) {
    }


    getNotifications() {
        this.unreadNotifications = 0;
        if (sessionStorage.getItem('loggedInUser') != undefined) {
            this.chatService.getNotifications(sessionStorage.getItem('loggedInUser'), this.timeStamp).subscribe(data => {
                if (data) {
                    try {
                        this.unreadNotifications = data['unreadNotifications'];
                        if (this.unreadNotifications == null) {
                            this.unreadNotifications = 0;
                        }
                        this.chatService.setUnreadNotifications(this.unreadNotifications);
                    } catch (error) {
                    }
                }
            }, error => {
// this.chatService.checkHttpCodes(error);
            });
        }
    }


    notificationsInService = [];

// HeartBeat Service to Get New Notifications
    getNotificationsAgain() {
        if (this.sessionService.getSession("token") && sessionStorage.getItem('loggedInUser') != undefined) {
            this.chatService.getNotifications(sessionStorage.getItem('loggedInUser'), this.timeStamp).subscribe(data => {
                if (data) {
                    try {
// console.log("here in");

                        this.notificationsInService = data['data'];
                        this.timeStamp = data['timeStamp'];
                        if (this.notificationsInService) {


                            if (data['unreadNotifications'] != null) {
                                this.unreadNotifications = data['unreadNotifications'];

                                this.chatService.setUnreadNotifications(this.unreadNotifications);
                            }

                            for (var i = 0; i < this.notificationsInService.length; i++) {
                                this.notifications = this.notifications || [];
                                this.notifications.unshift(this.notificationsInService[i]);
// if (this.unreadNotifications == null) {
// this.unreadNotifications = 1;
// }
// else {
// this.unreadNotifications += 1
// }
// this.chatService.setUnreadNotifications(this.unreadNotifications);
                            }
                        }
                    } catch (error) {
// this.webService.exceptionHandling(error, "getNotificationsAgainMethodError").subscribe(data => {
// });
                    }
                }
            }, error => {
                if (error == "Error : Connection Error") {
// this.openConnectionErrorDialog();
                }
// this.chatService.checkHttpCodes(error);
                clearInterval(this.timerSubscription);
            });
        }
    }

    unreadNotification: any;

    checkUnreadNotifications() {
        this.chatService.getUnreadNotifications.subscribe(data => {
            this.unreadNotification = data;
        });
        // console.log(this.unreadNotification);

    }

    selectAppointment(event) {

     /*   if(event.parentAppointmentId){
            this.sessionService.setSession('appointmentId', event.parentAppointmentId.appointmentId);
        }else {
            this.sessionService.setSession('appointmentId', event.appointmentId);
            this.sessionService.setSession('caseId', event.caseId);
        }*/
        this.sessionService.setSession('appointment', event);
        this.sessionService.setSession('appointmentId', event.appointmentId);
        this.sessionService.setSession('caseId', event.caseId);
        this.sessionService.setSession('patientId', event.patientId);
        this.sessionService.setSession('expertId', event.expertId.expertId);

        if (event.isFollowUp) {
          this.router.navigate(['/follow-up']);
        }
            this.router.navigate(['/history']);
    }

    selectPatient(event) {

      this.sessionService.setSession('patientId', event.patientId.patientId);
      this.sessionService.setSession('expertId', event.expertId);
      this.router.navigate(['/patient-details']);
  }
}
