<!--<app-app-header></app-app-header>-->
<section class="sectionmain" id="sectionmain-barData">
    <!-- <div class="container-fluid">
       <section class="sectionheading" id="sectionmain-barData-sectionheading">
          <h1 class="h1-style">Welcome Back Super Admin</h1>
          <h3 class="h3-style"> 14:10 3rd of June 2020 </h3>
       </section>
    </div> -->
    <div class="container">
        <section class="barData-wrapper" id="barData-wrap">
            <div class="p-col-12 barDataGraph" id="barData-nCompanies">
                <div class="chartsHeading">
                    New Companies Buy Month
                </div>
                <div>
                    <p-chart type="bar" [data]="newCompanies" [responsive]="chartSmall"></p-chart>
                </div>
            </div>
            <div class="p-col-12 barDataGraph" id="barData-canclledSubs">
                <div class="chartsHeading">
                    Cancelled Subscription
                </div>
                <div>
                    <p-chart type="bar" [data]="cancelledSubscription" [responsive]="chartSmall"></p-chart>
                </div>
            </div>
            <div class="p-col-12 barDataGraph " id="barData-usage">
                <div class="chartsHeading">
                    Usage
                </div>
                <div>
                    <p-chart type="bar" [data]="usage" [responsive]="chartSmall"></p-chart>
                </div>
            </div>
            <div class="p-col-12 barDataGraph " id="barData-dPortals">
                <div class="chartsHeading">
                    Different Portals %
                </div>
                <div>
                    <p-chart type="bar" [data]="differentPortals" [responsive]="chartSmall"></p-chart>
                </div>
            </div>
        </section>
    </div>
</section>
