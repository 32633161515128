<ngx-ui-loader></ngx-ui-loader>


  <div class="card-cpa-rounded-20 background-white-m-30">
    <p-steps [model]="stepsItems" (activeIndexChange)="urlChanged($event)" [(activeIndex)]="activeStep"
             [readonly]="false">
    </p-steps>
  </div>

<div class="p-row initial-assessment-container">

  <div class="p-col-12">
    <div class="row">
      <div class="p-col-3 m-5">

        <app-patient-information-pill [patientObj]="patientObj"></app-patient-information-pill>
      </div>
    </div>
    <div class="row">
      <div class="p-col-8 m-5">

        <p-card styleClass="card-cpa-rounded card-shadow">
          <p-tabView class="history-tabs">

            <p-tabPanel header="History Present Condition" headerStyleClass="h3-style">
              <app-present-condition-history></app-present-condition-history>
            </p-tabPanel>

            <p-tabPanel header="Medical History" headerStyleClass="h3-style ">
              <app-past-medical-history></app-past-medical-history>
            </p-tabPanel>

            <p-tabPanel header="Drug History" headerStyleClass="h3-style">
              <app-drug-history></app-drug-history>
            </p-tabPanel>

            <p-tabPanel header="Social History" headerStyleClass="h3-style">
              <app-social-history></app-social-history>
            </p-tabPanel>

          </p-tabView>
        </p-card>
      </div>
    </div>
  </div>

</div>
