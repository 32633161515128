import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import {ValidationService} from '../services/validation.service';
import {Router} from '@angular/router';
import {ValidationService} from '../services/validation.service';
import {NonInterceptService} from '../services/non-intercept.service';
import {ToastService} from '../services/toast.service';
import {HttpStatusCode} from '@angular/common/http';

@Component({
               selector: 'app-login-forgotpassword',
               templateUrl: './login-forgotpassword.component.html',
               styleUrls: ['./login-forgotpassword.component.scss']
           })
export class LoginForgotpasswordComponent implements OnInit {

    emailVerificationForm: FormGroup;
    submitted = false;
    validationMessages = {

        'Email': {
            'required': 'Email is required.',
            'pattern': 'Please provide valid Email ID'
        },
        'Password': {
            'required': 'Password is required.',
            // 'pattern':'Please Enter a Strong Password'
        },
        // 'ConfirmPassword': {
        //   'required': 'Confirm Password is required.',
        //   'mismatch': 'Password and Confirm Password do not match'
        // }
    };
    formErrors = {Email: '', Password: ''};

    constructor(
        private fb: FormBuilder,
        private _validation: ValidationService,
        private router: Router,
        private nonInterceptService: NonInterceptService,
        private toastrService: ToastService
    ) {

    }

    ngOnInit() {
        this.emailVerificationForm = this.fb.group(
            {
                // Name: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.pattern(this._validation.regex.email)]],
                // Password: ['', [Validators.required]],
                // ConfirmPassword: ['', [Validators.required]],
            }
        );

        this.emailVerificationForm.valueChanges.subscribe(
            value => {
                this.logValidationErrors();
            }
        );
    }


    logValidationErrors() {
        this.formErrors = this._validation.getValidationErrors(this.emailVerificationForm, this.validationMessages);
    }

    emailVerfication() {
      //  if (this.emailVerificationForm.valid) {
            this.nonInterceptService.resetPasswordByEmail(this.emailVerificationForm.value).subscribe(res => {
                // this.initiateForm();
                if (res.status === HttpStatusCode.Ok) {
                    this.submitted = true;
                    //this.router.navigate(['/login']);
                } else {
                  //  this.toastrService.showErrorWithCustomMessage(data['documentation']);
                   /// this.router.navigate(['/login']);
                }
            });
      //  }
    }


}
