import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExpensesDetailService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdExpensesDetail(expensesDetailId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ExpensesDetail/deleteExpensesDetail', {'expensesDetailId': expensesDetailId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countExpensesDetail(expensesDetail): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'ExpensesDetail/count', {'expensesDetail': expensesDetail},
                                            this.httpOptions).pipe(retry(3), catchError(Constant.handleError));
    }

    updateExpensesDetail(expensesDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ExpensesDetail/updateExpensesDetail', [expensesDetail], this.httpOptions).pipe(retry(3), catchError(
            Constant.handleError));
    }

    addExpensesDetail(expensesDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'ExpensesDetail/addExpensesDetail', [expensesDetail], this.httpOptions).pipe(retry(3), catchError(
            Constant.handleError));
    }

    findAllExpensesDetail(expensesDetail, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ExpensesDetail/findAllExpensesDetail', {
                'expensesDetail': expensesDetail,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}
            }, this.httpOptions).pipe(retry(3), catchError(Constant.handleError));
    }

    // custome services
    expenseDetailGlcount(searchTerm,expenseId): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'ExpensesDetail/countGlobalExpensesDetail',
                                            {'searchTerm': searchTerm,"expenseId":expenseId},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalExpenseDetail(searchTerm,expenseId, pageNo: number, itemsPerPage: number): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'ExpensesDetail/findGlobalExpenseDetail',
            {'searchTerm': searchTerm,"expenseId":expenseId, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}