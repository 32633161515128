import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {UsersService} from "../../web-services/users.service";



@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.css']
})
export class CheckoutSuccessComponent implements OnInit {

  constructor(private route: ActivatedRoute,    public usersService: UsersService) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {

        this.usersService.updateUnPaidAdminUserToAdmin(params.session_id).subscribe(res => {

          alert(res);
          console.log(res);

        });

        console.log(params);
      });


  }

}
