import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { Console } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import {HttpStatusCode} from '@angular/common/http';
import {ClinicalDataService} from '../web-services/clinical-data.service';

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-lov-details',
  templateUrl: './lov-details.component.html',
  styleUrls: ['./lov-details.component.css'],
  providers: [DynamicDialogRef, DynamicDialogConfig]
})


export class LovDetailsComponent implements OnInit {

    @Input() dataObj;
    @Input() categoryString;
    @Input() reset;

  @Output() getResponse = new EventEmitter;
  @Output() updatedListOfValuesForEdit = new EventEmitter;
  @Input() halfWidth;
  @Input() label;
  @Input() currentSelectedArray;
  @Input() alternateData;

  listOfValuesList: any = [];
  public form: FormGroup;
  lovValue: any;
  selectedCities3 = [];
  display: boolean = false;
  selectedArray : any = [];
  refId : any;
  refType :  any;
  half: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    public lovServices: ListOfValuesService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private loader: NgxUiLoaderService,
    private clinicalDataService: ClinicalDataService
  ) {

  };

 // ref: DynamicDialogRef;

  ngOnChanges(changes: SimpleChanges) {


    if (changes.dataObj.currentValue.reset) {
    ////  alert(changes.dataObj.currentValue.reset);
      this.form.controls['selectedArray'].setValue('');
    }


    if (changes.currentSelectedArray !== undefined && changes.currentSelectedArray.currentValue.length > 0) {

      this.listOfValuesList = this.listOfValuesList.map(item => {
        /* no need to re-get the items from the database , also item.listOfValuesId.listOfValuesId because we remapped it earlier */
        const selectedListItem = changes.currentSelectedArray.currentValue.find(s => s.listOfValuesId === item.listOfValuesId.listOfValuesId);
       //// alert('changes.currentSelectedArray' + selectedListItem);
        if (selectedListItem !== undefined) {
             item.selected = 1;
             item.previouslySelected = 1;
             item.listOfValuesDetailId = selectedListItem.listOfValuesDetailId;
             item.listOfValuesMasterId = selectedListItem.listOfValuesMasterId;
              this.selectedArray.push(item);
        }

        return item;

    });

      console.log(this.listOfValuesList);

      this.form.controls['selectedArray'].setValue(this.selectedArray);
      this.getResponse.emit(this.listOfValuesList);

    }
    else {

      console.log('changed value : ', this.dataObj);
      console.log("categoryString :: ", this.categoryString);
      this.refId = this.dataObj['refId'];
      this.refType = this.dataObj['refType'];
      this.getLovByCategory(this.categoryString);


    }





  }

  ngOnInit(): void {
    console.log('lov-details initialized');
    console.log("data obj :: ", this.dataObj);
    console.log("categoryString :: ", this.categoryString);

    this.form = this.formBuilder.group({
      selectedArray: new FormControl(''),
    });

    this.refId = this.dataObj['refId'];
    this.refType = this.dataObj['refType'];

    this.half = this.halfWidth !== undefined;

    if (this.label === undefined) {

      this.label = this.categoryString;
    }

    if (this.categoryString) {
        this.getLovByCategory(this.categoryString, this.currentSelectedArray);
    }

    if (this.alternateData) {

        this.getRedFlagsForFollowUp(this.currentSelectedArray);
    }


  }

  showDialog() {
    this.display = true;
  }

  startLoader() {
    this.loader.start();
    // setTimeout(() => {
    //     this.loader.stop();
    // }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }

  addListOfValue() {
    // this.cities.push({ name: this.dropdown, code: this.dropdown });

    // this.selectedCities3 = this.form.controls['selectedCities'].value;
    // this.selectedCities3.push({ name: this.dropdown, code: this.dropdown });
    // this.form.controls['selectedCities'].patchValue(this.selectedCities3);
    // console.log(this.form.value);
    this.startLoader();
    this.display = false;

    this.lovServices.addListOfValueByCat(this.categoryString,this.lovValue).subscribe(res => {
      if (res['message'] == 'Success') {
        console.log("result of add list of values :: ",res);
        this.getLovByCategory(this.categoryString);
        this.stopLoader();
      } else {
        console.log("error result :: ", res['message']);
        this.stopLoader();
      }
    });
  }

  async getRedFlagsForFollowUp(selectedArray = []): Promise<void> {

    this.selectedArray = selectedArray;
    await this.clinicalDataService.getRedFlagsForFollowUp().toPromise().then(response => {

      if (response.status === HttpStatusCode.Ok) {



        this.listOfValuesList = response.body;


        this.listOfValuesList =     this.listOfValuesList.map(item => {
          item.listOfValuesId =  {
            listOfValuesId: item.listOfValuesId
          };


          if (item.isRedFlag) item.colour = 'red';

          if (item.isYellowFlag) item.colour = 'yellow';

          return item;
        });

        console.log('RED FLAGS' , this.listOfValuesList);

        this.listOfValuesList.map(d => {
          if (d.selected === 1){
            this.selectedArray.push(d);
          }

        });
        this.form.controls['selectedArray'].setValue(this.selectedArray);
        this.getResponse.emit(this.listOfValuesList);

      }

    });
  }

  async getLovByCategory(categoryName, selectedArray = []): Promise<void> {
    this.startLoader();
    this.selectedArray = selectedArray;

    await this.lovServices.findAllListOfValuesByCat(categoryName,this.refId,this.refType).toPromise().then(res => {
      if (res['message'] == 'Success') {
        this.listOfValuesList = res['data'];
        console.log(' listOfValuesList' , this.listOfValuesList);
        this.listOfValuesList =     this.listOfValuesList.map(item => {
          item.listOfValuesId =  {
            listOfValuesId: item.listOfValuesId
          };
          return item;
        });


        this.listOfValuesList.map(d => {
          if (d.selected === 1){
            this.selectedArray.push(d);
          }

        });
        console.log(this.selectedArray);



        this.form.controls['selectedArray'].setValue(this.selectedArray);
        this.getResponse.emit(this.listOfValuesList);
          // this.selectedArray.push(this.listOfValuesList.filter( x=> x.selected === 1));
        // this.presentConditionHistoryForm.controls['expertId'].setValue(presentConditionHistory['expertId']['expertId']);

        console.log('selected array : ', this.selectedArray);
        console.log("list of values result :: ", this.listOfValuesList);
        this.stopLoader();
      } else {
        console.log("error result :: ", res['message']);
        this.stopLoader();
      }
    });
  }

  getValue = [];

  hasNoRedFlags(lovItem) {


    return !lovItem['isRedFlag'] && !lovItem['isOrangeFlag'] && !lovItem['isYellowFlag']  && !lovItem['isGreenFlag'] && !lovItem['isBlueFlag'];
  }
  selectedValues(event) {


    this.getValue = [];
    this.getValue = event.value;



    for (let i = 0; i < this.listOfValuesList.length; i++){

      if (this.getValue.find(x => x.listOfValuesId === this.listOfValuesList[i]['listOfValuesId'])){

        this.listOfValuesList[i]['selected'] = 1;

        if ( this.hasNoRedFlags(this.listOfValuesList[i]) && this.listOfValuesList[i]['isPartOfInitialAssessment']) {

          this.lovServices.findListOfValuesDiagnosisByListOfValuesId(this.listOfValuesList[i]['listOfValuesId'].listOfValuesId).subscribe(res => {

            if (res.status === 200) {
              this.listOfValuesList[i]['diagnosis'] = res.body;
              console.log(this.listOfValuesList[i]);
            }


          });
        }


      }else{
        this.listOfValuesList[i]['selected'] = 0;
      }
    }

    this.getResponse.emit(this.listOfValuesList);
  }

  getFlagColour(listOfValue: any) {

    if (listOfValue.isRedFlag) return 'red';

    if (listOfValue.isYellowFlag) return 'yellow';

  }

}
