import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Constant } from '../util/constant';

@Injectable({
  providedIn: 'root'
})
export class AddOutcomeService {
  token = '';
  httpOptions = {headers: new HttpHeaders( {
    'Content-Type': 'application/json',
    Authorization: this.token,
    browser: this.deviceService.browser,
    browser_version: this.deviceService.browser_version,
    device: 'laptop',
    os: this.deviceService.os,
    os_version: this.deviceService.os_version

  })};

  private httpNonIntercept: HttpClient;
    constructor(private httpClient: HttpClient,handler: HttpBackend, private deviceService: DeviceDetectorService) {
        this.httpNonIntercept = new HttpClient(handler);
        this.token = sessionStorage.getItem('token');
    };
  

    addOutcomeMeasure(outcomeMeasure): Observable<string> {
        return this.httpNonIntercept.post<string>(
            Constant.BASE_URL + 'OutcomeMeasure/addOutcomeMeasureWithDetail', outcomeMeasure, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    
    updateOutcomeMeasure(outcomeMeasure): Observable<string> {
      return this.httpNonIntercept.post<string>(
          Constant.BASE_URL + 'OutcomeMeasure/updateOutcomeMeasureWithDetail', outcomeMeasure, this.httpOptions).pipe(
          retry(3), catchError(Constant.handleError));
  }
}
