import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PaymentsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdPayments(paymentId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Payments/deletePayments', {'paymentId': paymentId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countPayments(payments): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Payments/count', {'payments': payments}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updatePayments(payments): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Payments/updatePayments', [payments], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPayments(payments): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Payments/addPayments', [payments], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllPayments(payments, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Payments/findAllPayments',
            {'payments': payments, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom services to count and get global search results
    paymentsGlcount(searchTerm,userId): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Payments/countGlobalPayments',
                                            {'searchTerm': searchTerm,'userId':userId},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalPayments(searchTerm,userId, pageNo: number, itemsPerPage: number): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Payments/findGlobalPayments',
            {'searchTerm': searchTerm, 'userId':userId,'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
