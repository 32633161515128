import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../constants/constants';

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: './dialog-delete-alert.html',
})
export class DeleteAlertDialog {

  constructor(
    public dialogRef: MatDialogRef<DeleteAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
      console.log(data)
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}

export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}