import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-initial-assessment-multi-select-info',
  templateUrl: './initial-assessment-multi-select-info.component.html',
  styleUrls: ['./initial-assessment-multi-select-info.component.css']
})
export class InitialAssessmentMultiSelectInfoComponent implements OnInit {

  @Input() valuesList;
  @Input() icon;
  @Input() title;


  constructor() { }

  ngOnInit(): void {
  }




}
