import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class MakeSkinsService {

  // at line no 72 we store the skin name in the value of hidden element skinName,
  // && at line 73 we call a click event of a hidden button with id changeSkinButton which call changeSkin Function in mainComponent
  
  // further info skinName element is populated with the selected Skin and then we update our user preferences according to that skin.
  constructor() { }

  makeSkins() {
    $(function () {
      'use strict'

      $('[data-toggle="control-sidebar"]').controlSidebar()
      $('[data-toggle="push-menu"]').pushMenu()

      var $pushMenu = $('[data-toggle="push-menu"]').data('lte.pushmenu')
      var $controlSidebar = $('[data-toggle="control-sidebar"]').data('lte.controlsidebar')
      var $layout = $('body').data('lte.layout')
      var mySkins = [
        'skin-blue',
        'skin-blue-dark',
        'skin-blue-light',
        'skin-brown',
        'skin-brown-light',
        'skin-gray',
        'skin-green',
        'skin-green-dark',
        'skin-green-light',
        'skin-purple',
        'skin-purple-light',
        'skin-red',
        'skin-red-light'
      ]

      function get(name) {
        if (typeof (Storage) !== 'undefined') {
          return localStorage.getItem(name)
        } else {
          window.alert('Please use a modern browser to properly view this template!')
        }
      }

      function store(name, val) {
        if (typeof (Storage) !== 'undefined') {
          localStorage.setItem(name, val)
        } else {
          window.alert('Please use a modern browser to properly view this template!')
        }
      }

      function changeLayout(cls) {
        $('body').toggleClass(cls)
        $layout.fixSidebar()
        if ($('body').hasClass('fixed') && cls == 'fixed') {
          $pushMenu.expandOnHover()
          $layout.activate()
        }
        $controlSidebar.fix()
      }

      function changeSkin(cls) {
        $.each(mySkins, function (i) {
          $('body').removeClass(mySkins[i])
        })

        $('body').addClass(cls);
        // skinName element is populated with the selected Skin and we update our user preferences according to this.
        $('#skinName').val(cls);
        $('#changeSkinButton').click();
        store('skin', cls);
        return false
      }

      function setup() {
        var tmp = get('skin')
        if (tmp && $.inArray(tmp, mySkins))
          changeSkin(tmp)

        $('[data-skin]').on('click', function (e) {
          if ($(this).hasClass('knob'))
            return
          e.preventDefault()
          changeSkin($(this).data('skin'))
        })

        $('[data-layout]').on('click', function () {
          changeLayout($(this).data('layout'))
        })

        $('[data-controlsidebar]').on('click', function () {
          changeLayout($(this).data('controlsidebar'))
          var slide = !$controlSidebar.options.slide

          $controlSidebar.options.slide = slide
          if (!slide)
            $('.control-sidebar').removeClass('control-sidebar-open')
        })

        $('[data-sidebarskin="toggle"]').on('click', function () {
          var $sidebar = $('.control-sidebar')
          if ($sidebar.hasClass('control-sidebar-dark')) {
            $sidebar.removeClass('control-sidebar-dark')
            $sidebar.addClass('control-sidebar-light')
          } else {
            $sidebar.removeClass('control-sidebar-light')
            $sidebar.addClass('control-sidebar-dark')
          }
        })

        $('[data-enable="expandOnHover"]').on('click', function () {
          $(this).attr('disabled', true)
          $pushMenu.expandOnHover()
          if (!$('body').hasClass('sidebar-collapse'))
            $('[data-layout="sidebar-collapse"]').click()
        })

        if ($('body').hasClass('fixed')) {
          $('[data-layout="fixed"]').attr('checked', 'checked')
        }
        if ($('body').hasClass('layout-boxed')) {
          $('[data-layout="layout-boxed"]').attr('checked', 'checked')
        }
        if ($('body').hasClass('sidebar-collapse')) {
          $('[data-layout="sidebar-collapse"]').attr('checked', 'checked')
        }

      }

      var $tabPane = $('<div />', {
        'id': 'control-sidebar-theme-demo-options-tab',
        'class': 'tab-pane active'
      })

      var $tabButton = $('<li />', { 'class': 'active' })
        .html('<a href=\'#control-sidebar-theme-demo-options-tab\' data-toggle=\'tab\'>'
          + '<i class="fa fa-wrench"></i>'
          + '</a>')

      $('[href="#control-sidebar-home-tab"]')
        .parent()
        .before($tabButton)

      var $demoSettings = $('<div />')

      var $skinsList = $('<ul />', { 'class': 'list-unstyled clearfix' })
      var $skinBlue =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-blue" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div></span><span style="display:block; width: 100%; float: left; height: 7px;background: #163867 "></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #122E54"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Blue</p>')
      $skinsList.append($skinBlue)
      var $skinGray =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-gray" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div style="box-shadow: 0 0 2px rgba(0,0,0,0.1)" class="clearfix"><span style="display:block; width: 100%; float: left; height: 7px; background: #363636"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #2F2F2F"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Gray</p>')
      $skinsList.append($skinGray)
      var $skinPurple =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-purple" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span style="display:block; width: 100%; float: left; height: 7px;background:#67163C"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #5A1335"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Purple</p>')
      $skinsList.append($skinPurple)
      var $skinGreen =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-green-dark" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span style="display:block; width: 100%; float: left; height: 7px;background:#166741 "></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #135A39"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Dark Green</p>')
      $skinsList.append($skinGreen)
      var $skinRed =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-red" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span  style="display:block; width: 100%; float: left; height: 7px;background:#7A2727 "></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #6B2222"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Red</p>')
      $skinsList.append($skinRed)
      var $skinYellow =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-brown" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div></span><span style="display:block; width: 100%; float: left; height: 7px;background:#7F4F1B"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #704518"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Brown</p>')
      $skinsList.append($skinYellow)

      // Light sidebar skins
      var $skinBlueLight =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-blue-light" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span  style="display:block; width: 100%; float: left; height: 7px;background:#32759C"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #3C8DBC"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Blue Light</p>')
      $skinsList.append($skinBlueLight)
      var $skinBlackLight =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-green" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div style="box-shadow: 0 0 2px rgba(0,0,0,0.1)" class="clearfix"><span style="display:block; width: 100%; float: left; height: 7px; background: #3C9C33 "></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #35892D"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Green</p>')
      $skinsList.append($skinBlackLight)
      var $skinPurpleLight =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-purple-light" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span style="display:block; width: 100%; float: left; height: 7px;background:#854C96"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #754384"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Purple Light</p>')
      $skinsList.append($skinPurpleLight)
      var $skinGreenLight =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-green-light" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span style="display:block; width: 100%; float: left; height: 7px;background:#3CBC78"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #329C64"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Green Light</p>')
      $skinsList.append($skinGreenLight)
      var $skinRedLight =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-brown-light" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span  style="display:block; width: 100%; float: left; height: 7px;background:#C05438"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #A94A31"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Brown Light</p>')
      $skinsList.append($skinRedLight)
      var $skinYellowLight =
        $('<li />', { style: 'float:left; width: 33.33333%; padding: 5px;' })
          .append('<a href="javascript:void(0)" data-skin="skin-blue-dark" style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4)" class="clearfix full-opacity-hover">'
            + '<div><span style="display:block; width: 100%; float: left; height: 7px;background:#0B3271"></span></div>'
            + '<div><span style="display:block; width: 20%; float: left; height: 20px; background: #09295D"></span><span style="display:block; width: 80%; float: left; height: 20px; background: #f4f5f7"></span></div>'
            + '</a>'
            + '<p class="text-center no-margin" style="font-size: 10px">Dark Blue</p>')
      $skinsList.append($skinYellowLight)

      $demoSettings.append('<h4 class="control-sidebar-heading">Skins</h4>')
      $demoSettings.append($skinsList)
      $tabPane.append($demoSettings)
      $('#control-sidebar-home-tab').after($tabPane)

      setup()

      $('[data-toggle="tooltip"]').tooltip()
    })

  }
}
