import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UsersService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    private httpNonIntercept: HttpClient;

    constructor(private httpClient: HttpClient, private handler: HttpBackend) {
        this.httpNonIntercept = new HttpClient(handler);
    };

    deleteByIdUsers(userId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Users/deleteUsers', {'userId': userId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countUsers(users): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Users/count', {'users': users}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateUsers(users): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Users/updateUsers', [users], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addUsers(users): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Users/addUsers', [users], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllUsers(users, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Users/findAllUsers', {'users': users, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllUsersByBusinessRoleId(businessRoleId): Observable<string[]> {
        return this.httpNonIntercept.post<string[]>(
            Constant.UMS_URL + 'getUser', {'user': {'search_username': '', 'search_role': businessRoleId}, 'itemsPerPage': 0, 'page': -1},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findUserById(userId) {
        return this.httpNonIntercept.post<string[]>(
            Constant.UMS_URL + 'findUser', {'userId': userId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));

    }

    addClientUser(clientUser): Observable<any> {
        return this.httpClient.post<any>(
            Constant.AUTH_SERVICE + '/register/addClientUser', clientUser,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json'),
            observe: 'response'
          }
        ).pipe(
            retry(0), catchError(Constant.handleError));

    }

  updateUnPaidAdminUserToAdmin(stripeSessionId) {
    return this.httpNonIntercept.post<string[]>(
      Constant.CPA_SPRING_PUBLIC_API_URL + 'stripe/payment-success', {'stripeSessionId': stripeSessionId},
      this.httpOptions
    ).pipe(
      retry(3), catchError(Constant.handleError));

  }

}
