import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import { map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class ApplicationRoleService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient) {

  };

  findAllApplicationRoles():Observable<HttpResponse<any>> {


    return this.httpClient.get<HttpResponse<any>>(Constant.AUTH_SERVICE + 'application-roles', { observe: 'response' }).pipe(
      retry(3), catchError(Constant.handleError));
  }

}
