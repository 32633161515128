import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';
import {EditHistoryFormDataDTO} from "../dto/EditHistoryFormDataDTO";

@Injectable({providedIn: 'root'})
export class PresentConditionHistoryService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdPresentConditionHistory(presentConditionHistoryId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PresentConditionHistory/deletePresentConditionHistory',
            {'presentConditionHistoryId': presentConditionHistoryId},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countPresentConditionHistory(presentConditionHistory): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'PresentConditionHistory/count', {'presentConditionHistory': presentConditionHistory},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updatePresentConditionHistory(presentConditionHistory, refData): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'PresentConditionHistory/updatePresentConditionHistory', [presentConditionHistory, refData], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addPresentConditionHistory(presentConditionHistory): Observable<any> {
        console.log('addPresentConditionHistory', presentConditionHistory);

        return this.httpClient.post<string>(
            Constant.CPA_SPRING_API_URL + 'present-condition-histories', presentConditionHistory, {headers: new HttpHeaders()
              .set('Content-Type', 'application/json'),
            observe: 'response'}).pipe(
            retry(3), catchError(Constant.handleError));
    }
    updatePresentConditionHistoryCpa(presentConditionHistory: EditHistoryFormDataDTO, presentConditionHistoryId: number): Observable<any> {
    console.log('updatePresentConditionHistoryCpa', presentConditionHistory);

    return this.httpClient.put<string>(
      Constant.CPA_SPRING_API_URL + 'present-condition-histories/' + presentConditionHistoryId, presentConditionHistory, {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'}).pipe(
      retry(3), catchError(Constant.handleError));
  }




  findAllPresentConditionHistory(presentConditionHistory, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PresentConditionHistory/findAllPresentConditionHistory',
            {'presentConditionHistory': presentConditionHistory, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    findPresentConditionHistoriesByPatientId(patientId, pageNo: number, itemsPerPage: number): Observable<any> {
    return this.httpClient.get(
      Constant.CPA_SPRING_API_URL + 'patients/' + patientId + '/present-condition-histories',
      {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'})
    .pipe(retry(3), catchError(Constant.handleError));
    }

  findPresentConditionHistoriesByAppointmentId(appointmentId): Observable<any> {
      alert(Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/present-condition-histories');
    return this.httpClient.get(
      Constant.CPA_CLINICAL_DATA_SERVICE + 'appointments/' + appointmentId + '/present-condition-histories',
      {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'})
      .pipe(retry(3), catchError(Constant.handleError));
  }

    findPresentConditionHistoriesById(presentConditionHistoryId): Observable<any> {
    return this.httpClient.get(
      Constant.CPA_SPRING_API_URL + 'present-condition-histories/' + presentConditionHistoryId,
      {headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'})
      .pipe(retry(3), catchError(Constant.handleError));
   }





    // custom service for global search

    globalSearchCount(searchTerm,appointmentId,expertId,patientId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'PresentConditionHistory/countGlobalPCH',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    globalSearchFind(searchTerm,appointmentId,expertId,patientId, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'PresentConditionHistory/findGlobalPCH',
            {'searchTerm': searchTerm,'appointmentId':appointmentId,'expertId':expertId,'patientId':patientId,
                'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
