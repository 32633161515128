<div class="h-100">
    <div class="loginContainer" id="loginchangepassword-container">

        <!--Login Header-->
        <div class="loginHeader" id="loginchangepassword-Header">
            <div class="container">
                <div class="loginHeaderInner">
                    <div class="loginHeading">
                        <h3>Welcome to CPA</h3>
                    </div>
                    <div class="loginLogo">
                        <a> <img src="assets/theme1/custom-files/img/logos/cpalogo.png"/> </a>
                    </div>

                </div>
            </div>
        </div>

        <!-- Login Body Area -->
        <div class="container-fluid h-100" id="loginchangepassword-Body">
            <div class="row justify-content-center align-items-center h-100">
                <div class="loginBox" id="loginchangepassword">
                    <p-card id="loginchangepassword-card" styleClass="p-card-shadow">
                        <div class="loginTitle">
                            <h3> Change Password </h3>
                            <h5>Create a new, strong password</h5>
                        </div>
                        <!--            <form class="formContainer" id="loginchangepassword" [formGroup]="changePassword"-->

                        <form (blur)="logValidationErrors()" [formGroup]="resetPasswordForm" class="formContainer"
                              id="loginchangepassword-p">
                            <div class="p-fluid p-formgrid p-grid">
                                <div [ngClass]="{ 'invalidfield': formErrors.password }" class="p-field p-col-12"
                                     id="loginchangepassword-newpassword">
                                    <label>New Password</label>
<!--                                    <small *ngIf="formErrors.password">{{formErrors.password}}</small>-->
                                    <span class="passwrodCustomized p-input-icon-left p-input-icon-right">
                    <i class="pi pi-lock"></i>
                    <p-password (blur)="logValidationErrors()" [toggleMask]="true" formControlName="password"
                                placeholder="New Password">

                      <ng-template pTemplate="footer">
                        <p class="p-mt-2">Password Must Be:</p>
                        <p-divider></p-divider>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                          <li>At least one lowercase</li>

                          <li>At least one uppercase</li>
                          <li>At Least 1 Special character</li>

                          <li>At least one numeric</li>
                          <li>Minimum 8 characters</li>
                        </ul>
                      </ng-template>
                    </p-password>
                  </span>
                                    <small *ngIf="formErrors.password">{{formErrors.password}}</small>
                                </div>

                                <div [ngClass]="{ 'invalidfield': formErrors.confirmPassword }" class="p-field p-col-12"
                                     id="loginchangepassword-confirmnewpassword">
                                    <label>Confirm New Password</label>
<!--                                    <small *ngIf="formErrors.confirmPassword">{{formErrors.confirmPassword}}</small>-->
                                    <span class="passwrodCustomized p-input-icon-left p-input-icon-right">
                    <i class="pi pi-lock"></i>
                    <p-password (blur)="logValidationErrors()"
                                [toggleMask]="true" formControlName="confirmPassword"
                                placeholder="Confirm New Password">
                    </p-password>
                  </span>
                                    <small *ngIf="formErrors.confirmPassword">{{formErrors.confirmPassword}}</small>
                                </div>

                            </div>
                        </form>
                        <div class="footer-content p-d-flex p-jc-between" id="loginchangepassword-footer">
                            <p-button [routerLink]="[ '/', 'login' ]" id="loginchangepassword-skipbtn" label="Skip"
                                      styleClass="p-button-link">
                            </p-button>
                            <p-button [disabled]="!resetPasswordForm.valid" (click)="resetPassword()"
                                      class="p-d-inline-flex p-ai-center"
                                      id="loginchangepassword-btn"
                                      label="Submit" styleClass="p-button">
                            </p-button>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>


    </div>
</div>
