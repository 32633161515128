
export class Utils {

    public static contain(arr, key) {
        if (arr != undefined) {
            for (var itr_arr_count = 0; itr_arr_count < arr.length; itr_arr_count++) {
                if (arr[itr_arr_count] == key) {
                    return true;
                }
            }
        }
        if (arr == undefined) {
            return false;
        }
        return false;
    }


    

    public  DeepCopy(obj: any): any {
        let clonedObject;

        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        if (obj instanceof Array) {
            clonedObject = [];
            for (let i = 0; i < obj.length; i++) {
                clonedObject[i] = this.DeepCopy(obj[i]);
            }
            return clonedObject;
        }
        if (obj instanceof Date) {
            clonedObject = new Date(obj.valueOf());
            return clonedObject;
        }
        if (obj instanceof RegExp) {
            clonedObject = RegExp(obj.source, obj.flags);
            return clonedObject;
        }
        if (obj instanceof Object) {
            clonedObject = new obj.constructor();
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    clonedObject[attr] = this.DeepCopy(obj[attr]);
                }
            }
            return clonedObject;
        }
    }

    convertDateTomilliSeconds(dateString){
        var date = new Date(dateString); // some mock date
        return date.getTime();
    }
    getUpdatedMillissecondObject(formValue , dateKey){
        var obj = this.DeepCopy(formValue);
        var date = obj[dateKey];
        var millisecondDate = this.convertDateTomilliSeconds(date);
        console.log(millisecondDate);
        obj[dateKey] = millisecondDate+'';
        return obj;
    }

    returnDateFromMilliSecond(userdate){
        var date = new Date(userdate);

        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var year = date.getFullYear();

        return year + '-' + month + '-' + day;

    }

    getAge(date){
        let today = new Date();
        let birthDate = new Date(date);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age + 'years';
    }

}
