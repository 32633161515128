import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {GoalsService} from '../web-services/goals.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {UsersService} from '../web-services/users.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewdetailComponent} from '../viewdetail/viewdetail.component';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {Utils} from '../util/utils';

@Component({
               selector: 'app-goals',
               templateUrl: './goals.component.html',
               styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateGoalId = null;
    listOfValuesDetailList: any = [];
    smartGoalList: any = [];
    goalsList: any = [];
    goalsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    goalsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    utils = new Utils();
    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    goalsCat = Constant.GOALS_LOV_GOALS;
    expertId;
    patientId;
    appointmentId;

    constructor(
        private goalsServices: GoalsService,
        private listOfValuesDetailServices: ListOfValuesDetailService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService
    ) {
    };
    ref: DynamicDialogRef;

    viewAgencyDetail(goalId: number) {
        // let flag = this.flagsList.find(x => x['flagId'] == flagId);
        //
        // this.ref = this.dialogService.open(ViewdetailComponent, {
        //     header: 'Company Detail',
        //     width: '90%',
        //     contentStyle: {'max-height': '750px', 'overflow': 'auto'},
        //     baseZIndex: 10000,
        //     data: {
        //         mainData: [
        //             {label: this.labels.flagsColumnFlag, value: flag['flag']['label']},
        //             {label: this.labels.flagsColumnFlagValue, value: flag['flagValue']['label']},
        //             {label: this.labels.agencyColumnAgencyLastname, value: flag['flagStatus']['label']},
        //         ]
        //     }
        // });
    }
    

    lovDataObject : any = {};
    responseData : any;
    refDataObj: any;

    goalsCompList: any = [];
    goalsCompError = false;
    getResponse($event) {
        this.responseData = $event;
        console.log('Response from child : ', this.responseData);
        if(this.responseData[0]['categoryId']['categoryTitle'] === Constant.GOALS_LOV_GOALS){
            this.goalsCompList = this.responseData;
        }

        console.log("after check :: ",this.goalsCompList);
    
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.expertId = parseInt(this.sessionService.getSession('expertId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.lovDataObject = {"refId":0,"refType":""};
        this.findAllListOfValuesDetail();
        this.goalsForm = new FormGroup({
                                           goalDate: new FormControl('',[Validators.required]),
                                           goals: new FormControl(),
                                           smartGoal: new FormControl('', [Validators.required]),
                                           patientId: new FormControl(),
                                           expertId: new FormControl(),
                                           appointmentId: new FormControl(),
                                           userId: new FormControl(0),
                                       });
        this.goalsSearch = new FormGroup({
                                             goalDate: new FormControl(),
                                             goals: new FormControl(),
                                             smartGoal: new FormControl(),
                                             patientId: new FormControl(),
                                             expertId: new FormControl(),
                                             appointmentId: new FormControl(),
                                             userId: new FormControl(),
                                         });
        this.findSmartGoals();
        this.countGoals();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.goalsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
        
    }

    findSmartGoals() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('smartGoal').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.smartGoalList.push(dropDown);
                        console.log('drop down values array :: ', dropDown);
                    }
                } else {
                    this.smartGoalList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    get addUpdateTitle() {
        return this.updateGoalId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.goalsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countGoals();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countGoals() {
        this.goalsSearch.controls['expertId'].setValue(this.expertId);
        this.goalsSearch.controls['patientId'].setValue(this.patientId);
        this.goalsSearch.controls['appointmentId'].setValue(this.appointmentId);
        // var obj =this.utils.getUpdatedMillissecondObject(this.goalsSearch.value,"goalDate");
        // if(obj['goalDate'] === "0"){
        //     obj['goalDate'] = null;
        // }
        let obj =this.utils.getUpdatedMillissecondObject(this.goalsSearch.value,"goalDate");
        if(obj['goalDate'] === '0'){
            delete obj['goalDate'];
        }
        this.goalsServices.countGoals(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGoals();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        console.log("on submit clicked");
        
        this.goalsForm.markAllAsTouched();
        
        this.goalsCompError = (this.goalsCompList.filter(d => {return d.selected === 1}).length) < 1;
        
        if(this.goalsCompError){
            return;
        }
        this.refDataObj = {
            'Goals': this.goalsCompList,
            'refDataCheck' : true
        }

        
        this.goalsForm.controls['expertId'].setValue(this.expertId);
        this.goalsForm.controls['patientId'].setValue(this.patientId);
        this.goalsForm.controls['appointmentId'].setValue(this.appointmentId);
        console.log("outside error :: ",this.goalsForm.valid);
        if (this.goalsForm.valid) {
            this.addUpdateGoals(this.goalsForm.value);
            this.submitted = false;
        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllGoals();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.goalsSearch.reset();
        this.countGoals();
        // this.findAllGoals();
    }
   
    addUpdateGoals(goals) {
        this.startLoader();
        let obj =this.utils.getUpdatedMillissecondObject(this.goalsForm.value,"goalDate");
        console.log(obj, 'date object');
        if (this.updateGoalId == null) {
            this.goalsServices.addGoals(obj,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    goals = res['data'][0];
                    this.goalsList.push(goals);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameGoals) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameGoals)
                        });
                    this.showHideAddDialog();
                    this.countGoals();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.goalId = this.updateGoalId;

            // goals.goalId = this.updateGoalId;
            this.goalsServices.updateGoals(obj,this.refDataObj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.goalsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameGoals) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameGoals)
                        });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countGoals();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateGoalId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findAllGoals() {
        this.startLoader();
        this.goalsSearch.controls['expertId'].setValue(this.expertId);
        this.goalsSearch.controls['patientId'].setValue(this.patientId);
        this.goalsSearch.controls['appointmentId'].setValue(this.appointmentId);
        let obj =this.utils.getUpdatedMillissecondObject(this.goalsSearch.value,"goalDate");
        if(obj['goalDate'] === '0'){
            delete obj['goalDate'];
        }
        this.goalsServices.findAllGoals(obj, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.goalsList = res['data'];
                } else {
                    this.goalsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    deleteByIdGoals(goalId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.goalsServices.deleteByIdGoals(goalId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.goalsList.splice(index, 1);
                        this.messageService.add(
                            {
                                severity: 'warn',
                                summary: this.translate(this.labels.entityNameGoals) +
                                    this.translate(this.labels.deletedSuccessfully),
                                detail: ''
                                // detail: this.translate(this.labels.entityNameGoals)
                            });
                        this.countGoals();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

    loadGoalsToEdit(goalId: number, index) {
        this.addEditTitle = 'Edit';
        let goals = this.goalsList.find(x => x['goalId'] == goalId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateGoalId = goals['goalId'];
        this.selectedIndex = index;
        this.lovDataObject = {"refId":goals['goalId'],"refType":""};

        if(goals['goalDate']) {
            this.goalsForm.controls['goalDate'].setValue(new Date(goals['goalDate']));
        }
        if(goals['goals']['listOfValuesMasterId']) {
            this.goalsForm.controls['goals'].setValue(goals['goals']['listOfValuesMasterId']);
        }
        if(goals['smartGoal']) {
            this.goalsForm.controls['smartGoal'].setValue(goals['smartGoal']['listOfValuesId']);
        }
        if(goals['patientId']) {
            this.goalsForm.controls['patientId'].setValue(goals['patientId']['patientId']);
        }
        if(goals['expertId']) {
            this.goalsForm.controls['expertId'].setValue(goals['expertId']['expertId']);
        }
        if(goals['appointmentId']) {
            this.goalsForm.controls['appointmentId'].setValue(goals['appointmentId']['appointmentId']);
        }
        // this.goalsForm.controls['userId'].setValue(goals['userId']);

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearAdd() {
        this.goalsForm.reset();
        this.goalsForm.markAsUntouched();
        this.updateGoalId = null;
        // closeAddOverlaydiv();
    }

    findAllListOfValuesDetail() {
        let temp = [];
        this.startLoader();
        this.listOfValuesDetailServices.findAllListOfValuesDetail(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesDetailId'];
                        dropDown.label = object['referenceType'];
                        this.listOfValuesDetailList.push(dropDown);
                    }
                } else {
                    this.listOfValuesDetailList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.goalsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.goalsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.goalsForm.get(control)?.valid && this.goalsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.goalsForm.reset();
    }

    onCloseSearch() {
        this.goalsSearch.reset();
    }

    refresh() {
        this.countGoals();
    }

    onAddOverlayDiv() {
        this.goalsForm.reset();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.goalsSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.goalsForm.reset();
            this.lovDataObject = {"refId":0,"refType":""};
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateGoalId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.goalsServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.goalsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.goalsServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.goalsList = result['data'];
            } else {
                this.goalsList = [];
            }
            console.log(result, 'result');
        });
    }
}
