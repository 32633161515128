import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient) {
  };

  findAllSubscription(subscription, pageNo: number, itemsPerPage: number): Observable<string[]> {
    return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'Subscription/findAllSubscription',
        {'subscription': subscription, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
    ).pipe(
        retry(3), catchError(Constant.handleError));
  }

  // addUserSubscriptionlist(subscription, pageNo: number, itemsPerPage: number): Observable<string[]> {
  //   return this.httpClient.post<string[]>(
  //       Constant.BASE_URL + 'Subscription/findAllSubscription',
  //       {'subscription': subscription, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
  //   ).pipe(
  //       retry(3), catchError(Constant.handleError));
  // }

  //custom created service (not by code generator)
  addUserSubscriptionlist(userSubscriptionlist): Observable<string> {
    return this.httpClient.post<string>(
        Constant.BASE_URL + 'UserSubscription/addSubscriptionlist', [userSubscriptionlist],
        this.httpOptions
    ).pipe(retry(3), catchError(Constant.handleError));
  }

  findAllUserSubscription(subscription, pageNo: number, itemsPerPage: number): Observable<string[]> {
    return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'UserSubscription/findAllUserSubscription', {'userSubscription': subscription, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
        this.httpOptions
    ).pipe(
        retry(3), catchError(Constant.handleError));
  }

  updateUserSubscriptionlist(UserSubscriptionlist): Observable<string> {
    return this.httpClient.post<string>(
        Constant.BASE_URL + 'UserSubscription/updateUserSubscription', [UserSubscriptionlist],
        this.httpOptions
    ).pipe(retry(3), catchError(Constant.handleError));
  }

  //Manage Subsriptions
  findUserSubscription(UserId): Observable<string[]> {
    // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
    return this.httpClient.post<string[]>(
        Constant.BASE_URL + 'UserSubscription/getActiveSubscriptionlist',
        {'userId': UserId},
        this.httpOptions
    ).pipe(
        retry(3), catchError(Constant.handleError));
  }

  activateUserSubscriptionlist(userId,userType,UserSubscriptionlist): Observable<string> {
    // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
    return this.httpClient.post<string>(
        Constant.BASE_URL + 'UserSubscription/addUserSubscriptions', {'userId': userId,'userTypeId':userType,'subscription': UserSubscriptionlist},
        this.httpOptions
    ).pipe(retry(3), catchError(Constant.handleError));
  }

  // Refresh subscription list
  refreshSubscriptionlist(parentId,userTypeId,childId): Observable<string> {
    // let baseURL = 'http://localhost:8080/CPA/Hive-Worx/';
    return this.httpClient.post<string>(
      Constant.BASE_URL + 'UserSubscription/getUpdatedUserSubscriptions', {'parentId': parentId,'userTypeId':userTypeId,'childId': childId},
        this.httpOptions
    ).pipe(retry(3), catchError(Constant.handleError));
  }

  // deleteByIdAgency(agencyId): Observable<string> {
  //   return this.httpClient.post<string>(Constant.BASE_URL + 'Agency/deleteAgency', {'agencyId': agencyId}, this.httpOptions).pipe(
  //       retry(3), catchError(Constant.handleError));
  // }
  //
  // countAgency(agency): Observable<number> {
  //   return this.httpClient.post<number>(Constant.BASE_URL + 'Agency/count', {'agency': agency}, this.httpOptions).pipe(
  //       retry(3), catchError(Constant.handleError));
  // }
  //
  // updateAgency(agency): Observable<string> {
  //   return this.httpClient.post<string>(Constant.BASE_URL + 'Agency/updateAgency', [agency], this.httpOptions).pipe(
  //       retry(3), catchError(Constant.handleError));
  // }
  //
  // addAgency(agency): Observable<string> {
  //   return this.httpClient.post<string>(Constant.BASE_URL + 'Agency/addAgency', [agency], this.httpOptions).pipe(
  //       retry(3), catchError(Constant.handleError));
  // }

}
