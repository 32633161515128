import { Component, OnInit } from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {WebService} from "../services/webservice/web.service";

interface MenuNode {
  name: string;
  applicationroleid: number;
  menuicon: string;
  menuurl?: string;
  children?: MenuNode[];
}



const MENU: MenuNode[] = [
  {
    "applicationroleid": 870,
    "name": "Dashboard",
    "menuicon": "assets/theme1/custom-files/img/icons/Dashboard.svg",
    "menuurl": null,


  },
  {
    "applicationroleid": 875,
    "name": "Invoice",
    "menuicon": "assets/theme1/custom-files/img/icons/invoicing.svg",
    "menuurl": "/invoice",

  },
  {
    "applicationroleid": 882,
    "name": "Add Patient",
    "menuicon": "assets/theme1/custom-files/img/icons/Patient.svg",
    "menuurl": "/patients",

  },
  {
    "applicationroleid": 894,
    "name": "Clinic Schedule",
    "menuicon": "assets/theme1/custom-files/img/icons/clinic schedule.svg",
    "menuurl": "/clinic_experts_schedule",

  },
];

/** Flat node with expandable and level information */
interface MenuFlatNode {
  expandable: boolean;
  name: string;
  menuicon: string;
  level: number;
}


@Component({
  selector: 'app-z',
  templateUrl: './z.component.html',
  styleUrls: ['./z.component.css']
})



export class ZComponent implements OnInit {

  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      menuicon: node.menuicon,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<MenuFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  loggedInUser: any;
  menuLinks: [];
  constructor( private webService: WebService) {

   // this.dataSource.data = MENU;


  }
  hasChild = (_: number, node: MenuFlatNode) => node.expandable;

  ngOnInit(): void {

   this.getSideMenu();

  }


  getSideMenu() {

    this.webService.getSideMenu(854).subscribe(data => {
      console.log(data);

      if (Array.isArray(data)) {
       this.dataSource.data = data;
      // alert(data.length);
     //   alert(Array.isArray(data));
      //  this.dataSource.data = data;

    //  console.log(data);



      }

    });
  }


}/*
[
  {
    "name": "CPA",
    "children": [
      {
        "name": "Initial Assessment",
        "sort": 1,
        "children": [
          {
            "name": "Client Details",
            "path": "/client_details",
            "application_id": 1,
            "application_url": "http://app.clinicalproassist.com/",
            "quick_link": "false",
            "quick_link_id": 0,
            "id": 902,
            "icon": "assets/theme1/custom-files/img/icons/clients-details.svg"
          },
          {
            "name": "History",
            "path": "/history",
            "application_id": 1,
            "application_url": "http://app.clinicalproassist.com/",
            "quick_link": "true",
            "quick_link_id": 1352,
            "id": 903,
            "icon": "assets/theme1/custom-files/img/icons/history.svg"
          },
          {
            "name": "Body Chart",
            "path": "/body_chart",
            "application_id": 1,
            "application_url": "http://app.clinicalproassist.com/",
            "quick_link": "false",
            "quick_link_id": 0,
            "id": 904,
            "icon": "assets/theme1/custom-files/img/icons/body-chart.svg"
          },
          {
            "name": "Observations",
            "path": "/observations",
            "application_id": 1,
            "application_url": "http://app.clinicalproassist.com/",
            "quick_link": "false",
            "quick_link_id": 0,
            "id": 905,
            "icon": "assets/theme1/custom-files/img/icons/observations.svg"
          },
          {
            "name": "Testing",
            "path": "/testing",
            "application_id": 1,
            "application_url": "http://app.clinicalproassist.com/",
            "quick_link": "false",
            "quick_link_id": 0,
            "id": 906,
            "icon": "assets/theme1/custom-files/img/icons/testing.svg"
          },
          {
            "name": "Treatment",
            "path": "/treatment",
            "application_id": 1,
            "application_url": "http://app.clinicalproassist.com/",
            "quick_link": "false",
            "quick_link_id": 0,
            "id": 907,
            "icon": "assets/theme1/custom-files/img/icons/treatment.svg"
          }
        ]
      },
      {
        "name": "Flags",
        "icon": "assets/theme1/custom-files/img/icons/flags.svg",
        "path": "/flags",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1373,
        "id": 908,
        "sort": 8
      },
      {
        "name": "Goals",
        "icon": "assets/theme1/custom-files/img/icons/goals.svg",
        "path": "/goals",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1185,
        "id": 909,
        "sort": 9
      },
      {
        "name": "Outcome Measures",
        "icon": "assets/theme1/custom-files/img/icons/outcome-measures.svg",
        "path": "/outcome_measure",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1182,
        "id": 910,
        "sort": 10
      },
      {
        "name": "Follow Up",
        "icon": "assets/theme1/custom-files/img/icons/follow-up.svg",
        "path": "/followup",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1186,
        "id": 911,
        "sort": 11
      },
      {
        "name": "Discharge",
        "icon": "assets/theme1/custom-files/img/icons/Discharge.svg",
        "path": "/discharge",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "false",
        "quick_link_id": 0,
        "id": 912,
        "sort": 12
      },
      {
        "name": "Reports",
        "icon": "assets/theme1/custom-files/img/icons/view-medical-report.svg",
        "path": "/reports",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1327,
        "id": 913,
        "sort": 13
      },
      {
        "name": "CPA Tools",
        "icon": "assets/theme1/custom-files/img/icons/cpa-tools.svg",
        "path": "/cpaTools",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1326,
        "id": 914,
        "sort": 14
      },
      {
        "name": "Communications",
        "icon": "assets/theme1/custom-files/img/icons/communications.svg",
        "path": "/communications",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1382,
        "id": 915,
        "sort": 15
      },
      {
        "name": "Image File Upload",
        "icon": "assets/theme1/custom-files/img/icons/image-upload.svg",
        "path": "/image-file-upload",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "false",
        "quick_link_id": 0,
        "id": 916,
        "sort": 16
      },
      {
        "name": "Dashboard",
        "icon": "assets/theme1/custom-files/img/icons/Dashboard.svg",
        "path": "/dashboard",
        "application_id": 1,
        "application_url": "http://app.clinicalproassist.com/",
        "quick_link": "true",
        "quick_link_id": 1323,
        "id": 870,
        "sort": 17
      }
    ],
    "icon": "assets/theme1/custom-files/img/icons/cpaLogo.svg"
  }
]*/
