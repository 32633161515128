import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ChatService} from '../services/chat/chat.service';
// import { SharedService } from '../../services/sharedservice/shared.service';
import {CollaborationSharedServiceService} from '../services/collaboration-shared-service/collaboration-shared-service.service';
// import { AlertService } from '../../services/alertservice/alert.service';
import {CollabSessionService} from '../services/session/collab-session.service';
import {Chat, Group, User} from '../utilities/model/chat';
import {closeDiv, showDiv} from '../common/common';
// import {SessionService} from 'src/app/web-services/session.service';
// import {UserDetail} from 'src/app/interfaces/user';
import {MessageService} from 'primeng/api';
// import {RoomUserService} from 'src/app/web-services/room-user.service';
import {Constant} from 'src/app/util/constant';

// import { AppSettings } from '../../utilities/constants/constants';

@Component({
               selector: 'app-usersCollab',
               templateUrl: './users.component.html',
               styleUrls: ['./users.component.css']
           })
export class UsersCollabComponent implements OnInit {

    @Output() emitUsersClose = new EventEmitter<any>();
    @Output() emitSelectedUser = new EventEmitter<any>();
    @Output() moveToGroupParticipantScreen = new EventEmitter<any>();
    @Output() afterParticipantAdded = new EventEmitter<any>();
    @Output() afterGroupCreated = new EventEmitter<any>();

    public collaborators: any[];
    public chats = [];
    public groupModel;
    public collaboratorModel;
    public chatModel;
    public loggedInUser;
    public chatId;
    public groupId;
    public newChatButtonCliked;
    public errorMessage;
    public searchText: string;
    public addParticipantButton: boolean = false;
    public contextId;
    public username;
    selectedParticipants: Array<string> = [];
    public pageControlMap = new Map<string, boolean>().set('CreateGroup', false);
    userRoles: string[];

    contexutualGroupChat = false;
    // userDetail: UserDetail;

    constructor(private router: Router, private _webService: ChatService,
                // public sessionService: SessionService,
                public sharedService: CollaborationSharedServiceService,
                private collabSession: CollabSessionService,
                private messageService: MessageService,
                // private roomUserService: RoomUserService
    ) {

        this.groupModel = new Group(this.loggedInUser, '', '', this.sharedService.participants);

    }

    //-----------------------------------OnInit---------------------------
    applicationShortName = 'ums';

    ngOnInit() {
        // this.userDetail = this.sessionService.getUserDetails();
        // console.log(this.collabSession.isContexualTab);
        // this.checkApplicationShortNameAndAssign();
        // this.assignUserRolesAndPageControlMap();
        this.addParticipantButton = this.collabSession.isAddParticipantClicked;
        this.newChatButtonCliked = this.collabSession.isNewChatButtonClicked;
        this.loggedInUser = sessionStorage.getItem('loggedInUser');
        this.username = sessionStorage.getItem('firstName');;
        this.groupId = this.collabSession.groupId;
        this.collaboratorModel = new User(this.loggedInUser, '', '', '', 0, 0, 0, '', '');
        this.groupModel = new Group(this.loggedInUser, '', '', this.sharedService.participants);
        this.getAllCollaborators();
        this.contextId = this.collabSession.contextId;
        this.checkVersion();
    }

    currentVersion = '0';

    checkVersion() {
        this.sharedService.CurrentVersion.subscribe(data => {
            this.currentVersion = data;
        });
    }

    changeContexualChat(event) {
        this.collabSession.isContexualTab = event.checked;
        if (event == true) {
            this.getProjectTeam();
        }
    }

    moveToParticipantPage() {
        this.moveToGroupParticipantScreen.emit(true);
    }

    backToUsersComponent() {
        closeDiv('createnewchat-overlaydiv');
    }

    // checkApplicationShortNameAndAssign() {
    //   if (this.globalSharedService.getCookie('applicationShortName')) {
    //     this.applicationShortName = this.globalSharedService.getCookie('applicationShortName')
    //   }
    // }

    // assignUserRolesAndPageControlMap() {
    //   if (this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') && this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles') != undefined) {
    //     this.userRoles = this.globalSharedService.getCookie(this.globalSharedService.getCookie("applicationShortName") + '_roles');
    //     this.pageControlMap = this.globalSharedService.checkRole(this.pageControlMap, this.userRoles);
    //   }
    // }

    backToChatComponent() {
        closeDiv('createnewchat-overlaydiv');
        setTimeout(() => {
            this.emitUsersClose.emit(true);
        }, 5);

    }

    createGroup() {
        showDiv('createnewchat-overlaydiv');
    }

    searchCollaborator = '';

    searchCollaborators() {
        console.log(this.searchCollaborator);
        if (this.collabSession.isContexualTab) {
            this.filteredCollaborators = this.sharedService.filterArray(this.projectTeamCollaborators, this.searchCollaborator);
        } else {
            this.filteredCollaborators = this.sharedService.filterArray(this.filteredCollaborators, this.searchCollaborator);
        }
        if (this.filteredCollaborators.length === 0) {
            if (this.addParticipantButton) {
                // this.toastr.showCustomInfoAlert("No Participant Found");
            } else {
                // this.toastr.showCustomInfoAlert("No Collaborator Found");
            }
        }
    }

    //---------------------Getting All Collaborators-----------------------

    filteredCollaborators = [];

    getAllCollaborators(): void {
        this.collaborators = [];
        this._webService.getCollaborators(this.collaboratorModel).subscribe(
            data => {
                try {
                    this.collaborators = data['data'];
                    // console.log(this.collaborators)
                    for (var i = this.collaborators.length - 1; i >= 0; --i) {
                        if (this.sharedService.alreadyAddedParticipants) {
                            for (var j = this.sharedService.alreadyAddedParticipants.length - 1; j >= 0; --j) {
                                if (this.sharedService.alreadyAddedParticipants[j].participant.userId == this.collaborators[i].userId) {
                                    this.collaborators.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    }
                    this.filteredCollaborators = this.collaborators;
                    this.collaborators = Object.assign([], this.filteredCollaborators);

                    this.getProjectTeam();

                } catch (error) {
                    console.log(error);
                }
            },
            error => {
                console.log(error);
            }
        );
        this.collaboratorModel = new User(this.loggedInUser, '', '', '', 0, 0, 0, '', '');
    }

    //----------Gets Chats if user already Chatted with a person ------------

    getChats(collaborator): void {
        this._webService.getChatbyusers(this.chatModel).subscribe(
            data => {
                try {
                    if (data && data['data']) {
                        this.chats = data['data'];
                        if (this.chats) {
                            this.collabSession.isSingleChat = true;
                            this.collabSession.status = collaborator.status;
                            this.collabSession.username = collaborator.username;
                            this.collabSession.secondUserId = collaborator.userId;
                            this.collabSession.groupId = this.sharedService.ZER0;
                            this.emitSelectedUser.emit(this.chats[0]);
                            for (var i = 0; i < this.chats.length; i++) {
                                if (this.chats[i].hasOwnProperty('chatId')) {
                                    this.chatId = this.chats[0]['chatId'];
                                    this.collabSession.chatId = this.chatId;
                                } else {
                                    this.collabSession.chatId = this.sharedService.ZER0;
                                }
                            }
                        }
                    } else {
                        this.collabSession.isSingleChat = true;
                        this.collabSession.status = collaborator.status;
                        this.collabSession.username = collaborator.username;
                        // this.collabSession.username = collaborator.firstName;
                        this.collabSession.secondUserId = collaborator.userId;
                        this.collabSession.groupId = this.sharedService.ZER0;
                        this.emitSelectedUser.emit(null);

                    }
                } catch (error) {
                    console.log(error);
                }
            },
            error => {
                console.log(error);
            }
        );
        this.collaboratorModel = new User(this.loggedInUser, '', '', '', 0, 0, 0, '', '');
    }

    //-----------------------When Any USer Is Selected ------------------------

    openChat(collaborator): void {
        // console.log("open chat");

        if (this.addParticipantButton) {
            this._webService.insertGroupParticipant(this.groupId, collaborator.userId, this.loggedInUser).subscribe(
                data => {
                    this.errorMessage = data;
                    if (this.errorMessage) {
                        try {
                            if (this.errorMessage['statusCode'] == 200) {
                                var participants = this.errorMessage['data'];
                                this.messageService.add({severity: 'success', summary: 'Add', detail: 'Participent Added Successfully'});
                                var res;
                                var currentTime = new Date();
                                if (this.collabSession.contextTitle != '') {
                                    var contextTitle = this.collabSession.contextTitle;
                                } else {
                                    contextTitle = '';
                                }
                                var JSONObject = this.sharedService.makeJson(
                                    this.username, 'GroupParticipantAdded', contextTitle, 'when New Participant Added in a group',
                                    currentTime, collaborator.userId
                                );
                                this._webService.registerEvent(
                                    this.collabSession.contextId, this.sharedService.applicationId, this.loggedInUser, participants,
                                    this.sharedService.groupParticipantAdded, JSONObject
                                ).subscribe(data => {
                                    res = data;
                                });
                            }
                        } catch (error) {
                            // console.log(error);
                        }
                    } else {
                        // this.toastr.showCustomInfoAlert("Participant Already Exist");
                        this.router.navigate([{outlets: {chat: ['message']}}], {skipLocationChange: true});
                    }
                    ;
                },
                error => {
                    console.log(error);
                }
            );
            this.collabSession.isAddParticipantClicked = false;

            this.afterParticipantAdded.emit();
        } else {
            if (this.contexutualGroupChat) {
                var reference_id = this.collabSession.contextId;
                this.chatModel = new Chat(0, '', reference_id, '', this.loggedInUser, collaborator.userId, 0, 0, 0, 0);
            } else {
                this.chatModel = new Chat(0, '', 0, '', this.loggedInUser, collaborator.userId, 0, 0, 0, 0);
            }
            this.getChats(collaborator);
            this.collabSession.chatId = this.sharedService.ZER0;
        }
    }

    //-----------------When Pop Up New Chat Button is Clicked------------------

    newChatButtonClicked(): void {
        this.collabSession.isNewChatButtonClicked = true;
        this.searchCollaborator = '';
        this.searchCollaborators();
    }

    //------------------When New Group Chat Button is Clicked------------------

    newGroupChatButtonClicked(): void {
        this.collabSession.isNewChatButtonClicked = false;
        var index = this.sharedService.participants.findIndex(x => x == sessionStorage.getItem('loggedInUser'));
        if (index === -1) {
            this.sharedService.participants.push(sessionStorage.getItem('loggedInUser'));
        }
        this.searchCollaborator = '';
        this.searchCollaborators();
    }

    //-----------Check and Uncheck of Check Boxes In Group Chat Tab ------------

    onOptionSelected(collaborator, index): void {
        try {
            // console.log("adding collabrator");

            let optionValue = collaborator.userId;
            let indexToDelete = undefined;
            this.sharedService.participants.forEach((collaborator, currIndex) => {
                if (collaborator === optionValue) {
                    indexToDelete = currIndex;
                }
            });

            if (indexToDelete !== undefined) {
                // this.toastr.showCustomInfoAlert("user already added");
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'User Added'});

            }
            if (indexToDelete == undefined) {
                this.sharedService.participants.push(collaborator.userId);
                this.selectedParticipants.push(collaborator);
            }
        } catch (error) {
            console.log(error);
        }
    }

    onOptionDeleted(collaborator) {
        try {
            let optionValue = collaborator;
            let indexToDelete = undefined;
            let indexToDeletefromParticipant = undefined;
            this.sharedService.participants.forEach((collaborator, currIndex) => {
                if (collaborator === optionValue.userId) {
                    indexToDelete = currIndex;
                }
            });
            if (indexToDelete !== undefined) {
                this.sharedService.participants.splice(indexToDelete, 1);
            }
            this.selectedParticipants.forEach((coll, index) => {
                if (coll === optionValue) {
                    indexToDeletefromParticipant = index;
                }
            });

            if (indexToDeletefromParticipant !== undefined) {
                this.selectedParticipants.splice(indexToDeletefromParticipant, 1);
            }
        } catch (error) {
        }
    }

    //------------------Inserting Values In Group Model--------------------------

    groupModelValues(): void {
        // console.log("grouping");

        //------------------INserting in contexual Chat
        if (this.contexutualGroupChat) {
            var contextId = this.collabSession.contextId;
            // console.log("context id is ::" + contextId)
            this._webService.InsertGroup(this.groupModel, contextId).subscribe(
                data => {
                    try {
                        if (data && data['statusCode'] == 200) {
                            closeDiv('createnewchat-overlaydiv');
                            setTimeout(() => {
                                this.afterGroupCreated.emit(true);
                            }, 5);
                            var res;
                            var currentTime = new Date();
                            if (this.collabSession.contextTitle != '') {
                                var contextTitle = this.collabSession.contextTitle;
                            } else {
                                contextTitle = '';
                            }
                            var JSONObject = this.sharedService.makeJson(
                                this.username, 'ContexualGroupCreated', contextTitle, 'when New Group is created in contexual Chat',
                                currentTime, 0
                            );
                            this._webService.registerEvent(
                                this.collabSession.contextId, this.sharedService.applicationId, this.loggedInUser,
                                this.sharedService.participants, this.sharedService.contextualGroupCreated, JSONObject
                            ).subscribe(data => {
                                res = data;
                            });

                            this.sharedService.participants = [this.loggedInUser];
                            this.collabSession.isNewChatButtonClicked = true;
                        } else {
                            this.sharedService.participants = [this.loggedInUser];
                            this.collabSession.isNewChatButtonClicked = true;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    if (data['data']) {
                        // this.toastr.showSuccess('Create', 'Group');
                    }
                },
                error => {
                    console.log(error);
                }
            );
        }
        //--------------Inserting in General Chat--------------
        else {
            var contextId = 0;
            this._webService.InsertGroup(this.groupModel, contextId).subscribe(
                data => {
                    try {
                        if (data && data['statusCode'] == 200) {
                            closeDiv('createnewchat-overlaydiv');
                            setTimeout(() => {
                                this.afterGroupCreated.emit(true);
                            }, 5);
                            var res;
                            var currentTime = new Date();
                            if (this.collabSession.contextTitle != '') {
                                var contextTitle = this.collabSession.contextTitle;
                            } else {
                                contextTitle = '';
                            }
                            var JSONObject = this.sharedService.makeJson(
                                this.username, 'newGroupCreated', contextTitle, 'when New Group is Created', currentTime, 0);
                            this._webService.registerEvent(
                                this.collabSession.contextId, this.sharedService.applicationId, this.loggedInUser,
                                this.sharedService.participants, this.sharedService.groupCreated, JSONObject
                            ).subscribe(data => {
                                res = data;
                            });

                            this.sharedService.participants = [this.loggedInUser];
                            this.collabSession.isNewChatButtonClicked = true;
                            var chatModel = new Chat(0, '', 0, '', 0, 0, 0, 0, this.loggedInUser, 0); //  Initializing the Chat Model
                        } else {
                            this.sharedService.participants = [this.loggedInUser];
                            this.collabSession.isNewChatButtonClicked = true;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    if (data['data']) {
                        // this.toastr.showSuccess('Create', 'Group');
                    }
                },
                error => {
                    console.log(error);
                }
            );
        }
    }

    projectTeam = [];
    projectTeamCollaborators = [];
    users = [];

    getProjectTeam() {

        this.projectTeam = [];
        // let user = {'userId': sessionStorage.getItem('loggedInUser'), 'status': Constant.STATUS_ACTIVE, 'roomId': 4};
        let user = {'userId': sessionStorage.getItem('loggedInUser')};
        // this.roomUserService.findAllRoomUser(user,null,null).subscribe(res => {
        //   console.log(res);

        //   if(res['message'] == Constant.STATUS_SUCCESS){
        //     console.log(res['data']);

        //   }
        // }
        // )
        // this.roomUserService.findAllRoomUser(user, null, null).subscribe(
        //     data => {
        //         try {
        //             if (data['statusCode'] == 200) {
        //                 this.projectTeam = data['data'];
        //
        //                 let result2 = this.projectTeam.reduce((arr, item) => {
        //                     let exists = !!arr.find(x => x.memberId.userId === item.memberId.userId);
        //                     if (!exists) {
        //                         arr.push(item);
        //                     }
        //                     return arr;
        //                 }, []);
        //
        //                 // console.log("result2 :: " ,result2);
        //                 // console.log()
        //
        //                 for (var i = 0; i < this.filteredCollaborators.length; i++) {
        //                     for (var j = 0; j < this.projectTeam.length; j++) {
        //                         if (this.filteredCollaborators[i].userId == this.projectTeam[j].memberId.userId) {
        //                             this.projectTeamCollaborators.push(this.filteredCollaborators[i]);
        //                             break;
        //                         }
        //                     }
        //                 }
        //                 this.filteredCollaborators = this.projectTeamCollaborators;
        //                 // console.log(this.filteredCollaborators)
        //             }
        //
        //         } catch (error) {
        //             console.log(error);
        //         }
        //     },
        //     error => {
        //         console.log(error);
        //     }
        // );

    }

    verifyLabel(label) {
        return label;
    }
}