import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {ContactsService} from '../web-services/contacts.service';
import {UsersService} from '../web-services/users.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';
import { ValidationService } from '../services/validation.service';

// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

declare var openSearchOverlaydiv: Function;


@Component({
    selector: 'app-contacts', 
    templateUrl: './contacts.component.html', 
    styleUrls: ['./contacts.component.css'],
    providers: [DialogService]
})
export class ContactsComponent implements OnInit {
    public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateContactsId = null;
    usersList: any = [];
    contactsList: any = [];
    contactsSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    contactsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;

    addEditTitle = 'Add';

    referenceId : any = 0;
    ref: DynamicDialogRef;

    constructor(
        private contactsServices: ContactsService, 
        private usersServices: UsersService, 
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService, 
        private route: Router,
        private loader: NgxUiLoaderService, 
        private constantService: ConstantService, 
        private sharedService: SharedService,
        private dialogService: DialogService,
        private _validation : ValidationService
    ) {
    };

    get addUpdateTitle() {
        return this.updateContactsId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.contactsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countContacts();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    countContacts() {
        this.contactsSearch.controls['referenceId'].setValue(this.referenceId);
        this.contactsServices.countContacts(this.contactsSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllContacts();
            } else {
                this.totalRecords = 0;
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {

        this.contactsForm.markAllAsTouched();
        console.log("contacts form :: ",this.contactsForm.value);
        // this.contactsForm.controls['referenceId'].setValue();
        if(this.contactsForm.valid){
            this.addUpdateContacts(this.contactsForm.value);
            this.submitted = false;
            this.onClearAdd();
        }
        
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllContacts();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.contactsSearch.reset();
        this.countContacts();
        // this.findAllContacts();
    }

    addUpdateContacts(contacts) {
        this.startLoader();
        this.contactsForm.controls['referenceId'].setValue(this.referenceId);
        if (this.updateContactsId == null) {
            this.contactsServices.addContacts(this.contactsForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    contacts = res['data'][0];
                    this.contactsList.push(contacts);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameContacts) +
                                this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameContacts)
                        });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countContacts();
                } else {
                    console.error(res['documentation']);
                    this.startLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            contacts.contactsId = this.updateContactsId;
            this.contactsServices.updateContacts(contacts).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.contactsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameContacts) +
                                this.translate(this.labels.updatedSuccessfully),
                            // detail: this.translate(this.labels.entityNameContacts)
                        });

                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.refresh();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateContactsId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
        // this.stopLoader();
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    ngOnInit() {
        this.referenceId = parseInt(sessionStorage.getItem('loggedInRefId'));
        console.log("ref id :: ",this.referenceId);
        this.findAllUsers();
        this.contactsForm = new FormGroup({
                                              contactsFirstname: new FormControl('', [Validators.required, validateSpaces]),
                                              contactsLastname: new FormControl('', [Validators.required, validateSpaces]),
                                              contactsOccupation: new FormControl('', [Validators.required, validateSpaces]),
                                              contactsCompany: new FormControl('', [Validators.required, validateSpaces]),
                                              contactsEmail: new FormControl('', [Validators.required, validateSpaces,Validators.pattern(this._validation.regex.email)]),
                                              contactsPassword: new FormControl(''),
                                              contactsAddress: new FormControl('', [Validators.required, validateSpaces]),
                                              userId: new FormControl(),
                                              referenceId : new FormControl(this.referenceId),
                                          });

        this.contactsSearch = new FormGroup({
                                                contactsFirstname: new FormControl(),
                                                contactsLastname: new FormControl(),
                                                contactsOccupation: new FormControl(),
                                                contactsCompany: new FormControl(),
                                                contactsEmail: new FormControl(),
                                                contactsPassword: new FormControl(),
                                                contactsAddress: new FormControl(),
                                                userId: new FormControl(),
                                                referenceId : new FormControl(this.referenceId),
                                            });
        this.countContacts();
        this.findAllContacts();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.contactsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    findAllContacts() {
        this.startLoader();
        this.contactsServices.findAllContacts(this.contactsSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.contactsList = res['data'];
                } else {
                    this.contactsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    deleteByIdContacts(contactsId: number, index) {
      this.confirmation.confirm({
                                    message: this.translate(this.labels.DELETE_MSG),
                                    accept: () => {
                                    this.startLoader();
                                    this.contactsServices.deleteByIdContacts(contactsId).subscribe(res => {
                                    if (res['message'] == 'Success') {
                                        this.contactsList.splice(index, 1);
                                        this.messageService.add({
                                            severity: 'warn',
                                                                    summary: this.translate(this.labels.entityNameContacts) +
                                                                        this.translate(this.labels.deletedSuccessfully),
                                                                    detail: ''
                                            // detail: this.translate(this.labels.entityNameContacts)
                                         });
                                        this.refresh();
                                        } else {
                                                console.error('error: ', res['documentation']);
                                                this.stopLoader();
                                            }
                                    }, err => {
                                        console.error('err: ', err);
                                        this.stopLoader();
                                    });
                                }, key: "contactsDialog"
                                                       });
    }

    loadContactsToEdit(contactsId: number, index) {
        this.addEditTitle = 'Edit';
        let contacts = this.contactsList.find(x => x['contactsId'] == contactsId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateContactsId = contacts['contactsId'];
        this.selectedIndex = index;

        if(contacts['contactsFirstname']){
            this.contactsForm.controls['contactsFirstname'].setValue(contacts['contactsFirstname']);
        }
        if(contacts['contactsLastname']){
            this.contactsForm.controls['contactsLastname'].setValue(contacts['contactsLastname']);
        }
        if(contacts['contactsOccupation']){
            this.contactsForm.controls['contactsOccupation'].setValue(contacts['contactsOccupation']);
        }
        if(contacts['contactsCompany']){
            this.contactsForm.controls['contactsCompany'].setValue(contacts['contactsCompany']);
        }
        if(contacts['contactsEmail']){
            this.contactsForm.controls['contactsEmail'].setValue(contacts['contactsEmail']);
        }
        if(contacts['contactsPassword']){
            this.contactsForm.controls['contactsPassword'].setValue(contacts['contactsPassword']);
        }
        if(contacts['contactsAddress']){
            this.contactsForm.controls['contactsAddress'].setValue(contacts['contactsAddress']);
        }
        if(contacts['userId']){
            this.contactsForm.controls['userId'].setValue(contacts['userId']);
        }
        
        // openAddOverlaydiv();
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }

    }

    onClearAdd() {
        this.contactsForm.reset();
        this.contactsForm.markAsUntouched();
        this.updateContactsId = null;
        // closeAddOverlaydiv();
    }

    findAllUsers() {
        let temp = [];
        this.startLoader();
        this.usersServices.findAllUsers(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['userId'];
                        dropDown.label = object['userName'];
                        this.usersList.push(dropDown);
                    }
                } else {
                    this.usersList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.contactsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.contactsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.contactsForm.get(control)?.valid && this.contactsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        
        // closeAddOverlaydiv();
        this.contactsForm.reset(); 
        this.updateContactsId = null;
    }

    onCloseSearch() {
        // closeSearchOverlaydiv();
        this.contactsSearch.reset();
    }

    refresh() {
        this.countContacts();
    }

    onAddOverlayDiv() {
        this.contactsForm.reset();
        // openAddOverlaydiv();
       
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue){
        console.log("global search clicked ",eventValue);
        this.searchTerm = eventValue;
        this.contactsGlCount();
    }

    contactsGlCount(){
        this.contactsServices.contactsGlcount(this.searchTerm,this.referenceId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchContacts();
            } else {
                this.totalRecords = 0;
                this.contactsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchContacts(){
        this.contactsServices.findGlobalContacts(this.searchTerm,this.referenceId, this.pageNo, this.itemPerPage).subscribe(result =>{
            if(result['statusCode'] == 200){
                this.contactsList = result['data'];
            }else{
                this.contactsList = [];
            }
            console.log(result,"result");
        });
    }

    viewContactDetail(contactId){
        let contacts = this.contactsList.find(x => x['contactsId'] == contactId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Contact Detail',
            width: '90%',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {
                mainData: [
                    {label: this.labels.contactsColumnContactsFirstname, value: contacts['contactsFirstname']},
                    {label: this.labels.contactsColumnContactsLastname, value: contacts['contactsLastname']},
                    {label: this.labels.contactsColumnContactsOccupation, value: contacts['contactsOccupation']},
                    {label: this.labels.contactsColumnContactsCompany, value: contacts['contactsCompany']},
                    {label: this.labels.contactsColumnContactsEmail, value: contacts['contactsEmail']},
                    {label: this.labels.contactsColumnContactsAddress, value: contacts['contactsAddress']},
                ]
            }
        });
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.contactsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
    }
}
