import {FormGroup} from "@angular/forms";

export class FollowUpFormData {

  public appointmentId: number;
  public consent: number;
  public percentageImprovement: number;
  public isSoap: boolean;
  public soapText: string;


  constructor(appointmentId: number, formData: any) {

    this.appointmentId = appointmentId;
    this.addFormDataToDTO(formData, this);

  }

  addFormDataToDTO(formData: any, currentClass: any): void {

    for (let key in formData.controls) {
      console.log(key);
      console.log(formData.controls[key].value);

      currentClass[key] =  formData.controls[key].value;
    }

  }


}
