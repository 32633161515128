<!-- Header Component-->

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<ngx-ui-loader id="loadId"></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <!-- <section class="sectionheading" id="sectionmain-invitationpage-sectionheading"> -->
        <!-- <h1 class="h1-style1">{{translate(labels.entityNameInvoiceItemsDetail)}}</h1> -->
        <!-- </section> -->
        <!-- <hr class="hrseprator"> -->


        <div class="readonlyContainer" id="PatientsviewDetailspopup-readonlyContainer1a">
            <div class="p-fluid p-formgrid p-grid p-mb-5" id="PatientsviewDetailspopup-formgrid1a">
                <div class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">Issue To</div>
                    <div class="readOnlyValue">{{invoiceObj.issueTo.agencyFirstname}}</div>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">Invoice Date</div>
                    <div class="readOnlyValue">{{invoiceObj.issueDate | date: 'yyyy-MM-dd'}}</div>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="readOnlyTitle">Amount</div>
                    <div class="readOnlyValue">{{InvoiceAmount}}</div>
                </div>
            </div>

        </div>


        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameInvoiceItemsDetail)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameInvoiceItemsDetail)}}"
                                        tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" >
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameInvoiceItemsDetail)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="invoiceItemsDetailSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnItemId)}} </label>
                                            <p-dropdown [filter]="true" [options]="itemsList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="itemId"
                                                        placeholder="Select">
                                            </p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnQuantity)}}</label>
                                            <p-inputNumber class="p-invalid pngInputnumber"
                                                           formControlName="quantity"
                                                           [useGrouping]="true"
                                                           name="quantity">
                                            </p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnUnitPrice)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="unitPrice" mode="decimal"
                                                           name="unitPrice" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnDiscount)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="discount" mode="decimal"
                                                           name="discount" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnTaxAmount)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="taxAmount" mode="decimal"
                                                           name="taxAmount" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnTotalAmount)}}</label>
                                            <p-inputNumber [useGrouping]="false" class="p-invalid pngInputnumber"
                                                           formControlName="totalAmount" mode="decimal"
                                                           name="totalAmount" pNumberInput
                                                           type="number"></p-inputNumber>
                                        </div>
                                        
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" >
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameInvoiceItemsDetail)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="invoiceItemsDetailForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnItemId)}}</label>
                                            <p-dropdown [filter]="true" [options]="itemsList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="itemId"
                                                        placeholder="Select">
                                            </p-dropdown>
                                            <app-custom-error [control]="'itemId'" [form]="invoiceItemsDetailForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnQuantity)}}</label>
                                            <p-inputNumber
                                                    (focusout)="calculateAmount()"
                                                    [class.p-invalid]="inputFieldDanger('quantity')"
                                                    [useGrouping]="true" class="p-invalid pngInputnumber"
                                                    formControlName="quantity"
                                                    name="quantity"
                                                    pNumberInput
                                                    type="number">
                                            </p-inputNumber>
                                            <app-custom-error [control]="'quantity'" [form]="invoiceItemsDetailForm"></app-custom-error>
                                            <span *ngIf="invalidQuantity" class="duplicate-Email">
                                                (Value Range : 0 - 2147483647)
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnUnitPrice)}}</label>
                                            <p-inputNumber
                                                    (focusout)="calculateAmount()"
                                                    [class.p-invalid]="inputFieldDanger('unitPrice')"
                                                    [useGrouping]="true" class="p-invalid pngInputnumber"
                                                    formControlName="unitPrice"
                                                    name="unitPrice"
                                                    pNumberInput
                                                    type="number">
                                            </p-inputNumber>
                                            <app-custom-error [control]="'unitPrice'" [form]="invoiceItemsDetailForm"></app-custom-error>
                                            <span *ngIf="invalidUnitPrice" class="duplicate-Email">
                                                  (Value Range : 0 - 2147483647)
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnDiscount)}}</label>
                                            <p-inputNumber
                                                    (focusout)="calculateAmount()"
                                                    [class.p-invalid]="inputFieldDanger('discount')"
                                                    [useGrouping]="true" class="p-invalid pngInputnumber"
                                                    formControlName="discount"
                                                    name="discount"
                                                    pNumberInput
                                                    type="number">
                                            </p-inputNumber>
                                            <app-custom-error [control]="'discount'" [form]="invoiceItemsDetailForm"></app-custom-error>
                                            <span *ngIf="invalidDiscount" class="duplicate-Email">
                                                Discount amount should not be greater than Total amount
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnTaxAmount)}}</label>
                                            <p-inputNumber
                                                    (focusout)="calculateAmount()"
                                                    [class.p-invalid]="inputFieldDanger('taxAmount')"
                                                    [useGrouping]="true" class="p-invalid pngInputnumber"
                                                    formControlName="taxAmount"
                                                    name="taxAmount"
                                                    pNumberInput
                                                    type="number">
                                            </p-inputNumber>
                                            <app-custom-error [control]="'taxAmount'" [form]="invoiceItemsDetailForm"></app-custom-error>
                                            <span *ngIf="invalidTaxAmount" class="duplicate-Email">
                                                  (Value Range : 0 - 2147483647)
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnTotalAmount)}}</label>
                                            <p-inputNumber [class.p-invalid]="inputFieldDanger('totalAmount')"
                                                           [useGrouping]="true"
                                                           class="p-invalid pngInputnumber"
                                                           formControlName="totalAmount"
                                                           name="totalAmount"
                                                           [disabled]="true"
                                                           type="number">
                                            </p-inputNumber>
<!--                                            <app-custom-error [control]="'totalAmount'" [form]="invoiceItemsDetailForm"></app-custom-error>-->
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.invoiceItemsDetailColumnAdditionalNotes)}}</label>
                                            <span class="p-input-icon-right">
                                                <i  (click)="fieldReset('additionalNotes')"
                                                    *ngIf="diplayeIconInField('additionalNotes')"
                                                    class="pi pi-times color-danger">
                                                </i>
                                                <input  [class.p-invalid]="inputFieldDanger('additionalNotes')"
                                                        class="pngInputtext"
                                                        formControlName="additionalNotes"
                                                        name="additionalNotes"
                                                        pInputText
                                                        type="text">
                                            </span>
                                            <app-custom-error [control]="'additionalNotes'" [form]="invoiceItemsDetailForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                  type="submit"
                                                  styleClass="p-button"
                                                  class="pngBlueBtn"></p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span class="p-input-icon-right globalSearch"
                                          id="invitationpage-memberslist-globalsearch">
                                        <i class="pi pi-search"
                                           (click)="onGlobalSearchClick(global.value)">
                                        </i>
                                    <input #global
                                           class="pngInputtext"
                                           pInputText
                                           placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                           type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="totalRecords >0"
                                         (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true"
                                         [paginator]="true"
                                         [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [alwaysShowPaginator]="false"
                                         [value]="invoiceItemsDetailList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': invoiceItemsDetailList?.length == 0}"
                                         id="invitationpage-memberslist-tableee"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <!--                                            <th> InvoiceId</th>-->
                                            <th> Item</th>
                                            <th> Quantity</th>
                                            <th> Unit Price</th>
                                            <th> Discount</th>
                                            <th> Tax Amount</th>
                                            <th> Total Amount</th>
                                            <!--                                            <th> AdditionalNotes</th>-->
                                            <!--                                            <th> UserId</th>-->
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <!--                                            <td> {{element?.invoiceId.invoiceId}} </td>-->
                                            <td> {{element?.itemId?.label}} </td>
                                            <td> {{element?.quantity}} </td>
                                            <td> {{element?.unitPrice}} </td>
                                            <td> {{element?.discount}} </td>
                                            <td> {{element?.taxAmount}} </td>
                                            <td> {{element?.totalAmount}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadInvoiceItemsDetailToEdit(element.invoiceItemsDetailId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdInvoiceItemsDetail(element.invoiceItemsDetailId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                              (click)="viewInvoiceDetail(element.invoiceItemsDetailId)"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
