import { Component, OnInit } from '@angular/core';
import {
  MenuItem
} from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientsService } from '../web-services/patients.service';
import { SessionService } from '../services/session.service';
import { ExpertService } from '../web-services/expert.service';
import { Utils } from '../util/utils';
import { Router } from '@angular/router';
import {FlagsService} from "../web-services/flags.service";
import {CaseService} from "../web-services/case.service";
import {InitialAssessmentSteps} from "../initial-assessment-steps/InitialAssessmentSteps";
import {BodyChartDetailService} from "../web-services/body-chart-detail.service";


@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css']
})
export class PatientDetailsComponent implements OnInit {


  //temporary
  // btnStatus: any;
  options;
  innerWidth: any;
  barData: any;
  responsiveOptions: any;
  allData: any;
  // temp end
  utils = new Utils();
  btnStatus: any;
  stepsItems: any;
  activeStep = 0;
  stepper = true;

  patientObj: any;
  expertObj: any;
  patientFlags: any = [];
  patientCasesList: any = [];
  patientBodyChartDetailList: any = [];
  diagnosisTabVisible: boolean;
  bodyChartTabVisible: boolean;
  initialTabVisible: boolean;
  patientCases: any = [];
  caseReviewData: any;
  initialAssessmentWidth: any;
  initialAssessmentWidthLeft: any;
  bodyChartDetailsWidth: any;
  bodyChartDetailsWidthLeft: any;


  constructor(
    public loader: NgxUiLoaderService,
    private patientsServices: PatientsService,
    private expertsServices: ExpertService,
    private sessionService: SessionService,
    private flagsService: FlagsService,
    private caseService: CaseService,
    private router: Router
  ) { };

  ngOnInit(): void {



    this.barData = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      datasets: [
        {
          label: 'Number',
          backgroundColor: '#009AFF',
          data: [65, 59, 80, 81, 56]
        },
        {
          label: 'Average',
          backgroundColor: '#43D5CB',
          data: [28, 48, 40, 19, 86]
        }
      ]
    };
    this.options = {
      aspectRatio: 3,
      // maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            fontColor: '#a1a1a1'
          }
        }],

      },
      legend: {
        position: 'top',
        align: 'end'
      }
    };

    this.allData = [
      {
        name: '24h Symptoms',
        list_item: [
          {
            item: 'Burning'
          },
          {
            item: 'Stabbing'
          }
        ]
      },
      {
        name: '24h Symptoms',
        list_item: [
          {
            item: 'Burning'
          },
          {
            item: 'Stabbing'
          }
        ]
      },
      {
        name: '24h Symptoms',
        list_item: [
          {
            item: 'Burning'
          },
          {
            item: 'Stabbing'
          }
        ]
      },
    ];


    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.options.aspectRatio = 0;
      this.options.legend.align = 'center';
    }
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];

    this.findAllCasesByPatientId();
    this.findBodyChartDetailByPatientId();

    this.findPatientsById();
    this.findBodyRedFlagsByPatientId();

    this.btnStatus = 'Active';
  }

  selectCase($event: any): void {

    console.log($event.value);

    this.sessionService.setSession('caseId',$event.value);
    this.router.navigate(['history']);
  }

  findAllCasesByPatientId(): void
  {
    this.patientsServices.findAllCasesByPatientId(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {

      if (res.status === 200) {

        this.patientCases = res.body;


        this.initialAssessmentWidth =  this.patientCases.length * 160 + 'px';
        this.initialAssessmentWidthLeft = '-' + this.patientCases.length * 130 + 'px';
        /* now get latest case and get the full info for that case to show on the patient details page */

        this.caseService.getReviewByCaseId(this.patientCases[0].caseId).subscribe(resReview => {

          alert("resReview.body" + resReview.status);
          if (resReview.status === 200) {

            alert("resReview.body");
            alert(JSON.stringify(resReview.body));

            this.caseReviewData = resReview.body;
            console.log(this.caseReviewData);

          }

        });
      }
    });

  }
  findPatientsById() {
    this.startLoader();
    this.patientsServices.findPatientsById(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {
      if (res['message'] == 'Success') {
        this.patientObj = res['data'][0];
        this.sessionService.setSession('patientObj', JSON.stringify(this.patientObj));

        console.log(this.patientObj, 'patient object');
      } else {
        console.error('error: ', res['documentation']);
      }
      this.stopLoader();
    }, err => {
      console.error('err: ', err);
      this.stopLoader();
    });
  }



  findBodyChartDetailByPatientId() {

    this.patientsServices.findBodyChartMasterByPatientId(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {



        if (res.status === 200) {


          console.log(res.body);

          this.patientBodyChartDetailList = res.body;

          this.bodyChartDetailsWidthLeft = '-' + (this.patientBodyChartDetailList.length * 130) +   'px';
          this.bodyChartDetailsWidth =  this.patientBodyChartDetailList.length * 100 +   'px';
        }
    });


  }

  findBodyRedFlagsByPatientId() {

    this.patientsServices.findBodyRedFlagsByPatientId(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {

      if (res.status === 200) {

        console.log(res.body);

        this.patientFlags = res.body;
      }
    });


  }
  createCase() {

   // alert('creating case');

    this.caseService.addCaseForPatient(parseInt(this.sessionService.getSession('patientId')),parseInt(this.sessionService.getSession('loggedInUser'))).subscribe(res => {
  //    alert('got response case');
      console.log(res);
      if (res['message'] == 'Success') {

    //    alert('case created ' + res['data'][0].caseId);

        this.sessionService.setSession('caseId',res['data'][0].caseId);
        this.router.navigate(['history']);

      } else {
        console.error('error: ', res['documentation']);
      }

    },err => {
      console.error('err: ', err);
      this.stopLoader();
    });

  }

  startLoader() {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 3000);
  }

  stopLoader() {
    this.loader.stop();
  }
  urlChanged(number) {

    let url = this.stepsItems.find(step => step.number === number).route;
    this.router.navigate([url]);
  }

  sideTabToggle(tab: string) {

    if (!tab) {
      this.diagnosisTabVisible = !this.diagnosisTabVisible;

    }

    if (tab === 'bodyCharts') {
      this.bodyChartTabVisible = !this.bodyChartTabVisible;

    }

    if (tab === 'initial') {
      this.initialTabVisible = !this.initialTabVisible;
    }

   /// this.diagnosisTabInvisible = !this.diagnosisTabVisible;
  }


}

