import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
                providedIn: 'root'
            })

export class CustomValidators {

    constructor() {
    }

    static isContainSpace(controls: AbstractControl) {
        return {cannotContainSpace: /^\s+|\s+$/g.test(controls.value)};
    }

    static isPhoneNoValid(controls: AbstractControl): ValidationErrors {
        return {returnPhoneTest: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(controls.value)};
    }

    static validate(control: AbstractControl): Observable<ValidationErrors | null> {
        // console.log("control ", control.value);
        // console.log("control touched ", control.touched);
        let isValidated = new BehaviorSubject<{ isRequired: boolean }>(null);
        if (control.touched) {
            if (control.value == undefined || control.value == null || control.value == '') {
                isValidated.next({isRequired: true});
                return isValidated;
            }
        }

        return null;
    }

    static validateSpaces(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let isValidated = new BehaviorSubject<{ validSpace: boolean }>(null);

            if (control.value == undefined || control.value == null || control.value == '') {
                isValidated.next(null);
            } else {
                if (control.value.startsWith(' ') || control.value.endsWith(' ')) {
                    isValidated.next({validSpace: true});
                }
            }
            return isValidated;
        };
    }

    ValidateUrl(control: AbstractControl) {
        if (!control.value.startsWith('https') || !control.value.endsWith('.io')) {
            return {validUrl: true};
        }
        return null;
    }


}


// export function ValidateUrl(control: AbstractControl) {
//   if (!control.value.startsWith('https') || !control.value.includes('.io')) {
//     return { validUrl: true };
//   }
//   return null;
// }


// export function validateSpaces(control: AbstractControl) {
//   // console.log("control ", control.value);
//   if (control.value == undefined || control.value == null || control.value == "") {
//     return null;
//   }
//   if (control.value.startsWith(' ') || control.value.endsWith(' ')) {
//     control.setErrors({ validSpace: true });
//   }
//   return control;
// }


export function validateSpaces(control: AbstractControl) {
    // console.log("control ", control.value);
    if (control.value == undefined || control.value == null || control.value == '') {
        return null;
    }
    // if contains spaces
    if (control.value.startsWith(' ') || control.value.endsWith(' ')) {
        // console.log("contains space");
        return {validSpace: true};
    }
    return null;
}


export function validateRequired(control: AbstractControl): Observable<ValidationErrors | null> {
    // console.log("control ", control.value);
    // console.log("control touched ", control.touched);
    let isValidated = new BehaviorSubject<{ isRequired: boolean }>(null);
    if (control.touched) {
        if (control.value == undefined || control.value == null || control.value == '') {
            isValidated.next({isRequired: true});
            return isValidated;
        }
    }

    return null;
}


export function validatePhone(control: AbstractControl): ValidationErrors {
    if (control.value == undefined || control.value == null || control.value == '') {
        return null;
    }
    // if phone is invalid
    if (!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(control.value))) {
        return {validPhone: true};
    }
    return null;
}


export function validateCodeExist(control: AbstractControl): Observable<ValidationErrors | null> {
    // console.log("control ", control.value);
    // console.log("control touched ", control.touched);
    let isValidated = new BehaviorSubject<{ isCodeValidated: boolean }>(null);
    if (control.touched) {
        if (!(control.value == undefined || control.value == null || control.value == '')) {
            let searchGroup = {code: this.courseService.courseCode};
            this.courseService.getCourseList(searchGroup).subscribe(data => {
                if (data['statusCode'] == 200) {
                    isValidated.next({isCodeValidated: true});
                }
                if (data['statusCode'] != 200) {
                    return null;
                }
            });
        }
    }

    return null;
}


// validate(control: AbstractControl): Observable<ValidationErrors | null> {
//   return of( this.validateRequiredFields(control));
// }


// export function validateRequired (control: AbstractControl) {
//   console.log("control ", control.value);
//   console.log("control touched ", control.touched);

//   if(control.touched){
//     if (control.value == undefined || control.value == null || control.value == "" ) {
//       return { isRequired: true };
//     }
//   }

//   return null;
// }


// private isDialogTrue = new BehaviorSubject<boolean>(false);

// public setIsDialogTrue(value) {
//   this.isDialogTrue.next(value);
// }

// get IsDialogTrue() {
//   return this.isDialogTrue.asObservable();
// }

// openDeleteConfirmationDialog() {
//   const dialogRef = this.conformationDialog.open(DeleteConfirmationDialogueComponent, {
//     width: '750px',
//     data: 'Are you sure?'
//   });

//   dialogRef.afterClosed().subscribe(result => {
//     console.log(result);
//     return result;
//   })
// }


//   validateClinicalCodeExist():ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {

//     let isValidated = new BehaviorSubject<{isRequired:boolean}>(null);


//       if (!(control.value == undefined || control.value == null || control.value == "")) {
//         let searchGroup = { code: control.value }

//         this.clinicalSiteService.getClinicalSiteList(searchGroup).subscribe(data => {
//           if (data['statusCode'] == 200) {
//             control.setErrors({ isCodeValidated: true });
//           }
//         })

//       }
//       return control;
//   }
// }


// validateCodeExist():ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//       if (!(control.value == undefined || control.value == null || control.value == "")) {
//         let searchGroup = { code: control.value }
//         this.courseService.getCourseList(searchGroup).subscribe(data => {
//           if (data['statusCode'] == 200) {
//             control.setErrors({ isCodeValidated: true });
//           }
//           if (data['statusCode'] != 200) {
//             control.setErrors(null);
//           }
//         })
//       }
//       return control;
//   }
// }


// TEsting
// validateClinicalCodeExist():ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     // let isValidated = new BehaviorSubject<{isCodeValidated:boolean}>(null);

//     if (control.value == undefined || control.value == null || control.value == "") {
//       return null;
//     }

//     if (control.hasError("required") || control.hasError("validSpace")) {
//       return null;
//     }

//     if (control.value.startsWith(' ') || control.value.endsWith(' ')) {
//       control.setErrors({ validSpace: true });
//     }

//       let searchGroup = { code: control.value }
//     this.clinicalSiteService.getClinicalSiteList(searchGroup).toPromise().then(data => {
//       if (data['statusCode'] == 200) {
//         control.setErrors({ isCodeValidated: true });
//         // isValidated.next({ isCodeValidated: true });
//       } else {
//         // isValidated.next(null);
//         control.setErrors(null);
//       }
//     })


//   }
// }
