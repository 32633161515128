import {Component, OnInit, ViewChild,AfterViewInit} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import { trigger, state, style, transition, animate } from '@angular/animations';
declare var $: any;
import * as Moment from 'moment';
import * as MomentRange from 'moment-range';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {AppointmentsService} from '../web-services/appointments.service';
import {AppointmentTypeService} from '../web-services/appointment-type.service';
import {UsersService} from '../web-services/users.service';
import {SessionService} from '../services/session.service';
import {PatientsService} from '../web-services/patients.service';
import {ExpertService} from '../web-services/expert.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {ClinicsService} from '../web-services/clinics.service';
import {ClinicExpertsService} from '../web-services/clinic-experts.service';
import {PrintpreviewComponent} from '../printpreview/printpreview.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TimeSlotsComponent} from '../time-slots/time-slots.component';
import {DatePipe} from '@angular/common';
import {TimeSlotService} from '../web-services/time-slot.service';
import {InvoiceItemsDetailComponent} from '../invoice-items-detail/invoice-items-detail.component';
import {ReportsComponent} from '../reports/reports.component';
import {InitialAssessmentReportComponent} from '../initial-assessment-report/initial-assessment-report.component';
import {Utils} from '../util/utils';
import {ClinicExpertsSchedualService} from "../web-services/clinic-experts-schedual.service";
import {User} from "../dto/User";
import {DropDown} from "../dto/DropDown";
import {AppointmentFormDataDTO} from "../dto/AppointmentFormDataDTO";
import {CaseService} from "../web-services/case.service";
declare var JitsiMeetExternalAPI: any;


const moment = MomentRange.extendMoment(Moment);
// declare var closeAddOverlaydiv: Function;
// declare var closeSearchOverlaydiv: Function;
// declare var openAddOverlaydiv: Function;

@Component({
               selector: 'app-appointments',
               templateUrl: './appointments.component.html',
               styleUrls: ['./appointments.component.css'],
               providers: [DialogService],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class AppointmentsComponent implements OnInit,AfterViewInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  public labels = Labels;
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateAppointmentId = null;
    appointmentTypeList: any = [];
    usersList: any = [];
    appointmentsList: any = [];
    patientsList: any = [];
    expertsList: any = [];
    appointmentsSearch: FormGroup;
    slotSearchForm: FormGroup;
    clinicExpertsForm: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    appointmentsForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    clinicsList: any = [];
    clinicExpertsSchedualList: any = [];
    agencyList: any = [];
    companyList: any = [];
    loggedInUserType: any;
    loggedInRefId: any;
    patientId: any;
    consentList: any = [];
    patientTypeList: any = [];
    clinicExpertsList: any = [];
    parentRefId: any;
    displayModal: boolean = false;
    isShowing: boolean;
    schedules: any = [];
    menuName: string = 'test';
    appointmentTimeMins: number = 0;
    clickedEvent: any  = {

        duration: 0,

  };
  fullAuthenticatedUser: User;
  selectedExpertName: any;

    calendarOptions: CalendarOptions = {
      initialView: 'timeGridWeek',
      allDaySlot: false,
      expandRows: true,
      nowIndicator: true,
      selectable: false,
      weekends: true,
      dateClick: this.handleDateClick.bind(this),
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      navLinkWeekClick: this.handleWeekClick.bind(this),
      eventBackgroundColor: 'white',
      themeSystem: 'bootstrap',
      nowIndicatorClassNames: 'blue-line',
      lazyFetching: false,
      headerToolbar: {
        end: 'prev,next',
        left: 'timeGridWeek,timeGridDay'
      }, // buttons for switching between views
     /// dayCellClassNames: this.handleDayClassNames.bind(this),
      eventOverlap: false,
      selectOverlap: true,
      editable: true,
      height: '1750px',
      eventConstraint: 'working',
      duration: '01:00:00',
      slotMinTime: '07:00:00',
      slotMaxTime: '20:00:00',
       events: [

        {
          id: 'not-working',


          daysOfWeek: [0,1,2,3,4,5,6,7],
          classNames: ['fc-nonbusiness'],
          //selectable: true,
          display: 'background'

        }
      ]




    };


    // week = Constant.weekDays;
    week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    followUpTag = false;
    appointmentConfirmTag = false;
    hideInitialAssessment = true;
    addEditTitle = 'Add';
    title: any;
    parentAppointmentId: any;
    expertId: any;
    menuState: any = 'out';
    patientCases: any = [];


  domain: string = "8x8.vc"; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;

  experts = [
    {label: 'Dr Mahmood', icon: 'pi pi-refresh'},
    {label: 'Mr Smith', icon: 'pi pi-times'},
    {label: 'Angular.io', icon: 'pi pi-info'},
{separator: true},
  ];


  constructor(
        private appointmentsServices: AppointmentsService,
        private appointmentTypeServices: AppointmentTypeService,
        private usersServices: UsersService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private sessionService: SessionService,
        private patientsServices: PatientsService,
        private expertService: ExpertService,
        private lovService: ListOfValuesService,
        private clinicsServices: ClinicsService,
        private clinicExpertsServices: ClinicExpertsService,
        private dialogService: DialogService,
        private timeSlotService: TimeSlotService,
        private caseService: CaseService,

        private router: Router,
        private clinicExpertScheduleService: ClinicExpertsSchedualService
    ) {
    };
    ref: DynamicDialogRef;
    paramsObj: any;

    handleEventClick(arg) {

      this.clickedEvent = arg.event;

      this.clickedEvent.formattedAppointmentDate = moment(arg.event.start).format('MMMM Do YYYY');
      this.clickedEvent.formattedStartTime = moment(arg.event.start).format('HH:mm');
      this.clickedEvent.formattedEndTime = moment(arg.event.end).format('HH:mm');

      console.log(arg.event);

      if (arg.event.id === 'working' || arg.event.id === 'appointment') {
        this.toggleMenu();
      }
      else {
        alert('event clicked ' + arg.event.id);
      }


    }


  formSubmittedHandler(appointmentDataDTO: AppointmentFormDataDTO) {


      /* this happens when you close the appointment slider */
      if (appointmentDataDTO === null) {

        this.toggleMenu();
        return;
      }

      this.addAppointment(appointmentDataDTO);
  }




  handleWeekClick(): void {


    const calendarApi = this.calendarComponent.getApi();
    calendarApi.refetchEvents();

  }


  toggleMenu(): void {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  handleDateClick(arg): void {
    this.isShowing = true;
  }

  handleEventOverlap(arg): boolean {

    return true;
  }

  handleDateSelect(selectionInfo): void {

    const calendarApi = selectionInfo.view.calendar;

  }


    ngOnInit(): void {

        if (this.router.url.includes('/followup')) {
            this.parentAppointmentId = parseInt(this.sessionService.getSession('appointmentId'));
            this.followUpTag = true;
        }
        if (this.router.url.includes('/appointments_confirmation')) {
            console.log(' appointments_confirmation called');
            // this.parentAppointmentId = parseInt(this.sessionService.getSession('appointmentId'));
            this.appointmentConfirmTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
        }
        this.appointmentsForm = new FormGroup({
                                                  appointmentTypeId: new FormControl(1),
                                                  appointmentDate: new FormControl('', [Validators.required]),
                                                  appointmentTime: new FormControl('', ),
                                                  patientId: new FormControl('', [Validators.required]),
                                                  expertId: new FormControl('', [Validators.required]),
                                                  parentAppointmentId: new FormControl(),
                                                  clinicId: new FormControl('', [Validators.required]),
                                                  userId: new FormControl(),
                                                  consent: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                  patientFor: new FormControl(0, [Validators.required, Validators.min(0)]),
                                                  allocateTo: new FormControl(),
                                                  clinicExpertSchedualId: new FormControl(),
                                                  agencyId: new FormControl(),
                                                  companyId: new FormControl(),
                                                  daySlot: new FormControl(),
                                                  status: new FormControl(),
                                              });


        this.appointmentsSearch = new FormGroup({
                                                    appointmentTypeId: new FormControl(),
                                                    appointmentDate: new FormControl(),
                                                    appointmentTime: new FormControl(),
                                                    patientId: new FormControl(),
                                                    expertId: new FormControl(),
                                                    parentAppointmentId: new FormControl(),
                                                    clinicId: new FormControl(),
                                                    userId: new FormControl(),
                                                    consent: new FormControl(),
                                                    patientFor: new FormControl(),
                                                    allocateTo: new FormControl(),
                                                    clinicExpertSchedualId: new FormControl(),
                                                    agencyId: new FormControl(),
                                                    companyId: new FormControl(),
                                                    status: new FormControl(),
                                                });

        this.slotSearchForm = new FormGroup({
                                                    appointmentTypeId: new FormControl(),
                                                    appointmentDate: new FormControl(),
                                                    appointmentTime: new FormControl(),
                                                    patientId: new FormControl(),
                                                    expertId: new FormControl(),
                                                    parentAppointmentId: new FormControl(),
                                                    clinicId: new FormControl(),
                                                    userId: new FormControl(),
                                                    consent: new FormControl(),
                                                    patientFor: new FormControl(),
                                                    allocateTo: new FormControl(),
                                                    clinicExpertSchedualId: new FormControl(),
                                                    agencyId: new FormControl(),
                                                    companyId: new FormControl(),
                                                });
        this.clinicExpertsForm = new FormGroup({
                                                   clinicId: new FormControl(),
                                                   expertId: new FormControl(),
                                                   userId: new FormControl(),
                                               });

        this.addEditTitle = this.labels.btnAdd;
        this.loggedInUserType = this.sessionService.getSession('loggedInUserType');
        this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.fullAuthenticatedUser =  JSON.parse(this.sessionService.getSession('fullAuthenticatedUser')) as User;

        this.findAllPatientsByClientId();

        this.findAllClinicsForAdmin();

        this.getAllLov();
       //// this.countAppointments();
       //// this.findAllAppointments();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.appointmentsSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }


  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    $('.fc-prev-button').on('click', x => {

      const calendarApi = this.calendarComponent.getApi();

      const workingEventSource = calendarApi.getEventSourceById('working-events');
      workingEventSource.remove();
      calendarApi.addEventSource(this.schedules);

    });
    $('.fc-next-button').on('click', x => {
      const calendarApi = this.calendarComponent.getApi();

      const workingEventSource = calendarApi.getEventSourceById('working-events');
      workingEventSource.remove();
      calendarApi.addEventSource(this.schedules);
    });

    let indicatorLineElement= $('.fc-timegrid-now-indicator-line').clone();

    $('.fc-timegrid-now-indicator-container').each(function(i, container) {

      if ($(container).children('.fc-timegrid-now-indicator-line')) {
        let indicatorLineElementCloned = indicatorLineElement.clone();
        indicatorLineElementCloned.appendTo(container);

      }



    });




  }


  findAllCasesByPatientId(): void
  {
    this.patientsServices.findAllCasesByPatientId(parseInt(this.sessionService.getSession('patientId'))).subscribe(res => {

      if (res.status === 200) {

        this.patientCases = res.body;
      }
    });

  }

  utils = new Utils();
    countAppointments() {
        if(this.router.url.includes('/followup')){
            // this.appointmentsForm.controls['patientId'].setValue(this.loggedInRefId);
            this.appointmentsSearch.controls['appointmentTypeId'].setValue(Constant.APPOINTMENT_FOLLOWUP_ID);
            this.appointmentsSearch.controls['parentAppointmentId'].setValue(this.parentAppointmentId);
            this.appointmentsSearch.controls['status'].setValue(Constant.APPOINTMENT_STATUS_CONFIRMED);
        }else {
            this.appointmentsSearch.controls['appointmentTypeId'].setValue(Constant.APPOINTMENT_NEW_ID);
        }
        if (this.router.url.includes('/appointments_confirmation')) {
            this.appointmentsSearch.controls['expertId'].setValue(this.loggedInRefId);
        } else {
            this.appointmentsSearch.controls['userId'].setValue(this.loggedInRefId);
        }
        // this.appointmentsSearch.controls['userId'].setValue(this.loggedInRefId);

        let obj =this.utils.getUpdatedMillissecondObject(this.appointmentsSearch.value,"appointmentDate");
        if(obj['appointmentDate'] === '0'){
            delete obj['appointmentDate'];
        }
        this.appointmentsServices.countAppointments(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllAppointments();
            } else {
                this.totalRecords = 0;
                this.appointmentsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllAppointments() {
        this.startLoader();


        // if(this.loggedInUserType === Constant.INDIVIDUAL_BUSINESS_ROLE) {
        //     // this.appointmentsSearch.controls['patientId'].setValue(this.loggedInRefId);
        //     this.appointmentsSearch.controls['userId'].setValue(this.loggedInRefId);
        // }
        if(this.router.url.includes('/followup')){
            // this.appointmentsForm.controls['patientId'].setValue(this.loggedInRefId);
            this.appointmentsSearch.controls['appointmentTypeId'].setValue(Constant.APPOINTMENT_FOLLOWUP_ID);
            this.appointmentsSearch.controls['parentAppointmentId'].setValue(this.parentAppointmentId);
            this.appointmentsSearch.controls['status'].setValue(Constant.APPOINTMENT_STATUS_CONFIRMED);
        }else {
            this.appointmentsSearch.controls['appointmentTypeId'].setValue(Constant.APPOINTMENT_NEW_ID);
        }
        if (this.router.url.includes('/appointments_confirmation')) {
            this.appointmentsSearch.controls['expertId'].setValue(this.loggedInRefId);
        } else {
            this.appointmentsSearch.controls['userId'].setValue(this.loggedInRefId);
        }
        // this.appointmentsSearch.controls['userId'].setValue(this.loggedInRefId);
        let obj =this.utils.getUpdatedMillissecondObject(this.appointmentsSearch.value,"appointmentDate");
        if(obj['appointmentDate'] === '0'){
            delete obj['appointmentDate'];
        }

        this.appointmentsServices.findAllAppointments(obj, 1, 20).subscribe(
            res => {
                if (res['message'] == 'Success') {

                    this.appointmentsList = res['data'];
                    console.log(this.appointmentsList, 'retrieved appointment');
                } else {
                    this.appointmentsList = [];
                }
                this.stopLoader();
            }, err => {
                alert('err: ' +  err);
                this.stopLoader();
            });
    }

    findPatientsById(){
        this.patientsList = [];
        this.patientsServices.findPatientsById(this.loggedInRefId).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    // this.patients = res['data'];

                    let patients = res['data'];
                    for (let object of patients) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['patientId'];
                        dropDown.label = object['firstName']+' '+object['lastName'];
                        this.patientsList.push(dropDown);
                        console.log("drop down values array :: ",dropDown);
                    }
                } else {
                    this.patientsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllClinicsForAdmin(): void {
        this.startLoader();

        this.clinicsServices.findByClientId(this.fullAuthenticatedUser.clientId).subscribe(
            res => {

                if (res.status === 200) {
                    let clinicss = res.body;
                    for (let object of clinicss) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['clinicId'];
                        dropDown.label = object['clinicName'];
                        this.clinicsList.push(dropDown);
                    }
                } else {
                    this.clinicsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    async  findAllClinicExperts(clinicExpertObj) {

        // let paramsObj = {"clinicId":clinicExpertObj};
        if(this.router.url.includes('/followup')){
            this.paramsObj = {"expertId":clinicExpertObj};
        } else {
            this.paramsObj = {"clinicId":clinicExpertObj};
        }
        let temp = [];
        this.startLoader();
        this.clinicExpertsList = [];
        await this.clinicExpertsServices.findAllClinicExperts(this.paramsObj, null, null).toPromise().then(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '',appointmentTimeMins:'' };
                        dropDown.value = object['expertId']['expertId'];
                        dropDown.label = object['expertId']['firstName']+" "+object['expertId']['lastName'];
                        dropDown.appointmentTimeMins = object['expertId']['appointmentTimeMins'];
                        this.clinicExpertsList.push(dropDown);

                    }
                    // console.log("before followup",temp );

                    if(this.router.url.includes('/followup')){
                        let tempclinic =[];
                        this.clinicsList =[];
                        temp.map(d => tempclinic.push(d.clinicId));
                        console.log('temp clinics : ',tempclinic);
                        for (let object of tempclinic) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['clinicId'];
                            dropDown.label = object['clinicName'];
                            this.clinicsList.push(dropDown);
                            // console.log(this.clinicsList, 'its followup clinics list');
                        }
                    }

                } else {
                    this.clinicExpertsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    getAllLov(){

        this.startLoader();
        this.lovService.getAllListOfValuesByName(Constant.PATIENTS_LOV_ITMES).subscribe(result => {

            if(result['statusCode'] == 200){
                let dataArray = result['data'];
                for (let index = 0; index < dataArray.length; index++) {
                    const element = dataArray[index];
                    // console.log("element of data arrrray :: ", index, " index obj :: ", element);

                    if (element[Constant.PATIENT_CONSENT]) {

                        // this.conscent_array = element[Constant.PATIENT_CONSENT];
                        for (let object of element[Constant.PATIENT_CONSENT]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.consentList.push(dropDown);
                            // console.log("drop down values array :: ", dropDown);
                        }

                    } else if (element[Constant.PATIENT_TYPE]) {

                        for (let object of element[Constant.PATIENT_TYPE]) {
                            let dropDown = {label: '', value: ''};
                            dropDown.value = object['listOfValuesId'];
                            dropDown.label = object['label'];
                            this.patientTypeList.push(dropDown);
                            // console.log("patient type drop down values array :: ", this.patientTypeList);
                        }
                    }
                }
            }else{

            }

        });
    }



    get addUpdateTitle() {
        return this.updateAppointmentId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.appointmentsSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countAppointments();
        this.showHideSearchDialog();
    }

    startLoader() {
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }

    stopLoader() {
        this.loader.stop();
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        this.findAllAppointments();
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.appointmentsSearch.reset();
        this.countAppointments();
    }

  addAppointment(appointmentDataDTO: AppointmentFormDataDTO): void {

    this.appointmentsServices.addAppointmentCpa(appointmentDataDTO).subscribe(res => {

      if (res.status === 201) {
          let appointment = res.body;
        this.messageService.add(
          {
            severity: 'success',
            summary: this.translate(this.labels.entityNameAppointments) +
              this.translate(this.labels.addedSuccessfully),
            // detail: this.translate(this.labels.entityNameAppointments)
          });

        const calendarApi = this.calendarComponent.getApi();


        const appointmentToShow = {

          id: 'appointment',
          start: appointment.appointmentStartDateTimeAsString,
          end: appointment.appointmentEndDateTimeAsString,
          className: 'fc-event-full-appointment',
          selectable: false,
          title: appointment.patient.firstName + ' ' + appointment.patient.lastName,
          overlap: true,
          block: true,
          textColor: 'white',
          backgroundColor: '#2D7DD2'

        };

        const appointmentEventSource = {

          events: [appointmentToShow],
          id: 'appointments'

        };


        calendarApi.addEventSource(appointmentEventSource);


        this.submitted = true;
        this.appointmentsForm.reset();
        this.toggleMenu();


      } else {
        console.error(res['documentation']);
        this.stopLoader();
      }
    }, err => {
      console.error('err: ', err);
      this.stopLoader();
    });


  }

    addUpdateAppointments(appointments) {
        this.startLoader();
        this.appointmentsSearch.reset();
        if(this.loggedInUserType === Constant.INDIVIDUAL_BUSINESS_ROLE){
            this.appointmentsForm.controls['patientId'].setValue(this.loggedInRefId);
            this.appointmentsForm.controls['userId'].setValue(this.loggedInRefId);
        }
        if(this.loggedInUserType === Constant.CORPORATE_BUSINESS_ROLE){
            this.appointmentsForm.controls['userId'].setValue(this.loggedInRefId);
        }
        // Check its is appointment or follow-up
        if (this.router.url.includes('/followup')){
            this.appointmentsForm.controls['appointmentTypeId'].setValue(Constant.APPOINTMENT_FOLLOWUP_ID);
            this.appointmentsForm.controls['parentAppointmentId'].setValue(this.parentAppointmentId);
            this.appointmentsForm.controls['expertId'].setValue(parseInt(this.sessionService.getSession('expertId')));
            this.appointmentsForm.controls['status'].setValue(Constant.APPOINTMENT_STATUS_CONFIRMED);
            this.appointmentsForm.controls['userId'].setValue(this.loggedInRefId);
        } else {
            this.appointmentsForm.controls['appointmentTypeId'].setValue(Constant.APPOINTMENT_NEW_ID);
        }

        // console.log('app date : ', this.appointmentsForm.value.appointmentDate);
        // let appointmentDate = new DatePipe('en-US').transform(this.appointmentsForm.value.appointmentDate,'yyyy-MM-dd');
        // this.appointmentsForm.controls['appointmentDate'].setValue(new Date(appointmentDate));
        // this.appointmentsForm.controls['appointmentDate'].setValue(appointmentDate);
        let obj =this.utils.getUpdatedMillissecondObject(this.appointmentsForm.value,"appointmentDate");
        if (this.updateAppointmentId == null) {
            this.appointmentsServices.addAppointments(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    appointments = res['data'][0];
                    this.appointmentsList.push(appointments);
                    this.messageService.add(
                        {
                            severity: 'success',
                            summary: this.translate(this.labels.entityNameAppointments) +
                                     this.translate(this.labels.addedSuccessfully),
                            // detail: this.translate(this.labels.entityNameAppointments)
                        });
                    this.submitted = true;
                    this.appointmentsForm.reset();
                    this.showHideAddDialog();
                    this.countAppointments();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            obj.appointmentId = this.updateAppointmentId;
            this.appointmentsServices.updateAppointments(obj).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.appointmentsList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNameAppointments) +
                                                        this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNameAppointments)
                                            });
                    if (!this.router.url.includes('/appointments_confirmation')) {
                        this.showSearchBtn = false;
                        this.showAddBtn = false;
                        this.showSearchDialog = false;
                        this.showAddDialog = false;
                        this.editTag = false;
                    }
                    this.appointmentsForm.reset();
                    this.countAppointments();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updateAppointmentId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }





    deleteByIdAppointments(appointmentId: number, index) {
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.appointmentsServices.deleteByIdAppointments(appointmentId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.appointmentsList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNameAppointments) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNameAppointments)
                                                });
                        this.countAppointments();
                    } else {
                        console.error('error: ', res['documentation']);
                        this.startLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
                                  });
    }

  async loadAppointmentsToEdit(appointmentId: number, index) {
      this.addEditTitle = this.labels.btnEdit;
        let appointments = this.appointmentsList.find(x => x['appointmentId'] == appointmentId);
        console.log("appointment obj :: ",appointments);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAppointmentId = appointments['appointmentId'];
        this.selectedIndex = index;

      if (appointments.status) {
          if (this.router.url.includes('/followup')){
              this.appointmentsForm.controls['status'].setValue(Constant.APPOINTMENT_STATUS_CONFIRMED);
          }else {
              this.appointmentsForm.controls['status'].setValue(Constant.APPOINTMENT_STATUS_PENDING);
          }
      }

      if (appointments.appointmentDate) {
          this.appointmentsForm.controls['appointmentTypeId'].setValue(appointments['appointmentTypeId']);
      }

        if (appointments.appointmentDate) {
            this.appointmentsForm.controls['appointmentDate'].setValue(new Date(appointments['appointmentDate']));
        }
        if (appointments.clinicId) {
            if(this.followUpTag) {
                this.findAllClinicExperts(this.sessionService.getSession('expertId'));
            } else {
                await this.findAllClinicExperts(appointments['clinicId']['clinicId']);
            }
            this.appointmentsForm.controls['clinicId'].setValue(appointments['clinicId']['clinicId']);
        }
        if (appointments.expertId) {
            this.appointmentsForm.controls['expertId'].setValue(appointments['expertId']['expertId']);
        }
        if (appointments.patientId) {
            // await this.findAllPatients();
            this.appointmentsForm.controls['patientId'].setValue(appointments['patientId']['patientId']);
        }
        // this.appointmentsForm.controls['parentAppointmentId'].setValue(appointments['parentAppointmentId']['parentAppointmentId']);
        if (appointments.userId) {
            this.appointmentsForm.controls['userId'].setValue(appointments['userId']);
        }
        if (appointments.consent) {
            this.appointmentsForm.controls['consent'].setValue(appointments['consent']);
        }
        if (appointments.patientFor) {
            this.appointmentsForm.controls['patientFor'].setValue(appointments['patientFor']['listOfValuesId']);
        }
            let dayName = this.week[new Date(appointments['appointmentDate']).getDay()];
            this.appointmentsForm.controls['daySlot'].setValue(dayName);
        if (appointments.appointmentTime) {
          /// await this.showTimeSlots(appointments['appointmentDate'], appointments['clinicId']['clinicId'], appointments['expertId']['expertId'])
            this.appointmentsForm.controls['appointmentTime'].setValue(appointments['appointmentTime']);
        }

      // by ali
      if(this.showAddDialog === false && this.editTag === false){
          this.editTag = true;
          this.showAddDialog = true;
          this.showAddBtn = true;
          this.showSearchBtn = true;
          this.showSearchDialog = false;
      } else if(this.showAddDialog === true && this.editTag === false){
          this.editTag = true;
          this.showAddBtn = true;
          this.showSearchBtn = true;
          this.showSearchDialog = false;
      } else if(this.showAddDialog === true && this.editTag === true) {
          this.editTag = false;
          this.showAddDialog = false;
          this.showAddBtn = false;
          this.showSearchBtn = false;
          this.showSearchDialog = false;
      }

    }

    onClearAdd() {
        this.appointmentsForm.reset();
        this.appointmentsForm.markAsUntouched();
        this.updateAppointmentId = null;
    }

    findAllPatientsByClientId(): void {

      this.patientsList = [];

      this.patientsServices.findAllPatientsByClientId(this.fullAuthenticatedUser.clientId).subscribe(
        res => {

          if (res.status === 200) {

            let patients = res.body;
            for (let object of patients) {
              let dropDown = new DropDown(object['patientId'], object['firstName'] + ' ' + object['lastName'], object);
              //dropDown.value = object['patientId'];
              //dropDown.label = object['firstName'] + ' ' + object['lastName'];
              this.patientsList.push(dropDown);

            }
            this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
          } else {
            this.patientsList = [];
            this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
          }
          this.stopLoader();
        }, err => {
          console.error('err: ', err);
          this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
          this.stopLoader();
        });



    }



    findAllPatients(){
        this.patientsList = [];
        if (this.router.url.includes('/followup')) {
            this.loggedInUserType = '';
            // this.loggedInRefId = -1;
        }
        this.patientsServices.findAllPatients(null, null, null,this.loggedInUserType,-1).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    // this.patients = res['data'];

                    let patients = res['data'];
                    console.log('patientssss : ', patients);
                    for (let object of patients) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['patientId'];
                        dropDown.label = object['firstName']+' '+object['lastName'];
                        this.patientsList.push(dropDown);
                        console.log("drop down values array :: ",dropDown);
                    }
                    this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
                } else {
                    this.patientsList = [];
                    this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.loggedInRefId = parseInt(this.sessionService.getSession('loggedInRefId'));
                this.stopLoader();
            });
    }

    findAllExperts(){
        this.startLoader();
        this.expertService.findAllExpert(null, null, null,0,null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    let experts = res['data'];
                    for (let object of experts) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['expertId'];
                        dropDown.label = object['firstName']+' '+object['lastName'];
                        this.expertsList.push(dropDown);
                        console.log("Expert :: ",dropDown);
                    }

                } else {
                    this.expertsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.appointmentsForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.appointmentsForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.appointmentsForm.get(control)?.valid && this.appointmentsForm?.get(control)?.touched;
    }

    onCloseAdd() {
        this.appointmentsForm.reset();
    }

    onCloseSearch() {
        this.appointmentsSearch.reset();
    }

    refresh() {
        this.countAppointments();
    }

    onAddOverlayDiv() {
        this.appointmentsForm.reset();
    }

    getTimeSlots(appointmentObj) {

        this.ref = this.dialogService.open(TimeSlotsComponent, {
            header: 'Time Slots',
            width: '90%',
            contentStyle: {'max-height': '500px', 'overflow': 'auto'},
            baseZIndex: 10000,
            data: {
                    appointmentObj: appointmentObj
            }
        });

    }
    appointmentDate : any;
    selectedClinicId : any;
    selectedExpertId : any;

    changeDate(event) {
        console.log('date changed called', event);
        this.timeSlots = [];
        let date = new DatePipe('en-US').transform(event,'yyyy-MM-dd');
        this.appointmentDate = date ;
        console.log(this.appointmentDate, 'now date changed');


    }
    onClinicChange(event){
        // console.log("on clinic change event :: ",event);
        this.clinicExpertsList = [];
        this.timeSlots = [];
        this.appointmentsForm.controls['daySlot'].setValue('');
        this.selectedClinicId = event.value;
        // this.appointmentsForm.controls['']
        if(this.followUpTag && this.appointmentDate && this.selectedClinicId){
            this.selectedExpertId = this.sessionService.getSession('expertId');
             ///   this.showTimeSlots(this.appointmentDate,this.selectedClinicId,this.selectedExpertId);
        } else
        {
                this.findAllClinicExperts(this.selectedClinicId);
        }
    }
    onExpertChange(event){



        /* this is where we need to for now get the availablity of the expert for this clinic */

        const selectedExpert =  this.clinicExpertsList.find(expert => expert.value === event.value);
        this.appointmentTimeMins = parseInt(selectedExpert.appointmentTimeMins);
        this.selectedExpertName = selectedExpert.label;
        this.selectedExpertId = event.value;

        const clinicExpertSchedule = {

            expertId: this.selectedExpertId,
            clinicId: this.selectedClinicId

        };

      const calendarApi = this.calendarComponent.getApi();
      const slotDuration = '00:' + selectedExpert.appointmentTimeMins + ':00';
      calendarApi.setOption('slotDuration', slotDuration);


      this.clinicExpertScheduleService.findAllClinicExpertSchedulesByClinicAndExpertIdCpa(this.selectedClinicId, this.selectedExpertId).subscribe(res => {

        if (res.status === 200) {


            let schedules = res.body.map(schedule => {

              return {
                id: 'working',
                start: schedule.scheduleStartDateTimeAsString,
                end: schedule.scheduleEndDateTimeAsString,
                className: 'fc-working',
                selectable: true,
                backgroundColor: 'green',
                display: 'background',
                overlap: true,
                extendedProps: {
                  clinicExpertScheduleId: schedule.clinicExpertScheduleId,
                  slotTime: schedule.slotTime
                }
              };


            });

            const workingEventSoruce = {

              events: schedules,
              id: 'working-events'

            };

            console.log(schedules);
            this.schedules = workingEventSoruce;


            calendarApi.addEventSource(workingEventSoruce);

            /* also need to find all appointments for expert and clinic so we can add to calendar */

            const appointments = {

              clinicId: this.selectedClinicId,
              expertId: this.selectedExpertId
            };

            this.appointmentsServices.findAppointmentsByClinicExpertIdCpa(this.selectedClinicId, this.selectedExpertId).subscribe(res => {

              console.log(res);


              if (res.status === 200) {


                let appointmentsToShow = res.body.map(appointment => {


                  return {
                    id: 'appointment',
                    start: appointment.appointmentStartDateTimeAsString,
                    end: appointment.appointmentEndDateTimeAsString,
                    className: 'fc-event-full-appointment',
                    selectable: false,
                    title: appointment.patientFirstName + ' ' + appointment.patientLastName,
                    overlap: true,
                    block: true,
                    textColor: 'white',
                    backgroundColor: '#2D7DD2',

                  };
                });
                const appointmentEventSource = {

                  events: appointmentsToShow,
                  id: 'appointments'

                };

                console.log(appointmentsToShow);

                calendarApi.addEventSource(appointmentEventSource);
              }
              else {
                alert('error');
              }

            });

          }

        }, err => {
          console.error('err: ', err);
          this.stopLoader();
        });


        ///findAllTimeSlots(appointmentDate, clinicId, expertId).toPromise().then(result =>{

        //if(this.appointmentDate && this.selectedClinicId && this.selectedExpertId){
           // this.showTimeSlots(this.appointmentDate,this.selectedClinicId,this.selectedExpertId);
       // }

    }
    savedAppointmentList: any =[];
   async findDateAppointment(appointmentDate, clinicId, expertId) {
       this.savedAppointmentList = [];
        this.startLoader();
        // this.slotSearchForm.controls['appointmentDate'].setValue(appointmentDate);
        this.slotSearchForm.controls['clinicId'].setValue(clinicId);
        this.slotSearchForm.controls['expertId'].setValue(expertId);
        this.slotSearchForm.controls['patientId'].setValue(null);
        this.slotSearchForm.controls['userId'].setValue(this.loggedInRefId);
       await this.appointmentsServices.findAllAppointments(this.slotSearchForm.value, this.pageNo, this.itemPerPage).toPromise().then(
            res => {
                if (res['message'] == 'Success') {
                    this.savedAppointmentList = res['data'];
                    this.savedAppointmentList.map(d => {
                        if(this.utils.returnDateFromMilliSecond(d.appointmentDate) != appointmentDate){
                            this.savedAppointmentList.splice(d);
                        }
                    });
                    // for (let i=0; i<this.savedAppointmentList.length; i++){
                    // this.savedAppointmentList.splice(
                    //     this.savedAppointmentList.findIndex(x => {
                    //         this.utils.returnDateFromMilliSecond(x.appointmentDate) != appointmentDate;
                    //         console.log('rt : ',this.utils.returnDateFromMilliSecond(x.appointmentDate));
                    //         console.log('appdate : ', appointmentDate);
                    //
                    //     }
                    //     ), 1);

                      // let tag =  this.savedAppointmentList.findIndex(x => {
                      //                                           this.utils.returnDateFromMilliSecond(x.appointmentDate) != appointmentDate;
                      //                                           console.log('rt : ',this.utils.returnDateFromMilliSecond(x.appointmentDate));
                      //                                           console.log('appdate : ', appointmentDate);
                      //
                      //                                       });
                      // console.log('tags : ', tag);

                    this.calculateSlots();
                    this.showAvailableSlots();
                } else {
                    this.savedAppointmentList = [];
                    this.calculateSlots();
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }


    showAvailableSlots(){
       console.log('saved appointments list : ',this.savedAppointmentList);
       for(let i=0; i<this.savedAppointmentList.length; i++){
           // this.timeSlots.splice()
           this.timeSlots.splice(
               this.timeSlots.findIndex(x => x == this.savedAppointmentList[i].appointmentTime), 1);

           // this.timeSlots.splice(
           //     this.timeSlots.findIndex(
           //         x => (x == this.savedAppointmentList[i].appointmentTime))
           //     , 1);

           console.log('Remaining time Slots : ', this.timeSlots);
       }
       if(this.editTag){
           this.timeSlots.push(this.appointmentsForm.value.appointmentTime);
       }
       console.log('appointment time : ',this.appointmentsForm.value.appointmentTime );

       console.log(this.timeSlots, 'for sorting');
       // if(this.appointmentsForm.value.appointmentTime){
       //     this.
       // }
    }
    onChange(event, day) {
        console.log(event, 'checkbox clicked');
        console.log(day, 'day clicked');
    }

    tempTime: any;
    startTime: any;
    timeSlots: any =[];

    calculateSlots() {
        this.timeSlots = [];

        console.log('Time slots created : ', this.timeSlots);
    }

    addMinutes(time, minutes) {
        let date = new Date(new Date('07/05/2021 ' + time).getTime() + minutes * 60000);
        this.tempTime =
            (date.getHours().toString().length == 1
                ? '0' + date.getHours()
                : date.getHours()) +
            ':' +
            (date.getMinutes().toString().length == 1
                ? '0' + date.getMinutes()
                : date.getMinutes());
            // +
            // ':' +
            // (date.getSeconds().toString().length == 1
            //     ? '0' + date.getSeconds()
            //     : date.getSeconds());
        return this.tempTime;
    }

    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }
    disablePatient = false;
    patientName : any;
    showHideAddDialog() {
        if(!this.showAddDialog) {
            // this.appointmentsForm.reset();
            this.timeSlots =[];
            if (this.loggedInUserType === Constant.INDIVIDUAL_BUSINESS_ROLE) {
                this.appointmentsForm.controls['patientId'].setValue(this.loggedInRefId);
              // let patientName = this.patientsList.map(d=> d.value === this.loggedInRefId).firstName;
              this.patientName = this.patientsList.find(x => x['value'] == this.loggedInRefId).label;
                console.log(this.patientName, 'Patient Name');
                this.disablePatient = true;
            } else {
                this.disablePatient = false;
            }
            if (this.router.url.includes('/followup')) {
                this.appointmentsForm.controls['patientId'].setValue(this.patientId);
            }
            this.appointmentDate = null;
            this.selectedClinicId = null;
            this.selectedExpertId = null;
        } else {
            this.appointmentsForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateAppointmentId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.appointmentsServices.globalSearchCount(this.searchTerm, this.loggedInRefId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.appointmentsList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.appointmentsServices.globalSearchFind(this.searchTerm, this.loggedInRefId, this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.appointmentsList = result['data'];
            } else {
                this.totalRecords = 0;
                this.appointmentsList = [];
            }
            console.log(result, 'result');
        });
    }

    ViewinitialAssessment(appointmentId) {
        // if (this.unSubService !== undefined) {
        //     this.unSubService.unsubscribe();
        // }
        console.log(appointmentId, 'appointment data');
        console.log('Initial Assessment Called');
        this.ref = this.dialogService.open(InitialAssessmentReportComponent, {
            header: 'Initial Assessment Report',
            width: '90%',
            contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                appointmentId: appointmentId
            }
        });
        console.log(this.ref, 'onclose reference');
        this.ref.onClose.subscribe(res => {
            console.log(' before confirmation closing');
            this.confirmation.confirm(null).close();
        });
    }

   async ConfirmAppointment(appointmentId) {
        this.startLoader();
        this.addEditTitle = this.labels.btnEdit;
        let appointments = this.appointmentsList.find(x => x['appointmentId'] == appointmentId);
        console.log("appointment obj :: ",appointments);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateAppointmentId = appointments['appointmentId'];

        if (appointments.appointmentDate) {
            this.appointmentsForm.controls['appointmentTypeId'].setValue(appointments['appointmentTypeId']);
        }

        if (appointments.appointmentDate) {
            this.appointmentsForm.controls['appointmentDate'].setValue(new Date(appointments['appointmentDate']));
        }
        if (appointments.clinicId) {
            if(this.followUpTag) {
                await this.findAllClinicExperts(this.sessionService.getSession('expertId'));
            } else {
                await this.findAllClinicExperts(appointments['clinicId']['clinicId']);
            }
            this.appointmentsForm.controls['clinicId'].setValue(appointments['clinicId']['clinicId']);
        }
        if (appointments.expertId) {
            this.appointmentsForm.controls['expertId'].setValue(appointments['expertId']['expertId']);
        }
        if (appointments.patientId) {
            // await this.findAllPatients();
            this.appointmentsForm.controls['patientId'].setValue(appointments['patientId']['patientId']);
        }
        // this.appointmentsForm.controls['parentAppointmentId'].setValue(appointments['parentAppointmentId']['parentAppointmentId']);
        if (appointments.userId) {
            this.appointmentsForm.controls['userId'].setValue(appointments['userId']);
        }
        if (appointments.consent) {
            this.appointmentsForm.controls['consent'].setValue(appointments['consent']);
        }
        if (appointments.patientFor) {

            this.appointmentsForm.controls['patientFor'].setValue(appointments['patientFor']['listOfValuesId']);
        }
        let dayName = this.week[new Date(appointments['appointmentDate']).getDay()];
        this.appointmentsForm.controls['daySlot'].setValue(dayName);


       this.appointmentsForm.controls['status'].setValue(Constant.APPOINTMENT_STATUS_CONFIRMED);
        this.addUpdateAppointments(this.appointmentsForm.value);

        // this.stopLoader();
    }
}
