import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../utilities/constants/constants';
import { AlertService } from '../alertservice/alert.service';
import { ActivatedRoute } from '@angular/router';
import { cookieObject } from '../../utilities/interfaces/interfaces';
import { BehaviorSubject } from 'rxjs';
import { WebService } from '../webservice/web.service';
import { UtilityService } from '../utility/utility.service';
import { DependancyServiceService } from '../dependancy-services/dependancy-service.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public timeStamp = 30000;
  consoleEnabled = false;
  applicationName = "Menu";
  applicationId = 4;
  projectId = 0;
  projectTitle;
  registerNewEvent = "RegisterNewEvent";
  unsubscribeEvent = "UnsubscribedEvent";
  mainMenuFormId = 94;
  allNotificationFormId = 47;
  newSubscriptionFormId = 52;

  applicationShortName = "";

  currentVersionTitle = "";

  cookieObject: cookieObject = {
    refreshToken: true,
    userInfo: {},
    loggedInUser: 0,
    userName: "",
    userImage: "",
    client: -1,
    token: "",
    gender: "",
    department: 1,
    applicationId: 1,
    currentVersion: 0,
    activatedRole: 1,
    expirationDate: 1,
    applicationShortName: "",
    routedFrom: {},
    userPreference: "",
    project: "",
    authenticationStatus: false,
    applicationRoles: [],
    ums_roles: [],
    xigt_roles: [],
    re_roles: [],
    tms_roles: [],
    ems_roles: [],
  }

  umsUrl = ""
  rocketChatUserTokenForIframe = ""

  applicationUrls = [];
  constructor(public cookieService: CookieService, private http: HttpClient, private toastr: AlertService, private activatedRoute: ActivatedRoute, private dependancyService: DependancyServiceService) { }


  private currentVersion = new BehaviorSubject<any>(0);
  public setCurrentVersion(value) {
    this.currentVersion.next(value);
  }

  get CurrentVersion() {
    return this.currentVersion.asObservable();
  }

  private unreadNotifications = new BehaviorSubject<number>(0);
  public setUnreadNotifications(value) {
    this.unreadNotifications.next(value);
  }

  get getUnreadNotifications() {
    return this.unreadNotifications.asObservable();
  }


  private refrershToken = new BehaviorSubject<any>(true);
  public setRefreshToken(value) {
    this.refrershToken.next(value);
  }

  get refreshToken() {
    return this.refrershToken.asObservable();
  }


  // ----------------Session Storage--------------
  setSession(key, value) {
    sessionStorage.setItem(key, value);
  }

  getSession(key) {
    return sessionStorage.getItem(key);
  }

  removeSession(key) {
    sessionStorage.removeItem(key);
  }

  //---------------Cookie Storage---------------

  setCookies(cookieName, cookieValue) {
    if (cookieName == "token" || cookieName == "expirationDate") {
      this.cookieService.set(cookieName, cookieValue, null, "/");
    }
    // this.cookieService.set(cookieName, cookieValue, null, "/");
    this.cookieObject[cookieName] = cookieValue;
  }

  getCookie(cookieName) {
    // return this.cookieService.get(cookieName);
    return this.cookieObject[cookieName];
  }

  // ----------- Get Application Url -------------

  getApplicationBackEndUrl(applicationId) {

    // if(applicationId==7){
    //   return "http://localhost:8080/";
    // }

    var obj = this.applicationUrls.filter(function (node) {
      return node.applicationId == applicationId;
    });
    if (obj && obj[0] && obj[0]['applicationBackendUrl']) {
      return obj[0]['applicationBackendUrl'];
    }
    else {
      return null;
    }

    return "http://localhost:8080/"
  }

  getApplicationFrontEndUrl(applicationId) {
    var obj = this.applicationUrls.filter(function (node) {
      return node.applicationId == applicationId;
    });
    if (obj && obj[0] && obj[0]['applicationFrontendUrl']) {
      return obj[0]['applicationFrontendUrl'];
    }
    else {
      return null;
    }
  }


  // ---------------------------------------------

  // getAllCookies() {
  //   return this.cookieService.getAll();
  // }

  deleteCookie(cookiename) {
    this.cookieService.delete(cookiename, "/");
  }

  // deleteAllCookies() {
  //   this.cookieService.deleteAll();
  // }

  Print(message) {
    if (this.consoleEnabled) {
      console.log(message);
    }
    else { }
  }


  getGlobalVariable(name) {
    return this.cookieObject[name];
  }
  setGlobalVariable(name, value) {
    this.cookieObject[name] = value;
  }

  token = "";

  //------------------Role Match------------------

  roleMatch(allowedRoles): boolean {
    return true;
  }

  // ----------------Verify Labels--------------------

  // verifyLabel(name, constantlist) {
  //   if (constantlist != undefined) {
  //     if (constantlist.find(x => x['constant']['constantCode'] == name) != undefined) {
  //       return constantlist.find(x => x['constant']['constantCode'] == name)['value'];
  //     } else {
  //       return name;
  //     }
  //   } else {
  //     return name;
  //   }
  // }

  constants = []
  verifyLabel(name: string, constantlist: string[], formId: number) {
    if (constantlist != undefined && constantlist.length > 0) {
      if (constantlist.find(x => x['code'] == name)) {
        return constantlist.find(x => x['code'].toLowerCase() == name.toLowerCase())['value'];
        //  
      } else {
        var constants = this.constants.find(x => x.formId === formId);
        if (constants) {
          const index = constants['constants'].findIndex(element => element === name);
          if (index == -1) {
            constants['constants'].push(name);
            // this.translateLabel(formId, name, constantlist)
          }
        } else {
          var obj = {
            "formId": formId,
            "constants": [name]
          }
          this.constants.push(obj);
          // this.translateLabel(formId, name, constantlist)
          console.log("constants Menu :: ----------------------------- ::  ", this.constants);
        }
        return name;
      }
    } else {
      return name;
    }

  }

  translateLabel(formId, label, constantList) {
    var serverURL = this.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID)
    var applicationId = this.getCookie('applicationId');
    this.dependancyService.translateLabel(formId, label, serverURL, applicationId).subscribe((data) => {
      if (data && data['statusCode'] == AppSettings.API_SUCCESS_CODE) {
        constantList.push(data['data'][0]['constant']);
      }
    });
  }

  //Creating Json Object

  makeJson(placeholderName, eventTitle, projectName, eventDescription, insertionTime) {
    //console.log(placeholderName + eventTitle + projectName + eventDescription + insertionTime + participant)
    var fieldName1 = 'placeholderName'; var fieldValue1 = placeholderName;
    var fieldName2 = 'eventTitle'; var fieldValue2 = eventTitle;
    var fieldName3 = 'projectName'; var fieldValue3 = projectName;
    var fieldName4 = 'eventDescription'; var fieldValue4 = eventDescription;
    var fieldName5 = 'insertionTime'; var fieldValue5 = insertionTime;
    var object = {};
    object[fieldName1] = fieldValue1;
    object[fieldName2] = fieldValue2;
    object[fieldName3] = fieldValue3;
    object[fieldName4] = fieldValue4;
    object[fieldName5] = fieldValue5;
    return object;
  }

  checkRole(pageControls: Map<string, boolean>, roles: string[]): Map<string, boolean> {
    for (let key of Array.from(pageControls.keys())) {
      if (roles) {
        pageControls.set(key, true);
      }
    }
    return pageControls;
  }

  checkHttpCodes(error) {
    if (error.status == AppSettings.API_BAD_REQUEST_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " Bad Request");
    }
    else if (error.status == AppSettings.API_UNAUTHORIZED_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " Unauthorized Access");
    }
    else if (error.status == AppSettings.API_NOT_FOUND_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " Not Found");
    }
    else if (error.status == AppSettings.API_CONFLICT_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " Conflict");
    }
    else if (error.status == AppSettings.API_FORBIDDEN_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " Forbidden");
    }
    else if (error.status == AppSettings.API_INTERNAL_SERVER_ERROR_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " Server Error");
    }
    else if (error.status == AppSettings.API_TIME_OUT_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " TimeOut");
    }
    else if (error.status == AppSettings.API_NO_CONTENT_CODE) {
      this.toastr.showCustomInfoAlert(error.status + " No Content");
    }

  }

  // Report Session Creation
  public setLastPageUrl(url: string) {
    if (sessionStorage.getItem('url')) {
      sessionStorage.removeItem('url');
    }
    sessionStorage.setItem('url', url);
  }

  public getLastPageUrl(): string {
    let url = "Empty";
    if (sessionStorage.getItem('url')) {
      url = sessionStorage.getItem('url');
    }
    return url;
  }

  public removeLastPageUrl() {
    if (sessionStorage.getItem('url')) {
      sessionStorage.removeItem('url');
    }
  }

  resetCookieObject() {
    return this.cookieObject = {
      refreshToken: true,
      userInfo: {},
      loggedInUser: 0,
      userName: "",
      userImage: "",
      client: -1,
      token: "",
      gender: "",
      department: 1,
      applicationId: 1,
      currentVersion: 0,
      routedFrom: {},
      activatedRole: 1,
      expirationDate: 1,
      applicationShortName: "",
      userPreference: "",
      project: "",
      authenticationStatus: false,
      applicationRoles: [],
      ums_roles: [],
      xigt_roles: [],
      re_roles: [],
      tms_roles: [],
      ems_roles: [],
    }
  }
}
