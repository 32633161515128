export class FollowUpSteps {

  static stepItems =  [
    {
      label: 'General',
      number: 0,
      route: 'follow-up'
    },
    {
      label: 'Body Chart',
      number: 1,
      route: 'follow-up/body-chart'
    },
    {
      label: 'Testing',
      number: 2,
      route: 'follow-up/testing'
    },
    {
      label: 'Review',
      number: 3,
      route: 'follow-up/review'
    },
  ];
}
