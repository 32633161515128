//------------Chat Model-------------

export class Chat {
    constructor(
        public chat_id: number,
        public reference_type: string,
        public reference_id: number,
        public status: string,
        public first_user_id: number,
        public second_user_id: number,
        public group_id: number,
        public dml_status: number,
        public user_id: number,
        public root_chat_id: number
    ) {
    }

}

//------------Message Model-------------

export class Message {
    constructor(
        public chat_id: number,
        public first_contact_id: number,
        public second_contact_id: number,
        public group_id: number,
        public content: string,
        public task: boolean,
        public task_type: string,
        public task_id: number,
    ) {
    }

}

//------------User Model---------------

export class User {
    constructor(
        public user_id: number,
        public username: string,
        public password: string,
        public status: string,
        public dml_status: number,
        public root_user_id: number,
        public client_id: number,
        public email: string,
        public type: string
    ) {
    }

}

//----------Participant Model-------------

export class Participant {
    constructor(
        public group_participant_id: number,
        public participant_id: string,
        public type: string,
        public group_id: number,
        public dml_status: number,
        public user_id: number,
        public root_group_participant_id: number
    ) {
    }

}

//-------------Group Model-----------------

export class Group {
    constructor(
        public user_id: number,
        public title: string,
        public description: string,
        public participants: Array<number>
    ) {
    }

}

