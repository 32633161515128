export class DropDown {


  public value: number;
  public label: string;
  public classObject: any;

  constructor(value: number, label: string, classObject: any) {
    this.value = value;
    this.label = label;
    this.classObject = classObject;
  }

}
