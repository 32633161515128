import { Component, OnInit, ElementRef, Renderer2, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {Labels} from '../util/labels';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { PrimeIcons} from 'primeng/api';
import {PatientsService} from "../web-services/patients.service";
import {SessionService} from "../services/session.service";
import {CaseService} from "../web-services/case.service";
import {FormDataDTO} from "../dto/FormDataDTO";
import {AppointmentFormDataDTO} from "../dto/AppointmentFormDataDTO";
import {Constant} from "../util/constant";
import {BodyChartMultiSelects} from "../uiUtils/pages/body-chart/BodyChartMultiSelects";
import {SharedService} from "../web-services/shared.service";
import {ListOfValuesService} from "../web-services/list-of-values.service";
import {BodyChartFormDataDTO} from "../dto/BodyChartFormDataDTO";
declare var $: any;

@Component({
  selector: 'app-body-chart-anatomy-details',
  templateUrl: './body-chart-anatomy-details.component.html',
  styleUrls: ['./body-chart-anatomy-details.component.css']
})

export class BodyChartAnatomyDetailsComponent implements OnInit {
  public labels = Labels;
  @Output() anatomyPainScore =  new EventEmitter<any>();
  @Output() anatomyDetailsFormSubmitted =  new EventEmitter<any>();

  @Input() selectedBodyPartInfo: any;
  @Input() bund: any;
  @Input() selectedPainScore: number;
  @Input() patientsList: any;
  @Input() patientTypeList: any;
  @Input() consentList: any;
  @Input() selectedExpertName: any;
  @Input() selectedExpertId: any;
  @Input() selectedClinicId: any;
  @Input() existingPainScoreData: any;
  @Input() isFollowUp: boolean;
  patientSelected = false;
  selectedPatient: any;
  durationSlider: number;
  patientCasesList: any;
  selectedValue: string;
  patientId;
  caseId;
  caseCreated = false;
  objectMultiSelects: any;
  durationsList: any = [];
  hourlySymptomsList: any = [];


  lovDataObject: any = {};

  painScoreColours = ['#7AD047', '#95CE49', '#ACE24E', '#F9E104', '#F8CE07', '#FCB129', '#FCA329', '#FC8A29', '#F96B2D', '#E21B15']


  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  bodyChartAnatomyDetailsForm: FormGroup;


  constructor(private el: ElementRef,
              renderer: Renderer2,
              private bodyChartMultiSelects: BodyChartMultiSelects,
              private sharedService: SharedService,
              private lovServices: ListOfValuesService,




  ) {
  }

  ngOnInit(): void {


    this.objectMultiSelects = this.bodyChartMultiSelects.objectMultiSelects;
    this.lovDataObject = {"refId":0,"refType":""};

    this.bodyChartAnatomyDetailsForm = new FormGroup({
      duration: new FormControl('', ),
      hourlySymptoms: new FormControl('', ),
      bodyChartAnatomyNotes: new FormControl('', ),
    });

    this.findDurations();
    this.findHourlysymptoms();




  }


  ngAfterContentChecked(): void {

    $('.pain-scale__level').hover(function() {
      $(this).addClass("pain-scale__level--active").prevAll().addClass("pain-scale__level--active");

    }, function() {
      $(this).removeClass("pain-scale__level--active").prevAll().removeClass("pain-scale__level--active");
    });

    $('.pain-scale__level').click(function() {
      $(this).siblings().removeClass("pain-scale__level--selected");
      $(this).addClass("pain-scale__level--selected pain-scale__level--blink").one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function () {
        $(this).removeClass("pain-scale__level--blink");
      });
      $(this).prevAll().addClass("pain-scale__level--selected pain-scale__level--blink").one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function() {
        $(this).removeClass("pain-scale__level--blink");
      });
    });


  }

  painScore(score, colour): void {


    this.selectedBodyPartInfo.bodyChartAnatomyPainScore = score;
    this.anatomyPainScore.emit(colour);

  }

  getResponse($event) {
    this.bodyChartMultiSelects.getResponseObjects($event);
  }

  ngOnChanges(changes): void {


    if (this.selectedBodyPartInfo !== null && this.selectedBodyPartInfo.isExisting === true) {

     /// alert("selectedBodyPartInfo " + JSON.stringify(this.selectedBodyPartInfo));
     /// alert("selectedBodyPartInfo " + JSON.stringify(this.selectedBodyPartInfo.eases));
      this.bodyChartAnatomyDetailsForm.controls['bodyChartAnatomyNotes'].setValue(this.selectedBodyPartInfo.bodyChartAnatomyNotes);

      if (!this.selectedBodyPartInfo.hasFollowUpData) {

        this.lovDataObject = {"refId": 1,"refType":""};

        this.bodyChartAnatomyDetailsForm.controls['duration'].setValue(this.selectedBodyPartInfo.duration[0].listOfValuesMasterId);
        this.bodyChartAnatomyDetailsForm.controls['hourlySymptoms'].setValue(this.selectedBodyPartInfo.hourlySymptoms[0].listOfValuesMasterId);

        this.objectMultiSelects['description'].currentSelected = this.selectedBodyPartInfo.description;
        this.objectMultiSelects['eases'].currentSelected = this.selectedBodyPartInfo.eases;
        this.objectMultiSelects['aggs'].currentSelected = this.selectedBodyPartInfo.aggs;

        this.objectMultiSelects['description'].currentListOfValuesMasterId = this.selectedBodyPartInfo.description[0].listOfValuesMasterId;
        this.objectMultiSelects['eases'].currentListOfValuesMasterId = this.selectedBodyPartInfo.eases[0].listOfValuesMasterId;
        this.objectMultiSelects['aggs'].currentListOfValuesMasterId = this.selectedBodyPartInfo.aggs[0].listOfValuesMasterId;

      }




    }
    else {
      this.lovDataObject = {"refId":0,"refType":"","reset": true};

      this.objectMultiSelects['description'].currentSelected = [];
      this.objectMultiSelects['eases'].currentSelected = [];
      this.objectMultiSelects['aggs'].currentSelected = [];

    }

    ///  alert(this.selectedBodyPartInfo);

  }

  counter(i: number): any {
    return new Array(i);
  }


  saveAnatomyInformation(): void {

    this.bodyChartAnatomyDetailsForm.markAllAsTouched();

  ////  this.selectedBodyPartInfo.bodyChartAnatomyNotes = $('#anatomy-notes').val();



    const dataToEmit = {

      selectedBodyPartInfo: this.selectedBodyPartInfo,
      bodyChartAnatomyDetailsForm: this.bodyChartAnatomyDetailsForm,
      objectMultiSelects: this.objectMultiSelects
    };


    this.anatomyDetailsFormSubmitted.emit(dataToEmit);
    $('.pain-scale__level').siblings().removeClass("pain-scale__level--selected");
    $('#anatomy-notes').val('');


    this.bodyChartAnatomyDetailsForm.reset();

    this.bodyChartAnatomyDetailsForm.markAsUntouched();
    $('#appliationmenuTogglebtn').show();

    this.lovDataObject = {"refId":0,"refType":"","reset": true};
    this.lovDataObject = {"refId":0,"refType":"","reset": false};


  }

  translate(label) {
    return this.sharedService.verifyLabel(label,null);
  }

  toggleMenu(): void {

    this.anatomyDetailsFormSubmitted.emit(null);
    this.lovDataObject = {"refId":0,"refType":""};
    this.bodyChartAnatomyDetailsForm.reset();

    this.objectMultiSelects['description'].currentSelected = [];
    this.objectMultiSelects['eases'].currentSelected = [];
    this.objectMultiSelects['aggs'].currentSelected = [];

    this.objectMultiSelects['description'].currentListOfValuesMasterId = 0;
    this.objectMultiSelects['eases'].currentListOfValuesMasterId = 0;
    this.objectMultiSelects['aggs'].currentListOfValuesMasterId = 0;
    this.objectMultiSelects['aggs'].currentListOfValuesMasterId = 0;



  }


  findDurations() {
    let temp = [];

    this.lovServices.findAllListOfValuesByName('Duration').subscribe(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          for (let object of temp) {
            let dropDown = { label: '', value: '' };
            dropDown.value = object['listOfValuesId'];
            dropDown.label = object['label'];
            this.durationsList.push(dropDown);
          }

          console.log(this.durationsList);

        } else {
          this.durationsList = [];
        }
       // this.stopLoader();
      }, err => {
        console.error('err: ', err);
      //  this.stopLoader();
      });
  }


  findHourlysymptoms() {
    let temp = [];
    //this.startLoader();
    this.lovServices.findAllListOfValuesByName('HourlySymptoms').subscribe(
      res => {
        if (res['message'] == 'Success') {
          temp = res['data'];
          for (let object of temp) {
            let dropDown = { label: '', value: '' };
            dropDown.value = object['listOfValuesId'];
            dropDown.label = object['label'];
            this.hourlySymptomsList.push(dropDown);
          }
        } else {
          this.hourlySymptomsList = [];
        }
       // this.stopLoader();
      }, err => {
        console.error('err: ', err);
        //this.stopLoader();
      });
  }

}
