import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InvoiceItemsDetailService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    constructor(private httpClient: HttpClient) {
    };

    deleteByIdInvoiceItemsDetail(invoiceItemsDetailId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'InvoiceItemsDetail/deleteInvoiceItemsDetail', {'invoiceItemsDetailId': invoiceItemsDetailId},
            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    countInvoiceItemsDetail(invoiceItemsDetail): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'InvoiceItemsDetail/count', {'invoiceItemsDetail': invoiceItemsDetail},
                                            this.httpOptions
        ).pipe(retry(3), catchError(Constant.handleError));
    }

    updateInvoiceItemsDetail(invoiceItemsDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'InvoiceItemsDetail/updateInvoiceItemsDetail', [invoiceItemsDetail], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addInvoiceItemsDetail(invoiceItemsDetail): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'InvoiceItemsDetail/addInvoiceItemsDetail', [invoiceItemsDetail], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllInvoiceItemsDetail(invoiceItemsDetail, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'InvoiceItemsDetail/findAllInvoiceItemsDetail',
            {'invoiceItemsDetail': invoiceItemsDetail, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // findGlobalInvoiceDetail(searchTerm, pageNo: number, itemsPerPage: number): Observable<string[]>{
    //     return this.httpClient.post<string[]>(
    //         Constant.BASE_URL + 'InvoiceDetail/findGlobalInvoiceDetail',
    //         {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
    //         this.httpOptions
    //     ).pipe(
    //         retry(3), catchError(Constant.handleError));
    // }
    // custom service for global search
    invoiceDetailGlcount(searchTerm, invoiceId): Observable<number> {
        return this.httpClient.post<number>(
            Constant.BASE_URL + 'InvoiceItemsDetail/countGlobalInvoiceDetail',
            {'searchTerm': searchTerm, 'invoice_id': invoiceId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findGlobalInvoiceDetail(searchTerm, pageNo: number, itemsPerPage: number, invoiceId): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'InvoiceItemsDetail/findGlobalInvoiceDetail',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}, 'invoice_id': invoiceId},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }
}
