import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { Labels } from '../util/labels';

@Component({
               selector: 'app-viewdetail',
               templateUrl: './viewdetail.component.html',
               styleUrls: ['./viewdetail.component.css']
           })
export class ViewdetailComponent implements OnInit {

    columnsList: any = [];
    // public labels = Labels;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {
    };
    cvArray : any = [];
    signArray : any = [];
    tncArray : any = [];
    labels : any;
    ngOnInit(): void {
        // console.log(this.config.data.invoiceId, 'invoice Id');
        this.columnsList = this.config.data.mainData;
        // console.log(this.config.data['9'].value, 'config data');
        // var str = "How are you doing today?";
        // console.log("test :: ",this.config.data[9]);
        this.labels = this.config.data;
        if(this.config.data.cvUrls){
            this.cvArray = JSON.parse(this.config.data.cvUrls.value);
        }

        if(this.config.data.signUrls){
            this.signArray = JSON.parse(this.config.data.signUrls.value);
        }

        if(this.config.data.tncUrls){
            this.tncArray = JSON.parse(this.config.data.tncUrls.value);
        }
        
       
        // console.log("json array of urls :: ",this.uploadArray); 

    }

}
