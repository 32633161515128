<!--<app-app-header></app-app-header>-->
<section class="sectionmain" id="sectionmain-superAdmin">
    <div class="container-fluid">
        <div class="p-fluid p-formgrid p-grid admin-grid" id="superAdmin-grid">

          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/cpa-roles']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Cpa Roles</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>


          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/metrics']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Metrics</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>

          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/cpa-application-roles']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Cpa App Roles</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>

            <!--Active Clients-->
            <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
                <div class="adminCard adminCardBlue" [routerLink]="['/cpa-clients']">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-aClients">
                        <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                        <div class="dashboard-card-text">
                            <p>Clients</p>
                            <h3 class="dashboardText">{{activeRecords}}</h3>
                        </div>

                    </div>
                </div>
            </div>
            <!--Deactivated Clients-->
            <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-dClientsWrap">
                <div class="adminCard adminCardBlue" [routerLink]="['/deactivated_agency']">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-dClients">
                        <img src="assets/theme1/custom-files/img/icons/deactivatedClients.svg">
                        <div class="dashboard-card-text">
                            <p>Deactivated Clients</p>
                            <h3 class="dashboardText">{{deActivatedRecords}}</h3>
                        </div>

                    </div>
                </div>
            </div>
            <!--InComplete Clients-->
            <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-icClientsWrap"  [routerLink]="['/incomplete_agency']">
                <div class="adminCard adminCardBlue">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <div class="dashboard-card-content" id="superAdmin-icClients">
                        <img src="assets/theme1/custom-files/img/icons/incompleteClients.svg">
                        <div class="dashboard-card-text">
                            <p>InComplete Clients</p>
                            <h3 class="dashboardText">{{inCompleteRecords}}</h3>
                        </div>


                    </div>
                </div>
            </div>

          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/appointment_type']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Appointment Types</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>

          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/users']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Users</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>


          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/agency_type']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Company type</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>

          <div class="dashboard-card p-sm-4 p-md-6 p-lg-2" id="superAdmin-aClientsWrap">
            <div class="adminCard adminCardBlue" [routerLink]="['/appointments']">
              <ng-template pTemplate="header">
              </ng-template>
              <div class="dashboard-card-content" id="superAdmin-aClients">
                <img src="assets/theme1/custom-files/img/icons/activeClients.svg">
                <div class="dashboard-card-text">
                  <p>Appps</p>
                  <h3 class="dashboardText">{{activeRecords}}</h3>
                </div>

              </div>
            </div>
          </div>
<!--Start-Commented for UAT-->

            <!--Cases-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-casesWrap" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content" id="superAdmin-cases">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/cases.svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Cases</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <!--Total Subscriptions-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-tSubsWrap" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content" id="superAdmin-tSubs">-->
<!--                        <img src="assets/images/superAdminDashboard/subscription.png">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Total Subscriptions</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <!--Active Direct Debits-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-aDDWrap" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content" id="superAdmin-aDD">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/active-direct-debit.svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Active Direct Debits</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <!--Cancelled Direct Debits-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-cDDWrap" [routerLink]="">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content" id="superAdmin-cDD">-->
<!--                        <img src="assets/theme1/custom-files/img/icons/cancelled-direct-debit.svg">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Cancelled Direct Debits</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <!--Company Types-->
<!--            <div class="dashboard-card p-sm-4 p-md-4 p-lg-2" id="superAdmin-cTypesWrap">-->
<!--                <div class="adminCard adminCardBlue">-->
<!--                    <ng-template pTemplate="header">-->
<!--                    </ng-template>-->
<!--                    <div class="dashboard-card-content" id="superAdmin-cTypes">-->
<!--                        <img src="assets/images/superAdminDashboard/generate-companies.png">-->
<!--                        <div class="dashboard-card-text">-->
<!--                            <p>Company Types</p>-->
<!--                            <h3 class="dashboardText"></h3>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--End-Commented for UAT-->

        </div>
        <!-- </section> -->
    </div>
</section>
