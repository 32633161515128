import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig} from 'primeng/api';
import {validateSpaces} from 'src/app/util/custom.validator';
import {Constant} from '../util/constant';
import {SharedService} from '../web-services/shared.service';
import {ConstantService} from '../web-services/constant.service';
import {Labels} from '../util/labels';
import {PalpationService} from '../web-services/palpation.service';
import {UsersService} from '../web-services/users.service';
import {AppointmentsService} from '../web-services/appointments.service';
import {ListOfValuesDetailService} from '../web-services/list-of-values-detail.service';
import {ListOfValuesService} from '../web-services/list-of-values.service';
import {SessionService} from '../services/session.service';
import {PalpationMultiSelects} from '../uiUtils/pages/observations/PalpationMultiSelects';
import {FormDataDTO} from "../dto/FormDataDTO";
import {DiagnosisDataHelper} from "../util/DiagnosisDataHelper";

@Component({selector: 'app-palpation', templateUrl: './palpation.component.html', styleUrls: ['./palpation.component.css']})
export class PalpationComponent implements OnInit {

    constructor(
        private palpationServices: PalpationService,
        private appointmentsServices : AppointmentsService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        private lovServices: ListOfValuesService,
        private sessionService: SessionService,
        private palpationMultiSelects: PalpationMultiSelects
    )
    { };

    public labels = Labels;
    formButton:string  = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updatePalpationId = null;
    patientList :any = [];
    expertList :any = [];
    appointmentsList :any = [];
    palpationList :any = [];
    booleanCategoryList: any = [];
    severityCategoryList: any = [];
    palpationSearch:FormGroup ;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage:number  = Constant.ITEM_PER_PAGE;
    palpationForm:FormGroup ;
    constantList = [];
    pageNo:number  = 0;
    onValueChange = true;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';
    resetTag = true;

    expertId;
    patientId;
    appointmentId;
    caseId;
    lovDataObject : any = {};


  objectMultiSelects: any;

  getResponse($event: any): void {
    this.palpationMultiSelects.getResponseObjects($event);

  }

    ngOnInit (){
        this.addEditTitle = this.labels.btnAdd;
        this.resetTag = true;
        this.lovDataObject = {"refId":0,"refType":""};
        this.expertId = parseInt(this.sessionService.getSession('loggedInRefId'));
        this.patientId = parseInt(this.sessionService.getSession('patientId'));
        this.appointmentId = parseInt(this.sessionService.getSession('appointmentId'));
        this.caseId = parseInt(this.sessionService.getSession('caseId'));

        this.objectMultiSelects = this.palpationMultiSelects.objectMultiSelects;
        this.palpationForm = new FormGroup({
                                               palpationComments: new FormControl('', [Validators.required, validateSpaces])});

        this.palpationSearch = new FormGroup({
                                                 patientId: new FormControl(),
                                                 expertId: new FormControl(),
                                                 appointmentId: new FormControl(),
                                                 userId: new FormControl(),
                                                 tenderness: new FormControl(),
                                                 tendernessSeverity: new FormControl(),
                                                 tendernessComments: new FormControl(),
                                                 muscleSpasm: new FormControl(),
                                                 muscleSpasmSeverity: new FormControl(),
                                                 muscleSpasmComments: new FormControl(),
                                                 bonyTenderness: new FormControl(),
                                                 bonyTendernessSeverity: new FormControl(),
                                                 bonyTendernessComments: new FormControl(),});


        this.getConstantList(1234);
        this.findAllPalpationsByAppointmentId();
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.palpationSearch.valueChanges.subscribe(data => { this.onValueChange = false });
    }

  findAllPalpationsByPatientId(): void {
    this.startLoader();
    this.palpationServices.findAllPalpationsByPatientId(this.patientId).subscribe(
      res => {

        if (res.status === 200) {

          this.palpationList = res.body;

          console.log(this.palpationList);


        } else {
          this.palpationList = [];
          this.totalRecords = 0;
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

  findAllPalpationsByAppointmentId(): void {
    this.startLoader();
    this.palpationServices.findAllPalpationsByAppointmentId(this.appointmentId).subscribe(
      res => {

        if (res.status === 200) {

          this.palpationList = res.body;

          console.log(this.palpationList);


        } else {
          this.palpationList = [];
          this.totalRecords = 0;
        }
        this.stopLoader();
      }, err => {
        console.error('err: ', err);
        this.stopLoader();
      });
  }

    findBooleanCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('BooleanCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.booleanCategoryList.push(dropDown);
                    }
                } else {
                    this.booleanCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findSeverityCategory() {
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('SeverityCategory').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = {label: '', value: ''};
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.severityCategoryList.push(dropDown);
                    }
                } else {
                    this.severityCategoryList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findAllPalpation (){
        this.startLoader();
        this.palpationServices.findAllPalpation(this.palpationSearch.value, this.pageNo, this.itemPerPage).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    this.palpationList = res['data'];
                    console.log('palpation list : ', this.palpationList);
                }
                else{
                    this.palpationList = [];
                } this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }
    addUpdatePalpation (palpation ){
        this.startLoader();
        this.palpationForm.controls['expertId'].setValue(this.expertId);
        this.palpationForm.controls['patientId'].setValue(this.patientId);
        this.palpationForm.controls['appointmentId'].setValue(this.appointmentId);
        if (this.updatePalpationId == null) {
            this.palpationServices.addPalpation(this.palpationForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    palpation = res['data'][0];
                    this.palpationList.push(palpation);
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePalpation) +
                                                    this.translate(this.labels.addedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePalpation)
                    });
                    this.submitted = true;
                    this.showHideAddDialog();
                    this.countPalpation();
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }}, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        }
        else {
            palpation.palpationId = this.updatePalpationId;
            this.palpationServices.updatePalpation(palpation).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.palpationList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                                                severity: 'success',
                                                summary: this.translate(this.labels.entityNamePalpation) +
                                                    this.translate(this.labels.updatedSuccessfully),
                                                // detail: this.translate(this.labels.entityNamePalpation)
                                                });
                    this.showSearchBtn = false;
                    this.showAddBtn = false;
                    this.showSearchDialog = false;
                    this.showAddDialog = false;
                    this.editTag = false;
                    this.countPalpation();
                } else{
                    console.error(res['documentation']);
                    this.stopLoader();
                }
                this.stopLoader();
                }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            this.updatePalpationId = null;
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }
    deleteByIdPalpation (palpationId :number ,index ){
        this.confirmation.confirm({
                                      message: this.translate(this.labels.DELETE_MSG),accept: () => {
                this.startLoader();
                this.palpationServices.deleteByIdPalpation(palpationId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.palpationList.splice(index, 1);
                        this.messageService.add({
                                                    severity: 'warn',
                                                    summary: this.translate(this.labels.entityNamePalpation) +
                                                        this.translate(this.labels.deletedSuccessfully),
                                                    detail: ''
                                                    // detail: this.translate(this.labels.entityNamePalpation)
                        });
                    } else {
                        console.error('error: ', res['documentation']);
                    } }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            },
                                      key: "deletePalpation"
        });
    }
    loadPalpationToEdit (palpationId :number ,index ){
        this.addEditTitle = this.labels.btnEdit;
        let palpation = this.palpationList.find(x => x['palpationId'] == palpationId);
        this.submitted = null;
        this.formButton =this.translate(this.labels.btnEdit);
        this.updatePalpationId = palpation['palpationId'];
        this.selectedIndex = index;

        if(palpation['patientId']) {
            this.palpationForm.controls['patientId'].setValue(palpation['patientId']['patientId']);
        }
        if(palpation['expertId']) {
            this.palpationForm.controls['expertId'].setValue(palpation['expertId']['expertId']);
        }
        if(palpation['appointmentId']) {
            this.palpationForm.controls['appointmentId'].setValue(palpation['appointmentId']['appointmentId']);
        }
        if(palpation['userId']) {
            this.palpationForm.controls['userId'].setValue(palpation['userId']);
        }
        if(palpation['tenderness']) {
            this.palpationForm.controls['tenderness'].setValue(palpation['tenderness']['listOfValuesId']);
        }
        if(palpation['tendernessSeverity']) {
            this.palpationForm.controls['tendernessSeverity'].setValue(palpation['tendernessSeverity']['listOfValuesId']);
        }
        if(palpation['tendernessComments']) {
            this.palpationForm.controls['tendernessComments'].setValue(palpation['tendernessComments']);
        }
        if(palpation['muscleSpasm']) {
            this.palpationForm.controls['muscleSpasm'].setValue(palpation['muscleSpasm']['listOfValuesId']);
        }
        if(palpation['muscleSpasmSeverity']) {
            this.palpationForm.controls['muscleSpasmSeverity'].setValue(palpation['muscleSpasmSeverity']['listOfValuesId']);
        }
        if(palpation['muscleSpasmComments']) {
            this.palpationForm.controls['muscleSpasmComments'].setValue(palpation['muscleSpasmComments']);
        }
        if(palpation['bonyTenderness']) {
            this.palpationForm.controls['bonyTenderness'].setValue(palpation['bonyTenderness']['listOfValuesId']);
        }
        if(palpation['bonyTendernessSeverity']) {
            this.palpationForm.controls['bonyTendernessSeverity'].setValue(palpation['bonyTendernessSeverity']['listOfValuesId']);
        }
        if(palpation['bonyTendernessComments']) {
            this.palpationForm.controls['bonyTendernessComments'].setValue(palpation['bonyTendernessComments']);
        }

        // by ali
        if(this.showAddDialog === false && this.editTag === false){
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === false){
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if(this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
    }

    onClearSearch (){
        this.palpationSearch.reset();
        this.selectedIndex = -1;
    }
    onSearch (){
        this.clearSearchBtn = true;
        this.countPalpation();
    }
    startLoader (){
        this.loader.start();
        // setTimeout(() => {
        //     this.loader.stop();
        // }, 3000);
    }
    stopLoader (){
        this.loader.stop();
    }
    countPalpation (){
        this.palpationSearch.controls['expertId'].setValue(this.expertId);
        this.palpationSearch.controls['patientId'].setValue(this.patientId);
        this.palpationSearch.controls['appointmentId'].setValue(this.appointmentId);
        this.palpationServices.countPalpation(this.palpationSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
               // this.totalRecords = res['data'][0];
               // this.findAllPalpation();
            } else{
                this.totalRecords = 0;
            }}, err => {
            console.error('err: ', err);
        });
    }
    onSubmit (){

      const palpationDTO = new FormDataDTO(this.appointmentId, this.patientId, this.expertId, this.caseId, this.objectMultiSelects, this.palpationForm);

      const diagnosisData = new DiagnosisDataHelper(palpationDTO.diagnosisData);

      let uniqueCaseDiagnosisPredictionList = [];
      uniqueCaseDiagnosisPredictionList = diagnosisData.getUniqueDiagnosisData();
      console.log(uniqueCaseDiagnosisPredictionList);
      console.log(palpationDTO.redFlagData);

      console.log(palpationDTO);
      if (!palpationDTO.hasMultiSelectErrors && this.palpationForm.valid) {

        this.addPalpation(palpationDTO);
        if (uniqueCaseDiagnosisPredictionList.length > 0) {
          this.lovServices.createListOfCaseDiagnosisPredictions(uniqueCaseDiagnosisPredictionList).subscribe(res => {

            console.log(res);
          });
        }

        if (palpationDTO.redFlagData.length > 0) {

          this.lovServices.createListOfCaseRedFlags(palpationDTO.redFlagData).subscribe(res => {

            if (res.status === 201) {

              this.messageService.add({
                severity: 'success',
                summary: this.translate("Red Flags Updated"),
              });
            }

          });
        }

      }
      else {

        palpationDTO.multiSelectErrorFields.forEach(multiSelectError => {

          document.getElementById('#' + multiSelectError.dbField + 'Error').classList.remove('hidden');
        });
      }
    }

    addPalpation(palpationDTO: FormDataDTO): void {

      this.palpationServices.addPalpationCpa(palpationDTO).subscribe(res => {
            if (res.status === 200) {

              this.stopLoader();
              this.messageService.add({
                severity: 'success',
                summary: this.translate(this.labels.entityNamePalpation) +
                  this.translate(this.labels.addedSuccessfully),
              });

              this.lovDataObject = {"refId":0,"refType":"","reset": true};
              this.lovDataObject = {"refId":0,"refType":"","reset": false};
              this.submitted = true;
              this.findAllPalpationsByAppointmentId();
              this.showHideAddDialog();
            }
      });



    }

    onPageChnage (event :LazyLoadEvent ){
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
   /// this.findAllPalpation();
    }
    clearSearchResult (){
        this.clearSearchBtn = false;
        this.palpationSearch.reset();
       // this.countPalpation();
       //// this.findAllPalpation();
    }

    get addUpdateTitle (){
        return this.updatePalpationId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }
    translate (label ){
        return this.sharedService.verifyLabel(label, this.constantList);
    }
    getConstantList (formId ){
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }});
    }

    onClearAdd (){
        this.palpationForm.reset();
        this.palpationForm.markAsUntouched();
        this.updatePalpationId = null;
    }
    // findAllPatient (){
    //     let temp = [];
    //     this.startLoader();
    //     this.patientServices.findAllPatient(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = { label: '', value: '' }
    //                     dropDown.value = object['patientId'];
    //                     dropDown.label = object['patientId'];
    //                     this.patientList.push(dropDown);
    //                 }}else {
    //                 this.patientList = [];
    //             } this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }
    // findAllExpert (){
    //     let temp = [];
    //     this.startLoader();
    //     this.expertServices.findAllExpert(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = { label: '', value: '' }
    //                     dropDown.value = object['expertId'];
    //                     dropDown.label = object['expertId'];
    //                     this.expertList.push(dropDown);
    //                 }}else {
    //                 this.expertList = [];
    //             } this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }
    // findAllAppointments (){
    //     let temp = [];
    //     this.startLoader();
    //     this.appointmentsServices.findAllAppointments(null, null, null).subscribe(
    //         res => {
    //             if (res['message'] == 'Success') {
    //                 temp = res['data'];
    //                 for (let object of temp) {
    //                     let dropDown = { label: '', value: '' }
    //                     dropDown.value = object['appointmentsId'];
    //                     dropDown.label = object['appointmentsId'];
    //                     this.appointmentsList.push(dropDown);
    //                 }}else {
    //                 this.appointmentsList = [];
    //             } this.stopLoader();
    //         }, err => {
    //             console.error('err: ', err);
    //             this.stopLoader();
    //         });
    // }
    fieldReset (control ){
        return this.palpationForm.get(control).reset();
    }
    diplayeIconInField (control ){
        return this.palpationForm.get(control)?.valid;
    }
    inputFieldDanger (control ){
        return !this.palpationForm.get(control)?.valid && this.palpationForm?.get(control)?.touched;
    }
    onCloseAdd (){
        this.palpationForm.reset();
    }
    onCloseSearch (){
        this.palpationSearch.reset();
    }
    refresh (){
        this.countPalpation();
    }
    // by ali
    showHideSearchDialog() {
        if(this.resetTag){
            this.palpationSearch.reset();
            this.resetTag = false;
        }
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if(!this.showAddDialog) {
            this.palpationForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updatePalpationId = null;
    }

    searchTerm = null;
    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.globalSearchCount();
    }

    globalSearchCount() {
        this.palpationServices.globalSearchCount(this.searchTerm,this.appointmentId,this.expertId,this.patientId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.globalSearchFind();
            } else {
                this.totalRecords = 0;
                this.palpationList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    globalSearchFind() {
        this.palpationServices.globalSearchFind(this.searchTerm,this.appointmentId,this.expertId,this.patientId,this.pageNo, this.itemPerPage).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.palpationList = result['data'];
            } else {
                this.palpationList = [];
            }
            console.log(result, 'result');
        });
    }
}
