import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Labels} from '../util/labels';
import {Constant} from '../util/constant';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {UsersService} from '../web-services/users.service';
import {CpaRoleService} from '../web-services/cpa-role-service';
import {UserLevelService} from '../web-services/user-level.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {SharedService} from '../web-services/shared.service';
import {MessageService} from "primeng/api";


@Component({
  selector: 'app-user-levels',
  templateUrl: './user-levels.component.html',
  styleUrls: ['./user-levels.component.css'],
  providers: [DialogService]
})
export class UserLevelsComponent implements OnInit {

  public labels = Labels;
  usersLevelsList: any = [];
  cpaRoles: any = [];
  selectedCpaRoles: any = [];
  selectedCpaRoleIds: any = [];

  selectedUserId: number;
  userLevelForm: FormGroup;
  ref: DynamicDialogRef;
  addEditTitle = 'Add';
  referenceId = 0;
  clientId: number;
  itemPerPage = 10;
  totalRecords = 0;
  showSearchDialog = false;
  showSearchBtn = false;
  showAddDialog = false;
  showAddBtn = false;
  editTag = false;
  disableColumn = false;
  constantList = [];
  viewCpaRoles = false;
  selectedUserLevelId: number;
  submitted: any;
  title: any;
  updateUserLevelId: any;
  checked: boolean = true;

  options  =  [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];


  constructor(
    private usersService: UsersService,
    private cpaRoleService: CpaRoleService,
    private userLevelService: UserLevelService,
    private sharedService: SharedService,
    private loader: NgxUiLoaderService,
    private messageService: MessageService,

  ) {
  }

  ngOnInit(): void {
    this.referenceId = parseInt(sessionStorage.getItem('loggedInRefId'));
    this.clientId = parseInt(sessionStorage.getItem('clientId'));


    this.userLevelForm = new FormGroup({
      userLevelName: new FormControl('', [Validators.required]),
      userLevelDescription: new FormControl(0),
    });

    this.findAllUsersByClientId();
    this.findAllCpaRoles();

  }

  translate(label: any): any {
    return this.sharedService.verifyLabel(label, this.constantList);
  }

  findAllUsersByClientId(): void {
    this.loader.start();

    this.userLevelService.findUsersByClientId(8).subscribe(
      res => {
        console.log(res);
        if (res.status === 200) {
          this.usersLevelsList = res.body;
        } else {
          this.usersLevelsList = [];
        }
        this.loader.stop();
      }, err => {
        console.error('err: ', err);
        this.loader.stop();
      });
  }

  findAllCpaRoles(): any {
    this.loader.start();
    this.cpaRoleService.findAllLiveCpaRoles().subscribe(
      res => {

        if (res.status === 200) {
          this.cpaRoles = res.body;
        } else {
          this.cpaRoles = [];
        }
        this.loader.stop();
      }, err => {
        console.error('err: ', err);
        this.loader.stop();
      });
  }
  editCpaRoles(): void {
    this.viewCpaRoles = true;

  }

  selectCpaRole(cpaRole: any, event: any, action: any): void {

    if (event.checked) {

      /* we get all the application roles for this cpaRole and add them to applicationRoles array of the selectedCpaRole
      because my defalut all are applied but user can switch some off
       */

      let applicationRoles =  this.cpaRoles.find(cpaRoleDb => cpaRoleDb.cpaRoleId === cpaRole.cpaRoleId).cpaApplicationRoles;
      applicationRoles = applicationRoles.map(cpaApplicationRole => {

            return {
              userLevelCpaRoleApplicationRole: {
                applicationRole:  {
                  applicationRoleId: cpaApplicationRole.applicationRoleId
                },
                cpaRole: {
                  cpaRoleId: cpaRole.cpaRoleId
                }
              }
            } ;

      });

      const userLevelCpaRole = {

        userLevelCpaRole: {
          cpaRoleId: {
            cpaRoleId: cpaRole.cpaRoleId,
          },
          userLevelId: {
            userLevelId: 0,
          },
          applicationRoles: applicationRoles,
        }
      };

      this.selectedCpaRoles.push(userLevelCpaRole);

      this.selectedCpaRoleIds.push(cpaRole.cpaRoleId);

    }
    else {

      this.selectedCpaRoles = this.selectedCpaRoles.filter(userLevelCpaRole => userLevelCpaRole.cpaRoleId.cpaRoleId !== cpaRole.cpaRoleId);
      this.selectedCpaRoleIds = this.selectedCpaRoleIds.filter(cpaRoleId => cpaRoleId !== cpaRole.cpaRoleId);

    }
    }

    selectApplicationRole(cpaApplicationRole: any, cpaRole: any) {

      const checkboxElement = document.getElementById(cpaRole.cpaRoleId + '-' + cpaApplicationRole.applicationRoleId)  as HTMLInputElement;

      if (checkboxElement.checked) {

        /* we need to add back to the array */
        this.selectedCpaRoles = this.selectedCpaRoles.map(cpaRoleSelected =>  {

          if (cpaRoleSelected.cpaRoleId.cpaRoleId === cpaRole.cpaRoleId) {

           // if (!cpaRoleSelected.applicationRoles.includes(cpaApplicationRole.applicationRoleId)) {
            if (!cpaRoleSelected.applicationRoles.findIndex(cpaApplicationRoleArray =>  cpaApplicationRoleArray.userLevelCpaRoleApplicationRole.applicationRole.applicationRoleId !== cpaApplicationRole.applicationRoleId)) {

              const applicationRole = {
                userLevelCpaRoleApplicationRole: {
                  applicationRole: {
                    applicationRoleId: cpaApplicationRole.applicationRoleId
                  },
                  cpaRole: {
                    cpaRoleId: cpaRole.cpaRoleId
                  }
                }
              };
                  cpaRoleSelected.applicationRoles.push(applicationRole);
            }
          }


          return cpaRoleSelected;
        });



      }
      else {

        /* find the selected CPA role and remove the application role from the array */
        this.selectedCpaRoles = this.selectedCpaRoles.map(cpaRoleSelected =>  {

          if (cpaRoleSelected.userLevelCpaRole.cpaRoleId.cpaRoleId === cpaRole.cpaRoleId) {

         //     cpaRoleSelected.applicationRoles = cpaRoleSelected.applicationRoles.filter(applicationRole => applicationRole !== cpaApplicationRole.applicationRoleId);
              cpaRoleSelected.userLevelCpaRole.applicationRoles = cpaRoleSelected.userLevelCpaRole.applicationRoles.filter(applicationRole => applicationRole.userLevelCpaRoleApplicationRole.applicationRole.applicationRoleId !== cpaApplicationRole.applicationRoleId);



          }
         ////alert(cpaRoleSelected.applicationRoles.length);

          return cpaRoleSelected;
        });



      }


    }

  onSubmit(): void {

    this.userLevelForm.markAllAsTouched();
    console.log('cpaRoleForm form value :: ', this.userLevelForm.value);
    if (this.userLevelForm.valid) {

      this.createUserLevelAndCpaRoles(this.userLevelForm.value);
      this.submitted = false;

    }

  }

  createUserLevelAndCpaRoles(userLevelForm: any): void {

    this.loader.start();

    if (this.updateUserLevelId == null) {

      /* need to create an object with userlevel and the cparoles on the same object */

      let userLevel = userLevelForm;
      userLevel.clientId = this.clientId;

      let userLevelAndCpaRoles = {

        userLevel: userLevel,
        userLevelCpaRoles: this.selectedCpaRoles,
      };




      this.userLevelService.createUserLevelAndCpaRoles(userLevelAndCpaRoles).subscribe(res => {
        console.log(res);

        if (res.status === 201) {

           this.messageService.add(
             {
               severity: 'success',
               summary: this.translate(this.labels.entityNameUserLevels) +
                 this.translate(this.labels.addedSuccessfully),
             });

           this.submitted = true;
           this.showHideAddDialog();
         } else {
           console.error("error");
           this.loader.stop();
         }
       }, err => {

         this.messageService.add(
           {
             severity: 'error',
             summary: "opps something went wrong",
           });
         console.error('err: ', err);
         this.loader.stop();
       });
    }
  }

  showHideAddDialog(): void {

    if (!this.showAddDialog) {
      this.userLevelForm.reset();
    }
    this.addEditTitle = 'Add';
    this.showAddDialog = !this.showAddDialog;
    this.showAddBtn = this.showAddDialog;
    this.showSearchBtn = this.showAddDialog;
    this.editTag = false;
  }


  pageControlMap = new Map<string, boolean>()
    .set("viewUserLevels", false)
    .set("addUserLevel", false)
    .set("deleteUserLevel", false)
    .set("editUserLevel", false);
}

