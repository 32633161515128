import {Component, OnInit} from '@angular/core';
import {DataSharedService} from '../services/data-shared.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormControl, FormGroup} from '../../../node_modules/@angular/forms';

@Component({
               selector: 'app-logintypes',
               templateUrl: './logintypes.component.html',
               styleUrls: ['./logintypes.component.scss']
           })
export class LogintypesComponent implements OnInit {
    loggedInType = new Array<string>();
    roleSelectionForm: FormGroup = new FormGroup({
                                                     role: new FormControl(),
                                                 });

    constructor(private dataSharedService: DataSharedService, public ref: DynamicDialogRef) {
    }

    ngOnInit(): void {
    //  alert('logintypes');

        this.loggedInType = this.dataSharedService.getRoles();
        console.log('loggedIn type',this.loggedInType);
    }

    onClickBusinessRole() {
        console.log(this.roleSelectionForm.value);
        this.ref.close(this.roleSelectionForm.get('role').value);
    }

}
