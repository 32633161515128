import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ClinicsService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdClinics(clinicId): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Clinics/deleteClinics', {'clinicId': clinicId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countClinics(clinics,refId,userType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Clinics/count',
        {'clinics': clinics,'referenceData':{'userType':userType,'refId':refId}}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateClinics(clinics): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'Clinics/updateClinics', [clinics], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addClinics(clinics,refId,userType): Observable<string> {
        let refObj = {'userType':userType,'refId':refId};
        return this.httpClient.post<string>(Constant.BASE_URL + 'Clinics/addClinics',
        [clinics,refObj], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllClinics(clinics, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Clinics/findAllClinics',
            {'clinics': clinics, 'referenceData':{'userType':userType,'refId':refId}, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    // custom services to handle global searching
    clinicGlcount(searchTerm,refId,userType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'Clinics/countGlobalClinic',
                                            {'searchTerm': searchTerm,'refId':refId,'userType':userType},
                                            this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }
    findGlobalclinic(searchTerm, pageNo: number, itemsPerPage: number,refId,userType): Observable<string[]>{
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'Clinics/findGlobalClinic',
            {'searchTerm': searchTerm, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage},
            'refId':refId,'userType':userType},
            this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findByClientId(clientId: number): Observable<any> {

      return this.httpClient.get<string[]>(
        Constant.CPA_SPRING_API_URL + 'clinics/filter?type=byClient&id=' + clientId,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      ).pipe(
        retry(3), catchError(Constant.handleError));

    }

  addClinicsCpa(clinic): Observable<any> {

    return this.httpClient.post<any>(Constant.CPA_SPRING_API_URL + 'clinics',
      clinic,{
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }).pipe(
      retry(0), catchError(Constant.handleError));
  }

}
