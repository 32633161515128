import {Component, OnInit} from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking

import {DataSharedService} from './services/data-shared.service';
import {Observable} from 'rxjs';
// import {MainComponent} from 'menu-app';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import {Constant} from './util/constant';
import {SessionService} from './services/session.service';
import {Route, Router} from '@angular/router';
import {ChatService} from './collaboration/services/chat/chat.service';

// import {Observable} from 'rxjs';

@Component({
               selector: 'app-root',
               templateUrl: './app.component.html',
               styleUrls: ['./app.component.css']
           })
export class AppComponent implements OnInit {
    title = 'CPA';



    constructor(private dataSharedService: DataSharedService,
                // private menuComponent: MainComponent,
                private sessionService: SessionService,
                private router: Router,
                private chatService: ChatService,
                private afMessaging: AngularFireMessaging
    ) {
    }





    isLoggedIn$: Observable<boolean>;

    ngOnInit() {
        if (sessionStorage.getItem('loggedInUser')) {
            // debugger;
            this.dataSharedService.checkIsUserLoggedIn(true);
        }
        // debugger;
        this.isLoggedIn$ = this.dataSharedService.isLoggedIn;

        // this.dataSharedService.setUmsApplicationFrontendUrl(this.menuComponent.getApplicationFrontEndUrl(Constant.CPA_APPLICATION_ID));

    }

    getNotification(event){

        console.log("event :: " , event);


    }

}
