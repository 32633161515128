<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <!-- <section class="sectionheading" id="sectionmain-invitationpage-sectionheading"> -->
        <!-- <h1 class="h1-style1">{{translate(labels.entityNameAgencySubscriptionChecklist)}}</h1> -->
        <!-- </section> -->
        <!-- <hr class="hrseprator"> -->
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameAgencySubscriptionChecklist)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <!-- <span class="p-input-icon-right" id="invitationpage-memberslist-globalsearch"> -->
                                <!-- <i class="pi pi-search"></i> -->
                                <!-- <input pInputText type="text" placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}' /> -->
                                <!-- </span> -->
                                <!-- <span *ngIf="clearSearchBtn"> -->
                                <!-- <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-text" (click)="clearSearchResult()"></button> -->
                                <!-- </span> -->
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameAgencySubscriptionChecklist)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameAgencySubscriptionChecklist)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="agencySubscriptionChecklistSearch"
                                      class="pngForm" id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnAgencyId)}} </label>
                                            <p-dropdown [filter]="true" [options]="agencyList" [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="agencyId"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnUserId)}} </label>
                                            <p-dropdown [filter]="true" [options]="usersList" [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="userId"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnChecklistItemsId)}} </label>
                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="checklistItemsId"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnItemsStatus)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i (click)="fieldReset('itemsStatus')"
                                                                                  *ngIf="diplayeIconInField('itemsStatus')"
                                                                                  class="pi pi-times color-danger"></i> <input
                                                    class="pngInputtext"
                                                    formControlName="itemsStatus"
                                                    name="itemsStatus"
                                                    pInputText
                                                    type="text"> </span>
                                        </div>
                                        <!-- <div class="p-field p-col-12 pngbtnsdiv" id="invitationpage-searchmemeber-pngbtnsdiv"> -->
                                        <!-- <button pButton type="submit" id="invitationpage-searchmemeber-searchbtn" -->
                                        <!-- [disabled]="onValueChange" class="pngbtn btnprimaryblue" label={{translate(labels.btnSearch)}} ></button> -->
                                        <!-- <button pButton type="reset" id="invitationpage-searchmemeber-clearbtn" -->
                                        <!-- [disabled]="!this.agencySubscriptionChecklistSearch.touched" class="pngbtn btnprimaryblue-outline" -->
                                        <!-- label={{translate(labels.btnClear)}}></button> -->
                                        <!-- <button pButton type="button" (click)="onCloseSearch()" -->
                                        <!-- id="invitationpage-searchmemeber-cancelbtn" -->
                                        <!-- class="pngbtn btnprimaryblue-outline" label={{translate(labels.btnCancel)}}></button> -->
                                        <!-- </div> --></div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{translate(labels.btnAdd)}} {{translate(labels.entityNameAgencySubscriptionChecklist)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="agencySubscriptionChecklistForm"
                                      class="pngForm" id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnAgencyId)}}</label>
                                            <p-dropdown [filter]="true" [options]="agencyList" [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="agencyId"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'agencyId'"
                                                              [form]="agencySubscriptionChecklistForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnUserId)}}</label>
                                            <p-dropdown [filter]="true" [options]="usersList" [showClear]="true"
                                                        class="pngDropdown"
                                                        formControlName="userId"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'userId'"
                                                              [form]="agencySubscriptionChecklistForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnChecklistItemsId)}}</label>
                                            <p-dropdown [filter]="true" [options]="listOfValuesDetailList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="checklistItemsId"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'checklistItemsId'"
                                                              [form]="agencySubscriptionChecklistForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.agencySubscriptionChecklistColumnItemsStatus)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i (click)="fieldReset('itemsStatus')"
                                                                                  *ngIf="diplayeIconInField('itemsStatus')"
                                                                                  class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('itemsStatus')"
                                                    class="pngInputtext"
                                                    formControlName="itemsStatus"
                                                    name="itemsStatus"
                                                    pInputText
                                                    pInputText
                                                    type="text"></span>
                                            <app-custom-error [control]="'itemsStatus'"
                                                              [form]="agencySubscriptionChecklistForm"></app-custom-error>
                                        </div>
                                    </div>
                                    <!-- <div class="p-field p-col-12 pngbtnsdiv" id="invitationpage-addmemeber-pngbtnsdiv"> -->
                                    <!-- <button pButton type="submit" [disabled]="!this.agencySubscriptionChecklistForm.valid" -->
                                    <!-- [label]="addUpdateTitle" icon="pi pi-check" -->
                                    <!-- class="pngbtn btnprimaryblue"></button><button pButton type="button" -->
                                    <!-- (click)="onClearAdd()" -->
                                    <!-- [disabled]="!this.agencySubscriptionChecklistForm.touched || updateAgencySubscriptionChecklistId == null" label={{translate(labels.btnClear)}} icon="pi pi-times" class="pngbtn btnprimaryblue-outline"></button> -->
                                    <!-- <button pButton type="button" (click)="onCloseAdd()" -->
                                    <!-- id="invitationpage-addmemeber-cancelbtn" class="pngbtn btnprimaryblue-outline" -->
                                    <!-- label={{translate(labels.btnCancel)}}></button> --><!-- </div> -->
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                        <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}" type="submit" styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideAddDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div><!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <!-- {{translate(labels.entityNameAgencySubscriptionChecklist)}} {{translate(labels.LIST)}} -->
                                    <!-- <div class="pngbtnsdiv" id="invitationpage-memberslist-pngbtnsdiv"> --><span
                                        class="p-input-icon-right globalSearch"
                                        id="invitationpage-memberslist-globalsearch"><i
                                        class="pi pi-search"></i><input class="pngInputtext" pInputText
                                                                        placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                                        type="text"/></span></p-header>
                                <p-table #dv *ngIf="totalRecords > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true" [alwaysShowPaginator]="false"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [totalRecords]="totalRecords"
                                         [value]="agencySubscriptionChecklistList" class="pngTable" [ngClass]="{'pngTableEmptyMessage': agencySubscriptionChecklistList?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> AgencyId</th>
                                            <th> UserId</th>
                                            <th> ChecklistItemsId</th>
                                            <th> ItemsStatus</th>
                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.agencyId.agencyId}} </td>
                                            <td> {{element?.userId.userId}} </td>
                                            <td> {{element?.checklistItemsId.listOfValuesDetailId}} </td>
                                            <td> {{element?.itemsStatus}} </td>
                                            <td><!-- <div class="rowbtnstable"> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text p-button-plain"></button> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-plain" (click)="loadAgencySubscriptionChecklistToEdit(element.agencySubscriptionChecklistId, i)"></button> -->
                                                <!-- <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-plain" (click)="deleteByIdAgencySubscriptionChecklist(element.agencySubscriptionChecklistId,i)"></button> -->
                                                <!-- </div> -->
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadAgencySubscriptionChecklistToEdit(element.agencySubscriptionChecklistId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button
                                                            (click)="deleteByIdAgencySubscriptionChecklist(element.agencySubscriptionChecklistId,i)"
                                                            icon="pi pi-trash"
                                                            pTooltip="Delete"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button icon="pi pi-eye" pTooltip="View"
                                                              styleClass="p-button pbtn-transparent"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template
                                            pTemplate="emptymessage">{{translate(labels.mat_pagination_no_data_msg)}}</ng-template>
                                </p-table>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
