import {Component, OnInit} from '@angular/core';

@Component({
               selector: 'app-managesubscription',
               templateUrl: './managesubscription.component.html',
               styleUrls: ['./managesubscription.component.css']
           })
export class ManagesubscriptionComponent implements OnInit {


    searchCompanies;
    selectionButtonDataOne: any[];
    selectionButtonDataTwo: any[];
    subscriptions;
    selectBtnValueOne: string = 'required';
    selectBtnValueTwo: string = 'deactivate';

    constructor() {
    }

    ngOnInit(): void {
        this.searchCompanies = [
            {
                'name': 'Company 1'
            },
            {
                'name': 'Company 2'
            },
            {
                'name': 'Company 3'
            },
            {
                'name': 'Company 4'
            }
        ];
        this.selectionButtonDataOne = [
            {label: 'Required', value: 'required'},
            {label: 'Not Required', value: 'not required'}
        ];
        this.selectionButtonDataTwo = [
            {label: 'Activate', value: 'activate'},
            {label: 'Deactivate', value: 'deactivate'}
        ];
        this.subscriptions = [
            {
                'id': 1, 'title': 'Exercise Tool Portal', 'sButton': 'required'
            },
            {
                'id': 2, 'title': 'Single or Multiple Users', 'sButton': 'required'
            },
            {
                'id': 3, 'title': 'Clinical Notes Portal', 'sButton': 'required'
            },
            {
                'id': 4, 'title': 'SMS Activation', 'sButton': 'activate'
            },
            {
                'id': 5, 'title': 'CPD Portal', 'sButton': 'activate'
            },
            {
                'id': 6, 'title': 'Physiotherapy Tutorial', 'sButton': 'activate'
            },
            {
                'id': 7, 'title': 'Bespoke', 'sButton': 'required'
            }

        ];
    }
}
