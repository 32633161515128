import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { AuthToken } from '../../utilities/interfaces/interfaces';
import { AppSettings } from '../../utilities/constants/constants';
import { SharedService } from '../sharedservice/shared.service';
import { WebService } from '../webservice/web.service';

@Injectable({
  providedIn: 'root'
})
export class NonInterceptService {

  private httpClient: HttpClient;
  constructor(private sharedService: SharedService, handler: HttpBackend, private cookieService: CookieService
    , private webService: WebService) {
    this.httpClient = new HttpClient(handler);
  }

  updateCurrentRole(activatedRole, projectId, applicationId) {
    return this.httpClient.post<AuthToken>(this.sharedService.getApplicationBackEndUrl(AppSettings.UMS_APPLICATION_ID) + AppSettings.UMS_API_ENDPOINT + "updateActiveRole", { "cprojectId": projectId, "userId": this.sharedService.getCookie('loggedInUser'), "businessRoleId": activatedRole, "applicationId": applicationId }).pipe(catchError(this.errorHandler));
  }

  getAllApplication() {
    return this.httpClient.post(this.webService.getInitialUrl() + AppSettings.UMS_API_ENDPOINT + 'getAllApplication', {});
  }

  // Http Error handling
  errorHandler(error: HttpErrorResponse) {
    if (!navigator.onLine) {
      return throwError('Error : Connection Error');
    } else {
      return throwError('Error : Server Error');
    }

  }

}
