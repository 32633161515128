import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constant} from '../util/constant';
import {catchError, retry} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AgencyTypeService {
    httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    constructor(private httpClient: HttpClient) {
    };

    deleteByIdAgencyType(agencyTypeId): Observable<string> {
        return this.httpClient.post<string>(
            Constant.BASE_URL + 'AgencyType/deleteAgencyType', {'agencyTypeId': agencyTypeId}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    countAgencyType(agencyType): Observable<number> {
        return this.httpClient.post<number>(Constant.BASE_URL + 'AgencyType/count', {'agencyType': agencyType}, this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    updateAgencyType(agencyType): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyType/updateAgencyType', [agencyType], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    addAgencyType(agencyType): Observable<string> {
        return this.httpClient.post<string>(Constant.BASE_URL + 'AgencyType/addAgencyType', [agencyType], this.httpOptions).pipe(
            retry(3), catchError(Constant.handleError));
    }

    findAllAgencyType(agencyType, pageNo: number, itemsPerPage: number): Observable<string[]> {
        return this.httpClient.post<string[]>(
            Constant.BASE_URL + 'AgencyType/findAllAgencyType',
            {'agencyType': agencyType, 'pagination': {'pageNo': pageNo, 'itemsPerPage': itemsPerPage}}, this.httpOptions
        ).pipe(
            retry(3), catchError(Constant.handleError));
    }

}
