<!-- Header Component-->
<!--<p-toast position="top-center"></p-toast>-->
<p-confirmDialog key="deleteTestingNeuro" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ngx-ui-loader></ngx-ui-loader>
<!-- Main Invitation Page-->
<section class="sectionmain" id="sectionmain-invitationpage">
    <div class="container">
        <section class="sectioncontent" id="sectionmain-invitationpage-sectioncontent">
            <div class="overlaypaneldivContainer" id="invitationpage-overlaypaneldivContainer">
                <div class="overlaypaneldiv" id="overlaymain">
                    <p-panel class="pngPanelMain" id="patients-pngPanelMain">
                        <p-header> {{translate(labels.entityNameTestingNeuro)}}
                            <div class="pngbtnsdiv" id="patients-patientslist-pngbtnsdiv">
                                <button class="p-button-rounded p-button-text" id="searchOverlayBtn" icon="pi pi-search-plus"
                                        (click)="showHideSearchDialog()" [hidden]="showSearchBtn"
                                        pButton
                                        pTooltip="Advanced Search" tooltipPosition="bottom"
                                        type="button">
                                </button>
                                <button class="p-button-rounded p-button-text" id="addOverlayBtn" icon="pi pi-plus"
                                        (click)="showHideAddDialog()" [hidden]="showAddBtn"
                                        pButton
                                        pTooltip="{{addEditTitle}} {{translate(labels.entityNameTestingNeuro)}}"  tooltipPosition="bottom" type="button">
                                </button>
                            </div>
                        </p-header>
<!-- Advance Search DialogBox-->
                        <div [ngClass]="{'displayBlock' : showSearchDialog == true, 'displayNone' : showSearchDialog == false }"  class="overlaypaneldiv" id="overlaypaneldiv-search">
                            <p-panel class="pngPanel" id="invitationpage-searchmemeber-pngPanel">
                                <p-header>{{translate(labels.btnSearch)}} {{translate(labels.entityNameTestingNeuro)}}</p-header>
                                <form (ngSubmit)="onSearch()" [formGroup]="testingNeuroSearch" class="pngForm"
                                      id="invitationpage-searchmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-searchmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnDermatome)}} </label>
                                            <p-dropdown [filter]="true" [options]="dermatomeList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="dermatome"
                                                        placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnDermatomeSide)}} </label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="dermatomeSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnDermatomeComments)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('dermatomeComments')"
                                                    *ngIf="diplayeIconInField('dermatomeComments')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    class="pngInputtext" formControlName="dermatomeComments"
                                                    name="dermatomeComments" pInputText
                                                    type="text"> </span></div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnReflexesSide)}} </label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="reflexesSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-searchpatient-pngPanel-footer">
                                        <p-button label="Search" (click)="onSearch()" styleClass="p-button" class="pngBlueBtn"></p-button>
                                         <p-button label="Clear" (click)="clearSearchResult()"
                                                  styleClass="p-button" class="pngBlueBtn"></p-button>
                                        <p-button label="Close" (click)="showHideSearchDialog()"
                                                  styleClass="p-button" class="pngBtnTransparent"></p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Add DialogBox-->
                        <div [ngClass]="{'displayBlock' : showAddDialog == true, 'displayNone' : showAddDialog == false }" class="overlaypaneldiv" id="overlaypaneldiv-add">
                            <p-panel class="pngPanel" id="invitationpage-addmemeber-pngPanel">
                                <p-header>{{addEditTitle}} {{translate(labels.entityNameTestingNeuro)}}</p-header>
                                <form (ngSubmit)="onSubmit()" [formGroup]="testingNeuroForm" class="pngForm"
                                      id="invitationpage-addmemeber-pngForm">
                                    <div class="p-fluid p-formgrid p-grid" id="invitationpage-addmemeber-pgrid">
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnDermatome)}}</label>
                                            <p-dropdown [filter]="true" [options]="dermatomeList"
                                                        [showClear]="true"
                                                        class="pngDropdown" formControlName="dermatome"
                                                        placeholder="Select"></p-dropdown>
                                            <app-custom-error [control]="'dermatome'" [form]="testingNeuroForm"></app-custom-error>
                                        </div>
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnDermatomeSide)}}</label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="dermatomeSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                            <app-custom-error [control]="'dermatomeSide'" [form]="testingNeuroForm"></app-custom-error>
                                        </div>

                                        <!-- Dermatome Description -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['dermatomeDescription'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['dermatomeDescription'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['dermatomeDescription'].currentSelected"></app-lov-details>
                                          <span id="#dermatomeDescriptionError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <div class="p-field p-col-12 p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnDermatomeComments)}}</label>
                                            <span
                                                    class="p-input-icon-right"><i
                                                    (click)="fieldReset('dermatomeComments')"
                                                    *ngIf="diplayeIconInField('dermatomeComments')"
                                                    class="pi pi-times color-danger"></i> <input
                                                    [class.p-invalid]="inputFieldDanger('dermatomeComments')"
                                                    class="pngInputtext" formControlName="dermatomeComments"
                                                    name="dermatomeComments" pInputText
                                                    pInputText type="text"></span>
                                            <app-custom-error [control]="'dermatomeComments'" [form]="testingNeuroForm"></app-custom-error>
                                        </div>

                                        <!-- Reflexes -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['reflexes'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['reflexes'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['reflexes'].currentSelected"></app-lov-details>
                                          <span id="#reflexesError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>

                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                            <label>{{translate(labels.testingNeuroColumnReflexesSide)}}</label>
                                            <p-selectButton
                                                    [options]="sidesCategoryList"
                                                    formControlName="reflexesSide"
                                                    [optionValue]="sidesCategoryList['listOfValuesId']">
                                            </p-selectButton>
                                            <app-custom-error [control]="'reflexesSide'" [form]="testingNeuroForm"></app-custom-error>
                                        </div>

                                        <!-- Reflexes Description -->
                                        <div class="p-field p-col-12  p-md-6 formfieldheight">
                                          <app-lov-details [categoryString]="objectMultiSelects['reflexesDescription'].name" (getResponse)="getResponse($event)"
                                                           label="{{objectMultiSelects['reflexesDescription'].label}}"
                                                           [dataObj]="lovDataObject" [currentSelectedArray]="objectMultiSelects['reflexesDescription'].currentSelected"></app-lov-details>
                                          <span id="#reflexesDescriptionError" class="no-Item-Error hidden">{{translate(labels.requiredFieldMessage)}}</span>
                                        </div>
                                    </div>
                                    <div class="pngPanel-footer" id="patients-addpatient-pngPanel-footer">
                                         <p-button label="{{addEditTitle === labels.btnAdd? labels.btnSave : labels.btnUpdate}}"
                                                   type="submit"
                                                   styleClass="p-button"
                                                   class="pngBlueBtn">
                                         </p-button>
                                        <p-button label="Close"
                                                  (click)="showHideAddDialog()"
                                                  styleClass="p-button"
                                                  class="pngBtnTransparent">
                                        </p-button>
                                    </div>
                                </form>
                            </p-panel>
                        </div>
<!-- Table Data -->
                        <div class="overlaypaneldiv" id="overlaypaneldiv-list">
                            <p-panel class="pngPanel pngPanelList" id="invitationpage-memberslist-pngPanel">
                                <p-header>
                                    <span   class="p-input-icon-right globalSearch">
                                        <i  class="pi pi-search" (click) = "onGlobalSearchClick(global.value)"></i>
                                        <input #global class="pngInputtext" pInputText
                                                placeholder='{{translate(labels.GLOBEL)}} {{translate(labels.btnSearch)}}'
                                                type="text"/>
                                    </span>
                                </p-header>
                                <p-table #dv *ngIf="testingNeuroListOfItems.length > 0" (onLazyLoad)="onPageChnage($event)" [autoLayout]="true"
                                         [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} entries'"
                                         [lazy]="true" [paginator]="true" [resizableColumns]="true"
                                         [rowsPerPageOptions]="[10,20,30]" [rows]="itemPerPage"
                                         [showCurrentPageReport]=true
                                         [alwaysShowPaginator]="false"
                                         [totalRecords]="totalRecords"
                                         [value]="testingNeuroListOfItems" class="pngTable" [ngClass]="{'pngTableEmptyMessage': testingNeuroListOfItems?.length == 0}"
                                         id="invitationpage-memberslist-table"
                                         styleClass="p-datatable-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th> Dermatome</th>
                                            <th> Dermatome Side</th>

                                            <th> Comments</th>

                                            <th> Reflexes Side</th>

                                            <th>{{translate(labels.headerAction)}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-element let-i="rowIndex" pTemplate="body">
                                        <tr>
                                            <td> {{element?.dermatome}} </td>
                                            <td> {{element?.dermatomeSide}} </td>

                                            <td> {{element?.dermatomeComments}} </td>
                                            <td> {{element?.reflexesSide}} </td>
                                            <td>
                                                <div class="pngTable-icons">
                                                    <p-button
                                                            (click)="loadTestingNeuroToEdit(element.testingNeuroId, i)"
                                                            icon="pi pi-pencil"
                                                            pTooltip="Edit"
                                                            styleClass="p-button pbtn-transparent"></p-button>
                                                    <p-button (click)="deleteByIdTestingNeuro(element.testingNeuroId,i)"
                                                              icon="pi pi-trash"
                                                              pTooltip="Delete"
                                                              styleClass="p-button pbtn-transparent"></p-button>
<!--                                                    <p-button icon="pi pi-eye" label="View"-->
<!--                                                              styleClass="p-button pbtn-transparent"></p-button>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="totalRecords < 1">
                                    <h3 class="p-mt-4 textBold h3-style superAdminTitles" style="text-align: center;">No Record to Show</h3>
                                </div>
                            </p-panel>
                        </div>
                    </p-panel>
                </div>
            </div>
        </section>
    </div>
</section>
