import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { Labels } from '../util/labels';
import { InvoiceItemsDetailService } from '../web-services/invoice-items-detail.service';
import { InvoiceService } from '../web-services/invoice.service';
import { validateSpaces } from '../util/custom.validator';
import { Constant } from '../util/constant';
import { ConstantService } from '../web-services/constant.service';
import { SharedService } from '../web-services/shared.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListOfValuesService } from '../web-services/list-of-values.service';
import { ViewdetailComponent } from '../viewdetail/viewdetail.component';
import { first } from 'rxjs/operators';
import {Utils} from '../util/utils';

// declare var openAddOverlaydiv: Function;
// declare var openDialogAddOverlaydiv: Function;
// declare var closeDialogAddOverlaydiv: Function;
// declare var openDialogSearchOverlaydiv: Function;
// declare var closeDialogSearchOverlaydiv: Function;
//
// declare var closeDialogAddSerachOverlaydiv: Function;


@Component({
    selector: 'app-invoice-items-detail',
    templateUrl: './invoice-items-detail.component.html',
    styleUrls: ['./invoice-items-detail.component.css']
})
export class InvoiceItemsDetailComponent implements OnInit {
    public labels = Labels;
    utils = new Utils();
    formButton: string = this.translate(this.labels.btnAdd);
    submitted = false;
    selectedIndex = -1;
    updateInvoiceItemsDetailId = null;
    invoiceList: any = [];
    // listOfValuesDetailList: any = [];
    itemsList: any = [];
    invoiceItemsDetailList: any = [];
    invoiceItemsDetailSearch: FormGroup;
    clearSearchBtn = false;
    totalRecords;
    itemPerPage: number = Constant.ITEM_PER_PAGE;
    invoiceItemsDetailForm: FormGroup;
    constantList = [];
    pageNo: number = 0;
    onValueChange = true;
    invoiceId: any;
    invoiceObj: any;
    invoiceIssueDate: any;
    totalInvoiceAmount = 0;
    InvoiceAmount = 0;
    invoiceForm: FormGroup;

    showSearchDialog = false;
    showSearchBtn = false;
    showAddDialog = false;
    showAddBtn = false;
    editTag = false;
    disableColumn = false;
    passwordType = 'password';

    addEditTitle = '';

    invalidDiscount = false;

    constructor(
        private invoiceItemsDetailServices: InvoiceItemsDetailService,
        private invoiceServices: InvoiceService,
        private lovServices: ListOfValuesService,
        private primengConfig: PrimeNGConfig,
        private confirmation: ConfirmationService,
        private messageService: MessageService,
        private route: Router,
        private loader: NgxUiLoaderService,
        private constantService: ConstantService,
        private sharedService: SharedService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private dialogService: DialogService
    ) {
    };

   /// ref: DynamicDialogRef;

    // Temporary -> will see to minimize this later
    viewInvoiceDetail(invoiceItemsDetailId: number) {
        console.log('modal called');
        this.ref = null;
        let invoiceItemsDetail = this.invoiceItemsDetailList.find(x => x['invoiceItemsDetailId'] == invoiceItemsDetailId);

        this.ref = this.dialogService.open(ViewdetailComponent, {
            header: 'Invoice Item Detail',
            width: '80%',
            contentStyle: { 'max-height': '750px', 'overflow': 'auto' },
            baseZIndex: 10000,
            data: {
                mainData: [
                    { label: this.labels.invoiceItemsDetailColumnItemId, value: invoiceItemsDetail['itemId']['label'] },
                    { label: this.labels.invoiceItemsDetailColumnQuantity, value: invoiceItemsDetail['quantity'] },
                    { label: this.labels.invoiceItemsDetailColumnUnitPrice, value: invoiceItemsDetail['unitPrice'] },
                    { label: this.labels.invoiceItemsDetailColumnDiscount, value: invoiceItemsDetail['discount'] },
                    { label: this.labels.invoiceItemsDetailColumnTaxAmount, value: invoiceItemsDetail['taxAmount'] },
                    { label: this.labels.invoiceItemsDetailColumnTotalAmount, value: invoiceItemsDetail['totalAmount'] },
                    { label: this.labels.invoiceItemsDetailColumnAdditionalNotes, value: invoiceItemsDetail['additionalNotes'] },
                    { invoice: this.invoiceId }
                ]
            },

        });
        this.ref.onClose.subscribe(res => {
            // this.countInvoice();
        });
    }

    convertDate(issueDate) {
        let date = new Date(issueDate);
        console.log(date.toString());
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();
        this.invoiceIssueDate = yyyy + '-' + mm + '-' + dd;
    }

    ngOnInit() {
        this.addEditTitle = this.labels.btnAdd;
        console.log(this.config.data.invoiceObj, ' :: invoice object on init ');
        this.invoiceObj = this.config.data.invoiceObj;
        this.invoiceId = this.config.data.invoiceObj.invoiceId;
        this.convertDate(this.config.data.invoiceObj.issueDate);
        this.InvoiceAmount = this.config.data.invoiceObj.invoiceAmount;
        this.findItems();
        this.invoiceItemsDetailForm = new FormGroup({
            invoiceId: new FormControl(this.invoiceId),
            itemId: new FormControl('', [Validators.required]),
            quantity: new FormControl(0, [Validators.required, Validators.min(0)]),
            unitPrice: new FormControl(0, [Validators.required, Validators.min(0)]),
            discount: new FormControl(0, [Validators.required, Validators.min(0)]),
            taxAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
            totalAmount: new FormControl(0, [Validators.required, Validators.min(0)]),
            additionalNotes: new FormControl('', [Validators.required, validateSpaces]),
            // userId: new FormControl(0, [Validators.required, Validators.min(0)]),
        });
        // Setting invoice Details form initial values
        this.invoiceItemsDetailForm.controls['invoiceId'].setValue(0);
        this.invoiceItemsDetailForm.controls['quantity'].setValue(0);
        this.invoiceItemsDetailForm.controls['unitPrice'].setValue(0);
        this.invoiceItemsDetailForm.controls['discount'].setValue(0);
        this.invoiceItemsDetailForm.controls['taxAmount'].setValue(0);

        this.invoiceItemsDetailSearch = new FormGroup({
            invoiceId: new FormControl(this.invoiceId),
            itemId: new FormControl(),
            quantity: new FormControl(),
            unitPrice: new FormControl(),
            discount: new FormControl(),
            taxAmount: new FormControl(),
            totalAmount: new FormControl(0),
            additionalNotes: new FormControl(),
            // userId: new FormControl(),
        });
        // Setting invoice items search form initial values
        // this.invoiceItemsDetailSearch.controls['invoiceId'].setValue(this.invoiceId);
        this.countInvoiceItemsDetail();
        // closeDialogAddSerachOverlaydiv();
        this.getConstantList(1234);
        this.primengConfig.ripple = true;
        this.clearSearchBtn = false;
        this.invoiceItemsDetailSearch.valueChanges.subscribe(data => {
            this.onValueChange = false;
        });
    }

    get addUpdateTitle() {
        return this.updateInvoiceItemsDetailId == null ? this.translate(this.labels.btnAdd) : this.translate(this.labels.btnUpdate);
    }

    onClearSearch() {
        this.invoiceItemsDetailSearch.reset();
        this.selectedIndex = -1;
    }

    onSearch() {
        this.clearSearchBtn = true;
        this.countInvoiceItemsDetail();
        // closeDialogSearchOverlaydiv();
    }

    startLoader() {
        this.loader.start();
    }

    stopLoader() {
        this.loader.stop();
    }

    countInvoiceItemsDetail() {
        this.invoiceItemsDetailSearch.controls['invoiceId'].setValue(this.invoiceId);
        this.invoiceItemsDetailServices.countInvoiceItemsDetail(this.invoiceItemsDetailSearch.value).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllInvoiceItemsDetail();

            } else {
                this.totalRecords = 0;
                this.invoiceItemsDetailList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    onSubmit() {
        this.invoiceItemsDetailForm.markAllAsTouched();
        this.invoiceItemsDetailForm.controls['invoiceId'].setValue(this.invoiceId);
        console.log(this.invoiceItemsDetailForm, 'invoice form before saving');
        if (this.invoiceItemsDetailForm.valid && !this.invalidQuantity && !this.invalidDiscount && !this.invalidUnitPrice && !this.invalidTaxAmount) {
            this.addUpdateInvoiceItemsDetail(this.invoiceItemsDetailForm.value);
            this.submitted = false;
            this.onClearAdd();
        }
    }

    onPageChnage(event: LazyLoadEvent) {
        this.itemPerPage = event.rows;
        this.pageNo = event.first / event.rows;
        if (!this.searchTerm) {
            this.findAllInvoiceItemsDetail();
        } else {
            this.findAllGlSearchInvoiceDetail();
        }
    }

    clearSearchResult() {
        this.clearSearchBtn = false;
        this.invoiceItemsDetailSearch.reset();
        this.countInvoiceItemsDetail();
    }

    addUpdateInvoiceItemsDetail(invoiceItemsDetail) {
        this.startLoader();
        if (this.updateInvoiceItemsDetailId == null) {
            this.invoiceItemsDetailServices.addInvoiceItemsDetail(this.invoiceItemsDetailForm.value).subscribe(res => {
                if (res['message'] == 'Success') {
                    invoiceItemsDetail = res['data'][0];
                    this.invoiceItemsDetailList.push(invoiceItemsDetail);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Invoice Detail Added Successfully',
                        detail: ''
                    });
                    this.showHideAddDialog();
                    this.updateTotalInvoice();
                    this.countInvoiceItemsDetail();
                    this.submitted = true;
                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
        } else {
            invoiceItemsDetail.invoiceItemsDetailId = this.updateInvoiceItemsDetailId;
            this.invoiceItemsDetailServices.updateInvoiceItemsDetail(invoiceItemsDetail).subscribe(res => {
                if (res['message'] == 'Success') {
                    this.invoiceItemsDetailList[this.selectedIndex] = res['data'][0];
                    this.submitted = true;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Invoice Detail Updated Successfully',
                        detail: ''
                    });
                    this.showHideAddDialog();
                    this.updateTotalInvoice();
                    this.countInvoiceItemsDetail();
                    this.submitted = true;
                    // now find sum of amounts added against this invoice and update in invoice table -> in invoiceamount column

                } else {
                    console.error(res['documentation']);
                    this.stopLoader();
                }
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
            // this.updateInvoiceItemsDetailId = null;
            this.onClearAdd();
            this.formButton = this.translate(this.labels.btnAdd);
        }
    }

    translate(label) {
        return this.sharedService.verifyLabel(label, this.constantList);
    }

    getConstantList(formId) {
        this.constantService.getFormConstantsList(formId).subscribe(data => {
            if (data['statusCode'] == 200) {
                this.constantList = data['data'];
            } else {
                this.constantList = [];
            }
        });
    }

    findAllInvoiceItemsDetail() {
        this.startLoader();
        this.invoiceItemsDetailServices.findAllInvoiceItemsDetail(
            this.invoiceItemsDetailSearch.value, this.pageNo, this.itemPerPage).subscribe(
                res => {
                    if (res['message'] == 'Success') {
                        this.invoiceItemsDetailList = res['data'];
                        // this.getTotalAmount();
                    } else {
                        this.invoiceItemsDetailList = [];
                    }
                    this.stopLoader();
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
    }

    getTotalAmount() {
        this.invoiceItemsDetailList.map(d => this.totalInvoiceAmount = this.totalInvoiceAmount + d.totalAmount);
        this.InvoiceAmount = this.totalInvoiceAmount;
    }

    updateTotalInvoice() {
        console.log(this.invoiceItemsDetailList, 'invoice detail list');
        this.totalInvoiceAmount = 0;
        this.getTotalAmount();
        this.invoiceForm = new FormGroup({
            invoiceId: new FormControl(),
            patientId: new FormControl(),
            agencyId: new FormControl(),
            invoiceTo: new FormControl(),
            issueDate: new FormControl('', [Validators.required]),
            issueTo: new FormControl(0, [Validators.required, Validators.min(0)]),
            issueBy: new FormControl(),
            userId: new FormControl(),
            companyId: new FormControl(),
            issueByType: new FormControl(),
            issueToType: new FormControl(),
            invoiceAmount: new FormControl(0)
        });
        // if(this.invoiceObj['patientId']){
        this.invoiceForm.controls['patientId'].setValue(this.invoiceObj['patientId']);
        // }

        // if(this.invoiceObj['agencyId']){
        this.invoiceForm.controls['agencyId'].setValue(this.invoiceObj['agencyId']);
        // }

        // if(this.invoiceObj['invoiceTo']){
        this.invoiceForm.controls['invoiceTo'].setValue(this.invoiceObj['invoiceTo']);
        // }

        // this.invoiceForm.controls['issueDate'].setValue(this.invoiceIssueDate);

        // if(this.invoiceObj['issueTo']){
        this.invoiceForm.controls['issueTo'].setValue(this.invoiceObj['issueTo']['agencyId']);
        // }

        // if(this.invoiceObj['issueBy']){
        this.invoiceForm.controls['issueBy'].setValue(this.invoiceObj['issueBy']['userId']);
        // }

        // if(this.invoiceObj['userId']){
        this.invoiceForm.controls['userId'].setValue(this.invoiceObj['userId']);
        // }

        // if(this.invoiceObj['companyId']){
        this.invoiceForm.controls['companyId'].setValue(this.invoiceObj['companyId']);
        // }

        // if(this.invoiceObj['issueByType']){
        this.invoiceForm.controls['issueByType'].setValue(this.invoiceObj['issueByType']);
        // }
        // if(this.invoiceObj['issueToType']){
        this.invoiceForm.controls['issueToType'].setValue(this.invoiceObj['issueToType']);
        // }
        // if(this.invoiceObj['invoiceAmount']){
        this.invoiceForm.controls['invoiceAmount'].setValue(this.totalInvoiceAmount);
        // }
        // if(this.invoiceObj['invoiceId']){
        this.invoiceForm.controls['invoiceId'].setValue(this.invoiceObj['invoiceId']);
        // }
        console.log(this.invoiceForm.value, 'detail form updated');


        this.invoiceForm.controls['issueDate'].setValue(this.invoiceIssueDate);
        let obj =this.utils.getUpdatedMillissecondObject(this.invoiceForm.value,"issueDate");
        // return;
        this.invoiceServices.updateInvoice(obj).subscribe(res => {
            if (res['message'] == 'Success') {
                // this.invoiceList[this.selectedIndex] = res['data'][0];
                // this.submitted = true;
                if (!this.updateInvoiceItemsDetailId) {
                    // this.messageService.add(
                    //     {
                    //         severity: 'success',
                    //         summary: 'Invoice Detail Added Successfully',
                    //         detail: ''
                    //     });
                } else {
                    // this.messageService.add(
                    //     {
                    //         severity: 'success',
                    //         summary: 'Invoice Detail Updated Successfully',
                    //         detail: ''
                    //     });
                }
                this.updateInvoiceItemsDetailId == null;

            } else {
                console.error(res);
                this.stopLoader();
            }
        }, err => {
            console.error('err: ', err);
            this.stopLoader();
        });
    }
    deleteRef: any;
    deleteByIdInvoiceItemsDetail(invoiceItemsDetailId: number, index) {
        this.deleteRef = this.confirmation.confirm({
            message: this.translate(this.labels.DELETE_MSG), accept: () => {
                this.startLoader();
                this.invoiceItemsDetailServices.deleteByIdInvoiceItemsDetail(invoiceItemsDetailId).subscribe(res => {
                    if (res['message'] == 'Success') {
                        this.invoiceItemsDetailList.splice(index, 1);
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Invoice Detail Deleted Successfully',
                            detail: ''
                        });
                        this.updateTotalInvoice();
                        this.countInvoiceItemsDetail();
                        this.submitted = true;
                    } else {
                        console.error('error: ', res['documentation']);
                        this.stopLoader();
                    }
                }, err => {
                    console.error('err: ', err);
                    this.stopLoader();
                });
            }
        });
        console.log(this.deleteRef, 'delete reference');
        this.deleteRef = false;
    }

    loadInvoiceItemsDetailToEdit(invoiceItemsDetailId: number, index) {
        this.addEditTitle = this.labels.btnEdit;
        let invoiceItemsDetail = this.invoiceItemsDetailList.find(x => x['invoiceItemsDetailId'] == invoiceItemsDetailId);
        this.submitted = null;
        this.formButton = this.translate(this.labels.btnEdit);
        this.updateInvoiceItemsDetailId = invoiceItemsDetail['invoiceItemsDetailId'];
        this.selectedIndex = index;
        this.invoiceItemsDetailForm.controls['invoiceId'].setValue(invoiceItemsDetail['invoiceId']['invoiceId']);
        this.invoiceItemsDetailForm.controls['itemId'].setValue(invoiceItemsDetail['itemId']['listOfValuesId']);
        this.invoiceItemsDetailForm.controls['quantity'].setValue(invoiceItemsDetail['quantity']);
        this.invoiceItemsDetailForm.controls['unitPrice'].setValue(invoiceItemsDetail['unitPrice']);
        this.invoiceItemsDetailForm.controls['discount'].setValue(invoiceItemsDetail['discount']);
        this.invoiceItemsDetailForm.controls['taxAmount'].setValue(invoiceItemsDetail['taxAmount']);
        this.invoiceItemsDetailForm.controls['totalAmount'].setValue(invoiceItemsDetail['totalAmount']);
        this.invoiceItemsDetailForm.controls['additionalNotes'].setValue(invoiceItemsDetail['additionalNotes']);
        // this.invoiceItemsDetailForm.controls['userId'].setValue(invoiceItemsDetail['userId']);
        // openDialogAddOverlaydiv();

        // by ali
        if (this.showAddDialog === false && this.editTag === false) {
            this.editTag = true;
            this.showAddDialog = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === false) {
            this.editTag = true;
            this.showAddBtn = true;
            this.showSearchBtn = true;
            this.showSearchDialog = false;
        } else if (this.showAddDialog === true && this.editTag === true) {
            this.editTag = false;
            this.showAddDialog = false;
            this.showAddBtn = false;
            this.showSearchBtn = false;
            this.showSearchDialog = false;
        }
        this.disableColumn = true;
    }

    onClearAdd() {
        this.invoiceItemsDetailForm.reset();
        this.invoiceItemsDetailForm.markAsUntouched();
        this.updateInvoiceItemsDetailId = null;
        // closeDialogAddOverlaydiv();
    }

    findAllInvoice() {
        let temp = [];
        this.startLoader();
        this.invoiceServices.findAllInvoice(null, null, null).subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['invoiceId'];
                        dropDown.label = object['invoiceId'];
                        this.invoiceList.push(dropDown);
                    }
                    // console.log(this.invoiceList, 'invoice list');
                } else {
                    this.invoiceList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    findItems() {
        console.log('finditems called');
        let temp = [];
        this.startLoader();
        this.lovServices.findAllListOfValuesByName('Items').subscribe(
            res => {
                if (res['message'] == 'Success') {
                    temp = res['data'];
                    for (let object of temp) {
                        let dropDown = { label: '', value: '' };
                        dropDown.value = object['listOfValuesId'];
                        dropDown.label = object['label'];
                        this.itemsList.push(dropDown);
                    }
                    console.log(this.itemsList, 'items list');
                } else {
                    this.itemsList = [];
                }
                this.stopLoader();
            }, err => {
                console.error('err: ', err);
                this.stopLoader();
            });
    }

    fieldReset(control) {
        return this.invoiceItemsDetailForm.get(control).reset();
    }

    diplayeIconInField(control) {
        return this.invoiceItemsDetailForm.get(control)?.valid;
    }

    inputFieldDanger(control) {
        return !this.invoiceItemsDetailForm.get(control)?.valid && this.invoiceItemsDetailForm?.get(control)?.touched;
    }

    onCloseAdd() {
        // closeDialogAddOverlaydiv();
        this.invoiceItemsDetailForm.reset();
    }

    onCloseSearch() {
        // closeDialogSearchOverlaydiv();
        this.invoiceItemsDetailSearch.reset();
    }

    refresh() {
        this.countInvoiceItemsDetail();
    }

    onSearchOverlayDiv() {
        // openDialogSearchOverlaydiv();
        // this.invoiceItemsDetailSearch.reset();
    }

    onCloseSearchOverlayDiv() {
        // closeDialogSearchOverlaydiv();
    }

    onAddOverlayDiv() {
        this.invoiceItemsDetailForm.reset();
        // openDialogAddOverlaydiv();
        this.addEditTitle = 'Add';
    }

    onCloseAddOverlayDiv() {
        // closeDialogAddOverlaydiv();
        this.invalidQuantity = false;
        this.invalidDiscount = false;
        this.invalidUnitPrice = false;
        this.invalidTaxAmount = false;
    }

    maxValue = 2147483647;
    invalidQuantity = false;
    invalidUnitPrice = false;
    invalidTaxAmount = false;
    calculateAmount() {

        this.invalidQuantity = this.invoiceItemsDetailForm.value.quantity && this.invoiceItemsDetailForm.value.quantity > this.maxValue;

        this.invalidUnitPrice = this.invoiceItemsDetailForm.value.unitPrice && this.invoiceItemsDetailForm.value.unitPrice > this.maxValue;

        this.invalidTaxAmount = this.invoiceItemsDetailForm.value.taxAmount && this.invoiceItemsDetailForm.value.taxAmount > this.maxValue;


        if (this.invoiceItemsDetailForm.value.quantity) {

        } else {
            this.invoiceItemsDetailForm.controls['quantity'].setValue(0);
        }
        if (this.invoiceItemsDetailForm.value.unitPrice) {

        } else {
            this.invoiceItemsDetailForm.controls['unitPrice'].setValue(0);
        }
        if (this.invoiceItemsDetailForm.value.discount) {

        } else {
            this.invoiceItemsDetailForm.controls['discount'].setValue(0);
        }
        if (this.invoiceItemsDetailForm.value.taxAmount) {

        } else {
            this.invoiceItemsDetailForm.controls['taxAmount'].setValue(0);
        }
        let totalamount = 0;
        totalamount = (this.invoiceItemsDetailForm.value.quantity * this.invoiceItemsDetailForm.value.unitPrice -
            this.invoiceItemsDetailForm.value.discount + this.invoiceItemsDetailForm.value.taxAmount);
        this.invalidDiscount = totalamount < 0;
        console.log('Total amount is : ', totalamount);
        this.invoiceItemsDetailForm.controls['totalAmount'].setValue(totalamount);

        // this.invalidDiscount = totalAmount && this.invoiceItemsDetailForm.value.discount && totalAmount < this.invoiceItemsDetailForm.value.discount;



    }

    // searchTerm = null;
    // onGlobalSearchClick(eventValue){
    //     console.log("global search clicked ",eventValue);
    //     this.searchTerm = eventValue;
    //     this.invoiceItemsDetailServices.findGlobalInvoiceDetail(eventValue, this.pageNo, this.itemPerPage).subscribe(result =>{
    //         if(result['statusCode'] == 200){
    //             this.invoiceItemsDetailList = result['data'];
    //             this.totalRecords = this.invoiceList.length;
    //         }else{
    //             this.invoiceItemsDetailList = [];
    //             this.totalRecords = 0;
    //             this.countInvoiceItemsDetail();
    //         }
    //
    //         console.log(result,"result");
    //     });
    //
    // }
    searchTerm = null;

    onGlobalSearchClick(eventValue) {
        console.log('global search clicked ', eventValue);
        this.searchTerm = eventValue;
        this.invoiceDetailGlCount();

    }

    invoiceDetailGlCount() {
        this.invoiceItemsDetailServices.invoiceDetailGlcount(this.searchTerm, this.invoiceId).subscribe(res => {
            if (res['message'] == 'Success') {
                this.totalRecords = res['data'][0];
                this.findAllGlSearchInvoiceDetail();
            } else {
                this.totalRecords = 0;
                this.invoiceItemsDetailList = [];
            }
        }, err => {
            console.error('err: ', err);
        });
    }

    findAllGlSearchInvoiceDetail() {
        this.invoiceItemsDetailServices.findGlobalInvoiceDetail(this.searchTerm, this.pageNo, this.itemPerPage, this.invoiceId).subscribe(result => {
            if (result['statusCode'] == 200) {
                this.invoiceItemsDetailList = result['data'];
            } else {
                this.invoiceItemsDetailList = [];
            }
            console.log(result, 'result');
        });
    }
    // by ali
    showHideSearchDialog() {
        this.showSearchDialog = !this.showSearchDialog;
        this.showSearchBtn = this.showSearchDialog;
        this.showAddBtn = this.showSearchDialog;
    }

    showHideAddDialog() {
        if (!this.showAddDialog) {
            this.invoiceItemsDetailForm.reset();
        }
        this.addEditTitle = this.labels.btnAdd;
        this.showAddDialog = !this.showAddDialog;
        this.showAddBtn = this.showAddDialog;
        this.showSearchBtn = this.showAddDialog;
        this.editTag = false;
        this.updateInvoiceItemsDetailId = null;

        this.invalidQuantity = false;
        this.invalidDiscount = false;
        this.invalidUnitPrice = false;
        this.invalidTaxAmount = false;
    }
}
